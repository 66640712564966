import React, { useEffect, useState } from "react";
import { BootstrapColors, FontAwesomeIcons } from '../../core/Types';



type IconProps = {
    type?: "solid" | "regular" | "light" | "thin" | "brands"
    doutone?: boolean
    color?: string
    icon?: FontAwesomeIcons
    spin?: boolean
    rotate?: "90" | "180"
    className?: string
    size?: number
    shake?: boolean
    id?: string
    title?: string
    tooltip?: string
    minWidth?: number
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

function Icon(props: IconProps) {

    const [inputId, inputIdSet] = useState<string>("icon-" + (Math.floor(Math.random() * 1000000)))
    useEffect(() => {
        if (props.id) inputIdSet(props.id)
    }, [props.id])

    const createClassName = () => {
        var icon = props.icon;
        if (icon == "queue") icon = "arrow-right-to-line";
        var className = "fa-" + (props.type ? props.type : "regular");
        if (props.doutone) className = " fa-duotone";
        className += " fa-" + icon;
        if (props.spin) className += " fa-spin";
        if (props.className) className += " " + props.className;
        if (props.rotate) className += " fa-rotate-" + props.rotate;
        if (props.onClick) className += " corsur-pointer";
        return className;
    }

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (props.shake) {
            $("#" + inputId).addClass("ringing");
            setTimeout(() => {
                $("#" + inputId).removeClass("ringing");
            }, 2500);

            const shakeInterval = setInterval(() => {
                $("#" + inputId).addClass("ringing");
                setTimeout(() => {
                    $("#" + inputId).removeClass("ringing");
                }, 2500);
            }, 4000);
            return () => clearInterval(shakeInterval);
        }
    }, [])


    // useEffect(() => {
    //     if (inputId && props.title) {
    //         window.createBootstrapTooltip(inputId);
    //     }
    // }, [inputId, props.title])


    return (
        <i className={createClassName()}
            id={inputId}
            title={props.title}
            style={{
                color: props.color ?? "inherit",
                fontSize: props.size,
                minWidth: props.minWidth
            }}
            onClick={props.onClick}
        ></i>
    )
}

export default Icon;

