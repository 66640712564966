import { useEffect, useState } from "react";
import { closeUpdateEnvironment, setOwnerUserId, useHub } from "../../core/Global";
import Modal from "../base/Modal";
import DataItem from "../../models/DataItem";
import { API_ROUTES } from "../../core/Constants";
import { CompactUser } from "../../core/CompactModels";
import Avatar from "../base/Avatar";
import User from "../../modules/accounts/models/User";





export default function ModalChangeEnvironment() {

    const hub = useHub()

    const [keyword, keywordSet] = useState<string>("")
    const [model, modelSet] = useState<User[]>([])
    const [selectedEnv, selectedEnvSet] = useState<User>();

    useEffect(() => {
        hub.Get(API_ROUTES.Users + "envs", res => modelSet(res.data))
    })


    return (
        <Modal id="modal-change-environment"
            title="Workspaces"
            buttonText="Change Workspace"
            onClose={closeUpdateEnvironment}
            open visible
            onOpen={() => {
                $("#txt-workspaces-keyword").focus();
            }}
            onConfirm={callback => {



                hub.Post(API_ROUTES.Users + "envs", {
                    id: selectedEnv?.id
                }, res => {
                    if (res.success) {
                        setOwnerUserId(selectedEnv!.id!)
                        window.location.reload()
                    }

                })

            }}
            confirmDisabled={selectedEnv?.id == null}>
            <div>
                <div className="mb-3 position-relative">
                    <input id="txt-workspaces-keyword" type="text" autoComplete="one-time-code"
                        className="form-control form-control-sm form-control-solid" placeholder="Search..." value={keyword}
                        onChange={e => keywordSet(e.target.value)} />
                </div>

                <div className="scroll h-300px h-xxl-400px">
                    {
                        model.filter(f => {
                            return f.fullName?.toLowerCase().includes(keyword.toLowerCase()) || f.companyName?.toLowerCase().includes(keyword.toLowerCase())
                        })
                            .map((item, key) =>
                                <label key={key} onClick={e => selectedEnvSet(item)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center mb-2" + (selectedEnv?.id == item.id ? " active" : "")}>
                                    <Avatar avatarUrl={item.avatarUrl} width={26} />
                                    <div className="ms-3">
                                        <div className="text-dark fw-bolder fs-7 text-start">{item.fullName}</div>
                                        <div className="text-gray-500 fs-8 text-start">{item.companyName}</div>
                                    </div>
                                </label>
                            )
                    }
                </div>


            </div>
        </Modal>
    )
}