import { useEffect, useState } from "react";
import ChatMessage from "./modules/chat/models/ChatMessage";
import { useEnvironment, useOwnerUserId, useSocket } from "./core/Global";
import { DesktopNotificationItem } from "./models/Notification";


interface IProps {
    userId: string
    ownerId: string
}

export default function NotificationCenter(props: IProps) {

    const socket = useSocket();
    const env = useEnvironment();
    const ownerUserId = useOwnerUserId();

    useEffect(() => {


        var socketKey = socket.start(
            skt => {
                skt.invoke("StartListenToDesktopNotifs", props.userId, props.ownerId)
                    .then(() => {

                        skt.on("PushDesktopNotification", (notif: DesktopNotificationItem) => {

                            if (env.isDevelopment) console.log("new desktop notif recieved!", document.visibilityState, notif);

                            if (document.visibilityState == "visible") return;

                            if (!("Notification" in window)) {
                                alert("This browser does not support system notifications!")
                            }
                            else if (Notification.permission === "granted") {
                                push(notif);
                            }
                            else if (Notification.permission !== "denied") {
                                Notification.requestPermission((permission) => {
                                    if (permission === "granted") {
                                        push(notif);
                                    }
                                })
                            }
                        })
                    });

            }, skt => {
                skt.invoke("StopListenToDesktopNotifs", props.userId, props.ownerId);
                skt.off("PushDesktopNotification");
            });

        return () => {
            socket.stop(socketKey);
        }

    }, [])

    const push = (notif: DesktopNotificationItem) => {

        const n = new Notification(notif.title, {
            body: notif.body,
            tag: notif.tag,
            icon: notif.icon
        });

        n.onclick = () => {
            window.focus();

            if (notif.objectType == "chat") {
                var link = "/chat/" + notif.objectId;
                if (!window.location.pathname.startsWith(link)) {
                    document.location.href = link;
                }
            }

        }
    }

    // const sendChatNotif = (msg: ChatMessage) => {
    //     const n = new Notification(msg.senderUser?.fullName ?? "", {
    //         body: msg.previewText,
    //         tag: "sendChatNotif-" + msg.id,
    //         icon: msg.senderUser?.avatarUrl
    //     });

    //     n.onclick = () => {
    //         window.focus();
    //         var link = "/chat/" + msg.chatId;
    //         if (!window.location.pathname.startsWith(link)) {
    //             document.location.href = link;
    //         }
    //     }
    // }

    return (<></>)
}