import { useEffect, useState } from "react";
import Job from "../models/Job";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Timer from "../../../../components/timer/Timer";
import Clock from "../../../../components/timer/Clock";
import { Link, useNavigate } from "react-router-dom";
import { createJob, getUser, isDemo, openArchiveJob, openCopyJob, openJobQuickView, opennotCalledActions, toPrettyDate, useChatList, useGlobal, useHub, usePreferences, useScreen, useSocket, useStatusesForJob } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import JobStatus from "../../base/models/JobStatus";
import Avatar from "../../../../components/base/Avatar";
import { toastDefault, toastError } from "../../../../components/base/Toast";
import DropdownMenu from "../../../../components/menu/DropdownMenu";
import { convertTo12HourFormat, copyToClipboard } from "../../../../core/Helper";
import Time from "../../../../components/timer/Time";
import { TimeFormats } from "../../../../core/Enums";

interface IProps {
    job: Job
    onQuickView?: (job: Job) => void
    onUpdateAddress?: (job: Job) => void
    onUpdateTech?: (job: Job) => void
    onUpdateAppointment?: (job: Job) => void
    onUpdateStatusAppointment?: (job: Job) => void
    onUpdateStatus?: (job: Job) => void
    onUpdateStatusNote?: (job: Job, st: JobStatus) => void
    onSendJob?: (job: Job) => void
    onManageTechResponse?: (job: Job, s: number) => void
    onViewNotes?: (job: Job) => void
    onOpenAttachments?: (job: Job) => void
    onViewTasks?: (job: Job) => void
    onEdit?: (job: Job) => void
    onCloseJob?: (job: Job, callback: () => void) => void
    onInProgressError?: (err: string) => void
    onReopenFromClosed?: (job: Job, st: JobStatus) => void
}
export default function JobDataRow(props: IProps) {

    const hub = useHub();
    const user = getUser();
    const pref = usePreferences();
    const chats = useChatList();
    const statuses = useStatusesForJob()
    const navigate = useNavigate();

    const screen = useScreen();
    const [compact, compactSet] = useState<boolean>(false)
    useEffect(() => {
        if (screen.width < 1500) compactSet(true)
        else compactSet(false)
    }, [screen])


    const [job, jobSet] = useState<Job>({});
    useEffect(() => jobSet(props.job), [props.job]);

    const [statusUpdating, statusUpdatingSet] = useState<boolean>(false);

    const [jobSentTime, jobSentTimeSet] = useState<number>(0);
    const [jobSentColor, jobSentColorSet] = useState<string>("success");

    const [HasAttachments, HasAttachmentsSet] = useState<boolean>(false);
    const [HasTasks, HasTasksSet] = useState<boolean>(false);

    const [techAcceptedTotalSeconds, techAcceptedTotalSecondsSet] = useState<number>(0);

    useEffect(() => {
        if (job) {
            var hasNote = false;
            var HasAttachments = false;
            var hasTasks = false;
            var hasCalls = false;

            if (job.attachmentsCount && job.attachmentsCount > 0 && job.usersSeenAttachments && user?.id && job.usersSeenAttachments.indexOf(user.id) < 0) HasAttachments = true;
            if (job.tasksCount && job.tasksCount > 0 && job.usersSeenTasks && user?.id && job.usersSeenTasks.indexOf(user.id) < 0) hasTasks = true;


            HasAttachmentsSet(HasAttachments);
            HasTasksSet(hasTasks);


            job.flags?.forEach(f => {
                window.createBootstrapTooltip(`job-flags-${job.id}-${f.id}`);
            });

            window.createBootstrapTooltip(`job-tags-${job.id}`)



        }
    }, [job])

    useEffect(() => {

        if (job.techAccepted && !job.ignoreNotCalledAlert) {

            var i = job.techAcceptedTotalSeconds ?? 0;
            techAcceptedTotalSecondsSet(i);

            const callIntv = setInterval(() => {
                techAcceptedTotalSecondsSet(t => t + 1);
            }, 1000);

            return () => {
                clearInterval(callIntv);
            }
        }

    }, [job])

    // useEffect(() => {

    //     if (job.id) {

    //         var socketKey = socket.start(
    //             skt => {
    //                 skt.invoke("StartListenToJob", job.id)
    //                     .then(() => {
    //                         skt.on("UpdateJobRow-" + job.id, data => {
    //                             if (data.id == job.id) {
    //                                 jobSet(data);
    //                                 // $("#job-row-" + job.id).addClass("job-note-blink");
    //                                 // $("#job-actions-" + job.id).addClass("job-note-blink");
    //                                 // setTimeout(() => {
    //                                 //     $("#job-row-" + job.id).addClass("job-note-blink-off");
    //                                 //     $("#job-actions-" + job.id).addClass("job-note-blink-off");
    //                                 //     $("#job-row-" + job.id).removeClass("job-note-blink");
    //                                 //     $("#job-actions-" + job.id).removeClass("job-note-blink");
    //                                 // }, 500);
    //                             }
    //                         });
    //                     });

    //             }, skt => {
    //                 skt.invoke("StopListenToJob", job.id)
    //                     .then(() => {
    //                         skt.off("UpdateJobRow-" + job.id);
    //                     });
    //             });

    //         return () => {
    //             socket.stop(socketKey);
    //         };
    //     }

    // }, [job]);



    const [chatOpened, chatOpenedSet] = useState<boolean>(false);
    useEffect(() => {
        chatOpenedSet(false);
    }, chats);

    const HasUnreadChat = () => {
        if (chatOpened) return false;
        var res = false;
        chats?.forEach(c => {
            if (c.tech?.id && job.techId && c.tech.id == job.techId && c.unreadCount && c.unreadCount > 0) {
                res = true;
                return;
            }
        })
        return res;
    }

    const HasChat = () => {
        var res = false;
        chats?.forEach(c => {
            if (c.tech?.id && job.techId && c.tech.id == job.techId) {
                res = true;
                return;
            }
        })
        return res;
    }

    return (
        <tr id={`job-row-${job.id}`} className="data-row border-bottom">

            <td>
                <FlexRow className={`fs-7 mt-1`} title={`Job ID: #${job.jobId}`}>
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        if (props.onQuickView) props.onQuickView(job);
                    }}>
                        #{job.jobId}
                    </a>
                </FlexRow>

                <FlexRow>
                    <a href="#" title="Job Status"
                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                        onClick={e => {
                            e.preventDefault();
                        }}>
                        {
                            statusUpdating ?
                                <Icon icon="circle-notch" type="solid" spin color={job.jobStatus?.color} size={12} />
                                :
                                <Icon icon="circle" type="solid" color={job.jobStatus?.color} size={12} />
                        }

                    </a>
                    <span className={`fs-8 ms-1`} style={{ width: 45 }}>
                        <Timer key={props.job.id} baseDate={props.job.lastStatusUpdatedAt} short />
                    </span>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"

                                onClick={e => {
                                    e.preventDefault();
                                    statusUpdatingSet(true);
                                    hub.Post(API_ROUTES.Jobs + "refreshstatus", {
                                        id: job?.id
                                    }, res => {
                                        statusUpdatingSet(false);
                                    });
                                }}>
                                <Icon icon="redo" color={job.jobStatus?.color} className="me-2" />
                                Refresh Status
                            </a>
                        </div>
                        {
                            statuses?.map((st, k) =>
                                <>
                                    {
                                        st.id == job.jobStatusId ? <></>
                                            :
                                            <div key={k} className="menu-item">
                                                <a href="#" className="menu-link px-3"

                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (job.isClosed) {
                                                            if (props.onReopenFromClosed) props.onReopenFromClosed(job, st)
                                                        }
                                                        else if (st.isClosed) {
                                                            statusUpdatingSet(true)
                                                            if (props.onCloseJob) props.onCloseJob(job, () => statusUpdatingSet(false))
                                                        }
                                                        else if (st.isAppointment && window.isNullOrEmpty(job.appointmentDate)) {
                                                            if (props.onUpdateStatusAppointment) props.onUpdateStatusAppointment(job);
                                                        }
                                                        else if (st.noteRequired) {
                                                            if (props.onUpdateStatusNote) props.onUpdateStatusNote(job, st);
                                                        }
                                                        else {
                                                            statusUpdatingSet(true);
                                                            hub.Post(API_ROUTES.Jobs + "jobstatus", {
                                                                id: job?.id,
                                                                status: st.id
                                                            }, res => {
                                                                statusUpdatingSet(false);
                                                            });

                                                        }

                                                    }}>
                                                    <Icon icon="circle" type="solid" color={st.color} className="me-2" />
                                                    {st.name}
                                                </a>
                                            </div>
                                    }
                                </>
                            )
                        }
                    </div>
                </FlexRow>

            </td>

            <td className="min-125">
                <FlexRow className={`mb-1`}>
                    <div className={`${compact ? "max-100" : "max-150"} fs-7 limited-text`} title={`Name: ${job.fullName}`}>
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            if (props.onQuickView) props.onQuickView(job);
                        }}>
                            {job.fullName}
                        </a>
                    </div>
                    {
                        job.flags?.map((flag, key) =>
                            <span id={`job-flags-${job.id}-${flag.id}`} key={key} className="job-dashboard-flag ms-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-custom-class="tooltip-dark"
                                title={`<div class='text-start'>
                                            <span class='badge' style='background-color:${flag.color}'>
                                                <i class='fas fa-flag text-white me-2 fs-9'></i>
                                                ${flag.name}
                                            </span>
                                        </div>
                                        ${window.isNullOrEmpty(flag.description) ? `` : `<div class='fs-8 fw-normal text-start line-break mt-4'>${flag.description}</div>`}`}
                                style={{
                                    backgroundColor: flag.color
                                }}>
                                <Icon icon="flag" size={8} type="solid" />
                            </span>
                        )
                    }
                    {
                        job.tags && job.tags.length > 0 &&
                        <span id={`job-tags-${job.id}`} className="ms-1" style={{ marginTop: 3 }}
                            data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" data-bs-custom-class="tooltip-dark"
                            title={`<div class='d-flex flex-wrap'>
                                        ${job.tags.map((t, k) => `<span class='badge badge-secondary me-1 mb-1'><i class='fas fa-tag me-1 fs-8'></i> ${t.name}</span>`).join('')}
                                    </div>`}>
                            <Icon icon="tag" size={13} type="solid" color="#666" />
                        </span>
                    }
                </FlexRow>
                <FlexRow className="text-gray-700">
                    <Icon icon="phone-rotary" type="solid" className="me-1" size={9} />
                    {
                        isDemo() ?
                            <span className={`fs-8`}>
                                {compact ? "+12073334444" : "(207) 333 4444"}
                            </span>
                            :
                            <span className={`fs-8`}>
                                {compact ? ((job.phone?.countryCode ?? "") + job.phone?.phone) : job.phone?.phoneFriendly}
                            </span>
                    }

                </FlexRow>
            </td>

            <td className="min-75">
                <div className={`${compact ? "max-100" : " max-150"} fs-7 limited-text mb-1`} title={`Job Type: ${job.jobType}`}>
                    {job.jobType}
                </div>
                {
                    compact ?
                        <FlexRow title={`Job Source: ${isDemo() ? "Source " + job.jobSource?.substring(0, 1).toUpperCase() : job.jobSource}`} className="text-gray-700">
                            <Icon icon="bullhorn" type="solid" className="me-1" size={9} />
                            <span className={`fs-8 max-100 limited-text`}>
                                {isDemo() ? "Source " + job.jobSource?.substring(0, 1).toUpperCase() : job.jobSource}
                            </span>
                        </FlexRow>
                        :
                        <FlexRow className="text-gray-700">
                            <Icon icon="tag" type="solid" className="me-1" size={9} />
                            <span className={`fs-8`}>
                                {job.jobTypeGroup}
                            </span>
                        </FlexRow>
                }
            </td>

            {
                !compact &&
                <td>
                    {
                        job.jobSourceId ?
                            <>
                                <div className={`fs-7  limited-text mb-1`}>
                                    {isDemo() ? "Source " + job.jobSource?.substring(0, 1).toUpperCase() : job.jobSource}
                                </div>
                                <FlexRow className="text-gray-700">
                                    <Icon icon="bullhorn" type="solid" className="me-1" size={9} />
                                    <span className={`fs-8`}>
                                        {job.jobSourceType}
                                    </span>
                                </FlexRow>
                            </>
                            :
                            <div className="fs-7 text-muted">[Unknown]</div>
                    }
                </td>
            }
            <td className="min-100 max-150">
                <div>
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        if (props.onUpdateAddress) props.onUpdateAddress(job);
                    }}>
                        {
                            job.address?.title ?
                                <>
                                    {
                                        isDemo() ?
                                            <div className={`fs-7`}>
                                                9977 S Semoran Blvd, Orlando, FL 9511-35777, USA
                                            </div>
                                            :
                                            <div className={`fs-7`}>
                                                {job.address.title}
                                            </div>
                                    }
                                </>
                                :
                                <div className="text-muted fs-8">
                                    [Set]
                                </div>
                        }

                    </a>
                </div>
            </td>

            {
                !compact &&
                <td className="fs-7 min-75">
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        if (props.onUpdateTech) props.onUpdateTech(job);
                    }}>
                        {
                            job.metroId ?
                                <div className="fs-7 max-150 limited-text">
                                    {job.metroName}
                                </div>
                                :
                                <div className="text-muted fs-8">
                                    [Assign]
                                </div>
                        }
                    </a>
                </td>
            }


            <td className="min-75">

                {
                    compact &&
                    <FlexRow title={`Metro: ${job.metroName ?? "Undefined"}`} className="mb-1">
                        <Icon icon="map" className="me-1" />
                        <span className="max-100 limited-text">
                            <a href="#" onClick={e => {
                                e.preventDefault();
                                if (props.onUpdateTech) props.onUpdateTech(job);
                            }}>
                                {
                                    job.metroId ?
                                        <div className="fs-7 max-150 limited-text">
                                            {job.metroName}
                                        </div>
                                        :
                                        <div className="text-muted fs-8">
                                            [Assign Metro]
                                        </div>
                                }
                            </a>
                        </span>
                    </FlexRow>
                }

                <FlexRow title={`Tech: ${job.tech?.fullName ?? "Undefined"}`}>
                    {
                        compact && <Icon icon="user-helmet-safety" className="me-2" />
                    }
                    <span>
                        <a href="#" onClick={e => {
                            e.preventDefault();
                            if (job.sentToTech && !job.techAccepted) {
                                if (props.onManageTechResponse) props.onManageTechResponse(job, jobSentTime);
                            }
                            else if (props.onUpdateTech) props.onUpdateTech(job);
                        }}>
                            {
                                job.tech ?
                                    <span className={`${compact ? "max-100" : "max-150"} limited-text fs-7`}>
                                        {isDemo() ? "Tech " + job.tech.fullName?.substring(0, 2).toUpperCase() : job.tech.fullName}
                                    </span>
                                    :
                                    <div className="text-muted fs-8">
                                        [Assign Tech]
                                    </div>
                            }
                        </a>
                    </span>
                </FlexRow>

                {
                    job.sentToTech && !job.techAccepted && !job.isClosed && !job.isCanceled &&
                    <div className="mt-1">
                        <a href="#" title="Click to manage the technician respond!"
                            onClick={e => {
                                e.preventDefault();
                                if (props.onManageTechResponse) props.onManageTechResponse(job, jobSentTime);
                            }}>
                            <FlexRow className={"text-" + jobSentColor}>
                                <Icon icon="timer" className="me-1" />
                                <Timer baseDate={job.sentToTechAt}
                                    onTick={t => {
                                        jobSentColorSet(t < 60 ? "success" : t < 120 ? "warning" : "danger");
                                        jobSentTimeSet(t);
                                    }} />
                            </FlexRow>
                        </a>
                    </div>
                }

                {
                    job.tech && job.techAccepted &&
                    <>
                        {
                            (job.jobCalls?.filter(f => f.isCompleted).length ?? 0) > 0 ?
                                <FlexRow title="Technician has contacted the customer!" className="mt-1">
                                    <Icon icon="phone-circle" className="text-success me-1" type="solid" />
                                    <span className="text-success fs-9" style={{ marginTop: 1 }}>Called</span>
                                </FlexRow>
                                : job.ignoreNotCalledAlert ?
                                    <FlexRow className="mt-1">
                                        <Icon icon="phone-circle" className="text-info me-1" type="solid" />
                                        <span className="text-info fs-9" style={{ marginTop: 1 }}>{job.ignoreNotCalledText}</span>
                                    </FlexRow>
                                    : techAcceptedTotalSeconds > 300 ?
                                        <FlexRow title="Technician call delayed!" className="mt-1 blinking-text" onClick={() => opennotCalledActions(job)}>
                                            <Icon icon="phone-slash" className="text-danger me-1" type="solid" />
                                            <span className="text-danger fs-8 me-2" style={{ marginTop: 1 }}>
                                                Not Called!
                                            </span>
                                            <span className="text-danger fs-9" style={{ marginTop: 1.5 }}>
                                                <Time totalSeconds={techAcceptedTotalSeconds} />
                                            </span>
                                        </FlexRow>
                                        : techAcceptedTotalSeconds > 100 ?
                                            <FlexRow title="Technician call delayed!" className="mt-1" onClick={() => opennotCalledActions(job)}>
                                                <Icon icon="phone-slash" className="text-warning me-1" type="solid" />
                                                <span className="text-warning fs-8 me-2" style={{ marginTop: 1 }}>
                                                    Not Called!
                                                </span>
                                                <span className="text-warning fs-9" style={{ marginTop: 1.5 }}>
                                                    <Time totalSeconds={techAcceptedTotalSeconds} />
                                                </span>
                                            </FlexRow>
                                            :
                                            <FlexRow title="Technician has accepted the job!" className="mt-1">
                                                <Icon icon="check-circle" className="text-success me-1" type="solid" />
                                                <span className="text-success fs-9" style={{ marginTop: 1 }}>Accepted</span>
                                            </FlexRow>

                        }

                    </>

                }
            </td>

            <td className="min-75">
                <div>
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        if (props.onUpdateAppointment) props.onUpdateAppointment(job);
                    }}>
                        {
                            job.appointmentDate ?
                                <>
                                    <div className="fs-7">
                                        {job.appointmentDate}
                                    </div>
                                    <FlexRow className="text-gray-700">
                                        <Icon icon="clock" type="solid" className="fs-8 me-1" size={9} />
                                        {
                                            pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours ?
                                                <span className="fs-8">
                                                    {convertTo12HourFormat(job.appointmentTimeFrom, true) + "-" + convertTo12HourFormat(job.appointmentTimeTo)}
                                                </span>
                                                :
                                                <span className="fs-8">
                                                    {job.appointmentTimeFrom + "-" + job.appointmentTimeTo}
                                                </span>
                                        }

                                    </FlexRow>
                                </>
                                :
                                <div className="text-muted fs-8">
                                    [Assign Apt]
                                </div>
                        }
                    </a>
                </div>

                {
                    compact &&
                    <>
                        {
                            job.timezone ?
                                <FlexRow className="mt-2 fs-8" title={`${job.address?.address?.state ?? "Local"} Time`}>
                                    <Icon icon="clock" className="me-1" size={11} />
                                    <span style={{
                                        marginTop: 1
                                    }}>
                                        <Clock offset={job.timezoneOffset} />
                                    </span>
                                </FlexRow>
                                :
                                <FlexRow className="mt-2 fs-8" title="Local Time is not specified!">
                                    <Icon icon="clock" className="me-1" size={11} />
                                    <span>-</span>
                                </FlexRow>
                        }
                    </>
                }

            </td>

            {
                !compact &&
                <td className="fs-7 min-75">
                    {
                        job.stateId && job.timezone ?
                            <>
                                <Icon icon="clock" className="me-1" size={11} />
                                <Clock offset={job.timezoneOffset} />
                            </>
                            :
                            <>
                                <Icon icon="clock" className="me-1" size={11} />
                                <span>-</span>
                            </>
                    }
                </td>
            }


            <td className="min-100">
                <div className="fs-7">
                    {toPrettyDate(job.modifiedAt)}
                </div>
                {
                    job.lastUpdateUser ?
                        <FlexRow title={`Created by: ${job.lastUpdateUser?.fullName}`}>
                            <Avatar avatarUrl={job.lastUpdateUser?.avatarUrl} fullName={job.lastUpdateUser?.fullName} width={16} />
                            <span className={`${compact ? "max-75 limited-text" : "max-125 limited-text"} fs-8 ms-1`}>
                                {isDemo() ? "Agent " + job.lastUpdateUser?.fullName?.substring(0, 2).toUpperCase() : job.lastUpdateUser?.fullName}
                            </span>
                        </FlexRow>
                        : <FlexRow title={`Created by: ${job.creatorUser?.fullName}`}>
                            <Avatar avatarUrl={job.creatorUser?.avatarUrl} fullName={job.creatorUser?.fullName} width={16} />
                            <span className={`${compact ? "max-75 limited-text" : "max-125 limited-text"} fs-8 ms-1`}>
                                {isDemo() ? "Agent " + job.creatorUser?.fullName?.substring(0, 2).toUpperCase() : job.creatorUser?.fullName}
                            </span>
                        </FlexRow>
                }
            </td>

            <td>

                <FlexRow>

                    {
                        !job.isClosed && !job.isCanceled &&
                        <>
                            {
                                job.sentToTech ?
                                    <a href="#" className={`${compact ? "me-2" : "me-3"}`} title="Resend Job" onClick={e => {
                                        e.preventDefault();
                                        if (props.onSendJob) props.onSendJob(job);
                                    }}>
                                        <Icon icon="share" type="solid" className="text-success" size={compact ? 14 : 16} />
                                    </a>
                                    :
                                    <a href="#" className="me-3" title="Send Job" onClick={e => {
                                        e.preventDefault();
                                        if (props.onSendJob) props.onSendJob(job);
                                    }}>
                                        <Icon icon="share" type="regular" className="text-dark" size={compact ? 14 : 16} />
                                    </a>
                            }
                        </>
                    }

                    <a href="#" className={`${compact ? "me-2" : "me-3"} position-relative`} title="Notes"
                        onClick={e => {
                            e.preventDefault();
                            if (props.onViewNotes) props.onViewNotes(job);

                        }}>
                        <Icon icon="note" type="regular" className="text-dark" size={compact ? 14 : 16} />
                        {
                            job.unseenNotes ?
                                <Icon icon="circle" type="solid" className="badge-job-dashboard text-danger" size={7} />
                                : (job.notesCount && job.notesCount > 0) ?
                                    <Icon icon="circle" type="solid" className="badge-job-dashboard text-primary" size={5} />
                                    : <></>
                        }
                    </a>

                    <a href="#" className={`${compact ? "me-2" : "me-3"} position-relative`} title="Job Chat"
                        onClick={e => {
                            e.preventDefault();
                            if (job.techId) {
                                openJobQuickView(job.id, "4")
                            }
                        }}>
                        <Icon icon="comment-lines" type="regular" className={job.techId ? "text-dark" : "text-muted"} size={compact ? 14 : 16} />
                        {
                            HasUnreadChat() ?
                                <Icon icon="circle" type="solid" className="badge-job-dashboard text-danger" size={7} />
                                : HasChat() ?
                                    <Icon icon="circle" type="solid" className="badge-job-dashboard text-primary" size={5} />
                                    : <></>
                        }
                    </a>

                    <a href="#" className={`${compact ? "me-2" : "me-3"} position-relative`} title="Job Calls"
                        onClick={e => {
                            e.preventDefault();
                            openJobQuickView(job.id, "5")
                        }}>
                        <Icon icon="phone" type="regular" className="text-dark" size={compact ? 14 : 16} />
                        {
                            job.jobCalls && job.jobCalls.length > 0 && job.jobCalls.filter(f => f.isCompleted && (f.listeners?.length ?? 0) < 1).length > 0 ?
                                <Icon icon="circle" type="solid" className="badge-job-dashboard text-danger" size={7} />
                                : job.jobCalls && job.jobCalls.length > 0 ?
                                    <Icon icon="circle" type="solid" className="badge-job-dashboard text-primary" size={5} />
                                    : <></>
                        }
                    </a>

                    <div>
                        <a href="#" className="ps-3 px-4 position-relative"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                            onClick={e => {
                                e.preventDefault();
                                //
                            }}>
                            <Icon icon="ellipsis-vertical" type="regular" className="text-dark" size={compact ? 14 : 16} />
                        </a>
                        <DropdownMenu width={180} source={[
                            {
                                title: "Archive Job",
                                icon: "archive",
                                visible: job.isClosed || job.isCanceled,
                                action() {
                                    openArchiveJob(job)
                                },
                            },
                            {
                                title: "View Job Details",
                                icon: "eye",
                                to: "/crm/jobs/overview/" + job.id
                            },
                            {
                                title: "Estimates",
                                icon: "file-invoice-dollar",
                                action() {
                                    navigate("/crm/jobs/estimates/" + job.id)
                                }
                            },
                            {
                                title: "Invoices",
                                icon: "file-invoice-dollar",
                                action() {
                                    navigate("/crm/jobs/invoices/" + job.id)
                                }
                            },
                            {
                                title: "Attachments",
                                icon: "paperclip-vertical",
                                visible: !job.isClosed && !job.isCanceled,
                                action() {
                                    if (props.onOpenAttachments) props.onOpenAttachments(job);
                                    HasAttachmentsSet(false);
                                }
                            },
                            {
                                title: "Tasks",
                                icon: "alarm-clock",
                                visible: !job.isClosed && !job.isCanceled,
                                action() {
                                    if (props.onViewTasks) props.onViewTasks(job);
                                    HasTasksSet(false);
                                }
                            },
                            {
                                title: "Copy Job",
                                icon: "copy",
                                async click(e) {
                                    e.preventDefault();
                                    var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=True")
                                    var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                    if (!copy) openCopyJob(job);
                                },
                            },
                            {
                                title: "Copy with Extension",
                                icon: "phone-office",
                                async click(e) {
                                    e.preventDefault();
                                    var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=False")
                                    var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                    if (!copy) openCopyJob(job);
                                }
                            },
                            {
                                title: "Duplicate",
                                icon: "layer-plus",
                                action() {
                                    createJob(job.id);
                                }
                            }
                        ]} />
                    </div>



                </FlexRow>

            </td>

        </tr>
    )
}