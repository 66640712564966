import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import { AgentStatuses } from "../../core/Enums";
import { getUserId, useHub } from "../../core/Global";
import { AgentStatus } from "../../modules/agents/models/AgentDetailsModel";
import Icon from "../base/Icon";
import Timer from "../timer/Timer";

interface IProps {
    id?: string
    status?: AgentStatus
    onUpdate?: () => void
}

export default function AgentStatusButton(props: IProps) {
    const hub = useHub();
    return (
        <div className="btn-group" role="group">
            <button className={"d-flex align-items-center btn btn-" + props.status?.colorClass + " btn-agent-ext"}
                onClick={e=> {
                    e.preventDefault();
                    window.openModal("modal-ext-info");
                }}>
                    <Icon icon={props.status?.icon} type="solid" className="" size={9} />
                    <span className="fs-8">{props.status?.name}</span>
            </button>
            {
                props.id == getUserId() &&
                <div className="btn-group" role="group">
                    <button id={"btn-status-agent"} disabled={props.status?.status == AgentStatuses.Busy}
                        type="button" className={"btn btn-"+props.status?.colorClass+" btn-agent-ext dropdown-toggle dsp-dropdown"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        onClick={e=> {
                            if (props.status?.status == AgentStatuses.Busy) return;
                            window.dropdown("btn-status-agent");
                        }}>
                        <Icon icon="ellipsis-vertical" className="dsp-dropdown" />
                    </button>
                    <div className="dropdown-menu dsp-dropdown">
                        {
                            props.status?.status != AgentStatuses.Active &&
                            <a className="dropdown-item dsp-dropdown fs-8" href="#"
                                onClick={e=> {
                                    e.preventDefault();
                                    window.dropdown("btn-status-agent", true);
                                    hub.Post("/accounts/agents/status/active/" + props.id, null, res => {
                                        if(props.onUpdate) props.onUpdate();
                                    })
                                }}>
                                    <Icon icon="circle" className="dsp-dropdown text-success w-20px text-center" size={10} type="solid" />
                                    Active
                            </a>
                        }

                        {
                            props.status?.status != AgentStatuses.Away &&
                            <a className="dropdown-item dsp-dropdown fs-8" href="#"
                                onClick={e=> {
                                    e.preventDefault();
                                    window.dropdown("btn-status-agent", true);
                                    hub.Post("/accounts/agents/status/away/" + props.id, null, res => {
                                        if (props.onUpdate) props.onUpdate();
                                    })
                                }}>
                                    <Icon icon="circle" className="dsp-dropdown text-warning w-20px text-center" size={10} type="solid" />
                                    <span>Away</span>
                            </a>
                        }

                        {
                            props.status?.status != AgentStatuses.Puased &&
                            <a className="dropdown-item dsp-dropdown fs-8" href="#"
                                onClick={e=> {
                                    e.preventDefault();
                                    hub.Post("/accounts/agents/status/pause/" + props.id, null, res => {
                                        if (props.onUpdate) props.onUpdate();
                                    })
                                }}>
                                    <Icon icon="pause" className="dsp-dropdown text-danger w-20px text-center" size={12} type="solid" />
                                    <span>Pause</span>
                            </a>
                        }
                            
                        
                    </div>
                </div>
            }
            
        </div>
    )
}

