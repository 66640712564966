import React, { useEffect } from "react"
import { Link } from "react-router-dom";
import Hub from "../../../api/Hub";
import { createJob, hasRole, openAnnouncements, setUnreadNotificationsCount, setUnseenNotificationsCount, useGlobal, useHub } from "../../../core/Global";
import Icon from "../../base/Icon";
import HeaderNavbar from "./HeaderNavbar";
import UserMenu from "./UserMenu";
import UserWallet from "./UserWallet";
import QuickMenu from "./QuickMenu";
import QuickCreateMenu from "./QuickCreateMenu";
import FlexRow from "../../base/FlexRow";
import IconButton from "../../base/IconButton";
import HeaderEnvironment from "./HeaderEnvironment";
import MobileHeader from "./MobileHeader";

function Header() {

  const hub = useHub();
  const [globalState] = useGlobal();

  useEffect(() => {
    if (globalState.user?.isCustomer) {
      setTimeout(() => {
        window.KTMenuCreate();
      }, 200);
    }
  }, [globalState.user])
  return (
    <div id="kt_header" className="header align-items-stretch">

      <MobileHeader />

      <div className="container-fluid d-none d-lg-flex align-items-stretch justify-content-between">

        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <HeaderNavbar />
          <div className="d-flex align-items-center flex-shrink-0 d-none d-md-flex">



            {
              globalState.user?.needsSubscribe &&
              <div className="me-10">
                <Link to="/profile/billing/subscription" className="btn btn-danger btn-sm">Subscribe Now</Link>
              </div>
            }

            {
              globalState.user?.isSystemAdmin && <HeaderEnvironment />
            }

            {globalState.user?.isCustomer && <UserWallet />}



            <FlexRow className="d-flex align-items-center">

              <div className="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative" title="Announcements"
                onClick={e => {
                  e.preventDefault();
                  openAnnouncements();
                }}>
                <Icon icon="bullhorn" type="solid" className="text-gray-600" size={16} />
                {
                  globalState.announcements && globalState.announcements.length > 0 &&
                  <span className="position-absolute translate-middle badge badge-circle badge-info" style={{
                    top: "20%",
                    left: "70%",
                    width: 18,
                    height: 18,
                    lineHeight: 18,
                    fontSize: 11
                  }}>
                    {globalState.announcements.length > 10 ? "9+" : globalState.announcements.length}
                  </span>
                }
              </div>

              <div className="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative" id="kt_activities_toggle"
                onClick={e => {
                  hub.Put("/accounts/notifications/seenall", {}, res => {
                    if (res.success) {
                      setUnseenNotificationsCount(0);
                    }
                  });
                }}>
                <Icon icon="bell" type="solid" className="text-gray-600" size={16} />
                {
                  globalState.unseenNotificationsCount && globalState.unseenNotificationsCount > 0 ?
                    <span className="position-absolute translate-middle badge badge-circle badge-primary" style={{
                      top: "15%",
                      left: "70%"
                    }}>
                      {globalState.unseenNotificationsCount > 10 ? "9+" : globalState.unseenNotificationsCount}
                    </span>
                    :
                    <>
                      {
                        globalState.unreadNotificationsCount && globalState.unreadNotificationsCount > 0 ?
                          <span className="bullet bullet-dot bg-primary h-6px w-6px position-absolute translate-middle top-25 start-75"></span>
                          : <></>
                      }
                    </>
                }
              </div>

            </FlexRow>

            <UserMenu />

          </div>

        </div>
      </div>
    </div>
  );
}

export default Header;
