import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import { CompactCountry } from "../../core/CompactModels";
import Country from "../../modules/base/models/Country";
import Contact from "../../modules/contacts/models/Contact";
import Icon from "../base/Icon";
import PhoneItem from "../../models/PhoneItem";
import FlexRow from "../base/FlexRow";
import DropDownItem from "../../models/DropDownItem";
import { getUSA, useAllCountries, useCountries, useHub, useScreen } from "../../core/Global";

interface TextBoxProps {
    id?: string
    label?: string
    labelUp?: boolean
    className?: string
    small?: boolean
    solid?: boolean
    name?: string
    parent?: string
    defaultValue?: string
    defaultPhone?: PhoneItem
    required?: boolean
    autoFocus?: boolean
    maxLength?: number
    email?: boolean
    inlineStyle?: boolean
    marginBottom?: number
    noMarginBottom?: boolean
    readOnly?: boolean
    block?: boolean
    tooltip?: string
    autoComplete?: boolean
    reset?: boolean
    countryCodes?: "strict" | "all" | "us-only"
    defaultCountry?: Country
    country?: Country
    tabIndex?: number
    onChange?: (e: PhoneItem) => void
    onSelect?: (contact: Contact) => void
    onComplete?: (e: PhoneItem) => void
    onInComplete?: () => void
    onCountrySelect?: (e: Country) => void
}


export default function PhoneInput(props: TextBoxProps) {

    let searchTimout: NodeJS.Timeout;

    const hub = useHub()
    const countries = useCountries()
    const countriesAll = useAllCountries()

    const screen = useScreen();

    const fontClass = () => {
        if (props.small) return "fs-8";
        return "fs-7";
    }

    const [inputId] = useState<string>(props.id ?? "txt-" + (Math.floor(Math.random() * 1000000)));

    const [model, modelSet] = useState<Contact[]>();
    const [showList, showListSet] = useState<boolean>();
    const [selectedIndex, selectedIndexSet] = useState<number>(0);
    const [selectedCountry, selectedCountrySet] = useState<Country>();

    const [placeholer, placeholderSet] = useState<string>("(XXX) XXX XXXX");

    const [defaultValueLoaded, defaultValueLoadedSet] = useState<boolean>(false);
    const [defaultCountryLoaded, defaultCountryLoadedSet] = useState<boolean>(false);

    const [value, valueSet] = useState<string>("");
    const [phone, phoneSet] = useState<PhoneItem>();

    const className = () => {
        var cls = "form-control form-inputs";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        if (props.autoFocus) cls += " auto-focus";
        if (props.className) cls += " " + props.className;
        return cls;
    };

    const codesClassName = () => {
        var cls = "form-select form-inputs form-select-solid";
        if (props.small) cls += " form-select-sm";
        return cls;
    };

    const inputGroupClassName = () => {
        //"input-group input-group-sm has-validation"
        var cls = "input-group has-validation";
        if (props.small) cls += " input-group-sm";
        if (props.solid) cls += " input-group-solid";
        return cls;
    }

    const keypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        window.hideFormError(props.name, props.parent);
        if (!/[0-9]/.test(e.key) && e.key != "Enter") {
            window.showFormError(props.name, "Please enter valid latin numbers!", true, props.parent);
            e.preventDefault();
        }
    }


    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        // if (e.key == "Backspace") {
        //     console.log("Backspace clear");
        //     e.preventDefault();
        // }

        if (!props.autoComplete) return;

        if (e.key == "ArrowDown") {
            e.preventDefault();
            handleArrowDown();
        }
        else if (e.key == "ArrowUp") {
            e.preventDefault();
            handleArrowUp();
        }
        else if (e.key == "Enter") {
            e.preventDefault();
            handleEnter();
        }
        else if (e.key == "Tab") {
            handleTab();
        }

    }

    const handleArrowDown = () => {
        if (model && model.length > 0) {
            var s = selectedIndex + 1;
            if (s >= model.length) s = model.length - 1;
            selectedIndexSet(s);
        }
    }

    const handleArrowUp = () => {
        var s = selectedIndex - 1;
        if (s < 0) s = 0;
        selectedIndexSet(s);
    }

    const handleEnter = () => {
        selectContact(selectedIndex);
    }

    const handleTab = () => {

    }

    const toRaw = (data?: string) => {
        var ph = "";
        if (!data) return "";
        data = data.replace("+1", "");
        var validNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        for (var i = 0; i < (data.length ?? 0); i++) {
            var c = data[i];
            if (validNumbers.indexOf(c) > -1) ph += c;
        }
        return ph;
    }

    const toFriendly = (data?: string) => {
        var ph = "";
        if (!data) return "";
        for (var i = 0; i < (data.length ?? 0); i++) {
            if (i == 0) ph += "(";
            if (i == 3) ph += ") ";
            if (i == 6) ph += " ";
            ph += data[i];
        }
        return ph;
    }


    const selectContact = (index: number) => {
        if (model && model.length > 0) {
            var item = model[index];
            console.log(item)
            selectedIndexSet(0);
            modelSet([]);
            if (props.onSelect) props.onSelect(item);

            phoneSet(item.phone);
            valueSet(item.phone?.phone ?? "");
        }
    }


    const fetch = (p?: string) => {

        if (!p || p.length < 3) {
            modelSet([]);
            return;
        }

        var q = `?phone=${p}&country=${phone?.countryId}`;

        hub.Get("/crm/contacts/autocomplete" + q, res => {
            if (res.data) {
                modelSet(res.data);
            }
        });
    }


    const change = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (props.name) {
            $("input[name=" + props.name + "]").removeClass("is-invalid");
            $("#" + props.name + "-err").hide();
        }

        var val = toRaw(e.target.value);
        if (val.length > 10) return;
        valueSet(val);

        let ph: PhoneItem = {
            ...phone,
            phone: val,
            phoneFriendly: toFriendly(val)
        };
        phoneSet(ph);

        if (ph.phone && ph.phone.length > 8 && props.onComplete) props.onComplete(ph);
        else if (props.onInComplete) props.onInComplete();

        if (props.autoComplete) {
            if (searchTimout) clearTimeout(searchTimout);
            searchTimout = setTimeout(() => fetch(ph.phone), 500);
        }

    }


    useEffect(() => {
        if (phone) if (props.onChange) props.onChange(phone);
    }, [phone])

    useEffect(() => {

        if (countries && (!props.countryCodes || props.countryCodes == "strict")) {
            window.makeCountriesDropDown(`${inputId}-drp-phone-code`,
                countries, props.parent,
                `${inputId}-div-phone-code`, true,
                data => {
                    selectedCountrySet(data);
                    if (props.onCountrySelect) props.onCountrySelect(data);
                    $("#" + inputId).focus();
                });
        }

        if (countriesAll && props.countryCodes == "all") {
            window.makeCountriesDropDown(`${inputId}-drp-phone-code`,
                countriesAll, props.parent,
                `${inputId}-div-phone-code`, true,
                data => {
                    selectedCountrySet(data);
                    if (props.onCountrySelect) props.onCountrySelect(data);
                    $("#" + inputId).focus();
                });
        }


    }, [countries, countriesAll, props.countryCodes])

    useEffect(() => {
        if (selectedCountry) {
            phoneSet({
                ...phone,
                countryId: selectedCountry.id,
                countryCode: "+" + selectedCountry.phoneCode
            })

            window.updateSelect2(`${props.id}-drp-phone-code`, selectedCountry.id);

            if (selectedCountry.alpha2Code?.toLowerCase() == "ge") placeholderSet("(5XX) XXX XXX");
            else placeholderSet("(XXX) XXX XXXX");
        }
    }, [selectedCountry])

    useEffect(() => {

        if (props.reset) {
            phoneSet({
                countryCode: "+1",
                phone: "",
                phoneFriendly: ""
            })
            valueSet("");
        }

    }, [props.reset])



    useEffect(() => {
        if (props.defaultValue && !defaultValueLoaded) {
            var val = toRaw(props.defaultValue);
            phoneSet({
                countryCode: "+1",
                phone: val,
                phoneFriendly: toFriendly(val)
            })
            valueSet(val);
            defaultValueLoadedSet(true);
        }
    }, [props.defaultValue])



    useEffect(() => {
        if (props.defaultPhone && !defaultValueLoaded && countries) {
            phoneSet(props.defaultPhone);
            countries.forEach(c => {
                if (c.id == props.defaultPhone?.countryId) {
                    selectedCountrySet(c);
                }
            });
            valueSet(toRaw(props.defaultPhone.phone))
            defaultValueLoadedSet(true);
            defaultCountryLoadedSet(true);
        }
        else if (props.defaultCountry && !defaultCountryLoaded) {
            selectedCountrySet(props.defaultCountry);
            defaultCountryLoadedSet(true);
        }
        else if (props.country) {
            selectedCountrySet(props.country);
        }
        else if (!defaultCountryLoaded) {
            selectedCountrySet(getUSA());
        }
    }, [props.defaultPhone, props.defaultCountry, props.country, countries])

    useEffect(() => {


    }, [])

    useEffect(() => {
        window.makeFormTooltip((props.id ?? inputId) + "-tooltip");
    }, []);

    return (
        <div className={"row align-items-center" + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 5))}>
            {
                props.label &&
                <label className={`col col-12 ${(props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2"))} ${fontClass()}`} htmlFor={inputId}>
                    {props.label}
                    {
                        props.tooltip &&
                        <i className="fa-solid fa-circle-question ms-2 form-tooltips"
                            id={(props.id ?? inputId) + "-tooltip"}
                            data-bs-placement="right"
                            data-bs-custom-class="tooltip-dark"
                            title={props.tooltip}></i>
                    }
                    {
                        props.labelUp && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                    }
                </label>
            }

            <div className={"col col-12 " + (props.autoComplete ? " position-relative" : "") + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>

                <div className={inputGroupClassName()}>
                    <div id={`${inputId}-div-phone-code`} className="input-group-text"
                        style={{
                            position: "relative"
                        }}>
                        {
                            selectedCountry &&
                            <>
                                <img src={selectedCountry.flagSquare} className="me-2"
                                    style={{
                                        borderRadius: "50%",
                                        height: 15
                                    }} />
                                <div className="fs-8 me-2" style={{
                                    height: 15
                                }}>
                                    +{selectedCountry.phoneCode}
                                </div>
                                {
                                    props.countryCodes != "us-only" &&
                                    <Icon icon="chevron-down" className="fs-8" />
                                }
                            </>
                        }

                        <div style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%"
                        }}>
                            <select id={`${inputId}-drp-phone-code`} className="d-none">
                            </select>
                        </div>
                    </div>

                    <input id={props.id ?? inputId}
                        value={toFriendly(value)}
                        name={props.name + "-main"}
                        type="tel"
                        autoComplete="one-time-code"
                        className={className()}
                        onKeyPress={keypress}
                        onKeyDown={keyDown}
                        autoFocus={props.autoFocus}
                        placeholder={placeholer}
                        readOnly={props.readOnly}
                        tabIndex={props.tabIndex}
                        onFocus={e => {
                            if (props.autoComplete) {
                                showListSet(true)
                            }
                        }}
                        onBlur={e => {
                            if (props.autoComplete) {
                                if (model && model.length > 0) {
                                    if (model[selectedIndex].phone?.phone == toRaw(e.target.value)) {
                                        selectContact(selectedIndex)
                                    }
                                }
                                setTimeout(() => {
                                    showListSet(false)
                                }, 500);
                            }
                        }}
                        onChange={change}
                    />
                    <input type="hidden" name={props.name} value={phone?.phone} data-hiddenphone="yes" />
                    <input type="hidden" name={props.name + "CountryId"} value={phone?.countryId} />
                    <input type="hidden" name={props.name + "CountryCode"} value={phone?.countryCode} />
                </div>

                {
                    props.autoComplete && model && showList &&
                    <div className="address-field-autocomplete">
                        {
                            model?.map((item, key) =>
                                <div key={key} className={"address-field-autocomplete-item" + (selectedIndex == key ? " selected" : "")}
                                    onClick={e => {
                                        e.preventDefault();
                                        selectContact(key);
                                    }}>
                                    <div className="d-flex align-items-center">
                                        <Icon icon="circle-user" type="solid" size={22} className="me-2" />
                                        <div>
                                            <div className="fw-bold fs-7">
                                                {item.fullName}
                                            </div>
                                            <div className="fs-7">
                                                {item.phone?.phoneFriendly}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
            </div>
            {
                props.block && props.label && false &&
                <div className="col col-12 col-md-3"></div>
            }
            {
                !props.labelUp &&
                <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                    <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                </div>
            }



        </div>
    )

}