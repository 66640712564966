import { useEffect, useState } from "react"
import Modal from "../../../components/base/Modal"
import { closeSettingsChat, useHub } from "../../../core/Global"
import ChatContactItem from "../models/ChatContactItem"
import Indicator from "../../../components/base/Indicator"
import { API_ROUTES } from "../../../core/Constants"
import PhoneInput from "../../../components/form/PhoneInput"
import DropDown from "../../../components/form/DropDown"
import FlexRow from "../../../components/base/FlexRow"
import Icon from "../../../components/base/Icon"


interface IProps {
    chat: ChatContactItem
}

export default function ChatSettingsModal(props: IProps) {

    const hub = useHub()


    return (
        <Modal id="modal-chat-settings"
            title="Chat Settings"
            open visible staticBackdrop
            onClose={closeSettingsChat}
            buttonText="Update Settings"
            onSubmit={(body, callback) => {
                hub.Post(API_ROUTES.Chat + "settings/" + props.chat.chatId, body, res => {
                    if (res.success) {

                    }
                    callback!(res)
                })
            }}>
            <div>

                <FlexRow className="mb-5 border-bottom pb-3">
                    <span className="me-2">
                        <Icon icon="comment-lines" size={18} />
                    </span>
                    <span className="fw-boldest fs-5">
                        {props.chat.title}
                    </span>
                </FlexRow>

                <DropDown id="drp-phonelines-envirmnetsss" key="drp-phonelines-envirmnetsss" name="environment"
                    label="Chat Environment" labelUp block parentModal="modal-chat-settings"
                    source={[
                        {
                            id: "1",
                            name: "GSM"
                        },
                        {
                            id: "3",
                            name: "Rinvox App"
                        }
                    ]}
                    defaultValue={props.chat.environment?.toString()} />

                <DropDown id="drp-phonelines-chatsender" key="drp-phonelines-chatsender" name="senderLineId"
                    label="GSM Sender" labelUp block parentModal="modal-chat-settings"
                    apiSource={API_ROUTES.Chat + "fromnumbers"} defaultValue={props.chat.senderLineId} />




            </div>
        </Modal>
    )
}