import { useEffect } from "react"
import { FontAwesomeIcons } from "../../core/Types"
import Icon from "../base/Icon"
import { CallListener } from "../../modules/voice/calls/models/Call"
import Avatar from "../base/Avatar"
import { toPrettyDate } from "../../core/Global"
import FlexRow from "../base/FlexRow"
import { Link } from "react-router-dom"


export interface MenuItem {
    title: string
    icon?: FontAwesomeIcons
    danger?: boolean
    visible?: boolean
    to?: string
    action?: () => void
    click?: (e: any) => Promise<void>
}
interface IProps {
    source?: MenuItem[]
    listerns?: CallListener[]
    width?: number
}

export default function DropdownMenu(props: IProps) {


    useEffect(() => {
        //window.dataTableMenu()
    }, [])

    return (
        <div className="menu menu-sub menu-sub-dropdown menu-column fw-bold fs-7 p-0 m-0"
            data-kt-menu="true"
            style={{
                width: props.width ?? 150,
                maxWidth: props.width ?? 150,
            }}
        >
            {
                props.source?.filter(f => f.visible == undefined || f.visible).map((item, key) =>
                    <div key={key} className="menu-item m-0">
                        {
                            item.to ?
                                <Link to={item.to} className={"menu-link px-3 " + (item.danger ? "text-danger" : "text-gray-700")}>
                                    <Icon icon={item.icon} className={"me-2 " + (item.danger ? "text-danger" : "text-gray-700")} type="solid" minWidth={13} />
                                    {item.title}
                                </Link>
                                :
                                <a href="#" className={"menu-link px-3 " + (item.danger ? " text-danger" : "text-gray-700")}
                                    onClick={item.click ? item.click : e => {
                                        e.preventDefault();
                                        if (item.action) item.action()
                                    }}
                                >
                                    <Icon icon={item.icon} className={"me-2 " + (item.danger ? " text-danger" : "text-gray-700")} type="solid" minWidth={13} />
                                    {item.title}
                                </a>
                        }

                    </div>
                )
            }

            {
                props.listerns?.map((item, key) =>
                    <div key={key} className="menu-item m-0">
                        <FlexRow className="menu-link px-3">
                            <Avatar fullName={item.user?.fullName} avatarUrl={item.user?.avatarUrl} width={20} className="me-2" />
                            <span className="text-white fw-normal max-100 limited-text">
                                {item.user?.fullName}
                            </span>
                            <span className="text-muted fs-9 ms-auto">
                                {toPrettyDate(item.listenedAt)}
                            </span>
                        </FlexRow>
                    </div>
                )
            }
        </div>
    )
}