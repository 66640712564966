import { HubConnectionState } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import Icon from "../../components/base/Icon";
import { useHub, useSocket } from "../../core/Global";
import { CallTypesPieChartModel } from "../models/PieChartsModels";


type Props = {
    model?: CallTypesPieChartModel
}

let socketInterval: NodeJS.Timeout;
let silentTime: number = 300;
let start: any, end: any, type: any;
function CallTypesPieChart(props: Props) {

    const hub = useHub();
    const socket = useSocket();
    const [model, modelSet] = useState<CallTypesPieChartModel>();
    const [dateLabel, dateLabelSet] = useState<string>("Today");
    const [dateQueryParam, dateQueryParamSet] = useState<string>(window.now().format("DDMMYYYY") + "," + window.now().format("DDMMYYYY"));

    const fetch = async () => {

        var q = "?";
        q += "from=" + (start ?? window.now()).format("DDMMYYYY");
        q += "&to=" + (end ?? window.now()).format("DDMMYYYY");
        hub.Get("/dashboard/main/callTypesPieChart" + q, res => {
            modelSet(res.data);
        })

    }

    const startSocket = async () => {

        //if (socket.state != HubConnectionState.Connected) await startSocket();

        // socket.invoke("StartListenToCallsList", getUserListenId());

        // socket.on("UpdateCallsList", () => {
        //     fetch();
        //     silentTime = 300;
        // });

        // if (socketInterval) clearInterval(socketInterval);

        // socketInterval = setInterval(async () => {
        //     if (socket.state != HubConnectionState.Connected) await startSocket();
        // }, 5000);

    }

    useEffect(() => {

        window.daterange("CallTypesPieChart_date_picker", (st: any, en: any, lbl: any) => {
            dateLabelSet(lbl);
            dateQueryParamSet(st.format("DDMMYYYY") + "," + en.format("DDMMYYYY"));
            if (lbl == "Custom Range") dateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
            else dateLabelSet(lbl);
            start = st;
            end = en;
            fetch();
        });

        startSocket();

        return () => {
            if (socketInterval) clearInterval(socketInterval);
        };


    }, [])

    useEffect(() => {
        modelSet(props.model);
    }, [props.model])

    useEffect(() => {
        if (model) {
            window.createCircleChart("chart-CallTypesPieChart",
                [model.totalIncomingCalls, model.totalOutgoingCalls],
                [window.getBootstrapColor("success"), window.getBootstrapColor("primary")]);

            window.createCircleChart("chart-CallTypesPieChart-small",
                [model.totalIncomingCalls, model.totalOutgoingCalls],
                [window.getBootstrapColor("success"), window.getBootstrapColor("primary")]);
        }

    }, [model])

    return (
        <>
            <div className="card card-flush d-none d-xxl-block">
                <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="chart-title fw-bolder text-dark me-2 lh-1">
                                {model?.totalCalls}
                            </span>
                        </div>
                        <span className="text-gray-400 pt-1 fw-bold fs-7">
                            Total Calls
                        </span>
                    </div>
                    <div>
                        <div id="CallTypesPieChart_date_picker" className="d-flex align-items-center cursor-pointer fs-8">
                            <span>{dateLabel}</span>
                            <Icon icon="chevron-down" className="ms-2" size={11} />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-items-center flex-grow-1">
                        <div>
                            <div id="chart-CallTypesPieChart" data-kt-size="70" data-kt-line="11"></div>
                        </div>
                        <div className="ms-auto">
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                                <div className="text-gray-500 flex-grow-1 me-6 fs-7">
                                    <a href={"/system/calls/history?callTypes=1&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                        Incoming
                                    </a>
                                </div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.totalIncomingCalls}
                                </div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                <div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                                <div className="text-gray-500 flex-grow-1 fs-7 me-6">
                                    <a href={"/system/calls/history?callTypes=2&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                        Outgoing
                                    </a>
                                </div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.totalOutgoingCalls}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-flush d-xxl-none">
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-column">

                        <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-dark me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-6 fs-8">
                                <a href={"/system/calls/history?callTypes=1&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Total Calls
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.totalCalls}
                            </div>
                        </div>

                        <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                            <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-6 fs-8">
                                <a href={"/system/calls/history?callTypes=1&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Incoming
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.totalIncomingCalls}
                            </div>
                        </div>
                        <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                            <div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
                            <div className="text-gray-500 flex-grow-1 fs-8 me-6">
                                <a href={"/system/calls/history?callTypes=2&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Outgoing
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.totalOutgoingCalls}
                            </div>
                        </div>

                    </div>

                    <div className="ms-auto">
                        <div id="chart-CallTypesPieChart-small" data-kt-size="70" data-kt-line="11"></div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default CallTypesPieChart;