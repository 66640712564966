import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/jobtypegroups.json";
import sortingProps from "../sorting/jobtypegroups.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import Section from "../../../../components/base/Section";
import { FormControl, InputLabel, TextField } from "@mui/material";
import TextBox from "../../../../components/form/TextBox";
import JobTypeGroup from "../models/JobTypeGroup";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";

function JobTypeGroupsList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<JobTypeGroup>();
  const [refresh, refreshSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);


  const edit = (data: any) => {
    selectedItemSet(data);
    editModalSet(true);
  }

  const deleteGroup = (data: any) => {
    selectedItemSet(data);
    var arr = [];

    arr.push({
      name: "excludeIds",
      value: data.id
    });

    if (data.ownerUserId) {
      arr.push({
        name: "userId",
        value: data.ownerUserId
      });
    }

    groupsRouteParamsSet(arr);

    deleteModalSet(true);
  }


  const NameItem = (data: any) => (
    <FlexRow>
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const JobTypesCount = (data: any) => (
    <div>
      {
        data.jobTypes > 0 ?
          <Link to={"/crm/base/jobtypes?groups=" + data.id}>
            {data.jobTypes}
          </Link>
          :
          <div>
            {data.jobTypes}
          </div>
      }

    </div>
  )

  const UserItem = (data: JobTypeGroup) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "Unassigned"}
      </div>
      <div className="small text-muted">
        @{data.ownerUser?.username}
      </div>
    </div>
  )

  const CreatedItem = (data: JobTypeGroup) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("Job Type Groups", "CRM", "Base Data");

  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>

        <DataTable
          objectName="Job Type Group"
          apiRoute={API_ROUTES.JobTypeGroups}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          addCustom={() => createModalSet(true)}
          edit editCustom={edit}
          delete={hasRole("DeleteJobTypeGroups")} deleteCustom={deleteGroup}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <DataTableActiveToggle route={API_ROUTES.JobTypeGroups} />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Job Types">
            <JobTypesCount />
          </DataTableField>
          <DataTableField title="Assigned To" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>

        </DataTable>

      </Section>


      {
        createModal &&
        <Modal id="modal-add-jobtypegroup"
          title="Create Group"
          buttonText="Add Group"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          onOpen={() => $("#txt-groupname").focus()}
          onSubmit={(body, callback) => {

            if (user?.isSuperAdmin) {
              if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
                window.showFormError("ownerUserId", "Please choose the assignee user!");
                if (callback) callback();
                return;
              }
            }

            if (window.isNullOrEmpty(body.name) || body.name.length < 2) {
              window.formError("name", "Please write a valid group name!", "modal-add-jobtypegroup", true);
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.JobTypeGroups, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Group has been added successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bolder">
                  Assign to
                  <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <TextBox id="txt-groupname" label="Group Name" name="name" labelUp block
              placeholder="E.g. Services, Keys, Cars, etc" />

          </div>
        </Modal>
      }


      {
        editModal && selectedItem &&
        <Modal id="modal-edit-jobtypegroup"
          title="Edit Group"
          buttonText="Update Group"
          staticBackdrop open visible
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onOpen={() => $("#txt-edit-groupname").focus()}
          onSubmit={(body, callback) => {

            if (user?.isSuperAdmin) {
              if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
                window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-edit-jobtypegroup");
                if (callback) callback();
                return;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid group name!", false, "modal-edit-jobtypegroup");
              if (callback) callback();
              return;
            }

            hub.Put(API_ROUTES.JobTypeGroups + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Group has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>

            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bolder">
                  Assign to
                  <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="ownerUserId" id="drp-edit-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} selectedId={selectedItem.ownerUserId} single block />
              </>
            }

            <TextBox id="txt-edit-groupname" label="Group Name" name="name" labelUp block value={selectedItem.name} />

          </div>
        </Modal>
      }


      {
        deleteModal && selectedItem &&
        <Modal id="modal-delete-jobtypegroup"
          title="Delete Group"
          buttonText="Delete Group"
          buttonColor="danger"
          open visible
          onClose={() => {
            deleteModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {


            // if (selectedItem.jobTypes > 0 && window.isNullOrEmpty(body.group)) {
            //   window.showFormError("group", "Please select a group!");
            //   if (callback) callback();
            //   return;
            // }

            hub.Post(API_ROUTES.JobTypeGroups + "delete/" + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Group has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              (selectedItem.jobTypes ?? 0) > 0 ?
                <div>
                  <div>
                    There {(selectedItem.jobTypes ?? 0) > 1 ? "are" : "is"} {selectedItem.jobTypes} Job Type{(selectedItem.jobTypes ?? 0) > 1 ? "s" : ""} assigned to this group!
                  </div>
                  <div className="fs-7 fw-bolder mt-5">
                    <FlexRow>
                      <span>Assign to</span>
                      <Icon icon="circle-info" className="text-muted ms-3 small" />
                      <span className="small text-muted ms-1">
                        Leave it blank to ungroup the job types!
                      </span>
                    </FlexRow>
                  </div>
                  <Tagify name="group" id="drp-groupId" placeholder="Select a Group" routeParams={groupsRouteParams}
                    apiUrl={API_ROUTES.TagifyJobTypeGroups} single block />
                </div>
                :
                <div>
                  Are you sure you want to delete this job type group?
                </div>
            }
          </div>
        </Modal>
      }




    </Layout>
  );
}

export default JobTypeGroupsList;
