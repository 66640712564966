import { getOwner, openUpdateEnvironment } from "../../../core/Global";
import Avatar from "../../base/Avatar";
import FlexRow from "../../base/FlexRow";
import Icon from "../../base/Icon";


export default function AsideEnviornment() {

    const owner = getOwner()


    return (
        <div className="px-5 py-5 cursor-pointer w-100"
            onClick={e => {
                e.preventDefault()
                openUpdateEnvironment()
            }}>
            <FlexRow>
                <Avatar width={26} avatarUrl={owner?.avatarUrl} />
                <div className="ms-3">
                    <div className="fs-7 text-gray-300 limited-text w-150px">
                        {owner?.fullName ?? "Select Environment"}
                    </div>
                    <div className="fs-8 text-gray-600">
                        {owner?.companyName}
                    </div>
                </div>

                <div className="text-gray-300 ms-auto">
                    <Icon icon="chevron-right" size={10} />
                </div>

            </FlexRow>
        </div>
    )
}