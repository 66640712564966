import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../api/Hub";
import Indicator from "../../../components/base/Indicator";
import { useHub } from "../../../core/Global";

type Props = {
    success?: boolean
}

function SignUpStripeRedirect(props: Props) {

    const hub = useHub()
    const { sessionId } = useParams();

    useEffect(() => {
        
        hub.Post("/auth/signup/step3confirm", {
            stripeSessionId: sessionId,
            success: props.success ?? false
        }, res => {
            if (res.success) window.location.href = "/join/" + res.data.token;
        });
        
    }, [])

    return (
        <div className="w-lg-600px p-10 mx-auto">
            <Indicator />
        </div>
    )
}

export default SignUpStripeRedirect;