import { useEffect, useState } from "react";
import Modal from "../../../../../components/base/Modal";
import Job from "../../models/Job";
import { useHub, usePreferences } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import { toastDefault, toastSuccess } from "../../../../../components/base/Toast";
import ToggleSwitch from "../../../../../components/form/ToggleSwitch";
import FlexRow from "../../../../../components/base/FlexRow";
import DropDown from "../../../../../components/form/DropDown";
import { AccountPreferencesJobSendSettings } from "../../../../../models/AccountPreferences";
import { copyToClipboard } from "../../../../../core/Helper";


interface IProps {
    job?: Job
    justCopy?: boolean
    onClose?: () => void
}

export default function JobSendModal(props: IProps) {

    const hub = useHub();
    const pref = usePreferences();

    const [companyName, companyNameSet] = useState<boolean>(false)
    const [sourceName, sourceNameSet] = useState<boolean>(false)
    const [jobId, jobIdSet] = useState<boolean>(false)
    const [clientName, clientNameSet] = useState<boolean>(false)
    const [clientPhone, clientPhoneSet] = useState<boolean>(false)
    const [directNumber, directNumberSet] = useState<boolean>(false)
    const [address, addressSet] = useState<boolean>(false)
    const [jobType, jobTypeSet] = useState<boolean>(false)
    const [jobDetails, jobDetailsSet] = useState<boolean>(false)
    const [appointment, appointmentSet] = useState<boolean>(false)
    const [confirmLink, confirmLinkSet] = useState<boolean>(false)

    const [note, noteSet] = useState<string>()
    const [text, textSet] = useState<string>()

    const [settings, settingsSet] = useState<any[]>();
    const [sendSettings, sendSettingsSet] = useState<AccountPreferencesJobSendSettings>();
    const [proxyLine, proxyLineSet] = useState<string>()

    useEffect(() => {
        if (props.job) {
            hub.Get(API_ROUTES.Jobs + "sendsettings/" + props.job.id, res => {
                if (res.success) {
                    settingsSet(res.data.settings)
                    sendSettingsSet(res.data.settings[0].settings)
                    proxyLineSet(res.data.proxyLine)
                }
            })
        }
    }, [props.job])

    useEffect(() => {
        if (sendSettings) {
            companyNameSet(sendSettings.companyName ?? false)
            sourceNameSet(sendSettings.sourceName ?? false)
            jobIdSet(sendSettings.jobId ?? false)
            clientNameSet(sendSettings.clientName ?? false)
            clientPhoneSet(sendSettings.clientPhone ?? false)
            directNumberSet(sendSettings.directNumber ?? false)
            addressSet(sendSettings.address ?? false)
            jobTypeSet(sendSettings.jobType ?? false)
            appointmentSet(sendSettings.appointment ?? false)
            jobDetailsSet(sendSettings.jobDetails ?? false)
            confirmLinkSet(sendSettings.confirmLink ?? false)
        }
    }, [sendSettings])


    useEffect(() => {
        var text = "";

        if (companyName) {
            text += `[${pref?.companyName}]`;
            text += "\n";
        }

        if (sourceName) {
            text += "Source: " + props.job?.jobSource;
            text += "\n";
        }

        if (jobId) {
            text += "ID: #" + props.job?.jobId;
            text += "\n";
        }

        if (clientName) {
            text += "Name: " + props.job?.fullName;
            text += "\n";
        }

        if (clientPhone && directNumber) {
            text += "Phone: " + props.job?.phone?.phoneFriendly;
            text += "\n";
        }

        if (clientPhone && !directNumber && proxyLine) {
            text += `Phone: ${proxyLine},${props.job?.sentPasscode}`;
            text += "\n";
        }

        if (address && props.job?.address) {
            text += "Address: " + props.job?.address?.address?.label;
            text += "\n";
        }

        if (jobType) {
            text += "Job: " + props.job?.jobType;
            text += "\n";
        }

        if (appointment) {
            text += "Appt: " + (props.job?.appointmentDateText ?? "-");
            text += "\n";
        }

        if (jobDetails && props.job?.jobDetails) {
            text += props.job?.jobDetails;
            text += "\n";
        }

        if (note && note.length > 0) {
            text += "\n";
            text += "note: " + note;
            text += "\n";
        }

        if (confirmLink) {
            text += "\n";
            text += "to accept: https://dsp.cx/XXXXX";
            text += "\n";
        }


        textSet(text);

    }, [companyName, sourceName, jobId, clientName, clientPhone, directNumber, address, jobType, jobDetails, appointment, confirmLink, note, pref])



    const [valid, validSet] = useState<string>();
    const [errors, errorsSet] = useState<string[]>();

    const [error, errorSet] = useState<boolean>()

    useEffect(() => {

        if (props.job) {

            var errs: string[] = [];

            if (!props.job.metroId) {
                errs.push("Set the Metro!");
            }
            if (!props.job.techId) {
                errs.push("Assign the job to Technician!");
            }

            if (errs.length > 0) {
                validSet("0")
                errorsSet(errs);
            }
            else {
                validSet("1");
            }


        }

    }, [props.job])

    return (
        <>
            {
                valid && valid == "1" &&
                <Modal id="modal-job-send"
                    title={props.justCopy ? "Copy Job" : "Send Job"} size="Large"
                    visible open
                    subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                    buttonText={props.justCopy ? "Copy Job" : "Send Job"}
                    buttonColor={props.justCopy ? "dark" : "primary"}
                    onSubmit={props.justCopy ? () => {
                        copyToClipboard(text, "Job has been copied to the clipboard!");
                        window.closeModal("modal-job-send")
                    } : (body, callback) => {

                        errorSet(false)

                        var s = 0;
                        if (companyName) s++
                        if (sourceName) s++
                        if (jobId) s++
                        if (clientName) s++
                        if (clientPhone) s++
                        if (address) s++
                        if (jobType) s++
                        if (appointment) s++
                        if (jobDetails) s++

                        if (s < 2) {
                            errorSet(true)
                            callback!()
                            return
                        }

                        hub.Post(API_ROUTES.Jobs + "send", {
                            id: props.job?.id,
                            companyName, sourceName, jobId, clientName, clientPhone, directNumber, address, jobType, appointment, jobDetails, confirmLink, note
                        }, res => {
                            if (res.success) {
                                toastSuccess("Job #" + props.job?.jobId + " has been send successfully!")
                            }
                            callback!(res)
                        });

                    }}
                    onClose={props.onClose}
                    secondActionColor="dark"
                    secondActionText="Copy Job"
                    onSecondAction={props.justCopy ? undefined : () => {
                        copyToClipboard(text, "Job has been copied to the clipboard!");
                    }}>

                    {
                        settings && settings.length > 1 &&
                        <div className="row mb-5">
                            <div className="col-md-8">
                                <DropDown id="drp-sendsettings" source={settings}
                                    label="Load Settings From" labelUp autoSelectFirstItem
                                    onSelectItem={e => {
                                        sendSettingsSet(e.settings)
                                    }} />
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-md-5">
                            <div className="mb-4 fw-bold fs-6">
                                Send to Technician
                            </div>
                            {
                                sendSettings?.companyName &&
                                <div className="mb-4">
                                    <ToggleSwitch label="Company Name" raw small checked={companyName} onChange={e => companyNameSet(e)} />
                                </div>
                            }
                            {
                                sendSettings?.sourceName &&
                                <div className="mb-4">
                                    <ToggleSwitch label="Source Name" raw small checked={sourceName} onChange={e => sourceNameSet(e)} />
                                </div>
                            }

                            <div className="mb-4">
                                <ToggleSwitch label="Job ID" raw small checked={jobId} onChange={e => jobIdSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Client Name" raw small checked={clientName} onChange={e => clientNameSet(e)} />
                            </div>
                            <FlexRow className="mb-4">
                                <ToggleSwitch label="Client Phone" raw small className="me-10" checked={clientPhone} onChange={e => clientPhoneSet(e)} />
                                {
                                    clientPhone &&
                                    <ToggleSwitch label="Direct Number" raw small checked={directNumber} onChange={e => directNumberSet(e)} />
                                }
                            </FlexRow>
                            <div className="mb-4">
                                <ToggleSwitch label="Address" raw small checked={address} onChange={e => addressSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Job Type" raw small checked={jobType} onChange={e => jobTypeSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Appointment" raw small checked={appointment} onChange={e => appointmentSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Job Details" raw small checked={jobDetails} onChange={e => jobDetailsSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Confirm Link" raw small checked={confirmLink} onChange={e => confirmLinkSet(e)} />
                            </div>
                            <div className="mb-4">
                                <textarea name="text" className="form-control form-control-sm"
                                    rows={4} value={note} placeholder="Note..." onChange={e => noteSet(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <FlexRow className="mb-4">
                                <span className="fw-bold fs-6 me-2">
                                    Text to Send
                                </span>
                                {
                                    error &&
                                    <span className="text-danger fs-8">
                                        Please select at least 2 items!
                                    </span>
                                }
                            </FlexRow>
                            <textarea name="text" className="form-control form-control-sm" rows={20} value={text} style={{ resize: "none" }}></textarea>
                        </div>
                    </div>

                </Modal>
            }

            {
                valid && valid == "0" &&
                <Modal id="modal-job-send-error"
                    title="Send Job"
                    subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                    visible open
                    onClose={props.onClose}
                    cancelButton cancelText="Okay">
                    <div>
                        <p>
                            Before send the job you must:
                        </p>
                        <div className="p-4 bg-light-warning">
                            {
                                errors?.map((err, k) =>
                                    <div key={k} className="fs-7 mb-2">- {err}</div>
                                )
                            }
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}