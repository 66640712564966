import { useEffect, useState } from "react";
import Job from "../models/Job";
import Modal from "../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import TextArea from "../../../../components/form/TextArea";
import JobStatus from "../../base/models/JobStatus";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";


interface IProps {
    job?: Job
    status?: JobStatus
    onClose?: () => void
    onCancel?: () => void
    onUpdate?: () => void
}
export default function JobReopenFromClosedModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();
    useEffect(() => selectedStatusSet(props.status), [props.status]);

    const [removePayment, removePaymentSet] = useState<boolean>(false)


    return (
        <>

            <Modal id="modal-reopen-job-from-closed"
                title="Reopen Job"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                buttonText="Reopen the Job"
                buttonColor="primary"
                visible open staticBackdrop
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    window.hideFormErrors();

                    if (body.note == null || body.note == "") {
                        window.showFormError("note", "Please write a note to reopen the job!", true, "modal-reopen-job-from-closed");
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "reopen", {
                        ...body,
                        id: job?.id,
                        status: selectedStatus?.id
                    }, res => {
                        if (props.onUpdate) props.onUpdate();
                        if (callback) callback(res);
                    });

                }}>
                <div>

                    {/* <div className="mb-5">
                            <label className="fs-7 mb-2">
                                Reopen job and update status to: 
                            </label>
                            <FlexRow>
                                <Icon icon="circle" type="solid" color={props.status?.color} />
                                <span className="ms-2 fs-7">
                                    {props.status?.name}
                                </span>
                            </FlexRow>
                        </div> */}

                    {
                        props.job?.isClosed &&
                        <div className="mb-5">
                            <ToggleSwitch label="Remove all payment information" name="removePayment"
                                raw small checked={removePayment} onChange={e => removePaymentSet(e)} />
                        </div>
                    }



                    <TextArea id="txt-reopen-jobnote-area" label="Note *" block labelUp name="note" rows={5} placeholder="Please write a note..." />

                </div>
            </Modal>

        </>
    )

}