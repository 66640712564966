import React, { useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { HubConnectionState } from "@microsoft/signalr";
import Environment from "../../env/env";
import Hub from "../../api/Hub";
import { demoText, getUser, isDemo, useHub, useSocket } from "../../core/Global";
import AgentMonitoringItem from "../../modules/dashboards/models/AgentMonitoringItem";
import LiveCall from "../../modules/voice/calls/models/LiveCall";
import { AgentExtensionItem } from "../../modules/agents/models/Agent";
import { API_ROUTES } from "../../core/Constants";
import Avatar from "../base/Avatar";
import AgentExtensionButton from "./AgentExtensionButton";
import Icon from "../base/Icon";
import { AgentStatuses, CallTypes, UserTypes } from "../../core/Enums";
import LiveCallActions from "../calls/LiveCallActions";
import Div from "../base/Div";
import Indicator from "../base/Indicator";
import Modal from "../base/Modal";
import FlexRow from "../base/FlexRow";
import CallAgent from "../../modules/voice/calls/models/CallAgent";



function AgentsLiveBoard() {

    const env = new Environment();
    const hub = useHub();
    const socket = useSocket();
    const user = getUser();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<AgentMonitoringItem[]>();
    const [onlineModel, onlineModelSet] = useState<AgentMonitoringItem[]>();
    const [selectedCall, selectedCallSet] = useState<LiveCall>();
    const [selectedAgent, selectedAgentSet] = useState<AgentMonitoringItem>();
    const [selectedExtension, selectedExtensionSet] = useState<AgentExtensionItem>();
    const [lineObj, lineObjSet] = useState<any>();
    const [searchParams] = useSearchParams();
    const [filteringValues, filteringValuesSet] = useState<any>();

    const fetch = async (callback?: () => void) => {

        hub.Get(API_ROUTES.DashboardAgentsMonitoring, res => {
            if (res?.data) {
                onlineModelSet(res.data);
                filteringValuesSet(res.filteringValues);
                if (callback) callback();
                fetchingSet(false);
                fetchedSet(true);
            }
        });
    }

    useEffect(() => {


        fetch();

        var socketKey = socket.start(
            skt => {
                skt.on("UpdateLiveBoards", data => {
                    if (user?.userType == UserTypes.Admin || user?.userType == UserTypes.Customer) onlineModelSet(data);
                    else fetch();
                });

            }, skt => {
                skt.off("UpdateLiveBoards");
            });


        return () => {
            socket.stop(socketKey);
        };



    }, [])

    const CallerID = (props: { agent?: CallAgent }) => (
        <span className="ms-2 d-flex align-items-center">
            {
                props.agent?.country ?
                    <Avatar width={15} avatarUrl={props.agent?.country.flagSquare} />
                    :
                    <Avatar width={15} avatarUrl={props.agent?.avatarUrl} />
            }

            {
                props.agent?.unknown ?
                    <span className="ms-1 fs-8">
                        {props.agent.number}
                    </span>
                    :
                    <span className="ms-1 fs-8">
                        {
                            props.agent?.name
                        }
                    </span>
            }

        </span>
    )

    const AgentCard = (props: { item: AgentMonitoringItem }) => (
        <div className={"card card-agentmap card-agentmap-" + props.item.status?.colorClass + "  mb-2"}>
            <div className="card-body p-2">
                <div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-0">
                                <Link to={"/agents/view/" + props.item?.id} className="text-gray-900 text-hover-primary fs-7 fw-bolder me-2">
                                    {isDemo() ? "Agent " + props.item?.firstName.substring(0, 1).toUpperCase() : props.item?.firstName + " " + props.item?.lastName.substring(0, 1).toUpperCase()}
                                </Link>
                                {
                                    props.item.status?.status == AgentStatuses.Away ?
                                        <div className="badge badge-warning ms-1 me-2 px-2 py-1 fs-9">
                                            <Icon icon="clock" size={9} className="me-1" />
                                            Away
                                        </div>
                                        : props.item.status?.status == AgentStatuses.Puased ?
                                            <div className="badge badge-warning ms-1 me-2 px-2 py-1 fs-9">
                                                <Icon icon="clock" size={9} className="me-1" />
                                                Paused
                                            </div>
                                            :
                                            <>

                                            </>
                                }

                            </div>
                            <FlexRow>
                                {
                                    props.item.companyName &&
                                    <>
                                        <Icon icon="briefcase" />
                                        <div className="fs-9 ms-1 me-2">
                                            {isDemo() ? `Company ${demoText(props.item.companyName)}` : props.item.companyName}
                                        </div>
                                    </>
                                }
                                {
                                    props.item.queueName &&
                                    <>
                                        <Icon icon="arrow-right-to-line" />
                                        <div className="fs-9 ms-1">
                                            {isDemo() ? `Queue ${demoText(props.item.queueName)}` : props.item.queueName}
                                        </div>
                                    </>
                                }
                            </FlexRow>
                        </div>
                        <div className="btn-group" role="group">
                            <button id={"btn-agent-" + props.item.id}
                                type="button" className="btn btn-link dropdown-toggle dsp-dropdown p-0"
                                onClick={e => {
                                    window.dropdown("btn-agent-" + props.item.id);
                                }}>
                                <Icon icon="ellipsis-vertical" className="dsp-dropdown px-2" color="#444" />
                            </button>
                            <div className="dropdown-menu dsp-dropdown">
                                <a className="dropdown-item dsp-dropdown text-danger fs-8" href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        selectedAgentSet(props.item);
                                        window.openModal("modal-logout-user");
                                    }}>
                                    <Icon icon="right-from-bracket" className="dsp-dropdown w-20px text-danger text-center" size={10} />
                                    Force Logout
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        {
                            props.item.extensions?.map((ext, key) =>
                                <div key={key} className="me-1">
                                    <AgentExtensionButton ext={ext} status={props.item.status}
                                        onSelect={() => {
                                            selectedExtensionSet(ext);
                                            selectedAgentSet(props.item);
                                        }} />
                                </div>
                            )
                        }
                        {/* <span key={key} className={"badge badge-success badge-sm d-flex px-2 py-1 me-1 fw-normal"}>
                                <span className="fs-7">{ext.extNumber}</span>
                                <Icon icon="phone-hangup" type="solid" className="mx-2" size={10} color="#fff" />
                                <Timer showHours width={45} totalSeconds={ext.activeTime} />
                            </span> */}
                    </div>
                    {/* <div>
                        {
                            props.item.busyExtensions?.map((ext, key) => 
                                <div className="mt-2">
                                    <div key={key} className="d-flex align-items-center">
                                        <AgentExtensionButton ext={ext} status={props.item.status} selectCall={call => selectedCallSet(call)}  />
                                        <div className="ms-2">
                                            <Icon icon={ext.currentCall?.callType == CallTypes.Incoming ? "phone-arrow-down-left" 
                                                : ext.currentCall?.callType == CallTypes.Outgoing ? "phone-arrow-up-right"
                                                : ext.currentCall?.callType == CallTypes.Local ? "user-headset"
                                                : ""} />
                                        </div>
                                        <CallerID agent={ext.currentCall?.sourceAgent?.id == props.item.id ? ext.currentCall.destinationAgent : ext.currentCall?.sourceAgent} />
                                        <div className="ms-auto">
                                            {
                                                ext.currentCall?.talking &&
                                                <LiveCallActions call={ext.currentCall} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div> */}
                </div>
            </div>

        </div>
    )


    return (
        <Div>


            <Div visible={fetching} className="mt-20">
                <Indicator />
            </Div>

            <Div visible={fetched && (onlineModel == null || onlineModel.length == 0)} className="p-10 text-center">
                <Icon icon="user-headset" size={46} type="solid" className="mb-5" />
                <br />
                There is No Active Agent!
            </Div>

            <Div visible={fetched}>

                <Div visible={onlineModel && onlineModel.length > 0}>
                    <div className="row mt-1">
                        {
                            onlineModel?.map((item, key) =>
                                <div key={key} className="col-12 col-sm-6 col-md-3 col-lg-2">
                                    <AgentCard item={item} />
                                </div>
                            )
                        }
                    </div>
                </Div>

            </Div>

            <Modal id="modal-logout-user"
                title="Logout User"
                buttonText="Force Logout"
                buttonColor="danger"
                onConfirm={(callback: any) => {
                    hub.Get("/auth/logout/" + selectedAgent?.id, res => {
                        if (res.data) {
                            if (callback) callback();
                        }
                    })
                }}>
                <p className="ps-1">Are you sure you want to force logout this user?</p>
                <div className="p-4 bg-secondary d-flex align-items-center">
                    <span>{selectedAgent?.firstName + " " + selectedAgent?.lastName}</span>
                    {
                        selectedAgent?.freeExtensions?.map((ext, key) =>
                            <span key={key} className="ms-4">
                                <Icon icon="phone-office" type="solid" size={9} />
                                <span className="ms-1 fs-8">{ext.extNumber}</span>
                            </span>
                        )
                    }
                </div>
            </Modal>

            <Modal id="modal-ext-remove"
                title={"Extension"}
                buttonText="Remove Extension"
                buttonColor="danger"
                onSubmit={(body, callback) => {

                    hub.Post(API_ROUTES.RevokeExtension, {
                        ext: selectedExtension?.extId,
                        userId: selectedAgent?.id
                    }, res => {
                        fetch();
                        if (callback) callback(res);
                    });

                }}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center">
                            <Icon icon="user-headset" size={14} />
                            <span className="fs-6 ms-2 fw-bold">{selectedAgent?.fullName}</span>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <Icon icon="phone-office" size={14} />
                            <span className="fs-6 ms-2 fw-bold">{selectedExtension?.extNumber}</span>
                        </div>
                    </div>
                </div>
            </Modal>

        </Div>
    )

}

export default AgentsLiveBoard;