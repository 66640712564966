import React from 'react';
import {Routes, Route} from "react-router-dom";
import ExtensionList from '../modules/voice/pbx/views/ExtensionList';
import ExtensionForm from '../modules/voice/pbx/views/ExtensionForm';
import QueueList from '../modules/voice/pbx/views/QueueList';
import QueueForm from '../modules/voice/pbx/views/QueueForm';
import CallsHistory from '../modules/voice/calls/views/CallsHistory';
import CallsLive from '../modules/voice/calls/views/CallsLive';
import CallDetails from '../modules/voice/calls/views/CallDetails';
import CallsReport from '../modules/voice/calls/views/CallsReport';
import LinesList from '../modules/voice/lines/views/LinesList';
import LinesReleased from '../modules/voice/lines/views/LinesReleased';
import LineInbound from '../modules/voice/lines/views/LineInbound';


function VoiceRoutes() {
  let k = 0;
  return (
    [
      <Route key={k++} path="/voice/pbx/extensions" element={<ExtensionList />}/>,
      <Route key={k++} path="/voice/pbx/extensions/edit/:selectedId" element={<ExtensionForm />}/>,
      <Route key={k++} path="/voice/pbx/extensions/draft/:selectedId" element={<ExtensionForm />}/>,
      <Route key={k++} path="/voice/pbx/queues" element={<QueueList />}/>,
      <Route key={k++} path="/voice/pbx/queues/edit/:selectedId" element={<QueueForm />}/>,
      
      <Route key={k++} path="/voice/calls/history" element={<CallsHistory />}/>,
      <Route key={k++} path="/voice/calls/live" element={<CallsLive />}/>,
      <Route key={k++} path="/voice/call/:selectedId" element={<CallDetails />}/>,
      <Route key={k++} path="/voice/report/calls" element={<CallsReport />}/>,

      <Route key={k++} path="/voice/lines" element={<LinesList />}/>,
      <Route key={k++} path="/voice/lines/released" element={<LinesReleased />}/>,
      <Route key={k++} path="/voice/lines/inbound/:selectedId" element={<LineInbound />}/>,
    ]
  );
}

export default VoiceRoutes;
