import { useEffect, useState } from "react";
import Job from "../../models/Job";
import Modal from "../../../../../components/base/Modal";
import { getCountry, getUSA, useEnvironment, useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import Map from "../../../../../components/here/Map";
import AddressField from "../../../../../components/here/AddressField";
import { HereGeocodeItem } from "../../../../../models/MapModels";
import TextBox from "../../../../../components/form/TextBox";
import Country from "../../../../base/models/Country";
import DropDown from "../../../../../components/form/DropDown";
import DateItem from "../../../../../models/DateItem";
import { toastError } from "../../../../../components/base/Toast";

interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobUpdateAddressModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();



    const [states, statesSet] = useState<DateItem[]>();
    const [country, countrySet] = useState<Country>();
    const [address, addressSet] = useState<HereGeocodeItem>();
    const [selectedState, selectedStateSet] = useState<string>();
    const [zipcode, zipcodeSet] = useState<string>();
    const [customAddress, customAddressSet] = useState<string>("");

    const [zoom, zoomSet] = useState<number>()

    useEffect(() => {
        var zipcode = "";
        var validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-']
        if (customAddress) {
            for (var i = 0; i < customAddress.length; i++) {
                var c = customAddress[i];
                if (validChars.indexOf(c) < 0) zipcode = "";
                else zipcode += c;

                if (zipcode.length > 4) break;
            }
        }

        if (zipcode.length > 4) {
            hub.LookUpZipcode(zipcode, country?.alpha3Code ?? "usa", res => {
                zipcodeSet(res?.address?.postalCode)
                selectedStateSet(res?.address?.stateId)
            })
        }
    }, [customAddress])

    useEffect(() => {
        console.log("country changed")
        if (country) {
            hub.Get("/base/states/?countryId=" + country.id, res => {
                if (res.data) statesSet(res.data);
            });
        }
    }, [country])

    useEffect(() => {
        if (props.job?.address) {
            var c = getCountry(props.job.address.address?.countryId);
            if (c) countrySet(c);
        }
        if (props.job) {
            addressSet(props.job.address)
            customAddressSet(props.job.address?.address?.label ?? "")
        }

    }, [props.job]);

    const [showMap, showMapSet] = useState<boolean>(false);

    const updateAddress = (anyway: boolean, callback: any) => {

        var valid = true;

        if (window.isNullOrEmpty(address?.id) && customAddress.length < 3) {
            window.showFormError("jobAddress", "Please write the job address!", false, "modal-update-address");
            valid = false;
        }


        if (!valid) {
            callback(true)
            return
        }

        if (window.isNullOrEmpty(address?.id) && !anyway) {
            window.openModal("modal-job-address-incomplete");
            callback(true)
            return
        }

        hub.Post(API_ROUTES.Jobs + "address", {
            id: props.job?.id,
            addressId: address?.id,
            customAddress: customAddress,
            zipCode: zipcode,
            stateId: selectedState,
            countryId: country?.id,
            zoom: zoom

        }, res => {
            if (res.success) {
                callback()
            }
            else {
                toastError(res.message)
            }
        });

    }

    return (
        <>

            <Modal id="modal-update-address"
                title="Address"
                subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                buttonText="Update Address"
                buttonColor="primary"
                size="Large"
                visible open noPadding
                onOpen={() => {
                    showMapSet(true);
                    $("#jobupdateaddress-address").focus();
                }}
                onClose={props.onClose}
                onConfirm={callback => {
                    updateAddress(false, callback)
                }}>

                <div className="mx-10 mt-5">
                    <AddressField id="jobupdateaddress-address" label="" name="jobAddress" labelUp
                        placeholder="Search address..." block verify
                        selectedAddress={address}
                        onTextChanged={e => {
                            customAddressSet(e)
                            window.hideFormError("jobAddress", "modal-update-address");
                        }}
                        onCountrySelect={c => countrySet(c)}
                        onSelect={e => addressSet(e)}
                        onUnselect={() => addressSet(undefined)} />
                </div>

                {
                    showMap &&
                    <Map id="job-updateaddress-map" height={400}
                        client={address} preventCenterizeOnLoad
                        onZoomChanged={z => zoomSet(z)}
                        onClientUpdated={e => addressSet(e)} />
                }

            </Modal>


            <Modal id="modal-job-address-incomplete"
                title="Incomplete Address"
                titleIcon="triangle-exclamation"
                buttonText="Submit Anyway"
                cancelText="Fix the Address"
                buttonColor="warning" secondModal
                onConfirm={callback => {

                    // if (window.isNullOrEmpty(selectedState)) {
                    //     window.formError("incompleted-states", "Please select the state!")
                    // }

                    updateAddress(true, () => {
                        window.closeModal("modal-job-address-incomplete")
                        window.closeModal("modal-update-address")
                    })
                }}>
                <div className="mb-5">
                    The job address appears to be incomplete. You can still submit the job, but please double-check to ensure accuracy and completeness.
                </div>

                <TextBox id="txt-incompleted-address" value={customAddress} label="Address" labelUp />

                <div className="row">
                    <div className="col-md-6">
                        <TextBox id="txt-incompleted-zipcode" value={zipcode}
                            label="Zip Code *" labelUp onTextChanges={zip => {
                                if (zip.length > 4) {
                                    hub.LookUpZipcode(zip, country?.alpha3Code ?? "usa", res => {
                                        zipcodeSet(zip);
                                        selectedStateSet(res?.address?.stateId);
                                    })
                                }
                                else {
                                    zipcodeSet(zip);
                                    selectedStateSet(undefined);
                                }

                            }} />
                    </div>
                    <div className="col-md-6">
                        <DropDown id="drp-incompleted-states"
                            name="incompleted-states"
                            forceSelectValue={selectedState}
                            source={states} parentModal="modal-job-address-incomplete"
                            onSelect={e => selectedStateSet(e)}
                            label="State *" labelUp />
                    </div>
                </div>

            </Modal>

        </>
    )

}