import React, { useEffect, useState } from "react";



type TimerProps = {
    totalSeconds?: number,
    showHours?: boolean,
    width?: number,
    stopped?: boolean,
    onFinish?: (e?: any) => void
}

function Countdown(props: TimerProps) {

    let timerInterval:NodeJS.Timeout;
    const [time, timeSet] = useState<number>(0);
    const [started, startedSet] = useState<boolean>(false);
    const [finished, finishedSet] = useState<boolean>(false);

    const showTimer = () => {
        var sec_num = time ?? 0;

        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));
        var result = "";

        if (props.showHours) result += (hours < 10 ? "0" + hours : hours) + ":";
        result += (minutes < 10 ? "0" + minutes : minutes) + ":";
        result += (seconds < 10 ? "0" + seconds : seconds);
        
        return result;
    }

    useEffect(() => {
        if(props.totalSeconds && props.totalSeconds > 0) {
            timeSet(props.totalSeconds);
            startedSet(true);
            finishedSet(false);
        }
    }, [props.totalSeconds]);

    useEffect(() => {
        if (started) {
            if (!timerInterval) {
                timerInterval = setInterval(() => {
                    timeSet(t => t - 1);
                }, 1000);
            }
        }
    }, [started]);
    
    useEffect(()=> {
        return () => {
            if (timerInterval) clearInterval(timerInterval);
        }
    }, []);

    useEffect(() => {
        if(time < 1 && started) {
            timeSet(0);
            startedSet(false);
            finishedSet(true);
            clearInterval(timerInterval);
        }
    }, [time, started]);

    useEffect(() => {
        if(finished) {
             if (props.onFinish) props.onFinish();
        }
    }, [finished]);

    // useEffect(() => {
    //     if(time && !interval) {
            
    //         intervalSet(true);
    //     }
    // }, [time, interval])

    return (
        <span style={{
            width: props.width ?? "auto",
            display:"inline-block"
        }}>
            {showTimer()}
        </span>
    )
}

export default Countdown;

