import React, { useEffect, useState } from "react";
import moment from "moment";


type TimerProps = {
    key?: any
    totalSeconds?: number
    baseDate?: Date
    showHours?: boolean
    short?: boolean
    width?: number
    minWidth?: number
    stopped?: boolean
    reset?: boolean
    onTick?: (t: number) => void
}

function Timer(props: TimerProps) {

    const [time, timeSet] = useState<number>(0);

    const showTimer = () => {
        var sec_num = time;

        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        var result = "";

        // if (props.showDays && hours > 23) {
        //     var d = Math.floor(hours / 24);
        //     result = d + " Day" + 
        // }

        if (props.short && hours > 0) {
            if (hours > 23) {
                var d = Math.floor(hours / 24);
                result = d + " Day" + (d > 1 ? "s" : "");
            }
            else {
                result = hours + "h";
            }
        }
        else {
            if (hours > 0 || props.showHours) result += (hours < 10 ? "0" + hours : hours) + ":";
            result += (minutes < 10 ? "0" + minutes : minutes) + ":";
            result += (seconds < 10 ? "0" + seconds : seconds);
        }


        return result;
    }

    useEffect(() => {

        if (props.totalSeconds) {

            timeSet(props.totalSeconds);

            const timerInterval = setInterval(() => {
                if (!props.stopped) timeSet(t => t + 1);
            }, 1000);

            return () => {
                clearInterval(timerInterval);
            }

        }
        else if (props.baseDate) {

            setTimeByDate();
            const dateInterval = setInterval(setTimeByDate, 1000);

            return () => {
                clearInterval(dateInterval);
            }
        }

    }, [props.totalSeconds, props.baseDate, props.key]);


    const setTimeByDate = () => {
        const now = moment.utc();
        const t = now.diff(props.baseDate, "seconds");
        timeSet(t);
    }


    useEffect(() => {
        if (props.onTick) props.onTick(time);
    }, [time])

    useEffect(() => {
        if (props.reset) timeSet(0);
    }, [props.reset])




    return (
        <span style={{
            width: props.width,
            minWidth: props.minWidth,
            display: "inline-block"
        }}>
            {showTimer()}
        </span>
    )
}

export default Timer;

