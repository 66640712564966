import React, { useEffect, useState } from "react";
import Modal from '../base/Modal';
import LiveCall from '../../modules/voice/calls/models/LiveCall';
import Environment from '../../env/env';
import Hub from '../../api/Hub';
import { useGlobal, useHub } from "../../core/Global";


function HangUp() {

    const env = new Environment();
    const hub = useHub();
    const [globalState] = useGlobal();

    const hangup = async (callback: any) => {
        if(globalState.hangupChannel) {
            hub.Post("/system/calls/hangup", globalState.hangupChannel, res => {
                if (env.isDevelopment) console.log(res);
                window.closeModal("modal-hangup");
                if (callback) callback();
            });    
        }
    }


    return (
        <Modal id="modal-hangup"
                title={"Hangup Call"}
                onConfirm={hangup}
                buttonText="Hangup"
                buttonColor="danger">
                    <p className="ps-1">Are you sure you want to hangup this call?</p>
        </Modal> 
    )
}

export default HangUp;