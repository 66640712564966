import { Link } from "react-router-dom";
import { hasRole, useUnreadChatsCount } from "../../../core/Global";
import Icon from "../../base/Icon";
import Menu from "../../../core/Menu.json";




export default function AsideModulesMenu() {

    const unreadChats = useUnreadChatsCount();

    return (
        <div className="aside-menu flex-column-fluid" id="kt_aside_menu">
            <div className="my-2 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
                data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px">
                <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold"
                    id="#kt_aside_menu" data-kt-menu="true">

                    {
                        hasRole("HasCRM") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/boards/jobs") ? " here" : "")}>
                            <Link to="/boards/jobs" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="list-check" type="light" />
                                </span>
                                <span className="menu-title">Board</span>
                            </Link>
                        </div>
                    }

                    {
                        hasRole("HasCRM") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/chat") ? " here" : "")}>
                            <Link to="/chat" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="message-lines" type="light" />
                                </span>
                                <span className="menu-title">Chat</span>
                            </Link>
                            {
                                unreadChats > 0 &&
                                <span className="aside-menu-badge">
                                    <span className="badge badge-primary badge-circle">
                                        {unreadChats}
                                    </span>
                                </span>
                            }

                        </div>
                    }

                    {
                        hasRole("ArchivedJobs") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/crm/jobs/all") ? " here" : "")}>
                            <Link to="/crm/jobs/all" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="box-archive" type="light" />
                                </span>
                                <span className="menu-title">Archived</span>
                            </Link>
                        </div>
                    }

                    {
                        hasRole("JobsReport") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/crm/report/jobs") ? " here" : "")}>
                            <Link to="/crm/report/jobs" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="chart-line" type="light" />
                                </span>
                                <span className="menu-title">Reports</span>
                            </Link>
                        </div>
                    }

                    {
                        hasRole("ManageAdmins") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/accounts/admins") ? " here" : "")}>
                            <Link to="/accounts/admins" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="user" type="light" />
                                </span>
                                <span className="menu-title">Accounts</span>
                            </Link>
                        </div>
                    }

                    {
                        hasRole("SystemSettings") &&
                        <div className={"menu-item py-2" + (window.location.pathname.toLocaleLowerCase().startsWith("/system/settings") ? " here" : "")}>
                            <Link to="/system/settings" className="menu-link menu-center">
                                <span className="menu-icon me-0">
                                    <Icon icon="cog" type="light" />
                                </span>
                                <span className="menu-title">Settings</span>
                            </Link>
                        </div>
                    }


                </div>
            </div>
        </div>
    )
}