import React, { useEffect } from "react";
import Layout from '../../../components/layouts/Layout';
import { DataTable, DataTableField } from '../../../components/data/DataTable';
import { API_ROUTES } from '../../../core/Constants';
import { UserValidator } from '../validators/UserValidator';
import { setPage, useGlobal } from '../../../core/Global';


function CompanyList() {

  const [globalState, setGlobalState] = useGlobal();

  useEffect(() => {
    setPage("Companyies", "Accounts");
  }, [])

  const CompanyField = (data: any) => (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <a>
          <div className="symbol-label">
            <img src={data.logoUrl} className="w-100" />
          </div>
        </a>
      </div>
      <div className="d-flex flex-column">
        <a className="text-gray-800 text-hover-primary mb-1 fw-bold">
          {data.name}
        </a>
        <span className="small">{data.email}</span>
      </div>
    </div>
  )

  const CustomerField = (data: any) => (
    <div>
      {
        data.users && data.users.map((user: any, k: any) =>
          <div key={k} className="d-flex align-items-center mb-1">
            <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
              <a>
                <div className="symbol-label">
                  <img src={user.avatarUrl} className="w-100" />
                </div>
              </a>
            </div>
            <div className="d-flex flex-column">
              <span className="small">{user.fullName}</span>
            </div>
          </div>
        )
      }
    </div>
  )
  return (
    <Layout>
      <DataTable objectName="Company"
        apiRoute={API_ROUTES.Companies}
        route="/accounts/companies"
        edit largeForm>
        <DataTableField title="Company Name">
          <CompanyField />
        </DataTableField>
        <DataTableField title="Customer">
          <CustomerField />
        </DataTableField>
      </DataTable>
    </Layout>
  );
}

export default CompanyList;
