import { useEffect, useState } from "react"
import DataItem from "../../../../models/DataItem"
import { Link, useNavigate, useParams } from "react-router-dom"
import Icon from "../../../../components/base/Icon"

interface IProps {

}
export default function SystemSettingsMenu(props: IProps) {

    const { selectedPage } = useParams()
    const navigate = useNavigate()

    const [Menu, MenuSet] = useState<DataItem[]>([])


    useEffect(() => {
        var menu = Menu;

        menu.push({
            id: "subscription",
            name: "Subscription",
            icon: "badge-check"
        })

        menu.push({
            id: "payment-methods",
            name: "Payment Methods",
            icon: "credit-card"
        })


        menu.push({
            id: "company",
            name: "Company Settings",
            icon: "briefcase"
        })


        menu.push({
            id: "send-job",
            name: "Send Job Settings",
            icon: "send"
        })

        menu.push({
            id: "jobs",
            name: "Job Settings",
            icon: "tasks"
        })

        menu.push({
            id: "chats",
            name: "Chat Forward Rules",
            icon: "comment-lines"
        })

        menu.push({
            id: "others",
            name: "Other Settings",
            icon: "cog"
        })

        MenuSet(m => [...menu])

    }, [])

    useEffect(() => {
        if (window.isNullOrEmpty(selectedPage) && Menu.length > 0) {
            navigate(`/system/settings/${Menu[0].id}`)
        }
    }, [Menu, selectedPage])

    return (
        <div className="card card-flush">
            <div className="card-body">
                <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">

                    {
                        Menu.map((m, k) =>
                            <div key={k} className="menu-item mb-3">
                                <Link to={`/system/settings/${m.id}`} className={"menu-link" + (selectedPage == m.id ? " active" : "")}>
                                    <span className="menu-icon">
                                        <span className="svg-icon svg-icon-2 me-3">
                                            <Icon icon={m.icon} type="solid" className={(selectedPage == m.id ? "" : " text-gray-700")} />
                                        </span>
                                    </span>
                                    <span className={"menu-title fw-bold fs-6" + (selectedPage == m.id ? "" : " text-gray-700")}>
                                        {m.name}
                                    </span>
                                </Link>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}