import { useEffect, useState } from "react";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Section from "../../../components/base/Section";
import Layout from "../../../components/layouts/Layout";
import { getUser, hasRole, setPage, useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import { Link, useParams } from "react-router-dom";
import User from "../models/User";
import AccountOverview from "./AccountOverview";
import AccountTechSettings from "../components/AccountTechSettings";
import Icon from "../../../components/base/Icon";
import AccountEditForm from "../../../components/account/AccountEditForm";
import { UserTypes } from "../../../core/Enums";
import AccountTimezone from "../components/AccountTimezone";
import AccountTechRates from "../components/AccountTechRates";
import AccountPBXServer from "../components/AccountPBXServer";
import RolesForm from "./RolesForm";
import AccountRoles from "../components/AccountRoles";
import AccountTechAddressMetro from "../components/AccountTechAddressMetro";
import AccountSecurity from "../components/AccountSecurity";
import AccountTechSendJobSettings from "../components/AccountTechSendJobSettings";
import AccountsIPRestrict from "../components/AccountsIPRestrict";


interface IPageLink {
    name: string
    icon: any
    slug: string
    default?: boolean
}

export default function AccountDetails() {

    let sidebar: HTMLDivElement | null;

    const hub = useHub();
    const user = getUser();
    const { selectedId, selectedPage, selectedAction } = useParams();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<User>();
    const [pages, pagesSet] = useState<IPageLink[]>([]);

    const fetchUser = () => {

        fetchingSet(true);
        fetchedSet(false);

        hub.Get(API_ROUTES.Users + "manage/" + selectedId, res => {
            if (res.success) {
                modelSet(res.data);
                fetchingSet(false);
                fetchedSet(true);
                bindLinks(res.data.userType);
                var ut = "";
                if (res.data.userType == UserTypes.Admin) ut = "Admins";
                else if (res.data.userType == UserTypes.Agent) ut = "Agents";
                else if (res.data.userType == UserTypes.Technician) ut = "Technicians";
                else if (res.data.userType == UserTypes.Customer) ut = "Customers";
                setPage(res.data.fullName, "Users", ut);
            }

        })
    }

    const bindLinks = (userType: UserTypes) => {
        var arr: IPageLink[] = [];

        arr.push({
            name: "Overview",
            icon: "chart-line",
            slug: "overview",
            default: true
        });
        arr.push({
            name: "Edit Account",
            icon: "user-pen",
            slug: "edit"
        });

        if (userType == UserTypes.Technician) {
            arr.push({
                name: "Address & Metro",
                icon: "map",
                slug: "address-metro"
            })
            arr.push({
                name: "Technician Settings",
                icon: "user-helmet-safety",
                slug: "techdetails"
            })
            arr.push({
                name: "Send Job Settings",
                icon: "cog",
                slug: "sendjobsettings"
            })
        }

        arr.push({
            name: "Login & Security",
            icon: "key",
            slug: "security"
        })



        if (hasRole("UsersRoles")) {
            arr.push({
                name: "Groups & Roles",
                icon: "lock",
                slug: "roles"
            })
        }
        arr.push({
            name: "Notifications",
            icon: "bell",
            slug: "notifications"
        })
        arr.push({
            name: "Timezone",
            icon: "clock-two-thirty",
            slug: "timezone"
        })

        if (userType == UserTypes.Customer && user?.isSuperAdmin) {
            arr.push({
                name: "PBX Server",
                icon: "server",
                slug: "servers"
            })
        }


        pagesSet(p => [...arr]);

    }

    useEffect(() => {
        fetchUser();
    }, [selectedId])


    const [boxHeight, boxHeightSet] = useState<number>();
    const [sidebarTop, sidebarTopSet] = useState<number>(0);
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400;
        if (w < 500) {
            h = 250;
        }
        else {
            h = h - ($("#kt_header").outerHeight() ?? 50);
            h = h - ($("#kt_toolbar").outerHeight() ?? 50);
            //h = h - ($("#kt_chat_contacts_header").outerHeight() ?? 50);

            h = h - 40;
        }

        boxHeightSet(h);
        sidebarTopSet(sidebar?.offsetTop ?? 100);
    }

    useEffect(() => {
        fixSizes();
    }, [fetched])

    return (
        <Layout wide>
            <Section fluid>

                <Div visible={fetching} className="mt-20">
                    <Indicator />
                </Div>

                <Div visible={fetched}>

                    <div className="row">
                        <div className="col col-12 col-md-3 col-xxxl-2">
                            <div ref={e => sidebar = e} className="card card-flush mb-0 scroll-y p-0 m-0 stickycard"
                                style={{
                                    height: boxHeight,
                                    top: sidebarTop
                                }}>
                                <div className="card-body p-0 m-0 pt-5">
                                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">

                                        {
                                            pages.map((p, k) =>
                                                <div key={k} className="menu-item mb-1 mb-xxl-3">
                                                    <Link to={`/accounts/manage/${selectedId}/${p.slug}`} className={"menu-link" + (((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? " active" : "")}>
                                                        <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2 me-3">
                                                                <Icon icon={p.icon} type="solid" className={(((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? "" : " text-gray-700")} />
                                                            </span>
                                                        </span>
                                                        <span className={"menu-title fw-bold fs-7 fs-xxl-6" + (((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? "" : " text-gray-700")}>{p.name}</span>
                                                        {/* <span className="badge badge-light-success">3</span> */}
                                                    </Link>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-9 col-xxxl-10">
                            {
                                (window.isNullOrEmpty(selectedPage) || selectedPage == "overview") && <AccountOverview userId={selectedId} model={model} />
                            }

                            {
                                selectedPage == "edit" && <AccountEditForm userId={selectedId} model={model} onUpdate={u => modelSet(u)} />
                            }

                            {
                                selectedPage == "security" && <AccountSecurity userId={selectedId} model={model} />
                            }

                            {
                                hasRole("UsersRoles") && selectedPage == "roles" && <AccountRoles userId={selectedId} />
                            }



                            {
                                selectedPage == "address-metro" && <AccountTechAddressMetro userId={selectedId} model={model} onUpdate={u => modelSet(u)} />
                            }

                            {
                                selectedPage == "techdetails" && <AccountTechSettings userId={selectedId} model={model} onUpdate={u => modelSet(u)} />
                            }

                            {
                                selectedPage == "sendjobsettings" && selectedId && <AccountTechSendJobSettings userId={selectedId} />
                            }

                            {
                                selectedPage == "timezone" && <AccountTimezone userId={selectedId} model={model} />
                            }

                            {
                                selectedPage == "servers" && user?.isSuperAdmin && model && <AccountPBXServer user={model} />
                            }
                        </div>
                    </div>


                    {/* <AccountHeader model={model} userId={selectedId} selectedPage={selectedPage?.toLowerCase() ?? "overview"} /> */}



                </Div>

            </Section>
        </Layout>
    )
}