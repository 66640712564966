import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import FilteringProps from "../filtering/extensions.json";
import SortingProps from "../sorting/extensions.json";
import { DataTable, DataTableCustomActions, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useGlobal, useHub, validateRole } from '../../../../core/Global';
import Extension from "../models/Extension";
import Icon from "../../../../components/base/Icon";
import { UserTypes } from "../../../../core/Enums";
import Modal from "../../../../components/base/Modal";
import FlexRow from "../../../../components/base/FlexRow";
import { toastSuccess } from "../../../../components/base/Toast";
import Tagify from "../../../../components/tagify/Tagify";
import { TagifyDataItem } from "../../../../models/TagifyOptions";
import Section from "../../../../components/base/Section";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";
import ModalLimited from "../../../../components/modals/ModalLimited";
import TextBox from "../../../../components/form/TextBox";
import DropDown from "../../../../components/form/DropDown";
import { useNavigate } from "react-router-dom";

function ExtensionList() {

  const hub = useHub();
  const [globalState, setGlobalState] = useGlobal();
  const user = getUser();
  const navigate = useNavigate();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [outboundModal, outboundModalSet] = useState<boolean>(false);
  const [defaultOutbound, defaultOutboundSet] = useState<string>();

  const [selectedItem, selectedItemSet] = useState<Extension>();
  const [selectedItems, selectedItemsSet] = useState<Extension[]>([]);

  const [refresh, refreshSet] = useState<boolean>();


  useEffect(() => {
    if (selectedItems.length > 0 && outboundModal) {
      var cid = selectedItems[0].outboundPhoneLineId;
      var mul = false;
      selectedItems.forEach(e => {
        if (e.outboundPhoneLineId != cid) mul = true;
      });

      if (!mul) defaultOutboundSet(cid);
    }
  }, [selectedItems, outboundModal])


  useEffect(() => {

    validateRole("ExtensionsList");

    setPage("Extensions", "Voice", "PBX Modules");

  }, [])


  const ExtensionNumberItem = (data: any) => (
    <div className='d-flex align-items-center cursor-pointer'
      onClick={e => {
        selectedItemSet(data);
        window.openModal("modal-view-extension");
      }}>
      <Icon icon="phone-office" className="me-2" size={14} />
      <div className="fs-6 fw-bold">
        {data.extensionNumber}
      </div>
    </div>
  )

  const ExtensionName = (data: any) => (
    <div className='d-flex align-items-center'>
      {
        data.displayName && data.displayName != "" ?
          <div>
            {data.displayName}
          </div>
          :
          <div className="text-muted">
            [No Name]
          </div>
      }
      {
        hasRole("ExtensionsManage") &&
        <a href="#" className="ms-1"
          onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }
            selectedItemSet(data);
            editModalSet(true);
          }}>
          <Icon icon="edit" className="ms-2" size={12} />
        </a>
      }

    </div>
  )

  const CompanyItem = (data: Extension) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.company?.name}
      </div>
      {
        data.ownerUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            {data.ownerUser?.fullName}
          </span>
        </div>
      }

    </div>
  )

  const OutboundItem = (data: any) => (
    <FlexRow>
      {
        hasRole("ExtensionsManage") ?
          <a href="#" onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }
            selectedItemsSet([data])
            outboundModalSet(true)
          }}>
            {
              data.outboundCallerID && data.outboundCallerID != "" ?
                <span>
                  {data.outboundCallerID}
                </span>
                :
                <span className="text-muted">
                  [Set Outbound]
                </span>
            }
          </a>
          :
          <>
            {
              data.outboundCallerID && data.outboundCallerID != "" ?
                <span>
                  {data.outboundCallerID}
                </span>
                :
                <span className="text-muted">
                  [Set Outbound]
                </span>
            }
          </>
      }


    </FlexRow>
  )

  const ServerItem = (data: Extension) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.server?.name}
      </div>
      <div className='d-flex align-items-center fs-8 mt-1'>
        <Icon icon="server" className="me-1" size={12} />
        <span>
          {data.server?.description}
        </span>
      </div>
    </div>
  )

  const InQueuesItem = (data: Extension) => (
    <FlexRow maxWidth={500} wrap>
      {
        data?.queues && data.queues.length > 0 ?
          <>
            {
              data.queues.map((q, k) =>
                <span key={k} className={"badge badge-secondary me-1 mb-1 fs-8 qu-" + data.id}>
                  <Icon icon="queue" className="me-2" />
                  {q.name}
                </span>
              )
            }
          </>
          :
          <span className="text-muted">
            [No Queues]
          </span>
      }
    </FlexRow>
  )

  const CreatedItem = (data: Extension) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 300);
    }
  }, [refresh])


  const ViewExtAction = (data: Extension) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3" onClick={e => {
        e.preventDefault();
        if (user?.limited) {
          window.openModal("modal-user-is-limited");
          return;
        }
        selectedItemSet(data);
        window.openModal("modal-view-extension");
      }}>
        <Icon icon="phone-office" className="me-2" />
        View Details
      </a>
    </div>
  )

  const RenameAction = (data: Extension) => (
    <>
      {
        hasRole("ExtensionsManage") &&
        <div className="menu-item">
          <a href="#" className="menu-link px-3" onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }
            selectedItemSet(data);
            editModalSet(true);
          }}>
            <Icon icon="edit" className="me-2" />
            Rename
          </a>
        </div>
      }
    </>
  )

  const OutboundAction = (data: Extension) => (
    <>
      {
        hasRole("ExtensionsManage") &&
        <div className="menu-item">
          <a href="#" className="menu-link px-3" onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }
            selectedItemsSet([data])
            outboundModalSet(true)
          }}>
            <Icon icon="phone-arrow-up-right" className="me-2" />
            Outbound Settings
          </a>
        </div>
      }
    </>
  )

  const ExtensionAdvanced = (data: Extension) => (
    <>
      {
        hasRole("ExtensionsAdvanced") &&
        <div className="menu-item">
          <a href="#" className="menu-link px-3" onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }

            navigate("/voice/pbx/extensions/edit/" + data.id)

          }}>
            <Icon icon="cog" className="me-2" />
            Advanced Settings
          </a>
        </div>
      }
    </>
  )




  return (
    <Layout wide>

      <Section fluid>
        <DataTable objectName="Extension"
          filteringProps={FilteringProps}
          sortingProps={SortingProps}
          route="/voice/pbx/extensions"
          apiRoute={API_ROUTES.Extensions}
          preventCreate={!hasRole("ExtensionsManage")}
          addCustom={() => {
            if (user?.limited) window.openLimitedModal();
            else createModalSet(true);
          }}
          refresh={refresh}
          delete={hasRole("ExtensionsDelete")}
          deleteCustom={data => {
            selectedItemsSet([data]);
            deleteModalSet(true);
          }}
          deleteMultiple={data => {
            selectedItemsSet(data);
            deleteModalSet(true);
          }}
          groupActions={[
            {
              title: "Set Outbound",
              icon: "phone-arrow-up-right",
              action(data) {
                selectedItemsSet(data)
                outboundModalSet(true)
              }
            }
          ]}
          largeForm>
          {/* <DataTableField title="" width={50} hidden={!hasRole("ExtensionsManage")}>
              <DataTableActiveToggle route={API_ROUTES.Extensions}
                callback={() => {
                  refreshSet(true);
                }} />
            </DataTableField> */}
          <DataTableField title="Extension">
            <ExtensionNumberItem />
          </DataTableField>
          <DataTableField title="Display Name">
            <ExtensionName />
          </DataTableField>
          <DataTableField title="In Queues">
            <InQueuesItem />
          </DataTableField>
          <DataTableField title="Outbound" help="You must define an outbound phone number to be able to make outgoing calls by the extension!">
            <OutboundItem />
          </DataTableField>
          <DataTableField title='Company' hidden={!user?.isSuperAdmin}>
            <CompanyItem />
          </DataTableField>
          <DataTableField title="Server" hidden={!user?.isSuperAdmin}>
            <ServerItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
          <DataTableCustomActions>
            <ViewExtAction />
          </DataTableCustomActions>
          <DataTableCustomActions>
            <RenameAction />
          </DataTableCustomActions>
          <DataTableCustomActions>
            <OutboundAction />
          </DataTableCustomActions>
          <DataTableCustomActions>
            <ExtensionAdvanced />
          </DataTableCustomActions>

        </DataTable>
      </Section>



      <Modal id="modal-view-extension"
        visible={!user?.limited}
        title="Extension Details">
        <div>
          <FlexRow className="bg-secondary p-3 mb-2">
            <div className="fw-bolder fs-5 me-2">
              Host:
            </div>
            <div className="fs-6">
              {selectedItem?.server?.description}
            </div>
          </FlexRow>
          <FlexRow className="bg-secondary p-3 mb-2">
            <div className="fw-bolder fs-5 me-2">
              Port:
            </div>
            <div className="fs-6">
              5060
            </div>
          </FlexRow>
          <FlexRow className="bg-secondary p-3 mb-2">
            <div className="fw-bolder fs-5 me-2">
              Extension Number:
            </div>
            <div className="fs-6">
              {selectedItem?.extensionNumber}
            </div>
          </FlexRow>
          <FlexRow className="bg-secondary p-3 mb-2">
            <div className="fw-bolder fs-5 me-2">
              Secret:
            </div>
            <div className="fs-6">
              {selectedItem?.secretPassword}
            </div>
          </FlexRow>
        </div>
      </Modal>


      {
        createModal &&
        <Modal id="modal-add-extension"
          title="Create Extension"
          buttonText="Add Extension"
          staticBackdrop open visible
          onOpen={() => $("#txt-ext-name").focus()}
          onClose={() => createModalSet(false)}
          onSubmit={(body, callback) => {

            if (globalState.user?.isSuperAdmin) {
              if (!body.companyId || body.companyId == "") {
                window.showFormError("company", "Please choose a company!");
                if (callback) callback();
                return;
              }
            }

            if (!body.extName || body.extName == "" || body.extName.length < 2) {
              window.showFormError("extName", "Please write a display name!");
              if (callback) callback();
              return;
            }
            hub.Post(API_ROUTES.Extensions, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Extension has been added successfully!");
              }
              if (callback) callback(res);
            })
          }}>
          <div>
            {
              (globalState.user?.isSuperAdmin) &&
              <>
                <div className="fs-7 fw-bolder">
                  Company:
                  <span id="company-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="companyId" id="pickCompany" placeholder="Select a Company" apiUrl={API_ROUTES.TagifyCompaneis} single block />
              </>
            }

            <TextBox id="txt-ext-name" label="Display Name" name="extName" block labelUp placeholder="E.g. Sales, Support, Marketing, etc" />
          </div>
        </Modal>
      }

      {
        editModal && selectedItem &&
        <Modal id="modal-rename-extension"
          title="Extension Name"
          open visible staticBackdrop
          buttonText="Update Name"
          onOpen={() => $("#txt-rename-ext").focus()}
          onClose={() => editModalSet(false)}
          onSubmit={(body, callback) => {
            hub.Post("/system/extensions/rename", {
              ...body,
              id: selectedItem.id
            }, res => {
              if (res.success) {
                refreshSet(true);
              }
              if (callback) callback(res);
            })
          }}>
          <div>
            <TextBox id="txt-rename-ext" label="Display Name" name="displayName" block labelUp value={selectedItem.displayName} />
          </div>
        </Modal>
      }

      {
        deleteModal && selectedItems.length > 0 &&
        <Modal id="modal-delete-extension"
          title={"Delete Extension"}
          open visible staticBackdrop
          buttonText="Delete Extension"
          buttonColor="danger"
          onClose={() => {
            deleteModalSet(false)
            selectedItemSet(undefined)
          }}
          onConfirm={callback => {
            var ids: string[] = [];
            selectedItems.forEach(e => ids.push(e.id!));
            hub.Post(API_ROUTES.Extensions + "delete", ids, res => {
              if (res.success) {
                toastSuccess("Selected Extension(s) has been deleted successfully!");
              }
              refreshSet(true)
              callback()
            })
          }}>
          <div className="py-5">
            Are you sure you want to delete the selected extension{selectedItems.length > 1 ? "s" : ""}?
          </div>
          <FlexRow className="mb-10">
            {
              selectedItems.map((ex, key) =>
                <span key={key} className="badge badge-light-danger me-2">
                  <Icon icon="phone-office" className="me-2" size={12} />
                  <span className="fs-7 fw-bold">
                    {ex.extensionNumber}
                  </span>
                </span>
              )
            }
          </FlexRow>
        </Modal>
      }

      {
        outboundModal && selectedItems.length > 0 &&
        <Modal id="modal-set-outbound"
          title={"Set Outbound"}
          open visible staticBackdrop
          buttonText="Set Outbound Line"
          onClose={() => {
            outboundModalSet(false)
            selectedItemSet(undefined)
            defaultOutboundSet(undefined)
          }}
          onSubmit={(body, callback) => {
            var ids = "";
            selectedItems.forEach(e => {
              if (ids != "") ids += ","
              ids += e.id
            });
            hub.Post("/system/extensions/outbound", {
              ...body,
              ids
            }, res => {
              if (res.success) {
                refreshSet(true);
              }
              if (callback) callback(res);
            })
          }}>
          <div>
            <div className="py-5">
              Set outbound for the following extension{selectedItems.length > 1 ? "s" : ""}:
            </div>

            <FlexRow className="mb-10">
              {
                selectedItems.map((ex, key) =>
                  <span key={key} className="badge badge-light-primary me-2">
                    <Icon icon="phone-office" className="me-2" size={12} />
                    <span className="fs-7 fw-bold">
                      {ex.extensionNumber}
                    </span>
                  </span>
                )
              }
            </FlexRow>

            <DropDown id="drp-outbound-numbers" name="cid" apiSource={API_ROUTES.TagifyPhoneNumbers} parentModal="modal-set-outbound"
              dataText="title" dataValue="value" label="Outbound Line" labelUp defaultValue={defaultOutbound} />
          </div>
        </Modal>
      }




    </Layout>
  );

}

export default ExtensionList;
