import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../api/Hub";
import Container from "../../../components/base/Container";
import Div from "../../../components/base/Div";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import Indicator from "../../../components/base/Indicator";
import Modal from "../../../components/base/Modal";
import { toastError } from "../../../components/base/Toast";
import AvatarUploade from "../../../components/form/AvatarUploade";
import Form from "../../../components/form/Form";
import TextBox from "../../../components/form/TextBox";
import { PhoneNumberTypes } from "../../../core/Enums";
import { getUSA, setOwnerUserId, setSignupStep, setUserId, setUserToken, useAllCountries, useCountries, useHub, useOwnerUserId, usePrices } from "../../../core/Global";
import HUBResponse from "../../../models/HUBResponse";
import PaymentMethod from "../../system/settings/models/PaymentMethod";
import SignupRequest from "../models/SignupRequest";
import DataItem from "../../../models/DataItem";
import { API_ROUTES } from "../../../core/Constants";
import { TagifyDataItem } from "../../../models/TagifyOptions";
import DropDown from "../../../components/form/DropDown";
import PhoneInput from "../../../components/form/PhoneInput";
import Country from "../../base/models/Country";
import TwilioLine from "../../voice/lines/models/TwilioLine";

function SignUpComplete() {

    const { token } = useParams();
    const hub = useHub()
    const ownerUserId = useOwnerUserId()
    const countries = useAllCountries();

    const prices = usePrices();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [req, reqSet] = useState<SignupRequest>();
    const [step, stepSet] = useState<number>(0);
    const [working, workingSet] = useState<boolean>(false);
    const [loading, loadingSet] = useState<boolean>(true);

    const [usernameVerifing, usernameVerifingSet] = useState<boolean>(false);
    const [usernameVerified, usernameVerifiedSet] = useState<boolean>(false);


    const [states, statesSet] = useState<any[]>([]);
    const [companyPhone, companyPhoneSet] = useState<string>();

    const [paymentMethods, paymentMethodsSet] = useState<PaymentMethod[]>([]);
    const [selectedItem, selectedItemSet] = useState<PaymentMethod>();
    const [fetchingPaymentMethods, fetchingPaymentMethodsSet] = useState<boolean>(true);

    const [monthlyBudget, monthlyBudgetSet] = useState<number>(25);
    const [monthlyBudgetVerified, monthlyBudgetVerifiedSet] = useState<boolean>(false);
    const [extraSeats, extraSeatsSet] = useState<number>(0);
    const [extraSeatsItems] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    const [subTotalAmount, subTotalAmountSet] = useState<number>(0);
    const [totalAmount, totalAmountSet] = useState<number>(0);


    const [selectedCountry, selectedCountrySet] = useState<Country>();
    const [areaCodes, areaCodesSet] = useState<any[]>();

    const fetch = () => {
        hub.Get("/auth/signup/request/" + token, res => {
            if (res.success && res.data) {
                reqSet(res.data);
                loadingSet(false);
                stepSet(res.data.step);
            }
            else {
                //console.log("errrrrrrrrrrrrrrrrrr", res);
                document.location.href = "/auth/login";
            }
        });
    }

    const usernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        usernameVerifiedSet(false);
        usernameVerifingSet(false);
        window.hideFormErrors();
        if (e.target.value.length < 4) return;
        usernameVerifingSet(true);
        hub.Post("/auth/signup/username", {
            username: e.target.value
        }, res => {
            usernameVerifingSet(false);
            if (res.success) {
                usernameVerifiedSet(true);
            }
            else {
                window.showFormError(res.field, res.message, true);
            }
        });

    }

    const submitSignInOptions = (body: any, callback?: (res?: HUBResponse<any>) => void) => {

        window.hideFormErrors();
        usernameVerifingSet(false);
        usernameVerifiedSet(false);

        if (window.isNullOrEmpty(body.username)) {
            window.showFormError("username", "username is required!");
            return;
        }

        workingSet(true);

        hub.Post("/auth/signup/signinoptions", {
            ...body,
            token: req?.token
        }, res => {
            if (res.success) {
                stepSet(3);
                reqSet(res.data);
            }
            else {
                window.showFormError(res.field, res.message);
            }

            workingSet(false);
        });

    }


    const fetchStates = (countryId: string) => {
        hub.Get("/base/states/?countryId=" + countryId, res => {
            if (res.data) statesSet(res.data);
        });
    }

    useEffect(() => {
        if (selectedCountry?.id) {
            fetchStates(selectedCountry.id);
        }
    }, [selectedCountry])

    const submitCompany = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormErrors();
        var valid = true;
        if (window.isNullOrEmpty(body.companyName) || body.companyName.trim().length < 2) {
            window.showFormError("companyName", "Please write your business name!");
            valid = false;
        }

        if (window.isNullOrEmpty(body.companyState)) {
            window.showFormError("companyState", "Please choose the state!");
            valid = false;
        }

        if (window.isNullOrEmpty(body.companyCity) || body.companyCity.trim().length < 2) {
            window.showFormError("companyCity", "Please write a valid city name!");
            valid = false;
        }

        if (window.isNullOrEmpty(body.companyAddressLine1) || body.companyAddressLine1.trim().length < 5) {
            window.showFormError("companyAddressLine1", "Please write a valid address!");
            valid = false;
        }

        if (!window.isNullOrEmpty(body.companyPhone) && body.companyPhone.length < 9) {
            window.formError("companyPhone", "Phone number is not valid!");
            valid = false;
        }

        if (!valid) return;

        workingSet(true);
        hub.Post("/auth/signup/company", {
            ...body,
            token: req?.token
        }, res => {
            if (res.success) {
                stepSet(4);
                reqSet(res.data);
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error");
            }
            workingSet(false);
        })

    }

    const updateStep = (st: number) => {
        stepSet(st);
        hub.Post("/auth/signup/updatestep", {
            token: req?.token,
            step: st
        }, res => { });
    }

    const budgetKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!/[0-9]/.test(e.key) && e.key != "Enter") {
            e.preventDefault();
        }
    }

    const budgetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        window.hideFormErrors();
        monthlyBudgetVerifiedSet(false);
        var budg = Number.parseInt(e.target.value);
        if (isNaN(budg)) budg = 0;
        monthlyBudgetSet(budg);
        if (budg >= 25 && budg <= 300) {
            monthlyBudgetVerifiedSet(true);
        }
        else {
            window.showFormError("monthlyBudget", "Monthly budget must be between 50$ to 300$", true);
        }
    }

    const fetchPaymentMethods = (callback?: any) => {
        fetchingPaymentMethodsSet(true);
        hub.Post("/auth/signup/paymentmethods", {
            userId: req?.userId,
            token: req?.token
        }, res => {
            if (res.success) {
                paymentMethodsSet(res.data);
                if (callback) callback();
                fetchingPaymentMethodsSet(false);
            }
        });

    }

    const PaymentCardView = (props: { item: PaymentMethod }) => (
        <div>
            <FlexRow>
                {
                    props.item.cardBrand == "visa" ?
                        <img src="/assets/media/svg/card-logos/visa.svg" alt="" className="me-4 h-50px" />
                        :
                        props.item.cardBrand == "mastercard" ?
                            <img src="/assets/media/svg/card-logos/mastercard.svg" alt="" className="me-4 h-50px" />
                            :
                            props.item.cardBrand == "amex" ?
                                <img src="/assets/media/svg/card-logos/american-express-dark.svg" alt="" className="me-4 h-50px" />
                                :
                                props.item.cardBrand == "discover" ?
                                    <img src="/assets/media/svg/card-logos/discover.png" alt="" className="me-4 h-50px" />
                                    :
                                    props.item.cardBrand == "diners" ?
                                        <img src="/assets/media/svg/card-logos/diners-club.png" alt="" className="me-4 h-50px" />
                                        :
                                        props.item.cardBrand == "jcb" ?
                                            <img src="/assets/media/svg/card-logos/jcb.png" alt="" className="me-4 h-50px" />
                                            :
                                            props.item.cardBrand == "unionpay" ?
                                                <img src="/assets/media/svg/card-logos/unionpay.svg" alt="" className="me-4 h-50px" />
                                                :
                                                <img src="/assets/media/svg/card-logos/unknown.png" alt="" className="me-4 h-50px" />
                }
                <div>
                    <div className="fs-4 fw-bolder">{props.item.cardBrand}&nbsp;****&nbsp;{props.item.cardLast4}</div>
                    <div className="fs-6 fw-bold text-gray-400">Exp: **/{props.item.cardExpYear}</div>
                </div>
            </FlexRow>
        </div>
    )

    const paymentmethodSubmit = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormErrors();

        workingSet(true);

        hub.Post("/auth/signup/paymentmethod", {
            ...body,
            userId: req?.userId,
            token: req?.token
        }, res => {
            if (res.success) {
                setTimeout(() => {
                    document.location.href = res.data.url;
                }, 1000);
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error happened!");
                workingSet(false);
            }
        });

    }

    const budgetSubmit = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormErrors();
        return;
        if (window.isNullOrEmpty(body.monthlyBudget)) {
            window.showFormError("monthlyBudget", "Please define your monthly budget!");
            return;
        }

        var budget = Number.parseInt(body.monthlyBudget);
        if (isNaN(budget) || budget < 50 || budget > 300) {
            window.showFormError("monthlyBudget", "Monthly budget must be between 50$ to 300$");
            return;
        }

        workingSet(true);

        hub.Post("/auth/signup/savebudget", {
            ...body,
            userId: req?.userId,
            token: req?.token
        }, res => {
            if (res.success) {
                reqSet(res.data);
                stepSet(6);
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error happened!");
            }
            workingSet(false);
        });

    }

    const payInvoice = () => {
        window.hideFormErrors();

        if (monthlyBudget < 25 || monthlyBudget > 300) {
            window.showFormError("invoice-budget", "Budget limit must be between 25$ to 300$");
            return;
        }
        workingSet(true);

        setTimeout(() => {

            hub.Post("/auth/signup/payinvoice", {
                token: req?.token,
                userId: req?.userId,
                monthlyBudget: monthlyBudget,
                extraSeats: extraSeats,
                couponCode: couponCode
            }, res => {
                if (res.success) {
                    document.location.href = res.data.checkPaymentLink;
                }
                else {
                    window.showFormError("invoice", res.message);
                    workingSet(false);
                }
            });

        }, 200);


    }

    const beginsetup = (token?: string, userId?: string) => {
        workingSet(true);
        hub.Post("/auth/signup/beginsetup", {
            token: token,
            userId: userId
        }, res => {
            if (res.success) {
                setUserToken(res.data.token);
                setUserId(res.data.id);
                if (res.data.isSuperAdmin) setOwnerUserId("admins");
                else if (res.data.isCustomer) setOwnerUserId(res.data.id);
                else if (res.data.ownerUserId) setOwnerUserId(res.data.ownerUserId);

                document.location.reload();
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error");
            }
            workingSet(false);
        });
    }

    const [addMoreExts, addMoreExtsSet] = useState<number[]>([]);
    const extcreate = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormError();

        if (!body.extname || body.extname.length < 2) {
            window.formError("extname", "Please write a valid name!");
            return;
        }


        workingSet(true);
        hub.Post("/auth/signup/createexts", {
            ...body,
            token: req?.token,
            userId: req?.userId
        }, res => {
            if (res.success) {
                stepSet(8);
                reqSet(res.data);
            }
            else {
                if (res.field) window.formError(res.field, res.message ?? "");
                else toastError(res.message ?? "general error");
            }
            workingSet(false);
        });


    }

    const [exts, extsSet] = useState<TagifyDataItem[]>([]);
    const fetchExts = () => {
        hub.Get(API_ROUTES.TagifyExtensions, res => {
            if (res.success) extsSet(res.data);
        })
    }
    const queuecreate = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormError();

        if (!body.queuename || body.queuename.length < 2) {
            window.showFormError("queuename", "Please write a valid name!");
            return;
        }

        workingSet(true);
        hub.Post("/auth/signup/createqueue", {
            ...body,
            token: req?.token,
            userId: req?.userId
        }, res => {
            if (res.success) {
                stepSet(9);
                reqSet(res.data);
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error");
            }
            workingSet(false);
        });


    }

    const [fetchingLines, fetchingLinesSet] = useState<boolean>();
    const [fetchedLines, fetchedLinesSet] = useState<boolean>();
    const [lines, linesSet] = useState<TwilioLine[]>();
    const [selectedLine, selectedLineSet] = useState<TwilioLine>();
    let b: any;
    const fetchLines = (params?: any) => {

        fetchedLinesSet(false);
        fetchingLinesSet(true);
        selectedLineSet(undefined);
        params = params ?? b;
        b = params;

        var q = "";
        if (params?.country && params.country != "") {
            if (q != "") q += "&";
            q += "country=" + params.country;
        }
        if (params?.areaCode && params.areaCode != "") {
            if (q != "") q += "&";
            q += "areaCode=" + params.areaCode;
        }
        if (params?.keyword && params.keyword != "") {
            if (q != "") q += "&";
            q += "keyword=" + encodeURIComponent(params.keyword);
        }
        if (params?.match) {
            if (q != "") q += "&";
            q += "match=" + encodeURIComponent(params.match);
        }

        if (params?.voice == "True") {
            if (q != "") q += "&";
            q += "voice=True";
        }

        if (params?.sms == "True") {
            if (q != "") q += "&";
            q += "sms=True";
        }

        if (params?.mms == "True") {
            if (q != "") q += "&";
            q += "mms=True";
        }

        if (selectedNumberType) {
            if (q != "") q += "&";
            q += "numberType=" + selectedNumberType.id;
        }

        if (q != "") q = "?" + q;

        hub.Get("/voice/lines/available/" + q, res => {
            if (res.data) {
                linesSet(res.data);
                fetchedLinesSet(true);
                fetchingLinesSet(false);
            }
        })

    }

    const buyfirstnumber = () => {
        window.hideFormError();
        if (!selectedLine) return;
        workingSet(true);
        hub.Post("/auth/signup/buyfirstnumber", {
            token: req?.token,
            userId: req?.userId,
            phoneNumber: selectedLine.phoneNumber,
            friendlyName: selectedLine.friendlyName
        }, res => {
            if (res.success) {
                stepSet(10);
                reqSet(res.data);
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error");
            }
            workingSet(false);
        });


    }

    const finalize = () => {
        hub.Post("/auth/signup/finalize", {
            token: req?.token,
            userId: req?.userId
        }, res => {
            if (res.success) {
                document.location.href = "/dashboard";
            }
            else {
                if (res.field) window.showFormError(res.field, res.message);
                else toastError(res.message ?? "general error happened!");
                workingSet(false);
            }
        });
    }

    useEffect(fetch, []);

    useEffect(() => {
        setSignupStep(step);
        if (step == 2) {
            $("#txt-username").focus();
            window.KTPasswordMeterCreate();
        }
        if (step == 3) {
            if (req?.companyCountry) fetchStates(req.companyCountry);
        }
        if (step == 4) {
            fetchPaymentMethods();
        }
        if (step == 7) {
            $("#txt-extname").focus();
        }
        if (step == 8) {
            $("#txt-queuename").focus();
            fetchExts();
        }
        if (step == 9) {
            fetchLines();
            selectedCountrySet(getUSA());
        }
    }, [step, req])

    useEffect(() => {
        $("#drp-companyState").val(req?.companyState ?? "");
        window.updateSelect2("drp-companyState", req?.companyState);
        if (req?.token) localStorage.setItem("signup_req_token", req.token);
    }, [states, req]);

    useEffect(() => {
        window.makeSelect2("drp-areacodes", false, undefined, undefined, undefined, undefined, undefined, true);
        if (states && selectedCountry?.alpha2Code?.toLowerCase() != "us" && step == 9) {
            var arr: any[] = [];
            states.forEach(s => {
                s.areaCodes.forEach((code: any) => {
                    if (arr.indexOf(code) < 0) arr.push(code);
                });
            });

            areaCodesSet(arr);
        }
    }, [states, step])

    useEffect(() => {
        setTimeout(() => {
            if (addMoreExts.length > 0) $("#txtextname" + addMoreExts.length).focus();
        }, 100);
    }, [addMoreExts]);


    const [couponCode, couponCodeSet] = useState<string>("");
    const [couponAmount, couponAmountSet] = useState<number>(0);
    const [couponChecking, couponCheckingSet] = useState<boolean>(false);
    const [couponIsValid, couponIsValidSet] = useState<boolean>(false);
    const [couponIsInvalid, couponIsInvalidSet] = useState<boolean>(false);

    useEffect(() => {
        couponIsValidSet(false);
        couponIsInvalidSet(false);
        couponAmountSet(0);
        if (couponCode && couponCode.length == 7) {
            couponCheckingSet(true);
            setTimeout(() => {
                if (couponCode.toLowerCase() == "start99") {
                    couponIsValidSet(true);
                    couponAmountSet(99);
                }
                else {
                    couponIsInvalidSet(true);
                }
                couponCheckingSet(false);
            }, 500);
        }
    }, [couponCode])

    useEffect(() => {
        var total = (prices?.subscriptionPerMonth ?? 0) + monthlyBudget + (extraSeats * (prices?.extra5SeatsPackage ?? 0));
        subTotalAmountSet(total);
        total = total - couponAmount;
        if (total < 0) total = 0;
        totalAmountSet(total);
    }, [monthlyBudget, extraSeats, couponAmount])


    const [numberTypes] = useState<DataItem[]>([
        {
            id: "1",
            name: "Local"
        },
        {
            id: "2",
            name: "Toll-Free"
        }
    ])
    const [selectedNumberType, selectedNumberTypeSet] = useState<DataItem>({
        id: "1",
        name: "Local"
    });


    return (
        <div className={((step == 4 || step == 5) ? "w-lg-800px" : (step == 9) ? "mw-lg-1000px w-100" : "w-lg-600px") + " p-10 mx-auto"}>

            <Container visible={loading}>
                <Indicator text="Please wait..." />
            </Container>

            <Container visible={step == 1}>
                <form className="form w-100" id="kt_sign_up_form">
                    <div className="mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="check-circle" size={96} className="me-2 text-success" />
                        </div>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Thank You {req?.firstName}!
                        </h1>
                        <div className="text-gray-600 fs-6">
                            Your email address <span className="text-success fw-bolder">{req?.email}</span> has been verified successfully!
                        </div>
                        <div className="text-gray-600 fs-6">
                            You can complete your registration process now!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-lg btn-primary" onClick={e => stepSet(2)}>
                            <span className="indicator-label">
                                Let's Keep Going
                            </span>
                        </button>
                    </div>
                </form>
            </Container>

            <Container visible={step == 2}>
                <Form onSubmit={submitSignInOptions} className="form w-100">
                    <div className="mb-10 text-center">
                        <h1 className="text-dark mb-3">Sign-In Options</h1>
                        <div className="text-gray-400 fw-bold fs-6">
                            Create your own username and password!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    <div className="fv-row mb-7">
                        <label className="form-label fs-7" htmlFor="txt-username">
                            Username
                            <span id="username-err" className="small text-danger mx-2 form-error"></span>
                        </label>
                        <div className="position-relative mb-3">
                            <input id="txt-username" className="form-control form-inputs" onChange={usernameChange}
                                type="text" placeholder="Choose your username" name="username" autoComplete="one-time-code" />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                {
                                    usernameVerifing && <i className="far fa-spinner fa-spin fs-2"></i>
                                }
                                {
                                    usernameVerified && <i className="far fa-check-circle fs-2 text-success"></i>
                                }
                            </span>
                        </div>
                    </div>

                    <div className="mb-10 fv-row" data-kt-password-meter="true">
                        <div className="mb-1">
                            <label className="form-label fs-7" htmlFor="txt-password">
                                Password
                                <span id="password-capslock" className="small text-danger mx-2 form-error">CapsLock is On</span>
                                <span id="password-err" className="small text-danger mx-2 form-error"></span>
                            </label>
                            <div className="position-relative mb-3">
                                <input id="txt-password" className="form-control form-inputs" type="password" placeholder="Choose a strong password" name="password" autoComplete="one-time-code"
                                    onKeyUp={e => {
                                        if (e.getModifierState("CapsLock")) {
                                            $("#password-capslock").fadeIn().css("display", "inline-block");
                                        }
                                        else {
                                            $("#password-capslock").hide();
                                        }
                                    }} />
                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                    <i className="bi bi-eye-slash fs-2"></i>
                                    <i className="bi bi-eye fs-2 d-none"></i>
                                </span>
                            </div>
                            <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                            </div>
                        </div>
                        <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
                    </div>

                    <div className="text-end">
                        <button type="submit" className="btn btn-primary w-150px" disabled={working}>
                            {
                                working ?
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label me-2">
                                            Continue
                                        </span>
                                        <Icon icon="chevron-right" type="solid" />
                                    </>
                            }
                        </button>
                    </div>

                </Form>
            </Container>

            <Container visible={step == 3}>
                <Form className="form w-100" onSubmit={submitCompany}>
                    <div className="mb-10 text-center">
                        <h1 className="text-dark mb-3">Add Business</h1>
                        <div className="text-gray-400 fw-bold fs-6">
                            Add your company or your personal business information!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    <div className="text-center mb-7">
                        <AvatarUploade name="logoUrl" type="logos" width={80} />
                    </div>
                    <div className="fv-row mb-3">
                        <TextBox name="companyName" placeholder="Write your company name" label="Business Name" labelUp
                            autoFocus block defaultValue={req?.companyName} />
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <DropDown id="drp-join-countries" name="companyCountry" block labelUp marginBottom={0}
                                label="Country" source={countries} defaultValue={req?.companyCountry}
                                onSelect={val => fetchStates(val ?? "")} />
                        </div>
                        <div className="col-md-6">
                            {
                                states.length > 0 ?
                                    <DropDown id="drp-join-states" name="companyState" block labelUp marginBottom={0}
                                        label="State" source={states} defaultValue={req?.companyState} />
                                    :
                                    <TextBox name="companyState" block labelUp marginBottom={0}
                                        label="State" defaultValue={req?.companyStateCustom} />
                            }

                        </div>
                    </div>

                    {/* <div className="fv-row mb-3">
                        <DropDown id="drp-join-countries" name="companyCountry" block labelUp marginBottom={0}
                            label="Country" source={countries} defaultValue={req?.companyCountry}
                            onSelect={val => fetchStates(val ?? "")}/>
                    </div>

                    <div className="fv-row mb-3">
                        
                    </div> */}

                    <div className="fv-row mb-3">
                        <TextBox block labelUp marginBottom={0} name="companyCity" value={req?.companyCity}
                            label="City" placeholder="Write your business city" />
                    </div>
                    <div className="fv-row mb-3">
                        <TextBox block labelUp marginBottom={0} name="companyAddressLine1" value={req?.companyAddressLine1}
                            label="Address Line 1" placeholder="District, Street name" />
                    </div>
                    <div className="fv-row mb-3">
                        <TextBox block labelUp marginBottom={0} name="companyAddressLine2" value={req?.companyAddressLine2}
                            label="Address Line 2" placeholder="Building Number, Entrance, Apartment" />

                    </div>
                    <div className="fv-row mb-10">
                        <PhoneInput name="companyPhone" labelUp block label="Company Phone" marginBottom={0}
                            defaultPhone={req?.companyPhoneItem} countryCodes="all" />
                    </div>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary w-150px" disabled={working}>
                            {
                                working ?
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label me-2">
                                            Continue
                                        </span>
                                        <Icon icon="chevron-right" type="solid" />
                                    </>
                            }
                        </button>
                    </div>
                </Form>
            </Container>

            <Container visible={step == 4}>
                <Form onSubmit={paymentmethodSubmit} className="form w-100">
                    <div className="text-center">
                        <div className="text-center mb-1">
                            <Icon type="brands" icon="stripe" size={96} className="me-2 text-info" />
                        </div>
                    </div>
                    <div className="mb-10 text-center">
                        <h1 className="text-dark mb-3">Payment Method</h1>
                        <div className="text-gray-400 fw-bold fs-6">
                            Add your credit card and pay easy and secure by Stripe!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    {
                        fetchingPaymentMethods && <div className="py-10"><Indicator /></div>
                    }

                    {
                        !fetchingPaymentMethods &&
                        <>
                            {
                                paymentMethods.length > 0 ?
                                    <>
                                        {
                                            paymentMethods.map((item, key) =>
                                                <>
                                                    <div key={key} className="d-flex flex-wrap align-items-center">
                                                        <PaymentCardView item={item} />
                                                        <div className="ms-auto">
                                                            <button type="button" className="btn btn-light-danger btn-sm"
                                                                onClick={e => {
                                                                    selectedItemSet(item);
                                                                    window.openModal("modal-remove-method");
                                                                }}>
                                                                Remove Card
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="separator separator-dashed my-6"></div>
                                                    <FlexRow>
                                                        <button type="button" className="btn btn-light" onClick={e => updateStep(3)}>
                                                            <Icon icon="chevron-left" className="me-2" />
                                                            Edit Business
                                                        </button>
                                                        <button type="button" className="btn btn-primary w-200px btn-icon ms-auto" onClick={e => updateStep(5)}>
                                                            Proceed to Payment
                                                            <Icon icon="chevron-right" className="ms-2" />
                                                        </button>
                                                    </FlexRow>
                                                </>
                                            )
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary w-200px" disabled={working}>
                                                {
                                                    working ?
                                                        <>
                                                            <span className="indicator-label me-2">
                                                                Please Wait
                                                            </span>
                                                            <Icon icon="spinner-third" spin />
                                                        </>
                                                        :
                                                        <>
                                                            <Icon icon="credit-card" type="solid" size={16} className="me-2" />
                                                            <span>
                                                                Add Credit Card
                                                            </span>
                                                        </>
                                                }
                                            </button>
                                        </div>
                                        <div className="text-center mt-3">
                                            <button type="button" className="btn btn-light w-200px" onClick={e => updateStep(3)}>
                                                <Icon icon="chevron-left" className="me-2" />
                                                Edit Business
                                            </button>
                                        </div>
                                    </>
                            }
                        </>
                    }




                </Form>
            </Container>

            <Container visible={step == 5}>
                <form onSubmit={e => e.preventDefault()} className="form w-100">
                    <div className="mb-10 text-center">
                        <h1 className="text-dark mb-3">
                            First Invoice
                        </h1>
                        <div className="text-gray-600 fw-bold fs-6">
                            Customize and pay your first invoice
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                        <div className="mt-n1">
                            {/* <div className="d-flex flex-stack pb-10">
                                <a href="#">
                                    <img alt="Logo" src="/assets/media/svg/brand-logos/code-lab.svg" />
                                </a>
                                <a href="#" className="btn btn-sm btn-success">Pay Now</a>
                            </div> */}

                            <div className="m-0">
                                {/* <div className="fw-bolder fs-3 text-gray-800 mb-8">Invoice #34782</div> */}
                                <div className="row g-5 mb-11">
                                    <div className="col-sm-3">
                                        <div className="fw-bold fs-7 text-gray-600 mb-1">Issue Date:</div>
                                        <div className="fw-bolder fs-6 text-gray-800">
                                            {window.now().format("DD MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="fw-bold fs-7 text-gray-600 mb-1">Issue For:</div>
                                        <div className="fw-bolder fs-6 text-gray-800">
                                            {req?.companyName}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-end">

                                    </div>
                                </div>
                            </div>

                            {/* <div className="row g-5 mb-12">
                                <div className="col-sm-9">
                                    <div className="fw-bold fs-7 text-gray-600 mb-1">Issue For:</div>
                                    <div className="fw-bolder fs-6 text-gray-800">
                                        {req?.companyName}
                                    </div>
                                    <div className="fw-bold fs-7 text-gray-600">8692 Wild Rose Drive
                                    <br />Livonia, MI 48150</div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="fw-bold fs-7 text-gray-600 mb-1">Issued By:</div>
                                    <div className="fw-bolder fs-6 text-gray-800">
                                        DSPro LTD
                                    </div>
                                    <div className="fw-bold fs-7 text-gray-600">
                                        9858 South 53rd Ave.
                                        <br />Matthews, NC 28104
                                    </div>
                                </div>
                            </div> */}

                            <div className="flex-grow-1">
                                <div className="table-responsive border-bottom mb-9">
                                    <table className="table mb-3">
                                        <thead>
                                            <tr className="border-bottom fs-6 fw-bolder text-muted">
                                                <th className="min-w-175px pb-2">Description</th>
                                                <th className="min-w-80px text-end pb-2">Rate</th>
                                                <th className="min-w-100px text-end pb-2">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                                <td className="align-middle text-start">
                                                    <div>Monthly Subscription</div>
                                                    <div className="d-flex align-items-center">
                                                        <span className="fs-7 fw-normal">+ 5 Online Seats</span>
                                                        <a href="#" className="ms-2"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                window.openModal("modal-help-subscription");
                                                            }}>
                                                            <Icon icon="circle-question" type="solid" size={14} />
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="pt-6">{moneyFormatter.format(prices?.subscriptionPerMonth ?? 0)}</td>
                                                <td className="pt-6 text-dark fw-boldest">{moneyFormatter.format(prices?.subscriptionPerMonth ?? 0)}</td>
                                            </tr>
                                            <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                                <td className="align-middle text-start">
                                                    <FlexRow>
                                                        <div>Initial Budget</div>
                                                    </FlexRow>
                                                    <div className="d-flex align-items-center">
                                                        <span className="fs-7 fw-normal">
                                                            Define budget charge limit
                                                        </span>
                                                        <a href="#" className="ms-2"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                window.openModal("modal-help-initialbudget");
                                                            }}>
                                                            <Icon icon="circle-question" type="solid" size={14} />
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-end">
                                                    {/* <input id="txt-monthlyBudget" className="form-control form-control-solid form-control-sm ms-auto w-150px" maxLength={4}
                                                        type="tel" placeholder="50$ - 300$" name="monthlyBudget" autoComplete="one-time-code"
                                                        onChange={budgetChange} onKeyPress={budgetKeypress} /> */}
                                                    <select name="monthlybudget" className="form-select form-select-solid form-select-sm ms-auto w-150px" value={monthlyBudget}
                                                        onChange={budgetChange}>
                                                        {/* <option value={0}>Choose Budget</option> */}
                                                        <option value={25}>$25</option>
                                                        <option value={50}>$50</option>
                                                        <option value={100}>$100</option>
                                                        <option value={150}>$150</option>
                                                        <option value={200}>$200</option>
                                                        <option value={250}>$250</option>
                                                        <option value={300}>$300</option>
                                                    </select>
                                                    <div id="invoice-budget-err" className="small text-danger mx-2 mt-1 form-error"></div>
                                                </td>
                                                <td className="pt-6 text-dark fw-boldest">{moneyFormatter.format(monthlyBudget)}</td>
                                            </tr>
                                            <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                                <td className="align-middle text-start">
                                                    <div>Seats Package</div>
                                                    <div className="d-flex align-items-center">
                                                        <span className="fs-7 fw-normal">
                                                            Add Extra Online Seats
                                                        </span>
                                                        <a href="#" className="ms-2"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                window.openModal("modal-help-extraseatspacks");
                                                            }}>
                                                            <Icon icon="circle-question" type="solid" size={14} />
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-end">
                                                    <select className="form-select form-select-solid form-select-sm ms-auto w-150px"
                                                        value={extraSeats} onChange={e => extraSeatsSet(Number.parseInt(e.target.value))}>
                                                        <option value={0}>No Extra Seats</option>
                                                        {extraSeatsItems.map((item, key) => <option key={key} value={item}>{item} ({item * 5} Users)</option>)}
                                                    </select>
                                                </td>
                                                <td className="pt-6 text-dark fw-boldest">{moneyFormatter.format(extraSeats * (prices?.extra5SeatsPackage ?? 0))}</td>
                                            </tr>
                                            <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                                <td className="align-middle text-start">
                                                    <div>Coupon</div>
                                                    <div className="d-flex align-items-center">
                                                        <span className="fs-7 fw-normal">
                                                            If you have a discount code
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-end">
                                                    <div className="ms-auto w-150px"
                                                        style={{
                                                            position: "relative"
                                                        }}>
                                                        <input type="text" className="form-control form-control-solid form-control-sm" maxLength={7} style={{ textTransform: "uppercase" }}
                                                            placeholder="Coupon Code" value={couponCode} onChange={e => couponCodeSet(e.target.value)} />
                                                        {
                                                            couponChecking &&
                                                            <span style={{
                                                                position: "absolute",
                                                                right: 10,
                                                                top: 6
                                                            }}>
                                                                <Icon icon="asterisk" spin color="#777" />
                                                            </span>
                                                        }
                                                        {
                                                            couponIsValid &&
                                                            <span style={{
                                                                position: "absolute",
                                                                right: 10,
                                                                top: 6
                                                            }}>
                                                                <Icon icon="circle-check" type="solid" className="text-success" />
                                                            </span>
                                                        }
                                                        {
                                                            couponIsInvalid &&
                                                            <span style={{
                                                                position: "absolute",
                                                                right: 10,
                                                                top: 6
                                                            }} title="Coupon code is invalid!">
                                                                <Icon icon="circle-exclamation" type="solid" className="text-warning" />
                                                            </span>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="pt-6 text-dark fw-boldest">{moneyFormatter.format(couponAmount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <FlexRow>

                                    <div>
                                        <FlexRow>
                                            <button type="button" onClick={payInvoice} className="btn btn-primary w-250px">
                                                {
                                                    working ?
                                                        <>
                                                            <span className="indicator-label me-2">
                                                                Please Wait
                                                            </span>
                                                            <Icon icon="spinner-third" spin />
                                                        </>
                                                        :
                                                        <>
                                                            <span className="indicator-label me-2">
                                                                Proceed to Checkout
                                                            </span>
                                                            <Icon icon="chevron-right" type="solid" />
                                                        </>
                                                }
                                            </button>
                                            <div id="invoice-err" className="small text-danger mx-2 form-error"></div>
                                        </FlexRow>
                                        <div className="mt-3 ms-1">
                                            <button type="button" onClick={e => updateStep(4)} className="btn btn-link w-250px text-gray-600">
                                                <Icon icon="chevron-left" className="me-2" />
                                                Back to Payment Methods
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mw-300px ms-auto">
                                        <div className="d-flex flex-stack mb-3">
                                            <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                            <div className="text-end fw-bolder fs-6 text-gray-800">{moneyFormatter.format(subTotalAmount)}</div>
                                        </div>
                                        <div className="d-flex flex-stack mb-3">
                                            <div className="fw-bold pe-10 text-gray-600 fs-7">Discount</div>
                                            {
                                                couponAmount > 0 ?
                                                    <div className="text-end fw-bolder fs-6 text-danger">- {moneyFormatter.format(couponAmount)}</div>
                                                    :
                                                    <div className="text-end fw-bolder fs-6 text-gray-800">{moneyFormatter.format(0)}</div>
                                            }
                                        </div>
                                        <div className="d-flex flex-stack mb-3">
                                            <div className="fw-bold pe-10 text-gray-600 fs-7">VAT 0%</div>
                                            <div className="text-end fw-bolder fs-6 text-gray-800">{moneyFormatter.format(0)}</div>
                                        </div>
                                        <div className="d-flex flex-stack">
                                            <div className="fw-bold pe-10 text-gray-600 fs-4">Total</div>
                                            <div className="text-end fw-bolder fs-1 text-gray-800">{moneyFormatter.format(totalAmount)}</div>
                                        </div>
                                        <FlexRow className="mt-5">
                                            <div className="me-2 fs-8">
                                                Powered by
                                            </div>
                                            <Icon icon="stripe" type="brands" className="text-info" size={24} />
                                        </FlexRow>
                                    </div>

                                </FlexRow>

                            </div>

                        </div>
                    </div>
                </form>
            </Container>

            <Container visible={step == 6}>
                <Form onSubmit={() => beginsetup(req?.token, req?.userId)} className="form w-100">
                    <div className="mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="check-circle" size={96} className="me-2 text-success" />
                        </div>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Congratulation!
                        </h1>
                        <div className="text-gray-600 fs-6">
                            Your registration process has been done successfully!
                        </div>
                        <div className="text-gray-600 fs-6">
                            You can start to setup your account!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-lg btn-primary w-200px btn-icon" disabled={working}>
                            {
                                working ?
                                    <Icon icon="asterisk" spin />
                                    :
                                    <span className="indicator-label">
                                        Let's Begin!
                                    </span>
                            }
                        </button>
                    </div>
                </Form>
            </Container>

            <Container visible={step == 7}>
                <Form onSubmit={extcreate} className="form w-100">
                    <div className="mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="phone-office" size={96} className="me-2 text-primary" />
                        </div>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Create Your First Extension
                        </h1>
                        <a href="#" className="text-gray-600 fs-6"
                            onClick={e => {
                                e.preventDefault();
                                window.openModal("modal-help-whatisextension");
                            }}>
                            <Icon icon="circle-info" type="solid" size={16} className="me-2" />
                            What is the Extension?
                        </a>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    <TextBox id="txt-extname" name="extname" label="Extension Name*" labelUp placeholder="E.g. Sales, Support, etc" />
                    {
                        addMoreExts.map((a, k) =>
                            <div key={k} style={{
                                position: "relative"
                            }}>
                                <TextBox id={"txtextname" + (k + 1)} name={"extname" + (k + 1)} label={"Extension#" + (k + 1)} labelUp placeholder="E.g. Sales 1, Sales 2, Support 1, etc" />
                                {
                                    (k + 1) == addMoreExts.length &&
                                    <a href="#" className="btn btn-secondary-light btn-sm btn-icon position-absolute bottom-0 end-0"
                                        onClick={e => {
                                            e.preventDefault();
                                            var arr = addMoreExts;
                                            arr.splice(arr.length - 1, 1);
                                            addMoreExtsSet(a => [...arr]);
                                        }}>
                                        <Icon icon="circle-minus" size={18} type="solid" className="text-danger" />
                                    </a>
                                }

                            </div>
                        )
                    }
                    {
                        addMoreExts.length < 4 &&
                        <div>
                            <a href="#" onClick={e => {
                                e.preventDefault();
                                var arr = addMoreExts;
                                arr.push(1);
                                addMoreExtsSet(a => [...arr]);
                            }}>
                                + Add More
                            </a>
                        </div>
                    }

                    <div className="text-center mt-10">
                        <button type="submit" className="btn btn-lg btn-primary w-200px btn-icon" disabled={working}>
                            {
                                working ?
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label me-2">
                                            Continue
                                        </span>
                                        <Icon icon="chevron-right" type="solid" />
                                    </>
                            }
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <button type="button" className="btn btn-light w-200px"
                            onClick={e => {
                                window.openModal("modal-skip-initiaion");
                            }}>
                            Skip Setup
                        </button>
                    </div>
                </Form>
            </Container>

            <Container visible={step == 8}>
                <Form onSubmit={queuecreate} className="form w-100">

                    <div className="mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="arrow-right-to-line" size={96} className="me-2 text-primary" />
                        </div>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Create Your First Queue
                        </h1>
                        <a href="#" className="text-gray-600 fs-6"
                            onClick={e => {
                                e.preventDefault();
                                window.openModal("modal-help-whatisqueue");
                            }}>
                            <Icon icon="circle-info" type="solid" size={16} className="me-2" />
                            What is the Queue?
                        </a>
                    </div>

                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    <TextBox id="txt-queuename" name="queuename" label="Queue Name*" labelUp placeholder="E.g. Florida Sales, Austin Support" />

                    {/* <div className={"row align-items-center mb-5"}>
                        <label className={"col col-12 fw-bold fs-7"}>
                            Assign Extensions
                        </label>
                        <div className="col col-12 mt-3">
                            {
                                exts.map((e, k) => 
                                <div key={k} className="form-check form-check-custom form-check-solid form-check-sm mb-2">
                                    <input className="form-check-input" type="checkbox" value={e.value} name="exts" id={"chk-ext-" + e.value} />
                                    <label className="form-check-label fs-7" htmlFor={"chk-ext-" + e.value}>
                                        {e.title}
                                    </label>
                                </div>
                                )
                            }
                        </div>
                    </div> */}

                    <div className="text-center mt-10">
                        <button type="submit" className="btn btn-lg btn-primary w-200px btn-icon" disabled={working}>
                            {
                                working ?
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label me-2">
                                            Continue
                                        </span>
                                        <Icon icon="chevron-right" type="solid" />
                                    </>
                            }
                        </button>
                    </div>

                    <div className="text-center mt-4">
                        <button type="button" className="btn btn-light w-200px"
                            onClick={e => {
                                window.openModal("modal-skip-initiaion");
                            }}>
                            Skip Setup
                        </button>
                    </div>

                </Form>
            </Container>

            <Container visible={step == 9}>
                <div className="form w-100">

                    <div className="row">
                        <div className="col-12">

                            <div className="row border-bottom pb-2 mb-2">
                                <div className="col-12">
                                    <Form onSubmit={(body, callback) => {
                                        fetchLines(body);
                                    }}>
                                        <FlexRow>
                                            <div className="me-1 w-75px">
                                                <DropDown id="drp-searchlines-countries" name="country" small block solid raw marginBottom={0} deselect={false}
                                                    placeholder="Countries" source={countries} dataText="alpha2Code" dataValue="id"
                                                    onSelectItem={c => selectedCountrySet(c)} defaultValue={getUSA().id} />
                                            </div>
                                            <div className="me-1">
                                                <select id="drp-areacodes" name="areaCode" className="form-select form-inputs form-select-sm form-select-solid w-125px" data-placeholder="Area Codes">
                                                    <option></option>
                                                    {
                                                        selectedCountry?.alpha3Code?.toLocaleLowerCase() == "usa" ?
                                                            <>
                                                                {
                                                                    states?.map((item, key) =>
                                                                        <optgroup key={key} label={item.name}>
                                                                            {item.areaCodes?.map((code: any, k: any) => <option key={k} value={code}>{code}</option>)}
                                                                        </optgroup>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    areaCodes?.sort((a: any, b: any) => a - b).map((code: any, k: any) =>
                                                                        <option key={k} value={code}>
                                                                            +{selectedCountry?.phoneCode} ({code})
                                                                        </option>)
                                                                }
                                                            </>
                                                    }

                                                </select>
                                            </div>
                                            <div className="me-1">
                                                <input className="form-control form-control-sm form-control-solid w-125px" type="tel" id="txt-lines-keyword"
                                                    name="keyword" placeholder="Part of Number" autoComplete="one-time-code" maxLength={8}
                                                    onKeyPress={e => {
                                                        if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                            </div>
                                            <div className="me-5 w-125px">
                                                <DropDown id="drp-numbertypes" name="numberType" small block solid marginBottom={0} deselect={false}
                                                    source={numberTypes} onSelectItem={c => selectedNumberTypeSet(c)} defaultValue="1" />
                                            </div>
                                            <div className="me-5">
                                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                    <input id="chk-search-voice" className="form-check-input" type="checkbox" name="voice" value="True" defaultChecked />
                                                    <label className="form-check-label" htmlFor="chk-search-voice">
                                                        Voice
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="me-5">
                                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                    <input id="chk-search-sms" className="form-check-input" type="checkbox" name="sms" value="True" />
                                                    <label className="form-check-label" htmlFor="chk-search-sms">
                                                        SMS
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="me-5">
                                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                    <input id="chk-search-mms" className="form-check-input" type="checkbox" name="mms" value="True" />
                                                    <label className="form-check-label" htmlFor="chk-search-mms">
                                                        MMS
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary btn-sm w-100px ms-auto">
                                                <Icon icon="search" />
                                                Search
                                            </button>
                                        </FlexRow>
                                    </Form>
                                </div>
                            </div>
                            {
                                fetchingLines &&
                                <div className="row h-400px h-xxl-600px">
                                    <div className="col-12 p-10">
                                        <Indicator />
                                    </div>
                                </div>
                            }
                            {
                                fetchedLines &&
                                <div className="row scroll h-400px h-xxl-600px">
                                    {
                                        lines && lines.length > 0 ?
                                            <>
                                                {
                                                    lines?.map((item, key) =>
                                                        <div key={key} className="col-12 col-md-6">
                                                            <div className={"card mb-5 border cursor-pointer" + (selectedLine == item ? " bg-primary text-white" : "")}
                                                                onClick={e => {
                                                                    selectedLineSet(item);
                                                                }}>
                                                                <div className="card-body d-flex flex-column p-6">
                                                                    <div className="d-flex align-items-start">
                                                                        <Icon icon="phone-rotary" type="solid" size={18} className="me-4 mt-1" />
                                                                        <div>
                                                                            <FlexRow>
                                                                                <a className={"fs-6 fw-bolder me-5 " + (selectedLine == item ? " text-white" : " text-gray-800")}>
                                                                                    {item.friendlyName}
                                                                                </a>
                                                                                {
                                                                                    item.voice ? <Icon icon="phone" type="solid" title="Voice Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                        : <Icon icon="phone" type="regular" title="Voice Disabled" className="me-4 text-muted" />
                                                                                }
                                                                                {
                                                                                    item.sms ? <Icon icon="comment-lines" type="solid" title="SMS Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                        : <Icon icon="comment-lines" type="regular" title="SMS Disabled" className="me-4 text-muted" />
                                                                                }
                                                                                {
                                                                                    item.mms ? <Icon icon="image" type="solid" title="MMS Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                        : <Icon icon="image" type="regular" title="MMS Disabled" className="me-4 text-muted" />
                                                                                }
                                                                            </FlexRow>
                                                                            <FlexRow className={"fs-8 mt-2" + (selectedLine == item ? " text-white" : " text-gray-400")}>
                                                                                <span className="me-5">
                                                                                    {PhoneNumberTypes[item.numberType ?? 1]}
                                                                                </span>
                                                                                {
                                                                                    item.region &&
                                                                                    <>
                                                                                        <Icon icon="location-dot" className="me-1" />
                                                                                        <span>
                                                                                            {item.rateCenter && item.rateCenter != "" ? item.rateCenter + ", " + item.region : item.region}
                                                                                        </span>
                                                                                    </>
                                                                                }

                                                                            </FlexRow>
                                                                        </div>
                                                                        <div className="ms-auto">
                                                                            <div className="fs-7 fw-bolder text-end mb-2">
                                                                                ${item.price}/Month
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <div className="p-10 pt-20 text-center">
                                                <Icon icon="phone-rotary" type="solid" size={48} />
                                                <div className="text-center mt-10">
                                                    There is no result! Please try again in few minutes!
                                                </div>
                                            </div>
                                    }

                                </div>
                            }

                        </div>
                    </div>

                    <div className="text-center mt-10">
                        <button type="button" className="btn btn-lg btn-primary w-200px btn-icon" disabled={working || (!selectedLine)} onClick={buyfirstnumber}>
                            {
                                working ?
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label me-2">
                                            Continue
                                        </span>
                                        <Icon icon="chevron-right" type="solid" />
                                    </>
                            }
                        </button>
                    </div>

                    <div className="text-center mt-4">
                        <button type="button" className="btn btn-light w-200px"
                            onClick={e => {
                                window.openModal("modal-skip-initiaion");
                            }}>
                            Skip Setup
                        </button>
                    </div>

                </div>
            </Container>

            <Container visible={step == 10}>
                <Form onSubmit={finalize} className="form w-100">
                    <div className="mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="check-circle" size={96} className="me-2 text-success" />
                        </div>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Congratulation!
                        </h1>
                        <div className="text-gray-600 fs-6">
                            Your account's first setup has been done successfully!
                        </div>
                        <div className="text-gray-600 fs-6">
                            You can go to your console now!
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-lg btn-primary w-200px btn-icon" disabled={working}>
                            {
                                working ?
                                    <Icon icon="asterisk" spin />
                                    :
                                    <span className="indicator-label">
                                        Go to Console
                                    </span>
                            }
                        </button>
                    </div>

                </Form>
            </Container>

            <Modal id="modal-remove-method"
                title="Remove Payment Method"
                buttonText="Remove Card"
                buttonColor="danger"
                onConfirm={callback => {
                    hub.Post("/auth/signup/removepaymentmethod", {
                        id: selectedItem?.id,
                        userId: req?.userId,
                        token: req?.token
                    }, res => {
                        if (res.success) fetchPaymentMethods(callback);
                    })
                }}>
                <div className="py-10">
                    {
                        selectedItem && <PaymentCardView item={selectedItem} />
                    }
                </div>
            </Modal>


            <Modal id="modal-skip-initiaion"
                title="Skip"
                buttonText="Skip & Go to Concole"
                buttonColor="warning"
                onConfirm={callback => {
                    finalize();
                }}>
                <div className="py-1">
                    <p>
                        Are you sure you want to skip the setup?
                    </p>
                </div>
            </Modal>

            <Modal id="modal-help-subscription"
                title="Monthly Subscription"
                titleIcon="circle-question">
                <div className="mb-10 mt-5">
                    <p>Your subscription plan includes 5 subaccounts, allowing you to add Admin and Agent users to manage your team. </p>
                    <p>If you need more than 5 subaccounts, you can easily expand your team by purchasing additional seats through our Extra Seats package.</p>
                </div>
            </Modal>

            <Modal id="modal-help-initialbudget"
                title="Monthly Subscription"
                titleIcon="circle-question">
                <div className="mb-10 mt-5">
                    <p>The initial budget in our CRM functions as a pre-set funding amount for your wallet, which is used to cover expenses such as purchasing phone numbers, call usage, and SMS charges. </p>
                    <p>When you set an initial budget—say, $50—this amount is added to your wallet to start. If your wallet balance reaches zero, the system automatically recharges your wallet with another $50, ensuring continuous service without interruption. </p>
                    <p>This automatic recharge occurs based on the initial budget amount you choose, streamlining your payments and account management.</p>
                </div>
            </Modal>

            <Modal id="modal-help-extraseatspacks"
                title="Extra Seats Packages"
                titleIcon="circle-question">
                <div className="mb-10 mt-5">
                    <p>
                        The Extra Seats package provides the flexibility to add more users to your CRM. Each package includes 5 additional subaccounts, allowing you to onboard more Admin and Agent users as your team grows.
                    </p>
                    <p>
                        For just ${prices?.extra5SeatsPackage} per package, you can easily scale up your team and enhance your CRM's capabilities to better manage your business. Purchase multiple packages if you need more than 5 extra subaccounts.
                    </p>
                </div>
            </Modal>

            <Modal id="modal-help-whatisextension"
                title="What is Extension?"
                titleIcon="circle-question">
                <div className="mb-10 mt-5">
                    <p>
                        If you’ve ever been to an office looking for someone and the receptionist dialed a short number and then directed you to the person, you probably have an idea of a phone extension. A VoIP phone extension is a short number – usually three, four, or five digits – that a caller can dial to reach an organization’s specific department or employee.
                    </p>
                    <p>
                        You set up and assign phone extension numbers to represent different segments of your team, such as internal lines in conference rooms and departments. As with traditional lines, phone extensions are linked to your business phone numbers and function in the same way. The only difference is that, unlike phone lines, you can use extensions to cover many departments and employees without investing in extra lines.
                    </p>
                    <p>
                        They are also efficient and convenient. If a client needs to track their package, they can dial the short number and immediately get connected to your shipping team. Similarly, users within the same organization can reach one another by dialing the three-digit extensions instead of hunting for the entire 10-digit phone number.
                    </p>
                </div>
            </Modal>

            <Modal id="modal-help-whatisqueue"
                title="What is Queue?"
                titleIcon="circle-question">
                <div className="mb-10 mt-5">
                    <p>
                        A queue is a powerful feature used to manage a high volume of incoming calls by placing callers in a line to wait for the next available representative. This system is particularly useful in customer service settings where multiple callers might be trying to reach support at the same time. Each caller is automatically added to the queue and held there until someone is free to answer their call.
                    </p>
                    <p>
                        Queues allow you to prioritize calls based on various criteria, such as how long a caller has been waiting or the importance of their inquiry. You can also configure announcements that play to the caller while they wait, providing information like estimated wait time or offering alternative contact options such as email or a website visit. This keeps the caller informed and can improve their overall experience despite the wait.
                    </p>
                    <p>
                        Additionally, queues are customizable with features like call-back options, where callers can choose to hang up and receive a call back when it’s their turn, rather than staying on the line. Managers can monitor queue status, track performance metrics like average wait time and call abandonment rates, and adjust settings to optimize call flow and agent efficiency. This level of management helps ensure that resources are used effectively and callers receive the best service possible.
                    </p>
                </div>
            </Modal>

        </div>
    )
}

export default SignUpComplete;