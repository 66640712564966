import { useEffect, useState } from "react";
import FlexRow from "../../../../components/base/FlexRow";
import { NotificationRule } from "../models/NotificationRuleModels";
import { getRuleEvent, getRuleType, getRuleUserType } from "../models/NotificationRuleConstants";
import Icon from "../../../../components/base/Icon";
import { NotificationRuleEvents, NotifTimeTypes } from "../../../../core/Enums";



export default function NotifRuleStatementItem(data: NotificationRule) {
    
    return (
        <FlexRow wrap>
            <span className="me-1 mb-1">
                Send
            </span>
            {
                data.ruleTypes?.map((t, k) => {
                    var rt = getRuleType(t);
                    return (
                        <span key={k} className="badge badge-light-primary me-1 mb-1">
                            <Icon icon={rt.icon} type="solid" className="me-2" />
                            {rt.name}
                        </span>
                    )
                })
            }

            <span className="me-1 mb-1">
                To
            </span>

            {
                data.ruleUserTypes?.map((u, k) => {
                    var usr = getRuleUserType(u);
                    return (
                        <span key={k} className="badge badge-light-primary me-1 mb-1">
                            {usr?.name}
                        </span>
                    )
                })
            }

            <span className="me-1 mb-1">
                When
            </span>

            {
                <span className="badge badge-light-danger me-1 mb-1">
                    {getRuleEvent(data.ruleEvent)?.name}
                </span>
            }

            {
                data.ruleEvent == NotificationRuleEvents.JobStatusChanged &&
                <>
                    <span className="me-1 mb-1">
                        From
                    </span>
                    {
                        data.jobStatusesFrom && data.jobStatusesFrom.length > 0 ? 
                        <>
                            {
                                data.jobStatusesFrom.map((st, k) => 
                                    <span key={k} className="badge badge-secondary me-1 mb-1" style={{
                                        backgroundColor: st.color,
                                        color: "#fff"
                                    }}>
                                        {st.name}
                                    </span>
                                )
                            }
                        </>
                        :
                        <span className="badge badge-secondary me-1 mb-1">
                            Any Status
                        </span>
                    }
                    <span className="me-1 mb-1">
                        To
                    </span>
                    {
                        data.jobStatuses && data.jobStatuses.length > 0 ? 
                        <>
                            {
                                data.jobStatuses.map((st, k) => 
                                    <span key={k} className="badge badge-secondary me-1 mb-1" style={{
                                        backgroundColor: st.color,
                                        color: "#fff"
                                    }}>
                                        {st.name}
                                    </span>
                                )
                            }
                        </>
                        :
                        <span className="badge badge-secondary me-1 mb-1">
                            Any Status
                        </span>
                    }
                </>
            }

            {
                data.ruleEvent == NotificationRuleEvents.JobInStatus &&
                <>
                    {
                        data.jobStatuses?.map((st, k) => 
                            <span key={k} className="badge badge-secondary me-1 mb-1" style={{
                                backgroundColor: st.color,
                                color: "#fff"
                            }}>
                                {st.name}
                            </span>
                        )
                    }
                    <span className="me-1 mb-1">
                        For More Than
                    </span>
                    {
                        data.ruleTimes?.map((t, k) => {
                            return (
                                <span key={k} className="badge badge-light-primary me-1 mb-1">
                                    {t.time} {NotifTimeTypes[t.type!]}
                                </span>
                            )
                        })
                    }
                </>
            }

            {
                data.ruleEvent == NotificationRuleEvents.JobAppointmentStarting &&
                <>
                    {
                        data.ruleTimes?.map((t, k) => {

                            return (
                                <span key={k} className="badge badge-light-primary me-1 mb-1">
                                    {t.time} {NotifTimeTypes[t.type!]}
                                </span>
                            )
                        })
                    }
                </>
            }

        </FlexRow>
    )
}