import React from "react"
import Call from '../../modules/voice/calls/models/Call';
import Environment from '../../env/env';
import Hub from '../../api/Hub';
import Modal from '../base/Modal';
import { useEffect } from 'react';
import Icon from '../base/Icon';
import Avatar from '../base/Avatar';
import { clearGlobalPlayer, useHub } from '../../core/Global';

type Props = {
    selectedCall?: Call
}

function CallQuickView(props: Props) {
    const env = new Environment();
    const hub = useHub();

    

    return (
        <Modal id="modal-call-quickview"
            size="Large"
            title="Call Details">
                <div className="row min-h-200px">
                    <div className="col-7">
                        <div className="row">
                            <div className="col-4">
                                <div className='text-center'>
                                    <Avatar avatarUrl={props.selectedCall?.sourceAgent?.country ? props.selectedCall.sourceAgent.country.flagSquare : props.selectedCall?.sourceAgent?.avatarUrl} width={35} />
                                    {
                                        props.selectedCall?.sourceAgent?.id ?
                                        <>
                                            <div className="fw-bolder fs-6">
                                                {props.selectedCall?.sourceAgent?.name}
                                            </div>
                                            <div className="fs-8">
                                                ({props.selectedCall?.sourceAgent?.number})
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="fw-bolder fs-6">
                                                {props.selectedCall?.sourceAgent?.number}
                                            </div>
                                            {
                                                props.selectedCall?.sourceAgent?.country &&
                                                <div className="fs-8">
                                                    ({props.selectedCall.sourceAgent.country.name})
                                                </div>
                                            }
                                            
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='col-4 text-center pt-5'>
                                {
                                    props.selectedCall?.disPosition == "ANSWERED" ? 
                                    <Icon type="solid" icon="phone-volume" className="text-success" size={14} />
                                    : props.selectedCall?.disPosition == "NO ANSWER" ? 
                                    <Icon type="solid" icon="phone-missed" className="text-danger" size={14} />
                                    : props.selectedCall?.disPosition == "BUSY" ? 
                                    <Icon type="solid" icon="phone-xmark" className="text-danger" size={14} />
                                    :
                                    <Icon type="solid" icon="phone" className="text-gray" size={14} />
                                }
                                <div className="text-center fs-7">
                                    {props.selectedCall?.disPosition}
                                </div>
                                <div className="text-center fs-8 text-gray">
                                    {props.selectedCall?.callDateStr}
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='text-center'>
                                    <Avatar avatarUrl={props.selectedCall?.destinationAgent?.country ? props.selectedCall.destinationAgent.country.flagSquare : props.selectedCall?.destinationAgent?.avatarUrl} width={35} />
                                    {
                                        props.selectedCall?.destinationAgent?.id ?
                                        <>
                                            <div className="fw-bolder fs-6">
                                                {props.selectedCall?.destinationAgent?.name}
                                            </div>
                                            <div className="fs-8">
                                                ({props.selectedCall?.destinationAgent?.number})
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="fw-bolder fs-6">
                                                {props.selectedCall?.destinationAgent?.number}
                                            </div>
                                            {
                                                props.selectedCall?.destinationAgent?.country &&
                                                <div className="fs-8">
                                                    ({props.selectedCall.destinationAgent.country.name})
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            props.selectedCall?.disPosition == "ANSWERED" &&
                            <div className="row mt-10">
                                <div className="col-12 text-center">
                                    <audio  style={{
                                            width: '100%'
                                            }}
                                            onPlay={e=> {
                                                clearGlobalPlayer();
                                            }}
                                            onEnded={e=> {
                                            console.log(e, "end");
                                            }}
                                            src={env.localHubUrl + "/system/calls/recording/"+props.selectedCall.id} controls></audio>
                                </div>
                                <div className="col-12">
                                    <a href={env.localHubUrl + "/system/calls/recording/"+props.selectedCall.id} target="_blank" className="fs-8">
                                        <Icon icon="download" className="me-1" size={12} />
                                        Download 
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-5 border-start">
                        <div className="timeline-label align-items-center mt-4">
                            {
                                props.selectedCall?.events?.map((item, key) => 
                                <div key={key} className="timeline-item align-items-center">
                                    <div className="timeline-label fw-bolder text-gray-800 fs-8">{item.time}</div>
                                    <div className="timeline-badge">
                                        <i className={"fa fa-genderless text-"+item.color+" fs-1"}></i>
                                    </div>
                                    <div className="timeline-content d-flex align-items-center">
                                    {
                                        item.name &&
                                        <>
                                            <div className="symbol symbol-circle symbol-30px overflow-hidden ms-3 me-3">
                                                <div className="symbol-label">
                                                    <img src={item.avatarUrl ?? "/assets/media/avatars/blank.png"} className="w-100" />
                                                </div>
                                            </div>
                                            <div className="fw-bolder text-gray-800 fs-9">
                                                <div>
                                                {item.name}
                                                </div>
                                                <div className="event-text fs-9">
                                                <Icon icon={item.icon} type="solid" className={"me-2 text-" + item.color}/>
                                                {item.text}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        !item.name &&
                                        <span className="fw-bolder text-gray-800 ps-3 fs-8">
                                            {item.text}
                                        </span>
                                    }
                                    
                                    </div>
                                </div>
                                )
                            }
                        </div>
                    </div>
                </div>
        </Modal> 
    )

}

export default CallQuickView;