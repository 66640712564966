import { useEffect, useState } from "react";
import { useHub, useSocket } from "../../core/Global";
import { AgentOverviewTotalCalls } from "../../modules/agents/models/AgentOverviewModel";
import Icon from "../base/Icon";



export default function AccountAgentCallsChart() {

    let socketInterval:NodeJS.Timeout;
    let silentTime:number = 300;
    let start:any,end:any,type:any;

    const hub = useHub();
    const socket = useSocket();
    const [totalCalls, totalCallsSet] = useState<AgentOverviewTotalCalls>({
        answered: 50,
        missed: 10,
        total: 60
    });
    const [totalCallsDateLabel, totalCallsDateLabelSet] = useState<string>("Today");
    const [totalCallsTypeLabel, totalCallsTypeLabelSet] = useState<string>("Incoming");
    const [dateLabel, dateLabelSet] = useState<any>("Today");


    useEffect(() => {

        window.daterange("todaycalls_date", (st:any, en:any, lbl:any) => {
            dateLabelSet(lbl);
            if (lbl == "Custom Range") totalCallsDateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
            else totalCallsDateLabelSet(lbl);
            start = st;
            end = en;
            //fetch();
        });

        window.inlineDropdown("todaycalls_type", ["Incoming", "Outgoing"], tp => {
            type = tp;
            totalCallsTypeLabelSet(tp);
            //fetch();
        });

    }, [])

    useEffect(()=> {
    
        if(totalCalls) window.createCircleChart("chart-total-calls", 
            [totalCalls?.answered ?? 0, totalCalls?.missed ?? 0],
            [window.getBootstrapColor("success"), window.getBootstrapColor("danger")]);
    
      }, [totalCalls]);

    return (
        <div className="card card-flush ">
            <div className="card-header pt-5">
                <div className="card-title d-flex flex-column">
                <div className="d-flex align-items-center">
                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                    50
                    </span>
                </div>
                <span className="text-gray-400 pt-1 fw-bold fs-8">
                    Total Calls
                </span>
                </div>
                <div>
                <div id="todaycalls_date" className="d-flex align-items-center justify-content-end cursor-pointer fs-8">
                    <span>{totalCallsDateLabel}</span>
                    <Icon icon="chevron-down" className="ms-1" size={11} />
                </div>
                <div id="todaycalls_type" className="d-flex align-items-center justify-content-end cursor-pointer fs-8 mt-2">
                    <span>{totalCallsTypeLabel}</span>
                    <Icon icon="chevron-down" className="ms-1" size={11} />
                </div>
                </div>
            </div>
            <div className="card-body pt-2 pb-4 d-flex align-items-center">
                <div className="d-flex flex-center me-5 pt-2">
                    <div id="chart-total-calls" data-kt-size="70" data-kt-line="11"></div>
                </div>
                <div className="d-flex flex-column content-justify-center w-100">
                <div className="d-flex fs-6 fw-bold align-items-center">
                    <div className="bullet w-8px h-6px rounded-2 bg-success me-1"></div>
                    <div className="text-gray-500 flex-grow-1 me-2 fs-7">Answered</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                    {totalCalls?.answered ?? 0}
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center my-3">
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-1"></div>
                    <div className="text-gray-500 flex-grow-1 fs-7 me-2">Missed</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                    {totalCalls?.missed ?? 0}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}