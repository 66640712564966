import { toastSuccess } from "../../../components/base/Toast";
import DropDown from "../../../components/form/DropDown";
import Form from "../../../components/form/Form";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import User from "../models/User";


type Props = {
    userId?: string
    model?: User
}

export default function AccountTimezone(props: Props) {

    const hub = useHub();

    return (
        <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_notifications" aria-expanded="true" aria-controls="kt_account_notifications">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Time Zone</h3>
                </div>
            </div>
            <div className="collapse show">
                <Form className="form"
                    onSubmit={(body, callback) => {
                        hub.Post("/accounts/users/timezone/" + props.userId, body, res => {
                            if(res.success) {
                                toastSuccess("User timezone has been updated successfully!");
                            }
                            if (callback) callback(res);
                        });
                    }}>
                    <div className="card-body border-top px-9 pt-10 pb-4">

                        <div className="mt-10" style={{
                            maxWidth: 1000
                        }}>
                            <DropDown id="drp-update-timezones" name="timezone" label="Timezone" block
                                apiSource={API_ROUTES.Users + "timezones"} defaultValue={props.model?.timeZoneId}
                                onApiSourceFetched={(data) => {
                                    var ofs = window.getClientTimeZoneOffset();
                                    var tz = window.getClientTimeZone()?.toLowerCase();
                                    var tzArr = tz.split("/");
                                    var cityName = tzArr.length > 1 ? tzArr[1] : tzArr[0];
                                    
                                    var id = "";

                                    if (data && data.length > 0) {
                                        for (var i = 0; i < data.length; i++) {
                                            if (data[i].offset == ofs && data[i].name.toLowerCase().indexOf(cityName) > -1) {
                                                id = data[i].id;
                                                break;
                                            }
                                        }

                                        if (id == "") {
                                            for (var i = 0; i < data.length; i++) {
                                                if (data[i].offset == ofs) {
                                                    id = data[i].id;
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    if (id == "") id = "UTC";
                                }} />
                        </div>
                        
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button className="btn btn-primary px-6">Update Timezone</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}