import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setPage, useGlobal } from '../../../core/Global';
import Form from '../../../components/form/Form';


function ConnectedAccounts() {

    const [globalState] = useGlobal();

  return (
    <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Connected Accounts</h3>
          </div>
        </div>
        <Form className="form">
        <div className="card-body border-top p-9">
            <div className="py-2">
              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <img src="/assets/media/svg/brand-logos/google-icon.svg" className="w-30px me-6" alt="" />
                  <div className="d-flex flex-column">
                    <a href="#" className="fs-5 text-dark text-hover-primary fw-bolder">Google</a>
                    <div className="fs-6 fw-bold text-gray-400">Plan properly your workflow</div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-check-solid form-switch">
                    <input className="form-check-input w-45px h-30px" type="checkbox" id="googleswitch" checked />
                    <label className="form-check-label" htmlFor="googleswitch"></label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <img src="/assets/media/svg/brand-logos/facebook-4.svg" className="w-30px me-6" alt="" />
                  <div className="d-flex flex-column">
                    <a href="#" className="fs-5 text-dark text-hover-primary fw-bolder">Facebook</a>
                    <div className="fs-6 fw-bold text-gray-400">Keep eye on on your Repositories</div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-check-solid form-switch">
                    <input className="form-check-input w-45px h-30px" type="checkbox" id="githubswitch" checked />
                    <label className="form-check-label" htmlFor="githubswitch"></label>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <img src="/assets/media/svg/brand-logos/apple-black.svg" className="w-30px me-6" alt="" />
                  <div className="d-flex flex-column">
                    <a href="#" className="fs-5 text-dark text-hover-primary fw-bolder">Apple</a>
                    <div className="fs-6 fw-bold text-gray-400">Integrate Projects Discussions</div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-check-solid form-switch">
                    <input className="form-check-input w-45px h-30px" type="checkbox" id="slackswitch" />
                    <label className="form-check-label" htmlFor="slackswitch"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
    </div>
  );
}

export default ConnectedAccounts;
