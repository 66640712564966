import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/jobtypes.json";
import sortingProps from "../sorting/jobtypes.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { ListItem } from "../../../../core/Types";
import Section from "../../../../components/base/Section";
import TextBox from "../../../../components/form/TextBox";
import DropDown from "../../../../components/form/DropDown";
import JobType from "../models/JobType";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";

function JobTypesList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<JobType>();

  const [refresh, refreshSet] = useState<boolean>();
  const [clearForms, clearFormsSet] = useState<boolean>();

  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);
  const [selectedOwnerId, selectedOwnerIdSet] = useState<string>();

  const edit = (data: JobType) => {

    groupsRouteParamsSet([{
      name: "userId",
      value: data.ownerUserId
    }])

    selectedItemSet(data);
    editModalSet(true);
  }


  const NameItem = (data: JobType) => (
    <FlexRow>
      <div>
        {data.nameWithGroup}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const ActiveJobsCount = (data: JobType) => (
    <div>
      {
        data.activeJobsCount! > 0 ?
          <a target="_blank" href={"/crm/jobs?jobTypes=" + data.id}>
            {data.activeJobsCount}
          </a>
          :
          <div>
            {data.activeJobsCount}
          </div>
      }

    </div>
  )

  const GroupItem = (data: any) => (
    <FlexRow>
      {
        data.jobTypeGroupName ?
          <div>
            {data.jobTypeGroupName}
          </div>
          :
          <div className="fst-italic text-muted">
            [Ungrouped]
          </div>
      }
    </FlexRow>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "Unassigned"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: JobType) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("Job Types", "CRM", "Base Data");

  }, [])

  useEffect(() => {
    if (user?.ownerUserId) {
      groupsRouteParamsSet([{
        name: "userId",
        value: user.ownerUserId
      }])
    }
    else {
    }
  }, [user])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  useEffect(() => {
    if (clearForms) {
      setTimeout(() => {
        clearFormsSet(false);
      }, 1000);
    }
  }, [clearForms])

  return (
    <Layout wide>

      <Section fluid>
        <DataTable
          objectName="Job Type"
          apiRoute={API_ROUTES.JobTypes}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          addCustom={() => createModalSet(true)}
          edit delete={hasRole("DeleteJobTypes")} editCustom={edit}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <DataTableActiveToggle route={API_ROUTES.JobTypes} />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Group">
            <GroupItem />
          </DataTableField>
          <DataTableField title="Active Jobs">
            <ActiveJobsCount />
          </DataTableField>
          <DataTableField title="User" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>

      {
        createModal &&
        <Modal id="modal-add-jobtype"
          title="Create Job Type"
          buttonText="Add Job Type"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          //onOpen={() => $("#txt-groupname").focus()}
          onSubmit={(body, callback) => {

            if (user?.isSuperAdmin) {
              if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
                window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-edit-jobtypegroup");
                if (callback) callback();
                return;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid name!");
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.JobTypes, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Job type(s) has been added successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bolder">
                  Assign to
                  <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer"
                  apiUrl={API_ROUTES.TagifyCustomers} single block selectedId={selectedOwnerId}
                  onSelect={val => {
                    groupsRouteParamsSet([{
                      name: "userId",
                      value: val
                    }])
                  }}
                  onRemoveItem={item => {
                    groupsRouteParamsSet([]);
                  }} />
              </>
            }

            {/* <Tagify name="group" id="drp-groupId" placeholder="Select job type group" routeParams={groupsRouteParams}
                  apiUrl={API_ROUTES.TagifyJobTypeGroups} single block freeTags label="Group" labelUp
                  onSelectItem={item => {
                    selectedOwnerIdSet(item?.ownerUserId);
                  }} /> */}

            <DropDown id="drp-typegroups" name="group" label="Job Type Group" labelUp block parentModal="modal-add-jobtype"
              apiSource={API_ROUTES.TagifyJobTypeGroups} dataText="title" dataValue="value" />

            <Tagify name="name" id="drp-jobtypenames" placeholder="E.g. Plumbing, Keys, Safe, etc" block freeTags clear={clearForms}
              label="Type Name(s)" labelUp />

          </div>
        </Modal>
      }


      {
        editModal && selectedItem &&
        <Modal id="modal-edit-jobtype"
          title="Edit Job Type"
          buttonText="Update Job Type"
          staticBackdrop open visible
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onOpen={() => $("#txt-edit-jobtype").focus()}
          onSubmit={(body, callback) => {


            // if (!body.groupId || body.groupId == "" || body.groupId.length < 2) {
            //   window.showFormError("groupId", "Please select a job type group!");
            //   if (callback) callback();
            //   return;
            // }

            if (!body.newname || body.newname == "" || body.newname.length < 2) {
              window.showFormError("newname", "Please write a valid name!");
              if (callback) callback();
              return;
            }

            hub.Put(API_ROUTES.JobTypes + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Job type has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bolder">
                  Assign to
                  <span id="newownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="newownerUserId" id="drp-newownerUserId" placeholder="Select a Customer"
                  apiUrl={API_ROUTES.TagifyCustomers} selectedId={selectedItem?.ownerUserId} single block
                  onSelect={val => {
                    groupsRouteParamsSet([{
                      name: "userId",
                      value: val
                    }])
                  }} />
              </>
            }

            {/* <Tagify name="groupId" id="drp-edit-groupId" placeholder="Select a Group" label="Group" labelUp routeParams={groupsRouteParams}
                    apiUrl={API_ROUTES.TagifyJobTypeGroups} selectedId={selectedItem.jobTypeGroupId} block /> */}

            <DropDown name="groupId" id="drp-edit-groupId" label="Group" labelUp block defaultValue={selectedItem.jobTypeGroupId}
              apiSource={API_ROUTES.JobTypeGroups + "all"} parentModal="modal-edit-jobtype" />

            <TextBox id="txt-edit-jobtype" name="newname" label="Type Name" labelUp block value={selectedItem.name} />

          </div>
        </Modal>
      }




    </Layout>
  );
}

export default JobTypesList;
