import React, { useEffect, useState } from "react";
import $ from "jquery";
import Icon from "../base/Icon";
import FlexRow from "../base/FlexRow";
import DateItem, { RangeDateItem } from "../../models/DateItem";
import { FontAwesomeIcons } from "src/core/Types";

export interface DatePickItem {
    date: any
    endDate: any
    value: string
}

interface DatePickerProps {
    id: string
    label?: string
    labelUp?: boolean
    block?: boolean
    name?: string
    small?: boolean
    solid?: boolean
    reset?: boolean
    autoFocus?: boolean
    selectedDate?: any
    selectedDateItem?: DateItem
    selectedDateItems?: DateItem[]
    selectedRangeDates?: RangeDateItem[]
    minDate?: string
    maxDate?: string
    empty?: boolean
    marginBottom?: number
    parentId?: string
    dateType?: "birthdate" | "range" | "single"
    timePicker?: boolean
    inlineMultipleDates?: boolean
    placeholder?: string
    icon?: FontAwesomeIcons
    drops?: "down" | "up" | "auto"
    onPick?: (e: DatePickItem) => void
    onClear?: () => void
    onRangeChanged?: (r: RangeDateItem) => void
}



export default function DatePicker(props:DatePickerProps) {
    
    const [selectedDate, selectedDateSet] = useState<any>();
    const [selectedEndDate, selectedEndDateSet] = useState<any>();
    const [selectedDateString, selectedDateStringSet] = useState<string>("");
    const [selectedStartDateString, selectedStartDateStringSet] = useState<string>("");
    const [selectedEndDateString, selectedEndDateStringSet] = useState<string>("");
    const [dp, dpSet] = useState<any>();

    const [pickedItem, pickedItemSet] = useState<DatePickItem>();
    const [multipleSelectedDates, multipleSelectedDatesSet] = useState<DatePickItem[]>([]);
    
    const [multipeExistsErr, multipeExistsErrSet] = useState<number>(-1);
    useEffect(() => {
        if(multipeExistsErr > -1) {
            setTimeout(() => {
                multipeExistsErrSet(-1);
            }, 500);
        }
    }, [multipeExistsErr])

    const className = () => {
        var cls = "form-control form-inputs kt_daterangepicker";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        if(props.autoFocus) cls += " auto-focus";
        return cls;
    }

    const inputGroupClassName = () => {
        var cls = "input-group";
        if (props.small) cls += " input-group-sm";
        if (props.solid) cls += " input-group-solid";
        return cls;
    }

    useEffect(()=> {
        
        var opt: any = {};

        if (props.minDate) opt.minDate = props.minDate;
        if (props.maxDate) opt.maxDate = props.maxDate;
        if (props.drops) opt.drops = props.drops;
        if (props.timePicker) {
            opt.timePicker = true;
        }
        if (!props.dateType || props.dateType == "birthdate" || props.dateType == "single") {
            opt.autoApply = true;
            opt.singleDatePicker = true;
        } 
        else {
            opt.autoApply = false;
            opt.singleDatePicker = false;
        }

        var d = window.datepicker(props.id, opt);

        dpSet(d);
        
        //if (props.onDatePicked) props.onDatePicked(val, date);
        //bindedSet(true);

    }, []);

    useEffect(() => {
        if (dp) {
            dp.on('apply.daterangepicker',function(e:any, p:any) {
                
                var dateLabel = p.startDate.format('DD/MM/YYYY');
                if (props.timePicker) dateLabel = p.startDate.format('DD/MM/YYYY HH:mm');
                if (props.dateType == "range") {
                    dateLabel += " - ";
                    if(props.timePicker) dateLabel += p.endDate.format('DD/MM/YYYY HH:mm');
                    else dateLabel += p.endDate.format('DD/MM/YYYY');
                }

                var pickItem:DatePickItem = {
                    date: p.startDate,
                    endDate: p.endDate,
                    value: dateLabel   
                }

                if (props.dateType == "range") {
                    selectedStartDateStringSet(p.startDate.format('DD/MM/YYYY HH:mm'));
                    selectedEndDateStringSet(p.endDate.format('DD/MM/YYYY HH:mm'));
                }
                selectedDateStringSet(dateLabel);

                pickedItemSet(pickItem);                
                
            });
        }
    }, [dp])

    useEffect(() => {
        if (pickedItem) {

            if (props.inlineMultipleDates) {
                var arr = multipleSelectedDates ?? [];
                var exists = -1;
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].value == pickedItem.value) {

                        exists = i;
                        break;
                    }   
                }

                if (exists > -1) {
                    multipeExistsErrSet(exists);
                }
                else {
                    arr.push(pickedItem);
                    multipleSelectedDatesSet(a=> [...arr]);
                }
            }

            if (props.onPick) props.onPick(pickedItem)

            if (props.inlineMultipleDates) {
                dp.data('daterangepicker').setStartDate(window.now().hours(0).minutes(0).seconds(0).milliseconds(0));
                dp.data('daterangepicker').setEndDate(window.now().hours(0).minutes(0).seconds(0).milliseconds(0));
            }

            
        }
    }, [pickedItem])

    useEffect(() => {
        if (props.selectedDateItems && props.inlineMultipleDates) {
            var arr = multipleSelectedDates ?? [];
            props.selectedDateItems.forEach(s => {
                arr.push({
                    date: window.parseToDate(s.text ?? ""),
                    endDate: window.now(),
                    value: s.text ?? ""
                });
            });

            multipleSelectedDatesSet(a=> [...arr]);
        }
    }, [props.selectedDateItems, props.inlineMultipleDates])

    useEffect(() => {
        if (props.selectedRangeDates && props.inlineMultipleDates && props.dateType == "range") {
            var arr = multipleSelectedDates ?? [];
            props.selectedRangeDates.forEach(s => {
                arr.push({
                    date: window.parseToDateTime(s.startDate?.text ?? ""),
                    endDate: window.parseToDateTime(s.endDate?.text ?? ""),
                    value: s.text ?? ""
                });
            });
            multipleSelectedDatesSet(a=> [...arr]);
        }
    }, [props.selectedRangeDates, props.inlineMultipleDates])


    useEffect(() => {
        if (props.minDate && dp) {
            dp.data('daterangepicker').minDate = props.minDate;
        }
    }, [props.minDate, dp])

    useEffect(() => {

        if (dp && props.selectedDateItem?.text) {
            selectedDateStringSet(props.selectedDateItem.text);
            selectedDateSet(window.parseToDate(props.selectedDateItem.text));
        }
        else if (dp && props.selectedDate) {
            var datestr = typeof(props.selectedDate) == "string" ? props.selectedDate : props.selectedDate.format("DD/MM/YYYY");
            var dateobj = typeof(props.selectedDate) == "string" ? window.parseToDate(props.selectedDate) : props.selectedDate;
            selectedDateStringSet(datestr);
            selectedDateSet(dateobj);   
        }
        else {
            selectedDateSet(window.now().hours(0).minutes(0).seconds(0).milliseconds(0));
            selectedDateStringSet("");
        }
    }, [props.selectedDate,props.selectedDateItem, dp]);

    useEffect(() => {
        if (dp) {
            dp.data('daterangepicker').setStartDate(selectedDate);
            dp.data('daterangepicker').setEndDate(selectedDate);
        }
    }, [selectedDate, dp])

    useEffect(() => {
        if (props.reset) {
            selectedDateSet(window.now());
            selectedDateStringSet("");
            //window.datepickerClear(props.id);
        }
    }, [props.reset])

    useEffect(() => {
        window.hideFormError(props.name, props.parentId);
    }, [selectedDateString])

    return (
        <>
        {
            props.inlineMultipleDates ? 
            <FlexRow className={"mb-" + (props.marginBottom ?? 5)} wrap>
                {
                    multipleSelectedDates.map((d, k) => 
                        <span key={k} className={"btn btn-outline badge-multiple-date me-1 mb-1" + (multipeExistsErr == k ? " badge-multiple-date-blink" : " badge-multiple-date-blink-off")}>
                            <span className="fs-9 me-2">
                                {d.value}
                            </span>
                            <a href="#" className="text-dark"
                                onClick={e=> {
                                    e.preventDefault();
                                    var arr = multipleSelectedDates ?? [];
                                    arr.splice(k, 1);
                                    multipleSelectedDatesSet(a=> [...arr]);
                                }}>
                                <Icon icon="times" size={11} />
                            </a>

                            {
                                props.dateType == "range" ? 
                                <>
                                    <input type="hidden" name={(props.name ?? "date") + "Start" + k} value={d.date.format("DD/MM/YYYY HH:mm")} />
                                    <input type="hidden" name={(props.name ?? "date") + "End" + k} value={d.endDate.format("DD/MM/YYYY HH:mm")} />
                                </>
                                :
                                <input type="hidden" name={(props.name ?? "date") + k} value={d.date.format("DD/MM/YYYY")} />
                            }

                            
                        </span>
                    )
                }
                <span id={props.id} className={"btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark btn-multiple-date "}>
                    <Icon icon="plus" size={10} />
                    <span className="ms-1 fs-9 fw-bold">
                        Add Date
                    </span>
                </span>
                
            </FlexRow>
            :
            <div className={"row align-items-center mb-" + (props.marginBottom ?? 5)}>
                {
                    props.label &&
                    <label className={"col col-12 " + (props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2")) + " fs-7"} htmlFor={props.id}>
                        {props.label}
                        {
                            props.labelUp && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                        }
                    </label>
                }
                
                <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                    <div className={(props.icon) ? inputGroupClassName() : ""} style={{
                        position:"relative"
                    }}>
                        {
                            props.icon && 
                            <span className="input-group-text">
                                <Icon icon={props.icon} />
                            </span>
                        }
                        <input
                            id={props.id}
                            name={props.name}
                            type="text" 
                            autoComplete="one-time-code"
                            readOnly
                            placeholder={props.placeholder}
                            value={selectedDateString}
                            className={className()}
                        />

                        {
                            selectedDateString && selectedDateString != "" && 
                            <a href="#" style={{
                                position:"absolute",
                                right: 16,
                                top: "25%"
                            }} onClick={e=> {
                                e.preventDefault();
                                selectedDateSet(undefined);
                                selectedDateStringSet("");
                                if (props.onClear) props.onClear();
                            }}>
                                <Icon icon="times" color="#5e6278" size={14} />
                            </a>
                        }

                        <input type="hidden" name={props.name + "Start"} value={selectedStartDateString} />
                        <input type="hidden" name={props.name + "End"} value={selectedEndDateString} />

                    </div>
                </div>

                {
                    !props.labelUp &&
                    <div className="col col-md-4">
                        <span id={props.name + "-err"} className="small text-danger mx-2 form-error"></span>
                    </div>
                }

            </div>
        }
        
        </>
    )
}