import { useEffect, useState } from "react";
import Job from "../../models/Job";
import Modal from "../../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import Icon from "../../../../../components/base/Icon";
import TextArea from "../../../../../components/form/TextArea";
import JobStatus from "../../../base/models/JobStatus";


interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobUpdateStatusModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [statuses, statusesSet] = useState<JobStatus[]>([]);
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();
    useEffect(() => selectedStatusSet(job?.jobStatus), [job]);


    const fetchStatuses = () => {
        hub.Get(API_ROUTES.JobStatuses + "all", res => {
            if (res.success && res.data) statusesSet(res.data);
        })
    }
    useEffect(fetchStatuses, []);


    return (
        <>

            <Modal id="modal-update-status"
                title="Status"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                buttonText="Update Status"
                buttonColor="primary"
                visible open
                onOpen={() => {
                    window.KTMenuCreate();
                }}
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    window.hideFormErrors();

                    if (selectedStatus?.noteRequired && (body.note == null || body.note == "")) {
                        window.showFormError("note", "Note is required for this status!", true, "modal-update-status");
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "jobstatus", {
                        ...body,
                        id: job?.id,
                        status: selectedStatus?.id
                    }, res => {
                        if (callback) callback(res);
                    });

                }}>
                <div>
                    <div className={"row align-items-center mb-5"}>
                        <label className={"col col-md-3 fw-bold fs-7"}>
                            Status
                        </label>
                        <div className="col col-md-9">
                            <button type="button" className="btn btn-bg-primary w-100 text-start btn-color-white btn-sm pe-0 ps-3"
                                style={{
                                    backgroundColor: selectedStatus?.color
                                }}
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-start">
                                {selectedStatus?.name}
                                <span className="svg-icon svg-icon-5 rotate-180 float-end" style={{ marginTop: 2 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                                    </svg>
                                </span>
                            </button>
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                {
                                    statuses.map((item, key) =>
                                        <div key={key} className="menu-item">
                                            <a href="#" className="menu-link px-3" onClick={e => {
                                                e.preventDefault();
                                                selectedStatusSet(item);
                                            }}>
                                                <Icon icon="circle" type="solid" className="me-2" color={item.color} />
                                                {item.name}
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <TextArea label="Note" block name="note" rows={3} />

                </div>
            </Modal>

        </>
    )

}