import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { TagifyDataItem } from "../../../models/TagifyOptions";
import FlexRow from "../../../components/base/FlexRow";
import DropDown from "../../../components/form/DropDown";
import DataItem from "../../../models/DataItem";
import { setChatList, useHub } from "../../../core/Global";
import TextArea from "../../../components/form/TextArea";
import { useNavigate } from "react-router-dom";


interface IProps {

    onClose: () => void
}
export default function NewChatModal(props: IProps) {

    const hub = useHub()
    const navigate = useNavigate()

    const [selectedContact, selectedContactSet] = useState<TagifyDataItem>();

    const [techs, techsSet] = useState<DataItem[]>([]);
    const [jobs, jobsSet] = useState<DataItem[]>([]);

    useEffect(() => {
        hub.Get(API_ROUTES.Chat + "recipients/techs", res => techsSet(res.data))
    }, [])


    return (
        <Modal id="modal-start-newchat"
            open visible staticBackdrop
            title="New Chat"
            buttonText="Start New Chat"
            onClose={props.onClose}
            onSubmit={(body, callback) => {


                hub.Post(API_ROUTES.ChatStart, body, res => {
                    if (res.success) {
                        navigate("/chat/" + res.data);
                    }
                    else if (res.field) {
                        window.formError(res.field, res.message ?? "", "modal-start-newchat");
                    }

                    callback!(res);
                })


            }}>

            <div>

                <DropDown id="drp-startchat-from" name="from" label="From" labelUp block deselect={false} autoSelectFirstItem
                    apiSource={API_ROUTES.Chat + "fromnumbers"} dataValue="id" dataText="name" />

                <Tagify id="tagify-startchat-contact" key="tagify-startchat-contact"
                    name="to" label="To" labelUp block liveSearch freeTags
                    placeholder="Search phone number, technician, job, contact..."
                    apiUrl={API_ROUTES.TagifyChatContacts} />

                <TextArea name="message" label="Message" labelUp block placeholder="Write a message..." rows={6} />

            </div>
        </Modal>
    )
}