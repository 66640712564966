import { useEffect, useState } from "react";
import Modal from "../../../../../components/base/Modal";
import Job from "../../models/Job";
import JobAttachment from "../../models/JobAttachment";
import { useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import JobAttachments from "../JobAttachments";

interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobAttachmentsModal(props: IProps) {

    const hub = useHub();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [selectedAttachment, selectedAttachmentSet] = useState<JobAttachment>();
    const [attachmentsRefresh, attachmentsRefreshSet] = useState<boolean>(false);

    useEffect(() => {
        if (job) {

            hub.Post(API_ROUTES.Jobs + "seenattachments", {
                id: job.id
            }, res => {

            });
        }
    }, [job])

    useEffect(() => {
        if (attachmentsRefresh) {
            setTimeout(() => {
                attachmentsRefreshSet(false);
            }, 1000);
        }
    }, [attachmentsRefresh])

    return (
        <>
            <Modal id="modal-job-attachments"
                title="Attachments"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                noFooter noForm staticBackdrop visible open
                size="Large"
                onClose={props.onClose}>
                <div>
                    {
                        job && <JobAttachments jobId={job.id} onSelectAttachment={item => selectedAttachmentSet(item)} refresh={attachmentsRefresh} />
                    }
                </div>
            </Modal>

            <Modal id="modal-job-attachments-delete"
                title="Delete Attachment"
                secondModal
                buttonText="Confirm & Delete"
                buttonColor="danger"
                onConfirm={callback => {

                    hub.Delete(API_ROUTES.Jobs + "attachments/" + selectedAttachment?.id,
                        res => {
                            if (res.success) {
                                attachmentsRefreshSet(true);
                                callback();
                            }
                        });

                }}>
                <div>
                    <p>
                        Are you sure you want to delete this attachment file?
                    </p>
                    <FlexRow className="px-6 py-6 bg-light">
                        <Icon icon={selectedAttachment?.fileIcon} color={selectedAttachment?.fileIconColor} type="solid" className="me-3" size={20} />
                        <div style={{
                            maxWidth: "80%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}>{selectedAttachment?.fileName}</div>
                    </FlexRow>
                </div>
            </Modal>

        </>
    )
}