import { useEffect, useState } from "react";
import Div from "../../../../components/base/Div";
import Icon from "../../../../components/base/Icon";
import Indicator from "../../../../components/base/Indicator";
import Modal from "../../../../components/base/Modal";
import { InvoiceStatuses } from "../../../../core/Enums";
import { getUserId, useEnvironment, useHub } from "../../../../core/Global";
import Invoice from "../../models/Invoice";
import PaymentMethod from "../../../system/settings/models/PaymentMethod";
import PaymentCardView from "./PaymentCardView";

function ProfileInvoices() {

    const hub = useHub();
    const env = useEnvironment();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Invoice[]>([]);

    const [previewInvoice, previewInvoiceSet] = useState<boolean>(false);
    const [selectedItem, selectedItemSet] = useState<Invoice>();

    const [paymentMethodFetching, paymentMethodFetchingSet] = useState<boolean>(false);
    const [paymentMethod, paymentMethodSet] = useState<PaymentMethod>();

    const fetch = () => {
        hub.Get("/accounts/profile/invoices", res => {
            modelSet(res.data);
            fetchingSet(false);
            fetchedSet(true);
        });
    }

    useEffect(() => {

        fetch();

    }, [])



    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-5">
                        <div className="card-header border-0 cursor-pointer">
                            <div className="card-title m-0">
                                <h3 className="fw-bolder m-0">Invoices</h3>
                            </div>
                            <div className="card-toolbar m-0">
                                {/* <ul className="nav nav-stretch nav-line-tabs border-transparent" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a id="kt_billing_6months_tab" className="nav-link fs-5 fw-bold me-3 active" data-bs-toggle="tab" role="tab" href="#kt_billing_months">Month</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a id="kt_billing_1year_tab" className="nav-link fs-5 fw-bold me-3" data-bs-toggle="tab" role="tab" href="#kt_billing_year">Year</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a id="kt_billing_alltime_tab" className="nav-link fs-5 fw-bold" data-bs-toggle="tab" role="tab" href="#kt_billing_all">All Time</a>
                            </li>
                        </ul> */}
                            </div>
                        </div>
                        <div className="card-body p-0" style={{ minHeight: 300 }}>
                            <Div visible={fetching} className="my-20">
                                <Indicator />
                            </Div>
                            <Div visible={fetched} className="table-responsive">
                                <table className="table table-row-bordered align-middle gy-4 gs-9">
                                    <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
                                        <tr>
                                            <td className="min-w-100px">Status</td>
                                            <td className="min-w-150px">Date</td>
                                            <td className="min-w-150px">Number</td>
                                            <td className="min-w-100px">Total</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-bold text-gray-800">
                                        {
                                            model.map((item, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        {
                                                            item.invoiceStatus == InvoiceStatuses.Paid &&
                                                            <Icon icon="check-circle" size={20} className="text-success" title="Paid" />
                                                        }

                                                        {
                                                            item.invoiceStatus == InvoiceStatuses.Pending &&
                                                            <Icon icon="circle-exclamation" size={20} className="text-warning" title="Pending" />
                                                        }

                                                        {
                                                            item.invoiceStatus == InvoiceStatuses.Failed &&
                                                            <Icon icon="circle-exclamation" size={20} className="text-danger" title="Failed" />
                                                        }

                                                    </td>
                                                    <td>
                                                        <div className="fw-bold fs-6">
                                                            {item.date}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="fw-bold fs-6">
                                                            {item.invoiceNumber}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="fw-bold fs-6">
                                                            {item.total}$
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        {
                                                            item.invoiceStatus != InvoiceStatuses.Failed &&
                                                            <>
                                                                <a href={env.apiUrl + "/base/public/customer/invoice/download/" + item?.id} target="_blank"
                                                                    className="btn btn-sm btn-light btn-active-light-primary me-2">PDF</a>
                                                                <a href="#" className="btn btn-sm btn-light btn-active-light-primary"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        selectedItemSet(item);
                                                                        previewInvoiceSet(true);
                                                                    }}>View</a>
                                                            </>
                                                        }
                                                        {
                                                            item.invoiceStatus == InvoiceStatuses.Pending &&
                                                            <a href="#" className="btn btn-sm btn-primary ms-2"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    window.openModal("modal-invoice-pay");
                                                                    selectedItemSet(item);
                                                                    paymentMethodFetchingSet(true);
                                                                    hub.Get("/accounts/profile/paymentmethod/default", res => {
                                                                        paymentMethodSet(res.data);
                                                                        paymentMethodFetchingSet(false);
                                                                    })
                                                                }}>Pay</a>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </Div>
                        </div>

                    </div>

                </div>
            </div>

            {
                previewInvoice && selectedItem &&
                <Modal id="modal-invoice"
                    title={"Invoice #" + selectedItem?.invoiceNumber}
                    size="XLarge" fitHeight
                    open visible
                    buttonText="Download PDF"
                    onClose={() => {
                        previewInvoiceSet(false);
                        selectedItemSet(undefined);
                    }}
                    confirmLink={env.apiUrl + "/base/public/customer/invoice/download/" + selectedItem?.id}>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        position: "relative"
                    }}>
                        <iframe src={env.apiUrl + "/base/public/customer/invoice/preview/" + selectedItem.id}
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0
                            }}></iframe>
                    </div>
                </Modal>
            }



            <Modal id="modal-invoice-pay"
                title="Pay Invoice"
                buttonText="Confirm & Pay"
                onConfirm={callback => {
                    hub.Post("/billings/payments/invoice/pay/" + selectedItem?.id, {}, res => {
                        if (res.success) {
                            document.location.href = res.data;
                        }
                        else {
                            callback(true);
                        }
                    });
                }}>
                <div>
                    {
                        paymentMethodFetching && <Indicator />
                    }
                    {
                        !paymentMethodFetching && paymentMethod &&
                        <div>
                            <p className="mb-4">
                                Invoice will be paid by this payment method:
                            </p>
                            <div>
                                <PaymentCardView item={paymentMethod} />
                            </div>
                        </div>
                    }
                    {
                        !paymentMethodFetching && !paymentMethod &&
                        <div>
                            <p>
                                You don't have any payment method to pay this invoice!
                            </p>
                        </div>
                    }
                </div>

            </Modal>
        </>
    );
}

export default ProfileInvoices;