import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../components/data/DataTable";
import { API_ROUTES } from '../../../core/Constants';
import { getUser, setPage, useHub } from '../../../core/Global';
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import Icon from "../../../components/base/Icon";
import FlexRow from "../../../components/base/FlexRow";
import Tagify from "../../../components/tagify/Tagify";
import { ListItem } from "../../../core/Types";
import TextBox from "../../../components/form/TextBox";
import DropDown, { DropDownOption } from "../../../components/form/DropDown";
import { DayOfWeek, ShiftIntervals } from "../../../core/Enums";
import DatePicker from "../../../components/form/DatePicker";
import ColorPicker from "../../../components/form/ColorPicker";
import Shift from "../models/Shift";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import TimePicker from "../../../components/form/TimePicker";
import ShiftType from "../models/ShiftType";
import Section from "../../../components/base/Section";
import ShiftTypeForm from "../components/ShiftTypeForm";

function ShiftsTypes() {

  const hub = useHub();
  const user = getUser();

  const [selectedItem, selectedItemSet] = useState<ShiftType>();
  const [refresh, refreshSet] = useState<boolean>();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);


  const AvtiveToggle = (data: any) => (
    <div>
      <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
        <input className="form-check-input" type="checkbox" defaultChecked={data.isActive}
          onChange={e => {
            hub.Post(API_ROUTES.Shifts + "activetoggle", {
              id: data.id,
              active: e.target.checked ? "1" : "0"
            }, res => {

            })
          }} />
      </label>
    </div>
  )

  const NameItem = (data: any) => (
    <FlexRow>
      <ColorPicker id={"color-picker-" + data.id} color={data.color} className="me-2"
        onSelect={color => {
          hub.Post(API_ROUTES.ShiftsTypes + "color", {
            id: data.id,
            color: color
          }, res => {

          })
        }} />
      <FlexRow onClick={() => {
        selectedItemSet(data)
        editModalSet(true)
      }}>
        {data.name}
      </FlexRow>
    </FlexRow>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "Unassigned"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const DepsItem = (data: ShiftType) => (
    <FlexRow>
      {
        (!data.departments || data.departments.length == 0) &&
        <div className="badge badge-secondary me-2">All Departments</div>
      }
      {
        data.departments?.map((item, key) =>
          <div key={key}
            className="badge badge-success me-2"
            style={{
              backgroundColor: item.color
            }}>{item.name}</div>
        )
      }
    </FlexRow>
  )

  const ShiftTimeItem = (data: ShiftType) => (
    <div>
      {data.startTime} - {data.endTime}
    </div>
  )

  const BreakTimeItem = (data: ShiftType) => (
    <div>
      {
        data.breakTime ?
          <div>
            {data.breakTimeStart} - {data.breakTimeEnd}
          </div>
          :
          <div>-</div>
      }
    </div>
  )

  useEffect(() => {
    setPage("Shift Types", "Account", "Shifts");

  }, [])




  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])




  return (
    <Layout wide>

      <Section fluid>
        <DataTable
          objectName="Shift Type"
          apiRoute={API_ROUTES.ShiftsTypes}
          edit delete ascending
          editCustom={s => {
            selectedItemSet(s)
            editModalSet(true)
          }}
          addCustom={() => createModalSet(true)}
          refresh={refresh}>

          <DataTableField title="Name">
            <NameItem />
          </DataTableField>

          <DataTableField title="Departments">
            <DepsItem />
          </DataTableField>

          <DataTableField title="Shift Time">
            <ShiftTimeItem />
          </DataTableField>

          <DataTableField title="Break Time">
            <BreakTimeItem />
          </DataTableField>

          <DataTableField title="User" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

        </DataTable>
      </Section>

      {
        createModal &&
        <ShiftTypeForm
          onClose={() => createModalSet(false)}
          onSave={(() => {
            refreshSet(true);
            toastSuccess("Shift type has been added successfully!");
          })} />
      }


      {/* <Modal id="modal-edit-shift"
        title="Edit Shift"
        buttonText="Update Shift"
        staticBackdrop
        size="Large"
        visible={editModal} open={editModal}
        onClose={() => {
          editModalSet(false);
          defaultEndTimeSet(undefined);
          editBreakTimeSet(false);
        }}
        onSubmit={(body, callback) => {

          window.hideFormErrors();
          var valid = true;

          if (!body.name || body.name == "" || body.name.length < 2) {
            window.showFormError("name", "Please write a valid name!", true, "modal-edit-shift");
            valid = false;
          }

          if (!startTimeValid) {
            window.showFormError("startTime", "Shift start time is not valid!", true, "modal-edit-shift", true);
            valid = false;
          }

          if (!endTimeValid) {
            window.showFormError("endTime", "Shift end time is not valid!", true, "modal-edit-shift", true);
            valid = false;
          }

          if (addBreakTime) {
            if (!startBreakTimeValid) {
              window.showFormError("breakTimeStart", "Break start time is not valid!", true, "modal-edit-shift", true);
              valid = false;
            }
            if (!endTimeBreakValid) {
              window.showFormError("breakTimeEnd", "Break end time is not valid!", true, "modal-edit-shift", true);
              valid = false;
            }
          }

          if (!valid) {
            if (callback) callback();
            return;
          }


          hub.Put(API_ROUTES.ShiftsTypes + editId, body, res => {

            if (res.success) {
              refreshSet(true);
              toastSuccess("Shift has been updated successfully!");
            }

            if (callback) callback(res);
          })

        }}>
        <div>
          <FlexRow className="mb-5">
            <ColorPicker id="color-edit-shift" width={30} className="me-2" name="color" color={editModel?.color} />
            <div className="flex-grow-1">
              <TextBox id="txt-editshift-name" name="name" block marginBottom={0}
                defaultValue={editModel?.name}
                inlineStyle mediumText noTextError placeholder="Shift Name" />
            </div>
          </FlexRow>

          <div className="fs-7 fw-bold">
            Department(s)
            <span className="small text-muted ms-3">
              <Icon icon="info-circle" type="solid" className="me-1" size={10} />
              Leave it blank to use the shift type for all departments!
            </span>
          </div>
          <Tagify name="departmentIds" id="drp-editshift-departmentIds" placeholder="Select Department(s)"
            apiUrl={API_ROUTES.TagifyDepartments} block routeParams={editDepIdsRouteParams} selectedIds={editModel?.departmentIds} />

          <div className="row">
            <div className="col-sm-6">
              <TimePicker id="time-editshift-startTime" name="startTime" time={editModel?.startTimeTotalMinute} timeInput label="Start Time" labelUp
                onTimePicked={t => {
                  minEndTimeSet(t.time);
                  minBreakStartSet(t.time);
                  minEndTimeSet(t.time + 30);
                  startTimeValidSet(true);
                }} />
            </div>
            <div className="col-sm-6">
              <TimePicker id="time-editshift-endTime" name="endTime" time={editModel?.endTimeTotalMinute} timeIsNextDay={(editModel?.startTimeTotalMinute ?? 0) > (editModel?.endTimeTotalMinute ?? 0)}
                timeInput label="End Time" labelUp min={minEndTime} nextDay
                onTimePicked={t => {
                  maxBreakStartSet(t.time - 30);
                  maxBreakEndSet(t.time);
                  if (t.time >= minEndTime || t.nextDay) endTimeValidSet(true);
                  else endTimeValidSet(false);
                }} />
            </div>
          </div>


          <div className="row mt-4">
            <div className="col-6">
              <ToggleSwitch name="breakTime" raw label="Break Time" checked={editBreakTime} onChange={e => editBreakTimeSet(e)} />
            </div>
          </div>

          {
            editBreakTime &&
            <div className="row mt-6">
              <div className="col-sm-6">
                <TimePicker id="time-editshift-breakTimeStart" name="breakTimeStart" time={editModel?.breakTimeStartTotalMinute} timeInput label="Break Time's Start" labelUp
                  min={minBreakStart} max={maxBreakStart}
                  onTimePicked={t => {
                    minBreakEndSet(t.time);
                    if (minBreakStart && maxBreakStart && t.time >= minBreakStart && t.time <= maxBreakStart) startBreakTimeValidSet(true);
                    else startBreakTimeValidSet(false);
                  }} />
              </div>
              <div className="col-sm-6">
                <TimePicker id="time-editshift-breakTimeEnd" name="breakTimeEnd" time={editModel?.breakTimeEndTotalMinute} timeInput label="Break Time's End" labelUp
                  min={minBreakEnd} max={maxBreakEnd}
                  onTimePicked={t => {
                    console.log(t, minBreakEnd, maxBreakEnd);
                    if (minBreakEnd && maxBreakEnd && t.time >= minBreakEnd && t.time <= maxBreakEnd) endTimeBreakValidSet(true);
                    else endTimeBreakValidSet(false);
                  }} />
              </div>
            </div>
          }

          <div className="h-100px">&nbsp;</div>

        </div>
      </Modal> */}

    </Layout>
  );
}

export default ShiftsTypes;
