import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import { API_ROUTES } from "../../../../core/Constants";
import { useHub } from "../../../../core/Global";
import Queue from "../models/Queue";
import QueueExtension from "../models/QueueExtension";
import { toastSuccess } from "../../../../components/base/Toast";
import Indicator from "../../../../components/base/Indicator";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";



interface IProps {
    queue: Queue
    onClose: () => void
    onUpdate: () => void
}
export default function ModalAssignExtToQueue(props: IProps) {

    const hub = useHub();

    const [queueExtPool, queueExtPoolSet] = useState<QueueExtension[]>([]);
    const [queueExts, queueExtsSet] = useState<QueueExtension[]>([]);
    const [queueExtsFetching, queueExtsFetchingSet] = useState<boolean>();

    
      const ExtsSort = (a: QueueExtension, b: QueueExtension) => {
        if (a.priority < b.priority) {
          return -1;
        }
        if (a.priority > b.priority) {
          return 1;
        }
        return 0;
      }

      useEffect(() => {

        hub.Get(API_ROUTES.Queues + "extensions/" + props.queue.id, res => {
            if(res.success) {
              queueExtPoolSet(res.data.pool);
              queueExtsSet(res.data.exts);
              queueExtsFetchingSet(false);
            }
        });

      }, [])

    return (
        <Modal id="modal-queue-exts"
          size="Large" 
          open visible staticBackdrop
          title={props.queue.name + ": Extensions"}
          buttonText="Update Extensions"
          onClose={props.onClose}
          onConfirm={callback => {

            hub.Post(API_ROUTES.Queues + "extensions/" + props.queue.id, queueExts, res => {
              if (res.success) {
                toastSuccess("Queue Extensions has been updated successfully!");
                props.onUpdate();
                callback();
              }
              else {
                callback(true);
              }
            })

          }}>

            <div>

              {
                queueExtsFetching && <Indicator />
              }

              {
                !queueExtsFetching && queueExts &&
                  <div className="row">
                    <div className="col-6">
                      <FlexRow className="border-bottom pb-2">
                        <div className="fs-5 fw-bolder">
                          Extensions Pool
                        </div>
                      </FlexRow>
                      <div>
                        {
                          queueExtPool.length > 0 &&
                          <div>
                            <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="fs-7 fw-bolder ps-2">
                                  Extension
                                </th>
                                <th className="text-end pe-2">
                                  {
                                    queueExtPool.length > 0 &&
                                    <span className="badge badge-primary cursor-pointer ms-auto" 
                                      onClick={e=> {
                                        let pool = queueExtPool ?? [];
                                        let exts = queueExts ?? [];
                                        for(var i = 0; i < pool.length; i++) {
                                          exts.push(pool[i]);
                                        }
                                        queueExtPoolSet([]);
                                        queueExtsSet(e=> [...exts]);
                                      }}>
                                      <Icon icon="plus" size={12} className="me-1" />
                                      Add All
                                    </span>
                                  }
                                </th>
                              </tr>
                            </thead>
                        </table>
                            <div className="scroll h-300px">
                              <table className="table table-striped">
                              <tbody>
                              {
                                queueExtPool.sort(ExtsSort).map((item, key) => 
                                <tr key={key}>
                                  <td className="px-3">
                                    <FlexRow>
                                      <Icon icon="phone-office" />
                                      <span className="ms-2">{item.extensionNumber}</span>
                                    </FlexRow>
                                  </td>
                                  <td className="text-end px-3">
                                    <span className="badge badge-light-primary cursor-pointer"
                                    onClick={e=> {
                                      let pool = queueExtPool ?? [];
                                      let exts = queueExts ?? [];
                                      pool.splice(pool.indexOf(item), 1);
                                      exts.push(item);
                                      queueExtPoolSet(e=> [...pool]);
                                      queueExtsSet(e=> [...exts]);
                                    }}>
                                      <Icon icon="plus" size={12} />
                                    </span>
                                  </td>
                                </tr>
                                )
                              }
                            </tbody>
                              </table>
                            </div>
                          </div>
                          
                        }
                        
                        {
                          queueExtPool.length == 0 &&
                          <div className="text-center mt-10">
                            There is no extension!
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-6">
                      
                      <FlexRow className="border-bottom pb-2">
                        <div className="fs-5 fw-bolder">
                          Selected Extensions
                        </div>
                      </FlexRow>
                      <div>
                        {
                          queueExts.length > 0 &&
                          <div>
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="px-3">
                                    <FlexRow>
                                      <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                                          title="Always On: Extension will always connected to queue, otherwise it will only connected when a dispatcher login and claim it!">
                                          
                                          <input className="form-check-input" type="checkbox"
                                            onChange={e=> {
                                              let exts = queueExts ?? [];
                                              exts.forEach(ex => {
                                                ex.alwaysOn = e.target.checked;
                                              })
                                              queueExtsSet(m => [...exts]);
                                            }} />
                                      </label>
                                      <span className="fs-9 ms-2">
                                        Always On
                                      </span>
                                      <Icon icon="info-circle" className="ms-2" title="On: Extension will always connected to queue.&#13;Off: Extension will connect to queue when dispatcher login and claim it." />
                                    </FlexRow>
                                  </th>
                                  <th className="text-end">
                                    {
                                        queueExts.length > 0 &&
                                        <span className="badge badge-danger cursor-pointer ms-auto" 
                                          onClick={e=> {
                                            let pool = queueExtPool ?? [];
                                            let exts = queueExts ?? [];
                                            for(var i = 0; i < exts.length; i++) {
                                              pool.push(exts[i]);
                                            }
                                            queueExtPoolSet(e=> [...pool]);
                                            queueExtsSet([]);
                                          }}>
                                          <Icon icon="minus" size={12} className="me-1" />
                                          Remove All
                                        </span>
                                      }
                                  </th>
                                </tr>
                              </thead>
                            </table>
                              <div className="scroll h-300px">
                                <table className="table table-striped">
                                  <tbody>
                                    {
                                      queueExts.sort(ExtsSort).map((item, key) => 
                                      <tr key={key}>
                                        <td className="px-3">
                                          <FlexRow>
                                            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" checked={item.alwaysOn}
                                                  onChange={e=> {
                                                    let exts = queueExts ?? [];
                                                    exts.forEach(ex => {
                                                      if(ex.extensionId == item.extensionId) {
                                                        ex.alwaysOn = e.target.checked;
                                                      }
                                                    })
                                                    queueExtsSet(m => [...exts]);
                                                  }} />
                                            </label>
                                            <Icon icon="phone-office" className="ms-2" />
                                            <span className="ms-2">{item.extensionNumber}</span>
                                          </FlexRow>
                                        </td>
                                        <td className="px-3">
                                          <FlexRow justify="end">
                                            <span className="badge badge-light-danger cursor-pointer"
                                              onClick={e=> {
                                                let pool = queueExtPool ?? [];
                                                let exts = queueExts ?? [];
                                                exts.splice(exts.indexOf(item), 1);
                                                pool.push(item);
                                                queueExtPoolSet(e=> [...pool]);
                                                queueExtsSet(e=> [...exts]);
                                              }}>
                                              <Icon icon="minus" size={12} />
                                            </span>
                                          </FlexRow>
                                        
                                          
                                        </td>
                                      </tr>
                                      )
                                    }
                                  </tbody>
                                </table>
                              </div>
                          </div>
                          
                        }
                        


                        {
                          queueExts.length == 0 &&
                          <div className="text-center mt-10">
                            There is no extension!
                          </div>
                        }
                        
                      </div>
                    </div>
                    
                  </div>
              }

            

            </div>
        </Modal>
    )
}