import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import { TagifyDataItem } from "../../../models/TagifyOptions";
import { ListItem } from "../../../core/Types";
import { useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import { toastSuccess } from "../../../components/base/Toast";
import Tagify from "../../../components/tagify/Tagify";
import DatePicker from "../../../components/form/DatePicker";
import FlexRow from "../../../components/base/FlexRow";
import FormTitle from "../../../components/form/FormTitle";
import Icon from "../../../components/base/Icon";
import { ShiftViewModel } from "../models/ShiftViewModels";


interface IProps {
    model?: ShiftViewModel
    onClose?: () => void
    callback?: () => void
}
interface AddedShiftItem {
    days: string[]
    shift: TagifyDataItem
}

export default function MultiAssignModal(props: IProps) {

    const hub = useHub();

    const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const daysOptions = [
        {
            id: "all",
            name: "All Days"
        },
        {
            id: "weekdays",
            name: "Weekdays"
        },
        {
            id: "weekend",
            name: "Weekend"
        },
        {
            id: "custom",
            name: "Custom"
        }
    ]

    const [addedShift, addedShiftSet] = useState<AddedShiftItem[]>([]);
    const [selectedShiftTypes, selectedShiftTypesSet] = useState<TagifyDataItem[]>([]);
    const [selectedWeekDays, selectedWeekDaysSet] = useState<string[]>(weekDays);
    const [selectedDayOption, selectedDayOptionSet] = useState<string>("all");

    const [clearTagifyShiftTypes, clearTagifyShiftTypesSet] = useState<boolean>(false);
    const [tafigyShiftTypesRouteParams,tafigyShiftTypesRouteParamsSet] = useState<ListItem[]>();


    useEffect(() => {
        
        if (selectedDayOption == "all") selectedWeekDaysSet(w=> [...weekDays]);
        if (selectedDayOption == "weekdays") selectedWeekDaysSet(w=> [...["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]]);
        if (selectedDayOption == "weekend") selectedWeekDaysSet(w=> [...["Saturday", "Sunday"]]);
        //if (selectedDayOption == "custom") selectedWeekDaysSet(w=> [...[]]);
    }, [selectedDayOption])

    useEffect(() => {
        var routeParams:ListItem[] = [];
        if (addedShift) {
            addedShift.forEach(sh => {
                routeParams.push({
                    name: "excludeIds",
                    value: sh.shift.value
                });
            });
        }
        tafigyShiftTypesRouteParamsSet(s=> [...routeParams]);

    }, [addedShift])

    useEffect(() => {
        
        if(clearTagifyShiftTypes) {
            setTimeout(() => {
                clearTagifyShiftTypesSet(false);
            }, 1000);
          }

      }, [clearTagifyShiftTypes])

    return (
        <>

        <Modal id="modal-assign-agents"
            title="Assign Agents"
            size="Large" staticBackdrop
            buttonText="Assign Shift"
            visible open
            onCancel={() => {
                window.openModal("modal-discard-multiassign");
            }}
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                window.hideFormErrors();

                if (!body.agents || body.agents.length < 10) {
                    window.showFormError("assign-agents", "Please select an agent!");
                    if (callback) callback();
                    return;
                }

                if (addedShift.length == 0) {
                    window.showFormError("assignagentshifttypes", "Please add a shift first!");
                    if(callback) callback();
                    return;
                }


                hub.Post(API_ROUTES.Shifts + "multiassign", {
                    ...body,
                    addedShifts: addedShift
                }, res => {
                    if (res.success) {
                        toastSuccess("Agent(s) have been assigned to shifts successfully!");
                        addedShiftSet([]);
                        if (props.callback) props.callback();
                    }
                    if (callback) callback(res);
                })

            }}>

                <div>

                    <div className="fs-7 fw-bold">
                        Agent(s)
                        <span id="assign-agents-err" className="small text-danger mx-2 form-error"></span>
                    </div>
                    <Tagify name="agents" id="tagify-assign-agents" placeholder="Select Agent(s)"
                        apiUrl={API_ROUTES.TagifyUsers} block 
                        routeParams={[{
                            name: "showUserType",
                            value: "True"
                        }]} />


                    <div className="row">
                        <div className="col-sm-6">
                            <DatePicker id="dt-assign-fromdate" name="dateFrom" label="From" labelUp selectedDate={props.model?.startDate} />
                        </div>
                        <div className="col-sm-6">
                            <DatePicker id="dt-assign-todate" name="dateTo" label="To" labelUp selectedDate={props.model?.endDate} />
                        </div>
                    </div>

                    <fieldset className="mt-5">
                        <caption>Add Shift</caption>

                        <div className="fs-7 fw-bold">
                            Shift Type
                            <span id="assignagentshifttypes-err" className="small text-danger mx-2 form-error"></span>
                        </div>
                        
                        <Tagify name="assignagentshifttypes" id="tagify-assign-shifttypes" placeholder="Select Shift(s)"
                                apiUrl={API_ROUTES.TagifyShiftTypesWithDepartments} block clear={clearTagifyShiftTypes}
                                routeParams={tafigyShiftTypesRouteParams}
                                onSelectItem={item => {
                                    var arr = selectedShiftTypes;
                                    console.log("onselectitem", arr);
                                    if (item && arr.indexOf(item) < 0) arr.push(item);
                                    selectedShiftTypesSet(s=> [...arr]);
                                }} 
                                onRemoveItem={item => {
                                    var arr = selectedShiftTypes;
                                    
                                    for (var i = 0; i < arr.length; i++) {
                                        if(arr[i].value == item?.value) {
                                            arr.splice(arr.indexOf(item), 1);
                                            break;
                                        }
                                    }
                                    selectedShiftTypesSet(s=> [...arr]);
                                }}/>
                        
                        <div className="row mt-5">
                            <label className={"col col-12 fw-bold fs-7"} htmlFor="drp-assign-daysss">
                                Days
                                <span id="assignagentweekdays-err" className="small text-danger mx-2 form-error"></span>
                            </label>
                            <div className="col col-12">
                                <select id="drp-assign-daysss" className="form-select form-select-sm form-select-solid" 
                                    value={selectedDayOption} onChange={e=> {
                                        selectedDayOptionSet(e.target.value)
                                        if(e.target.value == "custom") selectedWeekDaysSet(w=> [...[]]);
                                    }}>
                                    {
                                        daysOptions.map((op, k) => <option key={k} value={op.id}>{op.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>


                        <FlexRow className="mb-10 mt-10">
                            {
                                weekDays.map((day, k) => 
                                <div key={k} className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <label className="form-check-label fs-7">
                                        <input className="form-check-input me-1" type="checkbox" checked={(selectedWeekDays.indexOf(day) > -1)} 
                                            onChange={e=> {
                                                var arr = selectedWeekDays;
                                                if(e.target.checked) {
                                                    if (arr.indexOf(day) < 0) arr.push(day);
                                                }
                                                else {
                                                    if (arr.indexOf(day) > -1) arr.splice(arr.indexOf(day), 1);
                                                }
                                                selectedWeekDaysSet(d=> [...arr]);
                                                selectedDayOptionSet("custom");
                                            }}/>
                                        {day}
                                    </label>
                                </div>
                            )   
                            }
                        </FlexRow>

                        <div>
                            <button className="btn btn-sm btn-primary"
                                onClick={e=> {
                                    e.preventDefault();

                                    window.hideFormErrors();

                                    if (selectedShiftTypes.length == 0) {
                                        window.showFormError("assignagentshifttypes", "Please select a shift type!");
                                        return;
                                    }

                                    if (selectedWeekDays.length == 0) {
                                        window.showFormError("assignagentweekdays", "Please select the shift days!");
                                        return;
                                    }


                                    var arr = addedShift ?? [];
                                    var days:string[] = [];
                                    
                                    selectedShiftTypes.forEach(sh => {
                                        var exists = false;
                                        arr.forEach(a => {
                                            if (a.shift.value == sh.value && a.days == selectedWeekDays) {
                                                exists = true;  
                                            } 
                                        });

                                        if (!exists) {
                                            selectedWeekDays.forEach(d => {
                                                if (days.indexOf(d) < 0) {
                                                    days.push(d);
                                                }
                                            });
                                            if(days.length > 0) {
                                                arr.push({
                                                    days: days,
                                                    shift: sh
                                                });
                                            }
                                            
                                        }
                                        
                                    });

                                    addedShiftSet(a => [...arr]);
                                    clearTagifyShiftTypesSet(true);
                                    selectedShiftTypesSet(a => []);

                                }}>
                                Add Shift
                            </button>
                        </div>

                        
                    </fieldset>

                    <FormTitle title="Added Shifts" margin={5} />

                    <div>
                        {
                            addedShift.map((item, k) => 
                                <FlexRow key={k} className={"px-5 py-2 " + (k % 2 == 0 ? "bg-light" : "bg-secondary")} justify="between">
                                    <FlexRow>
                                        <div className="fs-7">
                                            {item.shift.title}
                                        </div>
                                        <div className="fs-8 ms-2">
                                            ({item.days.map((d, dk) => <span className={(dk > 0 ? "ms-1" : "")} key={dk}>{d.substring(0,3)}</span>)})
                                        </div>
                                    </FlexRow>
                                    <a href="#" onClick={e=> {
                                        e.preventDefault();
                                        var arr = addedShift ?? [];
                                        arr.splice(k, 1);
                                        addedShiftSet(s=> [...arr]);
                                    }}>
                                        <Icon icon="times" className="solid" color="#666" size={14} />
                                    </a>
                                </FlexRow>
                            )
                        }
                    </div>


                </div>

        </Modal>

        <Modal id="modal-discard-multiassign"
            title="Discard"
            buttonText="Discard"
            cancelText="Cancel"
            buttonColor="warning" secondModal
            onConfirm={callback => {
                window.closeModal("modal-discard-multiassign");
                window.closeModal("modal-assign-agents");
                callback();
            }}>
            <div>
                Are you sure you want to discard the changes and close the form?
            </div>
        </Modal>

        </>
    )
}