import React from 'react';
import { Routes, Route } from "react-router-dom";
import FirewallWhitelist from '../modules/system/firewall/views/FirewallWhitelist';
import NotificationRulesPage from '../modules/system/settings/views/NotificationRulesPage';
import PBXServerList from '../modules/system/servers/views/PBXServerList';
import PBXServerForm from '../modules/system/servers/views/PBXServerForm';
import PBXServerIssuesPage from '../modules/system/servers/views/PBXServerIssuesPage';
import SystemSettingsView from '../modules/system/settings/views/SystemSettingsView';
import AnnouncementsList from '../modules/system/announcements/views/AnnouncementsList';


function SystemRoutes() {
  let k = 0;
  return (
    [
      <Route key={k++} path="/system/settings" element={<SystemSettingsView />} />,
      <Route key={k++} path="/system/settings/:selectedPage" element={<SystemSettingsView />} />,

      <Route key={k++} path="/system/announcements" element={<AnnouncementsList />} />,

      <Route key={k++} path="/system/notifications/rules" element={<NotificationRulesPage />} />,

      <Route key={k++} path="/system/servers" element={<PBXServerList />} />,
      <Route key={k++} path="/system/servers/create" element={<PBXServerForm />} />,
      <Route key={k++} path="/system/servers/edit/:selectedId" element={<PBXServerForm />} />,
      <Route key={k++} path="/system/servers/draft/:selectedId" element={<PBXServerForm />} />,
      <Route key={k++} path="/system/servers/issues/:selectedId" element={<PBXServerIssuesPage />} />,

      <Route key={k++} path="/system/firewall/whitelist" element={<FirewallWhitelist />} />,

    ]
  );
}

export default SystemRoutes;
