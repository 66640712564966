import { useEffect, useRef, useState } from "react";
import Job from "../../models/Job";
import Modal from "../../../../../components/base/Modal";
import { useEnvironment, useHub, useScreen } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import Map from "../../../../../components/here/Map";
import Technician from "../../../../technicians/models/technician";
import { HereGeocodePosition } from "../../../../../models/MapModels";
import DropDown from "../../../../../components/form/DropDown";
import TextArea from "../../../../../components/form/TextArea";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import JobMetro from "../../../base/models/JobMetro";
import IconButton from "../../../../../components/base/IconButton";


interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobUpdateTechModal(props: IProps) {

    const screen = useScreen()
    const hub = useHub()
    const env = useEnvironment()

    const [working, workingSet] = useState<boolean>(false)
    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [showMap, showMapSet] = useState<boolean>(false);

    const [metros, metrosSet] = useState<JobMetro[]>([]);
    const [selectedMetro, selectedMetroSet] = useState<JobMetro>();
    const fetchMetros = (stateId?: string) => {
        hub.Get(API_ROUTES.JobMetros + "all", res => {
            if (res.success) metrosSet(res.data);
        });
    }

    const [techs, techsSet] = useState<Technician[]>();
    const [selectedTech, selectedTechSet] = useState<Technician>();
    const fetchTechs = (jobId?: string, metroId?: string) => {
        var q = "?jobId=" + jobId;
        if (metroId) q += "&metroId=" + metroId;
        hub.Get("/accounts/Technicians/byjob/" + q, res => {
            if (res.success) {
                techsSet(res.data);
            }
        })
    }

    useEffect(() => {
        if (job?.metroId && metros) {
            metros.forEach(m => {
                if (m.id == job.metroId) selectedMetroSet(m);
            });
        }
    }, [job, metros])

    useEffect(() => {
        if (job?.techId && techs) {
            techs.forEach(t => {
                if (t.id == job.techId) selectedTechSet(t);
            });
        }
    }, [job, techs])

    const [mapCenter, mapCenterSet] = useState<HereGeocodePosition>();
    const [mapZoom, mapZoomSet] = useState<number>(5);

    useEffect(() => {
        if (job) {
            fetchTechs(job.id);
            fetchMetros(job.stateId);
            mapCenterSet(job.position);
            mapZoomSet(8);
        }
    }, [job])

    const submit = (note?: any, send?: boolean) => {
        workingSet(true)
        hub.Post(API_ROUTES.Jobs + "tech", {
            id: job?.id,
            metroId: selectedMetro?.id,
            techId: selectedTech?.id,
            note: note,
            send: send
        }, res => {
            if (res.success) {
                window.closeModal("modal-update-tech-note");
                window.closeModal("modal-update-tech");
            }
            workingSet(false)
        });

    }

    const searchInput = useRef<HTMLInputElement>(null)
    const [keyword, keywordSet] = useState<string>("")


    const [techContainerHeight, techContainerHeightSet] = useState<number>(400)

    useEffect(() => {

    }, [])


    useEffect(() => {
        setTimeout(() => {
            searchInput.current?.focus()
        }, 500);
    }, [])

    return (
        <>

            <Modal id="modal-update-tech"
                title="Technician"
                visible open staticBackdrop noFooter noHeader
                buttonText="Update Technician"
                buttonColor="primary"
                size="XLarge" noPadding
                onOpen={() => {
                    setTimeout(() => {
                        showMapSet(true);
                    }, 200);
                }}
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    if (!body.metroId) {
                        window.showFormError("metroId", "Please select a metro!", true, "modal-update-tech");
                        if (callback) callback();
                        return;
                    }

                    if (job?.metroId == selectedMetro?.id && job?.techId == selectedTech?.id) {
                        window.closeModal("modal-update-tech");
                        return;
                    }

                    if (job?.techId && !selectedTech) {
                        window.openModal("modal-remove-tech-note");
                        if (callback) callback();
                        setTimeout(() => {
                            $("#txtTechRemoveNote").focus();
                        }, 500);
                        return;
                    }

                    if (job?.techId) {
                        window.openModal("modal-update-tech-note");
                        if (callback) callback();
                        setTimeout(() => {
                            $("#txtTechUpdateNote").focus();
                        }, 500);
                        return;
                    }

                    submit();


                }}
            >
                <div className="row">
                    <div className="col-sm-12 col-md-5 px-8 pb-0">

                        <FlexRow className="py-4 py-xxl-6 border-bottom-0">
                            <div>
                                <h2 className="fs-1 fs-xxxl-1 modal-title d-flex align-items-center">
                                    Metro & Technician
                                </h2>
                                <div className="fs-7 mt-1">
                                    {"Job ID: " + job?.jobId + " | " + job?.fullName}
                                </div>
                            </div>
                            <div className="ms-auto">
                                <IconButton icon="times"
                                    onClick={e => {
                                        window.closeModal("modal-update-tech")
                                    }}
                                />
                            </div>
                        </FlexRow>

                        <FlexRow>
                            <label className={"flex-grow-1 fw-bold fs-7"} htmlFor={"drp-jobdashboard-metros"}>
                                Metro
                                {
                                    <span id={"metroId-err"} className="small text-danger mx-3 form-error"></span>
                                }
                            </label>
                            {
                                props.job?.metroId && false &&
                                <a href="#" className="fs-8 text-danger"
                                    onClick={e => {
                                        e.preventDefault();
                                        window.openModal("modal-remove-tech-note");
                                    }}>Remove Metro</a>
                            }
                        </FlexRow>

                        <DropDown id="drp-jobdashboard-metros" name="metroId" parentModal="modal-update-tech"
                            labelUp marginBottom={0} source={metros} forceSelectValue={selectedMetro?.id}
                            defaultValue={job?.metroId} onSelectUpdate={job} deselect
                            onDeselect={() => {
                                selectedTechSet(undefined);
                                techsSet(undefined);
                                selectedMetroSet(undefined);
                            }}
                            onSelect={val => {
                                metros?.forEach(item => {
                                    if (item.id == val) {
                                        selectedMetroSet(item);
                                        selectedTechSet(undefined);
                                        fetchTechs(job?.id, item.id);
                                        mapZoomSet(7);
                                    }
                                });
                            }} />

                        <div className="mt-5">
                            <label className={"fw-bold fs-7"}>
                                Technician
                                <span id="techId-err" className="small text-danger mx-3 form-error"></span>
                            </label>
                        </div>

                        <div className="mt-3 mb-2">
                            <input ref={searchInput} className="form-control form-control-sm" placeholder="Search technician..."
                                value={keyword} onChange={e => keywordSet(e.target.value)} />
                        </div>

                        <div className="scroll-y" style={{
                            height: screen.height > 800 ? 400 : (screen.height / 2)
                        }}>
                            {
                                job?.techId &&
                                <label className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start py-3 px-4 mb-2">
                                    <div className="d-flex align-items-center me-2">
                                        <div className="form-check form-check-custom form-check-solid form-check-primary form-check-sm me-3">
                                            <input className="form-check-input form-check-input-sm"
                                                type="radio" checked={!selectedTech?.id}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        selectedTechSet(undefined);
                                                    }
                                                }} />
                                        </div>
                                        <div className="flex-grow-1">
                                            <h2 className="d-flex align-items-center fs-7 fw-bolder flex-wrap mb-1 text-danger">
                                                No Technician
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="ms-5">
                                    </div>
                                </label>
                            }
                            {
                                techs?.filter(f => f.fullName.toLowerCase().includes(keyword.toLocaleLowerCase())).map((item, k) =>
                                    <label key={k} className="btn btn-outline btn-outline-dashed d-flex flex-stack text-start py-3 px-4 mb-2">
                                        <div className="d-flex align-items-center me-2">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary form-check-sm me-3">
                                                <input className="form-check-input form-check-input-sm"
                                                    type="radio" checked={selectedTech?.id == item.id}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            selectedTechSet(item);
                                                        }
                                                    }} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <h2 className="d-flex align-items-center fs-7 fw-bolder flex-wrap mb-1">
                                                    {item.fullName}
                                                </h2>
                                                <div className="fs-8 opacity-50">
                                                    {item.distanceLabel ?? "Unknown Distance"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-5">
                                            <span className="fs-8 opacity-50">
                                                {
                                                    item.activeJobs && item.activeJobs > 0 ?
                                                        <span data-kt-element="period">{item.activeJobs} Active Jobs</span>
                                                        : <span data-kt-element="period">
                                                            <Icon icon="circle" type="solid" className="text-success" size={10} />
                                                        </span>
                                                }
                                            </span>
                                        </div>
                                    </label>
                                )
                            }
                        </div>

                        <FlexRow justify="end" className="border-top pt-xxl-6 mt-xxl-6 pt-2 mt-2 mb-5">
                            <button type="button" className="btn btn-secondary btn-sm me-2" disabled={working}
                                onClick={e => {
                                    e.preventDefault();
                                    window.closeModal("modal-update-tech")
                                }}>
                                Discard
                            </button>
                            <button className="btn btn-sm btn-primary" disabled={working}>
                                {
                                    working ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">
                                                Please Wait
                                            </span>
                                        </>
                                        :
                                        <>
                                            Update Technician
                                        </>
                                }
                            </button>
                        </FlexRow>

                    </div>
                    <div className="col-sm-12 col-md-7 d-none d-md-block">
                        {
                            showMap && job &&
                            <Map id="job-updatetech-map" height={750}
                                techs={techs} selectedTech={selectedTech}
                                metros={metros} selectedMetro={selectedMetro}
                                center={props.job?.address?.position} zoom={8}
                                jobMarker={props.job?.address?.position}
                                onSelectMetro={mtr => {
                                    if (env.isDevelopment) console.log("job-updatetech-map metro changed", mtr);
                                    selectedMetroSet(mtr);
                                    selectedTechSet(undefined);
                                    fetchTechs(job?.id, mtr.id);
                                }} />
                        }
                    </div>
                </div>
            </Modal>

            <Modal id="modal-update-tech-note"
                title="Update Technician Note"
                secondModal
                buttonText="Update & Send"
                buttonColor="success"
                onConfirm={callback => {
                    var note = $("#txtTechUpdateNote").val();
                    if (job?.techId && (note?.toString().length ?? 0) < 2) {
                        window.showFormError("techUpdateNote", "Please write a note!", false, "modal-update-tech-note");
                        callback(true);
                        return;
                    }
                    submit(note, true);
                }}
                secondActionText="Update Technician"
                onSecondConfirm={callback => {
                    var note = $("#txtTechUpdateNote").val();
                    if (job?.techId && (note?.toString().length ?? 0) < 2) {
                        window.showFormError("techUpdateNote", "Please write a note!", false, "modal-update-tech-note");
                        callback(true);
                        return;
                    }
                    submit(note);
                }}>
                <div>
                    <p>
                        Please describe why you are changing the thechnician:
                    </p>
                    <TextArea block name="techUpdateNote" id="txtTechUpdateNote" rows={5} />
                </div>
            </Modal>

            <Modal id="modal-remove-tech-note"
                title="Remove Technician"
                secondModal
                buttonText="Confirm & Remove"
                buttonColor="danger"
                onOpen={() => $("#txtTechRemoveNote").focus()}
                onConfirm={callback => {
                    var note = $("#txtTechRemoveNote").val();
                    if ((note?.toString().length ?? 0) < 2) {
                        window.showFormError("techRemoveNote", "Please write a note!", false, "modal-remove-tech-note");
                        callback(true);
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "cleartech", {
                        id: props.job?.id,
                        metroId: selectedMetro?.id,
                        note: note
                    }, res => {
                        if (res.success) {
                            if (callback) callback();
                            window.closeModal("modal-update-tech");
                        }
                    });

                }}>
                <div>
                    <p>
                        Please describe why you are removing the thechnician:
                    </p>
                    <TextArea block name="techRemoveNote" id="txtTechRemoveNote" rows={5} />
                </div>
            </Modal>
        </>
    )

}