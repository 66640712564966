import { useEffect, useState } from "react";
import { useHub, useSocket } from "../../core/Global";
import { AgentOverviewMissedCalls } from "../../modules/agents/models/AgentOverviewModel";
import Icon from "../base/Icon";


type Props = {
    userId?: string
 }
export default function AccountAgentMissedCallsChart(props: Props) {

    const hub = useHub();
    const [missedCalls, missedCallsSet] = useState<AgentOverviewMissedCalls>({
        total: 10,
        callBack: 7,
        noCallBack: 3
    });
    const [missedCallsDateLabel, missedCallsDateLabelSet] = useState<string>("Today");

    useEffect(() => {
        window.daterange("missedcalls_date", missedCallsFetch);
    }, [])

    const missedCallsFetch = async (start:any, end:any, label:any) => {
    
        if (label == "Custom Range") missedCallsDateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
        else missedCallsDateLabelSet(label); 
        var q = "?";
        q += "from=" + start.format("DDMMYYYY");
        q += "&to=" + end.format("DDMMYYYY");
        console.log(q);
        hub.Get("/accounts/agents/overview/missedcalls/" + props.userId + q, res => {
            missedCallsSet(res.data);
        })
      }

    
      useEffect(()=> {

        window.createCircleChart("chart-missed-calls", 
            [missedCalls?.callBack ?? 0, missedCalls?.noCallBack ?? 0], 
            [window.getBootstrapColor("primary"), window.getBootstrapColor("danger")]);
    
      }, [missedCalls]);

    return (
        <div className="card card-flush ">
            <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
                <div className="d-flex align-items-center">
                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                {missedCalls?.total ?? 0}
                </span>
                </div>
                <span className="text-gray-400 pt-1 fw-bold fs-8">
                <Icon icon="phone-missed" type="solid" className="me-1" size={11} />
                Misesd Calls
                </span>
            </div>
            <div className="d-flex align-items-start">
                <div id="missedcalls_date" className="d-flex align-items-center cursor-pointer fs-8">
                    <span>{missedCallsDateLabel}</span>
                    <Icon icon="chevron-down" className="ms-1" size={11} />
                </div>
            </div>
            </div>
            <div className="card-body pt-2 pb-4 d-flex align-items-center">
            <div className="d-flex flex-center me-5 pt-2">
                <div id="chart-missed-calls" data-kt-size="70" data-kt-line="11"></div>
            </div>
            <div className="d-flex flex-column content-justify-center w-100">
                <div className="d-flex fs-6 fw-bold align-items-center">
                <div className="bullet w-8px h-6px rounded-2 bg-primary me-1"></div>
                <div className="text-gray-500 flex-grow-1 me-2 fs-7">Call Back</div>
                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                    {missedCalls?.callBack ?? 0}
                </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center my-3">
                <div className="bullet w-8px h-6px rounded-2 bg-danger me-1"></div>
                <div className="text-gray-500 flex-grow-1 me-2 fs-7">Missed</div>
                <div className="fw-boldest text-gray-700 text-xxl-end">
                    {missedCalls?.noCallBack ?? 0}
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}