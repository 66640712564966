import { useEffect, useState } from "react";
import { getUser, setUserToken, useHub } from "../../core/Global";
import Modal from "../base/Modal";
import DropDown from "../form/DropDown";
import { API_ROUTES } from "../../core/Constants";
import FlexRow from "../base/FlexRow";
import Icon from "../base/Icon";


function ModalSetTimezone() {

    const hub = useHub();
    const user = getUser();
    const [selectedValue, selectedValueSet] = useState<string>();


    useEffect(() => {
        window.openModal("modal-user-set-timezone");
    }, [])
    return (
        <Modal id="modal-user-set-timezone"
            title="Set Timezone"
            buttonText="Update Timezone"
            buttonColor="primary"
            staticBackdrop preventDiscard
            onOpen={() => {
                window.select2SearchClick();
            }}
            onSubmit={(body, callback) => {
                
                //hub.Get(API_ROUTES.Users + "authuser", res => {})
                hub.Post(API_ROUTES.Users + "timezone/" + user?.id, body, res => {
                    if (res.success) {
                        window.closeModal("modal-user-set-timezone");
                    }
                })
            }}>
                <div>
                    <DropDown id="drp-update-timezones" name="timezone" block forceSelectValue={selectedValue}
                        apiSource={API_ROUTES.Users + "timezones"} parentModal="modal-user-set-timezone"
                        onApiSourceFetched={(data) => {

                            var ofs = window.getClientTimeZoneOffset();
                            var tz = window.getClientTimeZone()?.toLowerCase();
                            var tzArr = tz.split("/");

                            console.log("timezoness", ofs, tz);

                            var cityName = tzArr.length > 1 ? tzArr[1] : tzArr[0];
                            cityName = cityName.toLowerCase();
                            var id = "";

                            if (data && data.length > 0) {

                                for (var i = 0; i < data.length; i++) {
                                    if (data[i].offset == ofs && data[i].name.toLowerCase().indexOf(cityName) > -1) {
                                        id = data[i].id;
                                        break;
                                    }
                                    if (cityName == "istanbul" && data[i].offset == ofs && data[i].name.toLowerCase().indexOf("turkey") > -1) {
                                        id = data[i].id;
                                        break;
                                    }
                                    if (cityName == "istanbul" && data[i].offset == ofs && data[i].name.toLowerCase().indexOf("turkey") > -1) {
                                        id = data[i].id;
                                        break;
                                    } 
                                }

                                if (id == "") {
                                    for (var i = 0; i < data.length; i++) {
                                        if (data[i].offset == ofs) {
                                            id = data[i].id;
                                            break;
                                        }
                                    }
                                }
                            }

                            if (id == "") id = "UTC";

                            selectedValueSet(id);


                        }} />

                    <FlexRow className="mt-5">
                        <Icon icon="info-circle" className="me-2" />
                        <span className="fs-8">
                            You can change it from Preferences &gt; Timezone
                        </span>
                    </FlexRow>
                </div>
        </Modal>
    )
}

export default ModalSetTimezone;