import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/tags.json";
import sortingProps from "../sorting/tags.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import TextBox from "../../../../components/form/TextBox";
import Section from "../../../../components/base/Section";
import TagItem from "../models/TagItem";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";

function TagsList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<TagItem>();

  const [refresh, refreshSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);
  const [systemStatus, systemStatusSet] = useState<boolean>();

  const [clearForms, clearFormsSet] = useState<boolean>();


  const edit = (data: any) => {
    selectedItemSet(data);
    editModalSet(true);
  }

  const deleteTag = (data: any) => {
    selectedItemSet(data);
    groupsRouteParamsSet([{
      name: "userId",
      value: data.ownerUserId
    }, {
      name: "excludeIds",
      value: data.id
    }])
    deleteModalSet(true);
  }

  const NameItem = (data: any) => (
    <FlexRow>
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const JobsCount = (data: TagItem) => (
    <div>
      {
        (data.activeJobsCount ?? 0) > 0 ?
          <a href={"/crm/jobs/?tags=" + data.id} target="_blank">
            {data.activeJobsCount}
          </a>
          :
          <div>
            0
          </div>
      }

    </div>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Tag"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: TagItem) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  const StatusItem = (data: TagItem) => (
    <div>
      {
        data.isConfirmed ?
          <span className="badge badge-success">
            Confirmed
          </span>
          :
          <span className="badge badge-warning">
            Pending Confirmation
          </span>
      }
    </div>
  )

  useEffect(() => {
    setPage("Tags", "CRM", "Base Data");

    window.onModalOpen("modal-edit-tag", () => {
      $("#txt-edittag-name").focus();
    })
  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Tag"
          apiRoute={API_ROUTES.Tags}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          addCustom={() => createModalSet(true)}
          edit editCustom={edit}
          delete={hasRole("DeleteTags")} deleteCustom={deleteTag}
          refresh={refresh}>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Status">
            <StatusItem />
          </DataTableField>
          <DataTableField title="Jobs">
            <JobsCount />
          </DataTableField>
          <DataTableField title="" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>

      {
        createModal &&
        <Modal id="modal-add-tag"
          title="Create New Tag"
          buttonText="Add Tag(s)"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          onSubmit={(body, callback) => {


            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid name!", false, "modal-add-tag");
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.Tags, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tag(s) has been added successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system tag!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <div className="fs-7 fw-bolder mb-1">
              Tag(s)
              <span id="name-err" className="small text-danger mx-2 form-error"></span>
            </div>

            <Tagify name="name" id="drp-addtagsnames" placeholder="Tag Name" block freeTags clear={clearForms} />

          </div>
        </Modal>
      }


      {
        editModal && selectedItem &&
        <Modal id="modal-edit-tag"
          title="Edit Tag"
          buttonText="Update Tag"
          staticBackdrop open visible
          onOpen={() => $("#txt-edittag-name").focus()}
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            if (user?.isSuperAdmin) {
              if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
                window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-edit-tag");
                if (callback) callback();
                return;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid name!", false, "modal-edit-tag");
              if (callback) callback();
              return;
            }

            hub.Put(API_ROUTES.Tags + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tag has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="ownerUserId" id="drp-edit-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} selectedId={selectedItem.ownerUserId} single block />
              </>
            }

            <FlexRow className="mb-5">
              <div className="flex-grow-1">
                <TextBox id="txt-edittag-name" name="name" block marginBottom={0} inlineStyle mediumText noTextError placeholder="Flag Name" defaultValue={selectedItem.name} />
              </div>
            </FlexRow>

            {
              !selectedItem.isConfirmed &&
              <div className="mt-5">
                <ToggleSwitch name="confirmed" raw label="Confirm Tag" small />
              </div>
            }


          </div>
        </Modal>
      }


      {
        deleteModal && selectedItem &&
        <Modal id="modal-delete-tag"
          title="Delete Tag"
          buttonText="Delete Tag"
          buttonColor="danger"
          open visible staticBackdrop
          onClose={() => {
            deleteModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            hub.Post(API_ROUTES.Tags + "delete/" + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tag has been deleted successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            <div>
              Are you sure you want to delete this tag?
            </div>
          </div>
        </Modal>
      }





    </Layout>
  );
}

export default TagsList;
