import { HubConnectionState } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import Hub from "../../../api/Hub";
import Icon from "../../../components/base/Icon";
import Time from "../../../components/timer/Time";
import { useHub, useSocket } from "../../../core/Global";
import { AgentOverviewTimeTable } from "../models/AgentOverviewModel";



type Props = {
    agentId?: string
    model?: AgentOverviewTimeTable
}

let socketInterval:NodeJS.Timeout;
let silentTime:number = 300;
let start:any,end:any,type:any;
function AgentTimeTable(props: Props) {

    const hub = useHub();
    const socket = useSocket();
    const [model, modelSet] = useState<AgentOverviewTimeTable>()
    const [totalCallsDateLabel, totalCallsDateLabelSet] = useState<string>("Today");
    const [totalCallsTypeLabel, totalCallsTypeLabelSet] = useState<string>("Incoming");
    const [dateLabel, dateLabelSet] = useState<any>("Today");
    
    const fetch = async () => {
        
        var q = "?";
        q += "from=" + (start ?? window.now()).format("DDMMYYYY");
        q += "&to=" + (end ?? window.now()).format("DDMMYYYY");
        q += "&type=" + (type ?? "Incoming");

        // hub.Get("/accounts/agents/overview/totalcalls/" + props.agentId + q, res => {
        //     totalCallsSet(res.data);
        // })
      }

    //   const startSocket = async () => {
    //     await socket.start();
    
    //     socket.invoke("StartListenToAgent", props.agentId);
    
    //     socket.on("UpdateAgentOverview", () => {
    //         fetch();
    //         silentTime = 300;
    //     });
    
    //     if (socketInterval) clearInterval(socketInterval);
    
    //     socketInterval = setInterval(async () => {
    //         if (socket.state != HubConnectionState.Connected) await startSocket();
    //     }, 5000);
    
    // }

      

    // useEffect(()=> {
        
    //     fetch();

    //     window.daterange("todaycalls_date", (st:any, en:any, lbl:any) => {
    //         dateLabelSet(lbl);
    //         if (lbl == "Custom Range") totalCallsDateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
    //         else totalCallsDateLabelSet(lbl);
    //         start = st;
    //         end = en;
    //         fetch();
    //     });

    //     window.inlineDropdown("todaycalls_type", ["Incoming", "Outgoing"], tp => {
    //         type = tp;
    //         totalCallsTypeLabelSet(tp);
    //         fetch();
    //     });

    //     startSocket();


    //     return () => {
            
    //         if (socketInterval) clearInterval(socketInterval);
    //         socket.stop();
    //     };


    // }, [])



    useEffect(()=> {
    
        if(props.model) {
            modelSet(props.model);
            window.createCircleChart("chart-agent-timetable", 
                [model?.activeTime ?? 0, model?.talkTime ?? 0],
                [window.getBootstrapColor("success"), window.getBootstrapColor("danger")]);
        }
    
      }, [props.model]);

    return (
        <div className="card card-flush ">
            <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
                <div className="d-flex align-items-center">
                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                    <Time totalSeconds={model?.talkTime} showHours />
                </span>
                </div>
                <span className="text-gray-400 pt-1 fw-bold fs-6">
                    <Icon icon="phone" type="solid" className="me-1" />
                    Talked Today
                </span>
            </div>
            </div>
            <div className="card-body pt-2 pb-4 d-flex align-items-center">
            <div className="d-flex flex-center me-5 pt-2" style={{width:'50%'}}>
                <div id="chart-agent-timetable" data-kt-size="70" data-kt-line="11"></div>
            </div>
            <div className="d-flex flex-column content-justify-center w-100">
                <div className="d-flex fs-6 fw-bold align-items-center">
                    <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Active Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.activeTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Talk Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.talkTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-warning me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Away Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.awayTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Paused Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.pausedTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2 d-none">
                    <div className="bullet w-8px h-6px rounded-2 bg-info me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Hold Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        00:00:00
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2 d-none">
                    <div className="bullet w-8px h-6px rounded-2 bg-secondary me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Unreachable Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        00:00:00
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
    
}

export default AgentTimeTable;