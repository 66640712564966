import { useEffect, useState } from "react";
import { clearCallsJob, useHub, useSocket } from "../../core/Global";
import Job from "../../modules/crm/jobs/models/Job";
import CallsList from "../calls/CallsList";
import { API_ROUTES } from "../../core/Constants";


interface IProps {
    job: Job
}
export default function CallsDrawer(props: IProps) {

    const hub = useHub();
    const socket = useSocket();

    const [refresh, refreshSet] = useState<boolean>(false);
    useEffect(() => {
        if(refresh) {
            setTimeout(() => {
                refreshSet(false);
            }, 500);
        }
    }, [refresh])


    useEffect(() => {

        if (props.job.id) {

            hub.Post(API_ROUTES.Jobs + "seencalls", {
                id: props.job.id
            }, res => {

            })

            var socketKey = socket.start(
                skt => {
                    skt.invoke("StartListenToJobCalls", props.job.id)
                        .then(() => {
                            skt.on("PingJobCalls-" + props.job.id, () => {
                                refreshSet(true);
                            });
                        });
                    
                }, skt => {
                    skt.invoke("StopListenToJobCalls", props.job.id)
                        .then(() => {
                            skt.off("PingJobCalls-" + props.job.id);
                        });
                });

            return () => {
                socket.stop(socketKey);
            };
        }

    }, [props.job.id]);


    useEffect(() => {
        window.KTDrawerCreate();
        window.openCallsDrawer(clearCallsJob);
        

    }, [])

    return (
        <div id="kt_drawer_calls" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="calls" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '800px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_calls_toggle" data-kt-drawer-close="#kt_drawer_calls_close">
            <div className="card shadow-none rounded-0 w-100">
				<div className="card-header" id="kt_activities_header">
					<h3 className="card-title fw-bolder text-dark">Job Calls</h3>
					<div className="card-toolbar">
						<button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_drawer_calls_close">
							<span className="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
								</svg>
							</span>
						</button>
					</div>
				</div>
                <div className="card-body position-relative p-0" id="kt_drawer_calls_body">
                    <CallsList jobId={props.job.id} noFilter noHeader noAction compact refresh={refresh} />
                </div>
            </div>
            

        </div>
    )
}