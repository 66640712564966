import React, { useEffect, useState } from "react"
import HeaderTitle from "./HeaderTitle";
import GeneralSearch from '../../search/GeneralSearch';
import UserMenu from "./UserMenu";
import FlexRow from "../../base/FlexRow";
import Menu from "../../../core/Menu.json";
import { Link, useLocation } from "react-router-dom";
import { hasRoles } from "../../../core/Global";
import Container from "../../base/Container";
import Icon from "../../base/Icon";

function HeaderNavbar() {

  const location = useLocation()
  const [slug, slugSet] = useState<string>()

  useEffect(() => {

    const pathSegments = location.pathname.split("/").filter(Boolean);
    if (pathSegments.length > 0) {
      slugSet(pathSegments[0].toLowerCase());
    }

  }, [location.pathname]);


  const AreaHasRole = (area: any) => {
    for (var i = 0; i < area.items?.length; i++) {
      for (var j = 0; j < area.items[i].items?.length; j++) {
        if (hasRoles(area.items[i].items[j].roles)) return true;
      }
      if (area.items[i].roles) {
        if (hasRoles(area.items[i].roles)) return true;
      }
    }
    return false;
  }

  const MenuHasRole = (item: any) => {
    if (item.items) {
      for (var j = 0; j < item.items?.length; j++) {
        if (hasRoles(item.items[j].roles)) return true;
      }
    }
    if (item.roles) {
      if (hasRoles(item.roles)) return true;
    }
    return false;
  }



  return (
    <div className="d-flex align-items-center flex-grow-1" id="kt_header_nav">

      <div className="header-menu align-items-stretch me-3" data-kt-drawer="true"
        data-kt-drawer-name="header-menu"
        data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
        data-kt-swapper="true" data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

        <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-500 fw-semibold align-items-stretch"
          id="#kt_header_menu" data-kt-menu="true">

          <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="bottom-start"
            className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
            <span className="menu-link p-0">
              <i className="ki-duotone ki-element-11 text-primary" style={{ fontSize: 28 }}>
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
            </span>
            <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px">

              {
                Menu.map((area, key) =>
                  <Container key={`header-nav-area-${key}`}>
                    {
                      AreaHasRole(area) &&
                      <>
                        {
                          area.items.map((menu, k1) =>
                            <Container key={`header-nav-menu-${k1}`}>
                              {
                                MenuHasRole(menu) &&
                                <>
                                  {
                                    (menu.items && menu.items.length > 0) ?
                                      <>
                                        <div data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                                          data-kt-menu-placement="right-start"
                                          className="menu-item menu-lg-down-accordion">
                                          <span className="menu-link py-3">
                                            <span className="menu-bullet">
                                              <Icon icon={menu.icon as any} />
                                            </span>
                                            <span className="menu-title">{menu.title}</span>
                                            <span className="menu-arrow"></span>
                                          </span>
                                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                            {
                                              menu.items.map((item, k2) =>
                                                <Container key={`header-nav-item-${k2}`}>
                                                  {
                                                    hasRoles(item.roles) &&
                                                    <div className="menu-item">
                                                      <Link className="menu-link py-3" to={item.to}>
                                                        <span className="menu-bullet">
                                                          <span className="bullet bullet-dot"></span>
                                                        </span>
                                                        <span className="menu-title">{item.title}</span>
                                                      </Link>
                                                    </div>
                                                  }
                                                </Container>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <>
                                        <div className="menu-item">
                                          <Link to={menu.to ?? ""} className="menu-link py-3" >
                                            <span className="menu-bullet">
                                              <Icon icon={menu.icon as any} />
                                            </span>
                                            <span className="menu-title">{menu.title}</span>
                                          </Link>
                                        </div>
                                      </>
                                  }
                                </>
                              }
                            </Container>
                          )
                        }
                      </>
                    }
                  </Container>
                )
              }



            </div>
          </div>


        </div>
      </div>

      <GeneralSearch />
    </div>
  );
}

export default HeaderNavbar;
