import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import Job from "../../models/Job";
import { hasRole, isDemo, openEditJobClient, openEditJobDetails } from "../../../../../core/Global";

interface IProps {
    job: Job
}
export default function JobDrawerDetails(props: IProps) {


    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="wrench" size={10} className="me-2" type="solid" />
                            Job Type
                        </div>
                        <div className="ps-5">
                            {props.job.jobType ?? "-"}
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="bullhorn" size={10} className="me-2" type="solid" />
                            Job Source
                        </div>
                        <div className="ps-5">
                            {isDemo() ? "Source " + props.job.jobSource?.substring(0, 1).toUpperCase() : props.job.jobSource}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <FlexRow justify="end">
                        {
                            (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                            <IconButton icon="edit" size={14} type="solid" title="Edit Details"
                                onClick={e => {
                                    openEditJobDetails(props.job)
                                }} />
                        }

                    </FlexRow>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="file-text" size={10} className="me-2" type="solid" />
                            Job Details
                        </div>
                        <div className="ps-5">
                            {props.job.jobDetails ?? "-"}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}