import { useEffect, useState } from "react";
import { toastSuccess } from "../../../../components/base/Toast";
import DropDown from "../../../../components/form/DropDown";
import Form from "../../../../components/form/Form";
import FormTitle from "../../../../components/form/FormTitle";
import TextBox from "../../../../components/form/TextBox";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub, usePreferences } from "../../../../core/Global";
import Icon from "../../../../components/base/Icon";



export default function CRMDefaultRates() {

    const hub = useHub();
    const user = getUser();
    const pref = usePreferences();
    const [working, workingSet] = useState<boolean>(false);
    


    return (
        <Form className="form"
                    onSubmit={(body, callback) => {
                        workingSet(true);
                        hub.Post("/accounts/profile/crmsettings", body, res => {
                            if(res.success) {
                                toastSuccess("Default rates has been updated successfully!");
                            }
                            if (callback) callback(res);
                            workingSet(false);
                        });
                    }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Default Technician Rates
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                            working ? 
                            <>
                                <Icon icon="spinner-third" spin />
                                <span className="ms-2">Updating...</span>
                            </>
                            : <>Update Rates</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <TextBox name="defaultTechsRate" label="Default Rate" icon="percent" numeric maxLength={2} value={pref?.defaultTechsRate.toString()} />
                    <TextBox name="defaultCCRate" label="Credit Card Rate" icon="percent" numeric maxLength={2} value={pref?.defaultTechsCCRate.toString()} />
                    <TextBox name="defaultTechAdditionalFees" label="Tech Fee" icon="dollar-sign" numeric maxLength={2} value={pref?.defaultTechsFee.toString()} />

                    {/* <FormTitle title="Default Source Rates" margin={5} sizeClass="fs-6 fw-bolder" />

                    <TextBox name="defaultSourcesRate" label="Default Rate" icon="percent" numeric maxLength={2} value={model?.defaultSourcesRate} />
                    <TextBox name="defaultTechsFee" label="Technicians Fee" icon="dollar-sign" numeric maxLength={2} value={model?.defaultSourcesTechsFee} /> */}
                    
                </div>
            </div>
        </Form>
    )
}