import { useEffect, useState } from "react";
import Job from "../models/Job";
import Modal from "../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import FlexRow from "../../../../components/base/FlexRow";
import Avatar from "../../../../components/base/Avatar";
import JobNote from "../models/JobNote";
import { JobNoteTypes } from "../../../../core/Enums";
import Icon from "../../../../components/base/Icon";
import { toastInfo } from "../../../../components/base/Toast";
import FormTitle from "../../../../components/form/FormTitle";
import TextArea from "../../../../components/form/TextArea";
import NoteItem from "./NoteItem";
import NotesContainer from "./NotesContainer";
import Button from "../../../../components/base/Button";
import NotesTypeDropDown from "./NotesTypeDropDown";


interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobNotesModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [noteType, noteTypeSet] = useState<string>("0");
    const [notesSending, notesSendingSet] = useState<boolean>(false);
    const [clear, clearSet] = useState<boolean>(false);
    //const [notes, notesSet] = useState<JobNote[]>([]);
    const [replyItem, replyItemSet] = useState<JobNote>();

    const [notesHeight, notesHeightSet] = useState<number>();
    const setHeight = () => {

        var h = ($("#modal-job-notes").height() ?? 600);
        if (h > 1000) h = 1000;
        h = h - ($("#modal-job-notes .modal-header").innerHeight() ?? 50);
        h = h - 150; //modal margins and paddings

        var nh = h - ($("#notes-quickview-textbox-area").outerHeight() ?? 50);
        notesHeightSet(nh);
    }

    useEffect(() => {
        if (job) {
            setHeight();

        }
    }, [job])

    useEffect(() => {
        if (clear) {
            setTimeout(() => {
                clearSet(false);
            }, 1000);
        }
    }, [clear])



    useEffect(() => {
        setTimeout(() => {
            $("#txt-jobnotesmodal-message").focus();
        }, 200);
    }, [replyItem]);



    return (
        <>

            <Modal id="modal-job-notes"
                title="Job Notes"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                size="XLarge"
                // footerNoteInput footerNoteReply={replyItem} 
                // onFooterNoteReplyClose={() => replyItemSet(undefined)}
                visible open noFooter
                onClose={props.onClose}
                onOpen={() => {
                    $("#txt-jobnotesmodal-message").focus();
                }}
                onSubmit={(body, callback) => {

                    if (!body.message) {
                        if (callback) callback();
                        return;
                    }

                    notesSendingSet(true);

                    hub.Post(API_ROUTES.Jobs + "note", {
                        ...body,
                        id: job?.id,
                        replyId: replyItem?.id
                    }, res => {
                        if (res.success) {
                            //fetchNotes();
                            replyItemSet(undefined);
                            clearSet(true);
                            notesSendingSet(false);
                            $("#txt-jobnotesmodal-message").focus();
                        }

                        if (callback) callback();
                    })
                }}>
                <div className="row align-items-start">

                    <div className="col-12 col-md-8 border-end">
                        <FlexRow className="border-bottom mb-5 pb-3">
                            <h3>
                                Notes
                            </h3>
                            <div className="ms-auto">
                                <NotesTypeDropDown jobId={props.job?.id} onChange={e => noteTypeSet(e)} />
                            </div>
                        </FlexRow>
                        <NotesContainer id="job-notes-inmodal"
                            height={notesHeight}
                            refresh={clear}
                            noteType={noteType}
                            onReplyItemSet={r => replyItemSet(r)}
                            jobId={props.job?.id} />
                    </div>

                    <div className="col-12 col-md-4 px-3">
                        <FlexRow className="border-bottom mb-5 pb-3">
                            <h3>
                                New Note
                            </h3>
                        </FlexRow>
                        {
                            replyItem &&
                            <FlexRow className={"p-2 rounded text-dark fw-bold w-100 mb-1 bg-light-primary fs-8"}>
                                <Icon icon="reply" type="solid" className="me-2" />
                                <div className="fw-bolder me-2">
                                    Reply to:
                                </div>
                                <div style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: 200
                                }}>
                                    {replyItem.note}
                                </div>
                                <a href="#" className="ms-auto text-dark"
                                    onClick={e => {
                                        e.preventDefault();
                                        replyItemSet(undefined);
                                        //if(props.onFooterNoteReplyClose) props.onFooterNoteReplyClose();
                                    }}>
                                    <Icon icon="times" type="solid" className="me-2" size={11} />
                                </a>
                            </FlexRow>
                        }
                        <TextArea id="txt-jobnotesmodal-message" block name="message" placeholder="Write a note..." rows={10} clear={clear} />

                        <div className="text-end">
                            <Button color="primary" text="Submit Note" isSubmit isRunning={notesSending} />
                        </div>
                    </div>

                </div>
            </Modal>



            {/* <FlexRow id={"note-item-" + item.id} key={k} justify={(item.isMine ? "end" : "start")}>
                                    <div className={"p-5 rounded text-dark fw-bold mw-lg-75 w-500px mb-5 note-container " + 
                                        (item.noteTypes == JobNoteTypes.ChangeTech ? "bg-light-warning" 
                                        : item.noteTypes == JobNoteTypes.Cancelation ? "bg-light-danger" 
                                        : item.isMine ? "bg-light-success" : "bg-light-primary")}
                                        onDoubleClick={e=> {
                                            e.preventDefault();
                                            //replyItemSet(item);
                                        }}
                                        >
                                        <div className="fs-7" style={{
                                            paddingBottom: 10,
                                            marginBottom: 10,
                                            borderBottom: "1px solid #CCC",
                                            whiteSpace: "pre-line"
                                        }}>
                                            {
                                                item.replyId &&
                                                <div className="mb-4 cursor-pointer" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                    borderLeft: "4px solid #666",
                                                    paddingLeft: 5,
                                                    paddingRight: 5
                                                }} onClick={e=> {
                                                    e.preventDefault();
                                                    var d = $('#modal-job-notes .modal-body');
                                                    var n = $("#note-item-" + item.replyId);
                                                    d.scrollTop((d.scrollTop() ?? 0) + n.position().top - 10);
                                                    n.find(".note-container").addClass("job-note-blink");
                                                    setTimeout(() => {
                                                        n.find(".note-container").addClass("job-note-blink-off");
                                                        n.find(".note-container").removeClass("job-note-blink");
                                                    }, 500);
                                                }}>
                                                    <div className="fw-bolder">{item.replyName}</div>
                                                    <div className="fs-7" style={{
                                                        whiteSpace:"nowrap",
                                                        overflow:"hidden",
                                                        textOverflow:"ellipsis",
                                                        width: "100%"
                                                    }}>{item.replyText}</div>
                                                </div>
                                            }
                                            {item.note}
                                        </div>
                                        <FlexRow justify={(item.isMine ? "end" : "start")}>
                                            {
                                                !item.isMine && 
                                                <>
                                                    <Avatar avatarUrl={item.creatorUser?.avatarUrl} width={16} className="me-1" />
                                                    <div className="fs-9 text-gray">{item.creatorUser?.fullName}</div>
                                                    <div className="mx-2 fs-9 text-gray">|</div>
                                                </>
                                            }
                                            
                                            <div className="fs-9 text-gray">
                                                {item.createDate}
                                            </div>

                                            <div className="ms-auto me-2">
                                                <a href="#" className="text-dark"
                                                    onClick={e=> {
                                                        e.preventDefault();
                                                        window.dataTableMenu();
                                                    }}
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    <Icon icon="ellipsis-vertical" />
                                                </a>
                                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-2" data-kt-menu="true">
                                                    <div className="menu-item">
                                                        <a href="#" className="menu-link px-3" 
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                replyItemSet(item);
                                                            }}
                                                        >
                                                            <Icon icon="reply" className="me-2" />
                                                            Reply
                                                        </a>
                                                    </div>  
                                                    <div className="menu-item">
                                                        <a href="#" className="menu-link px-3" 
                                                            onClick={e => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <Icon icon="share" className="me-2" />
                                                            Send as SMS
                                                        </a>
                                                    </div>  
                                                    <div className="menu-item">
                                                        <a href="#" className="menu-link px-3" 
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                navigator.clipboard.writeText(item.note ?? "");
                                                                toastInfo("Note has been copeid to clipboard!");
                                                            }}
                                                        >
                                                            <Icon icon="copy" className="me-2" />
                                                            Copy Text
                                                        </a>
                                                    </div>  
                                                    
                                                </div>
                                            </div>
                                        </FlexRow>
                                    </div>
                                </FlexRow> */}
        </>
    )

}