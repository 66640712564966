import React, { useEffect, useState } from "react";
import $ from "jquery";
import Hub from '../../api/Hub';
import HUBResponse from '../../models/HUBResponse';
import Environment from "../../env/env";
import { FontAwesomeIcons, ListItem } from "../../core/Types";
import { useHub, useScreen } from "../../core/Global";
import Icon from "../base/Icon";

interface DropDownProps {
    id?: string
    name?: string
    label?: string
    labelUp?: boolean
    labelClass?: string
    icon?: FontAwesomeIcons
    iconColor?: string
    reset?: boolean
    small?: boolean
    solid?: boolean
    defaultValue?: string
    raw?: boolean
    forceSelectValue?: string
    selectedValues?: string[]
    autoSelectFirstItem?: boolean
    source?: any[]
    apiSource?: string
    autoFocus?: boolean
    dataText?: string
    dataValue?: string
    dataColor?: string
    block?: boolean
    children?: any
    childrenAtEnd?: boolean
    multiple?: boolean
    placeholder?: string
    required?: boolean
    disabled?: boolean
    marginBottom?: number
    parentModal?: string
    width?: number
    onSelectUpdate?: any
    visible?: boolean
    deselect?: boolean
    routeParams?: ListItem[]
    tabIndex?: number
    onSelect?: (val?: string, text?: string) => void
    onSelectItem?: (item: any) => void
    onClear?: () => void
    //onSelectDataItem?: (item: any) => void
    onDeselect?: () => void
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    onApiSourceFetched?: (data?: any) => void
}


export default function DropDown(props: DropDownProps) {

    const env = new Environment();
    const hub = useHub();
    const [loading, loadingSet] = useState<boolean>(false);
    const [items, itemsSet] = useState<any[]>();

    const [selectedValue, selectedValueSet] = useState<string>("");
    const [selectedItem, selectedItemSet] = useState<any>();

    const screen = useScreen();

    const fontClass = () => {
        if (props.small) return "fs-8";
        return "fs-7";
    }

    useEffect(() => {
        if (props.onSelectItem && selectedItem?.id) {
            items?.forEach(i => {
                if (props.onSelectItem && (i.id == selectedItem.id || i.value == selectedItem.id)) props.onSelectItem(i);
            });
        }
    }, [selectedItem])

    const inputId = "drp-" + Math.floor(Math.random() * 1000000);

    const className = () => {
        var cls = "form-select form-inputs";
        if (props.small) cls += " form-select-sm";
        if (props.solid) cls += " form-select-solid";
        if (props.autoFocus) cls += " auto-focus";
        return cls;
    };

    const fillFromApi = async () => {

        loadingSet(true);
        var q = "";
        if (props.routeParams) {
            for (var i = 0; i < props.routeParams.length; i++) {
                if (props.routeParams[i].value == null || props.routeParams[i].value == "") continue;
                if (q != "") q += "&";
                q += props.routeParams[i].name + "=" + encodeURIComponent(props.routeParams[i].value ?? "");
            }
        }
        if (q != "") q = "?" + q;

        hub.Get(props.apiSource + q, (res: HUBResponse<any[]>) => {
            if (res.data) {
                itemsSet(res.data);
                loadingSet(false);
                if (props.onApiSourceFetched) props.onApiSourceFetched(res.data);
            }
        });
    }

    const fillFromSource = () => {
        loadingSet(true);
        itemsSet(props.source);

        // setTimeout(() => {
        //     $("select[name=" + props.name + "]").val(props.defaultValue ?? "");
        //     makeSelect2(); 
        //     if (props.source && props.autoSelectFirstItem) {
        //         window.updateSelect2(props.id ?? inputId, props.source[0][props.dataValue ?? "id"]);
        //     }       
        //     loadingSet(false);
        // }, 500);
    }

    const makeSelect2 = () => {

        window.makeSelect2(props.id ?? inputId, props.multiple ?? false,
            props.selectedValues, props.defaultValue,
            data => {
                if (props.onSelect) props.onSelect(data.id, data.text);
                selectedItemSet(data);
                window.hideFormError(props.name);
            },
            data => {
                //console.log("unselect called", data);
                selectedItemSet(undefined);
                if (props.onClear) props.onClear();
                if (props.onDeselect) props.onDeselect();
            },
            props.parentModal, props.deselect ?? true);
        window.select2SearchClick();

        if (props.autoSelectFirstItem && items && items.length > 0) {
            window.updateSelect2(props.id ?? inputId, items[0][props.dataValue ?? "id"]);
            if (props.onSelect) props.onSelect(items[0][props.dataValue ?? "id"], items[0][props.dataText ?? "name"]);
            selectedItemSet(items[0]);
        }

    }


    const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
        //if(props.onSelect) props.onSelect(e.target.value);
        if (props.onChange) props.onChange(e);
        selectedValueSet(e.target.value);
    }

    useEffect(() => {
        if (props.source) fillFromSource();
    }, [props.source])

    useEffect(() => {
        if (props.onSelectUpdate) {

            window.updateSelect2OnSelect(props.id ?? inputId, data => {
                if (props.onSelect) props.onSelect(data.id, data.text);
                selectedItemSet(data);
                selectedValueSet(data.id);
            });

            window.updateSelect2OnClear(props.id ?? inputId, data => {
                selectedItemSet(undefined);
                if (props.onClear) props.onClear();
                if (props.onDeselect) props.onDeselect();
            })

        }

    }, [props.onSelectUpdate])

    useEffect(() => {
        if (props.apiSource) {
            //if (env.isDevelopment) console.log("dropdown api source changed", props.apiSource);
            //if (env.isDevelopment) console.log("props.routeParams", props.routeParams, props.name);
            fillFromApi();
        }
    }, [props.apiSource])

    useEffect(() => {
        //if(props.routeParams) fillFromApi();
    }, [props.routeParams])


    // useEffect(() => {
    //     if (props.selectedValue && !loading) {
    //         window.updateSelect2(props.id ?? inputId, props.selectedValue);
    //         if (props.onSelect) props.onSelect(props.selectedValue);
    //     }
    // }, [props.selectedValue, loading])

    useEffect(() => {

        if (props.forceSelectValue) {
            //console.log("props.forceSelectValue", props.forceSelectValue);
            window.updateSelect2(props.id ?? inputId, props.forceSelectValue);
            var item = items?.filter(f => f.id == props.forceSelectValue);
            if (item && item.length > 0) selectedItemSet(item[0]);
        }
        else if (props.forceSelectValue === "") {
            //console.log("props.forceSelectValue", "empty");
            window.updateSelect2(props.id ?? inputId, "");
        }
    }, [props.forceSelectValue, items])

    useEffect(() => {
        if (items) {
            makeSelect2();
        }
    }, [items])

    useEffect(() => {
        if (props.defaultValue) {
            window.updateSelect2(props.id ?? inputId, props.defaultValue);
            selectedValueSet(props.defaultValue);
        }
    }, [props.defaultValue])

    useEffect(() => {
        if (props.reset) {
            window.updateSelect2(props.id ?? inputId, "");
            selectedItemSet(undefined);
        }
    }, [props.reset])

    useEffect(() => {
        if (props.visible) {
            makeSelect2();
        }
    }, [props.visible])

    return (
        <>
            {
                (props.visible == undefined || props.visible) ?
                    <>

                        {
                            props.raw ?
                                <select id={props.id ?? inputId}
                                    style={{ width: props.width }}
                                    name={props.multiple ? "" : props.name}
                                    autoFocus={props.autoFocus}
                                    defaultValue={props.defaultValue}
                                    tabIndex={props.tabIndex}
                                    onChange={change}
                                    data-control="select2"
                                    multiple={props.multiple}
                                    required={props.required}
                                    disabled={props.disabled}
                                    data-placeholder={props.placeholder ?? "Choose One"}
                                    className={className()}>
                                    {
                                        !props.multiple && <option></option>
                                    }

                                    {
                                        !props.childrenAtEnd ? <>{props.children}</> : <></>
                                    }

                                    {
                                        loading && false && <option value="">Loading...</option>
                                    }
                                    {
                                        items && items.map((item, key) => <option key={key} value={item[props.dataValue ?? "id"]}>{item[props.dataText ?? "name"]}</option>)
                                    }

                                    {
                                        props.childrenAtEnd ? <>{props.children}</> : <></>
                                    }
                                </select>
                                :
                                <div className={"row align-items-center mb-" + (props.marginBottom ?? 5)}>

                                    {
                                        props.label &&
                                        <label className={`col col-12 ${(props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2"))} ${fontClass()} ${props.labelClass}`} htmlFor={props.id ?? inputId}>
                                            {
                                                props.icon &&
                                                <Icon icon={props.icon} color={props.iconColor} type="solid" className="me-1" />
                                            }
                                            {props.label}
                                            {/* {
                                        props.deselect && selectedValue != "" &&
                                        <a href="#" className="ms-2" onClick={e=>{
                                            selectedValueSet("");
                                            window.updateSelect2(props.id ?? inputId, "");
                                            if (props.onDeselect) props.onDeselect();
                                        }}>
                                            <Icon icon="times" size={11} />
                                        </a>
                                    } */}
                                            {
                                                props.labelUp && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                                            }
                                        </label>
                                    }
                                    <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                                        <select id={props.id ?? inputId}
                                            name={props.multiple ? "" : props.name}
                                            autoFocus={props.autoFocus}
                                            defaultValue={props.defaultValue}
                                            onChange={change}
                                            data-control="select2"
                                            multiple={props.multiple}
                                            required={props.required}
                                            disabled={props.disabled}
                                            tabIndex={props.tabIndex}
                                            data-placeholder={props.placeholder ?? "Choose One"}
                                            className={className()}>
                                            {
                                                !props.multiple && <option value="">--</option>
                                            }

                                            {
                                                !props.childrenAtEnd ? <>{props.children}</> : <></>
                                            }

                                            {
                                                loading && false && <option value="">Loading...</option>
                                            }
                                            {
                                                items && items.map((item, key) => <option key={key} value={item[props.dataValue ?? "id"]}>{item[props.dataText ?? "name"]}</option>)
                                            }

                                            {
                                                props.childrenAtEnd ? <>{props.children}</> : <></>
                                            }
                                        </select>
                                        {
                                            props.multiple && <input type="hidden" name={props.name} id={"hidden-" + (props.id ?? inputId)} />
                                        }
                                    </div>

                                    {
                                        props.block && props.label && false &&
                                        <div className="col col-12 col-md-3"></div>
                                    }
                                    {
                                        !props.labelUp &&
                                        <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                                            <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                                        </div>
                                    }
                                </div>
                        }

                    </>
                    :
                    <></>
            }

        </>

    )
}


interface DropDownOptionProps extends React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement> {
    value?: string,
    text?: string
}
export function DropDownOption(props: DropDownOptionProps) {

    return (
        <option value={props.value} selected={props.selected}>{props.text}</option>
    )
}