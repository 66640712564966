import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/tags.json";
import sortingProps from "../sorting/tags.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import TextBox from "../../../../components/form/TextBox";
import Section from "../../../../components/base/Section";
import DropDown from "../../../../components/form/DropDown";
import TextArea from "../../../../components/form/TextArea";
import TaxItem from "../models/TaxItem";

function TaxesList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<TaxItem>();
  const [selectedTaskAmountType, selectedTaskAmountTypeSet] = useState<string>("1");

  const [refresh, refreshSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);
  const [systemStatus, systemStatusSet] = useState<boolean>();

  const [clearForms, clearFormsSet] = useState<boolean>();


  const edit = (data: TaxItem) => {
    selectedItemSet(data);
    editModalSet(true);
    selectedTaskAmountTypeSet(data.amountType?.toString() ?? "1");
  }

  const deleteTag = (data: any) => {
    selectedItemSet(data);
    groupsRouteParamsSet([{
      name: "userId",
      value: data.ownerUserId
    }, {
      name: "excludeIds",
      value: data.id
    }])
    deleteModalSet(true);
  }

  const NameItem = (data: any) => (
    <FlexRow>
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const UserItem = (data: TaxItem) => (
    <div>
      <div>
        {data.ownerUser?.fullName}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            @{data.ownerUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: TaxItem) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  const DescriptionItem = (data: TaxItem) => {

    useEffect(() => {
      window.createBootstrapTooltip("rate-descr-" + data.id);
    }, [])

    return (
      <div id={`rate-descr-${data.id}`} data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-placement="top" title={data.description}
        className="w-150px limited-text">
        {data.description}
      </div>
    )
  }

  useEffect(() => {
    setPage("Taxes", "CRM", "Base Data");

  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Tax"
          apiRoute={API_ROUTES.Taxes}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          addCustom={() => createModalSet(true)}
          edit editCustom={edit}
          delete={hasRole("DeleteTaxes")}
          refresh={refresh}>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Amount" dataValueName="amountText" />
          <DataTableField title="Description">
            <DescriptionItem />
          </DataTableField>
          <DataTableField title="Assigned" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>

      {
        createModal &&
        <Modal id="modal-add-tax"
          title="Create New Tax"
          buttonText="Create Tax"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          onSubmit={(body, callback) => {

            var valid = true;

            if (user?.isSuperAdmin) {
              if (window.isNullOrEmpty(body.ownerUserId)) {
                window.formError("ownerUserId", "Please choose the assignee user!", "modal-add-tax");
                valid = false;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.formError("name", "Please write a valid name!", "modal-add-tax");
              valid = false;
            }

            if (window.isNullOrEmpty(body.amountType)) {
              window.formError("amountType", "Please select the amount type", "modal-add-tax");
              valid = false;
            }

            if (window.isNullOrEmpty(body.amount)) {
              window.formError("amount", "Please write a valid amount!", "modal-add-tax");
              valid = false;
            }

            if (!valid) {
              if (callback) callback();
              return
            }



            hub.Post(API_ROUTES.Taxes, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tax has been added successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system tag!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <TextBox id="txt-tax-name" label="Tax Name" name="name" placeholder="E.g. VAT, Estate Tax, etc"
              labelUp block autoFocus />

            <DropDown id="drp-taxamount-types" name="amountType" label="Amount Type" labelUp block defaultValue={selectedTaskAmountType}
              onSelect={val => selectedTaskAmountTypeSet(val ?? "1")} deselect={false}
              source={[
                {
                  id: "1",
                  name: "Percent"
                },
                {
                  id: "2",
                  name: "Fixed Rate"
                }
              ]} />

            {
              selectedTaskAmountType == "1" &&
              <TextBox label="Tax Amount" name="amount" labelUp block numeric icon="percent" maxLength={3} />
            }

            {
              selectedTaskAmountType == "2" &&
              <TextBox label="Tax Amount" name="amount" labelUp block money icon="dollar-sign" />
            }

            <TextArea label="Description" name="description" labelUp block rows={3} />

          </div>
        </Modal>
      }


      {
        editModal && selectedItem &&
        <Modal id="modal-edit-tax"
          title="Edit Tax"
          buttonText="Update Tax"
          staticBackdrop open visible
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            var valid = true;

            if (user?.isSuperAdmin) {
              if (window.isNullOrEmpty(body.ownerUserId)) {
                window.formError("ownerUserId", "Please choose the assignee user!", "modal-edit-tax");
                valid = false;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.formError("name", "Please write a valid name!", "modal-edit-tax");
              valid = false;
            }

            if (window.isNullOrEmpty(body.amountType)) {
              window.formError("amountType", "Please select the amount type", "modal-edit-tax");
              valid = false;
            }

            if (window.isNullOrEmpty(body.amount)) {
              window.formError("amount", "Please write a valid amount!", "modal-edit-tax");
              valid = false;
            }

            if (!valid) {
              if (callback) callback();
              return
            }



            hub.Put(API_ROUTES.Taxes + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tax has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system tag!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <TextBox id="txt-tax-name" label="Tax Name" name="name" placeholder="E.g. VAT, Estate Tax, etc"
              labelUp block autoFocus value={selectedItem.name} />

            <DropDown id="drp-taxamount-types" name="amountType" label="Amount Type" labelUp block defaultValue={selectedTaskAmountType}
              onSelect={val => selectedTaskAmountTypeSet(val ?? "1")} deselect={false}
              source={[
                {
                  id: "1",
                  name: "Percent"
                },
                {
                  id: "2",
                  name: "Fixed Rate"
                }
              ]} />

            {
              selectedTaskAmountType == "1" &&
              <TextBox label="Tax Amount" name="amount" labelUp block numeric icon="percent" maxLength={3} value={selectedItem.amountPercent?.toString()} />
            }

            {
              selectedTaskAmountType == "2" &&
              <TextBox label="Tax Amount" name="amount" labelUp block money icon="dollar-sign" value={selectedItem.amountFixed?.toString()} />
            }

            <TextArea label="Description" name="description" labelUp block rows={3} value={selectedItem.description} />

          </div>
        </Modal>
      }


      {
        deleteModal && selectedItem &&
        <Modal id="modal-delete-tag"
          title="Delete Tag"
          buttonText="Delete Tag"
          buttonColor="danger"
          open visible staticBackdrop
          onClose={() => {
            deleteModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            hub.Post(API_ROUTES.Tags + "delete/" + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Tag has been deleted successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>

          </div>
        </Modal>
      }





    </Layout>
  );
}

export default TaxesList;
