import FlexRow from "../../../../components/base/FlexRow";
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import ColorPicker from "../../../../components/form/ColorPicker";
import TextBox from "../../../../components/form/TextBox";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import Tagify from "../../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub } from "../../../../core/Global";

interface IProps {
  onClose?: () => void
  onCreated?: () => void
}
export default function JobStatusCreateModal(props: IProps) {

  const hub = useHub();
  const user = getUser();

  return (
    <Modal id="modal-add-jobstatus"
      title="Create Job Status"
      buttonText="Add Status"
      staticBackdrop visible open
      onOpen={() => {
        $("#txt-addstatus-name").focus();
      }}
      onClose={props.onClose}
      onSubmit={(body, callback) => {

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-add-jobstatus");
            if (callback) callback();
            return;
          }
        }

        if (!body.name || body.name == "" || body.name.length < 2) {
          window.showFormError("name", "Please write a valid name!", false, "modal-add-jobstatus");
          if (callback) callback();
          return;
        }

        hub.Post(API_ROUTES.JobStatuses, body, res => {
          if (res.success) {
            toastSuccess("Job status has been added successfully!");
            if (props.onCreated) props.onCreated();
          }
          if (callback) callback(res);
        })

      }}>
      <div>
        {
          user?.isSuperAdmin &&
          <>
            <div className="fs-7 fw-bold">
              Assign to
              <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
            </div>
            <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
          </>
        }

        <FlexRow className="mb-10">
          <ColorPicker id="color-add-jobstatus" width={30} className="me-2" name="color" />
          <div className="flex-grow-1">
            <TextBox id="txt-addstatus-name" name="name" block marginBottom={0} inlineStyle mediumText noTextError placeholder="Status Name" />
          </div>
        </FlexRow>


        <ToggleSwitch name="noteRequired" raw label="Note Required" />

      </div>
    </Modal>
  )
}