import Icon from "../../../components/base/Icon"
import { ChatEnvironments } from "../../../core/Enums"
import ChatContactItem from "../models/ChatContactItem"


interface IProps {
    item?: ChatContactItem
    size?: number
    classNames?: string
}
export default function ChatAvatarSpan(props: IProps) {

    const classNames = () => {
        var cls = "symbol-label fw-bolder ";
        if (props.item?.tech) {
            // if (props.item?.environment == ChatEnvironments.App) cls += "bg-light-primary text-primary";
            // else 
            cls += "bg-light-danger text-danger";
        }
        else cls += "bg-light-success text-success"



        if (props.classNames) cls += " " + props.classNames;

        return cls;
    }


    return (
        <span className={classNames()}>
            {
                props.item?.tech ? <Icon icon="user-helmet-safety" size={props.size ?? 18} />
                    : <Icon icon="mobile-retro" size={props.size ?? 18} />
            }
        </span>
    )
}