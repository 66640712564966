import React, { useEffect, useState } from "react";

interface ToggleSwitchProps {
    id?: string
    name?: string
    label?: string
    small?: boolean
    checked?: boolean
    defaultChecked?: boolean
    required?: boolean
    raw?: boolean
    value?: string
    className?: string
    onChange?: (e: boolean) => void
}

export default function ToggleSwitch(props: ToggleSwitchProps) {
    const [checked, checkedSet] = useState<boolean>();
    const inputId = props.id ?? "txt-" + (props.name ?? Math.floor(Math.random() * 1000000));
    let changed = false;
    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
        changed = true;
        checkedSet(e.target.checked);
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
    }

    const className = () => {
        var cls = "form-check form-switch form-check-custom form-check-solid";
        if (props.small) cls += " form-switch-sm";
        return cls;
    };

    useEffect(() => {
        if (!changed) checkedSet(props.defaultChecked);
    }, []);

    useEffect(() => {
        checkedSet(props.checked);
    }, [props.checked])

    return (
        <>
            {
                checked ? <input key={`chk-${props.name}-checked`} type="hidden" name={props.name} value={props.value ?? "True"} />
                    : <input key={`chk-${props.name}-unchecked`} type="hidden" name={props.name} value={(props.value ? undefined : "False")} />
            }

            {
                props.raw &&
                <div className={"d-flex align-items-center" + (props.className ? " " + props.className : "")}>

                    <div className={className()}>
                        <input id={inputId} className="form-check-input" type="checkbox"
                            checked={checked} defaultChecked={props.defaultChecked} onChange={change} />
                    </div>

                    {
                        props.label &&
                        <label className={"ms-2" + (props.small ? " fs-7" : " fs-6")} htmlFor={inputId}>
                            {props.label}
                        </label>
                    }
                </div>
            }
            {
                !props.raw &&
                <div className="row align-items-center mb-10">
                    {
                        props.label &&
                        <label className="col col-md-2 fw-bold fs-6 mb-2" htmlFor={inputId}>
                            {props.label}
                        </label>
                    }
                    <div className={"col " + (props.required ? "col-md-6" : "col-md-10")}>
                        <label className={className()}>
                            <input id={inputId} className="form-check-input" type="checkbox"
                                checked={checked} defaultChecked={props.defaultChecked} onChange={change} />
                        </label>
                    </div>
                    {
                        props.required &&
                        <div className="col col-md-4">
                            <span id={props.name + "-err"} className="small text-danger mx-2 form-error"></span>
                        </div>
                    }
                </div>
            }
        </>

    )
}