import React from "react"
import { BootstrapColors, FontAwesomeIcons } from "../../core/Types";

export function ToastContainer() {

    return (
        <div id="kt_docs_toast_stack_container" className="toast-container position-fixed end-0 p-3"></div>
    );
}

export function toastError(message?: string) {
    window.makeToast("danger", message ?? "something wrong!");
}
export function toastInfo(message: string) {
    window.makeToast("info", message);
}
export function toastSuccess(message: string) {
    window.makeToast("success", message);
}
export function toastDefault(message: string) {
    window.makeToast("dark", message);
}

export function toastNotification(type?: string, message?: string, icon?: FontAwesomeIcons, title?: string, color?: string, onclick?: () => void) {
    window.makeNotificationToast(type ?? "primary", message ?? "", icon ?? "circle-dot", title, color, onclick);
}
