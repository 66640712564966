import React, { useEffect, useState } from "react";



type TimerProps = {
    totalSeconds?: number
    showHours?: boolean,
}

function Time(props: TimerProps) {

    const showTime = () => {
        var sec_num = Math.floor(props.totalSeconds ?? 0);

        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        var result = "";

        if (hours > 0 || props.showHours) result += (hours < 10 ? "0" + hours : hours) + ":";
        result += (minutes < 10 ? "0" + minutes : minutes) + ":";
        result += (seconds < 10 ? "0" + seconds : seconds);
        
        return result;
    }

    return (
        <span>
            {showTime()}
        </span>
    )
}

export default Time;

