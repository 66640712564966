import { FontAwesomeIcons } from "../../core/Types";
import Icon from "./Icon";

interface IProps {
    id?: string
    icon: FontAwesomeIcons
    type?: "solid" | "regular" | "light" | "thin" | "brands"
    size?: number
    gap?: number
    className?: string
    title?: string
    working?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function IconButton(props: IProps) {


    return (
        <button id={props.id} type="button" title={props.title} disabled={props.working}
            className={"btn btn-sm btn-white btn-icon " + props.className}
            style={{
                borderRadius: "50%",
                width: (props.size ?? 26) + (props.gap ?? 20),
                height: (props.size ?? 26) + (props.gap ?? 20),
            }}
            onClick={e => {
                e.preventDefault();
                if (props.onClick) props.onClick(e)
            }}>
            {
                props.working ?
                    <Icon icon={"spinner-third"} spin type={props.type ?? "thin"} size={props.size ?? 26} />
                    :
                    <Icon icon={props.icon} type={props.type ?? "thin"} size={props.size ?? 26} />
            }

        </button>
    )
}