import { useEffect } from "react"
import { closeAnnouncements, dismissAnnouncement, useAnnouncements, useHub } from "../../../../core/Global"
import AnnouncementView from "./AnnouncementView";
import { API_ROUTES } from "../../../../core/Constants";




export default function AnnouncementDrawer() {

    const hub = useHub()
    const announcements = useAnnouncements()



    useEffect(() => {
        window.KTDrawerCreate();
        window.openAnnouncementsDrawer(closeAnnouncements);
    }, [])

    return (
        <div id="kt_announcements" className="bg-body" data-kt-drawer="true"
            data-kt-drawer-name="announcements" data-kt-drawer-activate="true"
            data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'lg': '700px'}"
            data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_announcements_toggle"
            data-kt-drawer-close="#kt_announcements_close">
            <div className="card shadow-none rounded-0 w-100">
                <div className="card-header" id="kt_announcements_header">
                    <h3 className="card-title fw-bolder text-dark">Announcements</h3>
                    <div className="card-toolbar">
                        <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_announcements_close">
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="card-body position-relative" id="kt_announcements_body">

                    {
                        announcements && announcements.length > 0 ?
                            <div className="position-relative scroll-y me-n5 pe-5"
                                data-kt-scroll="true" data-kt-scroll-wrappers="#kt_announcements_body"
                                data-kt-scroll-dependencies="#kt_announcements_header"
                                data-kt-scroll-offset="5px">
                                <div className="timeline">
                                    {
                                        announcements?.map(item =>
                                            <AnnouncementView key={`announcement-view-${item.id}`} item={item}
                                                onDismiss={e => {
                                                    dismissAnnouncement(e)
                                                    hub.Post(API_ROUTES.Announcements + "dismiss", {
                                                        id: e.id
                                                    }, res => { })
                                                }} />
                                        )
                                    }
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-px text-center pt-15 pb-15">
                                        <h2 className="fs-2x fw-bolder mb-0">Nothing has happened!</h2>
                                        <p className="text-gray-400 fs-4 fw-bold py-7">
                                            There is no announcement for today!
                                        </p>
                                    </div>
                                    <div className="text-center pb-15 px-5">
                                        <img src="/assets/media/illustrations/sketchy-1/16.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </div>
    )

}