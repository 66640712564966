import { useState } from "react";
import { AgentOverviewTimeTable } from "../../modules/agents/models/AgentOverviewModel";
import Icon from "../base/Icon";
import Time from "../timer/Time";

type Props = {
    userId?: string
 }
export default function AccountAgentTalkingActivityChart(props: Props) {

    const [model, modelSet] = useState<AgentOverviewTimeTable>()

    return (
        <div className="card card-flush ">
            <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
                <div className="d-flex align-items-center">
                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                    <Time totalSeconds={model?.talkTime} showHours />
                </span>
                </div>
                <span className="text-gray-400 pt-1 fw-bold fs-6">
                    <Icon icon="phone" type="solid" className="me-1" />
                    Talked Today
                </span>
            </div>
            </div>
            <div className="card-body pt-2 pb-4 d-flex align-items-center">
            <div className="d-flex flex-center me-5 pt-2" style={{width:'50%'}}>
                <div id="chart-agent-timetable" data-kt-size="70" data-kt-line="11"></div>
            </div>
            <div className="d-flex flex-column content-justify-center w-100">
                <div className="d-flex fs-6 fw-bold align-items-center">
                    <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Active Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.activeTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Talk Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.talkTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-warning me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Away Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.awayTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2">
                    <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Paused Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        <Time totalSeconds={model?.pausedTime} showHours />
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2 d-none">
                    <div className="bullet w-8px h-6px rounded-2 bg-info me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Hold Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        00:00:00
                    </div>
                </div>
                <div className="d-flex fs-6 fw-bold align-items-center mt-2 d-none">
                    <div className="bullet w-8px h-6px rounded-2 bg-secondary me-3"></div>
                    <div className="text-gray-500 flex-grow-1 me-4 fs-7">Unreachable Time</div>
                    <div className="fw-boldest text-gray-700 text-xxl-end">
                        00:00:00
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}