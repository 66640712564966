import { useEffect, useRef, useState } from "react"
import FlexRow from "../../../components/base/FlexRow"
import IconButton from "../../../components/base/IconButton"
import { closeMessageToJob } from "../../../core/Global"
import "./MessageToJobFloat.css"


interface IProps {
    msg: string
}
export default function MessageToJobFloat(props: IProps) {


    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const ref = useRef<HTMLDivElement>(null);

    const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        //ref.current!.style.cursor = 'grabbing';
        setPosition({
            x: event.clientX - ref.current!.offsetLeft,
            y: event.clientY - ref.current!.offsetTop,
        });
    };

    const onMouseMove = (event: MouseEvent) => {
        if (isDragging) {
            const x = event.clientX - position.x;
            const y = event.clientY - position.y;
            ref.current!.style.left = `${x}px`;
            ref.current!.style.top = `${y}px`;
        }
    };

    const onMouseUp = () => {
        setIsDragging(false);
        //ref.current!.style.cursor = 'grab';
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        } else {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [isDragging]);



    return (
        <div ref={ref}
            className="message-to-job-float">
            <FlexRow onMouseDown={onMouseDown}>
                <h3>
                    Original Message
                </h3>
                <IconButton icon="times" className="ms-auto"
                    onClick={e => {
                        closeMessageToJob()
                    }} />
            </FlexRow>
            <div className="message-to-job-text">
                {props.msg}
            </div>
        </div>
    )
}