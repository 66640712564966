import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import Environment from "../../../env/env";
import Country from "../models/Country";
import {DataForm, DataTable, DataTableField} from "../../../components/data/DataTable";
import Hub from "../../../api/Hub";
import { API_ROUTES } from '../../../core/Constants';
import { CountryValidator } from '../validators/CountryValidator';
import CountryForm from "./CountryForm";
import { setPage } from '../../../core/Global';
import Section from "../../../components/base/Section";
import DataTableActiveToggle from "../../../components/data/DataTableActiveToggle";

const hub = new Hub<Country>(API_ROUTES.Countries);

function CountryList() {

  useEffect(()=> {
    setPage("Countries", "System");
  }, [])

  
  return (
    <Layout wide>
      <Section fluid>
        <DataTable
                objectName="Country"
                apiRoute={API_ROUTES.Countries}
                preventCreate ascending>
                  <DataTableField title="" width={50}>
                    <DataTableActiveToggle route={API_ROUTES.Countries}/>
                  </DataTableField>
                <DataTableField title="Title" dataValueName="name" dataValueImage="flagUrl" dataImageWidth={24} />
                <DataTableField title="Alpha2Code" dataValueName="alpha2Code" />
                <DataTableField title="Alpha3Code" dataValueName="alpha3Code" />
                <DataTableField title="Phone Code" dataValueName="phoneCode" />

        </DataTable>
      </Section>
    </Layout>
  );
}

export default CountryList;
