import React, { useEffect, useState } from "react"
import TextBox from '../../../../components/form/TextBox';
import DropDown, { DropDownOption } from '../../../../components/form/DropDown';
import Queue from '../models/Queue';
import Icon from "../../../../components/base/Icon";
import ToggleSwitch from '../../../../components/form/ToggleSwitch';
import FormPage from '../../../../components/form/FormPage';
import FormTitle from '../../../../components/form/FormTitle';
import Layout from '../../../../components/layouts/Layout';
import { setPage, useGlobal } from '../../../../core/Global';
import { API_ROUTES } from '../../../../core/Constants';
import { useParams } from 'react-router-dom';
import Indicator from '../../../../components/base/Indicator';
import Div from '../../../../components/base/Div';
import Hub from '../../../../api/Hub';
import UserPicker from "../../../../components/pickers/UserPicker";
import CompanyPicker from '../../../../components/pickers/CompanyPicker';
import { UserTypes } from '../../../../core/Enums';
import Tagify from "../../../../components/tagify/Tagify";
import { ListItem } from "../../../../core/Types";



function QueueForm() {
    const { selectedId } = useParams();
    const [globalState] = useGlobal();
    const [voicemail, voicemailSet] = useState<boolean>(false);
    const [loading, loadingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Queue>();
    const [customerSelectedId, customerSelectedIdSet] = useState<string>();
    const [customerId, customerIdSet] = useState<string>();
    const [companyId, companyIdSet] = useState<string>();
    const [clearCompany, clearCompanySet] = useState<boolean>();

    const [companiesRouteParams, companiesRouteParamsSet] = useState<ListItem[]>([]);
    const [usersRouteParams, usersRouteParamsSet] = useState<ListItem[]>([{
        name: "type",
        value: UserTypes.Customer.toString()
    }]);
    const [extsRouteParams, extsRouteParamsSet] = useState<ListItem[]>([
        {
            name: "free",
            value: "true"
        },
        {
            name: "queueId",
            value: selectedId
        }
    ]);

    const hub = new Hub<Queue>(API_ROUTES.Queues);

    var t = 0;

    const MaxWaitTimes = () => {
        var arr = [
            {
                id: 0,
                name: "Unlimited"
            },
            {
                id: 5,
                name: "5 Seconds"
            },
            {
                id: 10,
                name: "10 Seconds"
            },
            {
                id: 20,
                name: "20 Seconds"
            },
            {
                id: 30,
                name: "30 Seconds"
            },
            {
                id: 40,
                name: "40 Seconds"
            },
            {
                id: 50,
                name: "50 Seconds"
            }
        ];

        for (var i = 1; i <= 120; i++) arr.push({
            id: i * 60,
            name: i + " Minutes"
        });
        return arr;
    }

    const RetryTimes = () => {
        var arr = [{
            id: -1,
            name: "No Retry"
        }];
        for (var i = 0; i <= 60; i++) arr.push({
            id: i,
            name: i + " Seconds"
        });
        return arr;
    }

    const AgentTimouts = () => {
        var arr = [{
            id: 0,
            name: "Unlimited"
        }];
        for (var i = 1; i <= 120; i++) arr.push({
            id: i,
            name: i + " Seconds"
        });
        return arr;
    }

    const WrapUpTimes = () => {
        var arr = [];
        for (var i = 0; i < 60; i++) arr.push({
            id: i,
            name: i + " Seconds"
        });
        for (var i = 1; i <= 60; i++) {
            arr.push({
                id: i * 60,
                name: i + " Minutes"
            });
            arr.push({
                id: (i * 60) + 30,
                name: i + " Minutes & 30 Seconds"
            });
        }
        return arr;
    }

    const DelayTimes = () => {
        var arr = [];
        for (var i = 0; i <= 60; i++) arr.push({
            id: i,
            name: i + " Seconds"
        });
        return arr;
    }

    const FrequencyTimes = () => {
        var arr = [{
            id: 0,
            name: "0 Seconds"
        },
        {
            id: 15,
            name: "15 Seconds"
        },
        {
            id: 30,
            name: "30 Seconds"
        },
        {
            id: 45,
            name: "45 Seconds"
        }];
        for (var i = 1; i <= 20; i++) {
            arr.push({
                id: i * 60,
                name: i + " Minutes"
            });
            arr.push({
                id: (i * 60) + 15,
                name: i + " Minutes & 15 Seconds"
            });
            arr.push({
                id: (i * 60) + 30,
                name: i + " Minutes & 30 Seconds"
            });
            arr.push({
                id: (i * 60) + 45,
                name: i + " Minutes & 45 Seconds"
            });
        }
        return arr;
    }

    const init = async () => {
        if (selectedId) {

            setPage("Queues Advanced Settings", "Voice", "PBX Modules");
            loadingSet(true);
            await hub.Find(selectedId, res => {
                if (res.success) {
                    modelSet(res.data);
                    customerIdSet(res.data?.ownerUserId);
                    if (res.data?.ownerUserId) {
                        updateExtRouteParams("userId", res.data?.ownerUserId);
                        updateCompaniesRouteParams("userId", res.data?.ownerUserId);
                    }
                    loadingSet(false);
                }
                else {

                }
            });
        }
        else {
            document.location.href = "/voice/pbx/queues";
        }

    }

    const updateCompaniesRouteParams = (name: string, value: string) => {
        var arr = companiesRouteParams;
        var exists = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == name) {
                arr[i].value = value;
                exists = true;
                break;
            }
        }
        if (!exists) arr.push({
            name: name,
            value: value
        });
        companiesRouteParamsSet(c => [...arr])
    }

    const updateExtRouteParams = (name: string, value: string) => {
        var arr = extsRouteParams;
        var exists = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == name) {
                arr[i].value = value;
                exists = true;
                break;
            }
        }
        if (!exists) arr.push({
            name: name,
            value: value
        });
        extsRouteParamsSet(c => [...arr])
    }

    useEffect(() => {
        init();
    }, []);


    return (
        <Layout>

            <Div visible={loading} className="p-20 text-center">
                <Indicator text="Please wait..." />
            </Div>

            <FormPage apiRoute={API_ROUTES.Queues}
                selectedId={selectedId}
                objectName="Queue"
                visible={!loading}
                redirectTo="/voice/pbx/queues">

                <div className="row">
                    <div className="col-sm-2 big-icon">
                        <Icon icon="phone-arrow-down-left" type="thin" />
                    </div>
                    {
                        model?.id &&
                        <div className="col-sm-10 pt-5">
                            <TextBox name={model ? "" : "QueueNumber"} placeholder="Queue Number"
                                defaultValue={model?.queueNumber?.toString() + " - " + model.name} readOnly
                                inlineStyle largeText noMarginBottom />
                        </div>
                    }

                </div>

                {
                    globalState.user?.isSuperAdmin ?
                        <>
                            <FormTitle title="Assign To" />

                            <Tagify name="ServerId" id="pickServer" placeholder="Select a Server" apiUrl={API_ROUTES.TagifyPBXServers}
                                defaultId={model?.serverId} single />

                            <Tagify name="customerId" id="pickUser" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyUsers}
                                defaultId={model?.ownerUserId} single routeParams={usersRouteParams} selectedId={customerSelectedId}
                                onSelect={val => {
                                    customerIdSet(val);
                                    updateCompaniesRouteParams("userId", val);
                                    //updateExtRouteParams("userId", val);
                                    clearCompanySet(true);
                                    setTimeout(() => clearCompanySet(false), 300);
                                }}
                            />

                            <Tagify name="companyId" id="pickCompany" placeholder="Select a Company" apiUrl={API_ROUTES.TagifyCompaneis}
                                routeParams={companiesRouteParams} defaultId={model?.companyId} single
                                onSelect={val => {
                                    hub.Get("/accounts/companies/" + val, res => {
                                        customerSelectedIdSet(res.data.userIds[0]);
                                        companyIdSet(val);
                                    })
                                }} />
                        </>
                        :
                        <>
                            {/* <input type="hidden" name="ServerId" />
                        <input type="hidden" name="customerId" />
                        <input type="hidden" name="customerId" /> */}
                        </>
                }

                <FormTitle title="General Settings" />

                <TextBox label="Caller ID" name="CallerID" maxLength={25} defaultValue={model?.callerID}
                    tooltip="You can optionally prefix the CallerID name of callers to the queue. ie: If you prefix with 'Sales:', a call from John Doe would display as 'Sales:John Doe' on the extensions that ring." />
                <TextBox label="Queue Weight" name="QueueWeight" numeric defaultValue={(model?.queueWeight ?? 0).toString()} />
                <DropDown id="drp-ringstrategy" label="Ring Strategy" name="RingStrategy" defaultValue={(model?.ringStrategy ?? 1).toString()}>
                    <DropDownOption value="1" text="RingAll" />
                    <DropDownOption value="2" text="LeastRecent" />
                    <DropDownOption value="3" text="FewestCalls" />
                    <DropDownOption value="4" text="Random" />
                    <DropDownOption value="5" text="RRMemory" />
                    <DropDownOption value="6" text="RROrdered" />
                    <DropDownOption value="7" text="Linear" />
                    <DropDownOption value="8" text="WRandom" />
                </DropDown>
                <DropDown id="drp-recoding" label="Recording Settings" name="RecordingSettings" defaultValue={(model?.recordingSettings ?? 1).toString()}>
                    <DropDownOption value="1" text="Force" />
                    <DropDownOption value="2" text="Don't Care" />
                    <DropDownOption value="3" text="Never" />
                </DropDown>
                <DropDown id="drp-skipbusyagents" label="Skip Busy Agents" name="SkipBusyAgents" defaultValue={(model?.skipBusyAgents ?? 1).toString()}>
                    <DropDownOption value="1" text="Don't Skip" />
                    <DropDownOption value="2" text="Skip" />
                    <DropDownOption value="3" text="Skip (RingInUse = no)" />
                    <DropDownOption value="4" text="Queue Calls Only (RingInUse = no)" />
                </DropDown>

                <FormTitle title="Sound/Music Settings" />

                <DropDown id="drp-moh" label="Music on Hold Class" name="MusicOnHold" defaultValue={(model?.musicOnHold ?? 1).toString()}>
                    <DropDownOption value="1" text="No play" />
                    <DropDownOption value="2" text="Play till answer" />
                </DropDown>
                <DropDown id="drp-announcement" label="Join Announcement" name="Announcement" defaultValue={(model?.announcement ?? 1).toString()}>
                    <DropDownOption value="1" text="Always" />
                    <DropDownOption value="2" text="When No Free Agents" />
                    <DropDownOption value="2" text="When No Ready Agents" />
                </DropDown>
                <DropDown id="drp-sound" label="Sound">
                    <DropDownOption value="inherit" text="Inherit" />
                    <DropDownOption value="default" text="Default" />
                    <DropDownOption value="none" text="None" />
                </DropDown>
                <DropDown id="drp-audio" label="Audio">
                </DropDown>

                <FormTitle title="Timing & Agents Settings" />
                <DropDown id="drp-maxwait" label="Max Wait Time" name="MaxWaitTime" source={MaxWaitTimes()} defaultValue={(model?.maxWaitTimeMode ?? 0).toString()} />
                <DropDown id="drp-maxwaitmodes" label="Wait Time Reached" name="MaxWaitTimeMode" defaultValue={(model?.maxWaitTimeMode ?? 1).toString()}>
                    <DropDownOption value="1" text="Strict (remove call from the queue)" />
                    <DropDownOption value="2" text="Loose (assign the call to another agent)" />
                </DropDown>
                <DropDown id="drp-AgentTimeOut" label="Agent TimeOut" name="AgentTimeOut" source={AgentTimouts()} defaultValue={(model?.agentTimeOut ?? 15).toString()} />
                <DropDown id="drp-retrytime" label="Retry Time" name="RetryTime" source={RetryTimes()} defaultValue={(model?.retryTime ?? 5).toString()} />
                <DropDown id="drp-WrapUpTime" label="Wrap-Up-Time" name="WrapUpTime" source={WrapUpTimes()} defaultValue={(model?.wrapUpTime ?? 0).toString()} />
                <DropDown id="drp-ConnectionDelay" label="Connection Delay" name="ConnectionDelay" source={DelayTimes()} defaultValue={(model?.connectionDelay ?? 0).toString()} />


                <FormTitle title="Capacity Options" />
                <TextBox label="Max Callers" name="MaxCallers" numeric defaultValue={(model?.maxCallers ?? 0).toString()} />
                <DropDown id="drp-JoinEmpty" label="Join Empty" name="JoinEmpty" defaultValue={(model?.joinEmpty ?? 1).toString()}>
                    <DropDownOption value="1" text="Yes" />
                    <DropDownOption value="2" text="Strict" />
                    <DropDownOption value="3" text="UltraStrict" />
                    <DropDownOption value="4" text="No" />
                    <DropDownOption value="5" text="Loose" />
                </DropDown>
                <DropDown id="drp-LeaveEmpty" label="Leave Empty" name="LeaveEmpty" defaultValue={(model?.leaveEmpty ?? 4).toString()}>
                    <DropDownOption value="1" text="Yes" />
                    <DropDownOption value="2" text="Strict" />
                    <DropDownOption value="3" text="UltraStrict" />
                    <DropDownOption value="4" text="No" />
                    <DropDownOption value="5" text="Loose" />
                </DropDown>

                <FormTitle title="Caller Announcements" />
                <DropDown id="drp-Frequency" label="Frequency" name="Frequency" source={FrequencyTimes()} defaultValue={(model?.frequency ?? 0).toString()} />
                <DropDown id="drp-AnnouncePosition" label="Announce Position" name="AnnouncePosition" defaultValue={model?.announcePosition ?? "no"}>
                    <DropDownOption value="yes" text="Yes" />
                    <DropDownOption value="no" text="No" />
                </DropDown>
                <DropDown id="drp-AnnounceHoldTime" label="Announce Hold Time" name="AnnounceHoldTime" defaultValue={model?.announceHoldTime ?? "no"}>
                    <DropDownOption value="yes" text="Yes" />
                    <DropDownOption value="no" text="No" />
                    <DropDownOption value="once" text="Once" />
                </DropDown>


                {/* <TextBox label="Email Address" name="Email" email defaultValue={model?.email} />
                <DropDown id="drp-recoding" label="Recording Settings" name="RecordingSettings" defaultValue={model?.recordingSettings?.toString()}>
                    <DropDownOption value="1" text="Force" />
                    <DropDownOption value="2" text="Don't Care" />
                    <DropDownOption value="3" text="Never" />
                </DropDown>
                <ToggleSwitch label="WebRTC" name="WebRTC" defaultChecked={model?.webRTC} />
                <ToggleSwitch label="Call History" name="CallHistory" defaultChecked={model?.callHistory} />


                <FormTitle title="Voicemail Settings" /> */}

            </FormPage>


        </Layout>
    );
}

export default QueueForm;
