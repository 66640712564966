import { useEffect, useState } from "react";
import { toastSuccess } from "../../../../components/base/Toast";
import Form from "../../../../components/form/Form";
import { getUser, useHub, usePreferences } from "../../../../core/Global";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";



export default function SendJobSettings() {

    const hub = useHub()
    const user = getUser()
    const pref = usePreferences()
    const [working, workingSet] = useState<boolean>(false)

    const [companyName, companyNameSet] = useState<boolean>(false)
    const [sourceName, sourceNameSet] = useState<boolean>(false)
    const [jobId, jobIdSet] = useState<boolean>(false)
    const [clientName, clientNameSet] = useState<boolean>(false)
    const [clientPhone, clientPhoneSet] = useState<boolean>(false)
    const [directNumber, directNumberSet] = useState<boolean>(false)
    const [address, addressSet] = useState<boolean>(false)
    const [jobType, jobTypeSet] = useState<boolean>(false)
    const [jobDetails, jobDetailsSet] = useState<boolean>(false)
    const [appointment, appointmentSet] = useState<boolean>(false)
    const [confirmLink, confirmLinkSet] = useState<boolean>(false)

    const [sampleText, sampleTextSet] = useState<string>("")

    const [error, errorSet] = useState<boolean>()

    useEffect(() => {
        if (pref?.jobSendSettings) {
            companyNameSet(pref.jobSendSettings.companyName ?? false)
            sourceNameSet(pref.jobSendSettings.sourceName ?? false)
            jobIdSet(pref.jobSendSettings.jobId ?? false)
            clientNameSet(pref.jobSendSettings.clientName ?? false)
            clientPhoneSet(pref.jobSendSettings.clientPhone ?? false)
            directNumberSet(pref.jobSendSettings.directNumber ?? false)
            addressSet(pref.jobSendSettings.address ?? false)
            jobTypeSet(pref.jobSendSettings.jobType ?? false)
            jobDetailsSet(pref.jobSendSettings.jobDetails ?? false)
            appointmentSet(pref.jobSendSettings.appointment ?? false)
            confirmLinkSet(pref.jobSendSettings.confirmLink ?? false)

        }
    }, [pref])

    useEffect(() => {
        var text = "";
        errorSet(false)
        if (companyName) {
            text += "[Company]";
            text += "\n";
        }

        if (sourceName) {
            text += "Source: Source Name";
            text += "\n";
        }

        if (jobId) {
            text += "Job ID: #F43210";
            text += "\n";
        }

        if (clientName) {
            text += "Name: Jack Black";
            text += "\n";
        }

        if (clientPhone && directNumber) {
            text += "Phone: +12223334455";
            text += "\n";
        }

        if (clientPhone && !directNumber) {
            text += "Phone: +14444445566,2200";
            text += "\n";
        }

        if (address) {
            text += "Address: 6857 Hollywood Blvd, Los Angeles, CA 90028-6152";
            text += "\n";
        }

        if (jobType) {
            text += "Job: Car Key";
            text += "\n";
        }

        if (appointment) {
            text += "APP: 06 Aug, 16:00";
            text += "\n";
        }

        if (jobDetails) {
            text += "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
            text += "\n";
        }

        if (confirmLink) {
            text += "\n";
            text += "to confirm: https://dsp.cx/XXXXX";
            text += "\n";
        }


        sampleTextSet(text);

    }, [companyName, sourceName, jobId, clientName, clientPhone, directNumber, address, jobType, jobDetails, appointment, confirmLink])


    return (
        <Form className="form"
            onSubmit={(body, callback) => {
                errorSet(false)
                var s = 0;
                if (companyName) s++
                if (sourceName) s++
                if (jobId) s++
                if (clientName) s++
                if (clientPhone) s++
                if (address) s++
                if (jobType) s++
                if (appointment) s++
                if (jobDetails) s++

                if (s < 2) {
                    errorSet(true)
                    return
                }
                workingSet(true);
                hub.Post("/system/settings/jobsendsettings", {
                    companyName, sourceName, jobId, clientName, clientPhone, directNumber, address, jobType, appointment, jobDetails, confirmLink
                }, res => {
                    if (res.success) {
                        toastSuccess("Send job settings has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Send Job Settings
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Settings</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-4 fw-bold fs-6">
                                Send to Technician
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Company Name" raw small checked={companyName} onChange={e => companyNameSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Source" raw small checked={sourceName} onChange={e => sourceNameSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Job ID" raw small checked={jobId} onChange={e => jobIdSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Client Name" raw small checked={clientName} onChange={e => clientNameSet(e)} />
                            </div>
                            <FlexRow className="mb-4">
                                <ToggleSwitch label="Client Phone" raw small className="me-10" checked={clientPhone} onChange={e => clientPhoneSet(e)} />
                                {
                                    clientPhone &&
                                    <ToggleSwitch label="Direct Number" raw small checked={directNumber} onChange={e => directNumberSet(e)} />
                                }
                            </FlexRow>
                            <div className="mb-4">
                                <ToggleSwitch label="Address" raw small checked={address} onChange={e => addressSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Job Type" raw small checked={jobType} onChange={e => jobTypeSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Appointment" raw small checked={appointment} onChange={e => appointmentSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Job Details" raw small checked={jobDetails} onChange={e => jobDetailsSet(e)} />
                            </div>
                            <div className="mb-4">
                                <ToggleSwitch label="Confirm Link" raw small checked={confirmLink} onChange={e => confirmLinkSet(e)} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <FlexRow className="mb-4">
                                <span className="fw-bold fs-6 me-2">
                                    Sample Text
                                </span>
                                {
                                    error &&
                                    <span className="text-danger fs-8">
                                        Please select at least 2 items!
                                    </span>
                                }
                            </FlexRow>
                            <textarea className="form-control form-control-sm" rows={15}
                                style={{ resize: "none" }} value={sampleText}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}