import { HubConnectionState } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import Icon from "../../components/base/Icon";
import { useHub, useSocket } from "../../core/Global";
import { ActiveAgentsPieChartModel } from "../models/PieChartsModels";



type Props = {
    model?: ActiveAgentsPieChartModel
}

let socketInterval: NodeJS.Timeout;
function ActiveAgentsPieChart(props: Props) {

    const hub = useHub();
    const socket = useSocket();
    const [model, modelSet] = useState<ActiveAgentsPieChartModel>();

    const fetch = async () => {
        hub.Get("/dashboard/main/activeAgentsPieChart", res => {
            modelSet(res.data);
        })
    }

    const startSocket = async () => {

        //if (socket.state != HubConnectionState.Connected) await startSocket();

        // socket.invoke("StartListenToLiveBoards", getUserListenId());

        // socket.on("UpdateLiveBoards", () => {
        //     fetch();
        // });

        // if (socketInterval) clearInterval(socketInterval);

        // socketInterval = setInterval(async () => {
        //     if (socket.state != HubConnectionState.Connected) await startSocket();
        // }, 5000);

    }

    useEffect(() => {

        startSocket();

        return () => {
            if (socketInterval) clearInterval(socketInterval);
        };


    }, [])

    useEffect(() => {
        modelSet(props.model);
    }, [props.model])

    useEffect(() => {

        if (model) {
            window.createCircleChart("chart-ActiveAgentsPieChart",
                [model.active, model.busy, model.away],
                [window.getBootstrapColor("success"), window.getBootstrapColor("danger"), window.getBootstrapColor("warning")]);
            window.createCircleChart("chart-ActiveAgentsPieChart-small",
                [model.active, model.busy, model.away],
                [window.getBootstrapColor("success"), window.getBootstrapColor("danger"), window.getBootstrapColor("warning")]);
        }

    }, [model])

    return (
        <>
            <div className="card card-flush d-none d-xxl-block">
                <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="chart-title fw-bolder text-dark me-2 lh-1">
                                {model?.activePercent}%
                            </span>
                        </div>
                        <span className="text-gray-400 pt-1 fw-bold fs-8 fs-xxl-7">
                            Active Agents
                        </span>
                    </div>
                </div>
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-items-center flex-grow-1">
                        <div>
                            <div id="chart-ActiveAgentsPieChart" data-kt-size="70" data-kt-line="11"></div>
                        </div>
                        <div className="ms-auto">
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                                <div className="text-gray-500 flex-grow-1 me-6 fs-7">Active</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.active}
                                </div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                                <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                                <div className="text-gray-500 flex-grow-1 fs-7 me-6">Busy</div>
                                <div className="fw-boldest text-gray-700 fs-7 ms-auto">
                                    {model?.busy}
                                </div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                                <div className="bullet w-8px h-6px rounded-2 bg-warning me-3"></div>
                                <div className="text-gray-500 flex-grow-1 fs-7 me-6">Away</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.away}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-flush d-xxl-none">
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-column">

                        <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-6 fs-8">Active Agents</div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.active}
                            </div>
                        </div>
                        <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                            <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                            <div className="text-gray-500 flex-grow-1 fs-8 me-6">Busy Agents</div>
                            <div className="fw-boldest text-gray-700 fs-7 ms-auto">
                                {model?.busy}
                            </div>
                        </div>
                        <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                            <div className="bullet w-8px h-6px rounded-2 bg-warning me-3"></div>
                            <div className="text-gray-500 flex-grow-1 fs-8 me-6">Away</div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.away}
                            </div>
                        </div>

                    </div>

                    <div className="ms-auto">
                        <div id="chart-ActiveAgentsPieChart-small" data-kt-size="70" data-kt-line="11"></div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default ActiveAgentsPieChart;