import React, { useEffect, useState } from "react"
import TextBox from '../../../../components/form/TextBox';
import DropDown, { DropDownOption } from '../../../../components/form/DropDown';
import { useGlobal, useHub } from '../../../../core/Global';
import Extension from '../models/Extension';
import Icon from "../../../../components/base/Icon";
import ToggleSwitch from '../../../../components/form/ToggleSwitch';
import FormPage from '../../../../components/form/FormPage';
import FormRow from '../../../../components/form/FormRow';
import FormTitle from '../../../../components/form/FormTitle';
import Layout from '../../../../components/layouts/Layout';
import { setPage } from '../../../../core/Global';
import { API_ROUTES } from '../../../../core/Constants';
import { Link, useParams } from 'react-router-dom';
import Indicator from '../../../../components/base/Indicator';
import Div from '../../../../components/base/Div';
import Hub from '../../../../api/Hub';
import UserPicker from '../../../../components/pickers/UserPicker';
import { UserTypes } from "src/core/Enums";
import Tagify from "../../../../components/tagify/Tagify";
import { ListItem } from "../../../../core/Types";
import FlexRow from "../../../../components/base/FlexRow";
import Section from "../../../../components/base/Section";
import Form from "../../../../components/form/Form";
import DataItem from "../../../../models/DataItem";
import Button from "../../../../components/base/Button";


function ExtensionForm() {

    const hub = useHub();
    const { selectedId } = useParams();
    const [globalState] = useGlobal();
    const [voicemail, voicemailSet] = useState<boolean>(false);
    const [loading, loadingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Extension>();

    const [customerSelectedId, customerSelectedIdSet] = useState<string>();
    const [customerId, customerIdSet] = useState<string>();
    const [companyId, companyIdSet] = useState<string>();
    const [clearCompany, clearCompanySet] = useState<boolean>();

    const [companiesRouteParams, companiesRouteParamsSet] = useState<ListItem[]>([]);
    const [usersRouteParams, usersRouteParamsSet] = useState<ListItem[]>([{
        name: "type",
        value: UserTypes.Customer.toString()
    }]);

    const [RecordingSettings] = useState<DataItem[]>([
        {
            id: "1",
            name: "Force"
        },
        {
            id: "2",
            name: "Don't Care"
        },
        {
            id: "3",
            name: "Never"
        }
    ]);

    const [PBXServers, PBXServersSet] = useState<DataItem[]>();
    useEffect(() => {
        hub.Get(API_ROUTES.TagifyPBXServers, res => {
            if (res.data) {
                var arr: DataItem[] = [];
                res.data.forEach((d: any) => {
                    arr.push({
                        id: d.value,
                        name: d.title + "(" + d.text + ")"
                    })
                });

                PBXServersSet(arr);
            }
        })
    }, [])

    const updateCompaniesRouteParams = (name: string, value: string) => {
        var arr = companiesRouteParams;
        var exists = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == name) {
                arr[i].value = value;
                exists = true;
                break;
            }
        }
        if (!exists) arr.push({
            name: name,
            value: value
        });
        companiesRouteParamsSet(c => [...arr])
    }

    const removeCompaneisRouteParams = (name: string) => {
        var arr = companiesRouteParams;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == name) {
                arr.splice(i, 1);
                break;
            }
        }
        companiesRouteParamsSet(c => [...arr])
    }

    useEffect(() => {

        if (selectedId) {
            setPage("Advanced Settings", "Voice", "PBX Modules");
            loadingSet(true);
            hub.Get(API_ROUTES.Extensions + selectedId, res => {
                if (res.success) {
                    modelSet(res.data);
                    voicemailSet(res.data?.voicemailEnabled ?? false);
                    customerIdSet(res.data?.ownerUserId);
                    if (res.data?.ownerUserId) {
                        updateCompaniesRouteParams("userId", res.data?.ownerUserId);
                    }
                    loadingSet(false);
                }
            });
        }

    }, [selectedId]);

    useEffect(() => {
        if (model) {
            setPage(model.extensionNumber + " : Advanced Settings", "Voice", "PBX Modules");
        }
    }, [model])

    const [working, workingSet] = useState<boolean>(false);
    const validate = (body: any) => {
        var valid = true;
        var focus = true;
        if (globalState.user?.isSuperAdmin) {
            if (window.isNullOrEmpty(body.ownerUserId)) {
                window.formError("ownerUserId", "Please select the customer!");
                valid = false;
            }
            if (window.isNullOrEmpty(body.companyId)) {
                window.formError("companyId", "Please select the company!");
                valid = false;
            }
        }

        if (window.isNullOrEmpty(body.DisplayName)) {
            window.formError("DisplayName", "Please write the extension display name!", "form-ext-advanced", focus);
            valid = false;
            focus = false;
        }

        if (window.isNullOrEmpty(body.SecretPassword)) {
            window.formError("SecretPassword", "Please write the secret password!", "form-ext-advanced", focus);
            valid = false;
            focus = false;
        }

        if (window.isNullOrEmpty(body.RecordingSettings)) {
            window.formError("RecordingSettings", "Please select the recording settings!", "form-ext-advanced", focus);
            valid = false;
            focus = false;
        }


        return valid;
    }


    return (
        <Layout wide>

            <Div visible={loading} className="p-20 text-center">
                <Indicator text="Please wait..." />
            </Div>

            <Section fluid>

                <div className="card">
                    <div className="card-body">
                        <div style={{
                            maxWidth: 1400
                        }}>


                            <Form id="form-ext-advanced"
                                onSubmit={(body, callback) => {
                                    var valid = validate(body);
                                    if (!valid) return;
                                    workingSet(true);

                                    hub.Post(API_ROUTES.Extensions + "advanced", {
                                        ...body,
                                        id: selectedId
                                    }, res => {
                                        if (res.success) {

                                        }
                                        else {
                                            workingSet(false);
                                        }
                                    })
                                }}>


                                {
                                    (globalState.user?.isSuperAdmin) &&
                                    <>
                                        <FormTitle title="Assign To" />

                                        {/* <DropDown name="ServerId" id="drp-pbxservers" label="Server"
                                        source={PBXServers} defaultValue={model?.serverId} /> */}

                                        <TextBox label="Server" value={model?.server?.name + " - " + model?.server?.description} readOnly />

                                        <Tagify name="ownerUserId" id="pickUser" placeholder="Select a Customer" label="Customer" apiUrl={API_ROUTES.TagifyUsers}
                                            defaultId={model?.ownerUserId} single routeParams={usersRouteParams} selectedId={customerSelectedId} marginBottom={5}
                                            onSelect={val => {
                                                customerIdSet(val);
                                                updateCompaniesRouteParams("userId", val);
                                                clearCompanySet(true);
                                                setTimeout(() => clearCompanySet(false), 300);
                                            }}
                                            onChange={vals => {
                                                if (vals.length < 1) {
                                                    customerIdSet(undefined);
                                                    removeCompaneisRouteParams("userId");
                                                }
                                            }}
                                        />

                                        <Tagify name="companyId" id="pickCompany" label="Company" placeholder="Select a Company" apiUrl={API_ROUTES.TagifyCompaneis}
                                            routeParams={companiesRouteParams} defaultId={model?.companyId} clear={clearCompany} single
                                            onSelect={val => {
                                                hub.Get("/accounts/companies/" + val, res => {
                                                    customerSelectedIdSet(res.data.userIds[0]);
                                                    companyIdSet(val);
                                                })
                                            }} />

                                    </>
                                }


                                <FormTitle title="Device Settings" />

                                <TextBox name="DisplayName" label="Display Name" maxLength={30} defaultValue={model?.displayName} />
                                <TextBox label="Email Address" name="Email" email defaultValue={model?.email} />
                                <TextBox label="Outbound CID" name="OutboundCallerID" maxLength={30} defaultValue={model?.outboundCallerID} />
                                <TextBox label="Emergency CID" name="EmergencyCallerID" maxLength={30} defaultValue={model?.emergencyCallerID} />
                                <TextBox label="Secret Password" name="SecretPassword" password defaultValue={model?.secretPassword} />

                                <DropDown id="drp-recoding" label="Recording Settings" name="RecordingSettings"
                                    source={RecordingSettings} defaultValue={model?.recordingSettings?.toString()} />

                                <ToggleSwitch label="WebRTC" name="WebRTC" defaultChecked={model?.webRTC} />
                                <ToggleSwitch label="Call History" name="CallHistory" defaultChecked={model?.callHistory} />



                                <FormTitle title="Voicemail Settings" />

                                <ToggleSwitch label="Voicemail Enabled" name="VoicemailEnabled"
                                    defaultChecked={model?.voicemailEnabled ?? voicemail}
                                    onChange={e => voicemailSet(e)} />

                                {
                                    voicemail &&
                                    <>
                                        <TextBox label="Voicemail Email" name="VoicemailEmail" defaultValue={model?.voicemailEmail} />
                                        <TextBox label="Voicemail Password" name="VoicemailPassword" password defaultValue={model?.voicemailPassword} />
                                        <ToggleSwitch label="Voicemail Delete" name="VoicemailDelete" defaultChecked={model?.voicemailDelete} />
                                        <ToggleSwitch label="Email Attachment" name="VoicemailEmailAttachment" defaultChecked={model?.voicemailEmailAttachment} />
                                        <ToggleSwitch label="Require From Same Extension" name="VoicemailRequireFromSameExtension" defaultChecked={model?.voicemailRequireFromSameExtension} />
                                    </>
                                }

                                <div className="mt-20 mb-10">
                                    <Link to={"/voice/pbx/extensions"} className="btn btn-secondary btn-sm me-2">
                                        <Icon icon="chevron-left" />
                                        Back To List
                                    </Link>
                                    <Button text="Update Settings" isSubmit isRunning={working} />
                                </div>

                            </Form>
                        </div>
                    </div>
                </div>

                {/* <FormPage apiRoute={API_ROUTES.Extensions}
                    selectedId={selectedId}
                    title={model?.extensionNumber + " : Advanced Settings"}
                    visible={!loading}
                    redirectTo="/voice/pbx/extensions">

                    
                    
            </FormPage> */}
            </Section>


        </Layout>
    );
}

export default ExtensionForm;
