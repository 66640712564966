import { useEffect } from "react"



export default function DefaultRouteView() {

    useEffect(() => {
        document.location.href = "/boards/jobs";
    }, [])

    return (<></>)
}