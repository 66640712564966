import { useEffect, useState } from "react";
import FlexRow from "../../../../components/base/FlexRow";
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import ColorPicker from "../../../../components/form/ColorPicker";
import DropDown from "../../../../components/form/DropDown";
import TextBox from "../../../../components/form/TextBox";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import Tagify from "../../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub } from "../../../../core/Global";
import DataItem from "../../../../models/DataItem";
import TextArea from "../../../../components/form/TextArea";
import DatePicker from "../../../../components/form/DatePicker";
import WeekdaysPicker from "../../../../components/form/WeekdaysPicker";
import { RateCategories } from "../../../../core/Enums";
import RateItem from "../models/RateItem";
import Icon from "../../../../components/base/Icon";

interface IProps {
  rateCategory: RateCategories
  model?: RateItem
  onClose?: () => void
  onSave?: () => void
}
export default function RatesFormModal(props: IProps) {

  const hub = useHub();
  const user = getUser();

  const [parametersError, parametersErrorSet] = useState<boolean>(false);
  const [reset, resetSet] = useState<boolean>(false);
  const [keepOpen, keepOpenSet] = useState<boolean>(false);

  useEffect(() => {
    if (reset) {
      selectedRepeatTypeSet(undefined);
      selectedDateTypeSet(undefined);

      $("#txt-rate-name").focus();
      setTimeout(() => {
        resetSet(false);
      }, 1000);
    }
  }, [reset])

  const [selectedRateAmountType, selectedRateAmountTypeSet] = useState<DataItem>({
    id: "1",
    name: "Percent"
  })
  const [RateTypes] = useState<DataItem[]>([
    {
      id: "1",
      name: "Percent"
    },
    {
      id: "2",
      name: "Fixed Rate"
    }
  ])

  const [AmountTypes] = useState<DataItem[]>([
    {
      id: "1",
      name: "Less Than < "
    },
    {
      id: "2",
      name: "Greater Than or Equals To => "
    }
  ])

  const [selectedRepeatType, selectedRepeatTypeSet] = useState<DataItem>();
  const [selectedDateType, selectedDateTypeSet] = useState<DataItem>();
  const [DateTypes] = useState<DataItem[]>([
    {
      id: "specific",
      name: "Specific Date"
    },
    {
      id: "range",
      name: "Date Range"
    },
    {
      id: "weekdays",
      name: "Weekdays"
    }
  ])

  const [DateRepeatTypes] = useState<DataItem[]>([
    {
      id: "1",
      name: "Don't Repeat"
    },
    {
      id: "2",
      name: "Every Month"
    },
    {
      id: "3",
      name: "Every Year"
    }
  ])

  const [WeekdaysRepeatTypes] = useState<DataItem[]>([
    {
      id: "4",
      name: "Repeat Forever"
    },
    {
      id: "5",
      name: "Till Specific Date"
    }
  ])

  const [confirmRequired, confirmRequiredSet] = useState<boolean>();
  const [confirmText, confirmTextSet] = useState<string>();

  useEffect(() => {
    if (props.model) {
      DateTypes.forEach(d => {
        if (d.id == props.model?.dateTypeValue) selectedDateTypeSet(d);
      });
      DateRepeatTypes.forEach(r => {
        if (r.id == props.model?.repeatType?.toString()) selectedRepeatTypeSet(r);
      });
      WeekdaysRepeatTypes.forEach(r => {
        if (r.id == props.model?.repeatType?.toString()) selectedRepeatTypeSet(r);
      });
    }
  }, [props.model])

  return (
    <Modal id="modal-add-rate"
      title={props.model ? "Update Rate" : `Create ${(props.rateCategory == RateCategories.Source ? "Source" : "Technician")} Rate`}
      buttonText={props.model ? "Update Rate" : `Create ${(props.rateCategory == RateCategories.Source ? "Source" : "Technician")} Rate`}
      size="Large"
      // createAnother={props.model == undefined}
      // createAnotherText="Create Another Rate!"
      // onCreateAnotherChanged={k => keepOpenSet(k)}
      staticBackdrop visible open
      onOpen={() => {
        $("#txt-rate-name").focus();
      }}
      onClose={props.onClose}
      onSubmit={(body, callback) => {

        var valid = true;
        parametersErrorSet(false);

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-add-rate");
            valid = false;
          }
        }

        if (!body.name || body.name == "" || body.name.length < 2) {
          window.showFormError("name", "Please write a valid name!", false, "modal-add-rate");
          valid = false;
        }

        if (!body.rate || body.rate == "") {
          window.showFormError("rate", "Please write a valid rate!", false, "modal-add-rate");
          valid = false;
        }


        if (selectedDateType?.id == "specific" && window.isNullOrEmpty(body.specificDate0)) {
          window.formError("dateType", "Please add date(s)!", "modal-add-rate");
          valid = false;
        }

        if (selectedDateType?.id == "range" && window.isNullOrEmpty(body.rangeDateStart0) && window.isNullOrEmpty(body.rangeDateEnd0)) {
          window.formError("dateType", "Please add date(s)!", "modal-add-rate");
          valid = false;
        }

        if (selectedDateType?.id == "weekdays" && window.isNullOrEmpty(body.rateWeekdays)) {
          window.formError("dateType", "Please select day(s)!", "modal-add-rate");
          valid = false;
        }

        if (window.isNullOrEmpty(body.dateType) && window.isNullOrEmpty(body.techgroupIds) && window.isNullOrEmpty(body.techIds)
          && window.isNullOrEmpty(body.shiftIds) && window.isNullOrEmpty(body.metroIds)
          && window.isNullOrEmpty(body.sourceIds) && window.isNullOrEmpty(body.typeIds) && window.isNullOrEmpty(body.jobAmount)) {
          parametersErrorSet(true);
          valid = false;
        }




        if (!valid) {
          if (callback) callback();
          return;
        }

        if (props.model?.id) {
          hub.Put(API_ROUTES.Rates + props.model.id, {
            ...body,
            id: props.model.id,
            rateCategory: props.rateCategory
          }, res => {
            if (res.success) {
              if (res.confirmRequired) {

                if (callback) callback();
                return;
              }
              else {
                toastSuccess("Rate has been updated successfully!");
                if (props.onSave) props.onSave();
              }

            }
            if (callback) callback(res);
          })
        }
        else {
          hub.Post(API_ROUTES.Rates, {
            ...body,
            rateCategory: props.rateCategory
          }, res => {
            if (res.success) {
              toastSuccess("Rate has been added successfully!");
              if (keepOpen) {
                resetSet(true);
                if (callback) callback();
              }
              else if (callback) callback(res);

              if (props.onSave) props.onSave();

            }
            else if (callback) callback();
          })
        }

      }}>
      <div>

        {
          confirmRequired &&
          <div className="alert alert-warning">
            {confirmText}
          </div>
        }

        <TextBox id="txt-rate-name" name="name" label="Name *" labelUp block marginBottom={5} defaultValue={props.model?.name} clear={reset} />
        <TextArea name="descr" label="Description" labelUp block marginBottom={5} value={props.model?.description} clear={reset} />

        <div className="row">
          <div className="col-5">
            <DropDown id="drp-rates-types" name="rateType" block label="Rate Type" deselect={false}
              labelUp source={RateTypes} defaultValue={props.model?.rateType?.toString() ?? "1"}
              onSelectItem={e => selectedRateAmountTypeSet(e)} />
          </div>
          <div className="col-7">
            {
              selectedRateAmountType.id == "1" ?
                <TextBox key="txt-rate-percent" name="rate" label="Rate *" labelUp block numeric maxLength={2}
                  defaultValue={props.model?.rate?.toString()} clear={reset} marginBottom={5} icon="percentage" />
                :
                <TextBox key="txt-rate-fixed" name="rate" label="Rate *" labelUp block money
                  defaultValue={props.model?.rate?.toString()} clear={reset} marginBottom={5} icon="dollar-sign" />
            }

          </div>

        </div>

        <hr />


        {
          parametersError &&
          <div className="alert alert-danger fs-8 p-3">
            <span>You should fill in at least one of the parameters below!</span>
          </div>
        }


        <div className="row">
          <div className="col-5">
            <DropDown id="drp-amount-types" name="jobAmountType" block label="Amount" deselect={false}
              labelUp source={AmountTypes} defaultValue={props.model?.amountType?.toString() ?? "1"} />
          </div>
          <div className="col-7">
            <TextBox key="txt-rate-fixed" name="jobAmount" label="&nbsp;" labelUp block money
              defaultValue={props.model?.amount?.toString()} clear={reset} marginBottom={5} icon="dollar-sign" />
          </div>
        </div>


        <DropDown id="drp-rates-datetypes" name="dateType" label="Date Type" labelUp block marginBottom={5} deselect reset={reset}
          source={DateTypes} dataValue="id" dataText="name" parentModal="modal-add-rate" defaultValue={props.model?.dateTypeValue}
          onSelectItem={item => {
            selectedDateTypeSet(item);
            parametersErrorSet(false);
          }}
          onDeselect={() => selectedDateTypeSet(undefined)} />

        {
          selectedDateType?.id == "specific" &&
          <DatePicker id="dt-rates-specificdate" name="specificDate" inlineMultipleDates marginBottom={5} selectedDateItems={props.model?.specificDates} />
        }

        {
          selectedDateType?.id == "range" &&
          <DatePicker id="dt-rates-daterange" name="rangeDate" marginBottom={5} dateType="range" timePicker inlineMultipleDates selectedRangeDates={props.model?.rangeDates} />
        }

        {
          selectedDateType?.id == "weekdays" &&
          <WeekdaysPicker name="rateWeekdays" marginBottom={5} selectedWeekDays={props.model?.weekDays} />
        }

        {
          selectedDateType &&
          <DropDown id="drp-rates-repeats" name="repeatType" label="Repeat Type" labelUp block marginBottom={5} autoSelectFirstItem={(!props.model)}
            source={selectedDateType.id == "weekdays" ? WeekdaysRepeatTypes : DateRepeatTypes} dataValue="id" dataText="name" parentModal="modal-add-rate"
            defaultValue={props.model?.repeatType?.toString()}
            onSelectItem={item => selectedRepeatTypeSet(item)} />
        }

        {
          selectedRepeatType?.id == "5" &&
          <DatePicker id="dt-rates-repeattill" name="repeatTill" marginBottom={5} dateType="single"
            selectedDateItem={props.model?.repeatTill} label="Repeat Till" labelUp block />
        }

        {
          props.rateCategory == RateCategories.Source &&
          <Tagify id="tagify-rates-sources" name="sourceIds" apiUrl={API_ROUTES.TagifyJobSources} marginBottom={5} clear={reset} hint="Leave it blank to use Any Source"
            label="Job Source(s)" labelUp block placeholder="Select Source(s)" selectedIds={props.model?.jobSourceIds} onChange={() => parametersErrorSet(false)} />
        }

        {
          props.rateCategory == RateCategories.Technician &&
          <>

            <Tagify id="tagify-rates-techgroups" name="techgroupIds" apiUrl={API_ROUTES.TagifyTechGroups} marginBottom={5} clear={reset}
              label="Technician Group(s)" labelUp block placeholder="Select Group(s)" selectedIds={props.model?.techGroupIds} onChange={() => parametersErrorSet(false)} />

            <Tagify id="tagify-rates-techs" name="techIds" apiUrl={API_ROUTES.TagifyTechnicians} marginBottom={5} clear={reset}
              label="Technician(s)" labelUp block placeholder="Select Technician(s)" selectedIds={props.model?.techIds} onChange={() => parametersErrorSet(false)} />

            {/* <Tagify id="tagify-rates-shifts" name="shiftIds" apiUrl={API_ROUTES.TagifyShiftTypes} marginBottom={5} clear={reset}
              label="Shift(s)" labelUp block placeholder="Select Shift(s)" selectedIds={props.model?.shiftIds} onChange={() => parametersErrorSet(false)} /> */}

            <Tagify id="tagify-rates-sources" name="sourceIds" apiUrl={API_ROUTES.TagifyJobSources} marginBottom={5} clear={reset}
              label="Job Source(s)" labelUp block placeholder="Select Source(s)" selectedIds={props.model?.jobSourceIds} onChange={() => parametersErrorSet(false)} />
          </>
        }

        <Tagify id="tagify-rates-metros" name="metroIds" apiUrl={API_ROUTES.TagifyMetros} marginBottom={5} clear={reset}
          label="Metro(s)" labelUp block placeholder="Select Metro(s)" selectedIds={props.model?.metroIds} onChange={() => parametersErrorSet(false)} />



        <Tagify id="tagify-rates-types" name="typeIds" apiUrl={API_ROUTES.TagifyJobTypes} marginBottom={10} clear={reset}
          label="Job Type(s)" labelUp block placeholder="Select Type(s)" selectedIds={props.model?.jobTypeIds} onChange={() => parametersErrorSet(false)} />



      </div>
    </Modal>
  )
}