import { useEffect, useState } from "react";
import { toastSuccess } from "../../../components/base/Toast";
import Form from "../../../components/form/Form";
import Roles from "../../../core/Roles.json";
import { useHub } from "../../../core/Global";
import Icon from "../../../components/base/Icon";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import UserGroup from "../models/UserGroup";


type Props = {
    model: UserGroup
}



export default function UserGroupRolesForm(props: Props) {

    // let RolesInherited = new Array<string>();
    // let RolesGranted = new Array<string>();
    // let RolesDenied = new Array<string>();

    const hub = useHub();
    const [clear, clearSet] = useState<boolean>(false);
    const [working, workingSet] = useState<boolean>(false);

    const [rolesInherited, rolesInheritedSet] = useState<string[]>();
    const [rolesGranted, rolesGrantedSet] = useState<string[]>();
    const [rolesDenied, rolesDeniedSet] = useState<string[]>();
    const [rolesAllowed, rolesAllowedSet] = useState<string[]>([]);
    const [AllRolesAllowed, AllRolesAllowedSet] = useState<boolean>();

    const [loading, loadingSet] = useState<boolean>(true);


    useEffect(() => {
        if (clear) {
            setTimeout(() => {
                clearSet(false);
            }, 1000);
        }
    }, [clear])

    const fetch = () => {
        hub.Get("/accounts/usergroups/roles/" + props.model.id, res => {
            if (res.success) {
                rolesInheritedSet(res.data.rolesInherited);
                rolesGrantedSet(res.data.rolesGranted);
                rolesDeniedSet(res.data.rolesDenied);
                rolesAllowedSet(res.data.rolesAllowed);
                AllRolesAllowedSet(res.data.allRolesAllowed);
                loadingSet(false);
            }
        });
    }

    useEffect(() => {
        fetch();
    }, [])

    const updateInheritedRoles = (ids: string[]) => {

        hub.Post("/accounts/UserGroups/grouproles/", ids, res => {
            if (res.success) rolesInheritedSet(res.data);
        });
    }



    const AddRole = (arr?: string[], value?: string): string[] => {
        if (!value) return [];
        if (!arr) return [value ?? ""];
        var exists = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == value) {
                exists = true;
                break;
            }
        }

        if (!exists) arr.push(value);
        else arr = RemoveRole(arr, value);

        return arr;

    }

    const RemoveRole = (arr?: string[], value?: string): string[] => {
        if (!arr) return [];
        return arr.filter(function (ele) {
            return ele != value;
        });
    }

    return (
        <Form id="user-roles-form" className="form"
            onSubmit={(body, callback) => {

                workingSet(true);
                setTimeout(() => {

                    hub.Put("/accounts/usergroups/roles/" + props.model.id, {
                        rolesGranted: rolesGranted,
                        rolesDenied: rolesDenied
                    },
                        res => {
                            if (res.success) {
                                toastSuccess("Group roles has been updated successfully!");
                            }
                            workingSet(false);
                        });

                }, 500);



            }}>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <h3 className="card-title">
                        <span className="card-label fw-bolder text-dark">Group Roles</span>
                    </h3>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    :
                                    <>Update Roles</>
                            }
                        </button>
                    </div>
                </div>

                <div className="card-body border-top p-9">

                    <Div visible={loading} className="p-20 text-center">
                        <Indicator text="Please wait..." />
                    </Div>

                    <Div visible={!loading}>

                        <div className="card mb-1">
                            <div className="card-body pt-0">

                                {
                                    Roles.map((group, key) =>
                                        <div key={key}>
                                            <table className="table table-striped table-roles">
                                                <thead>
                                                    <tr>
                                                        <th className="fs-4 fw-bolder">
                                                            {group.title}
                                                        </th>
                                                        <th className="action-col">
                                                            Inherited
                                                        </th>
                                                        <th className="action-col">
                                                            Grant
                                                        </th>
                                                        <th className="action-col">
                                                            Deny
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        group.roles.filter(f => AllRolesAllowed || rolesAllowed.indexOf(f.roleName) > -1).map((role, roleKey) =>
                                                            <tr key={roleKey}>
                                                                <td>
                                                                    <div className="fw-bold fs-6">
                                                                        {role.title}
                                                                    </div>
                                                                    <div className={`fs-8 ${(role.danger ? "text-danger" : "text-muted")}`}>
                                                                        {role.description}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <label className={"form-check form-switch form-check-custom  form-check-solid"}>
                                                                        <input className="form-check-input" type="checkbox" disabled
                                                                            checked={rolesInherited?.includes(role.roleName)} />
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <label className={"form-check form-switch form-check-custom  form-check-solid"}>
                                                                        <input className="form-check-input" name="grantedRoles" type="checkbox"
                                                                            onChange={e => {

                                                                                var RolesDenied = RemoveRole(rolesDenied, role.roleName);
                                                                                var RolesGranted = AddRole(rolesGranted, role.roleName);

                                                                                rolesGrantedSet(r => [...RolesGranted]);
                                                                                rolesDeniedSet(r => [...RolesDenied]);

                                                                            }}
                                                                            value={role.roleName} checked={rolesGranted?.includes(role.roleName)} />
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    <label className={"form-check form-switch form-check-custom  form-check-solid form-check-danger"}>
                                                                        <input className="form-check-input" name={"deniedRoles"} type="checkbox"
                                                                            onChange={e => {

                                                                                var RolesGranted = RemoveRole(rolesGranted, role.roleName);
                                                                                var RolesDenied = AddRole(rolesDenied, role.roleName);

                                                                                rolesGrantedSet(r => [...RolesGranted]);
                                                                                rolesDeniedSet(r => [...RolesDenied]);

                                                                            }}
                                                                            value={role.roleName} checked={rolesDenied?.includes(role.roleName)} />
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <hr />
                                        </div>
                                    )
                                }

                            </div>
                        </div>

                    </Div>


                </div>

            </div>
        </Form>
    )
}