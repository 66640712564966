import { Link } from "react-router-dom"
import User from "../../modules/accounts/models/User"

type Props = {
    userId?: string
    model?: User
 }

export default function AccountProfileCard(props: Props) {


    return (
        <div className="card" id="kt_profile_details_view">
            <div className="card-header">
                <h3 className="card-title">
                    <span className="card-label fw-bolder text-dark">Profile</span>
                </h3>

                <Link to={`/accounts/manage/${props.userId}/edit`} className="btn btn-primary btn-sm align-self-center">Edit Account</Link>

            </div>
            <div className="card-body p-9 h-400px">
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Full Name</label>
                    <div className="col-lg-8">
                        <span className="fw-bolder fs-7 text-gray-800">
                            {props.model?.fullName}
                        </span>
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>
                    <div className="col-lg-8 fv-row">
                        <span className="fw-bold text-gray-800 fs-7 me-2">
                            {props.model?.email}
                        </span>
                        {/* <span className="badge badge-success badge-sm">Verified</span> */}
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                        Phone
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                        <span className="fw-bolder fs-7 text-gray-800 me-2">
                            {props.model?.phoneItem?.phoneFriendly}
                        </span>
                        {/* <span className="badge badge-success">Verified</span> */}
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Birth Date</label>
                    <div className="col-lg-8">
                        <span className="fw-bolder fs-7 text-gray-800 me-2">
                            {props.model?.birthDateItem?.text}
                        </span>
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Account Type</label>
                    <div className="col-lg-8">
                        <span className="fw-bolder fs-7 text-gray-800">
                            {props.model?.userTypeName}
                        </span>
                    </div>
                </div>
                <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">User Groups</label>
                    <div className="col-lg-8">
                        <span className="fw-bolder fs-7 text-gray-800">
                            {props.model?.groupNames?.map((grp, key) => <span key={key}>{grp}</span>)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}