import { useEffect, useState } from "react";
import Icon from "../../../components/base/Icon";
import Modal from "../../../components/base/Modal";
import { DataTable, DataTableField } from "../../../components/data/DataTable";
import TextBox from "../../../components/form/TextBox";
import { API_ROUTES } from "../../../core/Constants";
import { toPrettyDate, useHub } from "../../../core/Global";
import UserGroup from "../models/UserGroup";
import { toastSuccess } from "../../../components/base/Toast";
import { IPRestrictItem } from "../../../models/IPRestrictItem";
import FlexRow from "../../../components/base/FlexRow";
import User from "../models/User";


interface IProps {
    model?: User
}

export default function AccountsIPRestrict(props: IProps) {

    const hub = useHub();
    const [ipModal, ipModalSet] = useState<boolean>(false);
    const [refresh, refreshSet] = useState<boolean>(false);

    const [deleteModal, deleteModalSet] = useState<boolean>(false)
    const [selectedItem, selectedItemSet] = useState<IPRestrictItem>()

    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                refreshSet(false);
            }, 500);
        }
    }, [refresh])


    const CreatedItem = (data: IPRestrictItem) => (
        <div>
            <div className='d-flex align-items-center'>
                {toPrettyDate(data.createdAt)}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    const NoRowComp = () => (
        <div>
            <div className="mb-10">
                <Icon icon="network-wired" size={34} />
            </div>
            <div className="fs-6">
                This section allows you to restrict user access to specific IP addresses. <br />
                If no IPs are added, there are no access restrictions, and user can log in from any location. <br />
                Once IP addresses are added, this user can only log in from those specified IPs, enhancing security by limiting access to authorized locations.
            </div>
        </div>
    )


    return (
        <>
            <div className="card">
                <div className="card-header border-0">
                    <h3 className="card-title">
                        <span className="card-label fw-bolder text-dark">IP Restriction</span>
                    </h3>
                    <div className="card-action">
                        <button type="button" className="btn btn-sm btn-primary" onClick={e => ipModalSet(true)}>
                            <Icon icon="plus" />
                            <span>Add IP Address</span>
                        </button>
                    </div>
                </div>
                <div className="card-body border-top pt-9 pb-0">

                    <DataTable noHeader noFilter preventCreate objectName="User" preventLinkUpdate
                        apiRoute={API_ROUTES.Users + "ip-restricts/" + props.model?.id}
                        delete refresh={refresh} customNoRowComp={NoRowComp} noMultipleActions
                        deleteCustom={item => {
                            deleteModalSet(true)
                            selectedItemSet(item)
                        }}>

                        <DataTableField title="IP Address" dataValueName="ipAddress" />
                        <DataTableField title="Created At">
                            <CreatedItem />
                        </DataTableField>

                    </DataTable>

                </div>
            </div>

            {
                ipModal &&
                <Modal id="modal-add-group-restrict-ip"
                    title="Add IP Address"
                    buttonText="Add IP Address"
                    open visible staticBackdrop
                    onClose={() => ipModalSet(false)}
                    onOpen={() => $("#txt-group-ip-address").focus()}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.Users + "ip-restricts/" + props.model?.id, body, res => {
                            if (res.success) {
                                toastSuccess("IP Address has been added successfully!");
                                refreshSet(true);
                            }
                            callback!(res)
                        })

                    }}>
                    <div className="my-5">
                        <TextBox id="txt-group-ip-address" name="ip" ipAddress
                            label="IP Address" placeholder="XXX.XXX.XXX.XXX" labelUp />
                    </div>
                </Modal>
            }

            {
                deleteModal && selectedItem &&
                <Modal id="modal-delete-group-restrict-ip"
                    title="Delete IP Address"
                    buttonText="Delete IP Address"
                    open visible staticBackdrop
                    onClose={() => deleteModalSet(false)}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.Users + "delete-ip-restricts/" + props.model?.id, {
                            ip: selectedItem.ipAddress
                        }, res => {
                            if (res.success) {
                                toastSuccess("IP Address has been deleted successfully!");
                                refreshSet(true);
                            }
                            callback!(res)
                        })

                    }}>
                    <div className="my-5">
                        Are you sure you want to delete this IP Address?
                        <div className="mt-5 alert alert-danger">
                            {selectedItem.ipAddress}
                        </div>
                    </div>
                </Modal>
            }


        </>
    )
}