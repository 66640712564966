import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../api/Hub";
import Avatar from "../../../components/base/Avatar";
import Icon from "../../../components/base/Icon";
import Time from "../../../components/timer/Time";
import Timer from "../../../components/timer/Timer";
import { CallTypes } from "../../../core/Enums";
import Environment from "../../../env/env";
import SharedItem from "../models/SharedItem";
import { useHub, useScreen } from "../../../core/Global";
import FlexRow from "../../../components/base/FlexRow";


function SharedView() {
    
    const { selectedId } = useParams();
    const hub = useHub();
    const env = new Environment();
    const [model, modelSet] = useState<SharedItem>()

    const screen = useScreen()

    const fetch = () => {
        hub.Get("/base/share/" + selectedId, res => {
            if (res.data) {
                modelSet(res.data);
            }
        });
    }

    useEffect(() => {
        if(selectedId) fetch();
    }, [selectedId])

    return (
        <div>
            <div className="fs-4 fw-bolder">
                Hello, 
            </div>
            <div className="fs-6">
                {model?.user?.fullName} shared the following call{(model?.calls?.length ?? 0) > 1 ? "s" : ""}:
            </div>

            {
                model?.calls && model.calls.length > 0 &&
                <div className="pt-5">
                    {
                        model.calls.map((item, key) => 
                            <div key={key} className="alert bg-light-primary border border-secondary p-5 mb-5">

                                {
                                    screen.width < 800 ? 
                                        <div>
                                            <FlexRow>
                                                <div className="ms-2">
                                                    {
                                                        item?.sourceAgent?.id ?
                                                            <div className="fs-7">
                                                                {item?.sourceAgent?.name}
                                                                <span className="fs-9 ms-1">
                                                                    ({item.sourceAgent?.number ?? "-"})
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="fs-7">
                                                                {item?.source}
                                                            </div>
                                                    }
                                                </div>   
                                                <div className="text-center mx-3">
                                                    {
                                                        item.callType == CallTypes.Incoming &&
                                                        <Icon icon='phone-arrow-down-left' type='solid' className="text-success opacity-75" size={12} title="Incoming Call" />
                                                    }
                                                    {
                                                        item.callType == CallTypes.Outgoing &&
                                                        <Icon icon='phone-arrow-up-right' type='solid' className="text-primary opacity-75" size={12} title="Outgoing Call" />
                                                    }
                                                    {
                                                        item.callType == CallTypes.Local &&
                                                        <Icon icon='user-headset' type='solid' className="text-info opacity-75" size={12} title="Local Call" />
                                                    }
                                                </div>
                                                <div className="ms-2">
                                                    {
                                                        item.destinationAgent?.id ?
                                                            <div className="fs-7">
                                                                {item.destinationAgent?.name}
                                                                <span className="fs-8 ms-1">
                                                                    ({item.destinationAgent?.number ?? "-"})
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="fs-7">
                                                                {item.destination}
                                                            </div>
                                                    }
                                                </div>
                                            </FlexRow>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center flex-wrap">
                                            <Avatar avatarUrl={item.sourceAgent?.country ? item.sourceAgent.country.flagSquare : item.sourceAgent?.avatarUrl} width={20} />
                                            <div className="ms-2">
                                                {
                                                    item?.sourceAgent?.id ?
                                                        <div className="fs-7">
                                                            {item?.sourceAgent?.name}
                                                            <span className="fs-9 ms-1">
                                                                ({item.sourceAgent?.number})
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="fs-7">
                                                            {item?.source}
                                                            {
                                                                !item.sourceAgent?.country && item.sourceAgent?.name &&
                                                                <span className="fs-9 ms-1">
                                                                    ({item.sourceAgent?.name})
                                                                </span>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            <div className="text-center mx-3">
                                                {
                                                    item.callType == CallTypes.Incoming &&
                                                    <Icon icon='phone-arrow-down-left' type='solid' className="text-success opacity-75" size={14} title="Incoming Call" />
                                                }
                                                {
                                                    item.callType == CallTypes.Outgoing &&
                                                    <Icon icon='phone-arrow-up-right' type='solid' className="text-primary opacity-75" size={14} title="Outgoing Call" />
                                                }
                                                {
                                                    item.callType == CallTypes.Local &&
                                                    <Icon icon='user-headset' type='solid' className="text-info opacity-75" size={14} title="Local Call" />
                                                }
                                            </div>
                                            <Avatar avatarUrl={item.destinationAgent?.country ? item.destinationAgent.country.flagSquare : item.destinationAgent?.avatarUrl} width={20} />
                                            <div className="ms-2">
                                                {
                                                    item.destinationAgent?.id ?
                                                        <div className="fs-7">
                                                            {item.destinationAgent?.name}
                                                            <span className="fs-8 ms-1">
                                                                ({item.destinationAgent?.number})
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="fs-7">
                                                            {item.destination}
                                                            {
                                                                !item.destinationAgent?.country && item.destinationAgent?.name &&
                                                                <span className="fs-9 ms-1">
                                                                    ({item.destinationAgent?.name})
                                                                </span>
                                                            }
                                                        </div>
                                                }
                                            </div>

                                            <div className="d-flex align-items-center ms-auto">
                                                {
                                                    (item.disPosition == "ANSWERED" || item.twilioCallStatus == "completed") ?
                                                        <span className="badge badge-success d-flex align-items-center">
                                                            <Icon icon="phone-volume" type="solid" className="me-2" size={11} />
                                                            <div className="d-flex align-items-center" style={{ marginTop: 1 }}>
                                                                <span className="me-2">
                                                                    {item.disPosition}
                                                                </span>
                                                                |
                                                                <span className="ms-2">
                                                                    {item.billSecStr}
                                                                </span>
                                                            </div>
                                                        </span>
                                                        : (item.disPosition == "BUSY" || item.disPosition == "NO ANSWER") ?
                                                            <span className="badge badge-danger">
                                                                <Icon icon="phone-missed" type="solid" className="me-2" size={11} />
                                                                {item.disPosition}
                                                            </span>
                                                            :
                                                            <span className="badge badge-secondary">
                                                                <Icon icon="phone-xmark" type="solid" className="me-2" size={11} />
                                                                {item.disPosition ?? item.twilioCallStatus}
                                                            </span>
                                                }

                                                {
                                                    !item.twilioCallSid && 
                                                    <span className="badge badge-light-primary ms-2">
                                                        RING TIME | {item.ringSecStr}
                                                    </span>
                                                }

                                            </div>

                                        </div>
                                }

                                
                                {
                                    (item.disPosition == "ANSWERED" || item.twilioCallStatus == "completed") &&
                                    <div>
                                        <audio src={env.localHubUrl + "/system/calls/recording/" + item.id}
                                            controls style={{width:"100%"}} className="mt-5"></audio>
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default SharedView;