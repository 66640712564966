import { useScreen } from "../../../core/Global";


function PublicLayout(props: { children: any }) {

    const screen = useScreen()

    return (

        <div className="d-flex flex-column flex-column-fluid">


            <div className="d-flex flex-center  mt-10">
                <a className="mb-12">
                    <img alt="Rinvox" src="https://console.rinvox.com/assets/brands/rinvox/logo.png" className="h-50px" />
                </a>
            </div>

            <div className="bg-body rounded shadow-sm p-10 p-lg-15 mx-auto" style={{
                width: "90%",
                maxWidth: 800,
                zIndex: 15
            }}>
                {props.children}
            </div>


            {
                screen.width > 900 &&
                <div className="bgi-no-repeat bgi-size-contain bgi-attachment-fixed bgi-position-y-bottom position-x-center position-fixed h-100 w-100 d-md-none d-lg-block"
                    style={{ backgroundImage: "url(/assets/media/illustrations/dozzy-1/14.png", zIndex: 10 }}>
                </div>
            }

        </div>
    )
}

export default PublicLayout;