
export const API_ROUTES = {
    Authenticate: "/auth/login/",
    AuthenticateAuto: "/auth/login/auto",
    //Authorize: "/auth/login/authorize/",
    LoadApp: "/auth/login/load/",
    DashboardExtensionsBoard: "/dashboard/LiveBoards/extensions/",
    DashboardAgentsMonitoring: "/dashboard/LiveBoards/monitoring/",
    DashboardAgentsMap: "/dashboard/LiveBoards/agents/",
    PasswordForget: "/auth/password/forget/",
    PasswordTokenValidate: "/auth/password/validate/",
    PasswordReset: "/auth/password/reset/",

    Init: "/base/init/",
    CashedData: "/base/init/cashedata/",
    Countries: "/base/countries/",
    CountriesAllActive: "/base/countries/allactive",
    CountriesAll: "/base/countries/all",
    States: "/base/states/",
    Search: "/base/search/",

    FileUpload: "/base/files/",
    Extensions: "/system/extensions/",
    ClaimExtension: "/system/extensions/claimext/",
    RevokeExtension: "/system/extensions/revokext/",
    Lines: "/voice/lines/",
    Queues: "/system/queues/",
    PBXServers: "/system/pbxservers/",
    FirewallWhitelist: "/system/firewall/whitelist/",
    Calls: "/system/calls/",
    NotificationRules: "/system/notifrules/",

    Users: "/accounts/users/",
    UsersAdmins: "/accounts/users/1/",
    UsersAgents: "/accounts/users/2/",
    UsersCustomers: "/accounts/users/3/",
    UsersTechnicians: "/accounts/users/4/",
    UsersGroups: "/accounts/usergroups/",
    UserServers: "/accounts/users/servers/",
    Companies: "/accounts/companies/",
    TechGroups: "/accounts/techgroups/",
    Technicians: "/accounts/technicians/",
    Customers: "/accounts/Customers/",

    ProfileCompany: "/accounts/profile/company/",

    Shifts: "/base/shifts/",
    ShiftsView: "/base/shifts/view/",
    ShiftsTypes: "/base/ShiftTypes/",
    ShiftsAll: "/base/shifts/all/",
    ShiftsDepartments: "/base/ShiftDepartments/",
    ShiftsDepartmentsAll: "/base/ShiftDepartments/all/",

    TagifyCompaneis: "/base/tagify/companies/",
    TagifyExtensions: "/base/tagify/extensions/",
    TagifyQueues: "/base/tagify/queues/",
    TagifyPhoneNumbers: "/base/tagify/activenumbers/",
    TagifyUsers: "/base/tagify/users/",
    TagifyUserGroups: "/base/tagify/usergroups/",
    TagifyAgents: "/base/tagify/agents/",
    TagifyAdmins: "/base/tagify/admins/",
    TagifyAdminsAndAgents: "/base/tagify/adminsAndAgents/",
    TagifyCustomers: "/base/tagify/customers/",
    TagifyTechnicians: "/base/tagify/technicians/",
    TagifyPBXServers: "/base/tagify/servers/",
    TagifyShifts: "/base/tagify/shifts/",
    TagifyShiftTypes: "/base/tagify/shifttypes/",
    TagifyDepartments: "/base/tagify/departments/",
    TagifyShiftTypesWithDepartments: "/base/tagify/shifttypeWithDepartment/",

    TagifyJobs: "/base/tagify/jobs/",
    TagifyJobTypeGroups: "/base/tagify/jobtypegroups/",
    TagifyJobTypes: "/base/tagify/jobtypes/",
    TagifyJobSources: "/base/tagify/jobsources/",
    TagifyJobStatuses: "/base/tagify/jobstatuses/",
    TagifyMetros: "/base/tagify/metros/",
    //TagifyTags: "/base/tagify/tags/",
    //TagifyFlags: "/base/tagify/flags/",
    TagifyTagsFlags: "/base/tagify/tagsflags/",

    TagifyChatJobs: "/base/tagify/chatjobs/",
    TagifyChatContacts: "/base/tagify/chatcontacts/",
    TagifyEmailsAutocomplete: "/base/tagify/emailsautocomplete/",

    TagifyTechGroups: "/base/tagify/techgroups/",

    Chat: "/crm/chat/",
    ChatContacts: "/crm/chat/contacts/",
    ChatMessages: "/crm/chat/load/",
    ChatSearch: "/crm/chat/search/",
    ChatRead: "/crm/chat/read/",
    ChatClaim: "/crm/chat/claim/",
    ChatSend: "/crm/chat/send/",
    ChatStart: "/crm/chat/start/",

    Jobs: "/crm/Jobs/",
    JobsAll: "/crm/Jobs/AllJobs/",
    JobsClosed: "/crm/Jobs/Closed/",
    JobsCanceled: "/crm/Jobs/Canceled/",
    JobTypeGroups: "/base/JobTypeGroup/",
    JobTypes: "/base/JobType/",
    JobStatuses: "/base/JobStatus/",
    JobSources: "/base/JobSource/",
    JobMetros: "/base/metros/",
    JobMetrosAll: "/base/metros/all/",
    JobInvoices: "/crm/JobInvoice/",
    Flags: "/base/flags/",
    Tags: "/base/tags/",
    Taxes: "/base/taxes/",
    Rates: "/crm/rates/",


    Announcements: "/system/announcements/",
}

export const ROLES = {
    ExtensionsList: "ExtensionsList",
    ExtensionsManage: "ExtensionsManage",
    ExtensionsDelete: "ExtensionsDelete",

    QueuesList: "QueuesList",
    QueuesManage: "QueuesManage",
    QueuesDelete: "QueuesDelete",

    LiveCalls: "LiveCalls",
    CallsHistory: "CallsHistory",
    CallRecordedFile: "CallRecordedFile",
    CallListen: "CallListen",
    CallWhisper: "CallWhisper",
    CallJoin: "CallJoin",


    UsersList: "UsersList",
    UsersManage: "UsersManage",
    UsersRoles: "UsersRoles",
    UsersDelete: "UsersDelete",

    UserGroupsList: "UserGroupsList",
    UserGroupsManage: "UserGroupsManage",
    UserGroupsRoles: "UserGroupsRoles",
    UserGroupsDelete: "UserGroupsDelete",

    CompaniesList: "CompaniesList",
    CompaniesManage: "CompaniesManage",
    CompaniesDelete: "CompaniesDelete",

    ManageCountries: "ManageCountries",
    ManageStates: "ManageStates",
    ManageCities: "ManageCities"

}

export const DisPositions = {
    ANSWERED: "ANSWERED",
    BUSY: "BUSY",
    FAILED: "FAILED",
    NO_ANSWER: "NO ANSWER"
}


export const RedirectTypes = [
    {
        id: "1",
        name: "Job Customers"
    },
    {
        id: "2",
        name: "External Phone Number"
    }
]

export const JobCallbackTypes = [
    {
        id: "1",
        name: "Technician"
    },
    {
        id: "2",
        name: "Operator"
    }
]

export const TimeFormatsSource = [
    {
        id: "1",
        name: "24 Hours (22:30)"
    },
    {
        id: "2",
        name: "12 Hours (10:30 PM)"
    }
]

export const DistanceUnitsSource = [
    {
        id: "1",
        name: "Kilometers"
    },
    {
        id: "2",
        name: "Miles"
    }
]

export const TimePickerStepsSource = [
    {
        id: "1",
        name: "5 Minutes"
    },
    {
        id: "2",
        name: "10 Minutes"
    },
    {
        id: "3",
        name: "15 Minutes"
    },
    {
        id: "4",
        name: "30 Minutes"
    }
]