
interface FlexRowProps {
    id?: string
    visible?: boolean
    children?: any
    className?: string
    relative?: boolean
    fixed?: boolean
    title?: string
    column?: boolean
    alignItems?: "center" | "start" | "end"
    justify?: "start" | "end" | "center" | "between" | "around" | "evenly"
    wrap?: boolean
    maxWidth?: number
    scroll?: boolean
    onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseDown?: any
}

function FlexRow(props: FlexRowProps) {

    const className = () => {
        var cls = "d-flex ";
        cls += "align-items-" + (props.alignItems ?? "center");
        if (props.column) cls += " flex-column";
        if (props.wrap) cls += " flex-wrap";
        if (props.justify) cls += " " + "justify-content-" + props.justify;
        if (props.className) cls += " " + props.className;
        if (props.onClick) cls += " cursor-pointer";
        if (props.relative) cls += " position-relative";
        if (props.fixed) cls += " position-fixed";
        if (props.scroll) cls += " scroll-x"
        return cls;
    }

    return (
        props.visible == undefined || props.visible ?
            <div id={props.id} className={className()} onClick={props.onClick} title={props.title}
                onMouseDown={props.onMouseDown}
                style={{
                    maxWidth: props.maxWidth
                }}>
                {props.children}
            </div>
            :
            <></>
    )
}

export default FlexRow;

