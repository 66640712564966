import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser, setPage, setUser, useGlobal, useHub } from '../../../core/Global';
import DropDown, { DropDownOption } from '../../../components/form/DropDown';
import TextBox from '../../../components/form/TextBox';
import DatePicker from '../../../components/form/DatePicker';
import { API_ROUTES } from '../../../core/Constants';
import Form from '../../../components/form/Form';
import AvatarUploade from "../../../components/form/AvatarUploade";
import PhoneInput from "../../../components/form/PhoneInput";
import Hub from "../../../api/Hub";
import { toastError, toastSuccess } from "../../../components/base/Toast";
import Icon from "../../../components/base/Icon";


function ProfileDetailsForm() {

    const [globalState] = useGlobal();
    const hub = useHub()
    const user = getUser();

    const [working, workingSet] = useState<boolean>(false);

    

  return (
    <Form id="user-profile-form" className="form"
            onSubmit={(body, callback) => {
              workingSet(true);
              hub.Post("/accounts/profile/updatedetails", body, res => {
                  if(res.success) {
                    toastSuccess("Your profile details has been saved successfully!");
                    setUser(res.data);
                  }
                  else if(res.message) {
                    if (res.field) window.formError(res.field, res.message, "user-profile-form");
                    else toastError(res.message);
                  }
                  workingSet(false);
              })
            }}>
        <div className="card mb-5 mb-xl-10">
              <div className="card-header border-0">
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Edit Profile</h3>
                </div>
                <div className="card-action">
                  <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                    {
                      working ? 
                      <>
                        <Icon icon="spinner-third" spin />
                        <span className="ms-2">Updating...</span>
                      </>
                      : <>Update Profile</>
                    }
                  </button>
                </div>
              </div>
              
                <div className="card-body border-top p-9">
                <div className="mt-10" style={{
                        maxWidth: 1200
                    }}>
                        <AvatarUploade type="avatars" name="avatar" imageUrl={user?.avatarUrl} defaultValue={user?.avatarUrlRelativePath} />
                        
                        <div className="mb-10"></div>
                        <DropDown label="Gender" id="drpGender" name="gender" defaultValue={globalState.user?.gender?.toString()} >
                            <DropDownOption value="1" text="Mr." />
                            <DropDownOption value="0" text="Mrs." />
                        </DropDown>

                        <TextBox label="First Name" name="firstName" value={globalState.user?.firstName} />
                        <TextBox label="Last Name" name="lastName" value={globalState.user?.lastName}  />

                        <PhoneInput label="Phone" name="phone" defaultPhone={globalState.user?.phoneItem} countryCodes="all" />

                        <DatePicker label="Birth Date" name="birthDate" selectedDate={globalState.user?.birthDateItem?.text} id="dt-birthdate" drops="up"  />

                    </div>
                </div>
          </div>
      </Form>
  );
}

export default ProfileDetailsForm;
