import { useEffect, useState } from "react"
import Modal from "../../../../../components/base/Modal"
import DropDown from "../../../../../components/form/DropDown"
import { useHub } from "../../../../../core/Global"
import Job from "../../models/Job"
import { API_ROUTES } from "../../../../../core/Constants"
import Technician from "../../../../technicians/models/technician"
import DatePicker from "../../../../../components/form/DatePicker"
import TimePicker, { TimeItem } from "../../../../../components/form/TimePicker"
import JobMetro from "../../../base/models/JobMetro"


interface IProps {
    job?: Job
    error?: string
    onClose?: () => void
    onUpdate?: (job: Job) => void
    onDiscard?: () => void
}
export default function JobCloseNotValidModal(props: IProps) {

    const hub = useHub();

    const [metros, metrosSet] = useState<JobMetro[]>();
    const [selectedMetro, selectedMetroSet] = useState<JobMetro>();
    const [techs, techsSet] = useState<Technician[]>();
    const [selectedTech, selectedTechSet] = useState<Technician>();

    const [minToTime, minToTimeSet] = useState<number>();
    const [apFromTime, apFromTimeSet] = useState<TimeItem>();
    const [apToTime, apToTimeSet] = useState<TimeItem>();

    useEffect(() => {

        if (props.job) {

            hub.Get(API_ROUTES.JobMetros + "all", res => {
                if (res.success) {
                    metrosSet(res.data);
                }
            })

        }

    }, [props.job])

    useEffect(() => {
        if (props.job?.metroId && metros) {
            metros.forEach(m => {
                if (m.id == props.job?.metroId) selectedMetroSet(m);
            });
        }
    }, [props.job, metros])

    useEffect(() => {

        if (props.job && selectedMetro) {

            var q = "?jobId=" + props.job.id;
            //q += "&metroId=" + selectedMetro.id;
            hub.Get("/accounts/Technicians/byjob/" + q, res => {
                if (res.success) {
                    techsSet(res.data);
                }
            })

        }

    }, [props.job, selectedMetro])

    return (
        <Modal id="modal-closejob-notvalid"
            title="Close Job"
            subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
            open visible staticBackdrop
            onClose={props.onClose}
            buttonColor="primary"
            buttonText="Update Job & Close"
            onCancel={() => {
                if (props.onDiscard) props.onDiscard();
                window.closeModal("modal-closejob-notvalid");
            }}
            onSubmit={(body, callback) => {

                var valid = true;

                if (window.isNullOrEmpty(body.metroId)) {
                    window.formError("metroId", "Please select the metro!", "modal-closejob-notvalid");
                    valid = false;
                }

                if (window.isNullOrEmpty(body.techId)) {
                    window.formError("techId", "Please select the technician!", "modal-closejob-notvalid");
                    valid = false;
                }

                if (!valid) {
                    if (callback) callback();
                    return;
                }

                hub.Post(API_ROUTES.Jobs + "updatebeforeclose", {
                    ...body,
                    id: props.job?.id
                }, res => {
                    if (res.success) {
                        if (props.onUpdate && props.job) props.onUpdate(props.job);
                    }
                    if (callback) callback(res);
                })


            }}>
            <div>
                <div className="alert alert-warning">Before closing the job you must set the required fields: </div>

                <DropDown id="drp-metros" name="metroId" label="Metro *" labelUp marginBottom={5} source={metros}
                    defaultValue={props.job?.metroId} parentModal="modal-closejob-notvalid"
                    onSelectItem={item => selectedMetroSet(item)}
                    onClear={() => {
                        selectedMetroSet(undefined);
                        techsSet(t => [...[]]);
                    }} />

                <DropDown id="drp-technicians" label="Technician *" name="techId" labelUp marginBottom={5}
                    source={techs} dataText="fullName" dataValue="id" parentModal="modal-closejob-notvalid"
                    defaultValue={props.job?.techId}
                    onSelectItem={(item => selectedTechSet(item))} />




                <div className="row">
                    <div className="col-12 col-md-6">
                        <DatePicker id="dt-jobupdateappoitment" name="appointmentDate" label="Appointment Date" labelUp
                            block empty selectedDate={props.job?.appointmentDate} onPick={e => {
                                window.hideFormErrors();
                            }} />
                    </div>
                    <div className="col-12 col-md-3">
                        <TimePicker id="job-appointmentmodal-from" name="appointmentFrom"
                            label="From" labelUp defaultValue={props.job?.appointmentTimeFrom}
                            onTimePicked={t => {
                                window.hideFormErrors();
                                minToTimeSet(t.time);
                                apFromTimeSet(t);
                            }}
                            onTimeCleared={() => apFromTimeSet(undefined)}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <TimePicker id="job-appointmentmodal-to" name="appointmentTo"
                            min={minToTime} time={minToTime ? (minToTime + 60) : undefined}
                            onTimePicked={t => {
                                window.hideFormErrors();
                                apToTimeSet(t);
                            }}
                            onTimeCleared={() => apToTimeSet(undefined)}
                            label="To" labelUp defaultValue={props.job?.appointmentTimeTo} />
                    </div>
                </div>

            </div>
        </Modal>
    )
}