

export const SetThemeToLight = () => {
    window.KTThemeMode.setMode("light");
}

export const SetThemeToDark = () => {
    window.KTThemeMode.setMode("dark");
}

export const GetThemeMode = (): string => {
    return window.KTThemeMode?.getMode() ?? "light"
}