import { useEffect, useState } from "react";
import FlexRow from "../base/FlexRow";
import DataItem from "../../models/DataItem";
import { DayOfWeek } from "../../core/Enums";


interface IProps {
    id?: string
    name?: string
    selectedWeekDays?: DayOfWeek[]
    marginBottom?: number
}

export default function WeekdaysPicker(props: IProps) {

    const [weekDays] = useState<DayOfWeek[]>([
        DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday
    ])

    const [selectedWeekDays, selectedWeekDaysSet] = useState<DayOfWeek[]>([]);

    useEffect(() => {
        if (props.selectedWeekDays) {
            selectedWeekDaysSet(props.selectedWeekDays);
        }
    }, [props.selectedWeekDays])

    return (
        <FlexRow className={"mb-" + (props.marginBottom ?? 5)}>

            {
                weekDays.map((day, k) => 
                <div key={k} className="form-check form-check-custom form-check-solid form-check-sm me-3">
                    <label className="form-check-label fs-7">
                        <input name={props.name} className="form-check-input me-1" type="checkbox" 
                            checked={(selectedWeekDays.indexOf(day) > -1)} 
                            value={day}
                            onChange={e=> {
                                var arr = selectedWeekDays;
                                if(e.target.checked) {
                                    if (arr.indexOf(day) < 0) arr.push(day);
                                }
                                else {
                                    if (arr.indexOf(day) > -1) arr.splice(arr.indexOf(day), 1);
                                }
                                selectedWeekDaysSet(d=> [...arr]);
                            }}/>
                        {DayOfWeek[day]}
                    </label>
                </div>
            )   
            }

        </FlexRow>
    )
}