import { useEffect, useState } from "react"
import { API_ROUTES } from "../../../../core/Constants";
import { getDeviceId, getUserToken, useEnvironment, useHub } from "../../../../core/Global";
import JobAttachment from "../models/JobAttachment";
import Indicator from "../../../../components/base/Indicator";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Avatar from "../../../../components/base/Avatar";
import Modal from "../../../../components/base/Modal";

interface IProps {
    jobId?: string
    refresh?: boolean
    onSelectAttachment?: (item: JobAttachment) => void
}
let attachmentIds = "";
export default function JobAttachments(props: IProps) {
    let editInput: HTMLInputElement | null;
    let dzone: any;
    const env = useEnvironment();
    const hub = useHub();

    const [fetching, fetchingSet] = useState<boolean>(true);
    const [model, modelSet] = useState<JobAttachment[]>([]);
    const [selectedItem, selectedItemSet] = useState<JobAttachment>();

    const [editItem, editItemSet] = useState<JobAttachment>();
    const [editName, editNameSet] = useState<string>();
    useEffect(() => {
        if (editItem) {
            var name = editItem.fileName;
            name = name?.substring(0, name.lastIndexOf('.'));
            editNameSet(name);
            editInput?.focus();
            editInput?.select();
        }
        else editNameSet("");
    }, [editItem])

    const [renaming, renamingSet] = useState<boolean>(false);
    const rename = () => {
        renamingSet(true);

        hub.Post(API_ROUTES.Jobs + "attachmentsrename/" + editItem?.id, {
            name: editName
        }, res => {
            if (res.success) {
                fetch();
                editItemSet(undefined);
            }
            renamingSet(false);
        })


    }





    const headers = {
        'SecretKey': env.secretKey,
        'Authorization': getUserToken(),
        'DeviceId': getDeviceId(),
        'Version': env.versionNumber
    }

    const createDropZone = () => {
        if (!dzone) {
            dzone = window.dropzone("job_dropzonejs_attachments",
                env.apiUrl + API_ROUTES.Jobs + "attachments/" + props.jobId,
                headers, () => {
                    log();
                    fetch();
                });
        }
    }

    const log = () => {
        hub.Post(API_ROUTES.Jobs + "attachmentslog/" + props.jobId, {

        }, res => {

        })
    }

    const fetch = () => {

        hub.Get(API_ROUTES.Jobs + "attachments/" + props.jobId, res => {
            if (res.success) modelSet(res.data)
            else modelSet([]);
            fetchingSet(false);
        })

    }

    useEffect(() => {

        if (props.jobId) {
            fetch();
            createDropZone();
        }

    }, [props.jobId])

    useEffect(() => {
        if (props.refresh) fetch();
    }, [props.refresh])




    return (
        <div>
            <div className="fv-row">
                <div className="dropzone" id="job_dropzonejs_attachments">
                    <div className="dz-message needsclick">
                        <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                        <div className="ms-4">
                            <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Drop files here or click to upload.</h3>
                            <span className="fs-7 fw-bold text-gray-400">Upload up to 10 files</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                fetching &&
                <div className="p-10 py-20">
                    <Indicator />
                </div>
            }

            {
                !fetching && model.length == 0 &&
                <div className="p-10 py-20 text-muted text-center">
                    There is no uploaded attachment!
                </div>
            }

            {
                !fetching && model.length > 0 &&
                <div className="my-10">
                    <table className="table table-hover table-sm align-middle table-row-dashed">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>
                                    File Name
                                </th>
                                <th>
                                    Upload Date
                                </th>
                                <th>
                                    Uploaded By
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                model.map((item, k) =>
                                    <tr key={k}>
                                        {
                                            editItem?.id == item.id ?
                                                <>
                                                    <td colSpan={3}>
                                                        <FlexRow>
                                                            <input ref={e => editInput = e} type="text" className="form-control form-control-sm" value={editName}
                                                                onChange={e => editNameSet(e.target.value)}
                                                                onKeyPress={e => {
                                                                    if (e.key == "Escape") {
                                                                        editItemSet(undefined);
                                                                    }
                                                                    if (e.key == "Enter") {
                                                                        rename();
                                                                    }
                                                                }} />
                                                            <button type="button" className="btn btn-secondary btn-sm ms-2" onClick={e => editItemSet(undefined)}>Cancel</button>
                                                            <button type="button" className="btn btn-primary btn-sm w-100px ms-1" onClick={rename} disabled={renaming}>
                                                                {
                                                                    renaming ? <Icon icon="asterisk" spin />
                                                                        : <span>Update</span>
                                                                }
                                                            </button>
                                                        </FlexRow>
                                                    </td>
                                                    <td>

                                                    </td>
                                                </>
                                                :
                                                <>
                                                    <td>
                                                        <a href={env.cdnUrl + "/base/public/job/attachment/download/" + item.id} target="_blank">
                                                            <FlexRow className="px-3 py-4">
                                                                <Icon icon={item.fileIcon} type="solid" className="me-3" size={20} color={item.fileIconColor} />
                                                                <div className="fs-8" title={item.fileName}
                                                                    style={{
                                                                        maxWidth: 200,
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden"
                                                                    }}>
                                                                    {item.fileName}
                                                                </div>
                                                            </FlexRow>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="fs-8">
                                                            {item.createDate}
                                                        </div>
                                                    </td>
                                                    <td title={item.creatorUser?.fullName}>
                                                        <FlexRow>
                                                            <Avatar avatarUrl={item.creatorUser?.avatarUrl} width={20} className="me-2" />
                                                            <div className="fs-8"
                                                                style={{
                                                                    maxWidth: 100,
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden"
                                                                }}>
                                                                {item.creatorUser?.fullName}
                                                            </div>
                                                        </FlexRow>
                                                    </td>
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-default bnt-icon btn-active-light-primary btn-sm"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                window.dataTableMenu();
                                                            }}
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <Icon icon="ellipsis-vertical" />
                                                        </a>
                                                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-2 px-0" data-kt-menu="true">
                                                            <div className="menu-item">
                                                                <a href="#" className="menu-link fs-8" style={{ color: '#181c32' }}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        editItemSet(item);
                                                                    }}
                                                                >
                                                                    <Icon icon="edit" className="me-2" color="#181c32" size={11} />
                                                                    Rename
                                                                </a>
                                                            </div>
                                                            <div className="menu-item">
                                                                <a href={env.cdnUrl + "/base/public/job/attachment/download/" + item.id} target="_blank" className="menu-link fs-8" style={{ color: '#181c32' }}>
                                                                    <Icon icon="download" className="me-2" color="#181c32" size={11} />
                                                                    Download
                                                                </a>
                                                            </div>
                                                            <div className="menu-item">
                                                                <a href="#" className="menu-link fs-8" style={{ color: 'red' }}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        selectedItemSet(item);
                                                                        if (props.onSelectAttachment) props.onSelectAttachment(item);
                                                                        window.openModal("modal-job-attachments-delete");
                                                                    }}
                                                                >
                                                                    <Icon icon="circle-minus" className="me-2" color="red" size={11} />
                                                                    Delete
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </>
                                        }

                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            }



            <Modal id="modal-job-attachments-delete"
                title="Delete Attachment"
                secondModal
                buttonText="Confirm & Delete"
                buttonColor="danger"
                onConfirm={callback => {

                    hub.Delete(API_ROUTES.Jobs + "attachments/" + selectedItem?.id,
                        res => {
                            if (res.success) {
                                fetch()
                                callback();
                            }
                        });

                }}>
                <div>
                    <p>
                        Are you sure you want to delete this attachment file?
                    </p>
                    <FlexRow className="px-6 py-6 bg-light">
                        <Icon icon={selectedItem?.fileIcon} color={selectedItem?.fileIconColor} type="solid" className="me-3" size={20} />
                        <div style={{
                            maxWidth: "80%",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}>{selectedItem?.fileName}</div>
                    </FlexRow>
                </div>
            </Modal>
        </div>
    )
}