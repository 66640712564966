import { hasRoles, openMobileMenu, useEnvironment } from "../../../core/Global";
import Icon from "../../base/Icon";
import Menu from "../../../core/Menu.json";
import Container from "../../base/Container";
import { Link } from "react-router-dom";
import HeaderNavbar from "./HeaderNavbar";



export default function MobileHeader() {

    const env = useEnvironment();


    return (
        <div className="d-flex d-lg-none align-items-center px-5">

            <a href="#" className="me-4" onClick={e => {
                e.preventDefault()
                openMobileMenu()
            }}>
                <i className="ki-duotone ki-element-11 text-primary" style={{ fontSize: 24 }}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                </i>
            </a>


            <div className="d-flex align-items-center">
                <img src={`/assets/brands/${env.brand}/icon.png`} className="h-25px" />
                <div className="ms-1 fw-bolder" style={{
                    color: "#333",
                    fontSize: 16,
                    fontFamily: "Poppins"
                }}>
                    {env.siteTitle}
                </div>
            </div>
        </div>
    )
}