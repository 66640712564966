import "./Agents.css";
import React, { useEffect, useState } from "react";
import { setPage, useGlobal, useHub } from '../../../core/Global';
import Layout from '../../../components/layouts/Layout';
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Environment from '../../../env/env';
import Hub from "../../../api/Hub";
import { useParams, Link } from 'react-router-dom';
import Agent, { AgentExtensionItem } from '../models/Agent';
import Icon from '../../../components/base/Icon';
import Avatar from "../../../components/base/Avatar";
import LiveCall from '../../voice/calls/models/LiveCall';
import Timer from "../../../components/timer/Timer";
import Modal from '../../../components/base/Modal';
import LiveCallActions from '../../../components/calls/LiveCallActions';
import { AgentStatus } from '../models/AgentDetailsModel';
import { AgentStatuses } from "src/core/Enums";
import { API_ROUTES } from "../../../core/Constants";
import CallAgent from "../../voice/calls/models/CallAgent";


let data = new Array<Agent>();
let agentIds: string[] = [];
function AgentsView() {

    const env = new Environment();
    const hub = useHub();
    const { selectedId } = useParams(); 
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Agent[]>();
    const [onlineModel, onlineModelSet] = useState<Agent[]>();
    const [offlineModel, offlineModelSet] = useState<Agent[]>();
    const [selectedCall, selectedCallSet] = useState<LiveCall>();
    const [selectedAgent, selectedAgentSet] = useState<Agent>();
    const [selectedExtension, selectedExtensionSet] = useState<AgentExtensionItem>();
    const [lineObj, lineObjSet] = useState<any>();

    const fetch = async (callback?: () => void) => {
        hub.Get(API_ROUTES.DashboardAgentsMap, res=> {
            if(res?.data) {
                for (var i = 0; i < res.data.online?.length; i++) agentIds.push(res.data.online[i].id);
                for (var i = 0; i < res.data.offline?.length; i++) agentIds.push(res.data.offline[i].id);
                onlineModelSet(res.data.online);
                offlineModelSet(res.data.offline);
                if (callback) callback();
                fetchingSet(false);
                fetchedSet(true);
            }
        });
    }

    const updateCall = async (call:any) => {
        var exists = false;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == call.destinationAgent?.id || data[i].id == call.sourceAgent?.id) {
                console.log("detected", data[i]);
                data[i].currentCall = call;
                exists = true;
            }
        }
        if (exists) {
            modelSet(m => [...data]);
        }
    }

    const endCall = async (call: any) => {      
        var exists = false;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == call.destinationAgent?.id || data[i].id == call.sourceAgent?.id) {
                data[i].currentCall = undefined;
                exists = true;
            }
        }
        if (exists) {
            modelSet(m => [...data]);
        }
    }

    const updateStatus = async (agId:string, st: AgentStatus) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == agId) {
                data[i].status = st;
            }
        }

        modelSet(m => [...data]);
    }

    const updateExtNumber = async (agId:string, ext: number) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].id == agId) {
                data[i].extensionNumber = ext;
            }
        }
        modelSet(m => [...data]);
    }

    useEffect(()=> {
        setPage("Agents Map", "Agents");

        fetch(() => {

            // getSocket(socket => {
            //     console.log("StartListenToAgents", agentIds);
            //     socket.invoke("StartListenToAgents", agentIds)
            //         .then(()=> {
            //             socket.on('UpdateAgentMap', ext => fetch());
            //         });

    
            // })

        });

        return () => {
            // getSocket(socket => {
            //     socket.invoke("StopListenToAgents", agentIds);
            // })
        };


    }, [])

    const CallerID = (props: {agent?: CallAgent} ) => (
        <span className="ms-2 d-flex align-items-center">
            {
                props.agent?.country ?
                <img src={props.agent.country.flagUrl} className="w-20px" />
                :
                <Avatar width={20} avatarUrl={props.agent?.avatarUrl} />
            }

            {
                props.agent?.unknown ?
                <span className="ms-2">
                    {props.agent.number}
                </span>
                :
                <span className="ms-2">
                    {
                        props.agent?.name
                    }
                </span>
            }

        </span>
    )
    
    const ExtMenu = (props: {ext: AgentExtensionItem, item: Agent}) => (
        <>
            <a href="#" className="px-2 text-dark"
                onClick={e=> {
                    e.preventDefault();
                    window.dataTableMenu();
                }}
                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <Icon icon="ellipsis-vertical" />
            </a>
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-8 w-150px p-0" data-kt-menu="true">
                {
                    props.ext.currentCall &&
                    <>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3" 
                                data-bs-toggle="modal" data-bs-target="#modal-hangup"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                }}
                            >
                                <Icon icon="phone-slash" className="me-2"/>
                                Hang Up
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3" 
                                data-bs-toggle="modal"
                                data-bs-target="#modal-listen"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '994762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="volume" className="me-2"/>
                                Listen To Call
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3" 
                                data-bs-toggle="modal"
                                data-bs-target="#modal-whisper"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '995762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="user-headset" className="me-2"/>
                                Talk To Agent
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3" 
                                data-bs-toggle="modal"
                                data-bs-target="#modal-barge"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '996762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="phone-volume" className="me-2"/>
                                Join The Call
                            </a>
                        </div>
                    </>
                }
                {
                    !props.ext.currentCall &&
                    <>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3" 
                                onClick={e => {
                                    e.preventDefault();
                                    selectedAgentSet(props.item);
                                    selectedExtensionSet(props.ext);
                                    window.openModal("modal-revoke-ext");
                                }}
                            >
                                <Icon icon="times" className="me-2"/>
                                Revoke 
                            </a>
                        </div>
                    </>
                }
                
            </div>
        </>
    )

    const AgentCard = (props: {item: Agent}) => (
        <div className={"card card-agentmap card-agentmap-" + props.item.status?.colorClass + " mb-5"}>
            <div className="card-body py-0 px-0">
                <div className="d-flex flex-wrap flex-sm-nowrap align-items-center pe-5">
                    <div className="d-flex flex-column me-5">
                        <div className="symbol symbol-50px symbol-fixed position-relative">
                            <img src={props.item?.avatar ?? "/assets/media/avatars/blank.png"} />
                            <div className={"bg-" + props.item.status?.colorClass + " position-absolute translate-middle bottom-0 start-100 rounded-circle border border-2 border-white h-15px w-15px"}></div>        
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-end flex-wrap">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-0">
                                    <Link to={"/agents/view/" + props.item?.id} className="text-gray-900 text-hover-primary fs-6 fw-bolder me-2">
                                        {props.item?.firstName + " " + props.item?.lastName.substring(0,1).toUpperCase()} 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-1">
                        <span className={"badge badge-" + props.item.status?.colorClass + " badge-sm px-2 fs-9 fw-normal"}>
                            <Icon icon="phone-hangup" type="solid" className="me-2" size={9} />
                            {props.item.status?.name}
                        </span>
                    </div>
                    <div className="ms-auto">
                        {
                            (props.item.status?.status == AgentStatuses.Active)  &&
                            <a href="#" title="Force Logout"
                                className="mx-2 force-logout"
                                onClick={e=> {
                                    e.preventDefault();
                                    selectedAgentSet(props.item);
                                    window.openModal("modal-logout-user");
                                }}>
                                <Icon icon="right-from-bracket" color="#666" size={16} />
                            </a>
                        }
                    </div>
                </div>
            </div>
            {
                props.item.extensions && props.item.extensions.length > 0 &&
                <div className="card-body p-4">
                    {
                        props.item.extensions.map((ext, key) => 
                            <>
                                <hr />
                                <div key={key} className="d-flex align-items-center px-2">
                                    <div className="me-2">
                                        <Icon icon="phone-office" type="solid" className="me-2" size={11} />
                                        {ext.extNumber}
                                    </div>
                                    {
                                        !ext.currentCall &&
                                        <span className={"badge badge-success badge-sm px-2 fs-9 fw-normal"}>
                                            <Icon icon="phone-hangup" type="solid" className="me-2" size={9} />
                                            Active
                                        </span> 
                                    }
                                    {
                                        ext.currentCall &&
                                        <>
                                            {
                                                ext.currentCall.ringing ?
                                                <span className="d-flex align-items-center badge badge-light-primary badge-sm px-5">
                                                    <Icon icon="phone-rotary" type="solid" className="me-2" shake color="#009ef7" size={15} />
                                                    <CallerID agent={ext.currentCall.sourceAgent?.id == props.item.id ? ext.currentCall.destinationAgent : ext.currentCall.sourceAgent} />
                                                </span>
                                                :
                                                <>
                                                    <span className="badge badge-danger badge-sm px-3" style={{
                                                        fontSize: 10,
                                                        zIndex: 5
                                                    }}>
                                                        <Icon icon="phone-volume" type="solid" className="me-2" size={10} color="#fff" />
                                                        <Timer totalSeconds={ext.currentCall?.talkingSeconds} />
                                                    </span>
                                                    <span style={{
                                                        fontSize: 11
                                                    }}>
                                                        <CallerID agent={ext.currentCall?.sourceAgent?.id == props.item.id ? ext.currentCall.destinationAgent : ext.currentCall?.sourceAgent} />
                                                    </span>
                                                </>
                                            }
                                        </>
                                    }
                                    <div className="ms-auto">
                                        <ExtMenu ext={ext} item={props.item} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            }
            
            <div className="card-footer p-4">
                {
                    props.item.loginAt &&
                    <>
                        <div className="d-flex mb-2">
                            <div>
                                <div className="fw-bolder">
                                    Login Time
                                </div>
                                <div>
                                    {props.item.loginAt}
                                </div>
                            </div>
                            {
                                props.item.logoutAt ?
                                <div className="ms-auto">
                                    <div className="fw-bolder">
                                        Logout Time
                                    </div>
                                    <div>
                                        {props.item.logoutAt}
                                    </div>
                                </div>
                                :
                                <div className="ms-auto">
                                    <div className="fw-bolder text-end">
                                        Duration
                                    </div>
                                    <div className="text-start">
                                        <Timer totalSeconds={props.item.loginDuration} />
                                    </div>
                                </div>
                            }
                            
                        </div>
                        <hr />
                    </>
                }
                <div className="d-flex mb-2">
                    <div className="fw-bolder">
                        Total Calls
                    </div>
                    <div className="ms-auto fw-bolder">
                        {props.item.totalCalls}
                    </div>
                </div>
                <div className="d-flex mb-2">
                    <div>
                        Incoming Calls
                    </div>
                    <div className="ms-auto">
                        {props.item.incomingCalls}
                    </div>
                </div>
                <div className="d-flex mb-2">
                    <div>
                        Outgoing Calls
                    </div>
                    <div className="ms-auto">
                        {props.item.outgoingCalls}
                    </div>
                </div>
                <hr />
                <div className="d-flex mb-2">
                    <div>
                        Answered Calls
                    </div>
                    <div className="ms-auto">
                        {props.item.answeredCalls}
                    </div>
                </div>
                <div className="d-flex mb-2">
                    <div>
                        Missed Calls
                    </div>
                    <div className="ms-auto">
                        {props.item.missedCalls}
                    </div>
                </div>
                <div className="d-flex mb-2">
                    <div>
                        Failed Calls
                    </div>
                    <div className="ms-auto">
                        {props.item.failedCalls}
                    </div>
                </div>
                <hr />
                <div className="d-flex mb-2">
                    <div>
                        Total Talk Time
                    </div>
                    <div className="ms-auto">
                        {props.item.totalTalkTime}
                    </div>
                </div>
                <div className="d-flex">
                    <div>
                        Total Active Time
                    </div>
                    <div className="ms-auto">
                        {props.item.totalActiveTime}
                    </div>
                </div>
            </div>
        </div>
    )

    const OldCard = (item: any) => (
        <div>
            {
                !item.currentCall &&
                <span className={"badge badge-" + item.status?.colorClass + " badge-sm px-2 fs-9 fw-normal"}>
                    <Icon icon="phone-hangup" type="solid" className="me-2" size={9} />
                    {item.status?.name}
                </span>
            }
            
            {
                item.currentCall && 
                <>
                    {
                        item.currentCall.ringing ?
                        <span className="d-flex align-items-center badge badge-light-primary badge-sm px-5">
                            <Icon icon="phone-rotary" type="solid" className="me-2" shake color="#009ef7" size={15} />
                            <CallerID agent={item.currentCall.sourceAgent?.id == item.id ? item.currentCall.destinationAgent : item.currentCall.sourceAgent} />
                        </span>
                        :
                        <>
                            <span className="badge badge-danger badge-sm px-3" style={{
                                fontSize: 10,
                                zIndex: 5
                            }}>
                                <Icon icon="phone-volume" type="solid" className="me-2" size={10} color="#fff" />
                                <Timer totalSeconds={item.currentCall?.talkingSeconds} />
                            </span>
                            <span style={{
                                fontSize: 11
                            }}>
                                <CallerID agent={item.currentCall.sourceAgent?.id == item.id ? item.currentCall.destinationAgent : item.currentCall.sourceAgent} />
                            </span>
                            
                        </>
                    }
                </>
            }

            {
                (item.status?.status == AgentStatuses.Active || item.extensionNumber > 0)  &&
                <a href="#" title="Force Logout"
                    className="mx-2 force-logout"
                    onClick={e=> {
                        e.preventDefault();
                        selectedAgentSet(item);
                        window.openModal("modal-logout-user");
                    }}>
                    <Icon icon="right-from-bracket" color="#666" size={16} />
                </a>
            }

            {
                item.currentCall?.talking &&
                <div className="me-2">
                    <LiveCallActions call={item.currentCall} />
                </div>
            }
        </div>
    )

    return (
        <Layout>
            <audio id="maincall"></audio>
            <Div visible={fetching} className="mt-20">
                <Indicator />
            </Div>

            <Div visible={fetched}>

                <Div visible={onlineModel && onlineModel.length > 0}>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <h3 className="text-dark">Online ({onlineModel?.length})</h3>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            onlineModel?.map((item, key) => 
                            <div key={key} className="col-sm-6 col-lg-4">
                                <AgentCard item={item} />
                            </div>
                            )
                        }
                    </div>
                </Div>

                <Div visible={offlineModel && offlineModel.length > 0}>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <h3 className="text-dark">Offline ({offlineModel?.length})</h3>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            offlineModel?.map((item, key) => 
                            <div key={key} className="col-sm-6 col-lg-4">
                                <AgentCard item={item} />
                            </div>
                            )
                        }
                    </div>
                </Div>

            </Div>

            <Modal id="modal-logout-user"
                    title="Logout User"
                    buttonText="Force Logout"
                    buttonColor="danger"
                    onConfirm={(callback: any)=> {
                        hub.Get("/auth/logout/" + selectedAgent?.id, res => {
                            if(res.data) {
                                if (callback) callback();
                            }
                        })
                    }}>
                <p className="ps-1">Are you sure you want to force logout this user?</p>
                <div className="p-4 bg-secondary d-flex align-items-center">
                    <span>{selectedAgent?.firstName + " " + selectedAgent?.lastName}</span>
                    {
                        selectedAgent?.extensions?.map((ext, key) => 
                        <span key={key} className="ms-4">
                            <Icon icon="phone-office" type="solid" size={9} />
                            <span className="ms-1 fs-8">{ext.extNumber}</span>
                        </span>
                        )
                    }
                </div>
            </Modal>

            <Modal id="modal-revoke-ext"
                    title="Revoke Extension"
                    buttonText="Revoke Extension"
                    buttonColor="danger"
                    onConfirm={(callback: any)=> {
                        hub.Post("/system/extensions/revokext", {
                            userId: selectedAgent?.id,
                            ext: selectedExtension?.extNumber
                        }, res => {
                            if (res.data) {
                                if (callback) callback();
                            }
                        })
                    }}>
                <p className="ps-1">Are you sure you want to revoke this extension?</p>
                <div className="p-4 bg-secondary d-flex align-items-center">
                    <span>{selectedAgent?.firstName + " " + selectedAgent?.lastName}</span>
                    <span className="ms-4">
                        <Icon icon="phone-office" type="solid" size={9} />
                        <span className="ms-1 fs-8">{selectedExtension?.extNumber}</span>
                    </span>
                </div>
            </Modal>

        </Layout>
    )

}

export default AgentsView;