import { useEffect, useState } from "react";
import Form from "../../../../components/form/Form";
import { useHub, usePreferences } from "../../../../core/Global";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import TextBox from "../../../../components/form/TextBox";
import PhoneInput from "../../../../components/form/PhoneInput";
import FlexRow from "../../../../components/base/FlexRow";
import DropDown from "../../../../components/form/DropDown";
import Button from "../../../../components/base/Button";
import { v4 as uuidv4 } from 'uuid';
import { API_ROUTES } from "../../../../core/Constants";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import ChatForwardRule from "../../../chat/models/ChatForwardRule";




export default function ChatForwardCard() {


    const hub = useHub();
    const pref = usePreferences();
    const [working, workingSet] = useState<boolean>(false)
    const [forwardAllIncomingChats, forwardAllIncomingChatsSet] = useState<boolean>(false)
    const [forwardAllOutgoingChats, forwardAllOutgoingChatsSet] = useState<boolean>(false)
    const [model, modelSet] = useState<ChatForwardRule[]>([])

    useEffect(() => {
        if (pref?.chatForwardSettings) {
            forwardAllIncomingChatsSet(pref.chatForwardSettings.forwardAllIncomingChats);
            forwardAllOutgoingChatsSet(pref.chatForwardSettings.forwardAllOutgoingChats);
            modelSet(m => pref.chatForwardSettings.forwardRules);
        }
    }, [pref?.chatForwardSettings])

    return (
        <Form id="form-chat-forward-rules" className="form"
            onSubmit={(body, callback) => {

                var valid = true;
                var focus = true;

                for (var i = 0; i < model.length; i++) {
                    var m = model[i];

                    if (window.isNullOrEmpty(m.senderLineId)) {
                        window.formError(`sender${i}`, "Choose a sender!", "form-chat-forward-rules", focus);
                        valid = false;
                        focus = false;
                    }

                    if (!m.forwardToNumber || m.forwardToNumber.length < 9) {
                        window.formError(`phone${i}`, "Write a number!", "form-chat-forward-rules", focus);
                        valid = false;
                        focus = false;
                    }



                }

                if (!valid) {
                    return;
                }

                hub.Post("/system/Settings/chatforwardrules", {
                    forwardAllIncomingChats,
                    forwardAllOutgoingChats,
                    forwardRules: model
                }, res => {
                    if (res.success) {
                        toastSuccess("Chat forward settings has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Chat Forward
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Settings</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-20">

                    <ToggleSwitch label="Forward all incoming messages to the defined rules!"
                        name="forwardAllIncomingChats" raw small className="mb-5"
                        checked={forwardAllIncomingChats}
                        onChange={e => forwardAllIncomingChatsSet(e)} />

                    <ToggleSwitch label="Forward all outgoing messages to the defined rules!"
                        name="forwardAllOutgoingChats" raw small className="mb-5"
                        checked={forwardAllOutgoingChats}
                        onChange={e => forwardAllOutgoingChatsSet(e)} />

                    <div className="my-10 border-bottom">
                        {/* {JSON.stringify(model)} */}
                    </div>


                    {
                        model.length === 0 &&
                        <div className="text-center my-20">
                            <Button text="Add New Rule" icon="plus" type="outline" className="w-200px"
                                onClick={() => {
                                    modelSet(m => [{
                                        id: uuidv4()
                                    }])
                                }} />
                        </div>
                    }

                    {
                        model.map((item, key) =>
                            <FlexRow key={item.id} alignItems="end" className="mb-4">
                                <div className="w-lg-200px w-xxl-300px me-2">
                                    <DropDown key={`drp-senders-${item.id}`} id={`drp-senders-${item.id}`}
                                        apiSource={API_ROUTES.Chat + "fromnumbers"} dataValue="id" dataText="name"
                                        name={`sender${key}`} block labelUp label="Send with" marginBottom={0} onSelectUpdate={model}
                                        defaultValue={item.senderLineId}
                                        onSelect={id => {
                                            modelSet(prevRules =>
                                                prevRules.map(rule =>
                                                    rule.id === item.id
                                                        ? { ...rule, senderLineId: id }
                                                        : rule
                                                ))
                                        }} />
                                </div>
                                <div className="w-lg-200px w-xxl-250px me-2">
                                    <PhoneInput key={`txt-phone-item-${item.id}`} id={`txt-phone-item-${item.id}`}
                                        name={`phone${key}`} countryCodes="us-only" block labelUp label="Forward to" noMarginBottom
                                        defaultValue={item.forwardToNumber?.replace("+1", "")}
                                        onComplete={e => {
                                            modelSet(prevRules =>
                                                prevRules.map(rule =>
                                                    rule.id === item.id
                                                        ? { ...rule, forwardToNumber: "+1" + e.phone }
                                                        : rule
                                                ))
                                        }}
                                        onInComplete={() => {
                                            modelSet(prevRules =>
                                                prevRules.map(rule =>
                                                    rule.id === item.id
                                                        ? { ...rule, forwardToNumber: "" }
                                                        : rule
                                                ))
                                        }}
                                    />
                                </div>
                                <div className="w-lg-200px w-xxl-250px me-2">
                                    <TextBox key={`txt-phone-title-${item.id}`} id={`txt-phone-title-${item.id}`}
                                        name={`title${key}`} placeholder="Description" block labelUp label="&nbsp;" noMarginBottom
                                        value={item.description}
                                        onChange={e => {
                                            modelSet(prevRules =>
                                                prevRules.map(rule =>
                                                    rule.id === item.id
                                                        ? { ...rule, description: e.target.value }
                                                        : rule
                                                ))
                                        }} />
                                </div>
                                <FlexRow className="mb-1">
                                    <button type="button" className="btn btn-light-danger btn-active-light-danger btn-sm btn-icon me-1"
                                        onClick={e => {
                                            e.preventDefault();
                                            modelSet(m => m.filter(f => f.id != item.id))
                                        }}>
                                        <Icon icon="minus" />
                                    </button>
                                    {
                                        model.length - 1 == key &&
                                        <button type="button" className="btn btn-light btn-sm btn-icon"
                                            onClick={e => {
                                                e.preventDefault();
                                                modelSet(m => [...m, {
                                                    id: uuidv4()
                                                }])
                                            }}>
                                            <Icon icon="plus" />
                                        </button>
                                    }

                                </FlexRow>

                            </FlexRow>
                        )
                    }


                </div>
            </div>
        </Form>
    )
}