import { useEffect, useState } from "react"
import Job from "../models/Job"
import { useHub } from "../../../../core/Global"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"
import DropDown from "../../../../components/form/DropDown"
import JobDrawerStatus from "./jobdrawer/JobDrawerStatus"
import JobDrawerClient from "./jobdrawer/JobDrawerClient"
import JobDrawerTech from "./jobdrawer/JobDrawerTech"
import JobDrawerAppointment from "./jobdrawer/JobDrawerAppointment"
import JobDrawerAddress from "./jobdrawer/JobDrawerAddress"
import JobDrawerTags from "./jobdrawer/JobDrawerTags"
import JobDrawerDetails from "./jobdrawer/JobDrawerDetails"
import NotesContainer from "./NotesContainer"
import IconButton from "../../../../components/base/IconButton"


interface IProps {
    job: Job
    sentToTechTime?: number
    height?: number
    onWriteNote?: () => void
}
export default function JobDrawerEdit(props: IProps) {

    const hub = useHub()

    const [notesRefresh, notesRefreshSet] = useState<boolean>()
    useEffect(() => {
        notesRefreshSet(true)
        setTimeout(() => {
            notesRefreshSet(false)
        }, 1000);
    }, [props.job.notesCount])

    return (
        <div className="scroll-y" style={{ height: props.height }}>
            <div className="px-10 py-5">
                <JobDrawerClient job={props.job} />
            </div>

            <div className="px-10 py-5 bg-light">
                <JobDrawerStatus job={props.job} />
            </div>

            <div className="px-10 py-5">
                <JobDrawerAddress job={props.job} />
            </div>

            <div className="px-10 py-5 bg-light">
                <JobDrawerAppointment job={props.job} />
            </div>

            <div className="px-10 py-5">
                <JobDrawerTech job={props.job} />
            </div>

            <div className="px-10 py-5 bg-light">
                <JobDrawerTags job={props.job} />
            </div>

            <div className="px-10 py-5">
                <JobDrawerDetails job={props.job} />
            </div>

            <div className="px-10 py-5 bg-light">
                <FlexRow>
                    <div className="fw-bolder fs-5">
                        Latest Notes
                    </div>
                    <div className="ms-auto">
                        <IconButton icon="edit" size={14} type="solid" className="bg-light" title="Write a note..."
                            onClick={e => {
                                if (props.onWriteNote) props.onWriteNote();
                            }} />
                    </div>
                </FlexRow>
            </div>

            <div className="py-5">
                <NotesContainer key="jobs-drawerviewedit-notes" id="job-quickview-notes"
                    jobId={props.job.id}
                    refresh={notesRefresh}
                    className="px-5" />
            </div>

        </div>
    )
}