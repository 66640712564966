import React, { useEffect, useState } from "react";
import $ from "jquery";
import HUBResponse from '../../models/HUBResponse';
import { Validator } from '../../core/Types';
import { toastError } from "../base/Toast";

type FormProps = {
    id?: string
    children: any
    className?: string
    action?: string
    isUpdate?: boolean
    noForm?: boolean
    method?: "GET" | "POST" | "PUT" | "DELETE"
    validator?: Validator[]
    modalId?: string
    onSubmit?: (body: any, callback?: (res?: HUBResponse<any>) => void) => void
}

export default function Form(props: FormProps) {

    useEffect(() => {

    }, []);

    const formId = props.id ?? "form-" + Math.floor(Math.random() * 1000000);

    const isValidEmail = (email?: any) => {
        return email?.toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) ?? false;
    };

    const formSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        var formData = new FormData(e.target as HTMLFormElement);

        $(".form-error").hide();
        $(".form-general-error").html("");
        $(e.target).find("input").removeClass("is-invalid");

        if (props.validator) {

            for (var i = 0; i < props.validator.length; i++) {

                var v = props.validator[i];
                if (props.isUpdate && v.preventOnUpdate) continue;
                if (!props.isUpdate && v.preventOnCreate) continue;
                if (v.required) {
                    if (!formData.has(v.name) || formData.get(v.name) == null || formData.get(v.name) == "") {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html("this field is required!");
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                    }
                }
            }

            for (var i = 0; i < props.validator.length; i++) {
                var v = props.validator[i];
                if (props.isUpdate && v.preventOnUpdate) continue;
                if (!props.isUpdate && v.preventOnCreate) continue;
                if (v.required) {
                    if (!formData.has(v.name) || formData.get(v.name) == null || formData.get(v.name) == "") {
                        console.log("focus", $("input[name=" + v.name + "]"));
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html("this field is required!");
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.type == "Email") {
                    if (!isValidEmail(formData.get(v.name))) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html("please write a valid email address!");
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.maxLength && v.maxLength > 0) {
                    if ((formData.get(v.name)?.toString().length ?? 0) > v.maxLength) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html("maximum length: " + v.maxLength);
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.minLength && v.minLength > 0) {
                    if ((formData.get(v.name)?.toString().length ?? 0) < v.minLength) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html(v.name + " must contains at least " + v.minLength + " charachters!");
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.min != undefined && v.min != null) {
                    var val = parseInt(formData.get(v.name)?.toString() ?? "");
                    if (isNaN(val) || val < v.min) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        if (v.max != undefined && v.max != null) $("#" + formId).find("#" + v.name + "-err").html("value must be between " + v.min + " to " + v.max);
                        else $("#" + formId).find("#" + v.name + "-err").html("value must be greater than " + v.min);
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.max != undefined && v.max != null) {
                    var val = parseInt(formData.get(v.name)?.toString() ?? "");
                    if (isNaN(val) || val > v.max) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        if (v.min != undefined && v.min != null) $("#" + formId).find("#" + v.name + "-err").html("value must be between " + v.min + " to " + v.max);
                        else $("#" + formId).find("#" + v.name + "-err").html("value must be smaller than " + v.max);
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

                if (v.euqalTo) {
                    if (formData.get(v.name) !== formData.get(v.euqalTo)) {
                        $("#" + formId).find("input[name=" + v.name + "]").focus();
                        $("#" + formId).find("input[name=" + v.name + "]").select();
                        $("#" + formId).find("input[name=" + v.name + "]").addClass("is-invalid");
                        $("#" + formId).find("#" + v.name + "-err").html("value is not match with '" + v.euqalTo + "'");
                        $("#" + formId).find("#" + v.name + "-err").fadeIn().css("display", "inline-block");
                        return;
                    }
                }

            }
        }

        if (props.onSubmit) {

            var formBody: any = {};
            formData.forEach(function (value, key) {
                if (formBody[key]) formBody[key] += "," + value;
                else formBody[key] = value;
            });

            props.onSubmit(formBody, res => {

                if (!res?.success) {
                    if (res?.field && res.field != "" && res.message) {
                        // $("#" + formId).find("input[name="+res.field+"]").focus();
                        // $("#" + formId).find("input[name="+res.field+"]").select();
                        // $("#" + formId).find("#" + res.field + "-err").html(res.message ?? "");
                        // $("#" + formId).find("#" + res.field + "-err").fadeIn().css("display","inline-block");
                        window.formError(res.field, res.message, props.modalId)
                    }
                    else {
                        //$(".form-general-error").html(res?.message ?? "");
                        //toastError(res?.message ?? "something wrong!");
                    }
                }

            });
        }

        return false;
    };

    return (
        <>
            {
                props.noForm ?
                    <div className={props.className}>
                        {props.children}
                    </div>
                    :
                    <form className={props.className}
                        noValidate
                        id={formId}
                        onSubmit={formSubmit}>
                        {props.children}
                    </form>
            }
        </>
    )

}