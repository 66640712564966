import { useEffect, useState } from "react";
import Hub from "../../../api/Hub";
import Filtering from "../../../components/data/Filtering";
import filterProps from "../filtering/notifications.json";
import NotificationItem from "../../../components/notification/NotificationItem";
import Notification from "../../../models/Notification";
import BrowseOptions, { FilterParam } from "../../../models/BrowseOptions";

function ProfileNotifications() {

    const hub = new Hub<Notification[]>("/accounts/notifications/");
    const [model, modelSet] = useState<Notification[]>();
	const [loaded, loadedSet] = useState<boolean>(false);
    const[browse, browseSet] = useState<BrowseOptions>();

    const fetch = async (b?: BrowseOptions) => {
        if (b) browseSet(b);
        else b = browse;

		hub.Browse(b, res => {
			if (res.success) {
				modelSet(res.data);
				loadedSet(true);
			}
		})
	}

    const onFilter = async (params: FilterParam[], callback: () => void) => {
        var b = browse ?? {};
        b.filterParams = params;
        await fetch(b);
        await callback();
    };

    useEffect(() => {
        fetch();
    }, [])

    return (
        <div className="card">
            <div className="card-header card-header-stretch">
                <Filtering filteringProps={filterProps}
                    apiRoute="/accounts/notifications/"
                    loaded={loaded}
                    onFilter={onFilter} 
                    onRouteParamsBinded={params => {
                        var b = browse ?? {};
                        b.filterParams = params;
                        browseSet(b);
                    }} />
            </div>
            <div className="card-body">
                {
                    (model && model.length > 0) ?
                    <div className="timeline">
                        {
                            model?.map((item, key) => <NotificationItem key={key} item={item} />)
                        }
                    </div>
                    :
                    <div className="my-5">
                        <div className="card-px text-center pt-15 pb-15">
                            <h2 className="fs-2x fw-bolder mb-0">Nothing has happened!</h2>
                            <p className="text-gray-400 fs-4 fw-bold py-7">
                                There is no notification for today!
                            </p>
                        </div>
                        <div className="text-center pb-15 px-5">
                            <img src="/assets/media/illustrations/sketchy-1/16.png" alt="" className="mw-100 h-200px h-sm-325px" />
                        </div>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default ProfileNotifications;