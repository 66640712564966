import User from "../../modules/accounts/models/User"
import FlexRow from "../base/FlexRow"
import { Link } from "react-router-dom"
import AccountHeaderTechStatistics from "./AccountHeaderTechStatistics"
import { UserTypes } from "../../core/Enums"
import AccountHeaderAgentStatistics from "./AccountHeaderAgentStatistics"


type Props = {
   userId?: string
   model?: User
   selectedPage?: string
}

export default function AccountOverviewHeader(props: Props) {

    
    
    return (
        <div className="card">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex mb-3">
                    <div className="me-7 mb-4">
                        <div className="d-flex flex-column">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <img src={props.model?.avatarUrl ?? "/assets/media/avatars/blank.png"} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-gray-900 fs-2 fw-bolder mb-1">
                            {props.model?.firstName + " " + props.model?.lastName} 
                        </div>
                        
                        <FlexRow>
                            <FlexRow className="text-gray-900 fs-7 me-10" title="Username">
                                <span className="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                        <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path>
                                    </svg>
                                </span>
                                <span>
                                    {props.model?.username} 
                                </span>
                            </FlexRow>
                            <FlexRow className="text-gray-900 fs-7 me-5" title="User Type">
                                <span className="svg-icon svg-icon-4 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"></path>
                                        <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"></path>
                                    </svg>
                                </span>
                                <span>
                                    {props.model?.userTypeName} 
                                </span>
                            </FlexRow>
                            
                        </FlexRow>
                        
                        {
                            props.model?.userType == UserTypes.Technician && <AccountHeaderTechStatistics />
                        }
                        {
                            props.model?.userType == UserTypes.Agent && <AccountHeaderAgentStatistics />
                        }

                    </div>
                    <FlexRow justify="end" className="flex-grow-1">
                        
                    </FlexRow>
                </div>
            </div>
        </div>
    )
}