import FlexRow from "../../../../components/base/FlexRow";
import PaymentMethod from "../../../system/settings/models/PaymentMethod";


interface IProps {
    item: PaymentMethod
}
function PaymentCardView(props: IProps) {

    return (
        <div>
            <FlexRow>
                {
                    props.item.cardBrand == "visa" ?
                        <img src="/assets/media/svg/card-logos/visa.svg" alt="" className="me-4 h-50px" />
                        :
                        props.item.cardBrand == "mastercard" ?
                            <img src="/assets/media/svg/card-logos/mastercard.svg" alt="" className="me-4 h-50px" />
                            :
                            props.item.cardBrand == "amex" ?
                                <img src="/assets/media/svg/card-logos/american-express-dark.svg" alt="" className="me-4 h-50px" />
                                :
                                props.item.cardBrand == "discover" ?
                                    <img src="/assets/media/svg/card-logos/discover.png" alt="" className="me-4 h-50px" />
                                    :
                                    props.item.cardBrand == "diners" ?
                                        <img src="/assets/media/svg/card-logos/diners-club.png" alt="" className="me-4 h-50px" />
                                        :
                                        props.item.cardBrand == "jcb" ?
                                            <img src="/assets/media/svg/card-logos/jcb.png" alt="" className="me-4 h-50px" />
                                            :
                                            props.item.cardBrand == "unionpay" ?
                                                <img src="/assets/media/svg/card-logos/unionpay.svg" alt="" className="me-4 h-50px" />
                                                :
                                                <img src="/assets/media/svg/card-logos/unknown.png" alt="" className="me-4 h-50px" />
                }
                <div>
                    <div className="fs-4 fw-bolder">{props.item.cardBrand}&nbsp;****&nbsp;{props.item.cardLast4}</div>
                    <div className="fs-6 fw-bold text-gray-400">Exp: **/{props.item.cardExpYear}</div>
                </div>
            </FlexRow>
        </div>
    )
}

export default PaymentCardView;