import { useEffect, useState } from "react"
import { getUser, openCopyJob, openJobQuickView, setPage, toDateTime, toNormalDate, toPrettyDate, useHub } from "../../../../core/Global"
import Layout from "../../../../components/layouts/Layout"
import { DataTable, DataTableCustomActions, DataTableField } from "../../../../components/data/DataTable"
import { API_ROUTES } from "../../../../core/Constants"
import Section from "../../../../components/base/Section"
import Job from "../models/Job"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"
import FilteringProps from "../filtering/joblist-closed.json"
import SortingProps from "../sorting/jobs.json"
import { useNavigate } from "react-router-dom"
import Modal from "../../../../components/base/Modal"
import DatePicker from "../../../../components/form/DatePicker"
import { toastDefault, toastError, toastSuccess } from "../../../../components/base/Toast"
import DropDown from "../../../../components/form/DropDown"
import { convertTo12HourFormat, copyToClipboard } from "../../../../core/Helper"

export default function AllJobsList() {

    const navigate = useNavigate();

    const JobIdColumn = (data: Job) => (
        <div className="fs-7">
            <a href="#" onClick={e => {
                e.preventDefault();
                openJobQuickView(data.id)
            }}>
                #{data.jobId}
            </a>
        </div>
    )

    const ClientColumn = (data: Job) => (
        <div>
            <div className="fs-7">
                {data.fullName}
            </div>
            <FlexRow>
                <span>
                    <Icon icon="phone-rotary" type="solid" className="text-gray-600 me-1" />
                </span>
                <span className="fs-8 text-gray-600">
                    {data.phone?.phoneFriendly}
                </span>
            </FlexRow>
        </div>
    )

    const AddressColumn = (data: Job) => (
        <div className="fs-7">
            {data.address?.title}
        </div>
    )

    const StatusColumn = (data: Job) => (
        <div className="w-175px">

            <FlexRow className="mb-1">
                <Icon icon="circle" type="solid" color={data.jobStatus?.color} className="me-2" />
                <span>{data.jobStatus?.name}</span>
            </FlexRow>

            <FlexRow className="mb-1" title={"Your Timezone: " + getUser()?.timeZoneId}>
                <Icon icon="clock" type="solid" className="text-gray-600 me-1" size={10} />
                <div className="fs-8 text-gray-600" style={{ marginTop: 2 }}>
                    {toPrettyDate(data.lastStatusUpdatedAt)}
                </div>
            </FlexRow>

            {
                data.lastStatusUser &&
                <FlexRow className="text-gray-600">
                    <Icon icon="user" className="me-1" size={10} type="solid" />
                    <div className="fs-8" style={{ marginTop: 2 }}>
                        By: {data.lastStatusUser.fullName}
                    </div>
                </FlexRow>
            }
        </div>
    )

    const TechColumn = (data: Job) => (
        <div className="fs-7">
            {data.tech?.fullName}
        </div>
    )

    const AppointmentColumn = (data: Job) => (
        <div className="ms-3">
            <div className="fs-7">
                {data.appointmentDate}
            </div>
            <div className="fs-8 text-gray-600">
                {convertTo12HourFormat(data.appointmentTimeFrom, true) + "-" + convertTo12HourFormat(data.appointmentTimeTo)}
            </div>
        </div>
    )

    const CreatedColumn = (data: Job) => (
        <div className="w-175px">

            <FlexRow className="mb-1" title={"Job Timezone: " + data.timezone}>
                <div className="fs-7 me-1">
                    {toPrettyDate(data.createdAt, data.timezoneOffset)}
                </div>
                <div className="fs-9">
                    (Local Time)
                </div>
            </FlexRow>

            <FlexRow className="mb-1 text-gray-600" title={"Your Timezone: " + getUser()?.timeZoneId}>
                <div className="me-1 fs-8">
                    {toPrettyDate(data.createdAt)}
                </div>
                <div className="fs-9">
                    (Your Time)
                </div>
            </FlexRow>



            {
                data.creatorUser &&
                <FlexRow className="text-gray-600">
                    <Icon icon="user" className="fs-8 me-2" type="solid" />
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }
        </div>
    )



    const TagsField = (data: Job) => (
        <FlexRow>
            {
                data.flags?.map((item, key) =>
                    <div key={key}
                        className="badge badge-success me-2"
                        style={{
                            backgroundColor: item.color
                        }}>{item.name}</div>
                )
            }
            {
                data.tags?.map((item, key) =>
                    <div key={key} className="badge badge-secondary me-2">{item.name}</div>
                )
            }
        </FlexRow>
    )

    const CopyJob = (data: Job) => (
        <div className="menu-item">
            <a href="#" className="menu-link text-gray-700 px-3" onClick={async e => {
                e.preventDefault()
                var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + data.id)
                var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                if (!copy) openCopyJob(data);
            }}>
                <Icon icon="copy" className="me-2" />
                Copy Job
            </a>
        </div>
    )

    const UpdateCloseDateAction = (data: Job) => (
        <div className="menu-item">
            <a href="#" className="menu-link px-3" onClick={e => {
                e.preventDefault()
                selectedJobSet(data)
                updateCloseDateModalSet(true)
            }}>
                <Icon icon="calendar-edit" className="me-2" />
                Update Close Date
            </a>
        </div>
    )

    useEffect(() => {
        setPage("All Jobs", "CRM", "Jobs")
    }, [])


    const hub = useHub()
    const [refresh, refreshSet] = useState<boolean>()
    const [selectedJob, selectedJobSet] = useState<Job>()
    const [updateCloseDateModal, updateCloseDateModalSet] = useState<boolean>(false)

    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                refreshSet(false);
            }, 1000);
        }
    }, [refresh])

    const [timezonesSource, timezonesSourceSet] = useState<any[]>([])
    useEffect(() => {
        hub.Get("/reports/jobs/timezones", res => timezonesSourceSet(res.data))
    }, [])

    return (
        <Layout wide>
            <Section fluid>

                <DataTable
                    objectName="Job"
                    preventCreate
                    filteringProps={FilteringProps}
                    sortingProps={SortingProps}
                    refresh={refresh}
                    apiRoute={API_ROUTES.JobsAll}>

                    <DataTableField title="Job ID" width={70}>
                        <JobIdColumn />
                    </DataTableField>

                    <DataTableField title="Client" width={150}>
                        <ClientColumn />
                    </DataTableField>

                    <DataTableField title="Address" maxWidth={200}>
                        <AddressColumn />
                    </DataTableField>

                    <DataTableField title="Status">
                        <StatusColumn />
                    </DataTableField>

                    <DataTableField title="Job Type" dataValueName="jobType" className="fs-7" />
                    <DataTableField title="Job Source" dataValueName="jobSource" className="fs-7" />

                    <DataTableField title="Tech" maxWidth={150}>
                        <TechColumn />
                    </DataTableField>

                    <DataTableField title="Appointment" maxWidth={150}>
                        <AppointmentColumn />
                    </DataTableField>

                    <DataTableField title="Created" maxWidth={175}>
                        <CreatedColumn />
                    </DataTableField>


                    <DataTableCustomActions>
                        <CopyJob />
                    </DataTableCustomActions>


                </DataTable>

            </Section>

            {
                updateCloseDateModal && selectedJob &&
                <Modal id="modal-update-closingdate"
                    title="Update Close Date"
                    subtitle={"Job ID: #" + selectedJob?.jobId + " | " + selectedJob?.fullName}
                    open visible staticBackdrop
                    buttonText="Update Close Date"
                    onClose={() => {
                        updateCloseDateModalSet(false)
                        selectedJobSet(undefined)
                    }}
                    onSubmit={(body, callback) => {

                        if (window.isNullOrEmpty(body.closedate)) {
                            window.formError("closedate", "Please choose a date and time!");
                            return;
                        }

                        hub.Post(API_ROUTES.Jobs + "closingdate", {
                            ...body,
                            id: selectedJob.id
                        }, res => {
                            if (res.success) {
                                refreshSet(true)
                                toastSuccess("Close date has been updated successfully!")
                            }
                            callback!(res)
                        })


                    }}
                >
                    <div className="mb-5">
                        <DatePicker id="dt-update-closedate" name="closedate" timePicker block marginBottom={5}
                            label="Close Date" labelUp selectedDate={toDateTime(selectedJob.lastStatusUpdatedAt, selectedJob.timezoneOffset)} />

                        <DropDown name="timezone" block labelUp
                            label="Timezone" autoSelectFirstItem
                            source={[
                                {
                                    id: selectedJob.timezone,
                                    name: `Local Time (${selectedJob.timezone})`
                                },
                                {
                                    id: getUser()?.timeZoneId,
                                    name: `Your Time (${getUser()?.timeZoneId})`
                                }
                            ]} />

                    </div>
                </Modal>
            }

        </Layout>
    )
}