import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import DropDown from "../../../../components/form/DropDown";
import TextBox from "../../../../components/form/TextBox";
import { useHub } from "../../../../core/Global";
import PhoneLine from "../models/PhoneLine";
import DataItem from "../../../../models/DataItem";
import { API_ROUTES, JobCallbackTypes, RedirectTypes } from "../../../../core/Constants";
import PhoneInput from "../../../../components/form/PhoneInput";



interface IProps {
    item: PhoneLine
    onClose: () => void
    onSave: () => void
}

export default function LineSettingsModal(props: IProps) {

    const hub = useHub()


    const [inboundItems] = useState<DataItem[]>([
        {
            id: "0",
            name: "None"
        },
        {
            id: "1",
            name: "Extensions"
        },
        {
            id: "2",
            name: "Queues"
        },
        {
            id: "3",
            name: "Redirect"
        }
    ])

    const [redirectItems] = useState<DataItem[]>(RedirectTypes)
    const [inboundDest, inboundDestSet] = useState<string>();
    const [inboundRedirectType, inboundRedirectTypeSet] = useState<string>();


    useEffect(() => {
        inboundRedirectTypeSet(props.item.redirectType?.toString());
        inboundDestSet(props.item.inboundDest?.toString())
    }, [props.item])

    return (
        <Modal id="modal-inbound-settings"
            title={`Line Settings`}
            buttonText="Update Settings"
            open visible staticBackdrop
            onClose={props.onClose}
            onSubmit={(body, callback) => {
                //console.log(body);

                if (!body.inboundDest || body.inboundDest == "") {
                    window.formError("inboundDest", "Please choose a destination!", "modal-inbound-settings");
                    if (callback) callback();
                    return;
                }

                if ((body.inboundDest == "1") && (!body.inboundExtension || body.inboundExtension == "")) {
                    window.formError("inboundExtension", "Please choose an extension!", "modal-inbound-settings");
                    if (callback) callback();
                    return;
                }

                if ((body.inboundDest == "2") && (!body.inboundQueue || body.inboundQueue == "")) {
                    window.formError("inboundQueue", "Please choose a queue!", "modal-inbound-settings");
                    if (callback) callback();
                    return;
                }

                if ((body.inboundDest == "3") && (!body.inboundRedirect || body.inboundRedirect == "")) {
                    window.formError("inboundRedirect", "Please choose a redirect type!", "modal-inbound-settings");
                    if (callback) callback();
                    return;
                }

                if (body.inboundDest == "3" && body.inboundRedirect == "2" && (!body.redirectToExternalNumber || body.redirectToExternalNumber.length < 8)) {
                    window.formError("redirectToExternalNumber", "Please write a valid phone number!", "modal-inbound-settings");
                    if (callback) callback();
                    return;
                }

                hub.Put("/voice/lines/inbound/" + props.item.id, body, res => {
                    if (res.success) {
                        props.onSave()
                    }

                    if (callback) callback(res);
                })

            }}>

            <TextBox label="Phone Line" defaultValue={props.item.phoneNumber} readOnly labelUp block />

            <DropDown label="Set Destination" id="drp-inbound-destination" name="inboundDest" parentModal="modal-inbound-settings"
                defaultValue={props.item.inboundDest?.toString()} source={inboundItems} labelUp block
                onSelect={val => inboundDestSet(val)}
                onClear={() => inboundDestSet("0")} />

            {
                inboundDest == "1" &&
                <DropDown label="Extension" id="drp-inbound-extensions" name="inboundExtension" dataText="title" dataValue="value"
                    defaultValue={props.item.extensionId} parentModal="modal-inbound-settings" labelUp block
                    apiSource={"/base/tagify/extensions/?userId=" + props.item.ownerUserId}>
                </DropDown>
            }

            {
                inboundDest == "2" &&
                <DropDown label="Queue" id="drp-inbound-queues" name="inboundQueue" dataText="title" dataValue="value"
                    defaultValue={props.item.queueId} parentModal="modal-inbound-settings" labelUp block
                    apiSource={"/base/tagify/queues/?userId=" + props.item.ownerUserId}>
                </DropDown>
            }

            {
                inboundDest == "3" &&
                <DropDown label="Redirect To" id="drp-inbound-redirect" key="drp-inbound-redirect" name="inboundRedirect"
                    parentModal="modal-inbound-settings" labelUp block source={redirectItems}
                    defaultValue={props.item.redirectType?.toString()}
                    onSelect={e => inboundRedirectTypeSet(e)}
                    onClear={() => inboundRedirectTypeSet(undefined)}>
                </DropDown>
            }

            {
                inboundDest == "3" && inboundRedirectType == "1" &&
                <>
                    <DropDown label="Callback Priority" id="drp-inbound-jobcallback" key="drp-inbound-jobcallback" name="jobRedirectCallBackPriority"
                        parentModal="modal-inbound-settings" labelUp block source={JobCallbackTypes}
                        defaultValue={props.item.jobRedirectCallbackPriority?.toString() ?? "1"}
                        deselect={false} />

                    <PhoneInput id="phone-jobcallback-to" key="phone-jobcallback-to" name="jobCallbackExternalPhoneNumber"
                        defaultValue={props.item.jobCallbackPhoneNumber}
                        countryCodes="us-only" label="Operator Number" labelUp block />

                </>
            }

            {
                inboundDest == "3" && inboundRedirectType == "2" &&
                <PhoneInput id="phone-redirect-to" key="phone-redirect-to" name="redirectToExternalNumber"
                    defaultValue={props.item.redirectToExternalNumber}
                    countryCodes="us-only" label="Redirect to" labelUp block />
            }

        </Modal>
    )
}