import React, { useEffect } from "react"
import { useEnvironment } from "../../../core/Global";

function AuthLayout(props: {children: any}) {

    const env = useEnvironment();
	
    useEffect(()=> {

    }, [])

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" 
        style={{backgroundImage: "url(/assets/media/illustrations/sketchy-1/14.png"}}>

        <div className="text-center mt-20 mb-5">
            <a>
				<img alt={env.siteTitle} src={`/assets/brands/${env.brand}/icon.png`} style={{
                    width: 100,
                    maxWidth: "60%"
                }} />
			</a>
        </div>

        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            {props.children}                    
        </div>

        <div className="d-flex flex-center flex-column-auto p-10">
            {/* <div className="d-flex align-items-center fw-bold fs-6">
                <a href="https://keenthemes.com" className="text-muted text-hover-primary px-2">About</a>
                <a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
                <a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
            </div> */}
        </div>

    </div>
  );
}

export default AuthLayout;
