import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import { DataTable, DataTableCustomActions, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES } from '../../../../core/Constants';
import { hasRole, setPage, useGlobal, useHub } from '../../../../core/Global';
import Icon from "../../../../components/base/Icon";
import Modal from "../../../../components/base/Modal";
import Section from "../../../../components/base/Section";
import FlexRow from "../../../../components/base/FlexRow";
import PhoneLine from "../models/PhoneLine";

function LinesReleased() {

    const hub = useHub()
    const [selectendLine, selectendLineSet] = useState<PhoneLine>();
    const [refresh, refreshSet] = useState<boolean>();
  
    const LineCompany = (data: PhoneLine) => (
        <div>
            <div>
                {data.company?.name}
            </div>
            <div className="d-flex align-items-center mt-1">
                <Icon icon="user" className="me-1" type="solid" size={11}/>
                <div className="fs-8">
                    {data.ownerUser?.fullName}
                </div>
            </div>
        </div>
      )

    const ReleaseNumberAction = (data: any) => (
        <>
         {
          hasRole("RestoreNumber") && 
          <div className="menu-item">
              <a href="#" className="menu-link px-3"
                  onClick={e=> {
                      e.preventDefault();
                      selectendLineSet(data);
                      window.openModal("modal-restore-number");
                  }}>
                  <Icon icon="redo" className="me-2"/>
                  Restore Number
              </a>
          </div> 
         }
        </>
    )

  const ViewNumberLogs = (data: any) => (
    <div className="menu-item">
        <a className="menu-link px-3">
            <Icon icon="file-lines" className="me-2"/>
            View Logs
        </a>
    </div> 
  )

  const CreatedItem = (data: PhoneLine) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.purchasedDate}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }
      
    </div>
  )

  const ReleasedItem = (data: PhoneLine) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.releasedDate}
      </div>
      {
        data.releasedByUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.releasedByUser.fullName}
          </div>
        </FlexRow>
      }
      
    </div>
  )
	
  useEffect(()=> {
    setPage("Released Numbers", "Voice", "Lines");
  }, [])

  



  return (
    <Layout wide>

        <Section fluid>
            <DataTable objectName="Line"
                        route="/voice/lines"
                        addRoute="/voice/lines/search"
                        apiRoute={API_ROUTES.Lines}
                        refresh={refresh} preventCreate
                        extraParams={[{
                            name: "released",
                            value: true
                        }]}
                        ascending>
                <DataTableField title="Phone Number" dataValueName="friendlyName"/>
                <DataTableField title="Company">
                    <LineCompany />
                </DataTableField>
                <DataTableField title="Purchased">
                    <CreatedItem />
                </DataTableField>
                <DataTableField title="Released">
                    <ReleasedItem />
                </DataTableField>
                <DataTableCustomActions>
                    <ReleaseNumberAction />
                </DataTableCustomActions>
                <DataTableCustomActions>
                    <ViewNumberLogs />
                </DataTableCustomActions>
            </DataTable>
        </Section>

        <Modal id="modal-restore-number"
            title="Restore Number"
            buttonText="Restore Number"
            buttonColor="success"
            onSubmit={(body, callback) => {
                hub.Put("/voice/lines/restore/" + selectendLine?.id, body, res => {
                    if (res.data) {
                        refreshSet(true);
                        if(callback) callback(res);
                        setTimeout(() => refreshSet(false), 500);
                    }
                    else{
                        if(callback) callback();
                    }
                });
            }}>
                <p>
                    Are you sure you want to restore this number?
                </p>
                <div className="bg-light-primary p-4">
                    <div className="d-flex align-items-center">
                        <Icon icon="phone" className="me-2" type="solid" />
                        {selectendLine?.friendlyName}
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Icon icon="user" className="me-2" type="solid" />
                        {selectendLine?.ownerUser?.fullName}
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Icon icon="briefcase" className="me-2" type="solid" />
                        {selectendLine?.company?.name}
                    </div>
                </div>
        </Modal>

    </Layout>
  );
}

export default LinesReleased;
