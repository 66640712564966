import React from 'react';
import { Routes } from "react-router-dom";
import BaseRoutes from './modules/base/routes/BaseRoutes';
import SystemRoutes from "./routes/SystemRoutes";
import AccountsRoutes from "./modules/accounts/routes/AccountsRoutes";
import AgentsRoutes from './modules/agents/routes/AgentsRoutes';
import ProfileRoutes from './modules/profile/routes/ProfileRoutes';
import DashboardRoutes from './modules/dashboards/routes/DashboardRoutes';
import VoiceRoutes from './routes/VoiceRoutes';
import CRMRoutes from './routes/CRMRoutes';
import ChatRoutes from './modules/chat/routes/ChatRoutes';
function Switch() {
  return (
    <Routes>

      {VoiceRoutes()}
      {CRMRoutes()}

      {BaseRoutes()}

      {SystemRoutes()}

      {AccountsRoutes()}
      {AgentsRoutes()}
      {ProfileRoutes()}
      {DashboardRoutes()}
      {ChatRoutes()}

    </Routes>
  );
}

export default Switch;
