import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/jobtypegroups.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import ColorPicker from "../../../../components/form/ColorPicker";
import TextBox from "../../../../components/form/TextBox";
import Section from "../../../../components/base/Section";
import JobStatusCreateModal from "../components/JobStatusCreateModal";
import JobStatusEditModal from "../components/JobStatusEditModal";
import JobStatus from "../models/JobStatus";
import DropDown from "../../../../components/form/DropDown";
import TextArea from "../../../../components/form/TextArea";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";

function JobStatuses() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);
  const [deleteStatuses, deleteStatusesSet] = useState<JobStatus[]>();
  const [selectedDeleteStatus, selectedDeleteStatusSet] = useState<JobStatus>();

  const [selectedItem, selectedItemSet] = useState<JobStatus>();
  const [refresh, refreshSet] = useState<boolean>();


  useEffect(() => {
    if (deleteModal && selectedItem) {
      hub.Get(API_ROUTES.JobStatuses + "all/?exclude=closed&excludeId=" + selectedItem.id, res => {
        if (res.success) deleteStatusesSet(res.data);
      })
    }
  }, [deleteModal, selectedItem])

  const edit = (data: any) => {
    selectedItemSet(data);
    editModalSet(true);
  }

  const deleteGroup = (data: any) => {
    selectedItemSet(data);
    deleteModalSet(true);
    selectedDeleteStatusSet(undefined);
  }


  const NameItem = (data: any) => (
    <FlexRow>
      <ColorPicker id={"color-picker-" + data.id} color={data.color} className="me-2"
        onSelect={color => {
          hub.Post(API_ROUTES.JobStatuses + "color", {
            id: data.id,
            color: color
          }, res => {

          })
        }} />
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const JobsCount = (data: JobStatus) => (
    <div>
      {
        (data.jobsCount ?? 0) > 0 ?
          <a href={(data.isClosed || data.isCanceled) ? "/crm/jobs/archived?statuses=" + data.id : "/crm/jobs?statuses=" + data.id} target="_blank">
            {data.jobsCount}
          </a>
          :
          <div>
            0
          </div>
      }

    </div>
  )

  //
  const NotesReqItem = (data: any) => (
    <FlexRow>
      <Icon icon="circle-exclamation" type="solid" size={16} className={data.noteRequired ? "text-warning" : "text-secondary"} />
      <span className={"ms-1 fs-7 " + (data.noteRequired ? "" : "text-muted")}>
        {data.noteRequired ? "Required" : "Not Required"}
      </span>
    </FlexRow>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Status"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: JobStatus) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser ?
          <FlexRow className='fs-8 text-gray-600 mt-1'>
            <div>
              <Icon icon="user" className="me-2" size={10} type="solid" />
            </div>
            <div className="fs-8">
              By: {data.creatorUser.fullName}
            </div>
          </FlexRow>
          :
          <FlexRow className='fs-8 text-gray-600 mt-1'>
            <div>
              <Icon icon="cog" className="me-2" size={10} type="solid" />
            </div>
            <div className="fs-8">
              System Status
            </div>
          </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("Job Statuses", "CRM", "Base Data");

  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }

  }, [refresh])

  return (
    <Layout wide>

      <Section fluid>
        <DataTable
          objectName="Job Status"
          apiRoute={API_ROUTES.JobStatuses}
          ascending
          filteringProps={filteringProps}
          edit editCustom={edit} addCustom={() => createModalSet(true)}
          delete={hasRole("DeleteJobStatuses")} deleteCustom={deleteGroup}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <DataTableActiveToggle route={API_ROUTES.JobStatuses} />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Note*">
            <NotesReqItem />
          </DataTableField>

          <DataTableField title="Total Jobs">
            <JobsCount />
          </DataTableField>

          <DataTableField title="" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>

        </DataTable>
      </Section>


      {
        createModal &&
        <JobStatusCreateModal
          onClose={() => createModalSet(false)}
          onCreated={() => refreshSet(true)} />
      }

      {
        editModal && selectedItem &&
        <JobStatusEditModal status={selectedItem}
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onUpdate={() => refreshSet(true)} />
      }


      {
        deleteModal &&
        <Modal id="modal-delete-jobstatus"
          title="Delete Job Status"
          buttonText="Delete Status"
          open visible
          buttonColor="danger"
          onClose={() => {
            deleteModalSet(false)
            selectedItemSet(undefined)
          }}
          onSubmit={(body, callback) => {

            if (selectedItem?.jobsCount && selectedItem.jobsCount > 0) {
              if (window.isNullOrEmpty(body.newStatusId)) {
                window.formError("newStatusId", "Select the status!", "modal-delete-jobstatus", true);
                callback!();
                return;
              }

              if (selectedDeleteStatus?.noteRequired && window.isNullOrEmpty(body.note)) {
                window.formError("note", "Please write the status note!", "modal-delete-jobstatus", true);
                callback!();
                return;
              }
            }

            hub.Post(API_ROUTES.JobStatuses + "delete/" + selectedItem?.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Job Status has been deleted successfully!");
              }
              callback!(res);
            })

          }}>
          <div>
            {
              selectedItem?.jobsCount && selectedItem.jobsCount > 0 ?
                <div>
                  <div className="fw-bolder mb-3">
                    There {selectedItem.jobsCount > 1 ? "are" : "is"} {selectedItem.jobsCount} Job{selectedItem.jobsCount > 1 ? "s" : ""} assigned to this status!
                  </div>
                  <DropDown id="drp-delete-status-newstatus" name="newStatusId"
                    label="Select a new status to assign: " labelUp block parentModal="modal-delete-jobstatus"
                    source={deleteStatuses} onSelectItem={st => selectedDeleteStatusSet(st)} />

                  {
                    selectedDeleteStatus?.noteRequired &&
                    <TextArea label="Note*" labelUp block rows={5} name="note"
                      placeholder="Selected status requires note! it will be added for all jobs in the deleted status!" />
                  }
                </div>
                :
                <div>
                  Are you sure you want to delete this job status?
                </div>
            }
          </div>
        </Modal>
      }



    </Layout>
  );
}

export default JobStatuses;
