import React from 'react';
import {Routes, Route} from "react-router-dom";
import CountryList from '../views/CountryList';


function BaseRoutes() {
  return (
    [
      <Route key={0} path="/base/countries" element={<CountryList />}/>
    ]
  );
}

export default BaseRoutes;
