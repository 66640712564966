import { API_ROUTES } from "../../core/Constants";
import { hideDefaultTechRateModal, useHub, usePreferences } from "../../core/Global";
import Modal from "../base/Modal";
import TextBox from "../form/TextBox";



export default function ModalDefaultTechsRate() {

    const hub = useHub();
    const pref = usePreferences();

    return (
        <Modal id="modal-defaulttechrate"
            title="Default Technicians Rate"
            visible open staticBackdrop
            buttonColor="primary"
            buttonText="Update Default Rate"
            onOpen={() => {
                $("#txt-defaulttechrate").focus();
            }}
            onClose={() => {
                hideDefaultTechRateModal();
            }}
            onSubmit={(body, callback) => {
                
                hub.Post(API_ROUTES.Rates + "defaultrate/techs", body, res => {
                    if (res.success) {
                        window.location.reload();
                    }
                    setTimeout(() => {
                        callback!(res)
                    }, 1000);

                })
            }}>
                <div>
                    <TextBox id="txt-defaulttechrate" name="rate" label="Default Technicians Rate" 
                        labelUp block value={pref?.defaultTechsRate.toString()} icon="percent" />
                </div>
        </Modal>
    )
}