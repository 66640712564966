import "../styles/chat.css";
import { useEffect, useState } from "react";
import Section from "../../../components/base/Section";
import Layout from "../../../components/layouts/Layout";
import { hasRole, selectChatById, setPage, setToolbarActions, useGlobal, useScreen, useSelectedChat } from "../../../core/Global";
import ChatContactsBox from "../components/ChatContactsBox";
import ChatBox from "../components/ChatBox";
import ChatContactItem from "../models/ChatContactItem";
import ChatUnselectedView from "../components/ChatUnselectedView";
import { useNavigate, useParams } from "react-router-dom";
import ChatTechBox from "../components/ChatTechBox";
import Modal from "../../../components/base/Modal";
import ChatMessage from "../models/ChatMessage";
import { CompactJob, CompactUser } from "../../../core/CompactModels";
import ChatMessageItem from "../components/ChatMessageItem";
import AssignMessageToJob from "../components/AssignMessageToJob";
import Icon from "../../../components/base/Icon";
import NewChatModal from "../components/NewChatModal";


export default function ChatMainView() {

    const [globalState] = useGlobal();
    const screen = useScreen();
    const { selectedId } = useParams();
    const navigate = useNavigate();

    const selectedChat = useSelectedChat();

    const [newChat, newChatSet] = useState<boolean>(false);

    const [refreshContacts, refreshContactsSet] = useState<boolean>(false)
    const [resetContacts, resetContactsSet] = useState<boolean>(false)
    const [readedChatId, readedChatIdSet] = useState<string>();
    const [searchingChat, searchingChatSet] = useState<ChatContactItem>();
    const [filteringParticipant, filteringParticipantSet] = useState<CompactUser>();
    const [filteringJob, filteringJobSet] = useState<CompactJob>();

    const [assignToJob, assignToJobSet] = useState<boolean>(false);
    const [assignToJobMessage, assignToJobMessageSet] = useState<ChatMessage>();



    useEffect(() => {
        if (readedChatId) {
            setTimeout(() => {
                readedChatIdSet(undefined);
            }, 1000);
        }
    }, [readedChatId])

    useEffect(() => {
        if (refreshContacts) {
            setTimeout(() => {
                refreshContactsSet(false);
            }, 1000);
        }
    }, [refreshContacts])

    useEffect(() => {
        if (resetContacts) {
            setTimeout(() => {
                resetContactsSet(false);
            }, 1000);
        }
    }, [resetContacts])

    useEffect(() => {
        setPage("Chat")
        if (hasRole("ChatStart")) {
            setToolbarActions(ActionButtons)
        }
    }, [])

    useEffect(() => {

    }, [globalState.roles, globalState.user])

    const ActionButtons = () => (
        <>
            <button className="btn btn-primary btn-sm" onClick={e => {
                newChatSet(true)
            }}>
                <Icon icon="edit" />
                Start New Chat
            </button>
        </>
    )


    useEffect(() => {
        if (selectedChat && selectedChat.chatId != selectedId) {
            navigate(`/chat/${selectedChat.chatId}`)
        }
    }, [selectedChat?.chatId])

    useEffect(() => {
        if (selectedChat && selectedId == selectedChat.chatId) return;
        if (selectedId && globalState.chatlist) selectChatById(selectedId);
    }, [selectedId, globalState.chatlist])


    return (
        <Layout wide>
            <Section fluid>
                <div className="d-flex flex-column flex-lg-row">

                    <div className="flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 mb-lg-0">
                        <ChatContactsBox
                            reset={resetContacts}
                            readedChatId={readedChatId}
                            searchingChat={searchingChat}
                            filteringParticipant={filteringParticipant}
                            filteringJob={filteringJob}
                            onNewChat={() => newChatSet(true)}
                            onSearchingChatCleared={() => {
                                searchingChatSet(undefined);
                                filteringParticipantSet(undefined);
                                filteringJobSet(undefined);
                            }} />
                    </div>

                    <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                        {
                            selectedChat ?
                                <div className="row">
                                    <div className="col-md-12 col-xxl-8">
                                        <ChatBox key={selectedChat.chatId} chat={selectedChat} chatId={selectedChat.chatId}
                                            onRead={() => readedChatIdSet(selectedChat.chatId)}
                                            onPin={() => refreshContactsSet(true)}
                                            onUnpin={() => refreshContactsSet(true)}
                                            onSearch={chat => searchingChatSet(chat)}
                                            onMessageSending={msg => resetContactsSet(true)}
                                            onAssignMessageToJob={msg => {
                                                assignToJobSet(true);
                                                assignToJobMessageSet(msg);
                                            }}
                                            onFilterByParticipant={p => {
                                                searchingChatSet(selectedChat);
                                                filteringParticipantSet(p);
                                            }}
                                            onFilterByJob={j => {
                                                searchingChatSet(selectedChat);
                                                filteringJobSet(j);
                                            }} />
                                    </div>
                                    {
                                        screen.width > 1440 &&
                                        <div className="col-xxl-4">
                                            <ChatTechBox chat={selectedChat} />
                                        </div>
                                    }

                                </div>
                                :
                                <ChatUnselectedView />
                        }
                    </div>

                </div>
            </Section>

            <Modal id="modal-pinned-chats-exceeed"
                title="Alert"
                buttonText="Okay"
                buttonColor="secondary"
                onConfirm={callback => callback()}
                preventDiscard>
                <p>
                    You can't pin more than 5 chats!
                </p>
            </Modal>


            {
                assignToJob &&
                <AssignMessageToJob message={assignToJobMessage}
                    onClose={() => {
                        assignToJobSet(false);
                        assignToJobMessageSet(undefined);
                    }} />
            }

            {
                newChat &&
                <NewChatModal
                    onClose={() => {
                        newChatSet(false)
                    }} />
            }


        </Layout>
    )
}