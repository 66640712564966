import React from 'react';
import {Routes, Route} from "react-router-dom";
import Profile from '../views/Profile';
import ProfileSecurity from '../views/ProfileSecurity';


function ProfileRoutes() {
  return (
    [
      <Route key={0} path="/profile" element={<Profile />} />,
      <Route key={1} path="/profile/:selectedPage" element={<Profile />} />,
      <Route key={2} path="/profile/:selectedPage/:selectedAction" element={<Profile />} />
    ]
  );
}

export default ProfileRoutes;
