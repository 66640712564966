import { useEffect, useRef, useState } from "react";
import Icon from "../../../components/base/Icon";
import { useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import Tagify from "../../../components/tagify/Tagify";
import DatePicker from "../../../components/form/DatePicker";
import { CancelTokenSource } from "axios";
import ChatContactItem from "../models/ChatContactItem";



interface IProps {
    onSearch: (data?: ChatContactItem[]) => void
    onShowArchived: (show: boolean) => void
}
export default function ChatGeneralSearch(props: IProps) {

    let searchCancelToken: CancelTokenSource | null
    const hub = useHub()
    const searchContactInput = useRef<HTMLInputElement>(null)

    const [working, workingSet] = useState<boolean>(false)

    const [term, termSet] = useState<string>("")
    const [keyword, keywordSet] = useState<string>("")

    useEffect(() => {
        if (term.length == 0) {
            keywordSet("");
            workingSet(false);
            return;
        }
        workingSet(true)
        const tm = setTimeout(() => {
            keywordSet(term);
        }, 700);
        return () => clearTimeout(tm)
    }, [term])

    const [techGroups, techGroupsSet] = useState<string[]>([])
    const [techGroupAdd, techGroupAddSet] = useState<string>()
    const [techGroupRemove, techGroupRemoveSet] = useState<string>()

    useEffect(() => {
        if (techGroupAdd) {
            techGroupsSet(t => {
                if (t.includes(techGroupAdd)) return t;
                return [...t, techGroupAdd];
            })
        }
    }, [techGroupAdd])

    useEffect(() => {
        if (techGroupRemove) techGroupsSet(t => t.filter(f => f != techGroupRemove))
    }, [techGroupRemove])


    const [jobs, jobsSet] = useState<string[]>([])
    const [jobAdd, jobAddSet] = useState<string>()
    const [jobRemove, jobRemoveSet] = useState<string>()

    useEffect(() => {
        if (jobAdd) {
            jobsSet(j => {
                if (j.includes(jobAdd)) return j;
                return [...j, jobAdd];
            })
        }
    }, [jobAdd])

    useEffect(() => {
        if (jobRemove) jobsSet(j => j.filter(f => f != jobRemove))
    }, [jobRemove])

    const [dates, datesSet] = useState<any>()


    const [advancedSearch, advancedSearchSet] = useState<boolean>(false)
    const [showArchived, showArchivedSet] = useState<boolean>(false)



    const search = () => {

        if (searchCancelToken) {
            searchCancelToken.cancel();
        }

        var filtered = false;
        var q = "?";
        if (keyword.length > 0) {
            q += "keyword=" + encodeURIComponent(keyword);
            filtered = true;
        }

        techGroups.forEach(p => {
            if (q != "?") q += "&";
            q += "techgroups=" + p;
            filtered = true;
        });

        jobs?.forEach(j => {
            if (q != "?") q += "&";
            q += "jobs=" + j;
            filtered = true;
        });

        if (dates) {
            if (q != "?") q += "&";
            q += "startDate=" + dates.date.format("DD/MM/YYYY HH:mm");
            q += "&endDate=" + dates.endDate.format("DD/MM/YYYY HH:mm");
            filtered = true;
        }

        if (!filtered) {
            props.onSearch(undefined);
            workingSet(false);
            return;
        }

        workingSet(true);

        hub.Get(API_ROUTES.ChatContacts + q, res => {
            if (res.success) {
                props.onSearch(res.data);
                workingSet(false);
            }
        }, undefined, ct => searchCancelToken = ct)

    }

    useEffect(search, [keyword])

    return (
        <>
            <div className="w-100 position-relative">
                <div className="d-flex align-items-center position-absolute" style={{ top: 15, right: 10 }}>
                    {
                        working ?
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y me-2">
                                <Icon icon="spinner-third" spin className="text-muted" size={18} />
                            </span>
                            : keyword.length > 0 ?
                                <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y me-2 cursor-pointer"
                                    onClick={e => {
                                        termSet("");
                                        searchContactInput.current?.focus();
                                    }}>
                                    <Icon icon="times" className="text-muted" size={18} />
                                </span>
                                :
                                <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y me-1">
                                    <Icon icon="search" className="text-muted" size={18} />
                                </span>
                    }
                    {/* <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y cursor-pointer"
                        onClick={e => {
                            if (advancedSearch) advancedSearchSet(false)
                            else advancedSearchSet(true)
                        }}>
                        <Icon icon="gear" className="text-muted" size={18} type={advancedSearch ? "solid" : "regular"} />
                    </span> */}
                    <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y cursor-pointer"
                        title="View Archived Chats!"
                        onClick={e => {
                            var s = true;
                            if (showArchived) s = false;
                            showArchivedSet(s);
                            props.onShowArchived(s);
                        }}>
                        <Icon icon="box-archive" className={showArchived ? "text-primary" : "text-muted"} size={18} type={showArchived ? "solid" : "regular"} />
                    </span>
                </div>
                <input type="text" value={term} onChange={e => termSet(e.target.value)}
                    ref={searchContactInput} className="form-control form-control-sm px-2 chat-searchbox"
                    placeholder="Search in chats..." />
            </div>

            {
                advancedSearch &&
                <div id="contacts-searching-advanced" className="px-3 pb-2">
                    <Tagify id="tagify-contactfilter-techgroups" placeholder="Select Group(s)" block marginBottom={2}
                        label="Technician Groups" labelUp closeOnSelect
                        apiUrl={API_ROUTES.TagifyTechGroups}
                        onSelect={val => techGroupAddSet(val)}
                        onRemove={val => techGroupRemoveSet(val)} />

                    <Tagify id="tagify-contactfilter-jobs" placeholder="Select Job(s)" block marginBottom={2}
                        label="Jobs" labelUp closeOnSelect
                        apiUrl={API_ROUTES.TagifyJobs} //defaultId={props.filteringJob?.id}
                        onSelect={val => jobAddSet(val)}
                        onRemove={val => jobRemoveSet(val)} />

                    <DatePicker id="dt-contactfilter-dates" label="Date" labelUp block marginBottom={0}
                        dateType="range" timePicker maxDate={window.now()}
                        onPick={d => datesSet(d)}
                        onClear={() => datesSet(undefined)} />
                </div>
            }
        </>
    )
}