import { useNavigate } from "react-router-dom";
import Modal from "../../../components/base/Modal";
import { API_ROUTES } from "../../../core/Constants";
import { closeDeleteChat, useHub } from "../../../core/Global";
import ChatContactItem from "../models/ChatContactItem";


interface IProps {
    chat: ChatContactItem
}

export default function ChatDeleteConfirmModal(props: IProps) {

    const hub = useHub()
    const navigate = useNavigate()

    return (
        <Modal id="modal-delete-chat"
            title="Delete Chat"
            open visible
            buttonColor="danger"
            buttonText="Delete Chat Permanently"
            onClose={closeDeleteChat}
            onConfirm={callback => {
                hub.Delete(API_ROUTES.Chat + "delete/" + props.chat.id, res => {
                    if (res.success) {
                        callback()
                        navigate("/chat")
                    }
                    else callback(true)
                })
            }}
            secondActionText="Archive Instead"
            secondActionColor="secondary"
            onSecondConfirm={callback => {
                hub.Put(API_ROUTES.Chat + "archive/" + props.chat.id, {}, res => {
                    if (res.success) callback()
                    else callback(true)
                })
            }}
        >

            <div>
                <p>Are you sure you want to archive the conversation?</p>

                <div className="bg-light-danger p-5">
                    {props.chat.title}
                </div>
            </div>

        </Modal>
    )
}