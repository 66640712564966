import React, { useEffect, useState } from "react";
import FilteringProps from "../filtering/users.json";
import AgentsFilteringProps from "../filtering/agents.json";
import TechsFilteringProps from "../filtering/techs.json";
import SortingProps from "../sorting/users.json";
import TechSortingProps from "../sorting/techs.json";
import Layout from '../../../components/layouts/Layout';
import { DataTable, DataTableCustomActions, DataTableField, DataTableUserField } from '../../../components/data/DataTable';
import { API_ROUTES, ROLES } from '../../../core/Constants';
import { UserValidator } from '../validators/UserValidator';
import { setPage, useGlobal, hasRole, createUserWithType, useHub, validateRole, toPrettyDate, getUser, updateUsersOn } from '../../../core/Global';
import Icon from "../../../components/base/Icon";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../components/base/Modal";
import TextBox from "../../../components/form/TextBox";
import Hub from "../../../api/Hub";
import { toastSuccess } from "../../../components/base/Toast";
import { UserTypes } from "../../../core/Enums";
import Section from "../../../components/base/Section";
import User from "../models/User";
import FlexRow from "../../../components/base/FlexRow";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import DataTableActiveToggle from "../../../components/data/DataTableActiveToggle";
import CustomersRenewModal from "../components/CustomersRenewModal";
import CustomersChargeWalletModal from "../components/CustomersChargeWalletModal";
import CustomersCancelModal from "../components/CustomersCancelModal";

interface IProps {
    userType: UserTypes
}

function UsersList(props: IProps) {

    const hub = useHub();
    const user = getUser();

    const [selectedItem, selectedItemSet] = useState<User>();
    const [selectedItems, selectedItemsSet] = useState<User[]>([]);

    const [suspendModal, suspendModalSet] = useState<boolean>(false);
    const [activateModal, activateModalSet] = useState<boolean>(false);
    const [deleteModal, deleteModalSet] = useState<boolean>(false);

    const [passwordModal, passwordModalSet] = useState<boolean>(false);
    const [renewModal, renewModalSet] = useState<boolean>(false);
    const [cancelSubModal, cancelSubModalSet] = useState<boolean>(false);
    const [chargeWalletModal, chargeWalletModalSet] = useState<boolean>(false);
    const [globalState, setGlobalState] = useGlobal();

    const navigate = useNavigate();

    const createUserProfileLink = (data: any) => {
        var link = "/accounts/manage/" + data.id;
        //if (data.userType == UserTypes.Agent) link = "/agents/view/" + data.id;
        return link;
    }

    const UserAvatarField = (data: User) => (
        <>
            <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                <Link to={createUserProfileLink(data)}>
                    <div className="symbol-label">
                        <img src={data?.avatarUrl ?? "/assets/media/avatars/blank.png"} className="w-100" />
                    </div>
                </Link>
            </div>
            <div className="d-flex flex-column">
                <FlexRow>
                    <Link to={createUserProfileLink(data)} className="text-gray-800 text-hover-primary mb-1 fw-bold limited-text mw-150px" title={data.firstName + " " + data.lastName}>
                        {data.firstName + " " + data.lastName}
                    </Link>
                    {
                        data.suspended &&
                        <span className="badge badge-danger ms-3 cursor-pointer"
                            onClick={e => {
                                e.preventDefault()
                                selectedItemsSet([data])
                                activateModalSet(true)
                            }}>
                            Suspended
                        </span>
                    }
                </FlexRow>
                <span className="fs-8 text-muted">@{data.username}</span>
            </div>
        </>
    )

    const UserActiveStatus = (data: User) => (
        <>
            {
                data.suspended ?
                    <Icon icon="times-circle" type="solid" className="me-1 text-danger" size={18} title="User is Suspended!" />
                    :
                    <Icon icon="check-circle" type="solid" className="me-1 text-success" size={18} title="User is Active!" />
            }
        </>
    )

    const UserContactField = (data: User) => (
        <div>
            {
                window.isNullOrEmpty(data.phoneItem?.phone) ?
                    <>
                        {data.email}
                    </>
                    :
                    <>
                        {data.phoneItem?.phoneFriendly}
                        <div className="fs-8 text-muted">
                            {data.email}
                        </div>
                    </>
            }
        </div>
    )

    const TodaysShiftsField = (data: User) => (
        <>
            {
                data.todayShifts && data.todayShifts.length > 0 ?
                    <div>
                        {
                            data.todayShifts.map((sh, k) =>
                                <div className="d-flex align-items-center" key={k}>
                                    <Icon icon="circle" type="solid" color={sh.color} />
                                    <div className="ms-2 fs-7">
                                        {sh.departmentName} ({sh.shiftTypeName} | {sh.startTime}-{sh.endTime})
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className="text-muted">
                        [No Shift]
                    </div>
            }
        </>
    )

    const ViewAgentAction = (data: any) => (
        <div className="menu-item">
            <Link to={"/agents/view/" + data.id} className="menu-link px-3">
                <Icon icon="user" className="me-2" />
                View
            </Link>
        </div>
    )



    const TechJobSourcesField = (data: User) => (
        <>
            {
                window.isNullOrEmpty(data.techJobSourcesShortText) ?
                    <div className="fs-8 text-muted">
                        [No Source]
                    </div>
                    :
                    <div className="fs-8" title={data.techJobSourcesFullText}>
                        {data.techJobSourcesShortText}
                    </div>
            }
        </>

    )
    const TechJobTypesField = (data: User) => (
        <>
            {
                window.isNullOrEmpty(data.techJobTypesShortText) ?
                    <div className="fs-8 text-muted">
                        [No Type]
                    </div>
                    :
                    <div className="fs-8" title={data.techJobTypesFullText}>
                        {data.techJobTypesShortText}
                    </div>
            }
        </>
    )

    const TechGroupsField = (data: User) => (
        <>
            {
                window.isNullOrEmpty(data.techGroupsShortText) ?
                    <div className="fs-8 text-muted">
                        [No Group]
                    </div>
                    :
                    <div className="fs-8" title={data.techGroupsFullText}>
                        {data.techGroupsShortText}
                    </div>
            }
        </>
    )

    const TechMetroField = (data: User) => (
        <>
            {
                window.isNullOrEmpty(data.techMetrosShortText) ?
                    <div className="fs-8 text-muted">
                        [No Metro]
                    </div>
                    :
                    <div className="fs-8" title={data.techMetrosFullText}>
                        {data.techMetrosShortText}
                    </div>
            }
        </>
    )


    const WalletBalanceCol = (data: User) => (
        <div>
            {data.wallet?.balanceString}
        </div>
    )

    const SubscriptionCol = (data: User) => (
        <div>
            <div className={`${(data.subscription?.isCanceled ? "text-danger" : data.subscription?.isActive ? "text-success fw-bold" : "text-warning")}`}>
                {toPrettyDate(data.subscription?.endsAt)}
            </div>
            {
                data.subscription?.isCanceled &&
                <span className="text-danger fs-8">Canceled</span>
            }

            {
                data.subscription?.isActive &&
                <span className="text-success fw-bold fs-8">Active</span>
            }
        </div>
    )

    const CreatedItem = (data: User) => (
        <div>
            <div className='d-flex align-items-center'>
                {toPrettyDate(data.createdAt)}
            </div>
            {
                data.creatorUser && data.userType != UserTypes.Customer &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    useEffect(() => {

        if (props.userType == UserTypes.Admin) {
            validateRole("ManageAdmins");
            setPage("Admins", "Accounts");
        }

        if (props.userType == UserTypes.Agent) {
            validateRole("ManageAgents");
            setPage("Agents", "Accounts");
        }

        if (props.userType == UserTypes.Customer) {
            validateRole("IsSystemAdmin");
            setPage("Customers", "Accounts");
        }

        if (props.userType == UserTypes.Technician) {
            validateRole("ManageTechnicians");
            setPage("Technicians", "Accounts");
        }

    }, [props.userType])


    const SetPassword = (data: any) => (
        <div className="menu-item">
            <a href="#" className="menu-link text-gray-700 px-3"
                onClick={e => {
                    e.preventDefault();
                    selectedItemSet(data);
                    passwordModalSet(true);
                }}>
                <Icon icon="key" className="me-2" />
                Set Password
            </a>
        </div>
    )

    const CustomersActions = (data: User) => (
        <>
            <div className="menu-item">
                <a href="#" className="menu-link text-gray-700 px-3"
                    onClick={e => {
                        e.preventDefault();
                        selectedItemSet(data);
                        renewModalSet(true);
                    }}>
                    <Icon icon="shield-plus" className="me-2" />
                    Renew Subscription
                </a>
            </div>
            <div className="menu-item">
                <a href="#" className="menu-link text-danger px-3"
                    onClick={e => {
                        e.preventDefault();
                        selectedItemSet(data);
                        cancelSubModalSet(true);
                    }}>
                    <Icon icon="shield-plus" className="me-2" />
                    Cancel Subscription
                </a>
            </div>
            <div className="menu-item">
                <a href="#" className="menu-link text-gray-700 px-3"
                    onClick={e => {
                        e.preventDefault();
                        selectedItemSet(data);
                        chargeWalletModalSet(true);
                    }}>
                    <Icon icon="wallet" className="me-2" />
                    Charge Wallet
                </a>
            </div>
        </>
    )




    const SuspendAction = (data: User) => (
        <>
            {
                hasRole("UsersDelete") &&
                <>
                    {
                        data.suspended ?
                            <div className="menu-item">
                                <a href="#" className="menu-link text-gray-700 px-3"
                                    onClick={e => {
                                        e.preventDefault()
                                        selectedItemsSet([data])
                                        activateModalSet(true)
                                    }}>
                                    <Icon icon="check-circle" className="me-2" />
                                    Activate User
                                </a>
                            </div>
                            :
                            <div className="menu-item">
                                <a href="#" className="menu-link text-gray-700 px-3"
                                    onClick={e => {
                                        e.preventDefault()
                                        selectedItemsSet([data])
                                        suspendModalSet(true)
                                    }}>
                                    <Icon icon="ban" className="me-2" />
                                    Suspend User
                                </a>
                            </div>
                    }
                </>
            }
        </>
    )


    const getObjectName = () => (props.userType == UserTypes.Admin ? "Admin" : props.userType == UserTypes.Agent ? "Agent" : props.userType == UserTypes.Technician ? "Technician" : "Customer")


    return (
        <Layout wide>
            <Section fluid>
                <DataTable objectName={getObjectName()}
                    filteringProps={(props.userType == UserTypes.Agent ? AgentsFilteringProps : props.userType == UserTypes.Technician ? TechsFilteringProps : FilteringProps)}
                    sortingProps={(props.userType == UserTypes.Agent ? SortingProps : props.userType == UserTypes.Technician ? TechSortingProps : SortingProps)}
                    apiRoute={(props.userType == UserTypes.Admin ? API_ROUTES.UsersAdmins : props.userType == UserTypes.Agent ? API_ROUTES.UsersAgents : props.userType == UserTypes.Technician ? API_ROUTES.UsersTechnicians : API_ROUTES.UsersCustomers)}
                    formValidator={UserValidator} refresh={globalState.updateUsers}
                    addCustom={() => {
                        createUserWithType(props.userType);
                    }}
                    route={(props.userType == UserTypes.Admin ? "/accounts/admins" : props.userType == UserTypes.Agent ? "/accounts/agents" : props.userType == UserTypes.Technician ? "/accounts/technicians" : "/accounts/customers")}
                    edit editCustom={data => {
                        navigate(createUserProfileLink(data) + "/edit");
                    }}
                    delete={hasRole("UsersDelete")}
                    deleteCustom={data => {
                        selectedItemsSet([data]);
                        deleteModalSet(true);
                    }}
                    deleteMultiple={data => {
                        selectedItemsSet(data);
                        deleteModalSet(true);
                    }}
                    groupActions={[
                        {
                            title: "Suspend Selected User(s)",
                            icon: "ban",
                            action(data) {
                                selectedItemsSet(data)
                                suspendModalSet(true)
                            }
                        }
                    ]}
                >

                    <DataTableField title="user" className="d-flex">
                        <UserAvatarField />
                    </DataTableField>

                    <DataTableField title="Contact">
                        <UserContactField />
                    </DataTableField>

                    <DataTableField title="Groups" hidden={props.userType != UserTypes.Technician}>
                        <TechGroupsField />
                    </DataTableField>

                    <DataTableField title="Metro" hidden={props.userType != UserTypes.Technician}>
                        <TechMetroField />
                    </DataTableField>

                    <DataTableField title="Job Types" hidden={props.userType != UserTypes.Technician}>
                        <TechJobTypesField />
                    </DataTableField>

                    <DataTableField title="Job Sources" hidden={props.userType != UserTypes.Technician}>
                        <TechJobSourcesField />
                    </DataTableField>

                    <DataTableField title="Today Shift(s)" hidden={props.userType != UserTypes.Agent}>
                        <TodaysShiftsField />
                    </DataTableField>

                    <DataTableField title="Wallet Balance" hidden={props.userType != UserTypes.Customer}>
                        <WalletBalanceCol />
                    </DataTableField>

                    <DataTableField title="Subscription End" hidden={props.userType != UserTypes.Customer}>
                        <SubscriptionCol />
                    </DataTableField>

                    <DataTableField title="Created At">
                        <CreatedItem />
                    </DataTableField>

                    {
                        props.userType == UserTypes.Customer && user?.isSuperAdmin &&
                        <DataTableCustomActions>
                            <CustomersActions />
                        </DataTableCustomActions>
                    }

                    <DataTableCustomActions>
                        <SetPassword />
                    </DataTableCustomActions>


                    {
                        props.userType == UserTypes.Agent &&
                        <DataTableCustomActions>
                            <ViewAgentAction />
                        </DataTableCustomActions>
                    }

                    <DataTableCustomActions>
                        <SuspendAction />
                    </DataTableCustomActions>
                </DataTable>
            </Section>



            {
                passwordModal && selectedItem &&
                <Modal id="modal-set-password"
                    title="Set Password"
                    buttonText="Update Password"
                    visible open staticBackdrop
                    onClose={() => {
                        passwordModalSet(true);
                        selectedItemSet(undefined);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Put("/accounts/users/logininfo/" + selectedItem.id, body, res => {
                            if (callback) callback(res);
                            if (res.success) {
                                toastSuccess("'" + selectedItem.fullName + "' Password Changed Successfully!");
                            }
                        });

                    }}>

                    <div>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <a>
                                    <div className="symbol-label">
                                        <img src={selectedItem?.avatarUrl ?? "/assets/media/avatars/blank.png"} className="w-100" />
                                    </div>
                                </a>
                            </div>
                            <div className="d-flex flex-column">
                                <a className="text-gray-800 text-hover-primary mb-1 fw-bold">
                                    {selectedItem?.firstName + " " + selectedItem?.lastName}
                                </a>
                                <span>@{selectedItem?.username}</span>
                            </div>
                        </div>
                        <div className="mt-10">
                            <TextBox id="txt-reset-password" label="Password" name="password" password generatePassword block autoFocus marginBottom={10} />
                            <ToggleSwitch name="sendToEmail" raw small
                                label="Send the new login information to the user via email!" />
                        </div>
                    </div>

                </Modal>
            }

            {
                renewModal && selectedItem &&
                <CustomersRenewModal user={selectedItem}
                    onClose={() => {
                        renewModalSet(false)
                        selectedItemSet(undefined)
                    }} />
            }

            {
                cancelSubModal && selectedItem &&
                <CustomersCancelModal user={selectedItem}
                    onClose={() => {
                        cancelSubModalSet(false)
                        selectedItemSet(undefined)
                    }} />
            }

            {
                chargeWalletModal && selectedItem &&
                <CustomersChargeWalletModal user={selectedItem}
                    onClose={() => {
                        renewModalSet(false)
                        selectedItemSet(undefined)
                    }} />
            }


            {
                deleteModal && selectedItems.length > 0 &&
                <Modal id="modal-delete-user-account"
                    title={`Delete ${getObjectName()}`}
                    open visible staticBackdrop
                    buttonText="Confirm & Delete"
                    buttonColor="danger"
                    onClose={() => {
                        deleteModalSet(false)
                        selectedItemSet(undefined)
                    }}
                    onConfirm={callback => {
                        var ids: string[] = [];
                        selectedItems.forEach(e => ids.push(e.id!));
                        hub.Post(API_ROUTES.Users + "delete", ids, res => {
                            if (res.success) {
                                toastSuccess("Selected User(s) has been deleted successfully!");
                                updateUsersOn()
                            }
                            callback()
                        })
                    }}
                    secondActionText="Deactivate Instead"
                    secondActionColor="secondary"
                    onSecondConfirm={callback => {
                        var ids: string[] = [];
                        selectedItems.forEach(e => ids.push(e.id!));
                        hub.Post(API_ROUTES.Users + "suspend", ids, res => {
                            if (res.success) {
                                toastSuccess("Selected User(s) has been suspended successfully!");
                                updateUsersOn()
                            }
                            callback()
                        })
                    }}>
                    <div className="py-5">
                        Are you sure you want to delete the selected user{selectedItems.length > 1 ? "s" : ""}?
                        This action cannot be undone!
                        <br />
                        Alternatively, consider deactivating the user{selectedItems.length > 1 ? "s" : ""} to retain their data!
                    </div>
                    <FlexRow className="mb-10" wrap>
                        {
                            selectedItems.map(user =>
                                <span key={`user-selected-for-delete-${user.id}`} className="badge badge-light-danger me-2 mb-1">
                                    <Icon icon="user" className="me-2" size={12} type="solid" />
                                    <span className="fs-7 fw-bold">
                                        {user.fullName}
                                    </span>
                                </span>
                            )
                        }
                    </FlexRow>
                </Modal>
            }

            {
                suspendModal && selectedItems.length > 0 &&
                <Modal id="modal-suspend-user-account"
                    title={`Suspend ${getObjectName()}`}
                    open visible staticBackdrop
                    buttonText="Suspend Users"
                    buttonColor="danger"
                    onClose={() => {
                        suspendModalSet(false)
                        selectedItemSet(undefined)
                    }}
                    onConfirm={callback => {
                        var ids: string[] = [];
                        selectedItems.forEach(e => ids.push(e.id!));
                        hub.Post(API_ROUTES.Users + "suspend", ids, res => {
                            if (res.success) {
                                toastSuccess("Selected User(s) has been suspended successfully!");
                                updateUsersOn()
                            }
                            callback()
                        })
                    }}>
                    <div className="py-5">
                        Are you sure you want to suspend the selected user{selectedItems.length > 1 ? "s" : ""}?
                    </div>
                    <FlexRow className="mb-10" wrap>
                        {
                            selectedItems.map(user =>
                                <span key={`user-selected-for-delete-${user.id}`} className="badge badge-light-danger me-2 mb-1">
                                    <Icon icon="user" className="me-2" size={12} type="solid" />
                                    <span className="fs-7 fw-bold">
                                        {user.fullName}
                                    </span>
                                </span>
                            )
                        }
                    </FlexRow>
                </Modal>
            }

            {
                activateModal && selectedItems.length > 0 &&
                <Modal id="modal-activate-user-account"
                    title={`Activate ${getObjectName()}`}
                    open visible staticBackdrop
                    buttonText="Activate Users"
                    buttonColor="success"
                    onClose={() => {
                        activateModalSet(false)
                        selectedItemSet(undefined)
                    }}
                    onConfirm={callback => {
                        var ids: string[] = [];
                        selectedItems.forEach(e => ids.push(e.id!));
                        hub.Post(API_ROUTES.Users + "activate", ids, res => {
                            if (res.success) {
                                toastSuccess("Selected User(s) has been activated successfully!");
                                updateUsersOn()
                            }
                            callback()
                        })
                    }}>
                    <div className="py-5">
                        Are you sure you want to activate the selected user{selectedItems.length > 1 ? "s" : ""}?
                    </div>
                    <FlexRow className="mb-10" wrap>
                        {
                            selectedItems.map(user =>
                                <span key={`user-selected-for-delete-${user.id}`} className="badge badge-light-danger me-2 mb-1">
                                    <Icon icon="user" className="me-2" size={12} type="solid" />
                                    <span className="fs-7 fw-bold">
                                        {user.fullName}
                                    </span>
                                </span>
                            )
                        }
                    </FlexRow>
                </Modal>
            }

        </Layout>
    );
}

export default UsersList;
