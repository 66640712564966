import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Modal from "../../../../../components/base/Modal";
import DatePicker from "../../../../../components/form/DatePicker";
import TimePicker, { TimeItem } from "../../../../../components/form/TimePicker";
import { API_ROUTES } from "../../../../../core/Constants";
import { useHub } from "../../../../../core/Global";
import Job from "../../models/Job"
import DropDown from "../../../../../components/form/DropDown";
import TextArea from "../../../../../components/form/TextArea";
import JobStatus from "../../../base/models/JobStatus";
import ToggleSwitch from "../../../../../components/form/ToggleSwitch";
import TextBox from "../../../../../components/form/TextBox";


interface IProps {
    job: Job
    forceUpdateStatus?: boolean
    onUpdate?: () => void
    onClose?: () => void
    onCancel?: () => void
}

export default function JobAppointmentModal(props: IProps) {

    const hub = useHub();
    const [minToTime, minToTimeSet] = useState<number>();

    const [apFromTime, apFromTimeSet] = useState<TimeItem>();
    const [apToTime, apToTimeSet] = useState<TimeItem>();

    const [appointmentReminder, appointmentReminderSet] = useState<boolean>(false)

    const [statuses, statusesSet] = useState<JobStatus[]>([]);
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();
    const fetchStatuses = () => {
        hub.Get(API_ROUTES.JobStatuses + "forappointmentremove", res => {
            if (res.success && res.data) {
                statusesSet(res.data);
            }
        })
    }

    useEffect(() => {
        fetchStatuses();

    }, [])

    useEffect(() => {
        if (props.job?.appointmentTimeFrom && props.job.appointmentTimeFrom.includes(":")) {
            var h = parseInt(props.job.appointmentTimeFrom.split(":")[0]);
            var m = parseInt(props.job.appointmentTimeFrom.split(":")[1]);
            var t = (h * 60) + m;
            minToTimeSet(t);
            if (props.job.appointmentReminder) appointmentReminderSet(true);
        }
    }, [props.job])


    const submit = (body: any, callback: any) => {

        window.hideFormErrors();
        var valid = true;

        if (window.isNullOrEmpty(body.appointmentDate)) {
            window.showFormError("appointmentDate", "select appointment date!", true, "modal-update-appoiontment", true);
            valid = false;
        }

        if (!apFromTime) {
            window.showFormError("appointmentFrom", "select arrival from time!", true, "modal-update-appoiontment", true);
            valid = false;
        }

        if (!apToTime) {
            window.showFormError("appointmentTo", "select arrival to time!", true, "modal-update-appoiontment", true);
            valid = false;
        }

        if (apFromTime && apToTime && apToTime.time <= apFromTime.time && !apToTime.nextDay) {
            window.showFormError("appointmentTo", "must be greater than arrival from time!", true, "modal-update-appoiontment", true);
            valid = false;
        }

        if (!valid) {
            if (callback) callback();
            return;
        }

        hub.Post(API_ROUTES.Jobs + "appoiontment", {
            ...body,
            id: props.job?.id
        }, res => {
            if (props.onUpdate) props.onUpdate();
            if (callback) callback(res);
        });

    }

    return (
        <>
            <Modal id="modal-update-appoiontment"
                title="Appointment"
                subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                buttonText="Set Appointment"
                buttonColor="primary"
                visible open staticBackdrop={!window.isNullOrEmpty(props.job?.techId)}
                onClose={props.onClose}
                onCancel={() => {
                    if (props.onCancel) props.onCancel();
                    window.closeModal("modal-update-appoiontment");
                }}
                onSubmit={submit}>
                {
                    false ?
                        <div className="alert alert-danger">
                            You must assign a technician to the job first!
                        </div>
                        :
                        <div>
                            <FlexRow>
                                <label className={"flex-grow-1 fs-7 mb-1"} htmlFor={"dt-jobupdateappoitment"}>
                                    Appointment Date
                                    {
                                        <span id={"appointmentDate-err"} className="small text-danger mx-3 form-error"></span>
                                    }
                                </label>
                                {
                                    props.job?.appointmentDate &&
                                    <a href="#" className="fs-8 text-danger"
                                        onClick={e => {
                                            e.preventDefault();
                                            window.openModal("modal-clear-appointment-confirm");
                                        }}>Remove Appointment</a>
                                }
                            </FlexRow>

                            <DatePicker id="dt-jobupdateappoitment" name="appointmentDate" icon="calendar-clock"
                                block empty selectedDate={props.job?.appointmentDate} onPick={e => {
                                    window.hideFormErrors();
                                }} />

                            <div className="row">

                                <div className="col-md-6">
                                    <TimePicker key="job-appointmentmodal-from" id="job-appointmentmodal-from" name="appointmentFrom"
                                        label="Arrival From" labelUp defaultValue={props.job?.appointmentTimeFrom}
                                        onTimePicked={t => {
                                            window.hideFormErrors();
                                            minToTimeSet(t.time);
                                            apFromTimeSet(t);
                                        }}
                                        onTimeCleared={() => apFromTimeSet(undefined)}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <TimePicker id="job-appointmentmodal-to" name="appointmentTo"
                                        min={minToTime} time={!props.job.appointmentTimeFrom && minToTime ? (minToTime + 60) : undefined}
                                        onTimePicked={t => {
                                            window.hideFormErrors();
                                            apToTimeSet(t);
                                        }}
                                        onTimeCleared={() => apToTimeSet(undefined)}
                                        label="Arrival To" labelUp defaultValue={props.job.appointmentTimeTo} />
                                </div>

                            </div>

                            {
                                props.forceUpdateStatus ?
                                    <input type="hidden" name="updateStatus" value="True" />
                                    :
                                    <div className="mt-3">
                                        <ToggleSwitch name="updateStatus" label="Update Job Status to Appointment" raw small checked />
                                        {/* <div className="form-check form-check-custom form-check-solid form-check-sm">
                                        <input className="form-check-input" type="checkbox" name="updateStatus" value="True" defaultChecked id="chk-appointment-update-status" />
                                        <label className="form-check-label fs-7" htmlFor="chk-appointment-update-status">
                                            Update Job Status to Appointment
                                        </label>
                                    </div> */}
                                    </div>
                            }

                            <div className="mt-5 mb-5">
                                <ToggleSwitch name="appointmentReminder" label="Send Reminder to Technician"
                                    raw small checked={appointmentReminder}
                                    onChange={e => appointmentReminderSet(e)} />
                            </div>

                            {
                                appointmentReminder &&
                                <TextBox name="appointmentReminderTime" block
                                    numeric maxLength={3} iconText="Minutes Before"
                                    value={(props.job?.appointmentReminderTime ?? 60)} />
                            }

                        </div>
                }

            </Modal>
            <Modal id="modal-clear-appointment-confirm"
                title="Remove Appointment"
                secondModal
                onSubmit={(body, callback) => {

                    if (props.job?.isAppointment) {
                        if (selectedStatus?.noteRequired && (!body.statusNote || body.statusNote.length < 3)) {
                            window.showFormError("statusNote", "Please write the status note!", false, "modal-clear-appointment-confirm");
                            if (callback) callback();
                            return;
                        }
                    }

                    hub.Post(API_ROUTES.Jobs + "clearappoiontment", {
                        ...body,
                        id: props.job?.id
                    }, res => {
                        if (res.success) {
                            window.closeModal("modal-clear-appointment-confirm");
                            window.closeModal("modal-update-appoiontment");
                        }
                    });

                }}
                buttonText="Confirm & Remove"
                buttonColor="danger">
                {
                    props.job?.isAppointment ?
                        <div>
                            <p>Remove the appointment and update the job status to: </p>
                            <DropDown id="drp-clearapp-jobstatus" name="statusId"
                                source={statuses} autoSelectFirstItem block
                                onSelectItem={e => selectedStatusSet(e)}></DropDown>

                            {
                                selectedStatus?.noteRequired &&
                                <TextArea name="statusNote" label="Status Note*" block labelUp
                                    placeholder={"Please describe why you're changing the status to " + selectedStatus.name} />
                            }
                        </div>
                        :
                        <div className="my-10">
                            Are you sure you want to remove the appointment?
                        </div>
                }

            </Modal>
        </>
    )

}