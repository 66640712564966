import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import DropDown from "../../../../components/form/DropDown";
import TextArea from "../../../../components/form/TextArea";
import TextBox from "../../../../components/form/TextBox";
import Announcement from "../models/Announcement";
import DataItem from "../../../../models/DataItem";
import { AnnouncementColorTypes, AnnouncementDismissTypes } from "../../../../core/Enums";
import FlexRow from "../../../../components/base/FlexRow";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import { getUser, useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";


interface IProps {
    item?: Announcement
    onClose?: () => void
    onSave?: () => void
}

export default function AnnouncementForm(props: IProps) {

    const hub = useHub();
    const user = getUser();

    const [ColorTypes] = useState<DataItem[]>([
        {
            id: AnnouncementColorTypes.Info.toString(),
            name: "Info"
        },
        {
            id: AnnouncementColorTypes.Warning.toString(),
            name: "Warning"
        },
        {
            id: AnnouncementColorTypes.Danger.toString(),
            name: "Danger"
        }
    ])

    const [DismissTypes] = useState<DataItem[]>([
        {
            id: AnnouncementDismissTypes.ByUser.toString(),
            name: "Allow Dismiss by User!"
        },
        {
            id: AnnouncementDismissTypes.ByAdmin.toString(),
            name: "Only Dismiss by Admin!"
        }
    ])

    const [showToAll, showToAllSet] = useState<boolean>(false)

    useEffect(() => {
        showToAllSet(props.item?.showToAll ?? false)
    }, [props.item])

    return (
        <Modal id="modal-announcement-form"
            open visible staticBackdrop
            title={props.item?.id ? "Edit Announcement" : "Create Announcement"}
            buttonText={props.item?.id ? "Update Announcement" : "Create Announcement"}
            onOpen={() => $("#txt-announcement-title").focus()}
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                window.hideFormErrors();
                var valid = true;
                var focus = true;

                if (window.isNullOrEmpty(body.title)) {
                    window.formError("title", "Please write a valid title!", "modal-announcement-form", focus);
                    valid = false;
                    focus = false;
                }

                if (window.isNullOrEmpty(body.text)) {
                    window.formError("text", "Please write a valid text!", "modal-announcement-form", focus);
                    valid = false;
                    focus = false;
                }

                if (!valid) {
                    callback!()
                    return;
                }

                if (props.item?.id) {

                    hub.Put(API_ROUTES.Announcements + props.item.id, body, res => {
                        if (res.success) {
                            toastSuccess("Announcement has been updated successfully!");
                            if (props.onSave) props.onSave();
                        }

                        callback!(res)
                    })

                }
                else {

                    hub.Post(API_ROUTES.Announcements, body, res => {
                        if (res.success) {
                            toastSuccess("Announcement has been created successfully!");
                            if (props.onSave) props.onSave();
                        }

                        callback!(res)
                    })

                }





            }}>

            <div>
                <TextBox id="txt-announcement-title" name="title"
                    label="Title" labelUp block value={props.item?.title} />

                <TextArea name="text" label="Announcement Text" labelUp block rows={6} value={props.item?.text} />

                <DropDown name="colorType" label="Color Type" defaultValue={props.item?.colorType?.toString()}
                    labelUp block deselect={false} autoSelectFirstItem={props.item == undefined}
                    source={ColorTypes} />

                <DropDown name="dismissType" label="Dismiss Type" defaultValue={props.item?.dismissType?.toString()}
                    labelUp block deselect={false} autoSelectFirstItem={props.item == undefined}
                    source={DismissTypes} />

                <div className="fs-7 mb-2">
                    Show to
                </div>
                {
                    user?.isSuperAdmin || user?.isSystemAdmin &&
                    <FlexRow className="mb-4">
                        <ToggleSwitch name="showToAll" label="Everyone" raw small checked={showToAll} onChange={e => showToAllSet(e)} />
                        <FlexRow className="ms-3 mt-1">
                            <Icon icon="info-circle" className="fs-9 text-gray-600 me-1" />
                            <span className="fs-9 text-gray-600">Show the announcement to all accounts and all child users!</span>
                        </FlexRow>
                    </FlexRow>
                }

                {
                    !showToAll &&
                    <FlexRow>
                        <div className="me-6">
                            <ToggleSwitch name="showToAgents" label="Agents" raw small checked={props.item?.showToAgents ?? true} />
                        </div>
                        <div className="me-6">
                            <ToggleSwitch name="showToAdmins" label="Admins" raw small checked={props.item?.showToAdmins} />
                        </div>
                        <div>
                            <ToggleSwitch name="showToTechs" label="Technicians" raw small checked={props.item?.showToTechs} />
                        </div>
                    </FlexRow>
                }


                <div className="mt-10">
                    <ToggleSwitch name="showMyName" label="Show Creator Name!" raw small checked={props.item?.showMyName} />
                </div>
            </div>

        </Modal>
    )
}