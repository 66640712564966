import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { DataTable, DataTableCustomActions, DataTableField } from "../../../components/data/DataTable";
import { API_ROUTES } from '../../../core/Constants';
import { getUser, setPage, useHub } from '../../../core/Global';
import Icon from "../../../components/base/Icon";
import FlexRow from "../../../components/base/FlexRow";
import { ListItem } from "../../../core/Types";
import Section from "../../../components/base/Section";
import TechGroupCreateModal from "../components/TechGroupCreateModal";
import Div from "../../../components/base/Div";
import TechGroupDeleteModal from "../components/TechGroupDeleteModal";
import TechGroupManageModal from "../components/TechGroupManageModal";

function TechGroupsList() {

  const hub = useHub();
  const user = getUser();

  const [selectedItem, selectedItemSet] = useState<any>();
  const [refresh, refreshSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [techsModal, techsModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [manageModal, manageModalSet] = useState<boolean>(false);
  const [manageTab, manageTabSet] = useState<string>();

  const [sendJobModal, sendJobModalSet] = useState<boolean>(false);

  const edit = (data: any) => {
    selectedItemSet(data);
    editModalSet(true);
  }

  const deleteGroup = (data: any) => {
    selectedItemSet(data);
    deleteModalSet(true);
  }

  const AvtiveToggle = (data: any) => (
    <div>
      <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
        <input className="form-check-input" type="checkbox" defaultChecked={data.isActive}
          onChange={e => {
            hub.Post(API_ROUTES.JobTypeGroups + "activetoggle", {
              id: data.id,
              active: e.target.checked ? "1" : "0"
            }, res => {

            })
          }} />
      </label>
    </div>
  )

  const NameItem = (data: any) => (
    <a href="#"
      onClick={e => {
        e.preventDefault()
        selectedItemSet(data)
        manageModalSet(true)
        manageTabSet("edit")
      }}>
      {data.name}
    </a>
  )

  const DescrItem = (data: any) => (
    <Div limitedText maxWidth={250} title={data.description}>
      {data.description}
    </Div>
  )

  const TechsCount = (data: any) => (
    <div>
      <a href="#" onClick={e => {
        e.preventDefault()
        selectedItemSet(data)
        manageModalSet(true)
        manageTabSet("techs")
      }}>
        {data.techsCount}
      </a>
    </div>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "Unassigned"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const TechsAction = (data: any) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3" onClick={e => {
        e.preventDefault()
        selectedItemSet(data)
        manageModalSet(true)
        manageTabSet("techs")
      }}>
        <Icon icon="user-helmet-safety" className="me-2" />
        Technicians
      </a>
    </div>
  )

  const SendJobSettingsAction = (data: any) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3" onClick={e => {
        e.preventDefault()
        selectedItemSet(data)
        manageModalSet(true)
        manageTabSet("settings")
      }}>
        <Icon icon="cog" className="me-2" />
        Send Job Settings
      </a>
    </div>
  )

  useEffect(() => {
    setPage("Technician Groups", "Accounts");
  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>

        <DataTable
          objectName="Technician Group"
          apiRoute={API_ROUTES.TechGroups}
          modal="modal-add-jobtypegroup"
          edit editCustom={edit} addCustom={() => createModalSet(true)}
          delete deleteCustom={deleteGroup}
          refresh={refresh}>

          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Technicians">
            <TechsCount />
          </DataTableField>
          <DataTableField title="Description">
            <DescrItem />
          </DataTableField>
          <DataTableField title="User" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

          <DataTableField title="Created At" dataValueName="createdDate" />

          <DataTableCustomActions>
            <TechsAction />
          </DataTableCustomActions>
          <DataTableCustomActions>
            <SendJobSettingsAction />
          </DataTableCustomActions>

        </DataTable>

      </Section>


      {
        createModal &&
        <TechGroupCreateModal
          onClose={() => createModalSet(false)}
          onCreated={() => refreshSet(true)} />
      }

      {
        manageModal && selectedItem && manageTab &&
        <TechGroupManageModal model={selectedItem} tab={manageTab}
          onClose={() => {
            manageModalSet(false)
            selectedItemSet(undefined)
            manageTabSet(undefined)
          }}
          onUpdated={() => refreshSet(true)} />
      }

      {
        deleteModal &&
        <TechGroupDeleteModal model={selectedItem}
          onClose={() => deleteModalSet(false)}
          onDeleted={() => refreshSet(true)} />
      }

    </Layout>
  );
}

export default TechGroupsList;
