import React from "react"
import { setUserToken, useGlobal, useHub } from '../../../core/Global';
import Avatar from '../../base/Avatar';
import Environment from '../../../env/env';
import Hub from '../../../api/Hub';
import { HubConnectionState } from '@microsoft/signalr';
import { Link } from "react-router-dom";


function AsideUser() {

	const [globalState] = useGlobal();
	const env = new Environment();
	const hub = useHub();

	const fullName = () => {
		var name = globalState.user?.firstName + " " + globalState.user?.lastName;
		if (name.length > 15) name = name.substring(0, 12) + "...";
		return name;
	}

	return (
		<div className="aside-user d-flex align-items-sm-center justify-content-center w-100 py-5" style={{ padding: "0 10px" }}>

			<Link to="/profile/overview">
				<Avatar avatarUrl={globalState.user?.avatarUrl}
					fullName={globalState.user?.fullName} textSize={24} />
			</Link>

			<div className="aside-user-info flex-row-fluid flex-wrap ms-5">

				<div className="d-flex">

					<div className="flex-grow-1 me-2">
						<Link to="/profile/overview">
							<div className="text-white fs-7 fw-bold">
								{fullName()}
							</div>
						</Link>
						<div className="text-gray-600 fs-8 mb-1">
							@{globalState.user?.username}
						</div>
						{
							globalState.socketState == HubConnectionState.Connected ?
								<div className="d-flex align-items-center text-success fs-9">
									<span className="bullet bullet-dot bg-success me-1"></span>
									Connected
								</div>
								: globalState.socketState == HubConnectionState.Connecting ?
									<div className="d-flex align-items-center text-warning fs-9">
										<span className="bullet bullet-dot bg-warning me-1"></span>
										Connecting
									</div>
									:
									<div className="d-flex align-items-center text-danger fs-9">
										<span className="bullet bullet-dot bg-danger me-1"></span>
										Disconnected
									</div>
						}

					</div>

				</div>
			</div>
		</div>
	);
}

export default AsideUser;
