import { useEffect, useState } from "react";
import Calendar, { CalendarEventItem } from "../../../components/calendar/Calendar";
import { useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import Indicator from "../../../components/base/Indicator";
import Modal from "../../../components/base/Modal";

type AgentProps = {
  agentId?: string
}
let CalendarStart = "";
let CalendarEnd = "";
let CalendarViewType = "dayGridMonth";
function AgentShiftCalendar(props: AgentProps) {

    const hub = useHub();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [shiftEvents, shiftEventsSet] = useState<CalendarEventItem[]>();

    const fetch = (start?: any, end?: any, viewType?: string) => {

        CalendarStart = start ?? CalendarStart;
        CalendarEnd = end ?? CalendarEnd;
        CalendarViewType = viewType ?? CalendarViewType;

        var q = "?viewType=" + CalendarViewType;
        if(start) q+= "&start=" + CalendarStart;
        if(end) q+= "&end=" + CalendarEnd;
        fetchingSet(true);
        hub.Get("/accounts/agents/shiftscalendar/" + props.agentId + q, res => {
            if (res.success) {
                shiftEventsSet(res.data);
                fetchingSet(false);
            }
        })
    }

    const [fetchingDetails, fetchingDetailsSet] = useState<boolean>(true);
    const [shiftDetail, shiftDetailSet] = useState<any>();

    const fetchDetails = (id?:string) => {
        fetchingDetailsSet(true);
        hub.Get("/accounts/agents/shiftdetails/" + id, res => {
            if(res.success) {
                shiftDetailSet(res.data);
                fetchingDetailsSet(false);
            }
        })
    }

    useEffect(() => {
        fetch();
    }, [])

  return (
    <>
      <div className="row">
          <div className="col-12">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body border-top p-9">
                    <Calendar events={shiftEvents} loading={fetching}
                        onChange={e => {
                            //console.log("datesSet", SelectedCalendarQueueId, window.momentize(e.start).format("DDMMYYYY"));
                            //if(env.isDevelopment) console.log("calendar changed", e, e.view.type);
                            shiftEventsSet([]);
                            fetch(window.momentize(e.start).format("YYYYMMDD"), window.momentize(e.end).format("YYYYMMDD"), e.view.type);
                        }} 
                        onEventClick={e=> {
                            fetchDetails(e.event.id);
                            window.openModal("modal-view-myshift");
                        }}/>
                  
              </div>
            </div>
          </div>
      </div>


    <Modal id="modal-view-myshift"
        size="Large"
        title="Shift">
            <div>
                {
                    fetchingDetails && <Indicator className="p-20" />
                }

                {
                    !fetchingDetails && shiftDetail &&
                    <div>
                        <div>{shiftDetail.department.name}</div>
                    </div>
                }
                
            </div>
    </Modal>

    </>
  );
}

export default AgentShiftCalendar;
