import { useEffect, useState } from "react";
import Icon from "../../../../../components/base/Icon";
import Job from "../../models/Job";
import NotesContainer from "../NotesContainer";
import NotesTypeDropDown from "../NotesTypeDropDown";
import JobNote from "../../models/JobNote";
import { useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";


interface IProps {
    job: Job
}
export default function JobDrawerNotes(props:IProps) {
    
    let notesTextArea: HTMLTextAreaElement | null
    const hub = useHub()

    const [notesLoaded, notesLoadedSet] = useState<boolean>(false);
    const [noteType, noteTypeSet] = useState<string>("all");
    const [replyItem, replyItemSet] = useState<JobNote>();
    const [refreshNotes, refreshNotesSet] = useState<boolean>(false);
    useEffect(() => {
        if (refreshNotes) {
            setTimeout(() => {
                refreshNotesSet(false);
            }, 1000);
        }
    }, [refreshNotes])


    const [note, noteSet] = useState<string>("");
    const sendNote = () => {

        if (note == "") return;

        hub.Post(API_ROUTES.Jobs + "note", {
            message: note,
            id: props.job?.id,
            replyId: replyItem?.id
        }, res => {
            if (res.success) {
                refreshNotesSet(true);
                replyItemSet(undefined);
                noteSet("");
                notesTextArea?.focus();
            }
        })
    }

    const [notesHeight, notesHeightSet] = useState<number>()
    const setHeight = () => {
        var h = $(window).height() ?? 700;
        h = h - ($("#kt_drawer_job_header").outerHeight() ?? 50)
        h = h - ($("#kt_drawer_job_tabs").outerHeight() ?? 50)
        h = h - ($("#notes-quickview-textbox-area").outerHeight() ?? 50)
        h = h - ($("#div-notestypes-dropdown").outerHeight() ?? 50)
        h = h - 50

        notesHeightSet(h)
    }

    useEffect(() => {
        setHeight()

        window.addEventListener("resize", setHeight);
        return () => {
            window.removeEventListener("resize", setHeight);
        }
    }, [])

    return (
        <div>
            <div id="notes-quickview-textbox-area" className="mt-2 border-bottom"
                style={{
                    position: "relative",
                }}>
                <textarea id="txt-job-details-note" name="message" placeholder="Write a note..." data-gramm="false"
                    ref={e => notesTextArea = e} value={note} onChange={e => noteSet(e.target.value)}
                    className="form-control form-control-sm ps-5 pe-20" rows={4} autoFocus
                    style={{
                        resize: "none",
                        border: "none",
                        outline: "none"
                    }}></textarea>

                <button type="button" id="btn-job-details-sendnote" className="btn btn-sm btn-primary btn-circle btn-icon p-7"
                    style={{
                        position: "absolute",
                        right: 10,
                        top: 20
                    }}
                    onClick={sendNote}>
                    <Icon icon="paper-plane-top" size={24} />
                </button>
            </div>

            <div id="div-notestypes-dropdown" className="m-5">
                <NotesTypeDropDown jobId={props.job.id} onChange={t => noteTypeSet(t)} />
            </div>

            <NotesContainer id="job-quickview-notes"
                refresh={refreshNotes} jobId={props.job.id}
                noteType={noteType} height={notesHeight} className="px-5"
                onReplyItemSet={item => replyItemSet(item)} />

            
        </div>
    )
}