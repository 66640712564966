import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";
import { toastError, toastSuccess } from "../../../components/base/Toast";
import TechGroup from "../models/TechGroup";
import TextBox from "../../../components/form/TextBox";
import TextArea from "../../../components/form/TextArea";
import Icon from "../../../components/base/Icon";
import TechGroupEditForm from "./TechGroupEditForm";
import TechGroupTechnicians from "./TechGroupTechnicians";
import { FontAwesomeIcons } from "../../../core/Types";
import TechGroupSendJobSettings from "./TechGroupSendJobSettings";


interface IProps {
    model?: TechGroup
    tab?: string
    onClose?: () => void
    onUpdated?: () => void
}


interface IMenuItem {
  id: string
  name: string
  icon: FontAwesomeIcons
}

export default function TechGroupManageModal(props: IProps) {

    const hub = useHub();
    const user = getUser();

    const [focusNames, focusNamesSet] = useState<boolean>(true);

    const [menu, menuSet] = useState<IMenuItem[]>([
      {
        id: "edit",
        name: "Edit Group",
        icon: "edit"
      },
      {
        id: "techs",
        name:"Technicians",
        icon: "user-helmet-safety"
      },
      {
        id: "settings",
        name:"Job Send Settings",
        icon: "cog"
      }
    ]);

    const [selectedTab, selectedTabSet] = useState<string>(props.tab ?? "edit")


    useEffect(() => {
      if(focusNames) {
        setTimeout(() => {
          focusNamesSet(false);
        }, 1000);
      }
    }, [focusNames])


    return (
        <Modal id="modal-manage-techgroup"
          title={props.model?.name ?? "Tech Group"}
          titleIcon="user-group"
          staticBackdrop visible open noFooter
          noPadding noForm
          onClose={props.onClose}
          size="Large">

            <div className="px-10">
              <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder border-bottom">
                {
                  menu.map((m, k) => 
                    <li key={k} className="nav-item mt-2">
                      <a className={"nav-link text-active-primary ms-0 me-10 py-5 " + (selectedTab == m.id ? "active" : "")} href="#"
                          onClick={e=> {
                            e.preventDefault()
                            selectedTabSet(m.id)
                          }}>
                        <Icon icon={m.icon} className="me-2" />
                        {m.name}
                      </a>
                    </li>
                  )
                }
              </ul>
            </div>
            <div style={{
              height:2,
              backgroundColor: "#F5F8FA"
            }}></div>

            <div className="px-10 py-5">
              {
                selectedTab == "edit" &&
                <TechGroupEditForm model={props.model} 
                  onDiscard={() => window.closeModal("modal-manage-techgroup")} 
                  onUpdated={() => {
                    props.onUpdated!()
                    window.closeModal("modal-manage-techgroup")
                  }} />
              }
              {
                selectedTab == "techs" &&
                <TechGroupTechnicians model={props.model} 
                    onDiscard={() => window.closeModal("modal-manage-techgroup")} 
                    onUpdated={() => {
                      props.onUpdated!()
                      window.closeModal("modal-manage-techgroup")
                    }}/>
              }
              {
                selectedTab == "settings" && 
                <TechGroupSendJobSettings model={props.model} 
                    onDiscard={() => window.closeModal("modal-manage-techgroup")} 
                    onUpdated={() => {
                      props.onUpdated!()
                      window.closeModal("modal-manage-techgroup")
                    }}/>
              }
            </div>
        </Modal>
    )
}