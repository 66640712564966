//Public Enums
export enum ActiveToggle {
    Inactive = 0, Active = 1
}
export enum SortTypes {
    Ascending = 0, Descending = 1
}

//Agent Enums
export enum AgentStatuses {
    Active = 1, Busy = 2, Puased = 3, Away = 4, Offline = 5
}

export enum ExtensionStatus {
    Active = 1, Ringing = 2, Busy = 3, Offline = 4, Unreachable = 5
}

//User Enums
export enum Gender {
    Female = 0, Male = 1
}
export enum UserStatus {
    Inactive = 0, Active = 1, Banned = 2, Restricted = 3
}
export enum UserTypes {
    Admin = 1, Agent = 2, Customer = 3, Technician = 4
}

//Finance Enums
export enum PaymentGateways {
    Stripe = 1, Paypal = 2
}

export enum InvoiceStatuses {
    Pending = 1, Paid = 2, Failed = 3
}

//Notification Enums 
export enum NotificationType {
    Login = 1, Logout = 2, AgentStatus = 3, MissedCall = 4, AddRemoveExt = 5, Shifts = 6, Tasks = 7, Job = 8
}
export enum NotificationParamsType {
    None = 0, InlineList = 1, BlockList = 2
}
export enum NotificationActions {
    None = 0, AgentPage = 1, CallPage = 2, JobPage = 3
}
export enum NotificationObjects {
    None = 0, Agent = 1, Call = 2, Job = 3
}

export enum NotificationRuleTypes {
    AppNotif = 1, DesktopNotif = 2, AppAlert = 3, SMSText = 4, Email = 5
}

export enum NotificationRuleUsers {
    Customer = 1, Admins = 2, Agents = 3, Agent = 4, Technician = 5
}

export enum NotificationRuleEvents {
    None = 0, JobStatusChanged = 1, JobInStatus = 2, JobAppointmentStarting = 3,
}


//Calls Enums
export enum CallTypes {
    None = 0, Incoming = 1, Outgoing = 2, Local = 3
}

export enum CallSources {
    PBX = 1, Twilio = 2
}

//Phone Number Enums
export enum PhoneNumberTypes {
    Local = 1, TollFree = 2, Mobile = 3
}
export enum InboundDest {
    None = 0, Extension = 1, Queue = 2, Redirect = 3
}
export enum InboundRedirectTypes {
    None = 0, JobsCustomers = 1, ExternalNumber = 2
}
export enum JobCallbackPriority {
    None = 0, Technician = 1, Operator = 2
}

//Queue Logs
export enum RecordingSettings {
    Force = 1, DontCare = 2, Never = 3
}

export enum SkipBusyAgentsModes {
    DontSkip = 1, Skip = 2, SkipAndRinginuseNo = 3, QueueCallsOnlyRinginuseNo = 4
}

export enum RingStrategy {
    RingAll = 1, LeastRecent = 2, FewestCalls = 3, Random = 4, RRMemory = 5,
    RROrdered = 6, Linear = 7, WRandom = 8
}

export enum MusicOnHoldModes {
    NoPlay = 1, PlayTillAnswer = 2
}

export enum Announcements {
    Always = 1, WhenNoFreeAgents = 2, WhenNoReadyAgents = 3
}

export enum MaxWaitTimeModes {
    Strict = 1, Loose = 2
}

export enum EmptyModes {
    Yes = 1, Strict = 2, UltraStrict = 3, No = 4, Loose = 5
}

//Report Enums
export enum ChartTimingTypes {
    Hourly = 1, Day = 2, Week = 3, Month = 4
}
export enum CallsChartSegments {
    Dispatchers = 1, Companies = 2, Queues = 3, Extensions = 4
}

export enum JobsChartSegments {
    Dispatchers = 1, Technician = 2, JobSource = 4, JobType = 5,
    Metro = 6, Payments = 8, ClosedJobs = 9, CanceledJobs = 10
}


//CRM Base Data
export enum JobMetroLayerTypes {
    None = 0, Circle = 1, Rectangle = 2, Polygon = 3
}

export enum JobSourceTypes {
    None = 0, Company = 1, Advertisement = 2
}

//CRM Jobs
export enum JobNoteTypes {
    None = 0, Default = 1, ChangeTech = 2, Cancelation = 3, Status = 4, JobIssue = 5,
}

//CRM Tasks
export enum Priorities {
    None = 0, Low = 1, Medium = 2, High = 3, Urgent = 4
}
export enum RepeatTypes {
    None = 0, Daily = 1, Weekly = 2, Monthly = 3, Yearly = 4, SpecificDate = 5
}
export enum NotifTimeTypes {
    Minutes = 1, Hours = 2, Days = 3
}

//Shift Enums
export enum ShiftIntervals {
    None = 0, Daily = 1, Weekly = 2, SpecificDate = 3
}

export enum DayOfWeek {
    Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6
}


//Chats

export enum MediaTypes {
    Image = 1, Audio = 2, Video = 3, File = 4
}

export enum ChatEnvironments {
    GSM = 1, WhatsApp = 2, App = 3
}



//Rates
export enum RateCategories {
    Technician = 1, Source = 2
}

export enum RateTypes {
    Percent = 1, Fixed = 2
}

export enum RateAmountTypes {
    None = 0, LessThan = 1, GreaterThanOrEqual = 2
}
export enum RateDateTypes {
    None = 0, SpecificDate = 1, DateRange = 2, WeekDays = 3
}

export enum RateRepeatTypes {
    None = 0, NoRepeat = 1, EveryMonth = 2, EveryYear = 3, Forever = 4, SpecificDate = 5
}

// Payments
export enum PaymentTypes {
    None = 0, Cash = 1, Check = 2, CCCharge = 3, CCManual = 4
}

export enum AmountType {
    Percent = 1, Fixed = 2
}

export enum InvoiceItemTypes {
    Product = 1, FixedService = 2, HourlyService = 3, Expense = 4
}



// Announcements

export enum AnnouncementDismissTypes {
    None = 0, ByUser = 1, ByDate = 2, ByAdmin = 3
}

export enum AnnouncementColorTypes {
    None = 0, Info = 1, Warning = 2, Danger = 3
}


export enum TimeFormats {
    Format24Hours = 1, Format12Hours = 2
}

export enum DistanceUnits {
    Kilometers = 1, Miles = 2
}

export enum TimePickerSteps {
    Every5Mins = 1, Every10Mins = 2, Every15Mins = 3, Every30Mins = 4
}