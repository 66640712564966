import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import Job from "../../models/Job";
import { hasRole, openEditJobClient } from "../../../../../core/Global";

interface IProps {
    job: Job
}
export default function JobDrawerClient(props: IProps) {

    const [showMore, showMoreSet] = useState<boolean>(false)
    useEffect(() => {
        if (showMore) $("#drawer-client-more").slideDown()
        else $("#drawer-client-more").slideUp()
    }, [showMore])
    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="user" size={10} className="me-2" type="solid" />
                            Name
                        </div>
                        <div className="ps-5">
                            {props.job.fullName}
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="phone-rotary" size={10} className="me-2" type="solid" />
                            Phone
                        </div>
                        <div className="ps-5">
                            {props.job.phone?.phoneFriendly}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <FlexRow justify="end">
                        {
                            (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                            <IconButton icon="edit" size={14} type="solid" title="Edit Client"
                                onClick={e => {
                                    openEditJobClient(props.job)
                                }} />
                        }

                        <IconButton icon={showMore ? "chevron-up" : "chevron-down"} size={14} type="solid"
                            title={showMore ? "Show less" : "Show more"}
                            onClick={e => showMoreSet(s => !s)} />

                    </FlexRow>
                </div>
            </div>
            <div id="drawer-client-more" className="mt-3" style={{ display: "none" }}>
                <div className="row">
                    <div className="col-md-4">
                        <div>
                            <div className="text-muted fs-8">
                                <Icon icon="briefcase" size={10} className="me-2" type="solid" />
                                Company
                            </div>
                            <div className="ps-5">
                                {props.job.company ?? "-"}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="text-muted fs-8">
                                <Icon icon="phone-rotary" size={10} className="me-2" type="solid" />
                                Secondary Phone
                            </div>
                            <div className="ps-5">
                                {props.job.phoneContact?.phoneFriendly ?? "-"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div>
                            <div className="text-muted fs-8">
                                <Icon icon="envelope" size={10} className="me-2" type="solid" />
                                Email Address
                            </div>
                            <div className="ps-5">
                                {props.job.emailAddress ?? "-"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}