import { useEffect, useState } from "react";
import FlexRow from "../../../../components/base/FlexRow";
import DataItem from "../../../../models/DataItem";
import { NotificationRuleEvents, NotificationRuleTypes, NotificationRuleUsers, NotifTimeTypes } from "../../../../core/Enums";
import DropDown from "../../../../components/form/DropDown";
import JobStatus from "../../../crm/base/models/JobStatus";
import { getOwner, getUser, useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import Modal from "../../../../components/base/Modal";
import TextBox from "../../../../components/form/TextBox";
import Tagify from "../../../../components/tagify/Tagify";
import { NotificationRule, NotificationRuleRequestModel, NotificationRuleTimeModel } from "../models/NotificationRuleModels";
import Icon from "../../../../components/base/Icon";
import { RuleEvents, RuleTypes, RuleUsersTypes, TimeTypes } from "../models/NotificationRuleConstants";

interface IProps {
    model?: NotificationRule
    onClose?: () => void
    onSave?: () => void
}
export default function NotifRuleFormModal(props: IProps) {
    
    const hub = useHub();
    const owner = getOwner();

    const [body, bodySet] = useState<NotificationRuleRequestModel>({});



    const [statuses, statusesSet] = useState<JobStatus[]>();
    const [statusesFrom, statusesFromSet] = useState<JobStatus[]>();
    const [statusesTo, statusesToSet] = useState<JobStatus[]>();
    
    const fetchStatuses = () => {
        hub.Get(API_ROUTES.JobStatuses + "all", res => {
            statusesSet(res.data);
            statusesFromSet(res.data);
            statusesToSet(res.data);
        })    
    }
    useEffect(() => {
        fetchStatuses();
    }, [])


    const [SelectedRuleTypes, SelectedRuleTypesSet] = useState<string[]>([]);
    useEffect(() => {
        var arr:NotificationRuleTypes[] = [];
        SelectedRuleTypes.forEach(e => {
            var n = Number.parseInt(e);
            if (!isNaN(n) && n > 0) arr.push(n);
        });
        bodySet({
            ...body,
            ruleTypes: arr
        })
    }, [SelectedRuleTypes])

    
    const [SelectedRuleUsers, SelectedRuleUsersSet] = useState<string[]>([]);
    useEffect(() => {
        var arr:NotificationRuleUsers[] = [];
        SelectedRuleUsers.forEach(e => {
            var n = Number.parseInt(e);
            if (!isNaN(n) && n > 0) arr.push(n);
        });
        bodySet({
            ...body,
            ruleUserTypes: arr
        })
    }, [SelectedRuleUsers])

    
    const [SelectedRuleEvent, SelectedRuleEventSet] = useState<DataItem>();
    useEffect(() => {
        if (SelectedRuleEvent?.id) {
            var n = Number.parseInt(SelectedRuleEvent.id)
            bodySet({
                ...body,
                ruleEvent: n
            })
        }
    }, [SelectedRuleEvent])
    
    const [JobStatusIdsFrom, JobStatusIdsFromSet] = useState<string[]>([]);
    useEffect(() => {
        bodySet({
            ...body,
            jobStatusIdsFrom: JobStatusIdsFrom
        })

        if (JobStatusIdsFrom.length > 0 && statusesFrom) {
            var arr:any[] = [];
            statusesFrom.forEach(s => {
                if (JobStatusIdsFrom.indexOf(s.id!) < 0) arr.push(s);
            });

            statusesToSet(s=> [...arr]);
        }
        
    }, [JobStatusIdsFrom])

    const [JobStatusIds, JobStatusIdsSet] = useState<string[]>([]);
    useEffect(() => {
        bodySet({
            ...body,
            jobStatusIds: JobStatusIds
        })
    }, [JobStatusIds])
    
    const [TimesList, TimesListSet] = useState<NotificationRuleTimeModel[]>([{
        id: 1,
        type: NotifTimeTypes.Minutes
    }]);
    useEffect(()=> {
        bodySet({
            ...body,
            ruleTimes: TimesList
        })
    }, [TimesList])

    useEffect(() => {
        console.log("body changed", body);
    }, [body])

    useEffect(() => {
        if(props.model) {

            bodySet({
                jobStatusIdsFrom: props.model.jobStatusIdsFrom,
                jobStatusIds: props.model.jobStatusIds,
                ruleEvent: props.model.ruleEvent,
                ruleTypes: props.model.ruleTypes,
                ruleUserTypes: props.model.ruleUserTypes,
                ruleTimes: props.model.ruleTimes
            })


        }
    }, [props.model])

    const validate = () => {
        var valid = true;

        if (!body.ruleTypes || body.ruleTypes.length < 1) {
            window.formError("ruleTypes", "Please select the send types!");
            valid = false;
        }

        if (!body.ruleUserTypes || body.ruleUserTypes.length < 1) {
            window.formError("ruleUsers", "Please select the destination users!");
            valid = false;
        }

        if (!body.ruleEvent) {
            window.formError("ruleEvent", "Please select the event!");
            valid = false;
        }

        if (body.ruleEvent == NotificationRuleEvents.JobStatusChanged) {

            var exsitsErr = false;
            body.jobStatusIds?.forEach(e => {
                if (body.jobStatusIdsFrom && body.jobStatusIdsFrom.indexOf(e) > -1) {
                    exsitsErr = true;
                }
            });

            if (exsitsErr) {
                window.formError("ruleJobStatusTo", "Invalid Selection: cannot include any statuses selected in 'From Status'!")
                valid = false;
            }
        }

        if (body.ruleEvent == NotificationRuleEvents.JobInStatus) {
            if (!body.jobStatusIds || body.jobStatusIds.length < 1) {
                window.formError("ruleJobStatus", "Please select an status!");
                valid = false;
            }
            if (!body.ruleTimes || body.ruleTimes.filter(f => !f.time || f.time > 0).length < 1) {
                window.formError("JobInStatusTime", "Please enter a valid time!");
                valid = false;
            }
        }

        return valid;
    }

    return (
        
        <Modal id="modal-notif-rule-form"
            title={props.model ? "Edit Notification Rule" : "Create Notification Rule"}
            open visible staticBackdrop
            buttonText={props.model ? "Update Rule" : "Create Rule"}
            onClose={props.onClose}
            onSubmit={(b, callback) => {
                if(!validate()) {
                    callback!();
                    return;
                }

                hub.Post(API_ROUTES.NotificationRules, body, res => {

                    if (res.success) {
                        if (props.onSave) props.onSave();
                    }

                    callback!(res);
                })

            }}>

            {/* <DropDown id="drp-ruletypes" name="ruleTypes" source={RuleTypes} multiple deselect={false} 
                label="Send" labelUp /> */}
            
            <Tagify id="drp-ruletypes" name="ruleTypes" source={RuleTypes} marginBottom={3} tagColors="#D1E9F6" defaultIds={props.model?.ruleTypes}
                placeholder="Select Notification Type" label="Send" labelUp onChange={e=> SelectedRuleTypesSet(e)} />

            <Tagify id="drp-ruleusers" name="ruleUsers" source={RuleUsersTypes} marginBottom={3} tagColors="#D1E9F6" defaultIds={props.model?.ruleUserTypes}
                placeholder="Select User Type" label="To" labelUp onChange={e=> SelectedRuleUsersSet(e)} />

            <DropDown id="drp-ruleevents" name="ruleEvent" source={RuleEvents}
                label="When" labelUp marginBottom={3} defaultValue={props.model?.ruleEvent?.toString()}
                onSelectItem={e=> SelectedRuleEventSet(e)} 
                onClear={() => SelectedRuleEventSet(undefined)} />

            {
                body.ruleEvent == NotificationRuleEvents.JobStatusChanged &&
                <>
                    <Tagify id="drp-rulejobstatusesfrom" name="ruleJobStatusFrom" marginBottom={3} source={statusesFrom}
                       placeholder="Select Status" label="From" labelUp any anyText="Any Status" defaultIds={props.model?.jobStatusIdsFrom}
                        onChange={e=> JobStatusIdsFromSet(e)} />
                       
                    <Tagify id="drp-rulejobstatuses" name="ruleJobStatusTo" marginBottom={3} source={statusesTo}
                       placeholder="Select Status" label="To" labelUp any anyText="Any Status" defaultIds={props.model?.jobStatusIds}
                       onChange={e=> JobStatusIdsSet(e)} />
                </>
            }

            {
                body.ruleEvent == NotificationRuleEvents.JobInStatus &&
                <>
                    <Tagify id="drp-rulejobstatuses" name="ruleJobStatus" marginBottom={3} source={statuses}
                        placeholder="Select Status" label="Status" labelUp defaultIds={props.model?.jobStatusIds} 
                        onChange={e=> JobStatusIdsSet(e)} />


                    <div className="fs-7">
                        For More Than
                        <span id={"RuleTimes-err"} className="small text-danger mx-3 form-error"></span>
                    </div>
                </>
            }

            {
                (body.ruleEvent == NotificationRuleEvents.JobInStatus || body.ruleEvent == NotificationRuleEvents.JobAppointmentStarting) &&
                <>
                    {
                        TimesList.map((t, k) => 
                            <FlexRow key={k} className="mb-2">
                                <div className="me-2 w-150px">
                                    <TextBox id={`txt-jobstatusremainTime-${k}`} name="ruleJobStatusTime" 
                                        numeric block noMarginBottom value={t.time?.toString()}
                                        onTextChanges={e=> {
                                            var arr = TimesList
                                            arr[k].time = Number.parseInt(e)
                                            TimesListSet(t=> [...arr])
                                        }} />
                                </div>
                                <div className="me-2 w-125px">
                                    <DropDown id={`drp-jobstatusremainTimeType-${k}`} name="ruleJobStatusTimeType" source={TimeTypes} 
                                        deselect={false} block marginBottom={0} defaultValue={t.type?.toString()}
                                        onSelect={e => {
                                            if (!e) return;
                                            var arr = TimesList
                                            arr[k].type = Number.parseInt(e)
                                            TimesListSet(t=> [...arr])
                                        }}/>
                                </div>
                                <div>
                                    {
                                        (k + 1) == TimesList.length ? 
                                        <a href="#" onClick={e=> {
                                            e.preventDefault();
                                            var arr = TimesList ?? [];
                                            arr.push({
                                                id: TimesList.length + 1
                                            });
                                            TimesListSet(t=> [...arr])
                                        }}>
                                            <Icon icon="plus" type="solid" size={14} />
                                        </a>
                                        :
                                        <a href="#" onClick={e=> {
                                            e.preventDefault();
                                            var arr = TimesList ?? [];
                                            arr.splice(k, 1);
                                            TimesListSet(t=> [...arr])
                                        }}>
                                            <Icon icon="minus" type="solid" size={14} />
                                        </a>
                                    }
                                </div>
                            </FlexRow>
                        )
                    }
                </>
            }

            

        </Modal>
    )
}