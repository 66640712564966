import React from "react"
import Icon from "../../../components/base/Icon";
import { FontAwesomeIcons } from '../../../core/Types';
import { Link } from "react-router-dom";

type MenuItemProps = {
    title?: string
    icon?: FontAwesomeIcons
    children?: any
    visible?: boolean
    here?: boolean
    link?: string
}

function MenuItem(props: MenuItemProps) {

    return (
        <>
            {
                !props.children && props.visible && props.link &&
                <div className={"menu-item" + (props.here ? " here" : "")}>
                    <Link to={props.link} className="menu-link">
                        <span className="menu-icon">
                            <span className="svg-icon svg-icon-2">
                                <Icon icon={props.icon} type="solid" color="#666" />
                            </span>
                        </span>
                        <span className="menu-title">
                            {props.title}
                        </span>
                    </Link>
                </div>
            }
            {
                props.children && props.visible &&
                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion" + (props.here ? " here show" : "")}>
                    <span className="menu-link">
                        <span className="menu-icon">
                            <span className="svg-icon svg-icon-2">
                                <Icon icon={props.icon} type="solid" color="#666" />
                            </span>
                        </span>
                        <span className="menu-title">{props.title}</span>
                        <span className="menu-arrow"></span>
                    </span>
                    <div className="menu-sub menu-sub-accordion menu-active-bg">
                        {props.children}
                    </div>
                </div>
            }
        </>
    );
}

export default MenuItem;
