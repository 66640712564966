import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import { API_ROUTES } from "../../../../core/Constants";
import { getUSA, getUser, useHub } from "../../../../core/Global";
import { HereGeocodePosition } from "../../../../models/MapModels";
import { toastSuccess } from "../../../../components/base/Toast";
import FlexRow from "../../../../components/base/FlexRow";
import ColorPicker from "../../../../components/form/ColorPicker";
import TextBox from "../../../../components/form/TextBox";
import DropDown from "../../../../components/form/DropDown";
import Map from "../../../../components/here/Map";
import Tagify from "../../../../components/tagify/Tagify";
import JobMetro from "../models/JobMetro";
import Icon from "../../../../components/base/Icon";
import TextArea from "../../../../components/form/TextArea";

type IProps = {
  metro?: JobMetro
  onClose: () => void
  onSubmit: () => void
}

export default function MetroFormModal(props: IProps) {

  const hub = useHub();
  const user = getUser();
  const usa = getUSA();

  const [working, workingSet] = useState<boolean>(false)
  const [buildMapAdd, buildMapAddSet] = useState<boolean>(false)
  const [layersMap, layersMapSet] = useState<any[]>([])
  const [centerMap, centerMapSet] = useState<HereGeocodePosition>()
  const [zoomMap, zoomMapSet] = useState<number>()

  const [countryId, countryIdSet] = useState<string>()
  const [stateId, stateIdSet] = useState<string>()

  const [states, statesSet] = useState<any[]>()
  const fetchStates = () => {
    hub.Get(API_ROUTES.States + "?countryId=" + countryId, res => {
      if (res.success) statesSet(res.data)
    })
  }

  const [selectedTechsIds, selectedTechsIdsSet] = useState<string[]>([])

  const [clearSearch, clearSearchSet] = useState<boolean>(false)
  useEffect(() => {
    if (clearSearch) {
      setTimeout(() => {
        clearSearchSet(false);
      }, 1000);
    }
  }, [clearSearch])

  useEffect(() => {
    if (props.metro) {
      countryIdSet(props.metro.countryId)
      stateIdSet(props.metro.stateId)
      layersMapSet(props.metro.layers ?? [])
      zoomMapSet(props.metro.mapZoom)
      centerMapSet(props.metro.mapCenter)
    }
    else {
      countryIdSet(usa.id)
    }
  }, [props.metro])

  useEffect(() => {
    if (countryId) fetchStates()
  }, [countryId])

  return (
    <Modal id="modal-metro-form"
      title="Create Metro"
      buttonText="Add Metro"
      size="XLarge"
      visible open noHeader noFooter noPadding staticBackdrop
      onOpen={() => {
        $("#txt-addmetro-name").focus();
        buildMapAddSet(true);
      }}
      onClose={props.onClose}
      onSubmit={(body, callback) => {

        var valid = true;

        if (!body.name || body.name == "" || body.name.length < 2) {
          window.formError("name", "Please write a valid name!", "modal-metro-form", true);
          valid = false;
        }

        if (window.isNullOrEmpty(body.countryId)) {
          window.formError("countryId", "Please choose the metro country!", "modal-metro-form");
          valid = false;
        }

        if (window.isNullOrEmpty(body.stateId)) {
          window.formError("stateId", "Please choose the metro state/region!", "modal-metro-form");
          valid = false;
        }


        if (!valid) {
          if (callback) callback();
          return;
        }

        workingSet(true)


        hub.Post(API_ROUTES.JobMetros, {
          ...body,
          id: props.metro?.id,
          layers: layersMap,
          mapCenter: centerMap,
          mapZoom: zoomMap,
          techIds: selectedTechsIds
        }, res => {
          if (res.success) {
            props.onSubmit();
            toastSuccess("Job metro has been saved successfully!");
          }
          if (callback) callback(res);
          workingSet(false)
        })



      }}>
      <div className="row">

        <div className="col-md-5 px-8">
          <FlexRow className="py-4 py-xxl-6 border-bottom-0">
            <h2 className="fs-1 fs-xxxl-1 modal-title d-flex align-items-center">
              {props.metro ? "Edit Metro" : "Create Metro"}
            </h2>
            <button type="button" className="btn btn-icon btn-sm btn-active-icon-primary ms-auto" title="Close"
              onClick={e => {
                e.preventDefault()
                window.closeModal("modal-metro-form")
              }}>
              <Icon icon="times" type="thin" className="fs-1 fs-xxxl-1" />
            </button>
          </FlexRow>
          <FlexRow className="mb-5">
            <ColorPicker id="color-add-metro" width={30} className="me-2" name="color" color={props.metro?.color} />
            <div className="flex-grow-1">
              Pick Metro Color
            </div>
          </FlexRow>
          {
            user?.isSuperAdmin &&
            <>
              <div className="fs-7 fw-bold">
                Assign to
                <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
              </div>
              <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
            </>
          }

          <TextBox id="txt-addmetro-name" name="name" block label="Name" labelUp marginBottom={3} placeholder="Metro Name" value={props.metro?.name} />

          <TextArea id="txt-metro-descr" name="description" block label="Description" labelUp marginBottom={3} rows={4} placeholder="Write about the metro..." value={props.metro?.description} />

          <DropDown id="drp-addmetro-country" label="Country" name="countryId" apiSource={API_ROUTES.CountriesAllActive}
            parentModal="modal-metro-form" block labelUp marginBottom={3} forceSelectValue={countryId}
            onSelect={val => {
              countryIdSet(val ?? "")
              clearSearchSet(true)
            }}></DropDown>

          <DropDown id="drp-addmetro-state" label="State" name="stateId" source={states}
            parentModal="modal-metro-form" block labelUp marginBottom={5} forceSelectValue={stateId}
            onSelect={val => {
              stateIdSet(val)
              clearSearchSet(true)
            }}></DropDown>

          <Tagify id="tagify-metro-techs" label="Technician(s)" name="techIds" apiUrl={API_ROUTES.TagifyTechnicians}
            parentId="modal-metro-form" labelUp defaultIds={props.metro?.techIds}
            onChange={e => selectedTechsIdsSet(e)} />


          <FlexRow justify="end" className="border-top pt-xxl-6 mt-xxl-6 pt-2 mt-2">
            <button type="button" className="btn btn-secondary btn-sm me-2" disabled={working}
              onClick={e => {
                e.preventDefault();
                window.closeModal("modal-metro-form")
              }}>
              Discard
            </button>
            <button className="btn btn-sm btn-primary" disabled={working}>
              {
                working ?
                  <>
                    <Icon icon="spinner-third" spin />
                    <span className="ms-2">
                      Please Wait
                    </span>
                  </>
                  :
                  <>
                    {props.metro ? "Update Metro" : "Create Metro"}
                  </>
              }
            </button>
          </FlexRow>

        </div>

        <div className="col-md-7">
          <Map id="metro-add-map" defaultMetros build={buildMapAdd} clearSearch={clearSearch}
            countryId={countryId} stateId={stateId} height={700} editMetro={props.metro} search draw
            onLayersUpdated={layers => layersMapSet(layers)}
            center={props.metro?.mapCenter} zoom={props.metro?.mapZoom}
            onZoomChanged={zoom => zoomMapSet(zoom)}
            onCenterChanged={center => centerMapSet(center)}
            onSearchSelected={data => {
              countryIdSet(data.address?.countryId ?? "");
              stateIdSet(data.address?.stateId);
            }} />
        </div>

      </div>
    </Modal>
  )
}