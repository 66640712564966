import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import { DataTable, DataTableCustomActions, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useGlobal, useHub, validateRole } from '../../../../core/Global';
import Icon from "../../../../components/base/Icon";
import Modal from "../../../../components/base/Modal";
import Tagify from "../../../../components/tagify/Tagify";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import Queue from "../models/Queue";
import Indicator from "../../../../components/base/Indicator";
import QueueExtension from "../models/QueueExtension";
import FlexRow from "../../../../components/base/FlexRow";
import Section from "../../../../components/base/Section";
import TextBox from "../../../../components/form/TextBox";
import ModalAssignExtToQueue from "../components/ModalAssignExtToQueue";


function QueueList() {

  const hub = useHub();
  const user = getUser();
  const [globalState, setGlobalState] = useGlobal();


  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [extsModal, extsModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<Queue>();
  const [selectedItems, selectedItemsSet] = useState<Queue[]>([]);
  const [refresh, refreshSet] = useState<boolean>();


  useEffect(() => {

    validateRole("QueuesList");
    setPage("Queues", "Voice", "PBX Modules");


    window.onModalOpen("modal-assign-queue", () => {
      $("#txt-newcustomername").val("");
      $("#txt-newcompany").val("");

    })

  }, [])


  const CompanyItem = (data: Queue) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.company?.name}
      </div>
      {
        data.ownerUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            {data.ownerUser?.fullName}
          </span>
        </div>
      }

    </div>
  )

  const ServerItem = (data: Queue) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.server?.name}
      </div>
      <div className='d-flex align-items-center fs-8 mt-1'>
        <Icon icon="server" className="me-1" size={12} />
        <span>
          {data.server?.description}
        </span>
      </div>
    </div>
  )

  const ExtsItem = (data: Queue) => (
    <FlexRow>
      {
        data.extensions && data.extensions.length > 0 ?
          <>
            {
              data.extensions.map((ext, k) =>
                <span key={k} className="me-1">
                  <span className="badge badge-secondary" title={ext.name}>
                    <Icon icon="phone-office" className="me-2" />
                    {ext.description}
                  </span>
                </span>
              )
            }
          </>
          :
          <>
          </>
      }

      <a href="#" className="me-1"
        onClick={e => {
          e.preventDefault()
          selectedItemSet(data);
          extsModalSet(true);
        }}>
        <FlexRow className="badge badge-light border-dashed border-1" title="Add Extension">
          <Icon icon="plus" size={12} />
        </FlexRow>
      </a>
    </FlexRow>
  )

  const QueueNumberItem = (data: Queue) => (
    <div className='d-flex align-items-center'>
      <Icon icon="queue" className="me-2" size={14} />
      <div className="fs-6 fw-bold">
        {data.queueNumber}
      </div>
    </div>
  )

  const NameItem = (data: Queue) => (
    <div className='d-flex align-items-center'>
      {
        data.name && data.name != "" ?
          <div>
            {data.name}
          </div>
          :
          <div className="text-muted">
            [No Name]
          </div>
      }
      {
        hasRole("QueuesManage") &&
        <a href="#"
          onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }
            selectedItemSet(data);
            editModalSet(true);
          }}>
          <Icon icon="edit" className="ms-2" size={12} />
        </a>
      }

    </div>
  )

  const ExtsAction = (data: Queue) => (
    <>
      {
        hasRole("QueuesExtensions") &&
        <div className="menu-item">
          <a href="#" className="menu-link px-3" onClick={e => {
            e.preventDefault();
            if (user?.limited) {
              window.openModal("modal-user-is-limited");
              return;
            }

            selectedItemSet(data);
            extsModalSet(true);

          }}>
            <Icon icon="phone-office" className="me-2" />
            Extensions
          </a>
        </div>
      }
    </>
  )

  const CreatedItem = (data: Queue) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable objectName="Queue"
          preventCreate={!hasRole("QueuesManage")}
          edit={hasRole("QueuesAdvanced")}
          delete={hasRole("QueuesDelete")}
          addCustom={() => createModalSet(true)}
          route="/voice/pbx/queues"
          refresh={refresh}
          ascending
          apiRoute={API_ROUTES.Queues}
          editText="Advanced Settings"
          editIcon="cog"
          deleteCustom={data => {
            selectedItemsSet([data])
            deleteModalSet(true)
          }}
          deleteMultiple={data => {
            selectedItemsSet(data)
            deleteModalSet(true)
          }}
        >
          <DataTableField title="Queue Number">
            <QueueNumberItem />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title='Company' hidden={!user?.isSuperAdmin}>
            <CompanyItem />
          </DataTableField>
          <DataTableField title="Server" hidden={!user?.isSuperAdmin}>
            <ServerItem />
          </DataTableField>
          <DataTableField title="Extensions">
            <ExtsItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
          <DataTableCustomActions>
            <ExtsAction />
          </DataTableCustomActions>
        </DataTable>
      </Section>


      {
        createModal &&
        <Modal id="modal-add-queue"
          title="Create Queue"
          buttonText="Add Queue"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          onOpen={() => $("#txt-queuename").focus()}
          onSubmit={(body, callback) => {
            if (globalState.user?.isSuperAdmin) {
              if (!body.companyId || body.companyId == "") {
                window.showFormError("company", "Please choose a company!");
                if (callback) callback();
                return;
              }
            }
            if (!body.queueName || body.queueName == "" || body.queueName.length < 2) {
              window.showFormError("queueName", "Please write a queue name!");
              if (callback) callback();
              return;
            }
            hub.Post(API_ROUTES.Queues, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Queue has been added successfully!");
              }
              if (callback) callback(res);
            })
          }}>
          <div>
            {
              (globalState.user?.isSuperAdmin) &&
              <>
                <Tagify name="companyId" id="pickCompany" placeholder="Select a Company" marginBottom={5}
                  label="Company" labelUp apiUrl={API_ROUTES.TagifyCompaneis} single block />
              </>
            }

            <TextBox id="txt-queuename" name="queueName" label="Display Name" placeholder="E.g. Support, Sales Inquiry, Executive Hotline, etc" labelUp block />

          </div>
        </Modal>
      }

      {
        editModal && selectedItem &&
        <Modal id="modal-rename-queue"
          title="Queue Name"
          staticBackdrop open visible
          buttonText="Update Name"
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onOpen={() => $("#txt-edit-queuename").focus()}
          onSubmit={(body, callback) => {
            if (!body.queuereName || body.queuereName == "" || body.queuereName.length < 2) {
              window.showFormError("queuereName", "Please write a queue name!");
              if (callback) callback();
              return;
            }
            hub.Post("/system/queues/rename", {
              ...body,
              id: selectedItem.id
            }, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Queue name has been updated successfully!");
              }
              if (callback) callback(res);
            })
          }}>
          <div>
            <TextBox id="txt-edit-queuename" name="queuereName" label="Display Name" labelUp block value={selectedItem.name} />
          </div>
        </Modal>
      }

      {
        extsModal && selectedItem &&
        <ModalAssignExtToQueue queue={selectedItem}
          onClose={() => {
            extsModalSet(false);
            selectedItemSet(undefined);
          }}
          onUpdate={() => refreshSet(true)} />
      }


      {
        deleteModal && selectedItems.length > 0 &&
        <Modal id="modal-delete-extension"
          title={"Delete Extension"}
          open visible staticBackdrop
          buttonText="Delete Extension"
          buttonColor="danger"
          onClose={() => {
            deleteModalSet(false)
            selectedItemSet(undefined)
          }}
          onConfirm={callback => {
            var ids: string[] = [];
            selectedItems.forEach(e => ids.push(e.id!));
            hub.Post(API_ROUTES.Queues + "delete", ids, res => {
              if (res.success) {
                toastSuccess("Selected Queue(s) has been deleted successfully!");
              }
              refreshSet(true)
              callback()
            })
          }}>
          <div className="py-5">
            Are you sure you want to delete the selected queue{selectedItems.length > 1 ? "s" : ""}?
          </div>
          <FlexRow className="mb-10">
            {
              selectedItems.map((ex, key) =>
                <span key={key} className="badge badge-light-danger me-2">
                  <Icon icon="phone-office" className="me-2" size={12} />
                  <span className="fs-7 fw-bold">
                    {ex.queueNumber}
                  </span>
                </span>
              )
            }
          </FlexRow>
        </Modal>
      }



    </Layout>
  );
}

export default QueueList;
