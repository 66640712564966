import { useEffect, useState } from "react";
import JobNote from "../models/JobNote";
import { useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import NoteItem from "./NoteItem";
import Indicator from "../../../../components/base/Indicator";


type Iprops = {
    id: string
    jobId?: string
    noteType?: string
    height?: number
    refresh?: boolean
    className?: string
    onReplyItemSet?: (replyItem: JobNote) => void
}

export default function NotesContainer(props: Iprops) {

    const hub = useHub();

    const [notesFetching, notesFetchingSet] = useState<boolean>(false);
    const [notes, notesSet] = useState<JobNote[]>([]);
    const [replyItem, replyItemSet] = useState<JobNote>();

    const fetchNotes = () => {
        var q = "";
        if (props.noteType) q = "?type=" + props.noteType;
        hub.Get(API_ROUTES.Jobs + "notes/" + props.jobId + "/" + q, res => {
            if (res.success) {
                notesSet(res.data);
                notesFetchingSet(false);
            }
        })
    }

    useEffect(() => {
        if (notes && notes.length > 0) {
            var d = $('#' + props.id);
            d.scrollTop(0);
        }
    }, [notes])

    useEffect(() => {

        if (props.jobId) {
            notesFetchingSet(true);
            fetchNotes();
        }

    }, [props.jobId, props.noteType])

    useEffect(() => {
        if (props.refresh) fetchNotes();
    }, [props.refresh])

    return (
        <div id={props.id} className={"scroll-y " + props.className} style={{
            height: props.height,
            overflowX: "hidden"
        }}>

            {
                notesFetching ?
                    <div className="text-center py-10">
                        <Indicator />
                    </div>
                    :
                    <>
                        {
                            notes.length == 0 &&
                            <div className="text-center py-10">
                                There is no note for this job!
                            </div>
                        }
                        {
                            notes.map((item, k) =>
                                <NoteItem key={"note-item-" + item.id} note={item}
                                    parent={("#" + props.id)}
                                    onReply={note => {
                                        replyItemSet(note);
                                        if (props.onReplyItemSet) props.onReplyItemSet(note);
                                    }} />)
                        }
                    </>
            }

        </div>
    )
}