import Avatar from "../../../../components/base/Avatar";
import Icon from "../../../../components/base/Icon";
import Timer from "../../../../components/timer/Timer";
import { CallTypes } from "../../../../core/Enums";
import { pauseGlobalPlayer, playCall, playGlobalPlayer, setCallToListen, setChanSpyLine, shareCalls, useGlobal } from "../../../../core/Global";
import Environment from "../../../../env/env";
import Call from "../models/Call";

type ProfileHeaderProps = {
    selectPage: (page: string) => void
    selectedPage?: string
    model?: Call
}

function CallHeader(props: ProfileHeaderProps) {

    const [globalState] = useGlobal();
    const env = new Environment();

    return (
        <div className="card mb-5">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div className="me-7 mb-4">
                        <div className="symbol symbol-75px symbol-fixed position-relative">
                            {
                                props.model?.callType == CallTypes.Incoming ? 
                                <img src={(props.model?.sourceAgent?.country ? props.model?.sourceAgent?.country.flagSquare : props.model?.sourceAgent?.avatarUrl) ?? "/assets/media/avatars/blank.png"} />    
                                :
                                <img src={(props.model?.destinationAgent?.country ? props.model?.destinationAgent?.country.flagSquare : props.model?.destinationAgent?.avatarUrl) ?? "/assets/media/avatars/blank.png"} />
                            }
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="text-gray-900 fs-2 fw-bolder me-3">
                                        {
                                            props.model?.callType == CallTypes.Incoming ? 
                                            <>{props.model.source}</>
                                            :
                                            <>{props.model?.destination}</>
                                        }
                                        
                                    </div>
                                    {
                                        props.model?.isLive ? 
                                        <span className="badge badge-danger d-flex align-items-center">
                                            <Icon icon="circle" type="solid" className="me-2 animation-blink" size={8} />
                                            <div className="d-flex align-items-center" style={{marginTop: 2}}>
                                                <span className="me-2">
                                                    Live Call
                                                </span>
                                                |
                                                <span className="ms-2">
                                                    <Timer totalSeconds={props.model.talkingSeconds} />
                                                </span>
                                            </div>
                                        </span>
                                        :
                                        props.model?.disPosition == "ANSWERED" ? 
                                        <span className="badge badge-success d-flex align-items-center">
                                            <Icon icon="phone-volume" type="solid" className="me-2" size={11} />
                                            <div className="d-flex align-items-center" style={{marginTop: 2}}>
                                                <span className="me-2">
                                                    {props.model?.disPosition}
                                                </span>
                                                |
                                                <span className="ms-2">
                                                    {props.model?.billSecStr}
                                                </span>
                                            </div>
                                        </span>
                                        : (props.model?.disPosition == "BUSY" || props.model?.disPosition == "NO ANSWER") ? 
                                        <span className="badge badge-danger">
                                            <Icon icon="phone-missed" type="solid" className="me-2" size={11} />
                                            {props.model?.disPosition}
                                        </span>
                                        :
                                        <span className="badge badge-secondary">
                                            <Icon icon="phone-xmark" type="solid" className="me-2" size={11} />
                                            {props.model?.disPosition}
                                        </span>
                                    }

                                    <span className="badge badge-light-primary ms-2">
                                        RING TIME | {props.model?.ringSecStr}
                                    </span>

                                </div>
                                <div className="d-flex align-items-center">

                                        {
                                            props.model?.callType == CallTypes.Incoming ? 
                                            <span className="badge badge-light-success">
                                                <Icon icon='phone-arrow-down-left' type='solid' className="text-success me-2" size={12} title="Incoming Call" />
                                                <span className="fs-8">Incoming</span>
                                            </span>
                                            : props.model?.callType == CallTypes.Outgoing ? 
                                            <span className="badge badge-light-primary">
                                                <Icon icon='phone-arrow-up-right' type='solid' className="text-primary me-2" size={12} title="Outgoing Call" />
                                                <span className="fs-8">Outgoing</span>
                                            </span>
                                            :
                                            <span className="badge badge-light-info">
                                                <Icon icon='user-headset' type='solid' className="text-info me-2" size={12} title="Local Call" />
                                                <div className="fs-8">Local Call</div>
                                            </span>
                                        }

                                    
                                    <div className="d-flex align-items-center me-5">
                                        {
                                            props.model?.callType == CallTypes.Incoming ?
                                            <>
                                                <Avatar width={20} avatarUrl={props.model?.destinationAgent?.avatarUrl} />
                                                <div className="ms-1 fs-8">{props.model.destinationAgent?.name}</div>
                                            </>
                                            :
                                            <>
                                                <Avatar width={20} avatarUrl={props.model?.sourceAgent?.avatarUrl} />
                                                <div className="ms-1 fs-8">{props.model?.sourceAgent?.name}</div>
                                            </>
                                        }
                                    </div>

                                    <div className="d-flex align-items-center me-5" title="Extension">
                                        <Icon icon='phone-office' type='solid' className="me-1" size={12} />
                                        {
                                            props.model?.callType == CallTypes.Incoming ?
                                            <div className="fs-8">
                                                {props.model.destinationAgent?.number}
                                            </div>
                                            :
                                            <div className="fs-8">
                                                {props.model?.sourceAgent?.number}
                                            </div>
                                        }
                                    </div>

                                    <div className="d-flex align-items-center me-5" title="Company">
                                        <Icon icon='briefcase' type='solid' className="me-1" size={12} />
                                        <div className="fs-8">{props.model?.companyName}</div>
                                    </div>

                                    {
                                        props.model?.queueName &&
                                        <div className="d-flex align-items-center me-5" title="Queue">
                                            <Icon icon='arrow-right-to-line' type='solid' className="me-1" size={12} />
                                            <div className="fs-8">{props.model?.queueName}</div>
                                        </div>
                                    }

                                    <div className="d-flex align-items-center me-5" title="Linked ID">
                                        <Icon icon='info-circle' type='solid' className="me-1" size={12} />
                                        <div className="fs-8">{props.model?.linkedId}</div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center">
                                    
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center ms-auto">

                                    {
                                        props.model?.isLive &&
                                        <>
                                            <span className={"badge badge"+(globalState.selectedCallToListen?.id == props.model.id ? "" : "-light")+"-primary cursor-pointer"}
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    if (!props.model?.id) return;
                                                    var li = window.DialByLine('audio', undefined, '551' + props.model.extNumber);
                                                    setChanSpyLine(li);
                                                    setCallToListen(props.model);
                                                }}>
                                                <Icon icon="volume" type="solid" className="me-2" size={12} />
                                                {(globalState.selectedCallToListen?.id == props.model.id ? "Listening" : "Listen To Call")}
                                            </span>
                                        </>
                                    }

                                    {
                                        !props.model?.isLive && props.model?.disPosition == "ANSWERED" &&
                                        <>
                                            <span className={"badge "+(globalState.selectedCallToPlay?.id == props.model.id ? "badge-primary" : "badge-light-primary")+" cursor-pointer"}
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    if (!props.model?.id) return;
                                                    if (globalState.selectedCallToPlay?.id == props.model.id) {
                                                        if (globalState.floatAudioStatus?.playing) pauseGlobalPlayer();
                                                        else playGlobalPlayer();
                                                    }
                                                    else {
                                                        playCall(props.model);
                                                    }
                                                }}>
                                                <Icon icon={((globalState.selectedCallToPlay?.id == props.model.id && globalState.floatAudioStatus?.playing) ? "pause" : "play")} type='solid' className="me-2" size={12} />
                                                {((globalState.selectedCallToPlay?.id == props.model.id && globalState.floatAudioStatus?.playing) ? "Playing Recording" : "Play Recording")}
                                            </span>

                                            <a href={env.localHubUrl + "/system/calls/recording/" + props.model?.id} target="_blank" className="badge badge-light-dark ms-2">
                                                <Icon icon='download' type='solid' className="me-2" size={12} />
                                                Download
                                            </a>

                                        </>
                                    }
                                    
                                    {
                                        !props.model?.isLive &&
                                        <a href="#" className="badge badge-light-dark ms-2"
                                            onClick={e=> {
                                                e.preventDefault();
                                                if (props.model?.id) shareCalls([props.model?.id]);
                                            }}>
                                            <Icon icon='share' type='solid' className="me-2" size={12} />
                                            Share
                                        </a>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                    {/* <li className="nav-item mt-2">
                        <Link className={"nav-link text-active-primary ms-0 me-10 py-5" + (props.selectedPage == "overview" ? " active" : "")} to="/profile/overview">Overview</Link>
                    </li> */}
                    <li className="nav-item mt-2">
                        <a href="#" className={"nav-link text-active-primary ms-0 me-10 py-5" + (props.selectedPage == "history" ? " active" : "")}
                            onClick={e=> {
                                e.preventDefault();
                                props.selectPage("history");
                            }}>Calls History</a>
                    </li>
                    <li className="nav-item mt-2">
                        <a href="#" className={"nav-link text-active-primary ms-0 me-10 py-5" + (props.selectedPage == "path" ? " active" : "")}
                            onClick={e=> {
                                e.preventDefault();
                                props.selectPage("path");
                            }}>Call Path</a>
                    </li>
                    <li className="nav-item mt-2">
                        <a href="#" className={"nav-link text-active-primary ms-0 me-10 py-5" + (props.selectedPage == "cdr" ? " active" : "")}
                            onClick={e=> {
                                e.preventDefault();
                                props.selectPage("cdr");
                            }}>CDR Data</a>
                    </li>
                    <li className="nav-item mt-2">
                        <a href="#" className={"nav-link text-active-primary ms-0 me-10 py-5" + (props.selectedPage == "cel" ? " active" : "")}
                            onClick={e=> {
                                e.preventDefault();
                                props.selectPage("cel");
                            }}>Cahnnel Events</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CallHeader;