import React from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Icon from "../base/Icon";
import { BootstrapColors, FontAwesomeIcons } from '../../core/Types';


interface ButtonProps {
    id?: string
    color?: BootstrapColors
    customColor?: string
    type?: "light" | "bg" | "outline" | "default",
    isSubmit?: boolean
    text?: string
    icon?: FontAwesomeIcons
    className?: string
    modal?: string
    modalDismiss?: boolean
    isRunning?: boolean
    runningText?: string
    children?: any
    large?: boolean
    visible?: boolean
    to?: string
    tooltip?: string
    disabled?: boolean
    onClick?: () => void
}



function Button(props: ButtonProps) {
    const navigate = useNavigate();

    const createClassName = () => {
        var className = "btn ";
        className += "btn-" + (props.type && props.type != "default" ? props.type + "-" : "") + (props.color ? props.color : "primary");
        if (props.type && props.type == "outline") className += " btn-outline btn-active-light-primary";
        if (!props.large) className += " btn-sm";
        if ((!props.text || props.text == "") && props.icon) className += " btn-icon";
        if (props.className) className += " " + props.className;
        return className;
    }

    return (
        <>
            {
                (props.visible == undefined || props.visible) &&
                <button id={props.id} className={createClassName()}
                    // onMouseDown={e=>e.preventDefault()}
                    onClick={e => {
                        if (props.onClick) props.onClick();
                        if (props.to) navigate(props.to);
                    }}
                    disabled={props.isRunning || props.disabled}
                    title={props.tooltip}
                    type={props.isSubmit ? "submit" : "button"}
                    {...(props.modal ? { 'data-bs-toggle': 'modal', 'data-bs-target': '#' + props.modal } : {})}
                    {...(props.modalDismiss ? { 'data-bs-dismiss': 'modal' } : {})}
                >
                    {
                        props.isRunning &&
                        <span className="indicator-label">
                            <span className="spinner-border spinner-border-sm align-middle me-2"></span>
                            {props.runningText ? props.runningText : "Please wait..."}
                        </span>
                    }
                    {
                        !props.isRunning &&
                        <>
                            {
                                props.icon && <Icon icon={props.icon} />
                            }
                            {
                                props.text && <span>{props.text}</span>
                            }
                        </>
                    }

                </button>
            }
        </>

    )
}

export default Button;