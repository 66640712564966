import CRMDefaultRates from "../components/CRMDefaultRates";
import CRMJobBoardSettings from "../components/CRMJobBoardSettings";
import SendJobSettings from "../components/SendJobSettings";



export default function JobsSettingsView() {


    return (
        <>
            <CRMJobBoardSettings />
            <CRMDefaultRates />
        </>
    )
}