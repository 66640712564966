import { useEffect, useState } from "react";
import { useHub } from "../../../../core/Global"
import Job from "../models/Job";
import Indicator from "../../../../components/base/Indicator";
import Icon from "../../../../components/base/Icon";
import Button from "../../../../components/base/Button";
import Modal from "../../../../components/base/Modal";
import DropDown from "../../../../components/form/DropDown";
import { API_ROUTES } from "../../../../core/Constants";
import { DataTable, DataTableCustomActions, DataTableField } from "../../../../components/data/DataTable";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import JobInvoice from "../models/JobInvoice";
import FlexRow from "../../../../components/base/FlexRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextBox from "../../../../components/form/TextBox";
import PhoneInput from "../../../../components/form/PhoneInput";


interface IProps {
    job: Job
}

export default function JobEstimates(props: IProps) {


    const nav = useNavigate();

    const hub = useHub();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [loading, loadingSet] = useState<boolean>(false);

    const [createEstimate, createEstimateSet] = useState<boolean>(false);

    const [refresh, refreshSet] = useState<boolean>();
    useEffect(() => {
        if (refresh) {
            selectedInvSet(undefined);
            selectedIdsSet(undefined);
            setTimeout(() => {
                refreshSet(false);
            }, 1000);

        }
    }, [refresh])


    const NoRowComp = () => (
        <div>
            <div className="mb-5 text-center">
                <img src="/assets/media/illustrations/sketchy-1/4.png" alt="" className="mw-100 h-200px h-sm-325px" />
            </div>
            <div className="text-center fs-5 fw-bold">
                There is no estimate for this job!
            </div>
        </div>
    )

    const InvNumberItem = (data: JobInvoice) => (
        <div>
            <Link to={`/crm/jobs/estimates/${props.job.id}/view/${data.id}`}>
                {data.number}
            </Link>
        </div>
    )

    const InvTotal = (data: JobInvoice) => (
        <div>
            {moneyFormatter.format(data.total ?? 0)}
        </div>
    )

    const TechItem = (data: JobInvoice) => (
        <div>
            <div>
                {data.tech?.fullName}
            </div>
            <FlexRow className='fs-8 mt-1'>
                <div>
                    <Icon icon="phone" className="me-2" size={10} type="solid" />
                </div>
                <div className="fs-8">
                    {data.tech?.phoneNumber}
                </div>
            </FlexRow>
        </div>
    )


    const CreatedItem = (data: JobInvoice) => (
        <div>
            <div className='d-flex align-items-center'>
                {data.createDate}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    const StatusItem = (data: JobInvoice) => (
        <div>
            {
                data.lastSend &&
                <span className="badge badge-light-primary fw-normal me-1">
                    <Icon icon="share" className="me-2" />
                    Sent: {data.lastSend.sendDate}
                </span>
            }
            {
                data.confirmed &&
                <span className="badge badge-light-success fw-normal me-1">
                    <Icon icon="check" className="me-2" />
                    Confirmed: {data.confirmDate}
                </span>
            }
        </div>
    )

    const CreateInvAction = (data: JobInvoice) => (
        <>
            {
                data.confirmed ?
                    <div className="menu-item">
                        <a href="#" className="menu-link px-3"
                            onClick={e => {
                                e.preventDefault();
                                if (true) {
                                    selectedInvSet(data);
                                    estToInvModalSet(true);
                                }
                                else estToInvErrModalSet(true)
                            }}>
                            <Icon icon="file-invoice-dollar" className="me-2" />
                            Create Invoice
                        </a>
                    </div>
                    : <></>
            }
        </>
    )



    const [selectedInv, selectedInvSet] = useState<JobInvoice>();
    const [selectedIds, selectedIdsSet] = useState<string[]>();

    const [sendModal, sendModalSet] = useState<boolean>(false);
    const [confirmModal, confirmModalSet] = useState<boolean>(false);
    const [undoConfirmModal, undoConfirmModalSet] = useState<boolean>(false);

    const [estToInvModal, estToInvModalSet] = useState<boolean>(false);
    const [estToInvErrModal, estToInvErrModalSet] = useState<boolean>(false);

    const [sendTo, sendToSet] = useState<"email" | "phone">("email")

    return (
        <div>

            <DataTable apiRoute={API_ROUTES.JobInvoices}
                objectName="Estimate" actionsOnCard
                customNoRowComp={NoRowComp} refresh={refresh}
                extraParams={[
                    {
                        name: "jobId",
                        value: props.job.id
                    },
                    {
                        name: "estimates",
                        value: "True"
                    }
                ]}
                addCustom={() => createEstimateSet(true)}
                delete edit noFilter
                editCustom={data => {
                    nav(`/crm/jobs/estimates/${props.job.id}/view/${data.id}`);
                }}

                onSend={(inv: JobInvoice) => {
                    selectedIdsSet(undefined);
                    selectedInvSet(inv);
                    sendModalSet(true);
                }}
                onGroupSend={ids => {
                    selectedIdsSet(ids);
                    selectedInvSet(undefined);
                    sendModalSet(true);
                }}
                onConfirm={(inv: JobInvoice) => {
                    selectedIdsSet(undefined);
                    selectedInvSet(inv);
                    confirmModalSet(true);
                }}
                onUndoConfirm={(inv: JobInvoice) => {
                    selectedIdsSet(undefined);
                    selectedInvSet(inv);
                    undoConfirmModalSet(true);
                }}>

                <DataTableField title="EST Num">
                    <InvNumberItem />
                </DataTableField>
                <DataTableField title="Technician">
                    <TechItem />
                </DataTableField>
                <DataTableField title="Total">
                    <InvTotal />
                </DataTableField>
                <DataTableField title="Created">
                    <CreatedItem />
                </DataTableField>
                <DataTableField title="">
                    <StatusItem />
                </DataTableField>
                <DataTableCustomActions>
                    <CreateInvAction />
                </DataTableCustomActions>
            </DataTable>


            {
                createEstimate &&
                <Modal id="modal-create-estimate"
                    title="Create Estimate"
                    open visible staticBackdrop
                    onClose={() => createEstimateSet(false)}
                    buttonText="Create Estimate"
                    onSubmit={(body, callback) => {

                        var valid = true;

                        if (window.isNullOrEmpty(body.techId)) {
                            window.formError("techId", "Please select the technician!", "modal-create-estimate");
                            valid = false;
                        }

                        if (!valid) {
                            if (callback) callback();
                            return;
                        }

                        hub.Post(API_ROUTES.JobInvoices + "estimate", {
                            ...body,
                            jobId: props.job.id
                        }, res => {
                            if (res.success) {
                                if (callback) callback(res);
                                nav(`/crm/jobs/estimates/${props.job.id}/view/${res.data.id}`)
                            }
                            else toastError(res.message);

                            if (callback) callback(res);
                        });

                    }}>
                    <div>
                        <DropDown id="drp-techs-for-estimates" name="techId" apiSource={API_ROUTES.TagifyTechnicians} defaultValue={props.job.techId}
                            dataText="title" dataValue="value" label="Technician" labelUp parentModal="modal-create-estimate" />
                    </div>
                </Modal>
            }

            {
                sendModal &&
                <Modal id="modal-send-invoice"
                    title={selectedIds ? "Send Selected Estimates" : ("Send Estimate " + selectedInv?.number)}
                    buttonText="Send"
                    buttonColor="success"
                    open visible staticBackdrop
                    onClose={() => {
                        sendModalSet(false);
                        selectedInvSet(undefined);
                        selectedIdsSet(undefined);
                    }}
                    onSubmit={(body, callback) => {
                        var ids = "";
                        if (selectedInv) ids = selectedInv.id ?? "";
                        selectedIds?.forEach(id => {
                            if (ids != "") ids += ",";
                            ids += id;
                        });

                        hub.Post(API_ROUTES.JobInvoices + "sendinvoice", {
                            ...body,
                            jobId: props.job.id,
                            sendTo: sendTo,
                            ids
                        }, res => {
                            callback!(res);
                            refreshSet(true);
                        })
                    }}>

                    <div>
                        <FlexRow className="mb-5">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start me-5 px-6 py-4 ${(sendTo == "email" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "email"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("email")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Email
                                    </span>
                                </span>
                            </label>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-6 py-4 ${(sendTo == "phone" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "phone"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("phone")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Phone
                                    </span>
                                </span>
                            </label>
                        </FlexRow>

                        {
                            sendTo == "email" &&
                            <TextBox id="txt-invoice-send-email" key="txt-invoice-send-email" name="emails" email label="Email Address"
                                labelUp block autoFocus value={props.job.emailAddress} />
                        }

                        {
                            sendTo == "phone" &&
                            <PhoneInput id="txt-invoice-send-phone" key="txt-invoice-send-phone" name="phone" label="Phone Number"
                                labelUp block autoFocus defaultPhone={props.job.phone} />
                        }
                    </div>

                </Modal>
            }

            {
                confirmModal &&
                <Modal id="modal-confirm-invoice"
                    title={selectedIds ? "Confirm Selected Estimates" : ("Confirm Estimate " + selectedInv?.number)}
                    buttonText="Confirm"
                    buttonColor="success"
                    open visible staticBackdrop
                    onClose={() => {
                        confirmModalSet(false);
                        selectedInvSet(undefined);
                        selectedIdsSet(undefined);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.JobInvoices + "confirmestimate", {
                            ...body,
                            jobId: props.job.id,
                            id: selectedInv?.id
                        }, res => {
                            callback!(res);
                            refreshSet(true);
                        })
                    }}>

                    <div>
                        Are you sure you want to confirm the selected estimates?
                    </div>

                </Modal>
            }

            {
                undoConfirmModal &&
                <Modal id="modal-undoconfirm-invoice"
                    title={selectedIds ? "Undo Confirm Selected Estimates" : ("Undo Confirm Estimate " + selectedInv?.number)}
                    buttonText="Undo Confirm"
                    buttonColor="warning"
                    open visible staticBackdrop
                    onClose={() => {
                        undoConfirmModalSet(false);
                        selectedInvSet(undefined);
                        selectedIdsSet(undefined);
                    }}
                    onSubmit={(body, callback) => {
                        var ids = "";
                        if (selectedInv) ids = selectedInv.id ?? "";
                        selectedIds?.forEach(id => {
                            if (ids != "") ids += ",";
                            ids += id;
                        });

                        hub.Post(API_ROUTES.JobInvoices + "undoconfirmestimate", {
                            ...body,
                            jobId: props.job.id,
                            ids
                        }, res => {
                            callback!(res);
                            refreshSet(true);
                        })
                    }}>

                    <div>
                        Are you sure you want to unconfirm the selected estimates?
                    </div>

                </Modal>
            }

            {
                estToInvErrModal &&
                <Modal id="modal-estto-invoice-err"
                    title="Create Invoice"
                    open visible
                    onClose={() => {
                        estToInvErrModalSet(false);
                    }}
                    cancelButton cancelText="Ok">

                    <div className="alert alert-warning">
                        You should close the job before creating invoice!
                    </div>

                </Modal>
            }

            {
                estToInvModal &&
                <Modal id="modal-estimate-to-invoice"
                    title={`Create Invoice`}
                    buttonText={`Create Invoice`}
                    buttonColor="success"
                    open visible
                    onClose={() => {
                        estToInvModalSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.JobInvoices + "esttoinv", {
                            id: selectedInv?.id
                        }, res => {
                            callback!(res);
                            if (res.success) {
                                nav("/crm/jobs/invoices/" + props.job.id + "/view/" + res.data.id);
                            }
                        })

                    }}>

                    <div>
                        Do you want to create an invoice based on the selected estimate?
                    </div>

                </Modal>
            }



        </div>
    )
}