import React, { useEffect, useState } from "react"
import "./GeneralSearch.css"
import { isDarkMode, useHub } from "../../core/Global";
import SearchItem from "../../models/SearchItem";
import Indicator from "../base/Indicator";
import { API_ROUTES } from "../../core/Constants";
import SearchJobItem from "./SearchJobItem";
import Icon from "../base/Icon";
function GeneralSearch() {

    const hub = useHub()

    const [searching, searchingSet] = useState<boolean>(false)
    const [data, dataSet] = useState<SearchItem[]>([])
    const [keyword, keywordSet] = useState<string>("")

    useEffect(() => {

        if (keyword.length > 2) {
            searchingSet(true)
            let searchTimout = setTimeout(() => {
                hub.Post(API_ROUTES.Search, keyword, res => {
                    if (res.data) {
                        dataSet(res.data)
                        searchingSet(false)
                    }
                })
            }, 500);

            return () => {
                clearTimeout(searchTimout)
            }

        }
        else {
            dataSet([])
            searchingSet(false)
        }
    }, [keyword])

    useEffect(() => {
        if (data.length > 0) window.dataTableMenu();
    }, [data])

    return (
        <div className="d-none d-md-block" style={{
            width: 500
        }}>
            <div className="w-100 position-relative">
                <input type="hidden" />
                <span className="svg-icon svg-icon-2 search-icon position-absolute top-50 translate-middle-y ms-4">
                    <Icon icon="search" size={18} />
                </span>
                <input type="text" className={`search-input form-control form-control-solid ps-13 fs-7 h-40px`}
                    placeholder="Search for jobs..." value={keyword} onChange={e => keywordSet(e.target.value)}
                />

                {
                    searching &&
                    <span className="position-absolute top-50 end-0 translate-middle-y lh-0 me-5" data-kt-search-element="spinner">
                        <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                    </span>
                }

                {
                    !searching && keyword.length > 2 &&
                    <span className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-4"
                        onClick={e => {
                            e.preventDefault()
                            searchingSet(false)
                            keywordSet("")
                            dataSet([])
                        }}>

                        <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                            </svg>
                        </span>

                    </span>
                }

                {
                    data.length > 0 &&
                    <div className="general-search-container scroll-y">
                        {
                            data.map((s, k) =>
                                <div key={k} className="search-item-container">
                                    {
                                        s.job && <SearchJobItem job={s.job} onOpenJob={() => keywordSet("")} />
                                    }
                                </div>
                            )
                        }
                    </div>
                }

            </div>
        </div>
    );
}

export default GeneralSearch;
