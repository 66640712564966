import { Route, Routes } from "react-router-dom";
import SharedView from "../views/SharedView";
import JobAcceptView from "../views/JobAcceptView";
import JobCloseView from "../views/JobCloseView";
import InvoiceView from "../views/InvoiceView";

function PublicRoutes() {
    return (
        <Routes>
            <Route path="/share/acceptjobbytech/:token" element={<JobAcceptView />} />
            <Route path="/share/closejobbytech/:token" element={<JobCloseView />} />
            <Route path="/share/invoice/:id/:token" element={<InvoiceView />} />
            <Route path="/share/:selectedId" element={<SharedView />} />
        </Routes>
    );
}

export default PublicRoutes;