import { useEffect, useState } from "react";
import { toastSuccess } from "../../../components/base/Toast";
import Form from "../../../components/form/Form";
import AddressField from "../../../components/here/AddressField";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import User from "../models/User"
import { HereGeocodeItem, HereGeocodePosition } from "../../../models/MapModels";
import DropDown from "../../../components/form/DropDown";
import Map from "../../../components/here/Map";
import JobMetro from "../../crm/base/models/JobMetro";
import Icon from "../../../components/base/Icon";


type Props = {
    userId?: string
    model?: User
    onUpdate?: (user: User) => void
 }

export default function AccountTechAddressMetro(props: Props) {

    const hub = useHub();

    const [working, workingSet] = useState<boolean>(false);
    
    const [mapZoom, mapZoomSet] = useState<number>(12)
    const [selectedAddress, selectedAddressSet] = useState<HereGeocodeItem>();
    const [selectedCountry, selectedCountrySet] = useState<string>();
    const [metrosClear, metrosClearSet] = useState<boolean>(false);

    useEffect(() => {
        if(metrosClear) {
            setTimeout(() => {
                metrosClearSet(false);
            }, 1000);
        }
    }, [metrosClear])


    useEffect(() => {
        if (props.model) {
            selectedAddressSet(props.model.techWakeupAddress)
            mapZoomSet(props.model.techWakeupAddress?.zoom ?? 12)
        }
    }, [props.model])



    return(
        <Form id="user-techaddressmetro-form" className="form"
            onSubmit={(body, callback) => {

                if(window.isNullOrEmpty(selectedAddress?.id)) {
                    window.formError("technicianAddress", "Please write a valid address!", "user-techaddressmetro-form");
                    return;
                }

                workingSet(true);

                hub.Put("/accounts/technicians/addressmetro", {
                    ...body,
                    id: props.userId,
                    techAddressId: selectedAddress?.id,
                    zoom: mapZoom
                }, res => {
                    if (res.success) {
                        toastSuccess("Technician details has been updated successfully!")
                        if (props.onUpdate) props.onUpdate(res.data)
                    }
                    workingSet(false);
                })
            }}>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">
                                Address & Metro
                            </h3>
                        </div>
                        <div className="card-action">
                            <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                                {
                                    working ? 
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    :
                                    <>
                                        Update Technician
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                    
                        <div className="card-body border-top p-9"
                            style={{
                                maxWidth: 1000
                            }}>


                            <Tagify id="tagify-tech-metros" name="techMetroIds" label="Metro" clear={metrosClear}
                                marginBottom={5} block selectedIds={props.model?.techMetroIds}
                                apiUrl={API_ROUTES.TagifyMetros} />


                            <AddressField id="txt-tech-address" label="Wake-Up Address" name="technicianAddress" block
                                selectedAddress={selectedAddress}
                                onCountrySelect={c=> {
                                    selectedCountrySet(c.id)
                                    selectedAddressSet(undefined)
                                }}
                                onSelect={data => selectedAddressSet(data)} />


                            <div className={"row align-items-center mb-5"}>
                                <label className={"col col-12 col-md-3 fw-bold fs-7"}>
                                    Wake-Up Location
                                </label>
                                <div className={"col col-12 col-md-9"}>
                                    <Map id="map-tech-form" height={300}
                                        draggableMarker={selectedAddress?.position}
                                        draggableMarkerIcon="tech"
                                        zoom={selectedAddress?.zoom}
                                        countryId={selectedCountry}
                                        onZoomChanged={z=> mapZoomSet(z)}
                                        onMarkerUpdated={e => selectedAddressSet(e)} />
                                </div>
                            </div>

                        </div>
                    
                </div>
        </Form>
    )
}