import CallsList from "../../../components/calls/CallsList";

type AgentProps = {
  agentId?: string
}

function AgentCallsList(props: AgentProps) {


  return (
    <>
      <div className="row">
          <div className="col-12">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body border-top p-9">
                  <CallsList agentId={props.agentId} />
              </div>
            </div>
          </div>
      </div>


    </>
  );
}

export default AgentCallsList;
