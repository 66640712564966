import { useEffect, useState } from "react"
import { useHub, usePreferences } from "../../../../core/Global"
import { API_ROUTES } from "../../../../core/Constants"
import Country from "../../../base/models/Country"
import ToggleSwitch from "../../../../components/form/ToggleSwitch"
import FlexRow from "../../../../components/base/FlexRow"
import Avatar from "../../../../components/base/Avatar"
import Icon from "../../../../components/base/Icon"
import Form from "../../../../components/form/Form"
import { toastSuccess } from "../../../../components/base/Toast"




export default function SystemActivateCountries() {

    const hub = useHub()
    const pref = usePreferences()
    const [working, workingSet] = useState<boolean>(false)
    const [countries, countriesSet] = useState<Country[]>()

    useEffect(() => {
        hub.Get(API_ROUTES.CountriesAllActive, res => {
            countriesSet(res.data)
        })
    }, [])


    return (
        <Form className="form"
            onSubmit={(body, callback) => {
                workingSet(true);
                hub.Post("/system/Settings/systempreferences", body, res => {
                    if (res.success) {
                        toastSuccess("Active countries has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Active Countries
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Preferences</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    {
                        countries?.map(c =>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <FlexRow>
                                        <Avatar avatarUrl={c.flagSquare} width={20} className="me-2" />
                                        <div>
                                            {c.name}
                                        </div>
                                    </FlexRow>
                                </div>
                                <div className="col-md-6">
                                    <ToggleSwitch name="countries" raw small className="me-5" />
                                </div>
                            </div>
                        )
                    }


                </div>
            </div>
        </Form>
    )
}