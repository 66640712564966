import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../../api/Hub";
import Icon from "../../../../components/base/Icon";
import { toastError } from "../../../../components/base/Toast";
import Form from "../../../../components/form/Form";
import FormTitle from "../../../../components/form/FormTitle";
import Layout from "../../../../components/layouts/Layout";
import { API_ROUTES } from "../../../../core/Constants";
import HUBResponse from "../../../../models/HUBResponse";
import { TagifyDataItem } from "../../../../models/TagifyOptions";
import { useHub } from "../../../../core/Global";
import PBXServerIssues from "../models/PBXServerIssues";


function PBXServerIssuesPage() {

    const hub = useHub()
    const { selectedId } = useParams();
    const [model, modelSet] = useState<PBXServerIssues>();
    const [companies, companiesSet] = useState<TagifyDataItem[]>();
    const [working, workingSet] = useState<string>("");

    const fetch = () => {

        hub.Get("/system/pbxservers/issues/" + selectedId, res => {
            modelSet(res.data);
            workingSet("");
        });

        hub.Get(API_ROUTES.TagifyCompaneis, res => {
            companiesSet(res.data);
        });
    }

    const resolveExtension = (body: any, callback?: ((res?: HUBResponse<any> | undefined) => void) | undefined) => {
        workingSet(body.ext);
        hub.Post("/system/pbxservers/issues/resolve/extension", body, res => {
            if (res.success) {
                fetch();
            }
            else {
                toastError(res.message ?? "technical error");
                workingSet("");
            }
        });

    }


    useEffect(() => {
        fetch();
    }, [])

    return (
        <Layout>
            <FormTitle title="Extensions" />
            <div className="row mb-10">

                {
                    model?.extensions.map((ext, key) =>
                        <div key={key} className="col-sm-12">
                            <div className="card mb-5">
                                <div className="card-body d-flex flex-column p-6">
                                    <Form onSubmit={resolveExtension}>
                                        <input type="hidden" name="serverId" value={selectedId} />
                                        <input type="hidden" name="ext" value={ext.extensionNumber} />
                                        <div className="d-flex align-items-center">
                                            <Icon icon="phone-office" size={18} />
                                            <div className="fs-5 fw-bold ms-4">
                                                {ext.extensionNumber}
                                            </div>
                                            <div className="fs-7 ms-10">
                                                <input name="name" type="text" className="form-control form-control-sm form-control-solid w-200px" defaultValue={ext.description} />
                                            </div>
                                            <div className="fs-7 ms-5">
                                                <select name="companyId" className="form-select form-select-sm form-select-solid w-200px">
                                                    <option value="">Choose Company</option>
                                                    {
                                                        companies?.map((item, key) => <option key={key} value={item.value}>{item.title}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="d-flex align-items-center ms-auto">
                                                <button className="btn btn-sm btn-primary">
                                                    {
                                                        working == ext.extensionNumber.toString() ?
                                                            <Icon icon="asterisk" spin />
                                                            : <>+ Add Extension</>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>

            <FormTitle title="Queues" />
            <div className="row mb-10">

                {
                    model?.queus.map((qu, key) =>
                        <div key={key} className="col-sm-12">
                            <div className="card mb-5">
                                <div className="card-body d-flex flex-column p-6">
                                    <div className="d-flex align-items-center">
                                        <Icon icon="phone-office" size={18} />
                                        <div className="fs-5 fw-bold ms-4">
                                            {qu.queueName}
                                        </div>
                                        <div className="fs-7 ms-10">
                                            {qu.queueName}
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <button className="btn btn-sm btn-primary">
                                                + Add Queue
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>

        </Layout>
    )
}

export default PBXServerIssuesPage;