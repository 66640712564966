import "./DataTable.css";
import React, { useEffect, useState } from "react";
import { formatDiagnostic, Type } from "typescript";
import Button from "../base/Button";
import Modal from "../base/Modal";
import Indicator from "../base/Indicator";
import { BootstrapColors, DataSelect, DataTab, FontAwesomeIcons, ListItem } from '../../core/Types';
import BrowseOptions from '../../models/BrowseOptions';
import HUBResponse from '../../models/HUBResponse';
import Hub from '../../api/Hub';
import { Validator } from '../../core/Types';
import Environment from '../../env/env';
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toastSuccess, toastError, toastDefault } from '../base/Toast';
import Icon from "../base/Icon";
import Div from "../base/Div";
import { setPageCount, clearCreateButton, setToolbarActions, clearToolbar, shareCalls, getUser, useGlobal } from '../../core/Global';
import DropDown, { DropDownOption } from "../form/DropDown";
import { SortTypes } from '../../core/Enums';
import Filtering from "./Filtering";
import { FilterParam } from '../../models/BrowseOptions';


const env = new Environment();

type DataTableProps = {
    children?: any
    objectName: string
    route?: string
    modal?: string
    addRoute?: string
    apiRoute: string
    formValidator?: Validator[]
    extraParams?: FilterParam[]
    draft?: boolean
    delete?: boolean
    preventLinkUpdate?: boolean
    deleteCustom?: (data: any) => void
    deleteMultiple?: (data: any[]) => void
    edit?: boolean
    addCustom?: () => void
    editCustom?: (data: any) => void
    editText?: string
    editIcon?: FontAwesomeIcons
    share?: boolean
    largeForm?: boolean
    callActions?: boolean
    ascending?: boolean
    preventCreate?: boolean
    loadByCommand?: boolean
    filteringProps?: Array<any>
    sortingProps?: Array<any>
    noHeader?: boolean
    noFilter?: boolean
    noAction?: boolean
    noMultipleActions?: boolean
    defaultId?: string
    jobId?: string
    defaultKeyword?: string
    defaultFilters?: FilterParam[]
    refresh?: boolean
    unselectAll?: boolean
    bind?: any
    actionsOnCard?: boolean
    customNoRowComp?: any
    groupActions?: GroupActionItem[]
    isChild?: boolean
    parent?: string
    defaultSize?: number
    jobTable?: boolean
    onSend?: (data: any) => void
    onGroupSend?: (ids: string[]) => void
    onConfirm?: (data: any) => void
    onUndoConfirm?: (data: any) => void
    onGroupConfirm?: (ids: string[]) => void
    onRowClick?: (data: any) => void
    onDataBinded?: (data: any) => void

}


export interface GroupActionItem {
    title: string
    icon: FontAwesomeIcons
    iconClass?: string
    action: (data: any[]) => void
}

type PageItem = {
    pageNumber?: number,
    route?: string,
    text?: string,
    active?: boolean,
    loading?: boolean
}

type RowItem = {
    id: string,
    selected: boolean
}

let firstLoad = true;
export function DataTable(props: DataTableProps) {

    const defaultPage = 1;
    const defaultSize = props.defaultSize ?? 20;

    const user = getUser();
    const [globalState] = useGlobal();

    const [data, setData] = useState<Array<any>>();
    useEffect(() => {
        if (props.onDataBinded) props.onDataBinded(data);
        setTimeout(() => {
            window.dataTableMenu();
        }, 500);
    }, [data])


    const [loaded, setLoaded] = useState<boolean>(false);
    const [inited, setInited] = useState<boolean>(false);
    const [empty, emptySet] = useState<boolean>(false);
    const [firstload, firstloadSet] = useState<boolean>(true);

    const [selectedRow, selectedRowSet] = useState<any>();
    const [selectedIds, selectedIdsSet] = useState<string[]>();
    const [selectedItems, selectedItemsSet] = useState<any[]>();
    const [editModel, editModelSet] = useState<any>();
    const [formLoading, formLoadingSet] = useState<boolean>(false);
    const [filteringValues, filteringValuesSet] = useState<any>();
    const [isEdit, isEditSet] = useState<boolean>(false);

    const [browse, browseSet] = useState<BrowseOptions>();
    const [pageFetching, pageFetchingSet] = useState<number>(0);
    const [currentPage, currentPageSet] = useState<number>(1);

    const [selectedTab, selectedTabSet] = useState<DataTab>();

    const [exportedFile, exportedFileSet] = useState<string>();

    const [totalCount, totalCountSet] = useState<number>(0);
    const [trashedCount, trashedCountSet] = useState<number>(0);
    const [draftCount, draftCountSet] = useState<number>(0);

    const [rows, rowsSet] = useState<RowItem[]>();
    const [selectedCount, selectedCountSet] = useState<number>(0);

    const [routeParams, routeParamsSet] = useState<ListItem[]>([]);
    const [searchParams] = useSearchParams();

    const hub = new Hub<any>(props.apiRoute);
    const location = useLocation();
    const navigate = useNavigate();

    const isDataField = (item: any, index: number) => {
        return item.type == DataTableField;
    }

    const isDataForm = (item: any, index: number) => {
        return item.type == DataForm;
    }

    const isCustomActions = (item: any, index: number) => {
        return item.type == DataTableCustomActions;
    }

    const fetch = async (b?: BrowseOptions) => {
        if (b) browseSet(b);
        else b = browse;

        await hub.Browse({
            ...b,
            filterParams: b?.filterParams?.concat(props.extraParams ?? []),
            deafultFilter: props.defaultFilters,
            defaultId: props.defaultId,
            jobId: props.jobId,
            defaultKeyword: props.defaultKeyword,
            searchParams: firstLoad ? searchParams.toString() : ""
        },
            res => {
                setData(res.data);
                firstloadSet(firstLoad);
                if (b?.trashed) totalCountSet(res.trashedCount ?? 0);
                else if (b?.draft) totalCountSet(res.draftCount ?? 0);
                else totalCountSet(res.count ?? 0);
                emptySet(res.count == undefined || res.count == 0);
                trashedCountSet(res.trashedCount ?? 0);
                draftCountSet(res.draftCount ?? 0);
                filteringValuesSet(res.filteringValues);
                if (!b?.trashed && !b?.draft) setPageCount(res.count);
                setLoaded(true);
                setInited(true);
                pageFetchingSet(0);
                firstLoad = false;
                var rws = new Array<RowItem>();
                for (var r = 0; r < res.data.length; r++) {
                    rws.push({
                        id: res.data[r].id,
                        selected: false
                    })
                }
                rowsSet(rws);
                selectedCountSet(0);
                $("#data-table-check-all").prop("checked", false);
                $("#data-table-check-all").prop("indeterminate", false);
                $("#data-table-check-all").removeClass("form-check-indeterminate");
                if (props.isChild) {
                    if (props.parent) {
                        //console.log("scrolllll", $("#" + props.parent));
                        $("#" + props.parent).scrollTop(0);
                    }
                }
                else {
                    $(document).scrollTop(0);
                }
            });

    }

    const refresh = async () => {
        setLoaded(false);
        fetch();
    }

    const tablist = (trashed?: boolean, draft?: boolean) => {
        if (trashed && trashedCount == 0) return;
        if (draft && draftCount == 0) return;
        var b = browse ?? {};
        b.page = 1;
        b.trashed = trashed;
        b.draft = draft;
        setLoaded(false);
        fetch(b);
    }

    const selectTab = (tab: DataTab) => {
        selectedTabSet(tab);
        var b = browse ?? {};
        b.filterParams = tab.filterParams;
        setLoaded(false);
        fetch(b);
    }

    const add = async () => {
        selectedRowSet(false);
        formLoadingSet(false);
        editModelSet(undefined);
        setTimeout(() => $(".modal-form .auto-focus").focus(), 200);
    }

    const create = async (body: any, callback?: (res: HUBResponse<any>) => void) => {
        await hub.Create(body, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
            }
            if (callback) callback(res);
        });
    }

    const edit = async (item: any) => {
        selectedRowSet(item);
        formLoadingSet(true);
        editModelSet(undefined);

        await hub.Find(item.id, res => {
            formLoadingSet(false);
            if (res.success) {
                if (env.isDevelopment) console.log("Edit Form", res.data);
                editModelSet(res.data);
                // for (let key of Object.keys(res.data)) {
                //     $(".modal-form input[name="+key+"]").val(res.data[key]);
                //     $(".modal-form input[name="+key+"]").trigger("");
                // }
            }
            setTimeout(() => $(".modal-form .auto-focus").focus(), 200);
        });
    }

    const update = async (body: any, callback?: (res: HUBResponse<any>) => void) => {
        await hub.Update(selectedRow.id, body, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
            }
            if (callback) callback(res);
        });
    }

    const trash = async (callback: any) => {
        await hub.Trash(selectedRow.id, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(res.message ?? props.objectName + " has been trashed successfully!");
            }
            if (callback) callback();
        });
    }

    const trashMany = async (callback: any) => {
        var ids = new Array<string>();
        var rws = rows ?? [];
        rws.forEach(rw => {
            if (rw.selected) ids.push(rw.id);
        });

        await hub.Post(props.apiRoute + "trash", ids, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(res.message ?? "Selected item(s) moved to trash successfully!");
            }
            if (callback) callback();
        });
    }

    const restore = async (id: string) => {
        await hub.Restore(id, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(props.objectName + " has been restored successfully!");
            }
        });
    }

    const restoreMany = async (callback: any) => {
        var ids = new Array<string>();
        var rws = rows ?? [];
        rws.forEach(rw => {
            if (rw.selected) ids.push(rw.id);
        });

        await hub.Post(props.apiRoute + "restore", ids, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(res.message ?? "Selected item(s) restored successfully!");
            }
            if (callback) callback();
        });
    }

    const remove = async (callback: any) => {
        await hub.Trash(selectedRow.id, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(props.objectName + " has been deleted successfully!");
            }
            if (callback) callback();
        });
    }

    const removeMany = async (callback: any) => {
        var ids = new Array<string>();
        var rws = rows ?? [];
        rws.forEach(rw => {
            if (rw.selected) ids.push(rw.id);
        });

        await hub.Post(props.apiRoute + "remove", ids, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastSuccess(res.message ?? "Selected item(s) removed successfully!");
            }
            if (callback) callback();
        });
    }

    const discard = async (callback: any) => {
        await hub.Trash(selectedRow.id, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastDefault(props.objectName + " has been discarded successfully!");
            }
            if (callback) callback();
        });
    }

    const discardMany = async (callback: any) => {
        var ids = new Array<string>();
        var rws = rows ?? [];
        rws.forEach(rw => {
            if (rw.selected) ids.push(rw.id);
        });

        await hub.Post(props.apiRoute + "remove", ids, res => {
            if (res.success) {
                fetch();
                selectedRowSet(false);
                unselectAll();
                toastDefault("Selected item(s) dicarded successfully!");
            }
            if (callback) callback();
        });
    }

    const sort = async () => {
        var b = browse ?? {};
        if (b?.sortType == SortTypes.Ascending) b.sortType = SortTypes.Descending;
        else b.sortType = SortTypes.Ascending;
        browseSet(b);
        await fetch(b);
    }

    const renderCustomField = (children: any, data: any) => {
        if (children.type == DataTableUserField) {
            return (<DataTableUserField firstName={data.firstName} lastName={data.lastName} username={data.username} avatar={data.avatarUrl} />)
        }

        const childrenWithProps = React.Children.map(children, child => {
            // Checking isValidElement is the safe way and avoids a typescript
            // error too.
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { ...data });
            }
            return child;
        });

        return (<>{childrenWithProps}</>);
    }

    const renderCustomAction = (child: any, data: any) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...data });
        }
        return (<></>);
    }

    const paginationText = () => {
        var s = ((browse?.page ?? defaultPage) - 1) * (browse?.size ?? defaultSize);
        var e = s + (browse?.size ?? defaultSize);
        s++;
        if (e > totalCount) e = totalCount;
        return `${s} to ${e} of ${totalCount}`;
    }

    const pagination = () => {
        var p = new Array<PageItem>();
        var pt = Math.floor(totalCount / (browse?.size ?? defaultSize));
        if ((totalCount % (browse?.size ?? defaultSize)) > 0) pt++;

        // if (env.isDevelopment) {
        //     console.log("paginatoin size", (browse?.size ?? defaultSize))
        //     console.log("pagination pages", pt);
        // }

        pushPage(p, 1);

        if (pt < 11) {
            for (var i = 2; i <= pt; i++) pushPage(p, i);
        }
        else {
            var start = (currentPage > 6) ? (currentPage - 4) : 2;
            if (currentPage > 6) pushPage(p);
            var end = (currentPage < 7) ? 8 : currentPage + 4;
            var endDots = true;
            if (end >= pt - 1) {
                end = pt;
                endDots = false;
            }
            for (var i = start; i < end; i++) pushPage(p, i);
            if (endDots) pushPage(p);
            pushPage(p, pt);
        }

        return p;
    }

    const pushPage = (p: Array<PageItem>, pageNum?: number) => {
        p.push({
            pageNumber: pageNum,
            route: "",
            text: pageNum ? pageNum.toString() : "..",
            active: pageNum == browse?.page,
            loading: pageNum == pageFetching
        });
    }

    const setPage = async (p: number) => {
        currentPageSet(p);
        pageFetchingSet(p);

        var b = browse ?? {};
        b.page = p;
        await fetch(b);
    }

    const selectRow = (item: any) => {
        var ids = selectedIds ?? [];
        ids.push(item.id);
        selectedIdsSet(i => [...ids]);

        var itms = selectedItems ?? [];
        itms.push(item);
        selectedItemsSet(i => [...itms]);

        var rws = rows ?? new Array<RowItem>();
        var c = 0;
        for (var r = 0; r < rws.length; r++) {
            if (rws[r].id == item.id) rws[r].selected = true;
            if (rws[r].selected) c++;
        }
        rowsSet(rws);
        selectedCountSet(c);
        var allSelected = true;
        rws.forEach(rw => {
            if (!rw.selected) allSelected = false;
        });

        $("#row-" + item.id).addClass("data-table-selected");

        setTimeout(() => {
            if (allSelected) {
                $("#data-table-check-all").prop("indeterminate", false);
                $("#data-table-check-all").removeClass("form-check-indeterminate");
                $("#data-table-check-all").prop("checked", true);
            }
            else {
                $("#data-table-check-all").prop("indeterminate", true);
                $("#data-table-check-all").addClass("form-check-indeterminate");
            }
        }, 60);
    }

    const unselectRow = (item: any) => {
        var ids = selectedIds ?? [];
        ids.splice(ids.indexOf(item.id), 1);
        selectedIdsSet(i => [...ids]);

        var itms = selectedItems ?? [];
        for (var i = 0; i < itms.length; i++) {
            if (itms[i].id == item.id) {
                itms.splice(i, 1);
                break;
            }
        }
        selectedItemsSet(i => [...itms]);

        var rws = rows ?? new Array<RowItem>();
        var c = 0;
        for (var r = 0; r < rws.length; r++) {
            if (rws[r].id == item.id) rws[r].selected = false;
            if (rws[r].selected) c++;
        }
        rowsSet(rws);
        selectedCountSet(c);
        var noneSelected = true;
        rws.forEach(rw => {
            if (rw.selected) noneSelected = false;
        });

        $("#row-" + item.id).removeClass("data-table-selected");
        $("#data-table-check-all").prop("checked", false);

        if (noneSelected) {
            $("#data-table-check-all").prop("indeterminate", false);
            $("#data-table-check-all").removeClass("form-check-indeterminate");
        }
        else {
            $("#data-table-check-all").prop("indeterminate", true);
            $("#data-table-check-all").addClass("form-check-indeterminate");
        }
    }

    const selectAll = () => {
        var rws = rows ?? new Array<RowItem>();
        var ids: string[] = [];
        var c = 0;
        for (var r = 0; r < rws.length; r++) {
            rws[r].selected = true;
            c++;
            $("#select-row-" + rws[r].id).prop("checked", true);
            $("#row-" + rws[r].id).addClass("data-table-selected");
            ids.push(rws[r].id);
        }

        selectedIdsSet(d => [...ids]);
        selectedItemsSet(data);
        rowsSet(rws);
        selectedCountSet(c);

        setTimeout(() => {
            $("#data-table-check-all").prop("checked", true);
            $("#data-table-check-all").prop("indeterminate", false);
            $("#data-table-check-all").removeClass("form-check-indeterminate");
        }, 60);

    }

    const unselectAll = () => {
        var rws = rows ?? new Array<RowItem>();
        for (var r = 0; r < rws.length; r++) {
            rws[r].selected = false;
            $("#select-row-" + rws[r].id).prop("checked", false);
            $("#row-" + rws[r].id).removeClass("data-table-selected");
        }
        rowsSet(rws);
        selectedCountSet(0);
        selectedIdsSet([]);
        selectedItemsSet([]);
        $("#data-table-check-all").prop("indeterminate", false);
        $("#data-table-check-all").removeClass("form-check-indeterminate");
    }

    const toggleSelect = () => (
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
            <input className="form-check-input" type="checkbox" id="data-table-check-all"
                data-kt-check="true" data-kt-check-target="#data_table_1 .form-check-input" value="1"
                onChange={e => {
                    if (e.target.checked) selectAll();
                    else unselectAll();
                }}
            />
        </div>
    )

    const onFilter = async (params: FilterParam[], callback: () => void) => {
        var b = browse ?? {};
        b.page = 1;
        b.filterParams = params;
        await fetch(b);
        await callback();
    };

    const ActionButtons = () => (
        <>
            {
                !props.preventCreate &&
                <>
                    {
                        user?.limited ? <Button icon="plus" text={`New ${props.objectName}`} modal="modal-user-is-limited" />
                            : props.addCustom ? <Button icon="plus" text={`New ${props.objectName}`} onClick={props.addCustom} />
                                : props.modal ? <Button icon="plus" text={`New ${props.objectName}`} modal={props.modal} />
                                    : <Button icon="plus" text={`New ${props.objectName}`} to={props.addRoute ? props.addRoute : props.route + "/create"} />
                    }
                </>
            }
        </>
    )

    const dataKeys = () => {
        var keys: string[] = [];
        if (data && data.length > 0) {
            for (const k in data[0]) keys.push(k);
        }
        return keys
    }

    useEffect(() => {

        if (!props.actionsOnCard && !props.isChild) setToolbarActions(ActionButtons);

        var fparams: FilterParam[] = [];

        if (!props.loadByCommand) {
            fetch({
                page: defaultPage,
                size: defaultSize,
                sortType: props.ascending ? SortTypes.Ascending : SortTypes.Descending,
                filterParams: fparams
            });
        }

        if (!props.preventCreate) {

            //setCreateButton(props.objectName, props.route + "/create", (user?.limited ? "modal-user-is-limited" : props.modal));
        }

        setTimeout(() => {
            window.bindDragScroll();
        }, 3000);

        return () => {
            if (!props.isChild) {
                clearCreateButton();
                clearToolbar();
            }
            firstLoad = true;
        }

    }, [props.defaultKeyword, user, props.apiRoute]);

    useEffect(() => {
        setLoaded(false);
    }, [props.apiRoute])

    useEffect(() => {
        if (props.refresh) {
            //if (env.isDevelopment) console.log("props.refresh called!");
            fetch();
            unselectAll();
        }
    }, [props.refresh])

    useEffect(() => {
        if (props.unselectAll) {
            //if (env.isDevelopment) console.log("props.unselectAll called!");
            unselectAll();
        }
    }, [props.unselectAll])

    useEffect(() => {
        // if (props.bind) {
        //     if (env.isDevelopment) console.log("props.bind called!", props.bind);
        //     var arr = data ?? [];
        //     for (var i = 0; i < arr.length; i++) {
        //         if(arr[i].id == props.bind.id) {
        //             arr[i] = props.bind;
        //             break;
        //         }
        //     }

        //     setData(s=> [...arr]);
        // }
    }, [props.bind])




    return (
        <div className="card">
            {
                !props.noHeader &&
                <div className="card-header border-0 pt-3 pb-1">
                    <div className="card-title w-100" style={{
                        fontSize: "1rem"
                    }}>

                        <div className="d-flex flex-grow w-100 align-items-center">

                            {
                                !props.noFilter && !empty && loaded &&
                                <Filtering apiRoute={props.apiRoute}
                                    filteringProps={props.filteringProps}
                                    filteringValues={filteringValues}
                                    loaded={inited}
                                    onFilter={onFilter}
                                    page={currentPage}
                                    preventLinkUpdate={props.preventLinkUpdate}
                                    onRouteParamsBinded={params => {
                                        var b = browse ?? {};
                                        b.filterParams = params;
                                        browseSet(b);
                                    }} />
                            }


                            {
                                loaded &&
                                <div className="d-flex align-items-center ms-auto datatable-head" style={{ marginTop: -6 }}>

                                    {
                                        props.actionsOnCard && <ActionButtons />
                                    }

                                    {
                                        !empty &&
                                        <>
                                            {
                                                props.jobTable &&
                                                <div className="form-floating mx-2">
                                                    <select id="drp-datatable-timezone" className="form-select form-select-sm" style={{ width: 80 }}
                                                        onChange={e => {
                                                            fetch({
                                                                ...browse,
                                                                page: 1,
                                                                size: parseInt(e.target.value)
                                                            })
                                                        }}>
                                                        <option value={20}>20</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </select>
                                                    <label htmlFor="drp-datatable-timezone">
                                                        Timezone
                                                    </label>
                                                </div>
                                            }
                                            <div className="form-floating mx-2">
                                                <select id="drp-datatable-size" className="form-select form-select-sm" style={{ width: 80 }}
                                                    onChange={e => {
                                                        fetch({
                                                            ...browse,
                                                            page: 1,
                                                            size: parseInt(e.target.value)
                                                        })
                                                    }}>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                                <label htmlFor="drp-datatable-size">PAGE SIZE</label>
                                            </div>

                                            {
                                                props.sortingProps &&
                                                <div className="form-floating mx-2">
                                                    <select id="drp-datatable-sorting" className="form-select form-select-sm" style={{ width: 150 }}
                                                        onChange={e => {
                                                            if (props.sortingProps) {
                                                                var i = Number.parseInt(e.target.value);
                                                                if (isNaN(i)) i = 0;
                                                                var item = props.sortingProps[i];
                                                                fetch({
                                                                    ...browse,
                                                                    sortName: item.name,
                                                                    sortType: item.desc ? SortTypes.Descending : SortTypes.Ascending
                                                                });
                                                            }
                                                        }}>
                                                        {
                                                            props.sortingProps.map((item, key) => <option key={key} value={key}>{item.title}</option>)
                                                        }
                                                    </select>
                                                    <label htmlFor="drp-datatable-sorting">SORT BY</label>
                                                </div>
                                            }

                                        </>
                                    }


                                </div>
                            }



                        </div>

                    </div>
                </div>
            }


            <div className={`card-body position-relative ${(props.noAction || props.noMultipleActions) ? "p-0" : "pt-0"}`}>


                {
                    !loaded && <Indicator className="py-20" />
                }

                {
                    loaded && empty &&
                    <div style={{
                        margin: 60,
                        textAlign: "center",
                    }}>

                        {
                            props.customNoRowComp ? <props.customNoRowComp />
                                :
                                <>
                                    <div className="text-center">
                                        <Icon icon="triangle-exclamation" type="thin" size={24} />
                                    </div>
                                    <div className="text-center">
                                        There is no row to show!
                                    </div>
                                </>
                        }

                        {
                            !props.preventCreate && firstload &&
                            <div className="text-center mt-10">
                                <button className="btn btn-primary" onClick={e => {
                                    e.preventDefault();
                                    if (props.addCustom) props.addCustom();
                                    else if (props.modal) window.openModal(props.modal);
                                }}>
                                    <Icon icon="plus" />
                                    Add Your First {props.objectName}
                                </button>
                            </div>
                        }

                    </div>
                }

                {
                    loaded && !empty &&
                    <>
                        {
                            selectedCount > 0 &&
                            <div className="d-flex multi-action-row align-items-center px-2 py-3">
                                {toggleSelect()}
                                {
                                    !browse?.trashed && !browse?.draft &&
                                    <>
                                        {
                                            props.share &&
                                            <a href="#" className="me-3" title="Share Selected Rows"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (user?.limited) {
                                                        window.openModal("modal-user-is-limited");
                                                        return;
                                                    }
                                                    if (selectedIds && selectedIds.length > 0) shareCalls(selectedIds);
                                                }}>
                                                <Icon icon="share" size={15} />
                                            </a>
                                        }
                                        {
                                            props.onGroupConfirm &&
                                            <a href="#" className="me-3" title="Confirm Selected Rows"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (props.onGroupConfirm) props.onGroupConfirm(selectedIds ?? []);
                                                }}>
                                                <Icon icon="check-circle" size={15} />
                                            </a>
                                        }
                                        {
                                            props.onGroupSend &&
                                            <a href="#" className="me-3" title="Send Selected Rows"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (props.onGroupSend) props.onGroupSend(selectedIds ?? []);
                                                }}>
                                                <Icon icon="share" size={15} />
                                            </a>
                                        }

                                        {
                                            props.groupActions?.map((g, k) =>
                                                <span key={k} className="me-3 cursor-pointer text-gray-600" title={g.title}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (user?.limited) {
                                                            window.openModal("modal-user-is-limited");
                                                            return;
                                                        }
                                                        g.action(selectedItems ?? [])
                                                    }}>
                                                    <Icon icon={g.icon} size={15} className={g.iconClass} />
                                                </span>
                                            )
                                        }



                                        {
                                            props.delete &&
                                            <a href="#" className="me-3" title="Delete Selected Rows"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (user?.limited) {
                                                        window.openModal("modal-user-is-limited");
                                                        return;
                                                    }
                                                    if (props.deleteMultiple) props.deleteMultiple(selectedItems ?? [])
                                                }}>
                                                <Icon icon="circle-minus" size={15} className="text-danger" />
                                            </a>
                                        }

                                        {/* <a href="#" className="me-3">
                                <Icon icon="ban" size={15} />
                            </a> */}
                                    </>
                                }
                                {
                                    browse?.trashed &&
                                    <>
                                        <a href="#" className="mx-3" title="Restore Selected Rows"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (user?.limited) {
                                                    window.openModal("modal-user-is-limited");
                                                    return;
                                                }
                                                window.openModal("modal-restore-many")
                                            }}>
                                            <Icon icon="trash-undo" size={15} />
                                        </a>
                                        <a href="#" className="mx-3" title="Delete Selected Rows"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (user?.limited) {
                                                    window.openModal("modal-user-is-limited");
                                                    return;
                                                }
                                                window.openModal("modal-delete-many")
                                            }}>
                                            <Icon icon="trash-xmark" size={15} color="#db7b7b" />
                                        </a>
                                    </>
                                }

                                {
                                    browse?.draft &&
                                    <>
                                        <a href="#" className="mx-3" title="Discard"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (user?.limited) {
                                                    window.openModal("modal-user-is-limited");
                                                    return;
                                                }
                                                window.openModal("modal-discard-many")
                                            }}>
                                            <Icon icon="circle-xmark" size={15} />
                                        </a>
                                    </>
                                }

                                <div className="ms-4" style={{ fontSize: 12 }}>
                                    {selectedCount} item(s) selected!
                                </div>
                            </div>
                        }

                        <div className={((props.children?.length ?? dataKeys().length) > 4 ? "table-responsive" : "")}>
                            <table className="table table-hover table-sm align-middle table-row-dashed" id="data_table_1">
                                <thead>
                                    <tr className={"text-start text-muted fw-bolder fs-7 text-uppercase gs-0 "}>
                                        {
                                            !props.noAction && !props.noMultipleActions &&
                                            <th className="w-10px pe-2 ps-2">
                                                {selectedCount == 0 && <>{toggleSelect()}</>}
                                            </th>
                                        }

                                        {
                                            props.children ?
                                                <>
                                                    {
                                                        props.children.filter(isDataField).map((item: any, key: number) =>
                                                            <>
                                                                {
                                                                    !item.props.hidden &&
                                                                    <th key={key}
                                                                        style={{
                                                                            width: item.props.width,
                                                                            maxWidth: item.props.maxWidth
                                                                        }}
                                                                        className={(item.props.width ? "" : "min-w-125px")}>
                                                                        {item.props.title}
                                                                        {
                                                                            item.props.help &&
                                                                            <i className="fa fa-circle-info ms-2" title={item.props.help}></i>
                                                                        }
                                                                    </th>
                                                                }
                                                            </>)
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        dataKeys().map((name, k) => <th key={k}>{name}</th>)
                                                    }
                                                </>
                                        }

                                        {
                                            !props.noAction && <th></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((data: any, key: number) =>
                                            <tr key={key} id={"row-" + data.id} className={"data-row " + (props.onRowClick ? "cursor-pointer" : "")}>
                                                {
                                                    !props.noAction && !props.noMultipleActions &&
                                                    <td className="ps-2">
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                            <input className="form-check-input" type="checkbox" value="1"
                                                                id={"select-row-" + data.id}
                                                                onChange={e => {
                                                                    if (e.target.checked) selectRow(data);
                                                                    else unselectRow(data);
                                                                }} />
                                                        </div>
                                                    </td>
                                                }

                                                {
                                                    props.children ?
                                                        <>
                                                            {
                                                                props.children.filter(isDataField).map((item: any, key1: number) =>
                                                                    <>
                                                                        {
                                                                            !item.props.hidden &&
                                                                            <td key={key1} className={"align-items-center " + item.props.className}
                                                                                style={{
                                                                                    maxWidth: item.props.maxWidth
                                                                                }}
                                                                                onClick={e => {
                                                                                    if (props.onRowClick) props.onRowClick(data);
                                                                                }}>
                                                                                {
                                                                                    item.props.children &&
                                                                                    <>
                                                                                        {renderCustomField(item.props.children, data)}
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    item.props.dataValueName && data[item.props.dataValueName] &&
                                                                                    <div>
                                                                                        {
                                                                                            item.props.dataValueImage &&
                                                                                            <img src={env.cdnUrl + data[item.props.dataValueImage]} className="me-3"
                                                                                                style={{ width: item.props.dataImageWidth ?? 40, marginTop: -2 }} />
                                                                                        }
                                                                                        <span>{data[item.props.dataValueName]}</span>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                dataKeys().map((name, k) => <td key={k}>{data[name]}</td>)
                                                            }
                                                        </>
                                                }
                                                {
                                                    !props.noAction &&
                                                    <td className="text-end">
                                                        <a href="#" className="btn btn-default bnt-icon btn-active-transparent btn-sm"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                            }}
                                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                            <Icon icon="ellipsis-vertical" />
                                                        </a>
                                                        <div className="menu menu-sub menu-sub-dropdown menu-column fw-bold fs-7 p-0 m-0" data-kt-menu="true"
                                                            style={{
                                                                width: 175,
                                                                maxWidth: 175
                                                            }}>
                                                            {
                                                                props.children?.filter(isCustomActions).map((item: any, key1: number) => renderCustomAction(item.props.children, data))
                                                            }
                                                            {
                                                                browse?.trashed &&
                                                                <>
                                                                    <div className="menu-item">
                                                                        <a href="#" className="menu-link text-gray-700 px-3"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (user?.limited) {
                                                                                    window.openModal("modal-user-is-limited");
                                                                                    return;
                                                                                }
                                                                                restore(data.id);
                                                                            }}
                                                                        >
                                                                            <Icon icon="rotate-left" className="me-2" />
                                                                            Restore
                                                                        </a>
                                                                    </div>
                                                                    <div className="menu-item">
                                                                        <a href="#" className="menu-link text-danger px-3"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (user?.limited) {
                                                                                    window.openModal("modal-user-is-limited");
                                                                                    return;
                                                                                }
                                                                                selectedRowSet(data);
                                                                                window.openModal("modal-delete-row");
                                                                            }}
                                                                        >
                                                                            <Icon icon="circle-minus" className="me-2 text-danger" />
                                                                            Delete
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                browse?.draft && !user?.limited &&
                                                                <>
                                                                    <div className="menu-item">
                                                                        <Link to={props.route + "/draft/" + data.id} className="menu-link text-gray-700 px-3">
                                                                            <Icon icon="edit" className="me-2 text-gray-700" />
                                                                            Edit
                                                                        </Link>
                                                                    </div>
                                                                    <div className="menu-item">
                                                                        <a href="#" className="menu-link text-gray-700 px-3"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                if (user?.limited) {
                                                                                    window.openModal("modal-user-is-limited");
                                                                                    return;
                                                                                }
                                                                                selectedRowSet(data);
                                                                                window.openModal("modal-discard-row");
                                                                            }}>
                                                                            <Icon icon="circle-xmark" className="me-2 text-gray-700" />
                                                                            Discard
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                !browse?.trashed && !browse?.draft &&
                                                                <>
                                                                    {
                                                                        props.onConfirm && !data.confirmed &&
                                                                        <div className="menu-item">
                                                                            <a href="#" className="menu-link px-3 text-success"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    if (props.onConfirm) props.onConfirm(data);
                                                                                }}>
                                                                                <Icon icon="check-circle" className="me-2" />
                                                                                Confirm
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        props.onUndoConfirm && data.confirmed &&
                                                                        <div className="menu-item">
                                                                            <a href="#" className="menu-link px-3 text-warning"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    if (props.onUndoConfirm) props.onUndoConfirm(data);
                                                                                }}>
                                                                                <Icon icon="circle-exclamation" className="me-2" />
                                                                                Undo Confirm
                                                                            </a>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        props.onSend &&
                                                                        <div className="menu-item">
                                                                            <a href="#" className="menu-link text-gray-700 px-3"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    if (props.onSend) props.onSend(data);
                                                                                }}>
                                                                                <Icon icon="share" className="me-2" />
                                                                                Send
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        props.edit &&
                                                                        <div className="menu-item">
                                                                            {
                                                                                props.editCustom ?
                                                                                    <a href="#" className="menu-link text-gray-700 px-3"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            if (user?.limited) {
                                                                                                window.openModal("modal-user-is-limited");
                                                                                                return;
                                                                                            }
                                                                                            if (props.editCustom) props.editCustom(data);
                                                                                        }}>
                                                                                        <Icon icon={props.editIcon ? props.editIcon : "edit"} className="me-2" />
                                                                                        {props.editText ? props.editText : "Edit"}
                                                                                    </a>
                                                                                    :
                                                                                    <Link to={(user?.limited ? "#" : props.route + "/edit/" + data.id)} className="menu-link text-gray-700 px-3"
                                                                                        onClick={e => {
                                                                                            if (user?.limited) {
                                                                                                e.preventDefault();
                                                                                                window.openModal("modal-user-is-limited");
                                                                                                return;
                                                                                            }
                                                                                        }}>
                                                                                        <Icon icon={props.editIcon ? props.editIcon : "edit"} className="me-2" />
                                                                                        {props.editText ? props.editText : "Edit"}
                                                                                    </Link>
                                                                            }
                                                                        </div>
                                                                    }


                                                                    {
                                                                        props.delete && (!data.isSystemStatus) &&
                                                                        <div className="menu-item">
                                                                            {
                                                                                props.deleteCustom ?
                                                                                    <a href="#" className="menu-link px-3 text-danger"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            if (user?.limited) {
                                                                                                window.openModal("modal-user-is-limited");
                                                                                                return;
                                                                                            }
                                                                                            if (props.deleteCustom) props.deleteCustom(data);
                                                                                        }}
                                                                                    >
                                                                                        <Icon icon="circle-minus" className="me-2" />
                                                                                        Delete
                                                                                    </a>
                                                                                    :
                                                                                    <a href="#" className="menu-link px-3 text-danger"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            if (user?.limited) {
                                                                                                window.openModal("modal-user-is-limited");
                                                                                                return;
                                                                                            }
                                                                                            selectedRowSet(data);
                                                                                            window.openModal("modal-delete-row");
                                                                                        }}
                                                                                    >
                                                                                        <Icon icon="circle-minus" className="me-2" />
                                                                                        Delete
                                                                                    </a>
                                                                            }

                                                                        </div>
                                                                    }

                                                                </>
                                                            }



                                                        </div>
                                                    </td>
                                                }

                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        {
                            true &&
                            <div className="row mt-20 mb-20 align-items-center">

                                <div className="col-sm-9">
                                    {
                                        pagination().length > 1 &&
                                        <ul className="pagination pagination-circle justify-content-start">
                                            <li className={"page-item previous" + (currentPage > 1 ? "" : " disabled")}>
                                                <a href="#" className="page-link"
                                                    onClick={async e => {
                                                        e.preventDefault();
                                                        if (currentPage < 2) return;
                                                        await setPage(currentPage - 1);
                                                    }}>
                                                    <i className="previous"></i>
                                                </a>
                                            </li>
                                            {
                                                pagination().map((p, i) =>
                                                    <li key={i}
                                                        className={"page-item" + (p.active ? " active" : "")}>
                                                        <a href="#" className="page-link"
                                                            onClick={async (e) => {
                                                                e.preventDefault();
                                                                if (!p.pageNumber) return;
                                                                await setPage(p.pageNumber);
                                                            }}
                                                        >
                                                            {
                                                                p.loading && <Icon icon="asterisk" spin />
                                                            }
                                                            {
                                                                !p.loading && <span>{p.text}</span>
                                                            }
                                                        </a>
                                                    </li>
                                                )
                                            }
                                            <li className={"page-item next" + (currentPage < pagination().length ? "" : " disabled")}>
                                                <a href="#" className="page-link"
                                                    onClick={async e => {
                                                        e.preventDefault();
                                                        if (currentPage >= pagination().length) return;
                                                        await setPage(currentPage + 1);
                                                    }}>
                                                    <i className="next"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    }

                                </div>
                                <div className="col-sm-3 text-end fs-7 ">
                                    {paginationText()}
                                </div>
                            </div>

                        }




                        <Modal id="modal-export"
                            title={"Export"}
                            buttonText="Export"
                            buttonColor="primary"
                            onSubmit={(body, callback) => {

                                var format = body?.exportformat ?? "xls";
                                hub.Export(format, browse, res => {
                                    //console.log(res.data);
                                    return;
                                    if (res.success) {
                                        //exportedFileSet(res.data);
                                        document.location.href = env.cdnUrl + res.data;
                                        toastSuccess("List has been exported successfully!");
                                    }
                                    //if(callback) callback(res);
                                });

                            }}>
                            <div className="fs-7 fw-bolder">
                                File Format
                            </div>
                            <select name="exportformat" className="form-select form-select-solid form-select-sm">
                                <option value="xls" selected>EXCEL</option>
                                <option value="csv">CSV</option>
                                <option value="json">JSON</option>
                            </select>
                            {
                                exportedFile &&
                                <div className="p-5 bg-light-success">
                                    <div>
                                        List has been exported successfully!
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                        <a href={env.cdnUrl + exportedFile} target="_blank">
                                            Download the exported file!
                                        </a>
                                    </div>
                                </div>
                            }
                        </Modal>

                        <Modal id="modal-trash-row"
                            title={"Trash " + props.objectName}
                            onConfirm={trash}
                            buttonText="Move To Trash"
                            buttonColor="secondary">
                            <p className="ps-1">Are you sure you want to move this {props.objectName} to trash?</p>
                            <div className="p-5 bg-light-primary">
                                {selectedRow?.name}
                            </div>
                        </Modal>

                        <Modal id="modal-trash-many"
                            title={"Trash " + props.objectName}
                            onConfirm={trashMany}
                            buttonText="Move To Trash"
                            buttonColor="secondary">
                            <p className="ps-1">Are you sure you want to move selected {props.objectName}(s) to trash?</p>
                        </Modal>

                        <Modal id="modal-restore-many"
                            title={"Restore " + props.objectName}
                            onConfirm={restoreMany}
                            buttonText="Restore"
                            buttonColor="secondary">
                            <p className="ps-1">Are you sure you want to restore selected {props.objectName}(s)?</p>
                        </Modal>

                        <Modal id="modal-discard-row"
                            title={"Discard " + props.objectName}
                            onConfirm={discard}
                            buttonText="Discard"
                            buttonColor="secondary">
                            <p className="ps-1">Are you sure you want to discard changes of this {props.objectName}?</p>
                            <div className="p-5 bg-light-primary">
                                {selectedRow?.name}
                            </div>
                        </Modal>

                        <Modal id="modal-discard-many"
                            title={"Discard " + props.objectName}
                            onConfirm={discardMany}
                            buttonText="Discard"
                            buttonColor="secondary">
                            <p className="ps-1">Are you sure you want to discard changes of selected {props.objectName}(s)?</p>
                        </Modal>

                        <Modal id="modal-delete-row"
                            title={"Delete " + props.objectName}
                            onConfirm={remove}
                            buttonText="Confirm & Delete"
                            buttonColor="danger">
                            <p className="ps-1">Are you sure you want to delete the selected {props.objectName}?</p>
                            <div className="p-5 bg-light-danger">
                                {selectedRow?.name}
                            </div>
                        </Modal>

                        <Modal id="modal-delete-many"
                            title={"Delete " + props.objectName}
                            onConfirm={removeMany}
                            buttonText="Confirm & Delete"
                            buttonColor="danger">
                            <p className="ps-1">Are you sure you want to delete selected {props.objectName}(s)?</p>
                        </Modal>


                        <Modal id="modal-form-row"
                            buttonText={selectedRow ? "Update " + props.objectName : "Create " + props.objectName}
                            buttonColor="success"
                            isUpdate={editModel != undefined && editModel != null}
                            size={props.largeForm ? "XLarge" : "Large"}
                            formValidator={props.formValidator}
                            onSubmit={selectedRow ? update : create}
                            title={selectedRow ? "Edit " + props.objectName : "Add " + props.objectName}
                            staticBackdrop={true}>
                            {
                                formLoading && <Indicator className="py-20" />
                            }
                            {
                                !formLoading && <div key={editModel}>{
                                    React.Children.map(props.children?.filter(isDataForm), (child) => React.cloneElement(child, { model: editModel }))
                                }</div>
                            }
                        </Modal>


                    </>
                }

            </div>



        </div>
    );
}

type DataTableFieldProps = {
    title: string
    help?: string
    dataValueName?: string
    defaultValue?: string
    componentName?: string
    dataValuSubText?: string
    dataValueImage?: string
    dataImageWidth?: number
    children?: any
    className?: any
    hidden?: boolean
    width?: number
    maxWidth?: number
}
export function DataTableField(props: DataTableFieldProps) {

    return (<></>);
}


type DataTableUserFieldProps = {
    firstName?: string,
    lastName?: string,
    email?: string,
    username?: string,
    avatar?: string
}

export function DataTableUserField(props: DataTableUserFieldProps) {

    return (
        <>
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <a>
                    <div className="symbol-label">
                        <img src={props.avatar ?? "/assets/media/avatars/blank.png"} alt={props.firstName + " " + props.lastName} className="w-100" />
                    </div>
                </a>
            </div>
            <div className="d-flex flex-column">
                <a className="text-gray-800 text-hover-primary mb-1 fw-bold">
                    {props.firstName + " " + props.lastName}
                </a>
                <span>@{props.username}</span>
            </div>
        </>
    );
}



type DataFormProps = {
    model?: any,
    children: any
}
export function DataForm(props: DataFormProps) {

    return (
        <div key={props.model}>
            {
                React.Children.map(props.children, (child) => React.cloneElement(child, { model: props.model }))
            }
        </div>
    )
}


type DataTableCustomActionsProps = {
    children?: any
}
export function DataTableCustomActions(props: DataTableCustomActionsProps) {
    return (<>{props.children}</>);
}

