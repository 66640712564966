import { useParams } from "react-router-dom";
import Section from "../../../../components/base/Section";
import Layout from "../../../../components/layouts/Layout";
import { API_ROUTES } from "../../../../core/Constants";
import { setPage, useEnvironment, useHub, useJob, useScreen, useSocket } from "../../../../core/Global";
import JobDetailsHeader from "../components/JobDetailsHeader";
import { useEffect, useState } from "react";
import Job from "../models/Job";
import JobOverview from "../components/JobOverview";
import CallsList from "../../../../components/calls/CallsList";
import JobAttachments from "../components/JobAttachments";
import JobPayments from "./JobPayments";
import JobEstimates from "./JobEstimates";
import JobInvoiceDetails from "./JobInvoiceDetails";
import JobInvoices from "./JobInvoices";


export default function JobDetails() {
    let box: HTMLDivElement | null;
    const hub = useHub();
    const screen = useScreen();
    const socket = useSocket();
    const { selectedPage, selectedId, selectedObjectId } = useParams();

    const [job, jobSet] = useState<Job>()

    const fetch = () => {

        hub.Get(API_ROUTES.Jobs + selectedId, res => {
            if (res.success) {
                jobSet(res.data);
            }
        })

    }
    useEffect(() => {
        fetch()

        var socketKey = socket.start(
            skt => {
                skt.on("JobDetailsUpdate-" + selectedId, data => {
                    jobSet(data)
                });
            }, skt => {
                skt.off("JobDetailsUpdate-" + selectedId);
            })

        return () => {
            socket.stop(socketKey);
        }

    }, [])


    const [boxHeight, boxHeightSet] = useState<number>();
    const fixHeights = () => {
        boxHeightSet(screen.height - (box?.offsetTop ?? 50) - 20);
    }

    useEffect(() => {
        setPage("Job Details", "Jobs");

        fixHeights();
        window.addEventListener("resize", fixHeights);

        return () => {
            window.removeEventListener("resize", fixHeights);
        }

    }, [job])



    return (
        <Layout wide>

            <Section fluid>
                {job && <JobDetailsHeader job={job} selectedPage={selectedPage?.toLowerCase() ?? "overview"} />}
            </Section>

            <Section fluid>
                <div ref={e => box = e} className="scroll-y"
                    style={{
                        height: boxHeight,
                        overflowX: "hidden"
                    }}>
                    {
                        (!selectedPage || selectedPage == "" || selectedPage?.toLowerCase() == "overview")
                        && <JobOverview job={job} />
                    }
                    {selectedPage == "calls" && job?.id && <CallsList noHeader noFilter jobId={job.id} />}
                    {
                        selectedPage == "attachments" &&
                        <div className="card">
                            <div className="card-body">
                                <JobAttachments jobId={job?.id} />
                            </div>
                        </div>
                    }

                    {
                        selectedPage == "payments" && job && <JobPayments job={job} />
                    }

                    {
                        !selectedObjectId && selectedPage == "estimates" && job && <JobEstimates job={job} />
                    }

                    {
                        !selectedObjectId && selectedPage == "invoices" && job && <JobInvoices job={job} />
                    }

                    {
                        selectedObjectId && selectedPage == "estimates" && job && <JobInvoiceDetails job={job} invoiceId={selectedObjectId} />
                    }

                    {
                        selectedObjectId && selectedPage == "invoices" && job && <JobInvoiceDetails job={job} invoiceId={selectedObjectId} />
                    }

                </div>
            </Section>

        </Layout>
    )
}