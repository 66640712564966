import {
    closeArchiveJob, closeCloseJob, closeCopyJob, closeEditJobAddress, closeEditJobAppt, closeEditJobClient, closeEditJobDetails,
    closeEditJobTech, closeInvalidCloseJob, closeJobQuickView, closenotCalledActions, closeReopenJob, closeSendJob, openCloseJob, useGlobal
} from "../../../../../core/Global"
import JobFormModal from "../../views/JobFormModal"
import JobAppointmentModal from "./JobAppointmentModal"
import JobClientEdit from "./JobClientEdit"
import JobDetailsEdit from "./JobDetailsEdit"
import JobDrawerView from "../JobDrawerView"
import JobReopenFromClosedModal from "../JobReopenFromClosedModal"
import JobSendModal from "./JobSendModal"
import JobsListModal from "./JobsListModal"
import JobArchiveModal from "./JobArchiveModal"
import JobCloseNotValidModal from "./JobCloseNotValidModal"
import JobClosePaymentModal from "./JobClosePaymentModal"
import JobUpdateTechModal from "./JobUpdateTechModal"
import JobUpdateAddressModal from "./JobUpdateAddressModal"
import JobNotCalledActionsModal from "./JobNotCalledActionsModal"




export default function JobGlobalModals() {

    const [globalState] = useGlobal()

    return (
        <>

            {globalState.reportListModal && <JobsListModal list={globalState.reportListModal} />}

            {globalState.createJob && <JobFormModal duplicateId={globalState.duplicateJobId} chatMessageId={globalState.messageToJobId} />}

            {
                globalState.quickJobView &&
                <JobDrawerView id={globalState.quickJobView}
                    tab={globalState.quickJobTab ?? "1"}
                    onClose={closeJobQuickView}
                />
            }

            {
                globalState.editJobClient &&
                <JobClientEdit job={globalState.editJobClient} onClose={closeEditJobClient} />
            }

            {
                globalState.editJobAppt &&
                <JobAppointmentModal job={globalState.editJobAppt} onClose={closeEditJobAppt} />
            }

            {
                globalState.sendJob &&
                <JobSendModal job={globalState.sendJob} onClose={closeSendJob} />
            }

            {
                globalState.copyJob &&
                <JobSendModal job={globalState.copyJob} onClose={closeCopyJob} justCopy />
            }

            {
                globalState.editJobTech &&
                <JobUpdateTechModal job={globalState.editJobTech} onClose={closeEditJobTech} />
            }

            {
                globalState.editJobAddress &&
                <JobUpdateAddressModal job={globalState.editJobAddress} onClose={closeEditJobAddress} />
            }

            {
                globalState.editJobDetails &&
                <JobDetailsEdit job={globalState.editJobDetails} onClose={closeEditJobDetails} />
            }

            {
                globalState.archiveJob &&
                <JobArchiveModal job={globalState.archiveJob} onClose={closeArchiveJob} />
            }

            {
                globalState.invalidCloseJob &&
                <JobCloseNotValidModal job={globalState.invalidCloseJob} error={globalState.invalidCloseErrs}
                    onClose={closeInvalidCloseJob}
                    onUpdate={job => {
                        setTimeout(() => {
                            openCloseJob(job)
                        }, 500);
                    }} />
            }

            {
                globalState.closeJob &&
                <JobClosePaymentModal job={globalState.closeJob}
                    onClose={closeCloseJob}
                />
            }

            {
                globalState.reopenJob &&
                <JobReopenFromClosedModal job={globalState.reopenJob} status={globalState.reopenToStatus}
                    onClose={closeReopenJob} />
            }


            {
                globalState.notCalledActions &&
                <JobNotCalledActionsModal job={globalState.notCalledActions}
                    onClose={closenotCalledActions} />
            }



        </>
    )
}