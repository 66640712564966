import { useEffect, useState } from "react"
import { editJob, openJobQuickView, toPrettyDate, useHub, useSocket } from "../../core/Global"
import { API_ROUTES } from "../../core/Constants";
import Job from "../../modules/crm/jobs/models/Job";
import Div from "../base/Div";
import JobQuickView from "../../modules/crm/jobs/components/JobQuickView";


type Props = {
    userId?: string
}
export default function AccountTechActiveJobs(props: Props) {

    const hub = useHub();
    const socket = useSocket();
    const [data, dataSet] = useState<Job[]>();

    const [selectedJob, selectedJobSet] = useState<Job>();

    useEffect(() => {
        if (props.userId) {
            fetch();
            var socketKey = socket.start(
                skt => {
                    skt.on("UpdateTechLatestJobs-" + props.userId, fetch);

                }, skt => {
                    skt.off("UpdateTechLatestJobs-" + props.userId);
                });

            return () => {
                socket.stop(socketKey);
            };
        }
    }, [props.userId])

    const fetch = () => {
        //console.log("fetch tech latest jobs", props.userId);
        hub.Get(API_ROUTES.Jobs + "techlatestjobs/" + props.userId, res => {
            if (res.success) {
                dataSet(res.data);
            }
        })
    }

    return (
        <>

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        <span className="card-label fw-bolder text-dark">Latest Jobs</span>
                    </h3>
                    <div className="card-toolbar">
                        {/* <div className="d-flex flex-stack flex-wrap gap-4">
                        <div className="d-flex align-items-center fw-bolder">
                            <div className="text-muted fs-7 me-2">Cateogry</div>
                            <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option">
                                <option></option>
                                <option value="Show All" selected="selected">Show All</option>
                                <option value="a">Category A</option>
                                <option value="b">Category B</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center fw-bolder">
                            <div className="text-muted fs-7 me-2">Status</div>
                            <select className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bolder py-0 ps-3 w-auto" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option" data-kt-table-widget-5="filter_status">
                                <option></option>
                                <option value="Show All" selected="selected">Show All</option>
                                <option value="In Stock">In Stock</option>
                                <option value="Out of Stock">Out of Stock</option>
                                <option value="Low Stock">Low Stock</option>
                            </select>
                        </div>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/products.html" className="btn btn-light btn-sm">View Stock</a>
                    </div> */}
                    </div>
                </div>
                <div className="card-body p-5 h-400px scroll-y">
                    <Div visible={data && data.length == 0}>
                        <div className="text-center pt-20">
                            There is no job!
                        </div>
                    </Div>
                    <Div visible={data && data.length > 0}>
                        <table className="table align-middle table-row-dashed fs-6">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th>Job ID</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Source</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Appointment</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.map((job, k) =>
                                        <tr key={k}>
                                            <td className="fs-8">
                                                <a href="#" onClick={e => {
                                                    e.preventDefault()
                                                    openJobQuickView(job.id)
                                                }}>
                                                    #{job.jobId}
                                                </a>
                                            </td>
                                            <td>
                                                <span className="badge fs-8 badge-secondary text-white" style={{ backgroundColor: job.jobStatus?.color }}>
                                                    {job.jobStatus?.name}
                                                </span>
                                            </td>
                                            <td className="fs-8">
                                                {job.jobTypeWithGroup}
                                            </td>
                                            <td className="fs-8">
                                                {job.jobSource}
                                            </td>
                                            <td className="fs-8">
                                                {job.fullName}
                                            </td>
                                            <td className="fs-8">
                                                {job.phone?.phoneFriendly}
                                            </td>
                                            <td className="fs-8">
                                                {
                                                    job.appointmentDate ?
                                                        <div className="fs-8">
                                                            {job.appointmentDate} ({job.appointmentTimeFrom + "-" + job.appointmentTimeTo})
                                                        </div>
                                                        :
                                                        <div className="text-muted fs-8">
                                                            [No Appointment]
                                                        </div>
                                                }
                                            </td>
                                            <td className="fs-8">
                                                {toPrettyDate(job.createdAt)}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </Div>
                </div>
            </div>
        </>
    )
}