import Avatar from "../../../../components/base/Avatar";
import DescriptionContainer from "../../../../components/base/DescriptionContainer";
import Div from "../../../../components/base/Div";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import { toPrettyDate } from "../../../../core/Global";
import JobLogHistory from "../models/JobLogHistory";

interface IProps {
    log?: JobLogHistory
    jobTimezoneOffset?: number
}

export default function JobLogItem(props: IProps) {


    return (
        <div className="timeline-item" >
            <div className="timeline-line w-40px"></div>
            <div className="timeline-icon symbol symbol-circle symbol-40px">
                <div className="symbol-label bg-light">
                    <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="black"></path>
                            <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="black"></path>
                        </svg>
                    </span>
                </div>
            </div>
            <div className="timeline-content mt-2">
                <div className="pe-3 mb-5">
                    <div className={"fs-7 mb-1"}>
                        <div className="text-dark cursor-pointer">{props.log?.text}</div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        
                        <div className="d-flex align-items-center me-5"> 
                            <Avatar avatarUrl={props.log?.user?.avatarUrl} className="me-1 text-muted" width={20} />
                            <span className="text-muted fs-8">{props.log?.user?.fullName}</span>
                        </div>

                        <div className={"text-muted fs-8"}>
                            <Icon icon="clock" className="me-1" size={10} />
                            {toPrettyDate(props.log?.createdAt)}
                        </div>

                        {
                            props.jobTimezoneOffset &&
                            <div className={"text-muted fs-8 ms-5"}>
                                <Icon icon="clock" className="me-1" size={10} />
                                {toPrettyDate(props.log?.createdAt, props.jobTimezoneOffset)}
                                &nbsp;(Local Time)
                            </div>
                        }

                        
                        
                        
                    </div>
                </div>

                {
                    props.log?.description && 
                    <div className="fs-8" style={{
                        whiteSpace:"pre-line",
                        maxWidth:"90%"
                    }}>
                        <DescriptionContainer text={props.log.description} size={150} />
                    </div>
                }
                {
                    props.log?.changes && props.log.changes.length > 0 &&
                    <div className="overflow-auto pb-5">
                        
                            {
                                props.log.changes.map((p, k) => 
                                <div key={k} className="d-flex align-items-center border border-dashed border-gray-300 rounded py-2 px-5 mb-1">
                                    <div className="pe-5 pe-lg-10">
                                        
                                        <div className="fw-bolder">
                                            <Icon icon={p.icon ?? "edit"} className="me-2" />
                                            {p.name}
                                        </div>
                                        <div className={"mt-2 fs-8"} style={{marginTop: 1}}>
                                            {
                                                p.oldValue &&
                                                <div className="mb-2">
                                                    <b>Old Value: </b>{p.oldValue}
                                                </div>
                                            }
                                            {
                                                p.newValue &&
                                                <div>
                                                    {
                                                        p.oldValue && <b>New Value: </b>
                                                    }
                                                    {p.newValue}
                                                </div>
                                            }

                                            {
                                                p.description && p.description != "" &&
                                                <div className="mt-2">
                                                    <Icon icon="comment-lines" className="me-2" />
                                                    {p.description}
                                                </div>
                                            }

                                            {
                                                p.items?.map((item, key) => 
                                                    <FlexRow key={key} className="mb-2">
                                                        <Icon icon={item.icon} color={item.color} className="me-2" />
                                                        <div>{item.name}</div>
                                                    </FlexRow>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        
                    </div>
                }
            </div>
        </div>
    )
}