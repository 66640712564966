import { useEffect, useState } from "react";
import Job from "../models/Job";
import Modal from "../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import TextArea from "../../../../components/form/TextArea";


interface IProps {
    job?: Job
    onClose?: () => void
    onCancel?: () => void
    onUpdate?: (j: Job) => void
}
export default function JobReportIssue(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);


    return(
        <>
            <Modal id="modal-job-report-issue"
                title={job?.hasIssue && job.issueText ? "Update Issue" : "Report Issue"}
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                buttonText={job?.hasIssue && job.issueText ? "Update Issue" : "Report Issue"}
                buttonColor="danger"
                visible open staticBackdrop
                onOpen={() => {
                    $("#txt-issue-note-area").focus();
                }}
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    window.hideFormErrors();

                    if (body.issue == null || body.issue == "") {
                        window.showFormError("issue", "Please describe the issue!", true, "modal-job-report-issue");
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "reportissue", {
                        ...body,
                        id: job?.id
                    }, res => {
                        if(res.success) {
                            if (props.onUpdate) props.onUpdate(res.data);
                        }
                        if (callback) callback(res);
                    });
                    
                }}
                secondActionText={job?.hasIssue ? "Resolve Issue" : undefined}
                secondActionColor="success"
                onSecondAction={job?.hasIssue ? () => {
                    hub.Post(API_ROUTES.Jobs + "resolveissue", {
                        id: job?.id
                    }, res => {
                        if(res.success) {
                            if (props.onUpdate) props.onUpdate(res.data);
                            window.closeModal("modal-job-report-issue");
                        }
                    });
                } : undefined}> 
                    <div>
                        <TextArea id="txt-issue-note-area" label="Issue *" block labelUp name="issue" value={job?.issueText}
                             rows={5} placeholder="Please write what is wrong about this job..." />
                    </div>
            </Modal>
            
        </>
    )

}