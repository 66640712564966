import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import { AnnouncementColorTypes, AnnouncementDismissTypes } from "../../../../core/Enums";
import Announcement from "../models/Announcement"

interface IProps {
    item: Announcement
    onDismiss?: (item: Announcement) => void
}

export default function AnnouncementView(props: IProps) {

    const className = () => {
        var cls = "alert alert-";
        if (props.item.colorType == AnnouncementColorTypes.Danger) cls += "danger";
        else if (props.item.colorType == AnnouncementColorTypes.Warning) cls += "warning";
        else cls += "primary";

        return cls;
    }

    return (
        <div className={className()}>
            <FlexRow>
                <div>
                    <div className="fw-bolder fs-6">
                        {props.item.title}
                    </div>
                    <div className="fs-7 line-break">
                        {props.item.text}
                    </div>
                    {
                        props.item.showMyName &&
                        <FlexRow className="mt-5">
                            <Icon icon="user" size={10} type="solid" className="me-2" />
                            <span className="fs-8 mt-1">By: {props.item.creatorUser?.fullName}</span>
                        </FlexRow>
                    }
                </div>

                {
                    props.item.dismissType == AnnouncementDismissTypes.ByUser &&
                    <div className="ms-auto me-5 cursor-pointer"
                        onClick={e => {
                            e.preventDefault();
                            if (props.onDismiss) props.onDismiss(props.item);
                        }}>
                        <Icon icon="times" size={20} />
                    </div>
                }
            </FlexRow>
        </div>
    )
}