import { useEffect, useState } from "react";
import Section from "../../../../components/base/Section";
import Layout from "../../../../components/layouts/Layout";
import { getUser, setBottomComponents, setPage, setPageWithToolbarComponent, setToolbarActions, setToolbarComponent, showDefaultTechRateModal, useGlobal, useHub, usePreferences } from "../../../../core/Global";
import { DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from "../../../../core/Constants";
import techFilterProps from "../filtering/ratesTechs.json";
import sourceFilterProps from "../filtering/ratesSources.json";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import RateItem from "../models/RateItem";
import RatesFormModal from "../components/RatesFormModal";
import { DayOfWeek, RateDateTypes, RateRepeatTypes, RateCategories, RateAmountTypes, RateTypes } from "../../../../core/Enums";
import Div from "../../../../components/base/Div";
import { useParams } from "react-router-dom";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";


export default function RatesList() {

    const hub = useHub();
    const user = getUser();
    const pref = usePreferences();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [globalState] = useGlobal();

    const { cat } = useParams();
    useEffect(() => {
        if (cat == "techs") selectedRateCategorySet(RateCategories.Technician);
        if (cat == "sources") selectedRateCategorySet(RateCategories.Source);
    }, [cat])


    const [selectedItem, selectedItemSet] = useState<RateItem>();

    const [createModal, createModalSet] = useState<boolean>();
    const [editModal, editModalSet] = useState<boolean>();

    const [loaded, loadedSet] = useState<boolean>(false);

    const [selectedRateCategory, selectedRateCategorySet] = useState<RateCategories>(RateCategories.Technician);



    const DefaultTechRates = () => {

        return (
            <FlexRow>
                <span className="fw-bold">
                    Default Technicians Rate:
                </span>
                <span className="ms-2">
                    {pref?.defaultTechsRate}%
                </span>
                <a href="#" className="ms-2"
                    onClick={e => {
                        e.preventDefault();
                        showDefaultTechRateModal();
                    }}>
                    <Icon icon="edit" />
                </a>
            </FlexRow>
        )
    }

    const DefaultSourceRates = () => {

        const [defaultSourcesRate, defaultSourcesRateSet] = useState<string>();

        const fetchDefaultRates = () => {
            hub.Get(API_ROUTES.Rates + "defaultrate/sources", res => defaultSourcesRateSet(res.data));
        }

        useEffect(() => {
            fetchDefaultRates();
        }, [])

        return (<></>
            // <FlexRow>
            //     <span className="fw-bold">
            //         Default Sources Rate: 
            //     </span>
            //     <span className="ms-2">
            //         {defaultSourcesRate}%
            //     </span>
            //     <a href="#" className="ms-2"
            //         onClick={e=> {
            //             e.preventDefault();
            //         }}>
            //         <Icon icon="edit" />
            //     </a>
            // </FlexRow>
        )
    }


    useEffect(() => {

        loadedSet(false);
        setTimeout(() => {
            loadedSet(true);
            if (selectedRateCategory == RateCategories.Technician) {
                setPageWithToolbarComponent("Technician Rates", DefaultTechRates);
            }
            else if (selectedRateCategory == RateCategories.Source) {
                setPageWithToolbarComponent("Source Rates", DefaultSourceRates);
            }
        }, 200);
    }, [selectedRateCategory])

    const add = () => {
        createModalSet(true);
    }
    const edit = (data: any) => {
        selectedItemSet(data);
        editModalSet(true);
    }

    const [refresh, refreshSet] = useState<boolean>();
    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                refreshSet(false);
            }, 1000);
        }
    }, [refresh])

    const [bind, bindSet] = useState<any>();
    useEffect(() => {
        if (bind) {
            setTimeout(() => {
                bindSet(undefined);
            }, 1000);
        }
    }, [bind])

    const NameItem = (data: any) => (
        <FlexRow>
            <Div limitedText maxWidth={100} title={data.name}>
                {data.name}
            </Div>
            <a href="#" className="ms-2"
                onClick={e => {
                    e.preventDefault();
                    edit(data);
                }}>
                <Icon icon="edit" size={12} />
            </a>
        </FlexRow>
    )

    const RateItem = (data: RateItem) => (
        <div>
            {
                data.rateType == RateTypes.Percent ?
                    <span>{data.rate}%</span>
                    :
                    <span>{moneyFormatter.format(data.rate ?? 0)}</span>
            }
        </div>
    )

    const DateItem = (data: RateItem) => (
        <div>
            {
                data.dateType ?
                    <div>
                        {
                            data.dateType == RateDateTypes.SpecificDate ?
                                <FlexRow>
                                    {
                                        data.specificDates &&
                                        <>
                                            {
                                                data.specificDates.length > 2 ?
                                                    <>
                                                        {
                                                            data.specificDates.slice(0, 2).map((d, k) =>
                                                                <span key={k} className="badge badge-secondary me-1 fw-normal fs-8">
                                                                    <Icon icon="calendar" className="me-1" size={11} />
                                                                    {d.text}
                                                                </span>
                                                            )
                                                        }
                                                        <span className="badge badge-secondary fw-normal fs-8">
                                                            <Icon icon="ellipsis" size={11} />
                                                        </span>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            data.specificDates.map((d, k) =>
                                                                <span key={k} className="badge badge-secondary me-1 fw-normal fs-8">
                                                                    <Icon icon="calendar" className="me-1" size={11} />
                                                                    {d.text}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                            }
                                        </>
                                    }

                                </FlexRow>
                                : data.dateType == RateDateTypes.DateRange ?
                                    <FlexRow>
                                        {
                                            data.rangeDates &&
                                            <>
                                                {
                                                    data.rangeDates.length > 1 ?
                                                        <>
                                                            {
                                                                data.rangeDates.slice(0, 1).map((d, k) =>
                                                                    <span key={k} className="badge badge-secondary me-1 fw-normal fs-8">
                                                                        <Icon icon="calendar" className="me-1" size={11} />
                                                                        {d.text}
                                                                    </span>
                                                                )
                                                            }
                                                            <span className="badge badge-secondary fw-normal fs-8">
                                                                <Icon icon="ellipsis" size={11} />
                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                data.rangeDates.map((d, k) =>
                                                                    <span key={k} className="badge badge-secondary me-1 fw-normal fs-8">
                                                                        <Icon icon="calendar" className="me-1" size={11} />
                                                                        {d.text}
                                                                    </span>
                                                                )
                                                            }
                                                        </>
                                                }
                                            </>
                                        }

                                    </FlexRow>
                                    : <></>
                        }
                    </div>
                    :
                    <div className="text-muted">
                        [Unspecified]
                    </div>
            }
        </div>
    )

    const ParamsItem = (data: RateItem) => {

        const repeatTypeText = () => {
            if (data.repeatType == RateRepeatTypes.EveryMonth) return "Repeats Every Month";
            else if (data.repeatType == RateRepeatTypes.EveryYear) return "Repeats Every Year";
            else if (data.repeatType == RateRepeatTypes.Forever) return "Repeats Forever";
            else if (data.repeatType == RateRepeatTypes.SpecificDate) return "Repeats Till " + data.repeatTill?.text;
            else return "No Repeat";
        }
        return (
            <FlexRow>


                {
                    data.amount &&
                    <FlexRow className="badge badge-secondary me-2" title={`Job Amount ${data.amountType == RateAmountTypes.LessThan ? "Less Than" : "Greater Than or Equals To"} ${moneyFormatter.format(data.amount)}`}>
                        {
                            data.amountType == RateAmountTypes.LessThan ?
                                <Icon icon="less-than" className="me-2" />
                                : <Icon icon="greater-than-equal" className="me-2" />
                        }
                        <span className="badge badge-light me-1">
                            {moneyFormatter.format(data.amount)}
                        </span>
                    </FlexRow>
                }

                {
                    data.techGroups && data.techGroups.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Technician Group(s)">
                        <Icon icon="user-group" className="me-2" />
                        {
                            data.techGroups.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.name}</span>
                            )
                        }
                    </FlexRow>
                }
                {
                    data.techs && data.techs.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Technician(s)">
                        <Icon icon="user-helmet-safety" className="me-2" />
                        {
                            data.techs.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.fullName}</span>
                            )
                        }
                    </FlexRow>
                }

                {
                    selectedRateCategory == RateCategories.Technician && (data.techGroups?.length ?? 0) == 0 && (data.techs?.length ?? 0) == 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Technician(s)">
                        <Icon icon="user-helmet-safety" className="me-2" />
                        <span className="badge badge-light me-1">Any Technician</span>
                    </FlexRow>
                }

                {
                    data.jobSources && data.jobSources.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Job Source(s)">
                        <Icon icon="bullhorn" className="me-2" />
                        {
                            data.jobSources.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.name}</span>
                            )
                        }
                    </FlexRow>
                }

                {
                    selectedRateCategory == RateCategories.Source && (data.jobSources?.length ?? 0) == 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Job Source(s)">
                        <Icon icon="bullhorn" className="me-2" />
                        <span className="badge badge-light me-1">Any Source</span>
                    </FlexRow>
                }

                {
                    data.metros && data.metros.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Metro(s)">
                        <Icon icon="map" className="me-2" />
                        {
                            data.metros.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.name}</span>
                            )
                        }
                    </FlexRow>
                }
                {
                    data.jobTypes && data.jobTypes.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Job Type(s)">
                        <Icon icon="wrench" className="me-2" />
                        {
                            data.jobTypes.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.name}</span>
                            )
                        }
                    </FlexRow>
                }
                {
                    data.shifts && data.shifts.length > 0 &&
                    <FlexRow className="badge badge-secondary me-2" title="Job Type(s)">
                        <Icon icon="calendar-clock" className="me-2" />
                        {
                            data.shifts.map((item, key) =>
                                <span key={key} className="badge badge-light me-1">{item.name}</span>
                            )
                        }
                    </FlexRow>
                }

                {
                    data.dateType ?
                        <>
                            {
                                data.dateType == RateDateTypes.SpecificDate && data.specificDates &&
                                <FlexRow className="badge badge-secondary me-2" title={`"Date(s) - ${repeatTypeText()}"`}>
                                    {
                                        (data.repeatType == RateRepeatTypes.NoRepeat || data.repeatType == RateRepeatTypes.None) ?
                                            <Icon icon="calendar" className="me-2" />
                                            :
                                            <Icon icon="calendar-clock" className="me-2" />
                                    }

                                    {
                                        data.specificDates.map((item, key) =>
                                            <span key={key} className="badge badge-light me-1">{item.text}</span>
                                        )
                                    }
                                </FlexRow>
                            }
                            {
                                data.dateType == RateDateTypes.DateRange && data.rangeDates &&
                                <FlexRow className="badge badge-secondary me-2" title={`Date(s) - ${repeatTypeText()}`}>
                                    {
                                        (data.repeatType == RateRepeatTypes.NoRepeat || data.repeatType == RateRepeatTypes.None) ?
                                            <Icon icon="calendar" className="me-2" />
                                            :
                                            <Icon icon="calendar-clock" className="me-2" />
                                    }
                                    {
                                        data.rangeDates.map((item, key) =>
                                            <span key={key} className="badge badge-light me-1">{item.text}</span>
                                        )
                                    }
                                </FlexRow>
                            }
                            {
                                data.dateType == RateDateTypes.WeekDays && data.weekDays &&
                                <FlexRow className="badge badge-secondary me-2" title={`Date(s) - ${repeatTypeText()}`}>
                                    {
                                        (data.repeatType == RateRepeatTypes.NoRepeat || data.repeatType == RateRepeatTypes.None) ?
                                            <Icon icon="calendar" className="me-2" />
                                            :
                                            <Icon icon="calendar-clock" className="me-2" />
                                    }
                                    {
                                        data.weekDays.map((item, key) =>
                                            <span key={key} className="badge badge-light me-1">{DayOfWeek[item]}</span>
                                        )
                                    }
                                </FlexRow>
                            }
                        </>
                        : <></>
                }
            </FlexRow>
        )
    }

    const UserItem = (data: any) => (
        <div>
            <div>
                {data.ownerUser?.fullName ?? "System Tag"}
            </div>
            {
                data.creatorUser &&
                <div className='d-flex align-items-center fs-8 mt-1'>
                    <Icon icon="user" className="me-1" size={12} />
                    <span>
                        created by: {data.creatorUser?.username}
                    </span>
                </div>
            }
        </div>
    )

    const RepeatItem = (data: RateItem) => {

        const repeatTypeText = () => {
            if (data.repeatType == RateRepeatTypes.EveryMonth) return "Every Month";
            else if (data.repeatType == RateRepeatTypes.EveryYear) return "Every Year";
            else if (data.repeatType == RateRepeatTypes.Forever) return "Forever";
            else if (data.repeatType == RateRepeatTypes.SpecificDate) return "Till " + data.repeatTill?.text;
            else return "No Repeat";
        }

        return (
            <div>
                {repeatTypeText()}
            </div>
        )
    }

    const DescriptionItem = (data: RateItem) => {

        useEffect(() => {
            window.createBootstrapTooltip("rate-descr-" + data.id);
        }, [])

        return (
            <div id={`rate-descr-${data.id}`} data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-placement="top" title={data.description}
                className="w-150px limited-text">
                {data.description}
            </div>
        )
    }

    const PriorityToggle = (data: any) => (
        <div>
            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input className="form-check-input" type="checkbox" defaultChecked={data.highPriority}
                    onChange={e => {
                        hub.Post(API_ROUTES.Rates + "prioritytoggle", {
                            id: data.id,
                            priority: e.target.checked ? "1" : "0"
                        }, res => {
                        })
                    }} />
            </label>
        </div>
    )

    return (
        <Layout wide>
            <Section fluid>

                {
                    loaded &&
                    <>
                        <DataTable
                            objectName="Rate"
                            apiRoute={API_ROUTES.Rates}
                            extraParams={[{
                                name: "rateCategory",
                                value: selectedRateCategory
                            }]}
                            filteringProps={selectedRateCategory == RateCategories.Technician ? techFilterProps : sourceFilterProps}
                            edit delete
                            addCustom={add} editCustom={edit}
                            refresh={refresh}>

                            <DataTableField title="" width={50}>
                                <DataTableActiveToggle route={API_ROUTES.Rates} />
                            </DataTableField>

                            <DataTableField title="Name">
                                <NameItem />
                            </DataTableField>

                            <DataTableField title="Rate">
                                <RateItem />
                            </DataTableField>

                            <DataTableField title="Parameters">
                                <ParamsItem />
                            </DataTableField>

                            <DataTableField title="Priority">
                                <PriorityToggle />
                            </DataTableField>

                            <DataTableField title="Description">
                                <DescriptionItem />
                            </DataTableField>

                            <DataTableField title="Created At" dataValueName="createDate" />
                        </DataTable>
                    </>

                }

            </Section>


            {
                createModal &&
                <RatesFormModal rateCategory={selectedRateCategory}
                    onClose={() => createModalSet(false)}
                    onSave={() => refreshSet(true)} />
            }

            {
                editModal && selectedItem &&
                <RatesFormModal rateCategory={selectedRateCategory} model={selectedItem}
                    onClose={() => editModalSet(false)}
                    onSave={() => refreshSet(true)} />
            }



        </Layout>
    )
}