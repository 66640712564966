import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import Modal from "../../../components/base/Modal";
import { toastError } from "../../../components/base/Toast";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { API_ROUTES } from "../../../core/Constants";
import { toPrettyDate, updateUsersOn, useHub } from "../../../core/Global";
import User from "../models/User";


interface IProps {
    user: User
    onClose: () => void
}

export default function CustomersChargeWalletModal(props: IProps) {

    const hub = useHub();



    return (
        <Modal id="customers-charge-wallet-modal"
            title="Charge Wallet"
            open visible
            onClose={props.onClose}
            buttonText="Charge Wallet"
            buttonColor="success"
            onSubmit={(body, callback) => {

                hub.Post(API_ROUTES.Customers + "chargewallet", {
                    ...body,
                    id: props.user.id
                }, res => {
                    if (res.success) {
                        callback!(res)
                        updateUsersOn()
                    }
                    else {
                        toastError(res.message)
                    }
                })

            }}>
            <div>
                <FlexRow className="mb-2">
                    <Icon icon="user" type="solid" className="me-4" size={18} />
                    <span className="fs-3 mt-1">{props.user.fullName}</span>
                </FlexRow>
                <FlexRow className="mb-10">
                    <Icon icon="info-circle" type="solid" className="me-2" size={14} />
                    <span className="fs-7">
                        Charge customer wallet regarding his initial budget!
                    </span>
                </FlexRow>
                <ToggleSwitch name="freecharge" label="Add amount without charging!" raw small />
            </div>
        </Modal>
    )
}