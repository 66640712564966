import React, { useEffect, useState } from 'react';
import Form from '../../../components/form/Form';
import { LoginValidator } from '../validators/LoginValidator';
import AuthRequest from '../../../models/AuthRequest';
import Button from '../../../components/base/Button';
import Icon from '../../../components/base/Icon';
import Hub from '../../../api/Hub';
import User from '../../accounts/models/User';
import { API_ROUTES } from '../../../core/Constants';
import HUBResponse from '../../../models/HUBResponse';
import Alert from '../../../components/base/Alert';
import { Link, useParams } from 'react-router-dom';
import Div from '../../../components/base/Div';
import { UserTypes } from 'src/core/Enums';
import { setOwnerUserId, setUserId, setUserToken, useEnvironment, useHub, usePreferences } from '../../../core/Global';
import { ListItem } from '../../../core/Types';
import Tagify from '../../../components/tagify/Tagify';
import Avatar from '../../../components/base/Avatar';
import DataItem from '../../../models/DataItem';
import FlexRow from '../../../components/base/FlexRow';
import Countdown from '../../../components/timer/Countdown';
import DropDown from '../../../components/form/DropDown';
import { TagifyDataItem } from '../../../models/TagifyOptions';

let user: User;
type Props = {
    auto?: boolean
    redirectUrl?: string
}
function LoginPage(props: Props) {

    const { id } = useParams();
    const { token } = useParams();
    const pref = usePreferences();

    const [working, workingSet] = useState<boolean>(false);
    const [authenticated, authenticatedSet] = useState<boolean>(false);
    const [apiError, apiErrorSet] = useState<string>();
    const [stepLogin, stepLoginSet] = useState<boolean>(true);
    const [stepExt, stepExtSet] = useState<boolean>(false);
    const [freeExts, freeExtsSet] = useState<ListItem[]>([]);
    const [selectedExts, selectedExtsSet] = useState<ListItem[]>([]);
    const [googleAuthWorking, googleAuthWorkingSet] = useState<boolean>(false);
    const [authUser, authUserSet] = useState<User>();

    const [queuesRouteParams, queuesRouteParamsSet] = useState<ListItem[]>();
    const [departments, departmentsSet] = useState<DataItem[]>([]);
    const [stations, stationsSet] = useState<TagifyDataItem[]>([]);
    const [selectedDepartment, selectedDepartmentSet] = useState<DataItem>();
    const [nearestShift, nearestShiftSet] = useState<any>();

    const hub = useHub();
    const env = useEnvironment();

    const landingPage = () => {
        if (env.brand == "rinvox") return "/boards/jobs";
        return undefined;
    }

    const submit = async (body: AuthRequest, callback: any) => {
        workingSet(true);
        apiErrorSet(undefined);
        hub.Post(API_ROUTES.Authenticate, body, (res: HUBResponse<any>) => {

            if (res.success && res.data && res.data.user && res.data.user.token) {
                success(res);
            }
            else if (res.success && res.data?.nearestShift) {
                nearestShiftSet(res.data.nearestShift);
                workingSet(false);
            }
            else {
                apiErrorSet(res.message);
                workingSet(false);
            }

        });
    }

    const autoSubmit = async () => {
        workingSet(true);
        apiErrorSet(undefined);
        googleAuthWorkingSet(true);
        hub.Post(API_ROUTES.AuthenticateAuto, {
            id: id,
            token: token
        }, (res: HUBResponse<any>) => {
            if (res.success && res.data && res.data.user && res.data.user.token) {
                success(res);
            }
            else {
                apiErrorSet(res.message);
                workingSet(false);
            }
        });
    }

    const success = (res: any) => {
        user = res.data.user;
        authUserSet(res.data.user);
        setUserToken(res.data.user.token);
        setUserId(res.data.user.id);
        if (res.data.user.isSuperAdmin) setOwnerUserId("admins");
        else if (res.data.user.isCustomer) setOwnerUserId(res.data.user.id);
        else if (res.data.user.ownerUserId) setOwnerUserId(res.data.user.ownerUserId);

        if (props.redirectUrl) {
            authenticatedSet(true);
            document.location.href = props.redirectUrl;
        }
        else if (res.data.user.userType == UserTypes.Agent) {

            if (!res.data.pref.forceAgentLoginSelectExt || (res.data.user.extensions && res.data.user.extensions.length > 0)) {
                authenticatedSet(true);
                document.location.href = landingPage() ?? "/agents/view/" + res.data.user.id;
            }
            else {

                workingSet(false);
                stepLoginSet(false);
                stepExtSet(true);

                queuesRouteParamsSet([{
                    name: "userId",
                    value: res.data.user.ownerUserId
                }]);

                if (res.data.deps && res.data.deps.length > 0) {
                    departmentsSet(res.data.deps);
                    if (res.data.deps.length == 1) selectedDepartmentSet(res.data.deps[0]);
                }

            }
        }
        else {
            authenticatedSet(true);

            if (res.data.signup) {
                document.location.href = "/join/" + res.data.signupToken
            }
            else {
                document.location.href = landingPage() ?? "/dashboard";
            }
        }
    }

    const backToLogin = () => {
        authUserSet(undefined);
        setUserToken("");
        setUserId("");
        document.location.href = "/auth/login";
    }

    const skipExts = () => {
        authenticatedSet(true);
        document.location.href = landingPage() ?? "/dashboard";
    }

    const selectExt = async (e: any) => {
        workingSet(true);
        var exts = "";
        for (var i = 0; i < selectedExts.length; i++) {
            if (exts != "") exts += ",";
            exts += selectedExts[i].value;
        }
        hub.Post(API_ROUTES.ClaimExtension, {
            exts: exts,
            userId: user.id,
            action: "login"
        }, (res: HUBResponse<any>) => {
            if (res.success) {
                authenticatedSet(true);
                document.location.href = "/agents/view/" + user.id;
            }
            else {
                apiErrorSet(res.message);
                workingSet(false);
            }
        });
    }

    const fetchStations = async (depId: string) => {
        hub.Get(API_ROUTES.TagifyQueues + "?depId=" + depId, res => {
            if (res.data) stationsSet(res.data);
        })
    }

    const fetchExts = async (quId?: string) => {
        hub.Get("/system/extensions/freebyqueue/" + quId, res => {
            selectedExtsSet([]);
            freeExtsSet(res.data);
        })
    }

    useEffect(() => {
        selectedExtsSet([]);
        freeExtsSet([]);
        stationsSet([]);
        if (selectedDepartment?.id) fetchStations(selectedDepartment.id)
    }, [selectedDepartment])

    useEffect(() => {
        if (props.auto) autoSubmit();
        window.KTPasswordMeterCreate();
    }, [])


    return (
        <Form id='form-login' className='form w-100' validator={LoginValidator} onSubmit={submit}>
            <Div visible={stepLogin} className="text-center mb-5">
                <h1 className="text-dark mb-3">Sign In</h1>
            </Div>
            <Div visible={stepExt} className="text-center mb-5">
                <Avatar avatarUrl={authUser?.avatarUrl} width={70} />
                <h1 className="text-dark mb-0">Welcome {authUser?.fullName}</h1>
                <div className='border-bottom pb-3'>{authUser?.email}</div>
                <h1 className="text-dark mb-1 mt-5 fs-3">Select Extensions</h1>
                <div>You can select up to 3 extensions!</div>
            </Div>

            <Div visible={apiError ? true : false} className="fv-row mb-5">
                <Alert text={apiError} type='danger' />
            </Div>

            <Div visible={authenticated} className="fv-row mb-5">
                <Alert text="Redirecting to dashboard..." type='success' />
            </Div>

            <Div visible={stepLogin}>

                {
                    nearestShift &&
                    <div className='mb-5'>
                        <div className='text-center fw-bold mb-2'>
                            Your next shift will start in
                        </div>
                        <FlexRow className='alert alert-primary'>
                            <div className="d-flex flex-column">
                                <h4 className="mb-1 text-dark">{nearestShift.name}</h4>
                                <div>
                                    {nearestShift.startDate}
                                </div>
                            </div>
                            <FlexRow className='ms-auto fs-1 fw-bolder'>
                                <Icon icon='clock' type='solid' size={24} className='me-2' />
                                <Countdown totalSeconds={nearestShift.countdown} showHours width={100} />
                            </FlexRow>
                        </FlexRow>
                    </div>
                }

                <div className="fv-row mb-5">
                    <label className="form-label fs-6 fw-bolder text-dark" htmlFor='txtusername'>
                        Username
                        <span id="username-err" className="small text-danger mx-2 form-error"></span>
                    </label>
                    <input className="form-control form-control-solid" id="txtusername"
                        placeholder='Username or Email Address' autoFocus tabIndex={1}
                        type="email" name="username" autoComplete='Off' />
                </div>

                <div className="fv-row mb-5" data-kt-password-meter="true">
                    <div className="d-flex align-items-center flex-stack">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0" htmlFor='txtpassword'>
                            Password
                            <span id="password-capslock" className="small text-danger mx-2 form-error">CapsLock is On</span>
                            <span id="password-err" className="small text-danger mx-2 form-error"></span>
                        </label>
                        <a href="/auth/password/forget" className="link-primary fs-8 fw-bolder">Forgot Password?</a>
                    </div>
                    <div className="position-relative mb-3">
                        <input className="form-control form-control-solid" id="txtpassword"
                            placeholder='Your Password' tabIndex={2} type="password" name="password" autoComplete="off"
                            onKeyUp={e => {
                                if (e.getModifierState("CapsLock")) {
                                    $("#password-capslock").fadeIn().css("display", "inline-block");
                                }
                                else {
                                    $("#password-capslock").hide();
                                }
                            }} />
                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                            <i className="bi bi-eye-slash fs-2"></i>
                            <i className="bi bi-eye fs-2 d-none"></i>
                        </span>
                        {/* <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" 
                        onMouseDown={e=> $("#txtpassword").attr("type", "text")}
                        onMouseUp={e=> $("#txtpassword").attr("type", "password")}>
                        <i className="bi bi-eye fs-2"></i>
                    </span> */}
                    </div>

                </div>

                <div className="text-center">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-primary w-100 mb-5" disabled={working}>
                        {!working && <span className="indicator-label">Login to Account</span>}
                        {
                            working &&
                            <span className="indicator-btn">
                                <Icon icon='asterisk' spin />
                                <span className='ms-2'>
                                    Authenticating...
                                </span>
                            </span>
                        }
                    </button>
                    {/* <div className="text-center text-muted text-uppercase fw-bolder mb-5">or</div>
                <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />Continue with Google</a>
                <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                <img alt="Logo" src="assets/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3" />Continue with Facebook</a>
                <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100">
                <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="h-20px me-3" />Continue with Apple</a> */}
                </div>

                {
                    env.signUp &&
                    <>
                        <div className="d-flex align-items-center mb-5">
                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                            <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        </div>

                        <div className='text-center mb-5'>

                            <button type='button' className="btn btn-light-primary btn-sm fw-bolder w-100" disabled={googleAuthWorking}
                                onClick={e => {
                                    googleAuthWorkingSet(true);
                                    hub.Post("/auth/google/authlink", {}, res => {
                                        if (res.success) {
                                            document.location.href = res.data;
                                        }
                                        else {
                                            googleAuthWorkingSet(false);
                                        }
                                    })
                                }}>
                                <img src="/assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />
                                Continue with Google
                            </button>

                        </div>
                        <div className='text-center mb-5'>
                            <a href="/join" className="btn btn-light-info btn-sm fw-bolder w-100">
                                Sign Up With Email
                            </a>
                        </div>
                    </>
                }



            </Div>

            <Div visible={stepExt}>

                {
                    env.flexibleDispatchers ?
                        <div className='mb-5'>
                            {
                                queuesRouteParams &&
                                <Tagify apiUrl={API_ROUTES.TagifyQueues} placeholder="Select Your Station"
                                    id="tagifyPickQueue" name="queueId" block single routeParams={queuesRouteParams}
                                    onSelect={val => {
                                        fetchExts(val);
                                    }} />
                            }
                        </div>
                        :
                        <>

                            <div className='mb-5'>
                                {
                                    departments.length > 1 &&
                                    <DropDown id='drp-departments' placeholder='Select Department' source={departments}
                                        dataText='name' dataValue='id' block
                                        onSelectItem={item => selectedDepartmentSet(item)}>
                                    </DropDown>
                                }

                                {
                                    departments.length == 1 && selectedDepartment &&
                                    <FlexRow className='alert alert-primary'>
                                        <Icon icon='briefcase' type='solid' size={18} className='me-3' />
                                        <div className='fw-bolder fs-6'>
                                            {selectedDepartment.name}
                                        </div>
                                    </FlexRow>
                                }
                            </div>
                            <div className='mb-5'>
                                {
                                    stations.length == 0 &&
                                    <DropDown placeholder='Loading...' block />
                                }

                                {
                                    stations.length > 0 &&
                                    <DropDown id='drp-queues' placeholder='Select Station' block
                                        source={stations} dataText='title' dataValue='value'
                                        onSelect={val => fetchExts(val)} />
                                }

                            </div>

                        </>

                }

                <div className='mb-10'>
                    {
                        freeExts.map((item, key) =>
                            <button key={key}
                                onClick={e => {
                                    e.preventDefault();
                                    var s = selectedExts ?? [];
                                    if (s.indexOf(item) > -1) {
                                        s.splice(s.indexOf(item), 1);
                                    }
                                    else {
                                        if (s.length >= 3) return;
                                        s.push(item);
                                    }
                                    selectedExtsSet(m => [...s]);
                                }}
                                className={'btn ' + (selectedExts.indexOf(item) > -1 ? "btn-primary" : "btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary") + ' me-2 mb-2'}>
                                <Icon icon='phone-office' className='me-2' />
                                {item.name}
                            </button>
                        )
                    }
                </div>
                <div className='text-center'>
                    <button type="submit" className="btn btn-lg btn-primary w-100 mb-5"
                        onClick={selectExt}
                        disabled={working || selectedExts.length < 1}>
                        {!working && <span className="indicator-label">Continue</span>}
                        {
                            working &&
                            <span className="indicator-btn">
                                <Icon icon='asterisk' spin />
                                <span className='ms-2'>
                                    Please wait...
                                </span>
                            </span>
                        }
                    </button>
                </div>
                <div className='text-center'>
                    <button type="button" className="btn btn-lg btn-secondary w-100 mb-5" onClick={skipExts}>
                        Skip
                        <Icon icon='chevrons-right' className='ms-2' />
                    </button>
                </div>
                <div className='text-center'>
                    <button type="button" className="btn btn-lg btn-light w-100 mb-5" onClick={backToLogin}>
                        <Icon icon='chevron-left' className='me-2' />
                        Back to Login
                    </button>
                </div>

            </Div>

            <div className='text-center fs-7'>
                version: {env.versionName}
            </div>
        </Form>
    );
}

export default LoginPage;
