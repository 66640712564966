import React, { useEffect, useRef } from "react"
import HeaderSub from './header/HeaderSub';
import HeaderTools from "./header/HeaderTools";
type LayoutProps = {
    children: any
    wide?: boolean
    container?: boolean
    paddingTop?: number
}
function Layout(props: LayoutProps) {
  
	
  useEffect(() => {
    if (props.paddingTop) {
      //ContentDiv.current?.style.setProperty("padding-top", props.paddingTop + props.paddingTop > 0 ? "px": "", "important")
    }
  }, [props.paddingTop])

  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content"
      style={{
            paddingTop: props.paddingTop
          }}>
        <HeaderTools />
        {
          props.wide &&
          <div id="kt_content_container">
              {props.children}
          </div>
        }
        {
          !props.wide &&
          <div className="post d-flex flex-column-fluid" id="kt_post">
              <div id="kt_content_container" className="container-xxl">
                  {props.children}
              </div>
          </div>
        }
    </div>
  );
}

export default Layout;
