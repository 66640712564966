import { Link, useLocation, useNavigate } from "react-router-dom"
import Job from "../models/Job"
import Icon from "../../../../components/base/Icon"
import { useEffect, useState } from "react"
import FlexRow from "../../../../components/base/FlexRow"
import { createJob, editJob, hasRole, openCopyJob, setChatJob, useCashedData, useHub, useStatusesForJob } from "../../../../core/Global"
import Modal from "../../../../components/base/Modal"
import DataItem from "../../../../models/DataItem"
import { API_ROUTES } from "../../../../core/Constants"
import Tagify from "../../../../components/tagify/Tagify"
import Timer from "../../../../components/timer/Timer"
import JobReportIssue from "./JobReportIssue"
import JobStatus from "../../base/models/JobStatus"
import DropdownMenu from "../../../../components/menu/DropdownMenu"
import { copyToClipboard } from "../../../../core/Helper"
import JobClosePaymentModal from "./jobmodals/JobClosePaymentModal"
import JobSendModal from "./JobModalTemp"
import JobSendTechRespondModal from "./jobmodals/JobSendTechRespondModal"
import JobCloseNotValidModal from "./jobmodals/JobCloseNotValidModal"
import JobUpdateStatusNoteModal from "./jobmodals/JobUpdateStatusNoteModal"
import JobAppointmentModal from "./jobmodals/JobAppointmentModal"


type IProps = {
    selectedPage?: string
    job: Job
}
export default function JobDetailsHeader(props: IProps) {

    const hub = useHub();
    const cash = useCashedData();
    const navigate = useNavigate();
    const [closeJob, closeJobSet] = useState<boolean>(false);
    const [closeNotValid, closeNotValidSet] = useState<boolean>(false);
    const [closeNotValidErr, closeNotValidErrSet] = useState<string>();
    const [updateAppointment, updateAppointmentSet] = useState<boolean>(false);
    const [updateStatus, updateStatusSet] = useState<boolean>(false);
    const [sendJob, sendJobSet] = useState<boolean>(false);
    const [manageTechResponse, manageTechResponseSet] = useState<boolean>(false);

    const [removeFlag, removeFlagSet] = useState<boolean>(false);
    const [selectedFlag, selectedFlagSet] = useState<DataItem>();


    const [addFlags, addFlagsSet] = useState<boolean>(false);
    const [addedFlags, addedFlagsSet] = useState<string[]>([]);


    const [resetStatusTime, resetStatusTimeSet] = useState<boolean>(false);
    useEffect(() => {
        if (resetStatusTime) {
            setTimeout(() => {
                resetStatusTimeSet(false);
            }, 1000);
        }
    }, [resetStatusTime])

    const statuses = useStatusesForJob()
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();

    useEffect(() => {
        if (selectedStatus && selectedStatus.id != props.job?.jobStatusId) {
            //console.log("status update from job details header", selectedStatus);

            if (selectedStatus.isAppointment && window.isNullOrEmpty(props.job?.appointmentDate)) {
                updateAppointmentSet(true);
            }
            else if (selectedStatus.isClosed) {
                hub.Get(API_ROUTES.Jobs + "validateclosing/" + props.job?.id, res => {
                    if (res.data) {
                        closeJobSet(true);
                    }
                    else {
                        closeNotValidSet(true);
                        closeNotValidErrSet(res.message);
                    }
                })
            }
            else if (selectedStatus.noteRequired) {
                updateStatusSet(true);
            }
            else {

                hub.Post(API_ROUTES.Jobs + "jobstatus", {
                    id: props.job?.id,
                    status: selectedStatus.id
                }, res => {
                    if (res.success) {
                        resetStatusTimeSet(true);
                        selectedStatusSet(undefined);
                    }
                });
            }
        }
    }, [selectedStatus])


    useEffect(() => {
        window.dataTableMenu();
    }, [statuses])

    useEffect(() => {

        if (props.job) {

            var t: string[] = [];

            props.job.flagIds?.forEach(e => {
                if (t.indexOf(e) < 0) t.push(e);
            });
            props.job.tagIds?.forEach(e => {
                if (t.indexOf(e) < 0) t.push(e);
            });

            props.job.flags?.forEach(f => {
                window.createBootstrapTooltip(`job-flags-${props.job.id}-${f.id}`);
            });

        }
    }, [props.job])



    const [jobSentColor, jobSentColorSet] = useState<string>("success");
    const [settleWorking, settleWorkingSet] = useState<boolean>(false);

    const [inProgErrModal, inProgErrModalSet] = useState<boolean>(false);
    const [inProgErrText, inProgErrTextSet] = useState<string>("");

    const [reportIssueModal, reportIssueModalSet] = useState<boolean>(false);


    return (
        <>
            <div className="card mb-5">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-1">
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-4">
                                        <a className="me-2 pe-3 mt-1" href="#" onClick={e => {
                                            e.preventDefault();
                                            if (window.history.length > 1) {
                                                navigate(-1);
                                            } else {
                                                navigate("/boards/jobs");
                                            }
                                        }}>
                                            <Icon icon="chevron-left" className="fs-3 fw-bolder text-gray-900" />
                                        </a>
                                        <a className="text-gray-900 fs-2 fw-bolder me-5">
                                            Job #{props.job?.jobId}
                                        </a>
                                        <FlexRow>
                                            {
                                                props.job?.flags?.map((item, k) =>
                                                    <span id={`job-flags-${props.job.id}-${item.id}`} key={k} className="badge badge-secondary me-1"
                                                        data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" data-bs-custom-class="tooltip-dark"
                                                        title={`${window.isNullOrEmpty(item.description) ? `` : `<div class='fs-8 fw-normal text-start line-break'>${item.description}</div>`}`}
                                                        style={{
                                                            fontSize: 11,
                                                            backgroundColor: item.color,
                                                            color: "#fff"
                                                        }}>
                                                        {item.name}
                                                        {
                                                            (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                                            <a href="#" className="ps-2" title="Remove Tag"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    selectedFlagSet(item);
                                                                    removeFlagSet(true);
                                                                }}>
                                                                <Icon icon="times" className="text-white fs-8" />
                                                            </a>
                                                        }
                                                    </span>
                                                )
                                            }

                                            {
                                                (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&

                                                <a href="#" onClick={e => {
                                                    e.preventDefault();
                                                    addFlagsSet(true);
                                                }}
                                                    className="badge badge-secondary">
                                                    <Icon icon="plus" size={10} />
                                                    {
                                                        (props.job?.flags?.length ?? 0) == 0 &&
                                                        <span className="ms-1 fs-9 fw-bold">
                                                            Add Flag
                                                        </span>
                                                    }
                                                </a>
                                            }
                                        </FlexRow>
                                    </div>
                                    <FlexRow>
                                        <span className="me-4">
                                            <Icon icon="user" type="solid" className="me-1" size={12} />
                                            <span className="fs-8">{props.job?.fullName}</span>
                                        </span>
                                        <span>
                                            <Icon icon="phone-rotary" type="solid" className="me-1" size={12} />
                                            <span className="fs-8">{props.job?.phone?.phoneFriendly}</span>
                                        </span>
                                    </FlexRow>
                                </div>
                                <div className="d-flex">

                                    {
                                        props.job.hasIssue &&
                                        <button className="btn btn-sm btn-danger me-2"
                                            onClick={e => {
                                                e.preventDefault();
                                                reportIssueModalSet(true);
                                            }}>
                                            <Icon icon="circle-exclamation" />
                                            Issue Reported
                                        </button>
                                    }

                                    {
                                        props.job.jobStatus &&
                                        <div className="me-2">
                                            <button type="button" className="btn btn-bg-primary text-start btn-color-white btn-sm pe-0 ps-3"
                                                style={{
                                                    backgroundColor: props.job.jobStatus.color
                                                }}
                                                disabled={!(!selectedStatus)}
                                                data-kt-menu-trigger="click"
                                                data-kt-menu-placement="bottom-start">
                                                {
                                                    selectedStatus ?
                                                        <>
                                                            <Icon icon="spinner-third" spin />
                                                            <span className="ms-2">
                                                                Updating status...
                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>
                                                                {props.job.jobStatus.name}
                                                            </span>
                                                        </>
                                                }

                                                {
                                                    props.job.isArchived ? <></>
                                                        :
                                                        <>
                                                            <Icon icon="clock" size={11} className="ms-4" />
                                                            <span className="fs-8">
                                                                <Timer baseDate={props.job.lastStatusUpdatedAt} short minWidth={40} reset={resetStatusTime} />
                                                            </span>
                                                        </>
                                                }

                                                <span className="svg-icon svg-icon-5 rotate-180 float-end ms-2 me-2" style={{ marginTop: 2 }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                                                    </svg>
                                                </span>


                                            </button>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                                <div className="menu-item">
                                                    <a href="#" className="menu-link px-3"

                                                        onClick={e => {
                                                            e.preventDefault();

                                                            hub.Post(API_ROUTES.Jobs + "refreshstatus", {
                                                                id: props.job.id
                                                            }, res => {

                                                            });
                                                        }}>
                                                        <Icon icon="redo" color={props.job.jobStatus?.color} className="me-2" />
                                                        Refresh Status
                                                    </a>
                                                </div>
                                                {
                                                    statuses.map((item, key) =>
                                                        <>
                                                            {
                                                                item.id == props.job.jobStatusId ? <></>
                                                                    :
                                                                    <div key={key} className="menu-item">
                                                                        <a href="#" className="menu-link px-3" onClick={e => {
                                                                            e.preventDefault();
                                                                            selectedStatusSet(item);
                                                                        }}>
                                                                            <Icon icon="circle" type="solid" className="me-2" color={item.color} />
                                                                            {item.name}
                                                                        </a>
                                                                    </div>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        props.job.isClosed ?
                                            <>
                                                <div>
                                                    <button type="button" className={`btn text-start ${(props.job.isSetteled ? "btn-success" : "btn-warning")} btn-sm pe-0 ps-3 me-2`}
                                                        data-kt-menu-trigger="click"
                                                        data-kt-menu-placement="bottom-start">
                                                        <FlexRow>
                                                            <div className="me-2">
                                                                {
                                                                    settleWorking ? <Icon icon="spinner-third" spin className="p-0 m-0" />
                                                                        : props.job.isSetteled ? <Icon icon="check" className="p-0 m-0" />
                                                                            : <Icon icon="info-circle" className="p-0 m-0" />
                                                                }
                                                            </div>
                                                            <div className="me-3">
                                                                {props.job.isSetteled ? "Job Settled" : "Job is Unsettle"}
                                                            </div>
                                                            <div className="me-2">
                                                                <span className="svg-icon svg-icon-5 rotate-180 float-end" style={{ marginTop: 2 }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </FlexRow>
                                                    </button>
                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                                        {
                                                            props.job.isSetteled ?
                                                                <div className="menu-item">
                                                                    <a href="#" className="menu-link px-3" onClick={e => {
                                                                        e.preventDefault();
                                                                        settleWorkingSet(true);
                                                                        setTimeout(() => {
                                                                            hub.Post(API_ROUTES.Jobs + "unsettlejob", {
                                                                                id: props.job.id
                                                                            }, res => {
                                                                                if (res.success) settleWorkingSet(false);
                                                                            })
                                                                        }, 200);

                                                                    }}>
                                                                        <Icon icon="circle" type="solid" className="me-2 text-warning" />
                                                                        Unsettle Job
                                                                    </a>
                                                                </div>
                                                                :
                                                                <div className="menu-item">
                                                                    <a href="#" className="menu-link px-3" onClick={e => {
                                                                        e.preventDefault();
                                                                        settleWorkingSet(true);
                                                                        setTimeout(() => {
                                                                            hub.Post(API_ROUTES.Jobs + "settlejob", {
                                                                                id: props.job.id
                                                                            }, res => {
                                                                                if (res.success) settleWorkingSet(false);
                                                                            })
                                                                        }, 200);
                                                                    }}>
                                                                        <Icon icon="circle" type="solid" className="me-2 text-success" />
                                                                        Settle Job
                                                                    </a>
                                                                </div>
                                                        }

                                                    </div>
                                                </div>

                                            </>
                                            : props.job.isCanceled ? <></> :
                                                <>
                                                    {
                                                        props.job?.sentToTech ?
                                                            <>
                                                                {
                                                                    props.job.techAccepted ?
                                                                        <button className={"btn btn-sm me-2 fs-8 btn-success"}>
                                                                            <Icon icon="share" type="solid" className="ms-2" />
                                                                            Job Sent
                                                                        </button>
                                                                        :
                                                                        <button className={"btn btn-sm me-2 fs-8 btn-" + jobSentColor}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                manageTechResponseSet(true);
                                                                            }}>
                                                                            <span className="me-1">Waiting for Technician</span>
                                                                            <Timer minWidth={40} baseDate={props.job.sentToTechAt}
                                                                                onTick={t => {
                                                                                    jobSentColorSet(t < 60 ? "success" : t < 120 ? "warning" : "danger");
                                                                                }} />
                                                                            <Icon icon="clock" className="ms-2" />
                                                                        </button>
                                                                }
                                                            </>
                                                            :
                                                            <button className="btn btn-light-success btn-sm me-2" title="Send The Job"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    sendJobSet(true);
                                                                }}>
                                                                <Icon icon="share" />
                                                                Send Job
                                                            </button>
                                                    }
                                                </>
                                    }

                                    <div>
                                        <button className="btn btn-sm btn-secondary me-2"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <Icon icon="copy" />
                                            Copy Job
                                        </button>
                                        <DropdownMenu width={200}
                                            source={[
                                                {
                                                    title: "Copy with Direct Number",
                                                    icon: "phone-arrow-down-left",
                                                    async click(e) {
                                                        e.preventDefault();
                                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=True")
                                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                                        if (!copy) openCopyJob(props.job);
                                                    },
                                                },
                                                {
                                                    title: "Copy with Extension",
                                                    icon: "phone-office",
                                                    async click(e) {
                                                        e.preventDefault();
                                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=False")
                                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                                        if (!copy) openCopyJob(props.job);
                                                    }
                                                }
                                            ]} />
                                    </div>

                                    {
                                        props.job?.tech &&
                                        <button className="btn btn-sm btn-primary me-2"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.job?.tech) {
                                                    setChatJob(props.job);
                                                }
                                            }}>
                                            <Icon icon="comment-lines" />
                                            Chat
                                        </button>
                                    }


                                    {/* <div>
                                        <button className="btn btn-sm btn-light btn-icon"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <Icon icon="ellipsis-vertical" />
                                        </button>
                                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (props.job) editJob(props.job);
                                                        //if (props.onEdit) props.onEdit(job);
                                                    }}>
                                                    <Icon icon="edit" className="me-2" />
                                                    Edit Job
                                                </a>
                                            </div>
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (props.job) createJob(props.job.id);
                                                    }}
                                                >
                                                    <Icon icon="clone" className="me-2" />
                                                    Duplicate
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fs-xxxl-5 fw-bolder">

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "overview" ? " active" : "")} to={"/crm/jobs/overview/" + props.job?.id}>Overview</Link>
                        </li>

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "calls" ? " active" : "")} to={"/crm/jobs/calls/" + props.job?.id}>Calls History</Link>
                        </li>

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "attachments" ? " active" : "")} to={"/crm/jobs/attachments/" + props.job?.id}>
                                Attachments
                                {
                                    (props.job.attachmentsCount ?? 0) > 0 &&
                                    <span className="ms-1 fs-7">({props.job.attachmentsCount})</span>
                                }
                            </Link>
                        </li>

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "payments" ? " active" : "")} to={"/crm/jobs/payments/" + props.job?.id}>Payments</Link>
                        </li>

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "estimates" ? " active" : "")} to={"/crm/jobs/estimates/" + props.job?.id}>
                                Estimates
                                {
                                    (props.job.estimatesCount ?? 0) > 0 &&
                                    <span className="ms-1 fs-7">({props.job.estimatesCount})</span>
                                }
                            </Link>
                        </li>

                        <li className="nav-item mt-2">
                            <Link className={"nav-link text-active-primary ms-0 me-10 py-2" + (props.selectedPage == "invoices" ? " active" : "")} to={"/crm/jobs/invoices/" + props.job?.id}>
                                Invoices
                                {
                                    (props.job.invoicesCount ?? 0) > 0 &&
                                    <span className="ms-1 fs-7">({props.job.invoicesCount})</span>
                                }
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>

            {
                updateAppointment &&
                <JobAppointmentModal job={props.job} forceUpdateStatus
                    onUpdate={() => {
                        resetStatusTimeSet(true);
                        selectedStatusSet(undefined);
                    }}
                    onClose={() => {
                        updateAppointmentSet(false);
                        selectedStatusSet(undefined);
                    }}
                    onCancel={() => {
                        updateAppointmentSet(false);
                        selectedStatusSet(undefined);
                    }} />
            }

            {
                updateStatus &&
                <JobUpdateStatusNoteModal job={props.job} status={selectedStatus}
                    onUpdate={() => {
                        resetStatusTimeSet(true);
                        selectedStatusSet(undefined);
                    }}
                    onClose={() => {
                        updateStatusSet(false);
                        selectedStatusSet(undefined);
                    }}
                    onCancel={() => {
                        updateAppointmentSet(false);
                        selectedStatusSet(undefined);
                    }} />
            }

            {
                closeNotValid &&
                <JobCloseNotValidModal job={props.job} error={closeNotValidErr}
                    onClose={() => {
                        closeNotValidSet(false);
                    }}
                    onDiscard={() => {
                        selectedStatusSet(undefined);
                    }}
                    onUpdate={() => {
                        setTimeout(() => {
                            closeJobSet(true);
                        }, 500);
                    }} />
            }

            {
                closeJob &&
                <JobClosePaymentModal job={props.job}
                    onDiscard={() => selectedStatusSet(undefined)}
                    onClose={() => closeJobSet(false)}
                    onUpdate={() => {
                        resetStatusTimeSet(true);
                        selectedStatusSet(undefined)
                    }}
                />
            }

            {
                sendJob &&
                <JobSendModal job={props.job}
                    onClose={() => sendJobSet(false)} />
            }

            {
                manageTechResponse &&
                <JobSendTechRespondModal job={props.job}
                    onClose={() => manageTechResponseSet(false)} />
            }

            {
                removeFlag &&
                <Modal id="modal-remove-flag"
                    title="Remove Flag"
                    subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                    visible open
                    onClose={() => removeFlagSet(false)}
                    buttonText="Remove Flag"
                    buttonColor="danger"
                    onConfirm={callback => {

                        hub.Post(API_ROUTES.Jobs + "removeflag", {
                            jobId: props.job?.id,
                            flagId: selectedFlag?.id
                        }, res => {
                            callback();
                        })

                    }}
                >
                    <div>
                        <p>
                            Are you sure you want to remove this flag?
                        </p>
                        <div>
                            <span className="badge badge-secondary me-1"
                                style={{
                                    fontSize: 11,
                                    backgroundColor: selectedFlag?.color,
                                    color: "#fff"
                                }}>
                                {selectedFlag?.name}
                            </span>
                        </div>
                    </div>
                </Modal>
            }

            {
                addFlags &&
                <Modal id="modal-jobdetails-flags"
                    title="Flags"
                    subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                    visible open
                    onClose={() => addFlagsSet(false)}
                    buttonText="Update Flags"
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.Jobs + "updateflags", {
                            ...body,
                            jobId: props.job?.id
                        }, res => {
                            callback!(res);
                        })

                    }}
                >
                    <div>
                        <Tagify id="tagify-jobdetails-tags"
                            //apiUrl={API_ROUTES.TagifyFlags}
                            source={cash?.flags}
                            labelUp noMarginBottom defaultIds={props.job.flagIds}
                            name="flags"
                        />
                    </div>
                </Modal>
            }


            {
                inProgErrModal &&
                <Modal id="modal-closejob-inprognotvalid"
                    title="Warning"
                    subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                    open visible
                    onClose={() => inProgErrModalSet(false)}>
                    <div>
                        <div className="mb-2 fw-bolder">Before update the job status to In Progress you should: </div>
                        <div className="bg-light-warning p-5"
                            style={{
                                whiteSpace: "pre"
                            }}>
                            {inProgErrText}
                        </div>
                    </div>
                </Modal>
            }


            {
                reportIssueModal &&
                <JobReportIssue
                    onClose={() => reportIssueModalSet(false)}
                    onUpdate={j => {
                        // var arr = jobs ?? [];
                        // for (var i = 0; i < arr.length; i++) {
                        //     if(j.id == arr[i].id) {
                        //         arr[i].hasIssue = j.hasIssue;
                        //         arr[i].issueText = j.issueText;
                        //         break;
                        //     }
                        // }
                        // jobsSet(a=> [...arr]);
                    }}
                    job={props.job} />
            }
        </>
    )
}