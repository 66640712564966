import { useEffect, useState } from "react";
import Modal from "../../../../../components/base/Modal";
import { useHub } from "../../../../../core/Global";
import Job from "../../models/Job";
import TextBox from "../../../../../components/form/TextBox";
import DropDown from "../../../../../components/form/DropDown";
import { API_ROUTES } from "../../../../../core/Constants";
import TextArea from "../../../../../components/form/TextArea";
import Tagify from "../../../../../components/tagify/Tagify";

interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobDetailsEdit(props: IProps) {

    const hub = useHub();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    return (
        <>
            <Modal id="modal-edit-jobdetails"
                title="Edit Job Details"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                visible open
                buttonText="Update Job"
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    hub.Post(API_ROUTES.Jobs + "details", {
                        ...body,
                        id: props.job?.id
                    }, res => {
                        if (res.success) {
                            window.closeModal("modal-edit-jobdetails");

                        }
                        if (callback) callback(res);
                    })

                }}>

                <DropDown id="drp-edit-jobsources" name="jobSourceId" label="Job Source" labelUp marginBottom={5}
                    apiSource={API_ROUTES.TagifyJobSources} dataText="title" dataValue="value" parentModal="modal-edit-jobdetails"
                    defaultValue={job?.jobSourceId} />

                <DropDown id="drp-edit-jobtypes" name="jobTypeId" label="Job Type" labelUp marginBottom={5}
                    apiSource={API_ROUTES.TagifyJobTypes} dataText="title" dataValue="value" parentModal="modal-edit-jobdetails"
                    defaultValue={job?.jobTypeId} />

                <TextArea id="txt-edit-jobdetails" name="jobDetails" label="Job Details" labelUp marginBottom={5}
                    defaultValue={job?.jobDetails} rows={5} />

            </Modal>
        </>
    )
}