import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AgentStatuses } from "../../core/Enums";
import { setCallToListen, setChanSpyLine, setHangupChannel} from "../../core/Global";
import { AgentExtensionItem } from "../../modules/agents/models/Agent";
import { AgentStatus } from "../../modules/agents/models/AgentDetailsModel";
import LiveCall from "../../modules/voice/calls/models/LiveCall";
import Icon from "../base/Icon";
import Timer from "../timer/Timer";

interface IProps {
    ext: AgentExtensionItem
    status?: AgentStatus
    showTimers?: boolean
    onSelect?: () => void
    selectCall?: (call?: LiveCall) => void
}

export default function AgentExtensionButton(props: IProps) {
    const getColor = () => {
        if (props.status?.status != AgentStatuses.Active && props.status?.status != AgentStatuses.Busy) return props.status?.colorClass;
        if (props.ext.currentCall && props.ext.currentCall.ringing) return "primary";
        if (props.ext.currentCall && props.ext.currentCall.talking) return "danger";
        return "success";
    }
    return (
        <div className="btn-group" role="group">
            <button className={"d-flex align-items-center btn btn-"+getColor()+" btn-agent-ext"}
                
                onClick={e=> {
                    e.preventDefault();
                    if (props.ext.currentCall) {
                        var li = window.DialByLine('audio', undefined, '551' + props.ext.extNumber);
                        setChanSpyLine(li);
                        setCallToListen(props.ext.currentCall);
                        window.dropdown("btn-menu-" + props.ext.extNumber, true);
                    }
                }}>
                <span className={"fs-7" + (props.showTimers ? " me-2" : "")}>{props.ext.extNumber}</span>
                {
                    !props.ext.currentCall && props.showTimers &&
                    <>
                        {/* <Icon icon="phone-hangup" type="solid" className="ms-2 w-20px" size={10} color="#fff" /> */}
                        <Timer showHours width={45} totalSeconds={props.ext.activeTime} />
                    </>
                }
                {
                    props.ext.currentCall?.ringing && props.showTimers &&
                    <>
                        {/* <Icon icon="phone-hangup" type="solid" className="ms-2 w-20px" shake size={10} color="#fff" /> */}
                        <Timer showHours width={45} totalSeconds={props.ext.currentCall.ringingSeconds} />
                    </>
                }
                {
                    props.ext.currentCall?.talking && props.showTimers &&
                    <>
                        {/* <Icon icon="phone-volume" type="solid" className="ms-2 w-20px" size={10} color="#fff" /> */}
                        <Timer showHours width={45} totalSeconds={props.ext.currentCall.talkingSeconds} />
                    </>
                }

            </button>
            <div className="btn-group" role="group">
                <button id={"btn-menu-" + props.ext.extNumber}
                    type="button" className={"btn btn-"+getColor()+" btn-agent-ext dropdown-toggle dsp-dropdown"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    onClick={e=> {
                        window.dropdown("btn-menu-" + props.ext.extNumber);
                    }}>
                    <Icon icon="ellipsis-vertical" className="dsp-dropdown" />
                </button>
                <div className="dropdown-menu dsp-dropdown">
                    {
                        !props.ext.currentCall &&
                        <>
                            <a className="dropdown-item dsp-dropdown text-danger fs-8" href="#"
                                onClick={e=>{
                                    e.preventDefault();
                                    if (props.onSelect) props.onSelect();
                                    window.openModal("modal-ext-remove");
                                    window.dropdown("btn-menu-" + props.ext.extNumber, true);
                                }}>
                                    <Icon icon="times" className="dsp-dropdown w-20px text-danger text-center" size={10} />
                                    Remove
                            </a>
                        </>
                    }
                    {
                        props.ext.currentCall &&
                        <>
                            <Link className="dropdown-item dsp-dropdown fs-8" to={"/voice/call/" + props.ext.currentCall.id}>
                                <Icon icon="phone" className="dsp-dropdown w-20px text-center" size={10} />
                                Call Details
                            </Link>
                            <a className="dropdown-item dsp-dropdown fs-8" href="#"
                                onClick={e=>{
                                    e.preventDefault();
                                    if (props.ext.currentCall) {
                                        var li = window.DialByLine('audio', undefined, '551' + props.ext.extNumber);
                                        setChanSpyLine(li);
                                        setCallToListen(props.ext.currentCall);
                                        window.dropdown("btn-menu-" + props.ext.extNumber, true);
                                    }
                                }}>
                                    <Icon icon="volume" className="dsp-dropdown w-20px text-center" size={10} />
                                    Listen To Call
                            </a>
                            <a className="dropdown-item dsp-dropdown text-danger fs-8" href="#"
                                onClick={e=>{
                                    e.preventDefault();
                                    setHangupChannel(props.ext.currentCall?.id);
                                    window.openModal("modal-hangup");
                                    window.dropdown("btn-menu-" + props.ext.extNumber, true);
                                }}>
                                    <Icon icon="phone-slash" className="dsp-dropdown w-20px text-danger text-center" size={10} />
                                    Hangup
                            </a>
                        </>
                    }
                    
                </div>
            </div>
        </div>
    )
}

