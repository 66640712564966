import { Link } from "react-router-dom";
import { hasRoles, useGlobal } from "../../../core/Global";
import Icon from "../../base/Icon";
import { useEffect, useState } from "react";
import Menu from "../../../core/Menu.json";
import Container from "../../base/Container";
import FlexRow from "../../base/FlexRow";

function HeaderTools() {

    const [globalState] = useGlobal();

    const [sectionMenu, sectionMenuSet] = useState<string>();
    const [parentMenu, parentMenuSet] = useState<string>();


    useEffect(() => {
        if (globalState.page?.breadCrumb && globalState.page?.breadCrumb.length > 0) {
            sectionMenuSet(globalState.page?.breadCrumb[0].title);
            parentMenuSet(globalState.page?.breadCrumb[1]?.title);
        }
        else {
            sectionMenuSet(undefined);
            parentMenuSet(undefined);
        }
    }, [globalState.page?.breadCrumb])

    useEffect(() => {
        if (sectionMenu && parentMenu) {
            window.KTMenuCreate();
        }
    }, [sectionMenu, parentMenu])


    const getParents = () => {
        var section = Menu.filter(m => m.title.toLowerCase() == sectionMenu?.toLowerCase())[0];
        return section;
    }

    const getChilds = (title: string) => {
        var parent = Menu.filter(m => m.title.toLowerCase() == sectionMenu?.toLowerCase())[0];
        if (parent) {
            var menu = parent.items.filter(m => m.title.toLowerCase() == title.toLowerCase())[0];
            return menu?.items;
        }
        return undefined;
    }


    const AreaHasRole = (area: any) => {
        for (var i = 0; i < area.items?.length; i++) {
            for (var j = 0; j < area.items[i].items?.length; j++) {
                if (hasRoles(area.items[i].items[j].roles)) return true;
            }
        }
        return false;
    }

    const MenuHasRole = (item: any) => {
        if (item.items) {
            for (var j = 0; j < item.items?.length; j++) {
                if (hasRoles(item.items[j].roles)) return true;
            }
        }
        if (item.roles) {
            if (hasRoles(item.roles)) return true;
        }
        return false;
    }

    return (
        <>
            <div className="toolbar h-55px" id="kt_toolbar">

                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                    <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                        className="page-title d-flex align-items-center me-3 mb-5 mb-lg-0">

                        <h1 className="d-flex align-items-center text-dark fw-bolder fs-4 fs-xxxl-3 my-1">
                            {globalState.page?.title}
                        </h1>

                        {
                            (globalState.toolbarComponent || (globalState.page?.breadCrumb && globalState.page?.breadCrumb.length > 0)) &&
                            <span className="h-20px border-gray-300 border-start mx-4"></span>
                        }


                        {
                            globalState.toolbarComponent ? <globalState.toolbarComponent />
                                : globalState.page?.breadCrumb && globalState.page?.breadCrumb.length > 0 ?
                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-8 fs-xxxl-7 my-1">
                                        {/* <li className="breadcrumb-item text-muted">
                                <Link to={"/dashboard"} className="text-muted text-hover-primary">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-300 w-5px h-2px"></span>
                            </li> */}

                                        {
                                            sectionMenu &&
                                            <>
                                                <li className="breadcrumb-item text-muted">
                                                    <a href="#" className="text-muted text-hover-primary"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
                                                        onClick={e => e.preventDefault()}>{sectionMenu}</a>

                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                        {
                                                            Menu.map((sec, k1) =>
                                                                AreaHasRole(sec) ?
                                                                    <div key={k1} className="menu-item">
                                                                        <a href="#" className="menu-link px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start" onClick={e => e.preventDefault()}>
                                                                            {sec.title}
                                                                            <Icon icon="chevron-right" className="ms-auto" size={10} />
                                                                        </a>
                                                                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                                            {
                                                                                sec.items.map((p, k2) =>
                                                                                    MenuHasRole(p) ?
                                                                                        <div key={k2} className="menu-item">
                                                                                            <a href="#" className="menu-link px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                                                                {p.title}
                                                                                                <Icon icon="chevron-right" className="ms-auto" size={10} />
                                                                                            </a>
                                                                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                                                                {
                                                                                                    p.items?.map((item, k3) =>
                                                                                                        hasRoles(item.roles) ?
                                                                                                            <div key={k3} className="menu-item">
                                                                                                                <Link to={item.to} className="menu-link px-3">
                                                                                                                    {item.title}
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            : <></>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        : <></>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : <></>
                                                            )
                                                        }
                                                    </div>

                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-300 w-5px h-2px"></span>
                                                </li>
                                            </>
                                        }

                                        {
                                            parentMenu &&
                                            <>
                                                <li className="breadcrumb-item text-muted">
                                                    <a href="#" className="text-muted text-hover-primary"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
                                                        onClick={e => e.preventDefault()}>{parentMenu}</a>

                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                        {
                                                            getParents()?.items.map((p, k) =>
                                                                <div key={k} className="menu-item">
                                                                    <a href="#" className="menu-link px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                                                        {p.title}
                                                                        <Icon icon="chevron-right" className="ms-auto" size={10} />
                                                                    </a>
                                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">

                                                                        {
                                                                            p.items?.map((item, k3) =>
                                                                                <div key={k3} className="menu-item">
                                                                                    <Link to={item.to} className="menu-link px-3">
                                                                                        {item.title}
                                                                                    </Link>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                </li>
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-300 w-5px h-2px"></span>
                                                </li>
                                            </>
                                        }

                                        <li className="breadcrumb-item text-dark">{globalState.page?.title}</li>
                                    </ul>
                                    : <></>
                        }

                    </div>

                    <div className="d-flex align-items-center justify-contents-end gap-2 gap-lg-3">

                        {
                            globalState.toolbarActions && <globalState.toolbarActions />
                        }

                        {
                            globalState.toolbarButton &&
                            <button className={`btn btn-sm btn-${globalState.toolbarButton.color}`}
                                disabled={globalState.toolbarButton.working} onClick={globalState.toolbarButton.onClick}>
                                {
                                    globalState.toolbarButton.working ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">{globalState.toolbarButton.title}</span>
                                        </>
                                        :
                                        <>
                                            {globalState.toolbarButton.icon && <Icon icon={globalState.toolbarButton.icon} />}
                                            <span>{globalState.toolbarButton.title}</span>
                                        </>
                                }
                            </button>
                        }

                    </div>

                </div>

            </div>
        </>

    )
}

export default HeaderTools;