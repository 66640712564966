import { useEffect, useState } from "react";
import FlexRow from "../base/FlexRow";


interface IProps {
    id: string
    name?: string
    className?: string
    color?: string
    width?: number
    readonly?: boolean
    onSelect?: (color:string) => void
}

// const ColorsList = [
//     ["#0D47A1", "#1565C0", "#1976D2", "#2196F3", "#5C6BC0"],
//     ["#1B5E20", "#33691E", "#00695C", "#00897B", "#26A69A"],
//     ["#B71C1C", "#D32F2F", "#D81B60", "#EC407A", "#F48FB1"],
//     ["#BF360C", "#DD2C00", "#FF6D00", "#FFC400", "#FFD600"]
// ]

const ColorsList = [
    ["#009EF7", "#004CF4", "#0F67B1", "#3572EF", "#1F3E84"],
    ["#50CD89", "#2B8251", "#799351", "#05CE39", "#074918"],
    ["#FFC700", "#FFA823", "#EB5B00", "#FFDE95", "#7F710D"],
    ["#7239EA", "#452F7F", "#2E073F", "#E3A5C7", "#CD6688"],
    ["#F1416C", "#F5004F", "#A02334", "#FA7070", "#820300"],
    ["#E4E6EF", "#686D76", "#31363F", "#939185", "#1E201E"],
]

export default function ColorPicker(props: IProps) {

    const [changing, changingSet] = useState<boolean>(false);
    const [selectedColor, selectedColorSet] = useState<string>();

    const [offset, offsetSet] = useState<any>({top:0, left:0});
    

    const onWindowClick = (e:any) => {
        if($(e.target).hasClass("color-picker-item" + (props.id ? "-" + props.id : ""))) return;
        changingSet(false);
    }
    
    const onWindowResize = () => {
        offsetSet($("#" + props.id).offset());
    }

    useEffect(() => {
        if (props.color) selectedColorSet(props.color);
        else selectedColorSet(ColorsList[0][0]); 
    }, [props.color])

    

    useEffect(() => {

        offsetSet($("#" + props.id).offset());
        var parentModal = $("#" + props.id).parents(".modal-main-container");
        if (parentModal.length > 0) {
            window.onModalOpen(parentModal.attr("id") ?? "", () => {
                offsetSet($("#" + props.id).offset());
            });
        }
        
        window.addEventListener("click", onWindowClick);
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("click", onWindowClick);
            window.removeEventListener("resize", onWindowResize);
        }
    }, [])

    return (
        <div className={"color-picker-item" + (props.id ? "-" + props.id : "") + " " + props.className}
        style={{
            width: props.width ?? 20,
            height: props.width ?? 20,
            position:"relative"
        }}>
            <input type="hidden" name={props.name} value={selectedColor} />

            <div id={props.id} className={"color-picker-item" + (props.id ? "-" + props.id : "")} style={{
                backgroundColor: selectedColor,
                width: props.width ?? 20,
                height: props.width ?? 20,
                borderRadius:".475rem",
                cursor:"pointer"
            }}
            onClick={e=> {
                if (props.readonly) return;
                changingSet(!changing);
            }}
            >

            </div>

            {
                changing &&

                <div className={"color-picker-item" + (props.id ? "-" + props.id : "")} style={{
                    position:"fixed",
                    left: offset.left,
                    top: offset.top + (props.width ?? 20) + 2,
                    zIndex: 999,
                    padding: 10,
                    border:"1px solid #CCC",
                    borderRadius:"5px",
                    backgroundColor:"#fff"
                }}>
                    {
                        ColorsList.map((colors, k) => 
                            <FlexRow key={k} className={"mb-2 color-picker-item" + (props.id ? "-" + props.id : "")}>
                                {
                                    colors.map((color, key) => 
                                        <div key={key} className={"me-2 color-picker-item" + (props.id ? "-" + props.id : "")} style={{
                                            backgroundColor: color,
                                            width: 20,
                                            height: 20,
                                            borderRadius:"2px",
                                            cursor:"pointer"
                                        }}
                                        onClick={e=> {
                                            selectedColorSet(color);
                                            if(props.onSelect) props.onSelect(color);
                                            changingSet(false);
                                        }}
                                        >
                            
                                        </div>
                                    )
                                }
                            </FlexRow>
                        )
                    }
                </div>

            }

            
        </div>
    );
}