import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";
import { toastError, toastSuccess } from "../../../components/base/Toast";
import TechGroup from "../models/TechGroup";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import Avatar from "../../../components/base/Avatar";
import { CompactUser } from "../../../core/CompactModels";
import Form from "../../../components/form/Form";
import Button from "../../../components/base/Button";


interface IProps {
    model?: TechGroup
    onDiscard?: () => void
    onUpdated?: () => void
}

export default function TechGroupTechnicians(props: IProps) {

    const hub = useHub();
    const user = getUser();

    const [focusNames, focusNamesSet] = useState<boolean>(true);

    useEffect(() => {
      if(focusNames) {
        setTimeout(() => {
          focusNamesSet(false);
        }, 1000);
      }
    }, [focusNames])


    const [techsPool, techsPoolSet] = useState<CompactUser[]>([]);
    const [selectedTechs, selectedTechsSet] = useState<CompactUser[]>([]);

    const [working, workingSet] = useState<boolean>(false)

    useEffect(() => {
      if(props.model) {
        hub.Get(API_ROUTES.TechGroups + "techsmodal/" + props.model.id, res => {
          if(res.success) {
            techsPoolSet(res.data.pool);
            selectedTechsSet(res.data.techs);
          }
        })
      }
    }, [props.model])

    return (
        <Form id="form-edit-techgroup"
          onSubmit={(body, callback) => {
            workingSet(true)
            hub.Post(API_ROUTES.TechGroups + "techsmodal/" + props.model?.id, selectedTechs, res => {
              if (res.success) {
                 if (props.onUpdated) props.onUpdated();
                 toastSuccess("Group has been updated successfully!");
              }
              if (callback) callback(res);
              workingSet(false)
            })

          }}>
            <div className="row">
                <div className="col-6">
                  <FlexRow className="border-bottom pb-2">
                    <div className="fs-5 fw-bolder">
                      Technicians Pool
                    </div>
                    {
                      techsPool.length > 0 &&
                      <div className="ms-auto">
                        <span className="badge badge-primary cursor-pointer" 
                          onClick={e=> {
                            let pool = techsPool ?? [];
                            let arr = selectedTechs ?? [];
                            for (var i = 0; i < pool.length; i++) {
                              arr.push(pool[i]);
                            }
                            techsPoolSet([]);
                            selectedTechsSet(e=> [...arr]);
                          }}>
                          <Icon icon="plus" size={12} className="me-1" />
                          Add All
                        </span>
                      </div>
                    }
                  </FlexRow>
                  <div>
                    {
                      techsPool.length > 0 &&
                      <div>
                        <div className="scroll h-300px">
                          <table className="table table-striped">
                          <tbody>
                          {
                            techsPool.map((item, key) => 
                            <tr key={key}>
                              <td className="px-3">
                              <a href={"/accounts/manage/" + item.id} target="_blank">
                                <FlexRow>
                                  <Avatar avatarUrl={item.avatarUrl} width={30} fullName={item.fullName}/>
                                  <span className="ms-2 text-dark">{item.fullName}</span>
                                </FlexRow>
                              </a>
                              </td>
                              <td className="text-end px-3">
                                <span className="badge badge-light-primary cursor-pointer"
                                onClick={e=> {
                                  let pool = techsPool ?? [];
                                  let arr = selectedTechs ?? [];
                                  pool.splice(pool.indexOf(item), 1);
                                  arr.push(item);
                                  techsPoolSet(e=> [...pool]);
                                  selectedTechsSet(e=> [...arr]);
                                }}>
                                  <Icon icon="plus" size={12} />
                                </span>
                              </td>
                            </tr>
                            )
                          }
                        </tbody>
                          </table>
                        </div>
                      </div>
                      
                    }
                    
                    {
                      techsPool.length == 0 &&
                      <div className="text-center mt-10">
                        There is no technician!
                      </div>
                    }
                  </div>
                </div>
                <div className="col-6">
                  
                  <FlexRow className="border-bottom pb-2">
                    <div className="fs-5 fw-bolder">
                      Selected Extensions
                    </div>

                    {
                      selectedTechs.length > 0 &&
                      <div className="ms-auto">
                        <span className="badge badge-danger cursor-pointer" 
                          onClick={e=> {
                            let pool = techsPool ?? [];
                            let arr = selectedTechs ?? [];
                            for(var i = 0; i < arr.length; i++) {
                              pool.push(arr[i]);
                            }
                            techsPoolSet(e=> [...pool]);
                            selectedTechsSet([]);
                          }}>
                          <Icon icon="minus" size={12} className="me-1" />
                          Remove All
                        </span>
                      </div>
                    }
                  </FlexRow>
                  <div>
                    {
                      selectedTechs.length > 0 &&
                      <div>
                          <div className="scroll h-300px">
                            <table className="table table-striped">
                              <tbody>
                                {
                                  selectedTechs.map((item, key) => 
                                  <tr key={key}>
                                    <td className="px-3">
                                    <a href={"/accounts/manage/" + item.id} target="_blank">
                                      <FlexRow>
                                          <Avatar avatarUrl={item.avatarUrl} width={30} fullName={item.fullName}/>
                                          <span className="ms-2 text-dark">{item.fullName}</span>
                                      </FlexRow>
                                      </a>
                                    </td>
                                    <td className="px-3">
                                      <FlexRow justify="end">
                                        <span className="badge badge-light-danger cursor-pointer"
                                          onClick={e=> {
                                            let pool = techsPool ?? [];
                                            let arr = selectedTechs ?? [];
                                            arr.splice(arr.indexOf(item), 1);
                                            pool.push(item);
                                            techsPoolSet(e=> [...pool]);
                                            selectedTechsSet(e=> [...arr]);
                                          }}>
                                          <Icon icon="minus" size={12} />
                                        </span>
                                      </FlexRow>
                                    
                                      
                                    </td>
                                  </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                      </div>
                      
                    }
                    


                    {
                      selectedTechs.length == 0 &&
                      <div className="text-center mt-10">
                        There is no extension!
                      </div>
                    }
                    
                  </div>
                </div>
                
              </div>

              <FlexRow justify="end" className="my-5">
                <Button text="Discard" className="me-2" onClick={props.onDiscard} color="secondary" />
                <Button text="Update Group" isSubmit isRunning={working} />
              </FlexRow>
        </Form>
    )
}