import React, { useEffect, useState } from "react";
import Icon from "../base/Icon";

interface TextAreaProps {
    id?: string
    label?: string
    labelUp?: boolean
    clear?: boolean
    small?: boolean
    solid?: boolean
    placeholder?: string
    className?: string
    name?: string
    defaultValue?: string
    value?: string
    required?: boolean
    numeric?: boolean
    autoFocus?: boolean
    maxLength?: number
    timeInput?: boolean
    generatePassword?: boolean
    marginBottom?: number
    noMarginBottom?: boolean
    readOnly?: boolean
    block?: boolean
    tooltip?: string
    startWith?: string
    noTextError?: boolean
    rows?: number
    preventResize?: boolean
    tabIndex?: number
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
}




export default function TextArea(props: TextAreaProps) {

    let element: HTMLTextAreaElement | null;
    const [inputId] = useState<string>(props.id ?? "txt-" + (props.name ?? Math.floor(Math.random() * 1000000)));
    const [text, textSet] = useState<string>();
    const [focused, focusedSet] = useState<boolean>(false);

    const [defaultValueLoaded, defaultValueLoadedSet] = useState<boolean>(false);

    const className = () => {
        var cls = "form-control form-inputs";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        if (props.autoFocus) cls += " auto-focus";
        if (props.className) cls += " " + props.className;
        return cls;
    };

    const keypress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (props.numeric) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                e.preventDefault();
            }
        }

        if (props.timeInput) {
            if (text && text.length == 2) {
                if (!/[0-9]/.test(e.key) && e.key != "Enter" && e.key != ":") {
                    e.preventDefault();
                }
            }
            else {
                if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                    e.preventDefault();
                }
            }
        }
    }

    const change = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.name) {
            $("input[name=" + props.name + "]").removeClass("is-invalid");
            $("#" + props.name + "-err").hide();
        }

        var txt = e.target.value ?? "";

        textSet(txt);

        if (props.onChange) props.onChange(e);
    }

    const focus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
        focusedSet(true);
    }
    const blur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
        focusedSet(false);
    }


    useEffect(() => {
        window.makeFormTooltip((inputId) + "-tooltip");
        if (props.autoFocus) {
            setTimeout(() => {
                $("#" + props.id).focus();
            }, 200);
        }
    }, []);

    useEffect(() => {
        if (props.defaultValue && !defaultValueLoaded) {
            textSet(props.defaultValue);
            defaultValueLoadedSet(true);
        }
    }, [props.defaultValue])

    useEffect(() => {
        if (props.value) {
            textSet(props.value);
        }
        else {
            textSet("");
        }
    }, [props.value])

    useEffect(() => {
        if (props.clear) {
            textSet("");
        }
    }, [props.clear])

    return (
        <div className={"row align-items-center" + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 10))}>
            {
                props.label &&
                <label className={"col col-12 " + (props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2")) + (props.small ? " fs-7" : " fs-7")} htmlFor={inputId}>
                    {props.label}
                    {
                        props.tooltip &&
                        <i className="fa-solid fa-circle-question ms-2 form-tooltips"
                            id={(inputId) + "-tooltip"}
                            data-bs-placement="right"
                            data-bs-custom-class="tooltip-dark"
                            title={props.tooltip}></i>
                    }
                    {
                        props.labelUp && !props.noTextError && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                    }
                </label>
            }
            <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                <div style={{
                    position: "relative"
                }}>
                    <textarea id={inputId}
                        ref={e => element = e}
                        name={props.name}
                        rows={props.rows}
                        className={className()}
                        onKeyPress={keypress}
                        autoFocus={props.autoFocus}
                        maxLength={props.maxLength}
                        value={text}
                        placeholder={props.timeInput ? "08:30" : props.placeholder}
                        readOnly={props.readOnly}
                        tabIndex={props.tabIndex}
                        data-gramm="false"
                        onChange={change}
                        onFocus={focus}
                        onBlur={blur}></textarea>
                </div>
            </div>
            {
                props.block && props.label &&
                <div className="col col-12 col-md-3"></div>
            }
            {
                !props.labelUp && !props.noTextError &&
                <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                    <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                </div>
            }

        </div>
    )

}