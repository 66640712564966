import "./Aside.css";
import AsideBrand from "./AsideBrand";
import AsideEnviornment from "./AsideEnviornment";
import { getUser } from "../../../core/Global";
import Icon from "../../base/Icon";
import AsideModulesMenu from "./AsideModulesMenu";
import FlexRow from "../../base/FlexRow";
import AsideFooter from "./AsideFooter";

function Aside() {

  const user = getUser()

  return (
    <div id="kt_aside" className="aside overflow-visible pb-5 pt-5 pt-lg-0">
      <AsideBrand />
      <AsideModulesMenu />
      {/* <AsideToolbar />
      {user?.isSystemAdmin && <AsideEnviornment />}
      <AsideMenu />
       */}

      <AsideFooter />

    </div>

  );
}

export default Aside;
