import FlexRow from "../../../components/base/FlexRow";
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import ColorPicker from "../../../components/form/ColorPicker";
import DropDown from "../../../components/form/DropDown";
import TextBox from "../../../components/form/TextBox";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";


interface IProps {

  onClose?: () => void
  callback?: () => void
}
export default function AddShiftDepartmentModal(props: IProps) {

  const hub = useHub();
  const user = getUser();

  return (
    <Modal id="modal-add-department"
      title="Create Department"
      buttonText="Add Department"
      staticBackdrop
      visible open
      onOpen={() => {
        $("#txt-shiftdep-name").focus();
      }}
      onClose={props.onClose}
      onSubmit={(body, callback) => {

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-add-department");
            if (callback) callback();
            return;
          }
        }

        if (!body.name || body.name == "" || body.name.length < 2) {
          window.showFormError("name", "Please write a valid name!", false, "modal-add-department");
          if (callback) callback();
          return;
        }

        if (!body.timezone || body.timezone == "") {
          window.showFormError("timezone", "Please select a timezone!", false, "modal-add-department");
          if (callback) callback();
          return;
        }

        hub.Post(API_ROUTES.ShiftsDepartments, body, res => {
          if (res.success) {
            if (props.callback) props.callback();
            toastSuccess("Department has been added successfully!");
          }
          if (callback) callback(res);
        })

      }}>
      <div>
        {
          user?.isSuperAdmin &&
          <>
            <div className="fs-7 fw-bold">
              Assign to
              <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
            </div>
            <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
          </>
        }

        <FlexRow className="mb-5" alignItems="start">
          <ColorPicker id="color-picker-add-dep" width={30} className="me-2 mt-1" name="color" />
          <div className="flex-grow-1">
            <TextBox id="txt-shiftdep-name" name="name" block marginBottom={0} inlineStyle mediumText placeholder="Department Name" />
          </div>
        </FlexRow>

        <Tagify id="tagify-add-dep-queues" name="queueIds" apiUrl={API_ROUTES.TagifyQueues} block label="Station(s)" labelUp />

        <DropDown id="drp-shiftdep-timezones" name="timezone" label="Timezone" labelUp parentModal="modal-add-department"
          apiSource={API_ROUTES.Users + "timezones"} />


      </div>
    </Modal>
  )
}