import { useEffect, useState } from "react"
import Avatar from "../../../../components/base/Avatar"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"
import { Priorities } from "../../../../core/Enums"
import TaskItem from "../models/TaskItem"
import Tagify from "../../../../components/tagify/Tagify"
import { API_ROUTES } from "../../../../core/Constants"
import { ListItem } from "../../../../core/Types"
import Form from "../../../../components/form/Form"
import { useHub } from "../../../../core/Global"


type IProps = {
    task: TaskItem
    className?: string
    noAction?: boolean
    editOnClick?: boolean
    onEdit?: () => void
    onDelete?: () => void
}
export default function JobTaskCard(props: IProps) {

    const hub = useHub();

    const [task, taskSet] = useState<TaskItem>(props.task);
    useEffect(() => {
        taskSet(props.task);
    }, [props.task])

    const [assignNewUser, assignNewUserSet] = useState<boolean>(false);
    const [assignRouteParams, assignRouteParamsSet] = useState<ListItem[]>([]);
    const [assigning, assigningSet] = useState<boolean>(false);

    useEffect(() => {
        var arr: ListItem[] = [];
        task.assigneesUserIds?.forEach(id => {
            arr.push({
                name: "excludeIds",
                value: id
            })
        });

        assignRouteParamsSet(a=> [...arr])
    }, [task])


    const PriorityBadge = () => (
        <>
        {
            task.priority == Priorities.High &&
            <span className="badge badge-danger fs-9">
                {task.priorityText}
            </span>
        }
        {
            task.priority == Priorities.Urgent &&
            <span className="badge badge-danger fs-9">
                {task.priorityText}
            </span>
        }

        {
            task.priority == Priorities.Medium &&
            <span className="badge badge-warning fs-9">
                {task.priorityText}
            </span>
        }

        {
            task.priority == Priorities.Low &&
            <span className="badge badge-primary fs-9">
                {task.priorityText}
            </span>
        }
        </>
    )

    return (
        <div className={"card " + props.className}>
            <div className="card-body p-2" style={{
                backgroundColor:"#F2F2F5"
            }}>
                <FlexRow>
                    <FlexRow title={task.timezoneName}>
                        <Icon icon="calendar-days" size={12} type="solid" className="text-muted" />
                        <div className="fs-8 fw-bold ms-2 text-muted" style={{paddingTop:3}}>
                            {task.startDateFriendly}
                        </div>
                    </FlexRow>
                    <FlexRow className="ms-auto">
                        <div className="me-2">
                            <PriorityBadge />
                        </div>
                        {
                            props.noAction ? <></>
                            :
                            <>
                                <a href="#" className="text-dark me-2"
                                    onClick={e=> {
                                        e.preventDefault();
                                    }}>
                                    <Icon icon="thumbtack" />
                                </a>
                                <a href="#" className="text-dark me-2"
                                    onClick={e=> {
                                        e.preventDefault();
                                    }}>
                                    <Icon icon="bell" />
                                </a>
                                <a href="#" className="text-dark px-2"
                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                    onClick={e=> {
                                        e.preventDefault();
                                        window.dataTableMenu();
                                    }}>
                                    <Icon icon="ellipsis-vertical" />
                                </a>
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                    <div className="menu-item">
                                        <a href="#" className="menu-link px-3"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.onEdit) props.onEdit();
                                            }}
                                        >
                                            <Icon icon="edit" className="me-2" />
                                            Edit
                                        </a>
                                    </div>
                                    <div className="menu-item">
                                        <a href="#" className="menu-link px-3" style={{color:'red'}}
                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.onDelete) props.onDelete();
                                            }}
                                        >
                                            <Icon icon="circle-minus" className="me-2" color="red" />
                                            Delete
                                        </a>
                                    </div>
                                </div>
                            </>
                        }
                        
                    </FlexRow>
                </FlexRow>
                <div className="mt-2 fs-8 fw-bold">
                    {
                        props.editOnClick ? 
                        <a href="#" className="text-dark"
                            onClick={e=>{
                                e.preventDefault();
                                if(props.onEdit) props.onEdit();
                            }}>
                            {task.title}
                        </a>
                        :
                        <span className="text-dark">{task.title}</span>
                    }
                </div>
                <div className="mt-1 fs-9 limited-text" title={task.description} style={{width: "90%"}}>
                    {task.description}
                </div>

                <FlexRow className="mt-2">

                    <FlexRow>
                        {
                            task.assigneesUsers?.map((user, k) => 
                                <div key={k} className="me-1" title={user.fullName}>
                                    <Avatar avatarUrl={user.avatarUrl} fullName={user.fullName} width={20} />
                                </div>
                            )
                        }

                        {
                            props.noAction ? <></>
                            : 
                            <div className="me-1" title="Add User">
                                <div className={"symbol symbol-circle overflow-hidden"}
                                    style={{
                                        border: "1px dashed #666"
                                    }}>
                                    <a href="#" className="symbol-label" style={{
                                        width: 20,
                                        height: 20 
                                    }} onClick={e=> {
                                        e.preventDefault();
                                        assignNewUserSet(true);
                                    }}>
                                        <Icon icon="plus" size={10} />
                                    </a>
                                </div>
                            </div>
                        }

                        
                    </FlexRow>

                </FlexRow>

                {
                    assignNewUser && task &&
                    <div>

                        <Tagify id={"tagify-task-card-assignuser-" + task.id} name={"assignees-" + task.id} labelUp block marginBottom={2} 
                            apiUrl={API_ROUTES.TagifyUsers} placeholder="Select User(s)" selectedIds={task.assigneesUserIds} 
                            routeParams={[{
                                name: "includeMe",
                                value: "True"
                            }]} />
                        
                        <FlexRow justify="end">
                            
                            <button type="button" className="btn btn-secondary btn-xs me-2"
                                onClick={e=> assignNewUserSet(false)}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-success btn-xs w-100px" disabled={assigning}
                                onClick={e=> {

                                    var assignees = $("input[name=assignees-"+task.id+"]").val();
                                    assigningSet(true);

                                    hub.Post(API_ROUTES.Jobs + "task/assignusers", {
                                        id: task.id,
                                        assignees
                                    }, res => {
                                        if(res.success) {
                                            taskSet(res.data);
                                            assignNewUserSet(false);
                                        }
                                        assigningSet(false);
                                    })
                                }}>
                                {
                                    assigning ? <Icon icon="asterisk" spin />
                                    : <>Update</>
                                }
                            </button>
                            
                        </FlexRow>

                    </div>
                }
            </div>
        </div>
    )
}