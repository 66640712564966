import React, { useEffect, useState } from "react";
import Modal from '../base/Modal';
import LiveCall from '../../modules/voice/calls/models/LiveCall';
import Environment from '../../env/env';
import Hub from '../../api/Hub';
import Icon from '../base/Icon';
import { setCallToListen, setChanSpyLine, setHangupChannel, useHub } from "../../core/Global";

type Props = {
    call: LiveCall
}

function LiveCallActions(props: Props) {

    const env = new Environment();
    const hub = useHub();

    return (
       <>
            <a href='#' className='me-3 live-action-link' title='Hang up' 
                onClick={e=> {
                    e.preventDefault();
                    setHangupChannel(props.call.id);
                    window.openModal("modal-hangup");
                }}>
                <Icon icon='phone-slash' type="solid" size={14} />
            </a>
            <a href='#' className='me-3 live-action-link' title='Listen to call'
                onClick={e=> {
                    e.preventDefault();
                    var li = window.DialByLine('audio', undefined, '551' + props.call.extNumber);
                    setChanSpyLine(li);
                    setCallToListen(props.call);
                }}>
                <Icon icon='volume' type="solid" size={14} />
            </a>
       </>
    )
}

export default LiveCallActions;