import './CallsLive.css';
import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import { setPage, useGlobal, useSocket } from '../../../../core/Global';
import Div from "../../../../components/base/Div";
import Icon from "../../../../components/base/Icon";
import LiveCall, { LiveCallTime } from "../models/LiveCall";
import Hub from "../../../../api/Hub";
import Indicator from "../../../../components/base/Indicator";
import Timer from '../../../../components/timer/Timer';
import Environment from '../../../../env/env';
import Modal from '../../../../components/base/Modal';
import Avatar from '../../../../components/base/Avatar';
import LiveCallActions from '../../../../components/calls/LiveCallActions';
import Section from '../../../../components/base/Section';
import LiveCallsBoard from '../../../../components/calls/LiveCallsBoard';

let socketInterval:NodeJS.Timeout;
let silentTime:number = 30;
function CallsLive() {

    const [globalState] = useGlobal();
    const socket = useSocket();
    const [loading, loadingSet] = useState<boolean>(true);
    const [loaded, loadedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Array<LiveCall>>([]);
    const [selectedCall, selectedCallSet] = useState<LiveCall>();
    const [lineObj, lineObjSet] = useState<any>();

    const [listening, listeningSet] = useState<boolean>();
    const [whispering, whisperingSet] = useState<boolean>();
    const [barging, bargingSet] = useState<boolean>();


    const fetch = async (callback?:any) => {
        

    }


    useEffect(() => {
        setPage("Live Calls", "System");

    }, [])

    


    return (
        <Layout wide>
            
            <Section fluid>
                <div className="row mt-3">
                    <div className="col-12">
                        <LiveCallsBoard  />
                    </div>
                </div>
            </Section>

        </Layout>
    )
}

export default CallsLive;