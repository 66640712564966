import { Validator } from '../../../core/Types';

export const CompanyValidator: Validator[] = [
    {
        name: "name",
        required: true,
        maxLength: 50
    },
    {
        name: "userIds",
        required: true
    }
 ];