import { useEffect, useState } from "react"
import ChatContactItem from "../models/ChatContactItem"
import Icon from "../../../components/base/Icon"
import FlexRow from "../../../components/base/FlexRow"
import ChatContactContextMenu from "./ChatContactContextMenu"
import { ChatEnvironments, MediaTypes } from "../../../core/Enums"
import { goToMessage, isDemo, selectChat, selectChatById, toPrettyDate, useSelectedChat } from "../../../core/Global"
import ChatAvatarSpan from "./ChatAvatarSpan"

interface IProps {
    item?: ChatContactItem
    seperator?: boolean
    readed?: boolean
    onPin?: () => void
    onUnpin?: () => void
    onUnarchive?: () => void
}
export default function ChatContactRow(props: IProps) {

    const [unreads, unreadsSet] = useState<number>(0);
    const selectedChat = useSelectedChat()

    useEffect(() => {
        unreadsSet(props.item?.unreadCount ?? 0);
    }, [props.item])

    useEffect(() => {
        if (props.readed) {
            unreadsSet(0);
        }
    }, [props.readed])

    useEffect(() => {

    }, [])

    return (
        <>
            <div className={"d-flex align-items-start flex-stack py-2 px-3 chat-contact-item" + (selectedChat?.id == props.item?.id ? " chat-contact-item-selected" : "")}
                title={props.item?.text}
                onClick={e => {
                    if ($(e.target).hasClass("chat-contact-context-menu-link")) return;
                    if (props.item) selectChat(props.item);
                    if (props.item?.searchMsgItem && props.item.id) goToMessage(props.item.id);

                }}>
                <FlexRow className="w-100">
                    <div className="symbol symbol-40px symbol-circle">
                        {
                            props.item?.avatar ?
                                <img alt={props.item.title} src={props.item.avatar} />
                                :
                                <ChatAvatarSpan item={props.item} />
                        }

                        {
                            props.item?.environment == ChatEnvironments.App &&
                            <div className="symbol-badge text-center start-100 top-100 ms-n2 mt-n2">
                                <Icon icon="mobile-screen" doutone className="text-primary" size={16} />
                            </div>
                        }


                    </div>


                    <div className="ms-2 flex-grow-1">
                        <FlexRow>
                            <div className={"fs-7 text-gray-900 chat-contact-title" + (unreads > 0 ? " fw-bolder" : "")}>
                                {
                                    isDemo() ? "Tech " + props.item?.title?.substring(0, 2).toUpperCase() : props.item?.title
                                }
                            </div>
                            <FlexRow className="ms-auto">
                                {
                                    (props.item?.activeJobs ?? 0) > 0 &&
                                    <span className="badge badge-secondary badge-xs me-1">
                                        {props.item?.activeJobs} Jobs
                                    </span>
                                }

                                {
                                    unreads > 0 &&
                                    <span className="badge badge-primary badge-circle fs-9 ms-auto me-1" style={{
                                        width: 18, height: 18
                                    }}>
                                        {unreads}
                                    </span>
                                }

                                {
                                    props.item?.pinned && <Icon icon="thumbtack" type="solid" color="#333" size={10} className="px-2" />
                                }
                                {
                                    props.item?.archived && <Icon icon="box-archive" type="solid" color="#333" size={10} className="px-2" />
                                }

                                {
                                    props.item?.searchMsgItem ? <></>
                                        :
                                        <>
                                            <a href="#" className="px-2 chat-contact-context-menu-link" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                onClick={e => {
                                                    e.preventDefault();
                                                }}>
                                                <Icon icon="ellipsis-vertical" type="solid" color="#333" size={10} className="chat-contact-context-menu-link" />
                                            </a>
                                            <ChatContactContextMenu chat={props.item} chatId={props.item?.chatId}
                                                onPin={props.onPin}
                                                onUnpin={props.onUnpin}
                                                onUnarchive={props.onUnarchive}
                                            />
                                        </>
                                }

                            </FlexRow>
                        </FlexRow>
                        <FlexRow>
                            {
                                (props.item?.hasMedia && props.item.mediaType) ?
                                    <>
                                        <div className="me-1">
                                            {
                                                props.item.mediaType == MediaTypes.Audio ?
                                                    <Icon icon="volume" className="text-muted" />
                                                    : props.item.mediaType == MediaTypes.Image ?
                                                        <Icon icon="image" className="text-muted" />
                                                        : props.item.mediaType == MediaTypes.Video ?
                                                            <Icon icon="video" className="text-muted" />
                                                            :
                                                            <Icon icon="paperclip-vertical" className="text-muted" />
                                            }
                                        </div>
                                        <div className="fw-bold text-muted fs-8 chat-contact-subtext" style={{ width: 150 }}>
                                            {props.item?.text}
                                        </div>
                                    </>
                                    :
                                    <div className="fw-bold text-muted fs-8 chat-contact-subtext">
                                        {props.item?.text}
                                    </div>
                            }
                            <span className="ms-auto me-1 fs-9">
                                {toPrettyDate(props.item?.lastUpdateDate)}
                            </span>
                        </FlexRow>
                    </div>

                </FlexRow>
            </div>
            {
                props.seperator &&
                <div className="separator separator-dashed"></div>
            }
        </>
    )
}