import { useEffect, useState } from "react";
import Modal from "../../../../../components/base/Modal";
import { useHub } from "../../../../../core/Global";
import Job from "../../models/Job";
import { API_ROUTES } from "../../../../../core/Constants";
import FormTitle from "../../../../../components/form/FormTitle";
import TextBox from "../../../../../components/form/TextBox";
import TextArea from "../../../../../components/form/TextArea";
import Tagify from "../../../../../components/tagify/Tagify";
import DatePicker from "../../../../../components/form/DatePicker";
import FlexRow from "../../../../../components/base/FlexRow";
import TimePicker from "../../../../../components/form/TimePicker";
import DropDown, { DropDownOption } from "../../../../../components/form/DropDown";
import { ListItem } from "../../../../../core/Types";
import TaskItem from "../../models/TaskItem";
import ToggleSwitch from "../../../../../components/form/ToggleSwitch";
import JobTaskCard from "../JobTaskCard";
import Icon from "../../../../../components/base/Icon";
import { toastError } from "../../../../../components/base/Toast";

interface IProps {
    job?: Job
    onClose?: () => void
}

interface NotifItem {
    num: number
    notif: string
    type: string
}

export default function JobTasksModal(props: IProps) {

    const hub = useHub();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [priorities, prioritiesSet] = useState<ListItem[]>([
        {
            name: "Urgent",
            value: "4"
        },
        {
            name: "High",
            value: "3"
        },
        {
            name: "Medium",
            value: "2"
        },
        {
            name: "Low",
            value: "1"
        }
    ])

    const [repeatTypes, repeatTypesSet] = useState<ListItem[]>([
        {
            name: "Daily",
            value: "1"
        },
        {
            name: "Weekly",
            value: "2"
        },
        {
            name: "Monthly",
            value: "3"
        },
        {
            name: "Yearly",
            value: "4"
        }
    ])

    const [notifTypes, notifTypesSet] = useState<ListItem[]>([
        {
            name: "Minutes",
            value: "1"
        },
        {
            name: "Hours",
            value: "2"
        },
        {
            name: "Days",
            value: "3"
        }
    ])

    const defaultNotifs = [{
        num: 1,
        notif: "",
        type: "1"
    }];
    const [notifs, notifsSet] = useState<NotifItem[]>(defaultNotifs);

    const addNotif = () => {
        var arr = notifs;
        var last = arr[arr.length - 1];
        var n = last.num + 1;
        arr.push({
            num: n,
            notif: "",
            type: "1"
        });
        notifsSet(a => [...arr]);

        setTimeout(() => {
            $("#txt-notif-text-" + n).focus();
        }, 200);
    }
    const removeNotif = (i: number) => {
        var arr = notifs;
        arr.splice(i, 1);
        notifsSet(a => [...arr]);
    }

    useEffect(() => {
        if (job?.id) {
            hub.Post(API_ROUTES.Jobs + "seentasks", {
                id: job.id
            }, res => {

            });
        }
    }, [job])

    const [editTask, editTaskSet] = useState<boolean>(false);
    const [tasks, tasksSet] = useState<TaskItem[]>();
    const [selectedTask, selectedTaskSet] = useState<TaskItem>();
    const fetch = () => {

        hub.Get(API_ROUTES.Jobs + "tasks/" + job?.id, res => {
            if (res.success) {
                tasksSet(t => [...res.data]);
            }
        })
    }

    useEffect(() => {
        if (editTask && selectedTask) {
            if (selectedTask.notifications && selectedTask.notifications.length > 0) {
                var arr: NotifItem[] = [];
                var i = 1;
                selectedTask.notifications.forEach(n => {
                    arr.push({
                        num: i,
                        notif: n.notifTime.toString(),
                        type: n.notifTimeTypes.toString()
                    });
                    i++;
                });

                notifsSet(n => [...arr]);
            }
            else {
                notifsSet(defaultNotifs);
            }
        }
        else {
            selectedTaskDateSet(undefined);
            selectedDueDateSet(undefined);
            selectedTaskTimeSet(0);
            selectedDueTimeSet(0);
            minDueDateSet(window.now());
            minDueTimeSet(0);
        }
    }, [editTask, selectedTask])

    const [selectedTaskDate, selectedTaskDateSet] = useState<any>();
    const [selectedDueDate, selectedDueDateSet] = useState<any>();
    const [selectedTaskTime, selectedTaskTimeSet] = useState<any>(0);
    const [selectedDueTime, selectedDueTimeSet] = useState<any>(0);
    const [minDueDate, minDueDateSet] = useState<any>(window.now());
    const [minDueTime, minDueTimeSet] = useState<number>(0);

    useEffect(() => {

        if (selectedTaskDate) {
            minDueDateSet(selectedTaskDate);
            if (selectedDueDate < selectedTaskDate) selectedDueDateSet(selectedTaskDate);
        }
        if (selectedDueDate && selectedTaskDate && selectedDueDate.format("DD/MM/YYYY") == selectedTaskDate.format("DD/MM/YYYY")) {
            minDueTimeSet(selectedTaskTime + 15);
            if (selectedDueTime < selectedTaskTime) selectedDueTimeSet(selectedTaskTime + 30);
        }

        if (selectedDueTime > 0 && !selectedDueDate) {
            selectedDueDateSet(minDueDate);
        }


    }, [selectedTaskDate, selectedDueDate, selectedTaskTime, selectedDueTime])

    const [repeat, repeatSet] = useState<boolean>(false);

    const [timezones, timezonesSet] = useState<any[]>([]);
    const [editTimezone, editTimezoneSet] = useState<boolean>(false);
    const [selectedTimezone, selectedTimezoneSet] = useState<any>();
    const fetchTimezones = () => {
        hub.Get(API_ROUTES.Jobs + "tasks/timezones/" + job?.id, res => {
            if (res.success) timezonesSet(res.data);
        })
    }
    useEffect(() => {
        if (timezones.length > 0) {
            selectedTimezoneSet(timezones[0]);
        }
    }, [timezones])

    const [reset, resetSet] = useState<boolean>(false);
    const [showAssignees, showAssigneesSet] = useState<boolean>(true);

    useEffect(() => {
        if (reset) {
            window.hideFormErrors();
            editTaskSet(false);
            selectedTaskSet(undefined);
            showAssigneesSet(false);
            notifsSet(a => [...defaultNotifs]);
            editTimezoneSet(false);
            if (timezones.length > 0) {
                selectedTimezoneSet(timezones[0]);
            }
            setTimeout(() => {
                resetSet(false)
            }, 1000)

            $("#modal-job-tasks input[name=title]").focus();
        }
    }, [reset])

    useEffect(() => {
        if (!showAssignees) {
            setTimeout(() => {
                showAssigneesSet(true);

            }, 100);
        }
    }, [showAssignees])


    useEffect(() => {
        if (job) {
            fetch();
            fetchTimezones();
        }
    }, [job])


    return (
        <>
            <Modal id="modal-job-tasks"
                title="Job Tasks"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                buttonText={editTask ? "Update Task" : "Create Task"}
                buttonColor="primary"
                cancelText={editTask ? "Cancel" : "Discard"}
                size="XLarge"
                visible open noPadding cancelButton staticBackdrop
                onClose={props.onClose}
                onCancel={() => {
                    if (editTask) {
                        resetSet(true);
                    }
                    else {
                        window.closeModal("modal-job-tasks");
                    }
                }}
                onSubmit={(body, callback) => {

                    var focused = false;
                    var valid = true;

                    if (!body.title || body.title.length < 2) {
                        window.showFormError("title", "Please write the task title!", focused, "modal-job-tasks", focused);
                        valid = false;
                        focused = true;
                    }

                    if (!body.timezone || body.timezone.length < 2) {
                        window.showFormError("timezone", "Please choose the task timezone!", focused, "modal-job-tasks", focused);
                        valid = false;
                        focused = true;
                    }

                    if (!body.assignees || body.assignees.length < 2) {
                        window.showFormError("assignees", "Please choose the assignee users!", focused, "modal-job-tasks", focused);
                        valid = false;
                        focused = true;
                    }

                    if (!body.taskdate || body.taskdate.length < 2) {
                        window.showFormError("taskdate", "Please choose the task date!", focused, "modal-job-tasks", focused);
                        valid = false;
                        focused = true;
                    }
                    else if (!body.tasktime || body.tasktime.length < 2) {
                        window.showFormError("tasktime", "", focused, "modal-job-tasks", focused);
                        valid = false;
                        focused = true;
                    }



                    // if (!body.title || body.title.length < 2) {
                    //     window.showFormError("", "", focused, "modal-job-tasks", focused);
                    //     valid = false;
                    //     focused = true;
                    // }

                    // if (!body.title || body.title.length < 2) {
                    //     window.showFormError("", "", focused, "modal-job-tasks", focused);
                    //     valid = false;
                    //     focused = true;
                    // }

                    if (!valid) {
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "task", {
                        ...body,
                        taskId: selectedTask?.id,
                        id: job?.id
                    }, res => {
                        if (res.success) {
                            fetch();
                            resetSet(true);
                        }
                        else {
                            if (res.field) {
                                window.showFormError(res.field, res.message);
                            }
                            else {
                                toastError(res.message);
                            }
                        }

                        if (callback) callback();
                    });

                }}>

                {
                    job &&
                    <div className="row mx-2">
                        <div className="col-12 col-md-4 border-end py-5">

                            {
                                (tasks?.length ?? 0 > 0) ?
                                    <div>
                                        {
                                            tasks?.map((t, k) =>
                                                <JobTaskCard key={k} task={t} className="mb-2" editOnClick
                                                    onEdit={() => {
                                                        selectedTaskSet(t);
                                                        editTaskSet(true);
                                                        window.hideFormErrors();
                                                    }}
                                                    onDelete={() => {
                                                        selectedTaskSet(t);
                                                        window.openModal("modal-delete-task");
                                                    }} />
                                            )
                                        }
                                    </div>
                                    :
                                    <div className="mt-20 text-center">
                                        There is no task!
                                    </div>
                            }

                        </div>
                        <div className="col-12 col-md-8 py-5">

                            <FlexRow className="mb-5">
                                {
                                    editTask &&
                                    <a className="text-dark pe-4" href="#"
                                        style={{
                                            marginBottom: 4,
                                        }}
                                        onClick={e => {
                                            e.preventDefault();
                                            resetSet(true);
                                        }}>
                                        <Icon icon="chevron-left" size={18} />
                                    </a>
                                }
                                <FormTitle title={editTask ? "Edit Task : " + selectedTask?.title : "New Task"} margin={0} />
                            </FlexRow>

                            <TextBox name="title" label="Title*" labelUp block marginBottom={3} clear={reset} value={editTask ? selectedTask?.title : undefined} />
                            <TextArea name="description" label="Description" labelUp block rows={5} marginBottom={3} clear={reset} value={editTask ? selectedTask?.description : undefined} />

                            {
                                showAssignees &&
                                <Tagify id="tagify-task-assignees" name="assignees" label="Assign To*" labelUp block marginBottom={3} parentId="modal-job-tasks"
                                    apiUrl={API_ROUTES.TagifyAdminsAndAgents} placeholder="Select User(s)" selectedIds={editTask ? selectedTask?.assigneesUserIds : undefined}
                                    routeParams={[{
                                        name: "includeMe",
                                        value: "True"
                                    }]} />
                            }


                            <FlexRow>
                                <div className="flex-grow-1">

                                    <DatePicker id="dt-tasks-taskdate" name="taskdate" reset={reset} marginBottom={1} label="Task Date*" parentId="modal-job-tasks"
                                        minDate={editTask ? undefined : window.now()} labelUp block selectedDate={editTask && selectedTask?.startDateString ? selectedTask.startDateString : undefined}
                                        onPick={e => selectedTaskDateSet(e.date)} />

                                </div>
                                <div className="ms-2 w-150px">
                                    <TimePicker id="time-tasks-tasktime" name="tasktime" label="Task Time*" parentId="modal-job-tasks" marginBottom={1}
                                        labelUp block defaultValue={editTask ? selectedTask?.startTime : ""} clear={reset}
                                        onTimePicked={e => selectedTaskTimeSet(e.time)} />
                                </div>
                            </FlexRow>


                            {
                                !editTimezone &&
                                <div className="text-muted fs-9 mb-3">
                                    <Icon icon="clock" className="me-1" size={10} />
                                    {selectedTimezone?.name}
                                    <a href="#" className="ms-1"
                                        onClick={e => {
                                            e.preventDefault();
                                            editTimezoneSet(true);
                                        }}>
                                        <Icon icon="edit" size={10} />
                                    </a>
                                </div>
                            }

                            {
                                editTimezone ?
                                    <div className="mt-2">
                                        <DropDown id="drp-tasks-timezone" name="timezone" label="Timezone" labelUp block marginBottom={3}
                                            source={timezones} dataText="fullname" dataValue="id" parentModal="modal-job-tasks"
                                            autoSelectFirstItem={!editTask} defaultValue={editTask ? selectedTask?.timezone : undefined} >
                                        </DropDown>
                                    </div>
                                    :
                                    <input type="hidden" name="timezone" value={selectedTimezone?.id} />
                            }

                            <FlexRow>
                                <div className="flex-grow-1">
                                    <DatePicker id="dt-tasks-duedate" name="duedate" label="Due Date" parentId="modal-job-tasks" reset={reset}
                                        selectedDate={editTask && selectedTask?.dueDateString ? selectedTask?.dueDateString : selectedDueDate}
                                        minDate={minDueDate} labelUp block empty marginBottom={3}
                                        onPick={e => selectedDueDateSet(e.date)} />
                                </div>
                                <div className="ms-2 w-150px">
                                    <TimePicker id="time-tasks-duetime" name="duetime" label="Due Time" parentId="modal-job-tasks" labelUp block
                                        marginBottom={3} defaultValue={editTask ? selectedTask?.dueTime : ""} clear={reset}
                                        min={minDueTime} time={selectedDueTime} onTimePicked={e => selectedDueTimeSet(e.time)} />
                                </div>
                            </FlexRow>

                            <div className="fw-bold fs-7">
                                Notifications
                            </div>
                            <input type="hidden" name="notifCounts" value={notifs.length} />
                            {
                                notifs.map((item, k) =>
                                    <FlexRow key={k}>
                                        <div style={{
                                            width: "60%"
                                        }}>
                                            <TextBox id={"txt-notif-text-" + item.num} name={"notifAmount" + item.num} block marginBottom={3} numeric maxLength={3} value={item.notif}
                                                onChange={e => {
                                                    var arr = notifs;
                                                    arr.forEach(n => {
                                                        if (n.num == item.num) {
                                                            n.notif = e.target.value
                                                        }
                                                    });

                                                    notifsSet(a => [...arr])
                                                }} />
                                        </div>
                                        <div className="ms-2" style={{
                                            width: "30%"
                                        }}>
                                            <DropDown id={"drp-notif-types-" + item.num} name={"notifType" + item.num} block marginBottom={3}
                                                source={notifTypes} dataText="name" dataValue="value" defaultValue={item.type}
                                                onSelect={e => {
                                                    var arr = notifs;
                                                    arr.forEach(n => {
                                                        if (n.num == item.num) {
                                                            n.type = e ?? "1";
                                                        }
                                                    });

                                                    notifsSet(a => [...arr])
                                                }}>
                                            </DropDown>
                                        </div>
                                        <div className="ms-2">
                                            {
                                                (k + 1) == notifs.length ?
                                                    <button type="button" className="btn btn-secondary btn-sm btn-icon mb-3" onClick={addNotif}>
                                                        <Icon icon="plus" type="solid" />
                                                    </button>
                                                    :
                                                    <button type="button" className="btn btn-secondary btn-sm btn-icon mb-3" onClick={e => removeNotif(k)}>
                                                        <Icon icon="minus" type="solid" />
                                                    </button>
                                            }
                                        </div>
                                    </FlexRow>
                                )
                            }


                            <DropDown id="drp-tasks-priority" name="priority" label="Priority" labelUp block marginBottom={3}
                                source={priorities} dataText="name" dataValue="value" defaultValue={editTask ? selectedTask?.priority?.toString() : "2"}>
                            </DropDown>

                            <ToggleSwitch id="task-repeats" name="repeats" label="Repeat Task" raw className="mt-10 mb-5"
                                onChange={e => repeatSet(e)} />

                            {
                                repeat &&
                                <>
                                    <DropDown id="drp-tasks-repeatType" name="repeatType" label="Repeat Type" labelUp block marginBottom={3}
                                        source={repeatTypes} dataText="name" dataValue="value" defaultValue="1">
                                    </DropDown>
                                    <FlexRow>
                                        <div style={{
                                            width: "80%"
                                        }}>
                                            <DatePicker id="dt-tasks-repeatEndDate" name="repeatEndDate" label="End Date" labelUp block marginBottom={3} />
                                        </div>
                                        <div className="ms-2">
                                            <TimePicker id="time-tasks-repeatEndTime" name="repeatEndTime" label="End Time" labelUp block marginBottom={3} />
                                        </div>
                                    </FlexRow>
                                </>
                            }

                        </div>
                    </div>
                }

            </Modal>

            <Modal id="modal-delete-task"
                title="Delete Task"
                buttonColor="danger"
                buttonText="Delete Task"
                secondModal
                onConfirm={callback => {
                    hub.Delete(API_ROUTES.Jobs + "task/" + selectedTask?.id, res => {
                        if (res.success) {
                            fetch();
                            resetSet(true);
                            callback();
                        }
                        else {
                            callback(true)
                        }
                    })
                }}>

                <div>
                    <p>Are you sure you want to delete this task?</p>
                    <div>
                        {
                            selectedTask && <JobTaskCard task={selectedTask} noAction />
                        }
                    </div>
                </div>

            </Modal>
        </>
    )
}