import { Route } from "react-router-dom";
import ChatMainView from "../views/ChatMainView";



function ChatRoutes() {
    let k = 0;
    return (
      [
        <Route key={k++} path="/chat" element={<ChatMainView />}/>,
        <Route key={k++} path="/chat/:selectedId" element={<ChatMainView />}/>
      ]
    );
  }
  
  export default ChatRoutes;