import React, { useEffect, useState } from "react";
import { getUser, setPage, useHub } from "../../../core/Global";
import FlexRow from "../../../components/base/FlexRow";
import ColorPicker from "../../../components/form/ColorPicker";
import { API_ROUTES } from "../../../core/Constants";
import Icon from "../../../components/base/Icon";
import { Link } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import { DataTable, DataTableField } from "../../../components/data/DataTable";
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import Tagify from "../../../components/tagify/Tagify";
import TextBox from "../../../components/form/TextBox";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { ListItem } from "../../../core/Types";
import ShiftDepartment from "../models/ShiftDepartment";
import AddShiftDepartmentModal from "../components/AddShiftDepartmentModal";
import DropDown from "../../../components/form/DropDown";
import EditShiftDepartmentModal from "../components/EditShiftDepartmentModal";
import Section from "../../../components/base/Section";

function ShiftsDepartments() {

  const hub = useHub();
  const user = getUser();
  const [selectedItem, selectedItemSet] = useState<any>();
  const [editId, editIdSet] = useState<string>();
  const [editModel, editModelSet] = useState<ShiftDepartment>();
  const [refresh, refreshSet] = useState<boolean>();


  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);

  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);


  const edit = (data: any) => {
    editIdSet(data.id);
    editModelSet(data);
    editModalSet(true);
  }

  const add = () => createModalSet(true)

  const deleteGroup = (data: any) => {
    selectedItemSet(data);
    groupsRouteParamsSet([{
      name: "userId",
      value: data.ownerUserId
    }, {
      name: "excludeIds",
      value: data.id
    }])
    window.openModal("modal-delete-department");
  }

  const NameItem = (data: any) => (
    <FlexRow>
      <ColorPicker id={"color-picker-" + data.id} color={data.color} className="me-2"
        onSelect={color => {
          hub.Post(API_ROUTES.ShiftsDepartments + "color", {
            id: data.id,
            color: color
          }, res => {

          })
        }} />
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Status"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const QueuesItem = (data: ShiftDepartment) => (
    <FlexRow>
      {
        data.queues?.map((item, key) =>
          <div key={key} className="badge badge-secondary me-2">
            <Icon icon="arrow-right-to-line" className="me-2" />
            {item.name}
          </div>
        )
      }
    </FlexRow>
  )

  useEffect(() => {
    setPage("Shift Departments", "Account", "Shifts");
  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Department"
          apiRoute={API_ROUTES.ShiftsDepartments}
          ascending
          edit editCustom={edit}
          delete deleteCustom={deleteGroup}
          addCustom={add}
          refresh={refresh}>

          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Station(s)">
            <QueuesItem />
          </DataTableField>
          <DataTableField title="Timezone" dataValueName="timeZoneLabel" />
          <DataTableField title="Created At" dataValueName="createDate" />
          <DataTableField title="" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

        </DataTable>

      </Section>

      {
        createModal &&
        <AddShiftDepartmentModal
          onClose={() => createModalSet(false)}
          callback={() => refreshSet(true)} />
      }

      {
        editModal &&
        <EditShiftDepartmentModal id={editId} model={editModel}
          onClose={() => editModalSet(false)}
          callback={() => refreshSet(true)} />
      }

      <Modal id="modal-delete-department"
        title="Delete Department"
        buttonText="Confirm & Delete"
        buttonColor="danger"
        onSubmit={(body, callback) => {

          hub.Delete(API_ROUTES.ShiftsDepartments + selectedItem.id, res => {
            if (res.success) {
              refreshSet(true);
              toastSuccess("Department has been deleted successfully!");
            }
            if (callback) callback(res);
          })

        }}>
        <div>
          <div>
            Are you sure you want to delete this department?
          </div>
          <div className="text-danger fs-8">
            By deleting this department all assigned shifts will be deleted permanently!
          </div>
        </div>
      </Modal>

    </Layout>
  );
}

export default ShiftsDepartments;
