import { useEffect } from "react"
import FlexRow from "../../../components/base/FlexRow"
import User from "../models/User"
import AccountLoginChart from "../../../components/account/AccountLoginChart"
import AccountProfileCard from "../../../components/account/AccountProfileCard"
import AccountTechActiveJobs from "../../../components/account/AccountTechActiveJobs"
import AccountOverviewHeader from "../../../components/account/AccountOverviewHeader"
import AccountTechProfileCard from "../../../components/account/AccountTechProfileCard"
import Div from "../../../components/base/Div"
import { UserTypes } from "../../../core/Enums"
import AgentTotalCallChart from "../../agents/components/AgentTotalCallChart"
import AccountAgentTalkingActivityChart from "../../../components/account/AccountAgentTalkingActivityChart"
import AccountAgentCallsChart from "../../../components/account/AccountAgentCallsChart"
import AccountAgentMissedCallsChart from "../../../components/account/AccountAgentMissedCallsChart"


type Props = {
    userId?: string
    model?: User
}

export default function AccountOverview(props: Props) {

    useEffect(() => {
        window.createJobReportsSampleChart("kt_card_widget_1_chart");
    }, [])
    return (
        <div className="row">
            <Div className="col col-12 mb-5">
                <AccountOverviewHeader userId={props.userId} model={props.model} />
            </Div>
            <Div className={`col col-12 ${props.model?.userType == UserTypes.Admin ? "col-xxxl-6" : "col-md-6"} mb-5`}>
                <AccountProfileCard userId={props.userId} model={props.model} />
            </Div>
            <Div visible={props.model?.userType == UserTypes.Technician} className="col col-12 col-md-6 mb-5">
                <AccountTechProfileCard userId={props.userId} model={props.model} />
            </Div>
            <Div visible={props.model?.userType == UserTypes.Technician} className="col col-12 mb-5">
                <AccountTechActiveJobs userId={props.userId} />
            </Div>
            <Div visible={props.model?.userType == UserTypes.Agent} className="col col-12 col-md-6 mb-5">
                <div className="row">
                    <div className="col col-12 mb-5">
                        <AccountAgentTalkingActivityChart userId={props.userId} />
                    </div>
                    <div className="col col-md-6">
                        <AccountAgentCallsChart />
                    </div>
                    <div className="col col-md-6">
                        <AccountAgentMissedCallsChart />
                    </div>
                </div>
            </Div>
            {/* <Div className="col col-12 mb-5">
                <AccountLoginChart />
            </Div> */}
        </div>
    )
}