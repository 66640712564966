import React, { useEffect, useState } from "react";
import "./Agents.css";
import { setPage, useGlobal, useHub } from '../../../core/Global';
import Layout from '../../../components/layouts/Layout';
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Environment from '../../../env/env';
import Hub from "../../../api/Hub";
import { useParams, Link } from 'react-router-dom';
import AgentDetailsModel from "../models/AgentDetailsModel";
import AgentOverview from "./AgentOverview";
import AgentCallsList from "./AgentCallsList";
import AgentHeader from './AgentHeader';
import AgentShiftCalendar from "./AgentShiftCalendar";
import Section from "../../../components/base/Section";

function AgentDetailsView() {

    const env = new Environment();
    const hub = useHub();
    
    const { selectedId } = useParams(); 
    const [selectedPage, selectedPageSet] = useState<string>("Overview");
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<AgentDetailsModel>();




    const fetch = async () => {
        hub.Get("/accounts/agents/" + selectedId, res => {
            if(res?.data) {
                if(env.isDevelopment) console.log("AgentsView Model!", res.data);
                modelSet(res.data);
                fetchingSet(false);
                fetchedSet(true);
            }
        });
    }




    useEffect(()=> {
        if(env.isDevelopment) console.log("AgentsView Mounted!");
        setPage("Agent Details", "Agents");
        fetch();
    }, [selectedId])








    return (
        <Layout wide>

            <Div visible={fetching} className="mt-20">
                <Indicator />
            </Div>

            <Section fluid visible={fetched} className="mb-5 mb-xl-10">
                
                <AgentHeader model={model} agentId={selectedId} selectPage={page => selectedPageSet(page)} selectedPage={selectedPage} />

                {
                    selectedPage == "Overview" && <AgentOverview agentId={selectedId} model={model} selectPage={page => selectedPageSet(page)} />
                }
                {
                    selectedPage == "Calls History" && <AgentCallsList agentId={selectedId} />
                }
                {
                    selectedPage == "Working Calendar" && <AgentShiftCalendar agentId={selectedId} />
                }

            </Section>

           

        </Layout>
    )

}

export default AgentDetailsView;