import { useState } from "react";
import { toastSuccess } from "../../../../components/base/Toast";
import DropDown from "../../../../components/form/DropDown";
import Form from "../../../../components/form/Form";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub } from "../../../../core/Global";
import Icon from "../../../../components/base/Icon";



export default function Timezone () {

    const hub = useHub();
    const user = getUser();

    const [working, workingSet] = useState<boolean>(false);


    return (
        <Form className="form"
            onSubmit={(body, callback) => {
                workingSet(true);
                hub.Post("/accounts/users/timezone/" + user?.id, body, res => {
                    if(res.success) {
                        toastSuccess("Timezone has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Time Zone</h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                            working ? 
                            <>
                                <Icon icon="spinner-third" spin />
                                <span className="ms-2">Updating...</span>
                            </>
                            : <>Update Timezone</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <div>
                        <DropDown id="drp-update-timezones" name="timezone" label="Timezone"
                            apiSource={API_ROUTES.Users + "timezones"} defaultValue={user?.timeZoneId}
                            onApiSourceFetched={(data) => {
                                var ofs = window.getClientTimeZoneOffset();
                                var tz = window.getClientTimeZone()?.toLowerCase();
                                var tzArr = tz.split("/");
                                var cityName = tzArr.length > 1 ? tzArr[1] : tzArr[0];
                                
                                var id = "";

                                if (data && data.length > 0) {
                                    for (var i = 0; i < data.length; i++) {
                                        if (data[i].offset == ofs && data[i].name.toLowerCase().indexOf(cityName) > -1) {
                                            id = data[i].id;
                                            break;
                                        }
                                    }

                                    if (id == "") {
                                        for (var i = 0; i < data.length; i++) {
                                            if (data[i].offset == ofs) {
                                                id = data[i].id;
                                                break;
                                            }
                                        }
                                    }
                                }

                                if (id == "") id = "UTC";
                            }} />
                    </div>
                    
                </div>
            </div>
        </Form>
    )
}