import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/flags.json";
import sortingProps from "../sorting/flags.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import ColorPicker from "../../../../components/form/ColorPicker";
import TextBox from "../../../../components/form/TextBox";
import TextArea from "../../../../components/form/TextArea";
import Section from "../../../../components/base/Section";
import FlagItem from "../models/FlagItem";

function FlagsList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);
  const [selectedItem, selectedItemSet] = useState<FlagItem>();

  const [refresh, refreshSet] = useState<boolean>();
  const [clear, clearSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);
  const [systemStatus, systemStatusSet] = useState<boolean>();

  const edit = (data: any) => {
    selectedItemSet(data);
    editModalSet(true)
  }

  const deleteGroup = (data: any) => {
    selectedItemSet(data);
    groupsRouteParamsSet([{
      name: "userId",
      value: data.ownerUserId
    }, {
      name: "excludeIds",
      value: data.id
    }])
    deleteModalSet(true);
  }

  const AvtiveToggle = (data: any) => (
    <div>
      <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
        <input className="form-check-input" type="checkbox" defaultChecked={data.isActive}
          onChange={e => {
            hub.Post(API_ROUTES.Flags + "activetoggle", {
              id: data.id,
              active: e.target.checked ? "1" : "0"
            }, res => {

            })
          }} />
      </label>
    </div>
  )

  const NameItem = (data: any) => (
    <FlexRow>
      <ColorPicker id={"color-picker-" + data.id} color={data.color} className="me-2"
        onSelect={color => {
          hub.Post(API_ROUTES.Flags + "color", {
            id: data.id,
            color: color
          }, res => {

          })
        }} />
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const DescriptionItem = (data: FlagItem) => {

    useEffect(() => {
      window.createBootstrapTooltip("rate-descr-" + data.id);
    }, [])

    return (
      <div id={`rate-descr-${data.id}`} data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top"
        title={`<div class='fs-7 fw-normal line-break text-start'>${data.description}</div>`}
        className="w-150px limited-text">
        {data.description}
      </div>
    )
  }

  const JobsCount = (data: FlagItem) => (
    <div>
      {
        (data.activeJobsCount ?? 0) > 0 ?
          <a href={"/crm/jobs/?flags=" + data.id} target="_blank">
            {data.activeJobsCount}
          </a>
          :
          <div>
            0
          </div>
      }

    </div>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Flag"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: FlagItem) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("Flags", "CRM", "Base Data");

  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }

    if (clear) {
      setTimeout(() => {
        clearSet(false);
      }, 1000);
    }


  }, [refresh, clear])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Flag"
          apiRoute={API_ROUTES.Flags}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          addCustom={() => createModalSet(true)}
          edit editCustom={edit}
          delete={hasRole("DeleteFlags")} deleteCustom={deleteGroup}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <AvtiveToggle />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Description">
            <DescriptionItem />
          </DataTableField>
          <DataTableField title="Jobs">
            <JobsCount />
          </DataTableField>
          <DataTableField title="" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>


      {
        createModal &&
        <Modal id="modal-add-flag"
          title="Create New Flag"
          buttonText="Add Flag"
          staticBackdrop visible open
          onClose={() => createModalSet(false)}
          onOpen={() => {
            $("#txt-addflag-name").focus();
          }}
          onSubmit={(body, callback) => {

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid name!", false, "modal-add-flag");
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.Flags, body, res => {
              if (res.success) {
                refreshSet(true);
                clearSet(true);
                toastSuccess("Flag has been added successfully!");
              }
              if (callback) callback(res);
            })


          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system tag!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <FlexRow className="mb-5">
              <ColorPicker id="color-addd-flag" width={30} className="me-2" name="color" />
              <div className="flex-grow-1">
                <TextBox id="txt-addflag-name" name="name" block marginBottom={0} inlineStyle mediumText noTextError placeholder="Flag Name" clear={clear} />
              </div>
            </FlexRow>

            <TextArea name="description" label="Description" labelUp rows={6} />


          </div>
        </Modal>
      }

      {
        editModal && selectedItem &&
        <Modal id="modal-edit-flag"
          title="Edit Flag"
          buttonText="Update Flag"
          staticBackdrop open visible
          onClose={() => {
            editModalSet(false);
            selectedItemSet(undefined);
          }}
          onOpen={() => {
            $("#txt-editflag-name").focus();
          }}
          onSubmit={(body, callback) => {

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.showFormError("name", "Please write a valid name!", false, "modal-edit-jobstatus");
              if (callback) callback();
              return;
            }

            hub.Put(API_ROUTES.Flags + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Flag has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system tag!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-edit-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} selectedId={selectedItem.ownerUserId} single block />
              </>
            }

            <FlexRow className="mb-5">
              <ColorPicker id="color-editts-flag" width={30} className="me-2" name="color" color={selectedItem.color} />
              <div className="flex-grow-1">
                <TextBox id="txt-editflag-name" name="name" block marginBottom={0} inlineStyle mediumText noTextError placeholder="Flag Name" defaultValue={selectedItem.name} />
              </div>
            </FlexRow>

            <TextArea name="description" label="Description" labelUp rows={6} value={selectedItem.description} />


          </div>
        </Modal>
      }


      {
        deleteModal && selectedItem &&
        <Modal id="modal-delete-flag"
          title="Delete Flag"
          buttonText="Delete Flag"
          buttonColor="danger"
          onClose={() => {
            deleteModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            hub.Post(API_ROUTES.Flags + "delete/" + selectedItem.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Flag has been deleted successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            <div>
              Are you sure you want to delete this flag?
            </div>
          </div>
        </Modal>
      }






    </Layout>
  );
}

export default FlagsList;
