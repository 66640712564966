import { Link } from "react-router-dom";
import Hub from "../../api/Hub";
import { NotificationActions, NotificationParamsType, NotificationType } from "../../core/Enums";
import Notification from "../../models/Notification";
import Icon from "../base/Icon";
import { useState } from "react";
import DescriptionContainer from "../base/DescriptionContainer";

type Props = {
    item?: Notification
    onRead?: () => void
}
function NotificationItem(props: Props) {

    const[moreDescr, moreDescrSet] = useState<boolean>(false);

    const link = () => {
        if (props.item?.actionType == NotificationActions.AgentPage) {
            return "/agents/view/" + props.item.objectId;
        }
        if (props.item?.actionType == NotificationActions.CallPage) {
            return "/voice/call/" + props.item.objectId;
        }
        if (props.item?.actionType == NotificationActions.JobPage) {
            return "/crm/jobs/overview/" + props.item.objectId;
        }
        return "";
    }

    return (
        <div className="timeline-item" >
            <div className="timeline-line w-40px"></div>
            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">

                <div className={"symbol-label " + (props.item?.unread ? "bg-" + props.item?.color : "bg-secondary")}
                    style={{
                        backgroundColor: props.item?.color?.startsWith("#") ? props.item.color : undefined
                    }}>
                    <span className={"svg-icon svg-icon-2"}>
                        <Icon icon={props.item?.icon} 
                            className={(props.item?.unread ? "text-white" : "")} 
                            type={(props.item?.unread ? "solid" : "regular")} 
                            color={props.item?.color?.startsWith("#") ? props.item.color : undefined}
                            size={16} />
                    </span>
                </div>

            </div>
            <div className="timeline-content mt-2">
                <div className="pe-3 mb-5">
                    <div className={"fs-7 mb-1" + (props.item?.unread ? " fw-bolder" : "")}>
                        {
                            link() == "" ? 
                            <div className="text-dark cursor-pointer" onClick={props.onRead}>{props.item?.description}</div>
                            :
                            <Link to={link()} className="text-dark" onClick={e => {
                                if(props.onRead) props.onRead();
                            }}>
                                {props.item?.description}
                            </Link>
                        }
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        <div className={"text-muted me-2 fs-8" + (props.item?.unread ? " fw-bold" : "")}>
                            {props.item?.createdAt}
                        </div>
                        {
                            props.item?.companyName &&
                            <div className="d-flex align-items-center ms-3"> 
                                <Icon icon="briefcase" className="me-1 text-muted" size={10} />
                                <span className="text-muted fs-8">{props.item.companyName}</span>
                            </div>
                        }
                        {/* <div className="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" title="Nina Nilson">
                            <img src="assets/media/avatars/300-14.jpg" alt="img" />
                        </div> */}
                    </div>
                </div>
                {
                    props.item?.params && props.item.params.length > 0 && 
                    <div className="overflow-auto pb-5">
                        {
                            props.item?.paramsType == NotificationParamsType.InlineList &&
                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded py-2 px-5">
                                {
                                    props.item.params.map((p, k) => 
                                    <div key={k} className="d-flex align-items-center pe-5 pe-lg-10">
                                        {
                                            p.icon && <Icon icon={p.icon} />
                                        }
                                        <div className={"ms-2 fs-8" + (props.item?.unread ? " fw-bold" : "")} style={{marginTop: 1}}>
                                            {p.value}
                                        </div>
                                    </div>
                                    )
                                }
                            </div>
                        }

                        {
                            props.item?.paramsType == NotificationParamsType.BlockList &&
                            <>
                                {
                                    props.item.params.map((p, k) => 
                                        <div key={k} className="d-flex align-items-center border border-dashed border-gray-300 rounded py-2 px-5 mb-1">
                                            <div className="d-flex align-items-start pe-5 pe-lg-10">
                                                <div className={"ms-1 fw-bolder fs-8" + (props.item?.unread ? " fw-bold" : "")} style={{marginTop: 1}}>
                                                    {p.title}:
                                                </div>
                                                <div className={"ms-2 fs-8" + (props.item?.unread ? " fw-bold" : "")} style={{marginTop: 1}}>
                                                    <DescriptionContainer text={p.value} size={125} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                            
                        }
                        
                    </div>
                }
            </div>
        </div>
    )
}

export default NotificationItem;