import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import Icon from "../../../../components/base/Icon";
import { getUSA, hideLineWizard, refreshLinesList, useHub, usePrices } from "../../../../core/Global";
import Form from "../../../../components/form/Form";
import Indicator from "../../../../components/base/Indicator";
import Extension from "../../pbx/models/Extension";
import { TagifyDataItem } from "../../../../models/TagifyOptions";
import { API_ROUTES, RedirectTypes } from "../../../../core/Constants";
import FormTitle from "../../../../components/form/FormTitle";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import { useNavigate } from "react-router-dom";
import Country from "../../../base/models/Country";
import FlexRow from "../../../../components/base/FlexRow";
import DataItem from "../../../../models/DataItem";
import DropDown from "../../../../components/form/DropDown";
import { PhoneNumberTypes } from "../../../../core/Enums";
import TextBox from "../../../../components/form/TextBox";
import Tagify from "../../../../components/tagify/Tagify";
import TwilioLine from "../models/TwilioLine";
import PhoneInput from "../../../../components/form/PhoneInput";
import PhoneItem from "../../../../models/PhoneItem";


export default function LineSetupWizard() {

    const hub = useHub();
    const navigate = useNavigate();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const prices = usePrices()

    const [numberTypes] = useState<DataItem[]>([
        {
            id: "1",
            name: "Local"
        },
        {
            id: "2",
            name: "Toll-Free"
        }
    ])
    const [selectedNumberType, selectedNumberTypeSet] = useState<DataItem>({
        id: "1",
        name: "Local"
    });

    const [countries, countriesSet] = useState<Country[]>();
    const [selectedCountry, selectedCountrySet] = useState<Country>();
    const fetchCountries = () => {
        hub.Get(API_ROUTES.CountriesAllActive, res => {
            if (res.data) {
                countriesSet(res.data);
                selectedCountrySet(res.data[0]);
            }
        })
    }
    const [states, statesSet] = useState<any[]>();
    const [areaCodes, areaCodesSet] = useState<any[]>();
    const fetchStates = () => {
        hub.Get("/base/states/?countryId=" + selectedCountry?.id, res => {
            if (res.data) {
                statesSet(res.data);
            }
        })
    }
    useEffect(() => {
        window.makeSelect2("drp-areacodes", false, undefined, undefined, undefined, undefined, "modal-line-setup-wizard", true);
        if (states && selectedCountry?.alpha2Code?.toLowerCase() != "us") {
            var arr: any[] = [];
            states.forEach(s => {
                s.areaCodes.forEach((code: any) => {
                    if (arr.indexOf(code) < 0) arr.push(code);
                });
            });

            areaCodesSet(arr);
        }
    }, [states])


    useEffect(() => {
        if (selectedCountry) {
            fetchStates();
        }
    }, [selectedCountry])


    const [step, stepSet] = useState<number>(0);
    const [fetchingLines, fetchingLinesSet] = useState<boolean>();
    const [fetchedLines, fetchedLinesSet] = useState<boolean>();
    const [lines, linesSet] = useState<TwilioLine[]>();
    const [selectedLine, selectedLineSet] = useState<TwilioLine>();

    let b: any;
    const fetchLines = (params?: any) => {

        fetchedLinesSet(false);
        fetchingLinesSet(true);
        selectedLineSet(undefined);
        params = params ?? b;
        b = params;

        var q = "";
        if (params?.country && params.country != "") {
            if (q != "") q += "&";
            q += "country=" + params.country;
        }
        if (params?.areaCode && params.areaCode != "") {
            if (q != "") q += "&";
            q += "areaCode=" + params.areaCode;
        }
        if (params?.keyword && params.keyword != "") {
            if (q != "") q += "&";
            q += "keyword=" + encodeURIComponent(params.keyword);
        }
        if (params?.match) {
            if (q != "") q += "&";
            q += "match=" + encodeURIComponent(params.match);
        }

        if (params?.voice == "True") {
            if (q != "") q += "&";
            q += "voice=True";
        }

        if (params?.sms == "True") {
            if (q != "") q += "&";
            q += "sms=True";
        }

        if (params?.mms == "True") {
            if (q != "") q += "&";
            q += "mms=True";
        }

        if (selectedNumberType) {
            if (q != "") q += "&";
            q += "numberType=" + selectedNumberType.id;
        }

        if (q != "") q = "?" + q;

        hub.Get("/voice/lines/available/" + q, res => {
            if (res.data) {
                linesSet(res.data);
                fetchedLinesSet(true);
                fetchingLinesSet(false);
            }
        })

    }

    const [inboundItems] = useState<DataItem[]>([
        {
            id: "0",
            name: "None"
        },
        {
            id: "1",
            name: "Extensions"
        },
        {
            id: "2",
            name: "Queues"
        },
        {
            id: "3",
            name: "Redirect"
        }
    ])

    const [selectedInboundItem, selectedInboundItemSet] = useState<DataItem>()

    useEffect(() => {
        if (selectedInboundItem?.id == "0") inbountModeSet("none")
        else if (selectedInboundItem?.id == "1") inbountModeSet("existingext")
        else if (selectedInboundItem?.id == "2") inbountModeSet("existingque")
        else if (selectedInboundItem?.id == "3") inbountModeSet("redirect")
        else inbountModeSet("")
    }, [selectedInboundItem])


    const [inbountMode, inbountModeSet] = useState<string>("");

    const [exts, extsSet] = useState<TagifyDataItem[]>();
    const [selectedExt, selectedExtSet] = useState<TagifyDataItem>();
    const [newExtName, newExtNameSet] = useState<string>("");

    const [ques, quesSet] = useState<TagifyDataItem[]>();
    const [selectedQue, selectedQueSet] = useState<TagifyDataItem>();
    const [newQueName, newQueNameSet] = useState<string>("");

    const [redirectTypes] = useState<DataItem[]>(RedirectTypes);
    const [selectedRedirectType, selectedRedirectTypeSet] = useState<DataItem>();

    const [redirectToExternalNumber, redirectToExternalNumberSet] = useState<PhoneItem>();



    const [outboundExts, outboundExtsSet] = useState<any[]>([]);
    const [outboundExtIds, outboundExtIdsSet] = useState<string[]>([]);

    const [defaultOutbound, defaultOutboundSet] = useState<boolean>(false);


    const fetchExts = () => {
        hub.Get(API_ROUTES.TagifyExtensions, res => {
            if (res.data) {
                extsSet(res.data);
            }
        })
    }

    const fetchQueus = () => {
        hub.Get(API_ROUTES.TagifyQueues, res => {
            if (res.data) {
                quesSet(res.data);
            }
        })
    }

    useEffect(() => {

        newExtNameSet("");
        newQueNameSet("");
        selectedExtSet(undefined);
        selectedQueSet(undefined);
        selectedRedirectTypeSet(undefined);

        window.updateSelect2("drp-wizard-lines-extensions", "");
        window.updateSelect2("drp-wizard-lines-queues", "");
        window.updateSelect2("drp-wizard-lines-redirects", "");

        if (inbountMode == "existingext") window.enableSelect2("drp-wizard-lines-extensions");
        else window.disableSelect2("drp-wizard-lines-extensions");

        if (inbountMode == "existingque") window.enableSelect2("drp-wizard-lines-queues");
        else window.disableSelect2("drp-wizard-lines-queues");

        if (inbountMode == "newext") $("#txt-wizard-lines-extname").focus();
        if (inbountMode == "newque") $("#txt-wizard-lines-quname").focus();

        if (inbountMode == "redirect") window.enableSelect2("drp-wizard-lines-redirects");
        else window.disableSelect2("drp-wizard-lines-redirects");

    }, [inbountMode])


    const wizardButton = () => {
        return "Buy Phone Number";
        if (step == 1) return "Next Step >";
        if (step == 2) return "Next Step >";
        if (step == 3) return "Next Step >";
        if (step == 4) return "Confirm & Buy Line";
        return "Submit";
    }

    const cancelButtonText = () => {
        if (step == 1) return "Discard";
        if (step > 1) return "< Back";
        return "Cancel";
    }

    const wizardDisabled = () => {
        if (step == 1 && !selectedLine) return true;

        if (step == 2 && inbountMode == "") return true;
        if (step == 2 && inbountMode == "existingext" && !selectedExt) return true;
        if (step == 2 && inbountMode == "newext" && newExtName == "") return true;
        if (step == 2 && inbountMode == "existingque" && !selectedQue) return true;
        if (step == 2 && inbountMode == "newque" && newQueName == "") return true;
        if (step == 2 && inbountMode == "redirect" && !selectedRedirectType) return true;
        if (step == 2 && inbountMode == "redirect" && selectedRedirectType?.id == "2"
            && (!redirectToExternalNumber || !redirectToExternalNumber.phone || redirectToExternalNumber.phone.length < 8)) return true;

        return false;
    }

    useEffect(() => {
        window.onModalOpen("modal-line-setup-wizard", () => {
            stepSet(1);
            inbountModeSet("existingext");
        })
    }, [])

    useEffect(() => {
        if (step == 1) {
            fetchLines();
            fetchCountries();
        }
        if (step == 2) {
            fetchExts();
            fetchQueus();
            if (inbountMode != "redirect") window.disableSelect2("drp-wizard-lines-redirects");
        }
    }, [step])






    return (
        <>
            <Modal id="modal-line-setup-wizard"
                title="Buy Phone Number" noForm
                cancelButton visible open staticBackdrop
                buttonText={wizardButton()}
                cancelText={cancelButtonText()}
                confirmDisabled={wizardDisabled()}
                onClose={() => {
                    hideLineWizard();
                }}
                onConfirm={callback => {
                    if (step == 100) {
                        stepSet(2);
                        callback(true);
                    }
                    else if (step == 200) {
                        stepSet(3);
                        callback(true);
                    }
                    else if (step == 300) {
                        stepSet(4);
                        callback(true);
                    }
                    else if (true) {

                        var outbounds = "";
                        outboundExtIds.forEach(e => {
                            if (outbounds != "") outbounds += ",";
                            outbounds += e;
                        });

                        hub.Post(API_ROUTES.Lines + "wizard", {
                            phoneNumber: selectedLine?.phoneNumber,
                            numberType: selectedNumberType.id,
                            friendlyName: selectedLine?.friendlyName,
                            //inboundMode: inbountMode,
                            //selectedExt: selectedExt?.value,
                            //newExtName: newExtName,
                            //selectedQue: selectedQue?.value,
                            //newQueName: newQueName,
                            //outboundExtIds: outbounds,
                            //selectedRedirectType: selectedRedirectType?.id,
                            //redirectToExternalNumber: redirectToExternalNumber?.phone,
                            //redirectToExternalNumberCountryCode: "+1"
                        }, res => {

                            if (res.success) {
                                toastSuccess("Phone numbner has been added to your account!");
                                callback();
                                navigate("/voice/lines");
                                refreshLinesList();
                            }
                            else {
                                toastError(res.message);
                                callback(true);
                            }

                        });


                    }

                }}
                // onCloseButton={() => {
                //     window.openModal("modal-line-setup-wizard-discard");
                // }}
                // onCancel={() => {
                //     if (step == 1) {
                //         window.openModal("modal-line-setup-wizard-discard");
                //     }
                //     if (step == 2) {
                //         stepSet(1);
                //     }
                //     else if (step == 3) {
                //         stepSet(2);
                //     }
                //     else if (step == 4) {
                //         stepSet(3);
                //     }
                // }}
                size="XLarge">
                <div>
                    <div className="stepper stepper-links d-flex flex-column">
                        {/* <div className="stepper-nav mb-5">
                            {
                                step > 1 ?
                                    <div className={"stepper-item text-success"} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center text-success">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={20} />
                                            Phone Number
                                        </h3>
                                    </div>
                                    :
                                    <div className={"stepper-item" + (step == 1 ? " current" : "")} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center">
                                            <Icon icon="circle-1" className="me-2" type="solid" size={20} />
                                            Phone Number
                                        </h3>
                                    </div>
                            }

                            {
                                step > 2 ?
                                    <div className={"stepper-item text-success"} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center text-success">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={20} />
                                            Inbound Route
                                        </h3>
                                    </div>
                                    :
                                    <div className={"stepper-item" + (step == 2 ? " current" : "")} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center">
                                            <Icon icon="circle-2" className="me-2" type="solid" size={20} />
                                            Inbound Route
                                        </h3>
                                    </div>
                            }

                            {
                                step > 3 ?
                                    <div className={"stepper-item text-success"} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center text-success">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={20} />
                                            Outbound Route
                                        </h3>
                                    </div>
                                    :
                                    <div className={"stepper-item" + (step == 3 ? " current" : "")} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title d-flex align-items-center">
                                            <Icon icon="circle-3" className="me-2" type="solid" size={20} />
                                            Outbound Route
                                        </h3>
                                    </div>
                            }

                            <div className={"stepper-item" + (step == 4 ? " current" : "")} data-kt-stepper-element="nav">
                                <h3 className="stepper-title d-flex align-items-center">
                                    <Icon icon="circle-4" className="me-2" type="solid" size={20} />
                                    Confirm
                                </h3>
                            </div>
                        </div> */}

                        <div>
                            {
                                (selectedLine && step > 1) &&
                                <div className="card-body d-flex flex-column p-6 bg-light-success">
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={30} />
                                        </div>
                                        <div>
                                            <a className={"fs-6 fw-bolder text-gray-800"}>
                                                {selectedLine.friendlyName}
                                            </a>
                                            <div className={"fs-8 mt-1 text-gray-600"}>
                                                ${prices?.localNumberUs}/Month
                                            </div>
                                        </div>
                                        <div className="ms-auto">

                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                step > 2 &&
                                <div className="card-body d-flex flex-column p-6 bg-light-success mt-2">
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={30} />
                                        </div>
                                        <div>
                                            <a className={"fs-6 fw-bolder text-gray-800"}>
                                                {
                                                    (inbountMode == "none") && <span>Inbound Route</span>
                                                }
                                                {
                                                    (inbountMode == "existingext" || inbountMode == "newext") && <span>Assigned To Extension</span>
                                                }
                                                {
                                                    (inbountMode == "existingque" || inbountMode == "newque") && <span>Assigned To Queue</span>
                                                }
                                                {
                                                    (inbountMode == "redirect" && selectedRedirectType?.id == "1") && <span>Redirect to Jobs</span>
                                                }
                                                {
                                                    (inbountMode == "redirect" && selectedRedirectType?.id == "2") && <span>Redirect to External Phone</span>
                                                }
                                            </a>
                                            <div className={"fs-8 mt-1 text-gray-600"}>
                                                {
                                                    (inbountMode == "none") && <span>None</span>
                                                }
                                                {
                                                    inbountMode == "existingext" && <span><Icon icon="phone-office" size={10} className="me-1" /> {selectedExt?.title}</span>
                                                }
                                                {
                                                    inbountMode == "newext" && <span><Icon icon="phone-office" size={10} className="me-1" /> {newExtName}</span>
                                                }
                                                {
                                                    inbountMode == "existingque" && <span><Icon icon="arrow-right-to-line" size={10} className="me-1" /> {selectedQue?.title}</span>
                                                }
                                                {
                                                    inbountMode == "newque" && <span><Icon icon="arrow-right-to-line" size={10} className="me-1" /> {newQueName}</span>
                                                }
                                                {
                                                    (inbountMode == "redirect" && selectedRedirectType?.id == "2") && <span><Icon icon="phone" size={10} className="me-1" />{redirectToExternalNumber?.phoneFriendly}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="fs-7 fw-bolder text-end mb-2">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                step > 3 &&
                                <div className="card-body d-flex flex-column p-6 bg-light-success mt-2">
                                    <div className="d-flex align-items-center">
                                        <div className="me-2">
                                            <Icon icon="circle-check" className="me-2 text-success" type="solid" size={30} />
                                        </div>
                                        <div>
                                            <a className={"fs-6 fw-bolder text-gray-800"}>
                                                <span>Outbound Route</span>
                                            </a>
                                            <div className={"fs-8 mt-1 text-gray-600"}>
                                                {
                                                    outboundExts.length == 0 ?
                                                        <span>No Extension Selected!</span>
                                                        :
                                                        <>
                                                            {
                                                                outboundExts.map((ex, k) => <span key={k} className="me-4"><Icon icon="phone-office" size={10} className="me-1" /> {ex.text}</span>)
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="fs-7 fw-bolder text-end mb-2">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                step == 1 &&
                                <div className="row">
                                    <div className="col-12" style={{ minHeight: 600 }}>
                                        <Form onSubmit={(body, callback) => {
                                            fetchLines(body);
                                        }}>
                                            <div className="row border-bottom pb-2 mb-2">
                                                <div className="col-12">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-1 w-75px">
                                                            <DropDown id="drp-countries" name="country" small block raw marginBottom={0} deselect={false}
                                                                placeholder="Countries" source={countries} dataText="alpha2Code" dataValue="id" parentModal="modal-line-setup-wizard"
                                                                onSelectItem={c => selectedCountrySet(c)} defaultValue={getUSA().id} />
                                                        </div>
                                                        <div className="me-1">
                                                            <select id="drp-areacodes" name="areaCode" className="form-select form-inputs form-select-sm w-125px" data-placeholder="Area Codes">
                                                                <option></option>
                                                                {
                                                                    (selectedCountry?.alpha2Code?.toLocaleLowerCase() == "us" || selectedCountry?.alpha2Code?.toLocaleLowerCase() == "ca") ?
                                                                        <>
                                                                            {
                                                                                states?.map((item, key) =>
                                                                                    <optgroup key={key} label={item.name}>
                                                                                        {item.areaCodes?.map((code: any, k: any) => <option key={k} value={code}>{code}</option>)}
                                                                                    </optgroup>
                                                                                )
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                areaCodes?.sort((a: any, b: any) => a - b).map((code: any, k: any) =>
                                                                                    <option key={k} value={code}>
                                                                                        +{selectedCountry?.phoneCode} ({code})
                                                                                    </option>)
                                                                            }
                                                                        </>
                                                                }

                                                            </select>
                                                        </div>
                                                        <div className="me-1">
                                                            <input className="form-control form-inputs form-control-sm w-125px" type="tel" id="txt-lines-keyword"
                                                                name="keyword" placeholder="Part of Number" autoComplete="off" maxLength={8}
                                                                onKeyPress={e => {
                                                                    if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                                                                        e.preventDefault();
                                                                    }
                                                                }} />
                                                        </div>
                                                        <div className="me-5 w-150px">
                                                            <DropDown id="drp-numbertypes" name="numberType" small block marginBottom={0} deselect={false}
                                                                source={numberTypes} parentModal="modal-line-setup-wizard"
                                                                onSelectItem={c => selectedNumberTypeSet(c)} defaultValue="1" />
                                                        </div>
                                                        <div className="me-5">
                                                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                                <input id="chk-search-voice" className="form-check-input" type="checkbox" name="voice" value="True" defaultChecked />
                                                                <label className="form-check-label" htmlFor="chk-search-voice">
                                                                    Voice
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="me-5">
                                                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                                <input id="chk-search-sms" className="form-check-input" type="checkbox" name="sms" value="True" />
                                                                <label className="form-check-label" htmlFor="chk-search-sms">
                                                                    SMS
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="me-5">
                                                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                                <input id="chk-search-mms" className="form-check-input" type="checkbox" name="mms" value="True" />
                                                                <label className="form-check-label" htmlFor="chk-search-mms">
                                                                    MMS
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary btn-sm w-125px ms-auto">
                                                            <Icon icon="refresh" />
                                                            Refresh List
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                        {
                                            fetchingLines &&
                                            <div className="row">
                                                <div className="col-12 p-10 mt-20">
                                                    <Indicator />
                                                </div>
                                            </div>
                                        }
                                        {
                                            fetchedLines &&
                                            <>
                                                <div className="row">
                                                    {
                                                        lines?.map((item, key) =>
                                                            <div key={key} className="col-12 col-md-4">
                                                                <div className={"card mb-5 border cursor-pointer" + (selectedLine == item ? " bg-primary text-white" : "")}
                                                                    onClick={e => {
                                                                        selectedLineSet(item);
                                                                    }}>
                                                                    <div className="card-body d-flex flex-column p-6">
                                                                        <div className="d-flex align-items-start">
                                                                            <Icon icon="phone-rotary" type="solid" size={18} className="me-4 mt-1" />
                                                                            <div>
                                                                                <FlexRow>
                                                                                    <a className={"fs-6 fw-bolder me-5 " + (selectedLine == item ? " text-white" : " text-gray-800")}>
                                                                                        {item.friendlyName}
                                                                                    </a>
                                                                                    {
                                                                                        item.voice ? <Icon icon="phone" type="solid" title="Voice Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                            : <Icon icon="phone" type="regular" title="Voice Disabled" className="me-4 text-muted" />
                                                                                    }
                                                                                    {
                                                                                        item.sms ? <Icon icon="comment-lines" type="solid" title="SMS Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                            : <Icon icon="comment-lines" type="regular" title="SMS Disabled" className="me-4 text-muted" />
                                                                                    }
                                                                                    {
                                                                                        item.mms ? <Icon icon="image" type="solid" title="MMS Enabled" className={"me-4 " + (selectedLine == item ? "text-white" : "text-primary")} />
                                                                                            : <Icon icon="image" type="regular" title="MMS Disabled" className="me-4 text-muted" />
                                                                                    }
                                                                                </FlexRow>
                                                                                <FlexRow className={"fs-8 mt-2" + (selectedLine == item ? " text-white" : " text-gray-400")}>
                                                                                    <span className="me-5">
                                                                                        {PhoneNumberTypes[item.numberType ?? 1]}
                                                                                    </span>
                                                                                    {
                                                                                        item.region &&
                                                                                        <>
                                                                                            <Icon icon="location-dot" className="me-1" />
                                                                                            <span>
                                                                                                {item.rateCenter && item.rateCenter != "" ? item.rateCenter + ", " + item.region : item.region}
                                                                                            </span>
                                                                                        </>
                                                                                    }

                                                                                </FlexRow>
                                                                            </div>
                                                                            <div className="ms-auto">
                                                                                <div className="fs-7 fw-bolder text-end mb-2">
                                                                                    {moneyFormatter.format(item.price ?? 0)}/Month
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                {
                                                    (lines == null || lines.length == 0) &&
                                                    <div className="p-10 text-center">
                                                        <Icon icon="phone-rotary" type="solid" size={48} />
                                                        <div className="text-center mt-10">
                                                            There is no result! Please try again in few minutes!
                                                        </div>
                                                    </div>
                                                }
                                            </>

                                        }

                                    </div>
                                </div>
                            }

                            {
                                step == 2 &&
                                <div className="mt-10">

                                    <div className="row align-items-center mb-10">
                                        <div className="col-md-3 col-xxl-2 col-12">
                                            <label className="form-check-label" htmlFor="wizardextmode-existingext">
                                                Destination
                                            </label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <DropDown id="drp-wizard-lines-destinations" placeholder="Select a Destination"
                                                source={inboundItems} block marginBottom={0}
                                                onSelectItem={e => selectedInboundItemSet(e)}
                                                onClear={() => selectedInboundItemSet(undefined)} />
                                        </div>
                                    </div>

                                    {
                                        selectedInboundItem?.id == "1" &&
                                        <>
                                            <div className="row align-items-center mb-5">
                                                <div className="col-md-3 col-xxl-2 col-12">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" name="wizardextmode" id="wizardextmode-existingext"
                                                            checked={(inbountMode == "existingext")} onClick={e => inbountModeSet("existingext")} />
                                                        <label className="form-check-label" htmlFor="wizardextmode-existingext">
                                                            Existing Extension
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <DropDown id="drp-wizard-lines-extensions" placeholder="Select an Extension"
                                                        source={exts} dataText="title" dataValue="value" block marginBottom={0}
                                                        onSelectItem={e => selectedExtSet(e)} reset={!selectedExt} />
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3 col-xxl-2 col-12">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" name="wizardextmode" id="wizardextmode-newext"
                                                            checked={(inbountMode == "newext")} onClick={e => inbountModeSet("newext")} />
                                                        <label className="form-check-label" htmlFor="wizardextmode-newext">
                                                            New Extension
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <TextBox id="txt-wizard-lines-extname" block placeholder="Extension Name" marginBottom={0}
                                                        disabled={(inbountMode != "newext")} value={newExtName} onTextChanges={e => newExtNameSet(e)} />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        selectedInboundItem?.id == "2" &&
                                        <>
                                            <div className="row align-items-center mb-5">
                                                <div className="col-md-3 col-xxl-2 col-12">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" name="wizardextmode" id="wizardextmode-existingque"
                                                            checked={(inbountMode == "existingque")} onClick={e => inbountModeSet("existingque")} />
                                                        <label className="form-check-label" htmlFor="wizardextmode-existingque">
                                                            Existing Queue
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <DropDown id="drp-wizard-lines-queues" placeholder="Select an Queue" onSelectUpdate={inbountMode}
                                                        source={ques} dataText="title" dataValue="value" block marginBottom={0}
                                                        onSelectItem={e => selectedQueSet(e)} reset={!selectedQue} />
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3 col-xxl-2 col-12">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" name="wizardextmode" id="wizardextmode-newque"
                                                            checked={(inbountMode == "newque")} onClick={e => inbountModeSet("newque")} />
                                                        <label className="form-check-label" htmlFor="wizardextmode-newque">
                                                            New Queue
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <TextBox id="txt-wizard-lines-quname" block placeholder="Queue Name" marginBottom={0}
                                                        disabled={(inbountMode != "newque")} value={newQueName} onTextChanges={e => newQueNameSet(e)} />
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        selectedInboundItem?.id == "3" &&
                                        <>
                                            <div className="row align-items-center mb-5">
                                                <div className="col-md-3 col-xxl-2 col-12">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" name="wizardextmode" id="wizardextmode-redirect"
                                                            checked={(inbountMode == "redirect")} onClick={e => inbountModeSet("redirect")} />
                                                        <label className="form-check-label" htmlFor="wizardextmode-redirect">
                                                            Redirect Type
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <DropDown id="drp-wizard-lines-redirects" placeholder="Select Redirect Type" block marginBottom={0}
                                                        source={redirectTypes} onSelectItem={r => selectedRedirectTypeSet(r)} onSelectUpdate={inbountMode} />
                                                </div>
                                            </div>
                                            {
                                                selectedRedirectType?.id == "2" &&
                                                <div className="row align-items-center mb-5">
                                                    <div className="col-md-3 col-xxl-2 col-12">
                                                        <label className="form-check-label" htmlFor="wizardextmode-redirect">
                                                            Redirect To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <PhoneInput id="phone-redirect-to"
                                                            onComplete={e => redirectToExternalNumberSet(e)}
                                                            onInComplete={() => redirectToExternalNumberSet(undefined)}
                                                            countryCodes="us-only" block marginBottom={0} />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }


                                </div>
                            }

                            {
                                step == 3 &&
                                <div>
                                    <FormTitle title="Outbound Route" margin={10} />

                                    <div className="row align-items-center">
                                        <div className="col-12 col-xxl-9">
                                            <Tagify id="tagify-outbound-exts" label="Select Extension(s)"
                                                hint="Selected extension(s) which use the phone number to make outgoing calls!"
                                                apiUrl={API_ROUTES.TagifyExtensions} labelUp
                                                onChange={e => outboundExtIdsSet(e)} />
                                        </div>
                                    </div>

                                </div>
                            }

                            {
                                step == 4 &&
                                <>
                                    <div className="mx-20 my-10">
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bolder fs-1">
                                                {selectedLine?.friendlyName}
                                            </div>
                                            <div className="ms-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="fw-bold fs-4">${selectedLine?.price}</div>
                                                    <div className="ms-2">monthly fee</div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-10" />
                                    </div>
                                    <div className="mx-20 my-10">

                                        <div className="mt-10 d-flex align-items-center">
                                            <Icon icon="info-circle" type="solid" size={16} />
                                            <div className="ms-3">
                                                You'll be charged ${selectedLine?.price} immediately. Afterwards, you'll be charged ${selectedLine?.price}/month in addition to the usage you incur on the phone number.
                                            </div>
                                        </div>
                                        <div className="mt-4 d-flex align-items-center">
                                            <Icon icon="globe" type="solid" size={16} />
                                            <div className="ms-3">
                                                Voice and Messaging will be routed to the United States (US1) Region. You can re-route in the number configuration after purchase.
                                            </div>
                                        </div>
                                        {/* <hr className="my-10" />
                                    <div className="fw-bold fs-3 mt-10">
                                        Capabilities
                                    </div>
                                    <div className="mt-10 d-flex align-items-center">
                                        <Icon icon="phone" size={20} />
                                        <div className="ms-6">
                                            <div className="fs-5 fw-bold">
                                                Voice
                                            </div>
                                            <div>
                                                Receive incoming calls and make outgoing calls.
                                            </div>
                                        </div>
                                    </div> */}

                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal id="modal-line-setup-wizard-discard"
                title="Discard"
                buttonColor="warning"
                buttonText="Discard Wizard"
                secondModal
                onConfirm={() => {
                    window.closeModal("modal-line-setup-wizard-discard");
                    window.closeModal("modal-line-setup-wizard");
                }}>
                <p>
                    Are you sure you want to discard the changes and quit wizard?
                </p>
            </Modal>
        </>
    )
}