import React from "react";
import { BootstrapColors, FontAwesomeIcons } from '../../core/Types';
import Icon from "./Icon";



type AlertProps = {
    type?: BootstrapColors,
    className?: string,
    text?: string,
    icon?: FontAwesomeIcons
}

function Alert(props: AlertProps) {

    const createClassName = () => {
        var className = "alert alert-";
        className += props.type ? props.type : "info";
        if(props.className) className += " " + props.className;
        return className;
    }

    return (
        <div className={createClassName()}>
            {
                props.icon ? <Icon icon={props.icon} type="solid" size={20} className="me-2" />
                : props.type == "warning" ? <Icon icon="triangle-exclamation" type="solid" size={20} className="me-2" />
                : <></>
            }
            {props.text}
        </div>
    )
}

export default Alert;

