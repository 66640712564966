import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import { DataTable, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, setPage, useGlobal, useHub } from '../../../../core/Global';
import Section from "../../../../components/base/Section";
import Modal from "../../../../components/base/Modal";
import TextBox from "../../../../components/form/TextBox";
import Tagify from "../../../../components/tagify/Tagify";
import { toastSuccess } from "../../../../components/base/Toast";
import WhitelistIPItem from "../models/WhitelistIPItem";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import TextArea from "../../../../components/form/TextArea";


function FirewallWhitelist() {

  const hub = useHub();
  const user = getUser();
  const [createModal, createModalSet] = useState<boolean>(false);

  const [refresh, refreshSet] = useState<boolean>();
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  const isValidIP = (ip?: string) => {
    if (!ip) return false;
    var arr = ip.split(".");
    if (arr.length != 4) return false;

    for (var i = 0; i < arr.length; i++) {
      var n = Number.parseInt(arr[i]);
      if (isNaN(n) || n < 0 || n > 255) return false;
    }
    return true;
  }

  const UserItem = (data: WhitelistIPItem) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "Unassigned"}
      </div>
      <div className="small text-muted">
        @{data.ownerUser?.username}
      </div>
    </div>
  )

  const DescriptionItem = (data: WhitelistIPItem) => {

    useEffect(() => {
      window.createBootstrapTooltip("whitelist-ip-row-" + data.id);
    }, [])

    return (
      <div id={`whitelist-ip-row-${data.id}`} data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-placement="top" title={data.description}
        className="w-150px limited-text">
        {data.description}
      </div>
    )
  }

  const CreatedItem = (data: WhitelistIPItem) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.createDate}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )



  useEffect(() => {
    setPage("Whitelist IPs", "System", "Firewall");
  }, [])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable objectName="IP Address"
          apiRoute={API_ROUTES.FirewallWhitelist}
          addCustom={() => createModalSet(true)}
          refresh={refresh} delete>
          <DataTableField title="IP Address" dataValueName="ipAddress" />
          <DataTableField title="Assigned To" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Description">
            <DescriptionItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>

      {
        createModal &&
        <Modal id="modal-add-whitelist-ip"
          title="New IP Address"
          open visible staticBackdrop
          onClose={() => createModalSet(false)}
          buttonText="Add IP Address"
          onSubmit={(body, callback) => {

            var valid = true;

            if (user?.isSuperAdmin) {
              if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
                window.formError("ownerUserId", "Please choose the assignee user!", "modal-add-whitelist-ip");
                valid = false;
              }
            }

            if (!isValidIP(body.ip)) {
              window.formError("ip", "IP Address is not valid!", "modal-add-whitelist-ip", true);
              valid = false;
            }

            if (!valid) {
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.FirewallWhitelist, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("IP Address has been added to whitelist!");
              }
              if (callback) callback(res);
            })

          }}>

          {
            user?.isSuperAdmin &&
            <Tagify name="ownerUserId" id="drp-whitelist-ownerUserId" placeholder="Select a Customer"
              apiUrl={API_ROUTES.TagifyCustomers} single block label="Assign To" labelUp />
          }

          <TextBox id="txt-add-ip" label="IP Address" name="ip" labelUp block ipAddress placeholder="000.000.000.000" autoFocus />

          <TextArea id="txt-add-descr" label="Description" name="description" labelUp block rows={3}
            placeholder="Write about the IP Address (Optional)" />

        </Modal>
      }


    </Layout>
  );
}

export default FirewallWhitelist;
