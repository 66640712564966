import React, { useEffect, useState } from "react";
import Environment from "../../env/env";
import { getCountry, getUSA, useCountries, useHub, useScreen } from "../../core/Global";
import { HereGeocodeItem } from "../../models/MapModels";
import Indicator from "../base/Indicator";
import Icon from "../base/Icon";
import DropDownItem from "../../models/DropDownItem";
import Country from "../../modules/base/models/Country";
import { API_ROUTES } from "../../core/Constants";

interface AddressFieldProps {
    id: string
    name?: string
    label?: string
    labelUp?: boolean
    small?: boolean
    solid?: boolean
    stateId?: string
    placeholder?: string
    marginBottom?: number
    noMarginBottom?: boolean
    defaultValue?: string
    value?: string
    block?: boolean
    tooltip?: string
    raw?: boolean
    absolutePosition?: boolean
    top?: number
    left?: number
    width?: any
    zIndex?: number
    reset?: boolean
    parent?: string
    selectedAddress?: HereGeocodeItem
    selectedAddressId?: string
    defaultCountry?: Country
    country?: Country
    countryId?: string
    addressId?: string
    tabIndex?: number
    verify?: boolean
    onSelect?: (value: HereGeocodeItem) => void
    onUnselect?: () => void
    onCountrySelect?: (e: Country) => void
    onTextChanged?: (e: string) => void
}

let searchTimout:NodeJS.Timeout;
export default function AddressField(props: AddressFieldProps) {

    let addressInput: HTMLInputElement | null;
    const hub = useHub();
    const env = new Environment();

    const screen = useScreen();
    
    const fontClass = () => {
        if (props.small) return "fs-8";
        return "fs-7";
    }

    const countries = useCountries();
    const [selectedCountry, selectedCountrySet] = useState<Country>();

    const [addressText, addressTextSet] = useState<string>();
    const [address, addressSet] = useState<HereGeocodeItem>();
    
    const [fetching, fetchingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<HereGeocodeItem[]>();
    const [showList, showListSet] = useState<boolean>();
    const [selectedIndex, selectedIndexSet] = useState<number>(0);

    const [defaultCountryLoaded, defaultCountryLoadedSet] = useState<boolean>(false);
    
    const [onceSelected, onceSelectedSet] = useState<boolean>(false)

    useEffect(() => {
        if (address) {
            addressTextSet(address.address?.label);
            onceSelectedSet(true)
        }
        else if(onceSelected) {
            if (props.onUnselect) props.onUnselect();
        }
    }, [address])

    const fetch = (address: string) => {
        fetchingSet(true);
        if (window.isNullOrEmpty(address)) {
            modelSet(undefined);
        }
        var q = "address=" + address;
        q += "&country=" + selectedCountry?.alpha3Code;
        if(props.stateId) q += "&stateId=" + props.stateId;
        if (q != "") q = "?" + q;
        hub.Get("/maps/geocode/autocomplete" + q, res => {
            if (res.data) {
                modelSet(res.data);
            }
            fetchingSet(false);
        });
    }

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key == "ArrowDown") {
            e.preventDefault();
            handleArrowDown();
        }
        else if (e.key == "ArrowUp") {
            e.preventDefault();
            handleArrowUp();
        }
        else if (e.key == "Enter") {
            e.preventDefault();
            handleEnter();
        }
    }

    const handleArrowDown = () => {
        if (model && model.length > 0) {
            var s = selectedIndex + 1;
            if (s >= model.length) s = model.length - 1;
            selectedIndexSet(s);
        }
    }

    const handleArrowUp = () => {
        var s = selectedIndex - 1;
        if (s < 0) s = 0;
        selectedIndexSet(s);
    }

    const handleEnter = () => {
        if (model && model.length > 0) {
            selectAddress(selectedIndex);
        }
        else {
            console.log("do another thing");
        }
    }

    const selectAddress = (index: number) => {
        if (model) {
            var item = model[index];
            addressSet(item);
            selectedIndexSet(0);
            modelSet([]);
            if (props.onSelect && item.id) {
                hub.LookUpAddress(item.id, item => {
                    if(item) props.onSelect!(item);
                })
            }
        }
    }

    useEffect(() => {

        if (props.selectedAddressId) {
            fetchingSet(true);
            hub.LookUpAddress(props.selectedAddressId, d=> {
                addressSet(d);
                fetchingSet(false);
            })

        }

    }, [props.selectedAddressId])

    useEffect(() => {
        if (props.selectedAddress) {
            addressSet(props.selectedAddress)
            selectedCountrySet(getCountry(props.selectedAddress.address?.countryId))
        }
    }, [props.selectedAddress])
    

    useEffect(() => {
        if (props.reset) {
            addressSet(undefined);
            addressTextSet("");
            $("#" + props.id).val("");
        }
    }, [props.reset])



    const className = () => {
        //d-flex align-items-center
        var cls = "form-control form-inputs";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        return cls;
    };


    const inputGroupClassName = () => {
        //"input-group input-group-sm has-validation"
        var cls = "input-group";
        if (props.small) cls += " input-group-sm";
        if (props.solid) cls += " input-group-solid";
        return cls;
    }

    
    
    useEffect(() => {
        if (countries) {
            window.makeCountriesDropDown(`${props.id}-drp-countries`, 
                    countries, props.parent, 
                    `${props.id}-div-countries`, false, 
                    data => { 
                        selectedCountrySet(data);
                        if (props.onCountrySelect) props.onCountrySelect(data);
                        addressTextSet("");
                        addressSet(undefined);
                        $("#" + props.id).focus();
                    });
        }
    }, [countries])

    useEffect(() => {
        if (selectedCountry) {
            window.updateSelect2(`${props.id}-drp-countries`, selectedCountry.id);
            modelSet(undefined);
            defaultCountryLoadedSet(true);
        }
    }, [selectedCountry])

    useEffect(() => {
        addressTextSet(props.value);
    }, [props.value])

    useEffect(() => {
        if (props.addressId && countries && !defaultCountryLoaded) {
            hub.LookUpAddress(props.addressId, item => {
                if (item) {
                    if (props.onSelect) props.onSelect(item);
                    addressSet(item);
                    addressTextSet(item.address?.label);
                    countries.forEach(c => {
                        if (c.id == item.address?.countryId) {
                            selectedCountrySet(c);
                        }
                    });
                    defaultCountryLoadedSet(true);
                }
            })
        }
        else if (props.defaultCountry && !defaultCountryLoaded) {
            selectedCountrySet(props.defaultCountry);
            defaultCountryLoadedSet(true);
        }
        else if (props.country) {
            selectedCountrySet(props.country);
        }
        else if (!defaultCountryLoaded) {
            selectedCountrySet(getUSA());
        }
    }, [props.addressId, props.defaultCountry, props.country, countries])

    useEffect(() => {
        if (props.countryId) {
            countries?.forEach(c=> {
                if(c.id == props.countryId) selectedCountrySet(c);
            })
        }
    }, [props.countryId])

    


    return (
        <>
         {
            props.raw ? 
            <div className="address-field-container"
                style={{
                    position: props.absolutePosition ? "absolute" : "relative",
                    top: props.top,
                    left: props.left,
                    width: props.width,
                    zIndex: props.zIndex
                }}>
                <input id={props.id} type="text" placeholder={props.placeholder} 
                    name={props.name} autoComplete="one-time-code" value={addressText}
                    className={className()} 
                    onKeyDown={keyDown}
                    onFocus={e=> showListSet(true)}
                    tabIndex={props.tabIndex}
                    onBlur={e=> {
                        setTimeout(() => {
                            showListSet(false)
                        }, 250);
                    }}
                    onChange={e => {
                        if (props.onTextChanged) props.onTextChanged(e.target.value);
                        addressTextSet(e.target.value);
                        if (searchTimout) clearTimeout(searchTimout);
                        searchTimout = setTimeout(() => fetch(e.target.value), 500);
                    }}/>
                    {
                        props.raw && showList && model && model.length > 0 &&
                        <div className="address-field-autocomplete">
                            {
                                model.map((item, key) => 
                                    <div key={key} className={"address-field-autocomplete-item" + (selectedIndex == key ? " selected" : "")}
                                        onClick={e=> {
                                            e.preventDefault();
                                            selectAddress(key);
                                        }}>
                                        {item.address?.label}
                                    </div>
                                )
                            }
                        </div>
                    }
            </div>
            :
            <div className={"row align-items-center" + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 5))}>
                <input id={props.id + "-val"} type="hidden" name={props.name + "Id"} value={address?.id} />
                {
                    props.label &&
                    <label className={`col col-12 ${(props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2"))} ${fontClass()}`} htmlFor={props.id}>
                        {props.label}
                        {
                            props.tooltip &&
                            <i className="fa-solid fa-circle-question ms-2 form-tooltips"  
                                id={(props.id) + "-tooltip"}
                                data-bs-placement="right"
                                data-bs-custom-class="tooltip-dark"
                                title={props.tooltip}></i>
                        }
                        {
                            props.labelUp && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                        }
                    </label>
                }

                <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>

                    <div className="address-field-container"
                        style={{
                            position: props.absolutePosition ? "absolute" : "relative",
                            top: props.top,
                            left: props.left,
                            width: props.width,
                            zIndex: props.zIndex
                        }}>


                            <div className={inputGroupClassName()}>

                                <div id={`${props.id}-div-countries`} className="input-group-text"
                                        style={{
                                            position:"relative"
                                        }}>
                                            
                                            {
                                                selectedCountry &&
                                                <>
                                                    <img src={selectedCountry.flagSquare} className="me-2" 
                                                        style={{
                                                            borderRadius: "50%",
                                                            height: 15
                                                        }}/>
                                                    <div className="fs-8 me-2" style={{
                                                        height: 15
                                                    }}>
                                                        {selectedCountry.alpha3Code}
                                                    </div>
                                                    {
                                                        true &&
                                                        <Icon icon="chevron-down" className="fs-8" />
                                                    }
                                                    
                                                </>
                                            }

                                    <div style={{
                                        position:"absolute",
                                        bottom:0,
                                        left:0,
                                        width:"100%"
                                    }}>
                                        <select id={`${props.id}-drp-countries`} className="d-none">
                                        </select>
                                    </div>
                                </div>

                                <input id={props.id} type="text" placeholder={props.placeholder} ref={e=> addressInput = e}
                                    name={props.name} autoComplete="one-time-code" value={addressText}
                                    className={className()}  tabIndex={props.tabIndex}
                                    onKeyDown={keyDown}
                                    onFocus={e=> showListSet(true)}
                                    onBlur={e=> {
                                        setTimeout(() => {
                                            showListSet(false)
                                        }, 250);
                                    }}
                                    onChange={e => {
                                        if (props.onTextChanged) props.onTextChanged(e.target.value);
                                        addressTextSet(e.target.value);
                                        addressSet(undefined);
                                        if (searchTimout) clearTimeout(searchTimout);
                                        searchTimout = setTimeout(() => fetch(e.target.value), 500);
                                    }}/>

                            </div>


                            


                        {
                            showList && model && model.length > 0 &&
                            <div className="address-field-autocomplete">
                                {
                                    model.map((item, key) => 
                                        <div key={key} className={"address-field-autocomplete-item" + (selectedIndex == key ? " selected" : "")}
                                            onClick={e=> {
                                                e.preventDefault();
                                                selectAddress(key);
                                            }}>
                                            {item.address?.label}
                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            fetching &&
                            <div style={{
                                position:"absolute",
                                right:10,
                                top: 10,
                                zIndex: 5
                            }}>
                                <Icon icon="spinner-third" spin size={18} />
                            </div>
                        }

                        {
                            props.verify && 
                            <>
                                {
                                    !fetching && !window.isNullOrEmpty(address?.id) &&
                                    <div style={{
                                        position:"absolute",
                                        right:10,
                                        top: 10,
                                        zIndex: 5
                                    }}>
                                        <Icon icon="check-circle" size={18} className="text-success" type="solid" />
                                    </div>
                                }
                                 {
                                    !fetching && window.isNullOrEmpty(address?.id) && addressText && addressText.length > 0 &&
                                    <div style={{
                                        position:"absolute",
                                        right:10,
                                        top: 10,
                                        zIndex: 5
                                    }}>
                                        <Icon icon="triangle-exclamation" size={18} className="text-warning" type="solid" />
                                    </div>
                                }
                            </>
                        }

                    </div>
                
                    
                    
                </div> 

                {
                    props.block && props.label && !props.labelUp &&
                    <div className="col col-12 col-md-3"></div>
                }

                {   
                    !props.labelUp &&
                    <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                        <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                    </div>
                }
                
            </div>
         }
        </>
        
    )

}