import React from 'react';
import { Routes, Route } from "react-router-dom";
import AgentsMap from '../views/AgentsMap';
import AgentsMonitoring from '../views/AgentsMonitoring';
import DashboardView from '../views/DashboardView';
import ExtensionsBoardActivity from '../views/ExtensionsBoardActivity';
import DefaultRouteView from '../../../DefaultRouteView';



function DashboardRoutes() {
  return (
    [
      <Route key={0} path="/" element={<DefaultRouteView />} />,
      <Route key={0} path="/dashboard" element={<DashboardView />} />,
      <Route key={1} path="/dashboard/extensions/board" element={<ExtensionsBoardActivity />} />,
      <Route key={2} path="/dashboard/agents/monitoring" element={<AgentsMonitoring />} />,
      <Route key={3} path="/dashboard/agents/map" element={<AgentsMap />} />,
    ]
  );
}

export default DashboardRoutes;
