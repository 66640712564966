import React from "react";

interface DivProps {
    id?: string
    title?: string
    visible?: boolean
    className?: string
    height?: number
    width?: number
    maxWidth?: number
    limitedText?: boolean
    children?: any
}

function Div(props: DivProps) {

    return (
        props.visible == undefined || props.visible ? 
        <div id={props.id} title={props.title}
            className={props.className + (props.limitedText ? " limited-text" : "")} 
            style={{
                width: props.width,
                height: props.height,
                maxWidth: props.maxWidth
            }}>
            {props.children}
        </div>
        :
        <></>
    )
}

export default Div;

