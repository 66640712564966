import { useState } from "react"


interface IProps {
    text: string
    size: number
}

export default function DescriptionContainer(props: IProps) {

    const [more, moreSet] = useState<boolean>(false);

    return (
        <>
            {
                props.text.length > props.size ? 
                <>
                    {
                        more ? 
                        <>
                            {props.text}
                            <a href="#" className="ms-1" onClick={e=> {
                                e.preventDefault();
                                moreSet(false)
                            }}>less</a>
                        </>
                        :
                        <>
                            {props.text.substring(0, props.size - 1) + "..."}
                            <a href="#" className="ms-1" onClick={e=> {
                                e.preventDefault();
                                moreSet(true);
                            }}>more</a>
                        </>
                    }
                </>
                :
                <>
                    {props.text}
                </>
            }
        </>
    )
}