import React from 'react';
import { Routes, Route } from "react-router-dom";
import JobsList from '../modules/crm/jobs/views/JobsList';
import JobsDashboard from '../modules/crm/jobs/views/JobsDashboard';
import JobDetails from '../modules/crm/jobs/views/JobDetails';
import JobTypeGroupsList from '../modules/crm/base/views/JobTypeGroupsList';
import JobTypesList from '../modules/crm/base/views/JobTypesList';
import JobStatuses from '../modules/crm/base/views/JobStatuses';
import JobSourcesList from '../modules/crm/base/views/JobSourcesList';
import MetrosList from '../modules/crm/base/views/MetrosList';
import FlagsList from '../modules/crm/base/views/FlagsList';
import TagsList from '../modules/crm/base/views/TagsList';
import TaxesList from '../modules/crm/base/views/TaxesList';
import JobsReport from '../modules/crm/jobs/views/JobsReport';
import ClosedJobsList from '../modules/crm/jobs/views/ClosedJobsList';
import CanceledJobsList from '../modules/crm/jobs/views/CanceledJobsList';
import RatesList from '../modules/crm/rates/views/RatesList';
import AllJobsList from '../modules/crm/jobs/views/AllJobsList';


function CRMRoutes() {
  let k = 0;
  return (
    [
      <Route key={k++} path="/boards/jobs" element={<JobsDashboard />} />,
      <Route key={k++} path="/crm/jobs/archived" element={<JobsList type='closed' />} />,
      <Route key={k++} path="/crm/jobs/all" element={<AllJobsList />} />,
      <Route key={k++} path="/crm/jobs/closed" element={<ClosedJobsList />} />,
      <Route key={k++} path="/crm/jobs/canceled" element={<CanceledJobsList />} />,
      <Route key={k++} path="/crm/jobs/:selectedPage/:selectedId" element={<JobDetails />} />,
      <Route key={k++} path="/crm/jobs/:selectedPage/:selectedId/view/:selectedObjectId" element={<JobDetails />} />,
      <Route key={k++} path="/crm/base/jobtypegroups" element={<JobTypeGroupsList />} />,
      <Route key={k++} path="/crm/base/jobtypes" element={<JobTypesList />} />,
      <Route key={k++} path="/crm/base/jobstatuses" element={<JobStatuses />} />,
      <Route key={k++} path="/crm/base/jobsources" element={<JobSourcesList />} />,
      <Route key={k++} path="/crm/base/metros" element={<MetrosList />} />,
      <Route key={k++} path="/crm/base/flags" element={<FlagsList />} />,
      <Route key={k++} path="/crm/base/tags" element={<TagsList />} />,
      <Route key={k++} path="/crm/base/taxes" element={<TaxesList />} />,
      <Route key={k++} path="/crm/report/jobs" element={<JobsReport />} />,
      <Route key={k++} path="/crm/rates/:cat" element={<RatesList />} />,
    ]
  );
}

export default CRMRoutes;
