import React, { useEffect } from "react"
import Menu from "../../../core/Menu.json";
import MenuArea from './MenuArea';
import MenuItem from './MenuItem';
import MenuChild from './MenuChild';
import { closeMobileMenu, getUser, hasRole, hasRoles, useGlobal, useUnreadChatsCount } from '../../../core/Global';
import { Link, useLocation } from 'react-router-dom';
import { UserTypes } from '../../../core/Enums';
import Icon from "../../base/Icon";
import FlexRow from "../../base/FlexRow";
import AsideUser from "./AsideUser";
import AsideEnviornment from "./AsideEnviornment";


export default function AsideMobileMenuDrawer() {

  const [globalState] = useGlobal();
  const user = getUser();
  const location = useLocation();
  const unreadChats = useUnreadChatsCount();

  const AreaHasRole = (area: any) => {
    for (var i = 0; i < area.items?.length; i++) {
      for (var j = 0; j < area.items[i].items?.length; j++) {
        if (hasRoles(area.items[i].items[j].roles)) return true;
      }
      if (area.items[i].roles) {
        if (hasRoles(area.items[i].roles)) return true;
      }
    }
    return false;
  }

  const MenuHasRole = (item: any) => {
    if (item.items) {
      for (var j = 0; j < item.items?.length; j++) {
        if (hasRoles(item.items[j].roles)) return true;
      }
    }
    if (item.roles) {
      if (hasRoles(item.roles)) return true;
    }
    return false;
  }

  const MenuIsHere = (item: any) => {
    for (var j = 0; j < item.items?.length; j++) {
      if (window.location.pathname != "" && window.location.pathname != "/" && item.items[j].to.startsWith(window.location.pathname)) return true;
    }
    return false;
  }


  useEffect(() => {
    window.KTDrawerCreate();
    window.openMobileMenuDrawer(closeMobileMenu);
    window.dataTableMenu();
  }, [])

  return (

    <div id="kt_drawer_menu" className="mobile-menu-drawer"
      data-kt-drawer="true" data-kt-drawer-name="menu" data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'70%'}" data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_drawer_menu_toggle" data-kt-drawer-close="#kt_drawer_menu_close">

      <FlexRow column className="w-100">

        <AsideUser />
        {
          user?.isSuperAdmin && <AsideEnviornment />
        }


        <div className="menu menu-column menu-title-gray-800 menu-state-bullet-primary menu-mobile-side w-100" id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="false">

          {
            hasRole("HasCRM") &&
            <div className={"menu-item"}>
              <Link to="/boards/jobs" className="menu-link" onClick={e => window.closeMobileMenuDrawer()}>
                <span className="menu-icon">
                  <Icon icon="list-check" />
                </span>
                <span className="menu-title">Jobs Board</span>
              </Link>
            </div>
          }

          {
            hasRole("HasCRM") &&
            <div className={"menu-item" + (window.location.pathname.toLocaleLowerCase().startsWith("/chat") ? " here" : "")}>
              <Link to="/chat" className="menu-link" onClick={e => window.closeMobileMenuDrawer()}>
                <span className="menu-icon">
                  <Icon icon="comment-lines" />
                </span>
                <span className="menu-title">Chat</span>
                {
                  unreadChats > 0 &&
                  <span className="menu-badge">
                    <span className="badge badge-danger badge-circle">
                      {unreadChats}
                    </span>
                  </span>
                }

              </Link>
            </div>
          }




          {
            Menu.map((area, areaKey) =>
              <MenuArea key={areaKey} title={area.title} visible={AreaHasRole(area)}>
                {
                  area.items.map((item, itemKey) =>
                    <MenuItem key={itemKey} title={item.title} icon={item.icon as any} visible={MenuHasRole(item)} here={MenuIsHere(item)} link={item.to}>
                      {
                        item.items &&
                        <>
                          {item.items?.map((link, linkKey) => <MenuChild key={linkKey} title={link.title} to={link.to} visible={hasRoles(link.roles)} />)}
                        </>
                      }
                    </MenuItem>
                  )
                }
              </MenuArea>
            )
          }
        </div>

      </FlexRow>

    </div>

  );
}

