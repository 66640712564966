import { useEffect, useState } from "react";
import Job from "../../models/Job";
import Modal from "../../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import TextArea from "../../../../../components/form/TextArea";
import JobStatus from "../../../base/models/JobStatus";


interface IProps {
    job?: Job
    status?: JobStatus
    onClose?: () => void
    onCancel?: () => void
    onUpdate?: () => void
}
export default function JobUpdateStatusNoteModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();
    useEffect(() => selectedStatusSet(props.status), [props.status]);


    return (
        <>

            <Modal id="modal-update-status-note"
                title="Status"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                buttonText="Update Status"
                buttonColor="primary"
                visible open staticBackdrop
                onOpen={() => {
                    $("#txt-status-note-area").focus();
                }}
                onClose={props.onClose}
                onCancel={() => {
                    if (props.onCancel) props.onCancel();
                    window.closeModal("modal-update-status-note");
                }}
                onSubmit={(body, callback) => {

                    window.hideFormErrors();

                    if (body.note == null || body.note == "") {
                        window.showFormError("note", "Please write a note to update the status!", true, "modal-update-status-note");
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "jobstatus", {
                        ...body,
                        id: job?.id,
                        status: selectedStatus?.id
                    }, res => {
                        if (props.onUpdate) props.onUpdate();
                        if (callback) callback(res);
                    });

                }}>
                <div>

                    <div className="mb-5">
                        <div className="btn btn-bg-primary w-100 text-start btn-color-white btn-sm pe-0 ps-3"
                            style={{
                                backgroundColor: selectedStatus?.color
                            }}>
                            {selectedStatus?.name}
                            {/* <span className="svg-icon svg-icon-5 rotate-180 float-end" style={{marginTop: 2}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"/>
                                    </svg>
                                </span> */}
                        </div>
                    </div>

                    <TextArea id="txt-status-note-area" label="Note *" block labelUp name="note" rows={5} placeholder="Please write a note..." />

                </div>
            </Modal>

        </>
    )

}