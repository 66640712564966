import { API_ROUTES } from "../../core/Constants";
import { useHub } from "../../core/Global";
import User from "../../modules/accounts/models/User";
import Modal from "../base/Modal";
import { toastSuccess } from "../base/Toast";
import TextBox from "../form/TextBox";

interface IProps {
    user?: User
}
export default function ModalCreateManager() {

    const hub = useHub();

    return (
        <Modal id="modal-create-manager"
            size="Large"
            title="Create New Manager"
            buttonText="Create Manager"
            onSubmit={(body, callback) => {

                hub.Put(API_ROUTES.Users, body, res => {
                    if (callback) callback(res);
                    if (res.success) {
                        //toastSuccess("'" + props.user?.fullName + "' Password Changed Successfully!");
                    }
                });

            }}>
                <div>
                    <TextBox label="First Name" name="firstName" />
                    <TextBox label="Last Name" name="lastName" />
                    <TextBox label="Email Address" name="email" />
                    <TextBox label="Password" name="password" password />
                </div>

        </Modal>
    )
}