import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import { getUser, setPage, useGlobal } from '../../../core/Global';
import ProfileOverview from './ProfileOverview';
import Section from "../../../components/base/Section";
import DataItem from "../../../models/DataItem";
import Icon from "../../../components/base/Icon";
import ProfileDetailsForm from "../components/ProfileDetailsForm";
import ProfileCompanyForm from "../components/ProfileCompanyForm";
import ProfileSignInMethods from "./ProfileSignInMethods";
import ProfileNotifications from "./ProfileNotifications";
import { UserTypes } from "../../../core/Enums";
import Timezone from "../components/preferences/Timezone";
import ProfileSubscription from "../components/billing/ProfileSubscription";
import ProfileSubsPayments from "../components/billing/ProfileSubsPayments";
import ProfileWallet from "../components/billing/ProfileWallet";
import ProfileInvoices from "../components/billing/ProfileInvoices";
import ProfilePreferences from "../components/preferences/ProfilePreferences";


function Profile() {

    const [globalState] = useGlobal();
    const { selectedPage } = useParams(); 
    const user = getUser();

    const [Menu, MenuSet] = useState<DataItem[]>([])

      useEffect(() => {
        if (user) {
          var menu = Menu ?? [];
          menu.push({
            id: "overview",
            name: "Overview",
            icon: "table-columns"
          });
          menu.push({
              id: "edit",
              name: "Edit Profile",
              icon: "user"
          });
          
          if (user.userType == UserTypes.Customer) {
            menu.push({
                id: "company",
                name: "Company",
                icon: "briefcase"
            })
          }

          menu.push({
              id: "security",
              name: "Security & Login",
              icon: "lock"
          });

          

          if (user.userType == UserTypes.Customer) {
            menu.push({
                id: "subscription",
                name: "Subscription & Payments",
                icon: "dollar-sign"
            })
            menu.push({
                id: "wallet",
                name: "Wallet",
                icon: "wallet"
            })
            menu.push({
                id: "invoices",
                name: "Invoices",
                icon: "file-invoice"
            })
          }

          menu.push({
              id: "preferences",
              name: "Preferences",
              icon: "wrench"
          })

          if (user.userType == UserTypes.Customer) { 
            menu.push({
                id: "notificationrules",
                name: "Notification Rules",
                icon: "bell-exclamation"
            })
          }

          menu.push({
              id: "notifications",
              name: "Notifications",
              icon: "bell"
          })

          


          MenuSet(menu);

        }
      }, [user])

  useEffect(() => {
    var p = Menu.filter(f=>f.id == selectedPage?.toLowerCase())[0];
    setPage(p.name ?? "overview", "My Profile");
  }, [selectedPage, Menu])
  return (
    <Layout wide>

      <Section fluid>
        <div className="row">
          <div className="col col-md-4 col-xxl-3 col-xxxl-2">
              <div className="card card-flush mb-0 me-4">
                  <div className="card-body">
                      <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">

                          {
                              Menu.map((m, k) => 
                                <div key={k} className="menu-item mb-3">
                                    <Link to={`/profile/${m.id}`} className={"menu-link" + (selectedPage == m.id ? " active" : "")}>
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2 me-3">
                                                <Icon icon={m.icon} type="solid" className={(selectedPage == m.id ? "" : " text-gray-700")} />
                                            </span>
                                        </span>
                                        <span className={"menu-title fw-bold fs-6" + (selectedPage == m.id ? "" : " text-gray-700")}>
                                            {m.name}
                                        </span>
                                    </Link>
                                </div>
                              )
                          }

                      </div>
                  </div>
              </div>
              
            </div>
            <div className="col col-md-8 col-xxl-9 col-xxxl-10">
                {
                  (!selectedPage || selectedPage == "" || selectedPage?.toLowerCase() == "overview") && <ProfileOverview />
                }

                {
                    selectedPage?.toLowerCase() == "edit" && <ProfileDetailsForm />
                }

                {
                    selectedPage?.toLowerCase() == "company" && <ProfileCompanyForm />
                }

                {
                    selectedPage?.toLowerCase() == "security" && <ProfileSignInMethods />
                }
                
                {
                    user?.userType == UserTypes.Customer && selectedPage?.toLowerCase() == "subscription" && <ProfileSubsPayments />
                }

                {
                    user?.userType == UserTypes.Customer && selectedPage?.toLowerCase() == "wallet" && <ProfileWallet />
                }

                {
                    user?.userType == UserTypes.Customer && selectedPage?.toLowerCase() == "invoices" && <ProfileInvoices />
                }

                {
                    selectedPage?.toLowerCase() == "preferences" && <ProfilePreferences />
                }

                {
                    selectedPage?.toLowerCase() == "notifications" && <ProfileNotifications />
                }

            </div>
        </div>
      </Section>
        

    </Layout>
  );
}

export default Profile;
