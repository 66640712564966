import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Modal from "../../../../../components/base/Modal";
import DropDown from "../../../../../components/form/DropDown";
import Job from "../../models/Job";
import { AmountType, PaymentTypes } from "../../../../../core/Enums";
import TextBox from "../../../../../components/form/TextBox";
import Icon from "../../../../../components/base/Icon";
import ToggleSwitch from "../../../../../components/form/ToggleSwitch";
import TextArea from "../../../../../components/form/TextArea";
import { getUser, hasRole, useHub } from "../../../../../core/Global";
import Indicator from "../../../../../components/base/Indicator";
import { API_ROUTES } from "../../../../../core/Constants";
import JobClosingPaymentModel from "../../models/JobClosingPaymentModel";
import { toastSuccess } from "../../../../../components/base/Toast";
import { CompactTax } from "../../../base/models/TaxItem";
import DatePicker from "../../../../../components/form/DatePicker";


interface IProps {
    job?: Job
    error?: string
    editMode?: boolean
    onClose?: () => void
    onUpdate?: () => void
    onDiscard?: () => void
}

interface IPayType {
    type: PaymentTypes
    value: string
    text: string
}

interface IPayItem {
    amount?: string
    type?: PaymentTypes
    checkNumber?: string
    approval?: string
    cancelFee?: boolean
}

export default function JobClosePaymentModal(props: IProps) {

    const hub = useHub();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    const [bodyChanged, bodyChangedSet] = useState<boolean>();
    const [loading, loadingSet] = useState<boolean>(true);
    const [model, modelSet] = useState<JobClosingPaymentModel>();
    const [totalAmount, totalAmountSet] = useState<number>(0);

    const [fixedRates, fixedRatesSet] = useState<boolean>(false);

    const [customTechRate, customTechRateSet] = useState<boolean>(false);
    const [customTechFee, customTechFeeSet] = useState<boolean>(false);
    const [customSourceRate, customSourceRateSet] = useState<boolean>(false);

    const [partsExceedErr, partsExceedErrSet] = useState<boolean>(false);
    const [ratesExceedErr, ratesExceedErrSet] = useState<boolean>(false);

    const [customCloseDate, customCloseDateSet] = useState<boolean>(false);

    const [payItems, payItemsSet] = useState<IPayItem[]>([{
        type: undefined,
        amount: undefined
    }]);

    const [taxes, taxesSet] = useState<CompactTax[]>([]);
    const [taxItems, taxItemsSet] = useState<CompactTax[]>([{}]);

    const fetchTaxes = () => {
        hub.Get(API_ROUTES.Taxes + "all", res => {
            if (res.success) taxesSet(res.data);
        })
    }


    useEffect(() => {

        if (props.job?.id) {
            hub.Post(API_ROUTES.Jobs + "closingpaymentmodel",
                {
                    id: props.job.id,
                    total: totalAmount ?? 0
                },
                res => {
                    if (res.success) {
                        modelSet(res.data);
                        loadingSet(false);
                    }
                })
        }

    }, [props.job, totalAmount])

    useEffect(() => {
        if (props.job?.id) {

            if (props.job.payment?.paymentItems && props.job.payment?.paymentItems.length > 0) {
                var arr: IPayItem[] = [];
                props.job.payment.paymentItems.forEach(p => {
                    arr.push({
                        amount: p.amount?.toString(),
                        checkNumber: p.checkNumber,
                        approval: p.approval,
                        type: p.paymentType,
                        cancelFee: p.cancelFee
                    })
                });

                payItemsSet(p => [...arr]);
                if (props.job.payment.taxItems && props.job.payment.taxItems.length > 0) {
                    taxItemsSet(props.job.payment.taxItems);
                }

                fixedRatesSet(props.job.payment.techRateIsFixed ?? false);
            }

            customTechRateSet(props.job.payment?.techRateIsCustom ?? false)
            customSourceRateSet(props.job.payment?.sourceRateIsCustom ?? false);

            fetchTaxes();

        }
    }, [props.job])

    const [paymentTypes] = useState<IPayType[]>([
        {
            type: PaymentTypes.Cash,
            value: PaymentTypes.Cash.toString(),
            text: "Cash"
        },
        {
            type: PaymentTypes.Check,
            value: PaymentTypes.Check.toString(),
            text: "Check"
        },
        {
            type: PaymentTypes.CCCharge,
            value: PaymentTypes.CCCharge.toString(),
            text: "CC Charge"
        },
        {
            type: PaymentTypes.CCManual,
            value: PaymentTypes.CCManual.toString(),
            text: "CC Manual"
        }
    ])



    useEffect(() => {
        var total = 0;
        var tax = 0;
        payItems.forEach(p => {
            var a = (p.amount ?? "0").replaceAll(",", "")
            total += Number.parseFloat(a);
        });

        taxItems.forEach(t => {
            if (!t.included && t.amount) {
                if (t.amountType == AmountType.Percent) {
                    var a = (total * t.amount) / 100;
                    tax += a;
                }
                else {
                    tax += t.amount
                }
            }
        });

        totalAmountSet(total + tax);

    }, [payItems, taxItems])


    const toNumberAmount = (n?: string) => {
        if (!n) return 0;
        n = n.replaceAll(",", "");
        return Number.parseFloat(n);
    }

    const TotalAmount = () => (
        <div>
            <div className="fs-1 fw-bolder">
                {moneyFormatter.format(totalAmount)}
            </div>
            <div className="fs-7 fw-bold">
                Total Amount
            </div>
        </div>
    )



    return (
        <>


            <Modal id="modal-closejob-payment"
                title={props.editMode ? "Edit Payment" : "Close Job"}
                subtitle={"Job ID: #" + props.job?.jobId + " | " + props.job?.fullName}
                open visible staticBackdrop
                size="Large"
                onClose={props.onClose}
                onCancel={() => {
                    window.openModal("modal-discard-close");
                }}
                buttonText={props.editMode ? "Update Payment" : "Close Job"}
                buttonColor="primary"
                footerComponent={TotalAmount}
                onSubmit={(body, callback) => {

                    window.hideFormErrors();
                    partsExceedErrSet(false);
                    ratesExceedErrSet(false);
                    var valid = true;
                    var focus = true;

                    for (var i = 0; i < payItems.length; i++) {
                        var p = payItems[i];
                        if (!p.type) {
                            window.formError("paymentItems", "Please choose the payment type!");
                            valid = false;
                            break;
                        }
                        if (toNumberAmount(p.amount) == 0) {
                            window.formError("paymentItems", "Please write the payment amount!");
                            valid = false;
                            if (focus) {
                                $("#txt-paymentamount" + i).focus();
                                focus = false;
                            }
                            break;
                        }
                    }

                    if (customTechRate && (!body.techCustomRate || isNaN(Number.parseInt(body.techCustomRate)) || Number.parseInt(body.techCustomRate) < 1)) {
                        window.formError("techCustomRate", "", "modal-closejob-payment", focus, false, true);
                        valid = false;
                        focus = false;
                    }

                    // if (customSourceRate && (!body.sourceCustomRate || isNaN(Number.parseInt(body.sourceCustomRate)) || Number.parseInt(body.sourceCustomRate) < 1)) {
                    //     window.formError("sourceCustomRate", "", "modal-closejob-payment", focus, false, true);
                    //     valid = false;
                    //     focus = false;
                    // }

                    if (!props.editMode && model?.noteRequired && (!body.note || body.note.length < 2)) {
                        window.formError("note", "Please write the close status note!", "modal-closejob-payment", focus, false, false);
                        valid = false;
                        focus = false;
                    }

                    if (props.editMode && (!body.updateNote || body.updateNote.length < 2)) {
                        window.formError("updateNote", "Please write the update note!", "modal-closejob-payment", focus, false, false);
                        valid = false;
                        focus = false;
                    }

                    var tp = toNumberAmount(body.techPart);
                    var cp = toNumberAmount(body.companyParts);

                    if (tp + cp > totalAmount) {
                        partsExceedErrSet(true);
                        valid = false;
                    }

                    var techRate = model?.techRate ?? 0;
                    var sourceRate = Number.parseInt(model?.sourceRate ?? "0") ?? 0;

                    if (customTechRate) techRate = Number.parseInt(body.techCustomRate) ?? 0;
                    if (customSourceRate) sourceRate = Number.parseInt(body.sourceCustomRate) ?? 0;

                    if (techRate + sourceRate > 100) {
                        ratesExceedErrSet(true);
                        valid = false;
                    }

                    if (!valid) {
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "closejob", {
                        ...body,
                        id: props.job?.id,
                        payCount: payItems.length,
                        taxCount: taxItems.length,
                        editMode: props.editMode,
                        customCloseDate: customCloseDate
                    }, res => {
                        if (res.success) {
                            if (props.editMode) toastSuccess("Job #" + props.job?.jobId + " payment has been updated successfully!");
                            else toastSuccess("Job #" + props.job?.jobId + " has been closed successfully!");
                            if (props.onUpdate) props.onUpdate();
                        }
                        if (callback) callback(res);
                    })


                }}
                secondActionText="Close Without Payments"
                secondActionColor="secondary"
                onSecondAction={() => {
                    window.openModal("modal-confirm-closezero")
                }}
            >
                {
                    loading ?
                        <div className="my-10">
                            <Indicator text="Please wait..." />
                        </div>
                        :
                        <div>
                            <div className="fw-bold fs-7 mb-2">
                                Payment Type & Amount*
                                <span id="paymentItems-err" className="small text-danger mx-3 form-error">
                                </span>
                            </div>
                            <div className="mb-3">
                                {
                                    payItems.map((p, k) =>
                                        <FlexRow key={k} className="mb-2">
                                            <div className="me-2">
                                                {
                                                    payItems.length == 1 ?
                                                        <span>
                                                            <Icon icon="circle-minus" className="text-secondary" type="solid" size={18} />
                                                        </span>
                                                        :
                                                        <span className="cursor-pointer" title="Remove Payment Item"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                var arr = payItems;
                                                                arr.splice(k, 1);
                                                                payItemsSet(t => [...arr]);
                                                            }}>
                                                            <Icon icon="circle-minus" className="text-danger" type="solid" size={18} />
                                                        </span>
                                                }
                                            </div>
                                            <div className="w-200px me-2">
                                                <DropDown id={"drp-paymenttype" + k} name={"paymentType" + k} parentModal="modal-closejob-payment"
                                                    source={paymentTypes} dataText="text" dataValue="value" block marginBottom={0} defaultValue={p.type?.toString()}
                                                    onSelectItem={item => {
                                                        var arr = payItems;
                                                        arr[k].type = item.type;
                                                        payItemsSet(t => [...arr]);
                                                    }} />
                                            </div>
                                            <div className="w-150px me-2">
                                                <TextBox id={"txt-paymentamount" + k} name={"amount" + k} block money
                                                    placeholder="Amount" marginBottom={0} icon="dollar-sign" value={p.amount}
                                                    onTextChanges={t => {
                                                        var arr = payItems;
                                                        arr[k].amount = t;
                                                        payItemsSet(t => [...arr]);
                                                    }} />
                                            </div>
                                            {
                                                p.type == PaymentTypes.Check &&
                                                <div className="w-150px me-2">
                                                    <TextBox id={"txt-checknumber" + k} name={"checkNumber" + k} block
                                                        placeholder="Check Number" marginBottom={0} value={p.checkNumber}
                                                        onChange={e => {
                                                            var arr = payItems;
                                                            arr[k].checkNumber = e.target.value;
                                                            payItemsSet(t => [...arr]);
                                                        }} />
                                                </div>
                                            }
                                            {
                                                (p.type == PaymentTypes.CCCharge || p.type == PaymentTypes.CCManual) &&
                                                <div className="w-150px me-2">
                                                    <TextBox id={"txt-approval" + k} name={"approval" + k} block
                                                        placeholder="Approval" marginBottom={0} value={p.approval}
                                                        onChange={e => {
                                                            var arr = payItems;
                                                            arr[k].approval = e.target.value;
                                                            payItemsSet(t => [...arr]);
                                                        }} />
                                                </div>
                                            }
                                            {
                                                p.type == PaymentTypes.CCCharge &&
                                                <div className="w-150px me-2">
                                                    <ToggleSwitch id={"toggle-cancelfee" + k} name={"cancelFee" + k} raw label="Cancel Fee" className="fs-7" defaultChecked={p.cancelFee} small />
                                                </div>
                                            }

                                        </FlexRow>
                                    )
                                }
                                <div className="ms-2">
                                    <a href="#" className="fs-8"
                                        onClick={e => {
                                            e.preventDefault();
                                            var arr = payItems;
                                            var last = arr[arr.length - 1];
                                            arr.push({});
                                            payItemsSet(t => [...arr]);
                                        }}>
                                        <Icon icon="plus" className="me-1 fs-8" />
                                        Add Payment
                                    </a>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextBox name="techPart" block label="Tech Parts" labelUp money icon="dollar-sign" value={props.job?.payment?.techParts?.toString()} marginBottom={0} />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextBox name="companyParts" block label="Company Parts" labelUp money icon="dollar-sign" value={props.job?.payment?.companyParts?.toString()} marginBottom={0} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <TextBox name="invoiceNumber" block label="External Invoice Number" labelUp marginBottom={3} value={props.job?.payment?.externalInvoiceNumber} />
                                </div>
                            </div>
                            {
                                partsExceedErr &&
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-danger small">
                                            Parts amount can't be greater than total amount!
                                        </span>
                                    </div>
                                </div>
                            }



                            {
                                hasRole("CloseJobsCustomRates") ?
                                    <>
                                        <hr />
                                        <div className="row">

                                            <FlexRow className="mb-5">
                                                <div className="fw-bolder fs-5">Rates</div>
                                                <div className="ms-auto">
                                                    <ToggleSwitch raw label="Fixed Rates" small onChange={e => fixedRatesSet(e)} checked={fixedRates} />
                                                </div>
                                            </FlexRow>

                                            {
                                                fixedRates && <input type="hidden" name="fixedRates" value="True" />
                                            }

                                            <div className="col col-12 col-md-4">
                                                <div className="bg-light-primary p-5 mb-3">
                                                    <div className="fw-bolder fs-7 mb-2">Technician Rate</div>
                                                    <FlexRow className="mb-2">
                                                        <div className="me-2"><Icon icon="user-helmet-safety" size={15} /></div>
                                                        <div className="fw-bold fs-7">{model?.techName}</div>
                                                    </FlexRow>
                                                    {
                                                        fixedRates ?
                                                            <>
                                                                <div className="mt-3 mw-200px">
                                                                    <TextBox name="techFixedRate" block placeholder="Tech Rate" small
                                                                        value={props.job?.payment?.techFixRate?.toString()}
                                                                        money autoFocus marginBottom={0} icon="dollar-sign" />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <FlexRow className={"mb-3" + (customTechRate ? " text-decoration-line-through text-danger" : "")}>
                                                                    <div className="fw-bolder me-2">
                                                                        Rate:
                                                                    </div>
                                                                    <div className="me-1">
                                                                        {
                                                                            model?.techRateIsFixed ?
                                                                                <span>{moneyFormatter.format(model?.techRate ?? 0)}</span>
                                                                                :
                                                                                <span>{model?.techRate}%</span>
                                                                        }

                                                                    </div>
                                                                    <span className="fs-9 mt-1">
                                                                        <Icon icon="circle-question" type="solid" color="#666" title={model?.techRateName} />
                                                                    </span>
                                                                </FlexRow>
                                                                <div className="mb-2">
                                                                    <ToggleSwitch label="Custom Rate" raw className="fs-7" onChange={e => customTechRateSet(e)} checked={customTechRate} small />
                                                                </div>
                                                                {
                                                                    customTechRate ?
                                                                        <div className="mt-3 mw-200px">
                                                                            <input type="hidden" name="techRateIsCustom" value="True" />
                                                                            <TextBox name="techCustomRate" block placeholder="Custom Tech Rate" small
                                                                                value={props.job?.payment?.techRateIsCustom ? props.job.payment.techRate?.toString() : undefined}
                                                                                numeric autoFocus marginBottom={0} icon="percent" maxLength={2} />
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <input type="hidden" name="techRate" value={model?.techRate} />
                                                                            <input type="hidden" name="techRateId" value={model?.techRateId} />
                                                                            <input type="hidden" name="techRateName" value={model?.techRateName} />
                                                                            {
                                                                                model?.techRateIsFixed &&
                                                                                <input type="hidden" name="techRateIsFixed" value={"True"} />
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                    }

                                                </div>
                                            </div>

                                            <div className="col col-12 col-md-4">
                                                <div className="bg-light-primary p-5 mb-3">
                                                    <div className="fw-bolder fs-7 mb-2">Source Rate</div>
                                                    <FlexRow className="mb-2">
                                                        <div className="me-2"><Icon icon="bullhorn" size={15} /></div>
                                                        <div className="fw-bold fs-7">{model?.sourceName}</div>
                                                    </FlexRow>
                                                    {
                                                        fixedRates ?
                                                            <>
                                                                <div className="mt-3 mw-200px">
                                                                    <TextBox name="sourceFixedRate" block placeholder="Source Rate" small
                                                                        value={props.job?.payment?.sourceFixRate?.toString()}
                                                                        money autoFocus marginBottom={0} icon="dollar-sign" />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <FlexRow className={"mb-3" + (customSourceRate ? " text-decoration-line-through text-danger" : "")}>
                                                                    <div className="fw-bolder me-2">
                                                                        Rate:
                                                                    </div>
                                                                    <div className="me-1">
                                                                        {model?.sourceRate}%
                                                                    </div>
                                                                    <span>
                                                                        <Icon icon="circle-question" type="solid" color="#666" title={model?.sourceRateName} />
                                                                    </span>
                                                                </FlexRow>
                                                                <div className="mb-2">
                                                                    <ToggleSwitch label="Custom Rate" raw className="fs-7" onChange={e => customSourceRateSet(e)} checked={customSourceRate} small />
                                                                </div>
                                                                {
                                                                    customSourceRate ?
                                                                        <div className="mt-3 mw-200px">
                                                                            <input type="hidden" name="sourceRateIsCustom" value="True" />
                                                                            <TextBox name="sourceCustomRate" block placeholder="Custom Source Rate" small
                                                                                value={props.job?.payment?.sourceRateIsCustom ? props.job.payment.sourceRate?.toString() : undefined}
                                                                                numeric autoFocus marginBottom={0} icon="percent" maxLength={2} />
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <input type="hidden" name="sourceRate" value={model?.sourceRate} />
                                                                            <input type="hidden" name="sourceRateId" value={model?.sourceRateId} />
                                                                            <input type="hidden" name="sourceRateName" value={model?.sourceRateName} />
                                                                        </>
                                                                }
                                                            </>
                                                    }

                                                </div>


                                            </div>

                                            <div className="col col-12 col-md-4">
                                                <div className="bg-light-primary p-5 mb-3">
                                                    <div className="fw-bolder fs-7 mb-2">Technician Fee</div>
                                                    <FlexRow className="mb-2">
                                                        <div className="me-2"><Icon icon="user-helmet-safety" size={15} /></div>
                                                        <div className="fw-bold fs-7">{model?.techName}</div>
                                                    </FlexRow>
                                                    <FlexRow className={"mb-3" + (customTechFee ? " text-decoration-line-through text-danger" : "")}>
                                                        <div className="fw-bolder me-2">
                                                            Fee:
                                                        </div>
                                                        <div className="me-1">
                                                            {model?.techFee}$
                                                        </div>
                                                    </FlexRow>
                                                    <div className="mb-2">
                                                        <ToggleSwitch label="Custom Fee" raw className="fs-7" onChange={e => customTechFeeSet(e)} checked={customTechFee} small />
                                                    </div>
                                                    {
                                                        customTechFee ?
                                                            <div className="mt-3 mw-200px">
                                                                <TextBox name="techFee" block placeholder="Custom Tech Fee" small
                                                                    value={props.job?.payment?.techRateIsCustom ? props.job.payment.techRate?.toString() : undefined}
                                                                    money autoFocus marginBottom={0} icon="dollar-sign" />
                                                            </div>
                                                            :
                                                            <>
                                                                <input type="hidden" name="techFee" value={model?.techFee} />
                                                            </>
                                                    }
                                                </div>
                                            </div>



                                            {
                                                ratesExceedErr &&
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span className="text-danger small">
                                                            The total rates couldn't be greater than 100%
                                                        </span>
                                                    </div>
                                                </div>
                                            }


                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            fixedRates && <input type="hidden" name="fixedRates" value="True" />
                                        }
                                        <input type="hidden" name="techRate" value={model?.techRate} />
                                        <input type="hidden" name="techRateId" value={model?.techRateId} />
                                        <input type="hidden" name="techRateName" value={model?.techRateName} />
                                        {
                                            model?.techRateIsFixed &&
                                            <input type="hidden" name="techRateIsFixed" value={"True"} />
                                        }
                                        <input type="hidden" name="sourceRate" value={model?.sourceRate} />
                                        <input type="hidden" name="sourceRateId" value={model?.sourceRateId} />
                                        <input type="hidden" name="sourceRateName" value={model?.sourceRateName} />
                                        <input type="hidden" name="techFee" value={model?.techFee} />
                                    </>
                            }




                            {
                                taxes.length > 0 &&
                                <>
                                    <hr />

                                    <div>

                                        <FlexRow className="mb-2">
                                            <div className="me-2"><Icon icon="percent" size={14} /></div>
                                            <div className="fw-bolder fs-6">Tax</div>
                                        </FlexRow>

                                        <div className="mt-2">
                                            {
                                                taxItems.map((t, k) =>
                                                    <FlexRow key={k} className="mb-2">
                                                        <div className="me-2">
                                                            {
                                                                taxItems.length == 1 ?
                                                                    <span>
                                                                        <Icon icon="circle-minus" className="text-secondary" type="solid" size={18} />
                                                                    </span>
                                                                    :
                                                                    <span className="cursor-pointer" title="Remove Payment Item"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            var arr = taxItems;
                                                                            arr.splice(k, 1);
                                                                            taxItemsSet(t => [...arr]);
                                                                        }}>
                                                                        <Icon icon="circle-minus" className="text-danger" type="solid" size={18} />
                                                                    </span>
                                                            }
                                                        </div>
                                                        <div className="w-200px me-2">
                                                            <DropDown id={"drp-tax" + k} name={"taxId" + k} parentModal="modal-closejob-payment"
                                                                source={taxes} dataText="nameWithAmount" dataValue="id" block marginBottom={0} defaultValue={t.id}
                                                                onSelectItem={(item: CompactTax) => {
                                                                    var arr = taxItems;
                                                                    arr[k] = item;
                                                                    taxItemsSet(t => [...arr]);
                                                                }}
                                                                onClear={() => {
                                                                    var arr = taxItems;
                                                                    arr[k] = {};
                                                                    taxItemsSet(t => [...arr]);
                                                                }} />
                                                        </div>
                                                        {
                                                            t.id &&
                                                            <div className="w-150px me-2">
                                                                <ToggleSwitch id={"toggle-taxincluded" + k} name={"taxincluded" + k} raw label="Tax is included" className="fs-7" small
                                                                    onChange={e => {
                                                                        var arr = taxItems;
                                                                        arr[k].included = e;
                                                                        taxItemsSet(t => [...arr]);
                                                                    }} />
                                                            </div>
                                                        }
                                                    </FlexRow>
                                                )
                                            }
                                        </div>
                                        <div className="ms-2">
                                            <a href="#" className="fs-8"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    var arr = taxItems;
                                                    arr.push({});
                                                    taxItemsSet(t => [...arr]);
                                                }}>
                                                <Icon icon="plus" className="me-1 fs-8" />
                                                Add Tax
                                            </a>
                                        </div>

                                    </div>
                                </>
                            }


                            <hr />

                            <div className="mt-10 mb-10">
                                <ToggleSwitch label="Custom Closing Date" raw small onChange={e => customCloseDateSet(e)} />

                                {
                                    customCloseDate &&
                                    <div className="mt-5 row">
                                        <div className="col-md-6">
                                            <DatePicker id="dt-update-closedate" name="closedate" drops="up"
                                                timePicker marginBottom={5} label="Close Date" labelUp />
                                        </div>
                                        <div className="col-md-6">
                                            <DropDown name="closetimezone" block labelUp
                                                label="Timezone" autoSelectFirstItem
                                                source={[
                                                    {
                                                        id: props.job?.timezone,
                                                        name: `Local Time (${props.job?.timezone})`
                                                    },
                                                    {
                                                        id: getUser()?.timeZoneId,
                                                        name: `Your Time (${getUser()?.timeZoneId})`
                                                    }
                                                ]} />
                                        </div>
                                    </div>
                                }

                                {
                                    model?.noteRequired &&
                                    <TextArea name="note" label="Closing Note*" placeholder="Write the close status note..." block labelUp rows={4} />
                                }

                                {
                                    props.editMode &&
                                    <TextArea name="updateNote" label="Update Note*" placeholder="Please describe why you are updating the payment..." block labelUp rows={4} />
                                }
                            </div>

                        </div>
                }

            </Modal>

            <Modal id="modal-discard-close"
                title="Discard"
                buttonText="Discard"
                cancelText="Cancel"
                buttonColor="warning" secondModal
                onConfirm={callback => {
                    callback(true);
                    if (props.onDiscard) props.onDiscard();
                    window.closeModal("modal-discard-close");
                    window.closeModal("modal-closejob-payment");
                }}>
                <div>
                    Are you sure you want to discard the form?
                </div>
            </Modal>

            <Modal id="modal-confirm-closezero"
                title="Close Without Payments"
                buttonText="Close Without Payments"
                buttonColor="warning" secondModal

                onConfirm={callback => {

                    hub.Post(API_ROUTES.Jobs + "closejobwithzero", {
                        id: props.job?.id
                    }, res => {
                        if (res.success) {
                            toastSuccess("Job #" + props.job?.jobId + " has been closed successfully!");
                            if (props.onUpdate) props.onUpdate();
                        }
                        callback();
                        window.closeModal("modal-closejob-payment");
                    })
                }}>
                <div>
                    Are you sure you want to close this job without entering financial details?
                </div>
            </Modal>
        </>
    )
}