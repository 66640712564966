import { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import { addUnseenNotificationsCount, setUnreadNotificationsCount, setUnseenNotificationsCount, useGlobal, useHub, useSocket } from "../../core/Global";
import Notification from "../../models/Notification";
import { toastDefault, toastNotification } from "../base/Toast";
import NotificationItem from "./NotificationItem";
import { Link } from "react-router-dom";

type Props = {
	authenticated?: boolean
}
let notifIds: string[] = [];
function NotificationsDrawer(props: Props) {

	const hub = useHub();
	const [model, modelSet] = useState<Notification[]>([]);
	const [loaded, loadedSet] = useState<boolean>(false);
	const socket = useSocket();
	const [globalState] = useGlobal();

	const fetch = () => {
		hub.Get("/accounts/notifications/", res => {
			if (res.success) {
				modelSet(res.data);
				loadedSet(true);
			}
		})
	}

	useEffect(() => {
		if (props.authenticated) {
			fetch();
		}
	}, [props.authenticated])

	useEffect(() => {
		if (loaded) {

			socket.start(skt => {

				skt.on("PushNotificatoin", (notif: Notification) => {
					if (notifIds.indexOf(notif.id) > -1) return;
					notifIds.push(notif.id);
					var type = "primary";
					if (!notif.color?.startsWith("#")) type = notif.color ?? "primary";
					toastNotification(type, notif.text, notif.icon, notif.title, notif.color);
					var arr = model ?? [];
					arr.unshift(notif);
					modelSet(f => [...arr]);
					addUnseenNotificationsCount();
				});


			}, skt => {

			})
		}
	}, [loaded])


	useEffect(() => {
		if (model) {
			var unread = model.filter(f => f.unread).length
			setUnreadNotificationsCount(unread)
		}
	}, [model])

	return (
		<div id="kt_activities" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'lg': '700px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
			<div className="card shadow-none rounded-0 w-100">
				<div className="card-header" id="kt_activities_header">
					<h3 className="card-title fw-bolder text-dark">Notifications</h3>
					<div className="card-toolbar">
						<button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
							<span className="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
								</svg>
							</span>
						</button>
					</div>
				</div>
				<div className="card-body position-relative" id="kt_activities_body">

					{
						loaded && model && model.length > 0 ?
							<div id="kt_activities_scroll" className="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true" data-kt-scroll-wrappers="#kt_activities_body" data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px">
								<div className="timeline">
									{
										model?.map((item, key) =>
											<NotificationItem key={key} item={item}
												onRead={() => {
													var arr = model ?? [];
													arr[key].unread = false;
													modelSet(a => [...arr]);
													hub.Put("/accounts/notifications/read/" + item.id);
													setTimeout(() => {
														window.closeNotifications();
													}, 200);
												}} />
										)
									}
								</div>
							</div>
							:
							<div className="card">
								<div className="card-body">
									<div className="card-px text-center pt-15 pb-15">
										<h2 className="fs-2x fw-bolder mb-0">Nothing has happened!</h2>
										<p className="text-gray-400 fs-4 fw-bold py-7">
											There is no notification for today!
										</p>
									</div>
									<div className="text-center pb-15 px-5">
										<img src="/assets/media/illustrations/sketchy-1/16.png" alt="" className="mw-100 h-200px h-sm-325px" />
									</div>
								</div>
							</div>
					}

				</div>
				<div className="card-footer py-5 text-center" id="kt_activities_footer">
					<Link to="/profile/notifications" className="btn btn-bg-body text-primary"
						onClick={e => {
							$("#kt_activities_close").click();
						}}>
						View All Notifications
						<span className="svg-icon svg-icon-3 svg-icon-primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
								<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
								<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
							</svg>
						</span>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default NotificationsDrawer;