import React, { useEffect } from "react";
import Environment from '../../env/env';
import { BootstrapColors } from "../../core/Types";


type AvatarProps = {
    avatarUrl?: string
    fullName?: string
    className?: string
    width?: number
    textSize?: number
    background?: BootstrapColors
}

const env = new Environment();
function Avatar(props: AvatarProps) {

    useEffect(() => {
        //console.log("Avatar props", props);
    }, []);
    
    return (
        <div className={"symbol symbol-circle overflow-hidden " + props.className}>
            <div className={`symbol-label bg-${props.background ?? "primary"}`} style={{
                width: props.width ?? 50,
                height: props.width ?? 50
            }}>
                {
                    !window.isNullOrEmpty(props.fullName) && (window.isNullOrEmpty(props.avatarUrl) || props.avatarUrl == "/assets/media/avatars/blank.png") ? 
                    <span className={`text-white ${(props.textSize ? "" : (props.width ?? 50) < 24 ? "fs-9" : (props.width ?? 50) < 24 ? "fs-8" : "fs-6")}`} style={{
                        paddingTop: 2,
                        textTransform: "capitalize",
                        fontSize: props.textSize
                    }}>
                        {props.fullName?.substring(0, 1)}
                    </span>
                    :
                    <img src={props.avatarUrl ?? "/assets/media/avatars/blank.png"} className="w-100" />
                }
                
            </div>
        </div>
    )
}

export default Avatar;

