import { useEffect, useState } from "react";
import Section from "../../../components/base/Section";
import Calendar, { CalendarEventItem } from "../../../components/calendar/Calendar";
import Layout from "../../../components/layouts/Layout";
import { getUser, setPage, setToolbarActions, setToolbarComponent, useEnvironment, useGlobal, useHub } from "../../../core/Global";
import Button from "../../../components/base/Button";
import DropDown, { DropDownOption } from "../../../components/form/DropDown";
import { API_ROUTES } from "../../../core/Constants";
import Modal from "../../../components/base/Modal";
import TextBox from "../../../components/form/TextBox";
import Tagify from "../../../components/tagify/Tagify";
import { toastError, toastSuccess } from "../../../components/base/Toast";
import FlexRow from "../../../components/base/FlexRow";
import ColorPicker from "../../../components/form/ColorPicker";
import { ListItem } from "../../../core/Types";
import { ShiftIntervals } from "../../../core/Enums";
import DatePicker from "../../../components/form/DatePicker";
import Shift from "../models/Shift";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import { CompactUser } from "../../../core/CompactModels";
import Icon from "../../../components/base/Icon";
import Avatar from "../../../components/base/Avatar";

let CalendarQueueId = "";
let CalendarStart = "";
let CalendarEnd = "";
let CalendarViewType = "dayGridMonth";

interface IProps{
    agentId?: string
}

export default function ShiftsCalendar(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();
    const user = getUser();
    const [globalState] = useGlobal();

    const [queuesRouteParams, queuesRouteParamsSet] = useState<ListItem[]>([]);
    const [selectedOwnerId, selectedOwnerIdSet] = useState<string>();
    const [selectedShiftInterval, selectedShiftIntervalSet] = useState<ShiftIntervals>(ShiftIntervals.Daily);

    const [selectedQueueId, selectedQueueIdSet] = useState<string>();
    const [shiftEvents, shiftEventsSet] = useState<CalendarEventItem[]>();
    
    const [agentsAssignPool, agentsAssignPoolSet] = useState<CompactUser[]>([]);
    const [agentsAssigned, agentsAssignedSet] = useState<CompactUser[]>([]);
    const [agentsPool, agentsPoolSet] = useState<CompactUser[]>([]);
    const [shiftAgents, shiftAgentsSet] = useState<CompactUser[]>([]);

    const [agentsPoolKeyword, agentsPoolKeywordSet] = useState<string>("");
    const [shiftAgentsKeyword, shiftAgentsKeywordSet] = useState<string>("");

    const [selectedShiftId, selectedShiftIdSet] = useState<string>();
    const [selectedShift, selectedShiftSet] = useState<Shift>();
    const [selectedShiftDate, selectedShiftDateSet] = useState<string>();
    const [shiftLoading, shiftLoadingSet] = useState<boolean>(false);

    const fetchQueueEvents = (id?:string, start?: any, end?: any, viewType?: string) => {
        
        CalendarQueueId = id ?? CalendarQueueId;
        if (CalendarQueueId == "") return;

        CalendarStart = start ?? CalendarStart;
        CalendarEnd = end ?? CalendarEnd;
        CalendarViewType = viewType ?? CalendarViewType;

        var q = "?queueId=" + CalendarQueueId;
        q += "&viewType=" + CalendarViewType;
        if(start) q+= "&start=" + CalendarStart;
        if(end) q+= "&end=" + CalendarEnd;
        
        hub.Get("/base/shifts/calendarevents/" + q, res => {
            if(res.success) shiftEventsSet(res.data);
        });
    }
    
    const ActionButtons = () => (
        <>
            <Button color="light" text="Assign Agents" icon="user-plus" modal="modal-agents-addto-shift"
                onClick={() => {
                    shiftLoadingSet(true);
                }} />
            <Button color="primary" text="Add Shift" icon="plus" modal="modal-add-shift" />
        </>
    )

    const AgentsSelector = () => (
        <FlexRow>
            <div style={{
                width: 250
            }}>
                <DropDown id="drp-filtershifts-agents" apiSource={API_ROUTES.TagifyAgents} 
                    dataText="title" dataValue="value" raw
                    onSelect={(id) => {
                        selectedQueueIdSet(id);
                        fetchQueueEvents(id);
                    }}></DropDown>
            </div>
        </FlexRow>
    )

    useEffect(() => {
        setPage("Shifts Calendar", "Accounts")
        setToolbarActions(ActionButtons);
        setToolbarComponent(AgentsSelector);

        window.onModalOpen("modal-agents-addto-shift", () => {
            shiftLoadingSet(true);
            agentsAssignedSet([]);
            hub.Get("/accounts/agents/all", res => {
                if (res.success) {
                    agentsAssignPoolSet(res.data);
                    shiftLoadingSet(false);
                }
            })
        });
    }, []);

    

    return (
        <Layout wide>
            
            <Section fluid>
                <Calendar events={shiftEvents}
                    onChange={e => {
                        //console.log("datesSet", SelectedCalendarQueueId, window.momentize(e.start).format("DDMMYYYY"));
                        if(env.isDevelopment) console.log("calendar changed", e, e.view.type);
                        fetchQueueEvents(CalendarQueueId, window.momentize(e.start).format("DDMMYYYY"), window.momentize(e.end).format("DDMMYYYY"), e.view.type);
                    }} 
                    onEventClick={e=> {
                        shiftLoadingSet(true);
                        selectedShiftIdSet(e.event.id);
                        selectedShiftDateSet(window.momentize(e.event.start).format("DD/MM/YYYY"));
                        window.openModal("modal-agents-shift");

                        hub.Get(API_ROUTES.Shifts + "calendarevents/agents/" + e.event.id, res => {
                            if(res.success) {
                                selectedShiftSet(res.data.shift);
                                agentsPoolSet(res.data.agentsPool);
                                shiftAgentsSet(res.data.shiftAgents);
                                shiftLoadingSet(false);
                            }
                            shiftLoadingSet(false);
                        })
                    }} /> 
            </Section>

            <Modal id="modal-add-shift"
                title="Add New Shift"
                buttonText="Create Shift"
                staticBackdrop
                size="Large"
                onSubmit={(body, callback) => {

                    if (!body.name || body.name == "" || body.name.length < 2) {
                        window.showFormError("name", "Please write a valid name!");
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Shifts, {
                        ...body,
                        queueId: CalendarQueueId
                    }, res => {
                    if (res.success) {
                        fetchQueueEvents();
                        toastSuccess("Shift has been added successfully!");
                    }
                    if (callback) callback(res);
                    })
          }}>
            <div>
                <FlexRow className="mb-5">
                    <ColorPicker id="color-add-shift-cal" width={30} className="me-2" name="color" />
                    <div className="flex-grow-1">
                    <TextBox id="txt-addshift-name" name="name" block marginBottom={0} inlineStyle mediumText noTextError placeholder="Shift Name" />
                    </div>
                </FlexRow>
              
                  <div className="fs-7 fw-bold">
                    Repeats
                  </div>
                  <div className="mb-5">
                    <select id="drp-addshift-interval" name="shiftInterval" className="form-select form-select-solid form-select-sm" 
                        value={selectedShiftInterval} onChange={e=> {
                          if (e.target.value == "1") selectedShiftIntervalSet(ShiftIntervals.Daily);
                          if (e.target.value == "2") selectedShiftIntervalSet(ShiftIntervals.Weekly);
                          if (e.target.value == "3") selectedShiftIntervalSet(ShiftIntervals.SpecificDate);
                        }}>
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Specific Date</option>
                    </select>
                  </div>

                  {
                    selectedShiftInterval == ShiftIntervals.Weekly &&
                    <FlexRow className="mb-10 mt-5">
                    
                      <div className="form-check form-check-custom form-check-solid form-check-sm">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="1"/>
                            Monday
                          </label>
                      </div>
                      
                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="2"/>
                            Tuesday
                          </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="3"/>
                            Wednesday
                          </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="4"/>
                            Thursday
                          </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="5"/>
                            Friday
                          </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="6"/>
                            Saturday
                          </label>
                      </div>

                      <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                          <label className="form-check-label fs-7">
                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="0"/>
                            Sunday
                          </label>
                      </div>

                    </FlexRow>
                  }

                  {
                    selectedShiftInterval == ShiftIntervals.SpecificDate &&
                    <div className="row">
                        <div className="col-sm-12">
                          <DatePicker id="txt-addshift-startdate" label="Date" name="startDate" block labelUp marginBottom={5} />
                        </div>
                    </div>
                  }

                  <div className="row">
                      <div className="col-sm-6">
                        <TextBox name="startTime" timeInput label="Start Time" labelUp />
                      </div>
                      <div className="col-sm-6">
                        <TextBox name="endTime" timeInput label="End Time" labelUp />
                      </div>
                  </div>

                  

            </div>
            </Modal>

            <Modal id="modal-agents-shift"
                    title="Shift Agents"
                    buttonText="Update Shift"
                    staticBackdrop
                    size="Large"
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.Shifts + "calendarevents/agents/" + selectedShiftId, {
                            queueId: CalendarQueueId,
                            items: shiftAgents
                        }, res => {
                            if (res.success) {
                                fetchQueueEvents();
                                toastSuccess("Shift agents has been updated successfully!");
                            }
                            if (callback) callback(res);
                        })
            }}>
                <div>
                    <Div visible={shiftLoading}>
                        <Indicator />
                    </Div>
                    <Div visible={!shiftLoading}>

                        <div className="row">
                            <div className="col-6">
                            <FlexRow className="border-bottom pb-2">
                                <div className="fs-5 fw-bolder">
                                Agents Pool
                                </div>
                            </FlexRow>
                            <div>
                                {
                                agentsPool.length > 0 &&
                                <div>
                                    {/* <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th className="fs-7 fw-bolder ps-2">
                                            Agents
                                            </th>
                                            <th className="text-end pe-2">
                                            {
                                                agentsPool.length > 0 &&
                                                <span className="badge badge-primary cursor-pointer ms-auto" 
                                                onClick={e=> {
                                                    let pool = agentsPool ?? [];
                                                    let exts = shiftAgents ?? [];
                                                    for(var i = 0; i < pool.length; i++) {
                                                        exts.push(pool[i]);
                                                    }
                                                    agentsPoolSet([]);
                                                    shiftAgentsSet(e=> [...exts]);
                                                }}>
                                                <Icon icon="plus" size={12} className="me-1" />
                                                Add All
                                                </span>
                                            }
                                            </th>
                                        </tr>
                                        </thead>
                                    </table> */}
                                    <div className="mb-2">
                                        <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                            value={agentsPoolKeyword} onChange={e=> agentsPoolKeywordSet(e.target.value)} />
                                    </div>
                                    <div className="scroll h-300px">
                                    <table className="table table-striped">
                                    <tbody>
                                    {
                                        agentsPool
                                            .filter(item => {
                                                if (agentsPoolKeyword == "") return true;
                                                return item.fullName?.toLowerCase().includes(agentsPoolKeyword.toLowerCase());
                                            })
                                            .map((item, key) => 
                                        <tr key={key}>
                                        <td className="px-3">
                                            <FlexRow>
                                                <Avatar avatarUrl={item.avatarUrl} width={26} />
                                                <span className="ms-2">{item.fullName}</span>
                                            </FlexRow>
                                        </td>
                                        <td className="text-end px-3">
                                            <span className="badge badge-light-primary cursor-pointer"
                                                onClick={e=> {
                                                    let pool = agentsPool ?? [];
                                                    let exts = shiftAgents ?? [];
                                                    pool.splice(pool.indexOf(item), 1);
                                                    exts.push(item);
                                                    agentsPoolSet(e=> [...pool]);
                                                    shiftAgentsSet(e=> [...exts]);
                                                }}>
                                            <Icon icon="plus" size={12} />
                                            </span>
                                        </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                    </table>
                                    </div>
                                </div>
                                
                                }
                                
                                {
                                agentsPool.length == 0 &&
                                <div className="text-center mt-10">
                                    There is no agent!
                                </div>
                                }
                            </div>
                            </div>
                            <div className="col-6">
                            
                                <FlexRow className="border-bottom pb-2">
                                    <div className="fs-5 fw-bolder">
                                    Agents on shift
                                    </div>
                                </FlexRow>
                                <div>
                                    {
                                    shiftAgents.length > 0 &&
                                    <div>
                                        <div className="mb-2">
                                            <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                                value={shiftAgentsKeyword} onChange={e=> shiftAgentsKeywordSet(e.target.value)} />
                                        </div>
                                        <div className="scroll h-300px">
                                            <table className="table table-striped">
                                            <tbody>
                                                {
                                                shiftAgents
                                                    .filter(item => {
                                                        if (shiftAgentsKeyword == "") return true;
                                                        return item.fullName?.toLowerCase().includes(shiftAgentsKeyword.toLowerCase());
                                                    })
                                                    .map((item, key) => 
                                                <tr key={key}>
                                                    <td className="px-3">
                                                    <FlexRow>
                                                        <Avatar avatarUrl={item.avatarUrl} width={26} />
                                                        <span className="ms-2">{item.fullName}</span>
                                                    </FlexRow>
                                                    </td>
                                                    <td className="px-3">
                                                    <FlexRow justify="end">
                                                        <span className="badge badge-light-danger cursor-pointer"
                                                        onClick={e=> {
                                                            let pool = agentsPool ?? [];
                                                            let exts = shiftAgents ?? [];
                                                            exts.splice(exts.indexOf(item), 1);
                                                            pool.push(item);
                                                            agentsPoolSet(e=> [...pool]);
                                                            shiftAgentsSet(e=> [...exts]);
                                                        }}>
                                                        <Icon icon="minus" size={12} />
                                                        </span>
                                                    </FlexRow>
                                                    
                                                    
                                                    </td>
                                                </tr>
                                                )
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    }
                                    


                                    {
                                    shiftAgents.length == 0 &&
                                    <div className="text-center mt-10">
                                        There is no agent!
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                        
                        </div>

                    </Div>
                </div>
            </Modal>

            <Modal id="modal-agents-addto-shift"
                    title="Assign Agents to Shift"
                    buttonText="Assign Agents"
                    staticBackdrop
                    size="Large"
                    onSubmit={(body, callback) => {

                        if (body.queueId == null || body.queueId.length < 2) {
                            window.showFormError("queueId", "Please choose a queue!", true, "modal-agents-addto-shift");
                            if (callback) callback();
                            return;
                        }

                        if (body.shiftId == null || body.shiftId.length < 2) {
                            window.showFormError("shiftId", "Please choose a shift!", true, "modal-agents-addto-shift");
                            if (callback) callback();
                            return;
                        }

                        if(agentsAssigned.length < 1) {
                            toastError("No agent selected!");
                            if (callback) callback();
                            return;
                        }

                        hub.Post(API_ROUTES.Shifts + "assignagents", {
                            ...body,
                            agents: agentsAssigned
                        }, res => {
                            if (res.success) {
                                fetchQueueEvents();
                                toastSuccess("ُSelected agents has been assign to shift successfully!");
                            }
                            if (callback) callback(res);
                        })
            }}>
                <div>
                    <Div visible={shiftLoading}>
                        <Indicator />
                    </Div>
                    <Div visible={!shiftLoading}>

                        <DropDown id="drp-assignagents-queues" apiSource={API_ROUTES.TagifyQueues} 
                            label="Queue" labelUp name="queueId" marginBottom={5}
                            dataText="title" dataValue="value" defaultValue={selectedQueueId}
                            onSelect={(id, txt) => {
                                window.updateSelect2("drp-filtershifts-queues", id);
                                selectedQueueIdSet(id);
                                fetchQueueEvents(id);
                            }}></DropDown>

                        <DropDown id="drp-assignagents-shifts" name="shiftId" 
                            apiSource={API_ROUTES.TagifyShifts + "?queueId=" + selectedQueueId}
                            dataText="title" dataValue="value" childrenAtEnd
                            label="Shift" labelUp marginBottom={5}>
                                
                            </DropDown>    


                            {/* <div>
                                <div className="fs-7 fw-bold">
                                    Shift Name
                                </div>
                                <FlexRow className="mb-5">
                                    <ColorPicker width={24} className="me-2" name="color" />
                                    <div className="flex-grow-1">
                                        <TextBox id="txt-assignagents-shiftname" name="name" block marginBottom={0} />
                                    </div>
                                </FlexRow>
                            
                                <div className="fs-7 fw-bold">
                                    Repeats
                                </div>
                                <div className="mb-5">
                                    <select id="drp-assignagents-shiftinterval" name="shiftInterval" className="form-select form-select-solid form-select-sm" 
                                        value={selectedShiftInterval} onChange={e=> {
                                        if (e.target.value == "1") selectedShiftIntervalSet(ShiftIntervals.Daily);
                                        if (e.target.value == "2") selectedShiftIntervalSet(ShiftIntervals.Weekly);
                                        if (e.target.value == "3") selectedShiftIntervalSet(ShiftIntervals.SpecificDate);
                                        }}>
                                    <option value="1">Daily</option>
                                    <option value="2">Weekly</option>
                                    <option value="3">Specific Date</option>
                                    </select>
                                </div>

                                {
                                    selectedShiftInterval == ShiftIntervals.Weekly &&
                                    <FlexRow className="mb-10 mt-5">
                                    
                                    <div className="form-check form-check-custom form-check-solid form-check-sm">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="1"/>
                                            Monday
                                        </label>
                                    </div>
                                    
                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="2"/>
                                            Tuesday
                                        </label>
                                    </div>

                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="3"/>
                                            Wednesday
                                        </label>
                                    </div>

                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="4"/>
                                            Thursday
                                        </label>
                                    </div>

                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="5"/>
                                            Friday
                                        </label>
                                    </div>

                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="6"/>
                                            Saturday
                                        </label>
                                    </div>

                                    <div className="form-check form-check-custom form-check-solid form-check-sm ms-2">
                                        <label className="form-check-label fs-7">
                                            <input name="shiftdays" className="form-check-input me-1" type="checkbox" value="0"/>
                                            Sunday
                                        </label>
                                    </div>

                                    </FlexRow>
                                }

                                {
                                    selectedShiftInterval == ShiftIntervals.SpecificDate &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                        <DatePicker id="txt-assignagents-startdate" label="Date" name="startDate" block labelUp marginBottom={5} />
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextBox name="startTime" timeInput label="Start Time" labelUp />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextBox name="endTime" timeInput label="End Time" labelUp />
                                    </div>
                                </div>

                                

                            </div> */}

                        <div className="row">
                            <div className="col-sm-6">
                                <DatePicker id="txt-shiftagents-startdate" label="From" name="startDate" block labelUp marginBottom={5} />
                            </div>
                            <div className="col-sm-6">
                                <DatePicker id="txt-shiftagents-enddate" label="To" name="endDate" block labelUp marginBottom={5} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                            <FlexRow className="border-bottom pb-2">
                                <div className="fs-5 fw-bolder">
                                    Agents Pool
                                </div>
                            </FlexRow>
                            <div>
                                {
                                agentsAssignPool.length > 0 &&
                                <div>
                                    <div className="mb-2">
                                        <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                            value={agentsPoolKeyword} onChange={e=> agentsPoolKeywordSet(e.target.value)} />
                                    </div>
                                    <div className="scroll h-300px">
                                    <table className="table table-striped">
                                    <tbody>
                                    {
                                        agentsAssignPool
                                            .filter(item => {
                                                if (agentsPoolKeyword == "") return true;
                                                return item.fullName?.toLowerCase().includes(agentsPoolKeyword.toLowerCase());
                                            })
                                            .map((item, key) => 
                                        <tr key={key}>
                                        <td className="px-3">
                                            <FlexRow>
                                                <Avatar avatarUrl={item.avatarUrl} width={26} />
                                                <span className="ms-2">{item.fullName}</span>
                                            </FlexRow>
                                        </td>
                                        <td className="text-end px-3">
                                            <span className="badge badge-light-primary cursor-pointer"
                                                onClick={e=> {
                                                    let pool = agentsAssignPool ?? [];
                                                    let exts = agentsAssigned ?? [];
                                                    pool.splice(pool.indexOf(item), 1);
                                                    exts.push(item);
                                                    agentsAssignPoolSet(e=> [...pool]);
                                                    agentsAssignedSet(e=> [...exts]);
                                                }}>
                                                <Icon icon="plus" size={12} />
                                            </span>
                                        </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                    </table>
                                    </div>
                                </div>
                                
                                }
                                
                                {
                                agentsAssignPool.length == 0 &&
                                <div className="text-center mt-10">
                                    There is no agent!
                                </div>
                                }
                            </div>
                            </div>
                            <div className="col-6">
                            
                                <FlexRow className="border-bottom pb-2">
                                    <div className="fs-5 fw-bolder">
                                        Selected Agents
                                    </div>
                                </FlexRow>
                                <div>
                                    {
                                    agentsAssigned.length > 0 &&
                                    <div>
                                        <div className="mb-2">
                                            <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                                value={shiftAgentsKeyword} onChange={e=> shiftAgentsKeywordSet(e.target.value)} />
                                        </div>
                                        <div className="scroll h-300px">
                                            <table className="table table-striped">
                                            <tbody>
                                                {
                                                agentsAssigned
                                                    .filter(item => {
                                                        if (shiftAgentsKeyword == "") return true;
                                                        return item.fullName?.toLowerCase().includes(shiftAgentsKeyword.toLowerCase());
                                                    })
                                                    .map((item, key) => 
                                                <tr key={key}>
                                                    <td className="px-3">
                                                    <FlexRow>
                                                        <Avatar avatarUrl={item.avatarUrl} width={26} />
                                                        <span className="ms-2">{item.fullName}</span>
                                                    </FlexRow>
                                                    </td>
                                                    <td className="px-3">
                                                    <FlexRow justify="end">
                                                        <span className="badge badge-light-danger cursor-pointer"
                                                        onClick={e=> {
                                                            let pool = agentsAssignPool ?? [];
                                                            let exts = agentsAssigned ?? [];
                                                            exts.splice(exts.indexOf(item), 1);
                                                            pool.push(item);
                                                            agentsAssignPoolSet(e=> [...pool]);
                                                            agentsAssignedSet(e=> [...exts]);
                                                        }}>
                                                        <Icon icon="minus" size={12} />
                                                        </span>
                                                    </FlexRow>
                                                    
                                                    
                                                    </td>
                                                </tr>
                                                )
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    }
                                    


                                    {
                                    agentsAssigned.length == 0 &&
                                    <div className="text-center mt-10">
                                        There is no agent!
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                        
                        </div>

                    </Div>
                </div>
            </Modal>

            

        </Layout>
    )
}