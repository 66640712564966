import { Route, Routes } from "react-router-dom";
import SignUpComplete from "../views/SignUpComplete";
import SignUpStart from "../views/SignUpStart";
import SignUpStripeRedirect from "../views/SignUpStripeRedirect";



function SignUpRoutes() {
    return (
        <Routes>
            <Route path="/join" element={<SignUpStart />} />
            <Route path="/join/stripe/success/:sessionId" element={<SignUpStripeRedirect success />} />
            <Route path="/join/stripe/failed/:sessionId" element={<SignUpStripeRedirect />} />
            <Route path="/join/:token" element={<SignUpComplete />} />
        </Routes>
    );
  }
  
  export default SignUpRoutes;