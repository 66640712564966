import ChatForwardCard from "../components/ChatForwardCard";


export default function ChatSettingsView() {


    return (
        <>
            <ChatForwardCard />
        </>
    )
}