import SystemActivateCountries from "../components/SystemActivateCountries";
import SystemPreferences from "../components/SystemPreferences";


export default function OtherSettingsView() {


    return (
        <>
            <SystemPreferences />
            <SystemActivateCountries />
        </>
    )
}