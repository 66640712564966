import React, { useEffect, useState } from 'react';
import Form from '../../../components/form/Form';
import Icon from '../../../components/base/Icon';
import Alert from '../../../components/base/Alert';
import { Link, useParams } from 'react-router-dom';
import Hub from '../../../api/Hub';
import { API_ROUTES } from '../../../core/Constants';
import HUBResponse from '../../../models/HUBResponse';
import Div from '../../../components/base/Div';
import Indicator from '../../../components/base/Indicator';
import { PasswordResetValidator } from '../validators/PasswordResetValidator';
import { useHub } from '../../../core/Global';

function ResetPassword() {
    const { token } = useParams();
    const [validating, validatingSet] = useState<boolean>(true);
    const [valid, validSet] = useState<boolean>(false);
    const [working, workingSet] = useState<boolean>(false);
    const [done, doneSet] = useState<boolean>(false);
    const [apiError, apiErrorSet] = useState<string>();
    const [username, usernameSet] = useState<string>();

    const hub = useHub();
    
    const validate = async () => {
        validatingSet(true);
        hub.Post(API_ROUTES.PasswordTokenValidate, {token}, (res: HUBResponse<any>) => {
            validSet(res.success ?? false);
            validatingSet(false);
            usernameSet(res.data.username);
        });
    }

    const submit = async (body: any, callback: any) => {
        workingSet(true);
        apiErrorSet(undefined);
        doneSet(false);
        hub.Put(API_ROUTES.PasswordReset, body, (res: HUBResponse<any>) => {
            if(res.success) {
                doneSet(true);
            }
            else {
                apiErrorSet(res.message);
            }
            workingSet(false);
        });
    }

    useEffect(()=> {
        validate();
    }, [])

  return (
    <Form className='form w-100' validator={PasswordResetValidator} onSubmit={submit}>
        <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Reset Password</h1>
            <div className="text-gray-400 fs-6">
                
            </div>
        </div>
        
        <Div visible={(apiError ? true : false)} className="fv-row mb-10">
            <Alert text={apiError} type='danger' />
        </Div>

        <Div visible={validating} className="py-15">
            <Indicator text='validating token...' />
        </Div>

        <Div visible={!validating && !valid} className='py-15'>
            <Alert text='Reset link is not valid or expired' type='danger' className='text-center' />
        </Div>

        <Div visible={valid && !done}>
            <input type="hidden" name='token' value={token}/>
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark" htmlFor='txtusername'>
                    Username
                </label>
                <input className="form-control form-control-lg form-control-solid" id='txtusername'
                        type="text" value={username} readOnly autoComplete='Off' />
            </div>
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark" htmlFor='txtpass'>
                    New Password
                    <span id="password-err" className="small text-danger mx-2 form-error"></span>
                </label>
                <input className="form-control form-control-lg form-control-solid" autoFocus id='txtpass'
                        type="password" name="password" autoComplete='Off' />
            </div>

            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark" htmlFor='txtrepass'>
                    Confirm Password
                    <span id="repassword-err" className="small text-danger mx-2 form-error"></span>
                </label>
                <input className="form-control form-control-lg form-control-solid" id='txtrepass'
                        type="password" name="repassword" autoComplete='Off' />
            </div>

            <div className="text-center">
                
                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={working}>
                    {!working && <span className="indicator-label">Set Password</span>}
                    {
                        working &&
                        <span className="indicator-btn">
                            <Icon icon='asterisk' spin />
                            <span className='ms-2'>
                                Sending...
                            </span>
                        </span>
                    }
                </button>
                
            </div>
        </Div>

        <Div visible={valid && done} className='py-15'>
            <Alert text='New Password hes been set successfully!' type='success' className='text-center' />
            <div className='text-center'>
                <Link to="/auth/login" className='btn btn-lg btn-default'>
                    Back To Login
                </Link>
            </div>
        </Div>

        
    </Form>
  );
}

export default ResetPassword;
