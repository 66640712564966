import React, { useEffect, useState } from "react";
import FilterProps from "../filtering/lines.json";
import Layout from '../../../../components/layouts/Layout';
import { DataTable, DataTableCustomActions, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES, RedirectTypes } from '../../../../core/Constants';
import { getUser, hasRole, setPage, showLineWizard, useGlobal, useHub } from '../../../../core/Global';
import Icon from "../../../../components/base/Icon";
import Modal from "../../../../components/base/Modal";
import { FilterParam } from "../../../../models/BrowseOptions";
import Section from "../../../../components/base/Section";
import FlexRow from "../../../../components/base/FlexRow";
import { InboundDest, InboundRedirectTypes, JobCallbackPriority } from '../../../../core/Enums';
import { toastSuccess } from "../../../../components/base/Toast";
import PhoneLine from "../models/PhoneLine";
import LineSettingsModal from "../components/LineSettingsModal";

function LinesList() {

    const hub = useHub()
    const user = getUser();
    const [globalState] = useGlobal();
    const [selectendLine, selectendLineSet] = useState<PhoneLine>();
    const [refresh, refreshSet] = useState<boolean>();
    const [extraParams, extraParamsSet] = useState<FilterParam[]>();
    const [lineSettingsModal, lineSettingsModalSet] = useState<boolean>(false);
    const [defaultSmsSenderModal, defaultSmsSenderModalSet] = useState<boolean>(false);

    const [selectedLine, selectedLineSet] = useState<PhoneLine>();





    const ReleaseNumberAction = (data: any) => (
        <>
            {
                hasRole("ReleaseActiveNumbers") &&
                <div className="menu-item">
                    <a href="#" className="menu-link px-3 text-danger"
                        onClick={e => {
                            e.preventDefault();
                            selectendLineSet(data);
                            window.openModal("modal-release-number");
                        }}>
                        <Icon icon="phone-slash" className="me-2 text-danger" />
                        Release Number
                    </a>
                </div>
            }
        </>
    )

    const ViewNumberLogs = (data: any) => (
        <div className="menu-item">
            <a className="menu-link px-3">
                <Icon icon="file-lines" className="me-2" />
                View Logs
            </a>
        </div>
    )

    const LineSettingsAction = (data: PhoneLine) => (
        <>
            {
                hasRole("ManageActiveNumbers") &&
                <>
                    <div className="menu-item">
                        <a href="#" className="menu-link text-gray-700 px-3" onClick={e => {
                            e.preventDefault();
                            if (user?.limited) {
                                window.openModal("modal-user-is-limited");
                                return;
                            }
                            selectedLineSet(data);
                            defaultSmsSenderModalSet(true);

                        }}>
                            <Icon icon="check-circle" className="me-2" type="solid" />
                            Default SMS Sender
                        </a>
                    </div>
                    <div className="menu-item">
                        <a href="#" className="menu-link text-gray-700 px-3" onClick={e => {
                            e.preventDefault();
                            if (user?.limited) {
                                window.openModal("modal-user-is-limited");
                                return;
                            }
                            selectedLineSet(data);
                            lineSettingsModalSet(true);

                        }}>
                            <Icon icon="phone-arrow-down-left" className="me-2" />
                            Inbound Settings
                        </a>
                    </div>
                </>
            }
        </>
    )

    const OutboundSettings = (data: any) => (
        <div className="menu-item">
            <a className="menu-link px-3">
                <Icon icon="phone-arrow-up-right" className="me-2" />
                Outbound Settings
            </a>
        </div>
    )

    const LineName = (data: PhoneLine) => (
        <div>
            <a href="#" onClick={e => {
                e.preventDefault();
                if (user?.limited) {
                    window.openModal("modal-user-is-limited");
                    return;
                }
                selectedLineSet(data);
                lineSettingsModalSet(true);
            }}>
                {data.friendlyName}
            </a>

        </div>
    )

    const LineCompany = (data: PhoneLine) => (
        <div>
            <div>
                {data.company?.name}
            </div>
            <div className="d-flex align-items-center text-gray-700 mt-1">
                <Icon icon="user" className="me-1" type="solid" size={11} />
                <div className="fs-8">
                    {data.ownerUser?.fullName}
                </div>
            </div>
        </div>
    )

    const LineDest = (data: PhoneLine) => (
        <div>
            {
                data.inboundDest == InboundDest.Queue &&
                <>
                    <div className="d-flex align-items-center">
                        <div className="me-1">
                            Queue
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-700">
                        <Icon icon="arrow-right-to-line" className="me-1" type="solid" size={12} />
                        <div className="fs-8">
                            {data.queue?.description} - {data.queue?.name}
                        </div>
                    </div>
                </>
            }
            {
                data.inboundDest == InboundDest.Extension &&
                <>
                    <div className="d-flex align-items-center">
                        <div className="me-1">
                            Extension
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-700">
                        <Icon icon="phone-office" className="me-1" type="solid" size={11} />
                        <div className="fs-8">
                            {data.extension?.description} - {data.extension?.name}
                        </div>
                    </div>
                </>
            }
            {
                data.inboundDest == InboundDest.Redirect &&
                <>
                    <div className="d-flex align-items-center mb-1">
                        <div className="me-1">
                            Redirect
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-gray-700">
                        <Icon icon="phone-arrow-up-right" className="me-1" type="solid" size={11} />
                        {
                            data.redirectType == InboundRedirectTypes.JobsCustomers ?
                                <>
                                    <div className="fs-8">
                                        Jobs Customers
                                    </div>
                                </>
                                : data.redirectType == InboundRedirectTypes.ExternalNumber ?
                                    <div className="fs-8">
                                        To: {toFriendly(data.redirectToExternalNumber)}
                                    </div>
                                    :
                                    <></>
                        }

                    </div>
                    {
                        data.redirectType == InboundRedirectTypes.JobsCustomers &&
                        <div className="d-flex align-items-center text-gray-700 mt-1">
                            <Icon icon="phone-missed" className="me-1" type="solid" size={11} />
                            <div className="fs-8 me-1">
                                Callback To
                            </div>
                            {
                                data.jobRedirectCallbackPriority == JobCallbackPriority.Operator ?
                                    <div className="fs-8">
                                        {data.jobCallbackPhoneNumber}
                                    </div>
                                    :
                                    <div className="fs-8">
                                        Technician
                                    </div>
                            }
                        </div>
                    }
                </>
            }
            {
                data.inboundDest == InboundDest.None &&
                <a href="#" className="fs-8" onClick={e => {
                    e.preventDefault();
                    if (user?.limited) {
                        window.openModal("modal-user-is-limited");
                        return;
                    }
                    selectedLineSet(data);
                    lineSettingsModalSet(true);
                }}>
                    Set Destination
                </a>
            }
        </div>
    )

    const toFriendly = (data?: string) => {
        var ph = "";
        if (!data) return "";
        data = data.replace("+1", "");
        for (var i = 0; i < (data.length ?? 0); i++) {
            if (i == 0) ph += "(";
            if (i == 3) ph += ") ";
            if (i == 6) ph += " ";
            ph += data[i];
        }
        return ph;
    }

    const DefaultsTagsColumns = (data: PhoneLine) => (
        <div>
            {
                data.defaultSmsSender &&
                <FlexRow className="text-success mt-1">
                    <span className="me-2">
                        <Icon icon="check-circle" type="solid" />
                    </span>
                    <span className="fs-7">
                        Default SMS Sender
                    </span>
                </FlexRow>
            }
        </div>
    )

    const CreatedItem = (data: PhoneLine) => (
        <div>
            <div className='d-flex align-items-center'>
                {data.purchasedDate}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }
        </div>
    )

    useEffect(() => {
        setPage("Active Numbers", "Voice", "Lines");
    }, [])

    useEffect(() => {
        if (refresh) setTimeout(() => refreshSet(false), 500);
    }, [refresh])


    return (
        <Layout wide>

            <Section fluid>
                <DataTable objectName="Line"
                    route="/voice/lines"
                    addCustom={() => {
                        showLineWizard();
                    }}
                    filteringProps={FilterProps}
                    apiRoute={API_ROUTES.Lines}
                    refresh={refresh || globalState.refreshLinesList}
                    extraParams={extraParams}
                    preventCreate={!hasRole("BuyNumber")}
                    ascending>
                    <DataTableField title="Phone Number">
                        <LineName />
                    </DataTableField>
                    <DataTableField title="Company">
                        <LineCompany />
                    </DataTableField>
                    <DataTableField title="Destination">
                        <LineDest />
                    </DataTableField>
                    <DataTableField title="Purchased">
                        <CreatedItem />
                    </DataTableField>
                    <DataTableField title="">
                        <DefaultsTagsColumns />
                    </DataTableField>
                    <DataTableCustomActions>
                        <ReleaseNumberAction />
                    </DataTableCustomActions>
                    <DataTableCustomActions>
                        <LineSettingsAction />
                    </DataTableCustomActions>
                </DataTable>
            </Section>


            <Modal id="modal-release-number"
                title="Release Number"
                buttonText="Release Number"
                buttonColor="danger"
                onSubmit={(body, callback) => {

                    hub.Put(API_ROUTES.Lines + "release/" + selectendLine?.id, body, res => {
                        if (res.data) {
                            refreshSet(true);
                            if (callback) callback(res);
                            toastSuccess("Phone number released successfully!");
                        }
                    });

                }}>
                <p>
                    Are you sure you want to release this number?
                </p>
                <div className="bg-light-primary p-4">
                    <div className="d-flex align-items-center">
                        <Icon icon="phone" className="me-2" type="solid" />
                        {selectendLine?.friendlyName}
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Icon icon="user" className="me-2" type="solid" />
                        {selectendLine?.ownerUser?.fullName}
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Icon icon="briefcase" className="me-2" type="solid" />
                        {selectendLine?.company?.name}
                    </div>
                </div>
            </Modal>

            {
                lineSettingsModal && selectedLine &&
                <LineSettingsModal item={selectedLine}
                    onClose={() => {
                        lineSettingsModalSet(false)
                        selectedLineSet(undefined)
                    }}
                    onSave={() => {
                        refreshSet(true)
                        toastSuccess("Line settings has been updated!")
                    }} />
            }

            {
                defaultSmsSenderModal && selectedLine &&
                <Modal id="modal-default-sms-sender"
                    title="Set as Defauls SMS Sender"
                    open visible
                    buttonText="Set as Default SMS Sender"
                    buttonColor="success"
                    onClose={() => defaultSmsSenderModalSet(false)}
                    onConfirm={callback => {
                        hub.Put(API_ROUTES.Lines + "defaultsmssender/" + selectedLine.id, undefined, res => {
                            toastSuccess("Phone Number has been set as default SMS sender!")
                            callback();
                            refreshSet(true);
                        })
                    }}>

                    <p>
                        Are you sure you want to set the selected phone number as default sms sender?
                    </p>
                    <div className="alert alert-primary">
                        {selectedLine.friendlyName}
                    </div>

                </Modal>
            }



        </Layout>
    );
}

export default LinesList;
