import { useEffect, useState } from "react"
import FlexRow from "../../../components/base/FlexRow"
import ChatContactItem from "../models/ChatContactItem"
import Technician from "../../technicians/models/technician"
import { isDemo, openJobQuickView, useHub, useStatusesForChatTechJobs } from "../../../core/Global"
import { API_ROUTES } from "../../../core/Constants"
import Icon from "../../../components/base/Icon"
import Job from "../../crm/jobs/models/Job"
import ChatJobCard from "./ChatJobCard"
import JobQuickView from "../../crm/jobs/components/JobQuickView"
import JobStatus from "../../crm/base/models/JobStatus"
import ChatAvatarSpan from "./ChatAvatarSpan"
import { ChatEnvironments } from "../../../core/Enums"


interface IProps {
    chat?: ChatContactItem
    chatId?: string
}

export default function ChatTechBox(props: IProps) {

    const hub = useHub();

    const [boxHeight, boxHeightSet] = useState<number>();
    const [jobsHeight, jobsHeightSet] = useState<number>();
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400;
        if (w < 500) {
            h = 250;
        }
        else {
            h = h - ($("#kt_header").outerHeight() ?? 50);
            h = h - ($("#kt_toolbar").outerHeight() ?? 50);
            h = h - 40;
        }
        boxHeightSet(h);

        var j = h - 120;
        $(".chat-techbox-items").each((i, el) => {
            j = j - ($(el).outerHeight() ?? 50)
            //j = j - ($(el).innerHeight() ?? 50)
        })
        jobsHeightSet(j);
    }

    useEffect(() => {
        fixSizes();
        window.addEventListener("resize", fixSizes);

        return () => {
            window.removeEventListener("resize", fixSizes);
        }

    }, [])


    const [tech, techSet] = useState<Technician>();
    const [jobs, jobsSet] = useState<Job[]>([]);
    const statuses = useStatusesForChatTechJobs();
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();

    useEffect(() => {
        if (!selectedStatus && statuses.length > 0) selectedStatusSet(statuses[0])
    }, [statuses])


    const fetchTech = () => {
        hub.Get(API_ROUTES.Technicians + props.chat?.tech?.id, res => {
            if (res.success) techSet(res.data);
        })
    }
    const fetchJobs = (stId: string) => {
        var q = "";
        if (stId) q = "?statusId=" + stId;
        hub.Get(API_ROUTES.Jobs + "techactivejobs/" + props.chat?.tech?.id + q, res => {
            if (res.success) jobsSet(res.data);
        })
    }


    useEffect(() => {
        if (props.chat?.tech?.id) {
            fetchTech();
        }
    }, [props.chat?.id])


    useEffect(() => {
        if (selectedStatus?.id) {
            fetchJobs(selectedStatus.id);
        }
    }, [props.chat?.id, selectedStatus])

    useEffect(() => {
        fixSizes();
    }, [tech])

    return (
        <>
            <div className="card" style={{
                height: boxHeight
            }}>
                <div className="card-body">

                    <FlexRow className="chat-techbox-items">
                        {
                            props.chat?.tech?.avatarUrl ?
                                <div className="symbol symbol-75px symbol-circle">
                                    <img alt={props.chat.tech?.fullName} src={props.chat.tech?.avatarUrl} />
                                </div>
                                :
                                <div className="symbol symbol-75px symbol-circle">
                                    {/* <span className="symbol-label bg-light-danger text-danger fw-bolder" style={{ fontSize: "30px" }}>{props.chat?.title?.substring(0, 1).toUpperCase()}</span> */}
                                    <ChatAvatarSpan item={props.chat} size={28} />
                                </div>
                        }

                        <div className="ms-2">
                            <div className="fs-2 fw-bolder text-gray-900 me-1 mb-2 lh-1">
                                {isDemo() ? "Tech " + props.chat?.title?.substring(0, 2).toUpperCase() : props.chat?.title}
                            </div>
                            <div className="fs-7 text-muted">
                                {isDemo() ? "+1 (222) 333 4455" : props.chat?.recipientPhone}
                            </div>
                        </div>

                    </FlexRow>

                    <hr className="chat-techbox-items" />

                    <div>
                        <FlexRow className="chat-techbox-items">
                            <div className="fw-bolder fs-6">Active Jobs</div>
                            <div className="fs-8 ms-auto cursor-pointer" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                <Icon icon="circle" type="solid" className="me-1" size={10} color={selectedStatus?.color ?? "#555"} />
                                <span>{selectedStatus?.name ?? "All Active Jobs"}</span>
                                <Icon icon="chevron-down" className="ms-2" size={10} />
                            </div>
                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-2" data-kt-menu="true">
                                {
                                    statuses?.map((st, k) =>
                                        <div key={k} className="menu-item">
                                            <a href="#" className="menu-link px-3"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    selectedStatusSet(st);
                                                }}>
                                                <Icon icon="circle" type="solid" className="me-2" size={10} color={st.color} />
                                                {st.name}
                                            </a>
                                        </div>
                                    )
                                }
                                {/* <div className="menu-item">
                                    <a href="#" className="menu-link px-3"
                                        onClick={e => {
                                            e.preventDefault();
                                            selectedStatusSet(undefined);
                                            fetchJobs();
                                        }}>
                                        <Icon icon="circle" type="solid" className="me-2" size={10} color="#555" />
                                        All Active Jobs
                                    </a>
                                </div> */}
                            </div>
                            {/* <select className="form-select form-select-sm ms-auto" style={{width: 120}}>
                                <option value="inprogress">In Progress</option>
                                <option value="appointments">Appointments</option>
                                <option value="all">All Active Jobs</option>
                            </select> */}
                        </FlexRow>

                        {
                            jobs.length > 0 ?
                                <div className="my-5 scroll-y"
                                    style={{
                                        height: jobsHeight
                                    }}>
                                    {
                                        jobs.map(job =>
                                            <ChatJobCard key={"chat-job-card-" + job.id}
                                                id={job.id}
                                                onEdit={j => {
                                                    openJobQuickView(j.id)
                                                }}
                                                onRemove={j => {
                                                    var arr = jobs ?? [];
                                                    var index = -1;
                                                    for (var i = 0; i < arr.length; i++) {
                                                        if (j.id == arr[i].id) {
                                                            index = i;
                                                            break;
                                                        }
                                                    }

                                                    if (index > -1) {
                                                        arr.splice(index, 1);
                                                        jobsSet(a => [...arr]);
                                                    }
                                                }}
                                            />
                                        )
                                    }
                                </div>
                                :
                                <div className="my-5 pt-10 text-center"
                                    style={{
                                        height: jobsHeight
                                    }}>
                                    There is no active job!
                                </div>
                        }
                    </div>

                    <hr className="chat-techbox-items" />

                    <div className="chat-techbox-items">
                        <h4>Chat Settings</h4>
                        <div className="pt-5 pb-5">
                            {
                                props.chat?.environment == ChatEnvironments.GSM ?
                                    <>
                                        <FlexRow className="mb-3">
                                            <Icon icon="comment-lines" className="me-2" type="solid" />
                                            Send By GSM
                                        </FlexRow>
                                        <FlexRow>
                                            <Icon icon="phone-rotary" className="me-2" type="solid" />
                                            <div className="fs-8">
                                                Sender: {props.chat?.senderPhone}
                                            </div>
                                        </FlexRow>
                                    </>
                                    : props.chat?.environment == ChatEnvironments.App ?
                                        <>
                                            <FlexRow>
                                                <Icon icon="mobile-alt" className="me-2" />
                                                Send By Rinvox App
                                            </FlexRow>
                                        </>
                                        : <></>
                            }

                        </div>
                    </div>


                </div>
            </div>


        </>
    )
}