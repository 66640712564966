import React from "react"
import Environment from '../../../env/env';
import UserMenu from "../header/UserMenu";
import Icon from "../../base/Icon";
import { createJob, getUser } from "../../../core/Global";
import { UserTypes } from "../../../core/Enums";
import DropdownMenu from "../../menu/DropdownMenu";


function AsideFooter() {
  const env = new Environment();
  const user = getUser()
  return (

    <div className="aside-footer flex-column-auto w-100" id="kt_aside_footer">
      <div className="w-100 text-center mb-10">
        <button className="btn btn-success btn-icon" onClick={e => {
          e.preventDefault()
          createJob()
        }}>
          <Icon icon="plus" type="light" size={24} />
        </button>

      </div>
    </div>

  );
}

export default AsideFooter;
