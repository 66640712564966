import React, { useEffect, useRef, useState } from "react";
import { renderToString } from 'react-dom/server'
import { getCountry, getUSA, isDarkMode, useCountries, useEnvironment, useHub } from "../../core/Global";
import { HereGeocodeAddress, HereGeocodeItem, HereGeocodePosition } from "../../models/MapModels";
import Icon from "../base/Icon";
import AddressField from "./AddressField"
import { JobMetroLayerTypes } from "src/core/Enums";
import Technician from "../../modules/technicians/models/technician";
import TechTooltip from "./TechTooltip";
import { API_ROUTES } from "../../core/Constants";
import JobMetro, { JobMetroLayer } from "../../modules/crm/base/models/JobMetro";
import Country from "../../modules/base/models/Country";

interface IMapProps {
  id: string
  build?: boolean
  clear?: boolean
  rebuild?: boolean
  update?: boolean
  center?: HereGeocodePosition
  draggableMarkerIcon?: "client" | "tech"
  draggableMarker?: HereGeocodePosition
  jobMarker?: HereGeocodePosition
  client?: HereGeocodeItem
  height?: number
  zoom?: number

  search?: boolean
  draw?: boolean
  countryId?: string
  country?: Country
  stateId?: string
  metroLayers?: JobMetroLayer[]
  metro?: JobMetro
  metros?: JobMetro[]
  defaultMetros?: boolean
  selectedMetro?: JobMetro
  editMetro?: JobMetro
  tech?: Technician
  techs?: Technician[]
  selectedTech?: Technician
  clearSearch?: boolean
  preventCenterizeOnLoad?: boolean
  onClientUpdated?: (data: HereGeocodeItem) => void
  onLayersUpdated?: (layers: JobMetroLayer[]) => void
  onMarkerUpdated?: (data: HereGeocodeItem) => void

  onZoomChanged?: (zoom: number) => void
  onCenterChanged?: (position: HereGeocodePosition) => void

  onSelectMetro?: (metro: JobMetro) => void
  onSelectTech?: (tech: Technician) => void
  onSearchSelected?: (item: HereGeocodeItem) => void
}

enum shapeTypes {
  None, Circle, Square, Polygon
}

const apiKey = 'MCXx2zgoEVwfGutJhRB1IPY3u4Kdaywi6FwnmNJx8Po';
const mapStyle = 'normal.day';
//const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${mapStyle}/{z}/{x}/{y}/512/png8?apiKey=${apiKey}&ppi=320`;
const tileUrlLight = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const tileUrlDark = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";


export default function Map(props: IMapProps) {

  //let map:any;
  let shape: any;
  let jobMarker: any;

  const L = window.GetLeafletInstance();
  const env = useEnvironment();
  const hub = useHub();

  const countries = useCountries();
  const [selectedCountry, selectedCountrySet] = useState<Country>(props.country ?? getUSA());

  const [initialized, initializedSet] = useState<boolean>(false)

  const [map, mapSet] = useState<any>();
  const [boundaryLayer, boundaryLayerSet] = useState<any>();
  const [zoom, zoomSet] = useState<number>(props.zoom ?? 6);

  const [shapeType, shapeTypeSet] = useState<shapeTypes>(shapeTypes.None);
  const [selectedMetro, selectedMetroSet] = useState<JobMetro>();
  const [selectedTech, selectedTechSet] = useState<Technician>();
  const [draggableMarker, draggableMarkerSet] = useState<any>();
  const [markersSize, markersSizeSet] = useState<number>(30);

  useEffect(() => {
    if (props.countryId) {
      var count = countries?.filter(f => f.id == props.countryId)[0];
      if (count) selectedCountrySet(count)
    }
  }, [props.countryId])

  useEffect(() => {
    if (props.country) selectedCountrySet(props.country)
  }, [props.country])



  const build = () => {

    if (map) return;
    draggableMarkerSet(undefined);
    shape = undefined;
    boundaryLayerSet(undefined);

    var mapOptions = {
      zoomControl: false,
      zoom: props.zoom ?? 6,
      center: props.center ?? [39.8097343, -98.5556199],
      layers: [L.tileLayer(isDarkMode() ? tileUrlDark : tileUrlLight, {
        opacity: .7
      })]
    };

    var m = L.map(props.id, mapOptions);
    m.attributionControl.addAttribution('&copy; Rinvox 2024');

    m.on("zoomend", (e: any) => {
      var z = e.target.getZoom();
      if (props.onZoomChanged) props.onZoomChanged(z);
      zoomSet(z);
    });

    if (props.onCenterChanged) props.onCenterChanged(props.center ?? {
      lat: 39.8097343,
      lng: -98.5556199
    });

    m.on("moveend", (e: any) => {
      if (props.onCenterChanged) {
        props.onCenterChanged(e.target.getCenter());
      }
    });


    mapSet(m);
  }
  const enableDraw = () => {
    var editableLayers = new L.FeatureGroup();
    map?.addLayer(editableLayers);

    if (props.editMetro?.layers && props.editMetro.layers.length > 0) {

      //if(props.onLayersUpdated) props.onLayersUpdated(props.editableLayers);

      props.editMetro.layers.forEach(layer => {
        if (layer.type == JobMetroLayerTypes.Circle) {
          var l = L.circle(layer.position, {
            radius: layer.circleRadius
          });
          editableLayers.addLayer(l);
        }

        if (layer.type == JobMetroLayerTypes.Rectangle) {
          var l = L.rectangle(layer.positions);
          editableLayers.addLayer(l);
        }

        if (layer.type == JobMetroLayerTypes.Polygon) {
          var l = L.polygon(layer.positions);
          editableLayers.addLayer(l);
        }

      });
    }

    var drawPluginOptions = {
      position: "topright",
      draw: {
        marker: false,
        polyline: false,
        circlemarker: false,
        circle: {
          allowIntersection: false,
          drawError: {
            color: "#e1e100",
            message:
              "<strong>Circle draw does not allow intersections!<strong> (allowIntersection: false)", // Message that will show when intersect
          },
          shapeOptions: {
            color: "#0206c2",
            weight: 3,
          }
        },
        polygon: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: "#e1e100", // Color the shape will turn when intersects
            message:
              "<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)", // Message that will show when intersect
          },
          shapeOptions: {
            color: "#028600",
            weight: 4,
          },
        },
        rectangle: {
          allowIntersection: false, // Restricts shapes to simple polygons
          drawError: {
            color: "#e1e100", // Color the shape will turn when intersects
            message:
              "<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)", // Message that will show when intersect
          },
          shapeOptions: {
            color: "#0286c2",
            weight: 4,
          },
        },
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: true,
      },
    };

    var drawControl = new L.Control.Draw(drawPluginOptions);
    map.addControl(drawControl);



    if (env.isDevelopment) console.log("L.Draw.Event", L.Draw.Event);

    map.on(L.Draw.Event.CREATED, function (e: any) {
      editableLayers.addLayer(e.layer);
      if (props.onLayersUpdated) props.onLayersUpdated(layersWithTypeNames(editableLayers.getLayers()));
    });

    map.on(L.Draw.Event.EDITED, function (e: any) {
      if (props.onLayersUpdated) props.onLayersUpdated(layersWithTypeNames(editableLayers.getLayers()));
    });

    map.on(L.Draw.Event.DELETED, function (e: any) {
      if (props.onLayersUpdated) props.onLayersUpdated(layersWithTypeNames(editableLayers.getLayers()));
    });

  }

  useEffect(() => {
    if (props.zoom) zoomSet(props.zoom)
  }, [props.zoom])

  useEffect(() => {
    if (!map) return;

    if (props.client?.position) {
      createClientMarker();
    }
    else if (props.client?.address?.countryId) {
      var c = getCountry(props.client.address.countryId)
      if (c) setCountryPosition(c)
    }

    if (initialized) return;
    if (props.center) {
      map.setView([props.center.lat, props.center.lng], props.zoom ?? 8)
      initializedSet(true)
    }
    else if (props.draggableMarker) createDraggableMarker()
    else if (selectedCountry) setCountryPosition(selectedCountry)

  }, [props.center, props.draggableMarker, props.client, selectedCountry, map])



  useEffect(() => {
    if (!initialized) return
    if (selectedCountry) setCountryPosition(selectedCountry)
  }, [selectedCountry])

  useEffect(() => {
    if (!initialized) return
    if (props.center) map.setView([props.center.lat, props.center.lng], props.zoom ?? 8)
  }, [props.center])

  useEffect(() => {
    if (!initialized) return
    if (props.draggableMarker) createDraggableMarker()
  }, [props.draggableMarker])






  const setCountryPosition = (count: Country) => {
    if (count.alpha2Code?.toLowerCase() == "us") {
      map.setView([39.8097343, -98.5556199], 4);
    }
    else if (count.alpha2Code?.toLowerCase() == "au") {
      map.setView([-23.79991207781832, 133.61022346000624], 5);
    }
    else if (count.alpha2Code?.toLowerCase() == "nz") {
      map.setView([-41.667682908454275, 173.41890734897805], 6);
    }
    else {
      hub.Get("/maps/geocode/country/" + count.alpha3Code, res => {
        if (res.success) {
          map.setView([res.data.position.lat, res.data.position.lng], 4);
        }
      })
    }
    initializedSet(true)
  }


  const [clientMarker, clientMarkerSet] = useState<any>();
  const createClientMarker = () => {

    if (map && props.client?.position) {

      var z = 5;
      if (props.client.zoom) z = props.client.zoom;
      else {
        if (props.client.address?.state) z += 1;
        if (props.client.address?.city) z += 2;
        if (props.client.address?.district || props.client.address?.county) z += 2;
        if (props.client.address?.street) z += 1;
        if (props.client.address?.houseNumber) {
          z += 2;
          if (z < zoom) z = zoom;
        }
      }
      zoomSet(z);
      map.setView([props.client.position?.lat, props.client.position?.lng], z);

      if (clientMarker) {
        var d = clientMarker;
        d.setLatLng([props.client.position?.lat, props.client.position?.lng], { draggable: 'true' });
        clientMarkerSet(d);
      }
      else {
        var d = L.marker([props.client.position?.lat, props.client.position?.lng], {
          icon: L.icon({
            iconUrl: `/assets/media/mapmarkers/client.png`,
            iconSize: [50, 50],
            iconAnchor: [25, 50],
          }),
          draggable: true
        });

        d.on('dragend', function (event: any) {
          var marker = event.target;
          var position = marker.getLatLng();
          map.panTo(new L.LatLng(position.lat, position.lng));

          hub.Get("/maps/geocode/reverse/?position=" + position.lat + "," + position.lng, res => {
            if (res.success && res.data) {
              if (props.onClientUpdated) props.onClientUpdated(res.data);
              // lookupResultSet(res.data);
              // addressTextSet(res.data.address?.label ?? "");
            }
          })

        });

        map.addLayer(d);
        clientMarkerSet(d);
        if (env.isDevelopment) console.log("Client Marker Created", d);
      }
    }
    else {
      clientMarkerSet(undefined);
    }
  }





  const addMetroLayers = () => {

    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.metroLayer) map.removeLayer(lay);
      });
    }

    if (map && props.metroLayers) {
      props.metroLayers.forEach(layer => {

        if (layer.type == JobMetroLayerTypes.Circle) {
          var l = L.circle(layer.position, {
            radius: layer.circleRadius,
            metroLayer: true
          });
          map.addLayer(l);
        }

        if (layer.type == JobMetroLayerTypes.Rectangle) {
          var l = L.rectangle(layer.positions, {
            metroLayer: true
          });
          map.addLayer(l);
        }

        if (layer.type == JobMetroLayerTypes.Polygon) {
          var l = L.polygon(layer.positions, {
            metroLayer: true
          });
          map.addLayer(l);
        }


      });

    }

  }

  const addMetros = () => {

    if (!map || !props.metros) return;

    map.eachLayer((lay: any) => {
      if (lay.options.metroLayer) map.removeLayer(lay);
    });


    props.metros.forEach(mtr => {

      mtr.layers?.forEach(layer => {

        var l;
        if (layer.type == JobMetroLayerTypes.Circle) {
          l = L.circle(layer.position, {
            radius: layer.circleRadius,
            metroLayer: true,
            metro: mtr,
            fillColor: mtr.color,
            fillOpacity: .1,
            color: mtr.color,
            opacity: .1
          }).bindTooltip(mtr.name);
        }

        if (layer.type == JobMetroLayerTypes.Rectangle) {
          l = L.rectangle(layer.positions, {
            metroLayer: true,
            metro: mtr,
            fillColor: mtr.color,
            fillOpacity: .1,
            color: mtr.color,
            opacity: .1
          }).bindTooltip(mtr.name);
        }

        if (layer.type == JobMetroLayerTypes.Polygon) {
          l = L.polygon(layer.positions, {
            metroLayer: true,
            metro: mtr,
            fillColor: mtr.color,
            fillOpacity: .1,
            color: mtr.color,
            opacity: .1
          }).bindTooltip(mtr.name);
        }

        if (l) {
          l.on("click", function (e: any) {
            selectedMetroSet(e.target.options.metro);
            if (props.onSelectMetro) props.onSelectMetro(e.target.options.metro);
          })
          map.addLayer(l);
        }

      });

    });

  }

  const addMetro = () => {
    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.metroLayer) map.removeLayer(lay);
      });
    }

    if (map && props.metro) {

      props.metro.layers?.forEach(layer => {

        var l;
        if (layer.type == JobMetroLayerTypes.Circle) {
          l = L.circle(layer.position, {
            radius: layer.circleRadius,
            metroLayer: true,
            metro: props.metro,
            fillColor: "#0A6EBD",
            fillOpacity: .2,
            color: "#7895CB"
          });
        }

        if (layer.type == JobMetroLayerTypes.Rectangle) {
          l = L.rectangle(layer.positions, {
            metroLayer: true,
            metro: props.metro,
            fillColor: "#0A6EBD",
            fillOpacity: .2,
            color: "#7895CB"
          });
        }

        if (layer.type == JobMetroLayerTypes.Polygon) {
          l = L.polygon(layer.positions, {
            metroLayer: true,
            metro: props.metro,
            fillColor: "#0A6EBD",
            fillOpacity: .2,
            color: "#7895CB"
          });
        }

        if (l) {
          map.addLayer(l);
        }

      });

    }

  }

  const removeMetro = () => {
    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.metroLayer) map.removeLayer(lay);
      });
    }

  }

  const addDefaultMetros = (metros?: JobMetro[]) => {
    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.defaultMetro) map.removeLayer(lay);
      });
    }

    if (map && metros) {

      metros.forEach(mtr => {

        mtr.layers?.forEach(layer => {

          var tooltipClass = zoom < 10 ? "metroname-tooltip-small" : "metroname-tooltip-large"

          var l;
          if (layer.type == JobMetroLayerTypes.Circle) {
            l = L.circle(layer.position, {
              radius: layer.circleRadius,
              defaultMetro: true,
              metro: mtr,
              fillColor: mtr.color,
              fillOpacity: .05,
              color: mtr.color,
              opacity: .3
            })
              .bindTooltip(mtr.name, { sticky: true, permanent: true, direction: "center", className: tooltipClass }).openTooltip()
          }

          if (layer.type == JobMetroLayerTypes.Rectangle) {
            l = L.rectangle(layer.positions, {
              defaultMetro: true,
              metro: mtr,
              fillColor: mtr.color,
              fillOpacity: .05,
              color: mtr.color,
              opacity: .3
            })
              .bindTooltip(mtr.name)
          }

          if (layer.type == JobMetroLayerTypes.Polygon) {
            l = L.polygon(layer.positions, {
              defaultMetro: true,
              metro: mtr,
              fillColor: mtr.color,
              fillOpacity: .05,
              color: mtr.color,
              opacity: .3
            })
              .bindTooltip(mtr.name)
          }

          if (l) {
            l.on("mouseover", function (e: any) {
              //e.layer.openTooltip();
            });
            map.addLayer(l);
          }


        });

      });

    }

  }

  const addTechs = () => {

    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.techMarker) map.removeLayer(lay);
      });
    }

    if (map && props.techs) {
      if (env.isDevelopment) console.log("bind tech markers!", props.techs);

      props.techs.forEach(tech => {
        if (tech.techWakeupLocation) {

          var t = L.marker(tech.techWakeupLocation, {
            icon: L.icon({
              iconUrl: (tech.activeJobs ?? 0) > 0 ? `/assets/media/mapmarkers/tech-busy.png` : `/assets/media/mapmarkers/tech.png`,
              iconSize: [markersSize, markersSize],
              iconAnchor: [(markersSize / 2), 0],
            }),
            draggable: false,
            techMarker: true,
            tech: tech
          })
            .addTo(map)
            .bindPopup(renderToString(<TechTooltip tech={tech} />));

          t.on('mouseover', function (e: any) {
            e.target.openPopup();
          });
          t.on('mouseout', function (e: any) {
            e.target.closePopup();
          });
          t.off('click');
          t.on('click', function (e: any) {
            e.target.openPopup();
            selectedTechSet(e.target.options.tech);
            if (props.onSelectTech) props.onSelectTech(e.target.options.tech);
          });

          if (env.isDevelopment) console.log("tech marker binded!", t);

        }
      });
    }

  }

  const addTech = () => {

    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.techMarker) map.removeLayer(lay);
      });
    }

    if (map && props.tech?.techWakeupLocation) {

      var t = L.marker(props.tech.techWakeupLocation, {
        icon: L.icon({
          iconUrl: `/assets/media/mapmarkers/tech.png`,
          iconSize: [markersSize, markersSize],
          iconAnchor: [(markersSize / 2), 0]
        }),
        draggable: false,
        techMarker: true,
        tech: props.tech
      })
        .addTo(map)
        .bindPopup(renderToString(<TechTooltip tech={props.tech} />));

    }

  }

  const removeTech = () => {
    if (map) {
      map.eachLayer((lay: any) => {
        if (lay.options.techMarker) map.removeLayer(lay);
      });
    }
  }

  const layersWithTypeNames = (layers: any[]) => {
    var result: JobMetroLayer[] = [];
    layers.forEach(layer => {
      var item: JobMetroLayer = {
        type: JobMetroLayerTypes.None,
        circleRadius: 0,
        position: null,
        positions: null
      };

      if (layer instanceof L.Circle) {
        item.type = JobMetroLayerTypes.Circle;
        item.circleRadius = layer.getRadius();
        item.position = layer.getLatLng();
      }
      if (layer instanceof L.Polygon) {
        item.type = JobMetroLayerTypes.Polygon;
        item.positions = layer.getLatLngs()[0];
      }
      if (layer instanceof L.Rectangle) {
        item.type = JobMetroLayerTypes.Rectangle;
        item.positions = layer.getLatLngs()[0];
      }

      result.push(item);
    });

    console.log(layers, result);
    return result;
  }

  const resize = () => {
    //if(map) map.getViewPort().resize();
  }

  const drawBoundry = (address: HereGeocodeAddress) => {
    var url = `https://nominatim.openstreetmap.org/search.php?country=usa&state=${address.state}&polygon_geojson=1&format=jsonv2`;
    fetch(url).then(function (response) {
      return response.json();
    })
      .then(function (json) {
        if (boundaryLayer) map.removeLayer(boundaryLayer);

        if (json && json.length > 0) {
          var geojsonFeature = json[0].geojson;
          var lay = L.geoJSON(geojsonFeature, {
            style: {
              fillColor: 'transparent',
              weight: 2,
              opacity: .7,
              color: '#A02334',  //Outline color
              fillOpacity: 0,
            }
          }).addTo(map);

          boundaryLayerSet(lay);
        }

      });
  }

  const createDraggableMarker = () => {

    if (map && props.draggableMarker) {

      if (draggableMarker) {
        var d = draggableMarker;
        d.setLatLng(props.draggableMarker, { draggable: 'true' });
        draggableMarkerSet(d);
      }
      else {
        var d = L.marker(props.draggableMarker, {
          icon: L.icon({
            iconUrl: `/assets/media/mapmarkers/${props.draggableMarkerIcon ?? "client"}.png`,
            iconSize: [50, 50],
            iconAnchor: [25, 50],
          }),
          draggable: true
        });

        d.on('dragend', function (event: any) {
          var marker = event.target;
          var position = marker.getLatLng();
          map.panTo(new L.LatLng(position.lat, position.lng));

          if (props.onMarkerUpdated) {
            hub.ReversePosition(position, e => {
              if (e) props.onMarkerUpdated!(e)
            })
          }

        });

        map.addLayer(d);
        draggableMarkerSet(d);
      }


      var z = (!initialized && props.zoom) ? props.zoom : zoom < 14 ? 14 : zoom;

      map.setView([props.draggableMarker.lat, props.draggableMarker.lng], z)

      initializedSet(true)

    }
    else {
      draggableMarkerSet(undefined);
    }
  }

  const createJobMarker = () => {
    if (env.isDevelopment) console.log("createJobMarker", jobMarker, props.jobMarker, map);
    if (map && props.jobMarker) {
      if (jobMarker) {
        jobMarker.setLatLng(props.jobMarker);
      }
      else {
        jobMarker = L.marker(props.jobMarker, {
          icon: L.icon({
            iconUrl: `/assets/media/mapmarkers/client.png`,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
          }),
          draggable: false
        });

        map.addLayer(jobMarker);
      }
    }
    else {
      jobMarker = undefined;
    }
  }

  useEffect(() => {
    if (map) {
      map.eachLayer(function (lay: any) {
        if (lay.options?.defaultMetro) {

          if (zoom < 7) {
            if (lay.getTooltip()) lay.unbindTooltip();
          }
          else if (zoom < 10) {
            if (lay.getTooltip()) lay.unbindTooltip();
            if (!lay.getTooltip()) lay.bindTooltip(lay.options?.metro?.name, { sticky: true, permanent: true, direction: "center", className: "metroname-tooltip-small" }).openTooltip()
          }
          else {
            if (lay.getTooltip()) lay.unbindTooltip();
            if (!lay.getTooltip()) lay.bindTooltip(lay.options?.metro?.name, { sticky: true, permanent: true, direction: "center", className: "metroname-tooltip-large" }).openTooltip()
          }
        }
      })

      if (zoom > 13) markersSizeSet(50);
      else if (zoom > 8) markersSizeSet(40);
      else markersSizeSet(30);

    }

  }, [zoom, map])












  useEffect(() => {
    createJobMarker();
  }, [props.jobMarker, map]);






  useEffect(() => {
    if (props.build == undefined || props.build) {
      build();
    }
  }, [props.build]);

  useEffect(() => {
    if (props.draw && map) enableDraw();
  }, [props.draw, map])

  useEffect(() => {
    if (props.metroLayers && map) addMetroLayers();
  }, [props.metroLayers, map])

  useEffect(() => {
    if (map && props.metros) {
      if (env.isDevelopment) console.log("map addMetros", props.metros);
      addMetros();
    }
  }, [props.metros, map])

  useEffect(() => {
    if (props.selectedMetro && map) {
      selectedMetroSet(props.selectedMetro);
    }

  }, [props.selectedMetro, map])

  useEffect(() => {
    if (env.isDevelopment) console.log("selectedMetro changed", selectedMetro);
    if (selectedMetro && map) {
      map.eachLayer((lay: any) => {
        if (lay?.options?.metroLayer) {
          if (lay.options?.metro?.id == selectedMetro?.id) lay.setStyle({
            opacity: .3,
            fillOpacity: .3,
          });
          else lay.setStyle({
            opacity: .1,
            fillOpacity: .1,
          })
        }
      });
    }
  }, [selectedMetro, map])

  useEffect(() => {
    if (props.techs && map) {
      addTechs();
    }
  }, [props.techs, map])

  useEffect(() => {
    if (map) {
      if (props.tech) {
        addTech();
      }
      else {
        removeTech();
      }
    }
  }, [props.tech, map])

  useEffect(() => {
    if (map) {
      if (props.metro) {
        addMetro();
      }
      else {
        //removeMetro();
      }
    }
  }, [props.metro, map])

  useEffect(() => {
    selectedTechSet(props.selectedTech);
  }, [props.selectedTech])

  useEffect(() => {
    if (env.isDevelopment) console.log("selectedTech changed", selectedTech);
    if (map) {

      map.eachLayer((lay: any) => {
        if (lay.options.techMarker) {
          if (lay.options?.tech?.id == selectedTech?.id) {
            lay.setIcon(L.icon({
              iconUrl: (lay.options?.tech?.activeJobs ?? 0) > 0 ? `/assets/media/mapmarkers/tech-busy-selected.png` : `/assets/media/mapmarkers/tech-selected.png`,
              iconSize: [markersSize, markersSize],
              iconAnchor: [(markersSize / 2), 0],
            }))
          }
          else {
            lay.setIcon(L.icon({
              iconUrl: (lay.options?.tech?.activeJobs ?? 0) > 0 ? `/assets/media/mapmarkers/tech-busy.png` : `/assets/media/mapmarkers/tech.png`,
              iconSize: [markersSize, markersSize],
              iconAnchor: [(markersSize / 2), 0],
            }))
          }
        }
      });

    }
  }, [selectedTech, markersSize, map])

  useEffect(() => {
    if (props.clear) {
      map?.remove();
      mapSet(undefined);
    }
  }, [props.clear])

  useEffect(() => {
    if (props.rebuild) {
      map?.remove();
      mapSet(undefined);
      boundaryLayerSet(undefined);
      draggableMarkerSet(undefined);
      setTimeout(() => {
        build();
        if (props.draw) enableDraw();
        if (props.metroLayers) addMetroLayers();
      }, 200);
    }
  }, [props.rebuild]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
      map?.remove();
      mapSet(undefined);
    }
  }, [])




  useEffect(() => {
    if (map && props.stateId) {
      hub.Get("/maps/geocode/state/" + props.stateId, res => {
        if (res.success) {

          if (!props.center) {
            //map.setView([res.data.position.lat, res.data.position.lng], 6);
            //movingSet(true);
          }

          if (res.data.address) drawBoundry(res.data.address);

          if (props.defaultMetros) {
            var q = "?stateId=" + props.stateId;
            if (props.editMetro) q += "&excludeId=" + props.editMetro.id
            hub.Get(API_ROUTES.JobMetrosAll + q, res => {
              if (res.success) addDefaultMetros(res.data);
            })
          }

        }
      })
    }
  }, [props.stateId, map])


  return (
    <div style={{
      position: "relative"
    }}>
      <div style={{
        width: '100%',
        height: props.height ?? '100%'
      }}
        id={props.id}
      ></div>

      {
        props.search &&
        <AddressField id="map-search" raw width="50%" placeholder="Search an address..."
          absolutePosition top={10} left={10} zIndex={99} small reset={props.clearSearch}
          country={selectedCountry}
          onSelect={item => {
            console.log("on map search select", item);
            if (props.onSearchSelected) props.onSearchSelected(item);
            else if (map && item.position) {
              var zoom = 12;
              if (item.address?.district) zoom++;
              if (item.address?.street) {
                zoom++;
                zoom++;
              }
              if (item.address?.houseNumber) {
                zoom++;
                zoom++;
              }
              map.setView([item.position.lat, item.position.lng], zoom);


            }
          }} />
      }

      {
        props.draw && false &&
        <div className="btn-group btn-group-sm" role="group"
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 99
          }}>
          <button type="button" className={"btn btn-light btn-hover-secondary btn-active-primary p-2 px-4" + (shapeType == shapeTypes.Circle ? " active" : "")}
            onClick={e => {
              if (shapeType == shapeTypes.Circle) return;
              shapeTypeSet(shapeTypes.Circle);
              map?.removeObjects(map.getObjects());
              //shape = window.createHereCircleShape(map);
              if (env.isDevelopment) console.log("shape created!", shape);
              map?.addObject(shape);
            }}>
            <Icon icon="draw-circle" type="light" />
          </button>
          <button type="button" className={"btn btn-light btn-hover-secondary btn-active-primary btn-active-primary p-2 px-4" + (shapeType == shapeTypes.Square ? " active" : "")}
            onClick={e => {
              if (shapeType == shapeTypes.Square) return;
              shapeTypeSet(shapeTypes.Square);
              map?.removeObjects(map.getObjects());
              //shape = window.createHereRectShape(map, behavior);
              if (env.isDevelopment) console.log("shape created!", shape);
              map?.addObject(shape);
            }}>
            <Icon icon="draw-square" type="light" />
          </button>
          <button type="button" className={"btn btn-light btn-hover-secondary btn-active-primary p-2 px-4" + (shapeType == shapeTypes.Polygon ? " active" : "")}
            onClick={e => {
              shapeTypeSet(shapeTypes.Polygon);
              map?.removeObjects(map.getObjects());
            }}>
            <Icon icon="draw-polygon" type="light" />
          </button>
        </div>
      }

    </div>
  )
}