import React, { useEffect, useState } from "react";
import Icon from "../base/Icon";
import { usePreferences } from "../../core/Global";
import { TimeFormats, TimePickerSteps } from "../../core/Enums";

interface TextBoxProps {
    id: string
    label?: string
    labelUp?: boolean
    clear?: boolean
    small?: boolean
    solid?: boolean
    placeholder?: string
    className?: string
    name?: string
    parentId?: string
    defaultValue?: string
    defaultTime?: number
    value?: string
    required?: boolean
    autoFocus?: boolean
    maxLength?: number
    timeInput?: boolean
    marginBottom?: number
    noMarginBottom?: boolean
    readOnly?: boolean
    block?: boolean
    listUp?: boolean
    working?: boolean
    noTextError?: boolean
    nextDay?: boolean
    min?: number
    max?: number
    time?: number
    timeIsNextDay?: boolean

    onTimePicked?: (t: TimeItem) => void
    onTimeCleared?: () => void
    onClear?: () => void
}

export interface TimeItem {
    time: number
    label: string
    label12: string
    nextDay: boolean
}


export default function TimePicker(props: TextBoxProps) {

    const pref = usePreferences()
    let element: HTMLInputElement | null;
    let container: HTMLDivElement | null;

    const [defaultValueLoaded, defaultValueLoadedSet] = useState<boolean>(false);

    const [showList, showListSet] = useState<boolean>();
    const [selectedIndex, selectedIndexSet] = useState<number>(0);

    const [offset, offsetSet] = useState<any>({ top: 0, left: 0 });
    const [width, widthSet] = useState<any>();
    const [height, heightSet] = useState<any>();

    const [tms, tmsSet] = useState<number[]>([]);
    const [times, timesSet] = useState<TimeItem[]>([]);
    //const [selectedTime, selectedTimeSet] = useState<TimeItem>();
    const [text, textSet] = useState<string>("")
    const [val, valSet] = useState<string>("")

    const className = () => {
        var cls = "form-control form-inputs " + (props.id + "-timepicker-item");
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        if (props.autoFocus) cls += " auto-focus";
        if (props.className) cls += " " + props.className;
        return cls;
    };

    const keypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (text && text.length == 2) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter" && e.key != ":") {
                e.preventDefault();
            }
        }
        else {
            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                e.preventDefault();
            }
        }
    }

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key == "ArrowDown") {
            e.preventDefault();
            handleArrowDown();
        }
        else if (e.key == "ArrowUp") {
            e.preventDefault();
            handleArrowUp();
        }
        else if (e.key == "Enter") {
            e.preventDefault();
            handleEnter();
        }
        else if (e.key == "Tab") {
            showListSet(false);
        }
    }

    const handleArrowDown = () => {
        showListSet(true);
        if (times && times.length > 0) {
            var s = selectedIndex + 1;
            if (s >= times.length) s = times.length - 1;
            selectedIndexSet(s);
            scrollItems(s);

        }
    }

    const handleArrowUp = () => {
        var s = selectedIndex - 1;
        if (s < 0) s = 0;
        selectedIndexSet(s);
        scrollItems(s);
    }

    const handleEnter = () => {
        selectTime(selectedIndex);
    }

    const selectTime = (i: number) => {
        var t = times[i];
        if (pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours) textSet(t.label12)
        else textSet(t.label)
        valSet(t.label)
        showListSet(false);
        if (props.onTimePicked) props.onTimePicked(times[i]);
    }

    const toTime = (totalMinutes: number) => {
        if (totalMinutes >= 1440) totalMinutes -= 1440;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    }

    const to12HoursTime = (totalMinutes: number) => {
        if (totalMinutes >= 1440) totalMinutes -= 1440;
        var hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        var amPM = "AM";
        if (hours > 11) amPM = "PM";
        if (hours > 12) hours -= 12;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)} ${amPM}`;
    }

    const padToTwoDigits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    const change = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (props.name) {
            $("input[name=" + props.name + "]").removeClass("is-invalid");
            $("#" + props.name + "-err").hide();
        }

        setValue(e.target.value);

    }

    const setValue = (val?: string) => {

        var txt = val ?? "";

        if (txt == "") {
            if (props.onTimeCleared) props.onTimeCleared()

            return;
        }

        if (txt.length < 3) {
            var h = Number.parseInt(txt);
            var i = tms.indexOf((h * 60));
            scrollItems(i);
            selectedIndexSet(i);
        }

        if (txt.length > 5) return;

        if (txt.length == 3) {
            var hh = Number.parseInt(txt[0] + txt[1]);
            if (hh > 23) hh = 23;
            if (txt[2] != ":") {
                txt = (hh < 10 ? "0" : "") + hh + ":" + txt[2];
            }
        }

        if (txt.length == 5) {
            var hh = Number.parseInt(txt[0] + txt[1]);
            if (hh > 23) hh = 23;
            var mm = Number.parseInt(txt[3] + txt[4]);
            if (mm > 59) mm = 59;
            txt = (hh < 10 ? "0" : "") + hh + ":" + (mm < 10 ? "0" : "") + mm;

            var t = (hh * 60) + mm;
            var time = {
                time: t,
                label: toTime(t),
                label12: to12HoursTime(t),
                nextDay: false
            }

            if (pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours) textSet(time.label12)
            else textSet(time.label)
            valSet(time.label)

            if (props.onTimePicked) props.onTimePicked(time);
            showListSet(false);
        }
        else {
            if (props.onClear) props.onClear();
            textSet(txt);
        }


    }

    const scrollItems = (s: number) => {
        var t = document.getElementById(props.id + "-timer-item-" + (s - 2))?.offsetTop;
        document.getElementById(props.id + "-items-layer")?.scrollTo({
            top: t
        })
    }

    const focus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        showListSet(true);
    }

    const blur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        // setTimeout(() => {
        //     showListSet(false);
        // }, 250);
    }

    const onWindowClick = (e: any) => {
        if ($(e.target).hasClass(props.id + "-timepicker-item")) return;
        showListSet(false);
    }

    const onWindowResize = () => {
        offsetSet($("#" + props.id).offset());
    }

    const fixPositoins = () => {

        //console.log("fix position", props.id, $("#" + props.id + "-container").offset());
        offsetSet($("#" + props.id + "-container").offset());
        // offsetSet({
        //     top: container?.offsetTop,
        //     left: container?.offsetLeft
        // });
        widthSet($("#" + props.id + "-container").width());
        heightSet($("#" + props.id + "-container").height());
    }

    const fetchTimes = () => {
        var t: TimeItem[] = [];
        var tm = [];
        var min = props.min ?? 0;
        var max = props.max ?? (props.nextDay ? 2880 : 1440);

        // if (props.min && props.min > 0) {
        //     var x = props.min % 15;
        //     min = props.min + (15 - x);
        // }

        // if (props.max && props.max > 0) {
        //     var x = props.max % 15;
        //     max = props.max + (15 - x);
        // }

        const steps = (() => {
            switch (pref?.systemPreferences.timePickersSteps) {
                case TimePickerSteps.Every5Mins: return 5;
                case TimePickerSteps.Every10Mins: return 10;
                case TimePickerSteps.Every15Mins: return 15;
                case TimePickerSteps.Every30Mins: return 30;
                default: return 15;
            }
        })();

        for (var i = min; i < max; i = i + steps) {
            t.push({
                time: i,
                label: toTime(i),
                label12: to12HoursTime(i),
                nextDay: i >= 1440
            });
            tm.push(i);
        }



        // if (props.nextDay) {
        //     for (var i = 1440; i < 2880; i = i + 15) {
        //         t.push({
        //             time: i,
        //             label: toTime(i - 1440),
        //             nextDay: true
        //         });
        //         tm.push(i + 1440);
        //     }
        // }

        timesSet(t);
        tmsSet(tm);
    }

    useEffect(() => {

        fixPositoins();

        var parentModal = $("#" + props.id + "-container").parents(".modal-main-container");
        if (parentModal.length > 0) {
            window.onModalOpen(parentModal.attr("id") ?? "", () => {
                fixPositoins();
            });
        }

        window.addEventListener("click", onWindowClick);
        window.addEventListener("resize", onWindowResize);
        window.addEventListener("scroll", onWindowResize);

        return () => {
            window.removeEventListener("click", onWindowClick);
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener("scroll", onWindowResize);
        }

    }, [])

    useEffect(() => {

        fetchTimes();

    }, [props.min, props.max, props.nextDay])



    useEffect(() => {
        if (props.defaultValue && !defaultValueLoaded) {
            setValue(props.defaultValue);
            defaultValueLoadedSet(true);
        }
    }, [props.defaultValue])

    // useEffect(() => {
    //     setValue(props.value);
    // }, [props.value])


    useEffect(() => {
        if (props.time && props.time > 0) {
            var time = props.time;
            if (time >= 1440) time = 1439;

            var t: TimeItem = {
                time: time,
                label: toTime(time),
                label12: to12HoursTime(time),
                nextDay: props.timeIsNextDay ?? false
            };

            if (pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours) textSet(t.label12)
            else textSet(t.label)
            valSet(t.label)

            if (props.onTimePicked) {
                props.onTimePicked(t);
            }
        }
    }, [props.time])

    useEffect(() => {
        if (showList) {
            var t = document.getElementById(props.id + "-timer-item-" + (selectedIndex - 2))?.offsetTop;
            document.getElementById(props.id + "-items-layer")?.scrollTo({
                top: t
            })

            fixPositoins();
        }
    }, [showList])

    useEffect(() => {
        if (props.clear) {
            textSet("")
            valSet("")
            defaultValueLoadedSet(false);
        }
    }, [props.clear])

    useEffect(() => {
        window.hideFormError(props.name, props.parentId);
    }, [text])





    return (
        <div className={"row align-items-center" + " " + (props.id + "-timepicker-item") + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 5))}>
            {
                props.label &&
                <label className={"col col-12 " + (props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2")) + " fs-7"} htmlFor={props.id}>
                    {props.label}
                    {
                        props.labelUp && !props.noTextError && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                    }
                </label>
            }
            <div className={"col col-12 " + " " + (props.id + "-timepicker-item") + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                <div id={props.id + "-container"} ref={e => container = e}
                    className={" " + (props.id + "-timepicker-item")} style={{
                        position: "relative"
                    }}>
                    <input id={props.id}
                        ref={e => element = e}
                        type="tel"
                        autoComplete="off"
                        className={className()}
                        onKeyPress={keypress}
                        onKeyDown={keyDown}
                        autoFocus={props.autoFocus}
                        maxLength={props.maxLength}
                        value={text}
                        placeholder={props.placeholder ?? "08:30"}
                        readOnly={props.readOnly}
                        onClick={e => showListSet(true)}
                        onChange={change}
                        onFocus={focus}
                        onBlur={blur}
                    />
                    <input type="hidden" name={props.name} value={val} />
                    {
                        text != "" &&
                        <a href="#" style={{
                            position: "absolute",
                            right: 16,
                            top: "25%"
                        }} onClick={e => {
                            e.preventDefault();
                            textSet("")
                            valSet("")
                            if (props.onClear) props.onClear();
                        }}>
                            <Icon icon="times" color="#5e6278" size={14} />
                        </a>
                    }
                    {
                        showList &&
                        <div id={props.id + "-items-layer"} className={"address-field-autocomplete " + (props.id + "-timepicker-item")}
                            style={{
                                position: "fixed",
                                width: width,
                                left: offset.left,
                                top: offset.top + height + 2
                            }}>
                            {
                                times.map((t, k) =>
                                    <div key={k} id={props.id + "-timer-item-" + k} className={"address-field-autocomplete-item timer-autocomplete-item" + (selectedIndex == k ? " selected" : "") + " " + (props.id + "-timepicker-item")}
                                        onMouseOver={e => {
                                            selectedIndexSet(k);
                                        }}
                                        onClick={e => {
                                            e.preventDefault();
                                            selectTime(k);
                                        }}>
                                        {t.nextDay && <Icon icon="chevron-right" className="me-2" />}
                                        {pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours ? t.label12 : t.label}
                                    </div>
                                )
                            }

                        </div>
                    }

                </div>
            </div>
            {
                props.block && props.label && false &&
                <div className="col col-12 col-md-3"></div>
            }
            {
                !props.labelUp && !props.noTextError &&
                <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                    <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                </div>
            }

        </div>
    )

}