import { Link } from "react-router-dom"
import User from "../../modules/accounts/models/User"
import FlexRow from "../base/FlexRow"

type Props = {
    userId?: string
    model?: User
}

export default function AccountTechProfileCard(props: Props) {




    return (
        <div className="card" id="kt_profile_details_view">
            <div className="card-header">

                <h3 className="card-title">
                    <span className="card-label fw-bolder text-dark">Technician Details</span>
                </h3>

                <Link to={`/accounts/manage/${props.userId}/techdetails`} className="btn btn-primary btn-sm align-self-center">Edit Details</Link>

            </div>
            <div className="card-body p-9"
                style={{
                    minHeight: 400
                }}>

                <div className="mb-8">
                    <label className="fw-bolder fs-5 mb-4">
                        Skills
                    </label>
                    <FlexRow wrap>
                        {
                            props.model?.techJobTypesNames?.slice(0, 10).map((t, k) =>
                                <span key={k} className="badge badge-secondary fs-8 me-2 mb-2">{t}</span>
                            )
                        }
                        {
                            (props.model?.techJobTypesNames?.length ?? 0) > 10 &&
                            <span className="badge badge-dark fs-8 me-2 mb-2">
                                {(props.model?.techJobTypesNames?.length ?? 10) - 10} More...
                            </span>
                        }
                    </FlexRow>
                </div>
                <div className="mb-8">
                    <label className="fw-bolder fs-5 mb-4">
                        Sources
                    </label>
                    <FlexRow wrap>
                        {
                            props.model?.techJobSourcesNames?.slice(0, 10).map((t, k) =>
                                <span key={k} className="badge badge-secondary fs-8 me-2 mb-2">{t}</span>
                            )
                        }
                        {
                            (props.model?.techJobSourcesNames?.length ?? 0) > 10 &&
                            <span className="badge badge-dark fs-8 me-2 mb-2">
                                {(props.model?.techJobSourcesNames?.length ?? 10) - 10} More...
                            </span>
                        }
                    </FlexRow>
                </div>
                <div>
                    <label className="fw-bolder fs-5 mb-4">
                        Metro
                    </label>
                    <FlexRow wrap>
                        {
                            props.model?.techMetros?.slice(0, 10).map((t, k) =>
                                <span key={k} className="badge badge-secondary fs-8 me-2 mb-2 text-white" style={{
                                    backgroundColor: t.color
                                }}>{t.name}</span>
                            )
                        }
                        {
                            (props.model?.techMetros?.length ?? 0) > 10 &&
                            <span className="badge badge-dark fs-8 me-2 mb-2">
                                {(props.model?.techMetros?.length ?? 10) - 10} More...
                            </span>
                        }
                    </FlexRow>
                    {/* {
                        props.model?.techMetros &&
                        <div>
                            {props.model?.techStateName} &gt; {props.model?.techMetros[0]?.name}
                        </div>
                    } */}

                </div>
            </div>
        </div>
    )
}