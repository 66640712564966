import React from "react";
import Icon from "../base/Icon";
type FormTitleProps = {
    title?: string
    color?: string
    margin?: number
    className?: string
    sizeClass?: string
}
export default function FormTitle(props: FormTitleProps) {
    return (
        <div className={"row " + props.className}>
            <div className={`col my-${props.margin ?? 10} border-bottom`}>
                <h3 className={props.sizeClass}>
                    {
                        props.color && <Icon icon="circle" type="solid" color={props.color} className="me-2" size={18} />
                    }
                    {props.title}
                </h3>
            </div>
        </div>

        
    )
}