import { toastDefault, toastError } from "../components/base/Toast";


export const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const copyToClipboard = async (text?: string, message?: string): Promise<boolean> => {
    try {
        if (!text) return false;
        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            await navigator.clipboard.writeText(text)
            if (message) toastDefault(message)
            return true;
        } else {
            const textArea = document.createElement('textarea')
            textArea.value = text
            textArea.style.position = 'fixed'
            textArea.style.opacity = '0'
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()

            const successful = document.execCommand('copy')
            document.body.removeChild(textArea)

            if (successful) {
                if (message) toastDefault(message)
                return true;
            }
            else return false;
        }
    } catch (err) {
        //toastError("Can't copy the job because it's not supported in your browser!")
        return false;
    }
}

export const convertTo12HourFormat = (time24?: string, hidePeriod?: boolean): string => {
    if (!time24) return "";
    const [hourStr, minute] = time24.split(":");
    const hour = parseInt(hourStr, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    if (hidePeriod) return `${hour12}:${minute}`;
    return `${hour12}:${minute} ${period}`;
};