import { Validator } from '../../../core/Types';

export const LoginValidator: Validator[] = [
    {
        name: "username",
        required: true
    },
    {
        name: "password",
        required: true
    },
 ];