import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { setPage, useGlobal } from '../../../core/Global';
import ProfileDetails from "../components/ProfileDetails";
import ProfileHeader from "../components/ProfileHeader";
import MobileApp from '../components/MobileApp';
import QuickAccess from "../components/QuickAccess";
import FlexRow from "../../../components/base/FlexRow";



function ProfileOverview() {

    const [globalState] = useGlobal();

  return (
    <div className="row">
      <div className="col col-12">
        <ProfileHeader />
      </div>
      <div className="col col-md-8 col-xxxl-5">
        <ProfileDetails />
      </div>
      <div className="col col-md-4 col-xxxl-3">
        <QuickAccess />
      </div>
    </div>
  );
}

export default ProfileOverview;
