import { useEffect, useState } from "react"
import Indicator from "../base/Indicator";

interface IProps {
    loading?: boolean
    events?: CalendarEventItem[]
    onChange?: (e:any) => void
    onEventClick?: (e:any) => void
}

export interface CalendarEventItem {
    id?: string
    title?: string
    start?: string
    backgroundColor?: string
    timeRange?: string
    usersCount?: number
}

let calendar: any;
export default function Calendar(props: IProps) {

    const [calendarCreated, calendarCreatedSet] = useState<boolean>(false);
    
    const [currentMonth, currentMonthSet] = useState<number>();
    const [currentYear, currentYearSet] = useState<number>();
    

    const build = () => {
        calendar = window.calendarCreate("dspvoice_full_calendar");
        if(calendar) { 
            calendarCreatedSet(true);
            
            calendar.on("datesSet", (e: any) => {
                if (props.onChange) {
                    props.onChange(e);
                }
            })

            calendar.on("eventClick", (e: any) => {
                if (props.onEventClick) {
                    props.onEventClick(e);
                }
            })
        }
    }


    useEffect(()=> {
        build();
    }, [])

    useEffect(() => {
        if(calendarCreated && props.events) {
            calendar.getEventSources().forEach((src:any) => src.remove());
            calendar.addEventSource(props.events);
        }
    }, [props.events, calendarCreated])


    return(
        <div className="card">
            <div className="card-body position-relative">
                <div id="dspvoice_full_calendar"></div>
                {
                    props.loading &&
                    <div className="calendar-loading">
                        <Indicator color="white" />
                    </div>
                }
                
            </div>
        </div>
        
    )
}