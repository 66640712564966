import { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import Environment from "../../env/env";
import Modal from "../base/Modal";
import FormTitle from "../form/FormTitle";
import TextBox from "../form/TextBox";
import Tagify from "../tagify/Tagify";
import { shareCallsClose, useHub } from "../../core/Global";
import { API_ROUTES } from "../../core/Constants";
import { copyToClipboard } from "../../core/Helper";

type Props = {
    ids?: string[]
}
function ShareCalls(props: Props) {

    const hub = useHub();
    const env = new Environment();
    const [link, linkSet] = useState<string>();
    const [Copied, CopiedSet] = useState<boolean>(false);
    const [sharedId, sharedIdSet] = useState<string>();

    const fetch = () => {
        hub.Post("/base/share/calls", {
            callIds: props.ids
        }, res => {
            linkSet(env.dashboardUrl + "/share/" + res.data.id);
            sharedIdSet(res.data.id);
        });
    }

    useEffect(() => {
        if (props.ids && props.ids.length > 0) {
            window.openModal("modal-share-calls");
            CopiedSet(false);
            fetch();
        }
        else {
            window.closeModal("modal-share-calls");
        }
    }, [props.ids]);

    return (
        <Modal id="modal-share-calls"
            title="Share"
            size="Large"
            buttonText="Share"
            open visible staticBackdrop
            onClose={() => {
                shareCallsClose()
            }}
            onSubmit={(body, callback) => {
                hub.Post("/base/share/calls/email/", body, res => {
                    if (res.success) {
                        if (callback) callback(res);
                    }
                });
            }}>
            <div>
                <div className="d-flex align-items-center">
                    <input className="form-control form-control-sm form-control-solid" value={link} readOnly
                        style={{
                            maxWidth: "70%"
                        }} />
                    <button className={"btn btn-sm ms-2 " + (Copied ? "btn-light-success" : "btn-light-primary")}
                        onClick={e => {
                            e.preventDefault();
                            copyToClipboard(link);
                            CopiedSet(true);
                            $(e.target).blur();
                        }}>
                        {Copied ? "Copied" : "Copy"}
                    </button>
                </div>
                <FormTitle title="Send To Email" />
                <Tagify label="" id="tagifyShareEmails" name="emails" apiUrl={API_ROUTES.TagifyEmailsAutocomplete} freeTags email block labelUp />
                <input type="hidden" value={sharedId} name="id" />
            </div>
        </Modal>
    )
}

export default ShareCalls;