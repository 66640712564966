import React from "react"
import { Link } from "react-router-dom";

type MenuChildProps = {
    title?: string,
    to?: string,
    visible?: boolean
}


function MenuChild(props: MenuChildProps) {

    return (
        <>
            {
                props.visible &&
                <div className="menu-item">
                    <Link className="menu-link" to={props.to ?? "#"} onClick={e => window.closeMobileMenuDrawer()}>
                        <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">
                            {props.title}
                        </span>
                    </Link>
                </div>
            }
        </>
    );
}

export default MenuChild;
