import axios, { CancelTokenSource } from 'axios';
import Environment from '../env/env';
import HUBResponse from "../models/HUBResponse";
import { API_ROUTES } from '../core/Constants';
import { toastError } from '../components/base/Toast';
import BrowseOptions from '../models/BrowseOptions';
import { getDeviceId, getUserId, getUserToken, isDemo, openLoginModal, setWallet, useOwnerUserId } from '../core/Global';
import FileUploadModel from '../models/FileUploadModel';
import User from '../modules/accounts/models/User';
import Wallet from '../models/Wallet';
import { HereGeocodeItem, HereGeocodePosition } from '../models/MapModels';

export default class Hub<T> {

    private baseUrl: string;
    private apiRoute: string;
    private env: Environment;
    private defaultHeaders: any;

    constructor(apiRoute: string = "") {
        this.env = new Environment();
        this.baseUrl = this.env.apiUrl;
        this.apiRoute = apiRoute;
        var token = getUserToken();
        var deviceId = getDeviceId();
        var userId = getUserId();
        this.defaultHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'SecretKey': this.env.secretKey,
            'Authorization': token,
            'DeviceId': deviceId,
            'Version': this.env.versionNumber,
            'DemoPreview': isDemo() ? 'demo' : undefined
        }
    }

    public async Find(id: string, success: (res: HUBResponse<T>) => void) {

        await axios({
            method: "GET",
            url: this.baseUrl + this.apiRoute + id,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Find", this.baseUrl + this.apiRoute + id, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                success(res.data);
                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });

    }

    public async Browse(options?: BrowseOptions, success?: (res: HUBResponse<T>) => void) {
        var q = "";
        var routeParams = "";

        if (options?.keyword) {
            if (routeParams != "") routeParams += "&";
            routeParams += "keyword=" + encodeURIComponent(options.keyword);
        }

        if (options?.page) {
            if (routeParams != "") routeParams += "&";
            routeParams += "page=" + options.page;
        }

        if (options?.filterParams && options.filterParams.length > 0) {
            for (var i = 0; i < options.filterParams.length; i++) {
                if (q != "") q += "&";
                var ext = "";
                if (options.filterParams[i].op == "not-equal") ext = "Not";
                q += options.filterParams[i].name + ext + "=" + encodeURIComponent(options.filterParams[i].value);
            }
        }

        if (options?.deafultFilter && options.deafultFilter.length > 0) {
            for (var i = 0; i < options.deafultFilter.length; i++) {
                if (q != "") q += "&";
                var ext = "";
                if (options.deafultFilter[i].op == "not-equal") ext = "Not";
                q += options.deafultFilter[i].name + ext + "=" + encodeURIComponent(options.deafultFilter[i].value);
            }
        }

        if (options?.searchParams && options.searchParams != "") {
            if (q != "") q += "&";
            q += options.searchParams;
        }

        if (q != "") q = "?" + q;


        if (this.env.isDevelopment) {
            console.log("Browse Started", this.baseUrl + this.apiRoute + q, options);
        }

        await axios({
            method: "GET",
            url: this.baseUrl + this.apiRoute + q,
            params: options,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Browse Done", this.baseUrl + this.apiRoute + q, options, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                if (success) success(res.data);

                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (this.env.isDevelopment) {
                    console.log("Browse Error", this.baseUrl + this.apiRoute + q, err);
                }
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async GetFilterValues(options?: any, params?: any[], success?: (res: HUBResponse<T>) => void) {

        var url = this.baseUrl + this.apiRoute + "filtervalues";
        if (params) {
            for (var i = 0; i < params.length; i++) {
                options[params[i].name] = params[i].value;
            }
        }
        await axios({
            method: "GET",
            url: url,
            params: options,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("GetFilterValues", url, options, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                if (success) success(res.data);

                if (!res.data.success) {
                    toastError(res.data.message);
                }

            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async Export(format?: string, options?: any, success?: (res: HUBResponse<string>) => void) {

        await axios({
            method: "GET",
            url: this.baseUrl + this.apiRoute + "export",
            params: {
                ...options,
                exportFormat: format
            },
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Export", this.baseUrl + this.apiRoute + "export", options, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                if (success) success(res.data);

                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                else {
                    toastError(err);
                }
            });
    }

    public async Create(body: T, success: (res: HUBResponse<T>) => void) {
        await axios({
            method: "POST",
            url: this.baseUrl + this.apiRoute,
            data: JSON.stringify(body),
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Create", this.baseUrl + this.apiRoute, body, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                success(res.data);
                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async Update(id: string, body: T, success: (res: HUBResponse<T>) => void) {
        await axios({
            method: "PUT",
            url: this.baseUrl + this.apiRoute + id,
            data: JSON.stringify(body),
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Update", this.baseUrl + this.apiRoute + id, body, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }

                success(res.data);

                if (!res.data.success) {
                    toastError(res.data.message);
                }

            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async Trash(id: string, success: (res: HUBResponse<T>) => void) {
        await axios({
            method: "DELETE",
            url: this.baseUrl + this.apiRoute + id,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Trash", this.baseUrl + this.apiRoute, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }

                if (res.status == 426) {
                    document.location.reload();
                }
                success(res.data);
                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async Restore(id: string, success: (res: HUBResponse<T>) => void) {
        await axios({
            method: "PUT",
            url: this.baseUrl + this.apiRoute + "restore/" + id,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Restore", this.baseUrl + this.apiRoute + "restore/" + id, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (res.status == 426) {
                    document.location.reload();
                }
                success(res.data);
                if (!res.data.success) {
                    toastError(res.data.message);
                }
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }

    public async Upload(data: FileUploadModel, success?: (res: HUBResponse<string>) => void) {
        const formData = new FormData();
        formData.append("category", data.category ?? "files");
        formData.append("file", data.file);
        if (data.cropX) formData.append("cropX", data.cropX.toString());
        if (data.cropY) formData.append("cropY", data.cropY.toString());
        if (data.cropW) formData.append("cropW", data.cropW.toString());
        if (data.cropH) formData.append("cropH", data.cropH.toString());
        if (data.width) formData.append("width", data.width.toString());
        if (data.height) formData.append("height", data.height.toString());
        if (data.pathId) formData.append("pathId", data.pathId);
        if (this.env.isDevelopment) {
            console.log("Upload Started", data);
        }
        await axios({
            method: "POST",
            url: this.baseUrl + API_ROUTES.FileUpload,
            data: formData,
            headers: {
                ...this.defaultHeaders
            }
        }).then(res => {
            if (this.env.isDevelopment) {
                console.log("Upload", this.baseUrl + API_ROUTES.FileUpload, res.data);
            }

            if (res.status == 401) {
                //document.location.href = "/auth/login";
                openLoginModal();
                return;
            }
            if (res.status == 426) {
                document.location.reload();
            }
            if (success) success(res.data);

            if (!res.data.success) {
                toastError(res.data.message);
            }
        })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                else {
                    console.log("Upload", formData, err);
                }
            });
    }

    public async Get(route: string,
        success?: (res: HUBResponse<any>) => void,
        error?: (err: any) => void,
        cancelToken?: (token: CancelTokenSource) => void) {
        if (!route || route.includes("undefined")) return;

        const cancelaionSource = axios.CancelToken.source();
        if (cancelToken) cancelToken(cancelaionSource);

        await axios({
            method: "GET",
            url: this.baseUrl + route,
            headers: {
                ...this.defaultHeaders
            },
            cancelToken: cancelaionSource.token
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Get", this.baseUrl + route, res.data);
                }
                if (res.status == 426) {
                    document.location.reload();
                    return;
                }
                if (res.status == 401) {
                    openLoginModal();
                    return;
                }
                if (success) success(res.data);
            })
            .catch(err => {
                if (this.env.isDevelopment) {
                    console.log("Get-calls-Error", this.baseUrl + route, err, "error method", error);
                }

                if (error) {
                    error(err);
                }

                if (err?.response?.status && err.response.status == 401) {
                    openLoginModal();
                    return;
                }

                if (err.response.status == 426) {
                    document.location.reload();
                    return;
                }


            });
    }

    public async GetAsync<T>(route: string) {
        if (!route || route == "undefined" || route == "undefined/") return;

        var res = await axios({
            method: "GET",
            url: this.baseUrl + route,
            headers: {
                ...this.defaultHeaders
            }
        });

        return res.data;
    }


    public async Post(route: string, body: any, success: (res: HUBResponse<any>) => void) {
        if (this.env.isDevelopment) {
            console.log("Post Started", this.baseUrl + route, body);
        }
        await axios({
            method: "POST",
            url: this.baseUrl + route,
            data: JSON.stringify(body),
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Post Done", this.baseUrl + route, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (res.status == 426) {
                    document.location.reload();
                }
                success(res.data);
            })
            .catch(err => {
                if (this.env.isDevelopment) {
                    console.log("Post Error", this.baseUrl + route, err);
                }
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (err.response.status == 426) {
                    document.location.reload();
                }
            });
    }


    public async Put(route: string, body?: any, success?: (res: HUBResponse<any>) => void) {
        await axios({
            method: "PUT",
            url: this.baseUrl + route,
            data: JSON.stringify(body),
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Put", this.baseUrl + route, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (res.status == 426) {
                    document.location.reload();
                }
                if (!res.data.success) {
                    if (res.data.field) {
                        window.showFormError(res.data.field, res.data.message);
                    }
                    else toastError(res.data.message);
                }
                if (success) success(res.data);
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (err.response.status == 426) {
                    document.location.reload();
                }
            });
    }

    public async Delete(route: string, success: (res: HUBResponse<any>) => void) {
        await axios({
            method: "DELETE",
            url: this.baseUrl + route,
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (this.env.isDevelopment) {
                    console.log("Delete", this.baseUrl + route, res.data);
                }
                if (res.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
                if (res.status == 426) {
                    document.location.reload();
                }
                if (success) success(res.data);
            })
            .catch(err => {
                if (err.response.status == 401) {
                    //document.location.href = "/auth/login";
                    openLoginModal();
                    return;
                }
            });
    }


    public async GetAuthUser(success?: (user: User) => void) {
        await axios({
            method: "GET",
            url: this.baseUrl + "/accounts/profile/currentuser",
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (res.status == 426) {
                    document.location.reload();
                }
                if (res.data.success) {
                    if (success) success(res.data.data);
                }
            })
            .catch(err => {

            });
    }

    public async GetWallet(success?: (res: Wallet) => void) {
        await axios({
            method: "GET",
            url: this.baseUrl + "/accounts/profile/wallet",
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (res.data.success) {
                    if (success) success(res.data.data);
                }
            })
            .catch(err => {

            });
    }

    public async UpdateWallet(success?: (res: Wallet) => void) {
        await axios({
            method: "GET",
            url: this.baseUrl + "/accounts/profile/wallet",
            headers: {
                ...this.defaultHeaders
            }
        })
            .then(res => {
                if (res.data.success) {
                    setWallet(res.data.data);
                    if (success) success(res.data.data);
                }
            })
            .catch(err => {

            });
    }

    public async LookUpAddress(addressId: string, callback: (item?: HereGeocodeItem) => void) {
        const ownerId = localStorage.getItem("rinvox-owner-user-id");
        await this.Get(`/maps/geocode/lookup/?id=${addressId}&ownerUserId=${ownerId}`, res => {
            callback(res.data);
        })
    }

    public async LookUpZipcode(zipcode: string, country: string, callback: (item?: HereGeocodeItem) => void) {
        await this.Get(`/maps/geocode/zipcode/?zipcode=${zipcode}&country=${country}`, res => {
            callback(res.data);
        })
    }

    public async ReversePosition(position: HereGeocodePosition, callback: (item?: HereGeocodeItem) => void) {
        await this.Get("/maps/geocode/reverse/?position=" + position.lat + "," + position.lng, res => {
            callback(res.data)
        })
    }














}