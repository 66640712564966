
interface ContainerProps {
    visible?: boolean
    children?: any
}

function Container(props: ContainerProps) {

    return (
        props.visible == undefined || props.visible ? 
        <>
            {props.children}
        </>
        :
        <></>
    )
}

export default Container;

