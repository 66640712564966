import { useEffect, useState } from "react";
import { toastSuccess } from "../../../components/base/Toast";
import DropDown from "../../../components/form/DropDown";
import Form from "../../../components/form/Form";
import { useHub } from "../../../core/Global";
import User from "../models/User"
import UserPBXServer from "../models/UserPBXServer";
import { API_ROUTES } from "../../../core/Constants";
import Icon from "../../../components/base/Icon";
import FlexRow from "../../../components/base/FlexRow";
import Modal from "../../../components/base/Modal";
import PBXServer from "../../system/servers/models/PBXServer";


type Props = {
    user: User
}
export default function AccountPBXServer(props: Props) {

    const hub = useHub();
    const [servers, serversSet] = useState<UserPBXServer[]>();

    const [addModal, addModalSet] = useState<boolean>(false);
    const [selectedServer, selectedServerSet] = useState<PBXServer>();
    const [availableRange, availableRangeSet] = useState<any>();

    useEffect(() => {
        if (selectedServer) {
            hub.Get(API_ROUTES.PBXServers + "availablerange/" + selectedServer.id, res => {
                if (res.success) availableRangeSet(res.data);
            })
        }
        else {
            availableRangeSet(undefined);
        }
    }, [selectedServer])

    const fetch = () => {
        hub.Get(API_ROUTES.UserServers + props.user.id, res => {
            if (res.success) serversSet(res.data);
        })
    }
    useEffect(() => {
        fetch();
    }, [])

    return (
        <>
            <div className="row align-items-center">
                {
                    servers?.map((s, k) =>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div key={k} className="card mb-5">
                                <div className="card-header">
                                    <div className="card-title m-0">
                                        <h3 className="fw-bolder m-0">
                                            <Icon icon="server" className="me-3" size={22} />
                                            {s.server?.name}
                                        </h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <FlexRow className="mb-2">
                                        <div className="fw-bolder me-2">
                                            Host:
                                        </div>
                                        <div>
                                            {s.server?.host}
                                        </div>
                                    </FlexRow>
                                    <FlexRow className="mb-2">
                                        <div className="fw-bolder me-2">
                                            Extensions Range:
                                        </div>
                                        <div>
                                            {s.extensionStart} - {s.extensionEnd}
                                        </div>
                                    </FlexRow>
                                    <FlexRow className="mb-2">
                                        <div className="fw-bolder me-2">
                                            Queues Range:
                                        </div>
                                        <div>
                                            {s.queueStart} - {s.queueEnd}
                                        </div>
                                    </FlexRow>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="col-12 col-md-6 col-lg-4 text-center">
                    <button type="button" className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success w-75"
                        onClick={e => {
                            e.preventDefault();
                            addModalSet(true);
                        }}>
                        <Icon icon="plus" />
                        Add Server
                    </button>
                </div>
            </div>

            {
                addModal &&
                <Modal id="modal-add-pbx-server-touser"
                    title="Add Server"
                    open visible staticBackdrop
                    buttonText="Add Server"
                    onClose={() => addModalSet(false)}
                    onSubmit={(body, callback) => {

                        if (!selectedServer) {
                            window.formError("serverId", "Please select a server!", "modal-add-pbx-server-touser");
                            if (callback) callback();
                            return;
                        }

                        hub.Post(API_ROUTES.UserServers, {
                            serverId: selectedServer.id,
                            userId: props.user.id
                        }, res => {
                            fetch();
                            if (callback) callback(res);
                        });

                    }}>

                    <div>
                        <DropDown id="drp-pbx-servers" name="serverId" apiSource={API_ROUTES.PBXServers + "all"}
                            label="Server" labelUp block parentModal="modal-add-pbx-server-touser"
                            onSelectItem={item => selectedServerSet(item)}
                            onClear={() => selectedServerSet(undefined)} />

                        {
                            selectedServer && availableRange &&
                            <div>

                                <FlexRow className="mb-2">
                                    <div className="fw-bolder me-2">
                                        Host:
                                    </div>
                                    <div>
                                        {selectedServer.host}
                                    </div>
                                </FlexRow>

                                <FlexRow className="mb-2">
                                    <div className="fw-bolder me-2">
                                        Extensions Range:
                                    </div>
                                    <div>
                                        {availableRange.extensionStart} - {availableRange.extensionEnd}
                                    </div>
                                </FlexRow>

                                <FlexRow className="mb-2">
                                    <div className="fw-bolder me-2">
                                        Queues Range:
                                    </div>
                                    <div>
                                        {availableRange.queueStart} - {availableRange.queueEnd}
                                    </div>
                                </FlexRow>

                            </div>
                        }
                    </div>
                </Modal>
            }
        </>
    )
}