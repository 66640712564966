import { useEffect, useState } from "react";
import Icon from "../../../../components/base/Icon";
import { useHub } from "../../../../core/Global";

type Props = {
    id?: string
    events?: any
}

function CallPath(props: Props) {

    const hub = useHub();
    const [events, eventsSet] = useState<string[]>();
    const [eventsLoading, eventsLoadingSet] = useState<boolean>();


    return (
        <div className="row">
            <div className="col-12">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body border-top p-9">
                     
                    {
                      props.events &&
                      <div className="timeline-label align-items-center mt-4">
                          {
                              props.events.map((item:any, key: number) => 
                              <div key={key} className="timeline-item align-items-center">
                                  <div className="timeline-label fw-bolder text-gray-800 fs-8">{item.time}</div>
                                  <div className="timeline-badge">
                                      <i className={"fa fa-genderless text-"+item.color+" fs-1"}></i>
                                  </div>
                                  <div className="timeline-content d-flex align-items-center">
                                  {
                                      item.name &&
                                      <>
                                          <div className="symbol symbol-circle symbol-30px overflow-hidden ms-3 me-3">
                                              <div className="symbol-label">
                                                  <img src={item.avatarUrl ?? "/assets/media/avatars/blank.png"} className="w-100" />
                                              </div>
                                          </div>
                                          <div className="fw-bolder text-gray-800 fs-9">
                                              <div>
                                                {item.name}
                                              </div>
                                              <div className="event-text fs-9">
                                                <Icon icon={item.icon} type="solid" className={"me-2 text-" + item.color}/>
                                                {item.text}
                                              </div>
                                          </div>
                                      </>
                                  }
                                  {
                                      !item.name &&
                                      <span className="fw-bolder text-gray-800 ps-3 fs-8">
                                          {item.text}
                                      </span>
                                  }
                                  
                                  </div>
                              </div>
                              )
                          }
                      </div>
                    }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallPath;