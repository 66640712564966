import { useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import Job from "../../models/Job";
import { hasRole, openEditJobAddress, openSendJob, useHub } from "../../../../../core/Global";

interface IProps {
    job: Job
}
export default function JobDrawerAddress(props: IProps) {



    return (
        <div>
            <FlexRow>
                <div>
                    <div className="text-muted fs-8">
                        <Icon icon="location-dot" size={10} className="me-2" type="solid" />
                        Address
                    </div>
                    <div className="ps-5">
                        {props.job.address?.title}
                    </div>
                </div>
                <FlexRow className="flex-grow-1" justify="end">
                    {
                        (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                        <IconButton icon="edit" size={14} type="solid" title="Edit Address"
                            onClick={e => {
                                openEditJobAddress(props.job)
                            }} />
                    }
                </FlexRow>
            </FlexRow>

        </div>
    )
}