import React, { useEffect, useState } from "react"
import "./Modal.css";
import $ from "jquery";
import Form from "../form/Form"
import HUBResponse from '../../models/HUBResponse';
import { FontAwesomeIcons, Validator } from '../../core/Types';
import Button from "./Button";
import { BootstrapColors } from '../../core/Types';
import FlexRow from "./FlexRow";
import JobNote from "../../modules/crm/jobs/models/JobNote";
import Icon from "./Icon";
import { useEnvironment } from "../../core/Global";
import IconButton from "./IconButton";

type ModalProps = {
    id: string
    title: string
    titleIcon?: FontAwesomeIcons
    titleLink?: string
    headerComponents?: any
    titleComponent?: any
    subtitle?: string
    staticBackdrop?: boolean
    fitHeight?: boolean
    height?: number
    size?: "Noraml" | "Large" | "XLarge" | "XXLarge"
    width?: any
    children: any
    formValidator?: Validator[]
    isUpdate?: boolean
    noHeader?: boolean
    noFooter?: boolean
    visible?: boolean
    open?: boolean
    buttonIcon?: FontAwesomeIcons
    buttonText?: string
    buttonDisabled?: boolean
    buttonColor?: BootstrapColors
    buttonCustomColor?: string
    cancelButton?: boolean
    cancelText?: string
    confirmLink?: string
    secondActionIcon?: FontAwesomeIcons
    secondActionText?: string
    secondActionColor?: BootstrapColors
    noPadding?: boolean
    confirmDisabled?: boolean
    fullscreen?: boolean
    secondModal?: boolean
    footerNoteInput?: boolean
    footerNoteReply?: JobNote
    footerComponent?: any
    onFooterNoteReplyClose?: () => void
    noForm?: boolean
    sideComponent?: any
    preventDiscard?: boolean
    preventCancelButton?: boolean
    scrollEnd?: boolean
    createAnother?: boolean
    createAnotherText?: string
    onSubmit?: (body?: any, callback?: (res?: HUBResponse<any>) => void) => void
    onSubmitted?: () => void
    onConfirm?: (callback: any) => void
    onConfirmed?: () => void
    onCancel?: () => void
    onOpen?: () => void
    onClose?: () => void
    onCloseButton?: () => void
    onSecondAction?: () => void
    onSecondConfirm?: (callback: any) => void
    onCreateAnotherChanged?: (keepOpen: boolean) => void
}



function Modal(props: ModalProps) {

    let footerTextArea: HTMLTextAreaElement | null;

    const env = useEnvironment();

    const [running, runningSet] = useState<boolean>(false)
    const [running2, running2Set] = useState<boolean>(false)

    const [modalHeight, modalHeightSet] = useState<number>();

    const [keepOpen, keepOpenSet] = useState<boolean>(false);
    useEffect(() => {
        if (props.onCreateAnotherChanged) props.onCreateAnotherChanged(keepOpen);
    }, [keepOpen])

    const [fullscreen, fullscreenSet] = useState<boolean>(false);

    const submit = async (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        runningSet(true);

        if (props.onSubmit) {
            props.onSubmit(body, res => {
                runningSet(false);
                if (callback && res) callback(res);
                if (res?.success) {
                    if (props.onSubmitted) props.onSubmitted();
                    //$(".btn-close-modal").click();
                    $(".modal-form input").val("");
                    window.closeModal(props.id);
                }
            });
        }
    }

    const confirm = async () => {
        runningSet(true);

        if (props.onConfirm) {
            props.onConfirm((keepOpen: boolean = false) => {
                runningSet(false);

                if (props.onConfirmed) props.onConfirmed();
                if (!keepOpen) window.closeModal(props.id);

                setTimeout(() => {
                    $(".btn").blur();
                }, 100);
            });
        }
    }

    const secondConfirm = async () => {
        running2Set(true);

        if (props.onSecondConfirm) {
            props.onSecondConfirm((keepOpen: boolean = false) => {
                running2Set(false);

                if (props.onConfirmed) props.onConfirmed();
                if (!keepOpen) window.closeModal(props.id);
                $(".modal-form input").val("");

                setTimeout(() => {
                    $(".btn").blur();
                }, 100);
            });
        }

    }

    const dialogClassName = () => {
        var cls = "modal-dialog";

        if (fullscreen) cls += " modal-fullscreen";
        else {
            if (props.size == "Large") cls += " modal-lg";
            if (props.size == "XLarge") cls += " modal-xl";
            if (props.size == "XXLarge") cls += " modal-xxl";
        }

        if (props.sideComponent) cls += " d-flex";

        if (props.secondModal) cls += " mt-20";

        return cls;
    }

    const setHeight = () => {
        //console.log("setHeight", $(window).height(), $("#" + props.id + " .modal-header").outerHeight(), $("#" + props.id + " .modal-footer").outerHeight());
        // if (props.height) {
        //     modalHeightSet(props.height);
        //     return;
        // }

        var h = $(window).height() ?? 800;
        h = h - ($("#" + props.id + " .modal-header").outerHeight() ?? 0);
        h = h - ($("#" + props.id + " .modal-footer").outerHeight() ?? 0);
        h = h - 60;
        //if(h > 900) h = 900;
        modalHeightSet(h);
    }


    useEffect(() => {
        if (props.visible) {

        }


        setHeight();
        window.addEventListener("resize", setHeight);

        window.onModalClose(props.id, () => {
            runningSet(false);
            running2Set(false);
            //if (props.secondModal) $(".modal-backdrop").last().css("z-index", 1050);
            if (props.onClose) props.onClose();
        });

        window.onModalOpening(props.id, () => {
            if (env.isDevelopment) console.log("modal openning", props.id);
            if (props.secondModal) {
                const backdrops: any = document.querySelectorAll('.modal-backdrop');
                if (backdrops.length > 1) {
                    backdrops[1].style.zIndex = '1060';
                }
            }
        })

        window.onModalOpen(props.id, () => {
            setHeight();
            if (env.isDevelopment) console.log("modal opened", props.id);

            if (props.onOpen) props.onOpen();
        });

        return () => {
            window.removeEventListener("resize", setHeight);
        }

    }, [])


    useEffect(() => {
        if (props.visible && props.open) {
            window.openModal(props.id);
            setTimeout(() => {
                footerTextArea?.focus();
            }, 200);
        }
    }, [props.visible, props.open])

    useEffect(() => {
        if (props.footerNoteInput) {
            footerTextArea?.focus();
        }
    }, [props.footerNoteReply])



    return (
        props.visible == undefined || props.visible ?
            <div className="modal modal-main-container fade modal-top" id={props.id} aria-hidden="true"
                style={{
                    overflow: "hidden",
                    zIndex: props.secondModal ? 1065 : 1055
                }}
                data-bs-keyboard="false"
                {...(props.staticBackdrop ? {
                    'data-bs-backdrop': 'static'
                } : {})}
            >
                <div className={dialogClassName()}
                    style={{
                        width: props.width,
                        maxWidth: props.width ? 1750 : "auto"
                    }}>

                    {
                        props.sideComponent &&
                        <div className="modal-content">
                            <div className="modal-body">
                                <props.sideComponent />
                            </div>
                        </div>
                    }

                    <Form id={"modal-form-" + props.id} noForm={props.noForm} className="modal-content modal-form" onSubmit={submit} validator={props.formValidator} isUpdate={props.isUpdate}>
                        {
                            !props.noHeader &&
                            <div className="modal-header py-4 py-xxl-6 border-bottom-0">
                                <div>
                                    <FlexRow>
                                        <h2 className="fs-1 fs-xxxl-1 modal-title d-flex align-items-center">
                                            {
                                                props.titleIcon &&
                                                <Icon icon={props.titleIcon} type="thin" className="me-2" size={28} />
                                            }
                                            {
                                                props.titleLink ?
                                                    <a href={props.titleLink} target="_blank">{props.title}</a>
                                                    : <>{props.title}</>
                                            }
                                        </h2>
                                        {
                                            props.titleComponent && <props.titleComponent />
                                        }
                                    </FlexRow>
                                    {
                                        props.subtitle &&
                                        <div className="fs-7 mt-1">
                                            {props.subtitle}
                                        </div>
                                    }
                                </div>

                                <div className="d-flex align-items-center">
                                    {
                                        props.headerComponents &&
                                        <div className="me-5">
                                            <props.headerComponents />
                                        </div>
                                    }

                                    {
                                        false &&
                                        <button type="button" className="btn btn-icon btn-sm btn-active-icon-primary" title={fullscreen ? "Exit Full Screen" : "Full Screen"}
                                            onClick={e => {
                                                fullscreenSet(!fullscreen);
                                            }}>
                                            <span className="svg-icon svg-icon-1">
                                                {
                                                    fullscreen ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.5" x="7.05026" y="15.5355" width="13" height="2" rx="1" transform="rotate(-45 7.05026 15.5355)" fill="black" />
                                                            <path d="M9.17158 14.0284L9.17158 8.11091C9.17158 7.52513 8.6967 7.05025 8.11092 7.05025C7.52513 7.05025 7.05026 7.52512 7.05026 8.11091L7.05026 15.9497C7.05026 16.502 7.49797 16.9497 8.05026 16.9497L15.8891 16.9497C16.4749 16.9497 16.9498 16.4749 16.9498 15.8891C16.9498 15.3033 16.4749 14.8284 15.8891 14.8284L9.97158 14.8284C9.52975 14.8284 9.17158 14.4703 9.17158 14.0284Z" fill="black" />
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="black" />
                                                            <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="black" />
                                                            <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="black" />
                                                        </svg>

                                                }

                                            </span>
                                        </button>
                                    }

                                    {
                                        !props.preventDiscard &&
                                        <IconButton icon="times"
                                            onClick={e => {
                                                if (props.onCloseButton) props.onCloseButton();
                                                else if (props.onCancel) props.onCancel();
                                                else {
                                                    window.closeModal(props.id);
                                                }
                                            }}
                                        />
                                    }

                                </div>
                            </div>
                        }
                        <div id={props.id + "-modal-body"} className={"modal-body " + (props.noPadding ? " p-0 m-0" : " py-2 mx-5")}
                            style={{
                                maxHeight: modalHeight,
                                height: props.fitHeight ? modalHeight : undefined,
                                position: "relative",
                                overflowX: "hidden"
                            }}>
                            {props.children}
                        </div>

                        {
                            !props.noFooter &&
                            <>

                                {
                                    (props.onSubmit || props.onConfirm || props.cancelButton) &&
                                    <div className="modal-footer border-top-0">
                                        {
                                            props.footerNoteInput &&
                                            <div className="flex-grow-1">
                                                {
                                                    props.footerNoteReply &&
                                                    <FlexRow className={"p-2 rounded text-dark fw-bold w-100 mb-1 bg-light-primary fs-8"}>
                                                        <Icon icon="reply" type="solid" className="me-2" />
                                                        <div style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: 400
                                                        }}>
                                                            {props.footerNoteReply.note}
                                                        </div>
                                                        <a href="#" className="ms-auto text-dark"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                if (props.onFooterNoteReplyClose) props.onFooterNoteReplyClose();
                                                            }}>
                                                            <Icon icon="times" type="solid" className="me-2" size={11} />
                                                        </a>
                                                    </FlexRow>
                                                }
                                                <textarea id={props.id + "-footer-note"} name="message" placeholder="Write a note..."
                                                    ref={e => footerTextArea = e}
                                                    className="form-control form-control-sm" rows={4} autoFocus
                                                    onKeyDown={e => {
                                                        if (e.key == "Enter") {
                                                            e.preventDefault();
                                                            var txt = $("#" + props.id + "-footer-note");
                                                            if (e.ctrlKey || e.shiftKey || e.altKey) {
                                                                txt.val(txt.val() + "\n");
                                                                txt.scrollTop(txt[0].scrollHeight - (txt.height() ?? 0));
                                                            }
                                                            else {
                                                                $("#" + props.id + "-footer-note-button-submit").click();
                                                            }
                                                        }
                                                    }}></textarea>
                                                <button id={props.id + "-footer-note-button-submit"} style={{ display: 'none' }}></button>
                                            </div>
                                        }

                                        {
                                            props.footerComponent &&
                                            <div className="flex-grow-1">
                                                <props.footerComponent />
                                            </div>
                                        }

                                        {
                                            props.createAnother &&
                                            <FlexRow className="flex-grow-1">
                                                <div className="form-check form-check-custom form-check-solid form-check-sm ms-3">
                                                    <input className="form-check-input me-1" type="checkbox"
                                                        checked={keepOpen} id="chk-job-form-keep-it-open"
                                                        onChange={e => keepOpenSet(e.target.checked)} />
                                                    <label className="form-check-label ms-1 fs-7" htmlFor="chk-job-form-keep-it-open">
                                                        {props.createAnotherText ?? "Create Another Item"}
                                                    </label>
                                                </div>
                                            </FlexRow>
                                        }
                                        <div className="text-danger me-5 form-general-error">
                                        </div>

                                        {
                                            (props.onSubmit || props.onConfirm || props.cancelButton) && (!props.preventDiscard) && (!props.preventCancelButton) &&

                                            <Button color="light" text={props.cancelText ?? "Discard"} onClick={() => {
                                                if (props.onCancel) props.onCancel();
                                                else {
                                                    window.closeModal(props.id);
                                                }
                                            }} />
                                        }

                                        {
                                            props.onSecondAction &&
                                            <Button color={props.secondActionColor ?? "secondary"} text={props.secondActionText} icon={props.secondActionIcon} onClick={props.onSecondAction} />
                                        }

                                        {
                                            props.onSecondConfirm &&
                                            <Button color={props.secondActionColor} isRunning={running2} disabled={running || running2} onClick={secondConfirm} text={props.secondActionText} icon={props.secondActionIcon} />
                                        }

                                        {
                                            props.onSubmit &&
                                            <Button id={props.id + "-submit"} color={props.buttonColor} isSubmit isRunning={running} disabled={running || running2 || props.buttonDisabled}
                                                text={props.buttonText ? props.buttonText : "Submit"} icon={props.buttonIcon}
                                            />
                                        }

                                        {
                                            props.onConfirm &&
                                            <Button color={props.buttonColor} isRunning={running} onClick={confirm} disabled={running || running2 || props.confirmDisabled}
                                                text={props.buttonText ? props.buttonText : "Ok"} icon={props.buttonIcon}
                                            />
                                        }

                                    </div>
                                }
                                {
                                    props.confirmLink &&
                                    <div className="modal-footer">
                                        <Button color="light" text={props.cancelText ?? "Discard"} modalDismiss />
                                        <a href={props.confirmLink} className="btn btn-sm btn-primary" target="_blank">
                                            {props.buttonText ? props.buttonText : "Submit"}
                                        </a>
                                    </div>
                                }

                            </>
                        }


                    </Form>
                </div>
            </div>
            : <></>
    )
}

export default Modal;
