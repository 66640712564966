import SubscriptionManage from "../components/SubscriptionManage";
import WalletSettings from "../components/WalletSettings";




export default function SubscriptionView() {


    return (
        <>
            <SubscriptionManage />
            <WalletSettings />
        </>
    )
}