import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import DropDown from "../../../components/form/DropDown";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import ChatMessage from "../models/ChatMessage";
import ChatMessageItem from "./ChatMessageItem";
import DataItem from "../../../models/DataItem";

interface IProps {
    message?: ChatMessage
    onClose: () => void
}
export default function AssignMessageToJob(props: IProps) {

    const hub = useHub();

    const [jobs, jobsSet] = useState<DataItem[]>([]);
    const fetchJobs = () => {
        hub.Get(API_ROUTES.Technicians + "jobsforchatmessages/" + props.message?.chatId, res => {
            if (res.success) jobsSet(res.data);
        })
    }

    useEffect(() => {
        if (props.message?.chatId) fetchJobs();
    }, [props.message])

    return (
        <Modal id="modal-assign-message-to-job"
            title="Assign to Job"
            visible open
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                if(window.isNullOrEmpty(body.jobId)) {
                    window.showFormError("jobId", "Please select a job!", true, "modal-assign-message-to-job");
                    if(callback) callback();
                    return;
                }

                hub.Post(API_ROUTES.Chat + "assigntojob", {
                    ...body,
                    messageId: props.message?.id,
                    chatId: props.message?.chatId,
                }, res => {
                    if (callback) callback(res);
                })

            }}>
                {/* <div>
                    <ChatMessageItem message={props.message} preview />
                </div>
                <hr />  */}
                <div>
                    <DropDown id="drp-assign-message-jobs" name="jobId" label="Job" labelUp block 
                        source={jobs} dataText="name" dataValue="id" />
                </div>
        </Modal>
    )
}