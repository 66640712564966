import { useEffect, useState } from "react";
import { toastSuccess } from "../../../../components/base/Toast";
import DropDown from "../../../../components/form/DropDown";
import Form from "../../../../components/form/Form";
import FormTitle from "../../../../components/form/FormTitle";
import TextBox from "../../../../components/form/TextBox";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub, usePreferences } from "../../../../core/Global";
import Icon from "../../../../components/base/Icon";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";



export default function LoginSettings() {

    const hub = useHub();
    const pref = usePreferences();

    const [working, workingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<any>();

    useEffect(() => {
        hub.Get("/accounts/profile/crmsettings", res=> {
            if (res.success) modelSet(res.data);
        })
    }, [])


    return (
        <Form className="form"
            onSubmit={(body, callback) => {
                workingSet(true);
                hub.Post("/accounts/profile/loginsettings", body, res => {
                    if(res.success) {
                        toastSuccess("Login settings has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Login Settings
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                            working ? 
                            <>
                                <Icon icon="spinner-third" spin />
                                <span className="ms-2">Updating...</span>
                            </>
                            : <>Update Settings</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <ToggleSwitch raw small className="mb-5" name="forceAgentLoginInShift" checked={pref?.forceAgentLoginInShift}
                        label="Agents may login only when they have active shift!" />
                        
                    <ToggleSwitch raw small className="mb-5" name="forceManagerLoginInShift" checked={pref?.forceManagerLoginInShift}
                        label="Managers may login only when they have active shift!" />

                    <ToggleSwitch raw small className="mb-5" name="forceAgentLoginSelectExt" checked={pref?.forceAgentLoginSelectExt}
                        label="Agents must select an extension when they login to system!" />

                </div>
            </div>
        </Form>
    )
}