import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";
import { toastError, toastSuccess } from "../../../components/base/Toast";


interface IProps {
  onClose?: () => void
  onCreated?: () => void
}

export default function TechGroupCreateModal(props: IProps) {

  const hub = useHub();
  const user = getUser();

  const [focusNames, focusNamesSet] = useState<boolean>(true);

  useEffect(() => {
    if (focusNames) {
      setTimeout(() => {
        focusNamesSet(false);
      }, 1000);
    }
  }, [focusNames])

  return (
    <Modal id="modal-add-techgroup"
      title="Create Technician Group"
      buttonText="Create Group(s)"
      staticBackdrop visible open
      onClose={props.onClose}
      onOpen={() => {
        $("#drp-groupNames").focus();
      }}
      onSubmit={(body, callback) => {

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.showFormError("ownerUserId", "Please choose the assignee user!");
            if (callback) callback();
            return;
          }
        }

        if (!body.groupNames || body.groupNames == "" || body.groupNames.length < 2) {
          window.formError("groupNames", "Please write a group name!", "modal-add-techgroup");
          focusNamesSet(true);
          if (callback) callback();
          return;
        }

        hub.Post(API_ROUTES.TechGroups, body, res => {
          if (res.success) {
            if (props.onCreated) props.onCreated();
            toastSuccess("Group(s) has been added successfully!");
          }
          if (callback) callback(res);
        })

      }}>
      <div>
        {
          user?.isSuperAdmin &&
          <>
            <div className="fs-7 fw-bolder">
              Assign to
              <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
            </div>
            <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
          </>
        }

        <Tagify name="groupNames" id="drp-groupNames" label="Group Name(s)" focus={focusNames}
          placeholder="Write a name" apiUrl={API_ROUTES.TagifyCustomers} block labelUp freeTags />

      </div>
    </Modal>
  )
}