import Technician from "../../modules/technicians/models/technician"
import Avatar from "../base/Avatar"
import FlexRow from "../base/FlexRow"
import Icon from "../base/Icon"

interface IProps {
    tech: Technician
}

export default function TechTooltip (props: IProps) {


    return (
        <div className="w-200px">
            <FlexRow>
                <Avatar width={40} avatarUrl={props.tech.avatar} className="me-3" />
                <div>
                    <div className="fw-bold fs-6 text-start">
                        {props.tech.fullName}
                    </div>
                    <div className="text-muted fs-8 text-start mt-1">
                        {props.tech.phoneNumber}
                    </div>
                </div>
            </FlexRow>
            <hr />
            <div className="fs-7 text-start">
                {
                    props.tech.activeJobs && props.tech.activeJobs > 0 ? 
                    <a className="text-danger">
                        {props.tech.activeJobs} Active Jobs
                    </a>
                    :
                    <a className="text-start">
                        Ready for Job
                    </a>
                }
            </div>
            <FlexRow className="mt-2 text-start">
                <Icon icon="location-dot" type="regular" className="me-2" size={14} />
                <div className="fs-8 text-start">
                    {
                        props.tech.distanceLabel ?? "Unknown Distance"
                    }
                </div>
            </FlexRow>
            <div className="mt-2 fs-8 text-muted text-start">
                {props.tech.techAddress}
            </div>
        </div>
    )
}