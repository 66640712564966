import { useEffect } from "react"
import { setPage } from "../../../../core/Global"
import Layout from "../../../../components/layouts/Layout"
import { DataTable, DataTableField } from "../../../../components/data/DataTable"
import { API_ROUTES } from "../../../../core/Constants"
import Section from "../../../../components/base/Section"
import Job from "../models/Job"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"

import SortingProps from "../sorting/jobs.json"
import { useNavigate } from "react-router-dom"


interface IProps {
    type: "closed" | "canceled"
}
export default function JobsList(props: IProps) {

    const navigate = useNavigate();


    const JobIdColumn = (data: Job) => (
        <div>
            <a href={"/crm/jobs/overview/" + data.id} target="_blank">
                #{data.jobId}
            </a>
        </div>
    )

    const PhoneColumn = (data: Job) => (
        <div>
            {data.phone?.phoneFriendly}
        </div>
    )

    const TechColumn = (data: Job) => (
        <div>
            {data.tech?.fullName}
        </div>
    )

    const AppointmentColumn = (data: Job) => (
        <div className="ms-3">
            <div className="fs-7 fw-bold">
                {data.appointmentDate}
            </div>
            <div className="fs-8">
                {data.appointmentTimeFrom} - {data.appointmentTimeTo}
            </div>
        </div>
    )

    const TagsField = (data: Job) => (
        <FlexRow>
            {
                data.flags?.map((item, key) =>
                    <div key={key}
                        className="badge badge-success me-2"
                        style={{
                            backgroundColor: item.color
                        }}>{item.name}</div>
                )
            }
            {
                data.tags?.map((item, key) =>
                    <div key={key} className="badge badge-secondary me-2">{item.name}</div>
                )
            }
        </FlexRow>
    )

    useEffect(() => {
        setPage("Archived Jobs", "CRM", "Jobs")
    }, [])



    return (
        <Layout wide>
            <Section fluid>

                <DataTable
                    objectName="Job"
                    preventCreate
                    sortingProps={SortingProps}
                    apiRoute={API_ROUTES.Jobs}>

                    <DataTableField title="Job ID">
                        <JobIdColumn />
                    </DataTableField>

                    <DataTableField title="Name" dataValueName="fullName" />
                    <DataTableField title="Phone">
                        <PhoneColumn />
                    </DataTableField>
                    <DataTableField title="Address" dataValueName="addressText" />

                    <DataTableField title="Job Source" dataValueName="jobSource" />
                    <DataTableField title="Job Type" dataValueName="jobType" />

                    <DataTableField title="Tech">
                        <TechColumn />
                    </DataTableField>

                    <DataTableField title="Appointment">
                        <AppointmentColumn />
                    </DataTableField>

                    <DataTableField title="Tags">
                        <TagsField />
                    </DataTableField>

                </DataTable>

            </Section>
        </Layout>
    )
}