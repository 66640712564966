import { useEffect, useState } from "react";
import { toastSuccess } from "../../../components/base/Toast";
import Form from "../../../components/form/Form";
import AddressField from "../../../components/here/AddressField";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import User from "../models/User"
import { HereGeocodePosition } from "../../../models/MapModels";
import DropDown from "../../../components/form/DropDown";
import Map from "../../../components/here/Map";
import JobMetro from "../../crm/base/models/JobMetro";
import Icon from "../../../components/base/Icon";
import TextBox from "../../../components/form/TextBox";
import AccountTechRates from "./AccountTechRates";
import AccountTechSendJobSettings from "./AccountTechSendJobSettings";


type Props = {
    userId?: string
    model?: User
    onUpdate?: (user: User) => void
}

export default function AccountTechSettings(props: Props) {

    const hub = useHub();
    const [working, workingSet] = useState<boolean>(false);


    return (
        <>
            <AccountTechRates userId={props.userId} model={props.model} onUpdate={props.onUpdate} />
            <Form id="user-techsetting-form" className="form"
                onSubmit={(body, callback) => {

                    workingSet(true);

                    hub.Put("/accounts/technicians/updatetechdetails", {
                        ...body,
                        id: props.userId
                    }, res => {
                        if (res.success) {
                            toastSuccess("Technician details has been updated successfully!");
                            if (props.onUpdate) props.onUpdate(res.data);
                        }
                        workingSet(false);
                    })

                }}>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Technician Details</h3>
                        </div>
                        <div className="card-action">
                            <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                                {
                                    working ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">Updating...</span>
                                        </>
                                        :
                                        <>
                                            Update Technician
                                        </>
                                }
                            </button>
                        </div>
                    </div>

                    <div className="card-body border-top p-9"
                        style={{
                            maxWidth: 1000
                        }}>

                        <Tagify id="tagify-tech-groups" name="groups" label="Groups" marginBottom={5}
                            apiUrl={API_ROUTES.TagifyTechGroups} selectedIds={props.model?.techGroupIds} />

                        <Tagify id="tagify-tech-skills" name="skills" label="Skills" marginBottom={5}
                            apiUrl={API_ROUTES.TagifyJobTypes} selectedIds={props.model?.techJobTypes} />

                        <Tagify id="tagify-tech-sources" name="sources" label="Sources" marginBottom={5}
                            apiUrl={API_ROUTES.TagifyJobSources} selectedIds={props.model?.techJobSources} />


                    </div>

                </div>
            </Form>

        </>
    )
}