import { Validator } from '../../../core/Types';

export const PasswordResetValidator: Validator[] = [
    {
        name: "password",
        required: true
    },
    {
        name: "repassword",
        required: true,
        euqalTo: "password"
    }
 ];