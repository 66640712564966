import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Section from "../../../../components/base/Section";
import Layout from "../../../../components/layouts/Layout";
import { ChartTimingTypes, JobsChartSegments, SortTypes } from "../../../../core/Enums";
import Modal from "../../../../components/base/Modal";
import { TagifyDataItem } from "../../../../models/TagifyOptions";
import { getUser, openJobQuickView, openReportJobsList, setPage, setToolbarActions, toPrettyDate, useEnvironment, useHub } from "../../../../core/Global";
import Avatar from "../../../../components/base/Avatar";
import Indicator from "../../../../components/base/Indicator";
import Div from "../../../../components/base/Div";
import { FontAwesomeIcons } from "../../../../core/Types";
import { API_ROUTES } from "../../../../core/Constants";
import Job from "../models/Job";
import JobPaymentDetails from "../models/JobPaymentDetails";
import JobReportIssue from "../components/JobReportIssue";
import Filtering from "../../../../components/data/Filtering";
import FilteringPropsAgents from "../filtering/filterings-agents.json";
import FilteringPropsTechs from "../filtering/filterings-techs.json";
import FilteringPropsSources from "../filtering/filterings-sources.json";
import FilteringPropsTypes from "../filtering/filterings-types.json";
import FilteringPropsMetros from "../filtering/filterings-metros.json";
import FilteringPropsPayments from "../filtering/filterings-payments.json";
import FilteringPropsPaymentsDetails from "../filtering/filterings-payments-details.json";
import FilteringPropsClosedJobs from "../filtering/filterings-closedjobs.json";
import DataItem from "../../../../models/DataItem";
import JobReportModel, { DataColumnsVisibility, JobReportDataRow, JobReportTechPaymentRow, JobsColumnsVisibility, TechPayColumnsVisibility } from "../models/JobReportModel";
import { JobsReportRequest, JobsReportSort } from "../models/JobsReportRequest";
import JobReportExportModal from "../components/jobmodals/JobReportExportModal";
import DatePicker from "../../../../components/form/DatePicker";
import Checkbox from "../../../../components/form/Checkbox";
import DropDown from "../../../../components/form/DropDown";
import Button from "../../../../components/base/Button";
import TextBox from "../../../../components/form/TextBox";
import IconButton from "../../../../components/base/IconButton";



export default function JobsReport() {

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const moneyFormatterNoneSign = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: "code"
    });

    let searchTimout: any;
    const env = useEnvironment()
    const hub = useHub()
    const user = getUser()
    const searchParams = useSearchParams()

    const navigate = useNavigate();
    const { segment } = useParams();

    // useEffect(() => {
    //     if (!segment) {
    //         let seg = initialSegment();
    //         navigate("/reports/charts/jobs/" + JobsChartSegments[seg].toLowerCase());
    //     }
    // }, [])

    const initialSegment = () => {

        if (segment) {
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.Dispatchers].toLowerCase()) return JobsChartSegments.Dispatchers;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.Technician].toLowerCase()) return JobsChartSegments.Technician;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.ClosedJobs].toLowerCase()) return JobsChartSegments.ClosedJobs;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.JobSource].toLowerCase()) return JobsChartSegments.JobSource;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.JobType].toLowerCase()) return JobsChartSegments.JobType;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.Metro].toLowerCase()) return JobsChartSegments.Metro;
            //if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.Flags].toLowerCase()) return JobsChartSegments.Flags;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.Payments].toLowerCase()) return JobsChartSegments.Payments;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.ClosedJobs].toLowerCase()) return JobsChartSegments.ClosedJobs;
            if (segment.toLowerCase() == JobsChartSegments[JobsChartSegments.CanceledJobs].toLowerCase()) return JobsChartSegments.CanceledJobs;
        }
        var seg = parseInt(localStorage.getItem("jobs_reports_segment") ?? "1");
        if (seg == 1) return JobsChartSegments.Dispatchers;
        if (seg == 2) return JobsChartSegments.Technician;
        if (seg == 3) return JobsChartSegments.ClosedJobs;
        if (seg == 4) return JobsChartSegments.JobSource;
        if (seg == 5) return JobsChartSegments.JobType;
        if (seg == 6) return JobsChartSegments.Metro;
        //if (seg == 7) return JobsChartSegments.Flags;
        if (seg == 8) return JobsChartSegments.Payments;
        if (seg == 9) return JobsChartSegments.ClosedJobs;
        if (seg == 10) return JobsChartSegments.CanceledJobs;

        return JobsChartSegments.Payments;
    }

    const initialDateId = () => {
        var d = localStorage.getItem("jobs_reports_dateid") ?? "1";
        return d;
    }

    const initialSortKey = () => {
        return "";
    }

    const initialSortType = () => {
        return SortTypes.Descending;
    }

    const [body, bodySet] = useState<JobsReportRequest>({
        chartTiming: ChartTimingTypes.Day,
        segment: JobsChartSegments.ClosedJobs,
        dateId: "1",
        segmentIds: [],
        metric1: "",
        metric2: "",
        metric3: "",
        startDate: window.now(-30).format("DD/MM/YYYY"),
        toDate: window.now().format("DD/MM/YYYY")
    });


    useEffect(() => {
        var jobs_report_body = localStorage.getItem("jobs_report_body");
        if (!window.isNullOrEmpty(jobs_report_body)) {
            var bd: JobsReportRequest = JSON.parse(jobs_report_body ?? "");
            if (bd) {
                bd.segmentIds = [];
                bodySet(bd);
            }


            // if (bd.segmentIds && bd.segmentIds.length > 0) {
            //     fetchSelectedSegments(bd.segment, bd.segmentIds);
            // }

        }
    }, [])

    const [timezonesSource, timezonesSourceSet] = useState<any[]>([])


    const [filterTimezone, filterTimezoneSet] = useState<string>("job")
    const [filterDateType, filterDateTypeSet] = useState<string>("1")
    const [selectedTimeZone, selectedTimeZoneSet] = useState<any>();



    useEffect(() => {
        hub.Get("/reports/jobs/timezones", res => timezonesSourceSet(res.data))
    }, [])

    useEffect(() => {
        let tz: any;

        timezonesSource.forEach(t => {
            if (t.id == body.timezone) {
                tz = t;
            }
        })
        if (!tz) {
            timezonesSource.forEach(t => {
                if (t.id == "job") {
                    tz = t;
                }
            })
        }

        selectedTimeZoneSet(tz);

    }, [timezonesSource, body])



    const [filterQuery, filterQuerySet] = useState<string>()


    const [sortBody, sortBodySet] = useState<JobsReportSort>({
        sortKey: initialSortKey(),
        sortType: initialSortType()
    });

    const [dates, datesSet] = useState<DataItem[]>([])
    useEffect(() => {
        hub.Get("/reports/jobs/dates", res => datesSet(res.data))
    }, [])

    const ActionButtons = useCallback(() => {
        return (
            <FlexRow>
                {/* <Button type="light" color="primary"
                    icon="sliders" text="Settings"
                    className="btn-active-light-primary me-2"
                    onClick={() => {
                        filterModalSet(true)
                    }} /> */}
                <Button type="light" color="primary"
                    icon="refresh" text="Refresh"
                    className="btn-active-light-primary"
                    onClick={fetch} />
            </FlexRow>
        )
    }, [filterDateType])




    const [model, modelSet] = useState<JobReportModel>();
    const [jobs, jobsSet] = useState<Job[]>();
    const [jobsTotalRow, jobsTotalRowSet] = useState<JobPaymentDetails>();
    const [data, dataSet] = useState<JobReportDataRow[]>();
    const [avTickets, avTicketsSet] = useState<number>();
    const [payments, paymentsSet] = useState<JobReportTechPaymentRow[]>();
    const [loading, loadingSet] = useState<boolean>(true);
    const [loaded, loadedSet] = useState<boolean>(false);
    const [firstLoad, firstLoadSet] = useState<boolean>(true);
    const [filteringValues, filteringValuesSet] = useState<any>();

    useEffect(() => {

        if (model && !loading) {
            var pieNumbers: number[] = [];
            var pieColors: string[] = [];
            model.pieChart?.statusCounts.forEach(s => {
                if (s.count > 0 && s.color) {
                    pieNumbers.push(s.count);
                    pieColors.push(s.color);
                }

            });

            window.createCircleChart("jobchart-circle", pieNumbers, pieColors);
            // window.createJobReportsSampleChart("kt_card_widget_1_chart");
            // window.createJobReportsSampleChart("kt_card_widget_2_chart");
            // window.createJobReportsSampleChart("kt_card_widget_3_chart");

            // $("#chart-target").html("");
            // $("#chart-target").html("<div id='main-chart' style='height: 400px'></div>");

            // setTimeout(() => {
            //     if(model.chart) {
            //         var chart = window.createJobsChart(model.chart);    
            //     }
            // }, 500);

            jobsSet(model.jobs);
            dataSet(model.data);
            paymentsSet(model.techPayments);

            if (model.data) {
                for (var i = 0; i < model.data.length; i++) {
                    if (model.data[i].id == "total") {
                        avTicketsSet(model.data[i].averageTickets);
                        break;
                    }
                }
            }
            //chartSet(chart);
        }

    }, [model, loading])

    useEffect(() => {
        if (jobs && jobs.length > 0) {
            var total: JobPaymentDetails = {
                amount: 0,
                totalCash: 0,
                totalCCCharge: 0,
                totalCCManual: 0,
                totalCCChargeRate: 0,
                totalCheck: 0,
                companyParts: 0,
                techParts: 0,
                companyProfit: 0,
                techProfit: 0,
                sourceProfit: 0,
                techBalance: 0,
                techFee: 0
            };

            jobs.forEach(j => {
                if (!j.isSetteled) {
                    total.amount! += (j.payment?.totalPayment ?? 0);
                    total.totalCash! += (j.paymentDetails?.totalCash ?? 0);
                    total.totalCCCharge! += (j.paymentDetails?.totalCCCharge ?? 0);
                    total.totalCCManual! += (j.paymentDetails?.totalCCManual ?? 0);
                    total.totalCheck! += (j.paymentDetails?.totalCheck ?? 0);
                    total.companyParts! += (j.paymentDetails?.companyParts ?? 0);
                    total.techParts! += (j.paymentDetails?.techParts ?? 0);
                    total.companyProfit! += (j.paymentDetails?.companyProfit ?? 0);
                    total.techProfit! += (j.paymentDetails?.techProfit ?? 0);
                    total.sourceProfit! += (j.paymentDetails?.sourceProfit ?? 0);
                    total.techBalance! += (j.paymentDetails?.techBalance ?? 0);
                    total.totalCCChargeRate! += (j.paymentDetails?.totalCCChargeRate ?? 0);
                    total.techFee! += j.paymentDetails?.techFee ?? 0;
                }
            })
            jobsTotalRowSet(total);
        }
    }, [jobs, body])


    const fetch = () => {



        var q = "?a=1";

        if (firstLoad && searchParams && searchParams[0]) {
            q += "&" + searchParams[0].toString();
        }

        if (filterQuery) {
            q += "&" + filterQuery;
        }

        localStorage.setItem("jobs_report_body", JSON.stringify(body));

        hub.Post("/reports/jobs/chart/" + q, {
            ...body,
            timezone: "job",
            dateType: "1",
            ...sortBody
        }, res => {
            if (res.success) {
                modelSet(res.data);
                filteringValuesSet(res.filteringValues);
                loadingSet(false);
                loadedSet(true);
                firstLoadSet(false);
            }
        })
    }

    const fetchSegmentData = (keyword?: string, include?: TagifyDataItem) => {
        var excludeIds = "";
        for (var i = 0; i < segmentIds.length; i++) {
            excludeIds += "&excludeIds=" + segmentIds[i];
        }
        var includeIds = "";
        if (include) includeIds = "&includeIds=" + include.value;

        var route = "";
        var extra = "";
        if (body.segment == JobsChartSegments.Dispatchers) {
            route = "/base/tagify/users/";
            extra = "&type=2";
        }
        else if (body.segment == JobsChartSegments.Technician || body.segment == JobsChartSegments.Payments) {
            route = "/base/tagify/users/";
            extra = "&type=4";
        }
        else if (body.segment == JobsChartSegments.JobSource) route = "/base/tagify/jobsources/";
        else if (body.segment == JobsChartSegments.JobType) route = "/base/tagify/jobtypes/";
        //else if (body.segment == JobsChartSegments.Flags) route = "/base/tagify/flags/";

        // else if (body.segment == CallsChartSegments.Dispatchers) route = "/base/tagify/users/";
        // else if (body.segment == CallsChartSegments.Extensions) route = "/base/tagify/extensions/";
        // else if (body.segment == CallsChartSegments.Queues) route = "/base/tagify/queues/";

        hub.Get(route + "?keyword=" + keyword + extra + excludeIds + includeIds, res => {
            if (res.data) {
                segmentsDataSet(res.data);
            }
        });
    }

    const fetchSelectedSegments = (segment: JobsChartSegments, ids: string[]) => {

        var includeIds = "";
        ids.forEach(id => includeIds = "&ids=" + id);

        var route = "";
        var extra = "";
        if (segment == JobsChartSegments.Dispatchers) {
            route = "/base/tagify/users/";
            extra = "&type=2";
        }
        else if (segment == JobsChartSegments.Technician || segment == JobsChartSegments.Payments) {
            route = "/base/tagify/users/";
            extra = "&type=4";
        }
        else if (segment == JobsChartSegments.JobSource) route = "/base/tagify/jobsources/";
        else if (segment == JobsChartSegments.JobType) route = "/base/tagify/jobtypes/";
        //else if (body.segment == JobsChartSegments.Flags) route = "/base/tagify/flags/";

        // else if (body.segment == CallsChartSegments.Dispatchers) route = "/base/tagify/users/";
        // else if (body.segment == CallsChartSegments.Extensions) route = "/base/tagify/extensions/";
        // else if (body.segment == CallsChartSegments.Queues) route = "/base/tagify/queues/";

        hub.Get(route + "?keyword=----" + extra + includeIds, res => {
            if (res.data) {
                selectedSegmentItemsSet(res.data)
            }
        });
    }



    useEffect(() => {

        if (body.dateId == "99") {

            window.daterangecustom("chart-date", (start, end, label) => {

                chartDatesSet({
                    start: start.format("DD/MM/YYYY"),
                    end: end.format("DD/MM/YYYY")
                });

                var difDays = end.diff(start, "days");
                // datesSet({
                //     startDate: start.format("DD/MM/YYYY"),
                //     toDate: end.format("DD/MM/YYYY"),
                //     difDays: difDays
                // });
                timingHourDisabledSet(difDays > 30);
                timingDayDisabledSet(difDays > 180 || difDays < 3);
                timingWeekDisabledSet(difDays < 14);
                timingMonthDisabledSet(difDays < 60);

            });
        }
        loadingSet(true);
        fetch();

    }, [body, filterQuery])

    const [chartDates, chartDatesSet] = useState<any>();
    useEffect(() => {
        if (chartDates) {
            bodySet({
                ...body,
                startDate: chartDates.start,
                toDate: chartDates.end
            })
        }
    }, [chartDates])

    const [timingHourDisabled, timingHourDisabledSet] = useState<boolean>(false);
    const [timingDayDisabled, timingDayDisabledSet] = useState<boolean>(false);
    const [timingWeekDisabled, timingWeekDisabledSet] = useState<boolean>(false);
    const [timingMonthDisabled, timingMonthDisabledSet] = useState<boolean>(true);


    const [exportExcelWorking, exportExcelWorkingSet] = useState<boolean>(false);
    useEffect(() => {
        if (exportExcelWorking) exportExcelFile();
    }, [exportExcelWorking])

    const exportExcelFile = () => {

        exportExcelWorkingSet(true);

        var q = "?a=1";

        if (firstLoad && searchParams && searchParams[0]) {
            q += "&" + searchParams[0].toString();
        }

        if (filterQuery) {
            q += "&" + filterQuery;
        }

        hub.Post("/reports/jobs/export/" + q, {
            ...body,
            ...sortBody
        }, res => {
            exportExcelWorkingSet(false);
            if (res.success) {
                document.location.href = env.cdnUrl + res.data;
            }
        })
    }


    const [boxHeight, boxHeightSet] = useState<number>();
    const fixSizes = () => {
        var h = $(window).height() ?? 400;
        h = h - ($("#kt_header").height() ?? 0);
        h = h - ($("#kt_toolbar").height() ?? 0);
        h = h - ($("#job-reports-stickybar").outerHeight() ?? 0);
        h = h - 50;
        boxHeightSet(h);
    }



    useEffect(() => {

        setPage("Jobs Report");
        setToolbarActions(ActionButtons);

        window.KTStickyCreate();
        fixSizes();

        window.addEventListener("resize", fixSizes);
        return () => {
            window.removeEventListener("resize", fixSizes);
            setToolbarActions(undefined);
        }

    }, [])



    useEffect(() => {
        if (sortBody.sortLoading) resort();
    }, [sortBody])

    const resort = () => {
        var q = "?a=1";

        if (firstLoad && searchParams && searchParams[0]) {
            q += "&" + searchParams[0].toString();
        }

        if (filterQuery) {
            q += "&" + filterQuery;
        }

        hub.Post("/reports/jobs/chart/" + q, {
            ...body,
            ...sortBody
        }, res => {
            if (res.success) {
                //modelSet(res.data);
                jobsSet(j => [...res.data.jobs]);
                dataSet(d => [...res.data.data]);
                paymentsSet(p => [...res.data.techPayments]);
                sortBodySet({
                    ...sortBody,
                    sortLoading: false
                });
            }
        })
    }

    const [exportTechId, exportTechIdSet] = useState<string>()

    useEffect(() => {
        if (!jobs || jobs.length == 0) {
            exportTechIdSet(undefined);
            return;
        }
        var techId = jobs[0].techId;
        if (jobs.every(f => f.techId == techId)) {
            exportTechIdSet(techId);
            return;
        }

        exportTechIdSet(undefined);
    }, [jobs])




    const [changeSegment, changeSegmentSet] = useState<boolean>(false);
    const [segmentIds, segmentIdsSet] = useState<string[]>([]);
    const [defaultSegmentId, defaultSegmentIdSet] = useState<string>();
    const [segmentsData, segmentsDataSet] = useState<TagifyDataItem[]>([]);
    const [selectedSegmentItem, selectedSegmentItemSet] = useState<TagifyDataItem>();
    const [selectedSegmentItems, selectedSegmentItemsSet] = useState<TagifyDataItem[]>([]);

    const [addSegment, addSegmentSet] = useState<boolean>(false);
    const [clearFilters, clearFiltersSet] = useState<boolean>(false);
    useEffect(() => {
        if (clearFilters) {
            setTimeout(() => {
                clearFiltersSet(false);
            }, 1000);
        }
    }, [clearFilters])

    const updateSegment = (s: JobsChartSegments) => {
        navigate("/crm/report/jobs");
        clearFiltersSet(true);
        loadingSet(true);
        segmentIdsSet([]);
        segmentsDataSet([]);
        selectedSegmentItemsSet([]);
        selectedSegmentItemSet(undefined);
        bodySet({
            ...body,
            segment: s,
            segmentIds: []
        });
        window.closeModal("modal-segments");
    }

    const segmentItemName = (s?: JobsChartSegments) => {
        if ((s ?? body.segment) == JobsChartSegments.Dispatchers) return "Dispatcher";
        //else if ((s ?? body.segment) == JobsChartSegments.Flags) return "Flag";
        else if ((s ?? body.segment) == JobsChartSegments.JobSource) return "Source";
        else if ((s ?? body.segment) == JobsChartSegments.ClosedJobs) return "";
        else if ((s ?? body.segment) == JobsChartSegments.CanceledJobs) return "";
        else if ((s ?? body.segment) == JobsChartSegments.JobType) return "Type";
        else if ((s ?? body.segment) == JobsChartSegments.Metro) return "Metro";
        else if ((s ?? body.segment) == JobsChartSegments.Payments) return "Technician";
        else if ((s ?? body.segment) == JobsChartSegments.Technician) return "Technician";
        return "";
    }

    const segmentTitle = (s?: JobsChartSegments) => {
        if ((s ?? body.segment) == JobsChartSegments.Dispatchers) return "Dispatcher";
        //else if ((s ?? body.segment) == JobsChartSegments.Flags) return "Flags";
        else if ((s ?? body.segment) == JobsChartSegments.JobSource) return "Job Source";
        else if ((s ?? body.segment) == JobsChartSegments.ClosedJobs) return "Closed Jobs";
        else if ((s ?? body.segment) == JobsChartSegments.CanceledJobs) return "Canceled Jobs";
        else if ((s ?? body.segment) == JobsChartSegments.JobType) return "Job Type";
        else if ((s ?? body.segment) == JobsChartSegments.Metro) return "Metro";
        else if ((s ?? body.segment) == JobsChartSegments.Payments) return "Payment";
        else if ((s ?? body.segment) == JobsChartSegments.Technician) return "Technician";
        return "";
    }

    const segmentIcon = (s?: JobsChartSegments): FontAwesomeIcons => {
        if ((s ?? body.segment) == JobsChartSegments.Dispatchers) return "user-headset";
        //else if ((s ?? body.segment) == JobsChartSegments.Flags) return "flag";
        else if ((s ?? body.segment) == JobsChartSegments.JobSource) return "bullhorn";
        else if ((s ?? body.segment) == JobsChartSegments.ClosedJobs) return "circle";
        else if ((s ?? body.segment) == JobsChartSegments.CanceledJobs) return "circle";
        else if ((s ?? body.segment) == JobsChartSegments.JobType) return "wrench";
        else if ((s ?? body.segment) == JobsChartSegments.Metro) return "map";
        else if ((s ?? body.segment) == JobsChartSegments.Payments) return "credit-card";
        else if ((s ?? body.segment) == JobsChartSegments.Technician) return "user-helmet-safety";
        return "dot";
    }

    const selectSegmentItem = (item: TagifyDataItem) => {
        var arr = selectedSegmentItems ?? [];
        var ids = segmentIds ?? [];

        if (ids.indexOf(item.value) > -1) {
            arr.splice(ids.indexOf(item.value), 1);
            ids.splice(ids.indexOf(item.value), 1);
        }
        else {
            if (selectedSegmentItem) {
                arr.splice(ids.indexOf(selectedSegmentItem.value), 1);
                ids.splice(ids.indexOf(selectedSegmentItem.value), 1);
            }
            arr.push(item);
            ids.push(item.value);
        }

        selectedSegmentItemsSet(s => [...arr]);
        segmentIdsSet(s => [...ids]);
        bodySet({
            ...body,
            segmentIds: ids
        });
        window.closeModal("modal-segments-items");
    }

    const removeSegmentItem = (id: string) => {
        var arr = selectedSegmentItems ?? [];
        var ids = segmentIds ?? [];

        if (ids.indexOf(id) > -1) {
            arr.splice(ids.indexOf(id), 1);
            ids.splice(ids.indexOf(id), 1);
        }

        if (selectedSegmentItem) {
            arr.splice(ids.indexOf(selectedSegmentItem.value), 1);
            ids.splice(ids.indexOf(selectedSegmentItem.value), 1);
        }

        selectedSegmentItemsSet(s => [...arr]);
        segmentIdsSet(s => [...ids]);
        bodySet({
            ...body,
            segmentIds: ids
        });

    }

    const segmentSelected = (id: string) => {
        for (var i = 0; i < selectedSegmentItems.length; i++) {
            if (selectedSegmentItems[i].value == id) return true;
        }
        return false;
    }

    const addSegmentItem = (item?: TagifyDataItem) => {
        selectedSegmentItemSet(item);
        fetchSegmentData("", item);
        addSegmentSet(true);
    }

    const SortIcon = (props: { sortKey: string }) => (
        <>
            {
                sortBody.sortKey == props.sortKey ?
                    <>
                        {
                            sortBody.sortLoading ?
                                <Icon icon="spinner-third" spin />
                                :
                                <Icon icon={sortBody.sortType == SortTypes.Ascending ? "sort-down" : "sort-up"} type="solid" />
                        }
                    </>
                    : <></>
            }
        </>
    )



    const [settleWorking, settleWorkingSet] = useState<string>();
    const [groupSettleWorking, groupSettleWorkingSet] = useState<boolean>();
    const [groupUnSettleWorking, groupUnSettleWorkingSet] = useState<boolean>();
    const [selectedJobs, selectedJobsSet] = useState<string[]>([]);


    const showGroupSettle = () => {
        if (selectedJobs.length < 1) return false;
        if (!jobs) return false;
        var show = true;
        for (var i = 0; i < selectedJobs.length; i++) {
            var id = selectedJobs[i];
            var checked = false;
            for (var j = 0; j < (jobs.length ?? 0); j++) {
                if (jobs[j].id == id && jobs[j].isSetteled) {
                    show = false;
                    checked = true;
                    break;
                }
            }

            if (checked) break;
        }
        return show;
    }
    const showGroupUnSettle = () => {
        if (selectedJobs.length < 1) return false;
        if (!jobs) return false;
        var show = true;
        for (var i = 0; i < selectedJobs.length; i++) {
            var id = selectedJobs[i];
            var checked = false;
            for (var j = 0; j < (jobs.length ?? 0); j++) {
                if (jobs[j].id == id && !jobs[j].isSetteled) {
                    show = false;
                    checked = true;
                    break;
                }
            }

            if (checked) break;
        }
        return show;
    }

    const settleSelectedJobs = () => {
        groupSettleWorkingSet(true);
        hub.Post(API_ROUTES.Jobs + "settlejobs", selectedJobs, res => {
            if (res.success) {
                var arr = jobs ?? [];
                for (var i = 0; i < arr.length; i++) {
                    if (selectedJobs.indexOf(arr[i].id!) > -1) {
                        arr[i].isSetteled = true;
                    }
                }
                jobsSet(a => [...arr]);
                selectedJobsSet(a => [...[]]);
            }
            groupSettleWorkingSet(false);
        })
    }

    const unsettleSelectedJobs = () => {
        groupUnSettleWorkingSet(true);
        hub.Post(API_ROUTES.Jobs + "unsettlejobs", selectedJobs, res => {
            if (res.success) {
                var arr = jobs ?? [];
                for (var i = 0; i < arr.length; i++) {
                    if (selectedJobs.indexOf(arr[i].id!) > -1) {
                        arr[i].isSetteled = false;
                    }
                }
                jobsSet(a => [...arr]);
                selectedJobsSet(a => [...[]]);
            }
            groupUnSettleWorkingSet(false);
        })
    }


    const [selectedJob, selectedJobSet] = useState<Job>();
    const [reportIssueModal, reportIssueModalSet] = useState<boolean>(false);

    const [exportModal, exportModalSet] = useState<boolean>(false)

    const [dataColsVisibility, dataColsVisibilitySet] = useState<DataColumnsVisibility>()
    const [dataStatusCols, dataStatusColsSet] = useState<Array<string>>([])
    const [dataColsCount, dataColsCountSet] = useState<number>(0)
    useEffect(() => {
        if (!body.segment) return;
        if (!data) return;
        if (data.length < 1) return;

        var str = window.localStorage.getItem("jobs_reports_dataColsVisibility_" + body.segment.toString())

        if (str) {
            var dcv: DataColumnsVisibility = JSON.parse(str)
            dataColsVisibilitySet(dcv)
            dataStatusColsSet(dcv.statuses ?? [])
        }
        else {
            var stIds: string[] = [];
            data[0].statusCounts.forEach(s => stIds.push(s.id!))

            var dv: DataColumnsVisibility = {
                avgtick: true,
                convrate: true,
                gross: true,
                jobs: true,
                profit: true
            }
            dataColsVisibilitySet(dv)
            dataStatusColsSet(stIds)
        }

    }, [body.segment, data])

    useEffect(() => {
        if (dataColsVisibility) {
            var i = 1;
            if (dataColsVisibility.avgtick) i++
            if (dataColsVisibility.convrate) i++
            if (dataColsVisibility.gross) i++
            if (dataColsVisibility.jobs) i++
            if (dataColsVisibility.profit) i++
            i += dataStatusCols.length
            dataColsCountSet(i)

            var v = dataColsVisibility ?? {};
            v.statuses = dataStatusCols;
            window.localStorage.setItem("jobs_reports_dataColsVisibility_" + body.segment.toString(), JSON.stringify(v))
        }
    }, [dataColsVisibility, dataStatusCols])

    const [jobsColsVisibility, jobsColsVisibilitySet] = useState<JobsColumnsVisibility>()
    const [jobsColsCount, jobsColsCountSet] = useState<number>(0)
    useEffect(() => {
        if (!body.segment) return;
        var jcv = window.localStorage.getItem("jobs_reports_jobsColsVisibility_" + body.segment.toString())

        if (jcv) jobsColsVisibilitySet(JSON.parse(jcv))
        else {
            if (body.segment == JobsChartSegments.CanceledJobs) {
                var v: JobsColumnsVisibility = {
                    name: true,
                    address: true,
                    type: true,
                    source: true,
                    tech: true,
                    createdDate: true,
                    lastUpdate: true,
                    cancelNote: true
                }
                jobsColsVisibilitySet(v)

            }
            else {
                var v: JobsColumnsVisibility = {
                    name: true,
                    address: true,
                    type: true,
                    source: true,
                    createdDate: true,
                    lastUpdate: true,
                    total: true,
                    cash: true,
                    ccmanual: true,
                    cccharge: true,
                    check: true,
                    coParts: true,
                    techParts: true,
                    coProfits: true,
                    techProfits: true,
                    sourceProfits: true,
                    techBalance: true
                }
                jobsColsVisibilitySet(v)
            }
        }
    }, [body.segment])

    useEffect(() => {
        if (jobsColsVisibility) {
            var i = 0;
            Object.entries(jobsColsVisibility).forEach(([key, value]) => {
                if (value) i++;
            });
            jobsColsCountSet(i)
            window.localStorage.setItem("jobs_reports_jobsColsVisibility_" + body.segment.toString(), JSON.stringify(jobsColsVisibility))
        }
    }, [jobsColsVisibility])


    const [techColsVisibility, techColsVisibilitySet] = useState<TechPayColumnsVisibility>()
    const [techColsCount, techColsCountSet] = useState<number>(0)
    useEffect(() => {
        if (!body.segment) return;
        var tcv = window.localStorage.getItem("jobs_reports_techColsVisibility_" + body.segment.toString())
        if (tcv) techColsVisibilitySet(JSON.parse(tcv))
        else {
            var v: TechPayColumnsVisibility = {
                cash: true,
                check: true,
                closedJobs: true,
                metro: true,
                techBalance: true
            }
            techColsVisibilitySet(v)
        }
    }, [body.segment])
    useEffect(() => {
        if (techColsVisibility) {
            var i = 1;
            Object.entries(techColsVisibility).forEach(([key, value]) => {
                if (value) i++;
            });
            techColsCountSet(i)
            window.localStorage.setItem("jobs_reports_techColsVisibility_" + body.segment.toString(), JSON.stringify(techColsVisibility))
        }
    }, [techColsVisibility])



    const [filterModal, filterModalSet] = useState<boolean>(false);


    const getLastSentColor = (lastReportSent?: Date): string => {
        if (!lastReportSent) return "";

        const reportDate = new Date(lastReportSent);
        const today = new Date();
        const diffDays = Math.floor((today.getTime() - reportDate.getTime()) / (1000 * 60 * 60 * 24));

        if (diffDays <= 3) return "text-success";
        if (diffDays <= 6) return "text-warning";
        return "text-danger";
    };


    return (
        <Layout wide>


            <Section fluid >

                <div id="job-reports-stickybar">

                    <FlexRow>

                        <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center active" onClick={e => changeSegmentSet(true)}>
                            <Icon icon={segmentIcon()} type="solid" size={14} className="me-2" />
                            <span className="text-dark fw-bolder d-block fs-7">
                                {segmentTitle()}
                            </span>
                        </label>

                        {
                            selectedSegmentItems.map((item, key) =>
                                <label key={key} className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center active ms-3" onClick={e => {
                                    if ($(e.target).hasClass("remove-segment-item")) return;
                                    addSegmentItem(item);
                                }}>
                                    {
                                        body.segment == JobsChartSegments.Dispatchers || body.segment == JobsChartSegments.Technician ?
                                            <Avatar avatarUrl={item.avatar} width={16} fullName={item.title} />
                                            : <></>
                                    }

                                    <span className="text-dark d-block fs-7 ms-2">
                                        {item.title}
                                    </span>
                                    <Icon icon="times" className="ms-auto remove-segment-item"
                                        onClick={e => {
                                            removeSegmentItem(item.value);
                                        }} />
                                </label>
                            )
                        }

                        {
                            selectedSegmentItems.length < 3 && body.segment != JobsChartSegments.CanceledJobs && body.segment != JobsChartSegments.ClosedJobs &&
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center ms-3" onClick={e => addSegmentItem()}>
                                <Icon icon="plus" type="solid" size={14} className="me-2" />
                                <span className="text-gray-500 d-block fs-7">
                                    Add {segmentItemName()}
                                </span>
                            </label>
                        }

                        <FlexRow className="ms-auto">

                            <div className="w-250px me-2">
                                <select className="form-select form-select-sm form-inputs" value={body.dateId}
                                    onChange={e => {
                                        bodySet({
                                            ...body,
                                            dateId: e.target.value
                                        })
                                    }}>
                                    {
                                        dates.map((d, k) =>
                                            <option key={k} value={d.id}>{d.name}</option>
                                        )
                                    }
                                </select>
                            </div>

                            {
                                body.dateId == "99" &&
                                <div className="w-250px me-2">
                                    <div className="input-group" id="chart-date">
                                        <input type="text" className="form-control form-control-sm" value={body.startDate + " - " + body.toDate} />
                                        <span className="input-group-text">
                                            <i className="fas fa-calendar fs-4"></i>
                                        </span>
                                    </div>
                                </div>
                            }

                        </FlexRow>

                    </FlexRow>

                    <div className="mt-3">
                        <Filtering id="filtering-reports" clear={clearFilters}
                            filteringProps={body.segment == JobsChartSegments.Dispatchers ? FilteringPropsAgents
                                : body.segment == JobsChartSegments.Technician ? FilteringPropsTechs
                                    : body.segment == JobsChartSegments.JobSource ? FilteringPropsSources
                                        : body.segment == JobsChartSegments.JobType ? FilteringPropsTypes
                                            : body.segment == JobsChartSegments.Metro ? FilteringPropsMetros
                                                //: body.segment == JobsChartSegments.Flags ? FilteringPropsFlags
                                                : body.segment == JobsChartSegments.Payments && body.segmentIds.length > 0 ? FilteringPropsPaymentsDetails
                                                    : body.segment == JobsChartSegments.Payments ? FilteringPropsPayments
                                                        : body.segment == JobsChartSegments.ClosedJobs ? FilteringPropsClosedJobs
                                                            : body.segment == JobsChartSegments.CanceledJobs ? FilteringPropsClosedJobs
                                                                : []}
                            apiRoute={`/reports/jobs/data/${body.segment}/`}
                            filteringValues={filteringValues}
                            loaded={loaded}
                            onItemsChanged={(items, q) => {
                                filterQuerySet(q)
                            }} />
                    </div>

                </div>

                <div className="scroll"
                    style={{
                        height: boxHeight
                    }}>

                    <Div className="mt-20" visible={loading}>
                        <Indicator />
                    </Div>

                    <Div visible={!loading}>

                        {
                            body.segment == JobsChartSegments.CanceledJobs ? <></>
                                : body.segment == JobsChartSegments.ClosedJobs ? <></>
                                    : body.segment == JobsChartSegments.Payments ? <></>
                                        : body.segmentIds.length > 0 ? <></>
                                            :
                                            <div className="card mt-2">
                                                <div className="card-body">
                                                    <FlexRow>

                                                        <FlexRow>

                                                            <div style={{
                                                                position: "relative",
                                                                width: 160,
                                                                height: 160
                                                            }}>
                                                                <div id="jobchart-circle" data-kt-size="160" data-kt-line="20"></div>

                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: 60,
                                                                    right: 0,
                                                                    width: 160,
                                                                    height: 160,
                                                                    textAlign: "center"
                                                                }}>

                                                                    <div className="fs-3 fw-bolder text-center">
                                                                        Total Jobs
                                                                    </div>
                                                                    <div className="fs-2 fw-bolder text-center">
                                                                        {model?.pieChart?.total}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="ms-10">
                                                                <div className="row">
                                                                    {
                                                                        model?.pieChart?.statusCounts.map((st, k) =>
                                                                            <div key={k} className="col col-md-6">
                                                                                <FlexRow key={k} alignItems="start" className="mb-3">
                                                                                    <div>
                                                                                        <Icon icon="circle" type="solid" color={st.color} size={12} />
                                                                                    </div>
                                                                                    <div className="ms-2">
                                                                                        <div className="fw-bold" style={{ minWidth: 120 }}>
                                                                                            {st.name}
                                                                                        </div>
                                                                                        <FlexRow>
                                                                                            <div className="fw-bold">
                                                                                                {st.count}
                                                                                            </div>
                                                                                            <div className="ms-2 text-muted fs-8">
                                                                                                ({st.percent}%)
                                                                                            </div>
                                                                                        </FlexRow>
                                                                                    </div>
                                                                                </FlexRow>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>

                                                        </FlexRow>

                                                        <FlexRow className="ms-auto">

                                                            {/* <div className="card overflow-hidden h-md-50 me-2">
                                                                <div className="card-body bg-primary d-flex justify-content-between flex-column px-0 pb-0">
                                                                    <div className="mb-4 px-4" style={{ minWidth: 200 }}>
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <span className="fs-4 fw-bold text-gray-200 align-self-start me-1>">%</span>
                                                                            <span className="fs-2hx fw-bolder text-white me-2 lh-1">{model?.callsToJobRate}</span>
                                                                        </div>
                                                                        <span className="fs-6 fw-bold text-gray-300">Calls to Job CR</span>
                                                                    </div>
                                                                    <div id="kt_card_widget_1_chart" className="min-h-auto" style={{ height: 100 }}></div>
                                                                </div>
                                                            </div> */}

                                                            <div className="card overflow-hidden h-md-50 me-2">
                                                                <div className="card-body bg-info d-flex justify-content-between flex-column px-0 pb-0">
                                                                    <div className="mb-4 px-4" style={{ minWidth: 200 }}>
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <span className="fs-4 fw-bold text-gray-200 align-self-start me-1>">%</span>
                                                                            <span className="fs-2hx fw-bolder text-white me-2 lh-1">{model?.jobsToClosedRate}</span>
                                                                        </div>
                                                                        <span className="fs-6 fw-bold text-gray-300">Jobs to Closed CR</span>
                                                                    </div>
                                                                    <div id="kt_card_widget_2_chart" className="min-h-auto" style={{ height: 100 }}></div>
                                                                </div>
                                                            </div>

                                                            <div className="card overflow-hidden h-md-50">
                                                                <div className="card-body bg-danger d-flex justify-content-between flex-column px-0 pb-0">
                                                                    <div className="mb-4 px-4" style={{ minWidth: 200 }}>
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <span className="fs-4 fw-bold text-gray-200 align-self-start me-1>">$</span>
                                                                            <span className="fs-2hx fw-bolder text-white me-2 lh-1">
                                                                                {moneyFormatter.format(avTickets ?? 0).replace("$", "")}
                                                                            </span>
                                                                        </div>
                                                                        <span className="fs-6 fw-bold text-gray-300">Average Tickets</span>
                                                                    </div>
                                                                    <div id="kt_card_widget_3_chart" className="min-h-auto" style={{ height: 100 }}></div>
                                                                </div>
                                                            </div>

                                                        </FlexRow>

                                                    </FlexRow>
                                                </div>
                                            </div>
                        }


                        {/* {
                            body.segment == JobsChartSegments.Payments ? <></>
                            : body.segment == JobsChartSegments.ClosedJobs ? <></>
                            : body.segmentIds.length > 0 ? <></>
                            : (data?.length ?? 0 > 0) ?
                            <div className="card mt-2">
                                <div className="card-body">
                                    <div id="chart-target"></div> 
                                </div>
                            </div>
                            : <></>
                        } */}

                        {
                            data && data.length > 0 &&
                            <div className="card mt-2">
                                <div className="card-body">
                                    <FlexRow justify="end" className="mb-5">
                                        <div className="me-auto fs-5 fw-bolder">
                                            Report List
                                        </div>

                                        <button className={`btn btn-primary btn-sm me-2`}
                                            onClick={e => {
                                                e.preventDefault()
                                                exportModalSet(true)
                                            }}>
                                            <Icon icon="arrow-right-from-file" className="me-2 mb-1" />
                                            <span>Send Report</span>
                                        </button>
                                        <div>
                                            <button className="btn btn-light-primary btn-active-light-primary btn-sm"
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    window.dataTableMenu()
                                                }}>
                                                <span>Columns ({dataColsCount})</span>
                                                <Icon icon="chevron-down" className="ms-3" />
                                            </button>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                <div className="scroll-y h-300px">

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Total Jobs" checked={dataColsVisibility?.jobs} onChange={e => dataColsVisibilitySet({
                                                            ...dataColsVisibility,
                                                            jobs: e
                                                        })} />
                                                    </div>

                                                    {
                                                        data[0].statusCounts.map((st, k) =>
                                                            <div key={k} className="menu-item p-3 border-top">
                                                                <Checkbox label={st.name} checked={dataStatusCols.includes(st.id!)}
                                                                    onChange={e => {
                                                                        var v = dataStatusCols ?? []
                                                                        if (e && !v.includes(st.id!)) v.push(st.id!)
                                                                        else v.splice(v.indexOf(st.id!), 1)
                                                                        dataStatusColsSet(d => [...v])
                                                                    }} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Conv Rate" checked={dataColsVisibility?.convrate} onChange={e => dataColsVisibilitySet({
                                                            ...dataColsVisibility,
                                                            convrate: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Avg Tickets" checked={dataColsVisibility?.avgtick} onChange={e => dataColsVisibilitySet({
                                                            ...dataColsVisibility,
                                                            avgtick: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Total Gross" checked={dataColsVisibility?.gross} onChange={e => dataColsVisibilitySet({
                                                            ...dataColsVisibility,
                                                            gross: e
                                                        })} />
                                                    </div>

                                                    {
                                                        body.segment == JobsChartSegments.JobSource &&
                                                        <div className="menu-item p-3 border-top">
                                                            <Checkbox label="Source Profit" checked={dataColsVisibility?.sourceprofit} onChange={e => dataColsVisibilitySet({
                                                                ...dataColsVisibility,
                                                                sourceprofit: e
                                                            })} />
                                                        </div>
                                                    }

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Total Profit" checked={dataColsVisibility?.profit} onChange={e => dataColsVisibilitySet({
                                                            ...dataColsVisibility,
                                                            profit: e
                                                        })} />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </FlexRow>
                                    <table className="table table-hover table-sm align-middle table-row-dashed">
                                        <thead>
                                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                <th>
                                                    <FlexRow onClick={e => {
                                                        sortBodySet({
                                                            sortKey: "name",
                                                            sortLoading: true,
                                                            sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                        })
                                                    }}>
                                                        <span className="me-2">Name</span>
                                                        <SortIcon sortKey="name" />
                                                    </FlexRow>
                                                </th>

                                                {
                                                    dataColsVisibility?.jobs &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "jobs",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Jobs</span>
                                                            <SortIcon sortKey="jobs" />
                                                        </FlexRow>
                                                    </th>
                                                }

                                                {
                                                    data[0].statusCounts.filter(f => dataStatusCols.includes(f.id!)).map((st, k) =>
                                                        <th key={k}>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "status-" + st.id,
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">{st.name}</span>
                                                                <SortIcon sortKey={"status-" + st.id} />
                                                            </FlexRow>
                                                        </th>
                                                    )
                                                }

                                                {
                                                    dataColsVisibility?.convrate &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "conv",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">CONV Rate</span>
                                                            <SortIcon sortKey="conv" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    dataColsVisibility?.avgtick &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "avgtickets",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Avg tickets</span>
                                                            <SortIcon sortKey="avgtickets" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    dataColsVisibility?.gross &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "totalgross",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Total Gross</span>
                                                            <SortIcon sortKey="totalgross" />
                                                        </FlexRow>

                                                    </th>
                                                }
                                                {
                                                    dataColsVisibility?.sourceprofit && body.segment == JobsChartSegments.JobSource &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "sourceprofit",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Source Profit</span>
                                                            <SortIcon sortKey="sourceprofit" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    dataColsVisibility?.profit &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "totalprofit",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Total Profit</span>
                                                            <SortIcon sortKey="totalprofit" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((row, k) =>
                                                    <tr key={k} className={"fs-8" + (row.id == "total" ? " fw-bolder reports-total-row" : "")}>
                                                        <td className="ps-1">
                                                            {
                                                                row.id == "total" ?
                                                                    <span>{row.name}</span>
                                                                    :
                                                                    <a href="#"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            selectSegmentItem({
                                                                                title: row.name ?? "",
                                                                                value: row.id ?? "",
                                                                                avatar: row.avatar
                                                                            });
                                                                        }}>
                                                                        {row.name}
                                                                    </a>
                                                            }
                                                        </td>
                                                        {
                                                            dataColsVisibility?.jobs &&
                                                            <td>
                                                                {
                                                                    row.totalJobs > 0 ?
                                                                        <a href="#" onClick={e => {
                                                                            e.preventDefault()
                                                                            openReportJobsList({
                                                                                name: row.name!,
                                                                                dateId: body.dateId,
                                                                                timezone: body.timezone ?? "job",
                                                                                segment: body.segment,
                                                                                startDate: body.startDate,
                                                                                toDate: body.toDate,
                                                                                segmentId: row.id
                                                                            })
                                                                        }}>
                                                                            {row.totalJobs}
                                                                        </a>
                                                                        :
                                                                        <>{row.totalJobs}</>
                                                                }
                                                            </td>
                                                        }

                                                        {
                                                            row.statusCounts.filter(f => dataStatusCols.includes(f.id!)).map((st, sk) =>
                                                                <td key={sk}>
                                                                    {
                                                                        st.isCanceledStatus || st.isClosedStatus ?
                                                                            <div style={{ width: 30, height: 30 }} title={`${st.percent}%`}>
                                                                                {
                                                                                    st.jobsCount && st.jobsCount > 0 ?
                                                                                        <a href="#" onClick={e => {
                                                                                            e.preventDefault()
                                                                                            openReportJobsList({
                                                                                                name: row.name!,
                                                                                                segment: body.segment,
                                                                                                segmentId: row.id,
                                                                                                dateId: body.dateId,
                                                                                                startDate: body.startDate,
                                                                                                timezone: body.timezone ?? "job",
                                                                                                toDate: body.toDate,
                                                                                                status: st
                                                                                            })
                                                                                        }}>
                                                                                            <CircularProgressbar
                                                                                                value={st.percent ?? 0}
                                                                                                text={st.jobsCount?.toString()}
                                                                                                styles={buildStyles({
                                                                                                    textColor: "#333",
                                                                                                    textSize: 34,
                                                                                                    pathColor: st.color
                                                                                                })}
                                                                                            />
                                                                                        </a>
                                                                                        :
                                                                                        <CircularProgressbar
                                                                                            value={st.percent ?? 0}
                                                                                            text={st.jobsCount?.toString()}
                                                                                            styles={buildStyles({
                                                                                                textColor: "#333",
                                                                                                textSize: 34,
                                                                                                pathColor: st.color
                                                                                            })}
                                                                                        />
                                                                                }

                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    st.jobsCount && st.jobsCount > 0 ?
                                                                                        <a href="#" onClick={e => {
                                                                                            e.preventDefault()
                                                                                            openReportJobsList({
                                                                                                name: row.name!,
                                                                                                segment: body.segment,
                                                                                                segmentId: row.id,
                                                                                                dateId: body.dateId,
                                                                                                startDate: body.startDate,
                                                                                                timezone: body.timezone ?? "job",
                                                                                                toDate: body.toDate,
                                                                                                status: st
                                                                                            })
                                                                                        }}>
                                                                                            {st.jobsCount}
                                                                                        </a>
                                                                                        :
                                                                                        <>{st.jobsCount}</>
                                                                                }
                                                                            </>
                                                                    }
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            dataColsVisibility?.convrate &&
                                                            <td>
                                                                <div style={{ width: 30, height: 30 }}>
                                                                    <CircularProgressbar
                                                                        value={row.conversionRate}
                                                                        text={`${row.conversionRate}%`}
                                                                        styles={{
                                                                            text: {
                                                                                fontSize: 30,
                                                                                fill: "#333"
                                                                            }
                                                                        }} />
                                                                </div>
                                                            </td>
                                                        }

                                                        {
                                                            dataColsVisibility?.avgtick && <td>{moneyFormatter.format(row.averageTickets ?? 0)}</td>
                                                        }
                                                        {
                                                            dataColsVisibility?.gross && <td>{moneyFormatter.format(row.totalGross ?? 0)}</td>
                                                        }
                                                        {
                                                            dataColsVisibility?.sourceprofit && body.segment == JobsChartSegments.JobSource &&
                                                            <td>{moneyFormatter.format(row.sourceProfit ?? 0)}</td>
                                                        }
                                                        {
                                                            dataColsVisibility?.profit && <td>{moneyFormatter.format(row.totalProfit ?? 0)}</td>
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {
                            payments && payments.length > 0 &&
                            <div className="card mt-2">
                                <div className="card-body">
                                    <FlexRow justify="end" className="mb-5">
                                        <div className="me-auto fs-4 fw-bolder">
                                            Technicians List
                                        </div>

                                        <button className={`btn btn-primary btn-sm me-2`}
                                            onClick={e => {
                                                e.preventDefault()
                                                exportModalSet(true)
                                            }}>
                                            <Icon icon="arrow-right-from-file" className="me-2 mb-1" />
                                            <span>Send Report</span>
                                        </button>
                                        <div>
                                            <button className="btn btn-light-primary btn-active-light-primary btn-sm"
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    window.dataTableMenu()
                                                }}>
                                                <span>Columns ({techColsCount})</span>
                                                <Icon icon="chevron-down" className="ms-3" />
                                            </button>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                <div className="scroll-y h-300px">

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Phone" checked={techColsVisibility?.phone} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            phone: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Metros" checked={techColsVisibility?.metro} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            metro: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Types" checked={techColsVisibility?.type} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            type: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Sources" checked={techColsVisibility?.source} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            source: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Closed Jobs" checked={techColsVisibility?.closedJobs} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            closedJobs: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Cash" checked={techColsVisibility?.cash} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            cash: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Check" checked={techColsVisibility?.check} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            check: e
                                                        })} />
                                                    </div>

                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Balance" checked={techColsVisibility?.techBalance} onChange={e => techColsVisibilitySet({
                                                            ...techColsVisibility,
                                                            techBalance: e
                                                        })} />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </FlexRow>
                                    <table className="table table-hover table-sm align-middle table-row-dashed">
                                        <thead>
                                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                <th>
                                                    <FlexRow onClick={e => {
                                                        sortBodySet({
                                                            sortKey: "tech",
                                                            sortLoading: true,
                                                            sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                        })
                                                    }}>
                                                        <span className="me-2">Technician</span>
                                                        <SortIcon sortKey="tech" />
                                                    </FlexRow>
                                                </th>
                                                {
                                                    techColsVisibility?.phone &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "phone",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Phone</span>
                                                            <SortIcon sortKey="phone" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    techColsVisibility?.metro && <th>Metro</th>
                                                }
                                                {
                                                    techColsVisibility?.type && <th>Types</th>
                                                }
                                                {
                                                    techColsVisibility?.source && <th>Sources</th>
                                                }

                                                {
                                                    techColsVisibility?.closedJobs &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "closed",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Closed Jobs</span>
                                                            <SortIcon sortKey="closed" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    techColsVisibility?.cash &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "cash",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Cash</span>
                                                            <SortIcon sortKey="cash" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    techColsVisibility?.check &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "check",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Check</span>
                                                            <SortIcon sortKey="check" />
                                                        </FlexRow>
                                                    </th>
                                                }
                                                {
                                                    techColsVisibility?.techBalance &&
                                                    <th>
                                                        <FlexRow onClick={e => {
                                                            sortBodySet({
                                                                sortKey: "balance",
                                                                sortLoading: true,
                                                                sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                            })
                                                        }}>
                                                            <span className="me-2">Balance</span>
                                                            <SortIcon sortKey="balance" />
                                                        </FlexRow>
                                                    </th>
                                                }

                                                <th>
                                                    <FlexRow onClick={e => {
                                                        sortBodySet({
                                                            sortKey: "lastSent",
                                                            sortLoading: true,
                                                            sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                        })
                                                    }}>
                                                        <span className="me-2">Last Sent Date</span>
                                                        <SortIcon sortKey="lastSent" />
                                                    </FlexRow>
                                                </th>

                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                payments.map(row =>
                                                    <tr key={`payment-row-${row.tech?.id}`} className={"fs-8" + (row.isTotalRow ? " fw-bolder reports-total-row" : "")}>
                                                        <td className="ps-1">
                                                            {
                                                                row.isTotalRow ?
                                                                    <span>Total</span>
                                                                    :
                                                                    <a href="#"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            selectSegmentItem({
                                                                                title: row.tech?.fullName ?? "",
                                                                                value: row.tech?.id ?? "",
                                                                                avatar: row.tech?.avatarUrl ?? ""
                                                                            });
                                                                        }}>
                                                                        {row.tech?.fullName}
                                                                    </a>
                                                            }
                                                        </td>

                                                        {
                                                            techColsVisibility?.phone && <td>{row.tech?.phoneNumber}</td>
                                                        }
                                                        {
                                                            techColsVisibility?.metro &&
                                                            <td>
                                                                <div className="limited-text mw-200px" title={row.metros}>
                                                                    {row.metros}
                                                                </div>
                                                            </td>
                                                        }
                                                        {
                                                            techColsVisibility?.type && <td>{row.types}</td>
                                                        }
                                                        {
                                                            techColsVisibility?.source && <td>{row.sources}</td>
                                                        }

                                                        {
                                                            techColsVisibility?.closedJobs && <td>{row.closedJobs}</td>
                                                        }
                                                        {
                                                            techColsVisibility?.cash && <td>{moneyFormatter.format(row.cash ?? 0)}</td>
                                                        }
                                                        {
                                                            techColsVisibility?.check && <td>{moneyFormatter.format(row.check ?? 0)}</td>
                                                        }
                                                        {
                                                            techColsVisibility?.techBalance &&
                                                            <td className={(row.balance ?? 0) < 0 ? "text-danger" : "text-success"}>
                                                                {(row.balance ?? 0) < 0 ? "" : "+"}
                                                                {moneyFormatter.format(row.balance ?? 0)}
                                                            </td>
                                                        }
                                                        <td>
                                                            {
                                                                row.lastReportSent ?
                                                                    <div className={`${getLastSentColor(row.lastReportSent)}`}>
                                                                        {toPrettyDate(row.lastReportSent)}
                                                                    </div>
                                                                    :
                                                                    <span>-</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                !row.isTotalRow &&
                                                                <a href="#" className="badge badge-light-primary" title="Send Report"
                                                                    onClick={e => {
                                                                        e.preventDefault()
                                                                        exportModalSet(true)
                                                                        defaultSegmentIdSet(row.tech?.id)
                                                                        exportTechIdSet(row.tech?.id)
                                                                    }}>
                                                                    <Icon icon="share" className="me-2" />
                                                                </a>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {
                            jobs && jobs.length > 0 &&
                            <div className="card mt-2">
                                <div className="card-body">
                                    <FlexRow justify="end" className="mb-5">
                                        <div className="me-auto fs-4 fw-bolder">
                                            Jobs List
                                        </div>
                                        {
                                            showGroupUnSettle() &&
                                            <button className="btn btn-warning btn-sm me-2 w-200px" disabled={groupUnSettleWorking} onClick={unsettleSelectedJobs}>
                                                <FlexRow>
                                                    <div className="me-2">
                                                        <Icon icon={groupUnSettleWorking ? "spinner-third" : "circle-exclamation"} className="mb-1" spin={groupUnSettleWorking} />
                                                    </div>
                                                    <div>Unsettle Selected Jobs</div>
                                                </FlexRow>
                                            </button>
                                        }
                                        {
                                            showGroupSettle() &&
                                            <button className="btn btn-success btn-sm me-2 w-200px" disabled={groupSettleWorking} onClick={settleSelectedJobs}>
                                                <FlexRow>
                                                    <div className="me-2">
                                                        <Icon icon={groupSettleWorking ? "spinner-third" : "check"} className="mb-1" spin={groupSettleWorking} />
                                                    </div>
                                                    <div>Settle Selected Jobs</div>
                                                </FlexRow>
                                            </button>
                                        }

                                        <button className={`btn btn-primary btn-sm me-2`}
                                            onClick={e => {
                                                e.preventDefault();
                                                //exportExcelWorkingSet(true);
                                                exportModalSet(true)
                                            }}>
                                            <Icon icon="arrow-right-from-file" className="me-2 mb-1" />
                                            <span>Send Jobs Report</span>
                                        </button>
                                        <div>
                                            <button className="btn btn-light-primary btn-active-light-primary btn-sm"
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    window.dataTableMenu()
                                                }}>
                                                <span>Columns ({jobsColsCount})</span>
                                                <Icon icon="chevron-down" className="ms-3" />
                                            </button>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                {/* <div className="p-2">
                                                        <a href="#" className="p-3"
                                                            onClick={e=> e.preventDefault()}>
                                                            Show All
                                                        </a>
                                                    </div> */}
                                                <div className="scroll-y h-300px">
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Status" checked={jobsColsVisibility?.status} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            status: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Name" checked={jobsColsVisibility?.name} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            name: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Phone" checked={jobsColsVisibility?.phone} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            phone: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Job Type" checked={jobsColsVisibility?.type} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            type: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Job Source" checked={jobsColsVisibility?.source} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            source: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Address" checked={jobsColsVisibility?.address} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            address: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Metro" checked={jobsColsVisibility?.metro} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            metro: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Tech" checked={jobsColsVisibility?.tech} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            tech: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label="Created At" checked={jobsColsVisibility?.createdDate} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            createdDate: e
                                                        })} />
                                                    </div>
                                                    <div className="menu-item p-3 border-top">
                                                        <Checkbox label={body.segment == JobsChartSegments.CanceledJobs ? "Canceled At" : body.segment == JobsChartSegments.ClosedJobs ? "Closed At" : "Last Update"} checked={jobsColsVisibility?.lastUpdate} onChange={e => jobsColsVisibilitySet({
                                                            ...jobsColsVisibility,
                                                            lastUpdate: e
                                                        })} />
                                                    </div>
                                                    {
                                                        body.segment == JobsChartSegments.CanceledJobs &&
                                                        <div className="menu-item p-3 border-top">
                                                            <Checkbox label="Note" checked={jobsColsVisibility?.cancelNote} onChange={e => jobsColsVisibilitySet({
                                                                ...jobsColsVisibility,
                                                                cancelNote: e
                                                            })} />
                                                        </div>
                                                    }
                                                    {
                                                        body.segment != JobsChartSegments.CanceledJobs &&
                                                        <>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Inv Number" checked={jobsColsVisibility?.invNo} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    invNo: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Total" checked={jobsColsVisibility?.total} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    total: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Cash" checked={jobsColsVisibility?.cash} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    cash: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="CC Manual" checked={jobsColsVisibility?.ccmanual} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    ccmanual: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="CC Charge" checked={jobsColsVisibility?.cccharge} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    cccharge: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Check" checked={jobsColsVisibility?.check} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    check: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Tech Parts" checked={jobsColsVisibility?.techParts} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    techParts: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="CO Parts" checked={jobsColsVisibility?.coParts} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    coParts: e
                                                                })} />
                                                            </div>

                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Tech Profit" checked={jobsColsVisibility?.techProfits} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    techProfits: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Source Profit" checked={jobsColsVisibility?.sourceProfits} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    sourceProfits: e
                                                                })} />
                                                            </div>
                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="CO Profit" checked={jobsColsVisibility?.coProfits} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    coProfits: e
                                                                })} />
                                                            </div>

                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Tech Fee" checked={jobsColsVisibility?.techFee} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    techFee: e
                                                                })} />
                                                            </div>

                                                            <div className="menu-item p-3 border-top">
                                                                <Checkbox label="Tech Balance" checked={jobsColsVisibility?.techBalance} onChange={e => jobsColsVisibilitySet({
                                                                    ...jobsColsVisibility,
                                                                    techBalance: e
                                                                })} />
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </FlexRow>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-sm align-middle table-row-dashed">
                                            <thead>
                                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                    {
                                                        (body.segment == JobsChartSegments.Payments || body.segment == JobsChartSegments.ClosedJobs) &&
                                                        <>
                                                            <th>
                                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                    <input className="form-check-input" type="checkbox" checked={selectedJobs.length == jobs.length}
                                                                        onChange={e => {
                                                                            if (e.target.checked) {
                                                                                var arr = selectedJobs;
                                                                                jobs.forEach(j => {
                                                                                    if (arr.indexOf(j.id!) < 0) {
                                                                                        arr.push(j.id!);
                                                                                    }
                                                                                });
                                                                                selectedJobsSet(j => [...arr]);
                                                                            }
                                                                            else {
                                                                                selectedJobsSet(j => [...[]]);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th></th>
                                                        </>
                                                    }

                                                    <th>Job ID</th>
                                                    {
                                                        jobsColsVisibility?.status &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "status",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Status</span>
                                                                <SortIcon sortKey="status" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.name &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "name",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Name</span>
                                                                <SortIcon sortKey="name" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.phone &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "phone",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Phone</span>
                                                                <SortIcon sortKey="phone" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.type &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "type",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Type</span>
                                                                <SortIcon sortKey="type" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.source &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "source",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Source</span>
                                                                <SortIcon sortKey="source" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.address &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "address",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Address</span>
                                                                <SortIcon sortKey="address" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.metro &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "metro",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Metro</span>
                                                                <SortIcon sortKey="metro" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.tech &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "tech",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Tech</span>
                                                                <SortIcon sortKey="tech" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.createdDate &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "createdAt",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">Created</span>
                                                                <SortIcon sortKey="createdAt" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.lastUpdate &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "lastUpdate",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-1">
                                                                    {
                                                                        body.segment == JobsChartSegments.CanceledJobs ? "Canceled" : body.segment == JobsChartSegments.ClosedJobs ? "Closed" : "Updated"
                                                                    }
                                                                </span>
                                                                <SortIcon sortKey="lastUpdate" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.cancelNote &&
                                                        <th>
                                                            Note
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.invNo &&
                                                        <th title="Invoice Number">
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "invNumber",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Inv NO</span>
                                                                <SortIcon sortKey="invNumber" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.total &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "total",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Total</span>
                                                                <SortIcon sortKey="total" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.cash &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "cash",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Cash</span>
                                                                <SortIcon sortKey="cash" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.ccmanual &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "ccmanual",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">CC Manual</span>
                                                                <SortIcon sortKey="ccmanual" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.cccharge &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "credit",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">CC Charge</span>
                                                                <SortIcon sortKey="credit" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.cccharge &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "ccrate",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">CC Rate</span>
                                                                <SortIcon sortKey="ccrate" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.check &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "check",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Check</span>
                                                                <SortIcon sortKey="check" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.techParts &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "techparts",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Tech Parts</span>
                                                                <SortIcon sortKey="techparts" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.coParts &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "coparts",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Co Parts</span>
                                                                <SortIcon sortKey="coparts" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.techProfits &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "techprofit",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Tech Profits</span>
                                                                <SortIcon sortKey="techprofit" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.sourceProfits &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "sourceprofit",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Source Profits</span>
                                                                <SortIcon sortKey="sourceprofit" />
                                                            </FlexRow>
                                                        </th>
                                                    }
                                                    {
                                                        jobsColsVisibility?.coProfits &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "coprofit",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Co Profits</span>
                                                                <SortIcon sortKey="coprofit" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.techFee &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "techFee",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Tech Fee</span>
                                                                <SortIcon sortKey="techFee" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                    {
                                                        jobsColsVisibility?.techBalance &&
                                                        <th>
                                                            <FlexRow onClick={e => {
                                                                sortBodySet({
                                                                    sortKey: "techbalance",
                                                                    sortLoading: true,
                                                                    sortType: sortBody.sortType == SortTypes.Ascending ? SortTypes.Descending : SortTypes.Ascending
                                                                })
                                                            }}>
                                                                <span className="me-2">Tech Balance</span>
                                                                <SortIcon sortKey="techbalance" />
                                                            </FlexRow>
                                                        </th>
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    jobs.map(job =>
                                                        <tr key={`job-row-${job.id}`} className={`fs-8 `}>
                                                            {
                                                                (body.segment == JobsChartSegments.Payments || body.segment == JobsChartSegments.ClosedJobs) &&
                                                                <>
                                                                    <td>
                                                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                            <input className="form-check-input" type="checkbox" checked={selectedJobs.indexOf(job.id!) > -1}
                                                                                onChange={e => {
                                                                                    if (e.target.checked) {
                                                                                        var arr = selectedJobs;
                                                                                        var exists = false;
                                                                                        for (var i = 0; i < arr.length; i++) {
                                                                                            if (arr[i] == job.id) {
                                                                                                exists = true;
                                                                                                break;
                                                                                            }
                                                                                        }
                                                                                        if (!exists) {
                                                                                            arr.push(job.id!);
                                                                                            selectedJobsSet(j => [...arr]);
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        var arr = selectedJobs;
                                                                                        for (var i = 0; i < arr.length; i++) {
                                                                                            if (arr[i] == job.id) {
                                                                                                arr.splice(i, 1);
                                                                                                break;
                                                                                            }
                                                                                        }

                                                                                        selectedJobsSet(j => [...arr]);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <FlexRow>
                                                                            <a href="#" title={job.hasIssue ? "Resolve Issue" : "Report Issue"} className="me-3"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    selectedJobSet(job);
                                                                                    reportIssueModalSet(true);
                                                                                }}>
                                                                                <Icon icon="circle-exclamation" size={18} className={job.hasIssue ? "text-danger" : "text-muted"} type={job.hasIssue ? "solid" : "regular"} />
                                                                            </a>
                                                                            <div>
                                                                                <span className={`badge text-start cursor-pointer ${(job.isSetteled ? "badge-success" : "badge-warning")}`}
                                                                                    onClick={e => {
                                                                                        window.KTMenuCreate();
                                                                                    }}
                                                                                    data-kt-menu-trigger="click"
                                                                                    data-kt-menu-placement="bottom-start">
                                                                                    <FlexRow>
                                                                                        <div className="me-2">
                                                                                            {
                                                                                                settleWorking == job.id ? <Icon icon="spinner-third" spin className="p-0 m-0" />
                                                                                                    : job.isSetteled ? <Icon icon="check" className="p-0 m-0" />
                                                                                                        : <Icon icon="info-circle" className="p-0 m-0" />
                                                                                            }
                                                                                        </div>
                                                                                        <div className="me-3">
                                                                                            {job.isSetteled ? "Settled" : "Unsettle"}
                                                                                        </div>
                                                                                        <div>
                                                                                            <Icon icon="chevron-down" className="text-white" size={10} />
                                                                                        </div>
                                                                                    </FlexRow>
                                                                                </span>
                                                                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-1" data-kt-menu="true">
                                                                                    {
                                                                                        job.isSetteled ?
                                                                                            <div className="menu-item">
                                                                                                <a href="#" className="menu-link px-3" onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    settleWorkingSet(job.id);
                                                                                                    setTimeout(() => {
                                                                                                        hub.Post(API_ROUTES.Jobs + "unsettlejob", {
                                                                                                            id: job.id
                                                                                                        }, res => {
                                                                                                            if (res.success) {
                                                                                                                var arr = jobs ?? [];
                                                                                                                for (var i = 0; i < arr.length; i++) {
                                                                                                                    if (arr[i].id == job.id) {
                                                                                                                        arr[i].isSetteled = false;
                                                                                                                    }
                                                                                                                }
                                                                                                                jobsSet(j => [...arr]);
                                                                                                                settleWorkingSet(undefined);
                                                                                                            }
                                                                                                        })
                                                                                                    }, 200);

                                                                                                }}>
                                                                                                    <Icon icon="circle" type="solid" className="me-2 text-warning" />
                                                                                                    Unsettle Job
                                                                                                </a>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="menu-item">
                                                                                                <a href="#" className="menu-link px-3" onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    settleWorkingSet(job.id);
                                                                                                    setTimeout(() => {
                                                                                                        hub.Post(API_ROUTES.Jobs + "settlejob", {
                                                                                                            id: job.id
                                                                                                        }, res => {
                                                                                                            var arr = jobs ?? [];
                                                                                                            for (var i = 0; i < arr.length; i++) {
                                                                                                                if (arr[i].id == job.id) {
                                                                                                                    arr[i].isSetteled = true;
                                                                                                                }
                                                                                                            }
                                                                                                            jobsSet(j => [...arr]);
                                                                                                            settleWorkingSet(undefined);
                                                                                                        })
                                                                                                    }, 200);
                                                                                                }}>
                                                                                                    <Icon icon="circle" type="solid" className="me-2 text-success" />
                                                                                                    Settle Job
                                                                                                </a>
                                                                                            </div>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </FlexRow>
                                                                    </td>
                                                                </>
                                                            }

                                                            <td>
                                                                <a href="#" onClick={e => {
                                                                    e.preventDefault();
                                                                    if (job.id) openJobQuickView(job.id)
                                                                }}>
                                                                    #{job.jobId}
                                                                </a>
                                                            </td>

                                                            {
                                                                jobsColsVisibility?.status &&
                                                                <td className="min-75">
                                                                    <FlexRow title={job.jobStatus?.name}>
                                                                        <Icon icon="circle" type="solid" color={job.jobStatus?.color} className="me-1" />
                                                                        {job.jobStatus?.name}
                                                                    </FlexRow>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.name &&
                                                                <td className="min-75">
                                                                    <div title={job.fullName}>
                                                                        {job.fullName}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.phone &&
                                                                <td className="min-75">
                                                                    <div title={job.phone?.phoneFriendly}>
                                                                        {job.phone?.countryCode + "" + job.phone?.phone}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.type &&
                                                                <td className="min-75">
                                                                    <div title={job.jobType}>
                                                                        {job.jobType}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.source &&
                                                                <td className="min-75">
                                                                    <div title={job.jobSource}>
                                                                        {job.jobSource}
                                                                    </div>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.address &&
                                                                <td className="min-75">
                                                                    <div>
                                                                        {job.address?.title}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.metro &&
                                                                <td className="min-75">
                                                                    <div>
                                                                        {job.metroName}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.tech &&
                                                                <td className="min-75">
                                                                    <div>
                                                                        {job.tech?.fullName}
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                jobsColsVisibility?.createdDate &&
                                                                <td className="min-100">
                                                                    <div>
                                                                        {toPrettyDate(job.createdAt, selectedTimeZone?.id == "job" ? job.timezoneOffset : selectedTimeZone?.offset)}
                                                                    </div>
                                                                    <div className="max-100 limited-text" title={job.creatorUser?.fullName}>
                                                                        by: {job.creatorUser?.fullName}
                                                                    </div>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.lastUpdate &&
                                                                <td className="min-100">
                                                                    <div>
                                                                        {toPrettyDate(job.lastStatusUpdatedAt, selectedTimeZone?.id == "job" ? job.timezoneOffset : selectedTimeZone?.offset)}
                                                                    </div>
                                                                    <div className="max-100 limited-text" title={job.lastStatusUser?.fullName}>
                                                                        by: {job.lastStatusUser?.fullName}
                                                                    </div>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.cancelNote &&
                                                                <td className="min-200">
                                                                    <div>
                                                                        {job.lastStatusNote}
                                                                    </div>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.invNo &&
                                                                <td>
                                                                    <div>
                                                                        {
                                                                            job.payment ?
                                                                                <>{job.payment.externalInvoiceNumber}</>
                                                                                : <>-</>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.total &&
                                                                <td>
                                                                    {
                                                                        job.payment ?
                                                                            <>{moneyFormatter.format(job.payment.totalPayment ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.cash &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.totalCash ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.ccmanual &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.totalCCManual ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.cccharge &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.totalCCCharge ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.cccharge &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>
                                                                                <div>
                                                                                    {moneyFormatter.format(job.paymentDetails.totalCCChargeRate ?? 0)}
                                                                                </div>
                                                                                <div className="fs-9">
                                                                                    ({job.paymentDetails.ccRate}%)
                                                                                </div>
                                                                            </>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.check &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.totalCheck ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.techParts &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.techParts ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.coParts &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.companyParts ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.techProfits &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>
                                                                                <div>
                                                                                    {moneyFormatter.format(job.paymentDetails.techProfit ?? 0)}
                                                                                </div>
                                                                                <div className="fs-9">
                                                                                    ({job.paymentDetails.techRate}%)
                                                                                </div>
                                                                            </>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.sourceProfits &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.sourceProfit ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.coProfits &&
                                                                <td>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>{moneyFormatter.format(job.paymentDetails.companyProfit ?? 0)}</>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.techFee &&
                                                                <td>
                                                                    <>{moneyFormatter.format(job.paymentDetails?.techFee ?? 0)}</>
                                                                </td>
                                                            }

                                                            {
                                                                jobsColsVisibility?.techBalance &&
                                                                <td className={!job.paymentDetails ? "" : (job.paymentDetails?.techBalance ?? 0) < 0 ? "text-danger" : "text-success"}>
                                                                    {
                                                                        job.paymentDetails ?
                                                                            <>
                                                                                {!job.paymentDetails ? "" : (job.paymentDetails?.techBalance ?? 0) < 0 ? "" : "+"}
                                                                                {moneyFormatter.format(job.paymentDetails.techBalance ?? 0)}
                                                                            </>
                                                                            : <>-</>
                                                                    }
                                                                </td>
                                                            }


                                                        </tr>
                                                    )
                                                }

                                                {
                                                    body.segment != JobsChartSegments.CanceledJobs &&
                                                    <tr className="fw-bolder reports-total-row">
                                                        {
                                                            (body.segment == JobsChartSegments.Payments || body.segment == JobsChartSegments.ClosedJobs) &&
                                                            <>
                                                                <td></td>
                                                                <td></td>
                                                            </>
                                                        }

                                                        {
                                                            jobsColsVisibility?.status && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.name && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.phone && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.type && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.source && <td></td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.address && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.metro && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.tech && <td></td>
                                                        }
                                                        {
                                                            jobsColsVisibility?.createdDate && <td></td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.lastUpdate && <td></td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.cancelNote && <td></td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.invNo && <td></td>
                                                        }

                                                        <td className="text-center">Total</td>

                                                        {
                                                            jobsColsVisibility?.total &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.amount ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.cash &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.totalCash ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.ccmanual &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.totalCCManual ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.cccharge &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.totalCCCharge ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.cccharge &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.totalCCChargeRate ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.check &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.totalCheck ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.techParts &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.techParts ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.coParts &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.companyParts ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.techProfits &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.techProfit ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.sourceProfits &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.sourceProfit ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.coProfits &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.companyProfit ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.techFee &&
                                                            <td>{moneyFormatter.format(jobsTotalRow?.techFee ?? 0)}</td>
                                                        }

                                                        {
                                                            jobsColsVisibility?.techBalance &&
                                                            <td className={(jobsTotalRow?.techBalance ?? 0) < 0 ? "text-danger" : "text-success"}>
                                                                {(jobsTotalRow?.techBalance ?? 0) < 0 ? "" : "+"}
                                                                {moneyFormatter.format(jobsTotalRow?.techBalance ?? 0)}
                                                            </td>
                                                        }
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            ((!data || data.length == 0) && (!payments || payments.length == 0) && (!jobs || jobs.length == 0)) &&
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-px text-center pt-15 pb-15">
                                        <h2 className="fs-2x fw-bolder mb-0">
                                            Nothing Found!
                                        </h2>
                                        <p className="text-gray-400 fs-4 fw-bold py-7">
                                            No data available for current filter selection!
                                        </p>
                                    </div>
                                    <div className="text-center pb-15 px-5">
                                        <img src="/assets/media/illustrations/sketchy-1/5.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                    </div>
                                </div>
                            </div>
                        }

                    </Div>

                </div>

            </Section>

            {
                changeSegment &&
                <Modal id="modal-segments"
                    title="Segments"
                    open visible
                    onClose={() => changeSegmentSet(false)}>
                    <div>
                        {
                            Object.values(JobsChartSegments)
                                .filter(a => typeof a !== "string")
                                .map((s, k) =>
                                    <label key={k} onClick={e => updateSegment(s as JobsChartSegments)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-4 min-w-200px d-flex align-items-center mb-2" + (body.segment == s ? " active" : "")}>
                                        <Icon icon={segmentIcon(s as JobsChartSegments)} type="solid" size={16} className="me-2" />
                                        <span className="text-dark fw-bolder d-block fs-6">
                                            {segmentTitle(s as JobsChartSegments)}
                                        </span>
                                    </label>
                                )
                        }

                    </div>
                </Modal>
            }

            {
                addSegment &&
                <Modal id="modal-segments-items"
                    open visible
                    title={segmentTitle()}
                    onOpen={() => $("#txt-chart-segmentitem-search").focus()}
                    onClose={() => addSegmentSet(false)}>
                    <div>
                        <div className="mb-3 position-relative">
                            <input id="txt-chart-segmentitem-search" type="text" autoComplete="one-time-code"
                                className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                onChange={e => {
                                    if (searchTimout) clearTimeout(searchTimout);
                                    searchTimout = setTimeout(() => fetchSegmentData(e.target.value), 500);
                                }} />
                        </div>
                        {
                            segmentsData.length == 0 &&
                            <div className="py-10 text-danger">
                                No Record Found!
                            </div>
                        }
                        <div className="scroll h-300px h-xxl-400px">
                            {
                                segmentsData.map((item, key) =>
                                    <label key={key} onClick={e => selectSegmentItem(item)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center mb-2" + (segmentSelected(item.value) ? " active" : "")}>
                                        {
                                            body.segment == JobsChartSegments.Dispatchers || body.segment == JobsChartSegments.Technician ?
                                                <Avatar avatarUrl={item.avatar} width={26} fullName={item.title} />
                                                : <Icon icon={segmentIcon()} size={18} />
                                        }
                                        <div className="ms-3">
                                            <div className="text-dark fw-bolder fs-7 text-start">{item.title}</div>
                                            <div className="text-gray-500 fs-8 text-start">{item.text}</div>
                                        </div>
                                    </label>
                                )
                            }
                        </div>

                    </div>
                </Modal>
            }


            {
                reportIssueModal && selectedJob &&
                <JobReportIssue
                    onClose={() => reportIssueModalSet(false)}
                    onUpdate={j => {
                        var arr = jobs ?? [];
                        for (var i = 0; i < arr.length; i++) {
                            if (j.id == arr[i].id) {
                                arr[i].hasIssue = j.hasIssue;
                                arr[i].issueText = j.issueText;
                                break;
                            }
                        }
                        jobsSet(a => [...arr]);
                    }}
                    job={selectedJob} />
            }

            {
                exportModal && body && sortBody &&
                <JobReportExportModal
                    onClose={() => {
                        exportModalSet(false)
                        exportTechIdSet(undefined)
                    }}
                    onSent={fetch} defaultSegmentId={defaultSegmentId}
                    techId={exportTechId}
                    jobVis={jobsColsVisibility} dataVis={dataColsVisibility} techVis={techColsVisibility}
                    body={body} sort={sortBody} query={filterQuery} />
            }

            {
                filterModal &&
                <Modal id="modal-filter-jobreports"
                    title="Report Settings"
                    open visible staticBackdrop
                    buttonText="Apply Settings"
                    buttonColor="primary"
                    onSubmit={body => {
                        filterDateTypeSet(body.filterDateType);
                        filterTimezoneSet(body.filterTimezone);
                        window.closeModal("modal-filter-jobreports");
                    }}
                    secondActionColor="secondary"
                    secondActionText="Reset Defaults"
                    preventCancelButton
                    onSecondAction={() => {
                        filterDateTypeSet("1");
                        filterTimezoneSet("job");
                        window.closeModal("modal-filter-jobreports");
                    }}
                    onClose={() => filterModalSet(false)}>
                    <div>

                        <DropDown id="drp-report-datebasis" name="filterDateType"
                            source={[
                                {
                                    id: "1",
                                    name: "Last Status Date"
                                },
                                {
                                    id: "2",
                                    name: "Create Date"
                                }
                            ]}
                            label="Report Date Basis" labelUp block deselect={false}
                            defaultValue={filterDateType} parentModal="modal-filter-jobreports" />

                        <DropDown id="drp-report-timezone" name="filterTimezone" source={timezonesSource}
                            label="Report Timezone" labelUp block deselect={false}
                            defaultValue={filterTimezone} parentModal="modal-filter-jobreports" />

                        <div className="text-muted fs-8">
                            <Icon icon="question-circle" className="text-muted fs-8 me-2" />
                            Enabling 'Job Timezone' syncs report times to the local time of the job’s location, ensuring the timing reflects the actual event times accurately.
                        </div>


                    </div>
                </Modal>
            }


        </Layout>
    )
}