import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../../api/Hub";
import Div from "../../../../components/base/Div";
import Indicator from "../../../../components/base/Indicator";
import Layout from "../../../../components/layouts/Layout";
import { API_ROUTES } from "../../../../core/Constants";
import FormPage from '../../../../components/form/FormPage';
import Icon from "../../../../components/base/Icon";
import TextBox from "../../../../components/form/TextBox";
import FormTitle from "../../../../components/form/FormTitle";
import { setPage } from "../../../../core/Global";
import PBXServer from "../models/PBXServer";

function PBXServerForm() {

    const hub = new Hub<PBXServer>(API_ROUTES.PBXServers);
    const { selectedId } = useParams();
    const [loading, loadingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<PBXServer>();


    const init = async () => {
        if (selectedId) {
            //voicemailSet(model?.voicemailEnabled ?? false);
            setPage("Edit Server", "System", "Servers");
            loadingSet(true);
            await hub.Find(selectedId, res => {
                if (res.success) {
                    modelSet(res.data);
                    loadingSet(false);
                }
            });
        }
        else {
            setPage("Create New Server", "System", "Servers");
        }

    }

    useEffect(() => {
        init();
    }, []);

    return (
        <Layout>
            <Div visible={loading} className="p-20 text-center">
                <Indicator text="Please wait..." />
            </Div>


            <FormPage apiRoute={API_ROUTES.PBXServers}
                selectedId={selectedId}
                objectName="Server"
                visible={!loading}
                redirectTo="/system/servers">

                <div className="row">
                    <div className="col-sm-2 big-icon">
                        <Icon icon="server" type="thin" />
                    </div>
                    <div className="col-sm-10 pt-5">
                        <TextBox name={"host"} placeholder="Host"
                            defaultValue={model?.host?.toString()}
                            inlineStyle largeText noMarginBottom autoFocus />

                        <TextBox name="name" placeholder="Server Name" maxLength={30} inlineStyle defaultValue={model?.name} />
                    </div>
                </div>

                <FormTitle title="Web Socket (WebRTC)" />
                <TextBox label="WSS Host" name="wssHost" defaultValue={model?.wssHost} />
                <TextBox label="WSS Port" name="wssPort" placeholder="8089" defaultValue={model?.wssPort?.toString()} numeric maxLength={4} />
                <TextBox label="WSS Path" name="wssPath" placeholder="/ws" defaultValue={model?.wssPath} />
                <TextBox label="Default Extension" name="defaultWebRTCExtension" numeric maxLength={6} defaultValue={model?.defaultWebRTCExtension} />
                <TextBox label="Secret" name="defaultWebRTCSecret" defaultValue={model?.defaultWebRTCSecret} />

                <FormTitle title="FreePBX API" />
                <TextBox label="Url" name="pbxApiUrl" defaultValue={model?.pbxApiUrl} />
                <TextBox label="Client Id" name="pbxClientId" defaultValue={model?.pbxClientId} />
                <TextBox label="Client Secret" name="pbxClientSecret" defaultValue={model?.pbxClientSecret} />

                <FormTitle title="Asterisk API" />
                <TextBox label="Url" name="asteriskApiUrl" defaultValue={model?.asteriskApiUrl} />
                <TextBox label="Client Id" name="asteriskClientId" defaultValue={model?.asteriskClientId} />
                <TextBox label="Client Secret" name="asteriskClientSecret" defaultValue={model?.asteriskClientSecret} />

                <FormTitle title="Asterisk Context" />
                <TextBox label="Database" name="asteriskContextDatabase" defaultValue={model?.asteriskContextDatabase} />
                <TextBox label="User" name="asteriskContextUser" defaultValue={model?.asteriskContextUser} />
                <TextBox label="Password" name="asteriskContextPassword" defaultValue={model?.asteriskContextPassword} />

                <FormTitle title="CDR Context" />
                <TextBox label="Database" name="cdrContextDatabase" defaultValue={model?.cdrContextDatabase} />
                <TextBox label="User" name="cdrContextUser" defaultValue={model?.cdrContextUser} />
                <TextBox label="Password" name="cdrContextPassword" defaultValue={model?.cdrContextPassword} />

                <FormTitle title="Digital Ocean Firewall" />
                <TextBox label="Authorization Token" name="digitalOceanAuthToken" defaultValue={model?.digitalOceanAuthToken} />
                <TextBox label="Rule ID" name="digitalOceanRuleID" defaultValue={model?.digitalOceanRuleID} />

            </FormPage>

        </Layout>
    )
}

export default PBXServerForm;