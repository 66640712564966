import { NotificationRuleEvents, NotificationRuleTypes, NotificationRuleUsers, NotifTimeTypes } from "../../../../core/Enums";
import { getOwner } from "../../../../core/Global";
import DataItem from "../../../../models/DataItem";


export const getRuleType = (t: NotificationRuleTypes) => {
    return RuleTypes.filter(f=>f.id == t.toString())[0];
}

export const RuleTypes:DataItem[] = [
    {
        id: NotificationRuleTypes.AppNotif.toString(),
        icon: "bell",
        name: "Notification"
    },
    {
        id: NotificationRuleTypes.DesktopNotif.toString(),
        icon: "laptop-mobile",
        name: "Push Notificaion"
    },
    // {
    //     id: NotificationRuleTypes.AppAlert.toString(),
    //     icon: "triangle-exclamation",
    //     name: "Alert"
    // },
    {
        id: NotificationRuleTypes.SMSText.toString(),
        icon: "message-lines",
        name: "SMS"
    },
    {
        id: NotificationRuleTypes.Email.toString(),
        icon: "envelope",
        name: "Email"
    }
]

export const getRuleUserType = (t: NotificationRuleUsers) => {
    return RuleUsersTypes.filter(f=>f.id == t.toString())[0];
}
export const RuleUsersTypes:DataItem[] = [
    {
        id: NotificationRuleUsers.Customer.toString(),
        name: "Main Account"
    },
    {
        id: NotificationRuleUsers.Admins.toString(),
        name: "Managers"
    },
    {
        id: NotificationRuleUsers.Agent.toString(),
        name: "Agent"
    },
    {
        id: NotificationRuleUsers.Technician.toString(),
        name: "Technician"
    },
    {
        id: NotificationRuleUsers.Agents.toString(),
        name: "All Agents"
    }
]

export const TimeTypes:DataItem[] = [
    {
        id: NotifTimeTypes.Minutes.toString(),
        name: "Minutes"
    },
    {
        id: NotifTimeTypes.Hours.toString(),
        name: "Hours"
    },
    {
        id: NotifTimeTypes.Days.toString(),
        name: "Days"
    }
]


export const getRuleEvent = (e?: NotificationRuleEvents) => {
    return RuleEvents.filter(f=>f.id == e?.toString())[0];
}
export const RuleEvents: DataItem[] = [
    {
        id: NotificationRuleEvents.JobStatusChanged.toString(),
        name: "Job Status Changed"
    },
    {
        id: NotificationRuleEvents.JobInStatus.toString(),
        name: "Job Remains in Status"
    },
    {
        id: NotificationRuleEvents.JobAppointmentStarting.toString(),
        name: "Job Appointment Starting in"
    },
    // {
    //     id: NotificationRuleEvents.JobInStatus.toString(),
    //     name: ""
    // },
    // {
    //     id: NotificationRuleEvents.JobInStatus.toString(),
    //     name: ""
    // }
]