import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hub from "../../../api/Hub";
import Icon from "../../../components/base/Icon";
import { ROLES } from "../../../core/Constants";
import { UserTypes } from "../../../core/Enums";
import { getUser, hasRole, setPage, setUserToken, useGlobal, useHub } from '../../../core/Global';
import { BootstrapColors, FontAwesomeIcons } from "../../../core/Types";

type ProfileHeaderProps = {
    selectedPage?: string
}

type QuickItemProps = {
    title: string
    link: string
    icon: any
    color: BootstrapColors
    className?: string
    onClick?: () => void
}

function QuickAccess(props: ProfileHeaderProps) {
    
    const hub = useHub()
    const [globalState] = useGlobal();

    const QuickItem = (props: QuickItemProps) => (
        <Link to={props.link} className={"col bg-light-"+props.color+" px-6 py-8 rounded-2 " + props.className}
            onClick={e=> {
                if(props.onClick) {
                    e.preventDefault();
                    props.onClick();
                }
            }}>
            <span className="svg-icon svg-icon-3x d-block my-2">
                <Icon icon={props.icon} className={"text-"+props.color} size={20} />
            </span>
            <span className={"text-"+props.color+" fw-bold fs-6 mt-2"}>
                {props.title}
            </span>
        </Link>
    )

    const GetDashboardLink = () => {
        if (globalState.user?.userType == UserTypes.Agent) return "/agents/view/" + globalState.user.id;
        else return "/dashboard";
    }

    useEffect(()=> {
        window.KTMenuCreate()
    }, [])

  return (
    <div className="card card-xl-stretch">
        <div className="card-header border-0 bg-primary py-5">
            <h3 className="card-title fw-bolder text-white">Quick Access</h3>
            {
                globalState.user?.userType != UserTypes.Agent &&
                <div className="card-toolbar">
                    <button type="button" className="btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                                    <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                    <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
                        <div className="menu-item">
                            <Link to="/dasboard" className="menu-link px-3">Dashboard</Link>
                        </div>
                        <div className="menu-item">
                            <Link to="/profile/account" className="menu-link px-3">Account</Link>
                        </div>
                        <div className="menu-item">
                            <Link to="/profile/account/password" className="menu-link px-3">Password</Link>
                        </div>
                        <div className="menu-item">
                            <Link to="/dashboard/agents/monitoring" className="menu-link px-3">Agent Monitoring</Link>
                        </div>
                        {
                            hasRole(ROLES.ExtensionsList) &&
                            <div className="menu-item">
                                <Link to="/system/extensions" className="menu-link px-3">Extensions</Link>
                            </div>
                        }
                        {
                            hasRole(ROLES.QueuesList) &&
                            <div className="menu-item">
                                <Link to="/system/queues" className="menu-link px-3">Queues</Link>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
        <div className="card-body p-0">
            <div className="card-rounded-bottom bg-primary h-50px"></div>
            <div className="card-p mt-n20 position-relative">
                <div className="row g-0 mb-4">
                    <QuickItem color="info" 
                        icon="dashboard"
                        title="Dashboard" 
                        link={GetDashboardLink()}
                        className="me-7" />
                        {
                            globalState.user?.userType == UserTypes.Agent ? 
                            <QuickItem color="success" 
                                icon="user"
                                title="Account"
                                link="/profile/account" />
                            :
                            <QuickItem color="success" 
                                icon="phone"
                                title="Live Calls"
                                link="/voice/calls/live" />
                        }
                </div>
                {
                    globalState.user?.userType != UserTypes.Agent &&
                    <div className="row g-0 mb-4">
                        <QuickItem color="primary" 
                            className="me-7"
                            icon="phone-arrow-down-left"
                            title="Queues"
                            link="/system/queues" />
                        <QuickItem color="info" 
                            icon="phone-office"
                            title="Extensions"
                            link="/system/extensions" />
                    </div>
                }
                <div className="row g-0">
                    <QuickItem color="warning" 
                        className="me-7"
                        icon="key"
                        title="Password"
                        link="/profile/account" />
                    <QuickItem color="danger" 
                        icon="sign-out"
                        title="Sign Out"
                        onClick={() => {
                            hub.Get("/auth/logout/" + globalState.user?.id, res => {
                                setUserToken("");
                                document.location.href = "/auth/login";
                            })
                        }}
                        link="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default QuickAccess;
