import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import React from 'react'
import Environment from '../env/env';
import { addOrUpdateJob, archiveJobs, setSocketState, updatePreferences, useOwnerUserId } from './Global';
import ChatMessage from '../modules/chat/models/ChatMessage';

type SocketFunc = {
    key: string
    onStart: (skt: HubConnection) => void
    onStop: (skt: HubConnection) => void
}

export default class SocketContext {
    public socket: HubConnection;
    public static instance: SocketContext = new SocketContext();

    private socketFuncs: Array<SocketFunc> = []

    constructor() {

        const env = new Environment();
        //const [global] = useGlobal();
        this.socket = new HubConnectionBuilder()
            .configureLogging((env.isDevelopment ? LogLevel.Debug : LogLevel.None))
            .withUrl(env.socketUrl + '/livehub', {
                headers: {
                    'SecretKey': env.secretKey,
                    'Authorization': ""
                }
            })
            .build();

        this.socket.start()
            .then(this.onStart)
            .catch(e => {
                setSocketState(HubConnectionState.Disconnected);
            });

        this.socket.onclose(() => {
            setSocketState(HubConnectionState.Disconnected);
        });


        setInterval(() => {
            if (this.socket.state == HubConnectionState.Disconnected) {
                setSocketState(HubConnectionState.Connecting);
                this.socket.start().then(this.onStart);
            }
        }, 3000);


    }


    private onStart = () => {


        setSocketState(HubConnectionState.Connected);

        for (var i = 0; i < this.socketFuncs.length; i++) {
            var func = this.socketFuncs[i];
            func.onStart(this.socket);
        }
    }

    public start = (onStart: (skt: HubConnection) => void, onStop: (skt: HubConnection) => void) => {
        var key = this.generateKey();
        if (this.socket.state == HubConnectionState.Connected) {
            onStart(this.socket);
        }

        this.socketFuncs.push({
            key: key,
            onStart: onStart,
            onStop: onStop
        })

        return key;
    }

    public stop = (key: string) => {
        var func: SocketFunc | undefined;
        var funcIndex = 0;
        for (var i = 0; i < this.socketFuncs.length; i++) {
            if (this.socketFuncs[i].key == key) {
                func = this.socketFuncs[i];
                funcIndex = i;
                break;
            }
        }

        if (func && this.socket.state == HubConnectionState.Connected) {
            func.onStop(this.socket);
        }

        this.socketFuncs.splice(funcIndex, 1);
    }

    public get = (invoke: (skt: HubConnection) => void) => {
        invoke(this.socket);
    }

    private generateKey = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 30; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



}
