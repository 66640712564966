import { Validator } from '../../../core/Types';

export const UserValidator: Validator[] = [
    {
        name: "firstName",
        required: true,
        maxLength: 100
    },
    {
        name: "lastName",
        required: true,
        maxLength: 100
    },
    {
        name: "email",
        required: true,
        type: "Email",
        maxLength: 255
    },
    {
        name: "username",
        required: true,
        maxLength: 255,
        preventOnUpdate: true
    },
    {
        name: "password",
        required: true,
        maxLength: 50,
        minLength: 6,
        preventOnUpdate: true
    }
 ];