import { useEffect, useState } from "react";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import { toastSuccess } from "../../../components/base/Toast";
import Form from "../../../components/form/Form";
import TextBox from "../../../components/form/TextBox";
import { useHub } from "../../../core/Global";
import User from "../models/User";
import { API_ROUTES } from "../../../core/Constants";
import Modal from "../../../components/base/Modal";
import ModalUpdateJobRates from "../../../components/modals/ModalUpdateJobRates";


type Props = {
    userId?: string
    model?: User
    onUpdate?: (user: User) => void
}

interface IModel {
    rate: any
    fee: any
    creditFee: any
    systemRate: any
    systemFee: any
    apptReminderRate: any
}

export default function AccountTechRates(props: Props) {

    const hub = useHub();
    const [model, modelSet] = useState<IModel>();
    const [working, workingSet] = useState<boolean>(false);

    const fetch = () => {
        hub.Get(API_ROUTES.Technicians + "defaultrates/" + props.userId, res => {
            if (res.success) modelSet(res.data);
        })
    }

    useEffect(() => {
        fetch();
    }, [])


    const [updateRequired, updateRequiredSet] = useState<boolean>();
    const [confirmed, confirmedSet] = useState<boolean>();
    const [updateJobs, updateJobsSet] = useState<string[]>();

    useEffect(() => {
        if (confirmed) {
            window.closeModal("modal-confirmedit-techrate");
            $("#btn-update-techrates").click();
        }
    }, [confirmed])

    return (
        <>
            <Form id="user-techdefaults-form" className="form"
                onSubmit={(body, callback) => {

                    workingSet(true);

                    var jobIds = "";
                    updateJobs?.forEach(j => {
                        if (jobIds != "") jobIds += ",";
                        jobIds += j;
                    });

                    hub.Put(API_ROUTES.Technicians + "updatedefaultrates", {
                        ...body,
                        id: props.userId,
                        confirmed: confirmed,
                        updateJobs: jobIds
                    }, res => {
                        confirmedSet(false);
                        if (res.success) {
                            if (res.confirmRequired) {
                                updateRequiredSet(true);
                                return;
                            }
                            toastSuccess("Technician rates has been updated successfully!");
                            if (props.onUpdate) props.onUpdate(res.data);
                        }
                        workingSet(false);
                    })

                }}>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Default Rates</h3>
                        </div>
                        <div className="card-action">
                            <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                                {
                                    working ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">Updating...</span>
                                        </>
                                        :
                                        <>
                                            Update Rates
                                        </>
                                }
                            </button>
                        </div>
                    </div>

                    <div className="card-body border-top p-9"
                        style={{
                            maxWidth: 1000
                        }}>

                        <TextBox name="defaultRate" label="Tech Rate" placeholder={`inherit from system rate (${model?.systemRate}%)`}
                            numeric maxLength={2} icon="percent" value={model?.rate} />

                        <TextBox name="defaultFee" label="Tech Fee" placeholder={`inherit from system fee (${model?.systemFee}$)`}
                            money maxLength={3} icon="dollar-sign" value={model?.fee} />

                        <TextBox name="creditFee" label="Credit Card Fee"
                            money maxLength={2} icon="dollar-sign" value={model?.creditFee} />

                        <TextBox name="apptReminderRate" label="Appt Reminder Sent"
                            numeric maxLength={3} iconText="Minutes" value={model?.apptReminderRate} />

                    </div>

                </div>
            </Form>

            {
                updateRequired &&
                <ModalUpdateJobRates rateType="tech"
                    techId={props.userId}
                    onConfirm={jobs => {
                        updateJobsSet(jobs);
                        confirmedSet(true);
                    }}
                    onClose={() => {
                        updateRequiredSet(false);
                    }} />
            }

        </>
    )
}