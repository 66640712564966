import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/flags.json";
import sortingProps from "../sorting/flags.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, setToolbarActions, toPrettyDate, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Tagify from "../../../../components/tagify/Tagify";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../core/Types";
import Section from "../../../../components/base/Section";
import TextBox from "../../../../components/form/TextBox";
import DataItem from "../../../../models/DataItem";
import DropDown from "../../../../components/form/DropDown";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import ModalUpdateJobRates from "../../../../components/modals/ModalUpdateJobRates";
import JobSource from "../models/JobSource";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";
import DatePicker from "../../../../components/form/DatePicker";

function JobSourcesList() {

  const hub = useHub();
  const user = getUser();

  const [createModal, createModalSet] = useState<boolean>(false);
  const [editModal, editModalSet] = useState<boolean>(false);
  const [deleteModal, deleteModalSet] = useState<boolean>(false);

  const [selectedItem, selectedItemSet] = useState<JobSource>();

  const [refresh, refreshSet] = useState<boolean>();
  const [groupsRouteParams, groupsRouteParamsSet] = useState<ListItem[]>([]);
  const [systemStatus, systemStatusSet] = useState<boolean>();


  const [sourceTypes] = useState<DataItem[]>([
    {
      id: "1",
      name: "Company"
    },
    {
      id: "2",
      name: "Advertisement"
    }
  ])



  const add = () => createModalSet(true)

  const edit = (data: any) => {
    selectedItemSet(data);
    updateOldRatesSet(false);
    editModalSet(true);
  }

  const deleteSource = (data: any) => {
    selectedItemSet(data);
    deleteModalSet(true);
  }



  const NameItem = (data: any) => (
    <FlexRow>
      <div>
        {data.name}
      </div>
      <a href="#" className="ms-1"
        onClick={e => {
          e.preventDefault();
          edit(data);
        }}>
        <Icon icon="edit" className="ms-2" size={12} />
      </a>
    </FlexRow>
  )

  const JobsCount = (data: JobSource) => (
    <div>
      {
        (data.activeJobsCount ?? 0) > 0 ?
          <a href={"/crm/jobs?jobSources=" + data.id} target="_blank">
            {data.activeJobsCount}
          </a>
          :
          <div>
            0
          </div>
      }

    </div>
  )

  const RatesItem = (data: any) => (
    <div>
      {data.rate}%
    </div>
  )
  const FeeItem = (data: any) => (
    <div>
      {data.fee}$
    </div>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Source"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const SourceTypeItem = (data: any) => (
    <div>
      {
        data.sourceType == 1 ?
          <FlexRow>
            <Icon icon="briefcase" className="me-1 text-gray-800" type="solid" />
            <div className="fs-8">
              Company
            </div>
          </FlexRow>
          : data.sourceType == 2 ?
            <FlexRow>
              <Icon icon="bullhorn" className="me-1 text-gray-800" type="solid" />
              <div className="fs-8">
                Advertisement
              </div>
            </FlexRow>
            : <></>
      }

    </div>
  )

  const CreatedItem = (data: JobSource) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )


  useEffect(() => {
    setPage("Job Sources", "CRM", "Base Data");

    window.onModalOpen("modal-add-source", () => {
      $("#txt-add-sourcename").val("");
      $("#txt-add-sourcename").focus();
    })

    window.onModalOpen("modal-edit-source", () => {
      $("#txt-edit-sourcename").focus();
    })
  }, [])

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])


  const [confirmRequired, confirmRequiredSet] = useState<boolean>();
  const [updateRequired, updateRequiredSet] = useState<boolean>();
  const [updateRate, updateRateSet] = useState<any>();
  const [updateJobs, updateJobsSet] = useState<string[]>();
  const [confirmed, confirmedSet] = useState<boolean>();


  useEffect(() => {
    if (confirmed && updateJobs) {
      $("#modal-edit-source-submit").click();
    }
  }, [confirmed])


  const [editedRate, editedRateSet] = useState<number>();
  const [updateOldRates, updateOldRatesSet] = useState<boolean>(false);


  useEffect(() => {
    editedRateSet(selectedItem?.rate)
  }, [selectedItem])


  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Source"
          apiRoute={API_ROUTES.JobSources}
          filteringProps={filteringProps}
          sortingProps={sortingProps}
          edit editCustom={edit} addCustom={add}
          delete={hasRole("DeleteJobSources")} deleteCustom={deleteSource}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <DataTableActiveToggle route={API_ROUTES.JobSources} />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Type">
            <SourceTypeItem />
          </DataTableField>
          <DataTableField title="Rate">
            <RatesItem />
          </DataTableField>
          <DataTableField title="Fee">
            <FeeItem />
          </DataTableField>
          <DataTableField title="Jobs">
            <JobsCount />
          </DataTableField>
          <DataTableField title="" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
        </DataTable>
      </Section>

      {
        createModal &&
        <Modal id="modal-add-source"
          title="Create Job Source"
          buttonText="Add Source"
          staticBackdrop open visible
          onClose={() => createModalSet(false)}
          onSubmit={(body, callback) => {

            var valid = true;
            var focus = true;

            if (window.isNullOrEmpty(body.type)) {
              window.formError("type", "Please select a source type!", "modal-add-source", focus);
              valid = false;
              focus = false;
            }

            if (window.isNullOrEmpty(body.name) || body.name.length < 2) {
              window.formError("name", "Please write a valid name!", "modal-add-source", focus);
              valid = false;
              focus = false;
            }

            if (!valid) {
              if (callback) callback();
              return;
            }

            hub.Post(API_ROUTES.JobSources, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Job Source has been added successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system source!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <DropDown name="type" source={sourceTypes} label="Source Type*" labelUp block parentModal="modal-add-source" />
            <TextBox name="name" label="Source Name*" labelUp block />
            <DropDown name="forceLineId" label="Default Phone for Extensions" apiSource={API_ROUTES.TagifyPhoneNumbers}
              dataText="title" dataValue="value" labelUp block parentModal="modal-add-source" />
            <TextBox name="rate" label="Default Rate" labelUp block numeric maxLength={2} icon="percent" />

            <TextBox name="fee" label="Source Fee" labelUp block money icon="dollar-sign" />
            <ToggleSwitch name="calculateAsCash" label="Calculate as Cash" raw small />

          </div>
        </Modal>
      }

      {
        editModal &&
        <Modal id="modal-edit-source"
          title="Edit Job Source"
          buttonText="Update Source"
          staticBackdrop open visible
          onClose={() => editModalSet(false)}
          onSubmit={(body, callback) => {

            var valid = true;
            var focus = true;

            if (window.isNullOrEmpty(body.type)) {
              window.formError("type", "Please select a source type!", "modal-edit-source", focus);
              valid = false;
              focus = false;
            }

            if (window.isNullOrEmpty(body.name) || body.name.length < 2) {
              window.formError("name", "Please write a valid name!", "modal-edit-source", focus);
              valid = false;
              focus = false;
            }

            if (!valid) {
              if (callback) callback();
              return;
            }

            updateRateSet(body.rate);

            var jobIds = "";
            updateJobs?.forEach(j => {
              if (jobIds != "") jobIds += ",";
              jobIds += j;
            });

            hub.Put(API_ROUTES.JobSources + selectedItem?.id, {
              ...body,
              updateJobs: jobIds
            }, res => {
              confirmedSet(false);
              updateRequiredSet(false);
              if (res.success) {
                if (res.confirmRequired) {
                  updateRequiredSet(true);
                  if (callback) callback();
                  return;
                }
                refreshSet(true);
                toastSuccess("Job Source has been updated successfully!");
              }
              if (callback) callback(res);
            })

          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bold">
                  Assign to
                  <span className="small text-muted mx-2">
                    Leave it blank to create system source!
                  </span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <DropDown name="type" source={sourceTypes} label="Source Type*" labelUp block parentModal="modal-edit-source" defaultValue={selectedItem?.sourceType?.toString()} />
            <TextBox name="name" label="Source Name*" labelUp block value={selectedItem?.name} />
            <DropDown name="forceLineId" label="Default Phone for Extensions" apiSource={API_ROUTES.TagifyPhoneNumbers}
              dataText="title" dataValue="value" labelUp block parentModal="modal-edit-source" defaultValue={selectedItem?.forceProxyLineId} />

            <TextBox name="rate" label="Default Rate" labelUp block numeric
              maxLength={2} icon="percent" value={selectedItem?.rate?.toString()}
              onTextChanges={t => editedRateSet(Number.parseInt(t))} />

            <TextBox name="fee" label="Source Fee" labelUp block money icon="dollar-sign" value={selectedItem?.fee?.toString()} />
            <ToggleSwitch name="calculateAsCash" label="Calculate as Cash" raw small checked={selectedItem?.calculateAsCash} />

            {
              editedRate !== selectedItem?.rate &&
              <div className="mt-5">
                <ToggleSwitch name="updateRates" label="Update rates for already closed jobs!" raw small
                  onChange={e => updateOldRatesSet(e)} />

                {
                  updateOldRates &&
                  <div className="my-5">
                    <DatePicker id="dt-pick-oldrates-from" name="oldratesFrom"
                      label="Update Jobs From" drops="up" labelUp icon="calendar" />
                  </div>
                }
              </div>
            }




          </div>
        </Modal>
      }

      {
        updateRequired &&
        <ModalUpdateJobRates rateType="source"
          sourceId={selectedItem?.id}
          onConfirm={jobs => {
            updateJobsSet(jobs);
            confirmedSet(true);
          }}
          onClose={() => {
            updateRequiredSet(false);
          }} />
      }

      {
        confirmRequired &&
        <Modal id="modal-confirmedit-source"
          title="Warning"
          open visible secondModal
          onConfirm={() => {
            confirmedSet(true);
          }}
          onClose={() => confirmRequiredSet(false)}
          buttonText="Confirm & Update"
          buttonColor="warning"
        >
          <div style={{ whiteSpace: "pre" }}>

          </div>
        </Modal>
      }

      {
        deleteModal &&
        <Modal id="modal-delete-source"
          title="Delete Job source"
          buttonText="Delete Source"
          buttonColor="danger"
          open visible
          onClose={() => deleteModalSet(false)}
          onSubmit={(body, callback) => {

            if ((selectedItem?.jobsCount ?? 0) > 0) {
              if (window.isNullOrEmpty(body.assignTo)) {
                window.formError("assignTo", "Select a source!", "modal-delete-source");
                if (callback) callback();
                return;
              }
            }

            hub.Post(API_ROUTES.JobSources + "delete/" + selectedItem?.id, body, res => {
              if (res.success) {
                refreshSet(true);
                toastSuccess("Job source has been deleted successfully!");
              }
              if (callback) callback(res);
            })

          }}
          secondActionColor="secondary"
          secondActionText="Disable Instead"
          onSecondAction={
            (selectedItem?.jobsCount ?? 0) == 0 ? undefined
              : () => {
                hub.Post(API_ROUTES.JobSources + "activetoggle", {
                  id: selectedItem?.id,
                  active: "0"
                }, res => {
                  refreshSet(true);
                  window.closeModal("modal-delete-source");
                })
              }
          }>
          <div>
            {
              selectedItem?.jobsCount && selectedItem.jobsCount > 0 ?
                <div>
                  <div className="text-danger mb-5">
                    There {selectedItem.jobsCount > 1 ? "are" : "is"} {selectedItem.jobsCount} {selectedItem.jobsCount > 1 ? "jobs" : "job"} assigned to this source!
                  </div>
                  <DropDown name="assignTo" apiSource={API_ROUTES.TagifyJobSources + "?excludeIds=" + selectedItem.id} dataText="title" dataValue="value"
                    label="Assign jobs to*" parentModal="modal-delete-source" labelUp block />
                </div>
                :
                <div>
                  Are you sure you want to delete this job source?
                </div>
            }

          </div>
        </Modal>
      }



    </Layout>
  );
}

export default JobSourcesList;
