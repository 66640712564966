import React, { useEffect, useState } from "react";
import Icon from '../../../components/base/Icon';
import Hub from '../../../api/Hub';
import AgentOverviewModel from '../models/AgentOverviewModel';
import Div from '../../../components/base/Div';
import Indicator from '../../../components/base/Indicator';
import AgentDetailsModel from '../models/AgentDetailsModel';
import { AgentOverviewTotalCalls, AgentOverviewMissedCalls } from '../models/AgentOverviewModel';
import { pauseGlobalPlayer, playCall, playGlobalPlayer, useGlobal, useHub, useSocket } from '../../../core/Global';
import AgentTotalCallChart from "../components/AgentTotalCallChart";
import { HubConnectionState } from "@microsoft/signalr";
import Time from "../../../components/timer/Time";
import AgentTimeTable from "../components/AgentTimeTable";
import CallAgent from "../../voice/calls/models/CallAgent";

type AgentProps = {
  agentId?: string
  model?: AgentDetailsModel  
  selectPage?: (page: string) => void
}

let AgentId:string;
function AgentOverview(props: AgentProps) {

  AgentId = props.agentId ?? "";
  const hub = useHub();
  const socket = useSocket();
  const [globalState] = useGlobal();
  const [model, modelSet] = useState<AgentOverviewModel>();
  const [fetching, fetchingSet] = useState<boolean>(true);
  const [fetched, fetchedSet] = useState<boolean>(false);
  
  const [missedCalls, missedCallsSet] = useState<AgentOverviewMissedCalls>();
  const [missedCallsDateLabel, missedCallsDateLabelSet] = useState<string>("Today");

  const fetch = async () => {
    hub.Get("/accounts/agents/overview/" + props.agentId, res => {
        if(res?.data) {

            modelSet(res.data);
            fetchingSet(false);
            fetchedSet(true);
            missedCallsSet(res.data?.missedCalls);
            window.daterange("missedcalls_date", missedCallsFetch);
            
        }
    });
  }

  

  const missedCallsFetch = async (start:any, end:any, label:any) => {
    
    if (label == "Custom Range") missedCallsDateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
    else missedCallsDateLabelSet(label); 
    var q = "?";
    q += "from=" + start.format("DDMMYYYY");
    q += "&to=" + end.format("DDMMYYYY");
    console.log(q);
    hub.Get("/accounts/agents/overview/missedcalls/" + props.agentId + q, res => {
        missedCallsSet(res.data);
    })
  }

  const LatestCallsCallerID = (props: {src?: CallAgent, dest?: CallAgent, date?: string} ) => (
    <div className="d-flex align-items-center me-5">
      {
        props.src?.id == AgentId ?
        <>
          {
            props.dest?.country ? <img src={props.dest.country.flagSquare} className="me-4 w-25px latestcall-avatar" />
            : <img src={props.dest?.avatarUrl} className="me-4 w-25px latestcall-avatar" />
          }
        </>
        :
        <>
          {
            props.src?.country ? <img src={props.src.country.flagSquare} className="me-4 w-25px latestcall-avatar" />
            : <img src={props.src?.avatarUrl} className="me-4 w-25px latestcall-avatar" />
          }
        </>
      }
      <div className="me-5">
        <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
          {
            props.src?.id == AgentId &&
            <>
              {props.dest?.country ? <span>{props.dest?.number}</span> : <span>{props.dest?.number}</span>}
            </>
          }
          {
            props.dest?.id == AgentId &&
            <>
              {props.src?.country ? <span>{props.src?.number}</span> : <span>{props.src?.number}</span>}
            </>
          }
        </a>
        <span className="text-gray-400 fw-bold fs-8 d-block text-start ps-0">
          {
            props.src?.id == AgentId ? <Icon icon="phone-arrow-up-right" type="solid" className="me-1" size={11} />
            : <Icon icon="phone-arrow-down-left" type="solid" className="me-1" size={11} />
          }
          {props.date}
        </span>
      </div>
    </div>
  )

  const PlayRow = (props: {data: any}) => (
    <div className="me-3">
        {
            props.data.disPosition == "ANSWERED" ? 
            <>
             <Icon icon={(globalState.selectedCallToPlay?.id == props.data.id && globalState.floatAudioStatus?.playing) ? "pause" 
                : (globalState.selectedCallToPlay?.id == props.data.id && globalState.floatAudioStatus?.loading) ? "spinner"
                : "play"} type="solid" spin={(globalState.selectedCallToPlay?.id == props.data.id && globalState.floatAudioStatus?.loading)}
                color="#555" size={17} />
            </>
            :
            <>
              <Icon icon={"play"} type="solid" color="#fff" size={17} />
            </>
          }
    </div>
  )
  
  useEffect(()=> {
    fetch();

    var socketKey = socket.start(
      skt => {
          skt.on("UpdateAgentOverview", () => {
              fetch();
          });
      }, skt => {
          skt.off("UpdateAgentOverview");
      });

    return () => {
        socket.stop(socketKey);
    };


  }, [props.agentId]);


  useEffect(()=> {

    window.createCircleChart("chart-missed-calls", 
        [missedCalls?.callBack ?? 0, missedCalls?.noCallBack ?? 0], 
        [window.getBootstrapColor("primary"), window.getBootstrapColor("danger")]);

  }, [missedCalls]);


  return (
    <>
      <Div visible={fetching} className="mt-20">
          <Indicator />
      </Div>
      <Div visible={fetched} className="mt-5">
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <AgentTotalCallChart agentId={props.agentId} model={model?.totalCalls}  />
                  </div>
                  <div className="col-6">
                    <div className="card card-flush ">
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                            {missedCalls?.total ?? 0}
                            </span>
                          </div>
                          <span className="text-gray-400 pt-1 fw-bold fs-8">
                            <Icon icon="phone-missed" type="solid" className="me-1" size={11} />
                            Misesd Calls
                          </span>
                        </div>
                        <div className="d-flex align-items-start">
                            <div id="missedcalls_date" className="d-flex align-items-center cursor-pointer fs-8">
                              <span>{missedCallsDateLabel}</span>
                              <Icon icon="chevron-down" className="ms-1" size={11} />
                            </div>
                        </div>
                      </div>
                      <div className="card-body pt-2 pb-4 d-flex align-items-center">
                        <div className="d-flex flex-center me-5 pt-2">
                          <div id="chart-missed-calls" data-kt-size="70" data-kt-line="11"></div>
                        </div>
                        <div className="d-flex flex-column content-justify-center w-100">
                          <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-primary me-1"></div>
                            <div className="text-gray-500 flex-grow-1 me-2 fs-7">Call Back</div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                              {missedCalls?.callBack ?? 0}
                            </div>
                          </div>
                          <div className="d-flex fs-6 fw-bold align-items-center my-3">
                            <div className="bullet w-8px h-6px rounded-2 bg-danger me-1"></div>
                            <div className="text-gray-500 flex-grow-1 me-2 fs-7">Missed</div>
                            <div className="fw-boldest text-gray-700 text-xxl-end">
                              {missedCalls?.noCallBack ?? 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12">
                    <AgentTimeTable model={model?.timeTable} />
                  </div>
                </div>
            </div>
            

            <div className="col-md-12 col-lg-6">
              <div className="card card-flush h-xl-100">
                <div className="card-header pt-7 mb-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-gray-800">Latest Calls</span>
                    <span className="text-gray-400 mt-1 fw-bold fs-6"></span>
                  </h3>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-sm btn-light" onClick={e=>{
                      e.preventDefault();
                      if(props.selectPage) props.selectPage("Calls History");
                    }}>View All</a>
                  </div>
                </div>
                
                
                <div className="card-body scroll h-350px pt-0">
                  {
                    model?.latestCalls && model.latestCalls.length > 0 ?
                    <>
                      {
                        model.latestCalls.map((item, key) => 
                        <div key={key}>
                            <a href="#" id={"latest-call-" + item.id}
                              className="d-flex align-items-center latest-call-link p-3"
                              onClick={e=> {
                                e.preventDefault();
                                if (item.disPosition != "ANSWERED") return;
                                if (globalState.selectedCallToPlay?.id == item.id) {
                                    if (globalState.floatAudioStatus?.playing) pauseGlobalPlayer();
                                    else playGlobalPlayer();
                                }
                                else {
                                    playCall(item);
                                    $(".latest-call-link").removeClass("selected");
                                    $("#latest-call-" + item.id).addClass("selected"); 
                                }
                            }}>

                              <PlayRow data={item} />
                              
                              <LatestCallsCallerID src={item.sourceAgent} dest={item.destinationAgent} date={item.callDateStr}/>
                              
                              <div className="d-flex align-items-center ms-auto">
                                <span className="text-gray-800 fw-bolder fs-6 me-3">

                                </span>
                                <div className="">
                                  {
                                    item?.disPosition == "ANSWERED" ? 
                                    <span className="badge badge-success px-2">
                                      {item.billSecStr}
                                    </span>
                                    : item.disPosition == "NO ANSWER" ? 
                                    <span className="px-2 fs-8">
                                      <Icon type="solid" icon="phone-missed" className="text-danger me-2 fs-8"/>
                                      {item.disPosition}
                                    </span>
                                    : item.disPosition == "BUSY" ? 
                                    <span className="px-2 fs-8">
                                      <Icon type="solid" icon="phone-xmark" className="text-danger me-2 fs-8"/>
                                      {item.disPosition}
                                    </span>
                                    :
                                    <span className="px-2 fs-8">
                                      <Icon type="solid" icon="phone" className="text-gray me-2 fs-8"/>
                                      {item.disPosition}
                                    </span>
                                  }

                                </div>
                              </div>
                            </a>
                            <div className="separator separator-dashed my-0"></div>
                        </div>)
                      }
                    </>
                    :
                    <div></div>
                  }
                  

                </div>
              </div>
            </div>


        </div>
      </Div>
      
    </>
  );
}

export default AgentOverview;
