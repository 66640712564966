import { useEffect, useState } from "react";
import DropDown from "../../../../../components/form/DropDown";
import JobStatus from "../../../base/models/JobStatus";
import { hasRole, openCloseJob, openInvalidCloseJob, openReopenJob, useHub, useStatusesForJob } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import Job from "../../models/Job";
import TextArea from "../../../../../components/form/TextArea";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import Timer from "../../../../../components/timer/Timer";
import IconButton from "../../../../../components/base/IconButton";
import Button from "../../../../../components/base/Button";



interface IProps {
    job: Job
}
export default function JobDrawerStatus(props: IProps) {

    const hub = useHub()
    const statuses = useStatusesForJob().filter(f => !f.isClosed);
    const [working, workingSet] = useState<boolean>(false)
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>();
    const [statusNote, statusNoteSet] = useState<string>();

    const [closeJobWorking, closeJobWorkingSet] = useState<boolean>(false)


    useEffect(() => {
        if (props.job) {
            var st = statuses?.find(f => f.id == props.job.jobStatusId);
            selectedStatusSet(st)
        }
    }, [props.job])

    const [refreshing, refreshingSet] = useState<boolean>(false)

    useEffect(() => {
        refreshingSet(false)
    }, [props.job])


    return (
        <div id="div-quickjob-status">

            {
                props.job.isClosed ?
                    <FlexRow>
                        <Icon icon="circle" type="solid" color={props.job.jobStatus?.color} size={16} />
                        <span className="ms-2 fs-6">
                            Job is Closed!
                        </span>
                        {
                            (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                            <button type="button" className="btn btn-primary btn-sm ms-auto"
                                onClick={e => {
                                    e.preventDefault()
                                    if (statuses) openReopenJob(props.job, statuses[0])
                                }}>
                                Reopen the Job
                            </button>
                        }

                    </FlexRow>
                    : props.job.isCanceled ?
                        <FlexRow>
                            <Icon icon="circle" type="solid" color={props.job.jobStatus?.color} size={16} />
                            <span className="ms-2 fs-6">
                                Job is Canceled!
                            </span>
                            {
                                (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                                <button type="button" className="btn btn-primary btn-sm ms-auto"
                                    onClick={e => {
                                        e.preventDefault()
                                        if (statuses) openReopenJob(props.job, statuses[0])
                                    }}>
                                    Reopen the Job
                                </button>
                            }

                        </FlexRow>
                        :
                        <div>
                            <DropDown id="job-quickform-statuses" label="Status" name="status" deselect={false}
                                labelUp block icon="circle" iconColor={selectedStatus?.color} labelClass="fw-bold"
                                source={statuses} marginBottom={3} defaultValue={selectedStatus?.id} //forceSelectValue={selectedStatus?.id}
                                onSelectItem={st => selectedStatusSet(st)} />

                            {
                                selectedStatus?.noteRequired && props.job.jobStatusId != selectedStatus.id &&
                                <TextArea label="Status Note*" name="statusNote" labelUp block
                                    onChange={e => statusNoteSet(e.target.value)} />
                            }

                            {
                                (selectedStatus && selectedStatus.id != props.job.jobStatusId) ?
                                    <FlexRow justify="end">
                                        <button className="btn btn-secondary btn-sm me-2" disabled={working}
                                            onClick={e => {
                                                var st = statuses?.find(f => f.id == props.job.jobStatusId);
                                                selectedStatusSet(st)
                                            }}>
                                            Discard
                                        </button>
                                        <button className="btn btn-success btn-sm" disabled={working}
                                            onClick={e => {
                                                e.preventDefault()
                                                if (props.job.jobStatusId != selectedStatus?.id && selectedStatus?.noteRequired && (!statusNote || statusNote.length < 3)) {
                                                    window.showFormError("statusNote", "Please write the status note!", false, "div-quickjob-status");
                                                    return;
                                                }

                                                workingSet(true)

                                                hub.Post(API_ROUTES.Jobs + "jobstatus", {
                                                    id: props.job.id,
                                                    status: selectedStatus.id,
                                                    note: statusNote

                                                }, res => {
                                                    workingSet(false)
                                                })

                                            }}>
                                            {
                                                working ?
                                                    <>
                                                        <Icon icon="spinner-third" spin />
                                                        <span className="ms-2">Updating...</span>
                                                    </>
                                                    :
                                                    <>
                                                        Update Status
                                                    </>
                                            }
                                        </button>
                                    </FlexRow>
                                    :
                                    <FlexRow>
                                        <div className="text-muted fs-8">
                                            <Icon icon="clock" size={10} className="me-2" type="solid" />
                                            Status Time
                                        </div>
                                        <FlexRow className="ps-5">
                                            <div style={{ width: 50 }}>
                                                <Timer key={props.job.id} baseDate={props.job.lastStatusUpdatedAt} />
                                            </div>
                                            <IconButton icon="redo" className="ms-3 btn-light" working={refreshing}
                                                type="solid" size={12} title="Refresh Status Time"
                                                onClick={e => {
                                                    refreshingSet(true)
                                                    hub.Post(API_ROUTES.Jobs + "refreshstatus", {
                                                        id: props.job.id
                                                    }, res => {

                                                    });
                                                }} />
                                        </FlexRow>
                                        <div className="ms-auto">
                                            <Button text="Close The Job" color="secondary" isRunning={closeJobWorking}
                                                onClick={() => {
                                                    closeJobWorkingSet(true)
                                                    hub.Get(API_ROUTES.Jobs + "validateclosing/" + props.job.id, res => {
                                                        if (res.data) {
                                                            openCloseJob(props.job)
                                                            closeJobWorkingSet(false)
                                                        }
                                                        else {
                                                            openInvalidCloseJob(props.job, res.message)
                                                            closeJobWorkingSet(false)
                                                        }
                                                    })
                                                }} />
                                        </div>
                                    </FlexRow>
                            }
                        </div>
            }



        </div>
    )
}