import React, { useEffect, useState } from "react";
import Hub from '../../api/Hub';
import { FontAwesomeIcons, ListItem } from "../../core/Types";
import Environment from "../../env/env";
import { TagifyDataItem } from "../../models/TagifyOptions";
import Icon from "../base/Icon";
import { useHub } from "../../core/Global";

interface TagifyProps {
    id: string
    apiUrl?: string
    source?: any[]
    name?: string
    label?: string
    icon?: FontAwesomeIcons
    labelUp?: boolean
    small?: boolean
    solid?: boolean
    any?: boolean
    anyText?: string
    placeholder?: string
    marginBottom?: number
    noMarginBottom?: boolean
    single?: boolean

    liveSearch?: boolean

    defaultValue?: string
    defaultValues?: string[]

    defaultId?: string
    defaultIds?: any[]

    selectedId?: string
    selectedIds?: any[]



    routeParams?: ListItem[]
    closeOnSelect?: boolean
    inlineSuggestions?: boolean
    block?: boolean
    tooltip?: string
    freeTags?: boolean
    email?: boolean
    clear?: boolean
    eventsUpdate?: any
    raw?: boolean
    parentId?: string
    focus?: boolean
    hint?: string
    valueType?: "free" | "ip"
    tagColors?: string

    onSelect?: (value: string) => void
    onRemove?: (value: string) => void
    onSelectItem?: (item?: TagifyDataItem) => void
    onRemoveItem?: (item?: TagifyDataItem) => void
    onChange?: (values: string[]) => void
}


export default function Tagify(props: TagifyProps) {

    const hub = useHub();
    const env = new Environment();

    const [value, valueSet] = useState<string>();
    const [values, valuesSet] = useState<string[]>();
    const [tagify, tagifySet] = useState<any>();

    const [localSource, localSourceSet] = useState<TagifyDataItem[]>();

    const [addedVal, addedValSet] = useState<string>();

    useEffect(() => {

        if (addedVal) {
            if (addedVal == "any") return;
            var arr = props.single ? [] : (values ?? []);
            //console.log("addedVal", addedVal);

            var exists = false;
            arr.forEach(a => {
                if (a == addedVal) {
                    exists = true;
                    return;
                }
            });
            if (!exists) {
                arr.push(addedVal);
                valuesSet(v => [...arr]);
            }

            removedValSet(undefined);
        }

    }, [addedVal])

    const [removedVal, removedValSet] = useState<string>();
    useEffect(() => {
        if (removedVal) {
            var arr = values ?? [];
            var i = arr.indexOf(removedVal);
            if (i > -1) {
                arr.splice(i, 1);
                valuesSet(v => [...arr]);
            }
            addedValSet(undefined);
        }
    }, [removedVal])


    const className = () => {
        var cls = "form-control d-flex align-items-center form-textbox";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        return cls;
    }

    const inputGroupClassName = () => {
        var cls = "input-group";
        if (props.small) cls += " input-group-sm";
        if (props.solid) cls += " input-group-solid";
        return cls;
    }

    const fetch = (keyword?: string) => {
        var q = "";

        if (keyword) {
            q += "keyword=" + encodeURIComponent(keyword);
        }

        if (props.routeParams) {
            for (var i = 0; i < props.routeParams.length; i++) {
                if (q != "") q += "&";
                q += props.routeParams[i].name + "=" + encodeURIComponent(props.routeParams[i].value ?? "");
            }
        }

        if (q != "") q = "?" + q;
        hub.Get(props.apiUrl + q, res => {
            if (res.data) {
                create(res.data);
            }
        });
    }

    const findTag = (value?: string) => {
        // for (var i = 0; i < (data ?? []).length; i++) {
        //     if (data[i].value == value) return data[i];
        // }
        // return null;
    }

    const onRemoveTag = (e: any) => {

        if (e.detail.data.value == "any") {
            tagify.addTags([{
                title: props.anyText ?? "Any item",
                value: "any"
            }])
        }
        else removedValSet(e.detail.data.value);

        if (props.onRemove) props.onRemove(e.detail.data.value);
        if (props.onRemoveItem) props.onRemoveItem(e.detail.data);

    }

    const onAddTag = (e: any) => {

        //if (env.isDevelopment) console.log("tagify add", e.detail);

        if (e.detail.data.color) {
            e.detail.tag.style = "--tag-bg:" + e.detail.data.color +
                ";--tag-hover:" + e.detail.data.color +
                ";--tag-remove-bg:" + e.detail.data.color +
                ";--tag-remove-btn-bg:#fff" +
                ";--tag-remove-btn-color:#fff" +
                ";--tag-text-color: #fff";
        }
        else if (props.tagColors) {
            e.detail.tag.style = "--tag-bg:" + props.tagColors +
                ";--tag-hover:" + props.tagColors +
                ";--tag-remove-bg:" + props.tagColors;
        }
        else if (!e.detail.data.exists) {
            e.detail.tag.style = "--tag-bg:#fdffe3" +
                ";--tag-hover:#fdffe3" +
                ";--tag-remove-bg:#fdffe3";
        }

        if (props.single) {
            tagify.removeAllTags();
        }

        addedValSet(e.detail.data.value);

        if (props.onSelect) props.onSelect(e.detail.data.value);
        if (props.onSelectItem) props.onSelectItem(e.detail.data);

        window.hideFormError(props.name, props.parentId);
    }

    const onDropDownSelect = (e: any) => {

        if (props.any && (values?.length ?? 0) < 1) {
            tagify.removeAllTags();
        }
    }

    const create = (whitelist?: any) => {

        var arr = [];
        if (props.any) {
            arr.push({
                title: props.anyText ?? "Any item",
                value: "any"
            })
        }

        if (whitelist) arr = arr.concat(whitelist);

        if (tagify) {
            tagify.whitelist = arr;
            return;
        }

        var tg = window.tagify(props.id, {
            closeOnSelect: props.closeOnSelect,
            single: props.single,
            freeTags: props.freeTags,
            email: props.email,
            inlineSuggestions: props.inlineSuggestions
        });

        tg.whitelist = arr;

        tagifySet(tg);
    }


    const onInput = (e: any) => {
        const keyword = e.detail.value;
        fetch(keyword);
    }


    useEffect(() => {

        tagify?.on('add', onAddTag);
        tagify?.on('remove', onRemoveTag);
        tagify?.on('dropdown:select', onDropDownSelect);

        if (props.liveSearch) tagify?.on('input', onInput);

        return () => {
            tagify?.off('add', onAddTag);
            tagify?.off('remove', onRemoveTag);
            tagify?.off('dropdown:select', onDropDownSelect);
            tagify?.off('input', onInput);
        }

    }, [tagify, values, props.eventsUpdate, props.liveSearch])


    useEffect(() => {

        return () => {
            //window.tagifyDestroy(props.id);
            tagify?.destroy();
            tagifySet(null);
        };
    }, [])

    useEffect(() => {
        if (props.clear && tagify) {
            tagify.removeAllTags();
            valuesSet([]);
            valueSet("");
        }

    }, [props.clear])

    useEffect(() => {
        if (env.isDevelopment) console.log("props.routeParams", props.routeParams, props.name);
        if (props.apiUrl) {
            fetch();
        }
        else create([]);
    }, [props.routeParams, props.apiUrl])

    useEffect(() => {
        if (props.source) {
            const src: TagifyDataItem[] = props.source.map(s => ({
                title: s.name,
                value: s.id ?? s.value,
                icon: s.icon,
                color: s.color,
                valid: true,
                exists: true
            }))

            localSourceSet(s => [...src])
        }
    }, [props.source])

    useEffect(() => {
        if (localSource) {
            create(localSource);
        }
    }, [localSource])


    const [defualtsLoaded, defualtsLoadedSet] = useState<boolean>(false);
    const [selectedLoaded, selectedLoadedSet] = useState<boolean>(false);

    useEffect(() => {

        if (tagify) {

            if (props.defaultId && !defualtsLoaded) {
                hub.Get(props.apiUrl + props.defaultId, res => {
                    if (res.data) tagify.addTags([res.data]);
                });
                var arr = values ?? [];
                arr.push(props.defaultId);
                valuesSet(v => [...arr]);
                defualtsLoadedSet(true);
            }
            else if (props.defaultIds && !defualtsLoaded) {
                tagify.removeAllTags();
                var arr: string[] = [];
                for (var i = 0; i < props.defaultIds.length; i++) {
                    var id = props.defaultIds[i];
                    if (arr.indexOf(id) > -1) continue;
                    if (localSource) {
                        localSource.forEach(e => {
                            if (e.value == id) {
                                tagify.addTags([e])
                                arr.push(id)
                            }
                        });
                    }
                    else if (props.apiUrl) {
                        hub.Get(props.apiUrl + id, res => {
                            if (res.data) {
                                tagify.addTags([res.data]);
                                arr.push(id);
                            }
                        });
                    }
                }
                valuesSet(v => [...arr]);
                defualtsLoadedSet(true);
            }
            else if (props.defaultValue && !defualtsLoaded) {
                var arr: string[] = [];
                arr.push(props.defaultValue);
                tagify.addTags(arr);
                valuesSet(v => [...arr]);
                defualtsLoadedSet(true);
            }
            else if (props.defaultValues && !defualtsLoaded) {
                var arr: string[] = [];
                for (var i = 0; i < props.defaultValues.length; i++) {
                    var val = props.defaultValues[i];
                    arr.push(val);
                }
                tagify.addTags(arr);
                valuesSet(v => [...arr]);
                defualtsLoadedSet(true);
            }
            else if (props.selectedId) {
                if (props.single) {
                    tagify.removeAllTags();
                }
                hub.Get(props.apiUrl + props.selectedId, res => {
                    if (res.data) tagify.addTags([res.data]);
                });
                var arr = props.single ? [] : (values ?? []);
                arr.push(props.selectedId);
                valuesSet(v => [...arr]);
                selectedLoadedSet(true);
            }
            else if (props.selectedIds) {
                var arr: string[] = [];
                for (var i = 0; i < props.selectedIds.length; i++) {
                    var id = props.selectedIds[i];
                    if (arr.indexOf(id) > -1) continue;
                    hub.Get(props.apiUrl + id, res => {
                        if (res.data) tagify.addTags([res.data]);
                    });
                    arr.push(id);
                }
                valuesSet(v => [...arr]);
                selectedLoadedSet(true);
            }
            else if (selectedLoaded) {
                valuesSet(v => []);
                tagify.removeAllTags();
            }
            else if (props.any) {
                tagify.addTags([{
                    title: props.anyText ?? "Any item",
                    value: "any"
                }])
            }

        }


    }, [tagify, props.defaultId, props.defaultValues, props.defaultIds, props.selectedId, props.selectedIds, props.any])


    useEffect(() => {

        if (values) {

            var vals = "";
            for (var i = 0; i < values.length; i++) {
                if (vals != "") vals += ",";
                vals += values[i];
            }
            if (env.isDevelopment) console.log("vals set", values.length, vals);
            valueSet(vals);

            if (defualtsLoaded) {

            }

            if (props.onChange) props.onChange(values);

            if (props.any && values.length < 1) {
                tagify.addTags([{
                    title: props.anyText ?? "Any item",
                    value: "any"
                }])
            }

        }

    }, [values])


    return (
        <>
            {
                props.raw ?
                    <input id={props.id} type="text" placeholder={props.placeholder} className={className()} />
                    :
                    <div className={"row align-items-center" + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 10))}>

                        {
                            props.label &&
                            <label className={"col col-12 " + (props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2")) + (props.small ? " fs-7" : " fs-7")} htmlFor={props.id}>
                                {props.label}
                                {
                                    props.tooltip &&
                                    <i className="fa-solid fa-circle-question ms-2 form-tooltips"
                                        id={(props.id) + "-tooltip"}
                                        data-bs-placement="right"
                                        data-bs-custom-class="tooltip-dark"
                                        title={props.tooltip}></i>
                                }
                                {
                                    props.labelUp && <span id={props.name + "-err"} className="small text-danger mx-3 form-error" data-tagify="yes"></span>
                                }
                                {
                                    props.hint &&
                                    <span className="small text-muted mx-3">
                                        <Icon icon="info-circle" className="me-1 small" />
                                        {props.hint}
                                    </span>
                                }
                            </label>
                        }

                        <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                            <div className={(props.icon) ? inputGroupClassName() : ""}>
                                {
                                    props.icon &&
                                    <span className="input-group-text">
                                        <Icon icon={props.icon} />
                                    </span>
                                }
                                <input id={props.id} type="text" placeholder={props.placeholder} className={className()} />
                                <input id={props.id + "-val"} type="hidden" name={props.name} value={value} />
                            </div>
                        </div>
                        {
                            !props.block && !props.labelUp &&
                            <div className="col col-md-4">
                                <span id={props.name + "-err"} className="small text-danger mx-2 form-error" data-tagify="yes"></span>
                            </div>
                        }

                    </div>
            }
        </>

    )

}