import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import { useHub } from "../../../core/Global";
import { ShiftViewDay, ShiftViewDepartment } from "../models/ShiftViewModels";
import { CompactUser } from "../../../core/CompactModels";
import { API_ROUTES } from "../../../core/Constants";
import { toastSuccess } from "../../../components/base/Toast";
import FlexRow from "../../../components/base/FlexRow";
import ColorPicker from "../../../components/form/ColorPicker";
import Icon from "../../../components/base/Icon";
import DropDown, { DropDownOption } from "../../../components/form/DropDown";
import TimePicker from "../../../components/form/TimePicker";
import Avatar from "../../../components/base/Avatar";
import { UserTypes } from "../../../core/Enums";


interface IProps {
    days?: ShiftViewDay[]
    department?: ShiftViewDepartment
    onClose?: () => void
    callback?: () => void
}

export default function AddShiftModal(props: IProps) {

    const hub = useHub();
    const [selectedDays, selectedDaysSet] = useState<ShiftViewDay[]>();

    const [agentsAssignPool, agentsAssignPoolSet] = useState<CompactUser[]>([]);
    const [agentsAssigned, agentsAssignedSet] = useState<CompactUser[]>([]);
    const [agentsPool, agentsPoolSet] = useState<CompactUser[]>([]);
    const [shiftAgents, shiftAgentsSet] = useState<CompactUser[]>([]);
    const [agentsPoolKeyword, agentsPoolKeywordSet] = useState<string>("");
    const [shiftAgentsKeyword, shiftAgentsKeywordSet] = useState<string>("");
    const [hasShiftTypes, hasShiftTypesSet] = useState<boolean>(false);
    const [customShiftType, customShiftTypeSet] = useState<boolean>(false);

    const [poolUserType, poolUserTypeSet] = useState<UserTypes>();
    const [assignedUserType, assignedUserTypeSet] = useState<UserTypes>();
 
    const fetchPool = () => {
        hub.Get("/accounts/users/pool", res => {
            if (res.success) {
                agentsAssignPoolSet(res.data);
            }
        })
    }

    useEffect(() => {
        window.dataTableMenu()
    }, [agentsAssignPool, agentsAssigned])

    const [conflictText, conflictTextSet] = useState<string>();
    const [assignBody, assignBodySet] = useState<any>();
      
      const postAssignAgents = (body?: any, callback?: any) => {  
        assignBodySet(body);
        hub.Post(API_ROUTES.Shifts + "assign", body, res => {
            if (res.success) {
                if (res.data == "conflict") {
                    conflictTextSet(res.message);
                    window.openModal("modal-add-shift-conflict-warning");
                    if (callback) callback();
                }
                else {
                    toastSuccess("Agent(s) have been assigned to shifts successfully!");
                    if (callback) callback(res);
                    if(props.callback) props.callback();
                }
            }
            else {
                if (callback) callback(res);
            }
            
        })
      }

    useEffect(() => {
        agentsAssignedSet([]);
        fetchPool();
    }, [])

    useEffect(() => {
        selectedDaysSet(props.days);
    }, [props.days])

    return (
        <>

        <Modal id="modal-add-shift"
            title="Assign New Shift"
            size="Large" staticBackdrop
            buttonText="Assign Shift"
            visible open
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                var shiftDates:number[] = [];
                var agents:string[] = [];

                selectedDays?.forEach(s => {
                    if (s.date) shiftDates.push(s.dateNumber);
                });
                agentsAssigned.forEach(a => {
                    if (a.id) agents.push(a.id);
                });

                postAssignAgents({
                    ...body,
                    departmentId: props.department?.id,
                    shiftDates: shiftDates,
                    agents: agents,
                    customShift: customShiftType ?? false
                }, callback);

            }}>

                <div>

                    <FlexRow className="mb-5">
                        <ColorPicker id="color-add-shift" color={props.department?.color} readonly width={26} />
                        <div className="fs-5 ms-2">
                            {props.department?.name}
                        </div>
                    </FlexRow>

                    <div className={"row align-items-center mb-5"}>
                        <label className={"col col-12 fw-bold fs-7"}>
                            Shift Date
                            <span id={"shiftDate-err"} className="small text-danger mx-3 form-error"></span>
                        </label>
                        <div className="col col-12 mt-2">
                            <div>
                                {
                                    selectedDays?.map((d, k)=> 
                                        <span key={k} className="badge badge-secondary me-1 mb-1">
                                            {d.dayName} - {d.dateLabel}
                                            <span className="px-2 cursor-pointer"
                                                onClick={e=> {
                                                    e.preventDefault();
                                                    var arr = selectedDays ?? [];
                                                    arr = arr.filter(day => day.dateNumber != d.dateNumber);
                                                    arr = arr.sort((a, b) => a.dateNumber - b.dateNumber);
                                                    selectedDaysSet(d=> [...arr]);
                                                }}>
                                                <Icon icon="times" size={10} />
                                            </span>
                                        </span>
                                    )
                                }

                                <span id="add-shift-dtrangepicker"></span>

                                <a href="#" className="btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark btn-agent-ext btn-inline"
                                    onClick={e=> {
                                        e.preventDefault();
                                        window.daterangeinline("add-shift-dtrangepicker", {
                                            autoUpdateInput: false,
                                            timePicker: false,
                                            // startDate: window.now().format("DD MMM YYYY"),
                                            // endDate: window.now(7).format("DD MMM YYYY"),
                                        }, (start, end) => {
                                            var arr = selectedDays ?? [];
                                            for(var d = start; d <= end; d = d.add(1, 'days')) {
                                                var dateNum = Number.parseInt(d.format("YYYYMMDD"));
                                                var exists = false;

                                                for (var i = 0; i < arr.length; i++) {
                                                    if (arr[i].dateNumber == dateNum) {
                                                        exists = true;
                                                        break;
                                                    }
                                                }

                                                if(!exists) {
                                                    arr.push({
                                                        dayName: d.format("dddd"),
                                                        dateLabel: d.format("DD MMM YYYY"),
                                                        date: d.format("DD/MM/YYYY"),
                                                        dateNumber: dateNum
                                                    });
                                                }
                                                
                                            }

                                            arr = arr.sort((a, b) => a.dateNumber - b.dateNumber);

                                            selectedDaysSet(d=> [...arr]);
                                        });
                                    }}>
                                    <Icon icon="plus" size={10} />
                                    Add Date
                                </a>

                            </div>
                        </div>
                    </div>


                    <DropDown label="Shift Type" name="shiftTypeId" labelUp visible={hasShiftTypes}
                        apiSource={API_ROUTES.TagifyShiftTypes + "?depId=" + props.department?.id} 
                        dataText="title" dataValue="value" childrenAtEnd
                        onSelect={val => {
                            customShiftTypeSet(val == "custom");
                        }}
                        onApiSourceFetched={data=> {
                            hasShiftTypesSet(data && data.length > 0);
                            customShiftTypeSet(!(data && data.length > 0));
                        }}>
                            <DropDownOption value="custom" text="Custom Shift"/>
                    </DropDown>

                    {
                        customShiftType &&
                        <>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TimePicker id="time-customshift-startTime" name="startTime" timeInput label="Start Time" labelUp />
                                </div>
                                <div className="col-sm-6">
                                    <TimePicker id="time-customshift-endTime" name="endTime" timeInput label="End Time" labelUp />
                                </div>
                            </div>
                        </>
                    }

                    <div className="row">
                        <div className="col-6">
                        <FlexRow className="border-bottom pb-2">
                            <div className="fs-5 fw-bolder">
                                Users Pool
                            </div>
                        </FlexRow>
                        <div>
                            {
                            agentsAssignPool.length > 0 &&
                            <div>
                                <div className="mb-2 position-relative">
                                    <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                        value={agentsPoolKeyword} onChange={e=> agentsPoolKeywordSet(e.target.value)} />
                                    
                                    <div className="position-absolute end-0 top-0 me-3 mt-3">
                                        <div className="align-items-center d-flex cursor-pointer" 
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <span className="me-2 fs-8">
                                                {
                                                    poolUserType == UserTypes.Admin ? "Managers"
                                                    : poolUserType == UserTypes.Agent ? "Agents"
                                                    : poolUserType == UserTypes.Technician ? "Technicians"
                                                    : "All Users"
                                                }
                                            </span>
                                            <Icon icon="chevron-down" size={11} />
                                        </div>
                                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        poolUserTypeSet(undefined);
                                                    }}>
                                                    All Users
                                                </a>
                                            </div>
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        poolUserTypeSet(UserTypes.Agent);
                                                    }}>
                                                    Agents
                                                </a>
                                            </div>
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        poolUserTypeSet(UserTypes.Technician);
                                                    }}>
                                                    Technicians
                                                </a>
                                            </div>
                                            <div className="menu-item">
                                                <a href="#" className="menu-link px-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        poolUserTypeSet(UserTypes.Admin);
                                                    }}>
                                                    Managers
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="scroll h-300px">
                                <table className="table table-striped">
                                    <tbody>
                                    {
                                        agentsAssignPool
                                            .filter(item => {
                                                if (poolUserType) return item.userType == poolUserType;
                                                return true;
                                            })
                                            .filter(item => {
                                                if (agentsPoolKeyword == "") return true;
                                                return item.fullName?.toLowerCase().includes(agentsPoolKeyword.toLowerCase());
                                            })
                                            .sort((a, b) => (a.sortNumber ?? 0) - (b.sortNumber ?? 0))
                                            .map((item, key) => 
                                        <tr key={key}>
                                        <td className="px-3">
                                            <FlexRow>
                                                <Avatar avatarUrl={item.avatarUrl} width={26} fullName={item.fullName} className="me-2" />
                                                <div className="me-1">
                                                    <div>
                                                        {item.fullName}
                                                    </div>
                                                    <div className="fs-9">{item.userType == UserTypes.Admin ? "Manager" : item.userType == UserTypes.Technician ? "Technician" : "Agent"}</div>
                                                </div>
                                            </FlexRow>
                                        </td>
                                        <td className="text-end px-3">
                                            <span className="badge badge-light-primary cursor-pointer"
                                                onClick={e=> {
                                                    let pool = agentsAssignPool ?? [];
                                                    let exts = agentsAssigned ?? [];
                                                    pool.splice(pool.indexOf(item), 1);
                                                    exts.push(item);
                                                    agentsAssignPoolSet(e=> [...pool]);
                                                    agentsAssignedSet(e=> [...exts]);
                                                }}>
                                                <Icon icon="plus" size={12} />
                                            </span>
                                        </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            
                            }
                            
                            {
                            agentsAssignPool.length == 0 &&
                            <div className="text-center mt-10">
                                There is no agent!
                            </div>
                            }
                        </div>
                        </div>
                        <div className="col-6">
                        
                            <FlexRow className="border-bottom pb-2">
                                <div className="fs-5 fw-bolder">
                                    Selected Users
                                </div>
                            </FlexRow>
                            <div>
                                {
                                agentsAssigned.length > 0 &&
                                <div>
                                    <div className="mb-2">
                                        <input type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                            value={shiftAgentsKeyword} onChange={e=> shiftAgentsKeywordSet(e.target.value)} />

                                        <div className="position-absolute end-0 top-0 me-3 mt-3">
                                            <div className="align-items-center d-flex cursor-pointer" 
                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                <span className="me-2 fs-8">
                                                    {
                                                        assignedUserType == UserTypes.Admin ? "Managers"
                                                        : assignedUserType == UserTypes.Agent ? "Agents"
                                                        : assignedUserType == UserTypes.Technician ? "Technicians"
                                                        : "All Users"
                                                    }
                                                </span>
                                                <Icon icon="chevron-down" size={11} />
                                            </div>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-1" data-kt-menu="true">
                                                <div className="menu-item">
                                                    <a href="#" className="menu-link px-3"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            assignedUserTypeSet(undefined);
                                                        }}>
                                                        All Users
                                                    </a>
                                                </div>
                                                <div className="menu-item">
                                                    <a href="#" className="menu-link px-3"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            assignedUserTypeSet(UserTypes.Agent);
                                                        }}>
                                                        Agents
                                                    </a>
                                                </div>
                                                <div className="menu-item">
                                                    <a href="#" className="menu-link px-3"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            assignedUserTypeSet(UserTypes.Technician);
                                                        }}>
                                                        Technicians
                                                    </a>
                                                </div>
                                                <div className="menu-item">
                                                    <a href="#" className="menu-link px-3"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            assignedUserTypeSet(UserTypes.Admin);
                                                        }}>
                                                        Managers
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scroll h-300px">
                                        <table className="table table-striped">
                                        <tbody>
                                            {
                                            agentsAssigned
                                                .filter(item => {
                                                    if (assignedUserType) return item.userType == assignedUserType;
                                                    return true;
                                                })
                                                .filter(item => {
                                                    if (shiftAgentsKeyword == "") return true;
                                                    return item.fullName?.toLowerCase().includes(shiftAgentsKeyword.toLowerCase());
                                                })
                                                .sort((a, b) => (a.sortNumber ?? 0) - (b.sortNumber ?? 0))
                                                .map((item, key) => 
                                            <tr key={key}>
                                                <td className="px-3">
                                                <FlexRow>
                                                    <Avatar avatarUrl={item.avatarUrl} width={26} fullName={item.fullName} />
                                                    <div>
                                                        <div className="ms-2">{item.fullName}</div>
                                                        <div className="ms-2 fs-8">{item.userType == UserTypes.Admin ? "Manager" : item.userType == UserTypes.Technician ? "Technician" : "Agent"}</div>
                                                    </div>
                                                </FlexRow>
                                                </td>
                                                <td className="px-3">
                                                <FlexRow justify="end">
                                                    <span className="badge badge-light-danger cursor-pointer"
                                                    onClick={e=> {
                                                        let pool = agentsAssignPool ?? [];
                                                        let exts = agentsAssigned ?? [];
                                                        exts.splice(exts.indexOf(item), 1);
                                                        pool.push(item);
                                                        agentsAssignPoolSet(e=> [...pool]);
                                                        agentsAssignedSet(e=> [...exts]);
                                                    }}>
                                                    <Icon icon="minus" size={12} />
                                                    </span>
                                                </FlexRow>
                                                
                                                
                                                </td>
                                            </tr>
                                            )
                                            }
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                }
                                


                                {
                                agentsAssigned.length == 0 &&
                                <div className="text-center mt-10">
                                    There is no agent!
                                </div>
                                }
                                
                            </div>
                        </div>
                    
                    </div>

                </div>

        </Modal>
        
        <Modal id="modal-add-shift-conflict-warning"
            title="Warning"
            staticBackdrop secondModal
            buttonText="Assign Anyway"
            buttonColor="warning"
            onConfirm={callback => {
                postAssignAgents({
                    ...assignBody, 
                    verified: true
                }, () => {
                    callback();
                    window.closeModal("modal-add-shift");
                })
            }}>
                <p>
                    {conflictText}
                </p>
        </Modal>

        </>
    )
}