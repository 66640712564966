import { Link } from "react-router-dom";
import LiveCall from "../../modules/voice/calls/models/LiveCall";
import Avatar from "../base/Avatar";
import Icon from "../base/Icon";
import { demoPhone, demoText, isDemo, setCallToListen, setChanSpyLine, setHangupChannel, useEnvironment, useSocket } from "../../core/Global";
import Timer from "../timer/Timer";
import { CallTypes } from "../../core/Enums";
import { useEffect, useState } from "react";


interface IProps {
    call: LiveCall
}

export default function LiveCallItem(props: IProps) {

    const socket = useSocket();
    const env = useEnvironment();
    const [call, callSet] = useState<LiveCall>(props.call);
    useEffect(() => {
        callSet(props.call);
    }, [props.call.id])

    useEffect(() => {

        var socketKey = socket.start(
            skt => {

                skt.on("UpdateLiveCall-" + call.id, data => {
                    if (env.isDevelopment) console.log("LiveCallSocket Update", data);
                    callSet(data);
                });

            }, skt => {
                skt.off("UpdateLiveCall-" + call.id);
            });

        return () => {
            socket.stop(socketKey);
        };

    }, [call.id])


    return (
        <div className="card mb-2"
            onClick={e => {
                e.preventDefault();
            }}>

            <div className="card-header d-flex align-items-center p-3" style={{ minHeight: 0 }}>
                <Link to={"/voice/call/" + call.id} className="d-flex align-items-center text-dark">
                    <Avatar width={18} avatarUrl={(call.sourceAgent?.country ? call.sourceAgent?.country?.flagSquare : call.sourceAgent?.avatarUrl)} className="me-1" />
                    {
                        call.sourceAgent?.id ?
                            <div className='me-1 fw-bold fs-8 limited-text mw-100px' title={call.sourceAgent.name}>
                                {isDemo() ? "Agent " + demoText(call.sourceAgent.name) : call.sourceAgent.name}
                            </div>
                            :
                            <div className='me-1 fw-bold fs-8 limited-text mw-100px' title={call.sourceAgent?.number}>
                                {isDemo() ? demoPhone() : call.sourceAgent?.number}
                            </div>
                    }

                    {
                        call.sourceAgent?.id ?
                            <div className='me-1 fs-9'>
                                ({call.sourceAgent?.number})
                            </div>
                            : !call.sourceAgent?.country ?
                                <div className='me-1 fs-9 limited-text mw-150px'>
                                    ({call.sourceAgent?.name})
                                </div>
                                : <></>
                    }
                </Link>
                <div className="d-flex align-items-center ms-auto">
                    <div className="btn-group" role="group">
                        <button className={"d-flex align-items-center btn btn-" + (call.talking ? "danger" : "primary") + " btn-agent-ext"}
                            onClick={e => {
                                e.preventDefault();
                                //var li = window.DialByLine('audio', undefined, '551' + call.extNumber);
                                //setChanSpyLine(li);
                                setCallToListen(call);
                            }}>
                            {
                                call.talking ?
                                    <Icon icon='phone-volume' type='solid' color="white" className="me-2 fs-8" />
                                    :
                                    <Icon id={"icon-ring-" + call.id} icon='phone-rotary' shake type='solid' color="white" className="me-2 fs-8" />
                            }
                            {
                                call.talking ?
                                    <Timer totalSeconds={call.talkingSeconds} width={35} />
                                    :
                                    <Timer totalSeconds={call.ringingSeconds} width={35} />
                            }

                        </button>
                        <div className="btn-group" role="group">
                            <button id={"btn-menu-" + call.id} type="button" className={"btn btn-" + (call.talking ? "danger" : "primary") + " btn-agent-ext dropdown-toggle dsp-dropdown"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                onClick={e => {
                                    window.dropdown("btn-menu-" + call.id);
                                }}>
                                <Icon icon="ellipsis-vertical" className="dsp-dropdown" size={10} />
                            </button>
                            <div className="dropdown-menu dsp-dropdown">
                                <Link className="dropdown-item dsp-dropdown fs-8" to={"/voice/call/" + call.id}>
                                    <Icon icon="phone" className="dsp-dropdown w-20px text-center" size={10} />
                                    Call Details
                                </Link>
                                <a className="dropdown-item dsp-dropdown fs-8" href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        var li = window.DialByLine('audio', undefined, '551' + call.extNumber);
                                        setChanSpyLine(li);
                                        setCallToListen(call);
                                        window.dropdown("btn-menu-" + call.id, true);
                                    }}>
                                    <Icon icon="volume" className="dsp-dropdown w-20px text-center" size={10} />
                                    Listen To Call
                                </a>
                                <a className="dropdown-item dsp-dropdown text-danger fs-8" href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        setHangupChannel(call.id);
                                        window.openModal("modal-hangup");
                                    }}>
                                    <Icon icon="phone-slash" className="dsp-dropdown w-20px text-danger text-center" size={10} />
                                    Hang Up
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                call.callType == CallTypes.Incoming &&
                <div className={"card-body px-5 py-3 position-relative"}>

                    <div className="d-flex align-items-center">
                        <Icon icon="phone-office" size={12} className="me-1" />
                        <div className='fs-9' style={{ marginTop: 1 }}>
                            {call.extNumber}
                        </div>
                        <Avatar width={15} avatarUrl={call.destinationAgent?.avatarUrl} className="ms-5 me-1" />
                        <div className='fs-9 limited-text mw-100px' style={{ marginTop: 2 }}>
                            {isDemo() ? "Agent " + demoText(call.destinationAgent?.name) : call.destinationAgent?.name}
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <Icon icon="briefcase" size={12} className="me-1" />
                        <div className='fs-9 limited-text mw-75px' style={{ marginTop: 2 }}>
                            {isDemo() ? "Company " + demoText(call.companyName) : call.companyName}
                        </div>

                        {
                            call.queueName &&
                            <>
                                <Icon icon="arrow-right-to-line" size={12} className="ms-5 me-1" />
                                <div className='fs-9 limited-text mw-75px' style={{ marginTop: 2 }}>
                                    {isDemo() ? "Queue " + demoText(call.queueName) : call.queueName}
                                </div>
                            </>
                        }
                    </div>
                    <div className="position-absolute" style={{
                        bottom: 8,
                        right: 10
                    }}>
                        <Icon icon='phone-arrow-down-left' type='solid' className="text-success opacity-75" size={20} title="Incoming Call" />
                    </div>
                </div>
            }

            {
                call.callType == CallTypes.Outgoing &&
                <div className={"card-body px-5 py-3 position-relative"}>

                    <div className="d-flex align-items-center">
                        {
                            call.destinationAgent?.country ?
                                <Avatar width={15} avatarUrl={call.destinationAgent?.country.flagSquare} className="me-1" />
                                :
                                <Icon icon="phone-office" size={12} className="me-1" />
                        }
                        <div className='fs-9' style={{ marginTop: 1 }}>
                            {isDemo() ? demoPhone() : call.destinationAgent?.number}
                        </div>

                        <Icon icon="briefcase" size={12} className="me-1 ms-4" />
                        <div className='fs-9 limited-text mw-75px' style={{ marginTop: 2 }}>
                            {isDemo() ? "Company " + demoText(call.companyName) : call.companyName}
                        </div>

                    </div>

                    <div className="position-absolute" style={{
                        bottom: 8,
                        right: 10
                    }}>
                        <Icon icon='phone-arrow-up-right' type='solid' className="text-primary opacity-75" size={20} title="Outgoing Call" />

                    </div>
                </div>
            }

        </div>
    )
}