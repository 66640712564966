import { useEffect, useState } from "react";
import Div from "../../../../components/base/Div";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Indicator from "../../../../components/base/Indicator";
import Modal from "../../../../components/base/Modal";
import { toastError } from "../../../../components/base/Toast";
import { PaymentGateways } from "../../../../core/Enums";
import { useHub, getUserId } from "../../../../core/Global";
import PaymentMethod from "../../../system/settings/models/PaymentMethod";
import PaymentCardView from "./PaymentCardView";


function PaymentMethods() {

    const hub = useHub();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<PaymentMethod[]>([]);
    const [selectedItem, selectedItemSet] = useState<PaymentMethod>();

    const fetch = (callback?: any) => {
        hub.Get("/accounts/profile/paymentmethods/" + getUserId(), res => {
            modelSet(res.data);
            fetchingSet(false);
            fetchedSet(true);
            if (callback) callback();
        })
    }


    useEffect(() => {

        fetch();

    }, [])

    return (
        <div>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Payment Methods</h3>
                    </div>
                    <div className="card-action">
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={e => {
                            e.preventDefault();
                            window.openModal("modal-add-method");
                        }}>
                            <Icon icon="plus" />
                            Add Payment Method
                        </button>
                    </div>
                </div>
                <div className="card-body border-top p-9">
                    <Div visible={fetching} className="my-20">
                        <Indicator />
                    </Div>
                    <Div visible={fetched}>
                        {
                            model.map((item, key) =>
                                <>
                                    <div key={key} className="d-flex flex-wrap align-items-center py-6">
                                        <PaymentCardView item={item} />
                                        <div className="ms-auto">
                                            {
                                                item.isPrimary &&
                                                <span className="badge badge-success fs-7 fw-bold px-6 py-3">
                                                    <Icon icon="check" className="me-2" />
                                                    Primary Card
                                                </span>
                                            }
                                            {
                                                !item.isPrimary &&
                                                <>
                                                    <button className="btn btn-outline btn-outline-success btn-active-light-success btn-sm me-2"
                                                        onClick={e => {
                                                            selectedItemSet(item);
                                                            window.openModal("modal-primary-method");
                                                        }}>
                                                        Set as Primary
                                                    </button>
                                                    <button className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm"
                                                        onClick={e => {
                                                            selectedItemSet(item);
                                                            window.openModal("modal-remove-method");
                                                        }}>
                                                        Remove Card
                                                    </button>
                                                </>
                                            }
                                        </div>

                                    </div>
                                    <div className="separator separator-dashed"></div>
                                </>
                            )
                        }

                        <div className="p-10 text-center">

                        </div>

                    </Div>
                </div>
            </div>

            <Modal id="modal-add-method"
                title="Add Payment Method"
                buttonText="Add Payment Method"
                onConfirm={callback => {
                    hub.Post("/billings/payments/paymentmethod", {
                        userId: getUserId()
                    }, res => {
                        if (res.success) {
                            setTimeout(() => {
                                document.location.href = res.data.url;
                            }, 200);
                        }
                        else {
                            toastError(res.message ?? "general error happened!");
                            callback(true);
                        }
                    });
                }}>
                <FlexRow>
                    <div>
                        You will redirect to
                    </div>
                    <div className="mx-2">
                        <Icon icon="stripe" type="brands" className="text-info" size={36} />
                    </div>
                    <div>
                        to add your payment method!
                    </div>
                </FlexRow>
            </Modal>

            <Modal id="modal-primary-method"
                title="Set as Primary"
                buttonText="Set as Primary"
                buttonColor="success"
                onConfirm={callback => {
                    hub.Post("/accounts/profile/primarypaymentmethod/", {
                        userId: getUserId(),
                        paymentMethodId: selectedItem?.id
                    }, res => {
                        if (res.success) {
                            fetch(callback);
                        }
                        else {
                            toastError(res.message);
                            callback(true);
                        }
                    })
                }}>
                <div>
                    <div className="mb-5">
                        Are you sure you want to set this card as primary payment method?
                    </div>
                    {
                        selectedItem && <PaymentCardView item={selectedItem} />
                    }
                </div>
            </Modal>

            <Modal id="modal-remove-method"
                title="Remove Payment Method"
                buttonText="Remove Card"
                buttonColor="danger"
                onConfirm={callback => {
                    hub.Delete("/accounts/profile/paymentmethod/" + selectedItem?.id, res => {
                        fetch(callback);
                    })
                }}>
                <div>
                    <div className="mb-5">
                        Are you sure you want to delete this payment method?
                    </div>
                    {
                        selectedItem && <PaymentCardView item={selectedItem} />
                    }
                </div>
            </Modal>
        </div>
    )
}

export default PaymentMethods;