import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import DropDown, { DropDownOption } from "../../../components/form/DropDown";
import FormTitle from "../../../components/form/FormTitle";
import TextBox from "../../../components/form/TextBox";
import { createUserDismiss, createUserWithTypeDismiss, getUser, updateUsersOn, useGlobal, useHub, usePreferences } from "../../../core/Global";
import { ListItem } from "../../../core/Types";
import PhoneInput from "../../../components/form/PhoneInput";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { UserTypes } from "../../../core/Enums";
import AddressField from "../../../components/here/AddressField";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { toastError } from "../../../components/base/Toast";
import Indicator from "../../../components/base/Indicator";
import Icon from "../../../components/base/Icon";
import FlexRow from "../../../components/base/FlexRow";

interface UserTypeItem {
    name: string
    id: string
    type: UserTypes
}

interface IProps {
    type?: UserTypes
}

export default function CreateUserModal(props: IProps) {

    const hub = useHub();
    const user = getUser();
    const [global] = useGlobal();
    const pref = usePreferences();


    const [Types] = useState<UserTypeItem[]>([
        {
            name: "Admin",
            id: "1",
            type: UserTypes.Admin
        },
        {
            name: "Agent",
            id: "2",
            type: UserTypes.Agent
        },
        // {
        //     name: "Customer",
        //     id: "3",
        //     type: UserTypes.Customer
        // },
        {
            name: "Technician",
            id: "4",
            type: UserTypes.Technician
        }
    ])
    const [SelectedUserType, SelectedUserTypeSet] = useState<UserTypeItem>();
    useEffect(() => {
        if (props.type) {
            SelectedUserTypeSet(Types.filter(a => a.type == props.type)[0])
        }
    }, [props.type])

    const [reset, resetSet] = useState<boolean>(false);
    const [keepOpen, keepOpenSet] = useState<boolean>(false);

    const [selectedState, selectedStateSet] = useState<string>();
    const [techWakeupLocation, techWakeupLocationSet] = useState<string>();

    const modalTitle = () => {
        if (props.type == UserTypes.Admin) return "Create Admin";
        else if (props.type == UserTypes.Agent) return "Create Agent";
        else if (props.type == UserTypes.Customer) return "Create Customer";
        else if (props.type == UserTypes.Technician) return "Create Technician";
        else return "Create User"
    }

    useEffect(() => {
        if (reset) {
            $("#txt-usermodalform-firstname").focus();
            setTimeout(() => {
                resetSet(false);
            }, 1000);
        }
    }, [reset])



    return (
        <Modal id="modal-create-user"
            title={modalTitle()} size="Large"
            visible open staticBackdrop
            createAnother={true}
            createAnotherText={"Create Another User"}
            onCreateAnotherChanged={k => keepOpenSet(k)}
            onClose={() => {
                createUserDismiss();
                createUserWithTypeDismiss();
            }}
            buttonText={modalTitle()}
            onSubmit={(body, callback) => {

                window.hideFormErrors();

                var valid = true;
                var focused = false;

                console.log(body);

                if (window.isNullOrEmpty(body.userType)) {
                    valid = false;
                    window.showFormError("userType", "Please choose the user type!", false, "modal-create-user", false);
                    focused = true;
                }

                if (!body.firstName || body.firstName.length < 2) {
                    valid = false;
                    window.showFormError("firstName", "Please write a valid first name!", focused, "modal-create-user", focused);
                    focused = true;
                }

                if (!body.lastName || body.lastName.length < 2) {
                    valid = false;
                    window.showFormError("lastName", "Please write a valid last name!", focused, "modal-create-user", focused);
                    focused = true;
                }

                if (!body.username || body.username.length < 2) {
                    valid = false;
                    window.showFormError("username", "Please write a valid username!", focused, "modal-create-user", focused);
                    focused = true;
                }

                if (!body.password || body.password.length < 6) {
                    valid = false;
                    window.showFormError("password", "The password must contain at least 6 characters!", focused, "modal-create-user", focused, true);
                    focused = true;
                }

                if (!body.email) {
                    valid = false;
                    window.showFormError("email", "Please write a valid email address!", focused, "modal-create-user", focused);
                    focused = true;
                }

                // if (!body.firstName || body.firstName.length < 2) {
                //     valid = false;
                //     window.showFormError("password", "", false, "modal-create-user", false);
                // }

                if (!valid) {
                    if (callback) callback();
                    return;
                }

                hub.Post(API_ROUTES.Users, body, res => {
                    if (res.success) {
                        updateUsersOn();
                        if (keepOpen) {
                            resetSet(true);
                            if (callback) callback();
                        }
                        else if (callback) callback(res);
                    }
                    else {
                        if (res.field) window.showFormError(res.field, res.message, false, "modal-create-user");
                        else toastError(res.message);
                        if (callback) callback();
                    }
                });

            }}>

            {
                props.type ?
                    <input type="hidden" name="userType" value={props.type} />
                    :
                    <DropDown label="User Type" name="userType" block labelUp
                        source={Types} parentModal="modal-create-user"
                        onSelectItem={e => SelectedUserTypeSet(e)}
                        onDeselect={() => SelectedUserTypeSet(undefined)}>
                    </DropDown>
            }


            <div>

                <div className="row">
                    <div className="col-6">
                        <TextBox id="txt-usermodalform-firstname" name="firstName" label="First Name*" labelUp block clear={reset} parent="modal-create-user" />
                    </div>
                    <div className="col-6">
                        <TextBox name="lastName" label="Last Name*" labelUp block clear={reset} parent="modal-create-user" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <TextBox name="username" label="Username*" labelUp block clear={reset} parent="modal-create-user" />
                    </div>
                    <div className="col-6">
                        <TextBox name="password" label="Password*" labelUp block password generatePassword clear={reset} parent="modal-create-user" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <TextBox name="email" label="Email*" labelUp block email clear={reset} parent="modal-create-user" />
                    </div>
                    <div className="col-6">
                        <PhoneInput id="txt-usermodalform-phone" label="Phone" parent="modal-create-user" name="phone" block labelUp reset={reset} />
                    </div>
                </div>

                <Tagify name="groupIds" id="tagify-userform-groups" label="User Group" marginBottom={10}
                    placeholder="Select User Groups" apiUrl={API_ROUTES.TagifyUserGroups} block labelUp clear={reset} />

                {
                    user?.isSuperAdmin &&
                    <div className="row mb-5">
                        <div className="col">
                            <ToggleSwitch name="isSystemAdmin" raw small label="This user is a system administrator!" />
                        </div>
                    </div>
                }

                <div className="row mb-10">
                    <div className="col">
                        <ToggleSwitch id="toggle-userform-sendtoemail" name="sendToEmail" raw small
                            label="Send login information to the user via email!" />
                    </div>
                </div>
                {
                    SelectedUserType?.type == UserTypes.Technician &&
                    <>
                        <FormTitle title="Technician Details" margin={0} className="mb-5" />

                        <Tagify id="tagify-userform-techgroups" name="techGroups" label="Technician Group(s)" labelUp block
                            apiUrl={API_ROUTES.TagifyTechGroups} marginBottom={5} clear={reset} />

                        <Tagify id="tagify-userform-techskills" name="techSkills" label="Skills" labelUp block
                            hint="Keep it blank to use all skills!"
                            apiUrl={API_ROUTES.TagifyJobTypes} marginBottom={5} clear={reset} />

                        <Tagify id="tagify-userform-techsources" name="techSources" label="Sources" labelUp block
                            hint="Keep it blank to use all sources!"
                            apiUrl={API_ROUTES.TagifyJobSources} marginBottom={5} clear={reset} />

                        <Tagify id="tagify-userform-metros" name="techMetroIds" label="Metros" labelUp block
                            hint="Keep it blank to use all metros!"
                            apiUrl={API_ROUTES.TagifyMetros} marginBottom={5} clear={reset} />

                        <AddressField id="txt-userform-techaddress" label="Wakeup Address" name="techAddress" labelUp block reset={reset}
                            onSelect={data => {
                                selectedStateSet(data.address?.stateId);
                                techWakeupLocationSet(data.position?.lat + "," + data.position?.lng);
                            }} />

                        <input type="hidden" name="techWakeupLocation" value={techWakeupLocation} />

                        <div className="row">
                            <div className="col-4">
                                <TextBox name="defaultRate" label="Tech Rate" placeholder={`inherit from system rate (${pref?.defaultTechsRate}%)`}
                                    numeric maxLength={2} block labelUp icon="percent" />
                            </div>
                            <div className="col-4">
                                <TextBox name="defaultFee" label="Tech Fee" placeholder={`inherit from system fee (${pref?.defaultTechsFee}$)`}
                                    money block labelUp icon="dollar-sign" />
                            </div>
                            <div className="col-4">
                                <TextBox name="defaultCCFee" label="CC Fee" placeholder={`inherit from system fee (${pref?.defaultTechsCCRate}$)`}
                                    money block labelUp icon="dollar-sign" />
                            </div>
                        </div>
                    </>
                }

            </div>

        </Modal>
    )
}