import { useEffect, useState } from "react";
import Indicator from "../base/Indicator";
import { clearChatJob, useHub } from "../../core/Global";
import ChatBox from "../../modules/chat/components/ChatBox";
import { API_ROUTES } from "../../core/Constants";
import ChatContactItem from "../../modules/chat/models/ChatContactItem";
import Job from "../../modules/crm/jobs/models/Job";


interface IProps {
    job: Job
}
export default function ChatDrawer(props: IProps) {

    const hub = useHub();
    const [loading, loadingSet] = useState<boolean>(true);
    const [chat, chatSet] = useState<ChatContactItem>();

    const fetch = () => {
        loadingSet(true);

        hub.Get(API_ROUTES.ChatContacts + props.job?.techId, res => {
            if(res.success) {
                chatSet(res.data);
                loadingSet(false);
            }
        })
    }

    useEffect(() => {
        window.KTDrawerCreate();
        window.openChatDrawer(clearChatJob);
        fetch();

    }, [])

    return (
        <div id="kt_drawer_chat" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'35%'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close">
            {
                loading && 
                <div className="w-100" style={{paddingTop: 300}}>
                    <Indicator className="w-100" text="Loading Chat..."/>
                </div>
            }

            {
                !loading && chat && <ChatBox chat={chat} chatId={chat.chatId} jobId={props.job?.id} inDrawer />
            }
        </div>
    )
}