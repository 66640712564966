import { useEffect, useState } from "react";


export default function ChatUnselectedView() {

    const [boxHeight, boxHeightSet] = useState<number>();
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400; 
        if (w < 500) {
            h = 250;
        }
        else {
            h = h - ($("#kt_header").outerHeight() ?? 50);
            h = h - ($("#kt_toolbar").outerHeight() ?? 50);
            // h = h - ($("#kt_chat_messenger_header").outerHeight() ?? 50);
            // h = h - ($("#kt_chat_messenger_footer").outerHeight() ?? 50);
            h = h - 40;
        }
        boxHeightSet(h);
    }

    useEffect(() => {

        fixSizes();
        window.addEventListener("resize", fixSizes);

        return () => {
            window.removeEventListener("resize", fixSizes);
        }

    }, [])

    return (
        <div className="card">
            <div className="card-body" style={{
                height: boxHeight
            }}>
                <div className="card-px text-center pt-15 pb-15">
                    <h2 className="fs-2x fw-bolder mb-0">Select a Conversation to Continue!</h2>
                    
                </div>
                <div className="text-center pb-15 px-5">
                    <img src="/assets/media/illustrations/sketchy-1/16.png" alt="" className="mw-100 h-200px h-sm-325px" />
                </div>
            </div>
        </div>
    )
}