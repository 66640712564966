import { useState } from "react";
import Modal from "../../../components/base/Modal";
import ShiftType from "../models/ShiftType";
import { getUser, useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import FlexRow from "../../../components/base/FlexRow";
import ColorPicker from "../../../components/form/ColorPicker";
import TextBox from "../../../components/form/TextBox";
import Tagify from "../../../components/tagify/Tagify";
import Icon from "../../../components/base/Icon";
import { ListItem } from "../../../core/Types";
import TimePicker, { TimeItem } from "../../../components/form/TimePicker";
import ToggleSwitch from "../../../components/form/ToggleSwitch";


interface IProps {
    item?: ShiftType
    onClose: () => void
    onSave: () => void
}
export default function ShiftTypeForm(props: IProps) {

    const hub = useHub();
    const user = getUser();


    const [startTime, startTimeSet] = useState<TimeItem>();
    const [endTime, endTimeSet] = useState<TimeItem>();
    const [breakStart, breakStartSet] = useState<TimeItem>();
    const [breakEnd, breadkEdnSet] = useState<TimeItem>();
    const [breakTime, breakTimeSet] = useState<boolean>(false);



    return (
        <Modal id="modal-shifttype-form"
            title={props.item ? "Edit Shift Type" : "Create Shift Type"}
            buttonText={props.item ? "Update Shift Type" : "Create Shift Type"}
            visible open staticBackdrop
            size="Large"
            onClose={props.onClose}
            onSubmit={(body, callback) => {
                window.hideFormErrors();
                var valid = true;
                var focus = true;

                if (!body.name || body.name == "" || body.name.length < 2) {
                    window.formError("name", "Please write a valid name!", "modal-shifttype-form", focus);
                    valid = false;
                    focus = false;
                }

                if (!startTime) {
                    window.formError("startTime", "Shift start time is not valid!", "modal-shifttype-form", focus, false, false);
                    valid = false;
                    focus = false;
                }

                if (!endTime || endTime.time <= (startTime?.time ?? 0)) {
                    window.formError("endTime", "Shift end time is not valid!", "modal-shifttype-form", focus, false, false);
                    valid = false;
                    focus = false;
                }

                if (breakTime) {
                    if (!breakStart || breakStart.time < (startTime?.time ?? 0) || breakStart.time > (endTime?.time ?? 0)) {
                        window.formError("breakTimeStart", "Break start time is not valid!", "modal-shifttype-form", focus, false, false);
                        valid = false;
                        focus = false;
                    }
                    if (!breakEnd || breakEnd.time <= (breakStart?.time ?? 0) || breakEnd.time > (endTime?.time ?? 0)) {
                        window.formError("breakTimeEnd", "Break end time is not valid!", "modal-shifttype-form", focus, false, false);
                        valid = false;
                        focus = false;
                    }
                }

                if (!valid) {
                    if (callback) callback();
                    return;
                }

                hub.Post(API_ROUTES.ShiftsTypes, body, res => {
                    if (res.success) {
                        props.onSave()
                    }
                    if (callback) callback(res);
                })

            }}>
            <div>
                <FlexRow className="mb-5">
                    <ColorPicker id="color-addd-shift" width={30} className="me-2" name="color" />
                    <span className="small text-muted ms-2">
                        <Icon icon="info-circle" type="solid" className="me-1" size={10} />
                        Assign a color to differentiate this shift type easily on the calendar
                    </span>
                </FlexRow>

                <TextBox id="txt-addshift-name" name="name" block label="Name *" labelUp placeholder="Shift Type Name" />

                <div className="fs-7">
                    Department(s)
                    <span className="small text-muted ms-3">
                        <Icon icon="info-circle" type="solid" className="me-1" size={10} />
                        Leave it blank to use the shift type for all departments!
                    </span>
                </div>

                <Tagify name="departmentIds" id="drp-add-departmentIds" placeholder="Select Department(s)"
                    apiUrl={API_ROUTES.TagifyDepartments} block />

                <div className="row">
                    <div className="col-sm-6">
                        <TimePicker id="time-shifttype-startTime" name="startTime" timeInput label="Start Time" labelUp
                            onTimePicked={t => startTimeSet(t)}
                            onClear={() => startTimeSet(undefined)} />
                    </div>
                    <div className="col-sm-6">
                        <TimePicker id="time-addshifttype-endTime" name="endTime" timeInput label="End Time" labelUp min={startTime?.time} nextDay
                            onTimePicked={t => endTimeSet(t)} />
                    </div>
                </div>


                <div className="row mt-4">
                    <div className="col-6">
                        <ToggleSwitch name="breakTime" raw label="Break Time" checked={breakTime} onChange={e => breakTimeSet(e)} />
                    </div>
                </div>

                {
                    breakTime &&
                    <div className="row mt-6">
                        <div className="col-sm-6">
                            <TimePicker id="time-addshifttype-breakTimeStart" name="breakTimeStart"
                                timeInput label="Break Time's Start" labelUp
                                min={startTime?.time} max={endTime?.time} nextDay={endTime?.nextDay}
                                onTimePicked={t => breakStartSet(t)} />
                        </div>
                        <div className="col-sm-6">
                            <TimePicker id="time-addshifttype-breakTimeEnd" name="breakTimeEnd"
                                timeInput label="Break Time's End" labelUp
                                min={breakStart ? breakStart.time : startTime?.time}
                                max={endTime?.time} nextDay={endTime?.nextDay}
                                onTimePicked={t => breadkEdnSet(t)} />
                        </div>
                    </div>
                }

                <div className="h-100px">&nbsp;</div>

            </div>
        </Modal>
    )
}