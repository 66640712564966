import "./RolesForm.css";
import React, { useEffect, useState } from "react";
import UserGroup from '../models/UserGroup';
import { API_ROUTES } from '../../../core/Constants';
import Layout from '../../../components/layouts/Layout';
import Div from '../../../components/base/Div';
import Indicator from '../../../components/base/Indicator';
import { useParams } from "react-router-dom";
import { clearToolbarButton, setPage, setToolbarActions, setToolbarButton, updateToolbarButtonAction, updateToolbarButtonWorking, validateRole } from '../../../core/Global';
import Hub from '../../../api/Hub';
import Roles from "../../../core/Roles.json";
import Environment from '../../../env/env';
import { toastSuccess } from '../../../components/base/Toast';
import Section from "../../../components/base/Section";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";

type RolesFormProps = {
    isUser?: boolean,
    isGroup?: boolean,
    isType?: boolean
}

type RoleToggleProps = {
    name?: string
    roleName?: string,
    checked?: boolean,
    disabled?: boolean,
    deny?: boolean
}

let RolesInherited = new Array<string>();
let RolesGranted = new Array<string>();
let RolesDenied = new Array<string>();
let RouteName = "";

function RolesForm(props: RolesFormProps) {

    const hub = new Hub<UserGroup>(API_ROUTES.UsersGroups);
    const env = new Environment();
    const { selectedId } = useParams();
    const [rolesInherited, rolesInheritedSet] = useState<string[]>();
    const [rolesGranted, rolesGrantedSet] = useState<string[]>();
    const [rolesDenied, rolesDeniedSet] = useState<string[]>();
    const [rolesAllowed, rolesAllowedSet] = useState<string[]>([]);
    const [AllRolesAllowed, AllRolesAllowedSet] = useState<boolean>();
    const [loading, loadingSet] = useState<boolean>(true);

    const RoleToggle = (props: RoleToggleProps) => {
        return (
            (
                <label className={"form-check form-switch form-check-custom  form-check-solid" + (props.deny ? " form-check-danger" : "")}>
                    <input className="form-check-input" name={props.name} type="checkbox" disabled={props.disabled}
                        onChange={e => {

                            if (props.deny) {
                                RolesGranted = RemoveRole(RolesGranted, props.roleName);
                                RolesDenied = AddRole(RolesDenied, props.roleName);
                            }
                            else {
                                RolesDenied = RemoveRole(RolesDenied, props.roleName);
                                RolesGranted = AddRole(RolesGranted, props.roleName);
                            }

                            rolesGrantedSet(r => [...RolesGranted]);
                            rolesDeniedSet(r => [...RolesDenied]);

                        }}
                        value={props.roleName} checked={props.checked} />
                </label>
            )
        )
    }

    const AddRole = (arr?: string[], value?: string): string[] => {
        if (!value) return [];
        if (!arr) return [value ?? ""];
        var exists = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == value) {
                exists = true;
                break;
            }
        }

        if (!exists) arr.push(value);
        else arr = RemoveRole(arr, value);

        return arr;

    }

    const RemoveRole = (arr?: string[], value?: string): string[] => {
        if (!arr) return [];
        return arr.filter(function (ele) {
            return ele != value;
        });
    }

    const init = async () => {
        loadingSet(true);

        if (selectedId) {
            if (props.isUser) {
                setPage("User Roles", "Account", "Users");
                RouteName = "users";
            }
            if (props.isGroup) {
                setPage("Group Roles", "Account", "User Groups");
                RouteName = "usergroups";
            }
            if (props.isType) {
                if (selectedId != "admin" && selectedId != "customer" && selectedId != "dispatcher") {
                    document.location.href = "/";
                }
                setPage((selectedId == "admin" ? "Admins" : selectedId == "customer" ? "Customers" : selectedId == "dispatcher" ? "Agents" : "") + " Default Roles", "Account", "Default Roles");
                RouteName = "settings";
            }

            if (env.isDevelopment) {
                console.log("Roles", Roles);
            }

            await hub.Get("/accounts/" + RouteName + "/roles/" + selectedId, res => {
                if (res.success) {
                    RolesInherited = res.data.rolesInherited;
                    RolesGranted = res.data.rolesGranted;
                    RolesDenied = res.data.rolesDenied;
                    rolesInheritedSet(res.data.rolesInherited);
                    rolesGrantedSet(res.data.rolesGranted);
                    rolesDeniedSet(res.data.rolesDenied);
                    rolesAllowedSet(res.data.rolesAllowed);
                    AllRolesAllowedSet(res.data.allRolesAllowed);
                    loadingSet(false);
                }
            });

        }
        else {

        }
    }

    const update = async () => {

        updateToolbarButtonWorking(true);
        if (env.isDevelopment) {
            console.log("rolesGranted", rolesGranted)
            console.log("rolesDenied", rolesDenied)
        }

        await hub.Put("/accounts/" + RouteName + "/roles/" + selectedId, {
            rolesGranted: rolesGranted,
            rolesDenied: rolesDenied
        },
            res => {
                if (res.success) {
                    toastSuccess("Roles has been updated successfully!");
                    updateToolbarButtonWorking(false);
                }
            });
    }

    useEffect(() => {
        init();
    }, [selectedId])


    useEffect(() => {

        if (props.isType && selectedId?.toLowerCase() == "customer") {
            validateRole("IsSuperAdmin");
        }
        else if (props.isType) {
            validateRole("IsCustomer");
        }


        setToolbarButton({
            title: "Update Roles",
            color: "primary",
            onClick: update
        })

        return () => {
            clearToolbarButton();
        }
    }, [])

    useEffect(() => {
        updateToolbarButtonAction(update);
    }, [rolesGranted, rolesDenied]);

    return (

        <Layout wide>

            <Section fluid>

                <Div visible={loading} className="p-20 text-center">
                    <Indicator text="Please wait..." />
                </Div>

                <Div visible={!loading}>


                    <div className="card mb-1">
                        <div className="card-body">
                            {
                                Roles.map((group, key) =>
                                    <div key={key}>
                                        <table className="table table-striped table-roles">
                                            <thead>
                                                <tr>
                                                    <th className="fs-4 fw-bolder">
                                                        {group.title}
                                                    </th>
                                                    <th className="action-col">
                                                        Inherited
                                                    </th>
                                                    <th className="action-col">
                                                        Grant
                                                    </th>
                                                    <th className="action-col">
                                                        Deny
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    group.roles.filter(f => AllRolesAllowed || rolesAllowed.indexOf(f.roleName) > -1).map((role, roleKey) =>
                                                        <tr key={roleKey}>
                                                            <td>
                                                                <div className="fw-bold fs-6">
                                                                    {role.title}
                                                                </div>
                                                                <div className={`fs-8 ${(role.danger ? "text-danger" : "text-muted")}`}>
                                                                    {role.description}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <label className={"form-check form-switch form-check-custom  form-check-solid"}>
                                                                    <input className="form-check-input" type="checkbox" disabled
                                                                        checked={rolesInherited?.includes(role.roleName)} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <label className={"form-check form-switch form-check-custom  form-check-solid"}>
                                                                    <input className="form-check-input" name="grantedRoles" type="checkbox"
                                                                        onChange={e => {

                                                                            RolesDenied = RemoveRole(RolesDenied, role.roleName);
                                                                            RolesGranted = AddRole(RolesGranted, role.roleName);

                                                                            rolesGrantedSet(r => [...RolesGranted]);
                                                                            rolesDeniedSet(r => [...RolesDenied]);

                                                                        }}
                                                                        value={role.roleName} checked={rolesGranted?.includes(role.roleName)} />
                                                                </label>
                                                            </td>
                                                            <td>
                                                                <label className={"form-check form-switch form-check-custom  form-check-solid form-check-danger"}>
                                                                    <input className="form-check-input" name={"deniedRoles"} type="checkbox"
                                                                        onChange={e => {

                                                                            RolesGranted = RemoveRole(RolesGranted, role.roleName);
                                                                            RolesDenied = AddRole(RolesDenied, role.roleName);

                                                                            rolesGrantedSet(r => [...RolesGranted]);
                                                                            rolesDeniedSet(r => [...RolesDenied]);

                                                                        }}
                                                                        value={role.roleName} checked={rolesDenied?.includes(role.roleName)} />
                                                                </label>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                        <hr />
                                    </div>
                                )
                            }

                        </div>
                    </div>

                </Div>

            </Section>



        </Layout>
    );
}

export default RolesForm;
