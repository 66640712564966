import { DataTable } from "../../../../components/data/DataTable";

type Props = {
    id?: string
}

function CallCEL(props: Props) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body border-top p-9">
                        <DataTable apiRoute={"/system/calls/cel/" + props.id}
                            objectName="" noHeader noFilter noAction preventCreate />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallCEL;