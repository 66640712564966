import React, { useEffect, useState } from "react";
import Icon from "../base/Icon";
import { toastDefault, toastInfo } from "../base/Toast";
import { FontAwesomeIcons } from "../../core/Types";
import FlexRow from "../base/FlexRow";
import { useScreen } from "../../core/Global";
import { copyToClipboard } from "../../core/Helper";

interface TextBoxProps {
    id?: string
    label?: string
    labelUp?: boolean
    small?: boolean
    solid?: boolean
    clear?: boolean
    placeholder?: string
    className?: string
    name?: string
    parent?: string
    defaultValue?: string
    value?: any
    required?: boolean
    numeric?: boolean
    autoFocus?: boolean
    maxLength?: number
    password?: boolean
    money?: boolean
    timeInput?: boolean
    generatePassword?: boolean
    email?: boolean
    ipAddress?: boolean
    inlineStyle?: boolean
    largeText?: boolean
    mediumText?: boolean
    marginBottom?: number
    noMarginBottom?: boolean
    readOnly?: boolean
    block?: boolean
    working?: boolean
    tooltip?: string
    startWith?: string
    noTextError?: boolean
    icon?: FontAwesomeIcons
    iconText?: string
    hint?: string
    view?: boolean
    disabled?: boolean
    tabIndex?: number
    onEditMode?: () => void
    onTextChanges?: (text: string) => void
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}




export default function TextBox(props: TextBoxProps) {

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: "code"
    });

    let element: HTMLInputElement | null;
    const id = "txt-" + (Math.floor(Math.random() * 1000000));
    const [inputId] = useState<string>(props.id ?? id);

    const screen = useScreen();

    const fontClass = () => {
        if (props.small) return "fs-8";
        return "fs-7";
    }

    const [text, textSet] = useState<string>("");
    const [focused, focusedSet] = useState<boolean>(false);
    const [viewMode, viewModeSet] = useState<boolean>(false);
    useEffect(() => {
        viewModeSet(props.view ?? false);
    }, [props.view])

    const [defaultValueLoaded, defaultValueLoadedSet] = useState<boolean>(false);

    const className = () => {
        var cls = "form-control form-inputs";
        if (props.small) cls += " form-control-sm";
        if (props.solid) cls += " form-control-solid";
        if (props.autoFocus) cls += " auto-focus";
        if (props.inlineStyle && !focused) cls += " textbox-inline-style";
        if (props.largeText) cls += " textbox-large-text";
        if (props.mediumText) cls += " textbox-medium-text";
        if (props.password) cls += " textbox-password";
        if (props.className) cls += " " + props.className;
        return cls;
    };

    const inputGroupClassName = () => {
        var cls = "input-group";
        if (props.small) cls += " input-group-sm";
        if (props.solid) cls += " input-group-solid";
        return cls;
    }

    const type = () => {
        var res = "text";
        if (props.numeric) res = "tel";
        else if (props.timeInput) res = "tel";
        else if (props.email) res = "email";
        else if (props.password) res = "password";
        else if (props.money) res = "tel"
        return res;
    }

    const keypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.numeric) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                e.preventDefault();
            }
        }

        if (props.money) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter" && e.key != ".") {
                e.preventDefault();
            }
        }

        if (props.ipAddress && (text.endsWith(".") || text.split(".").length >= 4)) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                e.preventDefault();
            }
        }

        if (props.ipAddress && !text.endsWith(".")) {
            if (!/[0-9]/.test(e.key) && e.key != "Enter" && e.key != ".") {
                e.preventDefault();
            }
        }

        if (props.timeInput) {

            if (text && text.length == 2) {
                if (!/[0-9]/.test(e.key) && e.key != "Enter" && e.key != ":") {
                    e.preventDefault();
                }
            }
            else {
                if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                    e.preventDefault();
                }
            }




        }
    }

    const change = (e: React.ChangeEvent<HTMLInputElement>) => {

        window.hideFormError(props.name, props.parent);


        var txt = e.target.value ?? "";

        if (props.timeInput) {
            if (txt.length > 5) return;
            if (txt.length == 3) {
                var hh = Number.parseInt(txt[0] + txt[1]);
                if (hh > 23) hh = 23;
                if (txt[2] != ":") {
                    txt = (hh < 10 ? "0" : "") + hh + ":" + txt[2];
                }
            }

            if (txt.length == 5) {
                var hh = Number.parseInt(txt[0] + txt[1]);
                if (hh > 23) hh = 23;
                var mm = Number.parseInt(txt[3] + txt[4]);
                if (mm > 59) mm = 59;
                txt = (hh < 10 ? "0" : "") + hh + ":" + (mm < 10 ? "0" : "") + mm;
            }
        }

        if (props.ipAddress) {

            var ipArray = txt.split(".");
            var lastValue = ipArray[ipArray.length - 1];
            if (lastValue != "" && parseInt(lastValue) > 255) {
                ipArray[ipArray.length - 1] = '255';
            }
            txt = ipArray.join(".");
            if (lastValue.length == 3 && ipArray.length < 4) txt += ".";
        }

        if (props.money) {


            // var centdot = txt.endsWith(".");
            // var arr = txt.split(".");
            // var num1 = arr[0].replaceAll(",", "");
            // if (num1.length > 9) return;
            // var num2 = arr.length > 1 ? arr[1] : "";
            // num1 = num1.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            // var cent = Number.parseInt(num2);
            // if (isNaN(cent)) cent = 0;
            // if (cent > 99) cent = 99;

            // txt = num1;
            // if (cent > 0) txt += "." + cent;
            // else if (centdot) txt += ".";

            //txt = txt.replace(/[^0-9.,]/g, "");

            const pointCount = (txt.match(/\./g) || []).length;
            if (pointCount > 1) {
                txt = txt.substring(0, txt.lastIndexOf("."));
            }

            const [integerPart, decimalPart] = txt.split(".");

            const formattedInteger = integerPart
                ? parseInt(integerPart.replace(/,/g, ""), 10).toLocaleString()
                : "";

            const formattedDecimal = decimalPart !== undefined
                ? `.${decimalPart.substring(0, 2)}`
                : "";
            txt = formattedInteger + formattedDecimal;

        }

        textSet(txt);
        if (props.onTextChanges) props.onTextChanges(txt);
        if (props.onChange) props.onChange(e);
    }


    const focus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        focusedSet(true);
    }
    const blur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        focusedSet(false);
    }

    const mouseOver = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // if(props.password) {
        //     $(e.target).attr("type", "text");
        // }
    }

    const mouseOut = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // if (props.password) {
        //     $(e.target).attr("type", "password");
        // }
    }

    useEffect(() => {
        window.makeFormTooltip((inputId) + "-tooltip");
        if (props.autoFocus) {
            setTimeout(() => {
                $("#" + props.id).focus();
            }, 200);
        }

    }, []);


    useEffect(() => {
        if (props.defaultValue && !defaultValueLoaded) {
            textSet(props.defaultValue);
            defaultValueLoadedSet(true);
        }
        else {
            var txt = props.value ?? "";
            // if(props.money && txt != "") {
            //     var num = Number.parseFloat(txt.replaceAll(",", ""));
            //     if (isNaN(num)) num = 0;
            //     txt = moneyFormatter.format(num).replace("USD", "").trim();
            // }
            textSet(txt);
        }
    }, [props.defaultValue, props.value])


    useEffect(() => {
        if (props.clear) {
            textSet("");
        }
    }, [props.clear])




    return (
        <div className={"row align-items-center" + (props.noMarginBottom ? "" : " mb-" + (props.marginBottom ?? 5))}>
            {
                props.label &&
                <label className={`col col-12 ${(props.labelUp ? "mb-1" : (props.block ? "col-md-3" : "col-md-2"))} ${fontClass()}`} htmlFor={inputId}>
                    {props.label}
                    {
                        props.tooltip &&
                        <i className="fa-solid fa-circle-question ms-2 form-tooltips"
                            id={(inputId) + "-tooltip"}
                            data-bs-placement="right"
                            data-bs-custom-class="tooltip-dark"
                            title={props.tooltip}></i>
                    }
                    {
                        props.labelUp && !props.noTextError && <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                    }
                </label>
            }
            <div className={"col col-12 " + (props.labelUp ? "" : (props.block && props.label ? "col-md-9" : props.block ? "col-md-12" : "col-md-6"))}>
                <div className={(props.password || props.icon || props.iconText) ? inputGroupClassName() : ""} style={{
                    position: "relative"
                }}>
                    {
                        viewMode ?
                            <>
                                <FlexRow className={className() + " form-control-solid"}>
                                    <div className="me-2">
                                        {
                                            props.password ? "*******" : text
                                        }
                                    </div>
                                    <a href="#" onClick={e => {
                                        e.preventDefault();
                                        viewModeSet(false);
                                        if (props.onEditMode) props.onEditMode();
                                    }}>
                                        <Icon icon="edit" />
                                    </a>
                                </FlexRow>
                            </>
                            :
                            <>
                                <input id={inputId}
                                    ref={e => element = e}
                                    name={props.name}
                                    type={type()}
                                    autoComplete={props.password ? "new-password" : "one-time-code"}
                                    className={className()}
                                    onKeyPress={keypress}
                                    autoFocus={props.autoFocus}
                                    maxLength={props.maxLength}
                                    value={text}
                                    placeholder={props.timeInput ? "08:30" : props.placeholder}
                                    readOnly={props.readOnly}
                                    disabled={props.disabled}
                                    onChange={change}
                                    onFocus={focus}
                                    onBlur={blur}
                                    onMouseOver={mouseOver}
                                    onMouseOut={mouseOut}
                                    tabIndex={props.tabIndex}
                                />
                                {
                                    props.password && props.generatePassword &&
                                    <span className="fs-8 text-primary" style={{
                                        position: "absolute",
                                        right: 50,
                                        top: 14,
                                        cursor: "pointer",
                                        zIndex: 10
                                    }} onClick={e => {
                                        var p = window.getRandomString(8);
                                        textSet(p);
                                        copyToClipboard(p, "New password generated and has been copied to the clipboard!");
                                    }}>
                                        Generate
                                    </span>
                                }

                                {
                                    props.password &&
                                    <span className="input-group-text cursor-pointer"
                                        onMouseDown={e => $("#" + inputId).attr("type", "text")}
                                        onMouseUp={e => $("#" + inputId).attr("type", "password")}
                                    >
                                        <Icon icon="eye" />
                                    </span>
                                }
                                {
                                    props.icon &&
                                    <span className="input-group-text">
                                        <Icon icon={props.icon} />
                                    </span>
                                }

                                {
                                    props.iconText &&
                                    <span className="input-group-text fs-8">
                                        {props.iconText}
                                    </span>
                                }

                                {
                                    props.working &&
                                    <span style={{
                                        position: "absolute",
                                        right: 7,
                                        top: 7,
                                        zIndex: 10
                                    }}>
                                        <Icon icon="spinner-third" spin />
                                    </span>
                                }
                            </>
                    }

                </div>
            </div>
            {
                props.block && props.label && !props.labelUp &&
                <div className="col col-12 col-md-3"></div>
            }
            {
                !props.labelUp && !props.noTextError &&
                <div className={"col col-12 " + (props.block ? "col-md-9" : "col-md-4")}>
                    <span id={props.name + "-err"} className="small text-danger mx-3 form-error"></span>
                </div>
            }



        </div>
    )

}