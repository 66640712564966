import { useEffect, useState } from "react";
import Div from "../../../../components/base/Div";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Indicator from "../../../../components/base/Indicator";
import { getUser, getUserId, useHub } from "../../../../core/Global";
import WalletTransaction from "../../../../models/WalletTransaction";
import Modal from "../../../../components/base/Modal";
import PaymentMethod from "../../../system/settings/models/PaymentMethod";
import PaymentCardView from "./PaymentCardView";


function ProfileWallet() {

    const hub = useHub();
    const user = getUser();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [transactions, transactionsSet] = useState<WalletTransaction[]>([]);
    const [paymentMethod, paymentMethodSet] = useState<PaymentMethod>();

    const fetch = () => {
        hub.Get("/accounts/profile/wallet/transactions", res => {
            transactionsSet(res.data);
            fetchingSet(false);
            fetchedSet(true);
        })
    }

    const fetchPaymentMethods = () => {
        hub.Get("/accounts/profile/paymentmethod/default", res => {
            if (res.success) paymentMethodSet(res.data);
        })
    }

    useEffect(() => {
        fetch();
        fetchPaymentMethods();
    }, [])

    return (
        <div>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Wallet</h3>
                    </div>
                    <div className="card-action">
                        <span className="me-2 fw-bolder text-gray-700">
                            Ballance:
                        </span>
                        <span className="fs-5 text-gray-700">
                            {moneyFormatter.format(user?.wallet?.balance ?? 0)}
                        </span>
                        {/* <Icon icon="dollar-sign" type="solid" className="ms-1 text-gray-700" size={16} /> */}
                        <button type="button" className="btn btn-sm btn-primary btn-icon ms-3" style={{
                            padding: 1,
                            width: 25,
                            height: 25
                        }} onClick={e => {
                            e.preventDefault();
                            window.openModal("modal-add-amount");
                        }}>
                            <Icon icon="plus" />
                        </button>
                    </div>
                </div>

                <div className="card-body border-top p-9">
                    <Div visible={fetching} className="my-20">
                        <Indicator />
                    </Div>
                    <Div visible={fetched}>
                        <div>
                            {
                                transactions.map((item, key) =>
                                    <>
                                        <div key={key} className="px-5 py-2">
                                            <FlexRow>
                                                <div>
                                                    <div className={(item.deposit ? "text-success" : "text-danger") + " fs-5 fw-bolder"}>
                                                        {item.deposit ? "+" : "-"} {moneyFormatter.format(item.amount)}
                                                    </div>
                                                    <div className="text-muted fs-8 mt-2">
                                                        <Icon icon="tag" className="me-2" type="solid" size={12} />
                                                        {item.type}
                                                    </div>
                                                    {
                                                        item.description &&
                                                        <div className="text-muted fs-8 mt-2">
                                                            <Icon icon="info-circle" className="me-2" type="solid" size={12} />
                                                            {item.description}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="ms-auto text-gray-700 fs-7 fw-bold">
                                                    <Icon icon="clock" className="me-2" size={12} />
                                                    {item.shortDate}
                                                </div>
                                            </FlexRow>
                                        </div>
                                        <hr />
                                    </>
                                )
                            }
                        </div>
                    </Div>
                </div>

            </div>

            <Modal id="modal-add-amount"
                title="Add Amount"
                buttonText="Add Amount"
                onSubmit={(body, callback) => {

                    hub.Post("/billings/payments/wallet/pay/" + getUserId(), body, res => {
                        if (res.success) {
                            document.location.href = res.data;
                        }
                        else {
                            if (callback) callback(res);
                        }
                    });
                }}>
                <div>
                    <div className="fs-7 mb-2">
                        Amount
                    </div>
                    <select name="amount" className="form-select form-select-sm">
                        <option value={50}>50$</option>
                        <option value={100}>100$</option>
                        <option value={150}>150$</option>
                        <option value={200}>200$</option>
                        <option value={250}>250$</option>
                        <option value={300}>300$</option>
                    </select>

                    <div className="fs-7 mt-6 mb-2">
                        Payment Method
                    </div>

                    {
                        paymentMethod && <PaymentCardView item={paymentMethod} />
                    }

                </div>
            </Modal>
        </div>
    )
}

export default ProfileWallet;