import { useEffect, useState } from "react"
import { useHub } from "../../../../core/Global"
import JobLogHistory from "../models/JobLogHistory"
import { API_ROUTES } from "../../../../core/Constants"
import JobLogItem from "./JobLogItem"


type Iprops = {
    id: string
    jobId?: string
    height?: number
    refresh?: boolean
    className?: string,
    jobTimezoneOffset?: number
}

export default function JobLogsContainer(props: Iprops) {

    const hub = useHub();

    const [logs, logsSet] = useState<JobLogHistory[]>();
    const fetchLogs = () => {
        hub.Get(API_ROUTES.Jobs + "logs/" + props.jobId, res => {
            if(res.success) {
                logsSet(res.data)
            }
        })
    }

    useEffect(() => {
        if (props.jobId) fetchLogs();
    }, [props.jobId])

    useEffect(() => {
        if (props.refresh) fetchLogs();
    }, [props.refresh])

    return (
        <div className={"scroll-y " + props.className} style={{
            height: props.height,
            maxHeight: props.height,
            overflowX: "hidden"
        }}>
            <div className="timeline">
                {
                    logs?.map((log, k) => <JobLogItem key={k} log={log} jobTimezoneOffset={props.jobTimezoneOffset} />)
                }
            </div>
        </div>
    )
}