import { useEffect, useState } from "react"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"
import JobPaymentDetails from "../models/JobPaymentDetails"
import Indicator from "../../../../components/base/Indicator"
import { hasRole, useHub } from "../../../../core/Global"
import { API_ROUTES } from "../../../../core/Constants"
import { PaymentTypes } from "../../../../core/Enums"
import JobClosePaymentModal from "../components/jobmodals/JobClosePaymentModal"
import Job from "../models/Job"



interface IProps {
    job: Job
}
export default function JobPayments(props: IProps) {

    const hub = useHub();
    const [loading, loadingSet] = useState<boolean>(true);
    const [model, modelSet] = useState<JobPaymentDetails>();

    const [editPayment, editPaymentSet] = useState<boolean>(false);

    const fetch = () => {

        hub.Get(API_ROUTES.Jobs + "paymentdetails/" + props.job.id, res => {
            if (res.success) {
                modelSet(res.data);
                loadingSet(false);
            }
        })


    }

    useEffect(() => {
        if (model && !loading) {
            var pieNumbers = [model.companyProfit ?? 0, model.techProfit ?? 0, model.sourceProfit ?? 0, model.totalParts ?? 0];
            var pieColors = ["#50cd89", "#ffc700", "#009ef7", "#7239ea"];
            window.createCircleChart("jobpaymentchart-circle", pieNumbers, pieColors);
        }
    }, [model, loading])

    useEffect(() => {
        if (props.job.id) {
            fetch();
        }
    }, [props.job.id])




    return (
        <div>
            {
                loading &&
                <div className="my-20">
                    <Indicator text="Please wait..." />
                </div>
            }

            {
                !model?.payment && !loading &&
                <div className="card">
                    <div className="card-body my-10">
                        <div className="mb-5 text-center">
                            <Icon icon="credit-card" size={34} />
                        </div>
                        <div className="text-center">
                            There is no payment for this job!
                        </div>
                    </div>

                </div>
            }

            {
                model?.payment && !loading &&
                <>
                    {
                        hasRole("ViewJobRates") &&
                        <div className="card">
                            <div className="card-body">
                                <FlexRow>
                                    <div style={{
                                        position: "relative",
                                        width: 160,
                                        height: 160
                                    }}>
                                        <div id="jobpaymentchart-circle" data-kt-size="160" data-kt-line="20"></div>

                                        <div style={{
                                            position: "absolute",
                                            top: 60,
                                            right: 0,
                                            width: 160,
                                            height: 160,
                                            textAlign: "center"
                                        }}>
                                            <div className="fs-1 fw-bolder text-center">
                                                {model.payment?.totalPaymentWithTax}$
                                            </div>
                                            <div className="fs-7 text-center">
                                                Total
                                            </div>
                                        </div>
                                    </div>
                                    <FlexRow wrap className="ms-10">
                                        <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                            <FlexRow>
                                                <div>
                                                    <Icon icon="circle" type="solid" className="text-success" />
                                                </div>
                                                <div className="ms-2">
                                                    Company Profit
                                                </div>
                                            </FlexRow>
                                            <div className="fw-bolder fs-2 mt-2">
                                                {model.companyProfit}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Rate: {model.companyRate}%
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Tech Fee: {model.techFee}$
                                            </div>
                                        </div>
                                        <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                            <FlexRow>
                                                <div>
                                                    <Icon icon="circle" type="solid" className="text-warning" />
                                                </div>
                                                <div className="ms-2">
                                                    Technician Profit
                                                </div>
                                            </FlexRow>
                                            <div className="fw-bolder fs-2 mt-2">
                                                {model.techProfit}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Rate: {model.techRate}%
                                            </div>
                                            <div className="text-muted mt-2 fs-8 w-100 limited-text">
                                                <Icon icon="user-helmet-safety" className="me-2" size={12} />
                                                {props.job.tech?.fullName}
                                            </div>
                                        </div>
                                        <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                            <FlexRow>
                                                <div>
                                                    <Icon icon="circle" type="solid" className="text-primary" />
                                                </div>
                                                <div className="ms-2">
                                                    Source Profit
                                                </div>
                                            </FlexRow>
                                            <div className="fw-bolder fs-2 mt-2">
                                                {model.sourceProfit}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Rate: {model.sourceRate}%
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                <Icon icon="bullhorn" className="me-2" size={12} />
                                                {props.job.jobSource}
                                            </div>
                                        </div>
                                        {/* <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                        <FlexRow>
                                            <div>
                                                <Icon icon="circle" type="solid" className="text-danger" />
                                            </div>
                                            <div className="ms-2">
                                                Technician Fee
                                            </div>
                                        </FlexRow>
                                        <div className="fw-bolder fs-2 mt-2">
                                            {model.techFee}$
                                        </div>
                                        <div className="text-muted mt-2 fs-8">
                                            &nbsp;
                                        </div>
                                        <div className="text-muted mt-2 fs-8">
                                            &nbsp;
                                        </div>
                                    </div> */}
                                        <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                            <FlexRow>
                                                <div>
                                                    <Icon icon="circle" type="solid" className="text-info" />
                                                </div>
                                                <div className="ms-2">
                                                    Costs
                                                </div>
                                            </FlexRow>
                                            <div className="fw-bolder fs-2 mt-2">
                                                {model.totalParts}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Tech Parts: {model.techParts}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                Company Parts: {model.companyParts}$
                                            </div>
                                        </div>

                                        {
                                            model.payment.taxItems && model.payment.taxItems.length > 0 &&
                                            <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                                <FlexRow>
                                                    <div>
                                                        <Icon icon="circle" type="solid" className="text-secondary" />
                                                    </div>
                                                    <div className="ms-2">
                                                        Tax
                                                    </div>
                                                </FlexRow>
                                                <div className="fw-bolder fs-2 mt-2">
                                                    {model.totalTaxRate}$
                                                </div>

                                                {
                                                    model.payment.taxItems.map((tax, key) =>
                                                        <div key={key} className="text-muted mt-2 fs-8">
                                                            {tax.name}: {tax.amountText}
                                                        </div>
                                                    )
                                                }

                                                {
                                                    model.payment.taxItems.length < 2 &&
                                                    <div className="text-muted mt-2 fs-8">
                                                        &nbsp;
                                                    </div>
                                                }




                                            </div>
                                        }

                                        <div className="border border-gray-300 border-dashed rounded w-175px py-4 px-6 me-3 mb-2">
                                            <FlexRow>
                                                <div>
                                                    <Icon icon="circle" type="solid" className={(model.techBalance ?? 0) < 0 ? "text-danger" : "text-success"} />
                                                </div>
                                                <div className="ms-2">
                                                    Tech Balance
                                                </div>
                                            </FlexRow>
                                            <div className={`fw-bolder fs-2 mt-2 ${(model.techBalance ?? 0) < 0 ? "text-danger" : "text-success"}`}>
                                                {
                                                    model.techBalance && model.techBalance > 0 &&
                                                    <span>+</span>
                                                }
                                                {model.techBalance}$
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                &nbsp;
                                            </div>
                                            <div className="text-muted mt-2 fs-8">
                                                &nbsp;
                                            </div>
                                        </div>



                                    </FlexRow>

                                    {/*  */}
                                </FlexRow>
                            </div>
                        </div>
                    }

                    <div className="card mt-4">
                        <div className="card-header">
                            <div className="card-title m-0">
                                <h3 className="fs-4 fw-bolder m-0">
                                    Payments
                                </h3>
                            </div>
                            <div className="card-action">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <button type="button" className="btn btn-sm btn-primary" onClick={e => editPaymentSet(true)}>
                                        <Icon icon="edit" />
                                        Edit Payment
                                    </button>
                                }

                            </div>
                        </div>
                        <div className="card-body">
                            <div className="border-bottom mb-9"
                                style={{
                                    maxWidth: 1000
                                }}>
                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                    <thead>
                                        <tr className="border-bottom fs-6 fw-bolder text-muted">
                                            <th className="min-w-175px pb-2">Payment Type</th>
                                            <th className="min-w-70px pb-2">Amount</th>
                                            <th className="min-w-100px pb-2">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            model.payment?.paymentItems?.map((p, k) =>
                                                <tr key={k}>
                                                    <td className="fw-bolder">
                                                        {
                                                            p.paymentType == PaymentTypes.Cash ?
                                                                <FlexRow>
                                                                    <div className="me-2">
                                                                        <Icon icon="money-bill" />
                                                                    </div>
                                                                    <div>
                                                                        Cash
                                                                    </div>
                                                                </FlexRow>
                                                                : p.paymentType == PaymentTypes.Check ?
                                                                    <FlexRow>
                                                                        <div className="me-2">
                                                                            <Icon icon="money-check-dollar" />
                                                                        </div>
                                                                        <div className="me-2">
                                                                            <span>Check</span>
                                                                        </div>
                                                                    </FlexRow>
                                                                    : p.paymentType == PaymentTypes.CCManual ?
                                                                        <FlexRow>
                                                                            <div className="me-2">
                                                                                <Icon icon="credit-card" />
                                                                            </div>
                                                                            <div className="me-2">
                                                                                CC Manual
                                                                            </div>
                                                                        </FlexRow>
                                                                        : p.paymentType == PaymentTypes.CCCharge ?
                                                                            <FlexRow>
                                                                                <div className="me-2">
                                                                                    <Icon icon="credit-card" />
                                                                                </div>
                                                                                <div className="me-2">
                                                                                    CC Charge
                                                                                </div>
                                                                            </FlexRow>
                                                                            : <></>
                                                        }
                                                    </td>
                                                    <td>
                                                        {p.amount}$
                                                    </td>
                                                    <td>
                                                        {
                                                            p.checkNumber &&
                                                            <div className="text-muted">
                                                                Check No# {p.checkNumber}
                                                            </div>
                                                        }
                                                        {
                                                            p.approval &&
                                                            <div className="text-muted">
                                                                Approval# {p.approval}
                                                            </div>
                                                        }
                                                        {
                                                            p.cancelFee &&
                                                            <div className="text-muted">
                                                                <Icon icon="info-circle" className="me-2" type="solid" />
                                                                Fee Canceled
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }


            {
                editPayment &&
                <JobClosePaymentModal job={props.job} editMode
                    onClose={() => editPaymentSet(false)}
                    onUpdate={fetch} />
            }

        </div>
    )
}