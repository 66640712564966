import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hub from "../../../api/Hub";
import ActiveAgentsPieChart from "../../../charts/components/ActiveAgentsPieChart";
import CallTypesPieChart from "../../../charts/components/CallTypesPieChart";
import ResponseRatePieChart from "../../../charts/components/ResponseRatePieChart";
import AgentsLiveBoard from "../../../components/agent/AgentsLiveBoard";
import Div from "../../../components/base/Div";
import Icon from "../../../components/base/Icon";
import Section from "../../../components/base/Section";
import LiveCallsBoard from "../../../components/calls/LiveCallsBoard";
import Layout from "../../../components/layouts/Layout";
import { getUser, setPage, showLineWizard, useHub } from "../../../core/Global";
import DashboardModel from "../models/DashboardModel";



function DashboardView() {
    
    const hub = useHub();
    const user = getUser();
    const [model, modelSet] = useState<DashboardModel>();
    const [hasLiveCalls, hasLiveCallsSet] = useState<boolean>();
    const [hasAgents, hasAgentsSet] = useState<boolean>();

    const fetch = () => {
        hub.Get("/dashboard/main", res => {
            modelSet(res.data);
        });
    }
    useEffect(()=> {
        
        setPage("Dashboard");

        fetch();

        return () => {
            
        };


    }, [])

    return (
        <Layout wide>
            
            <Section visible={(model?.showBuyLineTip ?? false)} className="pb-10">
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 align-items-center">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <Icon icon="phone-office" size={46} type="thin" color="#ffc700" />
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bolder">Get your first phone number!</h4>
                            <div className="fs-6 text-gray-700">To start working with voice system, you must get a phone number first!</div>
                        </div>
                    </div>
                    <button className="btn btn-primary"
                        onClick={e=> {
                            e.preventDefault();
                            showLineWizard();
                        }}>
                        Buy a Number
                    </button>
                </div>
            </Section>
            <Section visible={!(model?.showBuyLineTip ?? false) && (user?.needsSubscribe ?? false)} className="pb-10">
                <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6">
                    <span className="svg-icon me-4">
                        <Icon icon="cloud-slash" size={46} type="thin" className="text-danger" />
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bolder">Your subscription has been expired!</h4>
                            <div className="fs-6 text-gray-700">To continue working with system, please renew your subscription!</div>
                        </div>
                    </div>
                    <Link to="/profile/billing/subscription" className="btn btn-primary">Subscribe Now</Link>
                </div>
            </Section>
            <Section fluid className="pb-10">
                <div className="row mt-3">
                    <div className="col-12">
                        <h3 className="text-dark mx-4 my-2">
                            Agents
                        </h3>
                    </div>
                    <div className="col-12">
                        <AgentsLiveBoard />
                    </div>
                </div>
            </Section>
            <Section fluid backgroundColor="secondary" className="py-10">
                <div className="row mt-3">
                    <div className="col-12">
                        <h3 className="text-dark mx-4 my-2">
                            Live Calls
                        </h3>
                    </div>
                    <div className="col-12">
                        <LiveCallsBoard  />
                    </div>
                </div>
            </Section>
            <Section fluid className="py-10">
            <div className="row mt-3">
                    <div className="col-12">
                        <h3 className="text-dark mx-4 my-2">
                            Statistics
                        </h3>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-lg-4">
                        <CallTypesPieChart model={model?.callTypesPieChart} />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <ResponseRatePieChart model={model?.responseRatePieChart} />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <ActiveAgentsPieChart model={model?.activeAgentsPieChart} />
                    </div>
                </div>
            </Section>
            
        </Layout>
    )
    
}

export default DashboardView;