import React from 'react';
import {Routes, Route} from "react-router-dom";
import LoginPage from '../views/LoginPage';
import ForgetPassword from '../views/ForgetPassword';
import ResetPassword from '../views/ResetPassword';


function AuthRoutes() {
  return (
    <Routes>
        <Route path="/auth/login" element={<LoginPage />}/>
        <Route path="/auth/password/forget" element={<ForgetPassword />}/>
        <Route path="/auth/password/reset/:token" element={<ResetPassword />}/>
        <Route path="/auth/auto/:id/:token" element={<LoginPage auto />}/>
    </Routes>
  );
}

export default AuthRoutes;
