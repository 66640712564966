import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import { getUserId, useHub } from "../../../../core/Global";
import PaymentCardView from "../../../profile/components/billing/PaymentCardView";
import UserSubscription from "../models/UserSubscription";
import PaymentMethod from "../models/PaymentMethod";
import Div from "../../../../components/base/Div";
import Indicator from "../../../../components/base/Indicator";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";



export default function SubscriptionManage() {

    const hub = useHub()
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<UserSubscription>();
    const [extraSeats, extraSeatsSet] = useState<number>(0);
    const [seatsVerifyResult, seatsVerifyResultSet] = useState<any>();

    const [paymentMethod, paymentMethodSet] = useState<PaymentMethod>();

    const fetch = () => {
        fetchingSet(true);

        hub.Get("/accounts/profile/subscription", res => {
            modelSet(res.data);
            fetchingSet(false);
            fetchedSet(true);
            extraSeatsSet(model?.extra5SeatPackages ?? 0);
        });

        hub.Get("/accounts/profile/paymentmethod/default", res => {
            paymentMethodSet(res.data);
        })
    }

    const verify = (seats: any) => {

        hub.Post("/accounts/profile/subscription/manage/verify", {
            extraSeats: seats
        }, res => {
            if (res.success) seatsVerifyResultSet(res.data);
        });
    }

    useEffect(() => {
        fetch();
    }, [])



    return (
        <>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Subscription</h3>
                    </div>
                </div>
                <div className="card-body border-top p-9">
                    <Div visible={fetching} className="my-20">
                        <Indicator />
                    </Div>
                    <Div visible={fetched}>
                        <div>
                            <FlexRow className="mb-3">
                                <h3 className="m-0 me-3">
                                    Monthly Subscription
                                </h3>
                                {
                                    model?.isActive ?
                                        <span className="badge badge-success me-2">
                                            <Icon icon="circle" type="solid" className="me-2" size={9} />
                                            Active
                                        </span>
                                        :
                                        <span className="badge badge-danger me-2">
                                            <Icon icon="circle" type="solid" className="me-2" size={9} />
                                            Expired
                                        </span>
                                }
                            </FlexRow>
                            <div className="mb-1 fw-bolder">
                                {model?.amountText}
                            </div>
                            <div className={"mb-1" + (model?.isActive ? "" : " text-danger")}>
                                {model?.dateText}
                            </div>
                            {
                                model?.isCanceled && model.isActive &&
                                <div className="mb-1 text-danger">
                                    {model?.cancelationText}
                                </div>
                            }

                            <div className="mb-1">
                                <Icon icon="user-headset" className="me-2" />
                                5 Free Users Included
                            </div>
                            <div className="mb-1">
                                <Icon icon="plus" className="me-2" />
                                {model?.addonsText}
                            </div>

                            {
                                model?.addonsCancelText &&
                                <FlexRow className="mb-1">
                                    <div className="text-danger">
                                        {model?.addonsCancelText}
                                    </div>
                                    <span className="badge badge-light-primary cursor-pointer ms-2"
                                        onClick={e => {
                                            window.openModal("modal-undo-seats-cancelation");
                                        }}>
                                        Undo Cancelation
                                    </span>
                                </FlexRow>
                            }

                        </div>
                        {
                            (model?.isCanceled && model.isActive) ?
                                <div className="text-end">
                                    <button type="button" className="btn btn-sm btn-outline btn-outline-primary btn-active-light-primary"
                                        onClick={e => {
                                            e.preventDefault();
                                            window.openModal("modal-undo-cancelation");
                                        }}>
                                        Undo Cancelation
                                    </button>
                                </div>
                                : model?.isActive ?
                                    <div className="text-end">
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-primary btn-active-light-primary me-2"
                                            onClick={e => {
                                                e.preventDefault();
                                                extraSeatsSet(model?.extra5SeatPackages ?? 0);
                                                seatsVerifyResultSet(undefined);
                                                window.openModal("modal-manage-subscription");
                                            }}>
                                            Manage Plan
                                        </button>
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger btn-active-light-danger"
                                            onClick={e => {
                                                e.preventDefault();
                                                window.openModal("modal-cancel-subscription");
                                            }}>
                                            Cancel Subscription
                                        </button>
                                    </div>
                                    :
                                    <div className="text-end">
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-primary btn-active-light-primary me-2"
                                            onClick={e => {
                                                e.preventDefault();
                                                window.openModal("modal-sub-now");
                                            }}>
                                            Subscribe Now
                                        </button>
                                    </div>
                        }

                    </Div>


                </div>
            </div>

            <Modal id="modal-manage-subscription"
                title="Manage Subscription"
                buttonColor="primary"
                buttonText="Update Subscription"
                confirmDisabled={!seatsVerifyResult?.verified}
                onConfirm={callback => {
                    var adding = extraSeats > (model?.extra5SeatPackages ?? 0);
                    hub.Post("/accounts/profile/subscription/manage", {
                        extraSeats: extraSeats
                    }, res => {
                        if (res.success) {
                            if (adding) {
                                document.location.href = res.data;
                            }
                            else {
                                modelSet(res.data);
                                callback();
                            }
                        }
                        else {
                            callback(true);
                        }
                    });
                }}>

                <div>
                    <div className="mb-2 mt-5 fw-bold">Extra Seats: </div>
                    <div className="mb-1">
                        <select className="form-select form-select-sm mw-100 w-300px" value={extraSeats} defaultValue={extraSeats}
                            onChange={e => {
                                var st = Number.parseInt(e.target.value);
                                extraSeatsSet(st);
                                verify(st);
                            }}>
                            {
                                [...Array(11)].map((item, key) =>
                                    <option key={key} value={key}>
                                        {key == 0 ? "No Extra Seat" : key + " Extra Package (" + (key * 5) + " Users)"}
                                    </option>
                                )
                            }
                            <option value={11}>+10 Extra Packages</option>
                        </select>
                    </div>
                    {
                        seatsVerifyResult &&
                        <>
                            <div className={"mt-4 ms-1 fw-bold fs-6 " + seatsVerifyResult?.color}>
                                {seatsVerifyResult.text}
                            </div>
                            {
                                seatsVerifyResult.adding &&
                                <div className={"mt-2 ms-1 fw-bold text-danger"}>
                                    You can't cancel it until {seatsVerifyResult.endsAt}!
                                </div>
                            }
                            {
                                seatsVerifyResult.text2 && seatsVerifyResult.text2 != "" &&
                                <div className={"mt-2 ms-1"}>
                                    {seatsVerifyResult.text2}
                                </div>
                            }
                            {
                                seatsVerifyResult.subtext && seatsVerifyResult.subtext != "" &&
                                <div className={"mt-2 ms-1"}>
                                    {seatsVerifyResult.subtext}
                                </div>
                            }



                        </>
                    }
                </div>

            </Modal>

            <Modal id="modal-cancel-subscription"
                title="Cancel Subscription"
                buttonColor="danger"
                buttonText="Confirm & Cancel Subscription"
                onConfirm={callback => {

                    hub.Post("/accounts/profile/subscription/cancel", {
                        id: model?.id
                    }, res => {
                        if (res.success) modelSet(res.data);
                        callback();
                    });

                }}>
                <p className="text-danger">
                    Are you sure you want to cancel your current subscription?
                </p>
                <p>
                    Your service will still be {model?.dateText}
                </p>
            </Modal>

            <Modal id="modal-undo-cancelation"
                title="Undo Cancelation"
                buttonColor="primary"
                buttonText="Undo Cancelation"
                onConfirm={callback => {

                    hub.Post("/accounts/profile/subscription/undocancel", {
                        id: model?.id
                    }, res => {
                        if (res.success) modelSet(res.data);
                        callback();
                    });

                }}>
                <p>
                    Your service is still {model?.dateText}
                </p>
                <p>
                    You can undo the canelation and go back to normal status till this date!
                </p>
            </Modal>

            <Modal id="modal-undo-seats-cancelation"
                title="Undo Cancelation"
                buttonColor="primary"
                buttonText="Undo Cancelation"
                onConfirm={callback => {

                    hub.Post("/accounts/profile/subscription/undoseatscancel", {
                        id: model?.id
                    }, res => {
                        if (res.success) modelSet(res.data);
                        callback();
                    });

                }}>
                <p>
                    Are you sure you want to undo the seats packages cancelation?
                </p>
            </Modal>

            <Modal id="modal-sub-now"
                title="Subscribe"
                buttonText="Subscribe Now"
                onSubmit={(body, callback) => {
                    hub.Post("/billings/payments/subscribe/pay/" + getUserId(), body, res => {
                        if (res.success) {
                            document.location.href = res.data;
                        }
                        else {
                            if (callback) callback(res);
                        }
                    });
                }}>
                <div>

                    <div className="fs-7 mb-5">
                        You are going to pay your subscription by this payment method
                    </div>

                    {paymentMethod && <PaymentCardView item={paymentMethod} />}

                </div>
            </Modal>

        </>
    )
}