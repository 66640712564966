import CallsList from "../../../../components/calls/CallsList";

type Props = {
  number?: string
}

function CallHistory(props: Props) {


  return (
    <>
      <div className="row">
          <div className="col-12">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body border-top p-9">
                  <CallsList keyword={props.number} noHeader />
              </div>
            </div>
          </div>
      </div>


    </>
  );
}

export default CallHistory;
