import PaymentMethods from "./PaymentMethods";
import ProfileBudgetSettings from "./ProfileBudgetSettings";
import ProfileSubscription from "./ProfileSubscription";



export default function ProfileSubsPayments() {


    return (
        <>
            <ProfileSubscription />
            <PaymentMethods />
            <ProfileBudgetSettings />
        </>
    )
}