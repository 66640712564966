import { useEffect, useState } from "react";
import Icon from "../../../components/base/Icon";
import DropdownMenu, { MenuItem } from "../../../components/menu/DropdownMenu";
import { API_ROUTES } from "../../../core/Constants";
import { hasRole, openDeleteChat, openSettingsChat, useHub } from "../../../core/Global";
import ChatContactItem from "../models/ChatContactItem"


interface IProps {
    chatId?: string
    chat?: ChatContactItem
    onPin?: () => void
    onUnpin?: () => void
    onUnarchive?: () => void
}

export default function ChatContactContextMenu(props: IProps) {

    const hub = useHub();

    const [menu, menuSet] = useState<MenuItem[]>([])

    useEffect(() => {
        var m: MenuItem[] = [];

        if ((props.chat?.unreadCount ?? 0) > 0) {
            m.push({
                title: "Claim All",
                icon: "check",
                action() {
                    hub.Get(API_ROUTES.Chat + "claimall/" + props.chatId, res => {
                        if (res.success) {
                        }
                    })
                },
            })
        }

        if (props.chat?.pinned) {
            m.push({
                title: "Unpin Chat",
                icon: "thumbtack",
                action() {
                    hub.Get(API_ROUTES.Chat + "unpin/" + props.chatId, res => {
                        if (res.success) {
                            if (props.onUnpin) props.onUnpin()
                        }
                    })
                },
            })
        }
        else if (!props.chat?.archived) {
            m.push({
                title: "Pin Chat",
                icon: "thumbtack",
                action() {
                    hub.Get(API_ROUTES.Chat + "pin/" + props.chatId, res => {
                        if (res.success) {
                            if (props.onPin) props.onPin()
                        }
                        else if (res.message == "pinnedexceed") {
                            window.openModal("modal-pinned-chats-exceeed");
                        }
                    })
                },
            })
        }

        if (props.chat?.tech) {
            m.push({
                title: "Chat Settings",
                icon: "cog",
                action() {
                    if (props.chat) openSettingsChat(props.chat)
                },
            })
        }

        if (hasRole("ArchiveChat") && !props.chat?.archived) {
            m.push({
                title: "Archive Chat",
                icon: "archive",
                action() {
                    hub.Put(API_ROUTES.Chat + "archive/" + props.chat?.id, {}, res => { })
                },
            })
        }

        if (hasRole("ArchiveChat") && props.chat?.archived) {
            m.push({
                title: "Unarchive Chat",
                icon: "arrow-up-from-bracket",
                action() {
                    hub.Put(API_ROUTES.Chat + "unarchive/" + props.chat?.id, {}, res => {
                        if (props.onUnarchive) props.onUnarchive();
                    })
                },
            })
        }

        if (hasRole("DeleteChat")) {
            m.push({
                title: "Delete Chat",
                icon: "circle-minus",
                danger: true,
                action() {
                    if (props.chat) openDeleteChat(props.chat)
                },
            })
        }

        menuSet(f => [...m])


    }, [props.chat])

    return (
        <DropdownMenu width={180} source={menu} />
    )
}