import { useEffect, useState } from "react";
import Section from "../../../../components/base/Section";
import { DataTable, DataTableField } from "../../../../components/data/DataTable";
import Layout from "../../../../components/layouts/Layout";
import { API_ROUTES } from "../../../../core/Constants";
import { hasRole, setPage, useHub } from "../../../../core/Global";
import NotifRuleFormModal from "../components/NotifRuleFormModal";
import { NotificationRule } from "../models/NotificationRuleModels";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import DataItem from "../../../../models/DataItem";
import { NotificationRuleTypes } from "../../../../core/Enums";
import { getRuleEvent, getRuleType, getRuleUserType } from "../models/NotificationRuleConstants";
import NotifRuleStatementItem from "../components/NotifRuleStatementItem";
import Modal from "../../../../components/base/Modal";


export default function NotificationRulesPage() {

    const hub = useHub();
    
    const [refresh, refreshSet] = useState<boolean>(false);
    useEffect(() => {
      if(refresh) {
        setTimeout(() => {
          refreshSet(false)
        }, 500);
      }
    }, [refresh])

    const [createModal, createModalSet] = useState<boolean>(false);
    const [editModal, editModalSet] = useState<boolean>(false);
    const [deleteModal, deleteModalSet] = useState<boolean>(false);

    const [selectedItem, selectedItemSet] = useState<NotificationRule>();

    useEffect(()=> {
        setPage("Notification Rules", "Notifications", "System");
      }, [])


    


      const CreatedItem = (data: NotificationRule) => (
        <div>
          <div className='d-flex align-items-center'>
            {data.createDate}
          </div>
          {
            data.creatorUser &&
            <FlexRow className='fs-8 mt-1'>
              <div>
                <Icon icon="user" className="me-2" size={10} type="solid" />
              </div>
              <div className="fs-8">
                By: {data.creatorUser.fullName}
              </div>
            </FlexRow>
          }
          
        </div>
      )


    return (
        <Layout wide>
            <Section fluid>

                <DataTable objectName="Rule"
                            addCustom={() => {
                                createModalSet(true);
                            }}
                            edit delete
                            deleteCustom={e=> {
                              deleteModalSet(true);
                              selectedItemSet(e);
                            }}
                            editCustom={e=> {
                              editModalSet(true);
                              selectedItemSet(e);
                            }}
                            //filteringProps={FilterProps}
                            apiRoute={API_ROUTES.NotificationRules}
                            refresh={refresh}
                            //preventCreate={!hasRole("CreateNotifRule")}
                            >

                    <DataTableField title="Rule" >
                        <NotifRuleStatementItem />
                    </DataTableField>
                    
                    <DataTableField title="Created">
                        <CreatedItem />
                    </DataTableField>
                    
                </DataTable>

            </Section>


            {
                createModal &&
                <NotifRuleFormModal 
                    onClose={() => createModalSet(false)}
                    onSave={() => refreshSet(true)} />
            }

            {
              editModal && selectedItem &&
              <NotifRuleFormModal model={selectedItem}
                  onClose={() => {
                    editModalSet(false)
                    selectedItemSet(undefined)
                  }}
                  onSave={() => refreshSet(true)} />
            }

            {
              deleteModal && selectedItem &&
              <Modal id="modal-delete-notifrule"
                open visible
                title="Delete Rule"
                buttonText="Delete Rule"
                buttonColor="danger"
                onClose={() => {
                  deleteModalSet(false)
                  selectedItemSet(undefined)
                }}
                onConfirm={callback => {

                  hub.Delete(API_ROUTES.NotificationRules + selectedItem.id, res => {
                    refreshSet(true)
                    callback()
                  })
                  
                }}>
                  <div>
                     <p className="mb-5">Are you sure you want to delete the selected rule? </p>
                     <div className="bg-light p-5">
                      <NotifRuleStatementItem {...selectedItem} />
                     </div>
                  </div>
              </Modal>
            }

        </Layout>
    )
}