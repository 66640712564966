import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../base/Button';
import Form from './Form';
import { Validator } from '../../core/Types';
import HUBResponse from '../../models/HUBResponse';
import Hub from '../../api/Hub';
import { toastSuccess, toastDefault } from '../base/Toast';
import { useEffect } from 'react';
import Indicator from "../base/Indicator";
import Modal from '../base/Modal';
import { clearToolbar, getUser, setCreateButton, setToolbarActions } from "../../core/Global";

type FormPageProps = {
    apiRoute: string
    objectName?: string
    redirectTo: string
    formValidator?: Validator[]
    selectedId?: string
    visible?: boolean
    children:any
    title?: string
    submitText?: string
    noCard?: boolean
    noHeader?: boolean
    noFooter?: boolean
    autoSave?: boolean
}
export default function FormPage(props: FormPageProps) {

    const location = useLocation();
    const [working, workingSet] = useState<boolean>();
    const navigate = useNavigate();
    const user = getUser();
    const hub = new Hub<any>(props.apiRoute);

    const isUpdateDraft = location.pathname.indexOf('/draft/') > -1;
    const isUpdate = !isUpdateDraft && props.selectedId != undefined;
    
    const [autoSaved, autoSavedSet] = useState<number>(-1);
    const [autoSaving, autoSavingSet] = useState<boolean>();
    const [draftid, draftidSet] = useState<string>();
    
    let autoSaveBody:any;
    let autoSavedTime:number = 0;
    let autoSaveInterval:NodeJS.Timeout;
    let autoSavedInterval:NodeJS.Timeout;
    let draftId:string;
    

    const buttonText = () => {
        if (props.submitText) return props.submitText;
        return (isUpdate ? "Update " : "Create ") + props.objectName;
    }

    const create = async (body: any, callback?: (res: HUBResponse<any>) => void) => {
        workingSet(true);

        if(draftid) body.id = draftid;
        
        await hub.Create(body, res=> {
            if (callback) callback(res);
            if (res.success) {
                toastSuccess(props.objectName + " has been created successfully!");
                navigate(props.redirectTo);
            }
            else {
                workingSet(false);
            }
        });
    }

    const update = async (body: any, callback?: (res: HUBResponse<any>) => void) => {
        workingSet(true);
        await hub.Update(props.selectedId ?? "", body, res => {
            if (callback) callback(res);
            if(res.success) {
                if (isUpdateDraft) toastSuccess(props.objectName + " has been created successfully!");
                else toastSuccess(props.objectName + " has been updated successfully!");
                navigate(props.redirectTo);
            }
            else{
                workingSet(false);
            }
        });
    }

    const discard = async (callback: any) => {
        console.log("draftId", draftid);
        await hub.Trash(draftid ?? "", res => {
            if (callback) callback();
            if(res.success) {
                toastDefault(props.objectName + " has been discarded successfully!");
                navigate(props.redirectTo);
            }
            
        });
    }

    const autoSave = async () => {

        if(!props.autoSave) return;

        var formData = new FormData(document.getElementById("main-object-form") as HTMLFormElement);
        var formBody:any = {};
        var changed = false;
        formBody.draft = true;
        formData.forEach(function(value, key) {
            formBody[key] = value;
            if (!autoSaveBody && value && value != "") changed = true;
            else if (value && autoSaveBody[key] != value) changed = true; 
        });

        if (!changed) return;
        
        if (draftId) formBody.id = draftId;

        clearInterval(autoSavedInterval);
        autoSavingSet(true);
        autoSavedTime = 0;
        await hub.Create(formBody, res=> {

            if (res.success) {
                autoSaveBody = formBody;
                draftId = res.data.id;
                draftidSet(res.data.id);
                autoSavingSet(false);
                autoSavedSet(0);
                autoSavedInterval = setInterval(() => {
                    autoSavedTime++;
                    autoSavedSet(autoSavedTime);
                    console.log(autoSavedTime);
                }, 60000);
                console.log("auto-save", res);
            }

        });
        
    }

    const autoSaveText = () => {
        if (autoSaving) return "Auto Saving...";
        if (autoSaved == 0) return "Auto Saved a moment ago";
        return "Auto Saved " + autoSaved + " minutes ago";
    }

    const autoSaveInit = () => {
        if(props.autoSave) {
            setTimeout(() => {
                var formData = new FormData(document.getElementById("main-object-form") as HTMLFormElement);
                    autoSaveBody = {};
                    formData.forEach(function(value, key) {
                        autoSaveBody[key] = value;
                    });
            }, 1000);
        }
    }

    const FormButtons = () => (
        <>
            <Button color="success" isRunning={working} text={buttonText()} />
        </>
    )

    useEffect(()=> {

        if (isUpdateDraft && props.selectedId) draftId = props.selectedId;
        if (!isUpdate) autoSaveInterval = setInterval(autoSave, 20000);
        
        if (!isUpdate && !autoSaveBody) autoSaveInit();

        return () => {
            clearInterval(autoSaveInterval);
            clearInterval(autoSavedInterval);
            //clearToolbar();
        }

    }, [])

    const ActionButtons = () => (
        <>
            {
                (props.noHeader || props.noCard) &&
                <>
                    {
                        isUpdate && <Button color="light" text="Back To List" icon="chevron-left" to={props.redirectTo} />
                    }
                    {
                        !isUpdate && <Button text="Discard" color="secondary" to={props.redirectTo} />
                    }
                    {
                        user?.limited ? <Button icon="plus" text={buttonText()} modal="modal-user-is-limited" color="success" />
                        : <Button text={buttonText()} color="success" isRunning={working} onClick={()=> $("#main-object-form-button").click()} />
                    }
                </>
            }
        </>
    )

    useEffect(() => {
        setToolbarActions(ActionButtons);
    }, [props.noFooter, props.noHeader, props.noCard])


    return (
        <>
        {
            props.visible &&
            <Form validator={props.formValidator} 
                isUpdate={isUpdate}
                id="main-object-form"
                onSubmit={isUpdate || isUpdateDraft ? update : create}>
                <div className={props.noCard ? "" : "card"}>
                    {
                        (!props.noCard && !props.noHeader) &&
                        <div className="card-header">
                            {
                                props.title ? 
                                <div className="card-title">
                                    {props.title}
                                </div>
                                :
                                <div className="card-title">
                                    {props.objectName} Form
                                </div>
                            }
                            
                            <div className="card-action">
                                {
                                    isUpdate && <Button color="light" text="Back To List" icon="chevron-left" to={props.redirectTo} />
                                }
                                {
                                    !isUpdate && autoSaved > -1 &&
                                    <>
                                        <span className="btn btn-light btn-sm me-1 cursor-default">
                                            {autoSaveText()}
                                        </span>
                                        <Button text="Discard" color="secondary" className="me-1" modal="modal-discard"/>
                                    </>
                                }
                                <Button color="success" isSubmit isRunning={working} text={buttonText()} />
                            </div>
                        </div>
                    }

                    {
                        props.noHeader && <button id="main-object-form-button" style={{display:"none"}}>submit form</button>
                    }
                  
                    <div className="card-body py-10 mb-20">
                        {props.children}
                    </div>
                    {
                        (!props.noCard && !props.noFooter) &&
                        <div className="card-footer card-footer-form text-end">
                            {
                                isUpdate && <Button color="light" text="Back To List" icon="chevron-left" to={props.redirectTo} />
                            }
                            <Button color="success" isSubmit isRunning={working} text={buttonText()} />
                        </div>
                    }
                    
                </div>
            </Form>
        }


            <Modal id="modal-discard"
                title={"Discard " + props.objectName}
                onConfirm={discard}
                buttonText="Discard"
                buttonColor="secondary">
                    <p className="ps-1">Are you sure you want to discard changes of this {props.objectName}?</p>
            </Modal>
        </>
    )
}