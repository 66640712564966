import React, { useEffect } from "react";
import Layout from '../../../../components/layouts/Layout';
import { DataTable, DataTableField } from '../../../../components/data/DataTable';
import { API_ROUTES } from '../../../../core/Constants';
import { setPage, useGlobal } from '../../../../core/Global';
import Section from "../../../../components/base/Section";


function PBXServerList() {

  const [globalState, setGlobalState] = useGlobal();

  useEffect(() => {
    setPage("Servers", "System");
  }, [])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable objectName="Server"
          route="/system/servers"
          ascending
          apiRoute={API_ROUTES.PBXServers}
          edit>
          <DataTableField title="Name" dataValueName="name" />
          <DataTableField title="Host" dataValueName="host" />
          {/* <DataForm>
              <ExtensionForm />
            </DataForm> */}
        </DataTable>
      </Section>
    </Layout>
  );
}

export default PBXServerList;
