



import { useEffect, useRef, useState } from "react";
import { hasRole, openEditJobAppt, toAppointmentDate, toPrettyDate, useCashedData, useHub } from "../../../../../core/Global";
import Job from "../../models/Job";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import { TagifyDataItem } from "../../../../../models/TagifyOptions";
import { API_ROUTES } from "../../../../../core/Constants";
import { TagCompact } from "../../../base/models/TagItem";



interface IProps {
    job: Job
}
export default function JobDrawerTags(props: IProps) {


    const hub = useHub()
    const cash = useCashedData()
    const [tags, tagsSet] = useState<TagCompact[]>([])
    const [keyword, keywordSet] = useState<string>("")
    const searchBox = useRef<HTMLInputElement>(null)



    useEffect(() => {
        if (cash?.tags) {
            var tgs = cash.tags
                .filter(f => !props.job.tagIds?.includes(f.id!))
                .filter(f => f.name?.toLowerCase().includes(keyword.toLowerCase()));

            tagsSet(tgs)
        }
    }, [cash?.tags, props.job, keyword])

    useEffect(() => {
        window.dataTableMenu()
    }, [tags])

    return (
        <FlexRow>
            <div>
                <div className="text-muted fs-8">
                    <Icon icon="tag" size={10} className="me-2" type="solid" />
                    Tags
                </div>
                <FlexRow className="ps-5 mt-2" wrap>
                    {
                        props.job.tags?.map((item, k) =>
                            <span key={k} className="badge badge-secondary me-1 mb-1">
                                {item.name}
                                {
                                    (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="ps-2" title="Remove Tag"
                                        onClick={e => {
                                            e.preventDefault();
                                            hub.Post(API_ROUTES.Jobs + "removetag", {
                                                jobId: props.job.id,
                                                tagId: item.id
                                            }, res => { })
                                        }}>
                                        <Icon icon="times" className="text-muted fs-8" />
                                    </a>
                                }

                            </span>
                        )
                    }

                    {
                        cash?.tags &&
                        <>
                            {
                                (!props.job.isArchived || hasRole("EditArchivedJobs")) && cash.tags.filter(f => !props.job.tagIds?.includes(f.id)).length > 0 &&
                                <div>
                                    <a href="#" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
                                        className="badge badge-secondary mb-1"
                                        onClick={e => {
                                            setTimeout(() => {
                                                searchBox.current?.focus()
                                            }, 100);
                                        }}
                                    >
                                        <Icon icon="plus" size={10} />
                                        {
                                            (props.job.tags?.length ?? 0) == 0 &&
                                            <span className="ms-1 fs-9 fw-bold">
                                                Add Tag
                                            </span>
                                        }
                                    </a>
                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-0 m-0" data-kt-menu="true">
                                        <div>
                                            <input type="textbox" ref={searchBox} className="form-control form-control-sm border-0"
                                                placeholder="Search tag..." value={keyword} onChange={e => keywordSet(e.target.value)} />
                                        </div>
                                        <div className="scroll-y noscrollbar" style={{
                                            maxHeight: 200
                                        }}>

                                            {
                                                tags.map((f, k) =>
                                                    <div key={k} className="menu-item m-0">
                                                        <a href="#" className="menu-link px-3"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                hub.Post(API_ROUTES.Jobs + "addtag", {
                                                                    jobId: props.job.id,
                                                                    tagId: f.id
                                                                }, res => {
                                                                    keywordSet("")
                                                                })
                                                            }}
                                                        >
                                                            <Icon icon="tag" className="me-2" type="solid" />
                                                            {f.name}
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </FlexRow>
            </div>
            <FlexRow className="flex-grow-1" justify="end">

            </FlexRow>
        </FlexRow>
    )
}