import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import Environment from "../../env/env";
import { getUser, hasAccessToExt, hasAccessToQueue, hasRole, setCallToListen, setChanSpyLine, setHangupChannel, useGlobal, useHub, useSocket } from "../../core/Global";
import LiveCall from "../../modules/voice/calls/models/LiveCall";
import Layout from "../layouts/Layout";
import Div from "../base/Div";
import Indicator from "../base/Indicator";
import Icon from "../base/Icon";
import Avatar from "../base/Avatar";
import Timer from "../timer/Timer";
import { CallTypes, UserTypes } from "../../core/Enums";
import { Link, useNavigate } from "react-router-dom";
import LiveCallItem from "./LiveCallItem";

interface IProps {
    onFetch?: (count: number) => void
}

function LiveCallsBoard(props: IProps) {

    const hub = useHub();
    const user = getUser();
    const env = new Environment();
    const socket = useSocket();
    const navigate = useNavigate();
    const [loading, loadingSet] = useState<boolean>(true);
    const [loaded, loadedSet] = useState<boolean>(false);
    
    const [model, modelSet] = useState<LiveCall[]>([]);
    const [addCall, addCallSet] = useState<LiveCall>();
    const [endCall, endCallSet] = useState<LiveCall>();


    useEffect(() => {
        if (addCall) {
            if (env.isDevelopment) console.log("LiveCallSocket addCall", addCall.id, model);
            if (hasAccessToQueue(addCall.queueId) || hasAccessToExt(addCall.extensionId)) {
                var arr = model ?? [];
                if (arr.filter(c=>c.id == addCall.id).length < 1) {
                    arr.unshift(addCall);
                    modelSet(a=> [...arr]);
                } 
            }
        }
    }, [addCall])

    useEffect(() => {
        if (endCall) {
            if (env.isDevelopment) console.log("LiveCallSocket endCall", endCall.id, model);
            var arr = model ?? [];
            arr = arr.filter(c=> c.id != endCall.id);
            modelSet(a=> [...arr]);
            
        }
    }, [endCall])


    const fetch = async (callback?:any) => {
        
        hub.Get("/system/calls/live", res => {
            if(res.data) {
                modelSet(res.data);
                loadingSet(false);
                loadedSet(true);
                if(callback) callback();
            }
            else {
                if (props.onFetch) props.onFetch(0);
            }
        });

    }

    useEffect(() => {
 
        
        fetch();
        
        // var socketKey = socket.start(
        //     skt => {
        //         skt.on("UpdateLiveCalls", data => {
        //             if (user?.userType == UserTypes.Admin || user?.userType == UserTypes.Customer) modelSet(data);
        //             else fetch();
        //         });
                
        //     }, skt => {
        //         skt.off("UpdateLiveCalls");
        //     });

        // return () => {
        //     socket.stop(socketKey);
        // };

        var socketKey = socket.start(
            skt => {
                skt.on("AddLiveCall", data => {
                    if (env.isDevelopment) console.log("LiveCallSocket Add", data.id, data);
                    addCallSet(data);
                });

                skt.on("EndLiveCall", data => {
                    if (env.isDevelopment) console.log("LiveCallSocket End", data.id, data);
                    endCallSet(data);
                });
            }, skt => {
                skt.off("AddLiveCall");
                skt.off("EndLiveCall");
            });


        return () => {
            if (socketKey) socket.stop(socketKey);
        }
       

    }, [])

    useEffect(() => {
        if (props.onFetch) props.onFetch(model.length);
    }, [model])    


    return (
        <Div>
            
            <Div visible={loading} className="my-10">
                <Indicator />
            </Div>

            <Div visible={loaded && (model == null || model.length == 0)} className="p-10 text-center">
                <Icon icon="phone-rotary" size={46} type="solid" className="mb-5" />
                <br/>
                There is No Live Call!
            </Div>

            <Div visible={loaded && model != null && model.length > 0}>

            <div className="row">
            {
                model?.map((item, key) => 
                    <div key={key} className="col-12 col-sm-6 col-md-3 col-lg-3 col-xxxl-2">
                        <LiveCallItem call={item} />
                    </div>
                )
            }
            </div>
            
            </Div>

        </Div>
    )
}

export default LiveCallsBoard;