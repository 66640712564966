import { UserTypes } from "../../../../core/Enums";
import { getUser } from "../../../../core/Global";
import CRMDefaultRates from "../../../system/settings/components/CRMDefaultRates";
import LoginSettings from "./LoginSettings";

import Timezone from "./Timezone";


export default function ProfilePreferences() {
    const user = getUser();

    return (
        <>
            <Timezone />
            {
                user?.userType == UserTypes.Customer &&
                <>
                    <CRMDefaultRates />
                    <LoginSettings />
                </>
            }
        </>
    )
}