import { useEffect, useState } from "react";
import Hub from "../../../../api/Hub";
import Avatar from "../../../../components/base/Avatar";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import Indicator from "../../../../components/base/Indicator";
import Modal from "../../../../components/base/Modal";
import Layout from "../../../../components/layouts/Layout";
import { DisPositions } from "../../../../core/Constants";
import { CallsChartSegments, ChartTimingTypes, JobsChartSegments } from "../../../../core/Enums";
import { setPage, useEnvironment, useHub } from "../../../../core/Global";
import ApexChart, { ApexChartRequestBody } from "../../../../models/ApexChart";
import { TagifyDataItem } from "../../../../models/TagifyOptions";
import Section from "../../../../components/base/Section";
import { FontAwesomeIcons } from "../../../../core/Types";

function CallsReport() {

    let startSearch = false;
    let searchTimout:NodeJS.Timeout;

    const env = useEnvironment();
    const hub = useHub()
    const [loading, loadingSet] = useState<boolean>(true);
    const [model, modelSet] = useState<ApexChart>();
    const [chart, chartSet] = useState<any>();
    const [dates, datesSet] = useState<any>();
    const [body, bodySet] = useState<ApexChartRequestBody>({
        startDate: window.now(-10).format("DD/MM/YYYY"),
        toDate: window.now().format("DD/MM/YYYY"),
        chartTiming: ChartTimingTypes.Day,
        segment: CallsChartSegments.Dispatchers,
        segmentIds: [],
        metric1: "",
        metric2: "",
        metric3: "",
        callType: ""
    });

    const [chartDateText, chartDateTextSet] = useState<string>(window.now(-10).format("DD/MM/YYYY") + " - " + window.now().format("DD/MM/YYYY"));

    const [segmentIds, segmentIdsSet] = useState<string[]>([]);
    
    const [segmentsData, segmentsDataSet] = useState<TagifyDataItem[]>([]);
    const [selectedSegmentItem, selectedSegmentItemSet] = useState<TagifyDataItem>();
    const [selectedSegmentItems, selectedSegmentItemsSet] = useState<TagifyDataItem[]>([]);

    const [timingHourDisabled, timingHourDisabledSet] = useState<boolean>(false);
    const [timingDayDisabled, timingDayDisabledSet] = useState<boolean>(false);
    const [timingWeekDisabled, timingWeekDisabledSet] = useState<boolean>(false);
    const [timingMonthDisabled, timingMonthDisabledSet] = useState<boolean>(true);

    const fetch = () => {
        loadingSet(true);
       hub.Post("/reports/calls/chart", body, res => {
            if(res.success) {
                modelSet(res.data);
                loadingSet(false);
            }
       }) 
    }

    const changeSegment = () => {
        window.openModal("modal-segments");
    }

    const updateSegment = (s: CallsChartSegments) => {
        segmentIdsSet([]);
        segmentsDataSet([]);
        selectedSegmentItemsSet([]);
        selectedSegmentItemSet(undefined);
        bodySet({
            ...body, 
            segment: s,
            segmentIds: []
        });
        window.closeModal("modal-segments");
    }

    const segmentItemName = () => {
        if (body.segment == CallsChartSegments.Companies) return "Company";
        else if (body.segment == CallsChartSegments.Dispatchers) return "Dispatcher";
        else if (body.segment == CallsChartSegments.Extensions) return "Extension";
        else if (body.segment == CallsChartSegments.Queues) return "Queue";
        return "";
    }

    const segmentTitle = () => {
        if (body.segment == CallsChartSegments.Companies) return "Companies";
        else if (body.segment == CallsChartSegments.Dispatchers) return "Dispatchers";
        else if (body.segment == CallsChartSegments.Extensions) return "Extensions";
        else if (body.segment == CallsChartSegments.Queues) return "Queues";
        return "";
    }

    const segmentIcon = () => {
        if (body.segment == CallsChartSegments.Companies) return "briefcase";
        else if (body.segment == CallsChartSegments.Dispatchers) return "user-headset";
        else if (body.segment == CallsChartSegments.Extensions) return "phone-office";
        else if (body.segment == CallsChartSegments.Queues) return "arrow-right-to-line";
        return "dot";
    }
   
    const fetchSegmentData = (keyword?: string, include?: TagifyDataItem) => {
        var excludeIds = "";
        for (var i = 0; i < segmentIds.length; i++) {
            excludeIds += "&excludeIds=" + segmentIds[i];
        }
        var includeIds = "";
        if (include) includeIds = "&includeIds=" + include.value;

        var route = "/base/tagify/companies/";
        if (body.segment == CallsChartSegments.Companies) route = "/base/tagify/companies/";
        else if (body.segment == CallsChartSegments.Dispatchers) route = "/base/tagify/users/";
        else if (body.segment == CallsChartSegments.Extensions) route = "/base/tagify/extensions/";
        else if (body.segment == CallsChartSegments.Queues) route = "/base/tagify/queues/";

        hub.Get(route + "?type=2&keyword=" + keyword + excludeIds + includeIds, res => {
            if (res.data) {
                segmentsDataSet(res.data);
            }
        });
    }

    const addSegmentItem = (item?: TagifyDataItem) => {
        selectedSegmentItemSet(item);
        fetchSegmentData("", item);
        
        window.openModal("modal-segments-items");
        window.onModalOpen("modal-segments-items", () => {
            $("#txt-chart-segmentitem-search").val("");
            $("#txt-chart-segmentitem-search").focus();
        })
    }

    const selectSegmentItem = (item: TagifyDataItem) => {
        var arr = selectedSegmentItems ?? [];
        var ids = segmentIds ?? [];
        
        if (ids.indexOf(item.value) > -1) {
            arr.splice(ids.indexOf(item.value), 1);
            ids.splice(ids.indexOf(item.value), 1);
        }
        else {
            if (selectedSegmentItem) {
                arr.splice(ids.indexOf(selectedSegmentItem.value), 1);
                ids.splice(ids.indexOf(selectedSegmentItem.value), 1);
            }
            arr.push(item);
            ids.push(item.value);
        }

        selectedSegmentItemsSet(s => [...arr]);
        segmentIdsSet(s=> [...ids]);
        bodySet({
            ...body, 
            segmentIds: ids
        });
        window.closeModal("modal-segments-items");
    }

    const removeSegmentItem = (id: string) => {
        var arr = selectedSegmentItems ?? [];
        var ids = segmentIds ?? [];

        if (ids.indexOf(id) > -1) {
            arr.splice(ids.indexOf(id), 1);
            ids.splice(ids.indexOf(id), 1);
        }

        if (selectedSegmentItem) {
            arr.splice(ids.indexOf(selectedSegmentItem.value), 1);
            ids.splice(ids.indexOf(selectedSegmentItem.value), 1);
        }

        selectedSegmentItemsSet(s => [...arr]);
        segmentIdsSet(s=> [...ids]);
        bodySet({
            ...body, 
            segmentIds: ids
        });

    }
    
    const segmentSelected = (id: string) => {
        for(var i = 0; i < selectedSegmentItems.length; i++) {
            if(selectedSegmentItems[i].value == id) return true;
        }
        return false;
    }

    const updateTiming = (t: ChartTimingTypes) => {
        bodySet({
            ...body,
            chartTiming: t
        });
    }

    useEffect(() => {
        
        setPage("Calls Report", "Voice", "Calls");
        
        window.daterangecustom("chart-date", (start, end, label) => {
            if(env.isDevelopment) console.log("chart date changed", start.date, end);
            chartDateTextSet(start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY"));
            var difDays = end.diff(start, "days");
            datesSet({
                startDate: start.format("DD/MM/YYYY"),
                toDate: end.format("DD/MM/YYYY"),
                difDays: difDays
            });
            timingHourDisabledSet(difDays > 30);
            timingDayDisabledSet(difDays > 180 || difDays < 3);
            timingWeekDisabledSet(difDays < 14);
            timingMonthDisabledSet(difDays < 60);

        });

    }, [])

    useEffect(() => {
        if(dates) {
            bodySet({
                ...body,
                startDate: dates.startDate,
                toDate: dates.toDate,
                chartTiming: (dates.difDays < 3) ? ChartTimingTypes.Hourly : body.chartTiming
            })
        }
    }, [dates])
    
    useEffect(() => {
        if (env.isDevelopment) console.log("chart body changed", body)
        fetch();
    }, [body])

    useEffect(() => {
        if (model) {
            if(env.isDevelopment) console.log("chart updated", model);
            setTimeout(() => {
                $("#chart-target").html("");
                $("#chart-target").html("<div id='main-chart' style='height: 400px'></div>");
                var chart = window.createCallsChart(model);
                chartSet(chart);
                loadingSet(false);
            }, 500);
        }
    }, [model])

    return (
        <Layout wide>
            <Section fluid>

            <div className="d-flex align-items-center">
                <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center active" onClick={changeSegment}>
                    <Icon icon={segmentIcon()} type="solid" size={14} className="me-2" />
                    <span className="text-dark fw-bolder d-block fs-7">
                        {segmentTitle()}
                    </span>
                </label>
                {
                    selectedSegmentItems.map((item, key) => 
                    <label key={key} className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center active ms-3" onClick={e=> addSegmentItem(item)}>
                        <Avatar avatarUrl={item.avatar} width={16} />
                        <span className="text-dark d-block fs-7 ms-2">
                            {item.title}
                        </span>
                    </label>
                    )
                }
                
                {
                    selectedSegmentItems.length < 3 &&
                    <label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center ms-3" onClick={e => addSegmentItem()}>
                        <Icon icon="plus" type="solid" size={14} className="me-2" />
                        <span className="text-gray-500 d-block fs-7">
                            Add {segmentItemName()}
                        </span>
                    </label>
                }
                
            </div>
            
            
            <div className="card mt-2">
                <div className="card-body">

                    <div className="d-flex align-items-center">
                        <select className="form-select form-select-sm form-select-solid w-125px me-2" value={body.callType} 
                            onChange={e=> bodySet({
                                ...body,
                                callType: e.target.value
                            })}>
                            <option value="">All Calls</option>
                            <option value="Incoming">Incoming</option>
                            <option value="Outgoing">Outgoing</option>
                        </select>
                        <select className="form-select form-select-sm form-select-solid w-150px" value={body.metric1}
                            onChange={e=> bodySet({
                                ...body,
                                metric1: e.target.value
                            })}>
                            {
                                !body.metric1 && <option value="">Choose Metric</option>
                            }
                            {
                                (body.metric2 != DisPositions.ANSWERED && body.metric3 != DisPositions.ANSWERED) && <option value={DisPositions.ANSWERED}>{DisPositions.ANSWERED}</option>
                            }
                            {
                                (body.metric2 != DisPositions.NO_ANSWER && body.metric3 != DisPositions.NO_ANSWER) && <option value={DisPositions.NO_ANSWER}>{DisPositions.NO_ANSWER}</option>
                            }
                            {
                                (body.metric2 != DisPositions.BUSY && body.metric3 != DisPositions.BUSY) && <option value={DisPositions.BUSY}>{DisPositions.BUSY}</option>
                            }
                            {
                                (body.metric2 != "CALLBACK" && body.metric3 != "CALLBACK") && <option value={"CALLBACK"}>{"CALLBACK"}</option>
                            }
                            {/* {
                                (body.metric2 != "CALLED BACK" && body.metric3 != "CALLED BACK") && <option value={"CALLED BACK"}>{"CALLED BACK"}</option>
                            } */}
                            {
                                (body.metric2 != DisPositions.FAILED && body.metric3 != DisPositions.FAILED) && <option value={DisPositions.FAILED}>{DisPositions.FAILED}</option>
                            }
                        </select>
                        {
                            body.metric1 != "" &&
                            <>
                                <span className="mx-2">
                                    vs
                                </span>
                                <select className="form-select form-select-sm form-select-solid w-150px" value={body.metric2}
                                    onChange={e=> bodySet({
                                        ...body,
                                        metric2: e.target.value
                                    })}>
                                    {
                                        !body.metric2 && <option value="">Choose Metric</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.ANSWERED && body.metric3 != DisPositions.ANSWERED) && <option value={DisPositions.ANSWERED}>{DisPositions.ANSWERED}</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.NO_ANSWER && body.metric3 != DisPositions.NO_ANSWER) && <option value={DisPositions.NO_ANSWER}>{DisPositions.NO_ANSWER}</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.BUSY && body.metric3 != DisPositions.BUSY) && <option value={DisPositions.BUSY}>{DisPositions.BUSY}</option>
                                    }
                                    {
                                        (body.metric1 != "CALLBACK" && body.metric3 != "CALLBACK") && <option value={"CALLBACK"}>{"CALLBACK"}</option>
                                    }
                                    {/* {
                                        (body.metric1 != "CALLED BACK" && body.metric3 != "CALLED BACK") && <option value={"CALLED BACK"}>{"CALLED BACK"}</option>
                                    } */}
                                    {
                                        (body.metric1 != DisPositions.FAILED && body.metric3 != DisPositions.FAILED) && <option value={DisPositions.FAILED}>{DisPositions.FAILED}</option>
                                    }
                                </select>
                            </>
                        }
                        {
                            body.metric2 != "" &&
                            <>
                                <span className="mx-2">
                                    vs
                                </span>
                                <select className="form-select form-select-sm form-select-solid w-150px" value={body.metric3}
                                    onChange={e=> bodySet({
                                        ...body,
                                        metric3: e.target.value
                                    })}>
                                    {
                                        !body.metric3 && <option value="">Choose Metric</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.ANSWERED && body.metric2 != DisPositions.ANSWERED) && <option value={DisPositions.ANSWERED}>{DisPositions.ANSWERED}</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.NO_ANSWER && body.metric2 != DisPositions.NO_ANSWER) && <option value={DisPositions.NO_ANSWER}>{DisPositions.NO_ANSWER}</option>
                                    }
                                    {
                                        (body.metric1 != DisPositions.BUSY && body.metric2 != DisPositions.BUSY) && <option value={DisPositions.BUSY}>{DisPositions.BUSY}</option>
                                    }
                                    {
                                        (body.metric1 != "CALLBACK" && body.metric2 != "CALLBACK") && <option value={"CALLBACK"}>{"CALLBACK"}</option>
                                    }
                                    {/* {
                                        (body.metric1 != "CALLED BACK" && body.metric2 != "CALLED BACK") && <option value={"CALLED BACK"}>{"CALLED BACK"}</option>
                                    } */}
                                    {
                                        (body.metric1 != DisPositions.FAILED && body.metric2 != DisPositions.FAILED) && <option value={DisPositions.FAILED}>{DisPositions.FAILED}</option>
                                    }
                                </select>
                            </>
                        }

                        {
                            body.metric1 != "" &&
                            <span className="ms-3 cursor-pointer" onClick={e=> {
                                if (body.metric3 != "") {
                                    bodySet({
                                        ...body, 
                                        metric3: ""
                                    })
                                }
                                else if (body.metric2 != "") {
                                    bodySet({
                                        ...body, 
                                        metric2: ""
                                    })
                                }
                                else if (body.metric1 != "") {
                                    bodySet({
                                        ...body, 
                                        metric1: ""
                                    })
                                }
                            }}>
                                <Icon icon="xmark" size={14} />
                            </span>
                        }

                        <div className="ms-auto">
                            <div className="input-group mb-5">
                                <input type="text" className="form-control form-control-sm" value={chartDateText} />
                                <span className="input-group-text" id="chart-date">
                                    <i className="fas fa-calendar fs-4"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="mt-3 ms-4 d-flex">
                        <table>
                            <tbody>
                                {
                                    model?.legends.map((item, key) => 
                                    <tr key={key}>
                                        <td className="fs-8 p-1 fw-bolder" style={{minWidth:100}}>
                                            {item.title}
                                        </td>
                                        {
                                            item.childs.map((itm, k) => 
                                                <td key={k} className="px-2 py-1">
                                                    <div className="d-flex align-items-center" style={{minWidth:100}}>
                                                        <FlexRow id={`chart-legend-${key}-${k}`} className="cursor-pointer"
                                                            onClick={e=> {
                                                                if(chart) {
                                                                    var el = $(`#chart-legend-${key}-${k}`);

                                                                    if(el.hasClass("legend-hidden")) {
                                                                        el.removeClass("legend-hidden");
                                                                        chart.showSeries(itm.name);
                                                                    }
                                                                    else {
                                                                        el.addClass("legend-hidden");
                                                                        chart.hideSeries(itm.name);
                                                                    }
                                                                    
                                                                }
                                                            }}>
                                                            <Icon icon="circle" type="solid" className="me-1" size={12} color={itm.color} />
                                                            <div className="fs-8">
                                                                {itm.title}
                                                            </div>
                                                        </FlexRow>
                                                        {
                                                            itm.id &&
                                                            <>
                                                                <span className="ms-1 text-dark cursor-pointer" 
                                                                    style={{
                                                                        marginTop: 1
                                                                    }}
                                                                    onClick={e=> {
                                                                        removeSegmentItem(itm.id ?? "");
                                                                    }}>
                                                                    <Icon icon="xmark" size={11}/>
                                                                </span>
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                            )
                                        }
                                    </tr>
                                    )
                                }
                            </tbody>
                        </table>     
                        <div className="ms-auto">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                <button type="button" className={"btn btn-secondary p-2 px-4" + (body.chartTiming == ChartTimingTypes.Hourly ? " active" : "")} 
                                    onClick={e=> updateTiming(ChartTimingTypes.Hourly)} disabled={timingHourDisabled}>
                                    Hour
                                </button>
                                <button type="button" className={"btn btn-secondary p-2 px-4" + (body.chartTiming == ChartTimingTypes.Day ? " active" : "")}
                                    onClick={e=> updateTiming(ChartTimingTypes.Day)} disabled={timingDayDisabled}>
                                    Day
                                </button>
                                <button type="button" className={"btn btn-secondary p-2 px-4" + (body.chartTiming == ChartTimingTypes.Week ? " active" : "")}
                                    onClick={e=> updateTiming(ChartTimingTypes.Week)} disabled={timingWeekDisabled}>
                                    Week
                                </button>
                                <button type="button" className={"btn btn-secondary p-2 px-4" + (body.chartTiming == ChartTimingTypes.Month ? " active" : "")}
                                    onClick={e=> updateTiming(ChartTimingTypes.Month)} disabled={timingMonthDisabled}>
                                    Month
                                </button>
                            </div>
                        </div>                   
                    </div>

                    <div className="position-relative mt-2">
                        <div id="chart-target"></div> 
                        {
                            loading &&
                            <div style={{
                                position:"absolute",
                                height:"100%",
                                width:"100%",
                                paddingTop: 100,
                                backgroundColor:"rgba(0,0,0,.1)",
                                top:0,
                                right:0
                            }}>
                                <Indicator />
                            </div>
                        }
                    </div>
                </div>
            </div>

            </Section>

            <Modal id="modal-segments"
                title="Segments">
                    <div>
                        <label onClick={e=> updateSegment(CallsChartSegments.Companies)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-4 min-w-200px d-flex align-items-center mb-2" + (body.segment == CallsChartSegments.Companies ? " active" : "")}>
                            <Icon icon="briefcase" type="solid" size={16} className="me-2" />
                            <span className="text-dark fw-bolder d-block fs-6">
                                Company
                            </span>
                        </label>
                        <label onClick={e=> updateSegment(CallsChartSegments.Queues)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-4 min-w-200px d-flex align-items-center mb-2" + (body.segment == CallsChartSegments.Queues ? " active" : "")}>
                            <Icon icon="arrow-right-to-line" type="solid" size={16} className="me-2" />
                            <span className="text-dark fw-bolder d-block fs-6">
                                Queue
                            </span>
                        </label>
                        <label onClick={e=> updateSegment(CallsChartSegments.Dispatchers)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-4 min-w-200px d-flex align-items-center mb-2" + (body.segment == CallsChartSegments.Dispatchers ? " active" : "")}>
                            <Icon icon="user-headset" type="solid" size={16} className="me-2" />
                            <span className="text-dark fw-bolder d-block fs-6">
                                Dispatcher
                            </span>
                        </label>
                        <label onClick={e=> updateSegment(CallsChartSegments.Extensions)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-4 min-w-200px d-flex align-items-center" + (body.segment == CallsChartSegments.Extensions ? " active" : "")}>
                            <Icon icon="phone-office" type="solid" size={16} className="me-2" />
                            <span className="text-dark fw-bolder d-block fs-6">
                                Extension
                            </span>
                        </label>
                    </div>
            </Modal>

            <Modal id="modal-segments-items"
                title={segmentTitle()}>
                    <div>
                        <div className="mb-3 position-relative">
                            <input id="txt-chart-segmentitem-search" type="text" className="form-control form-control-sm form-control-solid" placeholder="Search..."
                                onChange={e=> {
                                    if (searchTimout) clearTimeout(searchTimout);
                                    searchTimout = setTimeout(() => fetchSegmentData(e.target.value), 500);
                                }} />
                        </div>
                        {
                            segmentsData.length == 0 && 
                            <div className="py-10 text-danger">
                                No Record Found!
                            </div>
                        }
                        <div className="scroll h-300px h-xxl-400px">
                        {
                            segmentsData.map((item, key) =>
                                <label key={key} onClick={e=> selectSegmentItem(item)} className={"btn btn-outline btn-outline-dashed btn-outline-default p-3 min-w-150px d-flex align-items-center mb-2" + (segmentSelected(item.value) ? " active" : "")}>
                                    {
                                        body.segment == CallsChartSegments.Dispatchers ? 
                                        <Avatar avatarUrl={item.avatar} width={26} fullName={item.title} />
                                        : <Icon icon={segmentIcon()} />
                                    }
                                    <div className="ms-3">
                                        <div className="text-dark fw-bolder fs-7 text-start">{item.title}</div>
                                        <div className="text-gray-500 fs-8 text-start">{item.text}</div>
                                    </div>
                                </label>
                            )
                        }
                        </div>
                    </div>
            </Modal>

            

        </Layout>
    )
}

export default CallsReport;