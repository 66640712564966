import './CallsList.css';
import React, { useEffect, useState } from "react";
import { DataTable, DataTableCustomActions, DataTableField } from '../data/DataTable';
import { API_ROUTES } from '../../core/Constants';
import { pauseGlobalPlayer, playCall, playGlobalPlayer, shareCalls, toPrettyDate, useGlobal } from '../../core/Global';
import Icon from '../base/Icon';
import Environment from '../../env/env';
import Modal from "../base/Modal";
import Hub from "../../api/Hub";
import Indicator from "../base/Indicator";
import Avatar from "../base/Avatar";
import FilterProps from "./calls_filtering.json";
import CallQuickView from '../calls/CallQuickView';
import { DataSelect, DataTab } from '../../core/Types';
import { CallSources, CallTypes } from 'src/core/Enums';
import Call, { CallListener } from '../../modules/voice/calls/models/Call';
import { Link } from 'react-router-dom';
import CallAgent from '../../modules/voice/calls/models/CallAgent';
import { CompactJob, CompactUser } from '../../core/CompactModels';
import FlexRow from '../base/FlexRow';
import DropdownMenu, { MenuItem } from '../menu/DropdownMenu';

interface IProps {
  agentId?: string
  jobId?: string
  isChild?: boolean
  compact?: boolean
  showCompany?: string
  keyword?: string
  noHeader?: boolean
  noFilter?: boolean
  noAction?: boolean
  noMultipleAction?: boolean
  refresh?: boolean
}

function CallsList(props: IProps) {

  var env = new Environment();
  const [globalState, setGlobalState] = useGlobal();
  const [playRecording, playRecordingSet] = useState<string>();
  const [selectedCall, selectedCallSet] = useState<any>();
  const [events, eventsSet] = useState<string[]>();
  const [eventsLoading, eventsLoadingSet] = useState<boolean>();
  const [modalCloseEvent, modalCloseEventSet] = useState<boolean>(false);


  const hub = new Hub<any>();

  const tabs: DataSelect[] = [
    {
      title: "Queues",
      name: "Queues",
      options: []
    },
    {
      title: "Statuses",
      name: "Status",
      options: [
        {
          name: "Answered",
          value: "ANSWERED"
        },
        {
          name: "No Answer",
          value: "NO ANSWER"
        },
        {
          name: "Busy",
          value: "BUSY"
        },
        {
          name: "Failed",
          value: "FAILED"
        }
      ]
    },
    {
      title: "Types",
      name: "Type",
      options: [
        {
          name: "Incomming",
          value: "1"
        },
        {
          name: "Outgoing",
          value: "2"
        }
      ]
    }
  ];



  const fetchEvents = async (id: string) => {
    eventsLoadingSet(true);
    await hub.Get("/system/calls/events/" + id,
      res => {
        eventsSet(res.data);
        eventsLoadingSet(false);
      }
    )

  }
  const RecordingFile = (data: any) => (
    <div className="calls-reports-actions">
      {
        (data?.recordingFile != null && data.recordingFile != "") ?
          <>
            <a href="#"
              data-bs-toggle="modal"
              data-bs-target="#modalListenToCall"
              onClick={e => {
                e.preventDefault();
                playRecordingSet(data.id);
                selectedCallSet(data);
              }}
              title="Play Recorded Call">
              <Icon icon="volume-high" />
            </a>
          </>
          :
          <a title="Not Recorded">
            <Icon icon="volume-high" color="#ccc" />
          </a>
      }

    </div>
  )

  const TechItem = (p: { tech?: CompactUser, number?: string }) => (
    <div className="d-flex align-items-center">
      {!props.compact && <Avatar width={20} avatarUrl={p.tech?.avatarUrl} className="me-2" />}
      <div>
        <div className="fs-7">
          {p.tech?.fullName ?? "Unknown"}
        </div>
        <div className="d-flex align-items-center">
          <Icon icon='phone-office' size={11} className='me-1' />
          <div className="fs-9">{p.number}</div>
        </div>
      </div>
    </div>
  )

  const JobCallerItem = (props: { job?: CompactJob }) => (
    <div className="d-flex align-items-center">
      <Avatar width={20} className="me-2" />
      <div>
        <div className="fs-7">
          {(props.job?.phone?.countryCode ?? "") + props.job?.phone?.phone}
        </div>
        <div className="fs-9">
          {props.job?.fullName}
        </div>
      </div>
    </div>
  )

  const AgentItem = (props: { agent?: CallAgent, ext?: number, number?: string, cid?: string }) => (
    <div className="d-flex align-items-center">
      <Avatar width={20} avatarUrl={props.agent?.avatarUrl} className="me-2" />
      <div>
        <div className="fs-7">
          {props.agent?.name}
        </div>
        <div className="d-flex align-items-center">
          <Icon icon='phone-office' size={11} className='me-1' />
          {
            props.ext && props.ext > 0 ?
              <>
                <div className="fs-8">{props.ext}</div>
                {
                  props.cid ?
                    <div className="fs-9 ms-1" title={props.number}>({props.cid})</div>
                    :
                    <div className="fs-9 ms-1">({props.number})</div>
                }

              </>
              :
              <>
                {
                  props.cid ?
                    <div className="fs-9" title={props.number}>{props.cid}</div>
                    :
                    <div className="fs-9">{props.number}</div>
                }
              </>
          }

        </div>
      </div>
    </div>
  )

  const CallerItem = (props: { country?: any, number?: string, cid?: string }) => (
    <div className="d-flex align-items-center">
      <Avatar width={20} avatarUrl={props?.country?.flagSquare} className="me-2" />
      <div>
        <div className="fs-7">
          {props.number}
        </div>
        <div className="fs-9">
          {props.country?.name}
        </div>
      </div>
    </div>
  )

  const CallSource = (data: Call) => (
    <>
      {
        data.callSource == CallSources.Twilio && data.callType == CallTypes.Outgoing ?
          <TechItem tech={data.tech} number={data.source} />
          : data.callSource == CallSources.Twilio && data.callType == CallTypes.Incoming ?
            <JobCallerItem job={data.job} />
            : data.callType == CallTypes.Incoming ?
              <CallerItem country={data.sourceAgent?.country} number={data.sourceAgent?.number} cid={data.sourceCID} />
              :
              <AgentItem agent={data.sourceAgent} ext={data.sourceExtension} number={data.source} cid={data.sourceCID} />
      }
    </>
  )

  const CallDestination = (data: Call) => (
    <>
      {
        data.callSource == CallSources.Twilio && data.callType == CallTypes.Incoming ?
          <TechItem tech={data.tech} number={data.source} />
          : data.callSource == CallSources.Twilio && data.callType == CallTypes.Outgoing ?
            <JobCallerItem job={data.job} />
            : data.callType == CallTypes.Outgoing && data.destinationAgent?.country ?
              <CallerItem country={data.destinationAgent?.country} number={data.destinationAgent?.number} cid={data.destinationCID} />
              :
              <AgentItem agent={data.destinationAgent} ext={data.destinationExtension} number={data.destination} cid={data.destinationCID} />
      }
    </>
  )

  const CallType = (data: Call) => (
    <div>
      {
        data?.callType == CallTypes.Incoming &&
        <Icon icon='phone-arrow-down-left' type='solid' className={"text-" + (data.disPosition == "ANSWERED" ? "success" : "danger") + " opacity-75"} size={16} title="Incoming Call" />
      }
      {
        data?.callType == CallTypes.Outgoing &&
        <Icon icon='phone-arrow-up-right' type='solid' className="text-primary opacity-75" size={16} title="Outgoing Call" />
      }
    </div>
  )

  const CallStatus = (data: any) => (
    <div className="d-flex align-items-center">
      {
        data?.disPosition == "ANSWERED" ?
          <Icon type="solid" icon="phone-volume" className="text-success me-3" />
          : data?.disPosition == "NO ANSWER" ?
            <Icon type="solid" icon="phone-missed" className="text-danger me-3" />
            : data?.disPosition == "BUSY" ?
              <Icon type="solid" icon="phone-xmark" className="text-danger me-3" />
              :
              <Icon type="solid" icon="phone" className="text-gray me-3" />
      }
      <div>
        <div className="callname">
          {data?.disPosition}
        </div>
        {
          data?.callType == CallTypes.Incoming &&
          <div className="callnumber align-items-center d-flex">
            <Icon type="solid" icon="phone-arrow-down-left" className="me-1" size={9} />
            Incoming
          </div>
        }
        {
          data?.callType == CallTypes.Outgoing &&
          <div className="callnumber align-items-center d-flex">
            <Icon type="solid" icon="phone-arrow-up-right" className="me-1" size={9} />
            Outgoing
          </div>
        }
        {
          data?.callType == CallTypes.Local &&
          <div className="callnumber align-items-center d-flex">
            <Icon type="solid" icon="headset" className="me-1" size={9} />
            Local
          </div>
        }
      </div>
    </div>
  )

  const CallCompany = (data: Call) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.companyName}
      </div>
      {
        data.queueName &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="arrow-right-to-line" className="me-1" size={12} />
          <span>
            {data.queueName}
          </span>
        </div>
      }
    </div>
  )

  const CallDurations = (data: Call) => (
    <div>
      <div className='d-flex align-items-center' title='Talk Time'>
        {data.billSecStr}
      </div>
      <div className='d-flex align-items-center fs-8 mt-1' title='Ring Time'>
        <Icon icon="phone-rotary" className="me-1 text-muted" size={12} />
        <span className='text-muted'>
          {data.ringSecStr}
        </span>
      </div>
    </div>
  )

  const CallDate = (data: Call) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.callDate)}
      </div>
      {
        props.compact &&
        <div className='d-flex align-items-center fs-8 mt-1' title='Call Duration'>
          <Icon icon="phone-volume" className="me-1 text-muted" size={12} />
          <span className='text-muted'>
            {data.billSecStr}
          </span>
        </div>
      }
    </div>
  )

  const CallLabels = (data: Call) => {

    return (
      (
        <div className='d-flex align-items-center call-labels'>
          {
            !window.isNullOrEmpty(data.twilioCallStatus) &&
            <span className='badge badge-light-primary'>{data.twilioCallStatus}</span>
          }
          {
            data.disPosition == "ANSWERED" &&
            <span className='badge badge-light-success'>Answered</span>
          }
          {
            data.callType == CallTypes.Incoming && (data.disPosition == "NO ANSWER" || data.disPosition == "BUSY") &&
            <span className='badge badge-light-danger'>Missed Call</span>
          }

          {
            data.callType == CallTypes.Outgoing && (data.disPosition == "NO ANSWER" || data.disPosition == "BUSY") &&
            <span className='badge badge-light-warning'>No Answer</span>
          }

          {
            data.callType == CallTypes.Outgoing && data.isCallback &&
            <span className='badge badge-light-primary'>
              <Icon icon='arrow-rotate-left' size={9} className="me-1" />
              Callback
            </span>
          }

          {
            data.callType == CallTypes.Incoming && data.isCalledback &&
            <Link to={"/voice/call/" + data.calledBackId} className='badge badge-light-success'>
              <Icon icon='arrow-rotate-left' size={9} className="me-1" />
              Called Back
            </Link>
          }

          {
            (data.disPosition == "ANSWERED" || data.twilioCallStatus == "completed") &&
            <>
              {
                data.listeners && data.listeners.length > 0 ?
                  <div>
                    <span className='badge badge-light-primary ms-1 cursor-pointer'
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                      <Icon icon='phone-volume' size={10} className="me-1" />
                      {data.listeners.length} Listeners
                    </span>
                    {
                      <DropdownMenu listerns={data.listeners} width={220} />
                    }
                  </div>
                  :
                  <span className='badge badge-light-danger ms-1'>
                    <Icon icon='phone-slash' size={10} className="me-1" />
                    No Listener
                  </span>
              }
            </>
          }

        </div>
      )
    )
  }

  const DetailsAction = (data: any) => (
    <div className="menu-item">
      <Link to={"/voice/call/" + data.id} className="menu-link px-3">
        <Icon icon="clock-rotate-left" className="me-2" />
        Call Details
      </Link>
    </div>
  )

  const ShareAction = (data: any) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3"
        onClick={e => {
          e.preventDefault();
          shareCalls([data.id]);
        }}
      >
        <Icon icon="share" className="me-2" />
        Share Call
      </a>
    </div>
  )

  const HistoryAction = (data: any) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3"
        onClick={e => {
          openCallDetails(data);
        }}
      >
        <Icon icon="clock-rotate-left" className="me-2" />
        Quick View
      </a>
    </div>
  )

  const DownloadRecording = (data: Call) => (
    <>
      {
        (data.disPosition == "ANSWERED" || data.twilioCallStatus == "completed") &&
        <div className="menu-item">
          <a href={env.localHubUrl + "/system/calls/recording/" + data.id} className="menu-link px-3" target="_blank">
            <Icon icon="download" className="me-2" />
            Download
          </a>
        </div>
      }
    </>
  )

  const NotesAction = (data: any) => (
    <div className="menu-item">
      <a href="#" className="menu-link px-3"
        onClick={e => {
          e.preventDefault();
          console.log("notes clicked!")
        }}
      >
        <Icon icon="comment" className="me-2" />
        Notes
      </a>
    </div>
  )

  const openCallDetails = (data: any) => {
    selectedCallSet(data);
    window.openModal("modal-call-quickview");
    if (!modalCloseEvent) {
      window.onModalClose("modal-call-quickview", () => {
        selectedCallSet(null);
      })
      modalCloseEventSet(true);
    }
  }

  const CallDetails = (data: any) => (
    <div className="d-flex align-items-center">
      <Avatar width={20} avatarUrl={(data.sourceAgent?.country ? data.sourceAgent?.country?.flagSquare : data.sourceAgent?.avatarUrl)} className="me-2" />
      <div>
        {
          data?.sourceAgent?.id ?
            <>
              <div className="fs-8">
                {data?.sourceAgent?.name}
                <span className='d-inline-block ms-1 fs-9'>
                  ({data?.sourceAgent?.number})
                </span>
              </div>
            </>
            :
            <>
              <div className="fs-8">
                {data?.sourceAgent?.number}
              </div>
            </>
        }
      </div>
      <div className='badge badge-danger'>
        Busy
      </div>
    </div>
  )

  const PlayRow = (data: Call) => (
    <div className={(props.noAction || props.noMultipleAction) ? "ps-5" : "text-center"}>
      <a href="#"
        title="Play Recording File"
        onClick={e => {
          e.preventDefault();
          if (globalState.selectedCallToPlay?.id == data.id) {
            if (globalState.floatAudioStatus?.playing) pauseGlobalPlayer();
            else playGlobalPlayer();
          }
          else {
            playCall(data);
            $(".data-row").removeClass("selected");
            $("#row-" + data.id).addClass("selected");
          }
        }}>
        {
          (data.disPosition == "ANSWERED" || data.callSource == CallSources.Twilio) && !window.isNullOrEmpty(data.recordingFile) ?
            <>
              <Icon icon={(globalState.selectedCallToPlay?.id == data.id && globalState.floatAudioStatus?.playing) ? "pause"
                : (globalState.selectedCallToPlay?.id == data.id && globalState.floatAudioStatus?.loading) ? "spinner"
                  : "play"} type="solid" spin={(globalState.selectedCallToPlay?.id == data.id && globalState.floatAudioStatus?.loading)}
                color="#555" size={17} />
            </>
            :
            <></>
        }

      </a>
    </div>
  )


  useEffect(() => {

  }, [])

  return (
    <>

      <DataTable objectName="Call"
        route="/system/calls"
        filteringProps={FilterProps}
        apiRoute={API_ROUTES.Calls}
        defaultId={props.agentId}
        jobId={props.jobId}
        isChild={props.isChild}
        defaultKeyword={props.keyword}
        noHeader={props.noHeader}
        noFilter={props.noFilter}
        noMultipleActions={props.noMultipleAction}
        noAction={props.noAction}
        refresh={props.refresh}
        onDataBinded={data => {
          //window.dataTableMenu()
        }}
        preventCreate share>
        <DataTableField title="" width={30}>
          <PlayRow />
        </DataTableField>
        <DataTableField title="" width={30} hidden={props.compact}>
          <CallType />
        </DataTableField>
        <DataTableField title="Source">
          <CallSource />
        </DataTableField>
        <DataTableField title="Destination" hidden={props.compact}>
          <CallDestination />
        </DataTableField>
        <DataTableField title='Company' hidden={props.compact}>
          <CallCompany />
        </DataTableField>
        <DataTableField title="Duration" hidden={props.compact}>
          <CallDurations />
        </DataTableField>
        <DataTableField title="Call Date">
          <CallDate />
        </DataTableField>
        <DataTableField title="Labels">
          <CallLabels />
        </DataTableField>
        <DataTableCustomActions>
          <DetailsAction />
        </DataTableCustomActions>
        {/* <DataTableCustomActions>
          <HistoryAction />
        </DataTableCustomActions> */}
        <DataTableCustomActions>
          <ShareAction />
        </DataTableCustomActions>
        <DataTableCustomActions>
          <DownloadRecording />
        </DataTableCustomActions>
      </DataTable>

      <CallQuickView selectedCall={selectedCall} />

    </>
  );
}

export default CallsList;
