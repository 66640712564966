import { useEffect, useRef, useState } from "react";
import { API_ROUTES } from "../../../core/Constants";
import { hasRole, selectChat, useChatList, useHub } from "../../../core/Global";
import ChatContactRow from "./ChatContactRow";
import ChatContactItem from "../models/ChatContactItem";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import ChatMessage from "../models/ChatMessage";
import ChatSearchResultRow from "./ChatSearchResultRow";
import { CompactJob, CompactUser } from "../../../core/CompactModels";
import Tagify from "../../../components/tagify/Tagify";
import DatePicker from "../../../components/form/DatePicker";
import { useNavigate } from "react-router-dom";
import { CancelTokenSource } from "axios";
import ChatGeneralSearch from "./ChatGeneralSearch";
import Indicator from "../../../components/base/Indicator";

interface IProps {
    readedChatId?: string
    //refresh?: boolean
    reset?: boolean
    searchingChat?: ChatContactItem
    filteringParticipant?: CompactUser
    filteringJob?: CompactJob
    onSelectContact?: (e: ChatContactItem) => void
    onSearchingChatCleared?: () => void
    onNewChat?: () => void
}

export default function ChatContactsBox(props: IProps) {

    let searchChatInput: HTMLInputElement | null;

    const hub = useHub();
    const chatlist = useChatList();

    const [contactsFiltered, contactsFilteredSet] = useState<ChatContactItem[]>();

    const [keyword, keywordSet] = useState<string>("");
    const [keywordTyping, keywordTypingSet] = useState<boolean>(false);





    let chatSearchTypeTimeout: NodeJS.Timeout;
    const [chatSearching, chatSearchingSet] = useState<boolean>(false);
    const [chatSearchTyping, chatSearchTypingSet] = useState<boolean>(false);
    const [chatSearched, chatSearchedSet] = useState<boolean>(false);
    const [searchResult, searchResultSet] = useState<ChatMessage[]>([]);
    const [chatSearchKeyword, chatSearchKeywordSet] = useState<string>("");
    const [selectedSearchedMessage, selectedSearchedMessageSet] = useState<ChatMessage>();

    const [chatSearchingAdvanced, chatSearchingAdvancedSet] = useState<boolean>(false);
    useEffect(() => {
        setTimeout(() => {
            fixSizes();
        }, 1);

        if (!chatSearchingAdvanced) {
            chatFilterParticipantsSet(undefined);
            chatFilterParticipantsRemovedSet(undefined);
            chatFilterParticipantsSelectedSet(undefined);
            chatFilterJobsSet(undefined);
            chatFilterJobSelectedSet(undefined);
            chatFilterJobRemovedSet(undefined);
        }

    }, [chatSearchingAdvanced])


    const [chatFilterParticipantsSelected, chatFilterParticipantsSelectedSet] = useState<string>();
    useEffect(() => {
        if (chatFilterParticipantsSelected) {
            chatFilterParticipantsRemovedSet(undefined);
            var arr = chatFilterParticipants ?? [];
            arr.push(chatFilterParticipantsSelected);
            chatFilterParticipantsSet(p => [...arr]);
        }
    }, [chatFilterParticipantsSelected]);

    const [chatFilterParticipantsRemoved, chatFilterParticipantsRemovedSet] = useState<string>();
    useEffect(() => {
        if (chatFilterParticipantsRemoved) {
            chatFilterParticipantsSelectedSet(undefined);
            var arr = chatFilterParticipants ?? [];
            var i = arr.indexOf(chatFilterParticipantsRemoved);
            if (i > -1) {
                arr.splice(i, 1);
                chatFilterParticipantsSet(p => [...arr]);
            }
        }
    }, [chatFilterParticipantsRemoved]);

    const [chatFilterParticipants, chatFilterParticipantsSet] = useState<string[]>();
    useEffect(() => {
        if (chatFilterParticipants) {
            searchChat();
        }
    }, [chatFilterParticipants])

    const [chatFilterJobSelected, chatFilterJobSelectedSet] = useState<string>();
    useEffect(() => {
        if (chatFilterJobSelected) {
            chatFilterJobRemovedSet(undefined);
            var arr = chatFilterJobs ?? [];
            arr.push(chatFilterJobSelected);
            chatFilterJobsSet(p => [...arr]);
        }
    }, [chatFilterJobSelected]);

    const [chatFilterJobRemoved, chatFilterJobRemovedSet] = useState<string>();
    useEffect(() => {
        if (chatFilterJobRemoved) {
            chatFilterJobSelectedSet(undefined);
            var arr = chatFilterJobs ?? [];
            var i = arr.indexOf(chatFilterJobRemoved);
            if (i > -1) {
                arr.splice(i, 1);
                chatFilterJobsSet(p => [...arr]);
            }
        }
    }, [chatFilterJobRemoved]);

    const [chatFilterJobs, chatFilterJobsSet] = useState<string[]>();
    useEffect(() => {
        if (chatFilterJobs) {
            searchChat();
        }
    }, [chatFilterJobs])

    useEffect(() => {

    }, [selectedSearchedMessage])

    // useEffect(() => {
    //     if (chatSearchKeyword.length > 1 || chatSearchDate) {
    //         chatSearchingSet(true);
    //         if (chatSearchTyping) return;
    //         searchChat();
    //     }
    //     else {
    //         chatSearchingSet(false);
    //         chatSearchedSet(false);
    //         searchResultSet([]);
    //     }
    // }, [chatSearchKeyword, chatSearchTyping, chatSearchDate])

    useEffect(() => {
        if (props.filteringParticipant || props.filteringJob) {
            chatSearchingAdvancedSet(true);
        }
    }, [props.filteringParticipant, props.filteringJob])

    const searchChat = () => {
        var q = "?";
        if (chatSearchKeyword.length > 1) {
            q += "keyword=" + encodeURIComponent(chatSearchKeyword);
        }
        chatFilterParticipants?.forEach(p => {
            if (q != "?") q += "&";
            q += "participants=" + p;
        });

        chatFilterJobs?.forEach(j => {
            if (q != "?") q += "&";
            q += "jobs=" + j;
        });

        // if (chatSearchDate) {
        //     if (q != "?") q += "&";
        //     q += "startDate=" + chatSearchDate.date.format("DD/MM/YYYY HH:mm");
        //     q += "&endDate=" + chatSearchDate.endDate.format("DD/MM/YYYY HH:mm");
        // }

        hub.Get(API_ROUTES.ChatSearch + props.searchingChat?.chatId + q,
            res => {
                if (res.success) {
                    searchResultSet(res.data);
                }
                chatSearchedSet(true);
                chatSearchingSet(false);
                fixSizes();
            })
    }


    const [boxHeight, boxHeightSet] = useState<number>();
    const [chatSearchBoxHeight, chatSearchBoxHeightSet] = useState<number>();
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400;
        if (w < 500) {
            h = 250;
        }
        else {
            h = h - ($("#kt_header").outerHeight() ?? 50);
            h = h - ($("#kt_toolbar").outerHeight() ?? 50);
            h = h - ($("#kt_chat_contacts_header").outerHeight() ?? 50);

            h = h - 40;
        }

        if (chatSearchingAdvanced) {
            h = h - ($("#chat-searching-advanced").outerHeight() ?? 0);
        }

        h = h - ($("#contacts-searching-advanced").outerHeight() ?? 0);

        boxHeightSet(h);

        h = h - ($("#chat_search_messages_header").outerHeight() ?? 50);
        chatSearchBoxHeightSet(h);

    }

    useEffect(() => {

        fixSizes();
        window.addEventListener("resize", fixSizes);

        return () => {
            window.removeEventListener("resize", fixSizes);
        }

    }, [])


    useEffect(() => {
        if (props.searchingChat) {
            searchChatInput?.focus();
            fixSizes();
        }
    }, [props.searchingChat]);


    const [chatsLoading, chatsLoadingSet] = useState<boolean>(false);


    return (
        <div className="card card-flush">
            <div className="card-header p-2" id="kt_chat_contacts_header"
                style={{
                    minHeight: "auto"
                }}>
                {
                    props.searchingChat ?
                        <form className="w-100 position-relative" autoComplete="off"
                            onSubmit={e => e.preventDefault()}>
                            <div className="d-flex align-items-center position-absolute" style={{ top: 15, right: 10 }}>
                                {
                                    chatSearchKeyword.length > 0 ?
                                        <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y me-2 cursor-pointer"
                                            onClick={e => {
                                                chatSearchKeywordSet("");
                                                searchChatInput?.focus();
                                            }}>
                                            <Icon icon="times" className="text-muted" size={18} />
                                        </span>
                                        :
                                        <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y me-1">
                                            <Icon icon="search" className="text-muted" size={18} />
                                        </span>
                                }
                                <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y cursor-pointer"
                                    onClick={e => {
                                        if (chatSearchingAdvanced) chatSearchingAdvancedSet(false)
                                        else chatSearchingAdvancedSet(true)
                                    }}>
                                    <Icon icon="gear" className="text-muted" size={18} type={chatSearchingAdvanced ? "solid" : "regular"} />
                                </span>
                            </div>

                            <input type="text" value={chatSearchKeyword} onChange={e => {
                                chatSearchKeywordSet(e.target.value);
                                chatSearchTypingSet(true);
                                if (chatSearchTypeTimeout) clearTimeout(chatSearchTypeTimeout);
                                chatSearchTypeTimeout = setTimeout(() => {
                                    chatSearchTypingSet(false);
                                }, 1000);
                            }}
                                ref={e => searchChatInput = e} className="form-control form-control-sm px-2 chat-searchbox"
                                placeholder="Search" />
                        </form>
                        :
                        <ChatGeneralSearch
                            onSearch={data => contactsFilteredSet(data)}
                            onShowArchived={show => {
                                if (show) {
                                    chatsLoadingSet(true)
                                    hub.Get(API_ROUTES.Chat + "archived", res => {
                                        contactsFilteredSet(res.data)
                                        chatsLoadingSet(false)
                                    })
                                }
                                else {
                                    chatsLoadingSet(false)
                                    contactsFilteredSet(undefined)
                                }
                            }} />
                }

            </div>

            <div className="card-body p-0" id="kt_chat_contacts_body">
                {
                    props.searchingChat ?
                        <div>
                            {
                                chatSearchingAdvanced &&
                                <div id="chat-searching-advanced" className="px-3 pb-2">
                                    <Tagify id="tagify-filter-participants" placeholder="Select User(s)" block marginBottom={2}
                                        label="Participants" labelUp closeOnSelect
                                        defaultId={props.filteringParticipant?.id}
                                        onSelect={val => chatFilterParticipantsSelectedSet(val)}
                                        onRemove={val => chatFilterParticipantsRemovedSet(val)}
                                        routeParams={[{
                                            name: "chatId",
                                            value: props.searchingChat.chatId
                                        }]} />

                                    <Tagify id="tagify-filter-jobs" placeholder="Select Job(s)" block marginBottom={2}
                                        label="Jobs" labelUp closeOnSelect
                                        apiUrl={API_ROUTES.TagifyChatJobs} defaultId={props.filteringJob?.id}
                                        onSelect={val => chatFilterJobSelectedSet(val)}
                                        onRemove={val => chatFilterJobRemovedSet(val)}
                                        routeParams={[{
                                            name: "chatId",
                                            value: props.searchingChat.chatId
                                        }]} />
                                    {/* <DatePicker id="dt-chatfilter-dates" label="Date" labelUp block marginBottom={0}
                                        dateType="range" timePicker maxDate={window.now()}
                                        onPick={d => generalSearchDateSet(d)}
                                        onClear={() => generalSearchDateSet(undefined)} /> */}
                                </div>
                            }
                            <div id="chat_search_messages_header">
                                <div className="py-2 px-3 fs-7"
                                    style={{
                                        backgroundColor: "#f0f0f0"
                                    }}>
                                    Search messages in
                                </div>
                                <div className={"d-flex align-items-start flex-stack py-2 px-3"}>
                                    <FlexRow className="w-100">
                                        {
                                            props.searchingChat.avatar ?
                                                <div className="symbol symbol-45px symbol-circle">
                                                    <img alt={props.searchingChat.title} src={props.searchingChat.avatar} />
                                                </div>
                                                :
                                                <div className="symbol symbol-45px symbol-circle">
                                                    <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{props.searchingChat.avatarAlphabet}</span>
                                                </div>
                                        }

                                        <div className="ms-2 flex-grow-1">
                                            <FlexRow>
                                                <div className={"fs-6 text-gray-900"}>
                                                    {props.searchingChat.title}
                                                </div>
                                                <div className="ms-auto me-3">
                                                    <a href="#" onClick={e => {
                                                        e.preventDefault();
                                                        chatSearchKeywordSet("");
                                                        searchResultSet([]);
                                                        chatSearchingAdvancedSet(false);
                                                        if (props.onSearchingChatCleared) props.onSearchingChatCleared();
                                                    }}>
                                                        <Icon icon="times" className="text-dark" size={16} type="light" />
                                                    </a>
                                                </div>
                                            </FlexRow>
                                        </div>

                                    </FlexRow>
                                </div>
                                <div className="py-2 px-3 fs-7"
                                    style={{
                                        backgroundColor: "#f0f0f0"
                                    }}>
                                    {
                                        chatSearching ?
                                            <FlexRow>
                                                <span className="me-2"><Icon icon="spinner-third" type="light" spin size={12} /></span>
                                                <span>Searching...</span>
                                            </FlexRow>
                                            : chatSearched && searchResult.length > 0 ?
                                                <span>
                                                    {searchResult.length} Messages found!
                                                </span>
                                                : chatSearched && searchResult.length == 0 ?
                                                    <span>
                                                        No messages found!
                                                    </span>
                                                    :
                                                    <span>
                                                        Search result
                                                    </span>

                                    }
                                </div>
                            </div>
                            <div className="scroll-y"
                                style={{
                                    height: chatSearchBoxHeight
                                }}>

                                {
                                    searchResult.map((msg, key) =>
                                        <ChatSearchResultRow key={key} item={msg}
                                            selected={selectedSearchedMessage?.id == msg.id}
                                            onClick={m => selectedSearchedMessageSet(m)} />
                                    )
                                }

                            </div>
                        </div>
                        :
                        <div>
                            <div className="scroll-y"
                                style={{
                                    height: boxHeight
                                }}>

                                {
                                    chatlist?.length == 0 && !contactsFiltered && hasRole("ChatStart") && !chatsLoading &&
                                    <div className="text-center py-10">
                                        <button className="btn btn-sm btn-primary" onClick={props.onNewChat}>
                                            <Icon icon="edit" />
                                            Start New Chat
                                        </button>
                                    </div>
                                }

                                {
                                    chatsLoading ?
                                        <div className="my-10">
                                            <Indicator />
                                        </div>
                                        :
                                        <>
                                            {
                                                (contactsFiltered ? contactsFiltered :
                                                    (chatlist ?? []))
                                                    .sort((a, b) => {
                                                        if (a.pinned && !b.pinned) return -1;
                                                        if (!a.pinned && b.pinned) return 1;

                                                        const dateA = new Date(a.lastUpdateDate!).getTime();
                                                        const dateB = new Date(b.lastUpdateDate!).getTime();
                                                        return dateB - dateA;

                                                    })
                                                    .map((item, key) =>
                                                        <ChatContactRow key={item.id} item={item}
                                                            seperator={key + 1 < (chatlist ?? []).length}
                                                            readed={item.chatId == props.readedChatId}
                                                            onUnarchive={() => {
                                                                keywordSet("");
                                                                contactsFilteredSet([]);
                                                            }}
                                                        />
                                                    )
                                            }
                                        </>

                                }



                            </div>
                        </div>
                }

            </div>
        </div>
    )
}