import { useEffect, useState } from "react"
import Icon from "../../../../components/base/Icon"
import Job from "../models/Job"
import JobNote from "../models/JobNote"
import { getCountry, hasRole, openEditJobAddress, openEditJobAppt, openEditJobClient, openEditJobDetails, openEditJobTech, useHub } from "../../../../core/Global"
import { API_ROUTES } from "../../../../core/Constants"
import NoteItem from "./NoteItem"
import NotesContainer from "./NotesContainer"
import JobLogsContainer from "./JobLogsContainer"
import Map from "../../../../components/here/Map"
import Technician from "../../../technicians/models/technician"
import JobTaskCard from "./JobTaskCard"
import TaskItem from "../models/TaskItem"
import NotesTypeDropDown from "./NotesTypeDropDown"
import JobMetro from "../../base/models/JobMetro"
import FlexRow from "../../../../components/base/FlexRow"


type IProps = {
    job?: Job
}

type ItemProps = {
    title?: string
    value?: string
    icon?: any
    className?: string
    bottom?: number
    link?: string
}

export default function JobOverview(props: IProps) {

    let notesTextArea: HTMLTextAreaElement | null;

    const hub = useHub();


    const [editTask, editTaskSet] = useState<boolean>(false);
    const [tasks, tasksSet] = useState<TaskItem[]>();
    const [selectedTask, selectedTaskSet] = useState<TaskItem>();
    const fetchTasks = () => {

        hub.Get(API_ROUTES.Jobs + "tasks/" + props.job?.id, res => {
            if (res.success) {
                tasksSet(t => [...res.data]);
            }
        })
    }

    const [tab, tabSet] = useState<string>("notes");
    const [noteType, noteTypeSet] = useState<string>("all");
    const [replyItem, replyItemSet] = useState<JobNote>();
    const [refreshNotes, refreshNotesSet] = useState<boolean>(false);
    useEffect(() => {
        if (refreshNotes) {
            setTimeout(() => {
                refreshNotesSet(false);
            }, 1000);
        }
    }, [refreshNotes])

    useEffect(() => {
        if (tab == "tasks") fetchTasks();
    }, [tab])


    const [sideBoxHeight, sideBoxHeightSet] = useState<number>();
    const setHeight = () => {
        var h = ($("#card-address .card-body").innerHeight() ?? 500) + ($("#card-client .card-body").innerHeight() ?? 500);
        sideBoxHeightSet(h);
    }



    const [note, noteSet] = useState<string>("");
    const sendNote = () => {

        if (note == "") return;

        hub.Post(API_ROUTES.Jobs + "note", {
            message: note,
            id: props.job?.id,
            replyId: replyItem?.id
        }, res => {
            if (res.success) {
                refreshNotesSet(true);
                replyItemSet(undefined);
                noteSet("");
                notesTextArea?.focus();
            }
        })
    }

    useEffect(() => {
        setHeight();
    }, [props.job])

    const [tech, techSet] = useState<Technician>();
    useEffect(() => {
        if (props.job?.techId) {
            hub.Get(API_ROUTES.Technicians + props.job.techId, res => {
                if (res.success) techSet(res.data);
            })
        }
        else {
            techSet(undefined);
        }
    }, [props.job?.techId])

    const [metro, metroSet] = useState<JobMetro>();
    useEffect(() => {
        if (props.job?.metroId) {
            hub.Get(API_ROUTES.JobMetros + props.job.metroId, res => {
                if (res.success) metroSet(res.data);
            })
        }
        else {
            metroSet(undefined);
        }
    }, [props.job?.metroId])

    const JobDataItem = (p: ItemProps) => (
        <div className={"mb-" + (p.bottom ?? 5) + " " + p.className}>
            <div className="text-muted fs-8">
                <Icon icon={p.icon} size={10} className="me-2" type="solid" />
                {p.title}
            </div>
            <div className="fw-bold ps-5">
                {
                    p.link ?
                        <a href={p.link} target="_blank">{p.value} <Icon icon="arrow-up-right-from-square" size={11} /></a>
                        : <>{p.value}</>
                }
            </div>
        </div>
    )


    return (
        <>
            <div className="row">
                <div className="col-12 col-md-4 col-xxxl-3">

                    <div className="card mb-5">
                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <div className="card-title m-0">
                                <h3 className="fs-5 fw-bolder m-0">
                                    Job Details
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="btn btn-sm btn-light btn-icon" onClick={e => {
                                        e.preventDefault();
                                        if (props.job) openEditJobDetails(props.job)
                                    }}>
                                        <Icon icon="edit" size={12} className="mb-1" />
                                    </a>
                                }

                            </div>
                        </div>
                        <div className="card-body">

                            <JobDataItem icon="bullhorn" title="Job Source" value={props.job?.jobSource} />
                            <JobDataItem icon="wrench" title="Job Type" value={props.job?.jobTypeWithGroup} />

                            <div className="mb-5">
                                <div className="text-muted fs-8">
                                    <Icon icon="file-lines" size={10} className="me-2" />
                                    Job Details
                                </div>
                                <div className="fw-bold ps-5 fs-7" style={{
                                    whiteSpace: "pre-wrap"
                                }}>
                                    {props.job?.jobDetails}
                                </div>
                            </div>

                            {
                                props.job?.tags && props.job.tags.length > 0 &&
                                <div className="mb-5">
                                    <div className="text-muted fs-8 mb-2">
                                        <Icon icon="tag" size={10} className="me-2" />
                                        Tags
                                    </div>
                                    <div>
                                        {
                                            props.job.tags.map((t, k) =>
                                                <span key={k} className="badge badge-secondary me-1 mb-1">
                                                    {t.name}
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            }



                        </div>
                    </div>

                    <div className="card mb-5">

                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <div className="card-title m-0">
                                <h3 className="fs-5 fw-bolder m-0">
                                    Technician
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="btn btn-sm btn-light btn-icon" onClick={e => {
                                        e.preventDefault();
                                        if (props.job) openEditJobTech(props.job)
                                    }}>
                                        <Icon icon="edit" size={12} className="mb-1" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="card-body">

                            {
                                props.job?.tech ?
                                    <>
                                        <div className="row">
                                            <div className="col-6">
                                                <JobDataItem icon="user-helmet-safety" title="Technician" value={props.job.tech.fullName} link={`/accounts/manage/${props.job.tech.id}`} />
                                            </div>
                                            <div className="col-6">
                                                <JobDataItem icon="phone-rotary" title="Phone" value={props.job.tech.phoneNumber} />
                                            </div>
                                        </div>
                                        <JobDataItem icon="map" title="Metro" value={props.job?.metroName ?? "[Unassigned]"} />
                                    </>
                                    :
                                    <div className="text-center py-10">
                                        No technician assigned to job!
                                    </div>
                            }


                        </div>
                    </div>

                    <div className="card mb-5">

                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <div className="card-title m-0">
                                <h3 className="fs-5 fw-bolder m-0">
                                    Appointment
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="btn btn-sm btn-light btn-icon" onClick={e => {
                                        e.preventDefault();
                                        if (props.job) openEditJobAppt(props.job)
                                    }}>
                                        <Icon icon="edit" size={12} className="mb-1" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="card-body">

                            {
                                props.job?.appointmentDate ?
                                    <div className="row">
                                        <JobDataItem icon="calendar" title="Appointment Date" value={props.job?.appointmentDate} className="col-6" />
                                        <JobDataItem icon="clock" title="Appointment Time" value={(props.job?.appointmentTimeFrom + " - " + props.job?.appointmentTimeTo)} className="col-6" />
                                    </div>
                                    :
                                    <div className="text-center py-10">
                                        Job appointment not specified!
                                    </div>
                            }


                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">

                    <div id="card-address" className="card mb-5">
                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <div className="card-title m-0">
                                <h3 className="fs-5 fw-bolder m-0">
                                    Address
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="btn btn-sm btn-light btn-icon" onClick={e => {
                                        e.preventDefault();
                                        if (props.job) openEditJobAddress(props.job)
                                    }}>
                                        <Icon icon="edit" size={12} className="mb-1" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="card-body">


                            <JobDataItem icon="location-dot" title="Address" value={props.job?.address?.title} />

                            <div className="row">
                                <div className="col-6">
                                    <JobDataItem title="City" value={props.job?.address?.address?.city} bottom={1} />
                                </div>
                                <div className="col-6">
                                    <JobDataItem title="State" value={props.job?.address?.address?.state} bottom={1} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <JobDataItem title="Zip Code" value={props.job?.address?.address?.postalCode} />
                                </div>
                                <div className="col-6">
                                    <JobDataItem title="Country" value={getCountry(props.job?.address?.countryId)?.name} />
                                </div>
                            </div>

                            <div className="mt-5">

                                {
                                    props.job?.position &&
                                    <Map id="map-job-details" height={200}
                                        jobMarker={props.job?.position}
                                        center={props.job?.position}
                                        tech={tech} metro={metro}
                                        zoom={10} />
                                }
                            </div>

                        </div>
                    </div>

                    <div id="card-client" className="card mb-5">
                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <div className="card-title m-0">
                                <h3 className="fs-5 fw-bolder m-0">
                                    Client Details
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                {
                                    (!props.job?.isArchived || hasRole("EditArchivedJobs")) &&
                                    <a href="#" className="btn btn-sm btn-light btn-icon" onClick={e => {
                                        e.preventDefault()
                                        if (props.job) openEditJobClient(props.job)
                                    }}>
                                        <Icon icon="edit" size={12} className="mb-1" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="row">

                                <div className="col-12 col-xxl-6">
                                    <JobDataItem icon="user" title="Client Name" value={props.job?.fullName} />
                                </div>

                                <div className="col-12 col-xxl-6">
                                    <JobDataItem icon="phone-rotary" title="Phone" value={props.job?.phone?.phoneFriendly} />
                                </div>

                                <div className="col-12 col-xxl-6">
                                    <JobDataItem icon="envelope" title="Email Address" value={props.job?.emailAddress ?? "-"} />
                                </div>

                                <div className={"col-12 col-xxl-6"}>
                                    <JobDataItem icon="briefcase" title="Company" value={props.job?.company ?? "-"} />
                                </div>

                                <div className={"col-12 col-xxl-6"}>
                                    <JobDataItem icon="phone-rotary" title="Secondary Phone" value={props.job?.phoneContact?.phoneFriendly ?? "-"} />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


                <div className="col-12 col-md-4 col-xxxl-5">
                    <div className="card mb-5">
                        <div className="card-header" style={{
                            minHeight: 50
                        }}>
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-bold">

                                <li className="nav-item">
                                    <a href="#" className={"nav-link text-active-primary ms-0 me-5 py-1" + (tab == "notes" ? " active" : "")}
                                        onClick={e => {
                                            e.preventDefault();
                                            tabSet("notes");
                                        }}>
                                        Notes
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a href="#" className={"nav-link text-active-primary ms-0 me-5 py-1" + (tab == "tasks" ? " active" : "")}
                                        onClick={e => {
                                            e.preventDefault();
                                            tabSet("tasks");
                                        }}>
                                        Tasks
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a href="#" className={"nav-link text-active-primary ms-0 me-5 py-1" + (tab == "logs" ? " active" : "")}
                                        onClick={e => {
                                            e.preventDefault();
                                            tabSet("logs");
                                        }}>
                                        Logs
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div className="card-body">

                            {
                                tab == "notes" &&
                                <div className="mb-5">
                                    <div id="div-notestypes-dropdown" className="mb-5">
                                        <NotesTypeDropDown jobId={props.job?.id} onChange={t => noteTypeSet(t)} />
                                    </div>

                                    <NotesContainer id="job-overview-notes" refresh={refreshNotes} jobId={props.job?.id}
                                        noteType={noteType} height={(sideBoxHeight ?? 0) - 100}
                                        onReplyItemSet={item => replyItemSet(item)} />

                                    <div id="notes-quickview-textbox-area" className="mt-5"
                                        style={{
                                            position: "relative"
                                        }}>
                                        <textarea id="txt-job-details-note" name="message" placeholder="Write a note..."
                                            ref={e => notesTextArea = e} value={note} onChange={e => noteSet(e.target.value)}
                                            className="form-control form-control-sm" rows={4} autoFocus
                                            onKeyDown={e => {
                                                if (e.key == "Enter") {
                                                    e.preventDefault();
                                                    var txt = $("#txt-job-details-note");
                                                    if (e.ctrlKey || e.shiftKey || e.altKey) {
                                                        txt.val(txt.val() + "\n");
                                                        txt.scrollTop(txt[0].scrollHeight - (txt.height() ?? 0));
                                                    }
                                                    else {
                                                        sendNote();
                                                    }
                                                }
                                            }}></textarea>

                                        <button type="button" id="btn-job-details-sendnote" className="btn btn-sm btn-primary btn-circle btn-icon p-7"
                                            style={{
                                                position: "absolute",
                                                right: 6,
                                                top: 20
                                            }}
                                            onClick={sendNote}>
                                            <Icon icon="paper-plane-top" size={24} />
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                tab == "tasks" &&
                                <div className="mb-5">
                                    {
                                        (tasks?.length ?? 0 > 0) ?
                                            <div>
                                                {
                                                    tasks?.map((t, k) =>
                                                        <JobTaskCard key={k} task={t} className="mb-2" editOnClick
                                                            onEdit={() => {
                                                                selectedTaskSet(t);
                                                                editTaskSet(true);
                                                                window.hideFormErrors();
                                                            }}
                                                            onDelete={() => {
                                                                selectedTaskSet(t);
                                                                window.openModal("modal-delete-task");
                                                            }} />
                                                    )
                                                }
                                            </div>
                                            :
                                            <div className="mt-20 text-center">
                                                There is no task!
                                            </div>
                                    }
                                </div>
                            }

                            {
                                tab == "logs" &&
                                <div className="mb-5">
                                    <JobLogsContainer id="job-viewmodal-logs" jobId={props.job?.id} height={sideBoxHeight} />
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}