import React from "react";
import FlexRow from "./FlexRow";

type IndicatorProps = {
    className?: string,
    text?: string,
    noText?: boolean,
    color?: string
}

function Indicator(props: IndicatorProps) {

    return (
        <FlexRow justify="center" className={props.className}>
            {/* <span className="spinner-border spinner-border-sm align-middle me-3"></span>  */}
            <span className="me-2">
                <i className="fa-regular fa-spinner-third fa-spin fa-lg" style={{
                    color: props.color
                }}></i>
            </span>
            {
                !props.noText &&
                <span style={{
                    color: props.color
                }}>
                    {props.text ? props.text : "Loading..."}
                </span>
            }

        </FlexRow>
    )
}

export default Indicator;

