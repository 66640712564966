import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import Modal from "../../../components/base/Modal";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { API_ROUTES } from "../../../core/Constants";
import { toPrettyDate, updateUsersOn, useHub } from "../../../core/Global";
import User from "../models/User";


interface IProps {
    user: User
    onClose: () => void
}

export default function CustomersRenewModal(props: IProps) {

    const hub = useHub();



    return (
        <Modal id="customers-renew-modal"
            title="Renew Subscription"
            open visible
            onClose={props.onClose}
            buttonText="Renew Subscription"
            buttonColor="success"
            onSubmit={(body, callback) => {

                hub.Post(API_ROUTES.Customers + "renewsub", {
                    ...body,
                    id: props.user.id
                }, res => {
                    callback!(res);
                    updateUsersOn();
                })

            }}>
            <div>
                <FlexRow className="mb-2">
                    <Icon icon="user" type="solid" className="me-4" size={18} />
                    <span className="fs-3 mt-1">{props.user.fullName}</span>
                </FlexRow>
                <FlexRow className="mb-10">
                    <Icon icon="calendar-times" type="solid" className="me-4" size={18} />
                    <span className="fs-3 mt-1">{toPrettyDate(props.user.subscription?.endsAt)}</span>
                </FlexRow>
                <ToggleSwitch name="freecharge" label="Renew without charging!" raw small />
            </div>
        </Modal>
    )
}