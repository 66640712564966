import React, { useEffect, useState } from "react";
import Avatar from '../base/Avatar';
import Icon from '../base/Icon';
import Environment from '../../env/env';
import { clearCallToListen, setChanSpyLine, setHangupChannel, useEnvironment, useGlobal, useHub, useSIP, useSocket } from '../../core/Global';
import LiveCall from "../../modules/voice/calls/models/LiveCall";
import Hub from "../../api/Hub";
import Timer from "../timer/Timer";
import { CallTypes } from "../../core/Enums";
import Call from "../../modules/voice/calls/models/Call";
import Container from "../base/Container";
import { RegistererStats } from "../../models/DSPVoiceSIP";
import WebRTCModel from "../../models/WebRTCModel";

type Props = {
    selectedCall: Call | LiveCall,
    onPause?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void,
    onPlay?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void
}

enum ConnectionStatus {
    Disconnected = 0, Connecting = 1, Connected = 2
}

function FloatCallSpy(props: Props) {
    const env = useEnvironment();
    const hub = useHub();
    const sip = useSIP();
    const socket = useSocket();
    const [call, callSet] = useState<LiveCall>();
    const [whisper, whisperSet] = useState<boolean>();
    const [barge, bargeSet] = useState<boolean>();
    const [globalState, setGlobalState] = useGlobal();

    const [chanSpyLine, chanSpyLineSet] = useState<any>();
    const [currentServer, currentServerSet] = useState<string>();
    const [connectionStatus, connectionStatusSet] = useState<ConnectionStatus>(ConnectionStatus.Disconnected);
    const [registererState, registererStateSet] = useState<RegistererStats>(RegistererStats.Initial);
    const [status, statusSet] = useState<number>(1);


    const fetch = () => {
        hub.Get("/system/calls/live/" + props.selectedCall.id, res => {
            //console.log(res.data);
            callSet(res.data);
            
            if (props.selectedCall.extNumber) {
                if (window.UserAgentIsValid(res.data.webRTC)) {
                    if(env.isDevelopment) console.log("UserAgent is still valid!");
                    connectionStatusSet(ConnectionStatus.Connected);
                    registererStateSet(RegistererStats.Registered);
                    window.StartListen(props.selectedCall.extNumber, li => {
                        chanSpyLineSet(li);
                    });
                }
                else {
                    window.CreateUserAgent({
                        debug: env.isDevelopment,
                        webrtc: res.data.webRTC,
                        onConnect: () => {
                            if(env.isDevelopment) console.log("UserAgent onConnect");
                            connectionStatusSet(ConnectionStatus.Connected);
                        },
                        onDisconnect: err => {
                            if(env.isDevelopment) console.log("UserAgent onDisconnect", err);
                            connectionStatusSet(ConnectionStatus.Disconnected);
                        },
                        onRegister: () => {
                            if(env.isDevelopment) console.log("UserAgent onRegister");
                            window.StartListen(props.selectedCall.extNumber, li => {
                                chanSpyLineSet(li);
                            });
                        },
                        onStateChanged: state => {
                            if(env.isDevelopment) console.log("UserAgent onStateChanged", state);
                            registererStateSet(state);
                        }
                    });
                }
                // window.StartListen(res.data.webrtc, props.selectedCall.extNumber, li => {
                //     console.log("session started!", li);
                //     chanSpyLineSet(li);
                // })   
            }
        });
    }

    const startListen = () => {
        
    }



    useEffect(() => {

        if (props.selectedCall) {

            fetch();

            var socketKey = socket.start(
                skt => {
                    skt.on("EndLiveCall-" + props.selectedCall.id, data => {
                        window.endSessions();
                        clearCallToListen();
                    });
                }, skt => {
                    skt.off("EndLiveCall-" + props.selectedCall.id);
                });

            return () => {
                if (socketKey) socket.stop(socketKey);
            }

        }

    }, [props.selectedCall])

    return (
        <>
            <div className="float-audio-player">

                <div className="d-flex align-items-center px-6 py-4">
                    <Avatar avatarUrl={props.selectedCall?.sourceAgent?.country ? props.selectedCall.sourceAgent.country.flagSquare : props.selectedCall?.sourceAgent?.avatarUrl} 
                        width={20} />
                    <div className="ms-2">
                        {
                            props.selectedCall?.sourceAgent?.id ?
                            <div className="fs-7 text-white">
                                {props.selectedCall?.sourceAgent?.name}
                                <span className="fs-9 text-white ms-1">
                                    ({props.selectedCall.sourceAgent?.number})
                                </span>
                            </div>
                            :
                            <div className="fs-7 text-white">
                                {props.selectedCall?.sourceAgent?.number}
                                {
                                    !props.selectedCall.sourceAgent?.country &&
                                    <span className="fs-9 text-white ms-1">
                                        ({props.selectedCall.sourceAgent?.name})
                                    </span>
                                }
                            </div>
                        }
                    </div>
                    <div className="text-center mx-3">
                        {
                            call && 
                            <div className="badge badge-danger fs-8">
                                {
                                    props.selectedCall.callType == CallTypes.Incoming &&
                                    <Icon icon='phone-arrow-down-left' type='solid' title="Incoming Call" size={12} className="me-2" />
                                }
                                {
                                    props.selectedCall.callType == CallTypes.Outgoing &&
                                    <Icon icon='phone-arrow-up-right' type='solid' title="Outgoing Call" size={12} className="me-2" />
                                }
                                {
                                    props.selectedCall.callType == CallTypes.Local &&
                                    <Icon icon='user-headset' type='solid' title="Local Call" size={12} className="me-2" />
                                }
                                <Timer totalSeconds={call?.talkingSeconds} width={40} />
                            </div>
                        }
                    </div>
                    <Avatar avatarUrl={props.selectedCall?.destinationAgent?.country ? props.selectedCall.destinationAgent.country.flagSquare : props.selectedCall?.destinationAgent?.avatarUrl} 
                        width={20} />
                    <div className="ms-2">
                        {
                            props.selectedCall?.destinationAgent?.id ?
                            <div className="fs-7 text-white">
                                {props.selectedCall?.destinationAgent?.name}
                                <span className="fs-8 text-white ms-1">
                                    ({props.selectedCall?.destinationAgent?.number})
                                </span>
                            </div>
                            :
                            <div className="fs-7 text-white">
                                {props.selectedCall?.destinationAgent?.number}
                                {
                                    !props.selectedCall.destinationAgent?.country &&
                                    <span className="fs-9 text-white ms-1">
                                        ({props.selectedCall.destinationAgent?.name})
                                    </span>
                                }
                            </div>
                        }
                    </div>

                    <div className="ms-5">
                        <div id={"line-"+chanSpyLine?.LineNumber+"-monitoring"} className='d-flex align-items-center justify-content-center'>
                            <span className='me-2'>
                                <i className="fa-solid fa-volume-high"></i>
                            </span>
                            <span className="meterContainer" title="Speaker Levels">
                                <span id={"line-"+chanSpyLine?.LineNumber+"-Speaker"} className="meterLevel"></span>
                            </span>
                        </div>
                    </div>
                    
                    {
                        (whisper || barge) &&
                        <div className="ms-5">
                            <div className='d-flex align-items-center justify-content-center'>
                                <span className='me-2'>
                                    <i className="fa-solid fa-microphone"></i>
                                </span> 
                                <span className="meterContainer" title="Microphone Levels">
                                    <span id={"line-"+globalState.ChanSpyLine?.LineNumber+"-Mic"} className="meterLevel"></span>
                                </span>
                            </div>
                        </div>
                    }
                    
                    <div className="ms-auto d-flex align-items-center">
                        <div className="me-5 text-warning d-flex align-items-center">
                            {
                                connectionStatus == ConnectionStatus.Connected && registererState == RegistererStats.Registered && 
                                <Icon icon="circle" type="solid" size={6} className="text-success" />
                            }
                            {
                                connectionStatus == ConnectionStatus.Connected && registererState != RegistererStats.Registered && 
                                <Icon icon="circle" type="solid" size={6} className="text-warning" />
                            }
                            {
                                connectionStatus != ConnectionStatus.Connected &&
                                <Icon icon="circle" type="solid" size={6} className="text-danger" />
                            }
                            
                        </div>
                        <a href="#" className="me-4" title='Talk to Agent'
                            onClick={e=> {
                                e.preventDefault();
                                window.endSession(globalState.ChanSpyLine?.LineNumber);
                                bargeSet(false);
                                if (whisper) {
                                    whisperSet(false);
                                    var li = window.DialByLine('audio', undefined, '551' + props.selectedCall.extNumber);
                                    setChanSpyLine(li);
                                }
                                else {
                                    whisperSet(true);
                                    var li = window.DialByLine('audio', undefined, '552' + props.selectedCall.extNumber);
                                    setChanSpyLine(li);
                                }
                            }}>
                            <Icon icon='user-headset' type="solid" color={whisper ? "#7070ff" : "#bbbbc9"} size={14} />
                        </a>
                        <a href="#" className="me-4" title='Join the Call'
                            onClick={e=> {
                                e.preventDefault();
                                window.endSession(globalState.ChanSpyLine?.LineNumber);
                                whisperSet(false);
                                if (barge) {
                                    bargeSet(false);
                                    var li = window.DialByLine('audio', undefined, '551' + props.selectedCall.extNumber);
                                    setChanSpyLine(li);
                                }
                                else {
                                    bargeSet(true);
                                    var li = window.DialByLine('audio', undefined, '553' + props.selectedCall.extNumber);
                                    setChanSpyLine(li);
                                }
                                
                                
                            }}>
                            <Icon icon='phone-volume' type="solid" color={barge ? "#7070ff" : "#bbbbc9"} size={14} />
                        </a>
                        <a href="#" className="me-4" title='Hang Up'
                            onClick={e=> {
                                e.preventDefault();
                                setHangupChannel(props.selectedCall.id);
                                window.openModal("modal-hangup");
                            }}>
                            <Icon icon='phone-slash' type="solid" color="#bbbbc9" size={14} />
                        </a>
                        <a href="#" title='Close'
                            onClick={e=> {
                                e.preventDefault();
                                window.endSessions();
                                clearCallToListen();
                            }}>
                            <Icon type="solid" icon="times" color="#bbbbc9" size={16} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FloatCallSpy;