import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Avatar from "../../../components/base/Avatar";
import FlexRow from "../../../components/base/FlexRow";
import ChatContactItem from "../models/ChatContactItem";
import { getUser, isDemo, setChatList, useChatList, useGlobal, useHub, useSocket } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import ChatMessage from "../models/ChatMessage";
import ChatMessageItem from "./ChatMessageItem";
import { CompactJob, CompactUser } from "../../../core/CompactModels";
import Icon from "../../../components/base/Icon";
import ChatContactContextMenu from "./ChatContactContextMenu";
import Indicator from "../../../components/base/Indicator";
import IconButton from "../../../components/base/IconButton";
import { v4 as uuidv4 } from 'uuid';
import ChatAvatarSpan from "./ChatAvatarSpan";
import { toastError } from "../../../components/base/Toast";
import { ChatEnvironments } from "../../../core/Enums";

interface IProps {
    chat?: ChatContactItem
    chatId?: string
    inDrawer?: boolean
    jobId?: string
    onSearch?: (ch: ChatContactItem) => void
    onFilterByParticipant?: (p: CompactUser) => void
    onFilterByJob?: (p: CompactJob) => void
    onRead?: () => void
    onPin?: () => void
    onUnpin?: () => void
    onUnarchive?: () => void
    onAssignMessageToJob?: (msg: ChatMessage) => void
    onMessageSending?: (msg: ChatMessage) => void
}

export default function ChatBox(props: IProps) {

    let chatTextArea: HTMLTextAreaElement | null;
    let chatAttachmentInput: HTMLInputElement | null;


    const hub = useHub();
    const [globalState] = useGlobal()
    const socket = useSocket();
    const user = getUser();



    const chatContainer = useRef<HTMLDivElement>(null)




    const [scrollToBottom, scrollToBottomSet] = useState<boolean>(false)
    const [typingUsers, typingUsersSet] = useState<CompactUser[]>([]);
    const [appVisible, appVisibleSet] = useState<boolean>(document.visibilityState == "visible");
    const [boxHeight, boxHeightSet] = useState<number>();
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400;
        if (w < 500) {
            h = 250;
        }
        else {
            if (props.inDrawer) {
                h = h - ($("#kt_drawer_job_header").outerHeight() ?? 50);
                h = h - ($("#kt_drawer_job_tabs").outerHeight() ?? 50);
            }
            else {
                h = h - ($("#kt_header").outerHeight() ?? 50);
                h = h - ($("#kt_toolbar").outerHeight() ?? 50);
                h = h - 40;
            }

            h = h - ($("#kt_chat_messenger_header").outerHeight() ?? 50);
            h = h - ($("#kt_chat_messenger_footer").outerHeight() ?? 50);

        }
        boxHeightSet(h);
    }

    const onVisibilityChanged = () => {
        appVisibleSet(document.visibilityState == "visible");
    }

    useEffect(() => {

        fixSizes();
        window.addEventListener("resize", fixSizes);
        window.addEventListener("visibilitychange", onVisibilityChanged);

        return () => {
            window.removeEventListener("resize", fixSizes);
            window.removeEventListener("visibilitychange", onVisibilityChanged);
        }

    }, [])

    const [scrolling, scrollingSet] = useState<boolean>(false)
    const [claiming, claimingSet] = useState<boolean>(false)
    const [loading, loadingSet] = useState<boolean>(false)
    const [loaded, loadedSet] = useState<boolean>(false)
    const [messages, messagesSet] = useState<ChatMessage[]>([])
    const [scrollHeight, scrollHeightSet] = useState<number>(0)
    const [noEarlierMessages, noEarlierMessagesSet] = useState<boolean>(false)
    const [earlierMessagesLoaded, earlierMessagesLoadedSet] = useState<boolean>(false)
    const [loadingEarlierMessages, loadingEarlierMessagesSet] = useState<boolean>(false)

    const fetchMessages = () => {

        loadingSet(true)
        loadingEarlierMessagesSet(false)
        noEarlierMessagesSet(false)
        var q = "";
        if (props.chat?.searchMsgItem) {
            q = "?searchMsgId=" + props.chat.id;
        }
        hub.Get(API_ROUTES.ChatMessages + props.chatId + q, res => {
            if (res.success) {
                messagesSet(res.data)
                loadingSet(false)
                loadedSet(true)
                noEarlierMessagesSet(false)
            }
        })

    }

    const fetchEarlierMessages = () => {
        if (loading) return
        if (noEarlierMessages) return
        if (loadingEarlierMessages) return
        loadingEarlierMessagesSet(true)
        scrollHeightSet(chatContainer.current?.scrollHeight ?? 0)
        var q = "?lastMessageId=" + messages[0].id;
        hub.Get(API_ROUTES.ChatMessages + props.chatId + q, res => {
            if (res.success && res.data.length > 0) {
                messagesSet(msg => [...res.data, ...msg])
                earlierMessagesLoadedSet(true)
            }
            else {
                noEarlierMessagesSet(true)
                loadingEarlierMessagesSet(false)

            }
        })
    }

    useEffect(() => {
        if (messages && earlierMessagesLoaded && chatContainer.current) {
            const newScrollHeight = chatContainer.current!.scrollHeight;
            chatContainer.current.scrollTop = newScrollHeight - scrollHeight;
            loadingEarlierMessagesSet(false)
            earlierMessagesLoadedSet(false)
            loadingSet(false)
        }
    }, [messages, earlierMessagesLoaded])

    const [newMessage, newMessageSet] = useState<ChatMessage>();
    useEffect(() => {

        if (newMessage && user?.id) {
            if (newMessage.senderUserId == user.id) return;
            if (newMessage.chatId != props.chatId) return;
            var msgs = messages ?? [];
            msgs.push(newMessage);
            messagesSet(m => [...msgs]);
        }

    }, [newMessage, user])

    // useEffect(() => {
    //     //if (loaded) return;
    //     if (messages && messages.length > 0) {
    //         console.log("let's scroll to bottom!", chatContainer.current?.scrollHeight)
    //         if (chatContainer.current) {
    //             chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    //         }
    //     }

    // }, [messages, chatContainer.current?.scrollHeight])


    useEffect(() => {

        if (!chatContainer.current) return;

        if (props.chat?.searchMsgItem) return;
        if (scrolling) return;
        if (claiming) return;

        const observer = new MutationObserver(() => {
            if (chatContainer.current) {
                chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
            }
        });

        observer.observe(chatContainer.current, {
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();

    }, [props.chat, scrolling, claiming])

    useEffect(() => {
        if (messages.length > 0 && globalState.gotoMessageId) {
            gotoMessage(globalState.gotoMessageId);
        }
    }, [globalState.gotoMessageId])

    useEffect(() => {
        if (props.chat?.id) {

            fetchMessages();
            chatTextArea?.focus();

            // if (props.chat.searchMsgItem && chatContainer.current) {
            //     const observer = new MutationObserver(() => {

            //         observer.disconnect();
            //     });
            //     observer.observe(chatContainer.current, {
            //         childList: true,
            //         subtree: true,
            //     });
            // }


            if (!props.chat?.searchMsgItem) {

                var socketKey = socket.start(
                    skt => {
                        skt.invoke("StartListenToChat", props.chatId)
                            .then(() => {

                                skt.on("SendChatMessage-" + props.chatId, msg => newMessageSet(msg));

                                skt.on("StartTyping-" + props.chatId, data => {
                                    if (data.id == user?.id) return;
                                    var tu = typingUsers ?? [];
                                    var exists = false;
                                    tu.forEach(t => {
                                        if (t.id == data.id) {
                                            exists = true;
                                        }
                                    });
                                    if (exists) return;
                                    tu.push(data);
                                    typingUsersSet(t => [...tu]);
                                });

                                skt.on("StopTyping-" + props.chatId, data => {
                                    var index = -1;
                                    var tu = typingUsers ?? [];
                                    for (var i = 0; i < tu.length; i++) {
                                        if (tu[i].id == data.id) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    tu.splice(index, 1);
                                    typingUsersSet(t => [...tu]);
                                });

                            });

                    }, skt => {
                        skt.invoke("StopListenToChat", props.chatId)
                            .then(() => {
                                skt.off("SendChatMessage-" + props.chatId);
                                skt.off("StartTyping-" + props.chatId);
                                skt.off("StopTyping-" + props.chatId);
                            });
                    });


                return () => {
                    socket.stop(socketKey);
                }
            }
        }

    }, [props.chat?.id])

    useLayoutEffect(() => {

        if (props.chat?.searchMsgItem && messages.length > 0 && chatContainer.current) {
            requestAnimationFrame(() => {
                gotoMessage(props.chat!.id!)
            })
        }

    }, [messages, props.chat])



    // useEffect(() => {
    //     if (messages.length > 0 && !loading) {

    //         if (props.chat?.id && props.chat.searchMsgItem) {
    //             gotoMessage(props.chat?.id)
    //         }
    //         else {
    //             if (chatContainer.current) {
    //                 chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    //             }
    //             scrollToBottomSet(false)
    //         }

    //         window.dataTableMenu()
    //     }
    // }, [messages, scrollToBottom, loading])

    useEffect(() => {
        if (messages.length > 0 && !loading) {
            window.dataTableMenu()
        }
    }, [messages, loading])

    const [typingStarted, typingStartedSet] = useState<boolean>(false);
    useEffect(() => {
        if (typingStarted) {
            socket.get(skt => {
                skt.invoke("StartTyping", props.chatId, {
                    id: user?.id,
                    firstName: user?.firstName
                })
            })

            setTimeout(() => {
                socket.get(skt => {
                    skt.invoke("StopTyping", props.chatId, {
                        id: user?.id,
                        firstName: user?.firstName
                    })
                })
                typingStartedSet(false);
            }, 2000);
        }
    }, [typingStarted])

    // useEffect(() => {
    //     if (messages && messages.length > 0 && globalState.gotoMessageId) {
    //         setTimeout(() => {
    //             if (messages.some(f => f.id == globalState.gotoMessageId)) {
    //                 console.log("msg foundddddddddd")
    //                 gotoMessage(globalState.gotoMessageId!)
    //             }
    //             else {
    //                 console.log("Not existsssss")
    //             }

    //         }, 50);
    //     }
    // }, [globalState.gotoMessageId, messages])

    const gotoMessage = (id: string) => {
        var m = document.getElementById("chatmsg-" + id);
        m?.scrollIntoView({
            block: "center"
        });

        var n = $("#chatmsg-" + id)
        n.find(".chat-message-text").addClass("job-note-blink");
        setTimeout(() => {
            n.find(".chat-message-text").addClass("job-note-blink-off");
            n.find(".chat-message-text").removeClass("job-note-blink");
        }, 500);
    }


    const [text, textSet] = useState<string>()
    const send = (uuid: string) => {
        if (!text || text == "") return;
        let msgs = messages ?? [];
        let msg = {
            chatId: props.chatId,
            senderUserId: user?.id,
            senderUser: {
                avatarUrl: user?.avatarUrl,
                firstName: user?.firstName,
                fullName: user?.fullName,
                id: user?.id
            },
            isMe: true,
            isTech: user?.id == props.chat?.userId,
            text: text,
            sending: true,
            jobId: props.jobId,
            uuid: uuid
        };
        msgs.push(msg)

        messagesSet(m => [...msgs]);
        textSet("");
        rowsSet(1);
        chatTextArea?.focus();
        if (props.onMessageSending) props.onMessageSending(msg)
    }

    const [rows, rowsSet] = useState<number>(1)
    useEffect(() => {
        var r = text?.split("\n").length ?? 1
        rowsSet(r)
    }, [text])

    useEffect(() => {
        fixSizes()
    }, [rows])


    return (
        <div className={`card ${(props.inDrawer ? "w-100" : "")}`}>

            {
                props.inDrawer ?
                    <div className="card-header d-none" id="kt_chat_messenger_header"
                        style={{
                            minHeight: 40,
                            height: 40
                        }}>
                        <div className="card-title">
                            <FlexRow>
                                {
                                    props.chat?.tech?.avatarUrl ?
                                        <div className="symbol symbol-25px symbol-circle">
                                            <img alt={props.chat.tech?.fullName} src={props.chat.tech?.avatarUrl} />
                                        </div>
                                        :
                                        <div className="symbol symbol-25px symbol-circle">
                                            {/* <span className="symbol-label bg-light-danger text-danger fs-4 fw-bolder">{props.chat?.title?.substring(0, 1).toUpperCase()}</span> */}
                                            <ChatAvatarSpan item={props.chat} />
                                        </div>
                                }
                                <div className="d-flex justify-content-center flex-column ms-2 me-3">
                                    <a className="fs-7 text-gray-900 me-1 lh-1">
                                        {
                                            isDemo() ? "Tech " + props.chat?.title?.substring(0, 2).toUpperCase() : props.chat?.title
                                        }
                                    </a>
                                </div>
                            </FlexRow>
                        </div>
                        <div className="card-toolbar">

                        </div>
                    </div>
                    :
                    <div className="card-header" id="kt_chat_messenger_header">
                        <div className="card-title">
                            <FlexRow>
                                <div className="symbol symbol-40px symbol-circle">
                                    {
                                        props.chat?.tech?.avatarUrl ?
                                            <img alt={props.chat.tech?.fullName} src={props.chat.tech?.avatarUrl} />
                                            :
                                            <ChatAvatarSpan item={props.chat} />
                                    }

                                    {
                                        props.chat?.environment == ChatEnvironments.App &&
                                        <div className="symbol-badge text-center start-100 top-100 ms-n2 mt-n2">
                                            <Icon icon="mobile-screen" doutone className="text-primary" size={16} />
                                        </div>
                                    }
                                </div>

                                <div className="d-flex flex-column ms-2 me-3 mt-2">
                                    <a className="fs-6 fw-bolder text-gray-900 me-1 lh-1">
                                        {
                                            isDemo() ? "Tech " + props.chat?.title?.substring(0, 2).toUpperCase() : props.chat?.title
                                        }
                                    </a>
                                    {
                                        typingUsers.length > 0 ?
                                            <FlexRow className="fs-8 text-muted">
                                                {
                                                    typingUsers.map((t, k) =>
                                                        <span key={k} className="chat-typing-user-name">
                                                            {t.firstName}
                                                            {(k + 1) < typingUsers.length && <>,</>}
                                                        </span>)
                                                }

                                                <span>is typing...</span>
                                            </FlexRow>
                                            :
                                            <div className="fs-8">
                                                &nbsp;
                                            </div>
                                    }

                                </div>
                            </FlexRow>
                        </div>
                        <div className="card-toolbar">
                            {/* {
                        participants && participants.length > 1 &&
                        <div className="symbol-group symbol-hover">
                            {
                                participants.map((p, k) => 
                                <div key={k} className="symbol symbol-30px symbol-circle" title={p.fullName + (p.id == user?.id ? " (Me)" : "")}
                                    onClick={e=> {
                                        e.preventDefault();
                                        if(props.onFilterByParticipant) props.onFilterByParticipant(p);
                                    }}>
                                    {
                                        p.avatarUrl && p.avatarUrl != "/assets/media/avatars/blank.png" ? <img src={p.avatarUrl} />
                                        : <span className="symbol-label bg-light-danger text-danger fs-6">{p.firstName?.substring(0,1).toUpperCase()}</span>
                                    }
                                </div>
                                )
                            }
                        </div>
                    } */}

                            {/* <button type="button" className="btn btn-sm btn-icon btn-active-light-primary"
                                onClick={e => {
                                    if (props.chat && props.onSearch) props.onSearch(props.chat);
                                }}>
                                <i className="bi bi-search fs-2"></i>
                            </button> */}

                            <div className="me-n3">
                                <button className="btn btn-sm btn-icon btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i className="bi bi-three-dots fs-2"></i>
                                </button>
                                <ChatContactContextMenu chatId={props.chatId}
                                    chat={props.chat}
                                    onPin={props.onPin}
                                    onUnpin={props.onUnpin}
                                    onUnarchive={props.onUnarchive} />
                            </div>


                        </div>
                    </div>

            }

            <div className="card-body chat_messenger_body p-0" id="kt_chat_messenger_body">
                <div id="chat-messages-container" ref={chatContainer}
                    className="scroll-y px-5 py-2"
                    onScroll={e => {
                        if (e.currentTarget.scrollTop < 20) {
                            fetchEarlierMessages()
                        }

                        if ((e.currentTarget.scrollTop + e.currentTarget.clientHeight + 1400) < e.currentTarget.scrollHeight) {
                            scrollingSet(true)
                        }
                        else scrollingSet(false)

                    }}
                    style={{
                        height: boxHeight
                    }}>

                    {
                        loadingEarlierMessages && !loading &&
                        <div className="mt-5 mb-10">
                            <Indicator text="Loading Earlier Messages..." />
                        </div>
                    }

                    {
                        loading ?
                            <div className="mt-20">
                                <Indicator text="Loading Chat..." />
                            </div>
                            :
                            <>
                                {
                                    messages.map((msg, k) =>
                                        <ChatMessageItem key={msg.uuid} message={msg} lastSender={k > 0 ? messages[k - 1].senderUser : undefined}
                                            onAssignToJob={props.onAssignMessageToJob} onFilterByJob={props.onFilterByJob}
                                            onClaiming={e => claimingSet(true)}
                                            onClaimed={e => {
                                                setTimeout(() => {
                                                    claimingSet(false)
                                                }, 500);
                                            }} />
                                    )
                                }

                            </>
                    }



                </div>

                {
                    scrolling &&
                    <IconButton className="btn-chat-to-end btn-active" icon="arrow-down" size={26} gap={24}
                        onClick={e => {
                            if (chatContainer.current) {
                                chatContainer.current.scrollTo({
                                    top: chatContainer.current.scrollHeight,
                                    behavior: "smooth"
                                })
                            }
                        }} />
                }
                {
                    scrolling && (props.chat?.unreadCount ?? 0) > 0 &&
                    <div className="btn-chat-to-end-newmsg">
                        {props.chat?.unreadCount}
                    </div>
                }

            </div>
            <div className="card-footer pt-2 px-2" id="kt_chat_messenger_footer">
                <FlexRow>
                    <div className="flex-grow-1 me-2">
                        <textarea id="txt-chatbox-message" ref={e => chatTextArea = e} className="form-control form-control-flush mb-3" data-gramm="false"
                            rows={rows} placeholder="Type a message" value={text} onChange={e => textSet(e.target.value)}
                            style={{
                                resize: "none"
                            }}
                            onKeyDown={e => {
                                typingStartedSet(true);
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    var txt = $("#txt-chatbox-message");
                                    if (e.ctrlKey || e.shiftKey || e.altKey) {
                                        const textarea = e.currentTarget;
                                        const cursorPosition = textarea.selectionStart;
                                        const currentText = text ?? "";
                                        const newText = currentText.slice(0, cursorPosition) + "\n" + currentText.slice(cursorPosition);
                                        textSet(newText);
                                        setTimeout(() => {
                                            textarea.selectionStart = textarea.selectionEnd = cursorPosition + 1;
                                        }, 10);
                                    }
                                    else {
                                        send(uuidv4())
                                    }
                                }
                            }}
                        ></textarea>
                    </div>
                    <FlexRow>

                        <button className="btn btn-primary btn-sm btn-icon me-1" type="button" onClick={e => send(uuidv4())}>
                            <Icon icon="send" />
                        </button>
                        <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button"
                            data-bs-toggle="tooltip" title="Send File"
                            onClick={e => {
                                e.preventDefault();
                                chatAttachmentInput?.click();
                            }}>
                            <Icon icon="paperclip-vertical" className="" />
                        </button>

                    </FlexRow>

                    <div className="d-none">
                        <input ref={e => chatAttachmentInput = e} name="file" type="file" multiple
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={e => {
                                if (e.target.files) {
                                    var files = Array.from(e.target.files);
                                    var msgs = messages ?? [];
                                    files.forEach(file => {
                                        msgs.push({
                                            media: {
                                                fileName: file.name
                                            },
                                            fileToUpload: file,
                                            chatId: props.chatId,
                                            senderUserId: user?.id,
                                            senderUser: {
                                                avatarUrl: user?.avatarUrl,
                                                firstName: user?.firstName,
                                                fullName: user?.fullName,
                                                id: user?.id,
                                                username: user?.username
                                            },
                                            id: props.chatId,
                                            uploading: true,
                                            uuid: uuidv4()
                                        })
                                    });
                                    messagesSet(m => [...msgs]);
                                    if (chatAttachmentInput) chatAttachmentInput.value = "";
                                }
                            }} />
                    </div>
                </FlexRow>
            </div>
        </div>
    )
}