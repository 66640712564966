import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import Modal from "../../../../../components/base/Modal";
import { DataTable, DataTableField } from "../../../../../components/data/DataTable";
import { API_ROUTES } from "../../../../../core/Constants";
import { JobsChartSegments } from "../../../../../core/Enums";
import { closeReportJobsList, toPrettyDate } from "../../../../../core/Global";
import Job from "../../models/Job";
import JobReportListModel from "../../models/JobReportListModel";
import { FilterParam } from "../../../../../models/BrowseOptions";

interface IProps {
    list: JobReportListModel
}
export default function JobsListModal(props: IProps) {

    const JobIdColumn = (data: Job) => (
        <div>
            <a href={"/crm/jobs/overview/" + data.id} target="_blank">
                #{data.jobId}
            </a>
        </div>
    )

    const ClientColumn = (data: Job) => (
        <div>
            <div>
                {data.fullName}
            </div>
            <FlexRow>
                <Icon icon="phone-rotary" className="text-muted me-1" />
                <span className="fs-8 text-muted">
                    {data.phone?.phoneFriendly}
                </span>
            </FlexRow>
        </div>
    )

    const AddressColumn = (data: Job) => (
        <div>
            {data.address?.title}
        </div>
    )

    const StatusColumn = (data: Job) => (
        <FlexRow>
            <Icon icon="circle" type="solid" color={data.jobStatus?.color} />
            <span className="ms-1">{data.jobStatus?.name}</span>
        </FlexRow>
    )

    const TechColumn = (data: Job) => (
        <div>
            {data.tech?.fullName}
        </div>
    )

    const AppointmentColumn = (data: Job) => (
        <div className="ms-3">
            <div className="fs-7 fw-bold">
                {data.appointmentDate}
            </div>
            <div className="fs-8">
                {data.appointmentTimeFrom} - {data.appointmentTimeTo}
            </div>
        </div>
    )

    const CreatedColumn = (data: Job) => (
        <div>
            <div className='d-flex align-items-center'>
                {toPrettyDate(data.createdAt)}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    const LastUpdateColumn = (data: Job) => (
        <div>
            <div className='d-flex align-items-center'>
                {toPrettyDate(data.lastStatusUpdatedAt, data.timezoneOffset)}
            </div>
            <FlexRow className='fs-8 mt-1'>
                <div>
                    <Icon icon="clock" className="me-2" size={10} type="solid" />
                </div>
                <div className="fs-8 limited-text w-75px">
                    {data.timezone ?? "UTC"}
                </div>
            </FlexRow>

        </div>
    )

    const subtitle = () => {
        var res = "";
        if (props.list.segment == JobsChartSegments.Dispatchers) res = "Dispatcher";
        else if (props.list.segment == JobsChartSegments.Technician) res = "Technician";
        else if (props.list.segment == JobsChartSegments.Metro) res = "Metro";
        else if (props.list.segment == JobsChartSegments.JobSource) res = "Job Source";
        else if (props.list.segment == JobsChartSegments.JobType) res = "Job Type";

        res += " | " + props.list.name + " | ";

        if (props.list.status) res += props.list.status.name;
        else res += "All Jobs";

        return res;
    }

    const [filter, filterSet] = useState<FilterParam[]>()
    useEffect(() => {
        var fl: FilterParam[] = [];

        fl.push({
            name: "IncludeArchived",
            value: "True"
        })

        if (props.list.segment == JobsChartSegments.Dispatchers && props.list.segmentId && props.list.segmentId != "total") {
            fl.push({
                name: "agents",
                value: props.list.segmentId
            })
        }

        if (props.list.segment == JobsChartSegments.Technician && props.list.segmentId) {

            if (props.list.segmentId == "total") {
                fl.push({
                    name: "HasTech",
                    value: "True"
                })
            }
            else {
                fl.push({
                    name: "Techs",
                    value: props.list.segmentId
                })
            }

        }

        if (props.list.segment == JobsChartSegments.JobSource && props.list.segmentId && props.list.segmentId != "total") {
            fl.push({
                name: "JobSources",
                value: props.list.segmentId
            })
        }

        if (props.list.segment == JobsChartSegments.JobType && props.list.segmentId && props.list.segmentId != "total") {
            fl.push({
                name: "JobTypes",
                value: props.list.segmentId
            })
        }

        if (props.list.segment == JobsChartSegments.Metro && props.list.segmentId) {

            if (props.list.segmentId == "total") {
                fl.push({
                    name: "HasMetro",
                    value: "True"
                })
            }
            else {
                fl.push({
                    name: "Metros",
                    value: props.list.segmentId
                })
            }
        }


        if (props.list.dateId) {
            fl.push({
                name: "DateId",
                value: props.list.dateId
            })
        }

        if (props.list.startDate) {
            fl.push({
                name: "startDate",
                value: props.list.startDate
            })
        }

        if (props.list.toDate) {

            fl.push({
                name: "toDate",
                value: props.list.toDate
            })
        }

        if (props.list.timezone) {
            fl.push({
                name: "Timezone",
                value: props.list.timezone
            })
        }

        if (props.list.status) {
            fl.push({
                name: "Statuses",
                value: props.list.status.id
            })
        }

        filterSet(f => [...fl])

    }, [props.list])

    return (
        <Modal id="modal-viewjob-list"
            open visible
            title="Jobs List"
            subtitle={subtitle()}
            size="XLarge"
            onClose={closeReportJobsList}
        >
            <div>
                {
                    filter &&
                    <DataTable isChild
                        objectName="Job"
                        preventCreate noHeader noAction noFilter
                        apiRoute={API_ROUTES.Jobs}
                        defaultFilters={filter}
                        defaultSize={10}
                        parent="modal-viewjob-list-modal-body"
                    >

                        <DataTableField title="Job ID" width={70}>
                            <JobIdColumn />
                        </DataTableField>
                        <DataTableField title="Client" width={150}>
                            <ClientColumn />
                        </DataTableField>
                        <DataTableField title="Address" maxWidth={200}>
                            <AddressColumn />
                        </DataTableField>

                        <DataTableField title="Status">
                            <StatusColumn />
                        </DataTableField>

                        <DataTableField title="Job Type" dataValueName="jobType" maxWidth={150} />
                        <DataTableField title="Job Source" dataValueName="jobSource" maxWidth={150} />

                        <DataTableField title="Tech" maxWidth={150}>
                            <TechColumn />
                        </DataTableField>

                        <DataTableField title="Appointment" maxWidth={150}>
                            <AppointmentColumn />
                        </DataTableField>

                        <DataTableField title="Last Update" maxWidth={150}>
                            <LastUpdateColumn />
                        </DataTableField>
                    </DataTable>
                }

            </div>
        </Modal>
    )
}