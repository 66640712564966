import { useEffect, useState } from "react"
import Job from "../../crm/jobs/models/Job"
import { toPrettyDate, useHub } from "../../../core/Global"
import { API_ROUTES } from "../../../core/Constants"
import Indicator from "../../../components/base/Indicator"
import Icon from "../../../components/base/Icon"
import { useParams } from "react-router-dom"
import { AccountPreferencesJobSendSettings } from "../../../models/AccountPreferences"
import { toastError } from "../../../components/base/Toast"
import FlexRow from "../../../components/base/FlexRow"





export default function JobAcceptView() {

    const { token } = useParams()
    const hub = useHub()

    const [sendSettings, sendSettingsSet] = useState<AccountPreferencesJobSendSettings>()
    const [proxyNumber, proxyNumberSet] = useState<string>()
    const [loading, loadingSet] = useState<boolean>(true)
    const [expired, expiredSet] = useState<boolean>(false)
    const [company, companySet] = useState<string>()
    const [job, jobSet] = useState<Job>()

    const [tel, telSet] = useState<string>()

    const [accepting, acceptingSet] = useState<boolean>(false)
    const [rejecting, rejectingSet] = useState<boolean>(false)

    const [accepted, acceptedSet] = useState<boolean>(false)
    const [rejected, rejectedSet] = useState<boolean>(false)

    const [note, noteSet] = useState<string>()


    useEffect(() => {

        if (token) {
            hub.Post("/base/public/getJobToAcceept", {
                token: token
            }, res => {
                if (res.success) {
                    jobSet(res.data.job)
                    proxyNumberSet(res.data.proxyNumber)
                    sendSettingsSet(res.data.sendSettings)
                    companySet(res.data.company)
                    acceptedSet(res.data.accepted)
                    noteSet(res.data.note)
                }
                else {
                    expiredSet(true)
                }
                loadingSet(false)
            })
        }

    }, [token])

    useEffect(() => {
        if (sendSettings?.clientPhone) {
            if (sendSettings.directNumber) {
                telSet(job?.phoneRaw)
            }
            else telSet(proxyNumber)
        }
        else telSet(undefined)
    }, [sendSettings, job, proxyNumber])

    return (
        <div>
            {
                loading &&
                <div>
                    <Indicator text="Loading" />
                </div>
            }

            {
                !loading && expired &&
                <div>
                    <div className="text-center">
                        <Icon icon="times-circle" className="text-danger" size={84} type="thin" />
                    </div>
                    <div className="text-danger text-center fs-4 mt-10">
                        Link is Expired!
                    </div>
                </div>
            }

            {
                rejected &&
                <div>
                    <div className="text-center">
                        <Icon icon="times-circle" className="text-danger" size={84} type="thin" />
                    </div>
                    <div className="text-danger text-center fs-4 mt-10">
                        You rejected the job!
                    </div>
                </div>
            }



            {
                !loading && job && !rejected &&
                <div className="position-relative">

                    {
                        accepted &&
                        <FlexRow className="alert alert-success mb-5">
                            <Icon icon="check-circle" className="text-success" size={18} />
                            <span className="ms-2">You have accepted the job!</span>
                        </FlexRow>
                    }


                    {
                        sendSettings?.companyName &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="briefcase" size={11} className="me-2" type="solid" />
                                Company
                            </div>
                            <div className="ps-5 fs-6">
                                {company}
                            </div>
                        </div>
                    }

                    {
                        sendSettings?.clientName &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="user" size={11} className="me-2" type="solid" />
                                Name
                            </div>
                            <div className="ps-5 fs-6">
                                {job.fullName}
                            </div>
                        </div>
                    }

                    {
                        sendSettings?.clientPhone &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="phone" size={11} className="me-2" type="solid" />
                                Phone
                            </div>
                            <div className="ps-5 fs-6">
                                {
                                    sendSettings.directNumber ?
                                        <>{job.phone?.phoneFriendly}</>
                                        :
                                        <>{proxyNumber}</>
                                }
                            </div>
                        </div>
                    }

                    {
                        sendSettings?.address &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="location-dot" size={11} className="me-2" type="solid" />
                                Address
                            </div>
                            <div className="ps-5 fs-6">
                                {job.address?.title}
                            </div>
                        </div>
                    }

                    {
                        sendSettings?.jobType &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="tag" size={11} className="me-2" type="solid" />
                                Job Type
                            </div>
                            <div className="ps-5 fs-6">
                                {job.jobType}
                            </div>
                        </div>
                    }

                    {
                        sendSettings?.appointment &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="calendar" size={11} className="me-2" type="solid" />
                                Appointment
                            </div>
                            {
                                job.appointmentDate ?
                                    <div className="ps-5 fs-6">
                                        {`${job.appointmentDate}, ${job.appointmentTimeFrom}-${job.appointmentTimeTo}`}
                                    </div>
                                    :
                                    <div className="ps-5 fs-6">
                                        -
                                    </div>
                            }

                        </div>
                    }

                    {
                        sendSettings?.jobDetails &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="file-text" size={11} className="me-2" type="solid" />
                                Job Details
                            </div>
                            <div className="ps-5 fs-6">
                                {job.jobDetails ?? "-"}
                            </div>
                        </div>
                    }

                    {
                        note && note.length > 0 &&
                        <div className="mb-4">
                            <div className="text-muted fs-7">
                                <Icon icon="file-text" size={11} className="me-2" type="solid" />
                                Note
                            </div>
                            <div className="ps-5 fs-6">
                                {note}
                            </div>
                        </div>
                    }




                    {
                        accepted ?
                            <div className="mt-20">
                                {
                                    tel &&
                                    <a className="btn btn-primary w-100 mb-5"
                                        href={`tel:${tel}`}>
                                        <span className="ms-1">Call Client</span>
                                    </a>
                                }

                                {
                                    sendSettings?.address && job.address?.position &&
                                    <a className="btn btn-google w-100 mb-5" target="_blank"
                                        href={`https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${job.address.position.lat},${job.address.position.lng}`}>
                                        <span className="ms-1">Get Direction</span>
                                    </a>
                                }

                                {
                                    sendSettings?.address && !job.address?.position &&
                                    <a className="btn btn-google w-100 mb-5" target="_blank"
                                        href={`https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${job.address?.title}`}>
                                        <span className="ms-1">Get Direction</span>
                                    </a>
                                }


                            </div>
                            :
                            <div className="mt-20">
                                <button className="btn btn-success w-100 mb-5" disabled={accepting || rejecting}
                                    onClick={e => {

                                        acceptingSet(true)
                                        hub.Post("/base/public/jobToAcceept/accept", {
                                            token: token
                                        }, res => {
                                            if (res.success) {
                                                acceptedSet(true)
                                            }
                                            else {
                                                toastError("link is expired!")
                                            }
                                            acceptingSet(false)
                                        })

                                    }}>
                                    {
                                        accepting ?
                                            <>
                                                <Icon icon="spinner-third" spin />
                                                <span className="ms-2">Accepting Job</span>
                                            </>
                                            :
                                            <span>Accept the Job</span>
                                    }
                                </button>
                                <button className="btn btn-light-danger w-100" disabled={accepting || rejecting}
                                    onClick={e => {
                                        rejectingSet(true)

                                        hub.Post("/base/public/jobToAcceept/reject", {
                                            token: token
                                        }, res => {
                                            if (res.success) {
                                                rejectedSet(true)
                                            }
                                            else {
                                                toastError("link is expired!")
                                            }
                                            rejectingSet(false)
                                        })

                                    }}>
                                    {
                                        rejecting ?
                                            <>
                                                <Icon icon="spinner-third" spin />
                                                <span className="ms-2">Rejecting Job</span>
                                            </>
                                            :
                                            <span>Reject the Job</span>
                                    }
                                </button>
                            </div>
                    }




                </div>
            }
        </div>
    )
}