import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setPage, useGlobal } from '../../../core/Global';
import Form from '../../../components/form/Form';
import TextBox from '../../../components/form/TextBox';


function ProfileDetails() {

    const [globalState] = useGlobal();

  return (
    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header cursor-pointer">
            
            <div className="card-title m-0">
                <h3 className="fw-bolder m-0">Profile Details</h3>
            </div>
            
            <Link to="/profile/preferences/account" className="btn btn-primary btn-sm align-self-center">Edit Profile</Link>
        </div>
        <div className="card-body p-9">
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Full Name</label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                        {globalState.user?.fullName}
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Email</label>
                <div className="col-lg-8 fv-row">
                    <span className="fw-bold text-gray-800 fs-6 me-2">
                        {globalState?.user?.email}
                    </span>
                    {/* <span className="badge badge-success badge-sm">Verified</span> */}
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                    Phone
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                        {globalState.user?.phoneFriendly}
                    </span>
                    {/* <span className="badge badge-success">Verified</span> */}
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Birth Date</label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800 me-2">
                        {globalState.user?.birthDateItem?.text}
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">
                    Country
                </label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                        {globalState.user?.country?.name}
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">Account Type</label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                        {globalState.user?.userTypeName}
                    </span>
                </div>
            </div>
            <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">User Groups</label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                        {globalState.user?.groupNames?.map((grp, key) => <span key={key}>{grp}</span>)}
                    </span>
                </div>
            </div>
            {
                false &&
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                        </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                            <h4 className="text-gray-900 fw-bolder">We need your attention!</h4>
                            <div className="fs-6 text-gray-700">Your payment was declined. To start using tools, please&nbsp;
                            <a className="fw-bolder" href="">Add Payment Method</a>.</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
  );
}

export default ProfileDetails;
