import { useEffect, useState } from "react"
import { useHub } from "../../core/Global"
import Modal from "../base/Modal"
import { API_ROUTES } from "../../core/Constants"
import Job from "../../modules/crm/jobs/models/Job"
import Indicator from "../base/Indicator"


interface IProps {
    rateType: "source" | "tech"
    sourceId?: string
    techId?: string
    onClose?: () => void
    onConfirm?: (jobIds: string[]) => void
}

export default function ModalUpdateJobRates(props: IProps) {

    const hub = useHub();

    const [loading, loadingSet] = useState<boolean>(true);
    const [jobs, jobsSet] = useState<Job[]>();
    const [selectedJobs, selectedJobsSet] = useState<string[]>([]);

    const selectAll = () => {
        var arr = selectedJobs ?? [];
        jobs?.forEach(j => {
            if(j.id && selectedJobs.indexOf(j.id) < 0) arr.push(j.id);
        });
        selectedJobsSet(a => [...arr]);
    }
    const unselectAll = () => {
        selectedJobsSet([]);
    }

    const select = (id: string) => {
        for (var i = 0; i < (jobs?.length ?? 0); i++) {
            if (selectedJobs.indexOf(id) > -1) return;
        }

        var arr = selectedJobs ?? [];
        arr.push(id);
        selectedJobsSet(a => [...arr]);
    }

    const unselect = (id: string) => {
        var i = selectedJobs.indexOf(id);
        if (i < 0) return;

        var arr = selectedJobs ?? [];
        arr.splice(i, 1);
        selectedJobsSet(a => [...arr]);
    }

    useEffect(() => {

        if (props.sourceId || props.techId) {
            var q = `rateType=${props.rateType}&sourceId=${props.sourceId}&techId=${props.techId}`;
            hub.Get(API_ROUTES.Jobs + "forupdaterates?" + q , res => {
                if(res.success) {
                    jobsSet(res.data);
                    loadingSet(false);
                }
            })
        }

    }, [props.sourceId, props.techId, props.rateType])

    

    return (
        <Modal id="modal-update-job-rates"
            open visible staticBackdrop
            size="Large"
            title="Update Job Rates"
            onClose={props.onClose}
            buttonText={selectedJobs.length == 0 ? "Don't Update the Jobs" : "Update (" + selectedJobs.length+") Selected Job" + (selectedJobs.length > 1 ? "s" : "")}
            buttonColor={selectedJobs.length == 0 ? "secondary" : "primary"}
            onConfirm={(callback) => {
                if (props.onConfirm) props.onConfirm(selectedJobs);
                callback();
            }}>
                <div>

                    <div className="alert alert-warning mb-5">
                        There are some unsettled jobs connected to this source! <br /> 
                        Select the jobs you want to update the {props.rateType} rate!
                    </div>

                    {
                        loading ?
                        <div className="my-10">
                            <Indicator />
                        </div>
                        : 
                        <div className="table-responsive mb-10">
                            <table className="table table-hover table-sm align-start table-row-dashed">
                                <thead>
                                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                        <th className="ps-2" style={{
                                            width:50,
                                            minWidth: "50px !important"
                                        }}>
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" checked={selectedJobs.length == (jobs?.length ?? 0)}
                                                    onChange={e=> {
                                                        if (e.target.checked) selectAll();
                                                        else unselectAll();
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <th>Job ID</th>
                                        <th>Full Name</th>
                                        <th>Type</th>
                                        <th>Source</th>
                                        <th>Technician</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jobs?.map((job, key) => 
                                            <tr key={key} className={(selectedJobs.indexOf(job.id!) > -1) ? "table-row-selected" : ""}>
                                                <td className="ps-2" style={{
                                                        width:50,
                                                        minWidth: "50px !important"
                                                    }}>
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                        <input className="form-check-input" type="checkbox" checked={selectedJobs.indexOf(job.id!) > -1}
                                                            onChange={e=> {
                                                                if (e.target.checked) select(job.id!);
                                                                else unselect(job.id!);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>#{job.jobId}</td>
                                                <td>{job.fullName}</td>
                                                <td>{job.jobTypeWithGroup}</td>
                                                <td>{job.jobSource}</td>
                                                <td>{job.tech?.fullName}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    }

                    
                </div>
        </Modal>
    )
}