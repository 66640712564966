import { useEffect, useState } from "react"
import Icon from "../../../components/base/Icon"
import FlexRow from "../../../components/base/FlexRow"
import ChatMessage from "../models/ChatMessage"
import { toPrettyDate } from "../../../core/Global"

interface IProps {
    item?: ChatMessage
    seperator?: boolean
    selected?: boolean
    onClick?: (e: ChatMessage) => void
}

export default function ChatSearchResultRow(props: IProps) {


    return (
        <>
            <div className={"d-flex align-items-start flex-stack py-2 px-3 chat-contact-item" + (props.selected ? " chat-contact-item-selected" : "")}
                onClick={e => {
                    if (props.onClick && props.item?.id) {
                        props.onClick(props.item);
                        // console.log("go to message is setting", props.item)
                        // goToMessage(props.item.id)
                    }
                }}>
                <FlexRow className="w-100">
                    {
                        props.item?.senderUser?.avatarUrl ?
                            <div className="symbol symbol-45px symbol-circle">
                                <img alt={props.item.senderUser.fullName} src={props.item.senderUser.avatarUrl} />
                            </div>
                            :
                            <div className="symbol symbol-45px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fs-6 fw-bolder">{props.item?.senderUser?.fullName?.substring(0, 1)}</span>
                                <div className="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
                            </div>
                    }

                    <div className="ms-2 flex-grow-1">
                        <FlexRow>
                            <div className={"fs-6 text-gray-900"}>
                                {props.item?.senderUser?.fullName}
                            </div>
                            <div className="ms-auto fs-9 text-muted">
                                {toPrettyDate(props.item?.createdAt)}
                            </div>
                        </FlexRow>

                        <FlexRow>
                            {
                                !window.isNullOrEmpty(props.item?.previewIcon) &&
                                <Icon icon={props.item?.previewIcon} className="me-1 text-muted fs-8" />
                            }
                            <div className="text-muted fs-8 chat-contact-subtext" style={{ marginTop: .5 }}>
                                {props.item?.previewText}
                            </div>
                        </FlexRow>
                    </div>

                </FlexRow>
            </div>
            {
                props.seperator &&
                <div className="separator separator-dashed"></div>
            }
        </>
    )
}