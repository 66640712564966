import { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import Section from "../../../components/base/Section";
import FilteringProps from "../filtering/shiftview.json";
import { getUser, setPage, setToolbarActions, useHub } from "../../../core/Global";
import { API_ROUTES } from "../../../core/Constants";
import Icon from "../../../components/base/Icon";
import { ShiftViewAgentItem, ShiftViewDay, ShiftViewDepartment, ShiftViewModel } from "../models/ShiftViewModels";
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import FlexRow from "../../../components/base/FlexRow";
import ColorPicker from "../../../components/form/ColorPicker";
import TextBox from "../../../components/form/TextBox";
import DropDown, { DropDownOption } from "../../../components/form/DropDown";
import TimePicker from "../../../components/form/TimePicker";
import { CompactUser } from "../../../core/CompactModels";
import Avatar from "../../../components/base/Avatar";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Tagify from "../../../components/tagify/Tagify";
import FormTitle from "../../../components/form/FormTitle";
import Button from "../../../components/base/Button";
import DatePicker from "../../../components/form/DatePicker";
import TextArea from "../../../components/form/TextArea";
import { TagifyDataItem } from "../../../models/TagifyOptions";
import Filtering, { FilterItem } from "../../../components/data/Filtering";
import { ListItem } from "../../../core/Types";
import { useSearchParams } from "react-router-dom";
import Alert from "../../../components/base/Alert";
import EditShiftModal from "../components/EditShiftModal";
import AddShiftModal from "../components/AddShiftModal";
import MultiAssignModal from "../components/MultiAssignModal";
import AddShiftDepartmentModal from "../components/AddShiftDepartmentModal";
import { UserTypes } from "../../../core/Enums";




export default function ShiftsView() {

    const hub = useHub();
    const user = getUser();
    const searchParams = useSearchParams();
    const [model, modelSet] = useState<ShiftViewModel>();
    const [loaded, loadedSet] = useState<boolean>(false);
    const [week, weekSet] = useState<number>(0);
    const [selectedTimezone, selectedTimezoneSet] = useState<string>("dep");
    const [selectedUserType, selectedUserTypeSet] = useState<string>("all");
    
    
    const [createDep, createDepSet] = useState<boolean>(false);
    const [addShift, addShiftSet] = useState<boolean>(false);
    const [multiAssign, multiAssignSet] = useState<boolean>(false);
    const [editShift, editShiftSet] = useState<boolean>(false);
    
    const [editItemDay, editItemDaySet] = useState<ShiftViewDay>();
    const [editItem, editItemSet] = useState<ShiftViewAgentItem>();
    const [selectedDepartment, selectedDepartmentSet] = useState<ShiftViewDepartment>();

    const [selectedDays, selectedDaysSet] = useState<ShiftViewDay[]>();
    
    
    
    
    const [filterItems, filterItemsSet] = useState<FilterItem[]>();
    const [filteringValues, filteringValuesSet] = useState<any>();
    const [filterQuery, filterQuerySet] = useState<string>();

    
    

    const fetch = (week: number, query?: string) => {

        var q = "?week=" + week;
        
        if (selectedTimezone == "user") {
            q += "&userTimeZone=True";
        }

        if (selectedUserType != "all") {
            q += "&userType=" + selectedUserType;
        }

        if (searchParams && searchParams[0]) {
            q += "&" + searchParams[0].toString();
        }

        if (query) {
            q += "&" + query;
        }

        hub.Get(API_ROUTES.ShiftsView + q, res => {
            if (res.data) {
                modelSet(res.data);
                filteringValuesSet(res.filteringValues);
                loadedSet(true);
            }
        })
    }

    

    const ActionButtons = () => (
        <>
            <Button color="primary" text="Assign Agents" icon="user-plus"
                onClick={() => {
                    multiAssignSet(true);
                }} />
        </>
    )

    const agentCardColor = (hex?: string) => {
        if (hex == null) return "";
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) return "";
        var rgb = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };

        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, .3)`;
    }

    useEffect(() => {
        setToolbarActions(ActionButtons);
        setPage("Shifts Calendar","Account", "Shifts");
    }, [])


    

      useEffect(() => {
        fetch(week, filterQuery);
      }, [week, filterQuery, selectedTimezone, selectedUserType])



      

    return (
        <Layout wide>
            <Section fluid>
                <Filtering id="filteringshiftview"
                    filteringProps={FilteringProps}
                    filteringValues={filteringValues}
                    apiRoute={API_ROUTES.ShiftsView}
                    loaded={loaded}
                    onItemsChanged={(items, q) => {
                        filterItemsSet(items);
                        filterQuerySet(q);
                    }}
                     />
            </Section>

            <Section fluid> 
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <FlexRow>
                                <h3>{model?.startDay} - {model?.endDay}</h3>
                                <select className="form-select form-select-sm w-200px ms-4"
                                    value={selectedTimezone} onChange={e=> selectedTimezoneSet(e.target.value)}>
                                    <option value="dep">Local Timezone</option>
                                    <option value="user">My Timezone</option>
                                </select>
                                <select className="form-select form-select-sm w-200px ms-2"
                                    value={selectedUserType} onChange={e=> selectedUserTypeSet(e.target.value)}>
                                    <option value="all">All Users</option>
                                    <option value={UserTypes.Agent.toString()}>Agents</option>
                                    <option value={UserTypes.Admin.toString()}>Managers</option>
                                    <option value={UserTypes.Technician.toString()}>Technicians</option>
                                </select>
                            </FlexRow>
                        </div>
                        <div className="card-action">
                            <button className="btn btn-sm btn-secondary btn-icon" onClick={e=> weekSet(week - 1)}>
                                <Icon icon="chevron-left" />
                            </button>
                            <button className="btn btn-sm btn-secondary btn-icon" onClick={e=> weekSet(week + 1)}>
                                <Icon icon="chevron-right" />
                            </button>
                        </div>
                    </div>
                    <div className="cadr-body">

                        <table className="table">
                            <thead>
                                <tr className="border-bottom">
                                    <th>

                                    </th>

                                    {
                                        model?.days?.map((item, key) => 
                                            <th key={key} className={"border-end text-center" + (item.isToday ? " bg-light-primary" : "")}>
                                                <div>
                                                    {item.dateLabel}
                                                </div>
                                                <div className="fs-8">
                                                    {item.dayName}
                                                </div>
                                            </th>
                                        )
                                    }
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    model?.departments?.map((dep, k) => 
                                    <tr key={k} className="border-bottom">
                                        <td className="w-auto w-xxxl-300px" 
                                            style={{
                                                    backgroundColor: dep.color
                                                }}>
                                            <div className="px-6 pt-6 pb-2 text-white fw-bolder fs-2">
                                                {dep.name}
                                            </div>
                                            <div className="px-6 pb-6 text-white fs-9">
                                                <Icon icon="clock" size={10} className="me-1" type="solid" />
                                                {dep.timezone}
                                            </div>
                                        </td>
                                        {
                                            dep.days?.map((d, k) => 
                                            <td key={k} className={"border-end" + (d.isToday ? " bg-light-primary" : "")}>
                                                {
                                                    d.agents?.map((item, userKey) => 
                                                        <div key={userKey} className="mb-1 p-2 d-flex justify-content-between cursor-pointer"
                                                            onClick={e=> {
                                                                //editShiftLoadingSet(true);
                                                                selectedDepartmentSet(dep);
                                                                editItemSet(item);
                                                                model.days?.forEach(day => {
                                                                    if (day.dateNumber == item.shiftDate) {
                                                                        editItemDaySet(day);
                                                                    }
                                                                });
                                                                
                                                                editShiftSet(true);
                                                            }}
                                                            style={{
                                                                backgroundColor: agentCardColor(dep.color),
                                                                borderRadius: ".475rem"
                                                            }}>
                                                                <div>
                                                                    <FlexRow>
                                                                        <Avatar avatarUrl={item.user?.avatarUrl} fullName={item.user?.fullName} width={20} className="me-1" />
                                                                        <span className="limited-text w-100px w-xxxl-150px">{item.user?.fullName}</span>
                                                                    </FlexRow>
                                                                    {
                                                                        item.user?.userType == UserTypes.Admin ? 
                                                                        <FlexRow className="fs-8 mt-1">
                                                                            <Icon icon="user-tie" size={12} className="me-1" />
                                                                            <span>Manager</span>
                                                                        </FlexRow>
                                                                        : item.user?.userType == UserTypes.Technician ? 
                                                                        <FlexRow className="fs-8 mt-1">
                                                                            <Icon icon="user-helmet-safety" size={12} className="me-1" />
                                                                            <span>Technician</span>
                                                                        </FlexRow>
                                                                        : item.user?.userType == UserTypes.Agent ? 
                                                                        <FlexRow className="fs-8 mt-1">
                                                                            <Icon icon="user-headset" size={12} className="me-1" />
                                                                            <span>Agent</span>
                                                                        </FlexRow>
                                                                        : <></>
                                                                    }
                                                                    
                                                                    <FlexRow className="fs-8 mt-1">
                                                                        <Icon icon="clock" size={12} className="me-1" />
                                                                        <span className="me-1">{item.startTime}-{item.endTime}</span>
                                                                        <span className="d-none d-xxxl-block">({item.shiftType?.name})</span>
                                                                    </FlexRow>
                                                                    
                                                                    
                                                                </div>
                                                                <div>
                                                                    {
                                                                        item.hasConflict &&
                                                                        <span className="cursor-pointer me-2">
                                                                            <Icon icon="circle-exclamation" type="solid" color="#CC7A00" size={16} title={item.conflictText} />
                                                                        </span>
                                                                    }
                                                                </div>
                                                                
                                                        </div>
                                                    )
                                                }
                                                <div>
                                                    <button className="btn btn-sm btn-secondary btn-icon w-100"
                                                        onClick={e=> {
                                                            
                                                            selectedDepartmentSet(dep);
                                                            model.days?.forEach(day => {
                                                                if (day.dateNumber == d.dateNumber) {
                                                                    selectedDaysSet([day]);
                                                                }
                                                            });
                                                            addShiftSet(true);
                                                        }}>
                                                        <Icon icon="plus" />
                                                    </button>
                                                </div>
                                            </td>
                                            )
                                        }
                                    </tr>
                                    )
                                }


                                    <tr className="border-bottom">
                                        <td style={{
                                                    backgroundColor: "#f2f2f2",
                                                }}>
                                            <div className="p-6 text-white fs-bolder">
                                                <a href="#" onClick={e=> {
                                                    e.preventDefault();
                                                    createDepSet(true);
                                                }}>
                                                    <Icon icon="plus" className="me-2" />
                                                    Create Department
                                                </a>
                                            </div>
                                        </td>
                                        {
                                            model?.days?.map((d, k) => 
                                            <td key={k} className="border-end">
                                                
                                            </td>
                                            )
                                        }
                                    </tr>
                                
                            </tbody>
                        </table>

                        
                    </div>
                </div>
            </Section>


            {
                createDep &&
                <AddShiftDepartmentModal 
                    onClose={() => createDepSet(false)}
                    callback={() => fetch(week)} />
            }

            
            
            {
                addShift &&
                <AddShiftModal department={selectedDepartment}
                    days={selectedDays}
                    onClose={() => addShiftSet(false)}
                    callback={() => fetch(week)} />
            }

            {
                editShift &&
                <EditShiftModal item={editItem} 
                    department={selectedDepartment} 
                    day={editItemDay}
                    onClose={() => editShiftSet(false)}
                    callback={() => fetch(week)} />
            }

            {
                multiAssign &&
                <MultiAssignModal model={model}
                    onClose={() => multiAssignSet(false)}
                    callback={() => fetch(week)} />
            }
            


            

        </Layout>
    )

}