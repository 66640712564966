import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserTypes } from "../../../core/Enums";
import { setPage, useGlobal } from '../../../core/Global';

type ProfileHeaderProps = {
    selectedPage?: string
}

function ProfileHeader(props: ProfileHeaderProps) {

    const [globalState] = useGlobal();

  return (
    <div className="card mb-5">
        <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="me-7 mb-4">
                    <div className="symbol symbol-100px bg-primary symbol-fixed position-relative">
                        {
                            globalState.user?.avatarUrl ? 
                            <img src={globalState.user?.avatarUrl} />
                            : 
                            <div className={`text-white`} style={{
                                paddingTop: 18,
                                textTransform: "capitalize",
                                fontSize: 36,
                                width: 100,
                                height: 100,
                                textAlign: "center"
                            }}>
                                <span className="me-2">{globalState.user?.firstName?.substring(0, 1)}</span>
                                <span>{globalState.user?.lastName?.substring(0, 1)}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                                    {globalState.user?.fullName}
                                </a>
                                {/* <a href="#" className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Upgrade to Pro</a> */}
                            </div>
                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span className="svg-icon svg-icon-4 me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black" />
                                            <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black" />
                                        </svg>
                                    </span>
                                    {globalState.user?.userTypeName}
                                </a>
                                <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                    <span className="svg-icon svg-icon-4 me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="black" />
                                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="black" />
                                        </svg>
                                    </span>
                                    {
                                        globalState.user?.email
                                    }
                                </a>
                            </div>
                        </div>
                        <div className="d-flex">
                            {/* <a href="#" className="btn btn-sm btn-light me-2" id="kt_user_follow_button">
                                <span className="svg-icon svg-icon-3 d-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="black" />
                                        <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="black" />
                                    </svg>
                                </span>
                                <span className="indicator-label">Follow</span>
                                <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ProfileHeader;
