import { useEnvironment, useGlobal } from "../../../core/Global";
import Icon from "../../base/Icon";


function SignUpLayout(props: {children: any}) {

	const env = useEnvironment();
	const [globalState] = useGlobal();

	const stepClass = (st: number) => {
		var step = globalState.signupStep ?? 1;
		if (step == st) return " current";
		if (step > st) return " completed";
		if (step < st) return " pending";
		return "";
	}

    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_create_account_stepper">
				
				<div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm">
					<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
						<div className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
							<a className="mb-10">
								<img alt={env.siteTitle} src={`/assets/brands/${env.brand}/logo.png`} className="h-50px" />
							</a>

							{
								(globalState.signupStep ?? 1) <= 6 &&
								<div className="stepper-nav">
								
									<div className={"stepper-item" + stepClass(1)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">1</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Personal Information</h3>
											<div className="stepper-desc fw-bold">You must verify your email address!</div>
										</div>
									</div>
									<div className={"stepper-item" + stepClass(2)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">2</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Sign-In Options</h3>
											<div className="stepper-desc fw-bold">Setup your own username and password</div>
										</div>
									</div>
									<div className={"stepper-item" + stepClass(3)} data-kt-stepper-element="nav">
										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">3</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Add Business</h3>
											<div className="stepper-desc fw-bold">Add your company information</div>
										</div>
									</div>

									<div className={"stepper-item" + stepClass(4)} data-kt-stepper-element="nav">
										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">4</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Payment Method</h3>
											<div className="stepper-desc fw-bold">Add your credit card on stripe</div>
										</div>
									</div>

									<div className={"stepper-item" + stepClass(5)} data-kt-stepper-element="nav">
										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">5</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Invoice</h3>
											<div className="stepper-desc fw-bold">Pay the first invoice</div>
										</div>
									</div>

									<div className={"stepper-item" + stepClass(6)} data-kt-stepper-element="nav">
										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">6</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Finalize</h3>
											<div className="stepper-desc fw-bold">Go to your dashboard</div>
										</div>
									</div>

								</div>
							}

							{
								(globalState.signupStep ?? 1) > 6 &&
								<div className="stepper-nav">
								
									<div className={"stepper-item" + stepClass(7)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">
												<Icon icon="phone-office" type="solid" size={18} />
											</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Extension</h3>
											<div className="stepper-desc fw-bold">Create your first extension!</div>
										</div>
									</div>
									<div className={"stepper-item" + stepClass(8)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">
												<Icon icon="arrow-right-to-line" type="solid" size={18} />
											</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Queue</h3>
											<div className="stepper-desc fw-bold">Create your first queue!</div>
										</div>
									</div>

									<div className={"stepper-item" + stepClass(9)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">
												<Icon icon="phone" type="solid" size={18} />
											</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Phone Number</h3>
											<div className="stepper-desc fw-bold">Buy your first phone number!</div>
										</div>
									</div>

									<div className={"stepper-item" + stepClass(10)} data-kt-stepper-element="nav">

										<div className="stepper-line w-40px"></div>
										<div className="stepper-icon w-40px h-40px">
											<i className="stepper-check fas fa-check"></i>
											<span className="stepper-number">
												<Icon icon="check" type="solid" size={18} />
											</span>
										</div>
										<div className="stepper-label">
											<h3 className="stepper-title">Done</h3>
											<div className="stepper-desc fw-bold">Go to your console!</div>
										</div>
									</div>

								</div>
							}

						</div>
						<div className="d-xxl-flex d-none flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px" 
							style={{backgroundImage: "url(/assets/media/illustrations/sketchy-1/16.png)"}}></div>
					</div>
				</div>

				<div className="d-flex flex-column flex-lg-row-fluid py-10">
					
					<div className="d-flex flex-center flex-column flex-column-fluid">
						{props.children}
					</div>
					<div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
						{/* <div className="d-flex flex-center fw-bold fs-6">
							<a href="https://keenthemes.com" className="text-muted text-hover-primary px-2" target="_blank">About</a>
							<a href="https://devs.keenthemes.com" className="text-muted text-hover-primary px-2" target="_blank">Support</a>
							<a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2" target="_blank">Purchase</a>
						</div> */}
					</div>

				</div>
			</div>
    )
}

export default SignUpLayout;