import { useEffect, useState } from "react";
import { toastSuccess } from "../../../components/base/Toast";
import Form from "../../../components/form/Form";
import TextBox from "../../../components/form/TextBox";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { useHub } from "../../../core/Global";
import Icon from "../../../components/base/Icon";
import User from "../models/User";
import Tagify from "../../../components/tagify/Tagify";
import Button from "../../../components/base/Button";
import Modal from "../../../components/base/Modal";
import { API_ROUTES } from "../../../core/Constants";
import FlexRow from "../../../components/base/FlexRow";
import AccountsIPRestrict from "./AccountsIPRestrict";


type Props = {
    userId?: string
    model?: User
    onUpdate?: (user: User) => void
}

export default function AccountSecurity(props: Props) {

    const hub = useHub();
    const [clear, clearSet] = useState<boolean>(false);
    const [updateOne, updateOneSet] = useState<boolean>(false);
    const [usernameView, usernameViewSet] = useState<boolean>(true);
    const [passwordView, passwordViewSet] = useState<boolean>(true);

    const [working1, working1Set] = useState<boolean>(false);
    const [working2, working2Set] = useState<boolean>(false);

    const [suspended, suspendedSet] = useState<boolean>(false);

    useEffect(() => {
        if (clear) {
            setTimeout(() => {
                clearSet(false);
            }, 1000);
        }
    }, [clear])

    const [defaultIPs, defaultIPsSet] = useState<string[]>();
    useEffect(() => {
        hub.Get("/accounts/users/restrictips/" + props.userId, res => {
            if (res.success) defaultIPsSet(res.data);
        })
    }, [])

    useEffect(() => {
        suspendedSet(props.model?.suspended ?? false);
    }, [props.model])

    return (
        <>
            <Form id="user-password-form" className="form"
                onSubmit={(body, callback) => {

                    working1Set(true);

                    setTimeout(() => {

                        hub.Put("/accounts/users/logininfo/" + props.userId, body, res => {
                            if (res.success) {
                                toastSuccess("Login information has been updated successfully!");
                                usernameViewSet(true);
                                passwordViewSet(true);
                                updateOneSet(false);
                                usernameViewSet(true);
                                passwordViewSet(true);
                            }
                            working1Set(false)
                        })

                    }, 500);

                }}>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header border-0">
                        <div className="card-title m-0">
                            <h3 className="fw-bolder m-0">Login Information</h3>
                        </div>
                        <div className="card-action">
                            <button type="submit" className="btn btn-sm btn-primary" disabled={working1 || !updateOne}>
                                {
                                    working1 ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">Updating...</span>
                                        </>
                                        :
                                        <>Update</>
                                }
                            </button>
                        </div>
                    </div>

                    <div className="card-body border-top p-9">

                        <div className="mt-10" style={{
                            maxWidth: 1200
                        }}>

                            <TextBox id="txt-user-edit-username" label="Username" name="username"
                                marginBottom={10} value={props.model?.username} view={usernameView}
                                onEditMode={() => {
                                    updateOneSet(true);
                                    usernameViewSet(false);
                                }} />

                            <TextBox id="txt-user-edit-password" label="Password" name="password"
                                password generatePassword marginBottom={10} clear={clear} view={passwordView}
                                onEditMode={() => {
                                    updateOneSet(true);
                                    passwordViewSet(false);
                                }} />

                            {
                                updateOne &&
                                <ToggleSwitch name="sendToEmail" raw small
                                    label="Send the new login information to the user via email!" />
                            }

                        </div>


                    </div>

                </div>
            </Form>

            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            User Status
                        </h3>
                    </div>
                    <div className="card-action">

                    </div>
                </div>

                <div className="card-body border-top p-9">

                    <div className="" style={{
                        maxWidth: 1200
                    }}>

                        {
                            suspended ?
                                <FlexRow>
                                    <Icon icon="circle" type="solid" className="text-danger" size={20} />
                                    <span className="ms-2 me-10">
                                        User is Deativated!
                                    </span>
                                    <Button color="success" type="light" text="Activate User" modal="modal-activate-user" />
                                </FlexRow>
                                :
                                <FlexRow>
                                    <Icon icon="circle" type="solid" className="text-success" size={20} />
                                    <span className="ms-2 me-10">
                                        User is Active!
                                    </span>
                                    <Button color="warning" type="light" text="Deactivate User" modal="modal-deactive-user" />
                                </FlexRow>
                        }

                    </div>


                </div>

            </div>

            <AccountsIPRestrict model={props.model} />


            <Modal id="modal-deactive-user"
                title="Deactivate User"
                buttonText="Deactivate User"
                buttonColor="warning"
                onConfirm={callback => {
                    hub.Post(API_ROUTES.Users + "deactivate/" + props.model?.id, {}, res => {
                        suspendedSet(true);
                        callback();
                    })
                }}>

                <div>
                    Deactivating this user will prevent any future logins and remove their details from all lists.
                </div>
            </Modal>

            <Modal id="modal-activate-user"
                title="Activate User"
                buttonText="Activate User"
                buttonColor="success"
                onConfirm={callback => {
                    hub.Post(API_ROUTES.Users + "activate/" + props.model?.id, {}, res => {
                        suspendedSet(false);
                        callback();
                    })
                }}>

                <div>
                    This will reactivate the user's account, allowing them to log in and appear in lists again.
                </div>
            </Modal>

        </>
    )
}