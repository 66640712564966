import FlexRow from "../../../components/base/FlexRow";
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import ColorPicker from "../../../components/form/ColorPicker";
import DropDown from "../../../components/form/DropDown";
import TextBox from "../../../components/form/TextBox";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";
import ShiftDepartment from "../models/ShiftDepartment";



interface IProps {
  id?: string
  model?: ShiftDepartment
  onClose?: () => void
  callback?: () => void
}

export default function EditShiftDepartmentModal(props: IProps) {

  const hub = useHub();
  const user = getUser();

  return (
    <Modal id="modal-edit-department"
      title="Edit Department"
      buttonText="Update Department"
      staticBackdrop
      visible open
      onOpen={() => {
        $("#txt-editdep-name").focus();
      }}
      onClose={props.onClose}
      onSubmit={(body, callback) => {

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.showFormError("ownerUserId", "Please choose a valid user!", true, "modal-edit-department");
            if (callback) callback();
            return;
          }
        }

        if (!body.name || body.name == "" || body.name.length < 2) {
          window.showFormError("name", "Please write a valid name!", false, "modal-edit-department");
          if (callback) callback();
          return;
        }

        if (!body.timezone || body.timezone == "") {
          window.showFormError("timezone", "Please select a timezone!", false, "modal-add-department");
          if (callback) callback();
          return;
        }

        hub.Put(API_ROUTES.ShiftsDepartments + props.id, body, res => {
          if (res.success) {
            if (props.callback) props.callback();
            toastSuccess("Department has been updated successfully!");
          }
          if (callback) callback(res);
        })

      }}>
      <div>
        {
          user?.isSuperAdmin &&
          <>
            <div className="fs-7 fw-bold">
              Assign to
              <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
            </div>
            <Tagify name="ownerUserId" id="drp-edit-ownerUserId" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} selectedId={props.model?.ownerUserId} single block />
          </>
        }

        <FlexRow className="mb-5" alignItems="start">
          <ColorPicker id="color-edit-deppp" width={30} className="me-2 mt-1" name="color" color={props.model?.color} />
          <div className="flex-grow-1">
            <TextBox id="txt-editdep-name" name="name" block marginBottom={0} inlineStyle mediumText placeholder="Department Name" defaultValue={props.model?.name} />
          </div>
        </FlexRow>

        <Tagify id="tagify-edit-dep-queues" name="queueIds" apiUrl={API_ROUTES.TagifyQueues}
          block label="Station(s)" labelUp defaultIds={props.model?.queuesIds} />

        <DropDown id="drp-shiftdep-timezones" name="timezone" label="Timezone" labelUp parentModal="modal-edit-department"
          apiSource={API_ROUTES.Users + "timezones"} defaultValue={props.model?.timeZone} />

      </div>
    </Modal>
  )
}