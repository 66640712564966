import React, { useEffect, useState } from "react";
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Hub from '../../api/Hub';
import Indicator from "../base/Indicator";
import Environment from '../../env/env';
import Modal from "../base/Modal";
import { useHub } from "../../core/Global";

interface AvatarUploadeProps {
    name?:string
    imageUrl?:string
    defaultValue?: string
    label?: string
    type?: "avatars" | "logos"
    circle?: boolean
    width?: number
    extHelp?: boolean
    preventCrop?: boolean
    onUploaded?: (url: string) => void
}
let imgWidth:number = 0;
let imgHeight:number = 0;
export default function AvatarUploade(props: AvatarUploadeProps) {

    const hub = useHub();
    const env = new Environment();
    const [uploading, uploadingSet] = useState<boolean>(false);
    const [uploadData, uploadDataSet] = useState<File>();
    const [imageUrl, imageUrlSet] = useState<string>();
    const [imageVal, imageValSet] = useState<string>();
    const [inputId, inputIdSet] = useState<string>();
    const [cropSrc, cropSrcSet] = useState<string>();
    const [crop, cropSet] = useState<Crop>({
        unit: '%',
        x: 10,
        y: 10,
        width: 80,
        height: 80
      })

    const fileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if(e.target.files) {
            uploadDataSet(e.target.files[0]);
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                var img = new Image();
                img.src = reader.result?.toString() ?? "";
                cropSrcSet(img.src);
                img.onload = function () {
                    imgWidth = img.width;
                    imgHeight = img.height;
                    var w = 500;
                    var h = (img.height * 500) / img.width;
                    var w1 = 0;
                    var h1 = 0;
                    if(w < h) {
                        w1 = 80;
                        h1 = (w * w1) / h;
                    } 
                    else {
                        h1 = 80;
                        w1 = (h * h1) / w;
                    }
                    cropSet({
                        unit: "%",
                        width: w1,
                        height: h1,
                        x: 10,
                        y: 10
                    });

                    window.openModal("modal-crop-avatar-" + inputId);
                };
            }
            
        }
    };


    useEffect(()=> {
        inputIdSet("photo-upload-" + Math.floor(Math.random() * 1000000));
        if (props.imageUrl) imageUrlSet(props.imageUrl);
        if (props.defaultValue) imageValSet(props.defaultValue);
    }, [])
    return (
        <>
        <div id={inputId} className="fv-row">
            {
                props.label &&
                <label className="d-block fw-bold fs-6 mb-5">{props.label}</label>
            }
            
            <div className={"image-input image-input-outline" + (props.circle ? " image-input-circle" : "") + (imageVal ? "" : " image-input-empty")} data-kt-image-input="true" 
                style={{
                    backgroundImage: "url(" + (props.type == "avatars" ? "/assets/media/avatars/blank.png" : "/assets/media/blanks/company.png") + ")"
                }}>
                <div id={"image-input-wrapper-" + inputId} className="image-input-wrapper" style={{
                    backgroundImage: imageUrl ? "url(" + imageUrl + ")" : "none",
                    width: props.width ?? 125,
                    height: props.width ?? 125
                }}>
                    {
                        uploading && 
                        <div className="avatar-loading">
                            <Indicator text="uploading..." />
                        </div>
                    }
                </div>
                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change">
                    <i className="bi bi-pencil-fill fs-7"></i>
                    <input type="file" accept=".png, .jpg, .jpeg, .svg, .webp" onChange={fileChange} />
                    <input type="hidden" name={props.name} value={imageVal} />
                </label>
                <span id={"btn-avatar-cancel-" + inputId} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel">
                    <i className="bi bi-x fs-2"></i>
                </span>
                <span id={"btn-avatar-remove-" + inputId} className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" 
                        data-kt-image-input-action="remove" onClick={e=> {
                            imageUrlSet(undefined);
                            imageValSet(undefined);
                        }}
                        data-bs-toggle="tooltip" title="Remove avatar">
                    <i className="bi bi-x fs-2"></i>
                </span>
            </div>
            {
                props.extHelp && <div className="form-text">Allowed: png, jpg, jpeg</div>
            }
            
        </div>
        <Modal title="Upload Photo" staticBackdrop noHeader
            id={"modal-crop-avatar-" + inputId}
            buttonText="Upload Photo"
            onConfirm={callback => {
                uploadingSet(true);
                hub.Upload({
                    category: props.type ?? "avatars",
                    file: uploadData,
                    width: imgWidth,
                    height: imgHeight,
                    cropX: crop.x,
                    cropY: crop.y,
                    cropW: crop.width,
                    cropH: crop.height
                }, res => {
                    if(res.success && res.data) {
                        uploadingSet(false);
                        imageUrlSet(env.cdnUrl + res.data);
                        imageValSet(res.data);
                        if(props.onUploaded) props.onUploaded(res.data);
                        window.closeModal("modal-crop-avatar-" + inputId);
                    }
                });
            }}
            onClose={() => {
                $("#btn-avatar-cancel-" + inputId).click();
                //$("btn-avatar-remove-" + inputId).click();
            }}>
                <ReactCrop crop={crop} onChange={(c, p) => cropSet(p)} aspect={1} >
                    <img src={cropSrc} style={{width: 500}} />
                </ReactCrop>
        </Modal>
        </>
    )
}