import { useEffect, useState } from "react";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../core/Constants";
import { getUser, useHub } from "../../../core/Global";
import { toastError, toastSuccess } from "../../../components/base/Toast";
import TechGroup from "../models/TechGroup";
import TextBox from "../../../components/form/TextBox";
import TextArea from "../../../components/form/TextArea";
import Form from "../../../components/form/Form";
import FlexRow from "../../../components/base/FlexRow";
import Button from "../../../components/base/Button";


interface IProps {
  model?: TechGroup
  onDiscard?: () => void
  onUpdated?: () => void
}

export default function TechGroupEditForm(props: IProps) {

  const hub = useHub();
  const user = getUser();

  const [working, workingSet] = useState<boolean>(false)

  return (
    <Form id="form-edit-techgroup"
      onSubmit={(body, callback) => {

        if (user?.isSuperAdmin) {
          if (!body.ownerUserId || body.ownerUserId == "" || body.ownerUserId.length < 10) {
            window.formError("ownerUserId", "Please choose the assignee user!", "modal-edit-techgroup");
            if (callback) callback();
            return;
          }
        }

        if (!body.groupName || body.groupName == "" || body.groupName.length < 2) {
          window.formError("groupName", "Please write a group name!", "modal-edit-techgroup");
          if (callback) callback();
          return;
        }

        workingSet(true)
        hub.Put(API_ROUTES.TechGroups + props.model?.id, body, res => {
          if (res.success) {
            if (props.onUpdated) props.onUpdated();
            toastSuccess("Group has been updated successfully!");
          }
          if (callback) callback(res)
          workingSet(false)
        })

      }}>
      <div>
        <div style={{
          minHeight: 200
        }}>
          {
            user?.isSuperAdmin &&
            <>
              <div className="fs-7 fw-bolder">
                Assign to
                <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
              </div>
              <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer"
                apiUrl={API_ROUTES.TagifyCustomers} single block defaultId={props.model?.ownerUserId} />
            </>
          }

          <TextBox name="groupName" id="txt-edit-groupname" label="Group Name" autoFocus
            placeholder="Write a name" block labelUp value={props.model?.name} />

          <TextArea name="description" id="txt-edit-descr" label="Description" rows={5}
            placeholder="Write about the group (optional)" block labelUp value={props.model?.description} />
        </div>
        <FlexRow justify="end" className="my-5">
          <Button text="Discard" className="me-2" onClick={props.onDiscard} color="secondary" />
          <Button text="Update Group" isSubmit isRunning={working} />
        </FlexRow>
      </div>
    </Form>
  )
}