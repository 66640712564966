import React, { useEffect, useState } from "react";
import moment from "moment"
import { usePreferences } from "../../core/Global";
import { TimeFormats } from "../../core/Enums";

type ClockProps = {
    width?: number
    offset?: number
}

function Clock(props: ClockProps) {

    const pref = usePreferences()

    const getFormat = () => pref?.systemPreferences.timeFormat == TimeFormats.Format12Hours ? "h:mm A" : "HH:mm"

    const [currentTime, setCurrentTime] = useState<string>(
        moment.utc().add(props.offset ?? 0, "minutes").format(getFormat())
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment.utc().add(props.offset ?? 0, "minutes").format(getFormat()));
        }, 1000);

        return () => clearInterval(interval);
    }, [props.offset]);



    return (
        <span style={{
            width: props.width ?? "auto",
            display: "inline-block"
        }}>
            {currentTime}
        </span>
    )
}

export default Clock;

