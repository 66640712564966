import { useEffect } from "react"
import { getUser, openCopyJob, openJobQuickView, setPage, toPrettyDate, useHub } from "../../../../core/Global"
import Layout from "../../../../components/layouts/Layout"
import { DataTable, DataTableCustomActions, DataTableField } from "../../../../components/data/DataTable"
import { API_ROUTES } from "../../../../core/Constants"
import Section from "../../../../components/base/Section"
import Job from "../models/Job"
import FlexRow from "../../../../components/base/FlexRow"
import Icon from "../../../../components/base/Icon"
import FilteringProps from "../filtering/joblist-canceled.json"
import SortingProps from "../sorting/jobs.json"
import { useNavigate } from "react-router-dom"
import { toastDefault, toastError } from "../../../../components/base/Toast"
import { copyToClipboard } from "../../../../core/Helper"


export default function CanceledJobsList() {

    const navigate = useNavigate();
    const hub = useHub();

    const JobIdColumn = (data: Job) => (
        <div className="fs-7">
            <a href="#" onClick={e => {
                e.preventDefault();
                openJobQuickView(data.id)
            }}>
                #{data.jobId}
            </a>
        </div>
    )

    const ClientColumn = (data: Job) => (
        <div className="fs-7">
            <div>
                {data.fullName}
            </div>
            <FlexRow className="text-gray-600">
                <Icon icon="phone-rotary" type="solid" className="me-1" />
                <span className="fs-8">
                    {data.phone?.phoneFriendly}
                </span>
            </FlexRow>
        </div>
    )

    const AddressColumn = (data: Job) => (
        <div className="fs-7">
            {data.address?.title}
        </div>
    )

    const TechColumn = (data: Job) => (
        <div className="fs-7">
            {data.tech?.fullName ?? "-"}
        </div>
    )

    const CancelNote = (data: Job) => (
        <div className="text-danger fs-7">
            {data.lastStatusNote}
        </div>
    )

    const CreatedColumn = (data: Job) => (
        <div className="w-175px">

            <FlexRow className="mb-1" title={"Job Timezone: " + data.timezone}>
                <div className="fs-7 me-1">
                    {toPrettyDate(data.createdAt, data.timezoneOffset)}
                </div>
                <div className="fs-9">
                    (Local Time)
                </div>
            </FlexRow>

            <FlexRow className="mb-1 text-gray-600" title={"Your Timezone: " + getUser()?.timeZoneId}>
                <div className="me-1 fs-8">
                    {toPrettyDate(data.createdAt)}
                </div>
                <div className="fs-9">
                    (Your Time)
                </div>
            </FlexRow>
            {
                data.creatorUser &&
                <FlexRow className="text-gray-600">
                    <Icon icon="user" className="fs-8 me-2" type="solid" />
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }
        </div>
    )

    const CanceledColumn = (data: Job) => (
        <div className="w-175px">

            <FlexRow className="mb-1" title={"Job Timezone: " + data.timezone}>
                <div className="fs-7 me-1">
                    {toPrettyDate(data.lastStatusUpdatedAt, data.timezoneOffset)}
                </div>
                <div className="fs-9">
                    (Local Time)
                </div>
            </FlexRow>

            <FlexRow className="mb-1 text-gray-600" title={"Your Timezone: " + getUser()?.timeZoneId}>
                <div className="fs-8 me-1">
                    {toPrettyDate(data.lastStatusUpdatedAt)}
                </div>
                <div className="fs-9">
                    (Your Time)
                </div>
            </FlexRow>

            {
                data.lastStatusUser &&
                <FlexRow className="text-gray-600">
                    <Icon icon="user" className="me-2" size={10} type="solid" />
                    <div className="fs-8">
                        By: {data.lastStatusUser.fullName}
                    </div>
                </FlexRow>
            }
        </div>
    )

    useEffect(() => {
        setPage("Canceled Jobs", "CRM", "Jobs")
    }, [])


    const CopyJob = (data: Job) => (
        <div className="menu-item">
            <a href="#" className="menu-link text-gray-700 px-3" onClick={async e => {
                e.preventDefault()
                var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + data.id)
                var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                if (!copy) openCopyJob(data);

            }}>
                <Icon icon="copy" className="me-2" />
                Copy Job
            </a>
        </div>
    )


    return (
        <Layout wide>
            <Section fluid>

                <DataTable
                    objectName="Job"
                    preventCreate
                    filteringProps={FilteringProps}
                    sortingProps={SortingProps}
                    apiRoute={API_ROUTES.JobsCanceled}>

                    <DataTableField title="Job ID" width={70}>
                        <JobIdColumn />
                    </DataTableField>
                    <DataTableField title="Client" width={150}>
                        <ClientColumn />
                    </DataTableField>
                    <DataTableField title="Address" maxWidth={200}>
                        <AddressColumn />
                    </DataTableField>

                    <DataTableField title="Job Type" dataValueName="jobType" className="fs-7" />
                    <DataTableField title="Job Source" dataValueName="jobSource" className="fs-7" />

                    <DataTableField title="Tech" maxWidth={150}>
                        <TechColumn />
                    </DataTableField>

                    <DataTableField title="Created" maxWidth={175}>
                        <CreatedColumn />
                    </DataTableField>

                    <DataTableField title="Canceled" maxWidth={175}>
                        <CanceledColumn />
                    </DataTableField>

                    <DataTableField title="Canceltion Note">
                        <CancelNote />
                    </DataTableField>

                    <DataTableCustomActions>
                        <CopyJob />
                    </DataTableCustomActions>


                </DataTable>

            </Section>
        </Layout>
    )
}