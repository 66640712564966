import { useEffect, useState } from "react";
import Modal from "../../../../components/base/Modal";
import { closeJob, getCountry, getCountryByCode, getUSA, useCashedData, useEnvironment, useGlobal, useHub, useScreen, useStatusesForCreateJob, useStatusesForJob } from "../../../../core/Global";
import Map from "../../../../components/here/Map";
import TextBox from "../../../../components/form/TextBox";
import DropDown, { DropDownOption } from "../../../../components/form/DropDown";
import { API_ROUTES } from "../../../../core/Constants";
import Tagify from "../../../../components/tagify/Tagify";
import DatePicker from "../../../../components/form/DatePicker";
import TextArea from "../../../../components/form/TextArea";
import AddressField from "../../../../components/here/AddressField";
import { HereGeocodeAddress, HereGeocodeItem, HereGeocodePosition } from "../../../../models/MapModels";
import PhoneInput from "../../../../components/form/PhoneInput";
import Icon from "../../../../components/base/Icon";
import JobRequestModel from "../../../../models/JobRequestModel";
import TimePicker from "../../../../components/form/TimePicker";
import FlexRow from "../../../../components/base/FlexRow";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import Job from "../models/Job";
import Technician from "../../../technicians/models/technician";
import Indicator from "../../../../components/base/Indicator";
import JobMetro from "../../base/models/JobMetro";
import JobStatus from "../../base/models/JobStatus";
import Country from "../../../base/models/Country";
import DateItem from "../../../../models/DateItem";
import PhoneItem from "../../../../models/PhoneItem";
import Contact from "../../../contacts/models/Contact";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import IconButton from "../../../../components/base/IconButton";

interface IProps {
    duplicateId?: string
    chatMessageId?: string
}
export default function JobFormModal(props: IProps) {

    const [globalState] = useGlobal();

    const env = useEnvironment();
    const hub = useHub();
    const screen = useScreen();
    const cash = useCashedData();

    const setCountryCache = (count: Country) => {
        window.localStorage.setItem("crm-jobs-country-cache", JSON.stringify(count));
    }
    const getCountryCache = () => {
        var data = window.localStorage.getItem("crm-jobs-country-cache");
        var usa = getUSA();
        if (!data || data == "") return usa;
        else return JSON.parse(data);
    }

    const [country, countrySet] = useState<Country>(getCountryCache());
    const [mapCountry, mapCountrySet] = useState<Country>(getCountryCache());
    const [phoneCountry, phoneCountrySet] = useState<Country>(getCountryCache());
    const [states, statesSet] = useState<DateItem[]>();

    useEffect(() => {
        if (phoneCountry) {
            if (!address && mapCountry.id != phoneCountry.id) {
                mapCountrySet(phoneCountry);
            }
            phoneSet(undefined);
        }
    }, [phoneCountry])

    useEffect(() => {
        if (mapCountry) {
            if (!phone && phoneCountry.id != mapCountry.id) {
                phoneCountrySet(mapCountry);
            }
            countrySet(mapCountry);
        }
    }, [mapCountry])

    useEffect(() => {
        if (country) {
            setCountryCache(country);
            addressSet(undefined);
            customAddressSet(undefined);
            hub.Get("/base/states/?countryId=" + country.id, res => {
                if (res.data) statesSet(res.data);
            });
        }
    }, [country])


    const [phone, phoneSet] = useState<PhoneItem>();
    const [contact, contactSet] = useState<Contact>();
    const [fullName, fullNameSet] = useState<string>();
    const [address, addressSet] = useState<HereGeocodeItem>();
    const [selectedState, selectedStateSet] = useState<string>();
    const [zipcode, zipcodeSet] = useState<string>();
    const [customAddress, customAddressSet] = useState<string>();

    useEffect(() => {
        var zipcode = "";
        var validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-']
        if (customAddress) {
            for (var i = 0; i < customAddress.length; i++) {
                var c = customAddress[i];
                if (validChars.indexOf(c) < 0) zipcode = "";
                else zipcode += c;

                if (zipcode.length > 4) break;
            }
        }

        if (zipcode.length > 4) {
            hub.LookUpZipcode(zipcode, country.alpha3Code ?? "usa", res => {
                zipcodeSet(res?.address?.postalCode)
                selectedStateSet(res?.address?.stateId)
            })
        }
    }, [customAddress])

    useEffect(() => {
        if (contact) {
            phoneSet(contact.phone);
            fullNameSet(contact.fullName);
            addressSet(contact.defaultAddress?.address);
            hub.Get(API_ROUTES.Jobs + "contactActiveJobs/" + contact.id,
                res => {
                    if (res.success) contactJobsSet(res.data);
                    else contactJobsSet([]);
                });
        }
    }, [contact])

    const [contactPhone, contactPhoneSet] = useState<PhoneItem>();
    const [company, companySet] = useState<string>();
    const [email, emailSet] = useState<string>();

    const [jobSourceId, jobSourceIdSet] = useState<string>();
    const [jobTypeId, jobTypeIdSet] = useState<string>();

    const [metros, metrosSet] = useState<JobMetro[]>();
    const [selectedMetro, selectedMetroSet] = useState<JobMetro>();
    const fetchMetros = () => {
        hub.Get(API_ROUTES.JobMetros + "all", res => {
            if (res.success) metrosSet(res.data);
        })
    }

    const [techs, techsSet] = useState<Technician[]>();
    const [selectedTech, selectedTechSet] = useState<Technician>();
    const fetchTechs = () => {

        var q = "?sourceId=" + jobSourceId;
        q += "&typeId=" + jobTypeId;
        q += "&metroId=" + selectedMetro?.id;

        //q += "&lat=" + address?.position?.lat;
        //q += "&lng=" + address?.position?.lng;

        hub.Get("/accounts/Technicians/byatts/" + q, res => {
            if (res.success) techsSet(res.data);
        })
    }

    useEffect(() => {
        appointmentReminderTimeSet(selectedTech?.apptReminderTime ?? 60)
    }, [selectedTech])

    useEffect(() => {
        fetchTechs();
    }, [jobSourceId, jobTypeId, selectedMetro])

    const [jobDetails, jobDetailsSet] = useState<string>();

    const [flags, flagsSet] = useState<string[]>([]);
    const [tags, tagsSet] = useState<string[]>([]);

    const [appointmentDate, appointmentDateSet] = useState<string>();
    const [appointmentTimeFrom, appointmentTimeFromSet] = useState<string>();
    const [appointmentTimeTo, appointmentTimeToSet] = useState<string>();
    const [sendAppointmentReminder, sendAppointmentReminderSet] = useState<boolean>(false)
    const [appointmentReminderTime, appointmentReminderTimeSet] = useState<number>(60)

    const statuses = useStatusesForCreateJob()
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>(statuses[0]);


    const [duplicateModel, duplicateModelSet] = useState<Job>();
    const [duplicateLoading, duplicateLoadingSet] = useState<boolean>(false);

    const [buildJobMap, buildJobMapSet] = useState<boolean>(false);
    const [mapZoom, mapZoomSet] = useState<number>();


    const [addressOnceSelected, addressOnceSelectedSet] = useState<boolean>(false);


    const [formReset, formResetSet] = useState<boolean>(false);

    const [showMore, showMoreSet] = useState<boolean>(false);
    const [contentHeight, contentHeightSet] = useState<number>();




    const [minToTime, minToTimeSet] = useState<number>();



    const [bodyChanged, bodyChangedSet] = useState<boolean>(false);
    useEffect(() => {
        if (!window.isNullOrEmpty(address?.id) || !window.isNullOrEmpty(phone?.phone)
            || !window.isNullOrEmpty(fullName) || !window.isNullOrEmpty(jobSourceId)
            || !window.isNullOrEmpty(jobTypeId) || !window.isNullOrEmpty(jobDetails)) bodyChangedSet(true);
    }, [address, phone, fullName, jobSourceId, jobTypeId, jobDetails])



    const [keepOpen, keepOpenSet] = useState<boolean>(false);

    const [submiting, submitingSet] = useState<boolean>(false);
    const [sending, sendingSet] = useState<boolean>(false);

    const [contactJobs, contactJobsSet] = useState<Job[]>([]);



    useEffect(() => {
        if (props.duplicateId) {
            duplicateLoadingSet(true);

            hub.Get(API_ROUTES.Jobs + props.duplicateId + "?addmetros=True", res => {
                if (res.success) {
                    duplicateModelSet(res.data);
                    duplicateLoadingSet(false);
                }
            });
        }
    }, [props.duplicateId])

    useEffect(() => {
        if (duplicateModel) {

            phoneSet(duplicateModel.phone)
            fullNameSet(duplicateModel.fullName)
            addressSet(duplicateModel.address)
            contactPhoneSet(duplicateModel.phoneContact)
            emailSet(duplicateModel.emailAddress)
            companySet(duplicateModel.company)
            jobSourceIdSet(duplicateModel.jobSourceId)
            jobTypeIdSet(duplicateModel.jobTypeId)
            selectedStateSet(duplicateModel.stateId)

            hub.Get(API_ROUTES.Jobs + "contactActiveJobs/" + duplicateModel.contactId,
                res => {
                    if (res.success) contactJobsSet(res.data);
                    else contactJobsSet([]);
                });

        }
    }, [duplicateModel])


    useEffect(() => {
        if (props.chatMessageId) {
            buildJobMapSet(false);
            duplicateLoadingSet(true);
            hub.Get(API_ROUTES.Jobs + "msgtojob/" + props.chatMessageId, res => {
                if (res.success) {
                    fullNameSet(res.data.name);
                    var c = getCountryByCode(res.data.country);
                    if (c) {
                        countrySet(c);
                    }
                    phoneSet({
                        countryCode: "+1",
                        countryId: c?.id,
                        phone: res.data.phone
                    })
                    jobDetailsSet(res.data.jobDetails);
                    jobTypeIdSet(res.data.jobTypeId);
                    appointmentDateSet(res.data.appointmentDate);
                    appointmentTimeFromSet(res.data.appointmentTimeFrom);
                    jobSourceIdSet(res.data.jobSourceId);
                    addressSet(res.data.addressItem);
                }
                duplicateLoadingSet(false)
                buildJobMapSet(true)
            });
        }
    }, [props.chatMessageId])



    const mapWidth = () => {
        var winWidth = (globalState.windowsWidth ?? 1000);
        if (winWidth > 1600) return winWidth / 2.5;
        else return winWidth / 1;
    }

    const mapHeight = () => {
        var winWidth = (globalState.windowsWidth ?? 1000);
        var winHeight = (globalState.windowsHeight ?? 760);
        if (winWidth > 1600) return winHeight;
        else return winHeight / 3;
    }

    const setHeight = () => {
        var h = (screen.height - 100);
        //if (h > 900) h = 900;
        console.log("contentHeight", h)
        contentHeightSet(h);
    }

    // const [editModel, editModelSet] = useState<Job>();
    // const [fetching, fetchingSet] = useState<boolean>();
    // useEffect(() => {
    //     if (globalState.editJob && globalState.editJobId) {
    //         fetchingSet(true);
    //         hub.Get(API_ROUTES.Jobs + globalState.editJobId, res => {
    //             editModelSet(res.data);
    //             fetchingSet(false);
    //         })
    //     }
    //     else {
    //         editModelSet(undefined);
    //     }
    // }, [globalState.editJob, globalState.editJobId])





    useEffect(() => {

        setHeight();
        fetchMetros();

        window.removeEventListener("resize", setHeight);

        var k = window.localStorage.getItem("dspvoice-job-form-keepopen");
        if (k && k == "1") keepOpenSet(true);


    }, [])



    useEffect(() => {
        window.localStorage.setItem("dspvoice-job-form-keepopen", keepOpen ? "1" : "0");
    }, [keepOpen])



    useEffect(() => {
        if (buildJobMap) {
            setTimeout(() => {
                buildJobMapSet(false);
            }, 1000);
        }

        if (formReset) {
            setTimeout(() => {
                formResetSet(false);
            }, 1000);
        }

    }, [buildJobMap, formReset])


    useEffect(() => {
        if (address) {
            window.hideFormError("jobAddress");
            selectedStateSet(address.address?.stateId);
            addressOnceSelectedSet(true);
        }
    }, [address])


    useEffect(() => {
        if (address && metros) {
            metros.forEach(mt => {
                if (mt.id == address.metroId) {
                    selectedMetroSet(mt);
                }
            });
        }
        else if (addressOnceSelected) {
            selectedMetroSet(undefined);
        }
    }, [address, metros])



    const [submitAndSend, submitAndSendSet] = useState<boolean>();
    useEffect(() => {
        if (submitAndSend) {
            submit(true)
        }
    }, [submitAndSend])

    const submit = (send?: boolean, anyway?: boolean) => {

        let valid = true;
        let focused = false;
        window.hideFormErrors();

        if (!phone) {
            window.showFormError("phoneNumber", "Please write a valid phone number!", false, "modal-job-form", focused);
            valid = false;
            focused = true;
        }

        if (!fullName || fullName.length < 2) {
            window.showFormError("fullname", "Please write a valid name!", false, "modal-job-form", focused);
            valid = false;
            focused = true;
        }

        if (window.isNullOrEmpty(jobSourceId)) {
            window.showFormError("jobSourceId", "Required!", false, "modal-job-form", focused);
            valid = false;
            focused = true;
        }

        if (window.isNullOrEmpty(jobTypeId)) {
            window.showFormError("jobTypeId", "Required!", false, "modal-job-form", focused);
            valid = false;
            focused = true;
        }



        if (send) {

            if (!address && (customAddress?.length ?? 0) < 5) {
                window.showFormError("jobAddress", "Please write a valid address!", false, "modal-job-form", focused);
                valid = false;
                focused = true;
            }

            if (!selectedMetro) {
                window.showFormError("metroId", "Required!", false, "modal-job-form", focused);
                valid = false;
                focused = true;
            }

            if (!selectedTech) {
                window.showFormError("techId", "Required!", false, "modal-job-form", focused);
                valid = false;
                focused = true;
            }
            // if (!body.jobDetails) {
            //     window.showFormError("jobDetails", "Please write the job details!", false, "modal-job-form");
            //     valid = false;
            // }
        }

        // if (appointmentDate && !selectedTech) {
        //     if (!selectedTech) {
        //         window.showFormError("techId", "Required!", false, "modal-job-form", focused);
        //         valid = false;
        //         focused = true;
        //     }
        // }

        //if (selectedStatus?.isAppointment || selectedStatus?.isInProgress) {
        if (false) {

            if (!selectedMetro) {
                window.showFormError("metroId", "Required!", false, "modal-job-form", focused);
                valid = false;
                focused = true;
            }

            if (!selectedTech) {
                window.showFormError("techId", "Required!", false, "modal-job-form", focused);
                valid = false;
                focused = true;
            }

            if (window.isNullOrEmpty(appointmentDate)) {
                window.showFormError("appointmentDate", "Please set the appointment date!", false, "modal-job-form", focused, true);
                valid = false;
                focused = true;
            }
        }

        if (!valid) {
            submitAndSendSet(false);
            return;
        }

        if (!address && !anyway) {
            window.openModal("modal-job-address-incomplete");
            return;
        }

        if (send) sendingSet(true);
        else submitingSet(true);

        submitAndSendSet(false);

        let body: JobRequestModel = {
            phone: phone,
            fullName: fullName,
            addressId: address?.id,
            company: company,
            contactId: contact?.id,
            emailAddress: email,
            contactPhone: contactPhone,
            customAddress: customAddress,
            countryId: country.id,
            jobDetails: jobDetails,
            flagIds: flags,
            tagIds: tags,
            jobSourceId: jobSourceId,
            jobTypeId: jobTypeId,
            metroId: selectedMetro?.id,
            techId: selectedTech?.id,
            send: send ?? false,
            appointmentDate: appointmentDate,
            appointmentTimeFrom: appointmentTimeFrom,
            appointmentTimeTo: appointmentTimeTo,
            appointmentReminder: sendAppointmentReminder,
            appointmentReminderTime: appointmentReminderTime,
            jobStatusId: selectedStatus?.id,
            stateId: selectedState,
            zipCode: zipcode,
            mapZoom: mapZoom
        }

        hub.Post("/crm/jobs/", body, res => {

            if (res.success) {
                if (send) {
                    toastSuccess("Job has been sent successfully!");
                }
                else {
                    toastSuccess("Job has been submited successfully!");
                }

                window.closeModal("modal-job-address-incomplete");

                if (!keepOpen) {
                    window.closeModal("modal-job-form");
                }
                else {
                    $("#job-form-phonenumber").focus();
                }
            }
            else {
                toastError(res.message);
            }

            sendingSet(false);
            submitingSet(false);

        })

    }

    return (
        <>
            <Modal id="modal-job-form"
                title="Create Job"
                size="XXLarge"
                width={"90%"}
                visible open
                onOpen={() => {
                    setTimeout(() => {
                        buildJobMapSet(true);
                        window.KTMenuCreate();
                        $("#job-form-phonenumber").focus()
                    }, 50);
                }}
                onClose={() => {
                    closeJob();
                }}
                noPadding noFooter noHeader staticBackdrop>

                {
                    duplicateLoading ?
                        <div className="p-20">
                            <Indicator text="Please wait..." />
                        </div>
                        :
                        <div className="row">

                            <div className="col-lg-8 col-xxl-7 px-8 pb-0" style={{
                                height: contentHeight,
                                overflowX: "hidden"
                            }}>

                                <FlexRow className="py-4 py-xxl-6 border-bottom-0">
                                    <div>
                                        <h2 className="fs-1 fs-xxxl-1 modal-title d-flex align-items-center">
                                            Create Job
                                        </h2>
                                    </div>
                                    <div className="ms-auto">
                                        <IconButton icon="times"
                                            onClick={e => {
                                                if (bodyChanged) {
                                                    window.openModal("modal-discard-job");
                                                }
                                                else {
                                                    window.closeModal("modal-job-form");
                                                }
                                            }}
                                        />
                                    </div>
                                </FlexRow>


                                <div>

                                    {
                                        contactJobs.length > 0 &&
                                        <div className="alert alert-warning align-items-center d-flex">
                                            <Icon icon="triangle-exclamation" size={22} className="me-3" />
                                            <div className="fs-7">There is <b>{contactJobs.length}</b> active job already assigned to this client!</div>
                                            <div className="ms-auto">
                                                <a className="btn btn-primary btn-sm" target="_blank" href={"/crm/jobs?Keywords=" + phone?.phone}>View Jobs</a>
                                            </div>
                                        </div>
                                    }

                                    <div className="row">
                                        <div className="col-md-6 col-xxl-6">
                                            <PhoneInput id="job-form-phonenumber" label="Phone Number *" name="phoneNumber" parent="modal-job-form"
                                                labelUp block autoComplete noMarginBottom country={phoneCountry}
                                                defaultPhone={phone} defaultCountry={getCountryCache()}
                                                onCountrySelect={c => phoneCountrySet(c)} tabIndex={1}
                                                onChange={e => {
                                                    phoneSet(e);
                                                    contactJobsSet([]);
                                                }}
                                                onSelect={e => contactSet(e)} />
                                        </div>
                                        <div className="col-md-6 col-xxl-6">
                                            <TextBox label="Full Name *" name="fullname" labelUp block marginBottom={0} value={fullName}
                                                placeholder="Write the customer name" tabIndex={2} onTextChanges={e => fullNameSet(e)} />
                                        </div>
                                    </div>

                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-md-12">
                                            <AddressField id="jobform-address" label="Address *" name="jobAddress" block labelUp
                                                defaultCountry={getCountryCache()} country={mapCountry} tabIndex={6}
                                                //addressId={duplicateModel?.addressId}
                                                selectedAddress={address} verify
                                                placeholder="Search by Zip Code, Building Number, Street..."
                                                reset={formReset} parent="modal-job-form"
                                                onCountrySelect={c => mapCountrySet(c)}
                                                onSelect={e => addressSet(e)}
                                                onUnselect={() => addressSet(undefined)}
                                                onTextChanged={e => customAddressSet(e)}
                                                noMarginBottom />
                                        </div>
                                    </div>

                                    <div id="job-form-client-more-details" className={`${screen.height < 750 ? "mt-2" : "mt-4"}`} style={{ display: "none" }}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <PhoneInput label="Secondary Phone" labelUp block country={country}
                                                    noMarginBottom reset={formReset} tabIndex={3} defaultPhone={contactPhone}
                                                    onChange={e => contactPhoneSet(e)} />
                                            </div>
                                            <div className="col-md-4">
                                                <TextBox label="Company" labelUp block marginBottom={0} tabIndex={4}
                                                    value={company} placeholder="Write the comapny name"
                                                    onTextChanges={e => companySet(e)} />
                                            </div>
                                            <div className="col-md-4">
                                                <TextBox label="Email Address" labelUp block marginBottom={0} tabIndex={5}
                                                    value={email} placeholder="Write the customer email address"
                                                    onTextChanges={e => emailSet(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div id="job-form-client-more-details-btn" className={`row ${screen.height < 750 ? "" : "mt-2"}`}>
                                        <div className="col-12">
                                            <a href="#" className="fs-8" tabIndex={-1}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (showMore) {
                                                        showMoreSet(false);
                                                        $("#job-form-client-more-details").slideUp(200);
                                                    }
                                                    else {
                                                        showMoreSet(true);
                                                        $("#job-form-client-more-details").slideDown(200);
                                                    }

                                                }}>
                                                {
                                                    showMore ? <><Icon icon="chevron-up" className="me-1" size={11} /> Show Less</>
                                                        : <><Icon icon="chevron-down" className="me-1" size={11} /> Show More</>
                                                }
                                            </a>
                                        </div>
                                    </div>


                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <DropDown id="drp-jobsources" name="jobSourceId" label="Job Source" labelUp marginBottom={0} parentModal="modal-job-form" deselect
                                                        apiSource={API_ROUTES.TagifyJobSources} dataText="title" dataValue="value" reset={formReset} defaultValue={jobSourceId} tabIndex={7}
                                                        onSelect={val => jobSourceIdSet(val)}
                                                        onClear={() => {
                                                            jobSourceIdSet(undefined)
                                                        }} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DropDown id="drp-jobtypes" name="jobTypeId" label="Job Type" labelUp marginBottom={0} tabIndex={8}
                                                        apiSource={API_ROUTES.TagifyJobTypes} dataText="title" dataValue="value" parentModal="modal-job-form"
                                                        defaultValue={jobTypeId} reset={formReset} deselect
                                                        onSelect={val => jobTypeIdSet(val)}
                                                        onClear={() => {
                                                            jobTypeIdSet(undefined)
                                                        }} />
                                                </div>

                                                <div className="col-md-3">
                                                    <DropDown id="drp-metros" label="Metro" name="metroId" labelUp marginBottom={0} source={metros} parentModal="modal-job-form"
                                                        defaultValue={selectedMetro?.id} reset={formReset} deselect tabIndex={9}
                                                        onSelectItem={e => selectedMetroSet(e)}
                                                        onClear={() => {
                                                            selectedMetroSet(undefined)
                                                        }}>
                                                    </DropDown>
                                                </div>

                                                <div className="col-md-3">
                                                    <DropDown id="drp-technicians" label="Technician" labelUp marginBottom={0} parentModal="modal-job-form"
                                                        source={techs} dataText="fullName" dataValue="id" name="techId" tabIndex={10}
                                                        defaultValue={selectedTech?.id} reset={formReset} deselect
                                                        onSelectItem={e => selectedTechSet(e)}
                                                        onClear={() => selectedTechSet(undefined)} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-12">
                                            <TextArea id="txt-jobdetails" name="jobdetails" label="Job Details"
                                                labelUp block rows={screen.height < 750 ? 3 : 6} marginBottom={0} value={jobDetails}
                                                placeholder="Describe about the job..." tabIndex={11}
                                                onChange={e => jobDetailsSet(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-12">

                                            <Tagify id="tagify-job-flags"
                                                source={cash?.flags}
                                                //apiUrl={API_ROUTES.TagifyFlags}
                                                placeholder="Select Flag(s)" icon="flag"
                                                labelUp noMarginBottom defaultIds={flags} clear={formReset}
                                                onChange={ids => flagsSet(ids)}
                                            />

                                        </div>
                                    </div>

                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-12">

                                            <Tagify id="tagify-job-tags"
                                                source={cash?.tags}
                                                placeholder="Select or Enter Tag(s)" icon="tag"
                                                freeTags labelUp noMarginBottom defaultIds={tags} clear={formReset}
                                                onChange={ids => tagsSet(ids)}
                                            />

                                        </div>
                                    </div>

                                    <div className={`row ${screen.height < 750 ? "mt-2" : "mt-4"}`}>
                                        <div className="col-md-12">
                                            <FlexRow>

                                                <DatePicker id="dt-jobappoitment" name="appointmentDate" drops="up"
                                                    placeholder="Appointment Date" icon="calendar-clock" marginBottom={0}
                                                    labelUp block empty selectedDate={appointmentDate}
                                                    onPick={e => appointmentDateSet(e.value)}
                                                    onClear={() => appointmentDateSet(undefined)} />

                                                <div className="w-150px ms-2">
                                                    <TimePicker id="job-form-time-appointmentFrom" name="appointmentFrom" noMarginBottom
                                                        labelUp defaultValue={appointmentTimeFrom} placeholder="Arrival From" parentId="modal-job-form"
                                                        onTimePicked={t => {
                                                            appointmentTimeFromSet(t.label)
                                                            minToTimeSet(t.time)
                                                            console.log("let's make a select2 for", t);
                                                        }}
                                                        onClear={() => {
                                                            appointmentTimeFromSet(undefined)
                                                            minToTimeSet(0)
                                                        }} />
                                                </div>

                                                <div className="w-150px ms-2">
                                                    <TimePicker id="job-form-time-appointmentTo" name="appointmentTo" min={minToTime} noMarginBottom nextDay
                                                        labelUp defaultValue={appointmentTimeTo} placeholder="Arrival To" time={minToTime ? (minToTime + 60) : undefined}
                                                        onTimePicked={t => appointmentTimeToSet(t.label)} parentId="modal-job-form"
                                                        onClear={() => appointmentTimeToSet(undefined)} />
                                                </div>

                                            </FlexRow>

                                            <FlexRow className="mt-10">
                                                {
                                                    appointmentDate && appointmentTimeFrom && appointmentTimeTo &&
                                                    <div className="me-2">
                                                        <ToggleSwitch label="Send Reminder to Technician!" raw small
                                                            checked={sendAppointmentReminder} onChange={e => sendAppointmentReminderSet(e)} />
                                                    </div>
                                                }

                                                {
                                                    sendAppointmentReminder &&
                                                    <div className="w-200px">
                                                        <TextBox name="appointmentReminderTime" block marginBottom={0}
                                                            numeric maxLength={3} iconText="Minutes Before"
                                                            value={appointmentReminderTime} onTextChanges={e => appointmentReminderTimeSet(Number.parseInt(e))} />
                                                    </div>
                                                }
                                            </FlexRow>

                                        </div>
                                        <div className="col-md-3 col-xxl-2">

                                        </div>
                                        <div className="col-md-3 col-xxl-2">

                                        </div>
                                    </div>

                                    <div className="row border-top pt-xxl-6 mt-xxl-6 pt-2 mt-2">
                                        <div className="col-md-6">

                                            <FlexRow>
                                                <div>
                                                    <button type="button" className="btn btn-bg-primary btn-sm"
                                                        style={{
                                                            backgroundColor: selectedStatus?.color,
                                                            color: "#fff"
                                                        }}
                                                        data-kt-menu-trigger="click"
                                                        data-kt-menu-placement="bottom-start">
                                                        {selectedStatus?.name}
                                                        <span className="ms-3">
                                                            <Icon icon="chevron-down" color="white" />
                                                        </span>
                                                    </button>
                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                                        {
                                                            statuses?.map((item, key) =>
                                                                <div key={key} className="menu-item">
                                                                    <a href="#" className="menu-link px-3" onClick={e => {
                                                                        e.preventDefault()
                                                                        selectedStatusSet(item)
                                                                    }}>
                                                                        <Icon icon="circle" type="solid" className="me-2" color={item.color} />
                                                                        {item.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-custom form-check-solid form-check-sm ms-3">
                                                    <input className="form-check-input" type="checkbox"
                                                        checked={keepOpen} id="chk-job-form-keep-it-open"
                                                        onChange={e => keepOpenSet(e.target.checked)} />
                                                    <label className="form-check-label ms-1" htmlFor="chk-job-form-keep-it-open">
                                                        Create Another Job
                                                    </label>
                                                </div>
                                            </FlexRow>


                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="button" className="btn btn-secondary btn-sm"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (bodyChanged) {
                                                        window.openModal("modal-discard-job");
                                                    }
                                                    else {
                                                        window.closeModal("modal-job-form");
                                                    }
                                                }}>
                                                Discard
                                            </button>
                                            <button type="button" className="btn btn-success btn-sm ms-2 w-125px" disabled={(submiting || sending)} onClick={e => submitAndSendSet(true)}>
                                                {
                                                    sending ?
                                                        <>
                                                            <Icon icon="spinner-third" spin />
                                                            <span className="ms-2">
                                                                Please Wait
                                                            </span>
                                                        </>
                                                        : <>Submit & Send</>
                                                }
                                            </button>
                                            <button type="button" className="btn btn-primary btn-sm ms-2 w-125px" disabled={(submiting || sending)} onClick={e => submit()}>
                                                {
                                                    submiting ?
                                                        <>
                                                            <Icon icon="spinner-third" spin />
                                                            <span className="ms-2">
                                                                Please Wait
                                                            </span>
                                                        </>
                                                        : <>Submit</>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                </div>



                            </div>

                            <div className="col-lg-4 col-xxl-5 position-relative">

                                <Map id="job-form-map" build={buildJobMap} country={mapCountry}
                                    height={contentHeight} techs={techs} selectedTech={selectedTech}
                                    //draggableMarker={address?.position} center={address?.position}
                                    metros={metros} selectedMetro={selectedMetro} client={address}
                                    onZoomChanged={e => mapZoomSet(e)}
                                    onClientUpdated={e => addressSet(e)}
                                    onSelectMetro={mtr => selectedMetroSet(mtr)}
                                    onSelectTech={tech => selectedTechSet(tech)} />

                                {
                                    selectedMetro?.description && selectedMetro.description.length > 0 &&
                                    <div className="bg-light" style={{
                                        position: "absolute",
                                        zIndex: 2000,
                                        bottom: 20,
                                        left: 30,
                                        width: "60%",
                                        minHeight: 100,
                                        padding: 10,
                                        opacity: .8
                                    }}>
                                        <FlexRow className="mb-2">
                                            <Icon icon="circle" type="solid" color={selectedMetro?.color} className="me-2" />
                                            <span className="fw-bolder">{selectedMetro.name}</span>
                                        </FlexRow>
                                        <div className="fs-7 line-break">
                                            {selectedMetro.description}
                                        </div>
                                    </div>
                                }



                            </div>


                        </div>
                }


            </Modal>



            <Modal id="modal-job-address-incomplete"
                title="Incomplete Address"
                titleIcon="triangle-exclamation"
                buttonText="Submit Anyway"
                cancelText="Fix the Address"
                buttonColor="warning" secondModal
                onConfirm={callback => {
                    submit(submitAndSend, true)
                    callback()
                }}>
                <div className="mb-5">
                    The job address appears to be incomplete. You can still submit the job, but please double-check to ensure accuracy and completeness.
                </div>

                <TextBox id="txt-incompleted-address" value={customAddress} label="Address" labelUp />

                <div className="row">
                    <div className="col-md-6">
                        <TextBox id="txt-incompleted-zipcode" value={zipcode}
                            label="Zip Code *" labelUp onTextChanges={zip => {
                                if (zip.length > 4) {
                                    hub.LookUpZipcode(zip, country.alpha3Code ?? "usa", res => {
                                        zipcodeSet(zip);
                                        selectedStateSet(res?.address?.stateId);
                                    })
                                }
                                else {
                                    zipcodeSet(zip);
                                    selectedStateSet(undefined);
                                }

                            }} />
                    </div>
                    <div className="col-md-6">
                        <DropDown id="drp-incompleted-states" forceSelectValue={selectedState}
                            source={states} parentModal="modal-job-address-incomplete"
                            label="State" labelUp />
                    </div>
                </div>

            </Modal>


            <Modal id="modal-discard-job"
                title="Discard Job"
                buttonText="Discard Job"
                cancelText="Cancel"
                buttonColor="warning" secondModal
                onConfirm={callback => {
                    callback(true);
                    window.closeModal("modal-discard-job");
                    window.closeModal("modal-job-form");
                }}>
                <div>
                    Are you sure you want to discard the changes and close the form?
                </div>
            </Modal>

        </>
    )
}