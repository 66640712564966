import { useEffect, useRef, useState } from "react"
import Job from "../models/Job"
import FlexRow from "../../../../components/base/FlexRow"
import { openCloseJob, openInvalidCloseJob, useHub, useSocket, closeJobQuickView, useChatList, openCopyJob, useJob, useCashedData, hasRole } from '../../../../core/Global';
import DataItem from "../../../../models/DataItem"
import Icon from "../../../../components/base/Icon"
import IconButton from "../../../../components/base/IconButton"
import CallsList from "../../../../components/calls/CallsList"
import JobChat from "./JobChat"
import JobLogsContainer from "./JobLogsContainer"
import JobDrawerEdit from "./JobDrawerEdit"
import { API_ROUTES } from "../../../../core/Constants"
import JobDrawerNotes from "./jobdrawer/JobDrawerNotes"
import { useNavigate } from "react-router-dom"
import DropdownMenu from "../../../../components/menu/DropdownMenu"
import { copyToClipboard } from "../../../../core/Helper";
import Indicator from "../../../../components/base/Indicator";

interface IProps {
    id: string
    tab: string
    onClose: () => void
}

export default function JobDrawerView(props: IProps) {


    const hub = useHub()
    const cash = useCashedData()
    const socket = useSocket()
    const navigate = useNavigate()
    const chats = useChatList()

    const [loading, loadingSet] = useState<boolean>(false)
    const [notfound, notfoundSet] = useState<boolean>(false)

    const jb = useJob(props.id)
    const [job, jobSet] = useState<Job>()


    const fetch = () => {

        notfoundSet(false)
        loadingSet(true)

        hub.Get(API_ROUTES.Jobs + props.id, res => {
            if (res.success) {
                jobSet(res.data)
                loadingSet(false)
            }
            else {
                loadingSet(false)
                notfoundSet(true)
            }
        })

    }

    useEffect(() => {
        if (notfound) {
            setTimeout(() => {
                window.closeJobDrawer(() => closeJobQuickView())
            }, 1000);
        }
    }, [notfound])

    useEffect(() => {
        if (!jb) fetch()
    }, [])


    useEffect(() => {
        if (jb) jobSet(jb)
    }, [jb])



    const [Tabs] = useState<DataItem[]>([
        {
            id: "1",
            name: "Job"
        },
        {
            id: "2",
            name: "Notes"
        },
        // {
        //     id: "3",
        //     name: "Tasks"
        // },
        {
            id: "4",
            name: "Chat"
        },
        {
            id: "5",
            name: "Calls"
        },
        {
            id: "99",
            name: "Logs"
        }
    ])



    const [selectedTab, selectedTabSet] = useState<string>(props.tab)

    const [boxHeight, boxHeightSet] = useState<number>()
    const setHeight = () => {
        var h = $(window).height() ?? 400;
        h = h - ($("#kt_drawer_job_header").outerHeight() ?? 50);
        h = h - ($("#kt_drawer_job_tabs").outerHeight() ?? 50);
        boxHeightSet(h)
    }


    useEffect(() => {
        window.KTDrawerCreate();
        window.openJobDrawer(props.onClose);
        setHeight()
        window.addEventListener("resize", setHeight);

        return () => {
            window.removeEventListener("resize", setHeight);
        }
    }, [])

    useEffect(() => {
        if (job) {

            var socketKey = socket.start(
                skt => {

                    if (job.isArchived) {
                        skt.on("ArchiveJobUpdated-" + job.id, data => {
                            jobSet(data)
                        });
                    }

                    skt.invoke("StartListenToJobCalls", job.id)
                        .then(() => {
                            skt.on("PingJobCalls-" + job.id, () => {
                                refreshCallsSet(true);
                            });
                        });
                }, skt => {
                    skt.off("ArchiveJobUpdated-" + job.id)
                    skt.invoke("StopListenToJobCalls", job.id)
                        .then(() => {
                            skt.off("PingJobCalls-" + job.id);
                        });
                })
            return () => {
                socket.stop(socketKey);
            }
        }
    }, [job])

    useEffect(() => {
        if (job) {
            window.dataTableMenu()
        }
    }, [job])


    const HasUnreadChat = () => {
        var res = false;
        chats?.forEach(c => {
            if (c.tech?.id && job?.techId && c.tech.id == job.techId && c.unreadCount && c.unreadCount > 0) {
                res = true;
                return;
            }
        })
        return res;
    }

    const HasChat = () => {
        var res = false;
        chats?.forEach(c => {
            if (c.tech?.id && job?.techId && c.tech.id == job.techId) {
                res = true;
                return;
            }
        })
        return res;
    }

    const [refreshCalls, refreshCallsSet] = useState<boolean>(false)
    useEffect(() => {
        if (refreshCalls) {
            setTimeout(() => {
                refreshCallsSet(false);
            }, 500);
        }
    }, [refreshCalls])


    return (
        <div id="kt_drawer_job" className="bg-body" data-kt-drawer="true" style={{ overflow: "hidden" }}
            data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'300px', 'md': '650px'}" data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#kt_drawer_job_toggle" data-kt-drawer-close="#kt_drawer_job_close">


            {
                loading ?
                    <div className="mt-20 text-center w-100">
                        <Indicator />
                    </div>
                    : notfound ?
                        <div className="mt-20 text-center w-100">
                            <div className="mb-5">
                                <Icon icon="exclamation-triangle" size={46} type="thin" className="text-warning" />
                            </div>
                            <div className="fs-4 ">
                                Job not found!
                            </div>
                        </div>
                        :
                        <div className="card shadow-none rounded-0 w-100">
                            <div id="kt_drawer_job_header" className="card-header py-3 px-5" style={{
                                minHeight: 75
                            }}>

                                <div className="flex-grow-1">
                                    <FlexRow>
                                        <FlexRow className="fs-1 fs-xxxl-1 modal-title m-0 me-3">
                                            #{job?.jobId}
                                        </FlexRow>
                                        <FlexRow className="flex-grow-1">

                                            {
                                                job?.flags?.map((item, k) =>
                                                    <span id={`job-flags-${job.id}-${item.id}`} key={k} className="badge badge-secondary me-1"
                                                        data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" data-bs-custom-class="tooltip-dark"
                                                        title={`${window.isNullOrEmpty(item.description) ? `` : `<div class='fs-8 fw-normal text-start line-break'>${item.description}</div>`}`}
                                                        style={{
                                                            fontSize: 11,
                                                            backgroundColor: item.color,
                                                            color: "#fff"
                                                        }}>
                                                        {item.name}
                                                        {
                                                            (!job?.isArchived || hasRole("EditArchivedJobs")) &&
                                                            <a href="#" className="ps-2" title="Remove Tag"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    hub.Post(API_ROUTES.Jobs + "removeflag", {
                                                                        jobId: job.id,
                                                                        flagId: item.id
                                                                    }, res => { })
                                                                }}>
                                                                <Icon icon="times" className="text-white fs-8" />
                                                            </a>
                                                        }
                                                    </span>
                                                )
                                            }

                                            {
                                                cash?.flags &&
                                                <>
                                                    {
                                                        (!job?.isArchived || hasRole("EditArchivedJobs")) && cash.flags.filter(f => !job?.flagIds?.includes(f.id)).length > 0 && ((job?.flags?.length ?? 0) < 3) &&
                                                        <div>
                                                            <a href="#" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
                                                                className="badge badge-secondary">
                                                                <Icon icon="plus" size={10} />
                                                                {
                                                                    (job?.flags?.length ?? 0) == 0 &&
                                                                    <span className="ms-1 fs-9 fw-bold">
                                                                        Add Flag
                                                                    </span>
                                                                }
                                                            </a>
                                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-0 m-0" data-kt-menu="true">
                                                                <div className="scroll-y noscrollbar" style={{
                                                                    maxHeight: 300
                                                                }}>
                                                                    {
                                                                        cash.flags.filter(f => !job?.flagIds?.includes(f.id)).map((f, k) =>
                                                                            <div key={k} className="menu-item m-0">
                                                                                <a href="#" className="menu-link px-3"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        hub.Post(API_ROUTES.Jobs + "addflag", {
                                                                                            jobId: job?.id,
                                                                                            flagId: f.id
                                                                                        }, res => { })
                                                                                    }}
                                                                                >
                                                                                    <Icon icon="flag" className="me-2" type="solid" color={f.color} />
                                                                                    {f.name}
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }


                                        </FlexRow>
                                    </FlexRow>
                                    <FlexRow>
                                        <Icon icon="location-dot" className="text-muted me-1" type="solid" />
                                        <span className="fs-8 max-200 text-muted limited-text">
                                            {job?.address?.title}
                                        </span>
                                    </FlexRow>
                                </div>

                                <div className="card-toolbar">
                                    {
                                        !job?.isClosed && !job?.isCanceled &&
                                        <IconButton icon="box-archive" size={18} gap={20} title="Close Job" type="regular"
                                            onClick={e => {
                                                hub.Get(API_ROUTES.Jobs + "validateclosing/" + job?.id, res => {
                                                    if (job) {
                                                        if (res.data) {
                                                            openCloseJob(job)
                                                        }
                                                        else {
                                                            openInvalidCloseJob(job, res.message)
                                                        }
                                                    }
                                                })
                                            }} />
                                    }
                                    <div>
                                        <button type="button" title="Copy Job"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                            className={"btn btn-sm btn-white btn-icon"}
                                            style={{
                                                borderRadius: "50%",
                                                width: 38,
                                                height: 38
                                            }}>
                                            <Icon icon="copy" type="regular" size={18} />
                                        </button>
                                        <DropdownMenu width={200}
                                            source={[
                                                {
                                                    title: "Copy with Direct Number",
                                                    icon: "phone-arrow-down-left",
                                                    async click(e) {
                                                        e.preventDefault();
                                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + job?.id + "/?directNumber=True")
                                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                                        if (!copy && job) openCopyJob(job);
                                                    },
                                                },
                                                {
                                                    title: "Copy with Extension",
                                                    icon: "phone-office",
                                                    async click(e) {
                                                        e.preventDefault();
                                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + job?.id + "/?directNumber=False")
                                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                                        if (!copy && job) openCopyJob(job);
                                                    }
                                                }
                                            ]} />
                                    </div>

                                    <IconButton icon="arrow-up-right-from-square" size={18} gap={20} title="View Job Details" type="regular"
                                        onClick={e => {
                                            window.closeJobDrawer(() => {
                                                navigate(`/crm/jobs/overview/${job?.id}`)
                                                closeJobQuickView()
                                            })
                                        }} />
                                    <IconButton id="kt_drawer_job_close" size={26} gap={14}
                                        icon="times" title="Close" type="light" />

                                </div>
                            </div>
                            <div className="card-body position-relative p-0" id="kt_drawer_calls_body">
                                <ul id="kt_drawer_job_tabs" className="nav nav-tabs nav-line-tabs fs-6 ps-5 mb-1">
                                    {
                                        Tabs.map((t, k) =>
                                            <li key={k} className="nav-item">
                                                <a href="#" className={`nav-link m-0 px-3 fw-bolder me-3 ${selectedTab == t.id ? "active" : ""}`}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        selectedTabSet(t.id!)
                                                    }}>
                                                    <FlexRow>
                                                        <span>{t.name}</span>

                                                        {
                                                            t.id == "2" &&
                                                            <>
                                                                {
                                                                    job?.unseenNotes ?
                                                                        <Icon icon="circle" type="solid" className="ms-1 mb-2 text-danger" size={7} />
                                                                        : job?.notesCount && job.notesCount > 0 ?
                                                                            <Icon icon="circle" type="solid" className="ms-1 mb-2 text-primary" size={5} />
                                                                            : <></>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            t.id == "4" &&
                                                            <>
                                                                {
                                                                    HasUnreadChat() ?
                                                                        <Icon icon="circle" type="solid" className="ms-1 mb-2 text-danger" size={7} />
                                                                        : HasChat() ?
                                                                            <Icon icon="circle" type="solid" className="ms-1 mb-2 text-primary" size={5} />
                                                                            : <></>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            t.id == "5" &&
                                                            <>
                                                                {
                                                                    job?.jobCalls && job.jobCalls.length > 0 && job.jobCalls.filter(f => f.isCompleted && (f.listeners?.length ?? 0) < 1).length > 0 ?
                                                                        <Icon icon="circle" type="solid" className="ms-1 mb-2 text-danger" size={7} />
                                                                        : job?.jobCalls && job.jobCalls.length > 0 ?
                                                                            <Icon icon="circle" type="solid" className="ms-1 mb-2 text-primary" size={5} />
                                                                            : <></>
                                                                }
                                                            </>
                                                        }
                                                    </FlexRow>
                                                </a>
                                            </li>
                                        )
                                    }

                                </ul>
                                {
                                    selectedTab == "1" && job && <JobDrawerEdit job={job} height={boxHeight}
                                        onWriteNote={() => selectedTabSet("2")}
                                    />
                                }

                                {
                                    selectedTab == "2" && job && <JobDrawerNotes job={job} />
                                }

                                {
                                    selectedTab == "4" && job && <JobChat job={job} />
                                }

                                {
                                    selectedTab == "5" && job &&
                                    <CallsList jobId={job.id} refresh={refreshCalls} noFilter noHeader compact isChild noMultipleAction />
                                }

                                {
                                    selectedTab == "99" && job &&
                                    <JobLogsContainer id="job-drawer-logs" className="p-5" jobId={job?.id} height={boxHeight} jobTimezoneOffset={job.timezoneOffset} />
                                }
                            </div>
                        </div>
            }



        </div>
    )
}