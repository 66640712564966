import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobal, useHub, useSocket, useWallet } from "../../../core/Global";
import WalletTransaction from "../../../models/WalletTransaction";
import FlexRow from "../../base/FlexRow";
import Icon from "../../base/Icon";


export default function UserWallet() {

    const [transactions, transactionsSet] = useState<WalletTransaction[]>([]);
    const hub = useHub();
    const wallet = useWallet();
    const socket = useSocket();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const fetchTransactions = () => {
        hub.Get("/accounts/profile/wallet/transactions", res => {
            transactionsSet(res.data);
        })
    }

    useEffect(() => {

        var socketKey = socket.start(
            skt => {
                skt.on("UpdateWallet", () => {
                    hub.UpdateWallet();
                });
            }, skt => {
                skt.off("UpdateWallet");
            });


        return () => {
            socket.stop(socketKey);
        };

    }, []);

    return (
        <div className="d-flex align-items-center ms-2 ms-lg-3 me-2">

            <div className="btn btn-icon btn-icon-muted btn-active-light h-30px h-md-40px position-relative d-flex align-items-center me-2"
                onClick={e => {
                    fetchTransactions();
                }}
                data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <Icon icon="wallet" type="solid" className="text-gray-600" size={16} />
                <span className="ms-2 fs-7 mt-1">
                    {moneyFormatter.format(wallet?.balance ?? 0)}
                </span>
            </div>

            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                <div className="menu-item">
                    <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px me-5">
                            <Icon icon="wallet" type="solid" className="text-gray-600" size={32} />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                {/* <Icon icon="dollar-sign" type="solid" className="text-gray-600" size={18} /> */}
                                <div className="fw-bolder fs-6 ms-1">
                                    {wallet?.balanceString}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator my-2"></div>
                {
                    transactions.map((item, key) =>
                        <div key={key} className="menu-item px-5 py-2">
                            <FlexRow>
                                <div>
                                    <div className={(item.deposit ? "text-success" : "text-danger") + " fs-7"}>
                                        {item.deposit ? "+" : "-"} {item.amountText}$
                                    </div>
                                    <div className="text-muted fs-9">
                                        {item.description}
                                    </div>
                                </div>
                                <div className="ms-auto text-muted fs-9 fw-normal">
                                    {item.shortDate}
                                </div>
                            </FlexRow>
                        </div>
                    )
                }

                <div className="separator my-2"></div>

                <div className="menu-item">
                    <Link to="/profile/subscription" className="menu-link px-5">Subscription</Link>
                </div>
                <div className="menu-item">
                    <Link to="/profile/wallet" className="menu-link px-5">Transactions</Link>
                </div>
                <div className="menu-item">
                    <Link to="/profile/invoices" className="menu-link px-5">Invoices</Link>
                </div>
            </div>
        </div>
    )
}