import { useEffect, useState } from "react";
import { toastSuccess } from "../../../../components/base/Toast";
import DropDown from "../../../../components/form/DropDown";
import Form from "../../../../components/form/Form";
import FormTitle from "../../../../components/form/FormTitle";
import TextBox from "../../../../components/form/TextBox";
import { API_ROUTES } from "../../../../core/Constants";
import { getUser, useHub, usePreferences } from "../../../../core/Global";
import Icon from "../../../../components/base/Icon";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import { JobsPreferencesModel } from "../../../../models/AccountPreferences";



export default function CRMJobBoardSettings() {

    const hub = useHub();
    const user = getUser();
    const pref = usePreferences();
    const [working, workingSet] = useState<boolean>(false);

    const [body, bodySet] = useState<JobsPreferencesModel>({});

    useEffect(() => {
        if (pref) bodySet(pref.jobsPreferences);
    }, [pref])



    return (
        <Form className="form"
            onSubmit={(e, callback) => {
                workingSet(true);
                hub.Post("/system/Settings/jobpreferences", body, res => {
                    if (res.success) {
                        toastSuccess("Jobs preferences has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            Jobs Preferences
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Preferences</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <ToggleSwitch label="Limit the technicians by 'Job Type' while creating a job!"
                        name="limitTechsByJobType" raw small className="mb-5"
                        checked={body.limitTechsByJobType}
                        onChange={e => bodySet({
                            ...body,
                            limitTechsByJobType: e
                        })} />

                    <ToggleSwitch label="Limit the technicians by 'Job Source' while creating a job!"
                        name="limitTechsByJobSource" raw small className="mb-5"
                        checked={body.limitTechsByJobSource}
                        onChange={e => bodySet({
                            ...body,
                            limitTechsByJobSource: e
                        })} />

                    <ToggleSwitch label="Limit the technicians by 'Metro' while creating a job!"
                        name="limitTechsByMetro" raw small className="mb-5"
                        checked={body.limitTechsByMetro}
                        onChange={e => bodySet({
                            ...body,
                            limitTechsByMetro: e
                        })} />

                    <ToggleSwitch label="Automatically move jobs to 'Submitted' when there is less than 1 hour to appointment!"
                        name="popupJobsToSubmitted" raw small className="mb-5"
                        checked={body.popupJobsToSubmitted}
                        onChange={e => bodySet({
                            ...body,
                            popupJobsToSubmitted: e
                        })} />

                    <ToggleSwitch label="Automatically move jobs to 'In Progress' when technician accepted the job!"
                        name="moveToInProgressWhenTechAccept" raw small className="mb-5"
                        checked={body.moveToInProgressWhenTechAccept}
                        onChange={e => bodySet({
                            ...body,
                            moveToInProgressWhenTechAccept: e
                        })} />

                    <ToggleSwitch label="Automatically send 'Close Job' link when technician accepted the job!"
                        name="sendCloseJobLinkWhenTechAccept" raw small className="mb-5"
                        checked={body.sendCloseJobLinkWhenTechAccept}
                        onChange={e => bodySet({
                            ...body,
                            sendCloseJobLinkWhenTechAccept: e
                        })} />

                    <ToggleSwitch label="Allow technician to close the job directly with default rates!"
                        name="allowTechToCloseJobDirectly" raw small className="mb-5"
                        checked={body.allowTechToCloseJobDirectly}
                        onChange={e => bodySet({
                            ...body,
                            allowTechToCloseJobDirectly: e
                        })} />




                </div>
            </div>
        </Form>
    )
}