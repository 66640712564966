import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Modal from "../../../../../components/base/Modal";
import Checkbox from "../../../../../components/form/Checkbox";
import Tagify from "../../../../../components/tagify/Tagify";
import { API_ROUTES } from "../../../../../core/Constants";
import { useHub } from "../../../../../core/Global";
import { JobsReportRequest, JobsReportSort } from "../../models/JobsReportRequest";
import { toastSuccess } from "../../../../../components/base/Toast";
import TextArea from "../../../../../components/form/TextArea";
import { DataColumnsVisibility, JobsColumnsVisibility, TechPayColumnsVisibility } from "../../models/JobReportModel";


interface IProps {
    body: JobsReportRequest
    sort: JobsReportSort
    techId?: string
    query?: string
    jobVis?: JobsColumnsVisibility
    dataVis?: DataColumnsVisibility
    techVis?: TechPayColumnsVisibility
    defaultSegmentId?: string
    onSent?: () => void
    onClose?: () => void
}

export default function JobReportExportModal(props: IProps) {

    const hub = useHub()

    const [pdf, pdfSet] = useState<boolean>(true)
    const [csv, csvSet] = useState<boolean>(false)
    useEffect(() => {
        window.hideFormError("format")
    }, [pdf, csv])


    const [recipients, recipientsSet] = useState<string[]>()

    useEffect(() => {
        if (props.techId) {
            recipientsSet([props.techId])
        }
    }, [props.techId])

    return (
        <Modal id="modal-job-reports-export"
            title="Send Report"
            titleIcon="file-export"
            open visible staticBackdrop
            buttonIcon="share"
            buttonText="Send Files"
            secondActionIcon="arrow-down-to-line"
            secondActionColor="google"
            secondActionText="Download Files"
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                var q = "?a=1";
                var valid = true;

                if (!pdf && !csv) {
                    window.formError("format", "Please select at least 1 export format!", "modal-job-reports-export")
                    valid = false;
                }

                if (!recipients || recipients.length < 1) {
                    window.formError("recipients", "Please choose at least 1 recipient", "modal-job-reports-export")
                    valid = false;
                }

                if (!valid) {
                    callback!()
                    return
                }

                if (props.query) {
                    q += "&" + props.query;
                }

                if (csv) q += "&csv=True"
                if (pdf) q += "&pdf=True"

                if (props.jobVis)
                    q += "&" + Object.entries(props.jobVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                if (props.dataVis)
                    q += "&" + Object.entries(props.dataVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                if (props.techVis)
                    q += "&" + Object.entries(props.techVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                hub.Post("/reports/jobs/export/" + q, {
                    ...body,
                    ...props.body,
                    ...props.sort,
                    segmentIds: props.defaultSegmentId ? [props.defaultSegmentId] : props.body.segmentIds,
                    recipients,
                    exportAndSend: true
                }, res => {
                    if (res.success) {
                        toastSuccess("Job reports has been send successfully!")
                        if (props.onSent) props.onSent();
                    }
                    callback!(res)
                })

            }}
            onSecondConfirm={callback => {

                window.hideFormErrors();

                var valid = true;
                var q = "?a=1";

                if (!pdf && !csv) {
                    window.formError("format", "Please select at least 1 export format!", "modal-job-reports-export")
                    valid = false;
                }

                if (props.jobVis)
                    q += "&" + Object.entries(props.jobVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                if (props.dataVis)
                    q += "&" + Object.entries(props.dataVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                if (props.techVis)
                    q += "&" + Object.entries(props.techVis)
                        .map(([key, value]) => `${key}=${value ? "True" : "False"}`)
                        .join("&")

                if (!valid) {
                    callback(true)
                    return
                }

                if (props.query) {
                    q += "&" + props.query;
                }

                if (csv) q += "&csv=True"
                if (pdf) q += "&pdf=True"

                hub.Post("/reports/jobs/export/" + q, {
                    ...props.body,
                    ...props.sort,
                    exportAndDownload: true
                }, res => {
                    if (res.success) {
                        if (res.data.pdf) {
                            window.open(res.data.pdf, "_blank")
                        }
                        if (res.data.csv) {
                            window.open(res.data.csv, "_blank")
                        }
                    }
                    callback()
                })

            }}>
            <div>

                <FlexRow className="mb-5 ">
                    <div className="fs-5 border-bottom">
                        File Format
                    </div>
                    <div id="format-err" className="small text-danger mx-3 form-error"></div>
                </FlexRow>

                <FlexRow className="mb-5">
                    <Checkbox label="PDF" checked={pdf} onChange={e => pdfSet(e)} className="me-5" />
                    <Checkbox label="CSV" checked={csv} onChange={e => csvSet(e)} />
                </FlexRow>

                {/* <div className="fs-5 mb-5 border-bottom">
                    Include
                </div>

                <FlexRow className="mb-10">
                    <Checkbox label="Metro" checked={metro} onChange={e=> metroSet(e)} className="me-5" />
                    <Checkbox label="Address" checked={address} onChange={e=> addressSet(e)} className="me-5" />
                    <Checkbox label="Job Source" checked={source} onChange={e=> sourceSet(e)} className="me-5" />
                    <Checkbox label="Client Name" checked={name} onChange={e=> nameSet(e)} className="me-5" />
                    <Checkbox label="Client Phone" checked={phone} onChange={e=> phoneSet(e)} className="me-5" />
                </FlexRow> */}
                <Tagify id="tagify-exrpot-emails" block freeTags email closeOnSelect
                    label="Send to" labelUp apiUrl={API_ROUTES.TagifyEmailsAutocomplete}
                    selectedId={props.techId}
                    onChange={e => recipientsSet(e)} />


                <TextArea name="message" block label="Message" labelUp rows={5}
                    placeholder="Add a Message (optional)..." />

            </div>
        </Modal>
    )
}