import { useEffect, useState } from "react";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Section from "../../../components/base/Section";
import Layout from "../../../components/layouts/Layout";
import { setPage, useHub } from "../../../core/Global";
import { Link, useParams } from "react-router-dom";
import { API_ROUTES } from "../../../core/Constants";
import UserGroup from "../models/UserGroup";
import RolesModel from "../models/RolesModel";
import Icon from "../../../components/base/Icon";
import UserGroupOverview from "../components/UserGroupOverview";
import UserGroupRolesForm from "../components/UserGroupRolesForm";
import UserGroupIPRestrict from "../components/UserGroupIPRestrict";



interface IPageLink {
    name: string
    icon: any
    slug: string
    default?: boolean
}

export default function UserGroupManage() {

    let sidebar: HTMLDivElement | null;

    const hub = useHub();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);

    const [pages, pagesSet] = useState<IPageLink[]>([]);

    const bindLinks = () => {
        var arr: IPageLink[] = [];

        arr.push({
            name: "Overview",
            icon: "chart-line",
            slug: "overview",
            default: true
        });

        arr.push({
            name: "Group Roles",
            icon: "lock",
            slug: "roles"
        });

        arr.push({
            name: "IP Restriction",
            icon: "network-wired",
            slug: "ip-restriction"
        });

        pagesSet(p => [...arr]);

    }

    const { selectedId, selectedPage } = useParams();
    const [model, modelSet] = useState<UserGroup>();


    const [boxHeight, boxHeightSet] = useState<number>();
    const [sidebarTop, sidebarTopSet] = useState<number>(0);
    const fixSizes = () => {
        var w = $(window).width() ?? 500;
        var h = $(window).height() ?? 400;
        if (w < 500) {
            h = 250;
        }
        else {
            h = h - ($("#kt_header").outerHeight() ?? 50);
            h = h - ($("#kt_toolbar").outerHeight() ?? 50);
            //h = h - ($("#kt_chat_contacts_header").outerHeight() ?? 50);

            h = h - 40;
        }

        boxHeightSet(h);
        sidebarTopSet(sidebar?.offsetTop ?? 100);
    }


    useEffect(() => {
        fixSizes();
        bindLinks();
    }, [fetched])

    useEffect(() => {

        hub.Get(API_ROUTES.UsersGroups + selectedId, res => {
            if (res.success) {
                modelSet(res.data)
                fetchingSet(false)
                fetchedSet(true)
                setPage(res.data.name, "Accounts", "Users", "User Groups")
            }
        })

    }, [selectedId])

    return (
        <Layout wide>
            <Section fluid>
                <Div visible={fetching} className="mt-20">
                    <Indicator />
                </Div>

                <Div visible={fetched}>

                    <div className="row">
                        <div className="col col-12 col-md-3 col-xxxl-2">
                            <div ref={e => sidebar = e} className="card card-flush mb-0 scroll-y p-0 m-0 stickycard"
                                style={{
                                    height: boxHeight,
                                    top: sidebarTop
                                }}>
                                <div className="card-body p-0 m-0 pt-5">
                                    <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">

                                        {
                                            pages.map((p, k) =>
                                                <div key={k} className="menu-item mb-1 mb-xxl-3">
                                                    <Link to={`/accounts/usergroup/${selectedId}/${p.slug}`} className={"menu-link" + (((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? " active" : "")}>
                                                        <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2 me-3">
                                                                <Icon icon={p.icon} type="solid" className={(((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? "" : " text-gray-700")} />
                                                            </span>
                                                        </span>
                                                        <span className={"menu-title fw-bold fs-7 fs-xxl-6" + (((window.isNullOrEmpty(selectedPage) && p.default) || selectedPage?.toLowerCase() == p.slug) ? "" : " text-gray-700")}>{p.name}</span>
                                                        {/* <span className="badge badge-light-success">3</span> */}
                                                    </Link>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            selectedId && model &&
                            <div className="col col-12 col-md-9 col-xxxl-10">

                                {
                                    (window.isNullOrEmpty(selectedPage) || selectedPage == "overview") && <UserGroupOverview model={model} />
                                }

                                {
                                    selectedPage == "roles" && <UserGroupRolesForm model={model} />
                                }

                                {
                                    selectedPage == "ip-restriction" && <UserGroupIPRestrict model={model} />
                                }

                            </div>
                        }

                    </div>

                </Div>

            </Section>
        </Layout>
    )
}