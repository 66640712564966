import { useEffect, useState } from "react";
import Div from "../../../../components/base/Div";
import Indicator from "../../../../components/base/Indicator";
import Layout from "../../../../components/layouts/Layout";
import FormPage from '../../../../components/form/FormPage';
import { setPage } from "../../../../core/Global";
import TextBox from "../../../../components/form/TextBox";
import Icon from "../../../../components/base/Icon";
import PhoneLine from "../models/PhoneLine";
import { useParams } from "react-router-dom";
import Hub from "../../../../api/Hub";
import { API_ROUTES } from "../../../../core/Constants";
import FormTitle from "../../../../components/form/FormTitle";
import DropDown, { DropDownOption } from "../../../../components/form/DropDown";


function LineInbound() {
    
    const { selectedId } = useParams(); 
    const hub = new Hub<PhoneLine>(API_ROUTES.Lines);
    const [loading, loadingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<PhoneLine>();
    const [inboundDest, inboundDestSet] = useState<string>();

    const init = async () => {
        if (selectedId) {
            setPage("Line Inbound Settings");
            loadingSet(true);
            await hub.Find(selectedId, res => {
                if (res.success) {
                    modelSet(res.data);
                    loadingSet(false);
                }
            });
        }
        else {
            document.location.href = "/voice/lines";
        }
        
    }

    useEffect(() => {
        
        init();

    }, [])

    return (
        <Layout>

            <Div visible={loading} className="p-20 text-center">
                <Indicator text="Please wait..."  />
            </Div>

            <FormPage apiRoute="/voice/lines/inbound/"
                    redirectTo="/voice/lines"
                    title="Inbound Route"
                    submitText="Update Inbound Route"
                    objectName="Inbound"
                    selectedId={selectedId}
                    visible={!loading}>

                <div className="row">
                    <div className="col-sm-2 big-icon">
                        <Icon icon="phone-rotary" type="thin" />
                    </div>
                    <div className="col-sm-10 pt-5">
                        <TextBox defaultValue={model?.phoneNumber} readOnly inlineStyle largeText noMarginBottom />
                        <TextBox name="DisplayName" placeholder="Display Name" maxLength={30} inlineStyle defaultValue={model?.friendlyName} />
                    </div>
                </div>

                <FormTitle title="General Settings" />
                
                <DropDown label="Set Destination" id="drp-inbound-destination" name="inboundDest"
                        onSelect={val=> inboundDestSet(val)}>
                    <DropDownOption text="Extensions" value="1" /> 
                    <DropDownOption text="Queues" value="2" /> 
                </DropDown>

                {
                    inboundDest == "Extensions" &&
                    <DropDown label="Extension" id="drp-inbound-extensions" dataText="title" dataValue="value"
                        apiSource={"/base/tagify/extensions/?userId=" + model?.ownerUserId}>
                    </DropDown>
                }

                {
                    inboundDest == "Queues" &&
                    <DropDown label="Queue" id="drp-inbound-extensions" dataText="title" dataValue="value"
                        apiSource={"/base/tagify/queues/?userId=" + model?.ownerUserId}>
                    </DropDown>
                }

                <TextBox label="Caller ID Number" />

            </FormPage>
        </Layout>
    )
}

export default LineInbound;