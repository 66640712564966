import { getUser, setUserToken, useHub } from "../../core/Global";
import Modal from "../base/Modal";


function ModalLogout() {

    const hub = useHub();
    const user = getUser();

    return (
        <Modal id="modal-signout"
            title="Sign Out"
            buttonText="Sign Out"
            buttonColor="danger"
            onConfirm={callback => {
                hub.Get("/auth/logout/" + user?.id, res => {
                    if(res.success) {
                        setUserToken("");
                        document.location.href = "/auth/login";
                    }
                    else {
                        callback(true);
                    }
                })
            }}>
                <p>
                    Are you sure you want to sign out from the system?
                </p>
        </Modal>
    )
}

export default ModalLogout;