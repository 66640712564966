import { useEffect, useState } from "react";
import { useHub } from "../../../../core/Global";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import Div from "../../../../components/base/Div";
import Indicator from "../../../../components/base/Indicator";



export default function WalletSettings() {



    const hub = useHub()
    const [fetching, fetchingSet] = useState<boolean>(true)
    const [fetched, fetchedSet] = useState<boolean>(false)
    const [budget, budgetSet] = useState<number>(25)
    const [limit, limitSet] = useState<number>(1)
    const [working, workingSet] = useState<boolean>()

    const fetch = () => {
        fetchingSet(true);

        hub.Get("/accounts/profile/budget", res => {
            budgetSet(res.data.amount);
            limitSet(res.data.limit);
            fetchingSet(false);
            fetchedSet(true);
        });
    }



    useEffect(() => {
        fetch();
    }, [])



    return (
        <div className="card mb-5">
            <div className="card-header border-0">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Wallet Settings</h3>
                </div>
                <div className="card-action">
                    <button className="btn btn-sm btn-primary btn-icon w-150px" disabled={(budget < 50 || budget > 300 || working)}
                        onClick={e => {
                            workingSet(true);
                            setTimeout(() => {
                                hub.Post("/accounts/profile/budget", {
                                    amount: budget,
                                    limit: limit
                                }, res => {
                                    if (res.success) {
                                        toastSuccess("Budget settings updated successfully!");
                                    }
                                    else {
                                        toastError(res.message ?? "Something went wrong!");
                                    }
                                    workingSet(false);
                                });
                            }, 1000);
                        }}>
                        {
                            working ?
                                <>
                                    <Icon icon="spinner-third" spin />
                                    <span className="ms-2">Updating...</span>
                                </>
                                :
                                <>Update Settings</>
                        }
                    </button>
                </div>
            </div>
            <div className="card-body border-top p-9">
                <Div visible={fetching} className="my-20">
                    <Indicator />
                </Div>
                <Div visible={fetched}>
                    <div className="mb-2 mt-5 fw-bold">Budget Amount: </div>
                    <div className="mb-1">
                        <select className="form-select form-select-sm mw-100 w-300px" value={budget} defaultValue={budget}
                            onChange={e => {
                                var b = Number.parseInt(e.target.value);
                                budgetSet(b);
                            }}>
                            <option value={25}>$25</option>
                            <option value={50}>$50</option>
                            <option value={100}>$100</option>
                            <option value={150}>$150</option>
                            <option value={200}>$200</option>
                            <option value={250}>$250</option>
                            <option value={300}>$300</option>
                            <option value={301}>More Than $300</option>
                        </select>
                    </div>
                    <div className="fs-7 text-gray-600 mt-2">
                        <Icon icon="info-circle" type="solid" className="me-2" />
                        The maximum amount that will add to your wallet automatically!
                    </div>
                    {
                        budget > 300 &&
                        <div className={"mt-2 ms-1 fw-bold text-warning"}>
                            You need to contact our support team to update your wallet budget more than 300$!
                        </div>
                    }

                    <div className="mb-2 mt-5 fw-bold">Minimum Balance: </div>
                    <div className="mb-1">
                        <select className="form-select form-select-sm mw-100 w-300px" value={limit} defaultValue={limit}
                            onChange={e => {
                                var l = Number.parseInt(e.target.value);
                                limitSet(l);
                            }}>
                            {
                                [...Array(10)].map((item, key) =>
                                    <option key={key} value={(key + 1)}>
                                        {(key + 1)}$
                                    </option>
                                )
                            }
                        </select>
                    </div>
                    <div className="fs-7 text-gray-600 mt-2">
                        <Icon icon="info-circle" type="solid" className="me-2" />
                        We will add the budget to your wallet when your balance reaches this amount!
                    </div>
                </Div>

            </div>
        </div>
    )


}