import { getUser, setUserToken, useHub } from "../../core/Global";
import Modal from "../base/Modal";


function ModalLimited() {

    const hub = useHub();
    const user = getUser();

    return (
        <Modal id="modal-user-is-limited"
            title="Limited Access"
            buttonText="Subscribe Now"
            buttonColor="primary"
            onConfirm={callback => {
                document.location.href = "/profile/billing/subscription";
            }}>
                <p className="text-danger">
                    Your account has been limited because your subscription is ended!
                </p>
        </Modal>
    )
}

export default ModalLimited;