import Modal from "../../../../../components/base/Modal";
import DropDown from "../../../../../components/form/DropDown";
import { API_ROUTES } from "../../../../../core/Constants";
import { useHub } from "../../../../../core/Global";
import Job from "../../models/Job";



interface IProps {
    job?: Job
    onClose?: () => void
}

export default function JobNotCalledActionsModal(props: IProps) {

    const hub = useHub();


    return (
        <Modal id="modal-job-notcalled-actions"
            title="Not Called Actions"
            subtitle={"Job ID: #" + props.job?.jobId + " | " + props.job?.fullName}
            open visible staticBackdrop
            buttonText="Apply Action"
            onClose={props.onClose}
            onSubmit={(body, callback) => {
                if (window.isNullOrEmpty(body.action)) {
                    window.formError("action", "Please select an action!", "modal-job-notcalled-actions");
                    callback!();
                    return;
                }


                hub.Post(API_ROUTES.Jobs + "notcalledaction", {
                    id: props.job?.id,
                    action: body.action
                }, res => {
                    callback!(res);
                })

            }}>
            <div>
                <DropDown label="Select an action to prevent the alert" name="action"
                    block labelUp
                    source={[
                        {
                            id: "Direct Given!",
                            name: "Direct phone number given!"
                        },
                        {
                            id: "Ignored!",
                            name: "Just ignore the alert!"
                        }
                    ]} />
            </div>
        </Modal>
    )
}