



import { useEffect, useState } from "react";
import { hasRole, openEditJobAppt, toAppointmentDate, toPrettyDate, useHub } from "../../../../../core/Global";
import Job from "../../models/Job";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import Clock from "../../../../../components/timer/Clock";
import { convertTo12HourFormat } from "../../../../../core/Helper";



interface IProps {
    job: Job
}
export default function JobDrawerAppointment(props: IProps) {

    return (
        <div>
            <FlexRow className="mb-3">
                <div>
                    <div className="text-muted fs-8">
                        <Icon icon="calendar-clock" size={10} className="me-2" type="solid" />
                        Appointment
                    </div>
                    <div className="ps-5">
                        {
                            props.job.appointmentDate ?
                                <>
                                    <div>
                                        {props.job.appointmentDate}
                                        &nbsp;
                                        {convertTo12HourFormat(props.job.appointmentTimeFrom, true) + "-" + convertTo12HourFormat(props.job.appointmentTimeTo)}
                                    </div>
                                </>
                                :
                                <>-</>
                        }
                    </div>
                </div>

                <FlexRow className="flex-grow-1" justify="end">
                    {
                        (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                        <IconButton icon="edit" size={14} type="solid" className="bg-light" title="Edit Appointment"
                            onClick={e => {
                                openEditJobAppt(props.job)
                            }} />
                    }
                </FlexRow>

            </FlexRow>

            <div>
                <div className="text-muted fs-8">
                    <Icon icon="clock" size={10} className="me-2" type="solid" />
                    Local Time
                </div>
                <div className="ps-5">
                    {
                        props.job.timezone ?
                            <div title={`${props.job.address?.address?.state ?? "Local"} Time`}>
                                <Clock offset={props.job.timezoneOffset} />
                            </div>
                            :
                            <div title="Local Time is not specified!">
                                <span>-</span>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}