import WebRTCModel from "./WebRTCModel";


export interface UserAgentCreateOptions {
    debug: boolean
    webrtc: WebRTCModel
    onConnect?: () => void
    onDisconnect?: (err: any) => void
    onRegister?: () => void
    onUnregister?: () => void
    onStateChanged?: (state: RegistererStats) => void
}

export enum RegistererStats {
    Initial, Registered, Unregistered, Terminated
}