import { useGlobal } from "../../../core/Global"
import ChatDeleteConfirmModal from "./ChatDeleteConfirmModal"
import ChatSettingsModal from "./ChatSettingsModal"



export default function ChatGlobalModals() {

    const [globalState] = useGlobal()


    return (
        <>
            {globalState.deleteChat && <ChatDeleteConfirmModal chat={globalState.deleteChat} />}
            {globalState.chatSettingsModal && <ChatSettingsModal chat={globalState.chatSettingsModal} />}

        </>
    )
}