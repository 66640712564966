import { useEffect } from "react";
import Section from "../../../../components/base/Section";
import Layout from "../../../../components/layouts/Layout";
import { setPage, validateRole } from "../../../../core/Global";
import { useParams } from "react-router-dom";
import PaymentMethodsView from "../components/PaymentMethodsView";
import WalletSettings from "../components/WalletSettings";
import CompanySettings from "../components/CompanySettings";
import CRMDefaultRates from "../components/CRMDefaultRates";
import SystemSettingsMenu from "../components/SystemSettingsMenu";
import PreferencesView from "./PreferencesView";
import JobsSettingsView from "./JobsSettingsView";
import ChatSettingsView from "./ChatSettingsView";
import OtherSettingsView from "./OtherSettingsView";
import SubscriptionView from "./SubscriptionView";
import SendJobSettings from "../components/SendJobSettings";




export default function SystemSettingsView() {

    const { selectedPage } = useParams()

    useEffect(() => {
        validateRole("SystemSettings")
        setPage("System Settings", "System")
    }, [])

    return (
        <Layout wide>
            <Section fluid>
                <div className="row">
                    <div className="col col-md-4 col-xxl-3 col-xxxl-2">
                        <SystemSettingsMenu />
                    </div>
                    <div className="col col-md-8 col-xxl-9 col-xxxl-10">

                        {selectedPage?.toLowerCase() == "subscription" && <SubscriptionView />}
                        {selectedPage?.toLowerCase() == "payment-methods" && <PaymentMethodsView />}
                        {selectedPage?.toLowerCase() == "company" && <CompanySettings />}
                        {selectedPage?.toLowerCase() == "tech-rates" && <CRMDefaultRates />}
                        {selectedPage?.toLowerCase() == "send-job" && <SendJobSettings />}
                        {selectedPage?.toLowerCase() == "jobs" && <JobsSettingsView />}
                        {selectedPage?.toLowerCase() == "chats" && <ChatSettingsView />}

                        {selectedPage?.toLowerCase() == "others" && <OtherSettingsView />}


                    </div>
                </div>
            </Section>
        </Layout>
    )
}