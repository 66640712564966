import React, { useEffect, useState, ClipboardEvent } from "react";
import Hub from "../../../api/Hub";
import Icon from "../../../components/base/Icon";
import Modal from "../../../components/base/Modal";
import Form from "../../../components/form/Form";
import Countdown from "../../../components/timer/Countdown";
import HUBResponse from "../../../models/HUBResponse";
import TextBox from "../../../components/form/TextBox";
import { useEnvironment, useHub } from "../../../core/Global";

let codeText = "";
function SignUpStart() {

    const [code, codeSet] = useState<string>("");
    const [code1, code1Set] = useState<string>("");
    const [code2, code2Set] = useState<string>("");
    const [code3, code3Set] = useState<string>("");
    const [code4, code4Set] = useState<string>("");
    const [code5, code5Set] = useState<string>("");
    const [code6, code6Set] = useState<string>("");

    const hub = useHub()
    const env = useEnvironment();

    let resendCount = 0;
    const [googleAuthWorking, googleAuthWorkingSet] = useState<boolean>(false);
    const [email, emailSet] = useState<string>();
    const [step, stepSet] = useState<number>(1);
    const [agreement, agreementSet] = useState<boolean>(false);
    const [resendSecs, resendSecsSet] = useState<number>(0);
    const [sending, sendingSet] = useState<boolean>(false);
    const [request, requestSet] = useState<any>();

    const [verifying, verifyingSet] = useState<boolean>(false);
    const [codeError, codeErrorSet] = useState<string>("");

    let resendInterval:NodeJS.Timeout;


    const submit = (body: any, callback?: (res?: HUBResponse<any>) => void) => {
        window.hideFormErrors();
        var valid = true;
        if (window.isNullOrEmpty(body.firstName)) {
            window.showFormError("firstName", "First name is required!");
            valid = false;
        }
        if (window.isNullOrEmpty(body.lastName)) {
            window.showFormError("lastName", "Last name is required!");
            valid = false;
        }
        if (window.isNullOrEmpty(body.email)) {
            window.showFormError("email", "Email address is required!");
            valid = false;
        }
        if(!agreement) {
            window.showFormError("agreement", "You should agree with the DSPRO terms and condition to continue!");
            valid = false;
        }
        
        if (!valid) return;

        emailSet(body.email);
        sendingSet(true);
        hub.Post("/auth/signup/start", body, res => {
            if (res.success) {
                requestSet(res.data);
                showStep2();
            }
            else {
                window.showFormError(res.field, res.message);
            }
            sendingSet(false);
        });

    }

    const showStep2 = () => {
        stepSet(2);
        resendSecsSet(30);
    }

    const resend = () => {
        
        sendingSet(true);
        hub.Post("/auth/signup/resend/" + request.id, {}, res => {
            if (res.success) {
                resendSecsSet(60);     
            }
            sendingSet(false);
        });
    }


    const paste = (e: any) => {
        var code = e.clipboardData.getData("text")
        if (isNaN(Number.parseInt(code))) return;
        codeSet(code);
        codeText = code;
    }

    
    const keypress = (e: any) => {
        if (codeText.length >= 6) return;
        if (/[0-9]/.test(e.key)) {
            codeText += e.key;
            codeSet(codeText);
        }
    }

    const keydown = (e: any) => {
        if (e.key == "Backspace" || e.key == "Delete") {
            if (codeText.length > 0) {
                codeText = codeText.substring(0, codeText.length - 1);
                codeSet(codeText);
            }
        }
    }

    const verify = () => {
        verifyingSet(true);
        codeErrorSet("");
        hub.Post("/auth/signup/verify/" + request.id, {
            code: code
        }, res => {
            if (res.success) {
                document.location.href = "/join/" + res.data.token;
            }
            else {
                codeText = "";
                codeErrorSet(res.message ?? "verification code is not valid!");
                verifyingSet(false);
                codeSet("");
            }
        });
    }
    

    useEffect(() => {

        return () => {
            document.removeEventListener("paste", paste);
            document.removeEventListener("keypress", keypress);
            document.removeEventListener("keydown", keydown);   
        }

    }, [])
    
    useEffect(() => {
        if (step == 1) {
            $("#txt-firstname").focus();
        }
        
        if(step == 2) {
            document.addEventListener("paste", paste);
            document.addEventListener("keypress", keypress);
            document.addEventListener("keydown", keydown);
            $("#txt-verify-code-1").focus();
        }
        else {
            document.removeEventListener("paste", paste);
            document.removeEventListener("keypress", keypress);
            document.removeEventListener("keydown", keydown);   
        }

    }, [step]);

    useEffect(() => {

        code1Set(code[0] ?? "");
        code2Set(code[1] ?? "");
        code3Set(code[2] ?? "");
        code4Set(code[3] ?? "");
        code5Set(code[4] ?? "");
        code6Set(code[5] ?? "");

        if (code.length == 0) $("#txt-verify-code-1").focus();
        if (code.length == 1) $("#txt-verify-code-2").focus();
        if (code.length == 2) $("#txt-verify-code-3").focus();
        if (code.length == 3) $("#txt-verify-code-4").focus();
        if (code.length == 4) $("#txt-verify-code-5").focus();
        if (code.length == 5) $("#txt-verify-code-6").focus();
        else if (code.length == 6) {
            $("#txt-verify-code-6").focus();
            verifyingSet(true);
            setTimeout(verify, 1500);
        }
        
    }, [code]);

    return (
        <div className="w-lg-600px p-10 mx-auto">
            {
                step == 1 &&
                <Form className="form w-100" onSubmit={submit}>
                    
                    <div className="mb-10 text-center">
                        
                        <h1 className="text-dark mb-3">Create an Account</h1>
                        
                        <div className="text-gray-400 fw-bold fs-6">
                            Already have an account? &nbsp;
                            <a href="/auth/login" className="link-primary fw-bolder">Sign in here</a>
                        </div>

                    </div>

                    <button type="button" className="btn btn-light-primary fw-bolder w-100 mb-5" disabled={googleAuthWorking}
                         onClick={e => {
                            googleAuthWorkingSet(true);
                            hub.Post("/auth/google/authlink", {}, res => {
                                if (res.success) {
                                    document.location.href = res.data;
                                }
                                else {
                                    googleAuthWorkingSet(false);
                                }
                            })
                        }}>
                        <img alt="Logo" src="/assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />
                        Sign up with Google
                    </button>
                    
                    <div className="d-flex align-items-center mb-10">
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        <span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
                        <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>
                    
                    <div className="row fv-row mb-7">
                        <div className="col-xl-6">
                            <TextBox id="txt-firstname" name="firstName" labelUp block marginBottom={1}
                                label="First Name" placeholder="Write your first name" />
                        </div>
                        <div className="col-xl-6">
                            <TextBox name="lastName" labelUp block marginBottom={1}
                                label="Last Name" placeholder="Write your last name" />
                        </div>
                    </div>
                    <div className="fv-row mb-7">
                        <TextBox name="email" labelUp block marginBottom={1}
                            label="Email Address" placeholder="Write your email address" />
                    </div>
                    
                    <div className="fv-row mb-10">
                        <label className="form-check form-check-custom form-check-inline">
                            <input className="form-check-input" type="checkbox" name="agreement" checked={agreement} 
                                onChange={e=> {
                                    agreementSet(false);
                                    window.openModal("modal-terms");
                                    
                                }} />
                            <span className="form-check-label fw-bold text-gray-700 fs-6">I Agree Terms and conditions.</span>
                        </label>
                        <span id="agreement-err" className="small text-danger mt-2 form-error"></span>
                    </div>

                    <div className="text-end">
                        <button type="submit" className="btn btn-primary w-150px" disabled={sending}>
                            {
                                sending ? 
                                <>
                                    <span className="indicator-label me-2">
                                        Please Wait
                                    </span>
                                    <Icon icon="spinner-third" spin />
                                </>
                                :
                                <>
                                    <span className="indicator-label me-2">
                                        Continue
                                    </span>
                                    <Icon icon="chevron-right" type="solid" />
                                </>
                            }
                        </button>
                    </div>
                    
                </Form>
            }

            {
                step == 2 &&
                <div>
                    <div className="pt-lg-10 mb-10 text-center">
                        <div className="text-center mb-10">
                            <Icon type="thin" icon="envelopes" size={96} className="me-2 text-gray-600" />
                        </div>
						<h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                            Verify Your Email
                        </h1>
						<div className="fs-5 fw-bold text-muted mb-1">
                            We have sent an email include a verification code to
						</div>
                        <div className="fw-bolder text-dark fs-3">
                            {email}
                        </div>
                        <div className="mb-10 px-md-10 mt-10">
                            <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">
                                Type your security code
                                {
                                    codeError != "" && <span className="fw-normal text-danger fs-7 ms-2">{codeError}</span>
                                }
                            </div>
                            <div className="d-flex flex-wrap flex-stack">

                                <input type="tel" id="txt-verify-code-1" maxLength={1} value={code1} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />
                                <input type="tel" id="txt-verify-code-2" maxLength={1} value={code2} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />
                                <input type="tel" id="txt-verify-code-3" maxLength={1} value={code3} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />
                                <input type="tel" id="txt-verify-code-4" maxLength={1} value={code4} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />
                                <input type="tel" id="txt-verify-code-5" maxLength={1} value={code5} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />
                                <input type="tel" id="txt-verify-code-6" maxLength={1} value={code6} readOnly className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" />

                            </div>
                        </div>
                        <div className="text-center mb-10">
                            <button className="btn btn-primary w-150px" onClick={verify} disabled={verifying}>
                                {
                                    verifying ? 
                                    <>
                                        <span className="indicator-label me-2">
                                            Please Wait
                                        </span>
                                        <Icon icon="spinner-third" spin />
                                    </>
                                    :
                                    <>
                                        <span className="indicator-label">
                                            Verify Code
                                        </span>
                                    </>
                                }
                            </button>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                            <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                        </div>
						<div className="d-flex align-items-center justify-content-center fs-5 mb-1">
							<span className="fw-bold text-gray-700 me-2">Did’t receive an email?</span>
                            {
                                resendSecs > 0 ?
                                <button className="btn btn-link fw-bolder" disabled>
                                    <Countdown totalSeconds={resendSecs} 
                                        onFinish={e=> {
                                            resendSecsSet(0);
                                        }}
                                        />&nbsp;To Resend
                                </button>
                                :
                                <button className="btn btn-link fw-bolder" onClick={resend}>
                                    {
                                        sending ? 
                                        <>
                                            <Icon icon="spinner-third" spin />
                                        </>
                                        :
                                        <span className="indicator-label">
                                            Resend Email
                                        </span>
                                    }
                                </button>
                            }
						</div>
                        {
                            resendSecs == 0 &&
                            <div>
                                <button className="btn btn-link fw-bolder" onClick={() => {
                                    stepSet(1)
                                }}>
                                    Change Email Address
                                </button>
                            </div>
                        }
					</div>
                </div>
            }

            <Modal id="modal-terms"
                size="XLarge"
                visible
                title="Terms & Conditions"
                buttonText="Accept Agreement"
                onConfirm={callback => {
                    agreementSet(true);
                    window.hideFormErrors();
                    if (callback) callback();
                }}>
                
                <div className="scroll-y h-100 hover-scroll" style={{overflowX: "hidden"}}>
                    <div className="row">
                        <div className="col-12">
                            <p className="fw-bolder fs-5">
                                1- Use of the {env.siteTitle} platform
                            </p>
                            <p>
                                By accessing and using the {env.siteTitle} platform, you agree to be bound by these terms and conditions. If you do not agree with these terms and conditions, you must not access or use the platform.
                            </p>
                            <p>
                                The platform is intended for use by organizations and their authorized personnel who are responsible for managing and monitoring calls and dispatch operations. By using the platform, you represent and warrant that you have the legal authority to enter into these terms and conditions and to use the platform on behalf of your organization.
                            </p>
                            <p>
                                You are responsible for ensuring that your use of the platform complies with all applicable laws and regulations, including, but not limited to, privacy laws, data protection laws, and telecommunications laws. You are also responsible for obtaining any necessary licenses, permissions, or approvals for your use of the platform.
                            </p>
                            <p>
                                You acknowledge that the platform may be subject to export control laws and regulations, and you agree to comply with all applicable laws and regulations regarding the export, re-export, and transfer of the platform.
                            </p>
                            <p>
                                {env.siteTitle} may, in its sole discretion, suspend or terminate your access to the platform if you breach any of these terms and conditions or if {env.siteTitle} believes that your use of the platform may cause harm to {env.siteTitle} or to any third party.
                            </p>
                            <p>
                                {env.siteTitle} reserves the right to modify or discontinue the platform at any time without notice. {env.siteTitle} shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the platform.
                            </p>

                            <p className="fw-bolder fs-5 mt-10">
                                2- Privacy and security
                            </p>
                            <p>
                                {env.siteTitle} takes the privacy and security of your data seriously and uses industry-standard measures to protect it. However, please note that no data transmission over the internet or electronic storage system can be guaranteed to be completely secure.
                            </p>
                            <p>
                                By using the platform, you consent to {env.siteTitle} collecting certain data about your use of the platform, such as call logs and performance metrics, for the purpose of providing and improving the service. This data may include personal information about you and your customers, and {env.siteTitle} will only use such data in accordance with its privacy policy.
                            </p>
                            <p>
                                {env.siteTitle} may share your data with third-party service providers who assist in providing the platform, but will not sell or disclose your data to third parties for their own purposes. {env.siteTitle} will require such third-party service providers to maintain the confidentiality of your data and use it only for the purpose of providing the platform.
                            </p>
                            <p>
                                You are responsible for ensuring that any personal information you provide through the platform is accurate and up-to-date, and that you have obtained any necessary consents or permissions to use such information in connection with the platform. You agree to indemnify and hold {env.siteTitle} harmless from any claims, damages, or expenses arising out of your use of the platform in violation of applicable laws or regulations or any third-party rights.
                            </p>

                            <p className="fw-bolder fs-5 mt-10">
                                3-Intellectual property
                            </p>
                            <p>
                                You may not attempt to reverse engineer, decompile, or disassemble any part of the platform, or access or use the platform in any way that violates these terms and conditions or applicable laws and regulations.
                            </p>
                            <p>
                                {env.siteTitle} may terminate your access to the platform at any time for any reason, with or without notice, if you violate these terms and conditions or engage in any unauthorized use of the platform.
                            </p>
                            <p>
                                You agree to indemnify and hold {env.siteTitle} harmless from any claims, damages, losses, liabilities, or expenses arising from your use of the platform or your breach of these terms and conditions.
                            </p>
                            <p>
                                These terms and conditions constitute the entire agreement between you and {env.siteTitle} regarding your use of the platform, and supersede any prior agreements or understandings, whether written or oral.
                            </p>
                            

                            <p className="fw-bolder fs-5 mt-10">
                                4- Warranty and disclaimer
                            </p>
                            <p>
                                {env.siteTitle} warrants that the platform will operate in accordance with its documentation and that it will use commercially reasonable efforts to fix any defects that affect its functionality.
                            </p>
                            <p>
                                {env.siteTitle} does not warrant that the platform will be error-free, uninterrupted, or meet your specific requirements, and disclaims all other warranties, express or implied, including without limitation warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            </p>

                            <p className="fw-bolder fs-5 mt-10">
                                5-Limitation of liability
                            </p>
                            <p>
                                {env.siteTitle} shall not be liable for any damages arising out of or in connection with your use of the platform, including but not limited to any indirect, incidental, special, or consequential damages, or for any loss of profits, data, or business opportunities.
                            </p>
                            <p>
                                You acknowledge and agree that the platform is provided "as is" and that {env.siteTitle} makes no warranty or representation of any kind, express or implied, regarding the platform, including but not limited to the accuracy, reliability, or completeness of any information or content provided through the platform. {env.siteTitle} disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            </p>
                            <p>
                                This limitation of liability shall apply regardless of the form of action, whether in contract, tort, strict liability, or otherwise, and regardless of whether {env.siteTitle} has been advised of the possibility of such damages. You agree that you are solely responsible for your use of the platform, and that {env.siteTitle} shall not be liable for any damages, costs, or expenses that arise from your use of the platform, including but not limited to any damages to your computer system or mobile device.
                            </p>

                            <p className="fw-bolder fs-5 mt-10">
                                6-Termination
                            </p>
                            <p>
                                {env.siteTitle} may terminate your access to the platform at any time, for any reason or no reason, with or without notice. Upon termination, you must immediately cease all use of the platform and delete any copies of the platform in your possession. {env.siteTitle} may also delete or remove any content or data associated with your use of the platform, including any user-generated content, at any time and without notice.
                            </p>
                            <p>
                                You acknowledge and agree that {env.siteTitle} shall not be liable to you or any third party for any termination of your access to the platform. In the event of termination, you will not be entitled to any refund of any fees paid to {env.siteTitle} for the platform.
                            </p>
                            <p>
                                If you violate any of these terms or any applicable laws or regulations, {env.siteTitle} may, in its sole discretion, suspend or terminate your access to the platform and/or take any other action it deems appropriate. {env.siteTitle} reserves the right to investigate any suspected or alleged misconduct or violation of these terms, and may cooperate with law enforcement authorities in prosecuting users who violate the law.
                            </p>
                            <p>
                                You agree to indemnify, defend, and hold {env.siteTitle} and its affiliates, officers, directors, employees, agents, and licensors harmless from any claim, demand, or damage, including reasonable attorneys' fees, arising out of or in connection with your use of the platform, your violation of these terms, or your violation of any rights of another.
                            </p>

                            <p className="fw-bolder fs-5 mt-10">
                                7- Changes to the terms and conditions
                            </p>
                            <p>
                                {env.siteTitle} reserves the right to revise these terms and conditions at any time without prior notice. {env.siteTitle} may post an updated version of the terms and conditions on its website or notify you of the changes by email. Your continued use of the platform after the effective date of any changes constitutes your acceptance of the revised terms and conditions.
                            </p>
                            <p>
                                It is your responsibility to regularly review these terms and conditions to ensure that you are aware of any changes. If you do not agree with the revised terms and conditions, you must immediately stop using the platform. Your continued use of the platform after any changes to these terms and conditions shall indicate your acceptance of such changes.
                            </p>
                            <p>
                                If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. These terms and conditions constitute the entire agreement between you and {env.siteTitle} and govern your use of the platform, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and {env.siteTitle}.
                            </p>
                            <p>
                                No waiver of any provision of these terms and conditions shall be deemed a further or continuing waiver of such provision or any other provision, and {env.siteTitle}'s failure to assert any right or provision under these terms and conditions shall not constitute a waiver of such right or provision.
                            </p>
                        </div>
                    </div>
                </div>

            </Modal>
            
            <div className="h-200px d-none d-xxl-block">
                &nbsp;
            </div>
            
        </div>
    )
}

export default SignUpStart;