import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hub from "../../../../api/Hub";
import Layout from "../../../../components/layouts/Layout";
import { CallTypes } from "../../../../core/Enums";
import { setPage, useHub, useSocket } from "../../../../core/Global";
import Environment from "../../../../env/env";
import CallHeader from "../components/CallHeader";
import CallHistory from "../components/CallHistory";
import Call from "../models/Call";
import CallCDR from "../components/CallCDR";
import CallCEL from "../components/CallCEL";
import Div from "../../../../components/base/Div";
import Indicator from "../../../../components/base/Indicator";
import CallPath from "../components/CallPath";


function CallDetails() {
    let socketKey:string|undefined;
    const env = new Environment();
    const hub = useHub()
    const { selectedId } = useParams();
    const socket = useSocket();
    const [model, modelSet] = useState<Call>();
    const [loading, loadingSet] = useState<boolean>(true);
    const [selectedPage, selectedPageSet] = useState<string>("history");

    const fetch = () => {
        hub.Get("/system/calls/" + selectedId, res => {
            if (res.data) {
                modelSet(res.data);
                loadingSet(false);
                if (res.data.isLive) {
                    socketKey = socket.start(
                        skt => {
                            skt.invoke("StartListenToCall", selectedId)
                                .then(() => {
                                    skt.on("UpdateCall", data => {
                                        modelSet(data);
                                    });
                                });
                        }, skt => {
                            skt.invoke("StopListenToCall", selectedId);
                            skt.off("UpdateCall");
                        });
                }
            }
        })
    }

    useEffect(() => {
        setPage("Call Details", "Voice", "Calls");
        fetch();
        window.scrollTo(0, 0);
        return () => {
           if (socketKey) socket.stop(socketKey);
        };
        
    }, [selectedId])

    return (
        <Layout>
            <Div visible={loading}>
                <Indicator />
            </Div>
            <Div visible={!loading}>
                
                <CallHeader model={model} selectPage={page => selectedPageSet(page)} selectedPage={selectedPage} />
                {
                    selectedPage == "history" && model && <CallHistory number={model?.callType == CallTypes.Incoming ? model.source : model?.destination} />
                }

                {
                    selectedPage == "path" && model && <CallPath id={model.id} events={model.events} />
                }

                {
                    selectedPage == "cdr" && model && <CallCDR id={model.id} />
                }

                {
                    selectedPage == "cel" && model && <CallCEL id={model.id} />
                }
            </Div>
            
        </Layout>
    )
}

export default CallDetails;