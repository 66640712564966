import { Link } from "react-router-dom";
import AvatarUploade from "../form/AvatarUploade";
import DatePicker from "../form/DatePicker";
import DropDown, { DropDownOption } from "../form/DropDown";
import Form from "../form/Form";
import PhoneInput from "../form/PhoneInput";
import TextBox from "../form/TextBox";
import { API_ROUTES } from "../../core/Constants";
import { useHub } from "../../core/Global";
import User from "../../modules/accounts/models/User";
import { toastSuccess } from "../base/Toast";
import { useState } from "react";
import Icon from "../base/Icon";


type Props = {
    userId?: string
    model?: User
    onUpdate?: (user: User) => void
 }

export default function AccountEditForm(props: Props) {

    const hub = useHub();
    const [working, workingSet] = useState<boolean>(false);

    return (
        <Form id="user-edit-form" className="form"
                onSubmit={(body, callback) => {

                    workingSet(true);
                    
                    setTimeout(() => {

                        hub.Put("/accounts/users/manage/update", {
                            ...body,
                            id: props.userId
                        }, res => {
                            if (res.success) {
                                toastSuccess("User has been updated successfully!");
                                if (props.onUpdate) props.onUpdate(res.data);
                            }
                            workingSet(false);
                        })
                        
                    }, 500);
                    
                    

                }}>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Edit Account</h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ? 
                                <>
                                    <Icon icon="spinner-third" spin />
                                    <span className="ms-2">Updating...</span>
                                </>
                                :
                                <>Update User</>
                            }
                        </button>
                    </div>
                </div>
                
                <div className="card-body border-top p-9">
                    
                    <AvatarUploade type="avatars" name="avatar" imageUrl={props.model?.avatarUrl} defaultValue={props.model?.avatarUrlRelativePath} />
                    
                    <div className="mt-10" style={{
                        maxWidth: 1200
                    }}>
                        <DropDown label="Gender" id="drpGender" name="gender" defaultValue={props.model?.gender?.toString()}>
                            <DropDownOption value="1" text="Mr." />
                            <DropDownOption value="0" text="Mrs." />
                        </DropDown>

                        <TextBox label="First Name" name="firstName" value={props.model?.firstName} />
                        <TextBox label="Last Name" name="lastName" value={props.model?.lastName}  />

                        <TextBox label="Email Address" name="email" defaultValue={props.model?.email} email  />
                        
                        <PhoneInput label="Phone" name="phone" defaultPhone={props.model?.phoneItem} />

                        <DatePicker label="Birth Date" name="birthDate" drops="up"
                            selectedDate={props.model?.birthDateItem?.text} id="dt-birthdate" />

                        {/* <DropDown label="Country" id="drpCountries" disabled defaultValue="usa">
                            <DropDownOption value="usa" text="United States" selected />
                        </DropDown>
                        <DropDown label="State" name="stateId" defaultValue={props.model?.stateId} apiSource={API_ROUTES.States}>
                        </DropDown>
                        <TextBox label="City" name="city" defaultValue={props.model?.cityId} /> */}
                    </div>
                    

                </div>
            </div>
        </Form>
    )
}