import { useState } from "react";
import FlexRow from "../../../../components/base/FlexRow";
import JobNote from "../models/JobNote"
import { JobNoteTypes } from "../../../../core/Enums";
import Avatar from "../../../../components/base/Avatar";
import Icon from "../../../../components/base/Icon";
import { toastInfo } from "../../../../components/base/Toast";
import DescriptionContainer from "../../../../components/base/DescriptionContainer";
import { isDemo } from "../../../../core/Global";
import { copyToClipboard } from "../../../../core/Helper";
import { GetThemeMode } from "../../../../core/Metronic";


type IProps = {
    parent?: string
    note?: JobNote
    onReply?: (note: JobNote) => void
}
export default function NoteItem(props: IProps) {


    return (
        <FlexRow id={"note-item-" + props.note?.id} justify={"start"}>
            <div className={"p-5 rounded fw-bold w-100 mb-5 note-container " +
                (props.note?.noteTypes == JobNoteTypes.ChangeTech ? ((GetThemeMode() == "dark" ? "bg-warning text-gray-100" : "bg-light-warning"))
                    : props.note?.noteTypes == JobNoteTypes.Cancelation ? ("bg-light-danger" + (GetThemeMode() == "dark" ? " text-gray-100" : ""))
                        : props.note?.noteTypes == JobNoteTypes.JobIssue ? ("bg-light-danger" + (GetThemeMode() == "dark" ? " text-gray-100" : ""))
                            : "bg-light-secondary")}
                style={{
                    maxWidth: 500
                }}
                onDoubleClick={e => {
                    e.preventDefault();
                    //replyItemSet(item);
                }}
            >
                <div className={"fs-7 "} style={{
                    paddingBottom: 10,
                    marginBottom: 10,
                    borderBottom: "1px solid #CCC",
                    whiteSpace: "pre-line"
                }}>
                    {
                        props.note?.noteTypes == JobNoteTypes.Cancelation &&
                        <FlexRow className="mb-2 text-danger">
                            <Icon icon="circle" type="solid" className="me-2" />
                            <span className="fw-bolder">
                                Cancelation Note
                            </span>
                            <Icon icon="thumbtack" type="solid" className="ms-auto text-dark" />
                        </FlexRow>
                    }
                    {
                        props.note?.noteTypes == JobNoteTypes.Status && props.note.status &&
                        <FlexRow className="mb-2 fs-8">
                            <Icon icon="circle" type="solid" className="me-2 fs-8" color={props.note.status.color} />
                            <span className="fw-bolder" style={{
                                color: props.note.status.color
                            }}>
                                {props.note.status.name}
                            </span>
                        </FlexRow>
                    }
                    {
                        props.note?.noteTypes == JobNoteTypes.JobIssue &&
                        <FlexRow className="mb-2 text-danger">
                            <Icon icon="circle-exclamation" type="solid" className="me-2" />
                            <span className="fw-bolder">
                                Issue Reported
                            </span>
                        </FlexRow>
                    }
                    {
                        props.note?.replyId &&
                        <div className="mb-4 cursor-pointer" style={{
                            marginLeft: 10,
                            marginRight: 10,
                            borderLeft: "4px solid #666",
                            paddingLeft: 5,
                            paddingRight: 5
                        }} onClick={e => {
                            e.preventDefault();
                            var d = $(props.parent ?? "");
                            var n = $("#note-item-" + props.note?.replyId);
                            d.scrollTop((d.scrollTop() ?? 0) + n.position().top - 10);
                            n.find(".note-container").addClass("job-note-blink");
                            setTimeout(() => {
                                n.find(".note-container").addClass("job-note-blink-off");
                                n.find(".note-container").removeClass("job-note-blink");
                            }, 500);
                        }}>
                            <div className="fw-bolder">{props.note?.replyName}</div>
                            <div className="fs-7" style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%"
                            }}>{props.note?.replyText}</div>
                        </div>
                    }
                    <DescriptionContainer text={props.note?.note ?? ""} size={250} />
                </div>
                <FlexRow justify={(props.note?.isMine ? "end" : "start")}>
                    <Avatar avatarUrl={props.note?.creatorUser?.avatarUrl} width={16} className="me-1" fullName={isDemo() ? "User " + props.note?.creatorUser?.fullName?.substring(0, 1).toUpperCase() : props.note?.creatorUser?.fullName} />
                    <div className="fs-9 text-gray">{isDemo() ? "User " + props.note?.creatorUser?.fullName?.substring(0, 1).toUpperCase() : props.note?.creatorUser?.fullName}</div>
                    <div className="mx-2 fs-9 text-gray">|</div>

                    <div className="fs-9 text-gray">
                        {props.note?.createDate}
                    </div>

                    <div className="ms-auto me-2">
                        <a href="#" className="text-dark"
                            onClick={e => {
                                e.preventDefault();
                                window.dataTableMenu();
                            }}
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <Icon icon="ellipsis-vertical" />
                        </a>
                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-2" data-kt-menu="true">
                            <div className="menu-item">
                                <a href="#" className="menu-link px-3"
                                    onClick={e => {
                                        e.preventDefault();
                                        if (props.onReply && props.note) props.onReply(props.note);
                                    }}
                                >
                                    <Icon icon="reply" className="me-2" />
                                    Reply
                                </a>
                            </div>
                            <div className="menu-item">
                                <a href="#" className="menu-link px-3"
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    <Icon icon="share" className="me-2" />
                                    Send as SMS
                                </a>
                            </div>
                            <div className="menu-item">
                                <a href="#" className="menu-link px-3"
                                    onClick={e => {
                                        e.preventDefault();
                                        copyToClipboard(props.note?.note, "Note has been copeid to clipboard!");
                                    }}
                                >
                                    <Icon icon="copy" className="me-2" />
                                    Copy Text
                                </a>
                            </div>
                        </div>
                    </div>
                </FlexRow>
            </div>
        </FlexRow>
    )
}