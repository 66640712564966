import "./ExtensionsBoardActivity.css";
import FilteringProps from "../filtering/ExtensionsBoard.json";
import React, { useEffect, useState } from "react";
import { API_ROUTES } from "../../../core/Constants";
import { setPage, useSocket, useHub } from '../../../core/Global';
import Layout from '../../../components/layouts/Layout';
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Environment from '../../../env/env';
import Hub from "../../../api/Hub";
import ExtensionBoardItem from "../models/ExtensionBoardItem";
import { useParams, Link, useSearchParams } from 'react-router-dom';
import Icon from '../../../components/base/Icon';
import Avatar from "../../../components/base/Avatar";
import LiveCall from '../../voice/calls/models/LiveCall';
import Timer from "../../../components/timer/Timer";
import Modal from '../../../components/base/Modal';
import LiveCallActions from '../../../components/calls/LiveCallActions';
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import Agent from "../../agents/models/Agent";
import DropDown from "../../../components/form/DropDown";
import Filtering from "../../../components/data/Filtering";
import BrowseOptions, { FilterParam } from "../../../models/BrowseOptions";
import { ListItem } from "../../../core/Types";
import CallAgent from "../../voice/calls/models/CallAgent";

let exts: string[] = [];
let firstLoad = true;
let socketInterval: NodeJS.Timeout;
let silentTime: number = 30;
function ExtensionsBoardActivity() {

    const env = new Environment();
    const hub = useHub()
    const [browse, browseSet] = useState<BrowseOptions>();
    const socket = useSocket();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [BusyModel, BusyModelSet] = useState<ExtensionBoardItem[]>();
    const [ActiveModel, ActiveModelSet] = useState<ExtensionBoardItem[]>();
    const [AwayModel, AwayModelSet] = useState<ExtensionBoardItem[]>();
    const [SelectedExt, SelectedExtSet] = useState<ExtensionBoardItem>();
    const [IsAnonymous, IsAnonymousSet] = useState<boolean>(false);
    const [filteringValues, filteringValuesSet] = useState<any>();
    const [selectedCall, selectedCallSet] = useState<LiveCall>();
    const [lineObj, lineObjSet] = useState<any>();

    const [routeParams, routeParamsSet] = useState<ListItem[]>([]);
    const [searchParams] = useSearchParams();

    const fetch = async (b?: BrowseOptions, callback?: () => void) => {
        var q = firstLoad ? searchParams.toString() : "";
        if (b?.filterParams) {
            for (var i = 0; i < b.filterParams.length; i++) {
                if (q != "") q += "&";
                var ext = "";
                if (b.filterParams[i].op == "not-equal") ext = "Not";
                q += b.filterParams[i].name + ext + "=" + encodeURIComponent(b.filterParams[i].value);
            }
        }
        if (q != "") q = "?" + q;

        hub.Get(API_ROUTES.DashboardExtensionsBoard + q, res => {
            if (res?.data) {
                exts = res.data.exts;
                BusyModelSet(res.data.busy);
                ActiveModelSet(res.data.active);
                filteringValuesSet(res.filteringValues);
                fetchingSet(false);
                fetchedSet(true);
                if (callback) callback();
                window.makeSelect2("drp-ext-dispatcher", false);
            }
            firstLoad = false;
        });
    }

    const startSocket = async () => {
        // await socket.start();

        // socket.invoke("StartListenToLiveBoards", getUserListenId());

        // socket.on("UpdateLiveBoards", () => {
        //     fetch(browse);
        //     silentTime = 30;
        // });

        // if (socketInterval) clearInterval(socketInterval);

        // socketInterval = setInterval(async () => {
        //     if (socket.state != HubConnectionState.Connected) await startSocket();
        // }, 5000);

    }

    const updateExtConfig = (body: any, callback: any) => {
        if (env.isDevelopment) console.log("updateExtConfig", body);
        hub.Put(API_ROUTES.DashboardExtensionsBoard, body, res => {
            callback(res);
        });
    }

    const updateExt = (ext: ExtensionBoardItem) => {

        hub.Get("/dashboard/LiveBoards/extensions", res => {
            if (res?.data) {
                exts = res.data.exts;
                BusyModelSet(res.data.busy);
                ActiveModelSet(res.data.active);
            }
        });

        // var busy = BusyModel ?? [];
        // var active = ActiveModel ?? [];
        // var away = AwayModel ?? [];
        // if(env.isDevelopment) console.log("socket updateExt", ext);

        // for (var i = 0; i < busy.length; i++) {
        //     if (ext.extNumber == busy[i].extNumber) {
        //         if (ext.isBusy) {
        //             busy[i] = ext;
        //         }
        //     }
        // }

        // for (var i = 0; i < active.length; i++) {
        //     if (ext.extNumber == active[i].extNumber) {
        //         if (ext.isActive) {
        //             active[i] = ext;
        //         }
        //     }
        // }

        // for (var i = 0; i < away.length; i++) {
        //     if (ext.extNumber == away[i].extNumber) {
        //         if (ext.isAway) {
        //             away[i] = ext;
        //         }
        //     }
        // }

        // BusyModelSet(f=> [...busy]);
        // ActiveModelSet(f=> [...active]);
        // AwayModelSet(f=> [...away]);

    }

    const onFilter = async (params: FilterParam[], callback: () => void) => {
        var b = browse ?? {};
        b.filterParams = params;
        await fetch(b);
        await callback();
    };

    useEffect(() => {

        setPage("Extensions Activity Board", "Dashboard");

        fetch();
        startSocket();


        return () => {
            if (socketInterval) clearInterval(socketInterval);
            firstLoad = true;
        };

    }, [])

    const ExtensionCard = (props: { item: ExtensionBoardItem }) => (
        <div className={"card card-agentmap card-ext-" + props.item.status?.colorClass + " mb-5"}>
            <div className="card-body px-2 py-3">
                <div>
                    <div className="d-flex align-items-center mb-1">
                        <Icon icon="phone-office" className="me-1" />
                        <div className="text-gray-900 fs-6 fw-bold me-1">
                            {props.item.extNumber}
                        </div>
                        {
                            props.item.agent?.id ?
                                <div className="d-flex align-items-center ms-2">
                                    <Avatar width={20} avatarUrl={props.item.agent.avatar} />
                                    <span className="text-gray-700 fs-8 ms-1">
                                        {props.item.agent.shortName}
                                    </span>
                                </div>
                                :
                                <div className="text-gray-700 fs-8">
                                    ({props.item.agent?.firstName})
                                </div>
                        }

                        {
                            props.item.lastCall && props.item.isActive &&
                            <div className="text-gray-700 fs-8 ms-auto">
                                Last Call: {props.item.lastCall}
                            </div>
                        }

                        {
                            props.item.isBusy && props.item.currentCall &&
                            <div className="d-flex align-items-center ms-5">
                                {
                                    props.item.extNumber == props.item.currentCall.sourceAgent?.number &&
                                    <>
                                        <Icon icon="phone-arrow-down-left" className="me-2" />
                                        <CallerID item={props.item.currentCall.destinationAgent} />
                                    </>
                                }
                                {
                                    props.item.extNumber == props.item.currentCall.destinationAgent?.number &&
                                    <>
                                        <Icon icon="phone-arrow-down-left" className="me-2" />
                                        <CallerID item={props.item.currentCall.sourceAgent} />
                                    </>
                                }
                            </div>
                        }

                    </div>
                    <div className="d-flex align-items-center">
                        {
                            props.item.isActive &&
                            <>
                                <div className="badge badge-success">
                                    Active
                                </div>
                                <div className="ms-auto">
                                    <a href="#" className="text-dark"
                                        onClick={e => {
                                            e.preventDefault();
                                            SelectedExtSet(props.item);
                                            if (props.item.agent?.id) {
                                                IsAnonymousSet(false);
                                            }
                                            else {
                                                IsAnonymousSet(true);
                                            }
                                            window.openModal("modal-ext-configs");
                                        }}>
                                        <Icon icon="gear" />
                                    </a>
                                </div>
                            </>
                        }
                        {
                            props.item.isBusy && props.item.currentCall &&
                            <>
                                <div className="badge badge-danger">
                                    <Timer totalSeconds={props.item.currentCall?.talkingSeconds} />
                                </div>
                                <div className="ms-auto">
                                    <LiveCallActions call={props.item.currentCall} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

    const CallerID = (props: { item?: CallAgent }) => (
        <>
            <Avatar width={15} avatarUrl={props.item?.country ? props.item.country.flagSquare : props.item?.avatarUrl} />
            {
                props.item?.id ?
                    <span className="fs-8">{props.item.name}</span>
                    :
                    <span className="fs-8">{props.item?.number}</span>
            }
        </>
    )

    return (
        <Layout>

            <Filtering filteringProps={FilteringProps}
                filteringValues={filteringValues}
                apiRoute={API_ROUTES.DashboardExtensionsBoard}
                loaded={fetched}
                onFilter={onFilter} />

            <Div visible={fetching} className="mt-20">
                <Indicator />
            </Div>

            <Div visible={fetched} className="row">

                {
                    BusyModel && BusyModel.length > 0 &&
                    <>
                        <div className="col-12 mt-5">
                            <h3 className="text-dark">Busy</h3>
                        </div>

                        {
                            BusyModel?.map((item, key) =>
                                <div key={key} className="col-sm-6 col-lg-4">
                                    <ExtensionCard item={item} />
                                </div>
                            )
                        }
                    </>
                }

                {
                    ActiveModel && ActiveModel.length > 0 &&
                    <>
                        <div className="col-12 mt-5">
                            <h3 className="text-dark">Active</h3>
                        </div>

                        {
                            ActiveModel?.map((item, key) =>
                                <div key={key} className="col-sm-6 col-lg-4">
                                    <ExtensionCard item={item} />
                                </div>
                            )
                        }
                    </>
                }


            </Div>


            <Modal id="modal-ext-configs"
                title={"Extension Settings"}
                size="Large"
                onSubmit={updateExtConfig}
                buttonText="Update Extension">

                <div>
                    <input type="hidden" name="ext" value={SelectedExt?.extNumber} />

                    <div className="d-flex align-items-center mb-10">
                        <Icon icon="phone-office" className="me-3 fs-3" />
                        <div className="text-gray-900 fs-2 fw-bold me-1">
                            {SelectedExt?.extNumber}
                        </div>
                    </div>

                    <ToggleSwitch label="Anonymous"
                        name="Anonymous"
                        checked={IsAnonymous}
                        onChange={e => {
                            IsAnonymousSet(e);
                        }} />

                    {
                        !IsAnonymous &&
                        <DropDown label="Dispatcher"
                            id="drpDispatchers" name="agentId"
                            apiSource="/accounts/agents/list"
                            defaultValue={SelectedExt?.agent?.id}
                            dataValue="id" dataText="fullName"></DropDown>
                    }

                </div>

            </Modal>

        </Layout>
    )
}

export default ExtensionsBoardActivity;