import { useEffect, useState } from "react";
import { PaymentTypes } from "../../../core/Enums";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import DropDown from "../../../components/form/DropDown";
import TextBox from "../../../components/form/TextBox";
import ToggleSwitch from "../../../components/form/ToggleSwitch";
import { useHub } from "../../../core/Global";
import Job from "../../crm/jobs/models/Job";
import { useParams } from "react-router-dom";
import Indicator from "../../../components/base/Indicator";
import Form from "../../../components/form/Form";
import { toastError, toastInfo, toastSuccess } from "../../../components/base/Toast";


interface IPayType {
    type: PaymentTypes
    value: string
    text: string
}

interface IPayItem {
    amount?: string
    type?: PaymentTypes
    checkNumber?: string
    approval?: string
    cancelFee?: boolean
}


export default function JobCloseView() {

    const { token } = useParams()
    const hub = useHub()

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })


    const [payItems, payItemsSet] = useState<IPayItem[]>([{
        type: undefined,
        amount: undefined
    }])

    const [paymentTypes] = useState<IPayType[]>([
        {
            type: PaymentTypes.Cash,
            value: PaymentTypes.Cash.toString(),
            text: "Cash"
        },
        {
            type: PaymentTypes.Check,
            value: PaymentTypes.Check.toString(),
            text: "Check"
        },
        {
            type: PaymentTypes.CCCharge,
            value: PaymentTypes.CCCharge.toString(),
            text: "CC Charge"
        },
        {
            type: PaymentTypes.CCManual,
            value: PaymentTypes.CCManual.toString(),
            text: "CC Manual"
        }
    ])


    const [job, jobSet] = useState<Job>()
    const [loading, loadingSet] = useState<boolean>(true)
    const [expired, expiredSet] = useState<boolean>(false)

    const [closed, closedSet] = useState<boolean>(false)

    const [working, workingSet] = useState<boolean>(false)

    useEffect(() => {

        if (token) {
            hub.Post("/base/public/getJobToClose", {
                token: token
            }, res => {
                if (res.success) {
                    jobSet(res.data.job)
                    // proxyNumberSet(res.data.proxyNumber)
                    // sendSettingsSet(res.data.sendSettings)
                    // companySet(res.data.company)
                    // acceptedSet(res.data.accepted)
                    // noteSet(res.data.note)
                }
                else {
                    expiredSet(true)
                }
                loadingSet(false)
            })
        }

    }, [token])

    const toNumberAmount = (n?: string) => {
        if (!n) return 0;
        n = n.replaceAll(",", "");
        return Number.parseFloat(n);
    }


    const [partsExceedErr, partsExceedErrSet] = useState<boolean>(false);
    const [ratesExceedErr, ratesExceedErrSet] = useState<boolean>(false);

    const [totalAmount, totalAmountSet] = useState<number>(0);


    useEffect(() => {
        var total = 0;
        var tax = 0;
        payItems.forEach(p => {
            var a = (p.amount ?? "0").replaceAll(",", "")
            total += Number.parseFloat(a);
        });

        // taxItems.forEach(t => {
        //     if (!t.included && t.amount) {
        //         if (t.amountType == AmountType.Percent) {
        //             var a = (total * t.amount) / 100;
        //             tax += a;
        //         }
        //         else {
        //             tax += t.amount
        //         }
        //     }
        // });

        totalAmountSet(total + tax);

    }, [payItems])


    return (
        <div>

            {
                loading &&
                <div>
                    <Indicator text="Loading" />
                </div>
            }

            {
                !loading && expired &&
                <div>
                    <div className="text-center">
                        <Icon icon="times-circle" className="text-danger" size={84} type="thin" />
                    </div>
                    <div className="text-danger text-center fs-4 mt-10">
                        Link is Expired!
                    </div>
                </div>
            }

            {
                !loading && closed &&
                <div>
                    <div className="text-center">
                        <Icon icon="check-circle" className="text-success" size={84} type="solid" />
                    </div>
                    <div className="text-success text-center fs-4 mt-10">
                        Job is closed successfully!
                    </div>
                </div>
            }

            {
                !loading && !expired && !closed && job &&
                <Form onSubmit={body => {

                    window.hideFormErrors();
                    partsExceedErrSet(false);
                    ratesExceedErrSet(false);

                    var valid = true;
                    var focus = true;

                    for (var i = 0; i < payItems.length; i++) {
                        var p = payItems[i];
                        if (!p.type) {
                            window.formError("paymentItems", "Please choose the payment type!");
                            valid = false;
                            break;
                        }
                        if (toNumberAmount(p.amount) == 0) {
                            window.formError("paymentItems", "Please write the payment amount!");
                            valid = false;
                            if (focus) {
                                $("#txt-paymentamount" + i).focus();
                                focus = false;
                            }
                            break;
                        }
                    }


                    var tp = toNumberAmount(body.techPart);
                    var cp = toNumberAmount(body.companyParts);

                    if (tp + cp > totalAmount) {
                        partsExceedErrSet(true);
                        valid = false;
                    }

                    if (!valid) {
                        return;
                    }

                    workingSet(true);


                    hub.Post("/base/public/jobToClose/close", {
                        ...body,
                        payCount: payItems.length,
                        token: token
                    }, res => {
                        if (res.success) closedSet(true)
                        else toastError(res.message)
                    })

                }}>
                    <div className="row mb-15">
                        <div className="col-6">
                            <div>
                                <div className="text-muted fs-8">
                                    <Icon icon="user" size={10} className="me-2" type="solid" />
                                    Name
                                </div>
                                <div className="ps-5">
                                    {job.fullName}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div>
                                <div className="text-muted fs-8">
                                    <Icon icon="phone-rotary" size={10} className="me-2" type="solid" />
                                    Phone
                                </div>
                                <div className="ps-5">
                                    {job.phone?.phoneFriendly}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <div>
                                <div className="text-muted fs-8">
                                    <Icon icon="map-location-dot" size={10} className="me-2" type="solid" />
                                    Address
                                </div>
                                <div className="ps-5">
                                    {job.address?.title}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="fw-bold fs-7 mb-2">
                        Payments
                        <span id="paymentItems-err" className="small text-danger mx-3 form-error">
                        </span>
                    </div>
                    <div className="mb-3">
                        {
                            payItems.map((p, k) =>
                                <FlexRow key={k} className="mb-2">
                                    <div className="me-2">
                                        {
                                            payItems.length == 1 ?
                                                <span>
                                                    <Icon icon="circle-minus" className="text-secondary" type="solid" size={18} />
                                                </span>
                                                :
                                                <span className="cursor-pointer" title="Remove Payment Item"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        var arr = payItems;
                                                        arr.splice(k, 1);
                                                        payItemsSet(t => [...arr]);
                                                    }}>
                                                    <Icon icon="circle-minus" className="text-danger" type="solid" size={18} />
                                                </span>
                                        }
                                    </div>
                                    <div className="row flex-grow-1">
                                        <div className="col-6">
                                            <DropDown id={"drp-paymenttype" + k} name={"paymentType" + k} deselect={false}
                                                source={paymentTypes} dataText="text" dataValue="value" block marginBottom={0} defaultValue={p.type?.toString()}
                                                onSelectItem={item => {
                                                    var arr = payItems;
                                                    arr[k].type = item.type;
                                                    payItemsSet(t => [...arr]);
                                                }} />
                                        </div>
                                        <div className="col-6">
                                            <TextBox id={"txt-paymentamount" + k} name={"amount" + k} block money
                                                placeholder="Amount" marginBottom={0} icon="dollar-sign" value={p.amount}
                                                onTextChanges={t => {
                                                    var arr = payItems;
                                                    arr[k].amount = t;
                                                    payItemsSet(t => [...arr]);
                                                }} />
                                        </div>
                                        {
                                            p.type == PaymentTypes.Check &&
                                            <div className="col-12 mt-2">
                                                <TextBox id={"txt-checknumber" + k} name={"checkNumber" + k} block
                                                    placeholder="Check Number" marginBottom={0} value={p.checkNumber}
                                                    onChange={e => {
                                                        var arr = payItems;
                                                        arr[k].checkNumber = e.target.value;
                                                        payItemsSet(t => [...arr]);
                                                    }} />
                                            </div>
                                        }
                                        {
                                            (p.type == PaymentTypes.CCCharge || p.type == PaymentTypes.CCManual) &&
                                            <div className="col-12 mt-2">
                                                <TextBox id={"txt-approval" + k} name={"approval" + k} block
                                                    placeholder="Approval" marginBottom={0} value={p.approval}
                                                    onChange={e => {
                                                        var arr = payItems;
                                                        arr[k].approval = e.target.value;
                                                        payItemsSet(t => [...arr]);
                                                    }} />
                                            </div>
                                        }

                                        {
                                            false &&
                                            <div className="w-150px me-2">
                                                <ToggleSwitch id={"toggle-cancelfee" + k} name={"cancelFee" + k} raw label="Cancel Fee" className="fs-7" defaultChecked={p.cancelFee} small />
                                            </div>
                                        }
                                    </div>
                                </FlexRow>


                            )
                        }
                        <div className="mt-5">
                            <button type="button" className="btn btn-light w-100"
                                onClick={e => {
                                    e.preventDefault();
                                    var arr = payItems;
                                    var last = arr[arr.length - 1];
                                    arr.push({});
                                    payItemsSet(t => [...arr]);
                                }}>
                                <Icon icon="plus" />
                                Add Payment
                            </button>
                        </div>
                    </div>


                    <div className="mt-10">
                        <TextBox name="techPart" block label="Tech Parts" labelUp money icon="dollar-sign" />
                        <TextBox name="companyParts" block label="Company Parts" labelUp money icon="dollar-sign" />
                        <TextBox name="invoiceNumber" block label="External Invoice Number" labelUp />
                    </div>

                    {
                        partsExceedErr &&
                        <div className="row">
                            <div className="col-12">
                                <span className="text-danger small">
                                    Parts amount can't be greater than total amount!
                                </span>
                            </div>
                        </div>
                    }


                    <div className="mt-10">
                        <div className="fs-1 fw-bolder">
                            {moneyFormatter.format(totalAmount)}
                        </div>
                        <div className="fs-7 fw-bold">
                            Total Amount
                        </div>
                    </div>

                    <div className="mt-10">
                        <button className="btn btn-dark w-100" type="submit" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Please wait...</span>
                                    </>
                                    : <span>Close The Job</span>
                            }
                        </button>
                    </div>

                </Form>
            }





        </div>
    )
}