import React, { useState } from 'react';
import Form from '../../../components/form/Form';
import Icon from '../../../components/base/Icon';
import Alert from '../../../components/base/Alert';
import { Link } from 'react-router-dom';
import Hub from '../../../api/Hub';
import { API_ROUTES } from '../../../core/Constants';
import HUBResponse from '../../../models/HUBResponse';
import { PasswordForgetValidator } from '../validators/PasswordForgetValidator';
import Div from '../../../components/base/Div';
import { useHub } from '../../../core/Global';

function ForgetPassword() {
    
    const [working, workingSet] = useState<boolean>(false);
    const [linkSent, linkSentSet] = useState<boolean>(false);
    const [apiError, apiErrorSet] = useState<string>();
    const hub = useHub();
    
    const submit = async (body: any, callback: any) => {
        workingSet(true);
        apiErrorSet(undefined);
        linkSentSet(false);
        hub.Post(API_ROUTES.PasswordForget, body, (res: HUBResponse<any>) => {
            if(res.success) {
                linkSentSet(true);
            }
            else {
                apiErrorSet(res.message);
            }
            workingSet(false);
        });
    }

  return (
    <Form className='form w-100' validator={PasswordForgetValidator} onSubmit={submit}>
        <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Forgot Password?</h1>
            <div className="text-gray-400 fs-6">Enter your email address to get reset link!</div>
        </div>
        <Div visible={(apiError ? true : false)} className="fv-row mb-10">
            <Alert text={apiError} type='danger' />
        </Div>
        <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark" htmlFor='txtemail'>
                Email Address
                <span id="email-err" className="small text-danger mx-2 form-error"></span>
            </label>
            <input className="form-control form-control-lg form-control-solid" autoFocus id='txtemail'
                    type="email" name="email" autoComplete='Off' />
        </div>

        <div className="text-center">
                <Div visible={linkSent} className='mb-5'>
                    <Alert text="Reset link has been sent!" type='success' />
                </Div>
                <Div visible={!linkSent}>
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={working}>
                        {!working && <span className="indicator-label">Send Reset Link</span>}
                        {
                            working &&
                            <span className="indicator-btn">
                                <Icon icon='asterisk' spin />
                                <span className='ms-2'>
                                    Sending...
                                </span>
                            </span>
                        }
                    </button>
                </Div>
            <Link to="/auth/login" className='btn btn-lg btn-default'>
                Back To Login
            </Link>
        </div>
    </Form>
  );
}

export default ForgetPassword;
