import React from 'react';
import {Routes, Route} from "react-router-dom";
import AgentDetailsView from '../views/AgentDetailsView';
import AgentsView from '../views/Agents';



function AgentsRoutes() {
  return (
    [
      <Route key={0} path="/agents/map" element={<AgentsView />} />,
      <Route key={1} path="/agents/view/:selectedId" element={<AgentDetailsView />} />
    ]
  );
}

export default AgentsRoutes;
