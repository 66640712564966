import envJsonFile from "./env.json";

// "development": {
    //     "apiUrl": "https://localhost:7252",
    //     "cdnUrl": "https://localhost:7252",
    //     "secretKey": "dspro-b9TFnY3R4dyTjOgk0GdPyS2StXVMEB"
    // },

class Environment {

    public static instance:Environment = new Environment();
    
    isDevelopment: boolean;
    isProduction: boolean;
    env: any;
    apiUrl: string;
    cdnUrl: string;
    wssHost: string;
    socketUrl: string;
    dashboardUrl: string;
    localHubUrl: string;
    secretKey: string;
    versionNumber:number;
    versionName:string;
    signUp:boolean;
    signUpDispatchers: boolean;
    flexibleDispatchers: boolean;
    brand: string;
    siteTitle: string;


    constructor() {
        this.isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
        this.isProduction = !this.isDevelopment;
        let envJson:any = envJsonFile;
        this.env = envJson[window.location.host];
        this.apiUrl = this.env.apiUrl;
        this.cdnUrl = this.env.cdnUrl;
        this.wssHost = this.env.wssHost;
        this.socketUrl = this.env.socketUrl;
        this.dashboardUrl = this.env.dashboardUrl;
        this.localHubUrl = this.env.localHubUrl ?? this.env.apiUrl;
        this.secretKey = this.env.secretKey;
        this.versionNumber = envJson.version.number;
        this.versionName = envJson.version.name;
        this.signUp = this.env.signUp;
        this.signUpDispatchers = this.env.signUpDispatchers;
        this.flexibleDispatchers = this.env.flexibleDispatchers;
        this.brand = this.env.brand;
        this.siteTitle = this.env.siteTitle;
    }

}

export default Environment;