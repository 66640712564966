import { useEffect, useState } from "react";
import { useHub } from "../../../../core/Global";
import Job from "../models/Job";
import ChatContactItem from "../../../chat/models/ChatContactItem";
import { API_ROUTES } from "../../../../core/Constants";
import Indicator from "../../../../components/base/Indicator";
import ChatBox from "../../../chat/components/ChatBox";
import Icon from "../../../../components/base/Icon";
import DropDown from "../../../../components/form/DropDown";
import TextArea from "../../../../components/form/TextArea";
import Button from "../../../../components/base/Button";
import Form from "../../../../components/form/Form";


interface IProps {
    job: Job
}
export default function JobChat(props: IProps) {

    const hub = useHub();
    const [loading, loadingSet] = useState<boolean>(true);
    const [chat, chatSet] = useState<ChatContactItem>();
    const [newChat, newChatSet] = useState<boolean>(false);
    const [newChatWorking, newChatWorkingSet] = useState<boolean>(false);
    const [selectedTab, selectedTabSet] = useState<string>("tech");

    useEffect(() => {

        newChatSet(false);
        newChatWorkingSet(false);

        if (selectedTab == "tech") {
            loadingSet(true);
            hub.Get(API_ROUTES.Chat + "getchatbytech/" + props.job.techId, res => {
                if (res.success) {
                    chatSet(res.data);
                    loadingSet(false);
                }
            })
        }
        else if (selectedTab == "client") {
            loadingSet(true);
            hub.Get(API_ROUTES.Chat + "getchatbyclient/" + props.job.id, res => {
                if (res.success) {
                    if (res.data) {
                        chatSet(res.data);
                        newChatSet(false);
                    }
                    else {
                        chatSet(undefined);
                        newChatSet(true);
                    }
                    loadingSet(false);
                }
            })
        }

    }, [selectedTab])

    return (
        <div>
            <ul className="nav nav-tabs nav-line-tabs fs-6 ps-5 mb-1">
                <li className="nav-item">
                    <a href="#" className={`nav-link fw-bolder fs-7 m-0 px-3 me-3 ${selectedTab == "tech" ? "active" : ""}`}
                        onClick={e => {
                            e.preventDefault();
                            selectedTabSet("tech");
                        }}>
                        <Icon icon="user-helmet-safety" className="fs-8 me-2" type="solid" />
                        Tech
                        <span className="ms-1 fs-8 fw-normal">
                            ({props.job.tech?.fullName})
                        </span>
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#" className={`nav-link fw-bolder fs-7 m-0 px-3 me-3 ${selectedTab == "client" ? "active" : ""}`}
                        onClick={e => {
                            e.preventDefault();
                            selectedTabSet("client");
                        }}>
                        <Icon icon="user" className="fs-8 me-2" type="solid" />
                        Client
                        <span className="ms-1 fs-8 fw-normal">
                            ({props.job.phone?.phoneFriendly})
                        </span>
                    </a>
                </li>
            </ul>
            {
                loading &&
                <div className="w-100" style={{ paddingTop: 350 }}>
                    <Indicator className="w-100" text="Loading Chat..." />
                </div>
            }

            {
                !loading && newChat && selectedTab == "client" &&
                <Form className="m-10"
                    onSubmit={(body, callback) => {

                        newChatWorkingSet(true);

                        hub.Post(API_ROUTES.ChatStart, {
                            ...body,
                            to: props.job.phoneRaw
                        }, res => {
                            if (res.success) {
                                hub.Get(API_ROUTES.Chat + "getchatbyclient/" + props.job.id, res => {
                                    if (res.success && res.data) {
                                        chatSet(res.data);
                                        newChatSet(false);
                                        loadingSet(false);
                                    }
                                })
                            }
                            else if (res.field) {
                                window.formError(res.field, res.message ?? "", "modal-start-newchat");
                                newChatWorkingSet(false);
                            }

                        })

                    }}>
                    <DropDown id="drp-startchat-from" name="from" label="From" labelUp block deselect={false} autoSelectFirstItem
                        apiSource={API_ROUTES.Chat + "fromnumbers"} dataValue="id" dataText="name" />
                    <TextArea name="message" label="Message" labelUp block placeholder="Write a message..." rows={6} />

                    <div className="text-center">
                        <Button text="Start Chat" isSubmit isRunning={newChatWorking} className="w-150px" />
                    </div>
                </Form>
            }


            {
                !loading && chat && <ChatBox chat={chat} chatId={chat.id} jobId={props.job?.id} inDrawer />
            }

        </div>
    )
}