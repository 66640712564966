import React from "react";
import Avatar from '../base/Avatar';
import Call from '../../modules/voice/calls/models/Call';
import Icon from '../base/Icon';
import { clearGlobalPlayer, useEnvironment, useGlobal, useHub } from '../../core/Global';
import { CallSources, CallTypes } from "../../core/Enums";
import { API_ROUTES } from "../../core/Constants";

type Props = {
    selectedCall: Call,
    onPause?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void,
    onPlay?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void
}

function FloatAudioPlayer(props: Props) {
    const env = useEnvironment()
    const hub = useHub()

    const [globalState, setGlobalState] = useGlobal();

    return (
        <>
            <div className="float-audio-player">
                <div className="d-flex align-items-center px-6 py-4">

                    {
                        props.selectedCall.callSource == CallSources.Twilio && props.selectedCall.callType == CallTypes.Outgoing ?
                        <>
                            <div className="me-2">
                                <Avatar avatarUrl={props.selectedCall.tech?.avatarUrl} width={20} />
                            </div>
                            <div>
                                <div className="fs-7 text-white limited-text mw-150px">
                                    {props.selectedCall.tech?.fullName}
                                </div>
                                <div className="fs-9 text-white ">
                                    {props.selectedCall.tech?.phoneNumber}
                                </div>
                            </div>
                        </>
                        : props.selectedCall.callSource == CallSources.Twilio && props.selectedCall.callType == CallTypes.Incoming ?
                        <>
                            <div className="me-2">
                                <Avatar width={20} />
                            </div>
                            <div>
                                <div className="fs-7 text-white limited-text mw-150px">
                                    {props.selectedCall.job?.fullName}
                                </div>
                                <div className="fs-9 text-white ">
                                    {props.selectedCall.job?.phone?.phoneFriendly}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <Avatar avatarUrl={props.selectedCall.sourceAgent?.country ? props.selectedCall.sourceAgent.country.flagSquare : props.selectedCall.sourceAgent?.avatarUrl} 
                                    width={20} />
                            </div>
                            <div className="ms-2">
                                {
                                    props.selectedCall.sourceAgent?.id ?
                                    <div className="fs-7 text-white">
                                        {props.selectedCall.sourceAgent?.name}
                                    </div>
                                    :
                                    <div className="fs-7 text-white">
                                        {props.selectedCall.sourceAgent?.number}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    <div className="text-center ms-5 me-5">
                        <Icon type="solid" icon="phone-volume" className="text-success" size={14} />
                    </div>

                    {
                        props.selectedCall.callSource == CallSources.Twilio && props.selectedCall.callType == CallTypes.Incoming ?
                        <>
                            <div className="me-2">
                                <Avatar avatarUrl={props.selectedCall.tech?.avatarUrl} width={20} />
                            </div>
                            <div>
                                <div className="fs-7 text-white limited-text mw-150px">
                                    {props.selectedCall.tech?.fullName}
                                </div>
                                <div className="fs-9 text-white ">
                                    {props.selectedCall.tech?.phoneNumber}
                                </div>
                            </div>
                        </>
                        : props.selectedCall.callSource == CallSources.Twilio && props.selectedCall.callType == CallTypes.Outgoing ?
                        <>
                            <div className="me-2">
                                <Avatar width={20} />
                            </div>
                            <div>
                                <div className="fs-7 text-white limited-text mw-150px">
                                    {props.selectedCall.job?.fullName}
                                </div>
                                <div className="fs-9 text-white ">
                                    {props.selectedCall.job?.phone?.phoneFriendly}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <Avatar avatarUrl={props.selectedCall.destinationAgent?.country ? props.selectedCall.destinationAgent.country.flagSquare : props.selectedCall.destinationAgent?.avatarUrl} 
                                    width={20} />
                            </div>
                            <div className="ms-2">
                                {
                                    props.selectedCall.destinationAgent?.id ?
                                    <div className="fs-7 text-white">
                                        {props.selectedCall.destinationAgent?.name}
                                    </div>
                                    :
                                    <div className="fs-7 text-white">
                                        {props.selectedCall.destinationAgent?.number}
                                    </div>
                                }
                            </div>
                        </>
                    }

                    
                    <div className="ms-5" style={{
                        flexGrow: 1
                    }}>
                        <audio autoPlay 
                                id="global-float-audio-player"
                                style={{
                                    width: "100%",
                                    minWidth: 400,
                                    height: 40
                                }}
                                onPause={e=> {
                                    setGlobalState({
                                        floatAudioStatus: {
                                            playing: false
                                        }
                                    });
                                    if (props.onPause) props.onPause(e);
                                }}
                                onPlay={e=> {
                                    setGlobalState({
                                        floatAudioStatus: {
                                            playing: true
                                        }
                                    });
                                    if (props.onPlay) props.onPlay(e);

                                    
                                    hub.Post(API_ROUTES.Calls + "listenstart", {
                                        id: props.selectedCall.id
                                    }, res => {
                                            
                                    })

                                }}
                                onEnded={e=> {
                                    //console.log(e, "end");
                                }}
                                src={env.localHubUrl + "/system/calls/recording/" + props.selectedCall.id} controls></audio>
                    </div>
                    <div className="ms-5">
                        <a href="#" onClick={e=> {
                                e.preventDefault();
                                $(".data-row").removeClass("selected");
                                clearGlobalPlayer();
                            }}>
                            <Icon type="solid" icon="times" color="#444" size={16} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FloatAudioPlayer;