import { useEffect, useState } from "react";
import Modal from "../../../../../components/base/Modal";
import { useHub } from "../../../../../core/Global";
import Job from "../../models/Job";
import TextBox from "../../../../../components/form/TextBox";
import { API_ROUTES } from "../../../../../core/Constants";
import PhoneInput from "../../../../../components/form/PhoneInput";

interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobClientEdit(props: IProps) {

    const hub = useHub();

    const [job, jobSet] = useState<Job>();
    useEffect(() => jobSet(props.job), [props.job]);

    return (
        <>
            <Modal id="modal-edit-jobclient"
                title="Edit Client Details"
                subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                visible open
                buttonText="Update Client"
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    var valid = true;
                    window.hideFormErrors();

                    if (!body.fullName || body.fullName.length < 2) {
                        window.showFormError("fullName", "Please write a valid name!", false, "modal-edit-jobclient");
                        valid = false;
                    }

                    if (!body.phone || body.phone.length < 10) {
                        window.showFormError("phone", "Please write a valid phone number!", false, "modal-edit-jobclient");
                        valid = false;
                    }

                    if (!valid) {
                        if (callback) callback();
                        return;
                    }

                    hub.Post(API_ROUTES.Jobs + "client", {
                        ...body,
                        id: props.job?.id
                    }, res => {
                        if (callback) callback(res);
                    })

                }}>

                <TextBox id="txt-edit-clientname" name="fullName" label="Client Name" labelUp marginBottom={5} defaultValue={job?.fullName} />
                <PhoneInput id="txt-edit-phone" name="phone" label="PhoneNumber" labelUp defaultPhone={job?.phone} />
                <TextBox id="txt-edit-email" name="email" label="Email" labelUp marginBottom={5} defaultValue={job?.emailAddress} email />
                <TextBox id="txt-edit-company" name="company" label="Company" labelUp marginBottom={5} defaultValue={job?.company} />
                <PhoneInput id="txt-edit-secondphone" name="contactPhone" label="Secondary Phone" labelUp defaultPhone={job?.phoneContact} />

            </Modal>
        </>
    )
}