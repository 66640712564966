import { HubConnectionState } from "@microsoft/signalr";
import React, { useEffect, useState } from "react";
import Hub from "../../api/Hub";
import Icon from "../../components/base/Icon";
import { useHub, useSocket } from "../../core/Global";
import { ResponseRatePieChartModel } from "../models/PieChartsModels";


type Props = {
    model?: ResponseRatePieChartModel
}

let socketInterval: NodeJS.Timeout;
let silentTime: number = 300;
let start: any, end: any, type: any;

function ResponseRatePieChart(props: Props) {

    const hub = useHub();
    const socket = useSocket();
    const [model, modelSet] = useState<ResponseRatePieChartModel>();
    const [dateLabel, dateLabelSet] = useState<string>("Today");
    const [typeLabel, typeLabelSet] = useState<string>("Incoming");
    const [dateQueryParam, dateQueryParamSet] = useState<string>(window.now().format("DDMMYYYY") + "," + window.now().format("DDMMYYYY"));
    const [typeQueryParam, typeQueryParamSet] = useState<string>("1");

    const fetch = async () => {

        var q = "?";
        q += "from=" + (start ?? window.now()).format("DDMMYYYY");
        q += "&to=" + (end ?? window.now()).format("DDMMYYYY");
        q += "&type=" + (type ?? "Incoming");

        hub.Get("/dashboard/main/responseRatePieChart" + q, res => {
            modelSet(res.data);
        })
    }

    const startSocket = async () => {
        // await socket.start();

        // socket.invoke("StartListenToCallsList", getUserListenId());

        // socket.on("UpdateCallsList", () => {
        //     fetch();
        //     silentTime = 300;
        // });

        // if (socketInterval) clearInterval(socketInterval);

        // socketInterval = setInterval(async () => {
        //     if (socket.state != HubConnectionState.Connected) await startSocket();
        // }, 5000);

    }

    useEffect(() => {

        window.daterange("ResponseRatePieChart_date_picker", (st: any, en: any, lbl: any) => {
            dateLabelSet(lbl);
            dateQueryParamSet(st.format("DDMMYYYY") + "," + en.format("DDMMYYYY"));
            if (lbl == "Custom Range") dateLabelSet(start.format("DD MMM") + "-" + end.format("DD MMM"));
            else dateLabelSet(lbl);
            start = st;
            end = en;
            fetch();
        });

        window.inlineDropdown("ResponseRatePieChart_type", ["Incoming", "Outgoing"], tp => {
            type = tp;
            typeLabelSet(tp);
            typeQueryParamSet((tp == "Incoming" ? "1" : "2"));
            fetch();
        });

        startSocket();

        return () => {
            if (socketInterval) clearInterval(socketInterval);
        };


    }, [])

    useEffect(() => {
        modelSet(props.model);
    }, [props.model])

    useEffect(() => {

        if (model) {
            window.createCircleChart("chart-ResponseRatePieChart",
                [model.answered, model.dropped, model.missed],
                [window.getBootstrapColor("success"), window.getBootstrapColor("warning"), window.getBootstrapColor("danger")]);

            window.createCircleChart("chart-ResponseRatePieChart-small",
                [model.answered, model.dropped, model.missed],
                [window.getBootstrapColor("success"), window.getBootstrapColor("warning"), window.getBootstrapColor("danger")]);
        }

    }, [model])

    return (
        <>
            <div className="card card-flush d-none d-xxl-block">
                <div className="card-header pt-5">
                    <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="chart-title fw-bolder text-dark me-2 lh-1">
                                {model?.responseRate}%
                            </span>
                        </div>
                        <span className="text-gray-400 pt-1 fw-bold fs-8 fs-xxl-7">
                            Response Rate
                        </span>
                    </div>
                    <div>
                        <div id="ResponseRatePieChart_date_picker" className="d-flex align-items-center cursor-pointer fs-9 fs-xxl-8">
                            <span>{dateLabel}</span>
                            <Icon icon="chevron-down" className="ms-2" size={11} />
                        </div>
                        <div id="ResponseRatePieChart_type" className="d-flex align-items-center justify-content-end cursor-pointer fs-9 fs-xxl-8 mt-2">
                            <span>{typeLabel}</span>
                            <Icon icon="chevron-down" className="ms-1" size={11} />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-items-center flex-grow-1">
                        <div>
                            <div id="chart-ResponseRatePieChart" data-kt-size="70" data-kt-line="11"></div>
                        </div>
                        <div className="ms-auto">
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                                <div className="text-gray-500 flex-grow-1 me-6 fs-7">
                                    <a href={"/system/calls/history?status=ANSWERED&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                        Answered
                                    </a>
                                </div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.answered}
                                </div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                                <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                                <div className="text-gray-500 flex-grow-1 fs-7 me-6">
                                    <a href={"/system/calls/history?status=NO%20ANSWER&status=BUSY&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                        Missed
                                    </a>
                                </div>
                                <div className="fw-boldest text-gray-700 fs-7 ms-auto">
                                    {model?.missed}
                                </div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                                <div className="bullet w-8px h-6px rounded-2 bg-secondary me-3"></div>
                                <div className="text-gray-500 flex-grow-1 fs-7 me-6">
                                    <a href={"/system/calls/history?status=FAILED&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                        Failed
                                    </a>
                                </div>
                                <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                    {model?.dropped}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card card-flush d-xxl-none">
                <div className="card-body pt-2 pb-4 d-flex align-items-center">
                    <div className="d-flex flex-column">

                        <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-success me-3"></div>
                            <div className="text-gray-500 flex-grow-1 me-6 fs-8">
                                <a href={"/system/calls/history?status=ANSWERED&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Answered
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.answered}
                            </div>
                        </div>
                        <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                            <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                            <div className="text-gray-500 flex-grow-1 fs-8 me-6">
                                <a href={"/system/calls/history?status=NO%20ANSWER&status=BUSY&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Missed
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 fs-7 ms-auto">
                                {model?.missed}
                            </div>
                        </div>
                        <div className="d-flex fs-6 fw-bold align-items-center mt-1">
                            <div className="bullet w-8px h-6px rounded-2 bg-secondary me-3"></div>
                            <div className="text-gray-500 flex-grow-1 fs-8 me-6">
                                <a href={"/system/calls/history?status=FAILED&callTypes=" + typeQueryParam + "&callDate=" + dateQueryParam} target="_blank" className="text-gray-500">
                                    Failed
                                </a>
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end fs-7">
                                {model?.dropped}
                            </div>
                        </div>

                    </div>

                    <div className="ms-auto">
                        <div id="chart-ResponseRatePieChart-small" data-kt-size="70" data-kt-line="11"></div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default ResponseRatePieChart;