import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { setPage, useGlobal } from '../../../core/Global';
import ConnectedAccounts from "../components/ConnectedAccounts";
import ProfileDetailsForm from "../components/ProfileDetailsForm";
import SignInMethods from "../components/SignInMethods";

type Props = {
  action?: string
}

function ProfileSignInMethods(props: Props) {

    const [globalState] = useGlobal();


  return (
    <>
      <div className="row">
        <div className="col-12">
          <SignInMethods />
        </div>
        <div className="col-lg-12">
          <ConnectedAccounts />
        </div>
      </div>
    </>
  );
}

export default ProfileSignInMethods;
