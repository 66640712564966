import React from 'react';
import { Routes, Route } from "react-router-dom";
import UsersGroupsList from '../views/UsersGroupsList';
import UsersList from '../views/UsersList';
import CompanyForm from '../views/CompanyForm';
import CompanyList from '../views/CompanyList';
import RolesForm from '../views/RolesForm';
import { UserTypes } from '../../../core/Enums';
import ShiftsList from '../views/ShiftsList';
import ShiftsCalendar from '../views/ShiftsCalendar';
import ShiftsDepartments from '../views/ShiftsDepartments';
import ShiftsTypes from '../views/ShiftsTypes';
import ShiftsView from '../views/ShiftsView';
import AccountDetails from '../views/AccountDetails';
import TechGroupsList from '../views/TechGroupsList';
import UserGroupManage from '../views/UserGroupManage';


function AccountsRoutes() {
  let k = 0;
  return (
    [
      <Route key={k++} path="/accounts/usergroups" element={<UsersGroupsList />} />,
      <Route key={k++} path="/accounts/usergroup/:selectedId" element={<UserGroupManage />} />,
      <Route key={k++} path="/accounts/usergroup/:selectedId/:selectedPage" element={<UserGroupManage />} />,

      <Route key={k++} path="/accounts/admins" element={<UsersList userType={UserTypes.Admin} />} />,
      <Route key={k++} path="/accounts/customers" element={<UsersList userType={UserTypes.Customer} />} />,
      <Route key={k++} path="/accounts/agents" element={<UsersList userType={UserTypes.Agent} />} />,
      <Route key={k++} path="/accounts/technicians" element={<UsersList userType={UserTypes.Technician} />} />,

      <Route key={k++} path="/accounts/techgroups" element={<TechGroupsList />} />,

      <Route key={k++} path="/accounts/manage/:selectedId" element={<AccountDetails />} />,
      <Route key={k++} path="/accounts/manage/:selectedId/:selectedPage" element={<AccountDetails />} />,
      <Route key={k++} path="/accounts/manage/:selectedId/:selectedPage/:selectedAction" element={<AccountDetails />} />,

      <Route key={k++} path="/accounts/shifts/departments" element={<ShiftsDepartments />} />,
      <Route key={k++} path="/accounts/shifts/types" element={<ShiftsTypes />} />,
      <Route key={k++} path="/accounts/shifts/view" element={<ShiftsView />} />,
      <Route key={k++} path="/accounts/shifts/calendar" element={<ShiftsCalendar />} />,
      <Route key={k++} path="/accounts/shifts/calendar/:agentId" element={<ShiftsCalendar />} />,
      //<Route key={k++} path="/accounts/users" element={<UsersList />} />,
      //  <Route key={k++} path="/accounts/users/edit/:selectedId" element={<UsersForm />} />,
      //  <Route key={k++} path="/accounts/users/draft/:selectedId" element={<UsersForm />} />,
      <Route key={k++} path="/accounts/companies" element={<CompanyList />} />,
      <Route key={k++} path="/accounts/companies/create" element={<CompanyForm />} />,
      <Route key={k++} path="/accounts/companies/edit/:selectedId" element={<CompanyForm />} />,
      <Route key={k++} path="/accounts/companies/draft/:selectedId" element={<CompanyForm />} />,
      <Route key={k++} path="/accounts/roles/group/:selectedId" element={<RolesForm isGroup />} />,
      <Route key={k++} path="/accounts/roles/type/:selectedId" element={<RolesForm isType />} />
    ]
  );
}

export default AccountsRoutes;
