import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setPage, useGlobal, useHub } from '../../../core/Global';
import Form from '../../../components/form/Form';
import TextBox from '../../../components/form/TextBox';
import Hub from "../../../api/Hub";
import { toastSuccess } from "../../../components/base/Toast";


function SignInMethods() {

    const hub = useHub();
    const [globalState] = useGlobal();
    const [ChangeEmail, ChangeEmailSet] = useState<boolean>(false);
    const [ChangePassword, ChangePasswordSet] = useState<boolean>(false);

    useEffect(() => {
        if(ChangePassword) $("input[name=currentPassword]").focus();
    }, [ChangePassword])

    useEffect(() => {
        if(ChangeEmail) $("input[name=email]").focus();
    }, [ChangeEmail])


    const updateEmail = (body:any, callback:any) => {
        if(!body.email || body.email == "" || window.isValidEmail(body.email)) {
            $("#email-err").html("Email Address is not valid!");
            $("#email-err").show();
            $("input[name=email]").focus();
            $("input[name=email]").select();
            return;
        }
        if(!body.password || body.password == "") {
            $("#password-err").html("Please write your new password!");
            $("#password-err").show();
            $("input[name=password]").focus();
            return;
        }
        
        hub.Put("/accounts/users/profile/email/" + globalState.user?.id, body, res => {
            if (res.success) {
                toastSuccess("Your email has been changed successfully!");
                ChangeEmailSet(false);
            }
        })
    }

    const updatePassword = (body:any, callback:any) => {
        if(!body.currentPassword || body.currentPassword == "") {
            $("#currentPassword-err").html("Please write your current password!");
            $("#currentPassword-err").show();
            $("input[name=currentPassword]").focus();
            return;
        }
        if(!body.newPassword || body.newPassword == "") {
            $("#newPassword-err").html("Please write your new password!");
            $("#newPassword-err").show();
            $("input[name=newPassword]").focus();
            return;
        }
        if(!body.confirmPassword || body.confirmPassword == "") {
            $("#confirmPassword-err").html("Please confirm your new password!");
            $("#confirmPassword-err").show();
            $("input[name=confirmPassword]").focus();
            return;
        }
        if(body.newPassword != body.confirmPassword) {
            $("#confirmPassword-err").html("Confirm password isn't match to new password!");
            $("#confirmPassword-err").show();
            $("input[name=confirmPassword]").focus();
            $("input[name=confirmPassword]").select();
            return;
        }

        hub.Put("/accounts/users/profile/password/" + globalState.user?.id, body, res => {
            if (res.success) {
                toastSuccess("Your password changed successfully!");
                ChangePasswordSet(false);
            }
        })
    }
  return (
    <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Sign In Methods</h3>
          </div>
        </div>
        <div className="card-body border-top p-9">
            <div className="d-flex flex-wrap align-items-center">
                {
                    !ChangeEmail &&
                    <>
                        <div>
                            <div className="fs-6 fw-bolder mb-1">Email Address</div>
                            <div className="fw-bold text-gray-600">{globalState.user?.email}</div>
                        </div>
                        <div className="ms-auto">
                            <button onClick={e=> ChangeEmailSet(true)} className="btn btn-light btn-active-light-primary btn-sm">Change Email</button>
                        </div>
                    </>
                }
                {
                    ChangeEmail &&
                    <div className="flex-row-fluid">
                        <Form className="form" onSubmit={updateEmail}>
                            <TextBox label="New Email" name="email" email block  />
                            <TextBox label="Confirm Password" name="password" password block />
                            <div className="d-flex justify-content-end">
                                <button onClick={e=> ChangeEmailSet(false)} type="button" className="btn btn-color-gray-400 btn-active-light-primary btn-sm me-2 px-6">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm px-6">Update Email</button>
                            </div>
                        </Form>
                    </div>
                }
                
                
            </div>
            <div className="separator separator-dashed my-6"></div>
            <div className="d-flex flex-wrap align-items-center mb-10">
                {
                    !ChangePassword &&
                    <>
                        <div>
                            <div className="fs-6 fw-bolder mb-1">Password</div>
                            <div className="fw-bold text-gray-600">************</div>
                        </div>
                        <div className="ms-auto">
                            <button onClick={e=> ChangePasswordSet(true)} className="btn btn-light btn-active-light-primary btn-sm">Change Password</button>
                        </div>
                    </>
                }

                {
                    ChangePassword &&
                    <div className="flex-row-fluid">
                        <Form id="form_change_password" className="form" onSubmit={updatePassword}>
                            <TextBox label="Current Password" name="currentPassword" password block />
                            <TextBox label="New Password" name="newPassword" password block />
                            <TextBox label="Confirm Password" name="confirmPassword" password block />
                            <div className="d-flex justify-content-end">
                                <button onClick={e=> ChangePasswordSet(false)} type="button" className="btn btn-color-gray-400 btn-active-light-primary btn-sm px-6">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm me-2 px-6">Update Password</button>
                            </div>
                        </Form>
                    </div>
                }
                
                
                
            </div>
            {
                false &&
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                    <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black" />
                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black" />
                        </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                        <div className="mb-3 mb-md-0 fw-bold">
                            <h4 className="text-gray-900 fw-bolder">Secure Your Account</h4>
                            <div className="fs-6 text-gray-700 pe-7">Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code</div>
                        </div>
                        <a href="#" className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_two_factor_authentication">Enable</a>
                    </div>
                </div>
            }
            
        </div>
    </div>
  );
}

export default SignInMethods;
