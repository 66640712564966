import { useEffect, useState } from "react"

interface IProps{
    id?: string
    checked?: boolean
    label?: string
    className?: string
    onChange?: (e:boolean) => void
}

export default function Checkbox(props: IProps) {

    const id = "chk-" + (Math.floor(Math.random() * 1000000));
    const [inputId] = useState<string>(props.id ?? id);

    return (
        <div className={"form-check form-check-custom form-check-solid form-check-sm " + props.className}>
            <input className="form-check-input form-check-sm" type="checkbox" 
                checked={props.checked} id={inputId}
                onChange={e=> {
                    if (props.onChange) props.onChange(e.target.checked)
                }}/>
            <label className="form-check-label ms-1" htmlFor={inputId}>
                {props.label}
            </label>
        </div>
    )
}