import { getOwner, openUpdateEnvironment } from "../../../core/Global";
import Avatar from "../../base/Avatar";
import FlexRow from "../../base/FlexRow";
import Icon from "../../base/Icon";




export default function HeaderEnvironment() {

    const owner = getOwner()

    return (
        <FlexRow className="me-3" onClick={e => {
            openUpdateEnvironment()
        }}>
            <div style={{ height: 20 }}>
                <Icon icon="briefcase" type="solid" className="text-gray-600 me-1" size={16} />
            </div>
            <div className="fw-bold fs-7" style={{ height: 20 }}>
                {owner?.companyName ?? "Select Environment"}
            </div>
        </FlexRow>
    )
}