import "./Filtering.css";
import React, { useEffect, useState } from "react";
import Slider from '@mui/material/Slider';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Avatar from "../base/Avatar";
import Indicator from "../base/Indicator";
import Hub from '../../api/Hub';
import $ from 'jquery';
import Environment from '../../env/env';
import { FilterParam } from '../../models/BrowseOptions';
import { ListItem } from "../../core/Types";
import Icon from "../base/Icon";
import Time from "../timer/Time";
import { getUser } from "../../core/Global";
import FlexRow from "../base/FlexRow";


type FilteringProps = {
    id?: string
    groupId?: string
    apiRoute?: string
    filteringProps?: Array<any>
    filteringValues?: Array<FilterValue>
    loaded?: boolean
    clear?: boolean
    onFilter?: (params: FilterParam[], callback: () => void) => void
    onItemsChanged?: (items: FilterItem[], query?: string) => void
    onRouteParamsBinded?: (params: FilterParam[]) => void
    setPage?: () => void
    page?: number
    staticSource?: any[]
    preventLinkUpdate?: boolean
}

type FilterGroup = {
    title?: string,
    name?: string,
    icon?: string,
    items: FilterItem[]
}

export type FilterItem = {
    groupid?: string
    done?: boolean
    title?: string
    name?: string
    icon?: string
    type?: string
    customValue?: boolean
    value?: FilterValue
    any?: boolean
    none?: boolean
    op?: string
    selected?: boolean
    iskeyword?: boolean

}
type FilterValue = {
    icon?: string
    color?: string
    imageUrl?: string
    value?: string
    text?: string
    subText?: string
}

let filterBaseValues: Array<FilterValue>;
let dateFilter: boolean;
export default function Filtering(props: FilteringProps) {

    const [timeFilterHours, timeFilterHoursSet] = useState<number[]>([])
    const [timeFilterMinutes, timeFilterMinutesSet] = useState<number[]>([])
    const [timeFromHour, timeFromHourSet] = useState<string>("00");
    const [timeFromMin, timeFromMinSet] = useState<string>("00");
    const [timeToHour, timeToHourSet] = useState<string>("23");
    const [timeToMin, timeToMinSet] = useState<string>("59");

    const user = getUser();
    const hub = new Hub<any>(props.apiRoute);
    const env = new Environment();

    const [filterProps, filterPropsSet] = useState<Array<any>>([]);
    const [filterGroups, filterGroupsSet] = useState<Array<FilterGroup>>([]);
    const [filterItems, filterItemsSet] = useState<Array<FilterItem>>([]);
    const [currentFilterItem, currentFilterItemSet] = useState<FilterItem>();
    const [filteringItems, filteringItemsSet] = useState<boolean>(false);
    const [filterItemProc, filterItemProcSet] = useState<number>(0);
    const [filterValsFetching, filterValsFetchingSet] = useState<boolean>(false);
    const [filterValsFetched, filterValsFetchedSet] = useState<boolean>(false);
    const [filterVals, filterValsSet] = useState<Array<FilterValue>>([]);

    const [filtering, filteringSet] = useState<boolean>(false);
    const [filteringType, filteringTypeSet] = useState<string>("text");

    const [rangeType, rangeTypeSet] = useState<string>("");
    const [rangeMin, rangeMinSet] = useState<number>(0);
    const [rangeMax, rangeMaxSet] = useState<number>(0);
    const [rangeNumbers, rangeNumbersSet] = React.useState<number[]>([]);
    const [rangeFromVal, rangeFromValSet] = useState<string>("00:00");
    const [rangeToVal, rangeToValSet] = useState<string>("59:59");



    const [selectedOption, selectedOptionSet] = useState<number>(-1);
    const [routeParams, routeParamsSet] = useState<ListItem[]>([]);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (props.clear) {
            selectedOptionSet(0);
            filterItemProcSet(0);
            dateFilter = false;
            filteringTypeSet("text");
            rangeTypeSet("");
            filterPropsSet(props.filteringProps ?? []);
            $("div.daterangepicker").remove();
            filterGroupsSet([]);
        }
    }, [props.clear])

    const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).removeClass("text-danger");
        var k = e.target?.value?.toLowerCase();
        var currentFilterItem = filterItems[filterItems.length - 1];

        if (filterItemProc == 0) {
            var fprops = (props.filteringProps ?? []).filter((item, i) => {
                return (item?.title?.toLowerCase().indexOf(k) ?? -1) >= 0;
            });

            filterPropsSet(f => [...fprops]);
            if (fprops.length > 0) {
                selectedOptionSet(0);
                filteringItemsSet(true);
            }
            else {
                selectedOptionSet(-1);
                filteringItemsSet(false);
            }

        }

        if (filterItemProc == 2) {
            var fvals = (filterBaseValues ?? []).filter((item, i) => {
                return (item?.text?.toLowerCase().indexOf(k) ?? -1) >= 0
            });

            if (fvals.length > 0) {
                selectedOptionSet(0);
                filteringItemsSet(true);
            }
            else {
                if (!currentFilterItem.customValue) {
                    $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).addClass("text-danger");
                }
                selectedOptionSet(-1);
                filteringItemsSet(false);
            }

            filterValsSet(f => [...fvals]);

            if (currentFilterItem.customValue) {
                fetchFilterValues(currentFilterItem.name, k, true);
            }

        }

    }

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {



        if (e.key == "ArrowDown") handleArrowDown();
        else if (e.key == "ArrowUp") handleArrowUp();
        else if (e.key == "ArrowRight") handleArrowRight();
        else if (e.key == "ArrowLeft") handleArrowLeft();
        else if (e.key == 'Enter') handleEnter();
        else if (e.key == "Escape") handleEscape();
        else if (e.key == "Backspace") handleBackspace();

        if (filterItemProc == 1) {
            e.preventDefault();
            return;
        }
    }

    const handleArrowDown = () => {
        if (!filteringItems && !anySelected()) {
            filteringItemsSet(true);
            selectedOptionSet(0);
            return;
        }
        var s = selectedOption + 1;
        if (filterItemProc == 0 && s >= filterProps.length) s = filterProps.length - 1;
        if (filterItemProc == 1 && s >= 2) s = 1;
        if (filterItemProc == 2 && s >= filterVals.length) s = filterVals.length - 1;
        selectedOptionSet(s);
    }
    const handleArrowUp = () => {
        var s = selectedOption - 1;
        if (s < 0) s = 0;
        selectedOptionSet(s);
    }
    const handleArrowRight = () => {
        let arr = filterItems ?? [];
        var selected = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].selected) {
                selected = i;
            }
            arr[i].selected = false;
        }
        if (selected < 0 || selected >= (arr.length - 1)) {
            //Do Nothing
        }
        else {
            arr[selected + 1].selected = true;
        }
        filterItemsSet(f => [...arr]);
    }
    const handleArrowLeft = () => {
        let arr = filterItems ?? [];
        var selected = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].selected) {
                selected = i;
            }
            arr[i].selected = false;
        }
        if (selected == 0) {
            arr[0].selected = true;
            filteringItemsSet(false);
        }
        else if (selected < 0) {
            arr[arr.length - 1].selected = true;
            filteringItemsSet(false);
        }
        else {
            arr[selected - 1].selected = true;
            filteringItemsSet(false);
        }
        filterItemsSet(f => [...arr]);
    }

    const handleEnter = () => {
        var currentFilterItem = filterItems[filterItems.length - 1];
        if (selectedOption > -1) {
            if (filterItemProc == 0) {
                selectFilterItem(filterProps[selectedOption]);
                return;
            }
            if (filterItemProc == 1) {
                selectFilterOp(selectedOption == 0 ? "equals" : "not-equal");
                return;
            }
            if (filterItemProc == 2) {
                selectFilterValue(filterVals[selectedOption]);
                return;
            }
        }
        else {
            var k = $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val()?.toString() ?? "";
            if (filterItemProc == 0 && k != "") {
                let arr = filterItems ?? [];
                arr.push({
                    icon: "magnifying-glass",
                    name: "Keywords",
                    done: true,
                    value: {
                        value: k,
                        text: k
                    },
                    iskeyword: true
                });
                filterItemsSet(f => [...arr]);
                addToGroup(arr[arr.length - 1]);
                addRouteParam(arr[arr.length - 1]);
                //sendFilter();
                $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
                resetForNext();
                if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
            }
            else if (filterItemProc == 2 && k != "") {
                if (!currentFilterItem.customValue) return;
                let arr = filterItems ?? [];
                arr[arr.length - 1].value = {
                    text: k,
                    value: k
                }
                filterItemsSet(f => [...arr]);
                //sendFilter();
                $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
                filterItemProcSet(0);
                resetForNext();
                if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
            }
        }
    }
    const handleEscape = () => {
        if (filterItemProc > 0) {
            let arr = filterItems ?? [];
            if (arr.length > 0) {
                arr.pop();
                filterItemsSet(f => [...arr]);
                currentFilterItemSet(undefined);
                resetForNext();
            }
        }
        unselectAll();
        filterItemProcSet(0);
        filteringItemsSet(false);
        selectedOptionSet(-1);
        rangeTypeSet("");
        $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
    }

    const handleBackspace = () => {
        if (($("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val()?.toString().length ?? 0) > 0) return;
        if (filterItemProc > 0) {
            let arr = filterItems ?? [];
            if (arr.length > 0) {
                arr.pop();
                filterItemsSet(f => [...arr]);
                currentFilterItemSet(undefined);
                resetForNext();
                if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
            }
        }

        return;

        let arr = filterItems ?? [];
        if (arr.length < 1) return;
        var selected = -1;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].selected) {
                selected = i;
                break;
            }
        }
        if (selected < 0) {
            arr[arr.length - 1].selected = true;
            filterItemsSet(f => [...arr]);
        }
        else {
            arr.splice(selected, 1);
            filterItemsSet(f => [...arr]);
            sendFilter();
            resetForNext();
        }
        filteringItemsSet(false);
    }
    const handleClick = () => {
        filteringItemsSet(true);
    }

    const fetchFilterValues = async (name?: string, keyword?: string, preventLoader?: boolean, type?: string, staticSource?: string) => {
        if (!preventLoader) {
            filterValsFetchingSet(true);
            filterValsFetchedSet(false);
        }



        if (env.isDevelopment) console.log("GetFilterValues", routeParams);

        if (staticSource && props.staticSource) {
            var min = Math.min(...props.staticSource.filter(f => f.id != "total").map(f => f[staticSource]));
            var max = Math.max(...props.staticSource.filter(f => f.id != "total").map(f => f[staticSource]));
            rangeMinSet(min);
            rangeMaxSet(max);
            rangeNumbersSet([min, max]);
            filterValsFetchingSet(false);
            filterValsFetchedSet(true);
        }
        else {
            await hub.GetFilterValues({
                name: name,
                keyword: keyword
            }, routeParams, res => {
                filterBaseValues = res.data ?? [];
                filterValsSet(filterBaseValues);
                filterValsFetchingSet(false);
                filterValsFetchedSet(true);
                if (filterBaseValues.length > 0) {
                    selectedOptionSet(0);
                    filteringItemsSet(true);
                    if (type && type == "range") {
                        var min = 0;
                        var max = 999;
                        for (var i = 0; i < filterBaseValues.length; i++) {
                            if (filterBaseValues[i].text == "min") min = Number.parseInt(filterBaseValues[i].value ?? "0");
                            if (filterBaseValues[i].text == "max") max = Number.parseInt(filterBaseValues[i].value ?? "0");
                        }
                        rangeMinSet(min);
                        rangeMaxSet(max);
                        rangeNumbersSet([min, max]);
                        // window.makeRangeSlider("filtering-range-slider" + (props.id ? "-" + props.id : ""), min, max, 
                        //     (start, end) => {
                        //         rangeFromSet(start);
                        //         rangeToSet(end);
                        //     });
                    }
                }
            })
        }



    }

    let filterBluring = false;
    const filterFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (dateFilter) return;
        filteringItemsSet(true);
        filterBluring = false;
    }

    const filterBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        filterBluring = true;
        setTimeout(() => {
            if (filterBluring) filteringItemsSet(false);
        }, 200);
    }

    const selectFilterItem = (item: any) => {
        filterBluring = false;
        let arr = filterItems ?? [];
        arr.push({
            title: item.title,
            name: item.name,
            icon: item.icon,
            type: item.type,
            customValue: item.customValue
        });

        currentFilterItemSet({
            title: item.title,
            name: item.name,
            icon: item.icon,
            type: item.type,
            customValue: item.customValue
        });

        filterItemsSet(f => [...arr]);
        if (item.type == "date") {
            dateFilter = true;
            filteringTypeSet("date");
            filteringItemsSet(false);
            setTimeout(() => {
                window.filteringDateRange((start, end) => {
                    selectFilterValue({
                        text: start.format("DD MMM") + " - " + end.format("DD MMM"),
                        value: start.format("DDMMYYYY") + "," + end.format("DDMMYYYY")
                    });
                });
                setTimeout(() => {
                    $("#txt-datatable-date-filtering" + (props.id ? "-" + props.id : "")).focus();
                }, 100);
            }, 100);
        }
        else if (item.type == "range") {
            filteringTypeSet("range");
            rangeTypeSet(item.rangetype ?? "");
            filterItemProcSet(2);
            fetchFilterValues(item.name, "", false, item.type, item.staticSource);
        }
        else if (item.type == "time") {
            var hours = [];
            for (var h = 0; h < 24; h++) hours.push(h);
            var mins = [];
            for (var m = 0; m < 60; m++) mins.push(m);
            timeFilterHoursSet(hours);
            timeFilterMinutesSet(mins);
            filteringTypeSet("time");
            filterItemProcSet(2);
            fetchFilterValues(item.name, "", false, item.type);
        }
        else if (item.type == "boolean") {
            filterItemProcSet(2);
            filterValsSet([
                {
                    text: "Yes",
                    value: "True"
                },
                {
                    text: "No",
                    value: "False"
                }
            ]);
            filterValsFetchingSet(false);
            filterValsFetchedSet(true);
        }
        else {
            dateFilter = false;
            filteringTypeSet("text");
            filterItemProcSet(1);
            selectedOptionSet(0);
            $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
            $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).focus();
        }
    }

    const alreadyFiltering = (name: string) => {
        let arr = filterItems ?? [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == name) return true;
        }
        return false;
    }

    const selectFilterOp = (op: string) => {
        let arr = filterItems ?? [];
        filterBluring = false;
        if (arr.length < 1) {
            filterItemProcSet(0);
            filterItemsSet(f => []);
            return;
        }
        var filterName = arr[arr.length - 1].name;
        arr[arr.length - 1].op = op;

        var cur = currentFilterItem ?? {};
        cur.op = op;
        currentFilterItemSet(cur);

        filterItemsSet(f => [...arr]);
        filterItemProcSet(2);
        selectedOptionSet(-1);
        fetchFilterValues(filterName);
        $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
        $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).focus();
    }

    const selectFilterValueAny = () => {
        selectFilterValue(undefined, true);
    }

    const selectFilterValueNone = () => {
        selectFilterValue(undefined, false, true);
    }

    const selectFilterValue = (val?: FilterValue, any?: boolean, none?: boolean) => {
        if (env.isDevelopment) {
            console.log("selectFilterValue", val, filterItems);
        }
        filterBluring = false;
        let arr = filterItems ?? [];
        if (arr.length < 1) {
            filterItemProcSet(0);
            filterItemsSet(f => []);
            resetForNext();
            return;
        }

        arr[arr.length - 1].any = any;
        arr[arr.length - 1].none = none;
        arr[arr.length - 1].value = val;
        arr[arr.length - 1].done = true;


        var routeParamExists = false;
        if (arr[arr.length - 1].type == "boolean") {
            var name = arr[arr.length - 1].name;

            for (var i = 0; i < (arr.length - 1); i++) {
                if (arr[i].name == name) {
                    arr.splice(i, 1);
                    routeParamExists = true;
                    break;
                }
            }

        }

        currentFilterItemSet(undefined);
        filterItemsSet(f => [...arr]);
        addToGroup(arr[arr.length - 1]);
        if (routeParamExists) updateRouteParam(arr[arr.length - 1]);
        else addRouteParam(arr[arr.length - 1]);
        resetForNext();
        if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
    }

    const applyRange = () => {
        filterBluring = false;
        let arr = filterItems ?? [];
        const rangeFrom = rangeNumbers[0];
        const rangeTo = rangeNumbers[1];
        var rangeText = rangeFrom + "-" + rangeTo;

        if (rangeType == "time") {
            rangeText = toTime(rangeFrom) + " - " + toTime(rangeTo);
        }

        arr[arr.length - 1].value = {
            text: rangeText,
            value: rangeFrom + "-" + rangeTo
        };

        currentFilterItemSet(undefined);
        filterItemsSet(f => [...arr]);
        addToGroup(arr[arr.length - 1]);
        addRouteParam(arr[arr.length - 1]);
        resetForNext();
        if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
    }

    const applyTimes = () => {
        filterBluring = false;
        let arr = filterItems ?? [];
        var timesText = timeFromHour + ":" + timeFromMin + " - " + timeToHour + ":" + timeToMin;
        var timesVal = timeFromHour + timeFromMin + "-" + timeToHour + timeToMin;
        arr[arr.length - 1].value = {
            text: timesText,
            value: timesVal
        };
        currentFilterItemSet(undefined);
        filterItemsSet(f => [...arr]);
        addToGroup(arr[arr.length - 1]);
        addRouteParam(arr[arr.length - 1]);
        resetForNext();
        if (props.onItemsChanged) props.onItemsChanged(arr, FilterItemsToQueryString(arr));
    }

    const toTime = (sec_num: number) => {

        if (sec_num == 0) return "00:00"

        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        var result = "";

        if (hours > 0) result += (hours < 10 ? "0" + hours : hours) + ":";
        result += (minutes < 10 ? "0" + minutes : minutes) + ":";
        result += (seconds < 10 ? "0" + seconds : seconds);

        return result;
    }




    const addToGroup = (item: FilterItem) => {
        var arr = filterGroups ?? [];
        var exsist = false;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == item.name) {
                exsist = true;
                arr[i].items = arr[i].items ?? [];
                if (item.type == "boolean") arr[i].items = [];
                arr[i].items.push(item);
            }
        }

        if (!exsist) {
            var newItem: FilterGroup = {
                name: item.name,
                title: item.title,
                icon: item.icon,
                items: []
            }
            newItem.items?.push(item);
            arr.push(newItem);
        }

        filterGroupsSet(f => [...arr]);
    }

    const removeFromGroup = (item: FilterItem) => {
        let arr = filterGroups ?? [];
        let groupIndex = 0;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == item.name) {
                var itemIndex = 0;
                groupIndex = i;
                for (var j = 0; j < arr[i].items.length; j++) {
                    if (arr[i].items[j].name == item.name && arr[i].items[j].value?.value == item.value?.value) {
                        itemIndex = j;
                        break;
                    }
                }
                arr[i].items.splice(itemIndex, 1);
                break;
            }
        }
        if (env.isDevelopment) console.log("removeFromGroup", arr, groupIndex);
        if (arr[groupIndex].items.length < 1) arr.splice(groupIndex, 1);
        filterGroupsSet(f => [...arr]);
    }

    const setRouteParam = (item: FilterItem) => {
        var name = item.name;
        if (item.op == "not-equal") name += "Not";
        var val = encodeURIComponent(item.value?.value ?? "");
        var p = routeParams ?? [];
        var exists = false;
        for (var i = 0; i < p.length; i++) {
            if (p[i].name == name) {
                p[i].value = val;
                exists = true;
                break;
            }
        }
        if (!exists) {
            p.push({
                name: name,
                value: val
            });
        }
        routeParamsSet(m => [...p]);
    }

    const addRouteParam = (item: FilterItem) => {
        var name = item.name;
        if (item.op == "not-equal") name += "Not";
        var val = encodeURIComponent(item.value?.value ?? "");
        var p = routeParams ?? [];
        var exists = false;

        for (var i = 0; i < p.length; i++) {
            if (p[i].name == name && p[i].value == val) {
                exists = true;
                break;
            }
        }

        if (!exists) {
            for (var i = 0; i < p.length; i++) {
                if (p[i].name == "page") {
                    p.splice(i, 1);
                    break;
                }
            }

            p.push({
                name: name,
                value: val
            });
            routeParamsSet(m => [...p]);
        }
    }

    const updateRouteParam = (item: FilterItem) => {
        var name = item.name;
        var val = encodeURIComponent(item.value?.value ?? "");
        var p = routeParams ?? [];
        for (var i = 0; i < p.length; i++) {
            if (p[i].name == name) {
                p[i].value = val;
            }
        }

        routeParamsSet(m => [...p]);
    }

    const removeRouteParam = (item: FilterItem) => {
        var name = item.name;
        if (item.op == "not-equal") name += "Not";
        var val = encodeURIComponent(item.value?.value ?? "");
        var p = routeParams ?? [];
        var p1: ListItem[] = [];
        for (var i = 0; i < p.length; i++) {
            if (p[i].name == name && p[i].value == val) continue;
            p1.push(p[i]);
        }
        routeParamsSet(m => [...p1]);
    }

    const removeFilter = (item: FilterItem) => {

        removeFromGroup(item);
        removeRouteParam(item);

        let flt = filterItems ?? [];
        var itemIndex = flt.indexOf(item);
        flt.splice(itemIndex, 1);
        filterItemsSet(f => [...flt]);
        setTimeout(sendFilter, 100);
        resetForNext();
        if (props.onItemsChanged) props.onItemsChanged(flt, FilterItemsToQueryString(flt));
    }

    const resetForNext = () => {
        selectedOptionSet(0);
        filterItemProcSet(0);
        dateFilter = false;
        filteringTypeSet("text");
        rangeTypeSet("");
        rangeFromValSet("00:00");
        rangeToValSet("59:59");
        filterPropsSet(props.filteringProps ?? []);
        $("div.daterangepicker").remove();
        setTimeout(() => {
            $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).val("");
            $("#txt-datatable-filtering" + (props.id ? "-" + props.id : "")).focus();
        }, 100);
    }

    const sendFilter = () => {

        if (!props.onFilter) return;

        var params: FilterParam[] = [];

        for (var i = 0; i < filterItems.length; i++) {
            var item = filterItems[i];
            params.push({
                name: item.name ?? "",
                value: item.value?.value ?? "",
                op: item.op ?? ""
            });
        }

        filteringSet(true);
        props.onFilter(params, () => {
            filteringSet(false);
        });
    }

    const unselectAll = () => {
        let arr = filterItems ?? [];
        for (var i = 0; i < arr.length; i++) {
            arr[i].selected = false;
        }
        filterItemsSet(f => [...arr]);
    }

    const anySelected = () => {
        let arr = filterItems ?? [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].selected) return true;
        }
        return false;
    }

    const badgeColor = (item: FilterItem) => {
        if (item.value) {
            if (item.op == "not-equal") {
                return item.selected ? "badge-danger" : "badge-light-danger";
            }
            else if (item.type == "boolean" && item.value.value == "False") {
                return item.selected ? "badge-danger" : "badge-light-danger";
            }
            else {
                return item.selected ? "badge-primary" : "badge-light-primary";
            }
        }
        else {
            return item.selected ? "badge-secondary" : "badge-light";
        }
    }

    let searchParamsBinded = false;
    const bindSearchParams = () => {
        let arr = filterItems ?? [];
        var params: FilterParam[] = [];

        searchParams.forEach((val, key) => {
            var exists = false;
            if (env.isDevelopment) console.log("Filtering bindSearchParams", key, val, arr);
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].name == key && arr[i].value?.value == val) {
                    exists = true;
                    break;
                }
            }

            if (exists) return;

            var name = key;
            var not = false;
            if (name.endsWith("Not")) {
                not = true;
                name = name.substring(0, name.length - 3);
            }

            var p = getFilteringProp(name);

            if (p) {
                var item: FilterItem = {
                    name: name,
                    op: not ? "not-equal" : "",
                    value: getFilterValue(name, val, p.type),
                    icon: p.icon,
                    title: p.title,
                    customValue: p.customValue,
                    type: p.type,
                    done: true,
                    iskeyword: name.toLowerCase() == "keywords"
                };

                if (arr.indexOf(item) < 0) {
                    arr.push(item);
                    addToGroup(item);
                    addRouteParam(item);

                    params.push({
                        name: item.name ?? "",
                        value: item.value?.value ?? "",
                        op: item.op ?? ""
                    });

                }
            }
        })
        filterItemsSet(f => [...arr]);
        searchParamsBinded = true;
        if (props.onRouteParamsBinded) props.onRouteParamsBinded(params);
    }

    const getFilterValue = (name: string, value?: string, type?: string) => {

        if (name.toLowerCase() == "keywords") {
            return {
                value: value,
                text: value
            }
        }

        if (type == "boolean") {
            return {
                value: value,
                text: value == "True" ? "Yes" : "No"
            }
        }

        if (!props.filteringValues) return undefined;
        for (var i = 0; i < props.filteringValues.length; i++) {
            if (props.filteringValues[i].value == value) return props.filteringValues[i];
        }
        return {
            value: value,
            text: value
        }
    }

    const getFilteringProp = (name: string) => {
        if (name.toLowerCase() == "keywords") return {
            title: "Keywords",
            icon: "magnifying-glass",
            name: "Keywords"
        };
        if (!props.filteringProps) return undefined;
        for (var i = 0; i < props.filteringProps.length; i++) {
            if (props.filteringProps[i].name == name) return props.filteringProps[i];
        }
        return undefined;
    }

    const buildRoute = (page?: number) => {
        var q = "";

        if (page && page > 1) {
            q += "page=" + page;
        }

        for (var i = 0; i < routeParams.length; i++) {
            if (q != "") q += "&";
            q += routeParams[i].name + "=" + routeParams[i].value;
        }

        if (!props.preventLinkUpdate) {
            navigate({
                pathname: location.pathname,
                search: "?" + q
            });
        }
    }

    useEffect(() => {
        if (!props.loaded) return;
        if (env.isDevelopment) console.log("Filtering", "routeParams Effect");

        buildRoute();
        sendFilter();

    }, [routeParams])

    // useEffect(() => {
    //     var arr:any = [];

    //     filterItems.forEach(item => {
    //         item.groupid = props.groupId;
    //         if(item.done) arr.push(item);
    //     });

    //     //
    //     filterItemsDoneSet(m=> [...arr]);

    // }, [filterItems])

    useEffect(() => {
        if (props.loaded) return;
        if (env.isDevelopment) console.log("Filtering", "props.filteringValues Effect", props.filteringValues);
        if (props.filteringValues) bindSearchParams();
    }, [props.filteringValues])

    useEffect(() => {
        if (!props.loaded) return;
        if (props.page) {
            buildRoute(props.page);
            // var arr = routeParams ?? [];
            // var exists = false;
            // for(var i = 0; i < arr.length; i++) {
            //     if(arr[i].name == "page") {
            //         arr[i].value = props.page.toString();
            //         exists = true;
            //         break;
            //     }
            // }
            // if(!exists) {
            //     arr.push({
            //         name: "page",
            //         value: props.page.toString()
            //     });
            // }

            // routeParamsSet(p => [...arr]);
        }
    }, [props.page])


    useEffect(() => {
        filterPropsSet(props.filteringProps ?? []);
    }, [props.filteringProps])

    return (
        <div className="d-flex flex-grow w-100 align-items-center position-relative mb-2 data-table-bar">
            <div className="d-flex flex-grow align-items-center filtering ps-2" style={{ flexGrow: 1 }}>

                {
                    filterGroups.map((item, key) =>
                        <div key={key} title={item.title} className="filter-group d-flex align-items-center me-1">
                            <i className={"me-2 ms-2 fas fa-" + item.icon} style={{ fontSize: 12, color: "inherit" }}></i>
                            {
                                item.items?.map((item, key) =>
                                    <div key={key} className={"badge me-1 " + badgeColor(item)}>
                                        <span>
                                            {item.value?.text}
                                        </span>
                                        {
                                            item.value &&
                                            <i className={"ms-2 fs-9 fas fa-times cursor-pointer"}
                                                style={{ fontSize: 12 }}
                                                onClick={e => removeFilter(item)}></i>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    currentFilterItem &&
                    <div className={"badge me-1 " + badgeColor(currentFilterItem)}>

                        <i className={"me-2 fas fa-" + currentFilterItem.icon} style={{ fontSize: 12, color: "inherit" }}></i>

                        <span>
                            {currentFilterItem.title}
                        </span>

                        {
                            currentFilterItem.op && !currentFilterItem.value &&
                            <i className={"ms-1 me-1 fs-9 fas fa-" + currentFilterItem.op} style={{ fontSize: 12 }}></i>
                        }
                    </div>
                }

                <div className="position-relative w-100">
                    {
                        filteringType == "date" ?
                            <input type="text" className="form-control form-control-solid form-control-sm"
                                id={"txt-datatable-date-filtering" + (props.id ? "-" + props.id : "")}
                                autoComplete="Off"
                                placeholder={"Search"} />
                            :
                            filteringType == "range" ?
                                <input type="text" className="form-control form-control-solid form-control-sm" readOnly />
                                :
                                filteringType == "time" ?
                                    <input type="text" className="form-control form-control-solid form-control-sm" readOnly />
                                    :
                                    <input type="text" className="form-control form-control-solid form-control-sm"
                                        id={"txt-datatable-filtering" + (props.id ? "-" + props.id : "")}
                                        autoComplete="Off"
                                        placeholder={"Search"}
                                        onClick={handleClick}
                                        onFocus={filterFocus}
                                        onBlur={filterBlur}
                                        onKeyDown={keyDown}
                                        onChange={searchChange} />
                    }

                    {
                        filterProps && filteringItems && filterItemProc == 0 && filterProps.length > 0 &&
                        <div className="filter-options">
                            {
                                filterProps.map((item, key) =>
                                    <>
                                        {
                                            ((item.systemAdmin && !user?.isSuperAdmin) || (item.once && alreadyFiltering(item.name))) ?
                                                <></>
                                                :
                                                <div key={key}
                                                    onClick={e => selectFilterItem(item)}
                                                    className={"d-flex align-items-center filter-item" + (selectedOption == key ? " filter-item-selected" : "")}>
                                                    <i className={"fas fa-" + item.icon}></i>
                                                    <span className="ms-2">
                                                        {item.title}
                                                    </span>
                                                </div>
                                        }
                                    </>
                                )
                            }
                        </div>
                    }
                    {
                        props.filteringProps && filteringItems && filterItemProc == 1 &&
                        <div className="filter-options">
                            <div className={"filter-item" + (selectedOption == 0 ? " filter-item-selected" : "")}
                                onClick={e => selectFilterOp('equals')}>
                                <i className="fas fa-equals"></i>
                                <span className="ms-2">is</span>
                            </div>
                            <div className={"filter-item" + (selectedOption == 1 ? " filter-item-selected" : "")}
                                onClick={e => selectFilterOp('not-equal')}>
                                <i className="fas fa-not-equal"></i>
                                <span className="ms-2">is not</span>
                            </div>
                        </div>
                    }
                    {
                        props.filteringProps && filteringItems && filterItemProc == 2 &&
                        <div className={"filter-options" + ((filteringType == "range") ? " mw-250px p-4 pb-6" : filteringType == "time" ? " w-250px p-4 pb-6" : "") + (filterVals && filterVals.length > 6 ? " scroll mh-400px" : "")}>
                            {
                                filterValsFetching &&
                                <div className="py-10">
                                    <Indicator noText={true} />
                                </div>
                            }
                            {
                                filterValsFetched &&
                                <>
                                    {
                                        filteringType == "range" ?
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    {/* <div className="d-flex align-items-center">
                                                    <a href="#" className="me-1 fs-8"
                                                        onClick={e=> {
                                                            e.preventDefault();
                                                            var v = rangeFrom - 1;
                                                            if (v < rangeMin) v = rangeMin;
                                                            rangeFromSet(v);
                                                            window.setRangeSliderValues("filtering-range-slider" + (props.id ? "-" + props.id : ""), v, rangeTo);
                                                        }}>
                                                        <Icon icon="minus" />
                                                    </a>
                                                    <div className="fs-8 me-1">
                                                        {rangeType == "time" ? <Time totalSeconds={rangeFrom}/> : <>{rangeFrom}</>}
                                                    </div>
                                                    <a href="#" className="fs-8" 
                                                        onClick={e=> {
                                                            e.preventDefault();
                                                            var v = rangeFrom + 1;
                                                            if (v > rangeMax) v = rangeMax;
                                                            rangeFromSet(v);
                                                            window.setRangeSliderValues("filtering-range-slider" + (props.id ? "-" + props.id : ""), v, rangeTo);
                                                        }}>
                                                        <Icon icon="plus" />
                                                    </a>
                                                </div>
                                                <div className="d-flex align-items-center ms-auto">
                                                    <a href="#" className="me-1 fs-8"
                                                        onClick={e=> {
                                                            e.preventDefault();
                                                            var v = rangeTo - 1;
                                                            if (v < rangeMin) v = rangeMin;
                                                            rangeToSet(v);
                                                            window.setRangeSliderValues("filtering-range-slider" + (props.id ? "-" + props.id : ""), rangeFrom, v);
                                                        }}>
                                                        <Icon icon="minus" />
                                                    </a>
                                                    <div className="fs-8 me-1">
                                                        {rangeType == "time" ? <Time totalSeconds={rangeTo}/> : <>{rangeTo}</>}
                                                    </div>
                                                    <a href="#" className="fs-8" 
                                                        onClick={e=> {
                                                            e.preventDefault();
                                                            var v = rangeTo + 1;
                                                            if (v > rangeMax) v = rangeMax;
                                                            rangeToSet(v);
                                                            window.setRangeSliderValues("filtering-range-slider" + (props.id ? "-" + props.id : ""), rangeFrom, v);
                                                        }}>
                                                        <Icon icon="plus" />
                                                    </a>
                                                </div> */}
                                                    <Slider
                                                        value={rangeNumbers}
                                                        onChange={(e, newVal) => {
                                                            const rng = newVal as number[];
                                                            rangeNumbersSet(rng);
                                                            let rngFrom = rng[0];
                                                            let rngTo = rng[1];

                                                            if (rangeType == "time") {
                                                                rangeFromValSet(toTime(rngFrom));
                                                                rangeToValSet(toTime(rngTo));
                                                            }
                                                            else {
                                                                rangeFromValSet(rngFrom.toString());
                                                                rangeToValSet(rngTo.toString());
                                                            }
                                                        }}
                                                        min={rangeMin} max={rangeMax}
                                                        valueLabelDisplay="off"
                                                    />
                                                </div>
                                                <FlexRow justify="between">
                                                    <input type="tel" className="form-control form-control-solid form-control-sm input-range-numbers"
                                                        value={rangeFromVal} maxLength={5}
                                                        onKeyPress={e => {
                                                            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            var val = e.target.value;
                                                            if (val.length == 2) val += ":";
                                                            if (val.includes(":")) {
                                                                const arr = val.split(":");
                                                                var m = Number.parseInt(arr[0]);
                                                                var s = Number.parseInt(arr[1]);
                                                                if (isNaN(m)) m = 0;
                                                                if (isNaN(s)) s = 0;
                                                                const t = (m * 60) + s;
                                                                var rn = rangeNumbers ?? [];
                                                                rn[0] = t;
                                                                rangeNumbersSet(r => [...rn]);
                                                            }
                                                            rangeFromValSet(val);

                                                        }} />
                                                    <span>-</span>
                                                    <input type="tel" className="form-control form-control-solid form-control-sm input-range-numbers"
                                                        value={rangeToVal}
                                                        onKeyPress={e => {
                                                            if (!/[0-9]/.test(e.key) && e.key != "Enter") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            var val = e.target.value;
                                                            if (val.length == 2) val += ":";
                                                            if (val.includes(":")) {
                                                                const arr = val.split(":");
                                                                var m = Number.parseInt(arr[0]);
                                                                var s = Number.parseInt(arr[1]);
                                                                if (isNaN(m)) m = 0;
                                                                if (isNaN(s)) s = 0;
                                                                const t = (m * 60) + s;
                                                                var rn = rangeNumbers ?? [];
                                                                rn[1] = t;
                                                                rangeNumbersSet(r => [...rn]);
                                                            }
                                                            rangeToValSet(val);
                                                        }} />
                                                </FlexRow>
                                                <div className="mt-3 text-center">
                                                    <a href="#" className="badge badge-light-danger me-1"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            handleEscape();
                                                        }}>
                                                        <Icon icon="times" className="text-danger" />
                                                    </a>
                                                    <a href="#" className="badge badge-light-success"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            applyRange();
                                                        }}>
                                                        <Icon icon="check" className="text-success" />
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            filteringType == "time" ?
                                                <div>
                                                    <div className="d-flex align-items-center mb-3 fs-8">
                                                        <span>From</span>
                                                        <span className="ms-auto">To</span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            <select value={timeFromHour} onChange={e => timeFromHourSet(e.target.value)}>
                                                                {
                                                                    timeFilterHours.map((hour, key) =>
                                                                        <option key={key} value={(hour < 10 ? "0" + hour : hour)}>{(hour < 10 ? "0" + hour : hour)}</option>)
                                                                }
                                                            </select>
                                                            <span className="mx-1">:</span>
                                                            <select value={timeFromMin} onChange={e => timeFromMinSet(e.target.value)}>
                                                                {
                                                                    timeFilterMinutes.map((min, key) =>
                                                                        <option key={key} value={(min < 10 ? "0" + min : min)}>{(min < 10 ? "0" + min : min)}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="d-flex align-items-center ms-auto">
                                                            <select value={timeToHour} onChange={e => timeToHourSet(e.target.value)}>
                                                                {
                                                                    timeFilterHours.map((hour, key) =>
                                                                        <option key={key} value={(hour < 10 ? "0" + hour : hour)}>{(hour < 10 ? "0" + hour : hour)}</option>)
                                                                }
                                                            </select>
                                                            <span className="mx-1">:</span>
                                                            <select value={timeToMin} onChange={e => timeToMinSet(e.target.value)}>
                                                                {
                                                                    timeFilterMinutes.map((min, key) =>
                                                                        <option key={key} value={(min < 10 ? "0" + min : min)}>{(min < 10 ? "0" + min : min)}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 text-center">
                                                        <a href="#" className="badge badge-danger me-1"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                handleEscape();
                                                            }}>
                                                            <Icon icon="times" className="text-white" />
                                                        </a>
                                                        <a href="#" className="badge badge-success"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                applyTimes();
                                                            }}>
                                                            <Icon icon="check" className="text-white" />
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        filterVals.map((val, key) =>
                                                            <div key={key} className={"filter-item" + (selectedOption == key ? " filter-item-selected" : "")}
                                                                onClick={e => selectFilterValue(val)}>
                                                                <div className="d-flex align-items-center">
                                                                    {
                                                                        val.imageUrl &&
                                                                        <Avatar avatarUrl={val.imageUrl} width={30} className="me-2" />
                                                                    }
                                                                    {
                                                                        !val.imageUrl && val.icon &&
                                                                        <i className={"me-2 fas fa-" + val.icon + (val.color ? " text-" + val.color : "")} style={{ fontSize: 12, color: (val.color ? val.color : "inherit") }}></i>
                                                                    }
                                                                    <div>
                                                                        <div className="fs-8">
                                                                            {val.text}
                                                                        </div>
                                                                        <div className="fs-9 text-gray">
                                                                            {val.subText}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>

                {
                    filtering &&
                    <span className="svg-icon svg-icon-1 me-2">
                        <i className="fas fa-spinner fa-spin"></i>
                    </span>
                }

                {
                    !filtering &&
                    <>
                        {
                            filterGroups.length > 0 &&
                            <a href="#" onClick={e => {
                                e.preventDefault();
                                filterItemProcSet(0);
                                filterItemsSet([]);
                                filterGroupsSet([]);
                                routeParamsSet([]);
                                sendFilter();
                                if (props.onItemsChanged) props.onItemsChanged([], FilterItemsToQueryString([]));
                            }}>
                                <Icon icon="times" className="ps-2 pe-2 pt-1" size={15} />
                            </a>
                        }
                        <span className="svg-icon svg-icon-1 me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                            </svg>
                        </span>
                    </>
                }

            </div>
        </div>
    )
}

export function FilterItemsToQueryString(items?: FilterItem[]) {
    var q = "";

    if (items) {
        items.forEach(f => {
            if (q != "") q += "&";
            q += f.name + (f.op == "not-equal" ? "Not" : "") + "=" + encodeURIComponent(f.value?.value ?? "")
        });
    }

    return q;
}