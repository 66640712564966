import { useEffect, useState } from "react";
import { useHub } from "../../core/Global";
import Icon from "../base/Icon";





export default function DataTableActiveToggle(data: any) {

    const hub = useHub();
    const [active, activeSet] = useState<boolean>(false);
    const [working, workingSet] = useState<boolean>(false);

    useEffect(() => {

        activeSet(data.isActive ?? false);

    }, [data.id])

    return (
        <div>
            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid position-relative">
                <input className="form-check-input" type="checkbox" checked={active} disabled={working}
                    onChange={e => {

                        let active = e.target.checked ? "1" : "0";

                        workingSet(true);

                        hub.Post(data.route + "activetoggle", {
                            id: data.id,
                            active: active
                        }, res => {
                            if (res.success) {
                                if (data.callback) data.callback();
                                activeSet(res.data);
                            }
                            workingSet(false);
                        })


                    }} />
                {
                    working &&
                    <span style={{
                        position: "absolute",
                        right: active ? 8 : "auto",
                        left: active ? "auto" : 4
                    }}>
                        <Icon icon="spinner-third" spin type="solid" size={12} />
                    </span>
                }
            </label>
        </div>
    )
}