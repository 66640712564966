import React, { useEffect, useState } from "react";
import LiveCall from '../../voice/calls/models/LiveCall';
import Environment from '../../../env/env';
import Hub from '../../../api/Hub';
import Icon from '../../../components/base/Icon';
import Avatar from '../../../components/base/Avatar';
import LiveCallActions from '../../../components/calls/LiveCallActions';
import AgentDetailsModel from '../models/AgentDetailsModel';
import { AgentStatus } from '../models/AgentDetailsModel';
import Timer from '../../../components/timer/Timer';
import { useHub, useSocket } from '../../../core/Global';
import Modal from "../../../components/base/Modal";
import { API_ROUTES } from "../../../core/Constants";
import { AgentExtensionItem } from "../models/Agent";
import { CallTypes } from "../../../core/Enums";
import AgentExtensionButton from "../../../components/agent/AgentExtensionButton";
import AgentStatusButton from "../../../components/agent/AgentStatusButton";
import { ListItem } from "../../../core/Types";
import CallAgent from "../../voice/calls/models/CallAgent";

type Props = {
    agentId?: string
   model?: AgentDetailsModel
   selectedPage?: string
   selectPage: (page: string) => void
}


const MenuItems = [
    "Overview", "Calls History", "Working Calendar"
]

function AgentHeader(props: Props) {

    const env = new Environment();
    const hub = useHub();
    const socket = useSocket();
    const [selectedPage, selectedPageSet] = useState<string>("Overview");
    const [model, modelSet] = useState<AgentDetailsModel>();

    const [freeExtensions, freeExtensionsSet] = useState<ListItem[]>([]);
    const [selectedExtToClaim, selectedExtToClaimSet] = useState<ListItem>();
    const [extensions, extensionsSet] = useState<AgentExtensionItem[]>([]);
    const [selectedExtension, selectedExtensionSet] = useState<AgentExtensionItem>();
    
    const [status, statusSet] = useState<AgentStatus>();

    const fetch = () => {
        hub.Get("/accounts/agents/header/" + props.agentId, res => {
            if (res.data) {
                extensionsSet(res.data.exts);
                statusSet(res.data.status);
            }
        });
    }


    const CallerID = (props: {agent?: CallAgent} ) => (
        <span className="ms-5 d-flex align-items-center">
            {
                props.agent?.country ?
                <Avatar width={15} avatarUrl={props.agent.country.flagSquare} />
                :
                <Avatar width={15} avatarUrl={props.agent?.avatarUrl} />
            }

            {
                props.agent?.unknown ?
                <span className="ms-1 fs-8">
                    {props.agent.number}
                </span>
                :
                <span className="ms-1 fs-8">
                    {
                        props.agent?.name
                    }
                </span>
            }

        </span>
    )

    const openExtModal = () => {
        window.openModal("modal-add-ext");
        hub.Get("/system/extensions/free/" + props.agentId, res => {
            freeExtensionsSet(res.data);
        });
    }

    useEffect(()=> {
        
        var socketKey = socket.start(
            skt => {
                skt.invoke("StartListenToAgent", props.agentId);
                skt.on("UpdateAgentHeader", () => {
                    fetch();
                });
            }, skt => {
                skt.invoke("StopListenToAgent", props.agentId);
                skt.off("UpdateAgentHeader");
            });

        selectedPageSet("Overview");
        props.selectPage("Overview");

        return () => {
            socket.stop(socketKey);
        };

    }, [props.agentId])

    useEffect(() => {
        modelSet(props.model);

        if (props.model?.agentStatus) {
            statusSet(props.model.agentStatus);
        }
        if (props.model?.extensions) {
            extensionsSet(props.model.extensions);
        }

    }, [props.model])

    useEffect(() => {
        selectedPageSet(props.selectedPage ?? "Overview");
    }, [props.selectedPage])




  return (
    <>
    <div className="card">
        <div className="card-body pt-9 pb-0">          
            <div className="d-flex mb-3">
                
                <div className="me-7 mb-4">
                    <div className="d-flex flex-column">
                        <div className="symbol symbol-50px symbol-lg-100px symbol-fixed position-relative">
                            <img src={model?.avatar ?? "/assets/media/avatars/blank.png"} />
                            <div className={"bg-"+(status?.colorClass ?? "secondary")+" position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px"}></div>
                        </div>
                        {
                            model?.loginAt &&
                            <div className="d-flex align-items-center mt-2 ms-2">
                                <Icon icon="sign-in" className="me-2" />
                                <span className="fs-9 fw-bold">{model.loginAt}</span>
                            </div>
                        }
                        {
                            model?.loginAt && !model.logoutAt &&
                            <div className="d-flex align-items-center mt-2 ms-2">
                                <Icon icon="clock" className="me-2" />
                                <span className="fs-9 fw-bold">
                                    <Timer totalSeconds={model.loginDuration} />
                                </span>
                            </div>
                        }
                        {
                            model?.logoutAt &&
                            <div className="d-flex align-items-center mt-2 ms-2">
                                <Icon icon="sign-out" className="me-2" />
                                <span className="fs-9">{model.logoutAt}</span>
                            </div>
                        }
                    </div>
                    
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex align-items-center">
                        <a className="text-gray-900 fs-2 fw-bolder me-4">
                            {model?.firstName + " " + model?.lastName} 
                        </a>
                        <div>
                            <AgentStatusButton status={status} id={props.agentId} onUpdate={fetch} />
                        </div>                        
                    </div>

                    {
                        model?.queueName &&
                        <div className="text-gray-900 fs-6 fw-bold">
                            {model?.queueName}
                        </div>
                    }

                    {
                        extensions.map((ext, key) => 
                            <div key={key} className="d-flex align-items-center mb-2 mt-2 me-20">
                                
                                <AgentExtensionButton showTimers ext={ext} status={model?.agentStatus} onSelect={() => selectedExtensionSet(ext)}/>
                                
                                {
                                    ext.currentCall &&
                                    <>
                                        <div className="ms-5">
                                            <Icon icon={ext.currentCall?.callType == CallTypes.Incoming ? "phone-arrow-down-left" 
                                                : ext.currentCall?.callType == CallTypes.Outgoing ? "phone-arrow-up-right"
                                                : ext.currentCall?.callType == CallTypes.Local ? "user-headset"
                                                : "dot"} />
                                        </div>
                                        <CallerID agent={ext.currentCall.sourceAgent?.id == props.agentId ? ext.currentCall.destinationAgent : ext.currentCall.sourceAgent} />
                                        {
                                            ext.currentCall.queueName &&
                                            <div className='ms-10 fs-8'>
                                                <Icon icon='square-phone' className='me-1 fs-8' />
                                                {ext.currentCall.queueNumber}
                                                -
                                                {ext.currentCall.queueName}
                                            </div>
                                        }
                                        <div className="ms-auto">
                                            <LiveCallActions call={ext.currentCall} />
                                        </div>
                                    </>
                                }
                                
                                
                            </div>
                        )
                    }

                    {
                        extensions.length < 3 &&
                        <div className="d-flex align-items-center">
                            <a href="#" onClick={e=> {
                                e.preventDefault();
                                openExtModal();
                            }}
                                className="btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark btn-agent-ext">
                                <Icon icon="plus" className="me-1" />
                                0000
                            </a>  
                        </div>
                    }
                </div>
            </div>
            <ul className="nav nav-stretch nav-line-tabs border-transparent fs-6 fw-bolder">
                {
                    MenuItems.map((item, key) => 
                    <li key={key} className="nav-item">
                        <a href="#" onClick={e=> {
                            e.preventDefault();
                            selectedPageSet(item);
                            props.selectPage(item);
                        }}
                            className={"nav-link text-active-primary ms-0 me-5 py-3" + (selectedPage == item ? " active" : "")}>{item}</a>
                    </li>
                    )
                }
            </ul>
        </div>
    </div>
    
        
        <Modal id="modal-ext-remove"
            title={"Extension"}
            buttonText="Remove Extension"
            buttonColor="danger"
            onSubmit={(body, callback) => {

                hub.Post(API_ROUTES.RevokeExtension, {
                    ext: selectedExtension?.extId,
                    userId: props.agentId
                }, res => {
                    fetch();
                    if(callback) callback(res);
                });

            }}>
            <div className="row">
                <div className="col-sm-6">
                    <div className="d-flex align-items-center">
                        <Icon icon="phone-office" size={14} />
                        <span className="fs-6 ms-2 fw-bold">{selectedExtension?.extNumber}</span>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal id="modal-add-ext"
            title="Add Extension"
            buttonText="Add Extension"
            onSubmit={freeExtensions.length == 0 ? undefined :  (body, callback) => {
                if(!selectedExtToClaim) {
                    if (callback) callback();
                    return;
                }
                hub.Post(API_ROUTES.ClaimExtension, {
                    exts: selectedExtToClaim.value,
                    userId: props.agentId
                }, res => {
                    fetch();
                    if(callback) callback(res);
                });
                
            }}>
                <div>
                    {
                        freeExtensions.map((ext, key) => 
                            <button key={key} 
                                onClick={e=> {
                                    e.preventDefault();
                                    selectedExtToClaimSet(ext)
                                }}
                                className={'btn '+ (ext.value == selectedExtToClaim?.value ? "btn-primary" : "btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary") +' me-2 mb-2'}>
                                <Icon icon='phone-office' className='me-1' />
                                {ext.name}
                            </button>
                        )
                    }
                    {
                        freeExtensions.length == 0 &&
                        <div>
                            There is no free extension!
                        </div>
                    }
                </div>
        </Modal>

    </>
    
  );
}

export default AgentHeader;
