import { useEffect, useState } from "react";
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Modal from "../../../components/base/Modal";
import { toastSuccess } from "../../../components/base/Toast";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import { ShiftEditableDates, ShiftViewAgentItem, ShiftViewDay, ShiftViewDepartment } from "../models/ShiftViewModels";
import Alert from "../../../components/base/Alert";
import FlexRow from "../../../components/base/FlexRow";
import Avatar from "../../../components/base/Avatar";
import TextBox from "../../../components/form/TextBox";
import TimePicker from "../../../components/form/TimePicker";
import FormTitle from "../../../components/form/FormTitle";
import Tagify from "../../../components/tagify/Tagify";
import DropDown from "../../../components/form/DropDown";
import Icon from "../../../components/base/Icon";
import { UserTypes } from "../../../core/Enums";

interface IProps {
    item?: ShiftViewAgentItem
    day?: ShiftViewDay
    department?: ShiftViewDepartment
    onClose?: () => void
    callback?: () => void
}

export default function EditShiftModal(props: IProps) {

    const hub = useHub();

    const [editShiftLoading, editShiftLoadingSet] = useState<boolean>(false);
    const [editableDates, editableDatesSet] = useState<ShiftEditableDates>();
    const [selectedDates, selectedDatesSet] = useState<ShiftViewDay[]>();

    const [earlierDates, earlierDatesSet] = useState<number>(0);
    const [nextDates, nextDatesSet] = useState<number>(0);

    const [earlierDatesLoading, earlierDatesLoadingSet] = useState<boolean>(false);
    const [nextDatesLoading, nextDatesLoadingSet] = useState<boolean>(false);

    const isDateSelected = (d: ShiftViewDay) => {

        var arr = selectedDates ?? [];
        for (var i = 0; i < arr.length; i++) {
            if(arr[i].dateNumber == d.dateNumber) {
                return true;
            }
        }
        return false;
    }
    
    const fetchEditableDates = () => {
        var q = "?";
        q += "userId=" + props.item?.user?.id;
        q += "&departmentId=" + props.department?.id;
        q += "&shiftTypeId=" + props.item?.shiftType?.id;
        q += "&start=" + props.day?.dateNumber;
        q += "&earlierDates=" + earlierDates;
        q += "&nextDates=" + nextDates;

        hub.Get(API_ROUTES.Shifts + "editabledates" + q, res => {
            if (res.success) {
                editableDatesSet(res.data);
                earlierDatesLoadingSet(false);
            }
        })
    }

    useEffect(() => {
        if (props.item?.user?.id) fetchEditableDates();
    }, [props.item, earlierDates, nextDates])

    useEffect(() => {
        if(props.day) {
            var arr = selectedDates ?? [];
            arr.push(props.day);
            selectedDatesSet(a=> [...arr]);
        }
    }, [props.day])

    

    return (
        <>
        <Modal id="modal-edit-shift"
            title="Edit Shift"
            size="Large"
            secondActionText="Delete Shift"
            secondActionColor="danger"
            visible open
            onSecondAction={() => {
                window.openModal("modal-delete-shift");
            }}
            buttonText="Update Shift"
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                var dts = "";
                selectedDates?.forEach(d=> {
                    if (dts != "") dts += ",";
                    dts += d.dateNumber;
                });

                hub.Put(API_ROUTES.Shifts + props.item?.id, {
                    ...body,
                    dates: dts
                }, res => {
                    if (res.success) {
                        toastSuccess("Shift has been updated successfully!");
                        if (props.callback) props.callback();
                    }
                    if (callback) callback(res);
                })

            }}>

                <div>

                    <Div visible={editShiftLoading} className="py-20">
                        <Indicator />
                    </Div>

                    <Div visible={!editShiftLoading}>

                        {
                            props.item?.hasConflict &&
                            <Alert text={props.item.conflictText} type="warning" />
                        }

                        <FlexRow className="mb-5">
                            <Avatar avatarUrl={props.item?.user?.avatarUrl} width={26} fullName={props.item?.user?.fullName} />
                            <div className="fs-5 ms-2">
                                {props.item?.user?.fullName}
                            </div>
                        </FlexRow>

                        <TextBox label="Department" labelUp defaultValue={props.department?.name} readOnly />

                        {/* <TextBox label="Shift Date" labelUp defaultValue={editItemDay?.dateLabel} readOnly /> */}

                        <div className="my-8">
                            {
                                editableDates?.hasEarlierDates && 
                                <span className={`badge cursor-pointer badge-secondary me-1 mb-1`} title="Load Earlier Dates"
                                    onClick={e=> {
                                        earlierDatesLoadingSet(true);
                                        earlierDatesSet(earlierDates + 1);
                                    }}>
                                        {
                                            earlierDatesLoading ? <Icon icon="spinner" spin size={10} />
                                            : <Icon icon="chevron-left" size={10}/>
                                        }
                                </span>
                            }
                            {
                                editableDates?.dates?.map((d, k) =>
                                    <span key={k} className={`badge cursor-pointer ${isDateSelected(d) ? "badge-primary" : "badge-secondary"} me-1 mb-1`}
                                        onClick={e=> {
                                            var arr = selectedDates ?? [];
                                            if (isDateSelected(d)) {
                                                for (var i = 0; i < arr.length; i++) {
                                                    if(arr[i].dateNumber == d.dateNumber) {
                                                        arr.splice(i, 1);
                                                        break;
                                                    }
                                                }
                                            }
                                            else {
                                                arr.push(d);
                                            }

                                            selectedDatesSet(a=> [...arr]);
                                        }}>
                                        {d.dayName} - {d.dateLabel}
                                    </span>
                                )
                            }
                            {
                                editableDates?.hasNextDates && 
                                <span className={`badge cursor-pointer badge-secondary me-1 mb-1`} title="Load Earlier Dates"
                                    onClick={e=> {
                                        nextDatesLoadingSet(true);
                                        nextDatesSet(nextDates + 1);
                                    }}>
                                        {
                                            nextDatesLoading ? <Icon icon="spinner" spin size={10} />
                                            : <Icon icon="chevron-right" size={10}/>
                                        }
                                </span>
                            }
                        </div>

                        {
                            props.item?.shiftType && 
                            <DropDown label="Shift Type" name="shiftTypeId" labelUp
                                apiSource={API_ROUTES.TagifyShiftTypes + "?depId=" + props.department?.id} 
                                dataText="title" dataValue="value" childrenAtEnd defaultValue={props.item.shiftType.id}>
                            </DropDown>
                        }

                        {/* <div className="row">
                            <div className="col-sm-6">
                                <TimePicker id="time-editshift-startTime" name="startTime" timeInput label="Start Time" labelUp defaultValue={props.item?.startTime}  />
                            </div>
                            <div className="col-sm-6">
                                <TimePicker id="time-editshift-endTime" name="endTime" timeInput label="End Time" labelUp defaultValue={props.item?.endTime} />
                            </div>
                        </div> */}

                        

                        <FormTitle title="Replace User" margin={5} />

                        {
                            props.item?.user?.userType == UserTypes.Agent && 
                            <Tagify id="tagify-editshift-agent" name="userId" apiUrl={API_ROUTES.TagifyAgents}
                                placeholder="Select an Agent" single labelUp />
                        }

                        {
                            props.item?.user?.userType == UserTypes.Technician && 
                            <Tagify id="tagify-editshift-techs" name="userId" apiUrl={API_ROUTES.TagifyTechnicians}
                                placeholder="Select a Technician" single labelUp />
                        }

                        {
                            props.item?.user?.userType == UserTypes.Admin && 
                            <Tagify id="tagify-editshift-techs" name="userId" apiUrl={API_ROUTES.TagifyAdmins}
                                placeholder="Select a Manager" single labelUp />
                        }

                        

                    </Div>

                </div>

        </Modal>

        <Modal id="modal-delete-shift"
            title="Delete Shift"
            buttonText="Confirm & Delete"
            buttonColor="danger" secondModal
            onClose={() => {
                if(props.callback) props.callback();
            }}
            onConfirm={callback => {
                var dts = "";
                selectedDates?.forEach(d=> {
                    if (dts != "") dts += ",";
                    dts += d.dateNumber;
                });

                hub.Post(API_ROUTES.Shifts + "deleteshifts/" + props.item?.id, {
                    dates: dts
                }, res => {
                    if(res.success) {
                        callback();
                        if(props.callback) props.callback();
                        window.closeModal("modal-edit-shift");
                    }
                    else{
                        callback(true);
                    }
                })
            }}
            >
                <p>
                    Are you sure you want to delete this shift?
                </p>
        </Modal>
        </>
    )
}