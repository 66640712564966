import { useEffect, useState } from "react"
import FlexRow from "../../../components/base/FlexRow"
import Icon from "../../../components/base/Icon"
import { toPrettyDate, useJob, useSocket } from "../../../core/Global"
import Job from "../../crm/jobs/models/Job"
import { Link } from "react-router-dom"


interface IProps {
    id?: string
    onEdit?: (job: Job) => void
    onRemove?: (job: Job) => void
}
export default function ChatJobCard(props: IProps) {

    const socket = useSocket();

    const job = useJob(props.id) ?? {}


    return (
        <div className="chat-job-card cursor-pointer"
            onClick={e => {
                if ($(e.target).hasClass("chat-actions")) return;
                if (props.onEdit) props.onEdit(job);
            }}>

            <FlexRow>
                <div className="fs-7 fw-bold">
                    {job.fullName}
                </div>
                <FlexRow className="ms-auto">
                    <a className="badge badge-secondary me-2"
                        style={{
                            backgroundColor: job.jobStatus?.color,
                            color: "#fff",
                            fontSize: 10
                        }}>
                        {job.jobStatus?.name}
                    </a>
                    <a href="#" className="chat-actions text-dark px-2"
                        onClick={e => {
                            e.preventDefault();
                            window.dataTableMenu();
                        }}
                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <Icon icon="ellipsis-vertical" className="chat-actions" />
                    </a>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-2" data-kt-menu="true">
                        <div className="menu-item">
                            <Link to={"/crm/jobs/overview/" + job.id} className="menu-link px-3" target="_blank">
                                <Icon icon="eye" className="me-2" />
                                View Job Details
                            </Link>
                        </div>
                    </div>
                </FlexRow>
            </FlexRow>
            <FlexRow className="mt-2">
                <Icon icon="hashtag" size={13} className="me-1" title="Job ID" />
                <span className="fs-8" title="Job ID">{job.jobId}</span>
            </FlexRow>
            <FlexRow className="mt-2">
                <Icon icon="phone" size={13} className="me-1" title="Client Phone" />
                <span className="fs-8" title="Client Phone">{job.phone?.phoneFriendly}</span>
            </FlexRow>
            <FlexRow className="mt-2">
                <Icon icon="location-dot" size={13} className="me-2" title="Job Address" />
                <span className="fs-8" title="Job Address">{job.address?.title}</span>
            </FlexRow>

            <FlexRow className="mt-5">
                <div style={{
                    backgroundColor: "#2B2B3C0D",
                    padding: 4
                }} title="Appointment Time">
                    <Icon icon="calendar" size={13} className="me-2" />
                    {
                        job.appointmentDate ?
                            <span className="fs-8">
                                {job.appointmentDate} {job.appointmentTimeFrom}-{job.appointmentTimeTo}
                            </span>
                            :
                            <span className="fs-8 text-muted">Assign Appointment</span>
                    }
                </div>

                <div className="ms-auto">
                    <FlexRow title="Created At">
                        <Icon icon="clock" size={12} className="me-1 text-muted" />
                        <span className="fs-9 text-muted" style={{ marginTop: 2 }}>
                            {toPrettyDate(job.createdAt)}
                        </span>
                    </FlexRow>
                </div>
            </FlexRow>

        </div>
    )
}