import { useEffect, useState } from "react";
import "./JobsDashboard.css"
import Section from "../../../../components/base/Section";
import FormTitle from "../../../../components/form/FormTitle";
import Layout from "../../../../components/layouts/Layout";
import { createJob, editJob, openCloseJob, openEditJobAddress, openEditJobAppt, openEditJobTech, openInvalidCloseJob, openJobQuickView, openReopenJob, openSendJob, setPage, setToolbarActions, setToolbarComponent, updateJobBoard, useEnvironment, useGlobal, useHub, useJobBoard, useJobs, useScreen, useSocket, useStatuses } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import Modal from "../../../../components/base/Modal";
import Job from "../models/Job";
import Filtering, { FilterItem } from "../../../../components/data/Filtering";
import filteringProps from "../filtering/joblist.json";
import JobNotesModal from "../components/JobNotesModal";
import JobUpdateStatusModal from "../components/jobmodals/JobUpdateStatusModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import JobTasksModal from "../components/jobmodals/JobTasksModal";
import JobDataRow from "../components/JobDataRow";
import JobUpdateStatusNoteModal from "../components/jobmodals/JobUpdateStatusNoteModal";
import { SortTypes } from "../../../../core/Enums";
import JobStatus from "../../base/models/JobStatus";
import Indicator from "../../../../components/base/Indicator";
import JobSendModal from "../components/JobModalTemp";
import JobSendTechRespondModal from "../components/jobmodals/JobSendTechRespondModal";
import JobAttachmentsModal from "../components/jobmodals/JobAttachmentsModal";
import JobAppointmentModal from "../components/jobmodals/JobAppointmentModal";


interface ISortRows {
    id?: string
    sortKey?: string
    sortType?: SortTypes
}
export default function JobsDashboard() {

    const hub = useHub()
    const env = useEnvironment()
    const statuses = useStatuses()
    const jobs = useJobs()



    const [globalState] = useGlobal()

    const searchParams = useSearchParams();

    const screen = useScreen();
    const [compact, compactSet] = useState<boolean>(false)

    useEffect(() => {
        if (screen.width < 1500) compactSet(true)
        else compactSet(false)
    }, [screen])



    const [sortModel, sortModelSet] = useState<ISortRows[]>([]);
    const [filtering, filteringSet] = useState<boolean>();

    const [job, jobSet] = useState<Job>();

    const [statusAction, statusActionSet] = useState<JobStatus>();
    const [selectedStatus, selectedStatusSet] = useState<JobStatus>()

    const [formReset, formResetSet] = useState<boolean>(false);

    const [filterItems, filterItemsSet] = useState<FilterItem[]>([]);
    const [filterJobTypes, filterJobTypesSet] = useState<string[]>([]);
    const [filterJobSources, filterJobSourcesSet] = useState<string[]>([]);
    const [filterFlags, filterFlagsSet] = useState<string[]>([]);
    const [filterTags, filterTagsSet] = useState<string[]>([]);
    const [filterMetros, filterMetrosSet] = useState<string[]>([]);
    const [filterTechs, filterTechsSet] = useState<string[]>([]);

    const [filterHasCall, filterHasCallSet] = useState<string>();
    const [filterHasNewCall, filterHasNewCallSet] = useState<string>();

    useEffect(() => {
        const jobTypes = filterItems.filter(f => f.name == "jobTypes").map(f => (f.value?.value ?? ""));
        filterJobTypesSet(jobTypes);

        const jobSources = filterItems.filter(f => f.name == "jobSources").map(f => (f.value?.value ?? ""));
        filterJobSourcesSet(jobSources);

        const flags = filterItems.filter(f => f.name == "flags").map(f => (f.value?.value ?? ""));
        filterFlagsSet(flags);

        const tags = filterItems.filter(f => f.name == "tags").map(f => (f.value?.value ?? ""));
        filterTagsSet(tags);

        const metros = filterItems.filter(f => f.name == "metros").map(f => (f.value?.value ?? ""));
        filterMetrosSet(metros);

        const techs = filterItems.filter(f => f.name == "techs").map(f => (f.value?.value ?? ""));
        filterTechsSet(techs);

        const hasCall = filterItems.filter(f => f.name == "hasCall").map(f => (f.value?.value ?? ""));
        if (hasCall.length > 0) filterHasCallSet(hasCall[0])
        else filterHasCallSet(undefined)

        const hasNewCall = filterItems.filter(f => f.name == "hasNewCall").map(f => (f.value?.value ?? ""));
        if (hasNewCall.length > 0) filterHasNewCallSet(hasNewCall[0])
        else filterHasNewCallSet(undefined)

    }, [filterItems])


    const [updateStatus, updateStatusSet] = useState<boolean>(false);
    const [updateStatusNote, updateStatusNoteSet] = useState<boolean>(false);


    const [updateAppointmentStatus, updateAppointmentStatusSet] = useState<boolean>(false);
    const [viewJobNotes, viewJobNotesSet] = useState<boolean>(false);
    const [viewJobTasks, viewJobTasksSet] = useState<boolean>(false);

    const [manageTechResponse, manageTechResponseSet] = useState<boolean>(false);

    const [inProgErrModal, inProgErrModalSet] = useState<boolean>(false);
    const [inProgErrText, inProgErrTextSet] = useState<string>("");



    useEffect(() => {


        if (statuses && statuses.length > 0) {
            var arr = sortModel;
            for (var i = 0; i < statuses.length; i++) {
                var m = statuses[i];
                var exists = false;

                for (var x = 0; x < arr.length; x++) {
                    if (arr[x].id == m.id) {
                        exists = true;
                        break;
                    }
                }

                if (!exists) arr.push({ id: m.id });
            }

            sortModelSet(a => [...arr]);
        }


    }, [statuses])



    const JobTabs = () => {

        const statuses = useStatuses()
        const jobs = useJobs()
        const [selectedStatusTab, selectedStatusTabSet] = useState<JobStatus>()

        useEffect(() => {
            selectedStatusSet(selectedStatusTab)
        }, [selectedStatusTab])


        return (
            <>
                {
                    statuses && statuses.length > 0 &&
                    <FlexRow className="align-self-end">
                        <button className="btn btn-sm btn-white btn-active-white fw-boldest"
                            style={{
                                borderBottom: !selectedStatusTab ? "2px solid #666" : "2px solid transparent",
                                borderRadius: 0,
                                whiteSpace: "nowrap"
                            }}
                            onClick={e => {
                                e.preventDefault()
                                selectedStatusTabSet(undefined)
                            }}
                        >
                            <span className="me-1">
                                All Jobs
                            </span>
                            <span>
                                ({jobs?.length})
                            </span>
                        </button>
                        {
                            statuses
                                .filter(f => jobs?.some(j => j.jobStatusId == f.id))
                                .sort((a, b) => {
                                    if (a.isPendingClosed && b.isClosed) return -1;
                                    if (a.isClosed && b.isPendingClosed) return 1;
                                    return 0;
                                })
                                .map(st =>
                                    <button key={"sttab-" + st.id} className="btn btn-sm btn-white btn-active-white"
                                        style={{
                                            borderBottom: selectedStatusTab?.id == st.id ? "2px solid " + st.color : "2px solid transparent",
                                            borderRadius: 0,
                                            whiteSpace: "nowrap"
                                        }}
                                        onClick={e => {
                                            e.preventDefault()
                                            selectedStatusTabSet(st)
                                        }}>
                                        <Icon icon="circle" type="solid" className="me-1" color={st.color} size={9} />
                                        <span className="me-1">{st.name}</span>
                                        <span>({jobs?.filter(j => j.jobStatusId == st.id).length})</span>
                                    </button>
                                )
                        }
                    </FlexRow>
                }
            </>

        )
    }

    const JobBoardAction = () => (
        <FlexRow>
            <button className="btn btn-success btn-sm"
                onClick={e => {
                    e.preventDefault()
                    createJob()
                }}>
                <Icon icon="plus" />
                <span>Create Job</span>
            </button>
        </FlexRow>
    )

    useEffect(() => {
        setPage("Jobs Dashboard", "CRM", "Jobs");
        setToolbarComponent(JobTabs)
        setToolbarActions(JobBoardAction)


        return () => {
            setToolbarComponent(undefined)
            setToolbarActions(undefined)
        };
    }, [])



    useEffect(() => {

        if (formReset) {
            setTimeout(() => {
                formResetSet(false);
            }, 1000);
        }

    }, [formReset])




    const [openAttachments, openAttachmentsSet] = useState<boolean>(false);
    useEffect(() => {
        if (openAttachments) {
            window.openModal("modal-job-attachments");
        }
    }, [openAttachments])



    const sort = (id?: string, key?: string) => {
        var arr = sortModel ?? [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == id) {
                arr[i].sortKey = key;
                if (arr[i].sortType == SortTypes.Descending) arr[i].sortType = SortTypes.Ascending;
                else arr[i].sortType = SortTypes.Descending;
                break;
            }
        }
        sortModelSet(a => [...arr]);
    }


    const SortIcon = (props: { sortKey: string, id: string }) => {

        const active = () => {
            for (var i = 0; i < sortModel.length; i++) {
                var s = sortModel[i];
                if (s.id == props.id && s.sortKey == props.sortKey) return true;
            }
            return false;
        }

        const acsending = () => {
            for (var i = 0; i < sortModel.length; i++) {
                var s = sortModel[i];
                if (s.id == props.id && s.sortKey == props.sortKey && s.sortType == SortTypes.Ascending) return true;
            }
            return false;
        }
        return (
            <>
                {
                    active() ?
                        <Icon icon={acsending() ? "sort-down" : "sort-up"} type="solid" />
                        : <></>
                }
            </>
        )
    }

    useEffect(() => {
        window.dataTableMenu()
    }, [jobs])

    return (
        <Layout wide>

            {
                globalState.jobsFetched ?
                    <Section fluid>
                        <div>
                            <Filtering id="filtering-status-all"
                                apiRoute={API_ROUTES.Jobs}
                                filteringProps={filteringProps}
                                onRouteParamsBinded={e => { }}
                                onFilter={(p, c) => {

                                }}
                                onItemsChanged={(items, q) => {
                                    filterItemsSet(items);
                                }} />
                        </div>
                    </Section>
                    :
                    <Section fluid>
                        <div className="text-center my-20">
                            <Indicator />
                        </div>
                    </Section>
            }



            {
                globalState.jobsFetched && jobs &&
                <>
                    {
                        jobs.length == 0 ?
                            <Section>
                                <div>
                                    <div>
                                        <div className="card-px text-center pt-15 pb-15">
                                            <h2 className="fs-2x fw-bolder mb-10">There is no active job!</h2>
                                            <div>
                                                <button type="button" className="btn btn-primary" onClick={e => {
                                                    createJob();
                                                }}>
                                                    Create New Job
                                                </button>
                                            </div>
                                        </div>
                                        <div className="text-center pb-15 px-5">
                                            <img src="/assets/media/illustrations/sketchy-1/16.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                        </div>
                                    </div>
                                </div>
                            </Section>
                            :
                            <Section fluid>
                                {
                                    statuses?.filter(st => {
                                        var r = jobs.some(j => j.jobStatusId == st.id);
                                        if (selectedStatus && selectedStatus.id != st.id) r = false;
                                        return r;
                                    }).map(st =>
                                        <div key={"st-" + st.id} >
                                            <FlexRow className="job-dashboard-status-title">
                                                <Icon icon="circle" color={st.color} className="me-2" />
                                                <span >
                                                    {st.name}
                                                </span>
                                            </FlexRow>

                                            <div className="job-dashboard-jobs-div">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-body table-responsive">
                                                                <table className="table table-jobs table-hover table-sm align-start table-row-border">
                                                                    <thead>
                                                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "jobId")}>
                                                                                    <span className="me-2">Job ID</span>
                                                                                    <SortIcon sortKey="jobId" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "name")}>
                                                                                    <span className="me-2">Client</span>
                                                                                    <SortIcon sortKey="name" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "type")}>
                                                                                    <span className="me-2">Type</span>
                                                                                    <SortIcon sortKey="type" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            {
                                                                                !compact &&
                                                                                <th>
                                                                                    <FlexRow onClick={e => sort(st.id, "source")}>
                                                                                        <span className="me-2">Source</span>
                                                                                        <SortIcon sortKey="source" id={st.id ?? ""} />
                                                                                    </FlexRow>
                                                                                </th>
                                                                            }
                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "address")}>
                                                                                    <span className="me-2">Address</span>
                                                                                    <SortIcon sortKey="address" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            {
                                                                                !compact &&
                                                                                <th>
                                                                                    <FlexRow onClick={e => sort(st.id, "metro")}>
                                                                                        <span className="me-2">Metro</span>
                                                                                        <SortIcon sortKey="metro" id={st.id ?? ""} />
                                                                                    </FlexRow>
                                                                                </th>
                                                                            }

                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "tech")}>
                                                                                    <span className="me-2">Tech</span>
                                                                                    <SortIcon sortKey="tech" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "appointment")}>
                                                                                    <span className="me-2">Appointment</span>
                                                                                    <SortIcon sortKey="appointment" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            {
                                                                                !compact &&
                                                                                <th>
                                                                                    <FlexRow onClick={e => sort(st.id, "localTime")}>
                                                                                        <span className="me-2">Local</span>
                                                                                        <SortIcon sortKey="localTime" id={st.id ?? ""} />
                                                                                    </FlexRow>
                                                                                </th>
                                                                            }

                                                                            <th>
                                                                                <FlexRow onClick={e => sort(st.id, "lastUpdate")}>
                                                                                    <span className="me-2">Last Update</span>
                                                                                    <SortIcon sortKey="lastUpdate" id={st.id ?? ""} />
                                                                                </FlexRow>
                                                                            </th>
                                                                            {/* <th>
                                                                        Created At
                                                                    </th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            jobs
                                                                                .filter(job => {
                                                                                    var res: Array<boolean> = [];
                                                                                    res.push(job.jobStatusId == st.id)
                                                                                    if (filterJobTypes.length > 0) res.push(job.jobTypeId != null && filterJobTypes.includes(job.jobTypeId));
                                                                                    if (filterJobSources.length > 0) res.push(job.jobSourceId != null && filterJobSources.includes(job.jobSourceId));
                                                                                    if (filterFlags.length > 0) res.push(job.flagIds != null && job.flagIds.length > 0 && job.flagIds.some(fl => filterFlags.includes(fl)));
                                                                                    if (filterTags.length > 0) res.push(job.tagIds != null && job.tagIds.length > 0 && job.tagIds.some(tg => filterTags.includes(tg)));
                                                                                    if (filterMetros.length > 0) res.push(job.metroId != null && filterMetros.includes(job.metroId));
                                                                                    if (filterTechs.length > 0) res.push(job.techId != null && filterTechs.includes(job.techId));

                                                                                    if (filterHasCall && filterHasCall == "True") res.push(job.jobCalls && job.jobCalls.length > 0 ? true : false);
                                                                                    if (filterHasCall && filterHasCall == "False") res.push(job.jobCalls && job.jobCalls.length > 0 ? false : true);

                                                                                    if (filterHasNewCall && filterHasNewCall == "True") res.push(job.jobCalls && job.jobCalls.length > 0 && job.jobCalls.filter(f => f.isCompleted && (f.listeners?.length ?? 0) < 1).length > 0 ? true : false);
                                                                                    if (filterHasNewCall && filterHasNewCall == "False") res.push(job.jobCalls && job.jobCalls.length > 0 && job.jobCalls.filter(f => f.isCompleted && (f.listeners?.length ?? 0) < 1).length > 0 ? false : true);

                                                                                    return res.every(f => f);
                                                                                })
                                                                                .sort((a, b) => {
                                                                                    var srow = sortModel.filter(v => v.id == st.id)[0];
                                                                                    if (srow?.sortKey == "jobId") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return (new Date(a.lastStatusUpdatedAt!).getTime()) - (new Date(b.lastStatusUpdatedAt!).getTime());
                                                                                        else return (new Date(b.lastStatusUpdatedAt!).getTime()) - (new Date(a.lastStatusUpdatedAt!).getTime());
                                                                                    }
                                                                                    if (srow?.sortKey == "type") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.jobType ?? "") > (b.jobType ?? "")) ? 1 : ((b.jobType ?? "") > (a.jobType ?? "")) ? -1 : 0;
                                                                                        else return ((a.jobType ?? "") > (b.jobType ?? "")) ? -1 : ((b.jobType ?? "") > (a.jobType ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "source") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.jobSource ?? "") > (b.jobSource ?? "")) ? 1 : ((b.jobSource ?? "") > (a.jobSource ?? "")) ? -1 : 0;
                                                                                        else return ((a.jobSource ?? "") > (b.jobSource ?? "")) ? -1 : ((b.jobSource ?? "") > (a.jobSource ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "name") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.fullName ?? "") > (b.fullName ?? "")) ? 1 : ((b.fullName ?? "") > (a.fullName ?? "")) ? -1 : 0;
                                                                                        else return ((a.fullName ?? "") > (b.fullName ?? "")) ? -1 : ((b.fullName ?? "") > (a.fullName ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "phone") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.phone?.phone ?? "") > (b.phone?.phone ?? "")) ? 1 : ((b.phone?.phone ?? "") > (a.phone?.phone ?? "")) ? -1 : 0;
                                                                                        else return ((a.phone?.phone ?? "") > (b.phone?.phone ?? "")) ? -1 : ((b.phone?.phone ?? "") > (a.phone?.phone ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "address") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.address?.title ?? "") > (b.address?.title ?? "")) ? 1 : ((b.address?.title ?? "") > (a.address?.title ?? "")) ? -1 : 0;
                                                                                        else return ((a.address?.title ?? "") > (b.address?.title ?? "")) ? -1 : ((b.address?.title ?? "") > (a.address?.title ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "metro") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.metroName ?? "") > (b.metroName ?? "")) ? 1 : ((b.metroName ?? "") > (a.metroName ?? "")) ? -1 : 0;
                                                                                        else return ((a.metroName ?? "") > (b.metroName ?? "")) ? -1 : ((b.metroName ?? "") > (a.metroName ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "tech") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.tech?.fullName ?? "") > (b.tech?.fullName ?? "")) ? 1 : ((b.tech?.fullName ?? "") > (a.tech?.fullName ?? "")) ? -1 : 0;
                                                                                        else return ((a.tech?.fullName ?? "") > (b.tech?.fullName ?? "")) ? -1 : ((b.tech?.fullName ?? "") > (a.tech?.fullName ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "appointment") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.appointmentDate ?? "") > (b.appointmentDate ?? "")) ? 1 : ((b.appointmentDate ?? "") > (a.appointmentDate ?? "")) ? -1 : 0;
                                                                                        else return ((a.appointmentDate ?? "") > (b.appointmentDate ?? "")) ? -1 : ((b.appointmentDate ?? "") > (a.appointmentDate ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    if (srow?.sortKey == "localTime") {
                                                                                        if (srow?.sortType == SortTypes.Ascending) return ((a.localTime ?? "") > (b.localTime ?? "")) ? 1 : ((b.localTime ?? "") > (a.localTime ?? "")) ? -1 : 0;
                                                                                        else return ((a.localTime ?? "") > (b.localTime ?? "")) ? -1 : ((b.localTime ?? "") > (a.localTime ?? "")) ? 1 : 0;
                                                                                    }
                                                                                    return (new Date(b.lastStatusUpdatedAt!).getTime()) - (new Date(a.lastStatusUpdatedAt!).getTime())
                                                                                })
                                                                                .map(job =>
                                                                                    <JobDataRow key={"job-data-row-" + job.id} job={job}
                                                                                        onQuickView={job => openJobQuickView(job.id)}
                                                                                        onUpdateAddress={job => openEditJobAddress(job)}
                                                                                        onUpdateTech={job => openEditJobTech(job)}
                                                                                        onUpdateAppointment={job => openEditJobAppt(job)}
                                                                                        onUpdateStatusAppointment={job => {
                                                                                            jobSet(job);
                                                                                            updateAppointmentStatusSet(true);
                                                                                        }}
                                                                                        onUpdateStatus={job => {
                                                                                            jobSet(job);
                                                                                            updateStatusSet(true);
                                                                                        }}
                                                                                        onUpdateStatusNote={(job, st) => {
                                                                                            jobSet(job)
                                                                                            statusActionSet(st)
                                                                                            updateStatusNoteSet(true)
                                                                                        }}
                                                                                        onSendJob={job => {
                                                                                            jobSet(job);
                                                                                            openSendJob(job);
                                                                                        }}
                                                                                        onManageTechResponse={(job, s) => {
                                                                                            jobSet(job);
                                                                                            manageTechResponseSet(true);
                                                                                        }}
                                                                                        onViewNotes={job => openJobQuickView(job.id, "2")}
                                                                                        onOpenAttachments={job => {
                                                                                            jobSet(job);
                                                                                            openAttachmentsSet(true);
                                                                                        }}
                                                                                        onViewTasks={job => {
                                                                                            jobSet(job);
                                                                                            viewJobTasksSet(true);
                                                                                        }}
                                                                                        onEdit={job => {
                                                                                            editJob(job);
                                                                                        }}
                                                                                        onInProgressError={err => {
                                                                                            jobSet(job);
                                                                                            inProgErrModalSet(true);
                                                                                            inProgErrTextSet(err);
                                                                                        }}
                                                                                        onCloseJob={(job, callback) => {
                                                                                            hub.Get(API_ROUTES.Jobs + "validateclosing/" + job.id, res => {
                                                                                                if (res.data) {
                                                                                                    openCloseJob(job)
                                                                                                    callback();
                                                                                                }
                                                                                                else {
                                                                                                    openInvalidCloseJob(job, res.message)
                                                                                                    callback()
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                        onReopenFromClosed={(job, st) => {
                                                                                            openReopenJob(job, st)
                                                                                        }} />
                                                                                )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }
                            </Section>
                    }
                </>
            }






            {
                updateAppointmentStatus && job &&
                <JobAppointmentModal job={job} forceUpdateStatus
                    onClose={() => updateAppointmentStatusSet(false)} />
            }

            {
                updateStatus &&
                <JobUpdateStatusModal job={job}
                    onClose={() => updateStatusSet(false)} />
            }

            {
                updateStatusNote &&
                <JobUpdateStatusNoteModal job={job} status={statusAction}
                    onClose={() => updateStatusNoteSet(false)} />
            }


            {
                viewJobNotes &&
                <JobNotesModal job={job}
                    onClose={() => viewJobNotesSet(false)} />
            }

            {
                viewJobTasks &&
                <JobTasksModal job={job}
                    onClose={() => viewJobTasksSet(false)} />
            }


            {
                manageTechResponse &&
                <JobSendTechRespondModal job={job}
                    onClose={() => manageTechResponseSet(false)} />
            }

            {
                openAttachments &&
                <JobAttachmentsModal job={job}
                    onClose={() => openAttachmentsSet(false)} />
            }


            {
                inProgErrModal &&
                <Modal id="modal-closejob-inprognotvalid"
                    title="Warning"
                    subtitle={"Job ID: " + job?.jobId + " | " + job?.fullName}
                    open visible
                    onClose={() => inProgErrModalSet(false)}>
                    <div>
                        <div className="mb-2 fw-bolder">Before update the job status to In Progress you should: </div>
                        <div className="bg-light-warning p-5"
                            style={{
                                whiteSpace: "pre"
                            }}>
                            {inProgErrText}
                        </div>
                    </div>
                </Modal>
            }

        </Layout>
    )
}