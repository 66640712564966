import { useEffect, useState } from "react";
import { useHub } from "../../../../core/Global"
import Job from "../models/Job";
import Indicator from "../../../../components/base/Indicator";
import Icon from "../../../../components/base/Icon";
import Button from "../../../../components/base/Button";
import Modal from "../../../../components/base/Modal";
import DropDown from "../../../../components/form/DropDown";
import { API_ROUTES } from "../../../../core/Constants";
import { DataTable, DataTableField } from "../../../../components/data/DataTable";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import JobInvoice from "../models/JobInvoice";
import FlexRow from "../../../../components/base/FlexRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextBox from "../../../../components/form/TextBox";
import PhoneInput from "../../../../components/form/PhoneInput";


interface IProps {
    job: Job
}

export default function JobInvoices(props: IProps) {


    const nav = useNavigate();

    const hub = useHub();

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [loading, loadingSet] = useState<boolean>(false);

    const [createInvoice, createInvoiceSet] = useState<boolean>(false);

    const [refresh, refreshSet] = useState<boolean>();
    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                refreshSet(false);
            }, 1000);
        }
    }, [refresh])


    const NoRowComp = () => (
        <div>
            <div className="mb-5 text-center">
                <img src="/assets/media/illustrations/sketchy-1/4.png" alt="" className="mw-100 h-200px h-sm-325px" />
            </div>
            <div className="text-center fs-5 fw-bold">
                There is no invoice for this job!
            </div>
        </div>
    )

    const InvNumberItem = (data: JobInvoice) => (
        <div>
            <Link to={`/crm/jobs/invoices/${props.job.id}/view/${data.id}`}>
                {data.number}
            </Link>
        </div>
    )

    const InvTotal = (data: JobInvoice) => (
        <div>
            {moneyFormatter.format(data.total ?? 0)}
        </div>
    )

    const TechItem = (data: JobInvoice) => (
        <div>
            <div>
                {data.tech?.fullName}
            </div>
            <FlexRow className='fs-8 mt-1'>
                <div>
                    <Icon icon="phone" className="me-2" size={10} type="solid" />
                </div>
                <div className="fs-8">
                    {data.tech?.phoneNumber}
                </div>
            </FlexRow>
        </div>
    )


    const CreatedItem = (data: JobInvoice) => (
        <div>
            <div className='d-flex align-items-center'>
                {data.createDate}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    const StatusItem = (data: JobInvoice) => (
        <div>
            {
                data.lastSend &&
                <span className="badge badge-light-primary fw-normal">
                    <Icon icon="share" className="me-2" />
                    Sent: {data.lastSend.sendDate}
                </span>
            }
        </div>
    )



    const [selectedInvForSend, selectedInvForSendSet] = useState<JobInvoice>();
    const [selectedIdsForSend, selectedIdsForSendSet] = useState<string[]>();
    const [sendModal, sendModalSet] = useState<boolean>(false);

    const [sendTo, sendToSet] = useState<"email" | "phone">("email")

    return (
        <div>

            {
                false ?
                    <div className="card">
                        <div className="card-body my-10">
                            <div className="mb-5 text-center">
                                <Icon icon="file-invoice-dollar" size={34} />
                            </div>
                            <div className="text-center">
                                You can't access the invoices while the job is canceled!
                            </div>
                        </div>
                    </div>
                    :
                    <DataTable apiRoute={API_ROUTES.JobInvoices}
                        objectName="Invoice" actionsOnCard
                        customNoRowComp={NoRowComp} refresh={refresh}
                        extraParams={[
                            {
                                name: "jobId",
                                value: props.job.id
                            }
                        ]}
                        addCustom={() => createInvoiceSet(true)}
                        delete edit noFilter
                        editCustom={data => {
                            nav(`/crm/jobs/invoices/${props.job.id}/view/${data.id}`);
                        }}
                        onSend={(inv: JobInvoice) => {
                            selectedIdsForSendSet(undefined);
                            selectedInvForSendSet(inv);
                            sendModalSet(true);
                        }}
                        onGroupSend={ids => {
                            selectedIdsForSendSet(ids);
                            selectedInvForSendSet(undefined);
                            sendModalSet(true);
                        }}
                    >

                        <DataTableField title="INV Num">
                            <InvNumberItem />
                        </DataTableField>
                        <DataTableField title="Technician">
                            <TechItem />
                        </DataTableField>
                        <DataTableField title="Total">
                            <InvTotal />
                        </DataTableField>
                        <DataTableField title="Created">
                            <CreatedItem />
                        </DataTableField>
                        <DataTableField title="">
                            <StatusItem />
                        </DataTableField>
                    </DataTable>
            }




            {
                createInvoice &&
                <Modal id="modal-create-invoice"
                    title="Create Invoice"
                    open visible staticBackdrop noHeader noFooter
                    onOpen={() => {
                        hub.Post(API_ROUTES.JobInvoices + "invoice", {
                            jobId: props.job.id
                        }, res => {
                            if (res.success) {
                                window.closeModal("modal-create-invoice");
                                nav(`/crm/jobs/invoices/${props.job.id}/view/${res.data.id}`)
                                return;
                            }
                            else toastError(res.message);
                            window.closeModal("modal-create-invoice");
                        });
                    }}
                    onClose={() => {

                    }}>
                    <div className="my-20">
                        <Indicator text="Creating Invoice..." />
                    </div>
                </Modal>
            }

            {
                sendModal &&
                <Modal id="modal-send-invoice"
                    title={selectedIdsForSend ? "Send Selected Invoices" : selectedInvForSend?.issued ? ("Send Invoice " + selectedInvForSend.number) : ("Send Estimate " + selectedInvForSend?.number)}
                    buttonText="Send"
                    buttonColor="success"
                    open visible staticBackdrop
                    onClose={() => {
                        sendModalSet(false);
                    }}
                    onSubmit={(body, callback) => {
                        var ids = "";
                        if (selectedInvForSend) ids = selectedInvForSend.id ?? "";
                        selectedIdsForSend?.forEach(id => {
                            if (ids != "") ids += ",";
                            ids += id;
                        });

                        hub.Post(API_ROUTES.JobInvoices + "sendinvoice", {
                            ...body,
                            jobId: props.job.id,
                            sendTo: sendTo,
                            ids
                        }, res => {
                            callback!(res);
                            refreshSet(true);
                        })
                    }}>

                    <div>
                        <FlexRow className="mb-5">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start me-5 px-6 py-4 ${(sendTo == "email" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "email"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("email")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Email
                                    </span>
                                </span>
                            </label>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-6 py-4 ${(sendTo == "phone" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "phone"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("phone")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Phone
                                    </span>
                                </span>
                            </label>
                        </FlexRow>

                        {
                            sendTo == "email" &&
                            <TextBox id="txt-invoice-send-email" key="txt-invoice-send-email" name="emails" email label="Email Address"
                                labelUp block autoFocus value={props.job.emailAddress} />
                        }

                        {
                            sendTo == "phone" &&
                            <PhoneInput id="txt-invoice-send-phone" key="txt-invoice-send-phone" name="phone" label="Phone Number"
                                labelUp block autoFocus defaultPhone={props.job.phone} />
                        }
                    </div>

                </Modal>
            }



        </div>
    )
}