import { useState, useEffect } from 'react';
import crypto from "crypto-js";
import { Page, CreateButton } from '../models/Page';
import { BreadCrumb } from '../models/Page';
import User from '../modules/accounts/models/User';
import RolesModel from '../modules/accounts/models/RolesModel';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import Environment from '../env/env';
import Call from '../modules/voice/calls/models/Call';
import LiveCall from '../modules/voice/calls/models/LiveCall';
import SystemExtension from '../models/SystemExtension';
import SocketContext from './SocketContext';
import Hub from '../api/Hub';
import Wallet from '../models/Wallet';
import Job from '../modules/crm/jobs/models/Job';
import { JobsChartSegments, TimeFormats, UserTypes } from './Enums';
import Country from '../modules/base/models/Country';
import ChatContactItem from '../modules/chat/models/ChatContactItem';
import { BootstrapColors, FontAwesomeIcons } from './Types';
import DataItem from '../models/DataItem';
import AccountPreferences from '../models/AccountPreferences';
import PriceListModel from '../models/PriceListModel';
import TwilioLine from '../modules/voice/lines/models/TwilioLine';
import moment from 'moment';
import JobStatus from '../modules/crm/base/models/JobStatus';
import JobDashboard from '../modules/crm/jobs/models/JobDashboard';
import JobReportListModel from '../modules/crm/jobs/models/JobReportListModel';
import { getRandomNumber } from './Helper';
import { API_ROUTES } from './Constants';
import CashedData from '../models/CashedData';
import Announcement from '../modules/system/announcements/models/Announcement';
import { toastNotification } from '../components/base/Toast';
import { GetThemeMode } from './Metronic';


const env = new Environment();
let reconnectSocketRun = true;
let authUser: User;

type FloatAudioStatus = {
    loading?: boolean,
    playing?: boolean
}

type toolbarActionButton = {
    title?: string
    icon?: FontAwesomeIcons
    color?: BootstrapColors
    working?: boolean
    onClick?: () => void
}

export interface IState {
    darkMode?: boolean
    countries?: Country[]
    countriesAll?: Country[]
    prices?: PriceListModel
    device?: any
    page?: Page
    toolbarComponent?: any
    toolbarActions?: any
    bottomComponents?: any
    toolbarButton?: toolbarActionButton
    createButton?: CreateButton
    user?: User
    ownerUser?: User
    wallet?: Wallet
    roles?: Array<string>
    token?: string
    socket?: HubConnection
    socketState?: HubConnectionState
    selectedCallToPlay?: Call
    selectedCallToListen?: Call | LiveCall
    selectedCallsToShare?: string[]
    hangupChannel?: string
    ChanSpyLine?: any
    floatAudioStatus?: FloatAudioStatus
    deviceId?: string
    systemExt?: SystemExtension
    twilioBuyLine?: TwilioLine
    unreadNotificationsCount?: number
    unseenNotificationsCount?: number
    hub?: Hub<any>
    signupStep?: number
    windowsWidth?: number
    windowsHeight?: number
    createUser?: boolean
    createUserType?: UserTypes
    createJob?: boolean
    duplicateJobId?: string
    messageToJobId?: string
    editJob?: boolean
    editJobId?: string
    selectedJob?: Job
    showTimezoneModal?: boolean
    showLineWizard?: boolean
    chatJob?: Job
    callsDrawerJob?: Job
    archiveJob?: Job
    updateUsers?: boolean
    loginModal?: boolean
    showDefaultTechRateModal?: boolean
    showDefaultSourceRateModal?: boolean

    chatlist?: ChatContactItem[]
    selectedChat?: ChatContactItem
    gotoMessageId?: string
    chatUnread?: number

    announcements?: Announcement[]
    openAnnouncementsDrawer?: boolean

    cashedData?: CashedData
    cashedDataFetched?: boolean

    accountPreferences?: AccountPreferences
    refreshLinesList?: boolean

    jobBoard?: JobDashboard[]
    statuses?: JobStatus[]
    jobsFetched?: boolean
    jobs?: Job[]
    updateJobList?: (j: Job) => void

    reportListModal?: JobReportListModel
    quickJobView?: string
    quickJobTab?: string
    editJobClient?: Job
    editJobAppt?: Job
    editJobAddress?: Job
    editJobTech?: Job
    editJobDetails?: Job
    sendJob?: Job
    copyJob?: Job
    invalidCloseJob?: Job
    invalidCloseErrs?: string
    closeJob?: Job
    closeJobOnClose?: any
    reopenJob?: Job
    reopenToStatus?: JobStatus
    notCalledActions?: Job

    messageToJob?: string
    deleteChat?: ChatContactItem
    chatSettingsModal?: ChatContactItem

    updateEnvironemnt?: boolean
    mobileMenuDrawer?: boolean

}


export const loadCashedData = () => {
    const cpk = "IKfytN5XD65kU5qbRHoRarWNWDyaw1lm";
    var c = localStorage.getItem("rinvox-cashed-data");
    if (c) {
        var bytes = crypto.AES.decrypt(c, cpk);
        var data = JSON.parse(bytes.toString(crypto.enc.Utf8));
        return data;
    }
    return undefined;
}

export const setDarkMode = (e: boolean) => setState({
    darkMode: e
})
export const isDarkMode = () => state.darkMode

export const saveCashedData = (c: CashedData) => {
    const cpk = "IKfytN5XD65kU5qbRHoRarWNWDyaw1lm";
    var data = crypto.AES.encrypt(JSON.stringify(c), cpk).toString();
    localStorage.setItem("rinvox-cashed-data", data);
}

export const useCashedData = () => state.cashedData

export const openJobQuickView = (id?: string, tab?: string, overModal?: boolean) => setState({
    quickJobView: id,
    quickJobTab: tab
})
export const closeJobQuickView = () => setState({ quickJobView: undefined })

export const openSendJob = (job: Job) => setState({ sendJob: job })
export const closeSendJob = () => setState({ sendJob: undefined })

export const openCopyJob = (job: Job) => setState({ copyJob: job })
export const closeCopyJob = () => setState({ copyJob: undefined })

export const openEditJobClient = (job: Job) => setState({ editJobClient: job })
export const closeEditJobClient = () => setState({ editJobClient: undefined })

export const openEditJobAppt = (job: Job) => setState({ editJobAppt: job })
export const closeEditJobAppt = () => setState({ editJobAppt: undefined })

export const openEditJobAddress = (job: Job) => setState({ editJobAddress: job })
export const closeEditJobAddress = () => setState({ editJobAddress: undefined })

export const openEditJobTech = (job: Job) => setState({ editJobTech: job })
export const closeEditJobTech = () => setState({ editJobTech: undefined })

export const openEditJobDetails = (job: Job) => setState({ editJobDetails: job })
export const closeEditJobDetails = () => setState({ editJobDetails: undefined })

export const openArchiveJob = (job: Job) => setState({ archiveJob: job })
export const closeArchiveJob = () => setState({ archiveJob: undefined })

export const opennotCalledActions = (job: Job) => setState({ notCalledActions: job })
export const closenotCalledActions = () => setState({ notCalledActions: undefined })

export const openInvalidCloseJob = (job: Job, err?: string) => setState({
    invalidCloseJob: job,
    invalidCloseErrs: err
})
export const closeInvalidCloseJob = () => setState({
    invalidCloseJob: undefined,
    invalidCloseErrs: undefined
})

export const openCloseJob = (job: Job, onClosed?: () => void) => setState({
    closeJob: job,
    closeJobOnClose: onClosed
})
export const closeCloseJob = () => setState({
    closeJob: undefined,
    closeJobOnClose: undefined
})

export const openReopenJob = (job: Job, st: JobStatus) => setState({
    reopenJob: job,
    reopenToStatus: st
})
export const closeReopenJob = () => setState({
    reopenJob: undefined,
    reopenToStatus: undefined
})


export const openMessageToJob = (msg: string, msgId: string) => setState({
    messageToJob: msg,
    createJob: true,
    messageToJobId: msgId
})
export const closeMessageToJob = () => setState({
    messageToJob: undefined
})



export const openReportJobsList = (list: JobReportListModel) => setState({
    reportListModal: list
})
export const closeReportJobsList = () => setState({
    reportListModal: undefined
})



export const openDeleteChat = (chat: ChatContactItem) => setState({ deleteChat: chat })
export const closeDeleteChat = () => setState({ deleteChat: undefined })

export const openSettingsChat = (chat: ChatContactItem) => setState({ chatSettingsModal: chat })
export const closeSettingsChat = () => setState({ chatSettingsModal: undefined })


export const useJobs = () => state.jobs
export const useJob = (id?: string) => {
    if (!id) return undefined;
    if (id.length == 7) return state.jobs?.find(f => f.jobId?.toLowerCase() == id.toLowerCase());
    return state.jobs?.find(f => f.id == id);
}
export const setJobs = (data: Job[]) => setState({ jobs: data })

export const addOrUpdateJob = (newJob: Job) => {
    setState({
        jobs: state.jobs?.some(job => job.id === newJob.id)
            ? state.jobs?.map(job =>
                job.id === newJob.id ? newJob : job
            )
            : [...(state.jobs ?? []), newJob]
    });

    if (state.updateJobList) state.updateJobList(newJob);

}

export const archiveJobs = (ids: string[]) => {
    setState({
        jobs: state.jobs?.filter(job => job.id && !ids.includes(job.id))
    });
}




export const setPage = async (title: string, ...breadcrumbs: string[]) => {

    var breadCrumbs = new Array<BreadCrumb>();
    breadcrumbs.forEach(item => breadCrumbs.push({
        title: item[0].toUpperCase() + item.substring(1),
        link: "#"
    }));
    setState({
        page: {
            title: title[0].toUpperCase() + title.substring(1),
            breadCrumb: breadCrumbs
        }
    })

    document.title = title + " - " + env.siteTitle;
}

export const setPageWithToolbarComponent = async (title: string, comp: any) => {
    setState({
        page: {
            title: title[0].toUpperCase() + title.substring(1),
            breadCrumb: undefined
        },
        toolbarComponent: comp
    })

    document.title = title + " - DSPVoice";
}

export const setPageCount = async (count?: number) => {
    setState({
        page: {
            ...state.page,
            count: count
        }
    });
}

export const setBottomComponents = (components?: any) => setState({ bottomComponents: components })

export const setSignupStep = async (step: number) => setState({ signupStep: step })

export const useCountries = () => state.countries

export const useAllCountries = () => state.countriesAll?.sort((a, b) => {
    if (a.alpha3Code?.toLowerCase() === "usa") return -1;
    if (b.alpha3Code?.toLowerCase() === "usa") return 1;
    return a.name!.localeCompare(b.name!);
});

export const useScreen = () => {
    return {
        width: state.windowsWidth ?? 0,
        height: state.windowsHeight ?? 0
    }
}

export const useJobBoard = () => state.jobBoard
export const useStatuses = () => state.statuses ?? []
export const useStatusesForJob = () => state.statuses?.filter(f => !f.isPendingClosed) ?? []
export const useStatusesForCreateJob = () => state.statuses?.filter(f => !f.isPendingClosed && !f.isClosed && !f.isCanceled) ?? []
export const useStatusesForChatTechJobs = () => state.statuses?.filter(f => f.isInProgress || f.isAppointment) ?? []

export const updateStatuses = (data: any) => setState({ statuses: data })

export const updateJobBoard = (data: any) => setState({ jobBoard: data })


export const setChatList = (data: any) => setState({ chatlist: data })

export const goToMessage = (id: string) => {
    setState({ gotoMessageId: id })
    setTimeout(() => {
        setState({ gotoMessageId: undefined })
    }, 1000);
}

export const useChatList = () => state.chatlist
export const useUnreadChatsCount = () => {
    var res = 0;
    state.chatlist?.forEach(c => {
        res += (c.unreadCount ?? 0)
    });
    return res;
}

export const selectChat = (ch: ChatContactItem) => setState({ selectedChat: ch })
export const selectChatById = (id: string) => {
    var ch = state.chatlist?.find(f => f.chatId == id);
    if (ch) setState({ selectedChat: ch })
}
export const useSelectedChat = () => state.selectedChat

export const addOrUpdateChat = (ch: ChatContactItem) => {

    setState({
        chatlist: state.chatlist?.some(c => c.chatId === ch.chatId)
            ? state.chatlist?.map(c =>
                c.id === ch.id ? ch : c
            )
            : [...(state.chatlist ?? []), ch]
    });

    if (state.selectedChat?.chatId == ch.chatId) {
        setState({ selectedChat: ch });
    }
}


export const useAnnouncements = () => state.announcements
export const openAnnouncements = () => setState({ openAnnouncementsDrawer: true })
export const closeAnnouncements = () => setState({ openAnnouncementsDrawer: false })

export const addOrUpdateAnnouncement = (an: Announcement) => {
    setState({
        announcements: state.announcements?.some(c => c.id === an.id)
            ? state.announcements?.map(c =>
                c.id === an.id ? an : c
            )
            : [...(state.announcements ?? []), an]
    });

}

export const dismissAnnouncement = (an: Announcement) => {
    setState({
        announcements: state.announcements?.filter(a => a.id != an.id)
    })
}


export const getCountry = (id?: string) => {
    var res = state.countriesAll?.filter(f => f.id == id);
    if (res && res.length > 0) return res[0];
    return undefined;
}

export const getCountryByCode = (code?: string) => {
    if (code?.length == 2) {
        return state.countriesAll?.filter(f => f.alpha2Code?.toLowerCase() == code.toLowerCase())[0]
    }
    if (code?.length == 3) {
        return state.countriesAll?.filter(f => f.alpha3Code?.toLowerCase() == code.toLowerCase())[0]
    }

    return undefined;
}

export const getUSA = () => {
    var us: Country = {};
    state.countries?.forEach(c => {
        if (c.alpha2Code?.toLowerCase() == "us") us = c;
    });
    return us;
}

export const usePrices = () => state.prices

export const setToolbarComponent = async (comp: any) => setState({ toolbarComponent: comp })
export const setToolbarActions = async (comp: any) => setState({ toolbarActions: comp })

export const setToolbarButton = async (comp: toolbarActionButton) => setState({ toolbarButton: comp })
export const updateToolbarButtonWorking = async (w: boolean) => setState({
    toolbarButton: {
        ...state.toolbarButton,
        working: w
    }
})
export const updateToolbarButtonAction = async (a: () => void) => setState({
    toolbarButton: {
        ...state.toolbarButton,
        onClick: a
    }
})
export const clearToolbarButton = async () => setState({ toolbarButton: undefined })

export const clearToolbar = async () => setState({ toolbarActions: undefined, toolbarComponent: undefined })

export const openLoginModal = () => setState({ loginModal: true })
export const closeLoginModal = () => setState({ loginModal: false })

export const openUpdateEnvironment = () => setState({ updateEnvironemnt: true })
export const closeUpdateEnvironment = () => setState({ updateEnvironemnt: false })

export const openMobileMenu = () => setState({ mobileMenuDrawer: true })
export const closeMobileMenu = () => setState({ mobileMenuDrawer: false })

export const setChatJob = (job: Job) => setState({ chatJob: job })
export const clearChatJob = () => setState({ chatJob: undefined })
export const getChatJob = () => state.chatJob

export const setCallsJob = (job: Job) => setState({ callsDrawerJob: job })
export const clearCallsJob = () => setState({ callsDrawerJob: undefined })
export const getCallsJob = () => state.callsDrawerJob

export const refreshLinesList = () => {
    setState({ refreshLinesList: true });
    setTimeout(() => {
        setState({ refreshLinesList: false });
    }, 1000);
}

export const setUser = async (user: User) => {
    // if(!user.avatarUrl || user.avatarUrl == "") {
    //     user.avatarUrl = "/assets/media/avatars/blank.png";
    // }
    // user.fullName = user.firstName + " " + user.lastName;
    // authUser = user;
    await setState({
        user: user,
        showTimezoneModal: (user.timeZoneId == null)
    });
}

export const setRoles = async (roles: string[]) => {
    await setState({ roles: roles });
}

export const getUser = () => state.user
export const getOwner = () => state.ownerUser

export const isDemo = () => {
    return state.user?.username == "demo";
}
export const demoText = (str?: string) => str?.substring(0, 1).toUpperCase()
export const demoPhone = () => {
    return "+1" + getRandomNumber(111, 999) + "XXXXX" + getRandomNumber(11, 99)
}

export const usePreferences = () => state.accountPreferences
export const updatePreferences = (data: AccountPreferences) => setState({ accountPreferences: data })

export const createUser = () => setState({ createUser: true })
export const createUserDismiss = () => setState({ createUser: false })

export const createUserWithType = (type: UserTypes) => setState({ createUserType: type })
export const createUserWithTypeDismiss = () => setState({ createUserType: undefined })

export const createJob = (duplicateId?: string) => {
    setState({
        createJob: true,
        duplicateJobId: duplicateId
    })
}
export const closeJob = () => {
    setState({
        createJob: false,
        editJob: false,
        editJobId: undefined,
        selectedJob: undefined,
        messageToJob: undefined
    })
}
export const editJob = (job: Job) => {
    setState({
        editJob: true,
        editJobId: job.id,
        selectedJob: job
    })
}

export const showLineWizard = () => setState({ showLineWizard: true })
export const hideLineWizard = () => setState({ showLineWizard: false })

export const showDefaultTechRateModal = () => setState({ showDefaultTechRateModal: true })
export const hideDefaultTechRateModal = () => setState({ showDefaultTechRateModal: false })

export const updateUsersOn = () => {
    setState({ updateUsers: true });
    setTimeout(() => {
        setState({ updateUsers: false });
    }, 500);
}


export const setWallet = async (wallet: Wallet) => {
    await setState({ wallet: wallet })
}

export const useWallet = () => state.wallet

let socketInterval: NodeJS.Timeout;
export const useSocket = () => {
    return SocketContext.instance;
}
export const newSocket = () => {
    return new SocketContext();
}

export const useEnvironment = () => {
    return Environment.instance;
}

let hubInstance: Hub<any> | undefined;
export const useHub = () => {
    if (!hubInstance) {
        hubInstance = new Hub();
    }
    return hubInstance;
}

export const useSIP = () => {
    return window.GetSIPInstance();
}

export const setUnreadNotificationsCount = async (count: number) => setState({ unreadNotificationsCount: count })
export const setUnseenNotificationsCount = async (count: number) => setState({ unseenNotificationsCount: count })
export const addUnseenNotificationsCount = async () => setState({ unseenNotificationsCount: ((state.unseenNotificationsCount ?? 0) + 1) })

export const setSocketState = async (state: HubConnectionState) => setState({ socketState: state })

export const setCreateButton = async (name: string, route: string, modal?: string) => setState({
    createButton: {
        objectName: name,
        modal: modal,
        route: route
    }
})

export const clearCreateButton = async () => setState({
    createButton: undefined
})

export const setSystemExt = async (ext: SystemExtension) => {
    await setState({ systemExt: ext });
}

export const setUserToken = (token: string) => {
    localStorage.setItem("dspvoice-token", token);
}

export const getUserToken = () => {
    return localStorage.getItem("dspvoice-token");
}

export const setUserId = (userId: string) => {
    localStorage.setItem("dspvoice-user-id", userId);
}

export const getUserId = () => {
    return localStorage.getItem("dspvoice-user-id");
}

export const setOwnerUserId = (ownerId: string) => {
    localStorage.setItem("rinvox-owner-user-id", ownerId);
}

export const useOwnerUserId = () => {
    return localStorage.getItem("rinvox-owner-user-id") ?? state.user?.ownerUserId;
}

export const setDeviceId = (deviceId: string) => {
    localStorage.setItem("rinvox-device-id", deviceId);
}

export const getDeviceId = () => {
    return localStorage.getItem("rinvox-device-id");
}




export const setTwilioBuyLine = async (line: TwilioLine) => {
    await setState({ twilioBuyLine: line });
}


export const hasRole = (role?: string) => {

    if (state.user?.username == "root") return true;
    if (state.user?.isSuperAdmin) return true;
    if (!role || role == "") return false;

    if (state.roles && state.roles.indexOf(role) > -1) return true;

    return false;
}

export const hasAccessToQueue = (qu?: string) => {
    if (state.user?.username == "root") return true;
    if (state.user?.accessAllQueues) return true;
    if (state.user?.isCustomer) return true;

    if (!qu || qu == "") return false;
    if (state.user?.accessQueueIds && state.user?.accessQueueIds.indexOf(qu) > -1) return true;

    return false;
}

export const hasAccessToExt = (ext?: string) => {
    if (state.user?.username == "root") return true;
    if (state.user?.accessAllExtensions) return true;
    if (state.user?.isCustomer) return true;

    if (!ext || ext == "") return false;
    if (state.user?.accessExtensionsIds && state.user?.accessExtensionsIds.indexOf(ext) > -1) return true;

    return false;
}

export const hasRoles = (roles: string[]) => {

    for (var i = 0; i < roles.length; i++) {
        if (hasRole(roles[i])) return true;
    }

    return false;
}

export const validateRole = (role: string) => {
    if (!hasRole(role)) {
        window.location.href = "/";
    }
}

export const playCall = (call: Call) => setState({
    selectedCallToPlay: call,
    floatAudioStatus: {
        loading: true,
        playing: false
    }
})

export const playGlobalPlayer = () => {
    $("#global-float-audio-player").trigger("play");
}

export const pauseGlobalPlayer = () => {
    $("#global-float-audio-player").trigger("pause");
}

export const clearGlobalPlayer = () => setState({ selectedCallToPlay: undefined })

export const setCallToListen = (call: Call | LiveCall) => setState({ selectedCallToListen: call })

export const clearCallToListen = () => setState({ selectedCallToListen: undefined })

export const setChanSpyLine = (line: any) => {
    if (state.ChanSpyLine) {
        window.endSession(state.ChanSpyLine?.LineNumber);
    }
    setState({ ChanSpyLine: line })
}

export const setHangupChannel = (chan?: string) => setState({ hangupChannel: chan })

export const shareCalls = (ids: string[]) => {
    setState({ selectedCallsToShare: ids });
}

export const shareCallsClose = () => {
    setState({ selectedCallsToShare: undefined });
}

const timeFormat = () =>
    state.accountPreferences?.systemPreferences.timeFormat == TimeFormats.Format12Hours ? 'DD MMM - h:mm A'
        : 'DD MMM - HH:mm';
const timeClockFormat = () =>
    state.accountPreferences?.systemPreferences.timeFormat == TimeFormats.Format12Hours ? 'h:mm A'
        : 'HH:mm';

export const toPrettyDate = (dt?: Date, timezoneOffset?: number) => {
    if (!dt) return "";

    var offset = timezoneOffset ?? state.user?.timeZoneOffset ?? 0;
    var now = moment().utc();
    var mdt = moment(dt).utc();
    if (mdt.isSame(now, 'year')) {
        return mdt.add(offset, "minutes").format(timeFormat());
    }

    return mdt.add(offset, "minutes").format(timeFormat());
}

export const toNormalDate = (dt?: Date, timezoneOffset?: number) => {
    if (!dt) return "";

    var offset = timezoneOffset ?? state.user?.timeZoneOffset ?? 0;
    return moment(dt).utc().add(offset, "minutes").format(timeFormat());
}

export const toDateTime = (dt?: Date, timezoneOffset?: number) => {
    if (!dt) return "";
    var offset = timezoneOffset ?? state.user?.timeZoneOffset ?? 0;
    return moment(dt).utc().add(offset, "minutes").format(timeFormat());
}

export const toAppointmentDate = (from?: Date, to?: Date, timezoneOffset?: number) => {
    if (!from) return "";
    var res = "";
    var offset = timezoneOffset ?? state.user?.timeZoneOffset ?? 0;
    res = moment(from).utc().add(offset, "minutes").format(timeFormat()) + " - " + moment(to).utc().add(offset ?? 0, "minutes").format(timeClockFormat());
    return res;
}



let listeners: any = [];
let state: IState = {
    darkMode: GetThemeMode() == "dark",
    page: undefined,
    socketState: HubConnectionState.Connecting
};

const setState = (newState: IState) => {
    state = { ...state, ...newState };
    listeners.forEach((listener: any) => {
        listener(state);
    });
};


export function useGlobal(): [IState, (newState: IState) => void] {
    const newListener = useState()[1];
    useEffect(() => {
        listeners.push(newListener);
    }, []);
    return [state, setState];
}
