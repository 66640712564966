import Modal from "../../../../components/base/Modal";
import Job from "../models/Job";


interface IProps {
    job?: Job
    onClose?: () => void
}

export default function JobSendModal(props: IProps) {


    return (
        <Modal id="modal-job-send"
            title="Send Job"
            visible open
            subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
            onClose={props.onClose}>

            <></>

        </Modal>
    )
}