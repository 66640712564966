import { useEffect, useState } from "react";
import { useCountries, useEnvironment, useGlobal, useHub } from "../../../../core/Global";
import Company from "../../../accounts/models/Company";
import Country from "../../../base/models/Country";
import { API_ROUTES } from "../../../../core/Constants";
import Form from "../../../../components/form/Form";
import { toastError, toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import TextBox from "../../../../components/form/TextBox";
import DropDown from "../../../../components/form/DropDown";
import PhoneInput from "../../../../components/form/PhoneInput";



export default function CompanySettings() {



    const [globalState] = useGlobal()
    const hub = useHub()
    const env = useEnvironment()
    const [working, workingSet] = useState<boolean>(false);
    const [model, modelSet] = useState<Company>();

    const countries = useCountries()
    const [selectedCountry, selectedCountrySet] = useState<Country>()
    const [states, statesSet] = useState<any[]>()

    useEffect(() => {
        if (model) {
            selectedCountrySet(model.country);
        }
    }, [model])

    useEffect(() => {
        if (selectedCountry) {
            hub.Get(API_ROUTES.States + "?countryId=" + selectedCountry.id, res => {
                if (res.success) statesSet(res.data);
            })
        }
    }, [selectedCountry])


    const fetch = () => {
        hub.Get(API_ROUTES.ProfileCompany, res => {
            if (res.success && res.data) modelSet(res.data);
        })
    }

    useEffect(() => {
        fetch();
    }, [])


    const [imageChanged, imageChangedSet] = useState<boolean>(false);
    const [uploading, uploadingSet] = useState<boolean>(false);
    const [uploadData, uploadDataSet] = useState<File>();
    const [imageUrl, imageUrlSet] = useState<string>();
    const [imageVal, imageValSet] = useState<string>();

    const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            uploadDataSet(e.target.files[0]);
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                var img = $("#img-company-logo");
                img.attr("src", reader.result?.toString() ?? "");
                imageChangedSet(true);
            }
        }

    }

    useEffect(() => {
        if (uploadData) {
            uploadingSet(true);

            hub.Upload({
                category: "logos",
                file: uploadData
            }, res => {
                if (res.success && res.data) {
                    uploadingSet(false);
                    imageUrlSet(env.cdnUrl + res.data);
                    imageValSet(res.data);
                }
            });

        }
    }, [uploadData])


    return (
        <Form id="user-profile-company-form" className="form"
            onSubmit={(body, callback) => {
                var valid = true;
                var focus = true;
                if (window.isNullOrEmpty(body.name)) {
                    window.formError("name", "Please write the company name!", "user-profile-company-form", focus);
                    valid = false;
                    focus = false;
                }

                if (!valid) {
                    callback!();
                    return;
                }

                workingSet(true);

                hub.Post(API_ROUTES.ProfileCompany, body, res => {

                    if (res.success) {
                        toastSuccess("Company has been updated successfully!");
                        modelSet(res.data);
                    }
                    else if (res.message) {
                        if (res.field) window.formError(res.field, res.message, "user-profile-form");
                        else toastError(res.message);
                    }
                    workingSet(false);
                })

            }}>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">Company Details</h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Company</>
                            }
                        </button>
                    </div>
                </div>

                <div className="card-body border-top p-9">

                    <FlexRow>
                        <div className="me-2" style={{ position: "relative" }}>
                            <img id="img-company-logo" src={window.isNullOrEmpty(model?.logoUrl) ? `/assets/brands/${env.brand}/icon.png` : model?.logoUrl} className="h-40px" />
                            {
                                uploading &&
                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0,0,0,.5)",
                                    textAlign: "center",
                                    paddingTop: 12
                                }}>
                                    <Icon icon="spinner-third" spin className="text-white" />
                                </div>
                            }

                        </div>
                        {
                            window.isNullOrEmpty(model?.logoUrl) && !imageChanged ?
                                <span className="fw-boldest fs-2 ms-3 me-2">
                                    {model?.name ?? "Company Name"}
                                </span>
                                : <></>
                        }

                        <a href="#" className="badge badge-light-primary text-primary ms-3"
                            onClick={e => {
                                e.preventDefault();
                                $("#input-company-logo").click();
                            }}>
                            <Icon icon="magnifying-glass" className="me-2" />
                            Change Logo
                        </a>

                        <input id="input-company-logo" accept=".png, .jpg, .jpeg, .svg, .webp"
                            type="file" className="invisible" onChange={fileChange} />

                    </FlexRow>

                    <div className="mb-10"></div>

                    <input type="hidden" name="logo" value={imageVal} />

                    <TextBox label="Company Name" name="name" value={model?.name} />

                    <DropDown label="Country" name="countryId" defaultValue={model?.countryId} source={countries}
                        onSelectItem={c => selectedCountrySet(c)}>
                    </DropDown>

                    <DropDown label="State" name="stateId" defaultValue={model?.stateId} source={states}>
                    </DropDown>

                    <TextBox label="City" name="city" value={model?.city} />

                    <TextBox name="addressLine1" label="Address Line 1" value={model?.addressLine1} />
                    <TextBox name="addressLine2" label="Address Line 2" value={model?.addressLine2} />

                    <PhoneInput name="phone" label="Phone Number" defaultPhone={model?.phone} />

                </div>

            </div>
        </Form>
    )

}