import { useEffect, useState } from "react"
import { useHub } from "../../../../core/Global";
import { API_ROUTES } from "../../../../core/Constants";
import DataItem from "../../../../models/DataItem";

interface IProps {
    jobId?: string
    onChange?: (e: string) => void
}
export default function NotesTypeDropDown(props: IProps) {

    const hub = useHub();
    const [noteType, noteTypeSet] = useState<string>("0");
    const [types, typesSet] = useState<DataItem[]>([]);

    useEffect(() => {
        if (props.jobId) {
            hub.Get(API_ROUTES.Jobs + "notestypes/" + props.jobId, res => {
                if (res.success) typesSet(res.data);
            })
        }
    }, [props.jobId])

    useEffect(() => {
        if (props.onChange) props.onChange(noteType); 
    }, [noteType])
    
    return (
        <select className="form-select form-select-sm w-200px" 
            value={noteType} 
            onChange={e=> noteTypeSet(e.target.value)}
                style={{
                    minHeight: "auto",
                    fontSize: 11,
                    lineHeight: 1.1
                }}>
                {
                    types.map((t, k) => <option key={k} value={t.id} disabled={t.disabled}>{t.name}</option>)
                }
        </select>
    )
}