import "./AgentsMap.css";
import React, { useEffect, useState } from "react";
import FilteringProps from "../filtering/ExtensionsBoard.json";
import { setPage, useSocket, useHub } from '../../../core/Global';
import Layout from '../../../components/layouts/Layout';
import Div from "../../../components/base/Div";
import Indicator from "../../../components/base/Indicator";
import Environment from '../../../env/env';
import Hub from "../../../api/Hub";
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { AgentExtensionItem } from '../../agents/models/Agent';
import Icon from '../../../components/base/Icon';
import Avatar from "../../../components/base/Avatar";
import LiveCall from '../../voice/calls/models/LiveCall';
import Timer from "../../../components/timer/Timer";
import Modal from '../../../components/base/Modal';
import LiveCallActions from '../../../components/calls/LiveCallActions';
import { AgentStatus } from '../../agents/models/AgentDetailsModel';
import { AgentStatuses, CallTypes } from "src/core/Enums";
import { API_ROUTES } from "../../../core/Constants";
import Filtering from "../../../components/data/Filtering";
import BrowseOptions, { FilterParam } from "../../../models/BrowseOptions";
import AgentMonitoringItem from "../models/AgentMonitoringItem";
import AgentExtensionButton from "../../../components/agent/AgentExtensionButton";
import CallAgent from "../../voice/calls/models/CallAgent";


let data = new Array<AgentMonitoringItem>();
let agentIds: string[] = [];
let firstLoad = true;
let socketInterval: NodeJS.Timeout;
let silentTime: number = 30;
function AgentsMonitoring() {

    const env = new Environment();
    const hub = useHub();
    const { selectedId } = useParams();
    const socket = useSocket();
    const [browse, browseSet] = useState<BrowseOptions>();
    const [fetching, fetchingSet] = useState<boolean>(true);
    const [fetched, fetchedSet] = useState<boolean>(false);
    const [model, modelSet] = useState<AgentMonitoringItem[]>();
    const [onlineModel, onlineModelSet] = useState<AgentMonitoringItem[]>();
    const [selectedCall, selectedCallSet] = useState<LiveCall>();
    const [selectedAgent, selectedAgentSet] = useState<AgentMonitoringItem>();
    const [selectedExtension, selectedExtensionSet] = useState<AgentExtensionItem>();
    const [lineObj, lineObjSet] = useState<any>();
    const [searchParams] = useSearchParams();
    const [filteringValues, filteringValuesSet] = useState<any>();

    const fetch = async (b?: BrowseOptions, callback?: () => void) => {

        var q = firstLoad ? searchParams.toString() : "";
        if (b?.filterParams) {
            for (var i = 0; i < b.filterParams.length; i++) {
                if (q != "") q += "&";
                var ext = "";
                if (b.filterParams[i].op == "not-equal") ext = "Not";
                q += b.filterParams[i].name + ext + "=" + encodeURIComponent(b.filterParams[i].value);
            }
        }
        if (q != "") q = "?" + q;

        hub.Get(API_ROUTES.DashboardAgentsMonitoring + q, res => {
            if (res?.data) {
                for (var i = 0; i < res.data.length; i++) agentIds.push(res.data[i].id);
                onlineModelSet(res.data);
                filteringValuesSet(res.filteringValues);
                if (callback) callback();
                fetchingSet(false);
                fetchedSet(true);
            }
        });
    }

    const startSocket = async () => {
        // await socket.start();

        // socket.invoke("StartListenToLiveBoards", getUserListenId());

        // socket.on("UpdateLiveBoards", () => {
        //     fetch(browse);
        //     silentTime = 30;
        // });

        // if (socketInterval) clearInterval(socketInterval);

        // socketInterval = setInterval(async () => {
        //     if (socket.state != HubConnectionState.Connected) await startSocket();
        // }, 5000);

    }


    const onFilter = async (params: FilterParam[], callback: () => void) => {
        var b = browse ?? {};
        b.filterParams = params;
        await fetch(b);
        await callback();
    };

    useEffect(() => {
        setPage("Agents Monitoring", "Dashboard");

        fetch();
        startSocket();

        return () => {
            if (socketInterval) clearInterval(socketInterval);
            firstLoad = true;
        };

    }, [])

    const CallerID = (props: { agent?: CallAgent }) => (
        <span className="ms-2 d-flex align-items-center">
            {
                props.agent?.country ?
                    <Avatar width={15} avatarUrl={props.agent?.country.flagSquare} />
                    :
                    <Avatar width={15} avatarUrl={props.agent?.avatarUrl} />
            }

            {
                props.agent?.unknown ?
                    <span className="ms-1 fs-8">
                        {props.agent.number}
                    </span>
                    :
                    <span className="ms-1 fs-8">
                        {
                            props.agent?.name
                        }
                    </span>
            }

        </span>
    )

    const ExtMenu = (props: { ext: AgentExtensionItem, item: AgentMonitoringItem }) => (
        <>
            <a href="#" className="px-2 text-dark"
                onClick={e => {
                    e.preventDefault();
                    window.dataTableMenu();
                }}
                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <Icon icon="ellipsis-vertical" />
            </a>
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-8 w-150px p-0" data-kt-menu="true">
                {
                    props.ext.currentCall &&
                    <>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"
                                data-bs-toggle="modal" data-bs-target="#modal-hangup"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                }}
                            >
                                <Icon icon="phone-slash" className="me-2" />
                                Hang Up
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-listen"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '994762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="volume" className="me-2" />
                                Listen To Call
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-whisper"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '995762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="user-headset" className="me-2" />
                                Talk To Agent
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-barge"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedCallSet(props.ext.currentCall);
                                    var li = window.DialByLine('audio', undefined, '996762' + props.ext.extNumber);
                                    lineObjSet(li);
                                }}
                            >
                                <Icon icon="phone-volume" className="me-2" />
                                Join The Call
                            </a>
                        </div>
                    </>
                }
                {
                    !props.ext.currentCall &&
                    <>
                        <div className="menu-item">
                            <a href="#" className="menu-link px-3"
                                onClick={e => {
                                    e.preventDefault();
                                    selectedAgentSet(props.item);
                                    selectedExtensionSet(props.ext);
                                    window.openModal("modal-revoke-ext");
                                }}
                            >
                                <Icon icon="times" className="me-2" />
                                Revoke
                            </a>
                        </div>
                    </>
                }

            </div>
        </>
    )

    const AgentCard = (props: { item: AgentMonitoringItem }) => (
        <div className={"card card-agentmap card-agentmap-" + props.item.status?.colorClass + "  mb-5"}>
            <div className="card-body p-2">
                <div>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-0">
                                <Link to={"/agents/view/" + props.item?.id} className="text-gray-900 text-hover-primary fs-6 fw-bolder me-2">
                                    {props.item?.firstName + " " + props.item?.lastName.substring(0, 1).toUpperCase()}
                                </Link>
                                {
                                    props.item.queueName &&
                                    <div className="fs-8 ms-1">
                                        {props.item.queueName}
                                        <span className="ms-1">
                                            - {props.item.queueNumber}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        {
                            props.item.freeExtensions?.map((ext, key) =>
                                <div key={key} className="me-1">
                                    <AgentExtensionButton ext={ext} onSelect={() => {
                                        selectedExtensionSet(ext);
                                        selectedAgentSet(props.item);
                                    }} />
                                </div>
                            )
                        }
                        {/* <span key={key} className={"badge badge-success badge-sm d-flex px-2 py-1 me-1 fw-normal"}>
                                <span className="fs-7">{ext.extNumber}</span>
                                <Icon icon="phone-hangup" type="solid" className="mx-2" size={10} color="#fff" />
                                <Timer showHours width={45} totalSeconds={ext.activeTime} />
                            </span> */}
                    </div>
                    <div>
                        {
                            props.item.busyExtensions?.map((ext, key) =>
                                <div className="mt-2">
                                    <div key={key} className="d-flex align-items-center">
                                        <AgentExtensionButton ext={ext} selectCall={call => selectedCallSet(call)} />
                                        <div className="ms-2">
                                            <Icon icon={ext.currentCall?.callType == CallTypes.Incoming ? "phone-arrow-down-left"
                                                : ext.currentCall?.callType == CallTypes.Outgoing ? "phone-arrow-up-right"
                                                    : ext.currentCall?.callType == CallTypes.Local ? "user-headset"
                                                        : "dot"} />
                                        </div>
                                        <CallerID agent={ext.currentCall?.sourceAgent?.id == props.item.id ? ext.currentCall.destinationAgent : ext.currentCall?.sourceAgent} />
                                        <div className="ms-auto">
                                            {
                                                ext.currentCall?.talking &&
                                                <LiveCallActions call={ext.currentCall} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    )

    const OldCard = (item: any) => (
        <div>
            {
                !item.currentCall &&
                <span className={"badge badge-" + item.status?.colorClass + " badge-sm px-2 fs-9 fw-normal"}>
                    <Icon icon="phone-hangup" type="solid" className="me-2" size={9} />
                    {item.status?.name}
                </span>
            }

            {
                item.currentCall &&
                <>
                    {
                        item.currentCall.ringing ?
                            <span className="d-flex align-items-center badge badge-light-primary badge-sm px-5">
                                <Icon icon="phone-rotary" type="solid" className="me-2" shake color="#009ef7" size={15} />
                                <CallerID agent={item.currentCall.sourceAgent?.id == item.id ? item.currentCall.destinationAgent : item.currentCall.sourceAgent} />
                            </span>
                            :
                            <>
                                <span className="badge badge-danger badge-sm px-3" style={{
                                    fontSize: 10,
                                    zIndex: 5
                                }}>
                                    <Icon icon="phone-volume" type="solid" className="me-2" size={10} color="#fff" />
                                    <Timer totalSeconds={item.currentCall?.talkingSeconds} />
                                </span>
                                <span style={{
                                    fontSize: 11
                                }}>
                                    <CallerID agent={item.currentCall.sourceAgent?.id == item.id ? item.currentCall.destinationAgent : item.currentCall.sourceAgent} />
                                </span>

                            </>
                    }
                </>
            }

            {
                (item.status?.status == AgentStatuses.Active || item.extensionNumber > 0) &&
                <a href="#" title="Force Logout"
                    className="mx-2 force-logout"
                    onClick={e => {
                        e.preventDefault();
                        selectedAgentSet(item);
                        window.openModal("modal-logout-user");
                    }}>
                    <Icon icon="right-from-bracket" color="#666" size={16} />
                </a>
            }

            {
                item.currentCall?.talking &&
                <div className="me-2">
                    <LiveCallActions call={item.currentCall} />
                </div>
            }
        </div>
    )

    return (
        <Layout>
            <audio id="maincall"></audio>

            <Filtering filteringProps={FilteringProps}
                filteringValues={filteringValues}
                apiRoute={API_ROUTES.DashboardAgentsMap}
                loaded={fetched}
                onFilter={onFilter} />

            <Div visible={fetching} className="mt-20">
                <Indicator />
            </Div>

            <Div visible={fetched}>

                <Div visible={onlineModel && onlineModel.length > 0}>
                    <div className="row">
                        <div className="col-12 mt-5">
                            <h3 className="text-dark">Online ({onlineModel?.length})</h3>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            onlineModel?.map((item, key) =>
                                <div key={key} className="col-12 col-lg-6 col-xxl-4">
                                    <AgentCard item={item} />
                                </div>
                            )
                        }
                    </div>
                </Div>

            </Div>

            <Modal id="modal-logout-user"
                title="Logout User"
                buttonText="Force Logout"
                buttonColor="danger"
                onConfirm={(callback: any) => {
                    hub.Get("/auth/logout/" + selectedAgent?.id, res => {
                        if (res.data) {
                            if (callback) callback();
                        }
                    })
                }}>
                <p className="ps-1">Are you sure you want to force logout this user?</p>
                <div className="p-4 bg-secondary d-flex align-items-center">
                    <span>{selectedAgent?.firstName + " " + selectedAgent?.lastName}</span>
                    {
                        selectedAgent?.freeExtensions?.map((ext, key) =>
                            <span key={key} className="ms-4">
                                <Icon icon="phone-office" type="solid" size={9} />
                                <span className="ms-1 fs-8">{ext.extNumber}</span>
                            </span>
                        )
                    }
                </div>
            </Modal>

            <Modal id="modal-ext-remove"
                title={"Extension"}
                buttonText="Remove Extension"
                buttonColor="danger"
                onSubmit={(body, callback) => {

                    hub.Post(API_ROUTES.RevokeExtension, {
                        ext: selectedExtension?.extId,
                        userId: selectedAgent?.id
                    }, res => {
                        fetch();
                        if (callback) callback(res);
                    });

                }}>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center">
                            <Icon icon="user-headset" size={14} />
                            <span className="fs-6 ms-2 fw-bold">{selectedAgent?.fullName}</span>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <Icon icon="phone-office" size={14} />
                            <span className="fs-6 ms-2 fw-bold">{selectedExtension?.extNumber}</span>
                        </div>
                    </div>
                </div>
            </Modal>

        </Layout>
    )

}

export default AgentsMonitoring;