import React, { useEffect, useState } from "react";
import Layout from '../../../../components/layouts/Layout';
import { setPage } from '../../../../core/Global';
import CallsList from "../../../../components/calls/CallsList";
import Section from "../../../../components/base/Section";



function CallsHistory() {

  useEffect(()=> {
      setPage("Calls History", "Voice", "Calls");
  }, [])

  return (
    <Layout wide>
      <Section fluid>
        <CallsList />
      </Section>
    </Layout>
  );
}

export default CallsHistory;
