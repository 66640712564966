import Modal from "../../../components/base/Modal"
import { toastSuccess } from "../../../components/base/Toast"
import { API_ROUTES } from "../../../core/Constants"
import { useHub } from "../../../core/Global"
import TechGroup from "../models/TechGroup"


interface IProps {
    model?: TechGroup
    onClose?: () => void
    onDeleted?: () => void
}

export default function TechGroupDeleteModal(props: IProps) {

    const hub = useHub();


    return (
        <Modal id="modal-delete-techgroup"
            title="Delete Technician Group"
            open visible staticBackdrop
            buttonText="Confirm & Delete"
            buttonColor="danger"
            onClose={props.onClose}
            onConfirm={callback => {
                hub.Delete(API_ROUTES.TechGroups + props.model?.id, res => {
                    if (res.success) {
                        if (props.onDeleted) props.onDeleted();
                        toastSuccess("Group has been deleted successfully!");
                     }
                     if (callback) callback();
                })
            }}>
            <div>
                <div>
                    Are you sure you want to delete the technician group?
                </div>
                <div className="bg-secondary mt-2 px-4 py-2">
                    {props.model?.name}
                </div>
            </div>
        </Modal>
    )
}