import { useState } from "react";
import Form from "../../../../components/form/Form";
import { getUser, useHub, usePreferences } from "../../../../core/Global";
import { toastSuccess } from "../../../../components/base/Toast";
import Icon from "../../../../components/base/Icon";
import TextBox from "../../../../components/form/TextBox";
import DropDown from "../../../../components/form/DropDown";
import { SystemPreferencesModel } from "../../../../models/AccountPreferences";
import { DistanceUnitsSource, TimeFormatsSource, TimePickerStepsSource } from "../../../../core/Constants";




export default function SystemPreferences() {


    const hub = useHub()
    const user = getUser()
    const pref = usePreferences()
    const [working, workingSet] = useState<boolean>(false)


    return (
        <Form className="form"
            onSubmit={(body, callback) => {
                workingSet(true);
                hub.Post("/system/Settings/systempreferences", {
                    timeFormat: Number.parseInt(body.timeFormat),
                    distanceUnit: Number.parseInt(body.distanceUnit),
                    timePickersSteps: Number.parseInt(body.timePickersSteps)
                }, res => {
                    if (res.success) {
                        toastSuccess("System preferences has been updated successfully!");
                    }
                    if (callback) callback(res);
                    workingSet(false);
                });
            }}>
            <div className="card mb-5">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">
                            System Preferences
                        </h3>
                    </div>
                    <div className="card-action">
                        <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                            {
                                working ?
                                    <>
                                        <Icon icon="spinner-third" spin />
                                        <span className="ms-2">Updating...</span>
                                    </>
                                    : <>Update Preferences</>
                            }
                        </button>
                    </div>
                </div>
                <div className="card-body border-top px-9 pt-10 pb-4">

                    <DropDown id="drp-systempreferences-timeformat" key="drp-systempreferences-timeformat"
                        name="timeFormat" source={TimeFormatsSource} label="Time Format" deselect={false}
                        defaultValue={pref?.systemPreferences.timeFormat.toString()} />

                    <DropDown id="drp-systempreferences-distanceunits" key="drp-systempreferences-distanceunits"
                        name="distanceUnit" source={DistanceUnitsSource} label="Distance Unit" deselect={false}
                        defaultValue={pref?.systemPreferences.distanceUnit.toString()} />

                    <DropDown id="drp-systempreferences-timepickersteps" key="drp-systempreferences-timepickersteps"
                        name="timePickersSteps" source={TimePickerStepsSource} label="Time Picker Steps" deselect={false}
                        defaultValue={pref?.systemPreferences.timePickersSteps.toString()} />





                </div>
            </div>
        </Form>
    )
}