import { useEffect, useState } from "react";
import JobInvoice from "../../crm/jobs/models/JobInvoice";
import FlexRow from "../../../components/base/FlexRow";
import Icon from "../../../components/base/Icon";
import DataItem from "../../../models/DataItem";
import { AmountType, InvoiceItemTypes } from "../../../core/Enums";
import { useEnvironment, useHub } from "../../../core/Global";
import { useParams } from "react-router-dom";
import Indicator from "../../../components/base/Indicator";

export default function InvoiceView() {

    const { id, token } = useParams()
    const hub = useHub()
    const env = useEnvironment()
    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [model, modelSet] = useState<JobInvoice>()
    const [loading, loadingSet] = useState<boolean>(true)
    const [ItemTypes] = useState<DataItem[]>([
        {
            id: "1",
            name: "Product"
        },
        {
            id: "2",
            name: "Service"
        },
        {
            id: "3",
            name: "Hour"
        },
        {
            id: "4",
            name: "Expense"
        }
    ])

    useEffect(() => {
        hub.Get(`/base/public/share/invoice/${id}/${token}`, res => {
            if (res.success) {
                modelSet(res.data);
                loadingSet(false);
            }
            else console.log(res);
        })
    })

    return (
        <div>
            {
                loading ? <Indicator />
                    :
                    <div className="m-0">

                        <div className="mb-5">
                            <FlexRow className="mb-5">
                                <span className="fw-bolder fs-3 text-gray-800">
                                    {model?.issued ? "Invoice" : "Estimate"} {model?.number}
                                </span>
                                <FlexRow className="d-none d-md-flex ms-auto">
                                    <a href={`${env.apiUrl}/base/public/job/invoice/download/${model?.id}`} target="_blank"
                                        className="btn btn-light-primary btn-active-light-primary btn-sm me-2">
                                        <Icon icon="download" className="me-2" />
                                        Download PDF
                                    </a>
                                    {/* <a href={`${env.apiUrl}/base/public/job/invoice/download/${model?.id}`} target="_blank"
                                className="btn btn-success btn-sm">
                                <Icon icon="credit-card" className="me-2" />
                                Pay Invoice
                            </a> */}
                                </FlexRow>
                            </FlexRow>

                            <FlexRow className="mb-4">
                                <div className="me-10">
                                    <div className="fw-bold text-gray-600 fs-7">Name:</div>
                                    <div className="text-gray-800 fs-6">{model?.billingName}</div>
                                </div>
                                <div>
                                    <div className="fw-bold text-gray-600 fs-7">Phone:</div>
                                    <div className="text-gray-800 fs-6">{model?.billingPhone?.phoneFriendly}</div>
                                </div>
                            </FlexRow>

                            <div className="mb-4">
                                <div className="fw-bold text-gray-600 fs-7">Address:</div>
                                <div className="text-gray-800 fs-6">{model?.billingAddress}</div>
                            </div>
                        </div>



                        <div className="flex-grow-1">
                            <div className="table-responsive border-bottom mb-9">
                                <table className="table mb-3">
                                    <thead>
                                        <tr className="border-bottom fs-6 fw-bolder text-muted">
                                            <th className="min-w-150px pb-2">Description</th>
                                            <th className="min-w-70px text-end pb-2">Qty</th>
                                            <th className="min-w-80px text-end pb-2">Rate</th>
                                            <th className="min-w-100px text-end pb-2">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            model?.items?.map((item, key) =>
                                                <tr key={key} className="fw-bolder text-gray-700 fs-5 text-end align-items-center">
                                                    <td className="text-start align-middle">
                                                        <div className="pt-6">
                                                            <a href="#" onClick={e => {
                                                                e.preventDefault();
                                                                // selectedItemSet(item);
                                                                // itemFormModalSet(true);
                                                            }}>
                                                                {item.title}
                                                            </a>
                                                        </div>
                                                        <FlexRow>
                                                            <Icon icon="tag" size={11} />
                                                            <span className="fw-normal fs-7 ms-1">
                                                                {ItemTypes.filter(f => f.id == item.itemType?.toString())[0].name}
                                                            </span>
                                                        </FlexRow>
                                                        {
                                                            !window.isNullOrEmpty(item.description) &&
                                                            <FlexRow>
                                                                <Icon icon="info-circle" size={11} />
                                                                <span className="fw-normal ms-1 fs-7">
                                                                    {item.description}
                                                                </span>
                                                            </FlexRow>
                                                        }
                                                    </td>
                                                    <td className="pt-6 align-middle">
                                                        {item.qty}
                                                        {
                                                            item.itemType == InvoiceItemTypes.HourlyService &&
                                                            <span className="fs-8 text-muted ms-1">h</span>
                                                        }
                                                    </td>
                                                    <td className="pt-6 align-middle">
                                                        <div>
                                                            {moneyFormatter.format(item.amount ?? 0)}
                                                        </div>
                                                        {
                                                            item.tax &&
                                                            <div className="fs-8 fw-normal">
                                                                {item.tax.included ? "" : "+"} ({item.tax.name} {item.tax.amountText})
                                                                {
                                                                    item.tax.included && <Icon icon="circle-info" className="ms-1" size={10} type="solid" title={"Included Tax"} />
                                                                }
                                                            </div>
                                                        }
                                                    </td>
                                                    <td className="pt-6 text-dark fw-boldest align-middle">
                                                        {moneyFormatter.format(item.totalAmount ?? 0)}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end">
                                <div className="w-100 w-md-250px">
                                    <div className="d-flex flex-stack mb-3">
                                        <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                        <div className="text-end fw-bolder fs-6 text-gray-800">
                                            {moneyFormatter.format(model?.subtotal ?? 0)}
                                        </div>
                                    </div>
                                    {
                                        model?.itemsTotalTax && model?.itemsTotalTax > 0 ?
                                            <div className="d-flex flex-stack mb-3">
                                                <div className="fw-bold pe-10 text-gray-600 fs-7">
                                                    Items Tax
                                                </div>
                                                <div className="text-end fw-bolder fs-6 text-gray-800">
                                                    {moneyFormatter.format(model.itemsTotalTax)}
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                    {
                                        model?.taxes && model.taxes.length > 0 ?
                                            <>
                                                {
                                                    model.taxes.map((tax, key) =>
                                                        <div key={key} className="d-flex flex-stack mb-3">
                                                            <div className="fw-bold pe-10 text-gray-600 fs-7">
                                                                <a href="#" className="me-2" title="Remove Tax"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        // hub.Post(API_ROUTES.JobInvoices + "taxremove", {
                                                                        //     id: model.id,
                                                                        //     taxId: tax.id
                                                                        // }, res => {
                                                                        //     if (res.success) modelSet(res.data)
                                                                        // })

                                                                    }}>
                                                                    <Icon icon="circle-minus" />
                                                                </a>
                                                                {
                                                                    tax.amountType == AmountType.Percent ?
                                                                        <>{tax.nameWithAmount}</>
                                                                        : <>{tax.name}</>
                                                                }
                                                            </div>
                                                            <div className="text-end fw-bolder fs-6 text-gray-800">
                                                                {moneyFormatter.format(tax.amount ?? 0)}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <></>
                                    }

                                    <hr />

                                    <div className="d-flex flex-stack">
                                        <div className="fw-boldest pe-10 text-gray-800 fs-5">Total</div>
                                        <div className="text-end fw-bolder fs-4 text-gray-800">
                                            {moneyFormatter.format(model?.total ?? 0)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-20 d-block d-md-none">
                            <a href={`${env.apiUrl}/base/public/job/invoice/download/${model?.id}`} target="_blank"
                                className="btn btn-light-primary btn-active-light-primary w-100">
                                <Icon icon="download" className="me-2" />
                                Download PDF
                            </a>
                        </div>

                    </div>
            }


        </div>
    )
}