import { useEffect, useState } from "react";
import { useEnvironment, useGlobal, useHub } from "../../../../core/Global"
import Job from "../models/Job"
import Indicator from "../../../../components/base/Indicator";
import { API_ROUTES } from "../../../../core/Constants";
import JobInvoice, { JobInvoiceItem } from "../models/JobInvoice";
import Icon from "../../../../components/base/Icon";
import Modal from "../../../../components/base/Modal";
import DropDown from "../../../../components/form/DropDown";
import DataItem from "../../../../models/DataItem";
import TextBox from "../../../../components/form/TextBox";
import TextArea from "../../../../components/form/TextArea";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import { CompactTax } from "../../base/models/TaxItem";
import { AmountType, InvoiceItemTypes } from "../../../../core/Enums";
import FlexRow from "../../../../components/base/FlexRow";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../../../../components/base/Avatar";
import { toastError } from "../../../../components/base/Toast";
import PhoneInput from "../../../../components/form/PhoneInput";
import Company from "../../../accounts/models/Company";

interface IProps {
    job: Job
    invoiceId: string
}

export default function JobInvoiceDetails(props: IProps) {

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });



    const hub = useHub();
    const nav = useNavigate();
    const env = useEnvironment();
    const [globalState] = useGlobal();

    const [loading, loadingSet] = useState<boolean>(true);
    const [model, modelSet] = useState<JobInvoice>();
    const [invoiceOrEstimate, invoiceOrEstimateSet] = useState<string>("Estimate");
    const [invoiceOrEstimates, invoiceOrEstimatesSet] = useState<string>("Estimates");

    const [company, companySet] = useState<Company>();

    const fetchCompany = () => {
        hub.Get(API_ROUTES.ProfileCompany, res => {
            if (res.success && res.data) companySet(res.data);
        })
    }

    const [estimates, estimatesSet] = useState<JobInvoice[]>();
    const [invoices, invoicesSet] = useState<JobInvoice[]>();
    const [createEstimate, createEstimateSet] = useState<boolean>(false);
    const [createInvoice, createInvoiceSet] = useState<boolean>(false);

    const [selectedItem, selectedItemSet] = useState<JobInvoiceItem>();

    useEffect(() => {
        if (selectedItem) {
            itemAmountSet(selectedItem.amount ?? 0);
            itemQtySet(selectedItem.qty ?? 0);
            selectedTaxSet(selectedItem.tax);
            taxIncludedSet(selectedItem.tax?.included ?? false);
        }
    }, [selectedItem])

    const [ItemTypes] = useState<DataItem[]>([
        {
            id: "1",
            name: "Product"
        },
        {
            id: "2",
            name: "Service"
        },
        {
            id: "3",
            name: "Hour"
        },
        {
            id: "4",
            name: "Expense"
        }
    ])

    const [itemFormModal, itemFormModalSet] = useState<boolean>(false);
    const [createAnother, createAnotherSet] = useState<boolean>(false);
    const [confirmModal, confirmModalSet] = useState<boolean>(false);
    const [undoConfirmModal, undoConfirmModalSet] = useState<boolean>(false);
    const [deleteModal, deleteModalSet] = useState<boolean>(false);
    const [reset, resetSet] = useState<boolean>(false);

    useEffect(() => {
        if (reset) {
            selectedItemSet(undefined);
            selectedTaxSet(undefined);
            itemAmountSet(0);
            itemQtySet(0);
            itemTotalAmountSet(0);
            selectedTaxSet(undefined);
            taxIncludedSet(false);
            setTimeout(() => {
                resetSet(false);
            }, 1000);
        }
    }, [reset])

    const [deleteInvoiceItem, deleteInvoiceItemSet] = useState<boolean>(false);
    const [sendInvoiceModal, sendInvoiceModalSet] = useState<boolean>(false);
    const [sendAll, sendAllSet] = useState<boolean>(false);
    const [addTax, addTaxSet] = useState<boolean>(false);
    const [estToInvModal, estToInvModalSet] = useState<boolean>(false);
    const [estToInvErrModal, estToInvErrModalSet] = useState<boolean>(false);

    const [previewModal, previewModalSet] = useState<boolean>(false);

    const [taxes, taxesSet] = useState<CompactTax[]>([]);
    const fetchTaxes = () => {
        hub.Get(API_ROUTES.Taxes + "all", res => {
            if (res.success) taxesSet(res.data);
        })
    }

    const fetchInvoice = () => {
        hub.Get(API_ROUTES.JobInvoices + props.invoiceId, res => {
            if (res.success) {
                modelSet(res.data);
                loadingSet(false);
            }
        })
    }

    const fetchEstimates = () => {
        hub.Get(API_ROUTES.JobInvoices + "?estimates=True&jobId=" + props.job.id, res => {
            if (res.success) estimatesSet(res.data);
        })
    }

    const fetchInvoices = () => {
        hub.Get(API_ROUTES.JobInvoices + "?estimates=False&jobId=" + props.job.id, res => {
            if (res.success) invoicesSet(res.data);
        })
    }

    useEffect(() => {

        fetchEstimates();
        fetchInvoices();
        fetchInvoice();
        fetchTaxes();
        fetchCompany();

    }, [props.invoiceId])

    useEffect(() => {
        invoiceOrEstimateSet(model?.issued ? "Invoice" : "Estimate");
        invoiceOrEstimatesSet(model?.issued ? "Invoices" : "Estimates");
        if (model) {
            if (loading && model.items!.length == 0) {
                itemFormModalSet(true);
            }
            loadingSet(false);
        }
    }, [model]);


    const [itemAmount, itemAmountSet] = useState<number>(0);
    const [itemQty, itemQtySet] = useState<number>(0);
    const [itemTotalAmount, itemTotalAmountSet] = useState<number>(0);
    const [selectedTax, selectedTaxSet] = useState<CompactTax>();
    const [taxIncluded, taxIncludedSet] = useState<boolean>(false);

    useEffect(() => {
        if (itemAmount == 0 || itemQty == 0) itemTotalAmountSet(0);
        else {
            var am = itemAmount;
            var tx = 0;
            var qty = itemQty;
            if (selectedTax?.amount && !taxIncluded) {
                if (selectedTax.amountType == AmountType.Percent) tx = (am * selectedTax.amount) / 100;
                else tx = selectedTax.amount
            }

            var t = (am + tx) * qty;
            itemTotalAmountSet(t);

            console.log(itemAmount, itemQty);
        }
    }, [itemAmount, itemQty, selectedTax, taxIncluded])

    useEffect(() => {
        if (addTax && selectedTax?.amount) {
            var tx = 0;
            var t = model?.total ?? 0;
            if (selectedTax.amountType == AmountType.Percent) tx = (t * selectedTax.amount) / 100;
            else tx = selectedTax.amount;
            taxAmountForTaxModalSet(tx);
            if (!taxIncluded) t += tx;
            totalForTaxModalSet(t);
        }
        else {
            taxAmountForTaxModalSet(0);
            totalForTaxModalSet(model?.total ?? 0);
        }
    }, [selectedTax, taxIncluded, addTax])

    const ItemTotalAmount = () => (
        <div>
            <div className="fs-1 fw-bolder">
                {moneyFormatter.format(itemTotalAmount)}
            </div>
            <div className="fs-7 fw-bold">
                Total
            </div>
        </div>
    )


    const [totalForTaxModal, totalForTaxModalSet] = useState<number>(0);
    const [taxAmountForTaxModal, taxAmountForTaxModalSet] = useState<number>(0);
    const TaxAmountFooter = () => (
        <div>
            <div className="fs-1 fw-bolder">
                {moneyFormatter.format(totalForTaxModal)}
            </div>
            <div className="fs-7 fw-bold">
                Tax Amount: {moneyFormatter.format(taxAmountForTaxModal)}
            </div>
        </div>
    )

    const [updateBillingModal, updateBillingModalSet] = useState<boolean>(false);
    const [updateIssuedModal, updateIssuedModalSet] = useState<boolean>(false);



    const [sendTo, sendToSet] = useState<"email" | "phone">("email")

    return (
        <div>
            {
                loading ?
                    <div className="my-20">
                        <Indicator text="Please wait..." />
                    </div>
                    :
                    <>
                        <FlexRow>
                            <Link to={`/crm/jobs/${invoiceOrEstimates.toLowerCase()}/${props.job.id}`} className="nav-link" title={`Back to ${invoiceOrEstimates}`}>
                                <Icon icon="chevron-left" className="me-2 text-gray-600" size={24} />
                            </Link>
                            {
                                model &&
                                <>
                                    {
                                        model.issued ?
                                            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6">
                                                {
                                                    invoices?.map((item, key) =>
                                                        <li key={key} className="nav-item">
                                                            <Link to={`/crm/jobs/invoices/${props.job.id}/view/${item.id}`} className={"nav-link " + (item.id == props.invoiceId ? "active" : "")}>
                                                                <FlexRow>

                                                                    <div>
                                                                        <FlexRow className="fs-7">
                                                                            <span className="me-2 text-dark fw-bold">Invoice {item.number}</span>
                                                                            {
                                                                                item.lastSend && <Icon icon="share" className="me-2 text-primary" type="solid" />
                                                                            }
                                                                            {
                                                                                item.confirmed && <Icon icon="check-circle" className="me-2 text-success" type="solid" />
                                                                            }
                                                                        </FlexRow>
                                                                        <FlexRow>
                                                                            <div className="fs-8 me-2">
                                                                                {moneyFormatter.format(item.total ?? 0)}
                                                                            </div>
                                                                            <div className="fs-8">
                                                                                ({item.createDate})
                                                                            </div>
                                                                        </FlexRow>
                                                                    </div>
                                                                </FlexRow>
                                                            </Link>
                                                        </li>
                                                    )
                                                }

                                            </ul>
                                            :
                                            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6">
                                                {
                                                    estimates?.map((item, key) =>
                                                        <li key={key} className="nav-item">
                                                            <Link to={`/crm/jobs/estimates/${props.job.id}/view/${item.id}`} className={"nav-link " + (item.id == props.invoiceId ? "active" : "")}>
                                                                <FlexRow>
                                                                    <div className="me-2">
                                                                        <Avatar avatarUrl={item.tech?.avatarUrl} fullName={item.tech?.fullName} width={30} />
                                                                    </div>
                                                                    <div>
                                                                        <FlexRow className="fs-7">
                                                                            <span className="me-2 text-dark fw-bold">{item.tech?.fullName} {item.number}</span>
                                                                            {
                                                                                item.lastSend && <Icon icon="share" className="me-2 text-primary" type="solid" />
                                                                            }
                                                                            {
                                                                                item.confirmed && <Icon icon="check-circle" className="me-2 text-success" type="solid" />
                                                                            }
                                                                        </FlexRow>
                                                                        <FlexRow>
                                                                            <div className="fs-8 me-2">
                                                                                {moneyFormatter.format(item.total ?? 0)}
                                                                            </div>
                                                                            <div className="fs-8">
                                                                                ({item.createDate})
                                                                            </div>
                                                                        </FlexRow>
                                                                    </div>
                                                                </FlexRow>
                                                            </Link>
                                                        </li>
                                                    )
                                                }

                                            </ul>
                                    }
                                </>
                            }

                            <div className="ms-auto">
                                <a href="#" className="btn btn-sm btn-primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        if (model?.issued) {
                                            if (true) createInvoiceSet(true);
                                            else estToInvErrModalSet(true);
                                        }
                                        else {
                                            createEstimateSet(true);
                                        }
                                    }}>
                                    <Icon icon="plus" className="me-2" />
                                    New {invoiceOrEstimate}
                                </a>
                                {
                                    ((estimates && estimates.length > 1 && !model?.issued) || (invoices && invoices.length > 1 && model?.issued)) &&
                                    <a href="#" className="btn btn-sm btn-success ms-1"
                                        onClick={e => {
                                            e.preventDefault();
                                            sendInvoiceModalSet(true);
                                            sendAllSet(true);
                                        }}>
                                        <Icon icon="share" className="me-2" />
                                        Send All {invoiceOrEstimates}
                                    </a>
                                }
                            </div>
                        </FlexRow>

                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-column flex-xl-row">
                                    <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                        <div className="mt-n1">
                                            <FlexRow className="pb-10">
                                                <div>
                                                    <img src={company?.logoUrl ?? `/assets/brands/${env.brand}/logo.png`} className="h-40px h-xxxl-50px" />
                                                </div>
                                                <FlexRow className="ms-auto">
                                                    <button type="button" className="btn btn-sm btn-light-primary btn-active-light-primary me-1"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            selectedItemSet(undefined);
                                                            itemFormModalSet(true);
                                                            itemAmountSet(0);
                                                            itemQtySet(0);
                                                            itemTotalAmountSet(0);
                                                            selectedTaxSet(undefined);
                                                            taxIncludedSet(false);
                                                        }}>
                                                        <Icon icon="plus" /> Add Item
                                                    </button>
                                                    {
                                                        model?.items && model.items.length > 0 &&
                                                        <>



                                                        </>
                                                    }

                                                </FlexRow>
                                            </FlexRow>
                                        </div>

                                        <div className="m-0">

                                            <FlexRow className="mb-5">
                                                <span className="fw-bolder fs-3 text-gray-800">
                                                    {model?.issued ? "Invoice" : "Estimate"} {model?.number}
                                                </span>
                                            </FlexRow>

                                            <div className="flex-grow-1">
                                                <div className="table-responsive border-bottom mb-9">
                                                    <table className="table mb-3">
                                                        <thead>
                                                            <tr className="border-bottom fs-6 fw-bolder text-muted">
                                                                <th className="min-w-175px pb-2">Description</th>
                                                                <th className="min-w-70px text-end pb-2">Qty</th>
                                                                <th className="min-w-80px text-end pb-2">Rate</th>
                                                                <th className="min-w-100px text-end pb-2">Amount</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                model?.items?.map((item, key) =>
                                                                    <tr key={key} className="fw-bolder text-gray-700 fs-5 text-end align-items-center">
                                                                        <td className="text-start align-middle">
                                                                            <div className="pt-6">
                                                                                <a href="#" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    selectedItemSet(item);
                                                                                    itemFormModalSet(true);
                                                                                }}>
                                                                                    {item.title}
                                                                                </a>
                                                                            </div>
                                                                            <FlexRow>
                                                                                <Icon icon="tag" size={11} />
                                                                                <span className="fw-normal fs-7 ms-1">
                                                                                    {ItemTypes.filter(f => f.id == item.itemType?.toString())[0].name}
                                                                                </span>
                                                                            </FlexRow>
                                                                            {
                                                                                !window.isNullOrEmpty(item.description) &&
                                                                                <FlexRow>
                                                                                    <Icon icon="info-circle" size={11} />
                                                                                    <span className="fw-normal ms-1 fs-7">
                                                                                        {item.description}
                                                                                    </span>
                                                                                </FlexRow>
                                                                            }
                                                                        </td>
                                                                        <td className="pt-6 align-middle">
                                                                            {item.qty}
                                                                            {
                                                                                item.itemType == InvoiceItemTypes.HourlyService &&
                                                                                <span className="fs-8 text-muted ms-1">h</span>
                                                                            }
                                                                        </td>
                                                                        <td className="pt-6 align-middle">
                                                                            <div>
                                                                                {moneyFormatter.format(item.amount ?? 0)}
                                                                            </div>
                                                                            {
                                                                                item.tax &&
                                                                                <div className="fs-8 fw-normal">
                                                                                    {item.tax.included ? "" : "+"} ({item.tax.name} {item.tax.amountText})
                                                                                    {
                                                                                        item.tax.included && <Icon icon="circle-info" className="ms-1" size={10} type="solid" title={"Included Tax"} />
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td className="pt-6 text-dark fw-boldest align-middle">
                                                                            {moneyFormatter.format(item.totalAmount ?? 0)}
                                                                        </td>
                                                                        <td className="align-middle pt-5">
                                                                            <a href="#" className="text-dark px-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    window.dataTableMenu();
                                                                                }}>
                                                                                <Icon icon="ellipsis-vertical" />
                                                                            </a>
                                                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                                                                <div className="menu-item">
                                                                                    <a href="#" className="menu-link px-3"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            selectedItemSet(item);
                                                                                            itemFormModalSet(true);
                                                                                        }}>
                                                                                        <Icon icon="edit" className="me-2" />
                                                                                        Edit
                                                                                    </a>
                                                                                </div>
                                                                                <div className="menu-item">
                                                                                    <a href="#" className="menu-link px-3 text-danger"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            selectedItemSet(item);
                                                                                            deleteInvoiceItemSet(true);
                                                                                        }}>
                                                                                        <Icon icon="trash-xmark" className="me-2" />
                                                                                        Delete
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <div className="mw-400px w-250px">
                                                        <div className="d-flex flex-stack mb-3">
                                                            <div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                                            <div className="text-end fw-bolder fs-6 text-gray-800">
                                                                {moneyFormatter.format(model?.subtotal ?? 0)}
                                                            </div>
                                                        </div>
                                                        {
                                                            model?.itemsTotalTax && model?.itemsTotalTax > 0 ?
                                                                <div className="d-flex flex-stack mb-3">
                                                                    <div className="fw-bold pe-10 text-gray-600 fs-7">
                                                                        Items Tax
                                                                    </div>
                                                                    <div className="text-end fw-bolder fs-6 text-gray-800">
                                                                        {moneyFormatter.format(model.itemsTotalTax)}
                                                                    </div>
                                                                </div>
                                                                : <></>
                                                        }
                                                        {
                                                            model?.taxes && model.taxes.length > 0 ?
                                                                <>
                                                                    {
                                                                        model.taxes.map((tax, key) =>
                                                                            <div key={key} className="d-flex flex-stack mb-3">
                                                                                <div className="fw-bold pe-10 text-gray-600 fs-7">
                                                                                    <a href="#" className="me-2" title="Remove Tax"
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            hub.Post(API_ROUTES.JobInvoices + "taxremove", {
                                                                                                id: model.id,
                                                                                                taxId: tax.id
                                                                                            }, res => {
                                                                                                if (res.success) modelSet(res.data)
                                                                                            })

                                                                                        }}>
                                                                                        <Icon icon="circle-minus" />
                                                                                    </a>
                                                                                    {
                                                                                        tax.amountType == AmountType.Percent ?
                                                                                            <>{tax.nameWithAmount}</>
                                                                                            : <>{tax.name}</>
                                                                                    }
                                                                                </div>
                                                                                <div className="text-end fw-bolder fs-6 text-gray-800">
                                                                                    {moneyFormatter.format(tax.amount ?? 0)}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                        }

                                                        <div className="d-flex flex-stack mb-3">
                                                            <a href="#" className="fs-8"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    addTaxSet(true);
                                                                    totalForTaxModalSet(model?.total ?? 0);
                                                                    taxAmountForTaxModalSet(0);

                                                                }}>
                                                                <Icon icon="plus" className="me-1 fs-8" />
                                                                Add Tax
                                                            </a>
                                                        </div>

                                                        <hr />

                                                        <div className="d-flex flex-stack">
                                                            <div className="fw-boldest pe-10 text-gray-800 fs-5">Total</div>
                                                            <div className="text-end fw-bolder fs-4 text-gray-800">
                                                                {moneyFormatter.format(model?.total ?? 0)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div className="m-0">
                                        <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 w-350px p-5 bg-lighten">


                                            {
                                                model?.issued ?
                                                    <FlexRow className="mb-2">

                                                    </FlexRow>
                                                    :
                                                    <FlexRow className="mb-2">
                                                        {
                                                            model?.confirmed ?
                                                                <>
                                                                    <a href="#" className="me-2"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            undoConfirmModalSet(true);
                                                                        }}>
                                                                        <Icon icon="undo" />
                                                                    </a>
                                                                    <span className="badge badge-success me-1">
                                                                        <Icon icon="check" className="me-2" />
                                                                        Approved
                                                                    </span>
                                                                    <a href="#" className="badge badge-light-success text-success"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            if (true) estToInvModalSet(true);
                                                                            else estToInvErrModalSet(true);
                                                                        }}>
                                                                        <Icon icon="file-invoice-dollar" className="me-2" />
                                                                        Create Invoice
                                                                    </a>
                                                                </>
                                                                :
                                                                <>
                                                                    <a href="#" className="badge badge-light-success text-success"
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            confirmModalSet(true);
                                                                        }}>
                                                                        <Icon icon="check" className="me-2" />
                                                                        Approve Estimate
                                                                    </a>
                                                                </>
                                                        }
                                                    </FlexRow>
                                            }

                                            {
                                                model?.sourceEstimateId && model.issued &&
                                                <div className="mb-2">
                                                    <a href={`/crm/jobs/invoices/${model.jobId}/view/${model.sourceEstimateId}`}
                                                        target="_blank" title={"Created from Estimate: " + model.sourceEstimateNumber}>
                                                        <span className="badge badge-light-primary me-2">
                                                            Created from Estimate {model.sourceEstimateNumber}
                                                        </span>
                                                    </a>
                                                </div>
                                            }

                                            {
                                                model?.lastSend &&
                                                <div className="mb-2">
                                                    <span className="badge badge-light-primary me-2">
                                                        <Icon icon="share" className="me-2" />
                                                        Sent at {model.lastSend.sendDate}
                                                    </span>
                                                </div>
                                            }

                                            <div className="mb-4 mt-8 fw-boldest text-gray-600">TECHNICIAN</div>
                                            <FlexRow className="mb-10">
                                                <div className="me-2 h-40px">
                                                    <Avatar avatarUrl={model?.tech?.avatarUrl} fullName={model?.tech?.fullName} width={40} />
                                                </div>
                                                <div>
                                                    <div className="fw-bolder mb-1">{model?.tech?.fullName}</div>
                                                    <FlexRow>
                                                        <Icon icon="phone-rotary" className="me-1 text-muted h-20px" size={12} type="solid" />
                                                        <span className="fs-8 text-muted h-20px">{model?.tech?.phoneNumber}</span>
                                                    </FlexRow>
                                                </div>

                                            </FlexRow>


                                            <FlexRow className="mb-3 ">
                                                <span className="fw-boldest text-gray-600">BILLING DETAILS</span>
                                                <a href="#" className="ms-2"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        updateBillingModalSet(true);
                                                    }}>
                                                    <Icon icon="edit" />
                                                </a>
                                            </FlexRow>

                                            <div className="mb-4">
                                                <div className="fw-bold text-gray-600 fs-7">Name:</div>
                                                <div className="fw-bolder text-gray-800 fs-6">{model?.billingName}</div>
                                            </div>
                                            <div className="mb-4">
                                                <div className="fw-bold text-gray-600 fs-7">Phone:</div>
                                                <div className="fw-bolder text-gray-800 fs-6">{model?.billingPhone?.phoneFriendly}</div>
                                            </div>
                                            <div className="mb-4">
                                                <div className="fw-bold text-gray-600 fs-7">Email:</div>
                                                <div className="fw-bolder text-gray-800 fs-6">
                                                    {
                                                        window.isNullOrEmpty(model?.billingEmail) ?
                                                            <a href="#" className="fs-7"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    updateBillingModalSet(true);
                                                                    setTimeout(() => {
                                                                        $("#txt-inv-email-address").focus();
                                                                    }, 500);
                                                                }}>
                                                                [Set Email Address]
                                                            </a>
                                                            :
                                                            <>{model?.billingEmail}</>
                                                    }
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <div className="fw-bold text-gray-600 fs-7">Address:</div>
                                                <div className="fw-bolder text-gray-800 fs-6">{model?.billingAddress}</div>
                                            </div>


                                            <FlexRow className="mb-3 mt-10">
                                                <span className="fw-boldest text-gray-600">ISSUED BY</span>
                                                <a href="#" className="ms-2"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        updateIssuedModalSet(true);
                                                    }}>
                                                    <Icon icon="edit" />
                                                </a>
                                            </FlexRow>

                                            <div className="mb-4">
                                                <div className="fw-bolder text-gray-800 fs-6 mb-1">{model?.issuedByName}</div>
                                                <div className="text-gray-800 fs-7 line-break">{model?.issuedByAddress}</div>
                                                <div className="text-gray-800 fs-7">{model?.issuedByPhone}</div>
                                            </div>

                                            <FlexRow justify="end" className="mt-10">
                                                <button type="button" className="btn btn-sm btn-light-primary btn-active-light-primary me-1"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        previewModalSet(true)
                                                    }}>
                                                    <Icon icon="eye" /> Preview
                                                </button>
                                                <button type="button" className="btn btn-sm btn-success me-1"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        sendInvoiceModalSet(true);
                                                        sendAllSet(false);
                                                    }}>
                                                    <Icon icon="share" /> Send
                                                </button>
                                                <div>
                                                    <button className="btn btn-sm btn-light btn-icon"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                        onClick={e => window.dataTableMenu()}>
                                                        <Icon icon="ellipsis-vertical" />
                                                    </button>
                                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4" data-kt-menu="true">
                                                        <div className="menu-item">
                                                            <a href={`${env.apiUrl}/base/public/job/invoice/download/${props.invoiceId}`} target="_blank" className="menu-link px-3">
                                                                <Icon icon="download" className="me-2" />
                                                                Download PDF
                                                            </a>
                                                        </div>
                                                        <div className="menu-item">
                                                            <a href="#" className="menu-link px-3 text-danger"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    deleteModalSet(true);
                                                                }}>
                                                                <Icon icon="trash-xmark" className="me-2" />
                                                                Delete {invoiceOrEstimate}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FlexRow>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

            {
                createEstimate &&
                <Modal id="modal-create-estimate"
                    title="Create Estimate"
                    open visible staticBackdrop
                    onClose={() => createEstimateSet(false)}
                    buttonText="Create Estimate"
                    onSubmit={(body, callback) => {

                        var valid = true;

                        if (window.isNullOrEmpty(body.techId)) {
                            window.formError("techId", "Please select the technician!", "modal-create-estimate");
                            valid = false;
                        }

                        if (!valid) {
                            if (callback) callback();
                            return;
                        }

                        loadingSet(true);

                        hub.Post(API_ROUTES.JobInvoices + "estimate", {
                            ...body,
                            jobId: props.job.id
                        }, res => {
                            if (res.success) {
                                if (callback) callback(res);
                                nav(`/crm/jobs/estimates/${props.job.id}/view/${res.data.id}`)
                            }
                            else toastError(res.message);

                            if (callback) callback(res);
                        });

                    }}>
                    <div>
                        <DropDown id="drp-techs-for-estimates" name="techId" apiSource={API_ROUTES.TagifyTechnicians} defaultValue={props.job.techId}
                            dataText="title" dataValue="value" label="Technician" labelUp parentModal="modal-create-estimate" />
                    </div>
                </Modal>
            }

            {
                createInvoice &&
                <Modal id="modal-create-invoice"
                    title="Create Invoice"
                    open visible staticBackdrop noHeader noFooter
                    onOpen={() => {
                        loadingSet(true);
                        hub.Post(API_ROUTES.JobInvoices + "invoice", {
                            jobId: props.job.id
                        }, res => {
                            if (res.success) {
                                window.closeModal("modal-create-invoice");
                                nav(`/crm/jobs/invoices/${props.job.id}/view/${res.data.id}`)
                                return;
                            }
                            else toastError(res.message);
                            window.closeModal("modal-create-invoice");
                        });
                    }}
                    onClose={() => {

                    }}>
                    <div className="my-20">
                        <Indicator text="Creating Invoice..." />
                    </div>
                </Modal>
            }

            {
                itemFormModal &&
                <Modal id="modal-add-invoice-item"
                    title={selectedItem ? "Edit Item" : "Add Item"}
                    buttonText={selectedItem ? "Update Item" : "Create Item"}
                    open visible staticBackdrop
                    createAnother={!selectedItem} createAnotherText="Add Another Item"
                    onCreateAnotherChanged={e => createAnotherSet(e)}

                    onClose={() => {
                        itemFormModalSet(false);
                        selectedItemSet(undefined);
                        selectedTaxSet(undefined);
                        taxIncludedSet(false);
                        createAnotherSet(false);
                    }}
                    onCancel={() => {
                        window.closeModal("modal-add-invoice-item");
                        if (!model?.created) {
                            hub.Delete(API_ROUTES.JobInvoices + model?.id, res => { });
                            nav(`/crm/jobs/${invoiceOrEstimates.toLowerCase()}/${props.job.id}`);
                        }
                    }}
                    onSubmit={(body, callback) => {

                        var valid = true;
                        var focus = true;

                        if (window.isNullOrEmpty(body.itemType)) {
                            window.formError("itemType", "Please select the item type!", "modal-add-invoice-item");
                            valid = false;
                        }

                        if (window.isNullOrEmpty(body.itemTitle)) {
                            window.formError("itemTitle", "Please write the item title!", "modal-add-invoice-item", focus);
                            valid = false;
                            focus = false;
                        }

                        if (window.isNullOrEmpty(body.amount)) {
                            window.formError("amount", "Please write the amount!", "modal-add-invoice-item", focus);
                            valid = false;
                            focus = false;
                        }

                        var qty = Number.parseInt(body.qty);
                        if (window.isNullOrEmpty(body.qty)) {
                            window.formError("qty", "Please write the quantity!", "modal-add-invoice-item", focus);
                            valid = false;
                            focus = false;
                        }
                        else if (isNaN(qty) || qty < 1) {
                            window.formError("qty", "The quantity must be greater than 0!", "modal-add-invoice-item", focus);
                            valid = false;
                            focus = false;
                        }

                        if (!valid) {
                            if (callback) callback();
                            return;
                        }

                        hub.Post(API_ROUTES.JobInvoices + "item", {
                            ...body,
                            id: props.invoiceId
                        }, res => {
                            if (res.success) {
                                fetchInvoice();
                                fetchInvoices();
                                fetchEstimates();
                                selectedItemSet(undefined);
                                if (createAnother) {
                                    resetSet(true);
                                    callback!();
                                    $("#txt-item-title").focus();
                                }
                                else {
                                    callback!(res);
                                }
                            }
                            else {
                                callback!();
                            }

                        })

                    }}>

                    {
                        selectedItem && <input type="hidden" name="row" value={selectedItem.row} />
                    }

                    <DropDown id="drp-invoice-item-type" name="itemType" source={ItemTypes} parentModal="modal-add-invoice-item"
                        label="Item Type" labelUp block marginBottom={3} defaultValue={selectedItem?.itemType?.toString()} />

                    <TextBox id="txt-item-title" name="itemTitle" label="Item Title" labelUp block marginBottom={3} value={selectedItem?.title} clear={reset} />

                    <TextBox name="amount" label="Amount" labelUp block money icon="dollar-sign"
                        marginBottom={3} value={selectedItem?.amount?.toString()} clear={reset}
                        onChange={e => {
                            var a = Number.parseFloat(e.target.value.replaceAll(",", ""));
                            if (isNaN(a)) a = 0;
                            itemAmountSet(a);
                        }} />

                    <TextBox name="qty" label="Qty" labelUp block numeric marginBottom={3}
                        value={selectedItem?.qty?.toString()} clear={reset} maxLength={5}
                        onChange={e => {
                            var q = Number.parseInt(e.target.value);
                            if (isNaN(q)) q = 0;
                            itemQtySet(q);
                        }} />

                    <TextArea name="description" label="Description" labelUp block rows={3} marginBottom={3} value={selectedItem?.description} clear={reset} />

                    <DropDown id="drp-item-tax" name="taxId" parentModal="modal-add-invoice-item" defaultValue={selectedItem?.tax?.id}
                        source={taxes} dataText="nameWithAmount" dataValue="id" block label="Tax" labelUp reset={reset}
                        onSelectItem={e => selectedTaxSet(e)} onClear={() => selectedTaxSet(undefined)} />

                    {
                        selectedTax && <ToggleSwitch name="taxIncluded" raw label="Tax is Included" small checked={selectedItem?.tax?.included}
                            onChange={e => taxIncludedSet(e)} />
                    }

                    <div className="mt-10">
                        <ItemTotalAmount />
                    </div>

                </Modal>
            }

            {
                deleteInvoiceItem &&
                <Modal id="modal-delete-invoice-item"
                    title="Delete Item"
                    buttonText="Delete Item"
                    buttonColor="danger"
                    open visible
                    onClose={() => {
                        deleteInvoiceItemSet(false);
                        selectedItemSet(undefined);
                    }}
                    onConfirm={callback => {

                        hub.Post(API_ROUTES.JobInvoices + "deleteitem", {
                            id: props.invoiceId,
                            row: selectedItem?.row
                        }, res => {
                            if (res.success) fetchInvoice();
                            callback();
                            selectedItemSet(undefined);
                        })
                    }}>

                    <div>
                        <p>
                            Do you want to delete this item?
                        </p>
                        <FlexRow className="p-5 bg-light-danger">
                            <div>
                                {selectedItem?.title}
                            </div>
                            <div className="ms-2">
                                ({moneyFormatter.format(selectedItem?.totalAmount ?? 0)})
                            </div>
                        </FlexRow>
                    </div>

                </Modal>
            }

            {
                addTax &&
                <Modal id="modal-addtax-invoice"
                    title={"Add Tax"}
                    buttonText={"Add Tax"}
                    buttonColor="primary"
                    open visible staticBackdrop
                    footerComponent={TaxAmountFooter}
                    onClose={() => {
                        addTaxSet(false);
                        selectedTaxSet(undefined);
                        taxIncludedSet(false);
                    }}
                    onSubmit={(body, callback) => {


                        hub.Post(API_ROUTES.JobInvoices + "tax", {
                            ...body,
                            id: model?.id
                        }, res => {
                            callback!(res);
                            if (res.success) modelSet(res.data)
                        })
                    }}>

                    <div>
                        <DropDown id="drp-invoice-tax" name="taxId" parentModal="modal-addtax-invoice"
                            source={taxes} dataText="nameWithAmount" dataValue="id" block label="Tax" labelUp
                            onSelectItem={e => selectedTaxSet(e)} onClear={() => selectedTaxSet(undefined)} />

                        {
                            selectedTax && <ToggleSwitch name="taxIncluded" raw label="Tax is Included" small checked={taxIncluded}
                                onChange={e => taxIncludedSet(e)} />
                        }
                    </div>

                </Modal>
            }

            {
                updateBillingModal &&
                <Modal id="modal-update-billinginfo"
                    title="Update Billing Info"
                    buttonText="Update Billing"
                    open visible staticBackdrop
                    onClose={() => updateBillingModalSet(false)}
                    onSubmit={(body, callback) => {

                        var valid = true;
                        var focus = true;

                        if (window.isNullOrEmpty(body.fullname)) {
                            window.formError("fullname", "Please write the billing name!", "modal-update-billinginfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (window.isNullOrEmpty(body.phone) || body.phone.length < 10) {
                            window.formError("phone", "Please write the billing phone!", "modal-update-billinginfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (window.isNullOrEmpty(body.email)) {
                            window.formError("email", "Please write the billing email!", "modal-update-billinginfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (window.isNullOrEmpty(body.address)) {
                            window.formError("address", "Please write the billing address!", "modal-update-billinginfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (!valid) {
                            callback!();
                            return;
                        }

                        hub.Post(API_ROUTES.JobInvoices + "billing", {
                            ...body,
                            id: props.invoiceId
                        }, res => {
                            if (res.success) {
                                modelSet(res.data);
                            }
                            callback!(res);
                        });

                    }}>
                    <TextBox id="txt-billing-name" name="fullname" block labelUp label="Billing Name" autoFocus value={model?.billingName} placeholder="Customer/Company Name" />
                    <PhoneInput name="phone" block labelUp label="Billing Phone" defaultValue={model?.billingPhone?.phone} />
                    <TextBox id="txt-inv-email-address" name="email" block labelUp label="Email Address" email value={model?.billingEmail} />
                    <TextBox name="address" block labelUp label="Billing Address" value={model?.billingAddress} />
                </Modal>
            }

            {
                updateIssuedModal &&
                <Modal id="modal-update-issuedinfo"
                    title="Update Company"
                    buttonText="Update Company"
                    open visible staticBackdrop
                    onClose={() => updateIssuedModalSet(false)}
                    onSubmit={(body, callback) => {

                        var valid = true;
                        var focus = true;

                        if (window.isNullOrEmpty(body.name)) {
                            window.formError("fullname", "Please write the name!", "modal-update-issuedinfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (window.isNullOrEmpty(body.phone) || body.phone.length < 9) {
                            window.formError("phone", "Please write the phone!", "modal-update-issuedinfo", focus);
                            focus = false;
                            valid = false;
                        }


                        if (window.isNullOrEmpty(body.address)) {
                            window.formError("address", "Please write the address!", "modal-update-issuedinfo", focus);
                            focus = false;
                            valid = false;
                        }

                        if (!valid) {
                            callback!();
                            return;
                        }

                        hub.Post(API_ROUTES.JobInvoices + "issueby", {
                            ...body,
                            id: props.invoiceId
                        }, res => {
                            if (res.success) {
                                modelSet(res.data);
                            }
                            callback!(res);
                        });

                    }}>
                    <TextBox id="txt-isseubyname" name="name" block labelUp label="Company Name" autoFocus value={model?.issuedByName} placeholder="Customer/Company Name" />
                    <PhoneInput id="txt-isseubyphone" name="phone" block labelUp label="Company Phone" defaultValue={model?.issuedByPhone} />
                    <TextArea name="address" block labelUp label="Company Address" value={model?.issuedByAddress} rows={5} />
                </Modal>
            }

            {
                sendInvoiceModal &&
                <Modal id="modal-send-invoice"
                    title={sendAll ? "Send All " + invoiceOrEstimates : "Send " + invoiceOrEstimate}
                    buttonText={sendAll ? "Send All " + invoiceOrEstimates : "Send " + invoiceOrEstimate}
                    buttonColor="success"
                    open visible staticBackdrop
                    onClose={() => {
                        sendInvoiceModalSet(false);
                        sendAllSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        var ids = "";
                        if (sendAll && !model?.issued) {
                            estimates?.forEach(e => {
                                if (ids != "") ids += ",";
                                ids += e.id
                            });
                        }
                        else if (sendAll && model?.issued) {
                            invoices?.forEach(e => {
                                if (ids != "") ids += ",";
                                ids += e.id
                            });
                        }
                        else ids = props.invoiceId;

                        hub.Post(API_ROUTES.JobInvoices + "sendinvoice", {
                            ...body,
                            ids,
                            sendTo: sendTo,
                            jobId: props.job.id
                        }, res => {
                            callback!(res);
                            fetchInvoice();
                            fetchInvoices();
                            fetchEstimates();
                        })
                    }}>

                    <div>

                        <FlexRow className="mb-5">
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start me-5 px-6 py-4 ${(sendTo == "email" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "email"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("email")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Email
                                    </span>
                                </span>
                            </label>
                            <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start px-6 py-4 ${(sendTo == "phone" ? "active" : "")}`}>
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start">
                                    <input className="form-check-input" type="radio" checked={sendTo == "phone"}
                                        onChange={e => {
                                            if (e.target.checked) sendToSet("phone")
                                        }} />
                                </span>
                                <span className="ms-2">
                                    <span className="fs-7 fw-bold text-gray-800 d-block">
                                        Send To Phone
                                    </span>
                                </span>
                            </label>
                        </FlexRow>

                        {
                            sendTo == "email" &&
                            <TextBox id="txt-invoice-send-email" key="txt-invoice-send-email" name="emails" email label="Email Address"
                                labelUp block autoFocus value={props.job.emailAddress ?? model?.billingEmail} />
                        }

                        {
                            sendTo == "phone" &&
                            <PhoneInput id="txt-invoice-send-phone" key="txt-invoice-send-phone" name="phone" label="Phone Number"
                                labelUp block autoFocus defaultPhone={props.job.phone} />
                        }


                    </div>

                </Modal>
            }

            {
                confirmModal &&
                <Modal id="modal-confirm-invoice"
                    title={"Approve Estimate " + model?.number}
                    buttonText="Approve"
                    buttonColor="success"
                    open visible
                    onClose={() => {
                        confirmModalSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.JobInvoices + "confirmestimate", {
                            ...body,
                            jobId: props.job.id,
                            id: model?.id
                        }, res => {
                            fetchInvoice();
                            fetchEstimates();
                            callback!(res);
                        })

                    }}>

                    <div>
                        Are you sure you want to mark the selected estimate as Approved?
                    </div>

                </Modal>
            }

            {
                undoConfirmModal &&
                <Modal id="modal-undoconfirm-invoice"
                    title={"Undo Approve Estimate " + model?.number}
                    buttonText="Undo Approve"
                    buttonColor="warning"
                    open visible
                    onClose={() => {
                        undoConfirmModalSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.JobInvoices + "undoconfirmestimate", {
                            ...body,
                            jobId: props.job.id,
                            ids: model?.id
                        }, res => {
                            fetchInvoice();
                            fetchEstimates();
                            callback!(res);
                        })
                    }}>

                    <div>
                        Are you sure you want to unapprove the selected estimates?
                    </div>

                </Modal>
            }

            {
                deleteModal &&
                <Modal id="modal-delete-invoice"
                    title={`Delete ${invoiceOrEstimate}`}
                    buttonText={`Delete ${invoiceOrEstimate}`}
                    buttonColor="danger"
                    open visible
                    onClose={() => {
                        deleteModalSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Delete(API_ROUTES.JobInvoices + model?.id, res => {
                            callback!(res);
                            if (model?.issued) nav("/crm/jobs/invoices/" + model?.jobId);
                            else nav("/crm/jobs/estimates/" + model?.jobId);
                        })

                    }}>

                    <div>
                        Are you sure you want to delete the {invoiceOrEstimate.toLowerCase()}?
                    </div>

                </Modal>
            }

            {
                estToInvErrModal &&
                <Modal id="modal-estto-invoice-err"
                    title="Create Invoice"
                    open visible
                    onClose={() => {
                        estToInvErrModalSet(false);
                    }}
                    cancelButton cancelText="Ok">

                    <div className="alert alert-warning">
                        You should close the job before creating invoice!
                    </div>

                </Modal>
            }

            {
                estToInvModal &&
                <Modal id="modal-estimate-to-invoice"
                    title={`Create Invoice`}
                    buttonText={`Create Invoice`}
                    buttonColor="success"
                    open visible
                    onClose={() => {
                        estToInvModalSet(false);
                    }}
                    onSubmit={(body, callback) => {

                        hub.Post(API_ROUTES.JobInvoices + "esttoinv", {
                            id: model?.id
                        }, res => {
                            callback!(res);
                            if (res.success) {
                                nav("/crm/jobs/invoices/" + model?.jobId + "/view/" + res.data.id);
                            }
                        })

                    }}>

                    <div>
                        Do you want to create an invoice based on the selected estimate?
                    </div>

                </Modal>
            }

            {
                previewModal &&
                <Modal id="modal-preview-invoice"
                    open visible size="XLarge"
                    title={`Preview ${invoiceOrEstimate} ${model?.number}`}
                    onClose={() => previewModalSet(false)}
                    buttonText="Download PDF"
                    confirmLink={`${env.apiUrl}/base/public/job/invoice/download/${props.invoiceId}`}>
                    <iframe src={`${env.apiUrl}/base/public/job/invoice/preview/${props.invoiceId}`}
                        style={{
                            width: "100%",
                            height: (globalState.windowsHeight ?? 800) - 200
                        }}>
                    </iframe>
                </Modal>
            }

        </div>
    )
}
