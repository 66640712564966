import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import IconButton from "../../../../../components/base/IconButton";
import Job from "../../models/Job";
import { hasRole, openEditJobTech, openSendJob, useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";
import Timer from "../../../../../components/timer/Timer";

interface IProps {
    job: Job
}

export default function JobDrawerTech(props: IProps) {

    const hub = useHub()
    const [jobSentColor, jobSentColorSet] = useState<string>("success")
    const [sentPassCodeRefreshing, sentPassCodeRefreshingSet] = useState<boolean>(false)
    const [sentPassSecondaryCodeRefreshing, sentPassSecondaryCodeRefreshingSet] = useState<boolean>(false)
    const [proxyLine, proxyLineSet] = useState<string>()

    useEffect(() => {
        hub.Get(API_ROUTES.Jobs + "sendsettings/" + props.job.id, res => {
            proxyLineSet(res.data.proxyLine);
        })
    }, [])


    return (
        <div>
            <FlexRow className="mb-3">
                <div>
                    <div className="text-muted fs-8">
                        <Icon icon="user-helmet-safety" size={10} className="me-2" type="solid" />
                        Tech
                    </div>
                    <FlexRow>
                        <div className="ps-5">
                            {props.job.tech?.fullName ?? "-"}
                        </div>
                        {
                            props.job.sentToTech && !props.job.techAccepted && !props.job.isClosed && !props.job.isCanceled &&
                            <FlexRow className={"ms-2 text-" + jobSentColor}>
                                <Icon icon="timer" className="me-1" />
                                <Timer baseDate={props.job.sentToTechAt}
                                    onTick={t => {
                                        jobSentColorSet(t < 60 ? "success" : t < 120 ? "warning" : "danger")
                                    }} />
                            </FlexRow>
                        }
                        {
                            props.job.tech && props.job.techAccepted &&
                            <FlexRow title="Technician has accepted the job!" className="ms-2">
                                <Icon icon="check-circle" className="text-success me-1" type="solid" />
                                <span className="text-success fs-9">Accepted</span>
                            </FlexRow>
                        }
                    </FlexRow>
                </div>
                <div className="flex-grow-1">
                    <FlexRow justify="end" >
                        {
                            props.job.sentToTech && !props.job.techAccepted && !props.job.isClosed && !props.job.isCanceled &&
                            <>
                                <IconButton icon="times" size={14} type="regular"
                                    title="Tech rejected the job!"
                                    className="text-danger"
                                    onClick={e => {
                                        hub.Post(API_ROUTES.Jobs + "techresponse", {
                                            id: props.job?.id,
                                            action: "cancel"
                                        }, res => { })
                                    }} />
                                <IconButton icon="check" size={14} type="regular"
                                    title="Tech accepted the job!"
                                    className="text-success"
                                    onClick={e => {
                                        hub.Post(API_ROUTES.Jobs + "techresponse", {
                                            id: props.job?.id,
                                            action: "accept"
                                        }, res => { })
                                    }} />
                            </>
                        }


                        {
                            (!props.job.isArchived || hasRole("EditArchivedJobs")) &&
                            <>
                                <IconButton icon="share" size={14}
                                    type={props.job.sentToTech ? "solid" : "regular"}
                                    className={props.job.sentToTech ? "text-primary" : ""}
                                    title={props.job.sentToTech ? "Resend Job" : "Send Job"}
                                    onClick={e => {
                                        openSendJob(props.job)
                                    }} />
                                <IconButton icon="edit" size={14} type="solid" title="Edit Technician"
                                    onClick={e => {
                                        openEditJobTech(props.job)
                                    }} />
                            </>
                        }
                    </FlexRow>
                </div>

            </FlexRow>

            <div className="row">
                <div className="col-md-4">
                    <div>
                        <div className="text-muted fs-8">
                            <Icon icon="map" size={10} className="me-2" type="solid" />
                            Metro
                        </div>
                        <div className="ps-5">
                            {props.job.metroName ?? "-"}
                        </div>
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="text-muted fs-8">
                        <Icon icon="phone-office" size={10} className="me-2" type="solid" />
                        Extension
                    </div>
                    {
                        props.job.isArchived || props.job.isCanceled || props.job.isClosed ?
                            <div className="ps-5">-</div>
                            :
                            <FlexRow className="ps-5">
                                <div className="text-gray-600 fs-7 pe-2">{proxyLine},</div>
                                {props.job.sentPasscode}
                                <a href="#" className="ms-2" style={{ color: "#7e8299" }}
                                    onClick={e => {
                                        e.preventDefault()
                                        if (sentPassCodeRefreshing) return;
                                        sentPassCodeRefreshingSet(true)
                                        hub.Post(API_ROUTES.Jobs + "refreshsentpasscode", {
                                            id: props.job.id
                                        }, res => {
                                            sentPassCodeRefreshingSet(false)
                                        })
                                    }}>
                                    <Icon icon="refresh" spin={sentPassCodeRefreshing} />
                                </a>
                            </FlexRow>
                    }
                </div>

            </div>

            {
                props.job.phoneContact?.phone &&
                <div className="row mt-2">
                    <div className="col-md-4">

                    </div>

                    <div className="col-md-8">
                        <div className="text-muted fs-8">
                            <Icon icon="phone-office" size={10} className="me-2" type="solid" />
                            Secondary Phone
                        </div>
                        {
                            props.job.isArchived || props.job.isCanceled || props.job.isClosed ?
                                <div className="ps-5">-</div>
                                :
                                <FlexRow className="ps-5">
                                    <div className="text-gray-600 fs-7 pe-2">{proxyLine},</div>
                                    {props.job.secondarySentPasscode}
                                    <a href="#" className="ms-2" style={{ color: "#7e8299" }}
                                        onClick={e => {
                                            e.preventDefault()
                                            if (sentPassCodeRefreshing) return;
                                            sentPassSecondaryCodeRefreshingSet(true)
                                            hub.Post(API_ROUTES.Jobs + "refreshsecondsentpasscode", {
                                                id: props.job.id
                                            }, res => {
                                                sentPassSecondaryCodeRefreshingSet(false)
                                            })
                                        }}>
                                        <Icon icon="refresh" spin={sentPassSecondaryCodeRefreshing} />
                                    </a>
                                </FlexRow>
                        }
                    </div>

                </div>
            }


        </div>
    )
}