import React from "react"

type MenuAreaProps = {
    title?: string,
    children?: any,
    visible?: boolean
}

function MenuArea(props: MenuAreaProps) {
	
  return (
    <>
      {
        props.visible &&
        <>
          <div className="menu-item">
              <div className="menu-content pb-2">
                  <span className="menu-section text-muted text-uppercase fs-8 ls-1">{props.title}</span>
              </div>
          </div>
          {props.children}
        </>
      }
    </>
  );
}

export default MenuArea;
