import Job from "../../modules/crm/jobs/models/Job"
import FlexRow from "../base/FlexRow"
import Icon from "../base/Icon"
import JobMetro from '../../modules/crm/base/models/JobMetro';
import DropdownMenu from "../menu/DropdownMenu";
import { openCopyJob, openJobQuickView, useHub } from "../../core/Global";
import { API_ROUTES } from "../../core/Constants";
import { toastDefault } from "../base/Toast";
import IconButton from "../base/IconButton";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../core/Helper";


interface IProps {
    job: Job
    onOpenJob: () => void
}

export default function SearchJobItem(props: IProps) {

    const hub = useHub()
    const navigate = useNavigate()

    return (
        <div>
            <FlexRow className="mb-1">
                <a href="#" className="fs-7  text-gary-600"
                    onClick={e => {
                        e.preventDefault()
                        openJobQuickView(props.job.id)
                    }}>
                    #{props.job.jobId}
                </a>

                <FlexRow className="ms-auto">
                    <div>
                        <button type="button" title="Copy Job"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                            className={"btn btn-sm btn-white btn-icon"}
                            style={{
                                borderRadius: "50%"
                            }}>
                            <Icon icon="copy" type="regular" size={15} />
                        </button>
                        <DropdownMenu width={200}
                            source={[
                                {
                                    title: "Copy with Direct Number",
                                    icon: "phone-arrow-down-left",
                                    async click(e) {
                                        e.preventDefault();
                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=True")
                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                        if (!copy) openCopyJob(props.job);
                                    },
                                },
                                {
                                    title: "Copy with Extension",
                                    icon: "phone-office",
                                    async click(e) {
                                        e.preventDefault();
                                        var res = await hub.GetAsync(API_ROUTES.Jobs + "copyjob/" + props.job.id + "/?directNumber=False")
                                        var copy = await copyToClipboard(res.data, "Job has been copied to the clipboard!");
                                        if (!copy) openCopyJob(props.job);
                                    }
                                }
                            ]} />
                    </div>
                    <IconButton icon="arrow-up-right-from-square" size={15} title="View Job Details" type="regular"
                        onClick={e => {
                            navigate(`/crm/jobs/overview/${props.job.id}`)
                            props.onOpenJob()
                        }} />
                </FlexRow>
            </FlexRow>

            <FlexRow className="mb-1">
                <Icon icon="circle" type="solid" size={12} className="me-1" color={props.job.jobStatus?.color} />
                <span className="fs-8 me-5">{props.job.jobStatus?.name}</span>
            </FlexRow>
            <FlexRow className="mb-1">



                <Icon icon="user" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.fullName}</span>

                <Icon icon="phone-rotary" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.phone?.phoneFriendly}</span>

            </FlexRow>
            <FlexRow className="mb-1">
                <Icon icon="location-dot" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8">{props.job.address?.address?.label}</span>
            </FlexRow>
            <FlexRow className="mb-1">
                <Icon icon="wrench" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.jobType}</span>

                <Icon icon="bullhorn" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.jobSource}</span>
            </FlexRow>
            <FlexRow className="mb-1">
                <Icon icon="user-helmet-safety" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.tech?.fullName ?? "-"}</span>

                <Icon icon="map" type="solid" size={12} className="text-gray-600 me-1" />
                <span className="fs-8 me-5">{props.job.metroName ?? "-"}</span>
            </FlexRow>

        </div>
    )
}