import { useEffect, useState } from "react";
import FlexRow from "../../../../../components/base/FlexRow";
import Icon from "../../../../../components/base/Icon";
import Modal from "../../../../../components/base/Modal";
import Timer from "../../../../../components/timer/Timer";
import Job from "../../models/Job";
import DropDown from "../../../../../components/form/DropDown";
import DataItem from "../../../../../models/DataItem";
import { useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";


interface IProps {
    job?: Job
    onClose?: () => void
}

export default function JobSendTechRespondModal(props: IProps) {

    const hub = useHub();

    const [responseOptions, responseOptionsSet] = useState<DataItem[]>([
        {
            name: "Technician has accepted the job!",
            id: "accept",
            color: "text-success"
        },
        {
            name: "Technician didn't accepted the job!",
            id: "cancel",
            color: "text-danger"
        }
    ])
    const [jobIsSent, jobIsSentSet] = useState<boolean>(false);
    const [jobSentColor, jobSentColorSet] = useState<string>("success");

    useEffect(() => {

        jobIsSentSet(props.job?.sentToTech ?? false);
        // if (props.job) {
        //     if (props.job.sentToTech && props.job.sentToTechTotalSeconds && props.job.sentToTechTotalSeconds > 0) {

        //         jobSentColorSet(props.job.sentToTechTotalSeconds < 60 ? "success" : props.job.sentToTechTotalSeconds < 120 ? "warning" : "danger");
        //     }
        //     else {
        //         jobIsSentSet(false);
        //     }
        // }

    }, [props.job])

    return (
        <Modal id="job-send-manage-tech-response"
            title="Technician Response"
            subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
            visible open
            buttonText="Update"
            onClose={props.onClose}
            onSubmit={(body, callback) => {

                window.hideFormErrors();

                if (body.action != "accept" && body.action != "cancel") {
                    window.showFormError("action", "Please choose the technician response!", true, "job-send-manage-tech-response");
                    if (callback) callback();
                    return;
                }

                hub.Post(API_ROUTES.Jobs + "techresponse", {
                    ...body,
                    id: props.job?.id
                }, res => {
                    if (res.success) {

                    }
                    if (callback) callback(res);
                })

            }}>
            <div>
                <FlexRow>
                    Job has been sent to {props.job?.tech?.fullName}
                    <FlexRow className={"fs-6 ms-auto text-" + jobSentColor}>
                        <Icon icon="timer" className="me-1" />
                        <Timer baseDate={props.job?.sentToTechAt}
                            onTick={t => {
                                jobSentColorSet(t < 60 ? "success" : t < 120 ? "warning" : "danger");
                            }} />
                    </FlexRow>
                </FlexRow>

                <hr />

                <DropDown id="drp-tech-responses" name="action" source={responseOptions}
                    label="Technician Response" labelUp parentModal="job-send-manage-tech-response">
                </DropDown>

            </div>
        </Modal>
    )
}