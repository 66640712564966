import React, { useEffect } from "react"
import { getOwner, openUpdateEnvironment, setDarkMode, setUserToken, useGlobal, useHub } from '../../../core/Global';
import { Link } from 'react-router-dom';
import { UserTypes } from "../../../core/Enums";
import Hub from "../../../api/Hub";
import FlexRow from "../../base/FlexRow";
import ToggleSwitch from "../../form/ToggleSwitch";
import { SetThemeToDark, SetThemeToLight } from "../../../core/Metronic";
import Icon from "../../base/Icon";

function UserMenu() {
    const [globalState] = useGlobal()
    const hub = useHub()
    const owner = getOwner()

    useEffect(() => {
        window.KTMenuCreate()
    }, [])

    return (
        <div>

            <div className="cursor-pointer symbol symbol-30px bg-danger" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                {
                    globalState.user?.avatarUrl ?
                        <img src={globalState.user?.avatarUrl} />
                        :
                        <div className={`text-white`} style={{
                            paddingTop: 0,
                            textTransform: "capitalize",
                            fontSize: 20,
                            fontWeight: 400,
                            width: 34,
                            height: 34,
                            textAlign: "center"
                        }}>
                            {globalState.user?.fullName?.substring(0, 1)}
                        </div>
                }
            </div>

            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                <div className="menu-item">
                    <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px me-5 bg-danger">
                            {
                                globalState.user?.avatarUrl ?
                                    <img src={globalState.user?.avatarUrl} />
                                    :
                                    <div className={`text-white`} style={{
                                        paddingTop: 2,
                                        textTransform: "capitalize",
                                        fontSize: 30,
                                        width: 50,
                                        height: 50,
                                        textAlign: "center"
                                    }}>
                                        {globalState.user?.fullName?.substring(0, 1)}
                                    </div>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <div className="fw-bolder d-flex align-items-center fs-7">
                                {globalState.user?.firstName + " " + globalState.user?.lastName}
                            </div>
                            <a href="#" className="fw-bold text-muted text-hover-primary fs-8">
                                {owner?.companyName}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="separator my-2"></div>
                {
                    globalState.user?.isSystemAdmin &&
                    <div className="menu-item px-5">
                        <a href="#" className="menu-link px-5"
                            onClick={e => {
                                e.preventDefault()
                                openUpdateEnvironment()
                            }}>
                            Change Workspace
                        </a>
                    </div>
                }

                <div className="menu-item px-5">
                    <Link to="/profile/preferences" className="menu-link px-5">Preferences</Link>
                </div>
                <div className="menu-item px-5">
                    <Link to="/profile/notifications" className="menu-link px-5">Notifications</Link>
                </div>
                <div className="menu-item px-5">
                    <Link to="/profile/security" className="menu-link px-5">Security</Link>
                </div>
                <div className="separator my-2"></div>
                <div className="menu-item px-5">
                    <FlexRow className="px-3 mt-2">
                        <span className="me-3">
                            <Icon icon="moon" type="solid" />
                        </span>
                        <span>
                            Dark Mode
                        </span>
                        <div className="ms-auto">
                            <ToggleSwitch small raw checked={globalState.darkMode}
                                onChange={e => {
                                    if (e) SetThemeToDark()
                                    else SetThemeToLight()
                                    setDarkMode(e)
                                }} />
                        </div>
                    </FlexRow>
                </div>
                <div className="menu-item px-5">
                    <FlexRow className="px-3 mt-5 text-danger"
                        onClick={e => {
                            e?.preventDefault();
                            window.openModal("modal-signout")
                        }}>
                        <span className="me-3">
                            <Icon icon="sign-out" type="solid" />
                        </span>
                        <span>
                            Sign Out
                        </span>
                    </FlexRow>
                </div>

            </div>
        </div>
    );
}

export default UserMenu;