import { useEffect, useState } from "react";
import Job from "../../models/Job";
import Modal from "../../../../../components/base/Modal";
import { useEnvironment, useHub } from "../../../../../core/Global";
import { API_ROUTES } from "../../../../../core/Constants";


interface IProps {
    job?: Job
    onClose?: () => void
}
export default function JobArchiveModal(props: IProps) {

    const hub = useHub();
    const env = useEnvironment();



    return (
        <>
            <Modal id="modal-archive-job"
                title="Archive Job"
                subtitle={"Job ID: " + props.job?.jobId + " | " + props.job?.fullName}
                buttonText="Archive Job"
                buttonColor="secondary"
                visible open
                onClose={props.onClose}
                onSubmit={(body, callback) => {

                    hub.Post(API_ROUTES.Jobs + "archivejob", {
                        ...body,
                        id: props.job?.id
                    }, res => {
                        if (callback) callback(res);
                    });

                }}
            >
                <div>
                    Are you sure you want to archive this job?
                </div>
            </Modal>

        </>
    )

}