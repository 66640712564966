import { useState } from "react";
import Button from "../../../components/base/Button";
import Div from "../../../components/base/Div";
import { DataTable, DataTableField } from "../../../components/data/DataTable";
import TextBox from "../../../components/form/TextBox";
import { API_ROUTES } from "../../../core/Constants";
import { useHub } from "../../../core/Global";
import User from "../models/User";
import UserGroup from "../models/UserGroup";
import Icon from "../../../components/base/Icon";



interface IProps {
    model: UserGroup
}
export default function UserGroupOverview(props: IProps) {


    const hub = useHub();
    const [working, workingSet] = useState<boolean>(false);




    const UserGroupsField = (data: User) => (
        <div>
            <span className="badge badge-primary me-2">
                {props.model.name}
            </span>
            {
                data.groupNames?.map(g =>
                    <span key={`group-badge-${g}`} className="badge badge-secondary me-2">
                        {g}
                    </span>
                )
            }
        </div>
    )

    return (
        <div className="row">
            <div className="col col-12 mb-5">
                <div className="card">
                    <div className="card-header border-0">
                        <h3 className="card-title">
                            <span className="card-label fw-bolder text-dark">Group Name</span>
                        </h3>
                        <div className="card-action">
                            <button type="submit" className="btn btn-sm btn-primary" disabled={working}>
                                {
                                    working ?
                                        <>
                                            <Icon icon="spinner-third" spin />
                                            <span className="ms-2">Updating...</span>
                                        </>
                                        :
                                        <>
                                            Update Group
                                        </>
                                }
                            </button>
                        </div>
                    </div>
                    <div className="card-body border-top pt-9 pb-0"
                        style={{
                            maxWidth: 800
                        }}>
                        <TextBox name="name" block value={props.model.name} />
                    </div>
                </div>
            </div>
            <div className="col col-12">
                <div className="card">
                    <div className="card-header border-0">
                        <h3 className="card-title">
                            <span className="card-label fw-bolder text-dark">Users in Group</span>
                        </h3>
                    </div>
                    <div className="card-body border-top pt-9 pb-0">

                        <DataTable noHeader noAction noFilter preventCreate objectName="User" preventLinkUpdate
                            apiRoute={API_ROUTES.Users + "ingroup/" + props.model.id}>

                            <DataTableField title="name" dataValueName="fullName" />
                            <DataTableField title="username" dataValueName="username" />
                            <DataTableField title="email" dataValueName="email" />
                            <DataTableField title="type" dataValueName="userTypeName" />


                        </DataTable>

                    </div>
                </div>
            </div>
        </div>
    )
}