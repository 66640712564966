import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import Environment from "../../../env/env";
import UserGroup from "../models/UserGroup";
import { DataForm, DataTable, DataTableCustomActions, DataTableField } from "../../../components/data/DataTable";
import Hub from "../../../api/Hub";
import { API_ROUTES, ROLES } from '../../../core/Constants';
import { UserGroupValidator } from '../validators/UserGroupValidator';
import { setPage, setCreateButton, hasRole, useHub, getUser } from '../../../core/Global';
import Icon from '../../../components/base/Icon';
import { Link } from 'react-router-dom';
import Section from "../../../components/base/Section";
import Modal from "../../../components/base/Modal";
import Tagify from "../../../components/tagify/Tagify";
import DropDown from "../../../components/form/DropDown";
import TextBox from "../../../components/form/TextBox";
import FlexRow from "../../../components/base/FlexRow";
import { toastSuccess } from "../../../components/base/Toast";

const hub = new Hub<UserGroup>(API_ROUTES.UsersGroups);

function UsersGroupsList() {

  const hub = useHub();
  const user = getUser();

  const [refresh, refreshSet] = useState<boolean>();
  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }
  }, [refresh])

  const [formModal, formModalSet] = useState<boolean>(false);
  const [selectedItem, selectedItemSet] = useState<UserGroup>();

  const RolesAction = (data: any) => (
    <div className="menu-item">
      <Link to={"/accounts/roles/group/" + data.id} className="menu-link px-3">
        <Icon icon="lock" className="me-2" />
        Roles
      </Link>
    </div>
  )

  const NameItem = (data: any) => (
    <FlexRow>
      <Link to={`/accounts/usergroup/${data.id}`}>
        {data.name}
      </Link>
    </FlexRow>
  )


  const UserItem = (data: UserGroup) => (
    <div>
      <div>
        {data.ownerUser?.fullName}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <span>
            @{data.ownerUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: UserGroup) => (
    <div>
      <div className='d-flex align-items-center'>
        {data.createDate}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("User Groups", "Account");
  }, [])
  return (
    <Layout wide>

      <Section fluid>
        <DataTable
          objectName="Group"
          refresh={refresh}
          formValidator={UserGroupValidator}
          apiRoute={API_ROUTES.UsersGroups}
          ascending delete edit
          addCustom={() => {
            selectedItemSet(undefined);
            formModalSet(true);
          }}
          editCustom={data => {
            selectedItemSet(data);
            formModalSet(true);
          }}
        >
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Assigned" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>
          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>
          <DataTableCustomActions>
            <RolesAction />
          </DataTableCustomActions>
        </DataTable>
      </Section>



      {
        formModal &&
        <Modal id="modal-form-usergroup"
          title={selectedItem ? "Edit Group" : "New Group"}
          open visible staticBackdrop
          buttonText={selectedItem ? "Update Group" : "Create Group"}
          onClose={() => {
            formModalSet(false);
            selectedItemSet(undefined);
          }}
          onSubmit={(body, callback) => {

            var valid = true;

            if (user?.isSuperAdmin) {
              if (window.isNullOrEmpty(body.ownerUserId)) {
                window.formError("ownerUserId", "Please choose the assignee user!", "modal-form-usergroup");
                valid = false;
              }
            }

            if (!body.name || body.name == "" || body.name.length < 2) {
              window.formError("name", "Please write a valid name!", "modal-form-usergroup");
              valid = false;
            }

            if (!valid) {
              if (callback) callback();
              return
            }

            if (selectedItem) {

              hub.Put(API_ROUTES.UsersGroups + selectedItem.id, body, res => {
                if (res.success) {
                  refreshSet(true);
                  toastSuccess("Group has been saved successfully!");
                }
                if (callback) callback(res);
              })

            }
            else {

              hub.Post(API_ROUTES.UsersGroups, body, res => {
                if (res.success) {
                  refreshSet(true);
                  toastSuccess("Group has been saved successfully!");
                }
                if (callback) callback(res);
              })

            }



          }}>
          <div>
            {
              user?.isSuperAdmin &&
              <>
                <div className="fs-7 fw-bolder">
                  Assign to
                  <span id="ownerUserId-err" className="small text-danger mx-2 form-error"></span>
                </div>
                <Tagify name="ownerUserId" id="drp-ownerUserId" placeholder="Select a Customer"
                  apiUrl={API_ROUTES.TagifyCustomers} single block />
              </>
            }

            <TextBox id="txt-usergroupform-name" name="name"
              labelUp label="Group Name" block autoFocus value={selectedItem?.name}
              placeholder="E.g. Administrators, Managers, Agents, etc" />

          </div>
        </Modal>
      }


    </Layout>
  );
}

export default UsersGroupsList;
