import { useEffect, useState } from "react"
import { hasRole, setPage, toPrettyDate, useHub, validateRole } from "../../../../core/Global"
import filteringProps from "../filtering/announcements.json";
import sortingProps from "../sorting/announcements.json";
import Layout from "../../../../components/layouts/Layout";
import Section from "../../../../components/base/Section";
import { DataTable, DataTableCustomActions, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from "../../../../core/Constants";
import Announcement from "../models/Announcement";
import FlexRow from "../../../../components/base/FlexRow";
import Icon from "../../../../components/base/Icon";
import AnnouncementForm from "../components/AnnouncementForm";
import AnnouncementView from "../components/AnnouncementView";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";
import Modal from "../../../../components/base/Modal";



export default function AnnouncementsList() {


    const hub = useHub();
    const [selectedItem, selectedItemSet] = useState<Announcement>();
    const [createModal, createModalSet] = useState<boolean>(false);
    const [editModal, editModalSet] = useState<boolean>(false);
    const [deleteModal, deleteModalSet] = useState<boolean>(false);
    const [publishModal, publishModalSet] = useState<boolean>(false);
    const [archiveModal, archiveModalSet] = useState<boolean>(false);
    const [refresh, refreshSet] = useState<boolean>(false);

    useEffect(() => {
        validateRole("ManageAnnouncements")
        setPage("Announcements", "System");
    }, [])

    useEffect(() => {
        if (refresh) {
            setTimeout(() => {
                refreshSet(false)
            }, 500);
        }
    }, [refresh])

    const AnnouncementCol = (data: Announcement) => <AnnouncementView item={data} />

    const ShowToCol = (data: Announcement) => (
        <div className="fs-7">
            {data.showToAll && <div>Everyone</div>}
            {data.showToAdmins && <div>Admins</div>}
            {data.showToAgents && <div>Agents</div>}
            {data.showToTechs && <div>Technicians</div>}
        </div>
    )

    const StatusCol = (data: Announcement) => (
        <div className="fs-7">
            {
                data.isArchived ?
                    <span className="badge badge-secondary">Archived</span>
                    :
                    <>
                        {
                            data.isActive ?
                                <span className="badge badge-success">Published</span>
                                :
                                <span className="badge badge-warning">Draft</span>
                        }

                    </>
            }
        </div>
    )

    const CreatedItem = (data: Announcement) => (
        <div>
            <div className='d-flex align-items-center'>
                {toPrettyDate(data.createdAt)}
            </div>
            {
                data.creatorUser &&
                <FlexRow className='fs-8 mt-1'>
                    <div>
                        <Icon icon="user" className="me-2" size={10} type="solid" />
                    </div>
                    <div className="fs-8">
                        By: {data.creatorUser.fullName}
                    </div>
                </FlexRow>
            }

        </div>
    )

    const PublishOrArchiveAction = (data: Announcement) => (
        <>
            {
                !data.isActive ?
                    <div className="menu-item">
                        <a href="#" className="menu-link px-3 text-success"
                            onClick={e => {
                                e.preventDefault();
                                selectedItemSet(data);
                                publishModalSet(true);
                            }}
                        >
                            <Icon icon="up-from-bracket" className="me-2 text-success" />
                            Publish
                        </a>
                    </div>
                    :
                    <div className="menu-item">
                        <a href="#" className="menu-link px-3"
                            onClick={e => {
                                e.preventDefault();
                                selectedItemSet(data);
                                archiveModalSet(true);
                            }}
                        >
                            <Icon icon="box-archive" className="me-2" />
                            Archive
                        </a>
                    </div>
            }
        </>
    )

    return (
        <Layout wide>
            <Section fluid>

                <DataTable
                    objectName="Announcement"
                    apiRoute={API_ROUTES.Announcements}
                    filteringProps={filteringProps}
                    sortingProps={sortingProps}
                    addCustom={() => createModalSet(true)}
                    edit delete={hasRole("DeleteTags")}
                    editCustom={data => {
                        selectedItemSet(data);
                        editModalSet(true);
                    }}
                    deleteCustom={data => {
                        selectedItemSet(data);
                        deleteModalSet(true);
                    }}
                    refresh={refresh}>


                    <DataTableField title="Announcement">
                        <AnnouncementCol />
                    </DataTableField>

                    <DataTableField title="" width={30}>
                        <div></div>
                    </DataTableField>

                    <DataTableField title="Show To">
                        <ShowToCol />
                    </DataTableField>

                    <DataTableField title="Status">
                        <StatusCol />
                    </DataTableField>

                    <DataTableField title="Created">
                        <CreatedItem />
                    </DataTableField>

                    <DataTableCustomActions>
                        <PublishOrArchiveAction />
                    </DataTableCustomActions>

                </DataTable>

            </Section>

            {
                createModal &&
                <AnnouncementForm
                    onSave={() => refreshSet(true)}
                    onClose={() => createModalSet(false)} />
            }

            {
                editModal && selectedItem &&
                <AnnouncementForm
                    item={selectedItem}
                    onSave={() => refreshSet(true)}
                    onClose={() => {
                        selectedItemSet(undefined);
                        editModalSet(false);
                    }} />
            }

            {
                archiveModal && selectedItem &&
                <Modal id="modal-arhchive-announcement"
                    title="Archive Announcement"
                    buttonText="Archive Announcement"
                    buttonColor="dark"
                    open visible
                    onClose={() => {
                        archiveModalSet(false);
                        selectedItemSet(undefined);
                    }}
                    onConfirm={callback => {
                        hub.Post(API_ROUTES.Announcements + "archive", {
                            id: selectedItem.id
                        }, res => {
                            callback()
                            refreshSet(true)
                        })
                    }}>
                    <AnnouncementView item={selectedItem} />
                </Modal>
            }

            {
                publishModal && selectedItem &&
                <Modal id="modal-publish-announcement"
                    title="Publish Announcement"
                    buttonText="Publish Announcement"
                    buttonColor="success"
                    open visible
                    onClose={() => {
                        publishModalSet(false);
                        selectedItemSet(undefined);
                    }}
                    onConfirm={callback => {
                        hub.Post(API_ROUTES.Announcements + "publish", {
                            id: selectedItem.id
                        }, res => {
                            callback()
                            refreshSet(true)
                        })
                    }}>
                    <AnnouncementView item={selectedItem} />
                </Modal>
            }


            {
                deleteModal && selectedItem &&
                <Modal id="modal-delete-announcement"
                    title="Delete Announcement"
                    buttonText="Delete Announcement"
                    buttonColor="danger"
                    open visible
                    onClose={() => {
                        deleteModalSet(false);
                        selectedItemSet(undefined);
                    }}
                    onConfirm={callback => {
                        hub.Post(API_ROUTES.Announcements + "delete", {
                            id: selectedItem.id
                        }, res => {
                            callback()
                            refreshSet(true)
                        })
                    }}>
                    <AnnouncementView item={selectedItem} />
                </Modal>
            }



        </Layout>
    )
}