import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './App.css';
import './Responsive.css';
import Aside from './components/layouts/aside/Aside';
import Header from './components/layouts/header/Header';
import Switch from "./Switch";
import AuthLayout from './components/layouts/auth/AuthLayout';
import AuthRoutes from './modules/auth/routes/AuthRoutes';
import Icon from './components/base/Icon';
import {
    useGlobal, setDeviceId, getUserToken, setUserId, useSocket
    , setUserToken, useEnvironment, openLoginModal, closeLoginModal, useHub,
    setChatList,
    getUserId,
    useOwnerUserId,
    updatePreferences,
    addOrUpdateJob,
    archiveJobs,
    updateStatuses,
    addOrUpdateChat,
    saveCashedData,
    loadCashedData,
    addOrUpdateAnnouncement,
    dismissAnnouncement,
    hasRole,
    createJob,
} from './core/Global';
import HUBResponse from './models/HUBResponse';
import { API_ROUTES } from './core/Constants';
import Div from './components/base/Div';
import { ToastContainer, toastNotification } from './components/base/Toast';
import FloatAudioPlayer from './components/float/FloatAudioPlayer';
import HangUp from './components/calls/HangUp';
import FloatCallSpy from './components/float/FloatCallSpy';
import SystemExtension from './models/SystemExtension';
import NotificationsDrawer from './components/notification/NotificationsDrawer';
import ShareCalls from './components/calls/ShareCalls';
import PublicRoutes from './modules/public/routes/PublicRoutes';
import SignUpRoutes from './modules/signup/routes/SignUpRoutes';
import PublicLayout from './components/layouts/public/PublicLayout';
import SignUpLayout from './components/layouts/signup/SignUpLayout';
import Container from './components/base/Container';
import ModalLogout from './components/modals/ModalLogout';
import ModalLimited from './components/modals/ModalLimited';
import ModalCreateManager from './components/modals/ModalCreateManager';
import LineSetupWizard from './modules/voice/lines/views/LineSetupWizard';
import ModalSetTimezone from './components/modals/ModalSetTimezone';
import ChatDrawer from './components/drawers/ChatDrawer';
import CreateUserModal from './modules/accounts/components/CreateUserModal';
import ModalDefaultTechsRate from './components/modals/ModalDefaultTechsRate';
import NotificationCenter from './NotificationCenter';
import Modal from './components/base/Modal';
import LoginPage from './modules/auth/views/LoginPage';
import CallsDrawer from './components/drawers/CallsDrawer';
import JobGlobalModals from './modules/crm/jobs/components/jobmodals/JobGlobalModals';
import MessageToJobFloat from './modules/chat/components/MessageToJobFloat';
import ChatGlobalModals from './modules/chat/components/ChatGlobalModals';
import ModalChangeEnvironment from './components/modals/ModalChangeEnvironment';
import { UserTypes } from './core/Enums';
import { HubConnectionState } from '@microsoft/signalr';
import AnnouncementDrawer from './modules/system/announcements/components/AnnouncementDrawer';
import IconButton from './components/base/IconButton';
import AsideMobileMenuDrawer from './components/layouts/aside/AsideMobileMenuDrawer';

let deviceId: string | null;
function App() {


    const [globalState, globalStateSet] = useGlobal();
    const [loaded, loadedSet] = useState<boolean>(false);
    const [authenticated, authenticatedSet] = useState<boolean>(false);
    const [Public, PublicSet] = useState<boolean>(false);
    const [SignUp, SignUpSet] = useState<boolean>(false);
    const [mainLayout, mainLayoutSet] = useState<boolean>(false);

    const hub = useHub();
    const env = useEnvironment();
    const userId = getUserId();
    const ownerUserId = useOwnerUserId();

    const socket = useSocket();

    const init = () => {

        if (window.location.pathname.startsWith("/share/")) {
            $("body").attr("class", "bg-body");
            PublicSet(true);
            loadedSet(true);
            mainLayoutSet(false);
        }
        else if (window.location.pathname.startsWith("/auth/")) {
            $("body").attr("class", "bg-body");
            loadedSet(true);
            mainLayoutSet(false);
        }
        else if (window.location.pathname.startsWith("/join")) {
            $("body").attr("class", "bg-body");
            loadedSet(true);
            SignUpSet(true);
            mainLayoutSet(false);

            hub.Get(API_ROUTES.LoadApp, res => {
                if (res.data) {
                    setDeviceId(res.data.device.id);
                    globalStateSet({
                        countries: res.data.countries,
                        countriesAll: res.data.countriesAll,
                        prices: res.data.prices,
                        device: res.data.device
                    })
                }
            });

        }
        else {
            var token = getUserToken();
            if (!token) document.location.href = "/auth/login";
            mainLayoutSet(true);
            $("body").attr("class", "header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed");
            hub.Get(API_ROUTES.Init, (res: HUBResponse<any>) => {
                if (res.success && res.data) {

                    if (env.isDevelopment) console.log("initialize result", res.data)



                    globalStateSet({
                        user: res.data.user,
                        ownerUser: res.data.ownerUser,
                        showTimezoneModal: (res.data.user.timeZoneId == null),
                        wallet: res.data.user.wallet,
                        roles: res.data.roles,
                        unreadNotificationsCount: res.data.unreadCount,
                        unseenNotificationsCount: res.data.unseenCount,
                        accountPreferences: res.data.preferences,
                        statuses: res.data.statuses,
                        countries: res.data.countries,
                        countriesAll: res.data.countriesAll,
                        prices: res.data.prices,
                        device: res.data.device,
                        jobs: res.data.jobs,
                        jobsFetched: true,
                        chatlist: res.data.chats,
                        announcements: res.data.announcements,
                        cashedData: res.data.cashedData,
                        cashedDataFetched: true
                    })

                    setDeviceId(res.data.device.id);
                    authenticatedSet(true);
                    setUserId(res.data.user.id);

                }
                else {
                    if (window.location.pathname == "" || window.location.pathname == "/") {
                        window.location.href = "/auth/login"
                    }
                    else openLoginModal();
                }
            }, err => {
                setTimeout(() => {
                    init();
                }, 3000);
            });
        }

        setSizes();
        window.addEventListener("resize", setSizes);
        document.title = env.siteTitle;
    }

    const setSizes = () => {
        globalStateSet({ windowsWidth: window.getWindowWidth(), windowsHeight: window.getWindowHeight() })
    }


    useEffect(() => {
        init();
    }, [])


    useEffect(() => {
        if (mainLayout && loaded) {
            window.KTMenuCreate();
            window.KTDrawerCreate();
            window.KTToggleCreate();

            setTimeout(() => {
                window.KTScrollCreate();
            }, 2000);
        }
    }, [mainLayout, loaded])


    useEffect(() => {
        if (authenticated && globalState.user) {

            // const cash = loadCashedData();
            // if (cash) {
            //     globalStateSet({
            //         cashedData: cash,
            //         cashedDataFetched: true
            //     })
            //     return;
            // }

            // hub.Get(API_ROUTES.CashedData, res => {
            //     //console.log("get cashed data from server", res.data)
            //     if (res.success && res.data) {
            //         saveCashedData(res.data)
            //         globalStateSet({

            //         })
            //     }
            // })
        }
    }, [authenticated, globalState.user, globalState.socketState])

    useEffect(() => {
        if (globalState.cashedDataFetched && globalState.socketState == HubConnectionState.Connected) {

            loadedSet(true);
            $("#div-root").removeClass("d-none");

            setTimeout(() => {
                window.KTIntroCreate();
            }, 1000);

            if ("Notification" in window) {
                Notification.requestPermission().then(permission => {
                    //console.log(permission)
                })
            } else {
                console.log("Notifications are not supported on this browser.")
            }

            // onVisibilityChanged();
            // window.addEventListener("visibilitychange", onVisibilityChanged);

            var socketKey = socket.start(
                skt => {

                    skt.invoke("StartListenToEvents", ownerUserId)
                        .then(() => {

                            skt.on("AddOrUpdateJob", job => addOrUpdateJob(job))
                            skt.on("ArchiveJobs", ids => archiveJobs(ids))

                            skt.on("UpdateStatuses", data => updateStatuses(data))

                            skt.on("AddOrUpdateChat", data => {
                                addOrUpdateChat(data);
                            });

                            skt.on("UpdateChatList", data => {
                                setChatList(data);
                            });

                            skt.on("UpdateAccountPreferences", data => {
                                if (data) updatePreferences(data);
                            });


                            skt.on("PublishAnnouncement", data => addOrUpdateAnnouncement(data));
                            skt.on("DismissAnnouncement", data => dismissAnnouncement(data));

                        });

                    if (globalState.user?.userType == UserTypes.Admin) {
                        skt.invoke("StartListenToAdminsEvents", ownerUserId)
                            .then(() => {

                            });
                    }

                    if (globalState.user?.userType == UserTypes.Agent) {
                        skt.invoke("StartListenToAgentsEvents", ownerUserId)
                            .then(() => {

                            });
                    }


                    skt.invoke("StartListenToPersonalEvents", userId)
                        .then(() => {

                            skt.on("ForceLogout", () => {
                                setUserToken("");
                                document.location.href = "/auth/login";
                            });

                        });



                }, skt => {
                    skt.invoke("StopListenToEvents", ownerUserId)
                    skt.invoke("StopListenToAdminsEvents", ownerUserId)
                    skt.invoke("StopListenToAgentsEvents", ownerUserId)
                    skt.invoke("StopListenToPersonalEvents", userId)
                });

            return () => {
                window.removeEventListener("resize", setSizes);
                //window.removeEventListener("visibilitychange", onVisibilityChanged);
                socket.stop(socketKey);
            }
        }

    }, [globalState.cashedDataFetched, globalState.socketState])


    return (
        <Router>

            <Div visible={!loaded} className='app-loader text-center'>
                <Icon icon='asterisk' type='regular' spin />
                <h2>App Loading</h2>
            </Div>

            <Container visible={loaded && !authenticated && !Public && !SignUp}>
                <AuthLayout>
                    <AuthRoutes />
                </AuthLayout>
            </Container>

            <Container visible={loaded && SignUp}>
                <SignUpLayout>
                    <SignUpRoutes />
                </SignUpLayout>
            </Container>

            <Container visible={loaded && Public}>
                <PublicLayout>
                    <PublicRoutes />
                </PublicLayout>
            </Container>

            <Div visible={mainLayout} id="div-root" className="page d-flex flex-row flex-column-fluid d-none">
                <Aside />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header />
                    {
                        loaded && globalState.user && <Switch />
                    }
                </div>
                {
                    globalState.selectedCallToPlay && <FloatAudioPlayer selectedCall={globalState.selectedCallToPlay} />
                }

                {
                    globalState.selectedCallToListen && <FloatCallSpy selectedCall={globalState.selectedCallToListen} />
                }


                <HangUp />

                {
                    globalState.selectedCallsToShare && <ShareCalls ids={globalState.selectedCallsToShare} />
                }


                <NotificationsDrawer authenticated={authenticated} />

                <ModalCreateManager />

                <ModalLogout />
                <ModalLimited />


                {globalState.showTimezoneModal && <ModalSetTimezone />}
                {globalState.showDefaultTechRateModal && <ModalDefaultTechsRate />}

                {globalState.showLineWizard && <LineSetupWizard />}

                {globalState.chatJob && <ChatDrawer job={globalState.chatJob} />}
                {globalState.callsDrawerJob && <CallsDrawer job={globalState.callsDrawerJob} />}

                {globalState.createUser && <CreateUserModal />}
                {globalState.createUserType && <CreateUserModal type={globalState.createUserType} />}


                <JobGlobalModals />
                <ChatGlobalModals />


                {globalState.messageToJob && <MessageToJobFloat msg={globalState.messageToJob} />}

                {globalState.updateEnvironemnt && <ModalChangeEnvironment />}




            </Div>

            <ToastContainer />

            {globalState.user?.id && globalState.user.ownerUserId && <NotificationCenter userId={globalState.user.id} ownerId={globalState.user.ownerUserId} />}

            {globalState.bottomComponents && <globalState.bottomComponents />}

            {
                globalState.loginModal &&
                <Modal id="modal-relogin"
                    title="Login"
                    onClose={() => closeLoginModal()}
                    open visible noHeader noFooter noForm staticBackdrop>
                    <div className='py-10'>
                        <LoginPage redirectUrl={window.location.pathname} />
                    </div>
                </Modal>
            }

            {globalState.openAnnouncementsDrawer && <AnnouncementDrawer />}

            {globalState.mobileMenuDrawer && <AsideMobileMenuDrawer />}


            <audio id="maincall"></audio>
        </Router>
    );
}

export default App;
