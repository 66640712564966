import React from "react";
import { BootstrapColors } from "../../core/Types";

interface Props {
    id?: string
    visible?: boolean
    backgroundColor?: BootstrapColors
    className?: string
    height?: number
    width?: number
    fluid?: boolean
    children?: any
}

function Section(props: Props) {

    const className = () => {
        var cls = props.className ?? "";
        if(props.backgroundColor) cls += " bg-" + props.backgroundColor;
        return cls;
    }

    return (
        props.visible == undefined || props.visible ? 
        <section id={props.id} className={className()} 
            style={{
                width: props.width,
                height: props.height
            }}>
            <div className={props.fluid ? "container-fluid" : "container-xxl"}>
                {props.children}
            </div>
        </section>
        :
        <></>
    )
}

export default Section;

