import React, { useEffect, useState } from "react";
import TextBox from '../../../components/form/TextBox';
import UserGroup from '../models/UserGroup';
import DropDown from '../../../components/form/DropDown';
import FormPage from '../../../components/form/FormPage';
import { API_ROUTES } from '../../../core/Constants';
import Layout from '../../../components/layouts/Layout';
import Div from '../../../components/base/Div';
import Indicator from '../../../components/base/Indicator';
import { UserGroupValidator } from "../validators/UserGroupValidator";
import { useParams } from "react-router-dom";
import { setPage } from '../../../core/Global';
import Hub from '../../../api/Hub';
import { CompanyValidator } from '../validators/CompanyValidator';
import AvatarUploade from "../../../components/form/AvatarUploade";
import Company from '../models/Company';
import FormTitle from '../../../components/form/FormTitle';
import UserPicker from '../../../components/pickers/UserPicker';
import { UserTypes } from "src/core/Enums";
import Tagify from "../../../components/tagify/Tagify";


function CompanyForm() {	

    const hub = new Hub<Company>(API_ROUTES.Companies);
    const { selectedId } = useParams(); 
    const [model, modelSet] = useState<Company>();
    const [loading, loadingSet] = useState<boolean>(false);


  const init = async () => {
    if (selectedId) {
        //voicemailSet(model?.voicemailEnabled ?? false);
        setPage("Edit Company", "Account", "Companies");
        loadingSet(true);

        await hub.Find(selectedId, res => {
            if (res.success) {
                modelSet(res.data);
                setTimeout(() => {
                    loadingSet(false);
                }, 500);
            }
        });
    }
    else {
        setPage("Create Company", "Account", "Companies");
    }
}

  useEffect(()=> {
    init();
  }, [])
  
  return (
    <Layout>
        <Div visible={loading} className="p-20 text-center">
            <Indicator text="Please wait..."  />
        </Div>
        <FormPage apiRoute={API_ROUTES.Companies} 
                formValidator={CompanyValidator}
                selectedId={selectedId}
                objectName="Company"
                visible={!loading}
                redirectTo="/accounts/companies">


            <div className="row">
                <div className="col-sm-2 big-icon">
                    <AvatarUploade name="logoUrl" type="logos" imageUrl={model?.logoUrl} />
                </div>
                <div className="col-sm-10 pt-5">
                    <TextBox name="name" placeholder="Company Name" defaultValue={model?.name?.toString()}
                        maxLength={50} inlineStyle largeText noMarginBottom autoFocus />
                    <Tagify name="userIds" id="pickUserIds" placeholder="Select a Customer" apiUrl={API_ROUTES.TagifyCustomers} selectedIds={model?.userIds} />
                </div>
            </div>

            <FormTitle title="Company Information" />
            <TextBox name="email" label="Email Address" defaultValue={model?.email} email />  
            
            <TextBox name="address1" label="Address Line 1" defaultValue={model?.addressLine1} />
            
        </FormPage>
        
    </Layout>
  );
}

export default CompanyForm;
