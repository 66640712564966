import { useEffect, useState } from "react"
import FlexRow from "../../../components/base/FlexRow"
import Icon from "../../../components/base/Icon"
import { CompactJob, CompactUser } from "../../../core/CompactModels"
import ChatMessage from "../models/ChatMessage"
import { isDemo, openJobQuickView, openMessageToJob, toPrettyDate, useEnvironment, useHub, useSocket } from "../../../core/Global"
import { API_ROUTES } from "../../../core/Constants"
import { ChatEnvironments, MediaTypes } from "../../../core/Enums"
import Avatar from "../../../components/base/Avatar"
import DropdownMenu from "../../../components/menu/DropdownMenu"
import { copyToClipboard } from "../../../core/Helper"
import { toastDefault, toastError } from "../../../components/base/Toast"


interface IProps {
    message: ChatMessage
    lastSender?: CompactUser
    preview?: boolean
    onAssignToJob?: (msg: ChatMessage) => void
    onFilterByJob?: (p: CompactJob) => void
    onClaiming?: (msg: ChatMessage) => void
    onClaimed?: (msg: ChatMessage) => void
}

export default function ChatMessageItem(props: IProps) {

    const hub = useHub()
    const env = useEnvironment()
    const [message, messageSet] = useState<ChatMessage>(props.message)
    useEffect(() => messageSet(props.message), [props.message])

    const socket = useSocket()

    const [uploadStarted, uploadStartedSet] = useState<boolean>(false)
    const [sendStarted, sendStartedSet] = useState<boolean>(false)

    const [isImage, isImageSet] = useState<boolean>(false)
    const [isAudio, isAudioSet] = useState<boolean>(false)
    const [isVideo, isVideoSet] = useState<boolean>(false)
    const [isFile, isFileSet] = useState<boolean>(false)

    const [claiming, claimingSet] = useState<boolean>(false)

    const [failed, failedSet] = useState<boolean>(false)

    useEffect(() => {

        if (message.uploading && message.fileToUpload) {
            if (uploadStarted) return;
            uploadStartedSet(true);

            hub.Upload({
                category: "chat",
                pathId: props.message.chatId,
                file: message.fileToUpload
            }, res => {
                if (env.isDevelopment) {
                    console.log(res);
                    console.log(env.cdnUrl + res.data);
                }
                if (res.success && res.data) {
                    hub.Post(API_ROUTES.ChatSend, {
                        chatId: message.chatId,
                        text: "",
                        fileName: message.media?.fileName,
                        filePath: res.data,
                        jobId: message.jobId,
                        uuid: message.uuid
                    }, res => {
                        if (res.success) {
                            messageSet(res.data);
                        }
                    });
                }
            });
        }

        if (message.sending) {
            if (sendStarted) return;
            sendStartedSet(true);

            hub.Post(API_ROUTES.ChatSend, {
                chatId: message.chatId,
                text: message.text,
                jobId: message.jobId,
                uuid: message.uuid
            }, res => {
                if (res.success) {
                    messageSet(res.data);
                }
                else {
                    failedSet(true)
                }
            })
        }

    }, [])

    useEffect(() => {
        if (message.id) {

            isImageSet(false)
            isAudioSet(false)
            isVideoSet(false)
            isFileSet(false)

            if (message.media) {
                if (message.media.mediaType == MediaTypes.Image) isImageSet(true);
                else if (message.media.mediaType == MediaTypes.Audio) isAudioSet(true);
                else if (message.media.mediaType == MediaTypes.Video) isVideoSet(true);
                else isFileSet(true);
            }

            var socketKey = socket.start(
                skt => {
                    skt.on("UpdateChatMessage-" + message.id, msg => {
                        messageSet(msg)
                    });
                }, skt => {
                    skt.off("UpdateChatMessage-" + message.id);
                });


            return () => {
                socket.stop(socketKey);
            }
        }
    }, [message])

    const toHiddenDemoText = (txt: string) => {
        const noBrackets = txt.replace(/\[.*?\]/g, '');
        const noPhoneDetails = noBrackets.replace(/(Phone:\s*)\([^\)]+\)/g, '$1XXXXXXXX');
        const noAddressDetails = noPhoneDetails.replace(/(Address:\s*)[^\n]+/g, '$1XXXXXXXXXXXXXXX');

        return noAddressDetails;
    }


    const parseMessageText = (text?: string) => {
        if (!text) return null;

        const LINK_REGEX = /(https?:\/\/[^\s]+)/g;
        const JOB_ID_REGEX = /#([A-Z0-9]{7})/g;

        const parts = text.split(/(https?:\/\/[^\s]+|#[A-Z0-9]{7})/g).map((part, index) => {
            if (part.match(LINK_REGEX)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            } else if (part.match(JOB_ID_REGEX)) {

                const jobId = part.substring(1)

                return (
                    <a href="#" key={index}
                        onClick={e => {
                            e.preventDefault()
                            openJobQuickView(jobId)
                        }}>
                        {part}
                    </a>
                );

            }
            return part;
        });

        return parts;
    }

    return (
        <>
            {
                failed ? <></>
                    :
                    <div id={"chatmsg-" + props.message.id} className={"d-flex mb-2" + (message.isRecipient ? " justify-content-start" : " flex-row-reverse")}>

                        {
                            (!message.isRecipient || props.preview) &&
                            <>
                                {
                                    props.lastSender?.id == message.senderUser?.id ?
                                        <div className="symbol symbol-35px symbol-circle">
                                            <div style={{ width: 35 }}></div>
                                        </div>
                                        : message.senderUser?.avatarUrl ?
                                            <div className="symbol symbol-35px symbol-circle" title={message.senderUser?.fullName}>
                                                <img alt={message.senderUser?.fullName} src={message.senderUser?.avatarUrl} />
                                            </div>
                                            :
                                            <div className="symbol symbol-35px symbol-circle" title={message.senderUser?.fullName}>
                                                <span className="symbol-label bg-light-danger text-danger fs-1 fw-bolder">{message.senderUser?.fullName?.substring(0, 1).toUpperCase()}</span>
                                            </div>
                                }
                            </>
                        }

                        <div className={"d-flex flex-column flex-grow-1 mx-3 " + (message.isRecipient ? "align-items-start" : "align-items-end")}>

                            {
                                (!message.isRecipient && props.lastSender?.id != message.senderUser?.id || props.preview) &&
                                <div className="d-flex align-items-center justify-content-end mb-1">

                                    <div className="ms-3">
                                        <a className="fs-8 text-gray-900 me-1">
                                            {
                                                isDemo() ? "User" : message.senderUser?.firstName
                                            }
                                        </a>
                                        {/* <span className="text-muted fs-7 mb-1">2 mins</span> */}
                                    </div>

                                </div>
                            }

                            <div className={"p-3 rounded chat-message-text " + (message.isRecipient ? "bg-light-info" : "bg-chat-system")}
                                style={{
                                    whiteSpace: "pre-line",
                                    width: "95%",
                                    maxWidth: 400
                                    // minWidth: isImage ? 300 : 400,
                                    // width: isImage ? 300 : isVideo ? 400 : "auto"
                                }}>

                                {
                                    isFile ?
                                        <FlexRow>

                                            {
                                                message.uploading ?
                                                    <>
                                                        <div className="symbol symbol-35px symbol-circle">
                                                            <div className="symbol-label"
                                                                style={{
                                                                    backgroundColor: message.isRecipient ? "white" : "#126fa3"
                                                                }}>
                                                                <Icon icon="spinner-third" spin size={30} color="#fff" type="thin" />
                                                            </div>
                                                        </div>
                                                        <div className="ms-2">
                                                            {message.media?.fileName}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <a href={env.apiUrl + "/base/public/chat/file/download/" + message.id} target="_blank" className="symbol symbol-35px symbol-circle" title="Click to Download">
                                                            <div className="symbol-label"
                                                                style={{
                                                                    backgroundColor: message.isRecipient ? "white" : "#126fa3"
                                                                }}>
                                                                <Icon icon={message.media?.mediaIcon} size={15} color="#fff" />
                                                            </div>
                                                        </a>
                                                        <a href={env.apiUrl + "/base/public/chat/file/download/" + message.id} target="_blank" className="ms-2" title="Click to Download">
                                                            {message.media?.fileName}
                                                        </a>
                                                    </>

                                            }


                                        </FlexRow>
                                        : isImage ?
                                            <div>
                                                <img src={message.media?.fileUrl} className="w-100" onLoad={e => {

                                                }} />
                                            </div>
                                            : isVideo ?
                                                <div>
                                                    <video src={message.media?.fileUrl} className="w-100" controls></video>
                                                </div>
                                                : isAudio ?
                                                    <div>
                                                        <audio src={message.media?.fileUrl} className="w-100" controls></audio>
                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            isDemo() ? toHiddenDemoText(message.text ?? "")
                                                                : parseMessageText(message.text)
                                                        }

                                                        {
                                                            message.isJob && (message.isRecipient || env.isDevelopment) &&
                                                            <div className="mb-2 text-end">
                                                                <a href="#" target="_blank" className={"btn btn-info btn-xs fs-8"}
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        if (message.text && message.id) openMessageToJob(message.text, message.id)
                                                                    }}>
                                                                    Add as Job
                                                                </a>
                                                            </div>
                                                        }

                                                    </div>
                                }
                                <FlexRow className="chat-message-footer">
                                    {
                                        message.uploading ?
                                            <div className="fs-9">
                                                Uploading...
                                            </div>
                                            :
                                            message.sending ?
                                                <div className="fs-9">
                                                    Sending...
                                                </div>
                                                :
                                                <FlexRow>
                                                    {
                                                        message.sentViaGSM &&
                                                        <Icon icon="signal-strong" doutone size={12} className="me-2" title="Sent via SMS" />
                                                    }
                                                    {
                                                        message.sentViaApp &&
                                                        <Icon icon="mobile-signal-out" doutone size={14} className="me-2" title="Sent via Rinvox App" />
                                                    }
                                                    <div className="fs-9" style={{ height: 12 }}>
                                                        {toPrettyDate(message.createdAt)}
                                                    </div>
                                                </FlexRow>
                                    }

                                    {
                                        message.job &&
                                        <div className="fs-9 ms-2 cursor-pointer"
                                            onClick={e => {
                                                if (props.onFilterByJob && message.job) props.onFilterByJob(message.job);
                                            }}>
                                            #{message.job.jobId}
                                        </div>
                                    }

                                    {
                                        message.claimedUser &&
                                        <FlexRow className="ms-2">
                                            <Icon icon="check-circle" className="me-1 text-success" type="solid" />
                                            <div className="fs-8">
                                                {isDemo() ? "User 1" : message.claimedUser.fullName}
                                            </div>
                                        </FlexRow>
                                    }

                                    {
                                        message.unread && !message.claimedUser &&
                                        <FlexRow className="ms-2"
                                            onClick={e => {
                                                if (props.onClaiming) props.onClaiming(message);
                                                claimingSet(true);

                                                setTimeout(() => {
                                                    hub.Post(API_ROUTES.ChatClaim, {
                                                        id: message.id
                                                    }, res => {
                                                        claimingSet(false)
                                                        if (props.onClaimed) props.onClaimed(message)
                                                    })
                                                }, 500);

                                            }}>
                                            {
                                                claiming ?
                                                    <>
                                                        <Icon icon="spinner-third" spin />
                                                        <div className="fs-8 fw-bold ms-2">
                                                            Please wait...
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <Icon icon="check" className="me-1 text-danger" type="solid" />
                                                        <div className="fs-8 fw-bold text-danger">
                                                            Claim the message
                                                        </div>
                                                    </>
                                            }

                                        </FlexRow>
                                    }

                                    {
                                        message.media && !message.uploading &&
                                        <a href={env.apiUrl + "/base/public/chat/file/download/" + message.id} target="_blank" className="fs-9 ms-2">
                                            Download ({message.media.fileSizeLabel})
                                        </a>
                                    }



                                    {
                                        !props.preview &&
                                        <FlexRow className="ms-auto">

                                            {/* <div className="me-2 cursor-pointer">
                                                <a className={"cursor-pointer px-1 " + (message.isRecipient ? "text-dark" : "text-white")} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    <Icon icon="eye" size={11} />
                                                    <span className="fs-8 ms-1">
                                                        {message.readedUsers?.length ?? 0}
                                                    </span>
                                                </a>
                                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-1" data-kt-menu="true">
                                                    <div className="menu-item">
                                                        {
                                                            (message.readedUsers?.length ?? 0) > 0 ?
                                                                <span className="menu-link px-3 fs-8">
                                                                    <Icon icon="eye" className="me-2" type="solid" size={11} />
                                                                    {message.readedUsers?.length} User{(message.readedUsers?.length ?? 0) > 1 ? "s" : ""} Seen
                                                                </span>
                                                                :
                                                                <span className="menu-link text-muted px-3 fs-8">
                                                                    <Icon icon="eye" className="me-2 text-muted" type="solid" size={11} />
                                                                    Nobody Seen
                                                                </span>
                                                        }
                                                    </div>

                                                    {
                                                        (message.readedUsers?.length ?? 0) > 0 &&
                                                        <div className="separator"></div>
                                                    }

                                                    {
                                                        message.readedUsers?.map((u, k) =>
                                                            <div key={k} className="menu-item">
                                                                <span className="menu-link px-3 fs-8" title={u.fullName}>
                                                                    <Avatar avatarUrl={u.avatarUrl} fullName={u.fullName} width={24} className="me-2" />
                                                                    <span className="chat-seenlist-name">
                                                                        {isDemo() ? "User " + (k + 1) : u.fullName}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div> */}

                                            {
                                                !message.isRecipient &&
                                                <div className="me-1">
                                                    {
                                                        message.messagesStatus == "delivered" ?
                                                            <Icon icon="check-double" size={11} />
                                                            : message.messagesStatus == "sent" ?
                                                                <Icon icon="check" size={11} />
                                                                :
                                                                <Icon icon="clock" size={11} />
                                                    }
                                                </div>
                                            }

                                            <div>

                                                <a className={"cursor-pointer px-1 text-dark"} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    <Icon icon="ellipsis-vertical" size={11} />
                                                </a>
                                                <DropdownMenu
                                                    source={[
                                                        {
                                                            title: "Copy Text",
                                                            icon: "copy",
                                                            async action() {
                                                                const cp = await copyToClipboard(message.text, "Message has been copied to the clipboard!");
                                                                if (!cp) toastError("Copy action is not supported in your browser!")
                                                            },
                                                        },
                                                        {
                                                            title: "Assign to Job",
                                                            icon: "hashtag",
                                                            action() {
                                                                if (message && props.onAssignToJob) props.onAssignToJob(message);
                                                            },
                                                        }
                                                    ]} />

                                            </div>


                                        </FlexRow>
                                    }

                                </FlexRow>



                            </div>

                        </div>
                    </div>
            }
        </>
    )
}