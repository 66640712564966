import React, { useEffect, useState } from "react";
import filteringProps from "../filtering/jobmetros.json";
import Layout from "../../../../components/layouts/Layout";
import { DataForm, DataTable, DataTableField } from "../../../../components/data/DataTable";
import { API_ROUTES } from '../../../../core/Constants';
import { getUser, hasRole, setPage, toPrettyDate, useEnvironment, useHub } from '../../../../core/Global';
import Modal from "../../../../components/base/Modal";
import Icon from "../../../../components/base/Icon";
import FlexRow from "../../../../components/base/FlexRow";
import { Link } from "react-router-dom";
import Section from "../../../../components/base/Section";
import Avatar from "../../../../components/base/Avatar";
import JobMetro from "../models/JobMetro";
import MetroEditModal from "./MetroEditModal";
import MetroFormModal from "./MetroFormModal";
import DataTableActiveToggle from "../../../../components/data/DataTableActiveToggle";

function MetrosList() {

  const hub = useHub();
  const user = getUser();
  const env = useEnvironment();
  const [refresh, refreshSet] = useState<boolean>();

  const [createMetro, createMetroSet] = useState<boolean>(false);
  const [editMetro, editMetroSet] = useState<boolean>(false);



  const [buildMapEdit, buildMapEditSet] = useState<boolean>(false);
  const [clearForm, clearFormSet] = useState<boolean>(false);
  const [editId, editIdSet] = useState<string>();
  const [selectedItem, selectedItemSet] = useState<JobMetro>();
  const [editFetching, editFetchingSet] = useState<boolean>();


  const [countryIdEdit, countryIdEditSet] = useState<string>();
  const [stateIdEdit, stateIdEditSet] = useState<string>();

  const [showTechs, showTechsSet] = useState<boolean>(false);

  const add = () => {
    createMetroSet(true);
  }

  const edit = (data: any) => {
    editIdSet(data.id);
    selectedItemSet(undefined);
    editFetchingSet(true);
    editMetroSet(true);

    hub.Get(API_ROUTES.JobMetros + data.id, res => {
      if (res.success) {
        selectedItemSet(res.data);
        editFetchingSet(false);
        // setTimeout(() => {
        //   $("#txt-editmetro-name").focus();
        //   buildMapEditSet(true);
        // }, 100);
      }
    })
  }

  useEffect(() => {
    if (selectedItem) {
      countryIdEditSet(selectedItem.countryId);
      stateIdEditSet(selectedItem.stateId);
    }
  }, [selectedItem])



  const NameItem = (data: any) => (
    <FlexRow onClick={() => edit(data)}>
      <Icon icon="circle" type="solid" size={16} className="me-2" color={data.color} />
      <div>
        {data.name}
      </div>
    </FlexRow>
  )

  const JobsCount = (data: any) => (
    <div>
      {
        data.jobsCount > 0 ?
          <Link to={"/system/base/jobs?groups=" + data.id}>
            {data.jobsCount}
          </Link>
          :
          <div>
            0
          </div>
      }

    </div>
  )

  //
  const TechsItem = (data: any) => (
    <div>
      {
        data.techs.length > 0 ?
          <a href={"/accounts/technicians?metros=" + data.id} target="_blank" onClick={e => {
            // e.preventDefault();
            // selectedItemSet(data);
            // showTechsSet(true);
          }}>
            {data.techs.length}
          </a>
          :
          <>{data.techs.length}</>
      }
    </div>
  )

  const UserItem = (data: any) => (
    <div>
      <div>
        {data.ownerUser?.fullName ?? "System Metro"}
      </div>
      {
        data.creatorUser &&
        <div className='d-flex align-items-center fs-8 mt-1'>
          <Icon icon="user" className="me-1" size={12} />
          <span>
            created by: {data.creatorUser?.username}
          </span>
        </div>
      }
    </div>
  )

  const CreatedItem = (data: JobMetro) => (
    <div>
      <div className='d-flex align-items-center'>
        {toPrettyDate(data.createdAt)}
      </div>
      {
        data.creatorUser &&
        <FlexRow className='fs-8 text-gray-600 mt-1'>
          <div>
            <Icon icon="user" className="me-2" size={10} type="solid" />
          </div>
          <div className="fs-8">
            By: {data.creatorUser.fullName}
          </div>
        </FlexRow>
      }

    </div>
  )

  useEffect(() => {
    setPage("Metros", "CRM", "Base Data");



    window.onModalOpen("modal-rename-metro", () => {
      $("#txt-rename-name").focus();
      $("#txt-rename-name").select();
    });

  }, []);

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        refreshSet(false);
      }, 1000);
    }

    if (clearForm) {
      setTimeout(() => {
        clearFormSet(false);
      }, 1000);
    }


  }, [refresh, clearForm])

  return (
    <Layout wide>
      <Section fluid>
        <DataTable
          objectName="Metro"
          apiRoute={API_ROUTES.JobMetros}
          route="/system/base/metros"
          ascending
          filteringProps={filteringProps}
          edit editCustom={edit} addCustom={add}
          delete={hasRole("DeleteMetros")}
          refresh={refresh}>
          <DataTableField title="" width={50}>
            <DataTableActiveToggle route={API_ROUTES.JobMetros} />
          </DataTableField>
          <DataTableField title="Name">
            <NameItem />
          </DataTableField>
          <DataTableField title="Country" dataValueName="countryName" />
          <DataTableField title="State" dataValueName="stateName" />
          <DataTableField title="Technicians">
            <TechsItem />
          </DataTableField>
          <DataTableField title="Owner User" hidden={!user?.isSuperAdmin}>
            <UserItem />
          </DataTableField>

          <DataTableField title="Created">
            <CreatedItem />
          </DataTableField>

        </DataTable>
      </Section>


      {
        createMetro &&
        <MetroFormModal
          onClose={() => createMetroSet(false)}
          onSubmit={() => refreshSet(true)} />
      }

      {
        editMetro && selectedItem &&
        <MetroFormModal metro={selectedItem}
          onClose={() => editMetroSet(false)}
          onSubmit={() => refreshSet(true)} />
      }


      {
        showTechs &&
        <Modal id="modal-metro-techs"
          title={`${selectedItem?.name}: Technicians`}
          open visible
          onClose={() => {
            selectedItemSet(undefined);
            showTechsSet(false);
          }}>
          <div>
            {
              selectedItem?.techs?.map((t, k) =>
                <FlexRow key={k} className="border-bottom py-2">
                  <Avatar avatarUrl={t.avatarUrl} fullName={t.fullName} width={30} />
                  <div className="ms-2">
                    {t.fullName}
                  </div>
                </FlexRow>
              )
            }
          </div>
        </Modal>
      }



    </Layout>
  );
}

export default MetrosList;
